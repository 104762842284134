import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { auditLogsAccessControl } from '../access-control/auditLogsAccess.configuration'

const AuditLogsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AuditLogsListPage" */
      '../pages/list/AuditLogsListPage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const auditLogsRouter = (
  <DomainRoute route="audit-logs" accessControl={auditLogsAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="audit-logs-list"
        component={AuditLogsListPage}
        scopePicker={{
          titles: ['auditLogsDomain.title'],
        }}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
