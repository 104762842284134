import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { partnerPickDevicesRouter } from './routing/partnerPickDevicesRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { partnerPickDevicesAccessControl } from './access-control/partnerPickDevicesAccess.configuration'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'partner-pick-devices',
  totem: {
    entity: 'partner-pick-devices-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [partnerPickDevicesRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Shoppers, //old nav group -- delete this navItem when `dashboard_store_ops_nav` feature toggle is fully enabled
      subNavItems: [
        {
          type: 'item',
          labelId: 'partnerPickDevicesDomain.title',
          route: 'partner-pick-devices-list',
          accessControl: partnerPickDevicesAccessControl,
        },
      ],
    },
    {
      attachTo: NavItem.StoreOperations,
      attachToV2: NavEntryPoint.OperationsLabor,
      subNavItems: [
        {
          type: 'item',
          labelId: 'partnerPickDevicesDomain.title.new',
          route: 'partner-pick-devices-list',
          accessControl: partnerPickDevicesAccessControl,
          position: 3,
          positionNavV2: 3,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
