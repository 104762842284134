import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

// Merchandising Routes
const URL_MERCHANDISING = `${URL_RETAILER_SCOPED}/merchandising`
const URL_MERCHANDISING_HERO_BANNERS = `${URL_MERCHANDISING}/hero-banners`
const URL_MERCHANDISING_HERO_BANNERS_LIST = `${URL_MERCHANDISING}/hero-banners/list`
const URL_MERCHANDISING_HERO_BANNERS_EDIT = `${URL_MERCHANDISING}/hero-banners/edit/:heroBannerId`
const URL_MERCHANDISING_HERO_BANNERS_DETAILS = `${URL_MERCHANDISING}/hero-banners/details/:heroBannerId`
const URL_MERCHANDISING_HERO_BANNERS_CREATE = `${URL_MERCHANDISING}/hero-banners/create`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'merchandising'>
  | DashRouteType<'merchandising-hero-banners'>
  | DashRouteType<'merchandising-hero-banners-list'>
  | DashRouteType<'merchandising-hero-banners-details'>
  | DashRouteType<'merchandising-hero-banners-edit'>
  | DashRouteType<'merchandising-hero-banners-create'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  merchandising: URL_MERCHANDISING,
  'merchandising-hero-banners': URL_MERCHANDISING_HERO_BANNERS,
  'merchandising-hero-banners-list': URL_MERCHANDISING_HERO_BANNERS_LIST,
  'merchandising-hero-banners-details': URL_MERCHANDISING_HERO_BANNERS_DETAILS,
  'merchandising-hero-banners-edit': URL_MERCHANDISING_HERO_BANNERS_EDIT,
  'merchandising-hero-banners-create': URL_MERCHANDISING_HERO_BANNERS_CREATE,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
