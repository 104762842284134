import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, AdminNavEntryPoint } from '@retailer-platform/dashboard/routing'
import { caperRouter } from './routing/caperRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'caper',
  totem: {
    entity: 'caper-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [caperRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [],
}

export default definition
