import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_PARTNER_PICK_DEVICES = `${URL_RETAILER_SCOPED}/partner-pick-devices`
const URL_PARTNER_PICK_DEVICES_LIST = `${URL_PARTNER_PICK_DEVICES}/list`
const URL_PARTNER_PICK_DEVICES_EDIT = `${URL_PARTNER_PICK_DEVICES}/edit/:device_id`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'partner-pick-devices'>
  | DashRouteType<'partner-pick-devices-list'>
  | DashRouteType<'partner-pick-devices-edit'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'partner-pick-devices': URL_PARTNER_PICK_DEVICES,
  'partner-pick-devices-list': URL_PARTNER_PICK_DEVICES_LIST,
  'partner-pick-devices-edit': URL_PARTNER_PICK_DEVICES_EDIT,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
