import { ENV_PRODUCTION, DEBUG_ANALYTICS } from '../../../../legacy/common/constants'
import { ROUTES_BY_NAME } from '../../../routing/routes'

// If debugAnalytics query param is set to `true`, then we should be logging
// the events in the console
export const isDebugMode = () =>
  DEBUG_ANALYTICS || window.location.search.includes('debugAnalytics=true')

export const isPublicPage = () => {
  const currentPath = window.location.pathname
  const publicPath = ROUTES_BY_NAME['public']
  return currentPath.startsWith(publicPath)
}

export const logDebugEvent = (identifier: string, description: string, payload?: object) => {
  if (!isDebugMode()) return

  // eslint-disable-next-line no-console
  console.log('%c✅%s: %O %O', 'color: #00ad43;', identifier, description, payload || '')
}

// If we are in production mode, or we have useDebugMode enabled, then we want to
// track events. Otherwise, we should be skipping any event tracking.
// We also skip event tracking on public pages.
export const eventTrackingEnabled = () => (ENV_PRODUCTION || isDebugMode()) && !isPublicPage()
