import React, { type FunctionComponent } from 'react'
import { useRequiredAdminNavDataLoadingStatus } from './utils/nav.hooks'
import { AdminNavContent } from './components/admin-nav/AdminNavContent'

export const AdminNav: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { loading, error } = useRequiredAdminNavDataLoadingStatus()

  if (loading || error) return null

  return <AdminNavContent />
}
