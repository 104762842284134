import styled from '@emotion/styled'
import { spacing } from '@retailer-platform/shared-components'

interface NavSeparatorProps {
  backgroundColor?: string
}

export const NavSeparator = styled.hr((props: NavSeparatorProps) => ({
  border: 'none',
  height: '1px',
  width: '100%',
  backgroundColor: props?.backgroundColor || '#cccccc',
  marginTop: spacing.X12,
  marginBottom: spacing.X12,
}))
