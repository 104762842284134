import styled from '@emotion/styled'
import React from 'react'
import { type Color, type ThemeColors, useTheme } from '@instacart/ids-core'
import { spacing } from '../../foundation'

export type CircleSize = 'small' | 'medium' | 'large' | number
export type CircleColor = 'success' | 'inactive' | 'error' | 'pending' | Color | string

const circleSizeSp = {
  small: 8,
  medium: 11,
  large: 14,
}

const circleStatusToIdsColorMap: { [index: string]: Color } = {
  success: 'systemSuccessRegular',
  inactive: 'systemGrayscale50',
  error: 'systemDetrimentalRegular',
  pending: 'brandExpressDark',
}

/**
 * Converts the `CircleColor` string into a hex color string.
 *
 * @param circleColor the color to convert to hex
 * @param idsColors IDS color theme palette
 * @returns a hex code for the color, eg: #FF0000
 */
const circleColorToHex = (circleColor: CircleColor, idsColors: ThemeColors) => {
  const colorString = circleStatusToIdsColorMap[circleColor]

  // fall through logic:
  // 1) use the 'success' | 'inactive' | 'error' | 'pending' types as IDS colors, if the input maps correctly
  // 2) use the IDS colors, if the input maps correctly
  // 3) use the raw input (probably a hex color)
  return idsColors[(colorString || circleColor) as Color] || circleColor
}

export interface StatusCircleProps extends CircleProps {
  children?: React.ReactNode
  className?: string
}

interface CircleProps {
  size: CircleSize
  color: CircleColor
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: spacing.X4,
})

const CircleStyle = styled.div(
  ({ size, color, idsColors }: CircleProps & { idsColors: ThemeColors }) => ({
    backgroundColor: circleColorToHex(color, idsColors),
    width: typeof size === 'number' ? size : circleSizeSp[size],
    height: typeof size === 'number' ? size : circleSizeSp[size],
    borderRadius: '50%',
  })
)

export const StatusCircle = ({ children, ...rest }: StatusCircleProps) => {
  const { colors } = useTheme()

  return (
    <Container>
      <CircleStyle idsColors={colors} {...rest} />
      {children}
    </Container>
  )
}
