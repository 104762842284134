import { useRetailerId } from '@retailer-platform/dashboard/routing'
import { FilterType, type InsightsPortalValue, type Moment } from '../api'

export const getInsightsNumber = (insightsApiValue: InsightsPortalValue): number =>
  insightsApiValue?.doubleValue || Number(insightsApiValue?.intValue)

export const getPercentageChange = (
  firstNumber: number,
  secondNumber: number,
  fixedDigits = 1
): number | string => {
  if (firstNumber === 0) return '-'
  return (((secondNumber - firstNumber) / Math.abs(firstNumber)) * 100).toFixed(fixedDigits)
}

export const valueTypes: Array<keyof InsightsPortalValue> = [
  'doubleValue',
  'intValue',
  'stringValue',
  'timestampValue',
]

export const getInsightsValue = (value: InsightsPortalValue) =>
  Object.entries(value || {}).find(
    ([key, value]) => valueTypes.includes(key as keyof InsightsPortalValue) && Boolean(value)
  )?.[1]

export const retailerParticipatingMomentAlready = (moment: Moment) =>
  moment?.campaigns?.find(
    campaign =>
      campaign.retailerId === useRetailerId() &&
      campaign.status === 'active' &&
      campaign.filters?.find(
        filter =>
          filter.filterType === FilterType.FilterTypeMomentApproved &&
          filter.filterValue == moment.id
      ) !== undefined
  )
