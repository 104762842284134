import { type ReactNode } from 'react'

export enum NotificationLevel {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  None = 'none',
}

type NotificationTypeBase = {
  timeout?: number | null
  dismissable?: boolean
  icon?: ReactNode
  level?: NotificationLevel
} & (
  | {
      // Fully custom notification save for the close button...
      contents: ReactNode
      title?: never
      body?: never
    }
  | {
      // Standarized notification
      contents?: never
      title?: ReactNode
      body: ReactNode
    }
)

export type NotificationType = NotificationTypeBase & {
  id: string
}

/**
 * Same as NotificationType, but without the id requirement. It will use an autogenerated id, instead
 **/
export type NotificationTypeInput = NotificationTypeBase & {
  id?: string
}
