import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_STORE_INTERVENTIONS = `${URL_RETAILER_SCOPED}/store-interventions`
// @hygen:inject scoped-urls

const URL_ADMIN_STORE_INTERVENTIONS = `${URL_APP_ADMIN}/store-interventions`
const URL_ADMIN_STORE_INTERVENTIONS_CREATE_REQUEST = `${URL_ADMIN_STORE_INTERVENTIONS}/create-request`
const URL_ADMIN_STORE_INTERVENTIONS_UPDATE_REQUEST = `${URL_ADMIN_STORE_INTERVENTIONS}/:requestId/update-request`
const URL_ADMIN_STORE_INTERVENTIONS_DUPLICATE_REQUEST = `${URL_ADMIN_STORE_INTERVENTIONS}/:requestId/duplicate-request`
const URL_ADMIN_STORE_INTERVENTIONS_REQUEST_LIST = `${URL_ADMIN_STORE_INTERVENTIONS}/request-list`
const URL_ADMIN_STORE_INTERVENTIONS_REQUEST_DETAILS = `${URL_ADMIN_STORE_INTERVENTIONS}/:requestId/request-details`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'store-interventions'>
  | DashRouteType<'app-admin-store-interventions'>
  | DashRouteType<'app-admin-store-interventions-create-request'>
  | DashRouteType<'app-admin-store-interventions-update-request'>
  | DashRouteType<'app-admin-store-interventions-duplicate-request'>
  | DashRouteType<'app-admin-store-interventions-request-list'>
  | DashRouteType<'app-admin-store-interventions-request-details'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'store-interventions': URL_STORE_INTERVENTIONS,
  'app-admin-store-interventions': URL_ADMIN_STORE_INTERVENTIONS,
  'app-admin-store-interventions-create-request': URL_ADMIN_STORE_INTERVENTIONS_CREATE_REQUEST,
  'app-admin-store-interventions-update-request': URL_ADMIN_STORE_INTERVENTIONS_UPDATE_REQUEST,
  'app-admin-store-interventions-duplicate-request':
    URL_ADMIN_STORE_INTERVENTIONS_DUPLICATE_REQUEST,
  'app-admin-store-interventions-request-list': URL_ADMIN_STORE_INTERVENTIONS_REQUEST_LIST,
  'app-admin-store-interventions-request-details': URL_ADMIN_STORE_INTERVENTIONS_REQUEST_DETAILS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
