import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { credentialsManagementAccessControl } from '../access-control/credentialsManagementAccess.configuration'

const CredentialsManagementApiKeysPage = lazy(
  () => import('../pages/api-keys/CredentialsManagementApiKeysPage')
)
const CredentialsManagementApiKeyApprovalRequestsPage = lazy(
  () => import('../pages/api-key-approval-requests/CredentialsManagementApiKeyApprovalRequestsPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const credentialsManagementRouter = (
  <DomainRoute route="credentials-management" accessControl={credentialsManagementAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="credentials-management-api-keys"
        component={CredentialsManagementApiKeysPage}
      />
      <DomainRoute
        exact
        route="credentials-management-api-key-approval-requests"
        component={CredentialsManagementApiKeyApprovalRequestsPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
