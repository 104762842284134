import React, { useCallback, useEffect } from 'react'
import { type Renderer, type CellProps } from 'react-table-next'
import { Button, LoadingDots } from '@retailer-platform/shared-components'
import { css } from '@emotion/react'
import { isNotAuthorizedError } from '@retailer-platform/dashboard/apollo'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { useNotifications } from '@retailer-platform/dashboard/legacy'
import { type ReportsNormalizedReportsDatum } from '../../utils/ReportsNormalizedReportsList.types'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { useGetNormalizedReportSignedUrlLazyQuery } from '../../../../api'

const buttonStyles = css({
  '&&': {
    width: 150,
  },
})

export type ReportsNormalizedReportsListDownloadCellProps = CellProps<ReportsNormalizedReportsDatum>

export const ReportsNormalizedReportsListDownloadCell: Renderer<
  ReportsNormalizedReportsListDownloadCellProps
> = ({ row }) => {
  const { notifyError } = useNotifications()
  const s3Key = row?.original?.s3Key
  const bucketName = row?.original?.bucketName
  const messages = useDomainMessages({
    buttonLabel: 'reportsDomain.normalizedReports.list.download',
    genericError: 'reportsDomain.normalizedReports.list.download.error',
    unauthorizedError: 'reportsDomain.normalizedReports.list.download.unauthorizedError',
  })

  const [getDownloadLink, { data, loading, error }] = useGetNormalizedReportSignedUrlLazyQuery({
    fetchPolicy: 'network-only',
  })

  const signedUrl = data?.normalizedReportSignedUrl?.signedUrl

  const download = useCallback(
    () => getDownloadLink({ variables: { s3Key, bucketName } }),
    [getDownloadLink, s3Key, bucketName]
  )

  useEffect(() => {
    if (!signedUrl) return

    window.open(signedUrl)
  }, [signedUrl])

  useEffect(() => {
    if (!error) return

    if (isNotAuthorizedError(error)) {
      notifyError(messages.unauthorizedError, 'modern')
      return
    }

    notifyError(messages.genericError, 'modern')
  }, [error, notifyError, messages])

  if (!s3Key) return null
  if (!bucketName) return null

  return (
    <Button
      css={buttonStyles}
      variant="tertiary"
      onClick={download}
      disabled={loading}
      data-testid="ReportsNormalizedReportsListDownloadCell.button"
    >
      {loading ? <LoadingDots height={8} /> : messages.buttonLabel}
    </Button>
  )
}
