import React, { useState } from 'react'
import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'
import { useIntl } from 'react-intl'
import { useCurrentAccountContext } from '../../../../../utils/current-account/CurrentAccountContext'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import { useNavContext } from '../../../nav/utils/NavContext'
import { AdminNavEntryPointV2 } from '../../../nav/utils/adminNavConfig'
import { trackEvent } from '../../../../../utils/events/trackEvent'
import { useAdminControls } from '../../../../../utils/contexts/admin-controls/AdminControlsContext'
import { AdminControlModal } from '../../../admin-controls-modal/AdminControlsModal'
import { Option } from './Option'
import { UserMenu } from './UserMenu'
import { Divider } from './UserSettingsMenu.common'
import { NavEntryPointGroup } from './NavEntryPointGroup'

type TitleTextProps = {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  padding?: string
}

const TitleText = styled.div<TitleTextProps>(
  ({ fontWeight = 600, fontSize = '18px', lineHeight = '22px', padding = '' }) => ({
    display: 'flex',
    padding: padding,

    fontStyle: 'normal',
    fontWeight: fontWeight,
    fontSize: fontSize,
    lineHeight: lineHeight,
    alignItems: 'center',
    color: colors.GRAYSCALE.X80,
    cursor: 'default',
  })
)

type Props = {
  setShowPartnerSelector: (value: boolean) => void
  setCurrentlySelectedItem: (value: number) => void
  navMenuHierarchy: NavMenuHierarchy
}

export const UserSettingsMenuAdmin: React.FC<React.PropsWithChildren<Props>> = ({
  setShowPartnerSelector,
  setCurrentlySelectedItem,
  navMenuHierarchy,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const context = useCurrentAccountContext()
  const { allowNavV1RouletteEnabled, setNavV2Enabled } = useNavContext()
  const { openAdminControls } = useAdminControls()
  const intl = useIntl()

  const onClose = () => {
    setIsOpen(false)
  }

  const title = intl.formatMessage({ id: 'navV2.userSettings.instacartAdmin' })

  return (
    <UserMenu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setCurrentlySelectedItem={setCurrentlySelectedItem}
      title={title}
      navMenuHierarchy={navMenuHierarchy}
    >
      <div>
        <TitleText padding={'8px 24px'}>{title}</TitleText>
        <Option
          onClick={() => {
            setShowPartnerSelector(true)
            onClose()
          }}
          title="navV2.userSettings.selectPartner"
        />
        <Divider />
        <NavEntryPointGroup
          navEntryPoint={AdminNavEntryPointV2.UserMenu}
          onClose={onClose}
          optionType="option"
        >
          <Option
            onClick={() => {
              onClose()
              openAdminControls()
            }}
            title="app.admin.nav.links.adminControls"
            position={2}
          />
          <AdminControlModal />
        </NavEntryPointGroup>
        <Divider />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '12px',
            paddingLeft: '27px',
          }}
        >
          <TitleText fontWeight={600} fontSize="14px" lineHeight="20px">
            {context.account.givenName}
          </TitleText>
          <TitleText fontWeight={400} fontSize="12px" lineHeight="16px">
            {context.account.email}
          </TitleText>
        </div>
        {allowNavV1RouletteEnabled && (
          <Option
            onClick={() => {
              setNavV2Enabled(false)
              onClose()
              trackEvent({
                id: 'navV2.navigation.switchToNavV1',
                description: 'Tracks whenever the user switches back to NavV1',
                data: {
                  accountID: context.account.id,
                },
              })
            }}
            title="navV2.userSettings.switchToNavV1"
          />
        )}
        <Option onClick={onClose} route="logout" title="logout" />
      </div>
    </UserMenu>
  )
}
