import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  productsViewAccessControl,
  productsViewItemAnomalyAccessControl,
} from '../access-control/viewAccess.configuration'
import { productsSearchAccessControl } from '../access-control/searchAccess.configuration'

// @hygen:inject page-import
const ProductsViewPage = lazy(() => import('../pages/view/ProductsViewPage'))
const ProductsSearchPage = lazy(() => import('../pages/search/ProductsSearchPage'))
const ProductsEditImagesPage = lazy(() => import('../pages/images/ProductsEditImagesPage'))
const ItemAnomalyReportsPage = lazy(
  () => import('../pages/item-anomaly/ItemAnomalyReportsPage.tsx')
)

export const productsRouter = (
  <DomainRoute route="products">
    <DashSwitch>
      <DomainRoute
        route="products-view"
        component={ProductsViewPage}
        accessControl={productsViewAccessControl}
      />

      <DomainRoute
        route="products-edit-images"
        component={ProductsEditImagesPage}
        accessControl={productsViewAccessControl}
      />

      <DomainRoute
        exact
        route="products-search"
        component={ProductsSearchPage}
        accessControl={productsSearchAccessControl}
        scopePicker={{
          titles: ['productsDomain.breadcrumbs.root'],
        }}
      />

      <DomainRoute
        exact
        route="item-anomaly-reports"
        component={ItemAnomalyReportsPage}
        accessControl={productsViewItemAnomalyAccessControl}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
