import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_CAPER = `${URL_RETAILER_SCOPED}/caper`
// @hygen:inject scoped-urls

export type DashRouteSet = DashRouteType<'caper'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  caper: URL_CAPER,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
