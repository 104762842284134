import React, { type ReactElement, useMemo } from 'react'
import { type SerializedStyles, css } from '@emotion/react'
import { type TestID } from '../../../utils/testing/types'
import { gridBodyRowStyles, gridCompactBodyRowStyles } from '../utils/grid.styles'
import { type GridDatum, type GridRow } from '../utils/grid.types'
import { GridBodyCell } from './GridBodyCell'

interface Props<TData extends GridDatum> extends GridRow<TData>, TestID {
  last?: boolean
  compact?: boolean
}

export const GridBodyRow = <TDatum extends GridDatum>({
  'data-testid': testId,
  cells,
  getRowProps,
  last,
  compact,
}: Props<TDatum>): ReactElement => {
  const renderedCells = useMemo(
    () =>
      cells.map(cell => (
        <GridBodyCell key={`${cell.row.id}-${cell.column.id}`} {...cell} compact={compact} />
      )),
    [cells, compact]
  )

  const { style, ...rowProps } = getRowProps()
  const { key, ...restRowProps } = rowProps
  const dynamicStyles = css(
    style as SerializedStyles,
    gridBodyRowStyles,
    last && { borderBottom: 'none' },
    compact && gridCompactBodyRowStyles
  )

  return (
    <div key={key} {...restRowProps} css={dynamicStyles} data-testid={testId}>
      {renderedCells}
    </div>
  )
}
