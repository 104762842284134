import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { emergingBrandsPortalRouter } from './routing/emergingBrandsPortalRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { emergingBrandsPortalAccessControl } from './access-control/emergingBrandsPortalAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'emerging-brands-portal',
  totem: {
    entity: 'emerging-brands-portal-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [emergingBrandsPortalRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.BrandExplorer,
      attachToV2: NavEntryPoint.Catalog,
      subNavItems: [
        {
          type: 'item',
          labelId: 'emergingBrandsPortalDomain.title',
          route: 'emerging-brands-portal-search',
          accessControl: emergingBrandsPortalAccessControl,
          isNew: true,
          positionNavV2: 4,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
