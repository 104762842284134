import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { spacing } from '@retailer-platform/shared-components'
import { ReportsNormalizedReportsGridList } from './components/grid-list/ReportsNormalizedReportsGridList'
import { ReportsNormalizedReportsListFilters } from './components/filters/ReportsNormalizedReportsListFilters'
import { useReportsNormalizedReportsList } from './utils/useReportsNormalizedReportsList.hooks'
import { type ReportTypeConfig } from './utils/ReportsNormalizedReportsList.utils'

interface ReportsNormalizedReportsListProps {
  partnerId: string
  retailerId: string
  reportTypesConfig: ReportTypeConfig
}

const StyledGridListWrapper = styled.div({
  padding: `0 ${spacing.X24}`,
  overflow: 'hidden',
  width: '100%',
  flex: 1,
})

export const InstacartManagedReportsList: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListProps>
> = ({ partnerId, retailerId, reportTypesConfig }) => {
  const {
    apiResult,
    loading,
    refetch,
    canLoadMore,
    loadMore,
    dateRangePreset,
    setDateRangePreset,
    dateRange,
    setDateRange,
    reportType,
    setReportType,
    error,
    warehouseOptions,
    retailerIds,
    setRetailerIds,
    isHeavyQuery,
  } = useReportsNormalizedReportsList({
    partnerId,
    retailerId,
    reportTypesConfig,
  })

  return (
    <React.Fragment>
      <ReportsNormalizedReportsListFilters
        reportCount={apiResult?.length ?? 0}
        partnerId={partnerId}
        retailerId={retailerId}
        dateRangePreset={dateRangePreset}
        setDateRangePreset={setDateRangePreset}
        dateRange={dateRange}
        setDateRange={setDateRange}
        reportType={reportType}
        setReportType={setReportType}
        warehouseOptions={warehouseOptions}
        retailerIds={retailerIds}
        setRetailerIds={setRetailerIds}
        reportTypesConfig={reportTypesConfig}
      />
      <StyledGridListWrapper>
        <ReportsNormalizedReportsGridList
          reports={apiResult}
          loading={loading}
          loadMore={loadMore}
          canLoadMore={canLoadMore}
          refetch={refetch}
          error={error}
          isHeavyQuery={isHeavyQuery}
        />
      </StyledGridListWrapper>
    </React.Fragment>
  )
}
