const EN_US = {
  'featureEntitlementsManagementDomain.title': 'Feature entitlements',
  'featureEntitlementsManagementDomain.title.tooltip':
    'Entitlements are like permissions, giving Retailers access to premium features. To understand more, visit go/feature-entitlements.',
  'featureEntitlementsManagementDomain.none': 'None',
  'featureEntitlementsManagementDomain.loading': 'Loading...',
  'featureEntitlementsManagementDomain.XEntitlements': '{count} entitlement(s)',
  'featureEntitlementsManagementDomain.XPackages': '{count} package(s)',
  'featureEntitlementsManagementDomain.XRetailers': '{count} retailer(s)',

  'featureEntitlementsManagementDomain.errors.error': 'Error!',
  'featureEntitlementsManagementDomain.errors.unexpectedFetchError':
    'There was an unexpected error fetching the data here.',
  'featureEntitlementsManagementDomain.errors.retryOrContactSupport':
    'You may retry or contact support for assistance.',

  'featureEntitlementsManagementDomain.tabs.entitlements': 'Entitlements',
  'featureEntitlementsManagementDomain.tabs.packages': 'Packages',
  'featureEntitlementsManagementDomain.tabs.retailers': 'Retailers',

  'featureEntitlementsManagementDomain.entitlements.searchBar.label': 'Search entitlements',
  'featureEntitlementsManagementDomain.entitlements.searchBar.placeholder':
    'eg: retailer_collections, storefront_customizable, #prj-sfx-squad-pl1-enablement, etc',
  'featureEntitlementsManagementDomain.entitlements.list.displayingXofX':
    'Displaying {displaying} of {total} entitlements',
  'featureEntitlementsManagementDomain.entitlements.list.header.id': 'ID',
  'featureEntitlementsManagementDomain.entitlements.list.header.name': 'Name',
  'featureEntitlementsManagementDomain.entitlements.list.header.ownersSlack': "Owner's slack",
  'featureEntitlementsManagementDomain.entitlements.list.header.ippIntegration': 'IPP integration?',
  'featureEntitlementsManagementDomain.entitlements.list.header.ippIntegrationToolTip':
    'Is this entitlement integrated into IPP so that the user experience reflects the entitlement assignment?',
  'featureEntitlementsManagementDomain.entitlements.list.header.needsMigration':
    'Requires migration?',
  'featureEntitlementsManagementDomain.entitlements.list.header.needsMigrationToolTip':
    'Does this entitlement require manual migration outside the entitlement system in order for the feature to be successfully assigned?',
  'featureEntitlementsManagementDomain.entitlements.list.header.usedInPackages': '# Packages',
  'featureEntitlementsManagementDomain.entitlements.list.header.retailersUsing': '# Retailers',
  'featureEntitlementsManagementDomain.entitlements.list.header.updatedOn': 'Updated on',
  'featureEntitlementsManagementDomain.entitlements.list.header.fromEnv': 'From Environment',
  'featureEntitlementsManagementDomain.entitlements.list.environment.development': 'Development',
  'featureEntitlementsManagementDomain.entitlements.list.environment.staging': 'Staging',
  'featureEntitlementsManagementDomain.entitlements.list.environment.production': 'Production',
  'featureEntitlementsManagementDomain.entitlements.details.modalHeader': 'Entitlement details',
  'featureEntitlementsManagementDomain.entitlements.details.headline': 'Entitlement details',
  'featureEntitlementsManagementDomain.entitlements.details.name': 'Name',
  'featureEntitlementsManagementDomain.entitlements.details.id': 'ID',
  'featureEntitlementsManagementDomain.entitlements.details.ownersSlackHandle':
    "Owner's slack handle",
  'featureEntitlementsManagementDomain.entitlements.details.hasIppIntegration':
    'Has IPP integration',
  'featureEntitlementsManagementDomain.entitlements.details.requiresManualMigration':
    'Requires manual migration',
  'featureEntitlementsManagementDomain.entitlements.details.createdOn': 'Created on',
  'featureEntitlementsManagementDomain.entitlements.details.updatedOn': 'Updated on',
  'featureEntitlementsManagementDomain.entitlements.details.packagesWithX':
    'Packages with "{name}"',
  'featureEntitlementsManagementDomain.entitlements.details.retailersDirectlyIncludingX':
    'Retailers directly including "{name}"',
  'featureEntitlementsManagementDomain.entitlements.labels.ippIntegration.trueTip':
    'This entitlement is integrated into IPP so that the user experience reflects the entitlement assignment.',
  'featureEntitlementsManagementDomain.entitlements.labels.ippIntegration.falseTip':
    "This entitlement is not integrated into IPP, so the user experience is not affected by this entitlement's assignment.",
  'featureEntitlementsManagementDomain.entitlements.labels.requiresMigration.trueTip':
    'This entitlement requires manual migration outside the entitlement system in order for the feature to be successfully assigned. Assignments of this entitlement may not be immediately reflected in every system (eg: IPP).',
  'featureEntitlementsManagementDomain.entitlements.labels.requiresMigration.falseTip':
    'This entitlement does not require any manual migration outside the entitlement system in order for the feature to be successfully assigned. Assignments of this entitlement should be immediately reflected in the user experience of every integrated system (eg: IPP).',

  'featureEntitlementsManagementDomain.packages.searchBar.label': 'Search packages',
  'featureEntitlementsManagementDomain.packages.searchBar.placeholder':
    'eg: storefront_pro, insights, etc',
  'featureEntitlementsManagementDomain.packages.list.displayingXofX':
    'Displaying {displaying} of {total} packages',
  'featureEntitlementsManagementDomain.package.list.header.id': 'ID',
  'featureEntitlementsManagementDomain.package.list.header.name': 'Name',
  'featureEntitlementsManagementDomain.package.list.header.numOfEntitlements': '# Entitlements',
  'featureEntitlementsManagementDomain.package.list.header.retailersUsing': '# Retailers',
  'featureEntitlementsManagementDomain.package.list.header.createdOn': 'Created on',
  'featureEntitlementsManagementDomain.package.list.header.fromEnv': 'From Environment',
  'featureEntitlementsManagementDomain.package.list.environment.development': 'Development',
  'featureEntitlementsManagementDomain.package.list.environment.staging': 'Staging',
  'featureEntitlementsManagementDomain.package.list.environment.production': 'Production',
  'featureEntitlementsManagementDomain.package.details.modalHeader': 'Package details',
  'featureEntitlementsManagementDomain.package.details.headline': 'Package details',
  'featureEntitlementsManagementDomain.package.details.name': 'Name',
  'featureEntitlementsManagementDomain.package.details.id': 'ID',
  'featureEntitlementsManagementDomain.package.details.createdOn': 'Created on',
  'featureEntitlementsManagementDomain.package.details.containsEntitlements':
    'Contains entitlements',
  'featureEntitlementsManagementDomain.package.details.retailersUsingX': 'Retailers using "{name}"',

  'featureEntitlementsManagementDomain.retailers.searchBar.label': 'Search retailers',
  'featureEntitlementsManagementDomain.retailers.searchBar.placeholder':
    "eg: Costco, Wegman's, The Garden",
  'featureEntitlementsManagementDomain.retailers.list.displayingXofX':
    'Displaying {displaying} of {total} Retailers. Retailers not present do not have any entitlements configured.',
  'featureEntitlementsManagementDomain.retailers.list.header.warehouseId': 'Warehouse ID',
  'featureEntitlementsManagementDomain.retailers.list.header.warehouseName': 'Warehouse Name',
  'featureEntitlementsManagementDomain.retailers.list.header.numOfDenormalizedEntitlements':
    '# Denormalized Entitlements',
  'featureEntitlementsManagementDomain.retailers.list.header.numPackages': '# Packages',
  'featureEntitlementsManagementDomain.retailers.list.header.createdOn': 'Created on',
  'featureEntitlementsManagementDomain.retailers.list.header.fromEnv': 'From Environment',
  'featureEntitlementsManagementDomain.retailers.list.environment.development': 'Development',
  'featureEntitlementsManagementDomain.retailers.list.environment.staging': 'Staging',
  'featureEntitlementsManagementDomain.retailers.list.environment.production': 'Production',
  'featureEntitlementsManagementDomain.retailers.details.modalHeader': 'Retailer details',
  'featureEntitlementsManagementDomain.retailers.details.headline': 'Retailer',
  'featureEntitlementsManagementDomain.retailers.details.warehouseId': 'Warehouse ID',
  'featureEntitlementsManagementDomain.retailers.details.createdAt': 'Created on',
  'featureEntitlementsManagementDomain.retailers.details.packagesUsedByX':
    'Packages for {warehouseId}',
  'featureEntitlementsManagementDomain.retailers.details.entitlementsUsedByX':
    'Addional entitlements for {warehouseId}',
  'featureEntitlementsManagementDomain.retailers.details.addionalEntitlements':
    'Addional entitlements for {warehouseId}',
} as const

export default EN_US
