import { type Renderer, type CellProps } from 'react-table-next'
import { useDomainMessage, type DomainMessages } from '../../../../utils/domain/intl'
import { type ReportsNormalizedReportsDatum } from '../../utils/ReportsNormalizedReportsList.types'

export type ReportsNormalizedReportsListDefaultHeaderProps =
  CellProps<ReportsNormalizedReportsDatum>

export const ReportsNormalizedReportsListDefaultHeader: Renderer<
  ReportsNormalizedReportsListDefaultHeaderProps
> = ({ column }) =>
  useDomainMessage(`reportsDomain.normalizedReports.list.headers.${column.id}` as DomainMessages)
