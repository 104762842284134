import { type DashRouteType, URL_APP_ADMIN } from '@retailer-platform/dashboard/routing'

const URL_CONNECTED_STORES = `${URL_APP_ADMIN}/connected-stores`

// Caper
const URL_CONNECTED_STORES_CAPER = `${URL_CONNECTED_STORES}/caper`
const URL_CONNECTED_STORES_CAPER_CONFIG = `${URL_CONNECTED_STORES_CAPER}/config/:environment`
const URL_CONNECTED_STORES_CAPER_CONFIG_CREATE = `${URL_CONNECTED_STORES_CAPER}/config/:environment/create`
const URL_CONNECTED_STORES_CAPER_CONFIG_PARTNER = `${URL_CONNECTED_STORES_CAPER}/config/:environment/:partner`
const URL_CONNECTED_STORES_CAPER_CONFIG_RETAILER = `${URL_CONNECTED_STORES_CAPER}/config/:environment/:partner/:retailer`
const URL_CONNECTED_STORES_CAPER_CONFIG_STORE = `${URL_CONNECTED_STORES_CAPER}/config/:environment/:partner/:retailer/:store`
const URL_CONNECTED_STORES_CAPER_CONFIG_DEVICE = `${URL_CONNECTED_STORES_CAPER}/config/:environment/:partner/:retailer/:store/:device`

// Upos
const URL_CONNECTED_STORES_UPOS = `${URL_CONNECTED_STORES}/upos`
const URL_CONNECTED_STORES_UPOS_STATUS = `${URL_CONNECTED_STORES_UPOS}/status/:id?`
const URL_CONNECTED_STORES_UPOS_RETAILERS = `${URL_CONNECTED_STORES_UPOS}/retailers/:id?`
const URL_CONNECTED_STORES_UPOS_PROFILES = `${URL_CONNECTED_STORES_UPOS}/profiles/:id?`
const URL_CONNECTED_STORES_UPOS_STORES = `${URL_CONNECTED_STORES_UPOS}/stores/:id?`
const URL_CONNECTED_STORES_UPOS_INTEGRATIONS = `${URL_CONNECTED_STORES_UPOS}/integrations/:id?`

// Shelf Labels
const URL_CONNECTED_STORES_SHELF_LABELS = `${URL_CONNECTED_STORES}/shelf-labels`
const URL_CONNECTED_STORES_SHELF_LABELS_STORES = `${URL_CONNECTED_STORES_SHELF_LABELS}/stores/:id?`
const URL_CONNECTED_STORES_SHELF_LABELS_CONNECTIONS = `${URL_CONNECTED_STORES_SHELF_LABELS}/connections/:id?`
const URL_CONNECTED_STORES_SHELF_LABELS_SYNC_STRATEGIES = `${URL_CONNECTED_STORES_SHELF_LABELS}/sync-strategies/:id?`

// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-connected-stores'>

  // Caper
  | DashRouteType<'app-admin-connected-stores-caper'>
  | DashRouteType<'app-admin-connected-stores-caper-config'>
  | DashRouteType<'app-admin-connected-stores-caper-config-create'>
  | DashRouteType<'app-admin-connected-stores-caper-config-partner'>
  | DashRouteType<'app-admin-connected-stores-caper-config-retailer'>
  | DashRouteType<'app-admin-connected-stores-caper-config-store'>
  | DashRouteType<'app-admin-connected-stores-caper-config-device'>

  // Upos
  | DashRouteType<'app-admin-connected-stores-upos-status', { id: string | null }>
  | DashRouteType<'app-admin-connected-stores-upos-retailers', { id: string | null }>
  | DashRouteType<'app-admin-connected-stores-upos-profiles', { id: string | null }>
  | DashRouteType<'app-admin-connected-stores-upos-stores', { id: string | null }>
  | DashRouteType<'app-admin-connected-stores-upos-integrations', { id: string | null }>

  // Shelf Labels
  | DashRouteType<'app-admin-connected-stores-shelf-labels-stores', { id: string | null }>
  | DashRouteType<'app-admin-connected-stores-shelf-labels-connections', { id: string | null }>
  | DashRouteType<'app-admin-connected-stores-shelf-labels-sync-strategies', { id: string | null }>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-connected-stores': URL_CONNECTED_STORES,

  // Caper
  'app-admin-connected-stores-caper': URL_CONNECTED_STORES_CAPER,
  'app-admin-connected-stores-caper-config': URL_CONNECTED_STORES_CAPER_CONFIG,
  'app-admin-connected-stores-caper-config-create': URL_CONNECTED_STORES_CAPER_CONFIG_CREATE,
  'app-admin-connected-stores-caper-config-partner': URL_CONNECTED_STORES_CAPER_CONFIG_PARTNER,
  'app-admin-connected-stores-caper-config-retailer': URL_CONNECTED_STORES_CAPER_CONFIG_RETAILER,
  'app-admin-connected-stores-caper-config-store': URL_CONNECTED_STORES_CAPER_CONFIG_STORE,
  'app-admin-connected-stores-caper-config-device': URL_CONNECTED_STORES_CAPER_CONFIG_DEVICE,

  // Upos
  'app-admin-connected-stores-upos-status': URL_CONNECTED_STORES_UPOS_STATUS,
  'app-admin-connected-stores-upos-retailers': URL_CONNECTED_STORES_UPOS_RETAILERS,
  'app-admin-connected-stores-upos-profiles': URL_CONNECTED_STORES_UPOS_PROFILES,
  'app-admin-connected-stores-upos-stores': URL_CONNECTED_STORES_UPOS_STORES,
  'app-admin-connected-stores-upos-integrations': URL_CONNECTED_STORES_UPOS_INTEGRATIONS,

  // Shelf Labels
  'app-admin-connected-stores-shelf-labels-stores': URL_CONNECTED_STORES_SHELF_LABELS_STORES,
  'app-admin-connected-stores-shelf-labels-connections':
    URL_CONNECTED_STORES_SHELF_LABELS_CONNECTIONS,
  'app-admin-connected-stores-shelf-labels-sync-strategies':
    URL_CONNECTED_STORES_SHELF_LABELS_SYNC_STRATEGIES,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
