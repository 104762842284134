import React, { useState, type FunctionComponent, useCallback } from 'react'
import moment, { type Moment } from 'moment'
import { DateRangePicker } from 'react-dates'
import styled from '@emotion/styled'
import { borderRadius, colors } from '@retailer-platform/shared-components'

const minDate = moment().subtract(1, 'year')
const maxDate = moment()
const isOutsideRange = (date: Moment) => !date.isBetween(minDate, maxDate)

const StyleWrapper = styled.div({
  '&&': {
    '.DateRangePickerInput': {
      height: 32,
      border: `1px solid ${colors.GRAYSCALE.X30}`,
    },

    '.DateInput_input': {
      height: 30,
    },

    '.DateRangePickerInput, .SingleDatePickerInput, .DateInput, .DateInput_input': {
      borderRadius: borderRadius.X8,
    },
  },
})

type StartEndDate = {
  startDate: Moment | null
  endDate: Moment | null
}

interface ReportsNormalizedReportsListCustomDateRangeProps extends StartEndDate {
  onDatesChange: (val: StartEndDate) => void
}

export const ReportsNormalizedReportsListCustomDateRange: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListCustomDateRangeProps>
> = ({ startDate: initialStartDate, endDate: initialEndDate, onDatesChange }) => {
  const [calendarDates, setCalendarDates] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  })

  const handleDatesChange = useCallback(
    (nextDates: StartEndDate) => {
      // Keep inner state in sync, regardless if it's incomplete.
      setCalendarDates(nextDates)

      // Only update when both dates were set
      if (nextDates.startDate && nextDates.endDate) {
        onDatesChange(nextDates)
      }
    },
    [onDatesChange]
  )

  const [focusedInput, setFocusedInput] = useState(null)

  return (
    <StyleWrapper>
      <DateRangePicker
        small
        withPortal
        minimumNights={0}
        isOutsideRange={isOutsideRange}
        startDateId="startDate"
        endDateId="endDate"
        startDate={calendarDates.startDate}
        endDate={calendarDates.endDate}
        focusedInput={focusedInput}
        onDatesChange={handleDatesChange}
        onFocusChange={setFocusedInput}
        minDate={minDate}
        maxDate={maxDate}
      />
    </StyleWrapper>
  )
}
