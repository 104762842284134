import {
  optionsArrayToInFilterSelection,
  stringToInFilterSelection,
} from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig(
    'retailer',
    'WAREHOUSE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.retailers.label'
  ),
  createFilterParamConfig(
    'state',
    'WAREHOUSE_LOCATION_STATE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.state.label'
  ),
  createFilterParamConfig(
    'region',
    'REGION',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.region.label'
  ),
  createFilterParamConfig(
    'orderSource',
    'ORDER_SOURCE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.orderSources.label'
  ),
  createFilterParamConfig(
    'storeLocation',
    'WAREHOUSE_LOCATION',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.storeLocation.label'
  ),

  createFilterParamConfig(
    'storeLocationCode',
    'WAREHOUSE_LOCATION_CODE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.storeLocationCode.label'
  ),

  createFilterParamConfig(
    'storeGroup',
    'WAREHOUSE_LOCATION_GROUP',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.storeGroup.label'
  ),

  createFilterParamConfig(
    'fulfillmentType',
    'FULFILLMENT_TYPE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.fulfillmentType.label'
  ),

  createFilterParamConfig(
    'alcohol',
    'ALCOHOL',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.alcohol.label'
  ),

  createFilterParamConfig(
    'express',
    'EXPRESS',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.express.label'
  ),

  createFilterParamConfig(
    'convenience',
    'VIRTUAL_WAREHOUSE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.convenience.label'
  ),

  createFilterParamConfig(
    'loyalty',
    'LOYALTY',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.loyalty.label'
  ),

  createFilterParamConfig(
    'ebt',
    'EBT',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.ebt.label'
  ),

  createFilterParamConfig(
    'firstOrder',
    'FIRST_ORDER',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.firstOrder.label'
  ),
  createFilterParamConfig(
    'partnerDivision',
    'PARTNER_GEO_DIVISION',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.partnerDivision.label'
  ),
  createFilterParamConfig(
    'orderCurrency',
    'ORDER_CURRENCY',
    stringToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.orderCurrency.label'
  ),
  createFilterParamConfig(
    'deliveryState',
    'DELIVERY_STATE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.deliveryState.label'
  ),
  createFilterParamConfig(
    'fsaHsaOrder',
    'FSA_HSA_ORDER',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.fsaHsa.label'
  ),
  createFilterParamConfig(
    'rxOrder',
    'RX_ORDER',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.rx.label'
  ),
]

export const orderDeliveriesSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const transformFilterSelectionsToOrderDeliveriesSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)

export const transformOrderDeliveriesApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  WAREHOUSE_LOCATION: 'stringValue',
  WAREHOUSE_LOCATION_CODE: 'stringValue',
  ORDER_SOURCE: 'stringValue',
  REGION: 'stringValue',
  WAREHOUSE_LOCATION_STATE: 'stringValue',
  PICKUP: 'stringValue',
  ALCOHOL: 'stringValue',
  EXPRESS: 'stringValue',
  VIRTUAL_WAREHOUSE: 'stringValue',
  LOYALTY: 'stringValue',
  EBT: 'stringValue',
  FIRST_ORDER: 'stringValue',
  PARTNER_GEO_DIVISION: 'stringValue',
  ORDER_CURRENCY: 'stringValue',
  WAREHOUSE_LOCATION_GROUP: 'stringValue',
  FULFILLMENT_TYPE: 'stringValue',
  FSA_HSA_ORDER: 'stringValue',
  RX_ORDER: 'stringValue',
} as const
