import { createFilterParamConfig } from '../utils/insightsPortalSchemas.utils'
import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'storeLocationCode',
    'WAREHOUSE_LOCATION_CODE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'storeGroup',
    'WAREHOUSE_LOCATION_GROUP',
    optionsArrayToInFilterSelection('stringValue')
  ),
]

export const aggPerformanceSchemaFilterParamsConfig = transformFilterParamsForApiConfig
