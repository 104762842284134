import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { catalogAdminCatalogImageUploadsAccessConfiguration } from '../access-control/catalogAdminCatalogImageUploadsAccess.configuration'

// --------------------
// Image Uploads Page Components
// --------------------

const CatalogAdminImageUploadsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminImageUploadsPage" */
      '../pages/image-uploads/CatalogAdminImageUploadsPage'
    )
)

const CatalogAdminImageUploadsBatchDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CatalogAdminImageUploadsBatchDetailsPage" */
      '../pages/image-uploads/CatalogAdminImageUploadsBatchDetailsPage'
    )
)
// @hygen:inject page-import

export const catalogAdminScopedRouter = (
  <DomainRoute route="app-admin-catalog-admin-catalog">
    <DashSwitch>
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
      {/* Image Uploads Routes */}
      <DomainRoute
        exact
        route="app-admin-catalog-admin-image-uploads"
        component={CatalogAdminImageUploadsPage}
        accessControl={catalogAdminCatalogImageUploadsAccessConfiguration}
        scopePicker={{
          titles: ['catalogAdminDomain.imageUploads.title'],
        }}
      />
      <DomainRoute
        exact
        route="app-admin-catalog-admin-image-uploads-batch-details"
        component={CatalogAdminImageUploadsBatchDetailsPage}
        accessControl={catalogAdminCatalogImageUploadsAccessConfiguration}
      />
    </DashSwitch>
  </DomainRoute>
)
