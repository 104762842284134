import React, { type FunctionComponent, Fragment, type ComponentType } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { Text, Button } from 'ic-snacks'
import Modal from '../../../../../components/Modal/Modal'

const FooterStyles = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface Props {
  storeGroupName: string
  retailerLocationCount: number
  onConfirm(): void
  onClose(): void
}

export const StoreGroupsListConfirmDeleteModal: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ storeGroupName, retailerLocationCount, onClose, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal width="500px" height="300px" onClose={onClose}>
      {(
        Header: ComponentType<React.PropsWithChildren<unknown>>,
        Body: ComponentType<React.PropsWithChildren<unknown>>,
        Footer: ComponentType<React.PropsWithChildren<unknown>>
      ) => (
        <Fragment>
          <Header>
            <Text variant="T.18" fontWeight="semiBold">
              <FormattedMessage
                id="settings.storeGroups.deleteModal.title"
                values={{ storeGroupName }}
              />
            </Text>
          </Header>
          <Body>
            <Text variant="T.16">
              <FormattedMessage
                id="settings.storeGroups.deleteModal.body"
                values={{ retailerLocationCount }}
              />
            </Text>
          </Body>
          <Footer>
            <FooterStyles>
              <Button
                snacksStyle="secondary"
                onClick={onClose}
                elementAttributes={{ 'data-testid': 'delete-modal-cancel-button' }}
              >
                <FormattedMessage id="cancel" />
              </Button>

              <Button
                snacksStyle="primary"
                size="standard"
                onClick={handleConfirm}
                style={{ marginLeft: 30 }}
                elementAttributes={{ 'data-testid': 'delete-modal-confirm-button' }}
              >
                <FormattedMessage id="delete" />
              </Button>
            </FooterStyles>
          </Footer>
        </Fragment>
      )}
    </Modal>
  )
}
