import React, { useRef, useState, useEffect } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import { css } from '@emotion/react'

interface ResizeCallback {
  (currentSize: { width: number; height: number }): void
}

export function getResizeObserver(element: HTMLElement, callback: ResizeCallback) {
  const observer = new ResizeObserver(([entry]) => {
    if (!entry) return

    const { width, height } = entry.contentRect

    callback({ width, height })
  })

  observer.observe(element)

  return observer
}

/**
 * Types for the new component, we no longer require width, height
 * from the original set, and we add offsetY and offsetX
 */
type EnhancedProps<T> = Omit<T, 'width' | 'height'> & {
  /**
   * An offset subtracted to the calculated height, to control the value passed as a prop.
   * Use if the height received will not be the full height of the component. For instance,
   * this prop only sets the height of the body of a table, but the headers are unaccounted for.
   */
  offsetY?: number
  /**
   * An offset subtracted to the calculated width, to control the value passed as a prop.
   * Use if the width received will not be the full width of the component. For instance,
   * this prop only sets the width of the body of a table, but other elements are unaccounted for.
   */
  offsetX?: number
}

const containerStyles = css({
  height: '100%',
  width: '100%',
})

export function withContainerSize<TOriginalProps extends object>(
  OriginalComponent: React.ComponentType<React.PropsWithChildren<TOriginalProps>>
): React.ComponentType<React.PropsWithChildren<EnhancedProps<TOriginalProps>>> {
  return ({ offsetY = 0, offsetX = 0, ...props }) => {
    const originalProps = props as TOriginalProps
    const container = useRef<HTMLDivElement>(null)
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 })

    useEffect(() => {
      if (container.current === null) return

      const observer = getResizeObserver(container.current, setDimensions)

      return () => observer.disconnect()
    }, [container])

    return (
      <div ref={container} css={containerStyles} data-testid="with-container-size-container">
        <OriginalComponent
          {...originalProps}
          width={dimensions.width - offsetX}
          height={dimensions.height - offsetY}
        />
      </div>
    )
  }
}
