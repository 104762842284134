import {
  domainRedirectComponentFactory,
  domainRouteComponentFactory,
} from '@retailer-platform/dashboard/routing'
import { type DomainRouteName } from '../../routing/routes'
import { type DomainAccessControlConfig } from './accessControl'

export const DomainRoute = domainRouteComponentFactory<DomainRouteName, DomainAccessControlConfig>()
export const DomainRedirect = domainRedirectComponentFactory<
  DomainRouteName,
  DomainAccessControlConfig
>()
