import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'placementId',
    'PLACEMENT',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'placementType',
    'PLACEMENT_TYPE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'placementStatus',
    'STATUS',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('platform', 'PLATFORM', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'placementUserSegment',
    'DIM_USER_SEGMENT',
    optionsArrayToInFilterSelection('stringValue')
  ),
]

export const placementSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const mapApiNameToDashboardName = {
  WAREHOUSE: 'retailer',
  PLACEMENT: 'placementId',
  PLACEMENT_TYPE: 'placementType',
  STATUS: 'placementStatus',
  PLATFORM: 'platform',
  DIM_USER_SEGMENT: 'placementUserSegment',
}

export const transformPlacementApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  PLACEMENT: 'stringValue',
  PLACEMENT_TYPE: 'stringValue',
  STATUS: 'stringValue',
  PLATFORM: 'stringValue',
  DIM_USER_SEGMENT: 'stringValue',
} as const

export const transformFilterSelectionsToPlacementSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)
