import styled from '@emotion/styled'

const ContentContainer = styled.div<{ padding?: string; display?: string }>`
  width: 100%;
  padding: ${props => props.padding || '30px 50px 30px 30px'};
  flex: 1;
  display: ${props => props.display || 'inherit'};
  flex-wrap: wrap;
`

export default ContentContainer
