import React, { type FunctionComponent } from 'react'
import { Badge, type BadgeProps } from '@retailer-platform/shared-components'
import { useDashMessage } from '../../../intl/intl.hooks'

export const NewBadge: FunctionComponent<React.PropsWithChildren<BadgeProps>> = props => {
  const i18n = useDashMessage('badge.new')
  return (
    <Badge css={{ marginLeft: 8, marginRight: 8 }} {...props}>
      {i18n}
    </Badge>
  )
}
