import React from 'react'
import styled from '@emotion/styled'
import { Button, SVGIcon, colors } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import { type RowInfo } from 'react-table'

import {
  withNotificationsContext,
  type NotificationsContextValue,
} from '../../../../../contexts/notifications/NotificationsContext'
import {
  type WarehouseContextValue,
  withWarehouseContext,
} from '../../../../../../utils/contexts/warehouse/WarehouseContext'
import withApi, { type WithApiInjectedProps } from '../../../../../common/withApi'
import withDepartmentAisles from '../../../../../common/withDepartmentAisles'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import { type RetailerRouteComponentProps } from '../../../../../components/RetailerRoute'

import { DashTooltip } from '../../../../../dash-blocks/dash-tooltip/DashTooltip'
import FilterBar from '../../../../../components/FilterBar'
import Dropdown from '../../../../../components/Dropdown'
import Table from '../../../../../components/Table/Table'
import { trackEvent } from '../../../../../../utils/events/trackEvent'
import Link from '../../../../../components/Link'

import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { type ProductResponseBody, type Aisle, type MainAisle } from '../../../types/product.types'

// @FIXME refactor to TS
import ProductDetailsChangeAisleModal from './ProductDetailsChangeAisleModal'
import ProductAislesActions from './ProductAislesActions'

const Container = styled.div`
  border-left: 1px solid ${colors.GRAY_93};
  flex: 1;
`

const ICON_CELL_WIDTH = 50

interface DepartmentAndAisleProps {
  departments: { id: number; name: string }[] | null
  aisles: { id: number; name: string }[] | null
  aislesLoading: boolean
  departmentsLoading: boolean
}

type Props = DepartmentAndAisleProps &
  WithApiInjectedProps &
  NotificationsContextValue &
  WarehouseContextValue &
  RetailerRouteComponentProps & {
    partnerId: string
    warehouseId: string
    productId: string
    product: ProductResponseBody
    availableItemsCount: number
    onDepartmentSelected?: (id: number) => void
    className?: string
  }

interface State {
  draft: {
    departmentId: number | null
    aisleId: number | null
  }
  currentEditAisleId: number | null
  currentDeleteAisleId: number | null
  busyAisleId: number | null
  showAddAisleModal: boolean
  aisles: Partial<Aisle>[]
}

function sortPrimaryAisleFirst(
  mainAisle: MainAisle | null,
  aisles: { id: number; name: string }[]
): { id: number; name: string }[] {
  if (!mainAisle || !mainAisle.id) return aisles

  return [...aisles].sort(a => (mainAisle.id === a.id ? -1 : 1))
}

class ProductAisles extends React.Component<Props, State> {
  readonly state: State = {
    draft: {
      departmentId: null,
      aisleId: null,
    },
    currentEditAisleId: null,
    currentDeleteAisleId: null,
    busyAisleId: null,
    showAddAisleModal: false,
    aisles: sortPrimaryAisleFirst(this.props.product.main_aisle, this.props.product.aisles || []),
  }

  filterAvailableAisles = (data: { id: number; name: string }[]) =>
    data.filter(
      ({ id }) =>
        !this.props.product.aisles.some(
          ({ id: existingId }) => existingId === id && existingId !== this.state.currentEditAisleId
        )
    )

  optionify = (data: { id: number; name: string }[]) =>
    data.map(({ id, name }) => ({
      label: name,
      value: id,
    }))

  columns = (props: Props) => [
    {
      header: null,
      width: ICON_CELL_WIDTH,
      Cell: ({ original }: RowInfo) => {
        const mainAisleId = this.props.product.main_aisle && this.props.product.main_aisle.id

        if (original.id === mainAisleId) {
          return (
            <DashTooltip
              placement="right"
              target={<SVGIcon name="starFilled" color={colors.GREEN_500} />}
            >
              <FormattedMessage id="catalog.product.tooltip.primary" />
            </DashTooltip>
          )
        }
        return null
      },
    },
    {
      header: <FormattedMessage id="catalog.product.head.department" />,
      Cell: ({ original }: RowInfo) => {
        const {
          draft: { departmentId },
        } = this.state

        if (original.id === this.state.currentEditAisleId) {
          return (
            <Dropdown
              width="180px"
              theme="light"
              value={departmentId}
              isBusy={props.departmentsLoading}
              onChange={this.handleDepartmentChange}
              options={this.optionify(props.departments || [])}
            />
          )
        }

        return (
          <Link route={{ name: 'aisles', params: { department_id: original.department.id } }}>
            {original.department.name}
          </Link>
        )
      },
    },
    {
      header: <FormattedMessage id="catalog.product.head.aisle" />,
      Cell: ({ original }: RowInfo) => {
        const {
          draft: { aisleId },
        } = this.state
        if (original.id === this.state.currentEditAisleId) {
          const filteredOptions = this.filterAvailableAisles(props.aisles || [])

          return (
            <Dropdown
              width="180px"
              theme="light"
              value={aisleId}
              isBusy={props.aislesLoading}
              onChange={this.handleAisleChange}
              options={this.optionify(filteredOptions)}
            />
          )
        }

        return (
          <Link
            route={{
              name: 'aisle',
              params: {
                department_id: original.department.id,
                aisle_id: original.id,
              },
            }}
          >
            {original.name}
          </Link>
        )
      },
    },
    {
      header: <FormattedMessage id="catalog.product.head.availability" />,
      Cell: ({ original }: RowInfo) => (
        <FormattedMessage
          id="storeLocations.count"
          values={{
            count: this.props.availableItemsCount ? this.props.availableItemsCount : '—',
          }}
        />
      ),
    },
    {
      header: null,
      width: ICON_CELL_WIDTH * 3,
      Cell: ({ original }: RowInfo) => {
        const mainAisleId = this.props.product.main_aisle && this.props.product.main_aisle.id
        const isMainAisle = original.id === mainAisleId
        const isEditingAisle = original.id === this.state.currentEditAisleId
        const isDeletingAisle = original.id === this.state.currentDeleteAisleId
        const isBusyAisle = original.id === this.state.busyAisleId

        return (
          <ProductAislesActions
            isMainAisle={isMainAisle}
            isEditingAisle={isEditingAisle}
            isDeletingAisle={isDeletingAisle}
            isBusyAisle={isBusyAisle}
            onUpdatePrimaryAisleClick={() => this.handleUpdatePrimaryAisle(original.id)}
            onEditClick={() => this.handleEditClick(original.id, original.department.id)}
            onDeleteClick={() => this.handleDeleteClick(original.id)}
            onConfirmUpdateClick={this.handleConfirmUpdate}
            onConfirmDeleteClick={this.handleConfirmDeleteClick}
            onCancelClick={this.handleCancelClick}
          />
        )
      },
    },
  ]

  handleAisleChange = (aisleId: number) => {
    this.setState(
      (state: State): State => ({
        ...state,
        draft: {
          ...state.draft,
          aisleId,
        },
      })
    )
  }

  handleDepartmentChange = (departmentId: number) => {
    this.props.onDepartmentSelected && this.props.onDepartmentSelected(departmentId)

    this.setState(() => ({
      draft: {
        departmentId,
        aisleId: null,
      },
    }))
  }

  handleCancelClick = () => {
    // dual-purpose cancel handler
    this.setState({
      currentDeleteAisleId: null,
      currentEditAisleId: null,
      draft: {
        departmentId: null,
        aisleId: null,
      },
    })
  }

  handleEditClick = (currentEditAisleId: number, currentEditDepartmentId: number) => {
    this.props.onDepartmentSelected && this.props.onDepartmentSelected(currentEditDepartmentId)

    this.setState({
      currentEditAisleId,
      draft: {
        departmentId: currentEditDepartmentId,
        aisleId: currentEditAisleId,
      },
    })
  }

  handleDeleteClick = (aisleId: number) => {
    this.setState({
      currentDeleteAisleId: aisleId,
    })
  }

  handleUpdatePrimaryAisle = async (id: number) => {
    const { partnerId, warehouseId, productId, warehouse } = this.props
    const localeCode = warehouse.defaultLocale.localeCode.toUpperCase()
    const payload = {
      to_aisle_id: id,
      product_ids: productId,
      locale_code: localeCode,
    }

    this.setState({ busyAisleId: id })

    try {
      await this.props.api.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/bulk_set_main_aisle`,
        { data: payload }
      )

      trackEvent({
        id: 'catalog.products.departments_and_aisles.updated_primary_aisle',
        description: 'Updated a primary aisle',
        data: {
          aisleId: id,
          productId: parseInt(productId, 10),
        },
      })

      this.props.notify(<FormattedMessage id="catalog.product.aisle.updatePrimary.success" />)
    } catch (e) {
      this.props.notifyError(<FormattedMessage id="notification.request.failed" />)
    } finally {
      this.handleAisleAsyncDone()
    }
  }

  handleConfirmDeleteClick = async () => {
    const { currentDeleteAisleId: aisleId } = this.state
    const aisle = this.state.aisles.find(({ id }) => id === aisleId) || {
      name: 'Unknown',
    }

    this.setState({ busyAisleId: aisleId })

    try {
      const { productId, partnerId, warehouseId, api } = this.props
      await api.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/remove_aisle`,
        {
          data: {
            aisle_id: aisleId,
          },
        }
      )

      trackEvent({
        id: 'catalog.products.departments_and_aisles.deleted_aisle',
        description: 'Deleted an aisle',
        data: {
          aisleId,
          productId: parseInt(productId, 10),
        },
      })

      this.props.notify(
        <FormattedMessage
          id="catalog.product.aisles.deleteSuccess"
          values={{ aisleName: aisle.name }}
        />
      )

      this.setState(state => ({
        currentDeleteAisleId: null,
        aisles: state.aisles.filter(({ id }) => id !== aisleId),
      }))
    } catch (e) {
      this.props.notifyError(
        <FormattedMessage
          id="catalog.product.aisles.deleteFailure"
          values={{ aisleName: aisle.name }}
        />
      )

      this.setState({
        currentDeleteAisleId: null,
      })
    } finally {
      this.handleAisleAsyncDone()
    }
  }

  handleConfirmUpdate = async () => {
    const {
      currentEditAisleId,
      draft: { aisleId, departmentId },
    } = this.state

    const { partnerId, warehouseId, productId, aisles, departments } = this.props

    if (!aisleId || !departments || !aisles) return

    const payload = {
      from_aisle_id: currentEditAisleId,
      to_aisle_id: aisleId,
    }

    this.setState({ busyAisleId: currentEditAisleId })

    try {
      await this.props.api.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/change_aisle`,
        { data: payload }
      )

      deprecatedAnalytics.track('product.aisle_change', {
        product_id: productId,
        source_type: 'change_aisle',
        source_value: aisleId,
      })

      trackEvent({
        id: 'catalog.products.departments_and_aisles.change_aisle_success',
        description: 'Edited an aisle',
        data: {
          aisleId,
          productId: parseInt(productId, 10),
        },
      })

      const newAisle = aisles.find(({ id }) => id === aisleId)
      const newDepartment = departments.find(({ id }) => id === departmentId)

      if (!newAisle || !newDepartment) return

      // Just update the id and name of the new aisle and department to display in the table.
      this.setState(state => ({
        currentEditAisleId: null,
        aisles: state.aisles.map(aisle =>
          aisle.id === currentEditAisleId
            ? { ...aisle, ...newAisle, department: { ...newDepartment } }
            : aisle
        ),
        draft: {
          departmentId: null,
          aisleId: null,
        },
      }))

      this.props.notify(<FormattedMessage id="catalog.product.aisles.updateSuccess" />)
    } catch (e) {
      this.setState({
        currentEditAisleId: null,
        draft: {
          departmentId: null,
          aisleId: null,
        },
      })

      this.props.notifyError(<FormattedMessage id="catalog.product.aisles.updateFailure" />)
    } finally {
      this.handleAisleAsyncDone()
    }
  }

  handleAddAisleClick = () => {
    this.setState({
      showAddAisleModal: true,
    })
  }

  handleCloseModal = () => {
    this.setState({
      showAddAisleModal: false,
    })
  }

  handleAddAisleSuccess = (aisle: Aisle) => {
    this.setState(state => ({
      showAddAisleModal: false,
      aisles: [{ ...aisle }, ...state.aisles],
    }))
  }

  handleAisleAsyncDone = () => {
    this.setState({ busyAisleId: null })
  }

  render() {
    const { aisles } = this.state

    return (
      <Container className={this.props.className}>
        <FilterBar padding="15px 20px" style={{ justifyContent: 'flex-end' }}>
          <Button snacksStyle="primary" size="small" onClick={this.handleAddAisleClick}>
            <FormattedMessage id="catalog.product.aisles.add" />
          </Button>
        </FilterBar>
        <Table
          columns={this.columns(this.props)}
          data={aisles}
          paginated={false}
          noDataText="No Product Department & Aisles"
        />
        {this.state.showAddAisleModal && (
          <ProductDetailsChangeAisleModal
            product={this.props.product}
            onCloseSuccess={this.handleAddAisleSuccess}
            onClose={this.handleCloseModal}
          />
        )}
      </Container>
    )
  }
}

export default withTrackEventOnMount({
  id: 'catalog.products.departments_and_aisles.viewed',
  description: 'Viewed the departments and aisles page for a specific product',
})(
  // @ts-expect-error, all these legacy decorators have borked types
  withNotificationsContext(withDepartmentAisles(withApi(withWarehouseContext(ProductAisles))))
) as any
