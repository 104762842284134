import React, { type FC } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { colors } from '@retailer-platform/shared-components'
import { ChevronRightIcon } from '@instacart/ids-core'
import { useNavContext } from '../../../nav/utils/NavContext'
import { type NavMenuHierarchyL1 } from '../../NavMenuHierarchy'
import { useGatherAllRoutes } from '../navBar.common'
import { useAbsoluteRouteMatch } from '../../../../../utils/routing/useAbsoluteRouteMatch.hooks'

const MenuItemTitle = styled.div<{ selected: boolean; previouslySelected: boolean }>(
  {
    padding: '8px 24px 8px 20px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.PRIMARY_TDS.LIGHT,
    },
  },
  ({ selected, previouslySelected }) => ({
    backgroundColor: selected && colors.PRIMARY_TDS.EXTRA_LIGHT,
    borderLeft: previouslySelected
      ? `3px solid ${colors.PRIMARY_TDS.REGULAR}`
      : '3px solid transparent',
  })
)

const TextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  color: colors.PRIMARY_TDS.REGULAR,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
})

type Props = {
  currentlySelectedItem: number
  setCurrentlySelectedItem: any
  l1Key: number
  l1: NavMenuHierarchyL1
  title: string
}

export const NavMenuLeftL1Item: FC<React.PropsWithChildren<Props>> = ({
  currentlySelectedItem,
  setCurrentlySelectedItem,
  l1Key,
  l1,
  title,
}) => {
  const { mostRecentClickedRoute } = useNavContext()
  const intl = useIntl()
  const routeParams = useAbsoluteRouteMatch()

  const routes = useGatherAllRoutes(l1)

  const previouslySelected =
    routes?.includes(routeParams?.route) || routes?.includes(mostRecentClickedRoute)

  return (
    <MenuItemTitle
      key={l1Key}
      selected={currentlySelectedItem == l1Key}
      previouslySelected={previouslySelected}
      onClick={() => {
        setCurrentlySelectedItem(l1Key)
      }}
    >
      {title && (
        <TextContainer data-testid="nav-item-text">
          {intl.formatMessage({ id: title })}
        </TextContainer>
      )}
      <div css={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '0px' }}>
        <ChevronRightIcon css={{ fill: '#0A5546' }} size={16} />
      </div>
    </MenuItemTitle>
  )
}
