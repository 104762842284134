import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import { createFilterParamConfig } from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'collectionId',
    'COLLECTION',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'collectionId',
    'COLLECTION_AND_WAREHOUSE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('platform', 'PLATFORM', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'orderSource',
    'ORDER_SOURCE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'collectionLevel',
    'NAV_COLLECTION_LEVEL',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('collectionL1', 'L1', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig('collectionL2', 'L2', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig('collectionL3', 'L3', optionsArrayToInFilterSelection('stringValue')),
]

export const collectionSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const mapApiNameToDashboardName = {
  WAREHOUSE: 'retailer',
  COLLECTION: 'collectionId',
  PLATFORM: 'platform',
  ORDER_SOURCE: 'orderSource',
}
