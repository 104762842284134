import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_SITE_MANAGEMENT = `${URL_RETAILER_SCOPED}/site-management`

const URL_SITE_MANAGEMENT_SITE_SCOPED = `${URL_SITE_MANAGEMENT}${URL_SITE_SCOPED}`
const URL_SITE_MANAGEMENT_NAVIGATION_BASE = `${URL_SITE_MANAGEMENT_SITE_SCOPED}/navigation`
const URL_SITE_MANAGEMENT_NAVIGATION_DESKTOP = `${URL_SITE_MANAGEMENT_NAVIGATION_BASE}/desktop`
const URL_SITE_MANAGEMENT_NAVIGATION_MWEB = `${URL_SITE_MANAGEMENT_NAVIGATION_BASE}/mobile-web`
const URL_SITE_MANAGEMENT_NAVIGATION_NATIVE = `${URL_SITE_MANAGEMENT_NAVIGATION_BASE}/native`
const URL_SITE_MANAGEMENT_NAVIGATION_DESKTOP_STYLES = `${URL_SITE_MANAGEMENT_NAVIGATION_BASE}/desktop/styles`
const URL_SITE_MANAGEMENT_NAVIGATION_MWEB_STYLING = `${URL_SITE_MANAGEMENT_NAVIGATION_BASE}/mobile-web/styles`
const URL_SITE_MANAGEMENT_NAVIGATION_NATIVE_STYLING = `${URL_SITE_MANAGEMENT_NAVIGATION_BASE}/native/styles`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'site-management'>
  | DashRouteType<'site-management-navigation-desktop'>
  | DashRouteType<'site-management-navigation-mweb'>
  | DashRouteType<'site-management-navigation-native'>
  | DashRouteType<'site-management-navigation-desktop-styles'>
  | DashRouteType<'site-management-navigation-mweb-styles'>
  | DashRouteType<'site-management-navigation-native-styles'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'site-management': URL_SITE_MANAGEMENT_SITE_SCOPED,
  'site-management-navigation-desktop': URL_SITE_MANAGEMENT_NAVIGATION_DESKTOP,
  'site-management-navigation-mweb': URL_SITE_MANAGEMENT_NAVIGATION_MWEB,
  'site-management-navigation-native': URL_SITE_MANAGEMENT_NAVIGATION_NATIVE,
  'site-management-navigation-desktop-styles': URL_SITE_MANAGEMENT_NAVIGATION_DESKTOP_STYLES,
  'site-management-navigation-mweb-styles': URL_SITE_MANAGEMENT_NAVIGATION_MWEB_STYLING,
  'site-management-navigation-native-styles': URL_SITE_MANAGEMENT_NAVIGATION_NATIVE_STYLING,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
