import React, { type FunctionComponent, Fragment, useState } from 'react'
import withRetailerRouter from '../../common/withRouter'
import { type RetailerRouteComponentProps } from '../RetailerRoute'
import { type ScopedRouteParams } from '../../common/types'
import BannerSwitchButton from './BannerSwitchButton'
import SelectPartnerWarehouse from './SelectPartnerWarehouse'

interface Props extends RetailerRouteComponentProps<ScopedRouteParams> {}

const BannerSwitchTrigger: FunctionComponent<React.PropsWithChildren<Props>> = ({
  match: {
    params: { partner_id: partnerId },
  },
}) => {
  const currentPartnerId = partnerId ? parseInt(partnerId, 10) : undefined

  const [modalOpen, setModal] = useState(false)
  const openModal = () => setModal(true)
  const closeModal = () => setModal(false)

  const modalContent = (
    <SelectPartnerWarehouse
      initialPartnerId={currentPartnerId}
      handleClose={closeModal}
      beforeRedirect={closeModal}
    />
  )

  return (
    <Fragment>
      <BannerSwitchButton onClick={openModal} />
      {modalOpen && modalContent}
    </Fragment>
  )
}

export default withRetailerRouter(BannerSwitchTrigger)
