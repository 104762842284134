import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { createSchemaConfig } from '../helpers/schema.helpers'
import { InsightsFormatterType, InsightsGroupByTypes } from '../types/schema.types'
import { Dimensions, GroupBys } from './insightsPortalFutureScheduledOrders.types'
import { futureScheduledOrdersSchemaFilterParamsConfig } from './insightsPortalFutureScheduledOrders.hooks'

export { Dimensions, GroupBys }

export const schemaConfig = createSchemaConfig({
  dimensions: Dimensions,
  dimensionsConfig: {
    [Dimensions.ORDER_ID]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.orderId',
    },
    [Dimensions.ORDER_PLACED_DATE_PT]: {
      date: true,
      formatter: InsightsFormatterType.Date,
      label: 'insightsPortalDomain.filters.orderPlacedDate',
    },
    [Dimensions.WORKFLOW_STATE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.workflowState',
    },
    [Dimensions.ITEM_NAME]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.ItemName',
    },
    [Dimensions.RETAILER_REFERENCE_CODE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailerReferenceCode',
    },
    [Dimensions.ORDERED_QTY]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.orderedQuantity',
    },
  },
  metrics: {},
  metricsConfig: {},
  // TODO: deprecate
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.ORDER_ID]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.orderId',
    },
    [GroupBys.ORDER_PLACED_DATE_PT]: {
      formatter: InsightsFormatterType.Date,
      label: 'insightsPortalDomain.filters.orderPlacedDate',
    },
    [GroupBys.WORKFLOW_STATE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.workflowState',
    },
    [GroupBys.ITEM_NAME]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.ItemName',
    },
    [GroupBys.RETAILER_REFERENCE_CODE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailerReferenceCode',
    },
    [GroupBys.ORDERED_QTY]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.orderedQuantity',
    },
  },
  filterConfigs: futureScheduledOrdersSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    futureScheduledOrdersSchemaFilterParamsConfig
  ),
})
