import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'

export const ItemList = styled.div({
  overflow: 'auto',
  flexGrow: 1,
  width: 'max-content',
})

export const ColumnHeader = styled.div({
  padding: '12px 0px',
  fontSize: '14px',
  lineHeight: '20px',
})

interface StoreConfigItemProps {
  selected: boolean
  disabled?: boolean
}

export const StoreConfigItem = styled.div<StoreConfigItemProps>(({ selected, disabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 8px 8px 8px',
  gap: '8px',
  minWidth: '240px',
  minHeight: '48px',
  fontSize: '14px',
  lineHeight: '20px',
  backgroundColor: selected ? colors.GRAYSCALE.X20 : null,
  color: disabled ? colors.GRAYSCALE.X70 : null,
  cursor: !disabled && 'pointer',
  userSelect: 'none',
}))

export const StyledLogoImage = styled.img(({ grayout }: { grayout?: boolean }) => ({
  width: 32,
  height: 32,
  background: 'white',
  boxSizing: 'border-box',
  border: '1px solid #F6F7F8',
  borderRadius: '44px',
  filter: grayout ? 'grayscale(100%)' : '',
}))

export const StyledLogoDiv = styled.div({
  width: 32,
  height: 32,
  background: 'white',
  boxSizing: 'border-box',
  border: `1px solid ${colors.GRAYSCALE.X20}`,
  borderRadius: '44px',
})

interface TagProps {
  color: string
}

export const Tag = styled.div<TagProps>(({ color }) => ({
  flexShrink: 0,
  margin: '8px 8px',
  padding: '4px 8px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  textAlign: 'center',
  // marginLeft: 'auto',
  marginRight: 0,
  backgroundColor: color,
}))
