import React, { memo } from 'react'
import { type ListChildComponentProps } from 'react-window'
import { GridBodyRow } from '../components/GridBodyRow'
import { GridLoadingRow } from '../components/GridLoadingRow'
import { type GridDatum, type CustomTableInstance } from '../utils/grid.types'

interface RowProps<TDatum extends GridDatum> extends ListChildComponentProps {
  data: Pick<CustomTableInstance<TDatum>, 'rows' | 'prepareRow' | 'compact'>
}

export const GridRenderedRow = memo(
  <TDatum extends GridDatum>({ data, index, style }: RowProps<TDatum>) => {
    const { prepareRow, rows, compact } = data
    const row = rows[index]

    if (!row) {
      return (
        <div
          style={{ ...style, width: 'fit-content', minWidth: '100%' }}
          data-testid={`grid-window-row-${index}`}
        >
          <GridLoadingRow data-testid={`grid-window-row-loading-${index}`} />
        </div>
      )
    }

    prepareRow(row)

    return (
      <div
        style={{ ...style, width: 'fit-content', minWidth: '100%' }}
        data-testid={`grid-window-row-${index}`}
      >
        <GridBodyRow
          data-testid={`grid-window-row-rendered-${index}`}
          last={index === rows.length - 1}
          compact={compact}
          {...row}
        />
      </div>
    )
  }
)
