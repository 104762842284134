import React, { type ReactElement, useMemo } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import {
  gridBodyCellStyles,
  gridCompactBodyCellStyles,
  gridSortableCellStyles,
  gridSortedCellStyles,
} from '../utils/grid.styles'
import { type GridDatum, type GridCell } from '../utils/grid.types'

interface Props<TDatum extends GridDatum> extends GridCell<TDatum> {
  compact?: boolean
}

export const GridBodyCell = <TDatum extends GridDatum>({
  getCellProps,
  render,
  column,
  compact,
}: Props<TDatum>): ReactElement => {
  const renderedCell = useMemo(() => render('Cell'), [render])
  const { style, ...cellProps } = getCellProps()
  const { key, ...restCellProps } = cellProps
  const dynamicStyles = css(
    style as SerializedStyles,
    gridBodyCellStyles,
    column.canSort && gridSortableCellStyles,
    column.isSorted && gridSortedCellStyles,
    column.cellStyles && column.cellStyles,
    compact && gridCompactBodyCellStyles
  )

  return (
    <div key={key} {...restCellProps} css={dynamicStyles}>
      {renderedCell}
    </div>
  )
}
