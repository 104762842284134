import styled from '@emotion/styled'
import type React from 'react'
import { colors, fontWeight, spacing } from '../../foundation'

export type BadgeColorOptions = 'red' | 'blue' | 'green' | 'grey' | 'yellow'

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The color to be used for this badge. Note that any additional colors can be added to the `badgeColors` object in the component file.
   */
  color?: BadgeColorOptions
}

const badgeColors = {
  red: {
    backgroundColor: colors.DETRIMENTAL.LIGHT,
    color: colors.DETRIMENTAL.REGULAR,
  },
  blue: {
    backgroundColor: '#F2F8FF',
    color: colors.HIGHLIGHT.REGULAR,
  },
  green: {
    backgroundColor: colors.SUCCESS.LIGHT,
    color: colors.SUCCESS.REGULAR,
  },
  yellow: {
    backgroundColor: '#FFFADF',
    color: '#F89F1A',
  },
  grey: {
    backgroundColor: colors.GRAYSCALE.X20,
    color: colors.GRAYSCALE.X70,
  },
}

/**
 * A badge is a small piece of information that is used to label content.
 *
 * Note that the size of the badge will inherit whatever is passed as children.
 */
export const Badge = styled.div<BadgeProps>((props: BadgeProps) => {
  // Default is 'blue'
  const colorStyles = (props.color && badgeColors[props.color]) || badgeColors.blue

  return {
    ...colorStyles,
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: spacing.X12,
    fontSize: 10,
    fontWeight: fontWeight.BOLD,
    height: 'fit-content',
    textAlign: 'center',
  }
})
