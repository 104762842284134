import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'storeLocation',
    'WAREHOUSE_LOCATION',
    optionsArrayToInFilterSelection('stringValue')
  ),
]

export const futureScheduledOrdersSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const transformFilterSelectionsToFutureScheduledOrdersSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)

export const transformFutureScheduledOrdersApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  WAREHOUSE_LOCATION: 'stringValue',
} as const
