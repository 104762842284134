import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { retailerFundedMarketingAdminAccessControl } from '../access-control/retailerFundedMarketingAdminAccess.configuration'

const CampaignTemplatesPage = lazy(
  () => import('../pages/campaign-templates/CampaignTemplatesPage')
)

const RetailerCampaignListPage = lazy(
  () => import('../pages/retailer-campaigns/list/RetailerCampaignListPage')
)

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const retailerFundedMarketingAdminRouter = (
  <DomainRoute
    route="app-admin-retailer-funded-marketing"
    accessControl={retailerFundedMarketingAdminAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-retailer-funded-marketing-list"
        component={RetailerCampaignListPage}
      />
      <DomainRoute
        exact
        route="app-admin-retailer-funded-marketing-campaign-templates"
        component={CampaignTemplatesPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
