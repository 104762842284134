import React, { type ComponentType } from 'react'
import { Route, type RouteProps, matchPath } from 'react-router'
import useAccessControl from '../../components/AccessControl/useAccessControl'
import { type DashRouteName } from '../../../utils/routing/routes'
import { type AccessControlConfig } from '../../components/AccessControl/accessControl.utils'
import { useCoreContext } from '../../../utils/core/RPPCoreContext'
import { NoAccessPage } from '../../../gin-and-tonic/containers/no-access-page/NoAccessPage'
import { type DashRouteNameProps } from './utils/dashRoute.types'
import {
  type RetailerScopeWrapperProps,
  RetailerScopeWrapper,
} from '../../../gin-and-tonic/containers/retailer-scope-wrapper/RetailerScopeWrapper'
import { useOptionalAccessControlsContext } from '../../../utils/contexts/access-controls/AccessControls.hooks'
import {
  AccessControlsContext,
  type AccessControlsContextValue,
} from '../../../utils/contexts/access-controls/AccessControlsContext'
import { useOptionalPartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'
import { PartnerType } from '../../common/types'

const addAccessControlsProvider = (
  accessControl: AccessControlConfig,
  accessControlsContext: AccessControlsContextValue,
  component: JSX.Element
): JSX.Element => {
  if (accessControl) {
    // accumulate access controls we've seen along the way
    const newAccessControl = accessControl
    const parentAccessControls = accessControlsContext?.accessControls || []

    // we dont want to flatten the new access controls. If they are grouped in an array,
    // keep it that way since this grouping is important when evaluating in useHasAccess
    const accessControls = [...parentAccessControls, newAccessControl]

    component = (
      <AccessControlsContext.Provider value={{ accessControls }}>
        {component}
      </AccessControlsContext.Provider>
    )
  }

  return component
}

// Note that the route prop is used by the DashSwitch to determine the Route's path
export interface DashRouteProps<TRoute extends DashRouteName>
  extends Pick<RouteProps, 'children' | 'exact' | 'render'> {
  component?: ComponentType<React.PropsWithChildren<DashRouteNameProps<TRoute>>>
  route?: TRoute
  accessControl?: AccessControlConfig
  scopePicker?: RetailerScopeWrapperProps
}

// Default to the "base" route, which provides no params to the component prop
// (Meaning, no `:partnerId` or `:retailerId`)
export function DashRoute<TRoute extends DashRouteName = 'base'>({
  route,
  accessControl,
  scopePicker,
  ...rest
}: DashRouteProps<TRoute>) {
  const hasAccess = useAccessControl()
  const { routesByName } = useCoreContext()
  const accessControlsContext = useOptionalAccessControlsContext()
  const partnerContext = useOptionalPartnerContext()

  // `path` can be injected by the DashSwitch
  const routeProps = { ...(rest as RouteProps) }

  if (accessControl && !hasAccess(accessControl)) {
    let noAccess = <NoAccessPage accessControl={accessControl} />

    // only show retailer picker if we are in a partner scope (with a partner selected and for partnerType=Retailer).
    // Otherwise this retailer picker can incorrectly show for admin pages etc.
    if (partnerContext?.validId && partnerContext?.partnerType === PartnerType.Retailer) {
      noAccess = (
        <RetailerScopeWrapper {...scopePicker} retailerInsufficientPermission>
          {noAccess}
        </RetailerScopeWrapper>
      )
    }

    return addAccessControlsProvider(accessControl, accessControlsContext, noAccess)
  }

  // If it hasn't been injected, we add it ourselves
  if (!routeProps.path && route) {
    routeProps.path = routesByName[route]
  }

  let routeComponent = <Route {...routeProps} />

  if (scopePicker) {
    routeComponent = <RetailerScopeWrapper {...scopePicker}>{routeComponent}</RetailerScopeWrapper>
  }

  return addAccessControlsProvider(accessControl, accessControlsContext, routeComponent)
}
