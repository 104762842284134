import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { integrationsRouter } from './routing/integrationsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { integrationsSquareAccessControl } from './access-control/squareIntegration.configuration'
import { integrationsAccessControl } from './access-control/integrationsAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'integrations',
  totem: {
    entity: 'integrations-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [integrationsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Settings,
      attachToV2: NavEntryPoint.UserMenuSettings,
      subNavItems: [
        {
          type: 'item',
          labelId: 'integrationsDomain.title',
          route: 'integrations-list',
          accessControl: integrationsAccessControl,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
