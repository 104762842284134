import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const storeHoursBulkAccessControlView: DomainAccessControlConfig = {
  permissions: [Permission.WarehouseLocationHoursView],
}

export const storeHoursBulkAccessControlModify: DomainAccessControlConfig = {
  permissions: [Permission.WarehouseLocationHoursModify],
}
