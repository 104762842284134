import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'
export const catalogAdminExtractionModelsAccessConfiguration: DomainAccessControlConfig = {
  permissions: Permission.CatalogDetectionViewAccess,
  featureToggles: [FeatureToggle.catalogAdminExtractionModels],
}

export const catalogAdminExtractionModelsEditAccessConfiguration: DomainAccessControlConfig = {
  permissions: [Permission.CatalogDetectionViewAccess, Permission.CatalogDetectionModifyAccess],
  featureToggles: [FeatureToggle.catalogAdminExtractionModels],
}
