import moment from 'moment-timezone'

/**
 * Returns the timezone for the current user
 * @date 9/28/2022 - 12:23:30 PM
 *
 * @returns {string}
 */
export const currentUserTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Returns the current datetime
 * @date 9/28/2022 - 12:23:30 PM
 *
 * @param {boolean} localize whether or not we should localize to current user timezone
 * @returns {moment.Moment} current date time
 */
export const currentTime = (localize: boolean): moment.Moment => {
  if (localize) return moment().tz(currentUserTimezone())
  return moment()
}

/**
 * Creates an ISO 8601 datetime string for a given date
 * @date 2/1/2023 - 7:55:31 AM
 *
 * @param {Date} date
 * @param {?string} [timezone]
 * @returns {(string | null)}
 */
export const dateToMidnightISOString = (date: string, timezone?: string): string | null => {
  if (!date) return null
  return moment
    .tz(date, timezone || currentUserTimezone())
    .startOf('day')
    .toISOString()
}
/**
 * Formats a date string into MM/DD/YYYY format
 * @date 11/14/2023
 *
 * @param {string} date - The date string to format
 * @returns {string} The formatted date string in MM/DD/YYYY format
 */
export const dateToMMDDYYYY = (date: string) => moment(date).format('MM/DD/YYYY')

/**
 * Formats a date string into a relative time string (e.g. "2 hours ago")
 * @date 11/14/2023
 *
 * @param {string} date - The date string to convert to relative time
 * @returns {string} A human readable string describing how long ago the date was
 */
export const timeSince = (date: string) => moment(date).fromNow()
