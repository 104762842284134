import { type DashRouteType, URL_APP_ADMIN } from '@retailer-platform/dashboard/routing'

export const URL_ADMIN_FULFILLMENT_EVENTS = `${URL_APP_ADMIN}/fulfillment-events`
export const URL_ADMIN_FULFILLMENT_EVENTS_APPROVAL_REQUEST_SHOW = `${URL_ADMIN_FULFILLMENT_EVENTS}/approval-request/:approvalRequestId`
// @hygen:inject admin-urls

export type SharedFulfillmentEventsRouteSet =
  | DashRouteType<'app-admin-fulfillment-events'>
  | DashRouteType<'app-admin-fulfillment-events-approval-request'>
// @hygen:inject dash-route-set

export const SharedFulfillmentEventsRoutes: {
  [Key in SharedFulfillmentEventsRouteSet['identifier']]: string
} = {
  'app-admin-fulfillment-events': URL_ADMIN_FULFILLMENT_EVENTS,
  'app-admin-fulfillment-events-approval-request':
    URL_ADMIN_FULFILLMENT_EVENTS_APPROVAL_REQUEST_SHOW,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof SharedFulfillmentEventsRoutes
