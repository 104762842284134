import {
  GRID_COMPACT_HEADER_ROW_HEIGHT,
  GRID_HEADER_ROW_HEIGHT,
  GRID_COMPACT_ROW_HEIGHT,
  GRID_ROW_HEIGHT,
} from './grid.constants'

export const getHeaderRowHeight = (compact?: boolean) =>
  compact ? GRID_COMPACT_HEADER_ROW_HEIGHT : GRID_HEADER_ROW_HEIGHT

export const getBodyRowHeight = (compact?: boolean) =>
  compact ? GRID_COMPACT_ROW_HEIGHT : GRID_ROW_HEIGHT
