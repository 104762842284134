import { ApolloLink } from 'apollo-link'

export type createOperationNameLinkOptions = {
  uri: string
}

export const createOperationNameLink = (options: createOperationNameLinkOptions) => {
  return new ApolloLink((operation, forward) => {
    const uriParts = options.uri.split('?')
    const searchParams = new URLSearchParams(uriParts.at(1))

    searchParams.set('operationName', operation.operationName)

    operation.setContext({
      uri: `${uriParts.at(0)}?${searchParams.toString()}`,
    })

    return forward(operation)
  })
}
