import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_CONNECT = `${URL_RETAILER_SCOPED}/connect`
const URL_CONNECT_CONFIGURATION = `${URL_CONNECT}/configuration`
const URL_CONNECT_FULFILLMENT_SIMULATOR = `${URL_CONNECT}/fulfillment-simulator`
const URL_CONNECT_CALLBACK_TESTING = `${URL_CONNECT}/callback-testing`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'connect'>
  | DashRouteType<'connect-configuration'>
  | DashRouteType<'connect-fulfillment-simulator'>
  | DashRouteType<'connect-callback-testing'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  connect: URL_CONNECT,
  'connect-configuration': URL_CONNECT_CONFIGURATION,
  'connect-fulfillment-simulator': URL_CONNECT_FULFILLMENT_SIMULATOR,
  'connect-callback-testing': URL_CONNECT_CALLBACK_TESTING,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
