import React, { type FunctionComponent } from 'react'
import { Button } from 'ic-snacks'
import { Box } from '../box/Box'
import { type DashButtonProps } from './utils/dashButton.types'
import {
  mapDashButtonSizeToSnacksSize,
  mapDashButtonVariantToSnacksStyle,
  mapDashButtonVariantToSnacksTheme,
} from './utils/dashButton.utils'

export const DashButton: FunctionComponent<React.PropsWithChildren<Partial<DashButtonProps>>> = ({
  buttonStyle,
  children,
  testId,
  size,
  disabled,
  variant,
  onClick,
  type,
  ...rest
}) => {
  const snacksSize = mapDashButtonSizeToSnacksSize(size)
  const snacksStyle = mapDashButtonVariantToSnacksStyle(variant)
  const snacksTheme = mapDashButtonVariantToSnacksTheme(variant)
  const elementAttributes = { 'data-testid': testId || undefined }

  return (
    <Box {...rest}>
      <Button
        disabled={disabled}
        onClick={onClick}
        size={snacksSize}
        style={buttonStyle}
        snacksStyle={snacksStyle}
        snacksTheme={snacksTheme}
        elementAttributes={elementAttributes}
        type={type}
      >
        {children}
      </Button>
    </Box>
  )
}
