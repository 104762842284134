import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { emergingBrandsPortalAccessControl } from '../access-control/emergingBrandsPortalAccess.configuration'

const EmergingBrandsPortalProductPage = lazy(
  () =>
    import(
      /* webpackChunkName: "EmergingBrandsPortalProductPage" */
      '../pages/product/EmergingBrandsPortalProductPage'
    )
)
const EmergingBrandsPortalSellSheetPage = lazy(
  () =>
    import(
      /* webpackChunkName: "EmergingBrandsPortalSellSheetPage" */
      '../pages/sell-sheet/EmergingBrandsPortalSellSheetPage'
    )
)
const EmergingBrandsPortalBrandPage = lazy(
  () =>
    import(
      /* webpackChunkName: "EmergingBrandsPortalBrandPage" */
      '../pages/brand/EmergingBrandsPortalBrandPage'
    )
)
const EmergingBrandsPortalSearchPage = lazy(
  () =>
    import(
      /* webpackChunkName: "EmergingBrandsPortalSearchPage" */
      '../pages/search/EmergingBrandsPortalSearchPage'
    )
)
const EmergingBrandPortalTopBrandsBrowsePage = lazy(
  () =>
    import(
      /* webpackChunkName: "EmergingBrandPortalTopBrandsBrowsePage" */
      '../pages/topBrandsBrowse/EmergingBrandPortalTopBrandsBrowsePage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const emergingBrandsPortalRouter = (
  <DomainRoute route="emerging-brands-portal" accessControl={emergingBrandsPortalAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="emerging-brands-portal-product"
        component={EmergingBrandsPortalProductPage}
      />
      <DomainRoute
        exact
        route="emerging-brands-portal-sell-sheet"
        component={EmergingBrandsPortalSellSheetPage}
      />
      <DomainRoute
        exact
        route="emerging-brands-portal-brand"
        component={EmergingBrandsPortalBrandPage}
      />
      <DomainRoute
        exact
        route="emerging-brands-portal-search"
        component={EmergingBrandsPortalSearchPage}
        scopePicker={{
          titles: ['emergingBrandsPortalDomain.title'],
        }}
      />
      <DomainRoute
        exact
        route="emerging-brands-portal-browse-top-brands"
        component={EmergingBrandPortalTopBrandsBrowsePage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
