import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  landingAccessControl,
  pageBuilderModifyAccessControl,
  pageBuilderViewAccessControl,
} from '../access-control/landingAccess.configuration'

const LandingContentPagesDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LandingContentPagesDashboardPage" */
      '../pages/content-pages-dashboard/LandingContentPagesDashboardPage'
    )
)
const LandingContentPagesBuilderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LandingContentPagesBuilderPage" */
      '../pages/content-pages-builder/LandingContentPagesBuilderPage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const landingRouter = (
  <DomainRoute route="landing" accessControl={landingAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="landing-content-pages-dashboard"
        component={LandingContentPagesDashboardPage}
        accessControl={pageBuilderViewAccessControl}
      />
      <DomainRoute
        exact
        route="landing-content-pages-create"
        component={LandingContentPagesBuilderPage}
        accessControl={pageBuilderModifyAccessControl}
      />
      <DomainRoute
        exact
        route="landing-content-pages-edit"
        component={LandingContentPagesBuilderPage}
        accessControl={pageBuilderViewAccessControl}
      />
      <DomainRoute
        exact
        route="landing-content-pages-edit-version"
        component={LandingContentPagesBuilderPage}
        accessControl={pageBuilderViewAccessControl}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
