/* eslint-disable @typescript-eslint/naming-convention */

export type IntegrationConfig = {
  allowBreakGlassPublish: boolean
  autoPublishOnApprove: boolean
}

export const IntegrationConfigurations = {
  test_content_type: {
    allowBreakGlassPublish: true,
    autoPublishOnApprove: true,
  },
  data_ingestion_column_maps: {
    allowBreakGlassPublish: false,
    autoPublishOnApprove: true,
  },
  connect_api_key: {
    allowBreakGlassPublish: false,
    autoPublishOnApprove: true,
  },
  yoda_rules: {
    allowBreakGlassPublish: false,
    autoPublishOnApprove: true,
  },
  fulfillment_events: {
    allowBreakGlassPublish: true,
    autoPublishOnApprove: true,
  },
  /*
   * INTEGRATION DEVS: Add new content types here
   */
} as const satisfies { [x: string]: IntegrationConfig }

export type ContentTypes = keyof typeof IntegrationConfigurations
