import { type GetInsightsPortalFilterOptionsQuery } from '../../api'
import { type useFilterParams } from './FilterParamsProvider'

export type FilterParamsState = ReturnType<typeof useFilterParams>

export type FilterParamsFilterNames = keyof FilterParamsState['selectedFiltersValues']

export type InsightsPortalApiFilterOptions =
  GetInsightsPortalFilterOptionsQuery['insightsPortalServiceGetInsightsPortalFilterOptions']['filterOptions']

export enum ProductCategoryFilters {
  L1Category = 'L1Category',
  L2Category = 'L2Category',
  L3Category = 'L3Category',
  L4Category = 'L4Category',
  L5Category = 'L5Category',
  L6Category = 'L6Category',
}

export const InsightsWeekStartDayLocalStorageKey = 'insightsPortal.weekStartDay' as const
