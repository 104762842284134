import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { PrivateSchemaPropertyType, createSchemaConfig } from '../helpers/schema.helpers'
import { InsightsFormatterType } from '../types/schema.types'
import { missingImage90DayRetailerInsightsSchemaFilterParamsConfig } from './insightsPortalMissingImage90DayRetailerInsightsSchema.hooks'
import {
  GroupBys,
  Metrics,
  Dimensions,
} from './insightsPortalMissingImage90DayRetailerInsights.types'

export { GroupBys, Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.SUM_CONVERSION_GMV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.conversionGMV',
    },
    [Metrics.SUM_IMPRESSIONS]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.searchImpressions',
    },
  },
  dimensionsConfig: {
    [Dimensions.ITEM_NAME]: {
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.itemName',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [Dimensions.PRODUCT_ID]: {
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.productID',
      private: PrivateSchemaPropertyType.DataExplorerFilters,
      formatter: (value: number) => Math.round(value).toString(),
    },
    [Dimensions.WAREHOUSE_ID]: {
      label: 'insightsPortalDomain.filters.retailerIds.label',
      private: true,
    },
    [Dimensions.PRODUCT_UPC_CODE]: {
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.productUpcCode',
      private: PrivateSchemaPropertyType.DataExplorerFilters,
    },
    [Dimensions.DEPARTMENT]: {
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.department',
      private: PrivateSchemaPropertyType.DataExplorerFilters,
    },
    [Dimensions.PRODUCT_RRC_CODE]: {
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.productRrcCode',
    },
  },
  dimensionGroupings: [
    {
      label: 'insightsPortalDomain.filters.stores',
      dimensions: [Dimensions.WAREHOUSE],
    },
    {
      label: 'insightsPortalDomain.inventoryIntelligence.oosTable.displayName',
      dimensions: [
        Dimensions.ITEM_NAME,
        Dimensions.PRODUCT_ID,
        Dimensions.PRODUCT_UPC_CODE,
        Dimensions.DEPARTMENT,
        Dimensions.PRODUCT_RRC_CODE,
      ],
    },
  ],

  // TODO: deprecate
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.ITEM_NAME]: {
      private: true,
    },
    [GroupBys.PRODUCT_ID]: {},
    [GroupBys.WAREHOUSE]: {},
    [GroupBys.WAREHOUSE_ID]: {},
    [GroupBys.PRODUCT_UPC_CODE]: {
      private: true,
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.productUpcCode',
    },
    [GroupBys.DEPARTMENT]: {
      private: true,
    },
    [GroupBys.PRODUCT_RRC_CODE]: {
      private: true,
    },
  },
  filterConfigs: missingImage90DayRetailerInsightsSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    missingImage90DayRetailerInsightsSchemaFilterParamsConfig
  ),
})
