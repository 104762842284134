import React from 'react'

export enum FeatureToggle {
  CatalogMetricsV2 = 'catalog_metrics_v2',
  InventoryFileState = 'inventory_file_state',
  DepartmentOrdering = 'department_ordering',
  AisleOrdering = 'aisle_ordering',
  AlcoholOrderTooltip = 'rt_alcohol_order_tooltip',
  OrderColumnDeliveryOption = 'rt_order_column_delivery_option',
  HasImageFilter = 'has_image_filter',
  ModernFilesSection = 'modern_files_section',
  Merchandising = 'rt_merchandising',
  PromotedPlacements = 'rt_promoted_placements',
  HideDefaultDepartments = 'hide_default_departments',
  DisplayProductCategoryPathInProductDetails = 'display_product_category_path_in_product_details',
  ProductRequestHideDepartmentAisle = 'product_request_hide_department_aisle',
  CatalogSearchHideDepartmentAisle = 'catalog_search_hide_department_aisle',
  CatalogHideProductAisles = 'catalog_hide_product_aisles',
  ordersOperationalDashboard = 'rt_orders_operational_dashboard',
  WebhookConfiguration = 'rt_webhook_configuration',
  NavShowAccessControls = 'rt_ipp_nav_show_access_controls',
  ScanAndPay = 'rt_scan_and_pay_retailers',
  AuditLogs = 'rt_audit_logs_list',
  RetailerCollections = 'rt_retailer_collections',
  FeatureEducation = 'rt_feature_education',
  ScanAndPayOrderRefund = 'rt_scan_and_pay_order_refund',
  NavV2 = 'rt_ia_nav_v2',
  // hygen:inject featureToggle
}

export type Flips = { [key in FeatureToggle]?: boolean }
