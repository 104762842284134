import { useMeshQuery } from '../../../apollo/clientHooks'
import {
  ListTempTakedownOverridesDocument,
  type ListTempTakedownOverridesQuery,
  type ListTempTakedownOverridesQueryVariables,
} from '../../../../__codegen__/api'

export const useTempTakedownOverrides = (props: ListTempTakedownOverridesQueryVariables) => {
  const variables = {
    ...props,
  }

  const { data, error, loading, refetch } = useMeshQuery<
    ListTempTakedownOverridesQuery,
    ListTempTakedownOverridesQueryVariables
  >(ListTempTakedownOverridesDocument, {
    fetchPolicy: 'cache-and-network',
    variables,
  })

  return {
    data: data?.instacart_catalog_admin_v1_TempOverrideService_List,
    isLoading: loading,
    isError: error,
    refetch,
  }
}
