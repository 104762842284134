import React, { forwardRef, useMemo, type ButtonHTMLAttributes } from 'react'
import { css } from '@emotion/react'
import { borderRadius } from '../../foundation/borderRadius'
import { fontWeight } from '../../foundation/fontWeight'
import { fontSize } from '../../foundation/fontSize'
import { colors } from '../../foundation/colors'
import { useDashMessage } from '../../../intl/intl.hooks'
import { type DashMessages } from '../../../intl/intl.types'
import { type TestID } from '../../utils/testing/types'
import { type EmotionStyles } from '../../utils/styling/types'
import { spacing } from '../../foundation/spacing'

export interface ButtonProps
  extends EmotionStyles,
    TestID,
    ButtonHTMLAttributes<HTMLButtonElement> {
  messageId?: DashMessages
  loading?: boolean
  inverted?: boolean
}

const buttonStyles = css({
  height: 38,
  paddingLeft: spacing.X20,
  paddingRight: spacing.X20,
  margin: spacing.X8,
  fontSize: fontSize.X15,
  fontWeight: fontWeight.SEMIBOLD,
  color: colors.GRAYSCALE.X0,
  backgroundColor: colors.PRIMARY.REGULAR,
  borderRadius: borderRadius.X8,
  width: 'fit-content',
  '&:hover': {
    backgroundColor: colors.PRIMARY.DARK,
  },
  '&:disabled': {
    backgroundColor: colors.GRAYSCALE.X50,
  },
})

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = 'button', messageId, disabled, loading, inverted, styles, children, ...rest }, ref) => {
    const message = useDashMessage(messageId)
    const content = messageId ? message : children
    const isDisabled = disabled || loading

    const dynamicStyles = useMemo(() => {
      if (inverted) {
        return css({
          color: colors.PRIMARY.REGULAR,
          backgroundColor: colors.GRAYSCALE.X0,
          borderColor: colors.PRIMARY.REGULAR,
          borderWidth: 1,
          borderStyle: 'solid',
          '&:hover': {
            color: colors.PRIMARY.EXTRA_DARK,
            backgroundColor: colors.GRAYSCALE.X0,
            borderColor: colors.PRIMARY.EXTRA_DARK,
          },
        })
      }
      return css({})
    }, [inverted])

    return (
      <button
        css={css(buttonStyles, dynamicStyles, styles)}
        ref={ref}
        disabled={isDisabled}
        type={type}
        {...rest}
      >
        {content}
      </button>
    )
  }
)
