export const NOTIFICATIONS_EN_US_TRANSLATIONS = {
  'notificationsDomain.list.bannerNotifications.pageTitle': 'Banner notifications',
  'notificationsDomain.list.bannerNotifications.pageDescription':
    'Manage your notifications relevant to your banner. The notification subscriptions below are on a banner-by-banner basis.',
  'notificationsDomain.list.platformNotifications.pageTitle': 'Platform notifications',
  'notificationsDomain.list.platformNotifications.pageDescription':
    'Manage your platform notifications. The notification subscriptions below are globally applied across all your banners.',
  'notificationsDomain.list.columnTitle.eventName': 'Event Name',
  'notificationsDomain.list.columnTitle.description': 'Description',
  'notificationsDomain.list.columnTitle.subscribe': 'Subscribe',
  'notificationsDomain.list.columnTitle.manageUsers': 'Manage Users',
  'notificationsDomain.list.cell.ineligible': 'Ineligible',
  'notificationsDomain.list.button.manageUsers': 'Manage Users',
  'notificationsDomain.list.noNotifications': 'No notification options available.',
  'notificationsDomain.modal.columnTitle.name': 'Name',
  'notificationsDomain.modal.columnTitle.email': 'Email',
  'notificationsDomain.modal.columnTitle.subscribe': 'Subscribe',
  'notificationsDomain.modal.cell.ineligible': 'Ineligible',
  'notificationsDomain.modal.list100Max':
    'List truncated to 100 users. Use email search to find more users.',
  'notificationsDomain.noUsersFound': 'No users found',
  'notificationsDomain.list.filter.eligiblePlaceholder': 'Eligibility Filter',
  'notificationsDomain.list.filter.allEligible': 'All',
  'notificationsDomain.list.filter.eligible': 'Eligible',
  'notificationsDomain.list.filter.ineligible': 'Ineligible',
  'notificationsDomain.list.filter.subscribedPlaceholder': 'Subscribed Filter',
  'notificationsDomain.list.filter.allSubscriptions': 'All',
  'notificationsDomain.list.filter.subscribed': 'Subscribed',
  'notificationsDomain.list.filter.unsubscribed': 'Unsubscribed',
  'notificationsDomain.modal.search.placeholder': 'Search Email',
  'notificationsDomain.modal.button.close': 'Close',
  'notificationsDomain.modal.message.update.success': 'Update successful',
  'notificationsDomain.modal.message.update.failed': 'Update failed',
} as const

export default NOTIFICATIONS_EN_US_TRANSLATIONS
