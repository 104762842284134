import React from 'react'
import styled from '@emotion/styled'
import { useDashMessage, useDashMessages } from '../../../intl/intl.hooks'
import SelectBanner from './assets/SelectBanner.svg'
import { ViewType } from './RetailerScopeWrapper'

const EmptyBackground = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})

const SubImageText = styled.div({
  marginTop: '5px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
})

export const BannerSelectRequired = ({ selectionType }: { selectionType: ViewType }) => {
  const messages = useDashMessages({
    selectBanner: 'retailerPicker.emptyWindowSelectBanner',
    selectBannerAndSite: 'retailerPicker.emptyWindowSelectBannerAndSite',
  })

  const infoText =
    selectionType == ViewType.RETAILER ? messages.selectBanner : messages.selectBannerAndSite

  return (
    <EmptyBackground>
      <div css={{ marginTop: -200 }}>
        <img css={{ width: 200 }} src={SelectBanner} alt={infoText} />
      </div>
      <SubImageText>{infoText}</SubImageText>
    </EmptyBackground>
  )
}
