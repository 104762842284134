const EN_US = {
  'storefrontDomain.add': 'Add',
  'storefrontDomain.cancel': 'Cancel',
  'storefrontDomain.all': 'All',
  'storefrontDomain.layouts': 'Layouts',
  'storefrontDomain.next': 'Next',
  'storefrontDomain.save': 'Save',
  'storefrontDomain.saveAndNext': 'Save & Next',
  'storefrontDomain.edit': 'Edit',
  'storefrontDomain.set-active': 'Set Active',
  'storefrontDomain.duplicate': 'Duplicate',
  'storefrontDomain.delete': 'Delete',
  'storefrontDomain.archive': 'Archive',
  'storefrontDomain.unarchive': 'Unarchive',
  'storefrontDomain.error': 'Error',
  'storefrontDomain.active': 'Active',
  'storefrontDomain.inactive': 'Inactive',
  'storefrontDomain.error-generic-message': `There was a problem saving your changes. Please try again.`,
  'storefrontDomain.layouts-list.columns.name.header': 'Page Name',
  'storefrontDomain.layouts-list.columns.platform.header': 'Platform',
  'storefrontDomain.layouts-list.columns.id.header': 'Page ID',
  'storefrontDomain.layouts-list.columns.createdOn.header': 'Created On',
  'storefrontDomain.layouts-list.columns.archivedOn.header': 'Archived On',
  'storefrontDomain.layouts-list.columns.updatedOn.header': 'Updated On',
  'storefrontDomain.layouts-list.columns.status.header': 'Status',
  'storefrontDomain.layouts-list.filters.name.placeholder': 'Search By Name',
  'storefrontDomain.layouts-list.filters.platform.placeholder': 'Platform',
  'storefrontDomain.layouts-list.filters.status.placeholder': 'Status',
  'storefrontDomain.layouts-list.filters.button.clear': 'Clear',
  'storefrontDomain.layouts-list.column.status.active': 'Active',
  'storefrontDomain.layouts-list.column.status.draft': 'Draft',
  'storefrontDomain.layouts-list.column.status.archived': 'Archived',
  'storefrontDomain.layouts-list.columns.site.header': 'Site',
  'storefrontDomain.layouts-list.columns.banners.header': 'Banners',

  'storefrontDomain.btn.create-new-layout': 'Create New Page',
  'storefrontDomain.components.add-component-modal.title': 'Add a component',
  'storefrontDomain.components.add-component-modal.description':
    'Select the component you would like to add to your layout. You may also search for a specific component.',
  'storefrontDomain.components.add-component-modal.search-input-placeholder': 'Search component',

  'storefrontDomain.autosuggest-links-list.page-title': 'Keyword redirects',
  'storefrontDomain.autosuggest-links-list.title': 'Create a keyword redirect',
  'storefrontDomain.autosuggest-links-list.create-button': 'Create New',
  'storefrontDomain.autosuggest-links-list.getStarted-button': 'Get Started',
  'storefrontDomain.autosuggest-links-list.emptyBodyText1':
    'Keyword redirects allow you to surface custom links alongside autocomplete results in Instacart search. Perfect for highlighting current deals and popular items.',
  'storefrontDomain.autosuggest-links-list.emptyBodyText2':
    'When a customer searches specific keywords or phrases, your redirect will appear as the first search result. From there, you control how the keyword redirect appears, with a custom title, subtitle and image to set it apart from other autocomplete items. <a>Read documentation</a>',

  'storefrontDomain.autosuggest-links-new.document-title': 'New Keyword Redirect',
  'storefrontDomain.autosuggest-links-edit.document-title': 'Edit Keyword Redirect',

  'storefrontDomain.autosuggest-links-upsert.loading-error':
    'Failed loading link autosuggestion: {error}',
  'storefrontDomain.autosuggest-links-upsert.autosuggestion-not-found':
    'No link autosuggestion with id {linkId} found',
  'storefrontDomain.autosuggest-links-upsert.unknown-error': 'Unknown error',
  'storefrontDomain.autosuggest-links-view.error': 'An error has occured. Please try again',
  'storefrontDomain.autosuggest-links-list.error': 'An error has occured. Please retry',
  'storefrontDomain.autosuggest-links-list.error.retry-button': 'Retry',

  'storefrontDomain.autosuggest-links.toasts.autosuggestion-created-successfully':
    '{name} redirect published.',
  'storefrontDomain.autosuggest-links.toasts.autosuggestion-created-successfully-ongoing':
    '{name} redirect published. It will appear on your site now',
  'storefrontDomain.autosuggest-links.toasts.autosuggestion-created-successfully-partial-date-range':
    '{name} redirect published. It will appear on your site on {startDate, date, medium}',
  'storefrontDomain.autosuggest-links.toasts.autosuggestion-created-successfully-full-date-range':
    '{name} redirect published.  It will appear on your site from {startDate, date, medium} to {endDate, date, medium}',

  'storefrontDomain.autosuggest-links.toasts.failed-creating-autosuggestion':
    '{name} could not be published, {error}',
  'storefrontDomain.autosuggest-links-upsert-form.details-title': 'Details',
  'storefrontDomain.autosuggest-links-upsert-form.content-title': 'Autocomplete content',
  'storefrontDomain.autosuggest-links-upsert-form.content-subtitle':
    'This content appears in the autocomplete results for customers searching the keywords and phrases added above.',

  'storefrontDomain.autosuggest-links-upsert-form.preview-component.title': 'Preview',
  'storefrontDomain.autosuggest-links-upsert-form.name-title': 'Redirect name',
  'storefrontDomain.autosuggest-links-upsert-form.name-description':
    'Choose a name that’s easily recognized among other redirects.',
  'storefrontDomain.autosuggest-links-upsert-form.effective-dates-title': 'Effective dates',
  'storefrontDomain.autosuggest-links-upsert-form.effective-dates-ongoing': 'Ongoing',
  'storefrontDomain.autosuggest-links-upsert-form.effective-dates-ongoing-description':
    'Today ({today}) - No end date',
  'storefrontDomain.autosuggest-links-upsert-form.effective-dates-date-range': 'Custom dates',
  'storefrontDomain.autosuggest-links-upsert-form.date-range-selection.clear-end-date':
    'Clear end date',
  'storefrontDomain.autosuggest-links-upsert-form.date-range-selection.placeholder':
    'Select dates...',
  'storefrontDomain.autosuggest-links-upsert-form.url-title': 'Redirect URL',
  'storefrontDomain.autosuggest-links-upsert-form.url-description':
    'Note: External links open in new tab.',
  'storefrontDomain.autosuggest-links-upsert-form.redirect-type-title': 'Redirect type',
  'storefrontDomain.autosuggest-links-upsert-form.redirect-type-description':
    'Choose how the customer should interact with your redirect.',
  'storefrontDomain.autosuggest-links-upsert-form.redirect-type-soft': 'Suggested redirect',
  'storefrontDomain.autosuggest-links-upsert-form.redirect-type-soft-description':
    'Show keyword redirect when the search input is a prefix of any of the keywords.',
  'storefrontDomain.autosuggest-links-upsert-form.redirect-type-hard': 'Hard redirect',
  'storefrontDomain.autosuggest-links-upsert-form.redirect-type-hard-description':
    'Require an exact keyword match for the keyword redirect to appear.',
  'storefrontDomain.autosuggest-links-upsert-form.keywords-title': 'Keywords',
  'storefrontDomain.autosuggest-links-upsert-form.keywords-description':
    'Separate keywords with a comma. Instacart already accounts for partial words.',
  'storefrontDomain.autosuggest-links-upsert-form.keywords-hint':
    '{currentNumberOfKeywords}/{maxNumberOfKeywords} keywords',
  'storefrontDomain.autosuggest-links-upsert-form.title-title': 'Title',
  'storefrontDomain.autosuggest-links-upsert-form.title-description':
    'e.g., 2 for 1 Tortilla chips',
  'storefrontDomain.autosuggest-links-upsert-form.subtitle-title': 'Subtitle',
  'storefrontDomain.autosuggest-links-upsert-form.subtitle-description':
    'e.g., Get them while they last!',
  'storefrontDomain.autosuggest-links-upsert-form.thumbnail-title': 'Thumbnail (optional)',
  'storefrontDomain.autosuggest-links-upsert-form.thumbnail-description':
    'Images must adhere to a 1:1 ratio, have a minimum size of 40x40 pixels, and be in PNG or JPEG format.',
  'storefrontDomain.autosuggest-links-upsert-form.thumbnail-add-action': 'add image',
  'storefrontDomain.autosuggest-links-upsert-form.thumbnail-remove-action': 'remove',
  'storefrontDomain.autosuggest-links-upsert-form.thumbnail-file-type-error':
    'Only png and jpeg allowed',
  'storefrontDomain.autosuggest-links-upsert-form.thumbnail-upload-error':
    'Failed uploading image to filestack',
  'storefrontDomain.autosuggest-links-upsert-form.thumbnail-unknown-error':
    'Unknown error, try again soon',

  'storefrontDomain.autosuggest-links-upsert-form.submit-warning':
    'This change will apply instantly to your production site.',
  'storefrontDomain.autosuggest-links-upsert-form.cancel-button': 'Cancel',
  'storefrontDomain.autosuggest-links-upsert-form.submit-button': 'Publish',
  // Delete
  'storefrontDomain.autosuggest-links.delete-modal.header': 'Delete keyword redirect',
  'storefrontDomain.autosuggest-links.delete-modal.redirect-name': 'Redirect name',
  'storefrontDomain.autosuggest-links.delete-modal.keywords': 'Keywords',
  'storefrontDomain.autosuggest-links.delete-modal.effective-dates': 'Effective dates',
  'storefrontDomain.autosuggest-links.delete-modal.status': 'Status',
  'storefrontDomain.autosuggest-links.delete-modal.confirmation-checkbox':
    'Yes, delete this redirect.',
  'storefrontDomain.autosuggest-links.delete-modal.cancel': 'Cancel',
  'storefrontDomain.autosuggest-links.delete-modal.delete': 'Delete',
  'storefrontDomain.autosuggest-links.toasts.autosuggestion-deleted-successfully':
    '{name} redirect deleted.',
  'storefrontDomain.autosuggest-links.toasts.autosuggestion-deleted-failed':
    '{name} redirect delete failed.',
  // View
  'storefrontDomain.autosuggest-links-view.page-title': 'Keyword redirects',
  'storefrontDomain.autosuggest-links-view.delete-button': 'Delete',
  'storefrontDomain.autosuggest-links-view.edit-button': 'Edit',
  'storefrontDomain.autosuggest-links-view.list-button': 'Go back to view all autosuggestions',
  'storefrontDomain.autosuggest-links-view.first-card-title': 'Details',
  'storefrontDomain.autosuggest-links-view.second-card-title': 'Autosuggest content',
  'storefrontDomain.autosuggest-links-view.redirectName': 'Redirect name',
  'storefrontDomain.autosuggest-links-view.keywords': 'Keywords',
  'storefrontDomain.autosuggest-links-view.url': 'URL',
  'storefrontDomain.autosuggest-links-view.title': 'Title',
  'storefrontDomain.autosuggest-links-view.subtitle': 'Subtitle',
  'storefrontDomain.autosuggest-links-view.thumbnail': 'Thumbnail',
  'storefrontDomain.autosuggest-links-view.effective-dates': 'Effective dates',
  'storefrontDomain.autosuggest-links-view.redirect-type': 'Redirect type',
  'storefrontDomain.autosuggest-links-view.hard-redirect': 'Hard redirect',
  'storefrontDomain.autosuggest-links-view.soft-redirect': 'Suggested redirect',
  'storefrontDomain.autosuggest-links-view.autosuggestion-not-found':
    'No link autosuggestion with id {linkId} found',
  'storefrontDomain.autosuggest-links-list.filter.no-status': 'All status',
  'storefrontDomain.autosuggest-links-list.filter.clear': 'Clear',
  'storefrontDomain.autosuggest-links-list.filter.published': 'Published',
  'storefrontDomain.autosuggest-links-list.filter.scheduled': 'Scheduled',
  'storefrontDomain.autosuggest-links-list.filter.expired': 'Expired',

  // List
  'storefrontDomain.list.columns.name.header': 'Redirect name',
  'storefrontDomain.list.columns.content.header': 'Keywords',
  'storefrontDomain.list.columns.effectiveDates.header': 'Effective dates',
  'storefrontDomain.list.columns.status.header': 'Status',
  'storefrontDomain.list.columns.lastUpdated.header': 'Last Updated',
  'storefrontDomain.list.columns.title': 'Keyword Redirects',
  'storefrontDomain.list.columns.subtitle':
    'Show deals and other popular items in the search bar when customers enter specific keywords.',
  'storefrontDomain.list.columns.learnMore': 'Learn more',

  'storefrontDomain.autosuggest-links-upsert-form.cancel-confirmation-modal.header':
    'Do you want to cancel?',
  'storefrontDomain.autosuggest-links-upsert-form.cancel-confirmation-modal.body':
    'All changes will be deleted.',
  'storefrontDomain.autosuggest-links-upsert-form.cancel-confirmation-modal.abort':
    'No, back to edit',
  'storefrontDomain.autosuggest-links-upsert-form.cancel-confirmation-modal.confirm': 'Yes, cancel',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.header':
    'Multiple hard redirect keywords warning',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.description':
    'At least one keyword is already linked to another hard redirect. A keyword can only be associated with one hard redirect.',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.close': 'Edit conflict',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.error':
    'Failed loading redirects, {error}',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.retry': 'Retry',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.keywords-header':
    'Keywords',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.redirects-headers':
    'Redirect',
  'storefrontDomain.autosuggest-links-upsert-form.keyword-collision-modal.effective-dates-header':
    'Effective Dates',

  // Platforms
  'storefrontDomain.MOBILE': 'Mobile Web',
  'storefrontDomain.DESKTOP': 'Desktop',
  'storefrontDomain.WEB': 'Web',
  'storefrontDomain.IOS': 'iOS',
  'storefrontDomain.ANDROID': 'Android',

  // layout wizard step 3: layout confirm
  'storefrontDomain.layout-confirm.section-title': 'Confirm Details',
  'storefrontDomain.layout-confirm.back-to-layout': 'Back to Layout Builder',
  'storefrontDomain.layout-confirm.layout-name-label': 'Name',
  'storefrontDomain.layout-confirm.storefront-platform-label': 'Storefront Platform',
  'storefrontDomain.layout-confirm.storefront-platform-description':
    'Designate which platform this collection will display on',
  'storefrontDomain.layout-confirm.footer-info':
    'Additional information can be included here about this wizard',
  'storefrontDomain.layout-confirm.preview': 'Preview',
  'storefrontDomain.layout-confirm.launch-preview': 'Launch Preview',
  'storefrontDomain.layout-confirm.launch-live': 'Launch Live',

  // layout wizard step 2: layout preview
  'storefrontDomain.layout-preview.section-title': 'Layout preview',
  'storefrontDomain.layouts-create.add-component-modal-button': 'Add component', // TODO rename

  // component types
  'storefrontDomain.components.add-component-modal.hero-banner-name': 'Hero banner',
  'storefrontDomain.components.add-component-modal.hero-banner-legacy-name': 'Hero banner carousel',
  'storefrontDomain.components.add-component-modal.homepage-banners-name': 'Homepage banners',
  'storefrontDomain.components.add-component-modal.image-carousel-name': 'Image carousel',
  'storefrontDomain.components.add-component-modal.tile-carousel-name': 'Tile carousel',
  'storefrontDomain.components.add-component-modal.quick-links-name': 'Quick links',
  'storefrontDomain.components.add-component-modal.quick-links-legacy-name': 'Quick links (legacy)',
  'storefrontDomain.components.add-component-modal.order-history-bar-name': 'Order history bar',
  'storefrontDomain.components.add-component-modal.store-details-row-name': 'Store details row',
  'storefrontDomain.components.add-component-modal.rich-text-banner-name': 'Rich text container',
  'storefrontDomain.components.add-component-modal.item-list-name': 'Product collection',
  'storefrontDomain.components.add-component-modal.item-list-buy-it-again-name':
    'Buy it again collection',
  'storefrontDomain.components.add-component-modal.item-list-featured-name':
    'Sponsored product ads collection',
  'storefrontDomain.components.add-component-modal.department-card-list-name':
    'Department card list',
  'storefrontDomain.components.add-component-modal.ways-to-shop-name': 'Ways to shop',
  'storefrontDomain.components.add-component-modal.list-nav-name': 'List nav',

  'storefrontDomain.components.add-component-modal.hero-banner-description':
    'Below is a brief overview of the the best use of a Hero banner. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.hero-banner-legacy-description':
    'Below is a brief overview of the the best use of a Hero banner carousel. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.homepage-banners-description':
    'Below is a brief overview of the the best use of a Homepage banners. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.image-carousel-description':
    'Below is a brief overview of the the best use of a Image carousel. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.tile-carousel-description':
    'Below is a brief overview of the the best use of a Tile carousel. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.quick-links-description':
    'Below is a brief overview of the the best use of a Quick links. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.quick-links-legacy-description':
    'Below is a brief overview of the the best use of a Quick links (legacy). With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.order-history-bar-description':
    'Below is a brief overview of the the best use of a Order history bar. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.store-details-row-description':
    'Below is a brief overview of the the best use of a Store details row. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.rich-text-banner-description':
    'Below is a brief overview of the the best use of a Rich text container. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.item-list-description':
    'Below is a brief overview of the the best use of a Product collection. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.item-list-buy-it-again-description':
    'Below is a brief overview of the the best use of a Buy it again collection. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.item-list-featured-description':
    'Below is a brief overview of the the best use of a Featured product collection. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.department-card-list-description':
    'Below is a brief overview of the the best use of a Department card list. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.ways-to-shop-description':
    'Below is a brief overview of the the best use of a Ways to shop. With multiple placement options and suggestions for content. ',
  'storefrontDomain.components.add-component-modal.list-nav-description':
    'Below is a brief overview of the the best use of a List nav. With multiple placement options and suggestions for content. ',

  // navigation page
  'storefrontDomain.navigation.page-title': 'Navigation',
  'storefrontDomain.navigation.navigation.title': 'Navigation',
  'storefrontDomain.navigation.publish-bar.publish': 'Publish',
  'storefrontDomain.navigation.publish-bar.publish.first-time': 'Create',
  'storefrontDomain.navigation.publish-bar.cancel': 'Cancel',
  'storefrontDomain.navigation.publish-bar.preview': 'Preview',
  'storefrontDomain.navigation.publish-bar.disclaimer': `You made some changes, once you publish the change, it will appear on your storefront immediately`,
  'storefrontDomain.navigation.publish-bar.disclaimer.first-time': `Your navigation is not yet created yet, click create to enable for your storefront`,
  'storefrontDomain.navigation.link-table.header.add-link': 'Add new',
  'storefrontDomain.navigation.link-table.col-title.order': 'Order',
  'storefrontDomain.navigation.link-table.col-title.display-name': 'Display name',
  'storefrontDomain.navigation.link-table.col-title.destination': 'Destination',
  'storefrontDomain.navigation.link-table.row-button.up.aria-label': 'up',
  'storefrontDomain.navigation.link-table.row-button.down.aria-label': 'down',
  'storefrontDomain.navigation.link-table.row-button.edit.aria-label': 'edit',
  'storefrontDomain.navigation.link-table.row-button.delete.aria-label': 'delete',
  'storefrontDomain.navigation.link-table.row-button.add.aria-label': 'add',
  'storefrontDomain.navigation.link-table.expand.aria-label': 'expand',
  'storefrontDomain.navigation.link-table.options.aria-label': 'options',
  'storefrontDomain.navigation.link-table.empty': 'No {group} added',
  'storefrontDomain.navigation.link-table.readDocumentation': 'Read documentation',
  'storefrontDomain.navigation.header.newTooling':
    'Head over to our new navigation tool to make any updates to your existing menus.',
  'storefrontDomain.navigation.header.newDWebHeaderTooling':
    'Desktop header tooling is coming soon.',
  'storefrontDomain.navigation.header.newTabBarTooling':
    'Head over to new navigation tool for native tab bar management.',
  'storefrontDomain.navigation.header.newToolingCTA': 'New navigation',
  'storefrontDomain.navigation.header.title': 'Header',
  'storefrontDomain.navigation.header.description': `Manage the global navigation for your desktop header menu, mobile web hamburger menu and native app more menu.`,
  'storefrontDomain.navigation.header.link-group.primary.name': 'Primary',
  'storefrontDomain.navigation.header.link-group.primary.name-full': 'Primary links',
  'storefrontDomain.navigation.header.link-group.primary.description': `You can have up to two custom primary links. These should be the most important and frequently used links on your storefront. {readDocumentationURL}`,
  'storefrontDomain.navigation.header.link-group.primary.edit-title': `Edit primary link`,
  'storefrontDomain.navigation.header.link-group.primary.add-title': `Add a primary link`,
  'storefrontDomain.navigation.header.link-group.primary.delete-title': `Delete primary link`,
  'storefrontDomain.navigation.header.link-group.primary.add': `Add new primary link`,
  'storefrontDomain.navigation.header.link-group.secondary.name': 'Secondary',
  'storefrontDomain.navigation.header.link-group.secondary.name-full': 'Secondary links',
  'storefrontDomain.navigation.header.link-group.secondary.description': `Secondary links are top links for your storefront that you want to appear on your main navigation. You can add regular links and nested menus. The links appear on desktop header and overflow into a "more" menu if the width screen is limited. On mobile app, the links are in the more tab. If you have over 10 links, consider using the footer/tertiary links.  {readDocumentationURL}`,
  'storefrontDomain.navigation.header.link-group.secondary.edit-title': `Edit secondary link`,
  'storefrontDomain.navigation.header.link-group.secondary.add-title': `Add a secondary link`,
  'storefrontDomain.navigation.header.link-group.secondary.delete-title': `Delete secondary link`,
  'storefrontDomain.navigation.header.link-group.secondary.add': `Add new secondary link`,
  'storefrontDomain.navigation.header.link-group.none.name': `Featured`,
  'storefrontDomain.navigation.header.link-group.none.name-full': `Featured links`,
  'storefrontDomain.navigation.header.link-group.none.description': `Featured links are visually distinct links in the main navigation that elevate specific actions above others. The goal of featured links is to increase engagement with specific sections of the site, and/or to make specific actions more discoverable. You can have up to five featured links.  {readDocumentationURL}`,
  'storefrontDomain.navigation.header.link-group.none.edit-title': `Edit featured link`,
  'storefrontDomain.navigation.header.link-group.none.add-title': `Add a featured link`,
  'storefrontDomain.navigation.header.link-group.none.delete-title': `Delete featured link`,
  'storefrontDomain.navigation.header.link-group.none.add': `Add new featured link`,
  'storefrontDomain.navigation.footer.title': 'Footer',
  'storefrontDomain.navigation.footer.mp.nav.title': 'Footer',
  'storefrontDomain.navigation.footer.mp.page.title': 'Footer',
  'storefrontDomain.navigation.footer.description': `Manage the footer links for your web and native app. {readDocumentationURL}`,
  'storefrontDomain.navigation.footer.mp.description': `Manage the global navigation for your landing pages.`,
  'storefrontDomain.navigation.footer.link-group.primary.name': 'Main footer',
  'storefrontDomain.navigation.footer.link-group.primary.name-full': 'Main footer links',
  'storefrontDomain.navigation.footer.link-group.primary.description': `Footer content shows on the footer on desktop and at the bottom of the mobile navigation list. Footer are sections so you need to create a section then add links to each section.`,
  'storefrontDomain.navigation.footer.link-group.primary.edit-title': `Edit main footer link`,
  'storefrontDomain.navigation.footer.link-group.primary.add-title': `Add a main footer link`,
  'storefrontDomain.navigation.footer.link-group.primary.delete-title': `Delete main footer link`,
  'storefrontDomain.navigation.footer.link-group.primary.add': `Add new main footer link`,
  'storefrontDomain.navigation.footer.link-group.secondary.name': 'Sub-footer',
  'storefrontDomain.navigation.footer.link-group.secondary.name-full': 'Sub-footer links',
  'storefrontDomain.navigation.footer.link-group.secondary.description': `Sub-footer content shows below the main footer links on desktop and in the "more" section of the mobile navigation.`,
  'storefrontDomain.navigation.footer.link-group.secondary.edit-title': `Edit sub-footer link`,
  'storefrontDomain.navigation.footer.link-group.secondary.add-title': `Add a sub-footer link`,
  'storefrontDomain.navigation.footer.link-group.secondary.delete-title': `Delete sub-footer link`,
  'storefrontDomain.navigation.footer.link-group.secondary.add': `Add new sub-footer link`,
  'storefrontDomain.navigation.footer.link-group.none.name': 'Disclaimer',
  'storefrontDomain.navigation.footer.link-group.none.name-full': 'Disclaimer',
  'storefrontDomain.navigation.footer.link-group.none.description': `Shown at the bottom of the footer.`,
  'storefrontDomain.navigation.footer.link-group.none.edit-title': `Edit disclaimer`,
  'storefrontDomain.navigation.footer.link-group.none.add-title': `Add disclaimer`,
  'storefrontDomain.navigation.footer.link-group.none.add': `Add site disclaimer`,
  'storefrontDomain.navigation.footer.link-group.none.delete-title': `Delete disclaimer`,
  'storefrontDomain.navigation.footer.mp.link-group.primary.name': 'Customers footer',
  'storefrontDomain.navigation.footer.mp.link-group.primary.name-full': 'Customers footer links',
  'storefrontDomain.navigation.footer.mp.link-group.primary.description': `Customers Footer content shows on the footer. Footer links are section based so you need to create a section then add links to each section.`,
  'storefrontDomain.navigation.footer.mp.link-group.primary.edit-title': `Edit customers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.primary.add-title': `Add a customers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.primary.delete-title': `Delete customers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.primary.add': `Add new customers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.secondary.name': 'Shoppers footer',
  'storefrontDomain.navigation.footer.mp.link-group.secondary.name-full': 'Shoppers footer links',
  'storefrontDomain.navigation.footer.mp.link-group.secondary.description': `Shoppers Footer content shows on the footer. Footer links are section based so you need to create a section then add links to each section.`,
  'storefrontDomain.navigation.footer.mp.link-group.secondary.edit-title': `Edit shoppers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.secondary.add-title': `Add a shoppers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.secondary.delete-title': `Delete shoppers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.secondary.add': `Add new shoppers footer link`,
  'storefrontDomain.navigation.footer.mp.link-group.tertiary.name': 'Misc footer',
  'storefrontDomain.navigation.footer.mp.link-group.tertiary.name-full': 'Misc footer',
  'storefrontDomain.navigation.footer.mp.link-group.tertiary.description': `Links in the legal section at the bottom of the footer.`,
  'storefrontDomain.navigation.footer.mp.link-group.tertiary.edit-title': `Edit misc footer`,
  'storefrontDomain.navigation.footer.mp.link-group.tertiary.add-title': `Add misc footer`,
  'storefrontDomain.navigation.footer.mp.link-group.tertiary.delete-title': `Delete misc footer`,
  'storefrontDomain.navigation.footer.mp.link-group.tertiary.add': `Add misc footer`,
  'storefrontDomain.navigation.item-type.link.name': `Link`,
  'storefrontDomain.navigation.item-type.link.description': `Regular links show up on the navigation bar as a link`,
  'storefrontDomain.navigation.item-type.nested.name': `Nested menu`,
  'storefrontDomain.navigation.item-type.nested.description': `Nested menu is a dropdown on desktop and nested navigation on mobile. You can add additional links in this menu.`,
  'storefrontDomain.navigation.item-type.nested-link.name': `Nested link`,
  'storefrontDomain.navigation.item-type.nested-link.description': `Link nested in a menu.`,
  'storefrontDomain.navigation.item-type.text.name': `Text`,
  'storefrontDomain.navigation.item-type.text.description': `Plain old text.`,
  'storefrontDomain.navigation.item-wizard.title.create': `Add new navigation item`,
  'storefrontDomain.navigation.item-wizard.title.edit': `Edit navigation link`,
  'storefrontDomain.navigation.item-wizard.item-group.label': `Item group`,
  'storefrontDomain.navigation.item-wizard.submit': `Save`,
  'storefrontDomain.navigation.item-wizard.cancel': `Cancel`,
  'storefrontDomain.navigation.item-wizard.display-name.label': `Display name`,
  'storefrontDomain.navigation.item-wizard.display-name.error': `Please enter a valid display name`,
  'storefrontDomain.navigation.item-wizard.destination.label': `Destination`,
  'storefrontDomain.navigation.item-wizard.item-type.label': `Item type`,
  'storefrontDomain.navigation.item-wizard.parent-menu.label': `Parent menu`,
  'storefrontDomain.navigation.item-wizard.parent-menu.error': `Please select a parent menu`,
  'storefrontDomain.navigation.item-wizard.icon.label': `Icon`,
  'storefrontDomain.navigation.action-type.navigate-to-url.title': `URL`,
  'storefrontDomain.navigation.action-type.navigate-to-collection.title': `Collection`,
  'storefrontDomain.navigation.action-type.navigate-to-shop-page.title': `Shop page`,
  'storefrontDomain.navigation.action-type.navigate-to-content-page.title': `Content page`,
  'storefrontDomain.navigation.action-type.open-content-page-flyout.title': `Fly out`,
  'storefrontDomain.navigation.action-data.url.label': `URL`,
  'storefrontDomain.navigation.action-data.url.placeholder': `https://...`,
  'storefrontDomain.navigation.action-data.url.error': `Please enter a valid url`,
  'storefrontDomain.navigation.action-data.group-position.label': `Group Position`,
  'storefrontDomain.navigation.action-data.group-position.error': `Please select a valid location`,
  'storefrontDomain.navigation.action-data.cta-variant.label': `Featured link variant`,
  'storefrontDomain.navigation.action-data.cta-variant.error': `Please select a variant`,
  'storefrontDomain.navigation.action-data.cta-image.label': `Featured link image`,
  'storefrontDomain.navigation.action-data.cta-image.error': `Please upload an image`,
  'storefrontDomain.navigation.action-data.open-in.label': `Open in`,
  'storefrontDomain.navigation.action-data.open-in-new-tab.label': `New tab`,
  'storefrontDomain.navigation.action-data.open-in-new-window.label': `New window`,
  'storefrontDomain.navigation.action-data.default.label': `Same window`,
  'storefrontDomain.navigation.action-data.collection-slug.label': 'Collection',
  'storefrontDomain.navigation.action-data.collection-slug.error': `Please select a collection`,
  'storefrontDomain.navigation.action-data.shop-slug.label': 'Shop page',
  'storefrontDomain.navigation.action-data.shop-slug.error': `Please select a valid page`,
  'storefrontDomain.navigation.action-data.content-slug.label': 'Custom page',
  'storefrontDomain.navigation.action-data.flyout-slug.label': 'Fly out page',
  'storefrontDomain.navigation.action-data.content-slug.error': `Please select a valid page`,
  'storefrontDomain.navigation.delete-confirmation.title': `Delete {groupTitle}`,
  'storefrontDomain.navigation.delete-confirmation.submit': 'Delete',
  'storefrontDomain.navigation.delete-confirmation.message': `Are you sure you want to delete this item?`,
  'storefrontDomain.navigation.delete-confirmation.cancel': 'Cancel',
  'storefrontDomain.navigation.delete-confirmation.type': 'Type',
  'storefrontDomain.navigation.delete-confirmation.display-name': 'Display name',
  'storefrontDomain.navigation.delete-confirmation.destination': 'Destination',
  'storefrontDomain.navigation.row-options-menu.edit': 'Edit',
  'storefrontDomain.navigation.row-options-menu.delete': 'Delete',
  'storefrontDomain.navigation.row-options-menu.description': 'options',
  'storefrontDomain.navigation.nested-row.add': 'Add new link',
  'storefrontDomain.navigation.nested.link-count': `{count} {count, plural, one {link} other {links}}`,
  'storefrontDomain.navigation.publish-error': `We were unable to publish your changes. Please try again or contact Instacart support.`,
  'storefrontDomain.navigation.preview-error': `We were unable to preview your changes. Please try again or contact Instacart support.`,
  'storefrontDomain.navigation.no-items-error': `Navigation invalid, please ensure all nested items have at least one action.`,
  'storefrontDomain.navigation.section-title.edit-en': 'Edit in English',
  'storefrontDomain.navigation.section-title.edit-fr': 'Edit in French',
  'storefrontDomain.navigation.collections-dropdown.placeholder': 'Start typing a collection name…',

  // navigation collections (departments)
  'storefrontDomain.navigation.collections.effective-dates.interval': '{startDate} - {endDate}',
  'storefrontDomain.navigation.collections.effective-dates.ongoing': 'Ongoing',
  'storefrontDomain.navigation.collections.preview.parent-collection': 'Parent department',
  'storefrontDomain.navigation.collections.preview.tile-image': 'Tile Image',
  'storefrontDomain.navigation.collections.search.placeholder': 'Search departments',
  'storefrontDomain.navigation.collections.filter.description': 'department filters',
  'storefrontDomain.navigation.collections.filter.status': 'Status',
  'storefrontDomain.navigation.collections.filter.active': 'Active only',
  'storefrontDomain.navigation.collections.filter.effective': 'Effective only',
  'storefrontDomain.navigation.collections.filter.owner': 'Owner',
  'storefrontDomain.navigation.collections.table.actions.actions': 'Actions',
  'storefrontDomain.navigation.collections.table.actions.delete': 'Delete',
  'storefrontDomain.navigation.collections.table.actions.duplicate': 'Copy to site',
  'storefrontDomain.navigation.collections.table.actions.edit': 'Edit',
  'storefrontDomain.navigation.collections.table.actions.set-active': 'Set active',
  'storefrontDomain.navigation.collections.table.actions.set-inactive': 'Set inactive',
  'storefrontDomain.navigation.collections.table.actions.view-in-full-detail': `View in full detail`,
  'storefrontDomain.navigation.collections.table.actions.top': 'Send to top',
  'storefrontDomain.navigation.collections.table.actions.bottom': 'Send to bottom',
  'storefrontDomain.navigation.collections.table.empty-collection': 'This department is empty',
  'storefrontDomain.navigation.collections.table.empty-collection.description': `Add a department`,
  'storefrontDomain.navigation.collections.table.header.all': 'All',
  'storefrontDomain.navigation.collections.table.header.effective-dates': 'Effective Dates',
  'storefrontDomain.navigation.collections.table.header.name': 'Name',
  'storefrontDomain.navigation.collections.table.header.products': 'Products',
  'storefrontDomain.navigation.collections.table.header.status': 'Status',
  'storefrontDomain.navigation.collections.table.header.top-level': 'Top Level Departments',
  'storefrontDomain.navigation.collections.table.header.top-level.description': `These are the top level departments on your storefront`,
  'storefrontDomain.navigation.collections.table.header.top-level.description.marketplace': `These are the top level departments on Marketplace displayed underneath any Instacart owned departments`,
  'storefrontDomain.navigation.collections.table.header.updated': 'Last Updated',
  'storefrontDomain.navigation.collections.table.header.name.mp-owned': `This is an Instacart owned collection (readonly)`,
  'storefrontDomain.navigation.collections.table.header.externally-managed': `Externally managed`,
  'storefrontDomain.navigation.collections.table.header.add.department': `Add Department or Collection`,
  'storefrontDomain.navigation.collections.table.header.add.collection': `Add Collection`,
  'storefrontDomain.navigation.collections.table.reordered': 'Reordered',
  'storefrontDomain.navigation.collections.table.dynamic': 'Dynamic',
  'storefrontDomain.navigation.collections.title': 'Departments',
  'storefrontDomain.navigation.collections.types.category': 'Department',
  'storefrontDomain.navigation.collections.types.product': 'Product collection',
  'storefrontDomain.navigation.collections.upsert-modal.add-category-collection': 'Add department',
  'storefrontDomain.navigation.collections.upsert-modal.add-product-collections': `Bulk add collections`,
  'storefrontDomain.navigation.collections.upsert-modal.add-product-collection': `Add collection`,
  'storefrontDomain.navigation.collections.upsert-modal.bulk-add-category-collection': `Bulk add departments`,
  'storefrontDomain.navigation.collections.upsert-modal.edit-category-collection': `Edit department`,
  'storefrontDomain.navigation.collections.upsert-modal.edit-product-collection': `Edit collection`,
  'storefrontDomain.navigation.collections.upsert-modal.duplicate-category-collection': `Copy department`,
  'storefrontDomain.navigation.collections.upsert-modal.duplicate-product-collection': `Duplicate collection`,
  'storefrontDomain.navigation.collections.upsert-modal.parent-category': 'Parent department',
  'storefrontDomain.navigation.collections.upsert-modal.parent-group': 'Parent department',
  'storefrontDomain.navigation.collections.upsert-modal.parent-category-description': `Select the parent department where you want this new department to be placed.`,
  'storefrontDomain.navigation.collections.upsert-modal.parent-group-description': `Select the parent department where you want this new department to be placed.`,
  'storefrontDomain.navigation.collections.upsert-modal.no-parent-category': `No parent department (This will be a top level department)`,
  'storefrontDomain.navigation.collections.upsert-modal.no-parent-group': `No parent department (This will be a L1 department)`,
  'storefrontDomain.navigation.collections.upsert-modal.effective-dates': 'Effective dates',
  'storefrontDomain.navigation.collections.upsert-modal.effective-dates-collection-description': `Schedule the time when the department is effective online.`,
  'storefrontDomain.navigation.collections.upsert-modal.effective-dates-group-description': `Schedule the time when the groups are effective online.`,
  'storefrontDomain.navigation.collections.upsert-modal.ongoing': 'Ongoing',
  'storefrontDomain.navigation.collections.upsert-modal.select-date-range': 'Select a date range',
  'storefrontDomain.navigation.collections.upsert-modal.name': 'Name',
  'storefrontDomain.navigation.collections.upsert-modal.collection-id': 'Department ID',
  'storefrontDomain.navigation.collections.upsert-modal.product-id': 'Product ID',
  'storefrontDomain.navigation.collections.upsert-modal.retailer-collection-id': `Collection ID`,
  'storefrontDomain.navigation.collections.upsert-modal.tile-image': 'Tile image',
  'storefrontDomain.navigation.collections.upsert-modal.tile-image.description': `Add an image to display on mobile apps.`,
  'storefrontDomain.navigation.collections.upsert-modal.tile-image.example': 'See example.',
  'storefrontDomain.navigation.collections.upsert-modal.tile-image.search': `Search product catalog`,
  'storefrontDomain.navigation.collections.upsert-modal.tile-image.search.product-name': `Search by product name`,
  'storefrontDomain.navigation.collections.upsert-modal.tile-image.search.no-results': `There were no products found.`,
  'storefrontDomain.navigation.collections.upsert-modal.tile-image.upload': 'Upload image',
  'storefrontDomain.navigation.collections.upsert-modal.optional': 'Optional',
  'storefrontDomain.navigation.collections.upsert-modal.update': 'Update',
  'storefrontDomain.navigation.collections.upsert-modal.duplicate': 'Duplicate',
  'storefrontDomain.navigation.collections.upsert-modal.names': 'Names',
  'storefrontDomain.navigation.collections.upsert-modal.names-description': `Add all of the department names, each on it's own line.`,
  'storefrontDomain.navigation.collections.upsert-modal.select-collections': `Select existing collections`,
  'storefrontDomain.navigation.collections.upsert-modal.create-new': 'Create new collection',
  'storefrontDomain.navigation.collections.upsert-modal.search-for-collection': `Search for a collection`,
  'storefrontDomain.navigation.collections.upsert-modal.collection': `Collection`,
  'storefrontDomain.navigation.collections.upsert-modal.collection-search-description': `Please search for an existing collection or create a new one`,
  'storefrontDomain.navigation.collections.upsert-modal.collection-name': 'Collection name',
  'storefrontDomain.navigation.collections.upsert-modal.products': 'Products',
  'storefrontDomain.navigation.collections.upsert-modal.detail.collection-detail': `Collection detail`,
  'storefrontDomain.navigation.collections.upsert-modal.detail.view-full-detail': `View full detail`,
  'storefrontDomain.navigation.collections.upsert-modal.detail.type': 'Type',
  'storefrontDomain.navigation.collections.upsert-modal.detail.dynamic': 'Dynamic',
  'storefrontDomain.navigation.collections.upsert-modal.detail.static': 'Manual',
  'storefrontDomain.navigation.collections.upsert-modal.always-show.label': `Always show department`,
  'storefrontDomain.navigation.collections.upsert-modal.always-show.description': `Ignore inventory counts and always show the department`,
  'storefrontDomain.navigation.collections.upsert-modal.status': `Status`,
  'storefrontDomain.navigation.collections.upsert-modal.errors.slug': `Please enter a valid department ID`,
  'storefrontDomain.navigation.collections.upsert-modal.errors.name': `Please enter a valid department name`,
  'storefrontDomain.navigation.collections.upsert-modal.errors.image': `Please select or upload an image`,
  'storefrontDomain.navigation.collections.upsert-modal.errors.collection': `Please select collection`,
  'storefrontDomain.navigation.collections.upsert-modal.max-message': `Max number of top level departments reached. Your deparment will be created as inactive.`,
  'storefrontDomain.navigation.collections.upsert-modal.alcoholic': `Alcohol only`,
  'storefrontDomain.navigation.collections.upsert-modal.alcoholic.description': `This department and its sub-departments contain only alcohol products.`,
  'storefrontDomain.navigation.collections.upsert-modal.department-info.label': `Department`,
  'storefrontDomain.navigation.collections.upsert-modal.store-config-selector.label': `Sites`,
  'storefrontDomain.navigation.collections.upsert-modal.store-config-selector.description': `Selected department and all child departments will be copied to the selected sites.`,
  'storefrontDomain.navigation.collections.upsert-modal.duplicate-success': `Success`,
  'storefrontDomain.navigation.collections.upsert-modal.duplicate-success.description': `Your department has been copied to the selected sites.`,
  'storefrontDomain.navigation.collections.table.empty-tree': `Department customization not enabled.`,
  'storefrontDomain.navigation.collections.table.empty-tree.description': `Please contact your Instacart representative to enable.`,
  'storefrontDomain.navigation.collections.table.error': `Oops, something went wrong.`,
  'storefrontDomain.navigation.collections.marketplace.max-nodes': `{count} active top level departments on Marketplace`,
  'storefrontDomain.navigation.collections.marketplace.admin.title': `Admin Settings`,
  'storefrontDomain.navigation.collections.marketplace.admin.max-nodes.label': `Max top level departments`,
  'storefrontDomain.navigation.collections.marketplace.admin.max-nodes.error': `Please enter a value between 3 and 80`,
  'storefrontDomain.navigation.collections.marketplace.admin.always-pin': `Pin all top level departments`,
  'storefrontDomain.navigation.collections.marketplace.admin.inv-opt-out': `Opt out of inventory counts`,
  'storefrontDomain.navigation.collections.marketplace.admin.source-domain': `Source domain`,
  'storefrontDomain.navigation.collections.cross-banner.primary.banner.message.part1': `The department list created for`,
  'storefrontDomain.navigation.collections.cross-banner.primary.banner.message.part2': `will be synced with all your`,
  'storefrontDomain.navigation.collections.cross-banner.primary.banner.message.part3': `banner in approximately 10 minutes after publishing changes.`,
  'storefrontDomain.navigation.collections.cross-banner.secondary.banner.message.view-only':
    'View only: this banner is inheriting departments from your main banner',
  'storefrontDomain.navigation.collections.cross-banner.secondary.banner.message.make-changes':
    '. To make changes to the departments or collections, head over to your main banner, make edits and hit Publish. Your changes will be reflected in this and other banners in approximately 10 mins.',
  'storefrontDomain.navigation.collections.cross-banner.secondary.banner.button.go-to-primary':
    'Go to main banner',

  // Brand settings page
  'storefrontDomain.brand-settings.page-title': 'Storefront branding',
  'storefrontDomain.brand-settings.tab-title': 'Brand Settings',
  'storefrontDomain.brand-settings.header': 'Storefront Branding',
  'storefrontDomain.brand-settings.header-v2': 'Theme Settings',
  'storefrontDomain.brand-settings.edit.header': 'Edit Theme',
  'storefrontDomain.brand-settings.editButton': 'Edit draft',
  'storefrontDomain.brand-settings.cancelButton': 'Cancel',
  'storefrontDomain.brand-settings.saveForLater': 'Save for later',
  'storefrontDomain.brand-settings.saveAndPreview': 'Save and preview',
  'storefrontDomain.brand-settings.saveButton': 'Save draft',
  'storefrontDomain.brand-settings.publishButton': 'Publish',
  'storefrontDomain.brand-settings.discardChange': 'Discard unsaved changes',
  'storefrontDomain.brand-settings.backToEdit': 'Back to editing',
  'storefrontDomain.brand-settings.cancelDraftModal.title': 'Cancel draft editing',
  'storefrontDomain.brand-settings.cancelDraftModal.body':
    'Are you sure you want to leave? You might lose unsaved change for the Storefront Theme',
  'storefrontDomain.brand-settings.fieldRequired': 'This is required',
  'storefrontDomain.failedLoadingConfig': 'There was an issue obtaining your store configuration.',
  'storefrontDomain.brand-settings.readErrorNotification':
    'We were unable to read your draft. Please try again or contact Instacart support.',
  'storefrontDomain.brand-settings.updateErrorNotification':
    'We were unable to update your draft. Please try again or contact Instacart support.',
  'storefrontDomain.brand-settings.deleteErrorNotification':
    'We were unable to delete your draft. Please try again or contact Instacart support.',
  'storefrontDomain.brand-settings.createErrorNotification':
    'We were unable to create your draft. Please try again or contact Instacart support.',
  'storefrontDomain.brand-settings.publishErrorNotification.title': 'Publish error',
  'storefrontDomain.brand-settings.publishErrorNotification':
    'We were unable to publish your draft. Please try again or contact Instacart support.',
  'storefrontDomain.brand-settings.previewErrorNotification.title': 'Preview error',
  'storefrontDomain.brand-settings.previewErrorNotification.body':
    'Unable to preview without an existing draft. Please create a draft before preview.',
  'storefrontDomain.brand-settings.publishSuccessNotification.title': 'Publish succeeded!',
  'storefrontDomain.brand-settings.publishSuccessNotification.body':
    'Your changes have been published.',
  'storefrontDomain.brand-settings.updateSuccessNotification.title': 'Save succeeded!',
  'storefrontDomain.brand-settings.updateSuccessNotification.body': 'Your changes have been saved.',
  'storefrontDomain.brand-settings.moveAssetsErrorNotification':
    'Move assets from filestack bucket failed.',
  'storefrontDomain.brand-settings.diffModal.draftChange': 'Draft changes',
  'storefrontDomain.brand-settings.diffModal.published': 'Published',
  'storefrontDomain.brand-settings.diffModal.newChange': 'New changes',
  // - Brand Color Configuration
  'storefrontDomain.brand-settings.brand-color.header': 'Brand color',
  'storefrontDomain.brand-settings.brand-color.description':
    'Brand colors are used to emphasize very specific aspects of your brands. When used with intention customers and shopper alike immediately recognize and understand their meaning.',
  'storefrontDomain.brand-settings.brand-color.invalidText':
    'Please enter the hex color code or choose from the color picker. Hex color codes start with # and are followed by six letters and/or numbers.',
  'storefrontDomain.brand-settings.brand-color.primary.name': 'Primary',
  'storefrontDomain.brand-settings.brand-color.primary.description':
    'Used for primary actions, ‘Add to Cart’ Button, links, and selections for checkbox and radio groups.',
  'storefrontDomain.brand-settings.brand-color.primary.label': 'Primary Color Hex',
  'storefrontDomain.brand-settings.brand-color.secondary.name': 'Secondary',
  'storefrontDomain.brand-settings.brand-color.secondary.description':
    'Used for most text and background applications. Secondary Dark is used for the third level navigation bar and the main body of the footer.',
  'storefrontDomain.brand-settings.brand-color.secondary.label': 'Secondary Color',
  'storefrontDomain.brand-settings.brand-color.promotional.name': 'Promotional',
  'storefrontDomain.brand-settings.brand-color.promotional.description':
    'Used for promotions, sales, discounts, deals, free items and offers. Do not use for referral bonus content.',
  'storefrontDomain.brand-settings.brand-color.promotional.label': 'Promotional Color Hex',
  'storefrontDomain.brand-settings.brand-color.loyalty.name': 'Loyalty',
  'storefrontDomain.brand-settings.brand-color.loyalty.description':
    'Used for retailer loyalty offerings like loyalty clubs, discount cards or special member pricing.',
  'storefrontDomain.brand-settings.brand-color.loyalty.label': 'Loyalty Color Hex',
  // - Radius Configuration
  'storefrontDomain.brand-settings.radius-config.header': 'Radius',
  'storefrontDomain.brand-settings.radius-config.pixelSuffix': ' px',
  'storefrontDomain.brand-settings.radius-config.invalidText':
    'Please enter the valid radius value.',
  'storefrontDomain.brand-settings.border-radius-config.label': 'Button and Input',

  // - Logo Assets
  'storefrontDomain.brand-settings.logo-assets.header': 'Logo assets',
  'storefrontDomain.brand-settings.logo-assets.logo-image.title': 'Logo image',
  'storefrontDomain.brand-settings.logo-assets.logo-image.description':
    'This logo displays on the desktop header',
  'storefrontDomain.brand-settings.logo-assets.logo-image.specs':
    'Size: H: 112px W: 360px, filetype: .svg, .png',
  'storefrontDomain.brand-settings.logo-assets.favicon.title': 'Favicon',
  'storefrontDomain.brand-settings.logo-assets.favicon.description':
    'This shows on the browser tab',
  'storefrontDomain.brand-settings.logo-assets.favicon.specs':
    'Size 48x48px, filetype: .png or .ico.',
  'storefrontDomain.brand-settings.logo-assets.appleTouchIcon.title': 'Apple Touch Icon',
  'storefrontDomain.brand-settings.logo-assets.appleTouchIcon.description':
    'This shows in several places including the home screen on iOS devices',
  'storefrontDomain.brand-settings.logo-assets.appleTouchIcon.specs':
    'Size 180x180px, filetype: .png',
  // Assets Uploader
  'storefrontDomain.brand-settings.assets-uploader.uploadText': 'Upload',
  'storefrontDomain.brand-settings.assets-uploader.uploadButton': 'Upload button',
  'storefrontDomain.brand-settings.assets-uploader.or': 'or',
  'storefrontDomain.brand-settings.assets-uploader.retry': 'retry',
  'storefrontDomain.brand-settings.assets-uploader.error': 'error',
  'storefrontDomain.brand-settings.assets-uploader.error.file-type-error': 'File type error',
  'storefrontDomain.brand-settings.assets-uploader.error.file-content-error': 'File content error',
  'storefrontDomain.brand-settings.assets-uploader.error.upload-error': 'Upload error',
  'storefrontDomain.brand-settings.assets-uploader.matching-specs':
    'The uploaded file does not match the specifications.',
  // - Brand Type
  'storefrontDomain.brand-settings.brand-type-config.header': 'Brand type',
  'storefrontDomain.brand-settings.brand-type-config.description':
    'Type styles are used to visualize the brand voice of your brands.',
  'storefrontDomain.brand-settings.brand-type-config.view-mode.display-type.title': 'Font',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.info':
    'Font file requirement. Should be .otf,.tff format. Typefaces need to support a bold, semibold and normal weight, they need to support both uppercase, lowercase, alphabet and numeral characters.',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.display-type.title': 'Font',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.web-type.upload-text':
    'Upload new web font file (WOFF, WOFF2)',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.mobile-type.upload-text':
    'Upload new mobile font file (OTF, TTF)',
  'storefrontDomain.brand-settings.brand-type-config.font-family': 'Font family',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.font-files.title':
    'Upload new font files',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.font-files.name': 'Font files name',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.font-files.bold': 'Bold',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.font-files.normal': 'Normal',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.font-files.semi-bold': 'Semi bold',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.font-files.existing-font-family-name':
    'Existing font family name',
  'storefrontDomain.brand-settings.brand-type-config.edit-mode.font-files.invalid-font-files-name':
    'Invalid font files name',
  // - Static Preview
  'storefrontDomain.brand-settings.static-preview.refreshButton': 'Refresh',
  'storefrontDomain.brand-settings.static-preview.header': 'Your theme',
  'storefrontDomain.brand-settings.static-preview.description':
    'See how your layout and component abstraction might be like for the storefront.',
  'storefrontDomain.brand-settings.static-preview.typography.large-title': 'Large title',
  'storefrontDomain.brand-settings.static-preview.typography.medium-title': 'Medium title',
  'storefrontDomain.brand-settings.static-preview.typography.large-subtitle': 'Large subtitle',
  'storefrontDomain.brand-settings.static-preview.typography.large-body':
    'Large body text, Pecorino fromage frais hard cheese.',
  'storefrontDomain.brand-settings.static-preview.typography.medium-subtitle': 'Medium subtitle',
  'storefrontDomain.brand-settings.static-preview.typography.medium-body':
    'Medium body text, Pecorino fromage frais hard cheese.',
  'storefrontDomain.brand-settings.static-preview.typography.small-subtitle': 'Small subtitle',
  'storefrontDomain.brand-settings.static-preview.typography.small-body':
    'Small body text, Pecorino fromage frais hard cheese. St. agur blue cheese mascarpone.',
  'storefrontDomain.brand-settings.static-preview.typography.link': 'This is a link',
  'storefrontDomain.brand-settings.static-preview.item-tile.product-name': 'Product name',
  'storefrontDomain.brand-settings.static-preview.item-tile.item-weight': 'item weight',
  'storefrontDomain.brand-settings.static-preview.item-tile.sponsored': 'Sponsored',
  'storefrontDomain.brand-settings.static-preview.item-tile.currency': '$',
  'storefrontDomain.brand-settings.static-preview.item-tile.dollar': '99',
  'storefrontDomain.brand-settings.static-preview.item-tile.cents': '99',
  'storefrontDomain.brand-settings.static-preview.item-tile.secondary-price-info': 'each (est.)',
  'storefrontDomain.brand-settings.static-preview.item-tile.promotion': 'Promotion',
  'storefrontDomain.brand-settings.static-preview.buttons.primary': 'Primary button',
  'storefrontDomain.brand-settings.static-preview.buttons.secondary': 'Secondary button',

  // Banner Logos page
  'storefrontDomain.banner-logo.page-title': 'Banner logos',
  'storefrontDomain.banner-logo.tab-title': 'Banner logos',
  'storefrontDomain.banner-logo.inherits': 'This banner is inheriting the site logo.',
  'storefrontDomain.banner-logo.preview.notAvailable': 'Preview Unavailable',
  'storefrontDomain.banner-logo.asset-specs.title': 'Asset specifications',
  'storefrontDomain.banner-logo.asset-specs.width': 'Recommended width: {number}px',
  'storefrontDomain.banner-logo.asset-specs.type': 'File type: .png',
  'storefrontDomain.banner-logo.asset-specs.size': 'Max size: {number}MB',
  'storefrontDomain.banner-logo.cards.site.title': 'Site logo',
  'storefrontDomain.banner-logo.cards.site.description':
    'This is your site logo. Every banner uses this logo as a default.',
  'storefrontDomain.banner-logo.cards.banner.title': 'Banner logos',
  'storefrontDomain.banner-logo.cards.banner.description':
    'You may override a different logo for each of the banners in your site.',
  'storefrontDomain.banner-logo.cards.sitePreview.title': 'Preview of your site logo',
  'storefrontDomain.banner-logo.cards.sitePreview.description':
    'This is how your site logos will look on different backgrounds.',
  'storefrontDomain.banner-logo.cards.bannerPreview.title': 'Preview of your banner logos',
  'storefrontDomain.banner-logo.cards.bannerPreview.description':
    'This is how your banner logos will look on different backgrounds.',
  'storefrontDomain.banner-logo.cards.defaultLogo': 'Default logo',
  'storefrontDomain.banner-logo.cards.optional': '(optional)',
  'storefrontDomain.banner-logo.variants.light': 'Light background',
  'storefrontDomain.banner-logo.variants.dark': 'Dark background',
  'storefrontDomain.banner-logo.variants.lightSquare': 'Light square background',
  'storefrontDomain.banner-logo.variants.darkSquare': 'Dark square background',
  'storefrontDomain.banner-logo.variants.lightColorHex': 'Light color fill',
  'storefrontDomain.banner-logo.variants.darkColorHex': 'Dark color fill',

  // Custom images page
  'storefrontDomain.custom-images.page-title': 'Custom Images',
  'storefrontDomain.custom-images.tab-title': 'Custom Images',
  'storefrontDomain.custom-images.order.title': 'Account order empty state',
  'storefrontDomain.custom-images.cart.title': 'Cart empty state',
  'storefrontDomain.custom-images.enabled-switch-description': 'Customization enabled',
  'storefrontDomain.custom-images.imageRequiredError': 'Image is required when enabled is true',

  // - Content Pages
  'storefrontDomain.contentPages.dashboard.pageTitle': 'Content pages',
  'storefrontDomain.contentPages.dashboard.pageTitle.marketplace': 'Marketplace content pages',
  'storefrontDomain.contentPages.createPage.pageTitle': 'New Content Page',
  'storefrontDomain.contentPages.editPage.pageTitle': 'Edit Content Page',
  'storefrontDomain.contentPages.builder.pageTypeLabel': 'Content Page',

  // - Homepage
  'storefrontDomain.homepage.dashboard.pageTitle': 'Homepage',
  'storefrontDomain.homepage.dashboardV2.pageTitle': 'HomepageV2',
  'storefrontDomain.homepage.dashboard.createNewHomepage': 'Create Custom Homepage',
  'storefrontDomain.homepage.dashboardV2.createNewHomepage': 'New Homepage',
  'storefrontDomain.homepage.dashboardV2.noHomepageSubtitle':
    "You haven't created any homepages. Click on the new homepage button above to get started.",
  'storefrontDomain.homepage.dashboardV2.noHomepageTitle': 'No Homepages',
  'storefrontDomain.homepage.dashboardV2.createHomepageModal.selectSite':
    'Select one of your sites to create a homepage',
  'storefrontDomain.homepage.dashboardV2.createHomepageModal.sitesLabel': 'Sites',
  'storefrontDomain.homepage.dashboardV2.createHomepageModal.bannersLabel': 'Banners',
  'storefrontDomain.homepage.dashboardV2.createHomepageModal.cancel': 'Cancel',
  'storefrontDomain.homepage.dashboardV2.createFailed':
    'An error occurred while creating a new page.',
  'storefrontDomain.homepage.builder.pageTypeLabel': 'Homepage',

  // - Flyout Modal
  'storefrontDomain.flyout.builder.pageTypeLabel': 'Fly Out Page',

  // - creative builder
  'storefrontDomain.placementBuilder.create.pageTitle': 'Creative Builder',
  'storefrontDomain.placementBuilder.pageTypeLabel': 'Creative Builder',

  // - creative manager
  'storefrontDomain.creativeManager.nav': 'Creatives',
  'storefrontDomain.listCampaign.title': 'Campaigns',

  // - creative manager v2
  'storefrontDomain.creativeManagerV2.title': 'Creative Manager V2',
  'storefrontDomain.creativeManagerV2.nav': 'Creatives V2',

  // - Placement Manager
  'storefrontDomain.placementHub.pageTitle': 'Global Placements',
  // shared moments dropdown
  'storefrontDomain.moments-selector.placeholder': 'Start typing a moment name…',
  // shared collections dropdown
  'storefrontDomain.collections-selector.placeholder': 'Start typing a collection name…',
  'storefrontDomain.collections-selector.departments': 'Departments',
  'storefrontDomain.collections-selector.icOwned': 'Instacart created',
  'storefrontDomain.collections-selector.retailerOwned': 'Retailer created',
  'storefrontDomain.collections-selector.openModal': 'Open advanced search',
  'storefrontDomain.collections-selector.advancedSearch.title': 'Search departments & collections',
  'storefrontDomain.collections-selector.advancedSearch.close': 'Close',
  'storefrontDomain.collections-selector.advancedSearch.save': 'Save',
  'storefrontDomain.collections-selector.advancedSearch.searchPlaceholder':
    'Search for a collection name or slug',
  'storefrontDomain.collections-selector.advancedSearch.type.collection': 'Collection',
  'storefrontDomain.collections-selector.advancedSearch.type.department': 'Department',
  'storefrontDomain.collections-selector.advancedSearch.type.hub': 'Hub',
  'storefrontDomain.collections-selector.advancedSearch.type.holiday': 'Holiday',
  'storefrontDomain.collections-selector.advancedSearch.author.instacart': 'Instacart',
  'storefrontDomain.collections-selector.advancedSearch.author.retailer': 'Retailer',
  'storefrontDomain.collections-selector.advancedSearch.typePlaceholder':
    'Type (collection or department)',
  'storefrontDomain.collections-selector.advancedSearch.authorPlaceholder': 'Author (IC/Retailer)',
  'storefrontDomain.collections-selector.advancedSearch.columns.name': 'Name',
  'storefrontDomain.collections-selector.advancedSearch.columns.slug': 'Slug',
  'storefrontDomain.collections-selector.advancedSearch.columns.type': 'Type',
  'storefrontDomain.collections-selector.advancedSearch.columns.author': 'Author',
  'storefrontDomain.collections-selector.advancedSearch.columns.products': 'Products',
  'storefrontDomain.collections-selector.advancedSearch.columns.lastEdited': 'Last edited',
  'storefrontDomain.collections-selector.advancedSearch.columns.dynamic': 'Dynamic',
  'storefrontDomain.collections-selector.advancedSearch.empty': 'No options found',
  'storefrontDomain.collections-selector.advancedSearch.searchForCollection':
    'Search for a collection name or slug',
  'storefrontDomain.collections-selector.advancedSearch.typeToSearch': 'Type to search...',
  'storefrontDomain.collections-selector.advancedSearch.optionsDropdown.hide':
    'Hide empty collections',
  'storefrontDomain.collections-selector.advancedSearch.optionsDropdown.show':
    'Show all collections',
  'storefrontDomain.collections-selector.advancedSearch.optionsDropdown.options': 'More options',
  // - Slots manager
  'storefrontDomain.slotManager.pageTitle': 'Slot manager',
  'storefrontDomain.slotManager.breadcrumb': 'Slot Manager',
  'storefrontDomain.slotManager.slotsOverviewTable.slotName': 'Slot name',
  'storefrontDomain.slotManager.slotsOverviewTable.pageName': 'Page',
  'storefrontDomain.slotManager.slotsOverviewTable.slotType': 'Type',
  'storefrontDomain.slotManager.slotsOverviewTable.campaignName': 'Campaign',
  'storefrontDomain.slotManager.slotsOverviewTable.campaignSchedule': 'Schedule',
  'storefrontDomain.slotManager.slotsOverviewTable.campaignStatus': 'Status',
  'storefrontDomain.slotManager.slotsOverviewTable.targeting': 'Targeting',
  'storefrontDomain.slotManager.slotsOverviewTable.status.active': 'Active',
  'storefrontDomain.slotManager.slotsOverviewTable.status.scheduled': 'Scheduled',
  'storefrontDomain.slotManager.slotsOverviewTable.status.ended': 'Ended',
  'storefrontDomain.slotManager.slotsOverviewTable.moreCampaigns': 'more campaigns',
  'storefrontDomain.slotManager.slotsOverviewTable.unknown': 'Unknown',
  'storefrontDomain.slotManager.slotsOverviewTable.view': 'View',
  'storefrontDomain.slotManager.overview.infoText': `Personalize content for your customers by ranking assigned campaigns in dynamic slots.`,
  'storefrontDomain.slotManager.overview.documentation': `Read documentation`,
  'storefrontDomain.slotManager.slotsOverviewTable.filters.search': `Search`,
  'storefrontDomain.slotManager.slotsOverviewTable.filters.search.placeholder': `Search by slot or campaign name`,
  'storefrontDomain.slotManager.slotsOverviewTable.filters.pages.placeholder': `Pages`,
  'storefrontDomain.slotManager.slotsOverviewTable.filters.slotTypes.placeholder': `Slot types`,
  'storefrontDomain.slotManager.slotTable.rank': `Rank`,
  'storefrontDomain.slotManager.slotView.infoTextStatic': `Rank assigned campaigns for this dynamic slot.`,
  'storefrontDomain.slotManager.slotView.infoText': `Rank assigned campaigns for this dynamic slot. View the slot within`,
  'storefrontDomain.slotManager.slotView.documentation': ` your page.`,
  'storefrontDomain.slotManager.slotTable.noCampaigns': `No campaigns found`,
  'storefrontDomain.slotManager.slotsOverviewTable.noSlots': `No slots found`,
  'storefrontDomain.slotManager.slotTable.campaign': `Campaign`,
  'storefrontDomain.slotManager.slotView.allDates': `All dates`,
} as const

export default EN_US
