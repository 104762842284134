const EN_US = {
  'pickupSettingsDomain.title': 'Pickup settings',
  // RetailerLocationDropdown
  'pickupSettingsDomain.retailerLocationDropdown.noRetailerLocations': 'No stores available',
  'pickupSettingsDomain.retailerLocationDropdown.defaultOptionLabel': 'Select store',
  'pickupSettingsDomain.retailerLocationDropdown.inactive': 'Inactive',
  // Tabs
  'pickupSettingsDomain.tabs.ariaLabel': 'Store settings forms',
  'pickupSettingsDomain.tabs.staging': 'Staging',
  'pickupSettingsDomain.tabs.curbside': 'Curbside',
  'pickupSettingsDomain.tabs.inStore': 'In-store',
  // NoRetailerLocationSelected
  'pickupSettingsDomain.noRetailerLocationSelected.title':
    'Select a store to review and manage settings',
  'pickupSettingsDomain.noRetailerLocationSelected.text':
    'Because you have multiple stores, you’ll need to select one to see its settings.',

  // NoRetailerLocationSelected
  'pickupSettingsDomain.retailerLocationPickupSettingsNotSetup.title':
    'This store isn’t setup for Pickup yet',
  'pickupSettingsDomain.retailerLocationPickupSettingsNotSetup.text':
    'Follow the step by step guide to complete the required settings. Once you start, you can always save your progress and finish later.',
  'pickupSettingsDomain.retailerLocationPickupSettingsNotSetup.getStartedButton': 'Get started',
  'pickupSettingsDomain.retailerLocationPickupSettingsNotSetup.titleInProgress':
    'Continue working on your store pickup settings',
  'pickupSettingsDomain.retailerLocationPickupSettingsNotSetup.textInProgress':
    'Resume where you left off and follow the step by step guide to complete the required settings.',
  'pickupSettingsDomain.retailerLocationPickupSettingsNotSetup.resumeButton': 'Resume',

  // Forms
  'pickupSettingsDomain.forms.common.required': '* Required',
  'pickupSettingsDomain.forms.common.getError':
    'Something went wrong getting the store settings, please refresh and try again.',
  'pickupSettingsDomain.forms.common.mutationError':
    'Something went wrong submitting the form, please try again.',
  'pickupSettingsDomain.forms.common.missingInstructions': 'No instructions',
  'pickupSettingsDomain.forms.common.imageSize': 'Size: no larger than 4096x4096 pixels',
  'pickupSettingsDomain.forms.common.imageFileType': 'File type: PNG, GIF, JPEG, or JPG',
  'pickupSettingsDomain.forms.common.descriptionMaxLengthError':
    'The description can’t be more than {maxLength} characters',
  'pickupSettingsDomain.forms.common.descriptionInputLabel': 'Description',
  'pickupSettingsDomain.forms.common.formHasErrors': 'Please correct the errors and try again.',
  'pickupSettingsDomain.forms.common.customerViewTitle': 'Sample customer mobile view',
  'pickupSettingsDomain.forms.common.descriptionRequiredError': 'A description is required',
  // ActionBar
  'pickupSettingsDomain.forms.actionBar.edit': 'Edit',
  'pickupSettingsDomain.forms.actionBar.save': 'Save',
  'pickupSettingsDomain.forms.actionBar.cancel': 'Cancel',
  'pickupSettingsDomain.forms.actionBar.done': 'Done',
  'pickupSettingsDomain.forms.actionBar.restoreDefaults': 'Restore defaults',
  'pickupSettingsDomain.forms.actionBar.sampleMobilePreview': 'Sample mobile preview',
  'pickupSettingsDomain.forms.actionBar.restoreDefaultsForStagingSettings':
    'Restore defaults for staging settings?',
  'pickupSettingsDomain.forms.actionBar.restoreDefaultsForCurbsideSettings':
    'Restore defaults for curbside settings?',
  'pickupSettingsDomain.forms.actionBar.restoreDefaultsForInStoreSettings':
    'Restore defaults for in-store settings?',
  'pickupSettingsDomain.forms.actionBar.modal.restoreDefaultNoUndoTitle': 'If you do this:',
  'pickupSettingsDomain.forms.actionBar.modal.restoreDefaultNoUndoFooter':
    "You can't undo this action",
  'pickupSettingsDomain.forms.actionBar.modal.restoreDefaultWarning.locationLabel':
    'Location labels will be deleted',
  'pickupSettingsDomain.forms.actionBar.modal.restoreDefaultWarning.uploadedImages':
    'Uploaded images will be replaced with default images',
  'pickupSettingsDomain.forms.actionBar.modal.restoreDefaultWarning.replaceUploadedImageWithLogo':
    'Uploaded images will be removed and replaced with your logo',
  'pickupSettingsDomain.forms.actionBar.modal.restoreDefaultWarning.customDescription':
    'Custom description of staging area will be replaced with a default description',
  'pickupSettingsDomain.forms.actionBar.modal.defaultInstruction':
    "Look for the staging area, or ask an associate for help. Once you find it, label bags from shop-only orders and leave them here so they're easy to find later.",
  // StagingAreaSettingsPreview
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.title': 'Staging area',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.description':
    'Orders are stored in the staging area until a customer arrives to pick them up.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.locationLabel':
    'Location labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.shelfLabel':
    'Shelf labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.hotPlateLabel':
    'Hot plate labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.fridgeLabel':
    'Fridge labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.freezerLabel':
    'Freezer labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.shopperTitle':
    'Sample shopper mobile view',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.shopperShelfImageAlt':
    'Mockup of how shelves will appear in app.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.shopperShelfDescription':
    'After checkout, shoppers select a shelf, fridge, or freezer location for each bag they’re staging.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.shopperStagingAreaDescriptionImageAlt':
    'Mockup of how the staging area description will appear in app.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.shopperStagingAreaDescription':
    'Before staging, shoppers are shown an image and description of where they can find the staging area.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.stagingAreaImageLabel':
    'Image of staging area',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.stagingAreaDescriptionLabel':
    'Description of staging area',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.stagingAreaImageAlt':
    "Image of the store's staging area.",
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaPreview.missingShelfLabels':
    'No labels',
  // StagingAreaSettingsForm
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.title': 'Staging area',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.description':
    'Orders are stored in the staging area until a customer arrives to pick them up. ',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.labelingTitle':
    'Labelling the staging area',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.labelingDescription':
    'Bags from an order can be stored on a shelf, a hot plate, in the fridge, or in the freezer, depending on the temperature of the items in the bag. Clearly labeling each area of the shelf, fridge, and freezer makes it easier to track and locate the bags when the customer arrives.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.labelingImageDisclaimer':
    '* Sample setup: Your staging area may be set up differently.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.labelingImageAlt':
    'Image of an example staging area setup.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.shelfLabelTitle':
    'Shelf labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.shelfLabelDescription':
    'You can auto-generate location labels by entering the number of rows and columns for each shelf in the staging area, or you can create custom location labels (up to 10 characters).',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.autoGenerateLabel':
    'Auto-generate location labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.customLocationLabel':
    'Create custom location labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.addLabelButton':
    'Add label',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.duplicateLabelsError':
    'Labels must be unique.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.requiredLabelsError':
    'You must enter at least one label',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.labelInputLabel': 'Label',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.fridgeLabelTitle':
    'Fridge labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.fridgeLabelDescription':
    'You can auto-generate location labels by entering the number of rows and columns for each fridge in the staging area, or you can create custom location labels (up to 10 characters).',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.freezerLabelTitle':
    'Freezer labels',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.freezerLabelDescription':
    'You can auto-generate location labels by entering the number of rows and columns for each freezer in the staging area, or you can create custom location labels (up to 10 characters).',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.stagingAreaImageTitle':
    'Image of staging area',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.stagingAreaImageDescription':
    'Upload an image that clearly shows the staging area and where it is, so it’s easier to find.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.stagingAreaImageAlt':
    'Image of this locations staging area.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.stagingAreaDescriptionTitle':
    'Description of staging area',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.stagingAreaDescriptionDescription':
    'Describe where the staging area is. Include details that’ll make it easier to find.',
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.imageUploader.imageAlt':
    "Image of the store's staging area",
  'pickupSettingsDomain.forms.stagingSettingsArea.stagingSettingsAreaForm.hotPlateCheckboxLabel':
    'Add hot plates',
  // ShelfPill
  'pickupSettingsDomain.forms.stagingSettingsArea.shelfPill.removeButtonLabel': 'Remove label',
  // ImageUploader
  'pickupSettingsDomain.forms.imageUploader.uploadButtonLabel': 'Choose file',
  'pickupSettingsDomain.forms.imageUploader.removeButtonLabel': 'Remove',
  'pickupSettingsDomain.forms.imageUploader.noFileChosen': 'No file chosen',
  'pickupSettingsDomain.forms.imageUploader.imageRequired': 'An image is required',
  'pickupSettingsDomain.forms.imageUploader.uploadError': 'File upload failed, please try again',
  'pickupSettingsDomain.forms.imageUploader.maxDimensionsError':
    'Image can’t be larger than {maxWidth}x{maxHeight} pixels',
  'pickupSettingsDomain.forms.imageUploader.fileTypeError': 'Image must be PNG, GIF, JPEG, or JPG',
  // ImageUploaderWithCaption
  'pickupSettingsDomain.forms.imageUploaderWithCaption.captionTitle': 'Image caption',
  'pickupSettingsDomain.forms.imageUploaderWithCaption.captionLabel': 'Caption',
  // CurbsidePickupSettings
  'pickupSettingsDomain.forms.curbsidePickupSettings.notSetupTitle':
    'This store isn’t set up for curbside pickup',
  'pickupSettingsDomain.forms.curbsidePickupSettings.notSetupText':
    'Contact your Instacart representative to learn more about setting it up.',
  // CurbsidePickupSettingsPreview
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsPreview.title':
    'Curbside pickup',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsPreview.description':
    'When a customer arrives to pick up their order, they’ll wait outside for an associate to bring their order.',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsPreview.imageLabel':
    'Image of parking area',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsPreview.descriptionLabel':
    'Description of parking area',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsPreview.customerViewDescription':
    'Customers are shown an image and description of where they should park to wait for their order.',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsPreview.customerViewImageAlt':
    'Example image of what customers will be shown in app for curbside pickup.',
  // ImagePreviewCarousel
  'pickupSettingsDomain.forms.ImagePreviewCarousel.captionLabel': 'Photo caption',
  'pickupSettingsDomain.forms.ImagePreviewCarousel.emptyCaption': 'No caption',
  'pickupSettingsDomain.forms.ImagePreviewCarousel.navLeftLabel': 'Previous photo',
  'pickupSettingsDomain.forms.ImagePreviewCarousel.navRightLabel': 'Next photo',
  // CurbsidePickupSettingsForm
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.title':
    'Curbside pickup',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.description':
    'When a customer arrives to pick up their order, they’ll wait outside for an associate to bring their order.',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.customerParkingLabel':
    'Customer parking',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.imageLabel':
    'Image of parking area',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.imageDescription':
    'Upload an image that clearly shows the parking area and where it is, so it’s easy for customers to find.',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.descriptionLabel':
    'Description of parking area',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.descriptionDescription':
    'Describe where the parking area is. Include details that’ll make it easier for customers to find.',
  'pickupSettingsDomain.forms.curbsidePickupSettings.curbsidePickupSettingsForm.imageUploader.imageAlt':
    'Image of the curbside parking area',
  // InStorePickupSettingsPreview
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsPreview.title':
    'In-store pickup',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsPreview.description':
    'If you don’t offer curbside pickup, customers will come inside the store to pick up their order when they arrive. If you do offer curbside, customers may be prompted to come inside the store if their wait time is too long.',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsPreview.imageLabel':
    'Image of pickup area',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsPreview.descriptionLabel':
    'Description of pickup area',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsPreview.customerViewDescription':
    'Customers are shown an image and description of where they should go to pickup their order.',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsPreview.customerViewImageAlt':
    'Example image of what customers will be shown in app for in-store pickup.',
  // InStorePickupSettingsForm
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsForm.title':
    'In-store pickup',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsForm.description':
    'If you don’t offer curbside pickup, customers will come inside the store to pick up their order when they arrive. If you do offer curbside, customers may be prompted to come inside the store if their wait time is too long.',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsForm.imageLabel':
    'Image of parking area',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsForm.imageDescription':
    'Upload an image that clearly shows the parking area and where it is, so it’s easy for customers to find.',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsForm.descriptionLabel':
    'Description of in-store pickup area',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsForm.descriptionDescription':
    'Describe where the in-store pickup area is. Include details that’ll make it easier for customers to find.',
  'pickupSettingsDomain.forms.inStorePickupSettings.inStorePickupSettingsForm.imageUploader.imageAlt':
    'Image of the in-store pickup area',
  // CounterInput
  'pickupSettingsDomain.forms.counterInput.addLabel': 'Add',
  'pickupSettingsDomain.forms.counterInput.subtractLabel': 'Subtract',
  // AutoLabelShelfInput
  'pickupSettingsDomain.forms.autoLabelShelfInput.rowLabel': 'Rows',
  'pickupSettingsDomain.forms.autoLabelShelfInput.columnLabel': 'Columns',
  // AutoCustomLabelShelfSwitch
  'pickupSettingsDomain.forms.autoCustomLabelShelfSwitch.autoLabel':
    'Auto-generate location labels',
  'pickupSettingsDomain.forms.autoCustomLabelShelfSwitch.customLabel':
    'Create custom location labels',
  // HotPlateInput
  'pickupSettingsDomain.forms.hotPlateInput.label': 'Number of hot plates',
  // Mobile Preview
  'pickupSettingsDomain.forms.actionBar.mobilePreview.title': 'Sample mobile view & tips',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.shopperView': 'Shopper view',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.shopperViewDescription.afterCheckout':
    "After checkout, shoppers select a shelf, fridge, or freezer location for each bag they're staging.",
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.shopperViewDescription.beforeStaging':
    'Before staging, shoppers are shown an image and description of where they can find the staging area.',

  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.customerView': 'Customer view',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips': 'Tips',

  // Staging Area tips
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.easyToReadLabels':
    'Make labels easy to read by using a single letter and number combination (e.g. A1, F3).',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.orderOfLabels':
    'The order of the labels should follow this flow: left to right and top to bottom for dry shelves. For fridge and freezer use top to bottom and left to right.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.usingShelfClips':
    'Using shelf clips can help ensure labels hold up over time and are easy to read.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.keepInstructionsSimple':
    'Keep instructions simple. This is likely the shopper’s first time to the store.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.avoidUsingMarkers':
    'Avoid using markers or signs as guidance as these might change location in the future.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.havingAccountSignedIn':
    'Having an Instacart sign in the staging area is a great way to help locate it and bring attention to customers that you offer pickup.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.stagingAreaShelvesSampleSetupContent':
    '* Sample setup: Your staging area may be set up differently.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.curbsideModalImageCaption':
    '* Illustrative example: actual view may be different.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.inStoreModalImageCaption':
    '* Illustrative example: actual view may be different.',

  // Curbside tips
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.parkingArea':
    'The pickup parking area should be near a store entrance or near the staging area to facilitate handoffs.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.labelCurb':
    'Consider labeling the curb or having an Instacart sign to inform customers where to park. E.g. Park here for Instacart pickup.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.additionalSignage':
    'Customers may enter from different entrances and may be visiting your store for the first time. Additional signage will help guide them to the correct parking area.',

  // In store tips
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.photosAndInstructions':
    'Provide clear photos and instructions about where the customer should go inside the store.',
  'pickupSettingsDomain.forms.actionBar.mobilePreview.section.tips.avoidAskingAnEmployee':
    'Avoid saying “ask an employee for the order” as employees could be busy and this could lead to a negative customer experience.',

  // ModalCommon
  'pickupSettingsDomain.modal.close': 'Close',

  // ResumeModal
  'pickupSettingsDomain.onboarding.resumeModal.title':
    'Would you like to continue working on your store’s pickup settings?',
  'pickupSettingsDomain.onboarding.resumeModal.resume': 'Resume',
  'pickupSettingsDomain.onboarding.resumeModal.later': 'Remind me later',

  // DoneModal
  'pickupSettingsDomain.onboarding.doneModal.title':
    'Congrats! You’ve completed your Pickup settings',
  'pickupSettingsDomain.onboarding.doneModal.next': 'Next steps',
  'pickupSettingsDomain.onboarding.doneModal.step1': "Review your settings on the 'Settings' page",
  'pickupSettingsDomain.onboarding.doneModal.step2':
    "Our team will review your store's configuration.",
  'pickupSettingsDomain.onboarding.doneModal.step3':
    "You'll receive an e-mail once you're set up to schedule shifts and fulfill orders",
  'pickupSettingsDomain.onboarding.doneModal.gotIt': 'Got it',

  // Training tours
  'pickupSettingsDomain.tours.welcomeTour.revisitText':
    'Visit this page after the tour for a detailed walkthrough',
  // step 1
  'pickupSettingsDomain.tours.welcomeTour.step1.title': 'Keep track of pickup orders',
  'pickupSettingsDomain.tours.welcomeTour.step1.description.main':
    'Find details for every pickup order',
  'pickupSettingsDomain.tours.welcomeTour.step1.description.point1':
    'View order IDs and shopping lists',
  'pickupSettingsDomain.tours.welcomeTour.step1.description.point2':
    'Track real-time order progress',
  'pickupSettingsDomain.tours.welcomeTour.step1.description.points': `View order IDs and shopping lists\nTrack real-time order progress`,
  // step 2
  'pickupSettingsDomain.tours.welcomeTour.step2.title': "See how you're doing",
  'pickupSettingsDomain.tours.welcomeTour.step2.description.main':
    'Monitor pickup performance metrics.',
  'pickupSettingsDomain.tours.welcomeTour.step2.description.points':
    'Pickup Growth\nShopper efficiency\nCustomer satisfaction',
  // step 3
  'pickupSettingsDomain.tours.welcomeTour.step3.title': 'Download pickup reports',
  'pickupSettingsDomain.tours.welcomeTour.step3.description.main':
    'Gain more detailed insights on:',
  'pickupSettingsDomain.tours.welcomeTour.step3.description.points':
    'Store performance\nAssocitate performance\nAnd much more!',
  // step 4
  'pickupSettingsDomain.tours.welcomeTour.step4.title': 'Review all transactions',
  'pickupSettingsDomain.tours.welcomeTour.step4.description.main':
    'Review and download pickup delivery transaction logs.',
  'pickupSettingsDomain.tours.welcomeTour.step4.description.points':
    'Choose a custom date range\nMerge multiple reports',
  // step 5
  'pickupSettingsDomain.tours.welcomeTour.step5.title': 'Staff up to meet demand',
  'pickupSettingsDomain.tours.welcomeTour.step5.description.points':
    'View staffing forecasts and schedule shifts\nGet real-time staffing alerts to meet demand for incoming orders',
  // step 6
  'pickupSettingsDomain.tours.welcomeTour.step6.title': 'Get ready to launch pickup',
  'pickupSettingsDomain.tours.welcomeTour.step6.description.points':
    'Use the setup wizard to complete required settings\nOnce complete, review and manage pickup settings anytime',
  // step 7
  'pickupSettingsDomain.tours.welcomeTour.step7.title': 'Get help anytime',
  'pickupSettingsDomain.tours.welcomeTour.step7.description.points':
    'Visit the help center to browse or search for articles by topic\nStart this welcome tour again for a quick refresher of pickup features\nContact the retail support team',
  // go to settings page step
  'pickupSettingsDomain.tours.remindMeLaterStep.goToSettings.title': 'Get ready to launch Pickup!',
  'pickupSettingsDomain.tours.remindMeLaterStep.goToSettings.description':
    "Configure your store's pickup settings as a 1st step.",
  'pickupSettingsDomain.tours.remindMeLaterStep.goToSettings.nextBtnLabel': 'Go to settings',
  // detailed tour's remind me later step
  'pickupSettingsDomain.tours.remindMeLaterStep.detailedTour.description':
    'You can restart this page tour anytime',
  'pickupSettingsDomain.tours.remindMeLaterStep.detailedTour.nextBtnLabel': 'Got it',
  // pickup order tour
  // step 1
  'pickupSettingsDomain.tours.pickupOrder.step1.title': 'Keep track of pickup orders',
  'pickupSettingsDomain.tours.pickupOrder.step1.description.main':
    'See all orders in one place, or take a look at in-progress, completed, or canceled orders only.',
  // step 2
  'pickupSettingsDomain.tours.pickupOrder.step2.title': 'Find important order info',
  'pickupSettingsDomain.tours.pickupOrder.step2.description.main1':
    'See details like order ID, customer name, pickup time and item list for each order.',
  'pickupSettingsDomain.tours.pickupOrder.step2.description.main2':
    'This is especially useful if you need to contact support or troubleshoot an issue with an order.',
  // step 3
  'pickupSettingsDomain.tours.pickupOrder.step3.title': 'Monitor real-time progress for each order',
  'pickupSettingsDomain.tours.pickupOrder.step3.description.main':
    "Know when shopping hasn't started (0%) and monitor the shopper's progress once shopping begins.",
  // pickup analytics tour
  // step 1
  'pickupSettingsDomain.tours.pickupAnalytics.step1.title':
    'Select which store(s) you want to see metrics for',
  'pickupSettingsDomain.tours.pickupAnalytics.step1.description.main1':
    'You can view metrics for one store, all stores, or a group of stores.',
  'pickupSettingsDomain.tours.pickupAnalytics.step1.description.main2':
    'Tip: Create store groups in Settings',
  // step 2
  'pickupSettingsDomain.tours.pickupAnalytics.step2.title': 'Customize the time range',
  'pickupSettingsDomain.tours.pickupAnalytics.step2.description.main':
    'Choose from a list of options or set a custom date range to get the data you need.',
  // step 3
  'pickupSettingsDomain.tours.pickupAnalytics.step3.title': 'Start with a quick overview',
  'pickupSettingsDomain.tours.pickupAnalytics.step3.description.main':
    "See the total number of pickup orders for your selected time range and how often they're accepted.",
  // step 4
  'pickupSettingsDomain.tours.pickupAnalytics.step4.title':
    'Get a pulse on how your customers feel',
  'pickupSettingsDomain.tours.pickupAnalytics.step4.description.main':
    'Monitor customer satisfaction through wait times and experience ratings.',
  // step 5
  'pickupSettingsDomain.tours.pickupAnalytics.step5.title': 'Track shopper performance',
  'pickupSettingsDomain.tours.pickupAnalytics.step5.description.main':
    'Monitor speed, punctuality, efficiency and ratings for your shoppers.',
  // step 6
  'pickupSettingsDomain.tours.pickupAnalytics.step6.title': 'Learn more with info icons',
  'pickupSettingsDomain.tours.pickupAnalytics.step6.description.main':
    'Hover over the icon to see a description and calculation method for each metric.',

  // RestartTourFooter
  'pickupSettingsDomain.tours.restartFooter.description':
    "Take the {restartButton} for a detailed walkthrough of this page's features.",
  'pickupSettingsDomain.tours.restartFooter.restartButtonLabel': 'page tour',

  // PickupSettingsOnboardingWizard
  // Common
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.nextButtonLabel': 'Next',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.backButtonLabel': 'Back',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.doneButtonLabel': 'Finish',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.sampleTitle': 'Sample setup',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.sampleMobileViewButton':
    'Sample mobile view',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.sampleDisclaimer':
    '*Your configuration may vary',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.yesLabel': 'Yes',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.common.noLabel': 'No',

  // ExampleShelfLayout
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.exampleShelfLayout.example': 'Example',

  // WelcomeScreen
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.title':
    'Before you start',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.startButtonLabel':
    'Begin',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.remindButtonLabel':
    'Remind me later',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.accomplishTitle':
    'What you’ll accomplish',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.stagingTile':
    'Set up staging shelves and instructions',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.stagingLabels':
    'Create labels for dry, cold, and frozen items',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.stagingInstructions':
    'Add instructions and an image to help shoppers find the staging area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.curbsideTile':
    'Set up curbside pickup',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.curbsideEnabled':
    'Choose whether your store will allow curbside pickup',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.curbsideInstructions':
    'Add instructions and an image to help customers find the parking area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.inStoreTile':
    'Set up in-store pickup',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.inStoreOption':
    'Set up in-store pickup as your primary option or as a backup for curbside failures',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.inStoreInstructions':
    'Add instructions and an image to help customers find the in-store pickup area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.needTitle':
    'What you’ll need',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.requirementsTitle':
    'List of requirements',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.requirements1':
    'For grocery stores: dry, cold and frozen staging areas.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.requirements2':
    'For non-grocery: at least one dry staging area.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.requirements3':
    'Parking signage',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.requirements4':
    'In-store pickup wayfinding',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.requirements5':
    'Photograph of the staging, parking, and in-store pickup areas',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.estimatedTimeTitle':
    'Estimated completion time',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.welcomeScreen.estimatedTime':
    '7 minutes',

  // StagingOverview
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.title':
    'Staging overview',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.headline':
    'Orders are stored in the staging area until a customer arrives to pick them up',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.labelingTitle':
    'Labeling the staging area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.labelingDescription':
    'Bags from an order can be stored on a shelf, in the fridge, or in the freezer, depending on the temperature of the items in the bag. Clearly labeling each area of the shelf, fridge, and freezer makes it easier to track and locate the bags when the customer arrives.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.tipsTitle': 'Tips',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.tips1':
    'Make labels easy to read by using a single letter and number combination (e.g. A1, F3).',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.tips2':
    'The order of the labels should follow this flow: left to right and top to bottom for dry shelves. For fridge and freezer use top to bottom and left to right.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingOverview.tips3':
    'Using shelf clips can help ensure labels hold up over time and are easy to read.',

  // StagingSettingsShelf
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsShelf.title':
    'Staging settings',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsShelf.headline':
    'Create labels for the shelf area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsShelf.layoutTitle':
    'Shelf layout',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsShelf.layoutDescription':
    'Enter the number of rows and columns for each shelf you plan to use in the staging area. This will auto-generate location labels.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsShelf.sampleDescription':
    'You can also see an example of how the shopper app displays these settings',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsShelf.sampleMobilePreviewDescription':
    "After checkout, shoppers select a dry shelf location for each bag they're staging.",
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsShelf.sampleMobilePreviewImageAlt':
    'Example view of the shopper mobile view of the staging shelf layout',

  // StagingSettingsFridge
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFridge.title':
    'Staging settings',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFridge.headline':
    'Create labels for the fridge area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFridge.layoutTitle':
    'Fridge layout',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFridge.layoutDescription':
    'Enter the number of rows and columns for each fridge you plan to use in the staging area. This will auto-generate location labels.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFridge.sampleMobilePreviewDescription':
    "After checkout, shoppers select a fridge shelf for each cold item they're staging.",
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFridge.sampleMobilePreviewImageAlt':
    'Example view of the shopper mobile view of the staging fridge layout',

  // StagingSettingsFreezer
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFreezer.title':
    'Staging settings',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFreezer.headline':
    'Create labels for the freezer area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFreezer.layoutTitle':
    'Freezer layout',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFreezer.layoutDescription':
    'Enter the number of rows and columns for each freezer you plan to use in the staging area. This will auto-generate location labels.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFreezer.sampleMobilePreviewDescription':
    "After checkout, shoppers select a freezer shelf for each frozen item they're staging.",
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsFreezer.sampleMobilePreviewImageAlt':
    'Example view of the shopper mobile view of the staging freezer layout',

  // StagingSettingsInstructions
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.title':
    'Staging settings',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.headline':
    'Help shoppers find the staging area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.imageTitle':
    'Image of staging area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.imageDescription':
    'Upload an image that clearly shows the staging area and where it is, so it’s easier to find.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.descriptionTitle':
    'Description of staging area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.descriptionDescription':
    'Describe where the staging area is. Include details that’ll make it easier to find.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.sampleTitle':
    'Sample shopper mobile view',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.stagingSettingsInstructions.sampleDescription':
    'Before staging, shoppers are shown an image and description of where they can find the staging area.',

  // CurbsideOverview
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.title':
    'Curbside overview',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.headline':
    'When a customer arrives to pick up their order, they’ll wait outside for an associate to bring their order.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.questionTitle':
    'Will you offer curbside pickup?',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.questionDescription':
    'If so, you’ll need a designated parking area for customers.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.questionYesDescription':
    'As a back up in case of curbside failure, you will  need to complete the ‘in-store’ instructions in the next section.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.questionNoDescription':
    'If you won’t offer curbside pick-up you must complete the ‘In-store’ pickup instructions in the next section',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.tipsTitle': 'Tips',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.tips1':
    'The pickup parking area should be near a store entrance or near the staging area to facilitate handoffs.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.tips2':
    'Consider labeling the curb or having an Instacart sign to inform customers where to park. E.g. Park here for Instacart pickup.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideOverview.tips3':
    'Customers may enter from different entrances and may be visiting your store for the first time. Additional signage will help guide them to the correct parking area.',

  // CurbsideInstructions
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.title':
    'Curbside settings',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.headline':
    'Help customers find the parking area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.headlineDescription':
    'It’s best to have designated parking near a door and the staging area. This helps reduce wait times for customers because associates can hand off the orders more quickly.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.imageTitle':
    'Image of parking area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.imageAlt':
    "Image of the store's curbside pickup area",
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.imageDescription':
    'Upload an image that clearly shows the parking area and where it is, so it’s easy for customers to find.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.descriptionTitle':
    'Description of parking area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.descriptionDescription':
    'Describe where the parking area is. Include details that’ll make it easier for customers to find.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.sampleTitle':
    'Sample customer mobile view',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.curbsideInstructions.sampleDescription':
    'Customers are shown an image and description of where they should park to wait for their order.',

  // InStoreOverview
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.title':
    'In-store overview',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.headline':
    'There are cases when a customer may come inside the store to pick up their order.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.helpTitle':
    'Help customers find the correct pickup area inside the store',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.helpDescription':
    'There are two scenarios when a customer may come into the store:',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.helpDescriptionListItem1':
    'Curbside failure: No employee was available to run the order and handoff the order, in which case we will surface in-store instructions to the customer after 5 minutes of waiting time.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.helpDescriptionListItem2':
    'Your store doesn’t offer curbside.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.considerationsTitle':
    'Pickup area considerations',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.considerationsDescription':
    'For faster handoffs, it’s best to have the in-store pickup area near the entrance and staging area. Clear signage can also help customers locate the pickup area more easily.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.tipsTitle': 'Tips',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.tips1':
    'Provide clear photos and instructions about where the customer should go inside the store.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.tips2':
    'Avoid using markers or signs as guidance as these might change location in the future.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreOverview.tips3':
    'Avoid saying “ask an employee for the order” as employees could be busy and this could lead to a negative customer experience.',

  // InStoreInstructions
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.title':
    'In-store settings',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.headline':
    'Help customers find the in-store pickup area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.imageTitle':
    'Image of in-store pickup area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.imageAlt':
    "Image of the store's in-store pickup area",
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.imageDescription':
    'Upload an image that clearly shows the parking area and where it is, so it’s easy for customers to find. You can upload additional photos in your settings later.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.descriptionTitle':
    'Description of in-store pickup area',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.descriptionDescription':
    'Describe where the in-store pickup area is. Include details that’ll make it easier for customers to find.',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.sampleTitle':
    'Sample customer mobile view',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.inStoreInstructions.sampleDescription':
    'Customers are shown an image and description of where they should go to pickup their order.',

  // SampleMobilePreviewModal
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.sampleMobilePreview.title':
    'Sample mobile view',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.sampleMobilePreview.sampleTitle':
    'Sample shopper mobile view',
  'pickupSettingsDomain.onboarding.pickupSettingsOnboarding.sampleMobilePreview.doneButtonLabel':
    'Done',
} as const

export default EN_US
