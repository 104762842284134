import React, { type FunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form-7'
import { FormPassword } from '../form-password/FormPassword'
import { FormField, FormFieldError, FormFieldLabel } from '../../../form-field/FormField'
import { PasswordInput } from '../../../password-input/PasswordInput'
import { type MessageIds, useMessages } from '../../../../utils/intl/intl.hooks'
import { useFormErrorMessages } from '../../../../hooks/intl/useFormErrorMessages.hooks'

type PartialFormData = {
  confirmPassword: string
  password: string
}

export type FormPasswordConfirmProps = {
  deps?: string[]
  passwordLabel?: string
  testidPrefix: string
  disabled?: boolean
}

export const FormPasswordConfirm: FunctionComponent<
  React.PropsWithChildren<FormPasswordConfirmProps>
> = ({ testidPrefix, passwordLabel, disabled, deps }) => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<PartialFormData>()

  const errorMessages = useFormErrorMessages<Omit<PartialFormData, 'password'>, MessageIds>(
    errors,
    {
      confirmPassword: {
        default: 'sharedComponents.publicPages.formPasswordConfirm.errors.default',
        matches: 'sharedComponents.publicPages.formPasswordConfirm.errors.matches',
      },
    }
  )

  const messages = useMessages({
    confirmPasswordLabel: 'sharedComponents.publicPages.formPasswordConfirm.label',
    confirmPasswordPlaceholder: 'sharedComponents.publicPages.formPasswordConfirm.placeholder',
  })

  return (
    <React.Fragment>
      <FormPassword
        deps={deps}
        disabled={disabled}
        label={passwordLabel}
        testidPrefix={testidPrefix}
      />

      <FormField noMargin>
        <FormFieldLabel htmlFor="confirmPassword">{messages.confirmPasswordLabel}</FormFieldLabel>

        <PasswordInput
          disabled={disabled}
          id="confirmPassword"
          data-testid={`${testidPrefix}-field-confirmPassword`}
          placeholder={messages.confirmPasswordPlaceholder}
          {...register('confirmPassword', {
            deps: ['currnetPassword'],
            validate: {
              matches: (value: string) => getValues('password') === value,
            },
          })}
        />

        {errorMessages.confirmPassword && (
          <FormFieldError data-testid={`${testidPrefix}-field-error-confirmPassword`}>
            {errorMessages.confirmPassword}
          </FormFieldError>
        )}
      </FormField>
    </React.Fragment>
  )
}
