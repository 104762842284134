import React, { type FunctionComponent } from 'react'
import { NavContainer } from '../NavContainer'
import { RetailerNavHeaderContent } from './RetailerNavHeaderContent'
import { RetailerNavMainContent } from './RetailerNavMainContent'
import { RetailerNavFooterContent } from './RetailerNavFooterContent'

export const RetailerNavContent: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <NavContainer
    headerContent={<RetailerNavHeaderContent />}
    mainContent={<RetailerNavMainContent />}
    footerContent={<RetailerNavFooterContent />}
  />
)
