import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { clientEnv } from '@retailer-platform/dashboard/env'
import { DomainRedirect, DomainRoute } from '../utils/domain/routingComponents'
import { fulfillmentTestingCenterAdminAccessControl } from '../access-control/fulfillmentTestingCenterAdminAccess.configuration'

// @hygen:inject page-import
const FTCOrdersTablePage = lazy(
  () =>
    import(
      /* webpackChunkName: "FulfillmentTestingCenterOrdersTablePage" */
      '../pages/orders-table/FulfillmentTestingCenterOrdersTablePage'
    )
)

const FTCOrderSimulatorPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FTCOrderSimulatorPage" */
      '../pages/order-simulator/FulfillmentTestingCenterOrderSimulatorPage'
    )
)

export const fulfillmentTestingCenterAdminRouter = (
  <DomainRoute
    route="app-dev-portal-fulfillment-testing-center"
    accessControl={fulfillmentTestingCenterAdminAccessControl}
  >
    {clientEnv.PUBLIC_CLIENT_ENVIRONMENT != 'production' && (
      <DashSwitch>
        <DomainRoute exact route="ftc-orders" component={FTCOrdersTablePage} />
        <DomainRoute exact route="ftc-order-simulator" component={FTCOrderSimulatorPage} />
        <DomainRedirect toRoute="ftc-orders" />
        {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
      </DashSwitch>
    )}
  </DomainRoute>
)
