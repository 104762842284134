import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { recipeAdminAdminAccessControl } from '../access-control/recipeAdminAdminAccess.configuration'
import { recipeAdminLibraryCsvIngestionAccessConfiguration } from '../access-control/recipeAdminLibraryCsvIngestionAccess.configuration'
import { recipeAdminLibraryManagerAccessConfiguration } from '../access-control/recipeAdminLibraryManagerAccess.configuration'

// --------------------
// Recipe Library Page Components
// --------------------

const RecipeLibraryCsvIngestionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RecipeLibraryCsvIngestionPage" */
      '../pages/library/RecipeLibraryCsvIngestionPage'
    )
)

const RecipeLibraryManagerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RecipeLibraryManagerPage" */
      '../pages/library/RecipeLibraryManagerPage'
    )
)

const RecipeLibraryManagerRecipePage = lazy(
  () =>
    import(
      /* webpackChunkName: "RecipeLibraryManagerPage" */
      '../pages/library/RecipeLibraryManagerRecipePage'
    )
)

// @hygen:inject page-import

export const recipesAdminAdminRouter = (
  <DomainRoute route="app-admin-recipes-admin" accessControl={recipeAdminAdminAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-recipes-admin-library-csv-ingestion"
        component={RecipeLibraryCsvIngestionPage}
        accessControl={recipeAdminLibraryCsvIngestionAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-recipes-admin-library-manager"
        component={RecipeLibraryManagerPage}
        accessControl={recipeAdminLibraryManagerAccessConfiguration}
      />
      <DomainRoute
        exact
        route="app-admin-recipes-admin-library-manager-recipe"
        component={RecipeLibraryManagerRecipePage}
        accessControl={recipeAdminLibraryManagerAccessConfiguration}
      />
    </DashSwitch>
  </DomainRoute>
)
