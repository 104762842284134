import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { Text, TextButton } from '@retailer-platform/shared-components'
import { type BannerQueryParams } from '../../types/banner.types'
import { useDomainGoToPath } from '../../utils/domain/routing'
import { bannersAdminRoutingConfig } from '../../utils/bannersRoutingConfig'
import { useDomainMessages } from '../../utils/domain/intl'

const LinksToNewBannerDetails: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const messages = useDomainMessages({
    title: 'bannersDomain.linksToNewBannerDetails.title',
    enUSTextButton: 'bannersDomain.linksToNewBannerDetails.enUSTextButton',
    frCATextButton: 'bannersDomain.linksToNewBannerDetails.frCATextButton',
  })
  const { placementId } = useParams<BannerQueryParams>()
  const useGoToNewDetailsPage = (locale: string) =>
    useDomainGoToPath(bannersAdminRoutingConfig.details, {
      placementId: placementId,
      locale: locale,
    })
  return (
    <Fragment>
      <Text size="large" weight="bold">
        {messages.title}
      </Text>
      <TextButton onClick={useGoToNewDetailsPage('en_US')}>{messages.enUSTextButton}</TextButton>
      <TextButton onClick={useGoToNewDetailsPage('fr_CA')}>{messages.frCATextButton}</TextButton>
    </Fragment>
  )
}

export default LinksToNewBannerDetails
