import React, { useState, useEffect } from 'react'
import { MemoryRouter } from 'react-router'
import { RetailerSatisfactionSurveyStep } from '../RetailerSatisfactionSurveyStep'
import { SurveyContextProvider } from '../RetailerSatisfactionSurveyContext'
import { useRetailerId, usePartnerId } from '../../../../utils/routing/params.hooks'
import { trackEvent } from '../../../../utils/events/trackEvent'
import { RetailerFeedbackSurveyModal } from './RetailerFeedbackSurveyModal'
import Page1 from './pages/Page1'
import Page2 from './pages/Page2'

export enum RetailerFeedbackSurveyPages {
  Page1 = 'page1',
  Page2 = 'page2',
}

export interface RetailerFeedbackSurveyState {
  overallExperienceResponse: string
  improveExperienceResponse: string
}

export interface RetailerFeedbackSurveyControllerProps {
  isOpen: boolean
  onClose: () => void
}

export interface RetailerFeedbackSurveyProps {
  onClose: () => void
  isOpen: boolean
}

const defaultState = {
  overallExperienceResponse: '',
  improveExperienceResponse: '',
}

export const RetailerFeedbackSurveyController = ({
  isOpen,
  onClose,
}: RetailerFeedbackSurveyControllerProps) => {
  const [surveyData, setSurveyData] = useState<RetailerFeedbackSurveyState>(defaultState)
  const retailerId = useRetailerId({ throwOnMissing: false }) ?? '0'
  const partnerId = usePartnerId({ throwOnMissing: false }) ?? '0'

  useEffect(() => {
    trackEvent({
      id: 'app.retailerFeedback.survey.modal.viewed',
      description: 'Tracks whenever the retailer feedback survey modal is viewed',
    })

    return () => {
      trackEvent({
        id: 'app.retailerFeedback.survey.modal.dismissed',
        description: 'Tracks whenever the retailer feedback survey modal is dismissed',
      })
    }
  }, [])

  return (
    <MemoryRouter initialEntries={[RetailerFeedbackSurveyPages.Page1]}>
      <SurveyContextProvider
        toolProps={{ toolName: 'Quarterly Retailer Satisfaction Survey', onClose, isOpen }}
        milestoneSlug="retailer_satisfaction_survey_r202412"
        surveyData={[surveyData, setSurveyData]}
        onSurveyCompleted={() => surveyData}
        retailerId={retailerId}
        partnerId={partnerId}
      >
        <RetailerFeedbackSurveyModal>
          <RetailerSatisfactionSurveyStep
            name={RetailerFeedbackSurveyPages.Page1}
            component={Page1}
          />
          <RetailerSatisfactionSurveyStep
            name={RetailerFeedbackSurveyPages.Page2}
            component={Page2}
          />
        </RetailerFeedbackSurveyModal>
      </SurveyContextProvider>
    </MemoryRouter>
  )
}
