import React from 'react'
import styled from '@emotion/styled'
import { CardContext } from './CardContext'

const CardBodyContainer = styled.div<{ collapsed: boolean; padding?: string }>`
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  padding: ${({ padding }) => padding || '20px'};
  width: 100%;
  flex: 1 0;
  border-radius: inherit;
`

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> {
  padding?: string
}

const CardBody: React.FunctionComponent<React.PropsWithChildren<Props>> = props => (
  <CardContext.Consumer>
    {({ collapsible, collapsed, id }) => (
      <CardBodyContainer
        id={`${id}-body`}
        aria-labelledby={`${id}-heading`}
        collapsed={collapsible && collapsed}
        {...props}
      />
    )}
  </CardContext.Consumer>
)

export default CardBody
