import { useMemo } from 'react'
import { useCurrentAccountDetails } from '../../../../../api/current-account/currentAccount.hooks'
import { type DashMessages } from '../../../../../intl/intl.types'

const emailPasswordAuthenticationTypename = 'EnterpriseAuthenticationEmailPassword' as const

export const useHaltAccountActionRequiredDetails = () => {
  const { account, loading, error } = useCurrentAccountDetails()

  const authentication = account?.authentication

  const emailNeedsVerification = useMemo(() => {
    if (authentication?.__typename === emailPasswordAuthenticationTypename) {
      return !authentication.emailVerified
    }

    return false

    // We are just using this memo to help access emailVerified on the union type
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication])

  const errorMessageId: DashMessages | undefined = useMemo(() => {
    if (!error) return undefined

    return 'common.error'
  }, [error])

  return {
    emailNeedsVerification,
    loading,
    errorMessageId,
  }
}
