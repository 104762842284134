import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_PICKUP_SETTINGS = `${URL_RETAILER_SCOPED}/pickup-settings`
const URL_PICKUP_SETTINGS_RETAILER_LOCATION_SETTINGS = `${URL_PICKUP_SETTINGS}/retailer-location-settings`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'pickup-settings'>
  | DashRouteType<'pickup-settings-retailer-location-settings'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'pickup-settings': URL_PICKUP_SETTINGS,
  'pickup-settings-retailer-location-settings': URL_PICKUP_SETTINGS_RETAILER_LOCATION_SETTINGS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
