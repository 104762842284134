import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { retailerFundedMarketingAdminAccessControl } from '../access-control/retailerFundedMarketingAdminAccess.configuration'

const GlobalCampaignsRootPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerFundedMarketingRootPage" */
      '../pages/global-campaigns/GlobalCampaignsRootPage'
    )
)

const GlobalCampaignsCampaignMomentsCreate = lazy(
  () =>
    import(
      /* webpackChunkName: "GlobalCampaignsCampaignMomentsCreate" */
      '../pages/global-campaigns/GlobalCampaignsCampaignMomentsCreatePage'
    )
)

const GlobalCampaignsCampaignMomentsView = lazy(
  () =>
    import(
      /* webpackChunkName: "GlobalCampaignsCampaignMomentsView" */
      '../pages/global-campaigns/GlobalCampaignsCampaignMomentsViewPage'
    )
)

const GlobalCampaignsCampaignMomentsEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "GlobalCampaignsCampaignMomentsEdit" */
      '../pages/global-campaigns/GlobalCampaignsCampaignMomentsEditPage'
    )
)

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const globalCampaignsAdminRouter = (
  <DomainRoute
    route="app-admin-global-campaigns"
    accessControl={retailerFundedMarketingAdminAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments"
        component={GlobalCampaignsRootPage}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-retailer-campaigns"
        component={GlobalCampaignsRootPage}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments-create"
        component={GlobalCampaignsCampaignMomentsCreate}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments-create-set-up"
        component={GlobalCampaignsCampaignMomentsCreate}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments-view"
        component={GlobalCampaignsCampaignMomentsView}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments-view-moment"
        component={GlobalCampaignsCampaignMomentsView}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments-view-participating-retailers"
        component={GlobalCampaignsCampaignMomentsView}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments-edit"
        component={GlobalCampaignsCampaignMomentsEdit}
      />
      <DomainRoute
        exact
        route="app-admin-global-campaigns-campaign-moments-edit-moment"
        component={GlobalCampaignsCampaignMomentsEdit}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
