import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const retailerFundedMarketingHomeAccessControl: DomainAccessControlConfig = [
  {
    featureToggles: [FeatureToggle.InsightsCampaignAnalytics],
    permissions: [
      Permission.RetailerFundedMarketingViewAccess,
      Permission.RetailerFundedMarketingInsights,
    ],
    notFeatureToggles: [FeatureToggle.retailerFundedMarketingUserDenyList],
  },
]
