// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

const ModalFooter = styled.div`
  display: flex;
  padding: 10px 30px;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  border-top: 1px solid ${colors.GRAY_93};
`

export default ModalFooter
