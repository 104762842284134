import React, { type FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import { type RetailerScopedRouteParams } from '../../../../legacy/common/types'
import { type DashRouteComponentProps } from '../../../../legacy/dash-components/dash-route/utils/dashRoute.types'
import WarehouseProvider from '../../../../utils/contexts/warehouse/WarehouseProvider'
import { AttachRetailerAnalyticsMetadata } from '../../../utils/AttachAnalyticsMetadata'
import { RetailerScopedRoutes } from './RetailerScopedRoutes'

interface Props extends DashRouteComponentProps<RetailerScopedRouteParams> {}

export const RetailerScopedSection: FunctionComponent<React.PropsWithChildren<Props>> = ({
  match: {
    params: { warehouse_id: warehouseId },
  },
}) => {
  const history = useHistory()

  if (warehouseId === 'undefined') {
    history.push(location.pathname.replace(/\/warehouses\/(undefined)/, '/warehouses/0'))
  }

  return (
    <>
      <WarehouseProvider ids={warehouseId === 'undefined' ? '0' : warehouseId}>
        <AttachRetailerAnalyticsMetadata />
        <RetailerScopedRoutes />
      </WarehouseProvider>
    </>
  )
}
