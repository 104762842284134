import React, { type FunctionComponent } from 'react'
import { colors } from '@retailer-platform/shared-components'
import { NavContainer } from '../NavContainer'
import { AdminNavFooterContent } from './AdminNavFooterContent'
import { AdminNavMainContent } from './AdminNavMainContent'

export const AdminNavContent: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <NavContainer
    mainContent={<AdminNavMainContent />}
    footerContent={<AdminNavFooterContent />}
    rightBorderColor={colors.GRAYSCALE.X30}
  />
)
