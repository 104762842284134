import React, { type FunctionComponent, type CSSProperties, useMemo } from 'react'
import styled from '@emotion/styled'
import { type TestableProps } from '../../common/utils/testing/testing.types'
import { type OverridableStyles } from '../../common/utils/styling/styling.types'
import { type SpacingPixelUnits } from '../foundation/spacing-units/spacingUnits.types'
import { compactObject } from '../../../utils/objects/compact'
import { resolveBoxMargin, resolveBoxPadding, makeBoxChildStyleMapper } from './utils/box.utils'
import { type BoxMarginProps, type BoxPaddingProps } from './utils/box.types'

interface BoxWrapperProps {
  fillWidth: boolean
  fillHeight: boolean
  width?: SpacingPixelUnits
  height?: SpacingPixelUnits
  disabled?: boolean
}

const BoxWrapper = styled.div<BoxWrapperProps>`
  display: block;

  ${({ fillWidth }) => fillWidth && 'width: 100%;'}
  ${({ fillHeight }) => fillHeight && 'height: 100%;'}

  ${({ disabled }) =>
    disabled &&
    `
    user-select: none;
    pointer-events: none;
    filter: grayscale(100%);
    `}
`

export interface BoxProps
  extends Partial<BoxWrapperProps>,
    Partial<BoxMarginProps>,
    Partial<BoxPaddingProps>,
    OverridableStyles,
    TestableProps {
  className?: string
  overrideChildStyles?: Partial<BoxPaddingProps> & Partial<BoxMarginProps>
}

export const Box: FunctionComponent<React.PropsWithChildren<BoxProps>> = ({
  disabled,
  children,
  fillWidth = false,
  fillHeight = false,
  width = undefined,
  height = undefined,
  overrideStyle = {},
  className,
  overrideChildStyles = {},
  testId,
  style: injectedStyle = {},
  ...rest
}) => {
  // Style can be CSSProperties, we just restrict the user from
  // manually providing one
  const style = injectedStyle as unknown as CSSProperties
  const marginStyles = resolveBoxMargin(rest)
  const paddingStyles = resolveBoxPadding(rest)

  const calculatedStyle: CSSProperties = useMemo(
    () =>
      compactObject({
        // fillWidth and fillHeight take precedence over width and height
        width,
        height,
        ...style,
        ...marginStyles,
        ...paddingStyles,
        ...overrideStyle,
      }),
    [height, marginStyles, overrideStyle, paddingStyles, style, width]
  )

  const childMarginStyles = resolveBoxMargin(overrideChildStyles)
  const childPaddingStyles = resolveBoxPadding(overrideChildStyles)

  const boxChildStyleMapper = useMemo(
    () =>
      makeBoxChildStyleMapper({
        ...childMarginStyles,
        ...childPaddingStyles,
      }),
    [childMarginStyles, childPaddingStyles]
  )

  const styledChildren = useMemo(
    () => React.Children.map(children, boxChildStyleMapper),
    [boxChildStyleMapper, children]
  )

  return (
    <BoxWrapper
      data-testid={testId}
      style={calculatedStyle}
      fillWidth={fillWidth}
      fillHeight={fillHeight}
      className={className}
      disabled={disabled}
    >
      {styledChildren}
    </BoxWrapper>
  )
}
