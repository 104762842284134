export const spacing = {
  X0: '0px',
  X4: '4px',
  X8: '8px',
  X12: '12px',
  X16: '16px',
  X20: '20px',
  X24: '24px',
  X28: '28px',
  X32: '32px',
  X36: '36px',
  X40: '40px',
  X44: '44px',
  X48: '48px',
  X52: '52px',
  X56: '56px',
  X60: '60px',
  X64: '64px',
  X68: '68px',
  X72: '72px',
  X76: '76px',
  X80: '80px',
  X84: '84px',
  X88: '88px',
  X92: '92px',
  X96: '96px',
  X100: '100px',
  X104: '104px',
  X108: '108px',
  X112: '112px',
  X116: '116px',
  X120: '120px',
  X124: '124px',
  X128: '128px',
} as const
