import { useCallback } from 'react'
import {
  hasValues,
  type AccessControlFeatureEntitlements,
} from '../../legacy/components/AccessControl/accessControl.utils'
import { type WarehouseContextValue } from '../contexts/warehouse/WarehouseContext'
import { type PartnerContextValue } from '../contexts/partner/PartnerContext'

const noEntitlements = { featureEntitlements: undefined, error: null, loading: false }

/**
 * Returns a function that checks if the user has the given feature entitlements.
 * @returns A function that checks if the user has the given feature entitlements.
 */
export const useHasFeatureEntitlements = (
  warehouseOrPartnerContext?: WarehouseContextValue | PartnerContextValue
) => {
  const { featureEntitlements } = warehouseOrPartnerContext || noEntitlements

  return useCallback(
    (requiredEntitlementIds: AccessControlFeatureEntitlements[]) => {
      const entitlementIds = featureEntitlements?.map(entitlement => entitlement.id)
      return hasValues(entitlementIds, requiredEntitlementIds)
    },
    [featureEntitlements]
  )
}
