import { type DashRouteType, URL_RETAILER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_PRODUCTS = `${URL_RETAILER_SCOPED}/products`
const URL_PRODUCTS_VIEW = `${URL_PRODUCTS}/view/:productId`
const URL_PRODUCTS_VIEW_DETAILS = `${URL_PRODUCTS}/view/:productId/details`
const URL_PRODUCTS_EDIT_IMAGES = `${URL_PRODUCTS}/edit-images/:productId`
const URL_PRODUCTS_VIEW_STORE_LOCATIONS = `${URL_PRODUCTS}/view/:productId/store-locations`
const URL_PRODUCTS_VIEW_AISLES = `${URL_PRODUCTS}/view/:productId/aisles`
const URL_PRODUCTS_SEARCH = `${URL_PRODUCTS}/search`
const URL_PRODUCTS_VIEW_ATTRIBUTE_OVERRIDES = `${URL_PRODUCTS_VIEW}/attribute-overrides`
const URL_PRODUCTS_VIEW_COLLECTIONS = `${URL_PRODUCTS}/view/:productId/collections`
const URL_ITEM_ANOMALY_REPORTS = `${URL_PRODUCTS}/item-anomaly`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'products'>
  | DashRouteType<'products-view', { productId: string }>
  | DashRouteType<'products-view-details', { productId: string }>
  | DashRouteType<'products-edit-images', { productId: string }>
  | DashRouteType<'products-view-store-locations', { productId: string }>
  | DashRouteType<'products-view-aisles', { productId: string }>
  | DashRouteType<'products-search', { productId: string }>
  | DashRouteType<'products-view-attribute-overrides'>
  | DashRouteType<'products-view-collections'>
  | DashRouteType<'item-anomaly-reports'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  products: URL_PRODUCTS,
  'products-view': URL_PRODUCTS_VIEW,
  'products-view-details': URL_PRODUCTS_VIEW_DETAILS,
  'products-edit-images': URL_PRODUCTS_EDIT_IMAGES,
  'products-view-store-locations': URL_PRODUCTS_VIEW_STORE_LOCATIONS,
  'products-view-aisles': URL_PRODUCTS_VIEW_AISLES,
  'products-search': URL_PRODUCTS_SEARCH,
  'products-view-attribute-overrides': URL_PRODUCTS_VIEW_ATTRIBUTE_OVERRIDES,
  'products-view-collections': URL_PRODUCTS_VIEW_COLLECTIONS,
  'item-anomaly-reports': URL_ITEM_ANOMALY_REPORTS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
