// eslint-disable-next-line workspaces/no-relative-imports
import { ShiftType } from '../../../../../domains/shifts/src/components/types'
import { type WarehouseLocation, FulfillmentMethod, PickingLabor } from './types'

export enum WarehouseLocationFeature {
  RetailerPickup = 'retailer-pickup',
  RetailerAlcoholDelivery = 'retailer-alcohol-delivery',
}

export function locationManagesShifts(location: WarehouseLocation) {
  return getLocationFeatures(location).some(
    feature =>
      feature === WarehouseLocationFeature.RetailerPickup ||
      feature === WarehouseLocationFeature.RetailerAlcoholDelivery
  )
}

export function getLocationFeatures(
  location: WarehouseLocation | WarehouseLocation[]
): WarehouseLocationFeature[] {
  if (Array.isArray(location)) {
    const locationFeaturesSet = new Set<WarehouseLocationFeature>()

    location.forEach(n =>
      getLocationFeatures(n).forEach(feature => locationFeaturesSet.add(feature))
    )

    return [...locationFeaturesSet]
  }

  return [
    locationHasPickupAccess(location) && WarehouseLocationFeature.RetailerPickup,
    locationDoesAlcoholDelivery(location) && WarehouseLocationFeature.RetailerAlcoholDelivery,
  ].filter(Boolean) as WarehouseLocationFeature[]
}

export function locationDoesAlcoholDelivery(location: WarehouseLocation) {
  return location.partnerAlcoholDelivery
}

export function locationHasPickupAccess(location: WarehouseLocation) {
  // Only has pickup access if picking or fulfillment is done by the retailer
  return locationDoesPicking(location) || locationDoesRunning(location)
}

function locationHasHybridPicking(location: WarehouseLocation) {
  return location.configValues && location.configValues.hybridPickingEnabled
}

export function locationDoesPicking(location: WarehouseLocation) {
  if (locationHasHybridPicking(location)) {
    return true
  }

  return location.pickingLabor === PickingLabor.Retailer
}

export function locationDoesRunning(location: WarehouseLocation) {
  return Boolean(
    location.fulfillmentMethods && location.fulfillmentMethods.includes(FulfillmentMethod.Retailer)
  )
}

export function getLocationShiftTypes(
  location: WarehouseLocation,
  platformOnlyEnabled?: boolean
): ShiftType[] {
  return [
    locationDoesPicking(location) && ShiftType.Picking,
    locationDoesRunning(location) && ShiftType.Running,
    locationDoesAlcoholDelivery(location) && ShiftType.FullService,
    platformOnlyEnabled && ShiftType.Driving,
  ].filter(Boolean) as ShiftType[]
}

export function buildLocationDropdownOption(location: WarehouseLocation) {
  return {
    label: location.name || location.street,
    value: location.id.toString(),
  }
}
