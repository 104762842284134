export enum Dimensions {
  ORDER_ID = 'ORDER_ID',
  ORDER_PLACED_DATE_PT = 'ORDER_PLACED_DATE_PT',
  WORKFLOW_STATE = 'WORKFLOW_STATE',
  ITEM_NAME = 'ITEM_NAME',
  RETAILER_REFERENCE_CODE = 'RETAILER_REFERENCE_CODE',
  ORDERED_QTY = 'ORDERED_QTY',
}

export enum GroupBys {
  ORDER_ID = 'ORDER_ID',
  ORDER_PLACED_DATE_PT = 'ORDER_PLACED_DATE_PT',
  WORKFLOW_STATE = 'WORKFLOW_STATE',
  ITEM_NAME = 'ITEM_NAME',
  RETAILER_REFERENCE_CODE = 'RETAILER_REFERENCE_CODE',
  ORDERED_QTY = 'ORDERED_QTY',
}
