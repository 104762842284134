const EN_US = {
  'credentialsManagementDomain.title': 'Credentials Management',
  'credentialsManagementDomain.apiKeysPage.title': 'API keys',
  'credentialsManagementDomain.apiKeysPage.createApiKeyButton.text': 'Create API key',
  'credentialsManagementDomain.apiKeysPage.notifications.apiKeyLocked.text':
    'The API key has been created.',
  'credentialsManagementDomain.apiKeysGrid.emptyState.title': 'Create your first API key',
  'credentialsManagementDomain.apiKeysGrid.emptyState.description': 'Create your first API key',
  'credentialsManagementDomain.apiKeysGrid.emptyState.link.text': 'Learn more about API keys',
  'credentialsManagementDomain.apiKeysGrid.columns.name': 'Name',
  'credentialsManagementDomain.apiKeysGrid.columns.environment': 'Environment',
  'credentialsManagementDomain.apiKeysGrid.columns.status': 'Status',
  'credentialsManagementDomain.apiKeysGrid.columns.created': 'Created',
  'credentialsManagementDomain.apiKeysGrid.columns.expiry': 'Expiry',
  'credentialsManagementDomain.apiKeys.environments.development': 'Development',
  'credentialsManagementDomain.apiKeys.environments.production': 'Production',
  'credentialsManagementDomain.apiKeys.statuses.active': 'Active',
  'credentialsManagementDomain.apiKeys.statuses.expired': 'Expired',
  'credentialsManagementDomain.apiKeys.statuses.pendingApproval': 'Pending approval',
  'credentialsManagementDomain.apiKeys.statuses.revoked': 'Revoked',
  'credentialsManagementDomain.apiKeysGrid.revokeButton.text': 'Revoke',
  'credentialsManagementDomain.createApiKeyModal.title': 'Create an API key',
  'credentialsManagementDomain.createApiKeyModal.cancelButton.text': 'Cancel',
  'credentialsManagementDomain.createApiKeyModal.createButton.text': 'Create',
  'credentialsManagementDomain.createApiKeyModal.fields.name.label': 'Name',
  'credentialsManagementDomain.createApiKeyModal.fields.name.description':
    'Name your API key for easier reference.',
  'credentialsManagementDomain.createApiKeyModal.fields.environment.label': 'Environment',
  'credentialsManagementDomain.createApiKeyModal.fields.environment.development.label':
    'Development',
  'credentialsManagementDomain.createApiKeyModal.fields.environment.development.description':
    'Build and test your API calls in a sandbox environment.',
  'credentialsManagementDomain.createApiKeyModal.fields.environment.production.label': 'Production',
  'credentialsManagementDomain.createApiKeyModal.fields.environment.production.description':
    'Prepare to launch your integrated application. Requires Instacart approval.',
  'credentialsManagementDomain.createApiKeyModal.disclaimer': 'By proceeding, you agree to the ',
  'credentialsManagementDomain.createApiKeyModal.terms': 'Instacart Developer Platform Terms',
  'credentialsManagementDomain.createApiKeyModal.and': ' and ',
  'credentialsManagementDomain.createApiKeyModal.privacy': ' Privacy Policy',
  'credentialsManagementDomain.createApiKeyModal.period': '.',
  'credentialsManagementDomain.newApiKeyModal.title': 'Your new API key',
  'credentialsManagementDomain.newApiKeyModal.pendingApproval.title': 'Pending Instacart approval',
  'credentialsManagementDomain.newApiKeyModal.pendingApproval.text':
    "When you create a production API key, Instacart reviews your app integration. After the review is complete, you'll receive an email. When the app is approved, your API key will be activated.",
  'credentialsManagementDomain.newApiKeyModal.pendingApproval.link.text': 'Learn more',
  'credentialsManagementDomain.newApiKeyModal.fields.name.label': 'Name',
  'credentialsManagementDomain.newApiKeyModal.fields.environment.label': 'Environment',
  'credentialsManagementDomain.newApiKeyModal.fields.status.label': 'Status',
  'credentialsManagementDomain.newApiKeyModal.fields.apiKey.label': 'API key',
  'credentialsManagementDomain.newApiKeyModal.fields.apiKey.copyButton.text': 'Copy',
  'credentialsManagementDomain.newApiKeyModal.fields.apiKey.warning.text':
    'Save this key! For security reasons, you won’t be able to view this key once you close this window.',
  'credentialsManagementDomain.newApiKeyModal.startBuilding.title': 'Start building',
  'credentialsManagementDomain.newApiKeyModal.test.title': 'Test your integration',
  'credentialsManagementDomain.newApiKeyModal.test.text':
    'Follow a tutorial to learn how to integrate your application.',
  'credentialsManagementDomain.newApiKeyModal.test.link.text': 'View documentation',
  'credentialsManagementDomain.newApiKeyModal.confirmCheckbox.label':
    'I confirm that I have saved the key.',
  'credentialsManagementDomain.newApiKeyModal.closeButton.text': 'Close this window',
  'credentialsManagementDomain.newApiKeyModal.notifications.copied.text': 'Copied.',
  'credentialsManagementDomain.revokeApiKeyModal.title': 'Revoke this API key?',
  'credentialsManagementDomain.revokeApiKeyModal.warning.text':
    'After you revoke an API key, requests made with that API key will fail. To avoid downtime, ensure that you update your application with a new API key before revoking an old API key.',
  'credentialsManagementDomain.revokeApiKeyModal.fields.name.label': 'Name',
  'credentialsManagementDomain.revokeApiKeyModal.fields.environment.label': 'Environment',
  'credentialsManagementDomain.revokeApiKeyModal.fields.confirm.label':
    'Type the name of the key to confirm',
  'credentialsManagementDomain.revokeApiKeyModal.fields.confirm.description':
    "You can't restore a revoked API key.",
  'credentialsManagementDomain.revokeApiKeyModal.cancelButton.text': 'Cancel',
  'credentialsManagementDomain.revokeApiKeyModal.revokeButton.text': 'Revoke',
  'credentialsManagementDomain.apiKeyApprovalRequestsPage.title': 'API key approval requests',
} as const

export default EN_US
