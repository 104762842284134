import React, { useState, type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { AccountManagementMyAccountModal } from '@retailer-platform/domain-account-management'
import icPlatformLogo from '../assets/ic-platform.svg'
import { spacing } from '../../../foundation/spacing'
import { type NavMenuHierarchy } from '../NavMenuHierarchy'
import SelectPartner from '../../../common/select-partner/SelectPartner'
import { useNavContext } from '../../nav/utils/NavContext'
import { NavMenuTopDrawer } from './NavMenuTop/NavMenuTopDrawer'
import { NavMenuLeftDrawer } from './NavMenuLeftDrawer/NavMenuLeftDrawer'
import { MENU_BAR_Z_INDEX, navBarHeight } from './navBar.constants'
import { UserSettingsMenuRetailer } from './NavUserMenu/UserSettingsMenuRetailer'
import { UserSettingsMenuAdmin } from './NavUserMenu/UserSettingsMenuAdmin'
import { NavLink } from './NavLink'
import { NavSearchButton } from './NavSearchButton'

type Props = {
  navMenuHierarchy: NavMenuHierarchy
}

type ContainerProps = {
  navBarColor: string
}

const Container = styled.div<ContainerProps>(({ navBarColor }) => ({
  width: '100vw',
  position: 'relative',
  height: navBarHeight,
  backgroundColor: navBarColor,
  display: 'flex',
  alignItems: 'center',
  zIndex: MENU_BAR_Z_INDEX,
  paddingLeft: '8px',
  flexShrink: 0,
}))

const Image = styled.img({
  marginRight: spacing.X8,
  height: '18px',
  width: '179px',
  margin: '1px 24px 0px 16px',
})

const MenuBarTopContext = React.createContext<{
  setShowPartnerSelector: (value: boolean) => void
  setShowManageAccount: (value: boolean) => void
}>({
  setShowPartnerSelector: () => {},
  setShowManageAccount: () => {},
})

export const useMenuBarTopContext = () => React.useContext(MenuBarTopContext)

export const MenuBarTop: FunctionComponent<React.PropsWithChildren<Props>> = ({
  navMenuHierarchy,
}) => {
  const [currentlySelectedItem, setCurrentlySelectedItem] = useState<number>(null)
  const [showPartnerSelector, setShowPartnerSelector] = useState(false)
  const [showManageAccount, setShowManageAccount] = useState(false)

  const { setMostRecentClickedRoute } = useNavContext()
  const [leftDrawerOpened, setLeftDrawerOpened] = useState(false)

  const width = window.innerWidth
  const wideScreen = width >= 1024

  const settingsMenu =
    navMenuHierarchy.type == 'admin' ? (
      <UserSettingsMenuAdmin
        setShowPartnerSelector={setShowPartnerSelector}
        setCurrentlySelectedItem={setCurrentlySelectedItem}
        navMenuHierarchy={navMenuHierarchy}
      />
    ) : (
      <UserSettingsMenuRetailer
        setShowPartnerSelector={setShowPartnerSelector}
        setCurrentlySelectedItem={setCurrentlySelectedItem}
        setShowManageAccount={setShowManageAccount}
        navMenuHierarchy={navMenuHierarchy}
      />
    )

  return (
    <MenuBarTopContext.Provider value={{ setShowPartnerSelector, setShowManageAccount }}>
      <Container navBarColor={navMenuHierarchy.navBarColor}>
        {!wideScreen && (
          <NavMenuLeftDrawer
            navMenuHierarchy={navMenuHierarchy}
            currentlySelectedItem={currentlySelectedItem}
            setCurrentlySelectedItem={setCurrentlySelectedItem}
            leftDrawerOpened={leftDrawerOpened}
            setLeftDrawerOpened={setLeftDrawerOpened}
            navBarColorHover={navMenuHierarchy.navBarColorHover}
          />
        )}

        <NavLink
          onClick={() => {
            setMostRecentClickedRoute(null)
          }}
          route={navMenuHierarchy.homeRoute}
        >
          <Image
            src={icPlatformLogo}
            onClick={() => {
              setCurrentlySelectedItem(null)
              setLeftDrawerOpened(false)
            }}
          />
        </NavLink>

        <NavSearchButton navMenuHierarchy={navMenuHierarchy} />

        {wideScreen && (
          <NavMenuTopDrawer
            navMenuHierarchy={navMenuHierarchy}
            currentlySelectedItem={currentlySelectedItem}
            setCurrentlySelectedItem={setCurrentlySelectedItem}
          />
        )}
        <div css={{ marginLeft: 'auto', marginRight: '16px' }}>{settingsMenu}</div>
      </Container>
      {showPartnerSelector && (
        <SelectPartner
          handleClose={() => {
            setShowPartnerSelector(false)
          }}
        />
      )}
      <AccountManagementMyAccountModal
        isOpen={showManageAccount}
        onClose={() => {
          setShowManageAccount(false)
        }}
      />
    </MenuBarTopContext.Provider>
  )
}
