import matchSorter from 'match-sorter'
import { useMemo } from 'react'
import {
  type DataFetchingState,
  dataFetchingSuccess,
} from '../../../common/utils/data-fetching/dataFetching.types'
import { type WarehouseLocation } from '../../../common/types'
import { useWarehouseLocations } from '../../../common/utils/warehouse-locations/locations.hooks'

export interface UseFilteredWarehouseLocationsParams {
  filter?: string
  idFilter?: Set<string>
}

type Result = DataFetchingState<WarehouseLocation[]>

export const useFilteredWarehouseLocations = ({
  filter,
  idFilter,
}: UseFilteredWarehouseLocationsParams): Result => {
  const warehouseLocationState = useWarehouseLocations({ active: false })

  const { data, loading, error } = warehouseLocationState

  const resultingData = useMemo(() => {
    if (loading || error || !data) {
      return []
    }

    let filteredData = data

    if (idFilter) {
      filteredData = filteredData.filter(({ id }) => idFilter.has(id))
    }

    if (filter && filter !== '') {
      filteredData = matchSorter(filteredData, filter, {
        keys: ['locationCode', 'name', 'street', 'city', 'state', 'zipCode'],
      })
    }

    return filteredData
  }, [data, loading, error, filter, idFilter])

  if (loading || error) return warehouseLocationState

  return dataFetchingSuccess<WarehouseLocation[]>(resultingData)
}
