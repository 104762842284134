import React, { useCallback, forwardRef } from 'react'
import { ShownIcon, HiddenIcon } from '@instacart/ids-core'
import { useBooleanState } from '../../hooks/useStateEnum.hooks'
import { DecoratedInput, type DecoratedInputProps } from '../decorated-input/DecoratedInput'

export type PasswordInputProps = Omit<DecoratedInputProps, 'decorationComponent' | 'type'> & {
  'data-testid'?: string
}

/**
 * An input component for passwords. It allows toggling the visiblity of the contents of the password as needed.
 * This components exposes its input ref.
 * @param props
 * @returns
 */
export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
  const [isVisible, setVisible, setHidden] = useBooleanState(false)

  // The component is dynamic to allow onClick and being able to access local state
  const Decoration = useCallback(
    ({ className }: { className?: string }) => {
      const props = {
        className,
        'data-testid': 'password-input-decoration',
        onClick: isVisible ? setHidden : setVisible,
      }

      return isVisible ? <ShownIcon {...props} /> : <HiddenIcon {...props} />
    },
    [isVisible, setVisible, setHidden]
  )

  return (
    <DecoratedInput
      decorationPosition="right"
      {...props}
      type={isVisible ? 'text' : 'password'}
      decorationComponent={Decoration}
      ref={ref}
    />
  )
})
