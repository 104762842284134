import React, { useMemo } from 'react'
import { getTargetedPortal } from '@retailer-platform/shared-components'
import { useStoreConfigurations } from '../../../api/store-configurations/useStoreConfigurations.hooks'
import { StoreConfigurationRelationshipEnum } from '../../../__codegen__/api'
import RetailerAndSitePicker from '../retailer-and-site-picker/RetailerAndSitePicker'
import { type SiteOperationIntent } from '../../common/site-select/SiteSelect'
import { type FilterSitesFn } from '../retailer-and-site-picker/RetailerAndSitePicker.types'
import { MARKETPLACE_SITE_ID } from '../retailer-and-site-picker/CommonSiteFilters'
import { usePartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'
import { ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'

export interface RetailerScopeWrapperProps {
  selectionType?: ViewType
  retailerSelectionOptional?: boolean
  storeConfigOperationIntent?: SiteOperationIntent // 'view' or 'edit'
  storeConfigFilter?: FilterSitesFn // store config filters
  retailerInsufficientPermission?: boolean
  hiddenScopePicker?: boolean
}

export const [HeaderTitlePortalTarget, HeaderTitlePortal] = getTargetedPortal()
export const [HeaderToolsPortalTarget, HeaderToolsPortal] = getTargetedPortal()
export const [HeaderExtraContentPortalTarget, HeaderExtraContentPortal] = getTargetedPortal()

export const RetailerAndSitePickerWrapper = ({
  retailerSelectionOptional,
  selectionType,
  storeConfigOperationIntent,
  storeConfigFilter,
  retailerInsufficientPermission,
  hiddenScopePicker,
}: RetailerScopeWrapperProps) => {
  const { warehouses: availableWarehouses } = usePartnerContext()

  const storeConfigurations = useStoreConfigurations({
    retailerIds: availableWarehouses?.map(e => e.id.toString()),
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
  })

  // STORE_CONFIG only is not supported in the old scope picker, so we need to map it to RETAILER_AND_STORE_CONFIG
  if (selectionType == ViewType.STORE_CONFIG) {
    selectionType = ViewType.RETAILER_AND_STORE_CONFIG
  }

  selectionType = selectionType || ViewType.RETAILER

  // if scope picker is optional, then override retailerSelectionOptional, storeConfigSelectionOptional
  retailerSelectionOptional ||= hiddenScopePicker

  const storeConfigs = storeConfigurations?.data?.storeConfigurationsByRetailerIds
  const dropdownWarehouseOptions = useMemo(() => {
    if (!storeConfigs || !availableWarehouses) {
      return []
    }

    availableWarehouses.forEach(e => {
      e.storeConfigurations = storeConfigs.filter(
        storeConfig =>
          storeConfig.whitelistedWarehouseIds.includes(e.id.toString()) ||
          storeConfig.id === MARKETPLACE_SITE_ID
      )
    })

    return availableWarehouses?.map(e => ({
      context: e.context,
      label: e.name,
      value: String(e.id),
      storeConfigurations: e.storeConfigurations,
      logo: e.logo,
    }))
  }, [storeConfigs, availableWarehouses])

  return (
    <RetailerAndSitePicker
      options={dropdownWarehouseOptions}
      selectionType={selectionType}
      retailerSelectionOptional={retailerSelectionOptional}
      storeConfigOperationIntent={storeConfigOperationIntent}
      storeConfigFilter={storeConfigFilter}
      retailerInsufficientPermission={retailerInsufficientPermission}
    />
  )
}
