import React from 'react'
import { themer, colors } from 'ic-snacks'
import styled from '@emotion/styled'
import LinkBase from './LinkBase'

interface Props extends React.ComponentPropsWithoutRef<typeof LinkBase> {
  underline?: boolean
}

const Link = styled<React.FunctionComponent<Props>>(({ underline, ...rest }) => (
  <LinkBase {...rest} />
))`
  color: ${props => (props.disabled ? colors.GRAY_46 : themer.get('colors', 'primaryForeground'))};
  &:hover {
    color: ${props => (props.disabled ? 'inherit' : colors.GRAY_20)};
  }
  cursor: pointer;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-size: 100%;
`

export default Link
