const EN_US = {
  'recipesAdminDomain.library.csv-ingestion': 'Recipe admin CSV ingestion',
  'recipesAdminDomain.library.library-manager': 'Recipe admin library manager',
  'recipesAdminDomain.library.library-manager.search.recipe-id': 'Recipe Id',
  'recipesAdminDomain.library.library-manager.search.go': 'Go',
  'recipesAdminDomain.library.library-manager.recipe-details.save': 'Save',
  'recipesAdminDomain.library.library-manager.recipe-details.recipe-saved': 'Recipe Saved',
} as const

export default EN_US
