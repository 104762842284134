export enum Metrics {
  CAMPAIGN_BUDGET = 'CAMPAIGN_BUDGET',
  RATIO_BASKET_SPEND_LIFT = 'RATIO_BASKET_SPEND_LIFT',
  AVG_UNIT_SPEND_LIFT = 'AVG_UNIT_SPEND_LIFT',
  SUM_USERS = 'SUM_USERS',
  SUM_CAMPAIGN_SPEND = 'SUM_CAMPAIGN_SPEND',
  SUM_REDEMPTIONS = 'SUM_REDEMPTIONS',
  FACT_CI_SUM_IMPRESSIONS = 'FACT_CI_SUM_IMPRESSIONS',
  FACT_CI_SUM_IMPRESSIONS_PCT_DIFF = 'FACT_CI_SUM_IMPRESSIONS_PCT_DIFF',
  SUM_ATTRIBUTED_SALES = 'SUM_ATTRIBUTED_SALES',
  RETURN_ON_AD_SPEND = 'RETURN_ON_AD_SPEND',
  AVG_DAILY_CAMPAIGN_SPEND = 'AVG_DAILY_CAMPAIGN_SPEND',
  SUM_APPLICATIONS = 'SUM_APPLICATIONS',
  APPLICATION_RATE = 'APPLICATION_RATE',
  SUM_RESURRECTIONS = 'SUM_RESURRECTIONS',
  SUM_ACTIVATIONS = 'SUM_ACTIVATIONS',
  AVG_UNIT_SPEND_WITH_PROMO = 'AVG_UNIT_SPEND_WITH_PROMO',
  COUNT_RETAINED_CUSTOMERS = 'COUNT_RETAINED_CUSTOMERS',
  COUNT_LOYALTY_SIGNUPS = 'COUNT_LOYALTY_SIGNUPS',
  REDEMPTION_RATE = 'REDEMPTION_RATE',
  REORDER_RATE_LIFETIME = 'REORDER_RATE_LIFETIME',
  REORDER_RATE_7_DAY = 'REORDER_RATE_7_DAY',
  REORDER_RATE_28_DAY = 'REORDER_RATE_28_DAY',
  AVG_COST_PER_ACTIVATION = 'AVG_COST_PER_ACTIVATION',
  SUM_IMPRESSIONS = 'SUM_IMPRESSIONS',
  NUM_ACTIVE_CAMPAIGNS = 'NUM_ACTIVE_CAMPAIGNS',
  NUM_ENDING_SOON_CAMPAIGNS = 'NUM_ENDING_SOON_CAMPAIGNS',
  AVG_COST_PER_ORDER = 'AVG_COST_PER_ORDER',
  PCT_BASKET_LIFT = 'PCT_BASKET_LIFT',
  PCT_UNIT_LIFT = 'PCT_UNIT_LIFT',
  AVG_UNIT_LIFT = 'AVG_UNIT_LIFT',
  AVG_BASKET_SPEND_WITH_PROMO = 'AVG_BASKET_SPEND_WITH_PROMO',
  AVG_BASKET_SPEND_LIFT = 'AVG_BASKET_SPEND_LIFT',
  SUM_CAMPAIGN_SPEND_OUTSTANDING = 'SUM_CAMPAIGN_SPEND_OUTSTANDING',
  SUM_ORDERS_OUTSTANDING = 'SUM_ORDERS_OUTSTANDING',
  OFFER_CONVERSION_RATE = 'OFFER_CONVERSION_RATE',
  AVG_COST_PER_USER = 'AVG_COST_PER_USER',
  NUM_ORDERS_OFFERS = 'NUM_ORDERS_OFFERS',
  RATIO_AVG_UNIT_SPEND_LIFT = 'RATIO_AVG_UNIT_SPEND_LIFT',
}

export enum GroupBys {
  DIM_CAMPAIGN_NAME = 'DIM_CAMPAIGN_NAME',
  DIM_USER_SEGMENT_CAMPAIGN_TYPE = 'DIM_USER_SEGMENT_CAMPAIGN_TYPE',
  DIM_CAMPAIGN_END_DATE = 'DIM_CAMPAIGN_END_DATE',
  DIM_CAMPAIGN_START_DATE = 'DIM_CAMPAIGN_START_DATE',
  DIM_CAMPAIGN_OBJECTIVE = 'DIM_CAMPAIGN_OBJECTIVE',
  USER_SEGMENT = 'USER_SEGMENT',
  TAAS_USER_SEGMENT_CAMPAIGN_TYPE = 'TAAS_USER_SEGMENT_CAMPAIGN_TYPE',
  DIM_CAMPAIGN_STATUS = 'DIM_CAMPAIGN_STATUS',
  RFM_DATE_PT = 'RFM_DATE_PT',
  DATE_PT = 'DATE_PT',
  ORDER_SOURCE = 'ORDER_SOURCE',
  MONTH_PT = 'MONTH_PT',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  REGION = 'WAREHOUSE_LOCATION_REGION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  ZONE_STATE = 'ZONE_STATE',
  WAREHOUSE_LOCATION_LATITUDE = 'WAREHOUSE_LOCATION_LATITUDE',
  WAREHOUSE_LOCATION_LONGITUDE = 'WAREHOUSE_LOCATION_LONGITUDE',
  CAMPAIGN_TYPE = 'CAMPAIGN_TYPE',
  CAMPAIGN_ID = 'CAMPAIGN_ID',
  DELIVERY_STATE = 'DELIVERY_STATE',
}
