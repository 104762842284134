// Link to standardized file for cost_unit:
// https://github.com/instacart/carrot/blob/071b066e8bc614fd4b8d0fc5e994abe1d1a32ed6/catalog/data-ingestion/app/services/catalog/inventory/normalization/column_maps/dsl.rb#L6
//
// Link to standardized file for priced_by / converisons
// https://github.com/instacart/carrot/blob/master/catalog/catalog/app/services/items/update_by_priced_by_service.rb#L65

import { type SimpleOption } from '../../../dash-blocks/dash-select/utils/select.types'
import { errors } from '../../../../utils/error-handling/errors'

export const COST_UNIT = {
  EACH: 'each',
  POUND: 'lb',
  KILOGRAM: 'kg',
} as const

export type CostUnit = ValueOf<typeof COST_UNIT>

export const PRICED_BY = {
  EACH: 'each',
  POUND: 'per_lb',
  KILOGRAM: 'per_kg',
} as const

export type PricedBy = ValueOf<typeof PRICED_BY>

type CostUnitOptions = SimpleOption<CostUnit>

export const COST_UNIT_OPTIONS: CostUnitOptions[] = [
  {
    label: 'each',
    value: COST_UNIT.EACH,
  },
  {
    label: 'per lb',
    value: COST_UNIT.POUND,
  },
  {
    label: 'per kg',
    value: COST_UNIT.KILOGRAM,
  },
]

const VALID_EACH_COST_UNITS: string[] = [COST_UNIT.EACH, PRICED_BY.EACH]
const VALID_POUND_COST_UNITS: string[] = [COST_UNIT.POUND, PRICED_BY.POUND]
const VALID_KILOGRAM_COST_UNITS: string[] = [COST_UNIT.KILOGRAM, PRICED_BY.KILOGRAM]

export const parseCostUnit = (str: string): CostUnit => {
  const value = str.trim()

  if (VALID_EACH_COST_UNITS.includes(value)) return COST_UNIT.EACH
  if (VALID_POUND_COST_UNITS.includes(value)) return COST_UNIT.POUND
  if (VALID_KILOGRAM_COST_UNITS.includes(value)) return COST_UNIT.KILOGRAM

  // Fall back to `each`
  errors.captureMessage(
    `Unable to parse cost_unit => '${str}'. Falling back to '${COST_UNIT.EACH}'`,
    { level: 'warning' }
  )
  return COST_UNIT.EACH
}
