import { useTour } from '@reactour/tour'
import { useCallback } from 'react'
import { useDashMessages } from '../../intl/intl.hooks'
import { type TourStep } from './tour.types'
import { mapStepConfigsToSteps } from './tour.utils'

export function useRPPTour(): Omit<ReturnType<typeof useTour>, 'setSteps'> & {
  setSteps: (stepConfigs: TourStep[]) => void
} {
  const { setSteps: _setSteps, setIsOpen, setCurrentStep, ...rest } = useTour()
  const messages = useDashMessages({
    nextStepButtonA11yLabel: 'tour.a11y.nextStepButton',
    prevStepButtonA11yLabel: 'tour.a11y.prevStepButton',
    nextStepButton: 'tour.label.nextStepButton',
    doneStepButton: 'tour.label.doneStepButton',
    exitStepButton: 'tour.a11y.exitStepButton',
  })

  const setSteps = useCallback(
    (stepConfigs: TourStep[]) => {
      const steps = mapStepConfigsToSteps(stepConfigs, messages)
      _setSteps(steps)
      setIsOpen(true)
      setCurrentStep(0)
    },
    [_setSteps, setCurrentStep, setIsOpen, messages]
  )

  return { setSteps, setIsOpen, setCurrentStep, ...rest }
}
