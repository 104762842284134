import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const autosuggestViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.AutosuggestView],
}

export const autosuggestModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.AutosuggestModify],
}
