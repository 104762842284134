/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from 'react'
import { useDomainMessages } from './domain/intl'

export const useCountryOptions = () => {
  const messages = useDomainMessages({
    usa: 'onboardingDomain.country.usa',
    can: 'onboardingDomain.country.can',
  })

  return useMemo(
    () => [
      {
        label: messages.usa,
        value: 'usa',
      },
      {
        label: messages.can,
        value: 'can',
      },
    ],
    [messages]
  )
}

export const useCountryMapping = () =>
  useDomainMessages({
    usa: 'onboardingDomain.country.usa',
    can: 'onboardingDomain.country.can',
  })

export const COUNTRY = {
  USA: 'usa',
  CANADA: 'can',
}

// TODO: refactor with partner onboarding
export const useUsStates = (defaultOptionLabel?: string) => {
  const messages = useDomainMessages({
    alabama: 'onboardingDomain.usa.states.alabama',
    alaska: 'onboardingDomain.usa.states.alaska',
    arizona: 'onboardingDomain.usa.states.arizona',
    arkansas: 'onboardingDomain.usa.states.arkansas',
    california: 'onboardingDomain.usa.states.california',
    colorado: 'onboardingDomain.usa.states.colorado',
    connecticut: 'onboardingDomain.usa.states.connecticut',
    delaware: 'onboardingDomain.usa.states.delaware',
    districtOfColumbia: 'onboardingDomain.usa.states.districtOfColumbia',
    florida: 'onboardingDomain.usa.states.florida',
    georgia: 'onboardingDomain.usa.states.georgia',
    guam: 'onboardingDomain.usa.states.guam',
    hawaii: 'onboardingDomain.usa.states.hawaii',
    idaho: 'onboardingDomain.usa.states.idaho',
    illinois: 'onboardingDomain.usa.states.illinois',
    indiana: 'onboardingDomain.usa.states.indiana',
    iowa: 'onboardingDomain.usa.states.iowa',
    kansas: 'onboardingDomain.usa.states.kansas',
    kentucky: 'onboardingDomain.usa.states.kentucky',
    louisiana: 'onboardingDomain.usa.states.louisiana',
    maine: 'onboardingDomain.usa.states.maine',
    maryland: 'onboardingDomain.usa.states.maryland',
    massachusetts: 'onboardingDomain.usa.states.massachusetts',
    michigan: 'onboardingDomain.usa.states.michigan',
    minnesota: 'onboardingDomain.usa.states.minnesota',
    mississippi: 'onboardingDomain.usa.states.mississippi',
    missouri: 'onboardingDomain.usa.states.missouri',
    montana: 'onboardingDomain.usa.states.montana',
    nebraska: 'onboardingDomain.usa.states.nebraska',
    nevada: 'onboardingDomain.usa.states.nevada',
    newHampshire: 'onboardingDomain.usa.states.newHampshire',
    newJersey: 'onboardingDomain.usa.states.newJersey',
    newMexico: 'onboardingDomain.usa.states.newMexico',
    newYork: 'onboardingDomain.usa.states.newYork',
    northCarolina: 'onboardingDomain.usa.states.northCarolina',
    northDakota: 'onboardingDomain.usa.states.northDakota',
    ohio: 'onboardingDomain.usa.states.ohio',
    oklahoma: 'onboardingDomain.usa.states.oklahoma',
    oregon: 'onboardingDomain.usa.states.oregon',
    pennsylvania: 'onboardingDomain.usa.states.pennsylvania',
    rhodeIsland: 'onboardingDomain.usa.states.rhodeIsland',
    southCarolina: 'onboardingDomain.usa.states.southCarolina',
    southDakota: 'onboardingDomain.usa.states.southDakota',
    tennessee: 'onboardingDomain.usa.states.tennessee',
    texas: 'onboardingDomain.usa.states.texas',
    utah: 'onboardingDomain.usa.states.utah',
    vermont: 'onboardingDomain.usa.states.vermont',
    virginia: 'onboardingDomain.usa.states.virginia',
    washington: 'onboardingDomain.usa.states.washington',
    westVirginia: 'onboardingDomain.usa.states.westVirginia',
    wisconsin: 'onboardingDomain.usa.states.wisconsin',
    wyoming: 'onboardingDomain.usa.states.wyoming',
  })

  return useMemo(() => {
    const options = [
      { label: messages.alabama, value: 'AL' },
      { label: messages.alaska, value: 'AK' },
      { label: messages.arizona, value: 'AZ' },
      { label: messages.arkansas, value: 'AR' },
      { label: messages.california, value: 'CA' },
      { label: messages.colorado, value: 'CO' },
      { label: messages.connecticut, value: 'CT' },
      { label: messages.delaware, value: 'DE' },
      {
        label: messages.districtOfColumbia,
        value: 'DC',
      },
      { label: messages.florida, value: 'FL' },
      { label: messages.georgia, value: 'GA' },
      { label: messages.guam, value: 'GU' },
      { label: messages.hawaii, value: 'HI' },
      { label: messages.idaho, value: 'ID' },
      { label: messages.illinois, value: 'IL' },
      { label: messages.indiana, value: 'IN' },
      { label: messages.iowa, value: 'IA' },
      { label: messages.kansas, value: 'KS' },
      { label: messages.kentucky, value: 'KY' },
      { label: messages.louisiana, value: 'LA' },
      { label: messages.maine, value: 'ME' },
      { label: messages.maryland, value: 'MD' },
      { label: messages.massachusetts, value: 'MA' },
      { label: messages.michigan, value: 'MI' },
      { label: messages.minnesota, value: 'MN' },
      { label: messages.mississippi, value: 'MS' },
      { label: messages.missouri, value: 'MO' },
      { label: messages.montana, value: 'MT' },
      { label: messages.nebraska, value: 'NE' },
      { label: messages.nevada, value: 'NV' },
      { label: messages.newHampshire, value: 'NH' },
      { label: messages.newJersey, value: 'NJ' },
      { label: messages.newMexico, value: 'NM' },
      { label: messages.newYork, value: 'NY' },
      { label: messages.northCarolina, value: 'NC' },
      { label: messages.northDakota, value: 'ND' },
      { label: messages.ohio, value: 'OH' },
      { label: messages.oklahoma, value: 'OK' },
      { label: messages.oregon, value: 'OR' },
      { label: messages.pennsylvania, value: 'PA' },
      { label: messages.rhodeIsland, value: 'RI' },
      { label: messages.southCarolina, value: 'SC' },
      { label: messages.southDakota, value: 'SD' },
      { label: messages.tennessee, value: 'TN' },
      { label: messages.texas, value: 'TX' },
      { label: messages.utah, value: 'UT' },
      { label: messages.vermont, value: 'VT' },
      { label: messages.virginia, value: 'VA' },
      { label: messages.washington, value: 'WA' },
      { label: messages.westVirginia, value: 'WV' },
      { label: messages.wisconsin, value: 'WI' },
      { label: messages.wyoming, value: 'WY' },
    ]

    if (defaultOptionLabel) {
      options.unshift({ label: defaultOptionLabel, value: '' })
    }

    return options
  }, [messages, defaultOptionLabel])
}

export const useCanadaStates = (defaultOptionLabel?: string) => {
  const messages = useDomainMessages({
    alberta: 'onboardingDomain.canada.states.alberta',
    britishColumbia: 'onboardingDomain.canada.states.britishColumbia',
    manitoba: 'onboardingDomain.canada.states.manitoba',
    newBrunswick: 'onboardingDomain.canada.states.newBrunswick',
    newfoundlandAndLabrador: 'onboardingDomain.canada.states.newfoundlandAndLabrador',
    northwestTerritories: 'onboardingDomain.canada.states.northwestTerritories',
    novaScotia: 'onboardingDomain.canada.states.novaScotia',
    nunavut: 'onboardingDomain.canada.states.nunavut',
    ontario: 'onboardingDomain.canada.states.ontario',
    princeEdwardIsland: 'onboardingDomain.canada.states.princeEdwardIsland',
    quebec: 'onboardingDomain.canada.states.quebec',
    saskatchewan: 'onboardingDomain.canada.states.saskatchewan',
    yukonTerritory: 'onboardingDomain.canada.states.yukonTerritory',
  })

  return useMemo(() => {
    const options = [
      { label: messages.alberta, value: 'AB' },
      { label: messages.britishColumbia, value: 'BC' },
      { label: messages.manitoba, value: 'MB' },
      { label: messages.newBrunswick, value: 'NB' },
      { label: messages.newfoundlandAndLabrador, value: 'NL' },
      { label: messages.northwestTerritories, value: 'NT' },
      { label: messages.novaScotia, value: 'NS' },
      { label: messages.nunavut, value: 'NU' },
      {
        label: messages.ontario,
        value: 'ON',
      },
      { label: messages.princeEdwardIsland, value: 'PE' },
      { label: messages.quebec, value: 'QC' },
      { label: messages.saskatchewan, value: 'SK' },
      { label: messages.yukonTerritory, value: 'YT' },
    ]

    if (defaultOptionLabel) {
      options.unshift({ label: defaultOptionLabel, value: '' })
    }

    return options
  }, [messages, defaultOptionLabel])
}

export const useCategoryOptions = () => {
  const messages = useDomainMessages({
    alcohol: 'onboardingDomain.categories.alcohol',
    bakery: 'onboardingDomain.categories.bakery',
    butcherShop: 'onboardingDomain.categories.butcherShop',
    coOp: 'onboardingDomain.categories.coOp',
    drugstore: 'onboardingDomain.categories.drugstore',
    ethnic: 'onboardingDomain.categories.ethnic',
    farmersMarket: 'onboardingDomain.categories.farmersMarket',
    groceries: 'onboardingDomain.categories.groceries',
    meals: 'onboardingDomain.categories.meals',
    organic: 'onboardingDomain.categories.organic',
    petSupplies: 'onboardingDomain.categories.petSupplies',
    preparedMeals: 'onboardingDomain.categories.preparedMeals',
    specialty: 'onboardingDomain.categories.specialty',
    vegetarian: 'onboardingDomain.categories.vegetarian',
    wholesale: 'onboardingDomain.categories.wholesale',
    backToSchool: 'onboardingDomain.categories.backToSchool',
    books: 'onboardingDomain.categories.books',
    stationery: 'onboardingDomain.categories.stationery',
    home: 'onboardingDomain.categories.home',
    convenience: 'onboardingDomain.categories.convenience',
    essentials: 'onboardingDomain.categories.essentials',
    snacks: 'onboardingDomain.categories.snacks',
    preparedFood: 'onboardingDomain.categories.preparedFood',
    supplements: 'onboardingDomain.categories.supplements',
    healthWellness: 'onboardingDomain.categories.healthWellness',
    electronics: 'onboardingDomain.categories.electronics',
    generalMerchandise: 'onboardingDomain.categories.generalMerchandise',
    beauty: 'onboardingDomain.categories.beauty',
    bed: 'onboardingDomain.categories.bed',
    extremeValue: 'onboardingDomain.categories.extremeValue',
    gamesToys: 'onboardingDomain.categories.gamesToys',
    tech: 'onboardingDomain.categories.tech',
    homeOffice: 'onboardingDomain.categories.homeOffice',
    retail: 'onboardingDomain.categories.retail',
    lifestyle: 'onboardingDomain.categories.lifestyle',
    fashion: 'onboardingDomain.categories.fashion',
    sportingGoods: 'onboardingDomain.categories.sportingGoods',
    outdoors: 'onboardingDomain.categories.outdoors',
    costumeStores: 'onboardingDomain.categories.costumeStores',
  })

  return useMemo(
    () => [
      {
        label: messages.alcohol,
        value: messages.alcohol,
      },
      {
        label: messages.bakery,
        value: messages.bakery,
      },
      {
        label: messages.butcherShop,
        value: messages.butcherShop,
      },
      {
        label: messages.coOp,
        value: messages.coOp,
      },
      {
        label: messages.drugstore,
        value: messages.drugstore,
      },
      {
        label: messages.ethnic,
        value: messages.ethnic,
      },
      {
        label: messages.farmersMarket,
        value: messages.farmersMarket,
      },
      {
        label: messages.groceries,
        value: messages.groceries,
      },
      {
        label: messages.meals,
        value: messages.meals,
      },
      {
        label: messages.organic,
        value: messages.organic,
      },
      {
        label: messages.petSupplies,
        value: messages.petSupplies,
      },
      {
        label: messages.preparedMeals,
        value: messages.preparedMeals,
      },
      {
        label: messages.specialty,
        value: messages.specialty,
      },
      {
        label: messages.vegetarian,
        value: messages.vegetarian,
      },
      {
        label: messages.wholesale,
        value: messages.wholesale,
      },
      {
        label: messages.backToSchool,
        value: messages.backToSchool,
      },
      {
        label: messages.books,
        value: messages.books,
      },
      {
        label: messages.stationery,
        value: messages.stationery,
      },
      {
        label: messages.home,
        value: messages.home,
      },
      {
        label: messages.convenience,
        value: messages.convenience,
      },
      {
        label: messages.essentials,
        value: messages.essentials,
      },
      {
        label: messages.snacks,
        value: messages.snacks,
      },
      {
        label: messages.preparedFood,
        value: messages.preparedFood,
      },
      {
        label: messages.supplements,
        value: messages.supplements,
      },
      {
        label: messages.healthWellness,
        value: messages.healthWellness,
      },
      {
        label: messages.electronics,
        value: messages.electronics,
      },
      {
        label: messages.generalMerchandise,
        value: messages.generalMerchandise,
      },
      {
        label: messages.beauty,
        value: messages.beauty,
      },
      {
        label: messages.bed,
        value: messages.bed,
      },
      {
        label: messages.extremeValue,
        value: messages.extremeValue,
      },
      {
        label: messages.gamesToys,
        value: messages.gamesToys,
      },
      {
        label: messages.tech,
        value: messages.tech,
      },
      {
        label: messages.homeOffice,
        value: messages.homeOffice,
      },
      {
        label: messages.retail,
        value: messages.retail,
      },
      {
        label: messages.lifestyle,
        value: messages.lifestyle,
      },
      {
        label: messages.fashion,
        value: messages.fashion,
      },
      {
        label: messages.sportingGoods,
        value: messages.sportingGoods,
      },
      {
        label: messages.outdoors,
        value: messages.outdoors,
      },
      {
        label: messages.costumeStores,
        value: messages.costumeStores,
      },
    ],
    [messages]
  )
}

export const WORKFLOW_STATE = {
  EMAIL_INVITE_SENT: 'email_invite_sent',
  EMAIL_OPENED: 'email_opened',
  IPP_ACCOUNT_COMPLETED: 'ipp_account_completed',
  INITIAL_ONBOARDING_STARTED: 'initial_onboarding_started',
  INITIAL_WAREHOUSE_INFO_PROVIDED: 'initial_warehouse_info_provided',
  INITIAL_WAREHOUSE_LOCATIONS_INFO_PROVIDED: 'initial_warehouse_locations_info_provided',
  INITIAL_ONBOARDING_COMPLETED: 'initial_onboarding_completed',
  LAUNCH_ONBOARDING_STARTED: 'launch_onboarding_started',
  LAUNCH_ONBOARDING_COMPLETED: 'launch_onboarding_completed',
  LAUNCHED: 'launched',
}

export const STATE_SEQUENCE_MAP = {
  email_invite_sent: 0,
  email_opened: 1,
  ipp_account_completed: 2,
  initial_onboarding_started: 3,
  initial_warehouse_info_provided: 4,
  initial_warehouse_locations_info_provided: 5,
  initial_onboarding_completed: 6,
  launch_onboarding_started: 7,
  launch_onboarding_completed: 8,
}

export const STATE_TO_STEP_MAP = {
  email_invite_sent: 0,
  email_opened: 0,
  ipp_account_completed: 1,
  initial_onboarding_started: 2,
  initial_warehouse_info_provided: 3,
  initial_warehouse_locations_info_provided: 3,
  initial_onboarding_completed: 4,
  launch_onboarding_started: 4,
  launch_onboarding_completed: 4,
}

export const PAGE_TO_STEP_MAP = {
  complete_account_setup: 0,
  get_started: 1,
  complete_retailer_profile: 2,
  add_warehouse_locations: 3,
}

export const PRICING_POLICY_VALUE = {
  HIGHER: 'higher',
  CUSTOM: 'view_pricing_policy',
  EVERYDAY_STORE_PRICES: 'everyday_store_prices',
}

export const usePricingPolicyMapping = () =>
  useDomainMessages({
    higher: 'onboardingDomain.retailerProfile.form.pricingPolicy.higher',
    view_pricing_policy: 'onboardingDomain.retailerProfile.form.pricingPolicy.viewPricingPolicy',
    everyday_store_prices:
      'onboardingDomain.retailerProfile.form.pricingPolicy.everydayStorePrices',
  })

export const APPROVAL_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
}

export const WAREHOUSE_ATTRIBUTE = {
  NAME: 'name',
  RETAILER_TYPE: 'retailer_type',
  COUNTRY: 'country',
  CATEGORIES: 'categories',
  DESCRIPTION: 'description',
  ALLOW_ALCOHOL: 'allow_alcohol',
  LOGO: 'logo',
  PRICING_POLICY: 'pricing_policy',
  PRICING_POLICY_TEXT: 'pricing_policy_text',
  CERTIFIED_DELIVERY_THRESHOLD: 'certified_delivery_threshold',
  LOGO_BACKGROUND_COLOR: 'logo_background_color',
  ALIAS_NAME: 'alias_name',
  TAGS: 'tags',
}

export const WAREHOUSE_LOCATION_ATTRIBUTE = {
  NAME: 'name',
  LOCATION_CODE: 'location_code',
  ISOCHRONE_DATA: 'isochrone_data',
}

export const ADDRESS_ATTRIBUTE = {
  STREET_ADDRESS: 'street_address',
  APARTMENT_NUMBER: 'apartment_number',
  CITY: 'city',
  STATE: 'state',
  POSTAL_CODE: 'postal_code',
  COUNTRY: 'country',
}

export const ATTRIBUTES_EXCLUDED_FROM_PENDING_APPROVAL_REQUESTS = [
  WAREHOUSE_ATTRIBUTE.ALIAS_NAME,
  WAREHOUSE_ATTRIBUTE.TAGS,
  WAREHOUSE_LOCATION_ATTRIBUTE.ISOCHRONE_DATA,
]

export const useWarehouseAttributeNameMapping = (): Record<string, string> =>
  useDomainMessages({
    name: 'onboardingDomain.retailerProfile.form.retailerName',
    country: 'onboardingDomain.retailerProfile.form.country',
    logo: 'onboardingDomain.retailerProfile.form.logo',
    categories: 'onboardingDomain.retailerProfile.form.categories',
    description: 'onboardingDomain.retailerProfile.form.description',
    pricing_policy: 'onboardingDomain.retailerProfile.form.pricingPolicy',
    pricing_policy_text: 'onboardingDomain.retailerProfile.form.pricingPolicyText',
    allow_alcohol: 'onboardingDomain.retailerProfile.form.alcohol',
  })

export const useWarehouseLocationAttributeNameMapping = (): Record<string, string> =>
  useDomainMessages({
    location_code: 'onboardingDomain.storeManagement.storeId',
    name: 'onboardingDomain.storeManagement.storeName',
    street_address: 'onboardingDomain.addStoreLocations.form.streetAddress',
    apartment_number: 'onboardingDomain.addStoreLocations.form.apartmentNumber',
    city: 'onboardingDomain.addStoreLocations.form.city',
    state: 'onboardingDomain.addStoreLocations.form.state',
    postal_code: 'onboardingDomain.addStoreLocations.form.postalCode',
    country: 'onboardingDomain.retailerProfile.form.country',
  })

export const MAX_CATEGORIES_ALLOWED = 3

export const REQUIRED_LOGO_IMAGE_DIMENSIONS = {
  minWidth: 468,
  minHeight: 468,
}

export const DEFAULT_LOGO_BACKGROUND_COLOR = '#FFFFFF'

export const MAX_INITIAL_WAREHOUSE_LOCATIONS_ALLOWED = 10

export const DEFAULT_PAGE = 1

export const DEFAULT_PER_PAGE = 10

// custom error codes from Partners service backend
export const ERROR_CODES = {
  STORE_NAME_NOT_UNIQUE: 'STORE_NAME_NOT_UNIQUE',
  // Common Address validation errors from V4
  STREET_ADDRESS_INVALID: 'STREET_ADDRESS_INVALID',
  POSTAL_CODE_NOT_FOUND: 'POSTAL_CODE_NOT_FOUND',
  POSTAL_CODE_NOT_SERVICEABLE: 'POSTAL_CODE_NOT_SERVICEABLE',
  // Common Retailer Location validation errors from V4
  ADDRESS_HAS_ALREADY_BEEN_TAKEN: 'ADDRESS_HAS_ALREADY_BEEN_TAKEN',
  LOCATION_CODE_HAS_ALREADY_BEEN_TAKEN: 'LOCATION_CODE_HAS_ALREADY_BEEN_TAKEN',
  NAME_HAS_ALREADY_BEEN_TAKEN: 'NAME_HAS_ALREADY_BEEN_TAKEN',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
}

// Whitelabel Site related constants
export const WHITELABEL_SITE_WORKFLOW_STATE = {
  INITIALIZED: 'initialized',
  INITIAL_EMAIL_SENT: 'initial_email_sent',
  IN_PROGRESS: 'in_progress',
  IN_REVIEW: 'in_review',
  PUBLISHED: 'published',
}
