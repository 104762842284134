import { PartnerType } from '@retailer-platform/dashboard/utils'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const credentialsManagementAccessControl: DomainAccessControlConfig = {
  partnerTypes: PartnerType.IDP,
}

export const apiKeysAccessControl: DomainAccessControlConfig = {
  partnerTypes: PartnerType.IDP,
  permissions: [Permission.ConnectApiKeysView],
}

export const apiKeyRequestApprovalsAccessControl: DomainAccessControlConfig = {
  partnerTypes: PartnerType.IDP,
  permissions: [Permission.ConnectApiKeysApprove],
}
