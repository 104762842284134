import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_WIDGETS = `${URL_RETAILER_SCOPED}/widgets`
const URL_WIDGETS_SITE_SCOPED = URL_WIDGETS + URL_SITE_SCOPED
const URL_WIDGETS_CONFIG = `${URL_WIDGETS_SITE_SCOPED}/config`
const URL_WIDGETS_CONFIG_NAME = `${URL_WIDGETS_SITE_SCOPED}/config/:name`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'widgets'>
  | DashRouteType<'widgets-config'>
  | DashRouteType<'widgets-config-name'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  widgets: URL_WIDGETS_SITE_SCOPED,
  'widgets-config': URL_WIDGETS_CONFIG,
  'widgets-config-name': URL_WIDGETS_CONFIG_NAME,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
