/* eslint-disable */
import styled from '@emotion/styled'
import { spacing } from '../../foundation/spacing'
import { fontSize } from '../../foundation/fontSize'
import { borderRadius } from '../../foundation/borderRadius'
import { colors } from '../../foundation/colors'


export const TextArea = styled.textarea({
  width: '100%',
  boxSizing: 'border-box',
  fontFamily: 'inherit',
  padding: spacing.X12,
  fontSize: fontSize.X12,
  color: colors.GRAYSCALE.X70,
  backgroundColor: colors.GRAYSCALE.X0,
  borderRadius: borderRadius.X8,
  outline: 'none',
  transition: 'box-shadow .5s ease',
  boxShadow: `0 0 0 1px ${colors.GRAYSCALE.X30}`,
  resize: 'none',
  borderColor: 'transparent',

  '&:hover': {
    boxShadow: `0 0 0 1px ${colors.GRAYSCALE.X70}`,

  },

  '&:focus': {
    boxShadow: `0 0 0 2px ${colors.GRAYSCALE.X70}`,
  },

  '&:disabled': {
    background: colors.GRAYSCALE.X10,
  },
})
