/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import styled from '@emotion/styled'
import { colors, Text } from 'ic-snacks'
import { Flex } from '../dash-blocks/flex/Flex'
import { TOPBAR_HEIGHT } from '../common/theme'
import FlexRow from './FlexRow'

const HeaderContainer = styled(FlexRow)`
  padding: 0 0px;
  height: ${TOPBAR_HEIGHT};
  min-height: ${TOPBAR_HEIGHT};
  max-height: ${TOPBAR_HEIGHT};
  background-color: ${colors.WHITE};
  flex-basis: 100%;
  width: 100%;
`

const HeaderContent = styled(Flex)`
  margin-right: 30px;
`

const HeaderChildContent = styled(Flex)`
  margin-left: 10px;
`

interface Props {
  children?: React.ReactNode
  className?: string
  title: React.ReactNode
}

class AppHeader extends React.Component<Props> {
  render() {
    const { title, className, children } = this.props

    return (
      <HeaderContainer className={className}>
        <HeaderContent align="center">
          <Text variant="T.18" fontWeight="semiBold" elementType="div">
            {title}
          </Text>
          {children && <HeaderChildContent>{children}</HeaderChildContent>}
        </HeaderContent>
      </HeaderContainer>
    )
  }
}

export default AppHeader
