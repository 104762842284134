// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'

const Slash = styled.span`
  margin: 0 10px;
  &:after {
    content: '/';
  }
`

export default Slash
