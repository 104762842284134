import { type PlaceholderProps, components } from 'react-select-5'
import React from 'react'
import styled, { type CSSObject } from '@emotion/styled'
import { colors } from '../../../foundation'
import { type SimpleOption } from '../utils/types'

const PlaceholderLabel = styled.div<{ isFilled: boolean; isDisabled?: boolean }>(
  ({ isFilled, isDisabled }) => {
    const styles: CSSObject = {
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }

    if (isFilled) styles.color = colors.GRAYSCALE.X70
    if (isDisabled) styles.color = 'inherit'

    return styles
  }
)

/**
 * This will shorten the label for the selection to be like
 * {placeholder} {numSelected}
 * Instead of showing every single option. It's best to use this when space is limited.
 *
 * @param props
 * @returns
 */
export const CompactMultiSelectPlaceholder = (props: PlaceholderProps<SimpleOption, any, any>) => {
  const currentValue = props.getValue()
  const count = currentValue?.length ? `(${currentValue.length})` : undefined

  const children =
    typeof props.children === 'string'
      ? [props.children, count].filter(Boolean).join(' ')
      : props.children

  return (
    <components.Placeholder {...props}>
      <PlaceholderLabel
        isFilled={Boolean(count)}
        isDisabled={props.isDisabled}
        title={currentValue.map(v => v.label).join(', ')}
      >
        {children}
      </PlaceholderLabel>
    </components.Placeholder>
  )
}
