import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute, DomainRedirect } from '../utils/domain/routingComponents'
import { pickupSettingsAccessControl } from '../access-control/pickupSettingsAccess.configuration'

const PickupSettingsRetailerLocationSettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PickupSettingsRetailerLocationSettingsPage" */
      '../pages/retailer-location-settings/PickupSettingsRetailerLocationSettingsPage'
    )
)
// @hygen:inject page-import

export const pickupSettingsRouter = (
  <DomainRoute route="pickup-settings" accessControl={pickupSettingsAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="pickup-settings-retailer-location-settings"
        component={PickupSettingsRetailerLocationSettingsPage}
        scopePicker={{
          titles: ['pickupSettingsDomain.title'],
        }}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
      <DomainRedirect
        fromRoute="pickup-settings"
        toRoute="pickup-settings-retailer-location-settings"
      />
    </DashSwitch>
  </DomainRoute>
)
