import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint, NavItem } from '@retailer-platform/dashboard/routing'
import { connectOrdersRouter } from './routing/connectOrdersRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { connectOrdersAccessControl } from './access-control/connectOrdersAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'connect-orders',
  totem: {
    entity: 'connect-orders-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [connectOrdersRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Orders,
      attachToV2: NavEntryPoint.OperationsCustomerSupport,
      subNavItems: [
        {
          type: 'item',
          labelId: 'connectOrdersDomain.orderDetails.lastmiledelivery.title',
          route: 'connect-orders-orders-table',
          accessControl: connectOrdersAccessControl,
          positionNavV2: 2,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
