import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { onboardingRouter } from './routing/onboardingRouter'
import { onboardingPublicRouter } from './routing/onboardingPublicRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'onboarding',
  totem: {
    entity: 'onboarding-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [onboardingRouter],
    public: [onboardingPublicRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [],
}

export default definition
