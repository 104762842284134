import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

const URL_LANDING = `${URL_APP_ADMIN}/landing`
const URL_LANDING_CONTENT_PAGES = `${URL_LANDING}/content-pages`
const URL_LANDING_CONTENT_PAGES_DASHBOARD = `${URL_LANDING_CONTENT_PAGES}/dashboard`
const URL_LANDING_CONTENT_PAGES_CREATE = `${URL_LANDING_CONTENT_PAGES}/create`
const URL_LANDING_CONTENT_PAGES_EDIT = `${URL_LANDING_CONTENT_PAGES}/edit/:contentPageId`
const URL_LANDING_CONTENT_PAGES_EDIT_VERSION = `${URL_LANDING_CONTENT_PAGES}/version/:contentPageVersionId`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'landing'>
  | DashRouteType<'landing-content-pages-dashboard'>
  | DashRouteType<'landing-content-pages-create'>
  | DashRouteType<'landing-content-pages-edit'>
  | DashRouteType<'landing-content-pages-edit-version'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  landing: URL_LANDING,
  'landing-content-pages-dashboard': URL_LANDING_CONTENT_PAGES_DASHBOARD,
  'landing-content-pages-create': URL_LANDING_CONTENT_PAGES_CREATE,
  'landing-content-pages-edit': URL_LANDING_CONTENT_PAGES_EDIT,
  'landing-content-pages-edit-version': URL_LANDING_CONTENT_PAGES_EDIT_VERSION,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
