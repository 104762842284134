import React, { useMemo } from 'react'
import { type RowInfo } from 'react-table'
import { StoreGroupsFormLocationSelectorToggle } from '../../StoreGroupsFormLocationSelectorToggle'
import { type StoreLocationDetails } from '../../../utils/locations.hooks'
import { LocationSelectorActions } from '../../../utils/locationSelector.types'

interface FormLocationSelectorRow extends RowInfo {
  original: StoreLocationDetails
}

interface Props {
  isLocationSelected: (id: string) => boolean
  handleToggleLocation: (id: string) => void
  showActionButtons?: boolean
}

export const useStoreGroupFormLocationTableColumns = ({
  isLocationSelected,
  handleToggleLocation,
  showActionButtons,
}: Props) =>
  useMemo(
    () => [
      {
        header: 'Code',
        width: 80,
        accessor: 'locationCode',
      },
      {
        header: 'Store Name',
        accessor: 'name',
      },
      {
        header: 'Address',
        accessor: 'street',
      },
      {
        header: 'City',
        width: 110,
        accessor: 'city',
      },
      {
        header: 'State',
        width: 60,
        accessor: 'state',
      },
      {
        header: 'Zip Code',
        width: 80,
        accessor: 'zipCode',
      },
      {
        Header: null,
        width: 80,
        show: showActionButtons,
        Cell: ({ original: { id } }: FormLocationSelectorRow) => {
          const isSelected = isLocationSelected(id)

          const actionType = isSelected
            ? LocationSelectorActions.Remove
            : LocationSelectorActions.Add

          const onClick = () => handleToggleLocation(id)

          return <StoreGroupsFormLocationSelectorToggle onClick={onClick} actionType={actionType} />
        },
      },
    ],
    [handleToggleLocation, isLocationSelected, showActionButtons]
  )
