import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { configurableProductsRouter } from './routing/configurableProductsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { configurableProductsAccessControl } from './access-control/configurableProductsAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'configurable-products',
  totem: {
    entity: 'configurable-products-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [configurableProductsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Catalog,
      attachToV2: NavEntryPoint.Catalog,
      subNavItems: [
        {
          type: 'item',
          labelId: 'configurableProductsDomain.title',
          // the name of the route the user should be brought to when clicking
          // on this navigation button
          route: 'configurable-products-show',
          // the accessControl configuration that determines if this nav item
          // should be displayed to the user
          accessControl: configurableProductsAccessControl,
          positionNavV2: 3,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
