import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_ADMIN_TDS_PLAYGROUND = `${URL_APP_ADMIN}/tds-playground`
const URL_ADMIN_TDS_PLAYGROUND_COMPONENTS = `${URL_ADMIN_TDS_PLAYGROUND}/components`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-tds-playground'>
  | DashRouteType<'app-admin-tds-playground-components'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-tds-playground': URL_ADMIN_TDS_PLAYGROUND,
  'app-admin-tds-playground-components': URL_ADMIN_TDS_PLAYGROUND_COMPONENTS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
