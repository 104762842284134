import { Permission as legacyPermission } from '@retailer-platform/dashboard/utils'

export enum Permission {
  InsightsPortalAdsDashboard = 'insights_portal.view_ads_dashboard',
  Performance = 'insights_portal.performance',
  DataExplorer = 'insights_portal.data_explorer',
  ExecutiveSummary = 'insights_portal.executive_summary',
  Campaign = 'retailer_funded_marketing.insights',
  Collections = 'retailer_collections.view',
  Placements = 'insights_portal.placements',
  RetailerFundedMarketingAdminAccess = 'retailer_funded_marketing.admin',
  Analytics = legacyPermission.Analytics,
  PickupAnalytics = legacyPermission.PickupAnalytics,
}
