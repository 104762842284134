import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'
import { validSurfaceNameRouteParams } from '../utils/PlacementManager.types'

const URL_CAMPAIGN_MANAGEMENT = `${URL_RETAILER_SCOPED}/campaign-management`
// @hygen:inject scoped-urls

const URL_ADMIN_CAMPAIGN_MANAGEMENT = `${URL_APP_ADMIN}/campaign-management`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_CALENDAR = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/calendar`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_CREATIVES = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/creatives`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGES = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/theme-page`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGES_LIST = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/theme-page/list`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGE_EDIT = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/theme-page/edit/:themePageSlug`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGE_DETAILS = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/theme-page/details/:themePageSlug`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGE_CREATE = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/theme-page/create`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_LIST = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/:surfaceInstanceId/placements/list`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_DETAILS = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/:surfaceInstanceId/placements/details/:placementId`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_EDIT = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/:surfaceInstanceId/placements/edit/:placementId`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_CREATE = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/:surfaceInstanceId/placements/create`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_LIST = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/placements/list`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_DETAILS = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/placements/details/:placementId`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_EDIT = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/placements/edit/:placementId`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_CREATE = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/:surfaceTypeName(${validSurfaceNameRouteParams})/placements/create`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_REDEMPTION_URLS_LIST = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/redemption-urls/list`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_MULTI_RETAILER_PAGES_LIST = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/multi-retailer-pages/list`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_MULTI_RETAILER_PAGES_CREATE = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/multi-retailer-pages/create`
const URL_ADMIN_CAMPAIGN_MANAGEMENT_MULTI_RETAILER_PAGES_EDIT = `${URL_ADMIN_CAMPAIGN_MANAGEMENT}/multi-retailer-pages/edit/:contentPageId`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'campaign-management'>
  | DashRouteType<'app-admin-campaign-management'>
  | DashRouteType<'app-admin-campaign-management-creatives'>
  | DashRouteType<'app-admin-campaign-management-calendar'>
  | DashRouteType<'app-admin-campaign-management-theme-page'>
  | DashRouteType<'app-admin-campaign-management-theme-page-list'>
  | DashRouteType<'app-admin-campaign-management-theme-page-edit'>
  | DashRouteType<'app-admin-campaign-management-theme-page-details'>
  | DashRouteType<'app-admin-campaign-management-theme-page-create'>
  | DashRouteType<'app-admin-campaign-management-redemption-urls-list'>
  | DashRouteType<'app-admin-campaign-management-multi-retailer-pages-list'>
  | DashRouteType<'app-admin-campaign-management-multi-retailer-pages-create'>
  | DashRouteType<'app-admin-campaign-management-multi-retailer-pages-edit'>
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-list',
      { surfaceTypeName: string }
    >
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-details',
      { surfaceTypeName: string; placementId: string }
    >
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-edit',
      { surfaceTypeName: string; placementId: string }
    >
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-create',
      { surfaceTypeName: string }
    >
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-surface-instance-list',
      { surfaceTypeName: string; surfaceInstanceId: string }
    >
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-surface-instance-details',
      { surfaceTypeName: string; surfaceInstanceId: string; placementId: string }
    >
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-surface-instance-edit',
      { surfaceTypeName: string; surfaceInstanceId: string; placementId: string }
    >
  | DashRouteType<
      'app-admin-campaign-management-placement-manager-surface-instance-create',
      { surfaceTypeName: string; surfaceInstanceId: string }
    >
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'campaign-management': URL_CAMPAIGN_MANAGEMENT,
  'app-admin-campaign-management': URL_ADMIN_CAMPAIGN_MANAGEMENT,
  'app-admin-campaign-management-creatives': URL_ADMIN_CAMPAIGN_MANAGEMENT_CREATIVES,
  'app-admin-campaign-management-calendar': URL_ADMIN_CAMPAIGN_MANAGEMENT_CALENDAR,
  'app-admin-campaign-management-theme-page': URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGES,
  'app-admin-campaign-management-theme-page-list': URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGES_LIST,
  'app-admin-campaign-management-theme-page-edit': URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGE_EDIT,
  'app-admin-campaign-management-theme-page-details':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGE_DETAILS,
  'app-admin-campaign-management-theme-page-create':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_THEME_PAGE_CREATE,
  'app-admin-campaign-management-placement-manager-list':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_LIST,
  'app-admin-campaign-management-placement-manager-details':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_DETAILS,
  'app-admin-campaign-management-placement-manager-edit':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_EDIT,
  'app-admin-campaign-management-placement-manager-create':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_CREATE,
  'app-admin-campaign-management-placement-manager-surface-instance-list':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_LIST,
  'app-admin-campaign-management-placement-manager-surface-instance-details':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_DETAILS,
  'app-admin-campaign-management-placement-manager-surface-instance-edit':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_EDIT,
  'app-admin-campaign-management-placement-manager-surface-instance-create':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_PLACEMENT_MANAGER_SURFACE_INSTANCE_CREATE,
  'app-admin-campaign-management-redemption-urls-list':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_REDEMPTION_URLS_LIST,
  'app-admin-campaign-management-multi-retailer-pages-list':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_MULTI_RETAILER_PAGES_LIST,
  'app-admin-campaign-management-multi-retailer-pages-create':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_MULTI_RETAILER_PAGES_CREATE,
  'app-admin-campaign-management-multi-retailer-pages-edit':
    URL_ADMIN_CAMPAIGN_MANAGEMENT_MULTI_RETAILER_PAGES_EDIT,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
