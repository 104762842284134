import React from 'react'
import styled from '@emotion/styled'
import { spacing, fontSize, fontWeight } from '@retailer-platform/shared-components'
import { useDashMessages } from '../../intl/intl.hooks'

export const StyledNotification = styled.div({
  '.header': {
    fontSize: fontSize.X15,
    fontWeight: fontWeight.SEMIBOLD,
    marginBottom: spacing.X8,
  },
  '.content': {
    fontSize: fontSize.X12,
  },
})

export const FetchErrorNotification: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const messages = useDashMessages({
    header: 'app.pagination.fetchError.header',
    content: 'app.pagination.fetchError.content',
  })

  return (
    <StyledNotification>
      <div className="header">{messages.header}</div>
      <div className="content">{messages.content}</div>
    </StyledNotification>
  )
}
