import { type DashRouteType, URL_RETAILER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_CONNECT_ORDERS = `${URL_RETAILER_SCOPED}/connect-orders`
const URL_CONNECT_ORDERS_ORDERS_TABLE = `${URL_CONNECT_ORDERS}/orders-table`
const URL_CONNECT_ORDERS_ORDER_DETAILS = `${URL_CONNECT_ORDERS}/order-details/:orderId`
const URL_CONNECT_ORDERS_RESCHEDULE_ORDER = `${URL_CONNECT_ORDERS}/reschedule-order/:orderId`
const URL_CONNECT_ORDERS_ORDER_TRACKING = `${URL_CONNECT_ORDERS}/order-tracking/:orderId`
const URL_CONNECT_ORDERS_CREATE_ORDER = `${URL_CONNECT_ORDERS}/create-order`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'connect-orders'>
  | DashRouteType<'connect-orders-orders-table'>
  | DashRouteType<'connect-orders-order-details'>
  | DashRouteType<'connect-orders-reschedule-order'>
  | DashRouteType<'connect-orders-order-tracking'>
  | DashRouteType<'connect-orders-create-order'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'connect-orders': URL_CONNECT_ORDERS,
  'connect-orders-orders-table': URL_CONNECT_ORDERS_ORDERS_TABLE,
  'connect-orders-order-details': URL_CONNECT_ORDERS_ORDER_DETAILS,
  'connect-orders-reschedule-order': URL_CONNECT_ORDERS_RESCHEDULE_ORDER,
  'connect-orders-order-tracking': URL_CONNECT_ORDERS_ORDER_TRACKING,
  'connect-orders-create-order': URL_CONNECT_ORDERS_CREATE_ORDER,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
