import React, { type PropsWithChildren } from 'react'
import { Tooltip } from '@retailer-platform/shared-components'
import { type Permission, type PartnerType } from '../../common/types'
import { type WarehouseLocationFeature } from '../../common/warehouseLocations'
import { type FeatureToggle } from '../../contexts/featureToggles/FeatureToggleContext'
import { AccessControlOneOf, type AccessControlConfig } from './accessControl.utils'
import useAccessControl from './useAccessControl'
import { useDashMessage } from '../../../intl/intl.hooks'

/**
 * This component is designed to provide gated access to other components.
 * Be default accessBlockedResult is 'hide' which corresponds to component not being rendered in DOM at all.
 * Next option is 'disable', which is supported by any component that implements 'disabled' prop: <button>, <fieldset>, <optgroup>, <option>, <select>, <textarea> and <input>.
 * In addition it can be used with any custom component that implements 'disabled prop.
 */

type AccessBlockedType = 'remove-from-dom' | 'disable'

type BaseProps = PropsWithChildren<{
  accessControlConfig: AccessControlConfig
  accessBlockedResult?: AccessBlockedType
}>

interface RemoveFromDomOnlyProps extends BaseProps {
  accessBlockedResult: 'remove-from-dom'
}

interface DisableProps extends Omit<BaseProps, 'children'> {
  accessBlockedResult: 'disable'
  children:
    | React.ReactElement<{ disabled?: boolean }>
    | React.ReactElement<{ disabled?: boolean }>[]
}

export function AccessControl(props: RemoveFromDomOnlyProps | DisableProps) {
  const { accessControlConfig, accessBlockedResult, children } = props

  const hasAccess = useAccessControl()

  const disabledComponentTooltipText = useDashMessage('accessControl.disabledComponentTooltipText')

  if (hasAccess(accessControlConfig)) {
    return <>{children}</>
  }

  if (accessBlockedResult === 'disable') {
    return (
      <Tooltip
        target={React.Children.map(children, child =>
          React.cloneElement(child as React.ReactElement, { disabled: true })
        )}
      >
        {disabledComponentTooltipText}
      </Tooltip>
    )
  } else {
    return null
  }
}

function oneOf(values: Permission[]): AccessControlOneOf<Permission>
function oneOf(values: FeatureToggle[]): AccessControlOneOf<FeatureToggle>
function oneOf(values: WarehouseLocationFeature[]): AccessControlOneOf<WarehouseLocationFeature>
function oneOf(values: PartnerType[]): AccessControlOneOf<PartnerType>
function oneOf<T>(values: T[]) {
  return new AccessControlOneOf<T>(values)
}

export default Object.assign(AccessControl, { oneOf })
