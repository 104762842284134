import React from 'react'
import styled from '@emotion/styled'
import { colors, Text, Button } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import LoadingDots from '../LoadingDots'
import { Z_INDEX_THIRD } from '../../common/theme'

const Bar = styled.div<{ error?: boolean }>`
  position: fixed;
  z-index: ${Z_INDEX_THIRD};
  bottom: 0;
  left: 60px;
  height: 60px;
  background-color: ${({ error }) => (error ? colors.RED_500 : colors.GREEN_500)};
  width: calc(100% - 60px);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface Props {
  error?: boolean
  errorMessage?: React.ReactNode
  isSubmitting?: boolean
  submitButtonText?: React.ReactNode
}

/**
 * Note on usage: This component needs to be placed **inside**
 * the <form> tag. It uses standard form semantics and does not
 * accept callbacks for onSubmit/onReset. Please attach those to
 * your <form> (or automatic if using <Form> from Formik) instead.
 */
const FormConfirmationBar: React.FunctionComponent<React.PropsWithChildren<Props>> = props => {
  const { isSubmitting, error, errorMessage, submitButtonText } = props

  return (
    <Bar data-testid="form-confirmation-bar" error={error}>
      <Text variant="T.14" style={{ color: colors.WHITE }}>
        {error ? (
          errorMessage || <FormattedMessage id="forms.fieldError" />
        ) : (
          <FormattedMessage id="forms.savePrompt" />
        )}
      </Text>
      <div>
        <Button
          elementAttributes={{
            'data-testid': 'form-confirmation-bar-reset-button',
          }}
          size="small"
          type="reset"
          inverted
          snacksStyle="secondary"
          style={{
            marginRight: 10,
          }}
        >
          <FormattedMessage id="forms.discard" />
        </Button>
        <Button
          elementAttributes={{
            'data-testid': 'form-confirmation-bar-submit-button',
          }}
          type="submit"
          size="small"
          inverted
          style={{
            border: `1px solid ${colors.WHITE}`,
            ...(error ? { color: colors.RED_500 } : undefined),
          }}
        >
          {isSubmitting ? (
            <LoadingDots height={12} />
          ) : (
            submitButtonText || <FormattedMessage id="forms.save" />
          )}
        </Button>
      </div>
    </Bar>
  )
}

export default FormConfirmationBar
