import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRedirect, DomainRoute } from '../utils/domain/routingComponents'
import { accountManagementAdminAccessControl } from '../access-control/accountManagementAdminAccess.configuration'

const AccountManagementListAccountsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountManagementListAccountsPage" */
      '../pages/admin/AccountManagementListAccountPage'
    )
)

const AccountManagementCreateAccountPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountManagementCreateAccountPage" */
      '../pages/admin/AccountManagementCreateAccountPage'
    )
)

const AccountManagementUpdateAccountPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AccountManagementUpdateAccountPage" */
      '../pages/admin/AccountManagementUpdateAccountPage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const accountManagementAdminRouter = (
  <DomainRoute
    route="app-admin-account-management"
    accessControl={accountManagementAdminAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-account-management-list"
        component={AccountManagementListAccountsPage}
      />

      <DomainRoute
        exact
        route="app-admin-account-management-create"
        component={AccountManagementCreateAccountPage}
      />

      <DomainRoute
        route="app-admin-account-management-edit"
        component={AccountManagementUpdateAccountPage}
      />

      <DomainRedirect toRoute="app-admin-account-management-list" />

      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
