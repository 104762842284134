import {
  type YodaRulesDashRouteSet,
  yodaRulesRoutes,
} from '@retailer-platform/domain-approvals-management-library'

// @hygen:inject admin-urls

export type DashRouteSet = YodaRulesDashRouteSet

// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  ...yodaRulesRoutes,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
