import moment, { type Moment } from 'moment'
import React, { type FunctionComponent, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { type Styles } from 'react-select'
import { SimpleSelect } from '@retailer-platform/shared-components/src/common/simple-select/SimpleSelect'
import { borderRadius, colors } from '@retailer-platform/shared-components'
import { useDashIntl, useDashMessages } from '../../../intl/intl.hooks'
import { DateRangeCustom } from '../date-range-custom/DateRangeCustom'

const defaultComponentWidth = 270

export enum DateRangeOption {
  Yesterday = 'yesterday',
  Last7Days = 'last_7_days',
  LastWeek = 'last_week',
  TwoWeeksAgo = 'two_weeks_ago',
  ThreeWeeksAgo = 'three_weeks_ago',
  FourWeeksAgo = 'four_weeks_ago',
  FiveWeeksAgo = 'five_weeks_ago',
  Last30Days = 'last_30_days',
  LastMonth = 'last_month',
  Last90Days = 'last_90_days',
  QuarterToDate = 'quarter_to_date',
  Last120Days = 'last_120_days',
  LastYear = 'last_1_year',
  YearToDate = 'year_to_date',
  Custom = 'custom',
}

export enum WeekStartDay {
  Saturday = 'saturday',
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
}

const weekStartDayToDateDifference = {
  [WeekStartDay.Saturday]: -1,
  [WeekStartDay.Sunday]: 0,
  [WeekStartDay.Monday]: 1,
}

export interface DateRangePickerProps {
  dateRangeOptions?: DateRangeOption[]
  dateRangeOption: DateRangeOption
  customStartDate?: Moment
  customEndDate?: Moment
  onDateRangeChanged?: (
    newDateRange: DateRangeOption,
    customStartDate?: Moment,
    customEndDate?: Moment
  ) => void
  placeholder?: string
  itemDisplayPrefix?: string
  // This will always display the custom date picker and upon
  // selecting a select option, will select in the custom picker accordingly
  alwaysShowCustomDatePicker?: boolean
  isClearable?: boolean
  horizontal?: boolean
  dateContainerStyles?: Styles
  weekStartDay?: WeekStartDay
  isOutsideRange?: (moment: Moment) => boolean
  withPortal?: boolean
  shouldChangeCustomDateOnSelectChange?: boolean
}

export const StyledDateRangePickerLayout = styled.div<{
  width: number
  horizontal: ConstrainBoolean
}>(({ width, horizontal }) => ({
  display: 'flex',
  flexDirection: horizontal ? 'column' : 'row',
  alignItems: horizontal ? 'center' : 'default',
  width: width,
  marginRight: 12,
  '&&': {
    '.DateRangePicker': {
      marginTop: horizontal ? 20 : 0,
    },
    '.DateRangePickerInput': {
      height: 32,
      width: 200,
      border: 'none',
    },

    '.DateInput_input': {
      height: 32,
      width: 100,
      backgroundColor: colors.GRAYSCALE.X10,
    },

    '#endDate': {
      marginLeft: -9,
      paddingLeft: 14,
      width: 95,
    },

    '.DateRangePickerInput_arrow': {
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: -16,
      marginRight: 5,
      paddingTop: 3,
      zIndex: 1,
      backgroundColor: colors.GRAYSCALE.X10,
    },

    '.DateRangePickerInput_arrow_svg': {
      height: 16,
      width: 16,
    },

    '.DateRangePickerInput, .SingleDatePickerInput, .DateInput, .DateInput_input': {
      borderRadius: 0,
      borderTopRightRadius: borderRadius.X8,
      borderBottomRightRadius: borderRadius.X8,
      fontSize: 12,
    },
  },
}))

const defaultCustomDateContainerStyles: Styles = {
  container: () => ({
    backgroundColor: colors.GRAYSCALE.X10,
    borderTopLeftRadius: borderRadius.X8,
    borderBottomLeftRadius: borderRadius.X8,
    paddingRight: 1,
  }),
}

/**
 * Don't change this unless you have a good reason.
 * Other users of this component may not be prepared for the additional options.
 *
 * If you need extra options, you can build your own `dateRangeOptions`
 * based on what you need from the `DateRangeOption` enum.
 *
 * You can also use `allDateRangeOptions` if you wish to implement them all.
 * See stories for more examples!
 */
const defaultDateRangeOptions = [
  DateRangeOption.Last7Days,
  DateRangeOption.Last30Days,
  DateRangeOption.Last120Days,
  DateRangeOption.LastYear,
  DateRangeOption.Custom,
]

export const allDateRangeOptions = Object.values(DateRangeOption)

export const DateRangePicker: FunctionComponent<React.PropsWithChildren<DateRangePickerProps>> = ({
  dateRangeOptions,
  dateRangeOption,
  customStartDate,
  customEndDate,
  onDateRangeChanged,
  placeholder,
  isClearable = true,
  itemDisplayPrefix,
  alwaysShowCustomDatePicker = false,
  shouldChangeCustomDateOnSelectChange = false,
  horizontal = false,
  weekStartDay = WeekStartDay.Sunday,
  dateContainerStyles,
  isOutsideRange,
  withPortal = true,
}) => {
  const customDateContainerStyles = dateContainerStyles || defaultCustomDateContainerStyles
  const showCustomDatePicker =
    dateRangeOption === DateRangeOption.Custom || alwaysShowCustomDatePicker
  // hacky fixed widths to get the components to display correct.
  // something is probably going awry with the flex on the parent and/or children here
  let componentWidth = defaultComponentWidth
  if (showCustomDatePicker && horizontal === false) {
    componentWidth += 200
  }

  // The purpose of this is for the case when we always show the custom calendar. i.e. when alwaysShowCustomDatePicker = true
  // This will match the calendar dates to the option label
  const dateRangeToCalendarDates: Record<
    Exclude<DateRangeOption, DateRangeOption.Custom>,
    {
      customStartDate: Moment
      customEndDate: Moment
    }
  > = useMemo(
    () => ({
      [DateRangeOption.Yesterday]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().subtract(1, 'days').startOf('day'),
      },

      [DateRangeOption.Last7Days]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().subtract(7, 'd'),
      },

      [DateRangeOption.LastWeek]: {
        customEndDate: moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day')
          .add(6, 'day'),
        customStartDate: moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day'),
      },

      [DateRangeOption.TwoWeeksAgo]: {
        customEndDate: moment()
          .subtract(2, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day')
          .add(6, 'day'),
        customStartDate: moment()
          .subtract(2, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day'),
      },
      [DateRangeOption.ThreeWeeksAgo]: {
        customEndDate: moment()
          .subtract(3, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day')
          .add(6, 'day'),
        customStartDate: moment()
          .subtract(3, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day'),
      },
      [DateRangeOption.FourWeeksAgo]: {
        customEndDate: moment()
          .subtract(4, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day')
          .add(6, 'day'),
        customStartDate: moment()
          .subtract(4, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day'),
      },
      [DateRangeOption.FiveWeeksAgo]: {
        customEndDate: moment()
          .subtract(5, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day')
          .add(6, 'day'),
        customStartDate: moment()
          .subtract(5, 'weeks')
          .startOf('week')
          .add(weekStartDayToDateDifference[weekStartDay], 'day'),
      },
      [DateRangeOption.Last30Days]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().subtract(30, 'd'),
      },

      [DateRangeOption.LastMonth]: {
        customEndDate: moment().subtract(1, 'months').date(1).endOf('month'),
        customStartDate: moment().subtract(1, 'months').date(1),
      },

      [DateRangeOption.Last90Days]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().subtract(90, 'd'),
      },

      [DateRangeOption.QuarterToDate]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().startOf('quarter'),
      },

      [DateRangeOption.Last120Days]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().subtract(120, 'd'),
      },

      [DateRangeOption.LastYear]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().subtract(1, 'y'),
      },

      [DateRangeOption.YearToDate]: {
        customEndDate: moment().subtract(1, 'days').endOf('day'),
        customStartDate: moment().startOf('year'),
      },

      [DateRangeOption.Custom]: {
        customEndDate,
        customStartDate,
      },
    }),
    [weekStartDay, customEndDate, customStartDate]
  )

  let dateRangeLabels = useDashMessages({
    [DateRangeOption.Yesterday]: 'components.dateRangePicker.yesterday',
    [DateRangeOption.Last7Days]: 'components.dateRangePicker.last_7_days',
    [DateRangeOption.LastWeek]: 'components.dateRangePicker.last_week',
    [DateRangeOption.Last30Days]: 'components.dateRangePicker.last_30_days',
    [DateRangeOption.LastMonth]: 'components.dateRangePicker.last_month',
    [DateRangeOption.Last90Days]: 'components.dateRangePicker.last_90_days',
    [DateRangeOption.QuarterToDate]: 'components.dateRangePicker.quarter_to_date',
    [DateRangeOption.Last120Days]: 'components.dateRangePicker.last_120_days',
    [DateRangeOption.LastYear]: 'components.dateRangePicker.last_1_year',
    [DateRangeOption.YearToDate]: 'components.dateRangePicker.year_to_date',
    [DateRangeOption.Custom]: 'components.dateRangePicker.custom_dates',
  })
  const intl = useDashIntl()

  const relativeDateRangeLabels = useMemo(
    () => ({
      [DateRangeOption.TwoWeeksAgo]: intl.formatMessage(
        { id: 'components.dateRangePicker.customWeek' },
        {
          dateStart: dateRangeToCalendarDates.two_weeks_ago.customStartDate.format('MM/DD'),
          dateEnd: dateRangeToCalendarDates.two_weeks_ago.customEndDate.format('MM/DD'),
        }
      ),
      [DateRangeOption.ThreeWeeksAgo]: intl.formatMessage(
        { id: 'components.dateRangePicker.customWeek' },
        {
          dateStart: dateRangeToCalendarDates.three_weeks_ago.customStartDate.format('MM/DD'),
          dateEnd: dateRangeToCalendarDates.three_weeks_ago.customEndDate.format('MM/DD'),
        }
      ),
      [DateRangeOption.FourWeeksAgo]: intl.formatMessage(
        { id: 'components.dateRangePicker.customWeek' },
        {
          dateStart: dateRangeToCalendarDates.four_weeks_ago.customStartDate.format('MM/DD'),
          dateEnd: dateRangeToCalendarDates.four_weeks_ago.customEndDate.format('MM/DD'),
        }
      ),
      [DateRangeOption.FiveWeeksAgo]: intl.formatMessage(
        { id: 'components.dateRangePicker.customWeek' },
        {
          dateStart: dateRangeToCalendarDates.five_weeks_ago.customStartDate.format('MM/DD'),
          dateEnd: dateRangeToCalendarDates.five_weeks_ago.customEndDate.format('MM/DD'),
        }
      ),
    }),
    [dateRangeToCalendarDates, intl]
  )

  dateRangeLabels = { ...dateRangeLabels, ...relativeDateRangeLabels }

  const options = useMemo(
    () =>
      (dateRangeOptions ?? defaultDateRangeOptions).map(range => ({
        value: range,
        label: dateRangeLabels[range],
      })),
    [dateRangeOptions, dateRangeLabels]
  )

  useEffect(() => {
    // update selected dates for LastWeek when weekStartDay changes
    if (dateRangeOption === DateRangeOption.LastWeek) {
      onDateRangeChanged(
        DateRangeOption.LastWeek,
        dateRangeToCalendarDates['last_week'].customStartDate,
        dateRangeToCalendarDates['last_week'].customEndDate
      )
    }
  }, [weekStartDay, dateRangeToCalendarDates, dateRangeOption, onDateRangeChanged])

  const defaultIsOutsideRange = useCallback(
    (date: Moment) => date.isAfter(new Date().toISOString()),
    []
  )

  return (
    <StyledDateRangePickerLayout width={componentWidth} horizontal={horizontal}>
      <SimpleSelect
        options={options}
        value={dateRangeOption}
        onChange={(newValue: DateRangeOption) => {
          if (alwaysShowCustomDatePicker || shouldChangeCustomDateOnSelectChange) {
            onDateRangeChanged(
              newValue,
              dateRangeToCalendarDates[newValue].customStartDate,
              dateRangeToCalendarDates[newValue].customEndDate
            )
          } else {
            onDateRangeChanged(newValue)
          }
        }}
        isModernTheme
        isClearable={isClearable}
        placeholder={placeholder}
        styles={showCustomDatePicker ? customDateContainerStyles : undefined}
        components={{
          SingleValue: props => [itemDisplayPrefix, props.data.label].filter(Boolean).join(': '),
        }}
      />
      {showCustomDatePicker && (
        <DateRangeCustom
          value={{ startDate: customStartDate, endDate: customEndDate }}
          withPortal={withPortal}
          isOutsideRange={isOutsideRange ?? defaultIsOutsideRange}
          onChange={dateRange => {
            onDateRangeChanged(DateRangeOption.Custom, dateRange.startDate, dateRange.endDate)
          }}
        />
      )}
    </StyledDateRangePickerLayout>
  )
}
