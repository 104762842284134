import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const fulfillmentEventsViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.FulfillmentEventsView],
}

export const fulfillmentEventsModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.FulfillmentEventsModify],
}
