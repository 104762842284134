export enum GroupByTypes {
  TimeInterval,
  Program,
  Entity,
  Info,
}

export enum TimeIntervalDimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  MONTH_PT = 'MONTH_PT',
}
