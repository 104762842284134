import React, { type FunctionComponent } from 'react'
import { Button, Text } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { type OnClick } from '../../../../utils/react/event.types'

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

interface Props {
  handleNotFoundClick: OnClick
}

export const NotFound: FunctionComponent<React.PropsWithChildren<Props>> = ({
  handleNotFoundClick,
}) => (
  <Container>
    <Text variant="T.22" style={{ marginBottom: 40 }}>
      <FormattedMessage id="notFound.message" />
    </Text>
    <Button onClick={handleNotFoundClick}>
      <FormattedMessage id="notFound.button" />
    </Button>
  </Container>
)
