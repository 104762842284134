const EN_US = {
  'connectOrdersDomain.title': 'Connect Orders',
  'connectOrdersDomain.createOrderButton': 'Create New Order',
  'connectOrdersDomain.createOrder.form.validation.required': '{fieldName} required',
  'connectOrdersDomain.createOrder.form.validation.min': '{fieldName} cannot be a negative number',
  'connectOrdersDomain.createOrder.title': 'Create New Order',
  'connectOrdersDomain.createOrder.orderLogistics.title': 'Order Logistics',
  'connectOrdersDomain.createOrder.orderLogistics.orderId': 'Order ID',
  'connectOrdersDomain.createOrder.orderLogistics.locationCode': 'Location Code',
  'connectOrdersDomain.createOrder.deliveryLogistics.title': 'Delivery Logistics',
  'connectOrdersDomain.createOrder.customerInfo.title': 'Customer Information',
  'connectOrdersDomain.createOrder.customerInfo.lastName': 'Last Name',
  'connectOrdersDomain.createOrder.customerInfo.firstName': 'First Name',
  'connectOrdersDomain.createOrder.customerInfo.phoneNumber': 'Phone Number',
  'connectOrdersDomain.createOrder.customerInfo.addressLine': 'Address Line',
  'connectOrdersDomain.createOrder.customerInfo.aptSuite': 'Apartment, Suite, or Unit Number',
  'connectOrdersDomain.createOrder.customerInfo.city': 'City',
  'connectOrdersDomain.createOrder.customerInfo.state': 'State/Province',
  'connectOrdersDomain.createOrder.customerInfo.zipCode': 'ZIP Code/Postal Code',
  'connectOrdersDomain.createOrder.itemInfo.title': 'Item Information',
  'connectOrdersDomain.createOrder.itemInfo.initialTip': 'Initial Tip',
  'connectOrdersDomain.createOrder.itemInfo.itemCount': 'Item Count',
  'connectOrdersDomain.createOrder.itemInfo.bagCount': 'Bag Count',
  'connectOrdersDomain.createOrder.itemInfo.bagLabel': 'Bag Label',
  'connectOrdersDomain.createOrder.itemInfo.itemWeight': 'Item Weight (in lbs.)',
  'connectOrdersDomain.createOrder.itemInfo.cartTotal': 'Cart Total',
  'connectOrdersDomain.createOrder.itemInfo.specialInstructions': 'Special Instructions',
  'connectOrdersDomain.createOrder.itemInfo.alcoholic': 'Does the order contain alcohol?',
  'connectOrdersDomain.createOrder.itemInfo.leaveUnattended': 'Leave unattended?',
  'connectOrdersDomain.createOrder.itemInfo.smsOptOut': 'Opt out of text (SMS) messaging',
  'connectOrdersDomain.createOrder.itemInfo.total': 'Total',
  'connectOrdersDomain.createOrder.createReason': 'Creation Reason',
  'connectOrdersDomain.createOrder.createButton': 'Create',
  'connectOrdersDomain.createOrder.changeLocations': 'Change Locations',
  'connectOrdersDomain.createOrder.changeLocations.tooltip':
    'Location code cannot be changed when creating a new order. Click on Change Locations to create a new order.',
  'connectOrdersDomain.createOrder.fail': 'Failed to create order',
  'connectOrdersDomain.createOrder.success': 'Order created successfully',
  'connectOrdersDomain.createOrder.reason.retailerOmsIssue': 'Retailer OMS Issue',
  'connectOrdersDomain.createOrder.failToCreateAddress':
    'Failed to create order: invalid address info',
  'connectOrdersDomain.createOrder.failToCreateUser': 'Failed to create order: invalid user info',
  'connectOrdersDomain.createOrder.invalidLocationCode':
    'Failed to create order: invalid location code',
  'connectOrdersDomain.createOrder.invalidServiceOptionId':
    'Failed to create order: invalid service option Id',
  'connectOrdersDomain.createOrder.unsupportedLocale': 'Failed to create order: unsupported locale',
  'connectOrdersDomain.createOrder.invalidStartOrEndAt':
    'Failed to create order: invalid start or end at',
  'connectOrdersDomain.createOrder.invalidPhoneNumber':
    'Failed to create order: invalid phone number',
  'connectOrdersDomain.createOrder.invalidStreetAddress':
    'Failed to create order: invalid street address',
  'connectOrdersDomain.createOrder.invalidPostalCode':
    'Failed to create order: invalid postal code',
  'connectOrdersDomain.createOrder.noDeliverySlotInWindow':
    'Failed to create order: no delivery slot found in window',
  'connectOrdersDomain.createOrder.invalidAddressDeliveryNotSupported':
    'Failed to create order: provided address is too far from the selected store',
  'connectOrdersDomain.ordersTable.title': 'Connect Orders',
  'connectOrdersDomain.ordersTable.all.title': 'All Orders',
  'connectOrdersDomain.ordersTable.active.title': 'Active Orders',
  'connectOrdersDomain.ordersTable.past.title': 'Completed Orders',
  'connectOrdersDomain.ordersTable.canceled.title': 'Canceled Orders',
  'connectOrdersDomain.ordersTable.status.title': 'All Statuses',
  'connectOrdersDomain.ordersTable.orderPlaced.title': 'Order Placed',
  'connectOrdersDomain.ordersTable.waitingForDelivery.title': 'Waiting For Delivery',
  'connectOrdersDomain.ordersTable.orderEnRoute.title': 'Order En Route',
  'connectOrdersDomain.ordersTable.orderDelayed.title': 'Order Delayed',
  'connectOrdersDomain.ordersTable.orderComplete.title': 'Order Complete',
  'connectOrdersDomain.ordersTable.orderCancelled.title': 'Order Cancelled',
  'connectOrdersDomain.ordersTable.tooltip':
    'Estimated delivery time is past the customer’s delivery window',
  'connectOrdersDomain.ordersTable.orderDelayed': 'Order Delayed',
  'connectOrdersDomain.ordersTable.columns.orderId.header': 'Order ID',
  'connectOrdersDomain.ordersTable.columns.bagId.header': 'Bag ID',
  'connectOrdersDomain.ordersTable.columns.instacartId.header': 'Instacart ID',
  'connectOrdersDomain.ordersTable.columns.customerName.header': 'Customer Name',
  'connectOrdersDomain.ordersTable.columns.createdTime.header': 'Created',
  'connectOrdersDomain.ordersTable.columns.createdDate.header': 'Date',
  'connectOrdersDomain.ordersTable.columns.eta.header': 'ETA',
  'connectOrdersDomain.ordersTable.columns.storeName.header': 'Store',
  'connectOrdersDomain.ordersTable.columns.deliveryStatus.header': 'Status',
  'connectOrdersDomain.ordersTable.columns.deliveryWindow.header': 'Delivery Window',
  'connectOrdersDomain.ordersTable.columns.driverEtaToStore.header': 'Shopper ETA To Store',
  'connectOrdersDomain.ordersTable.columns.driverEtaToCustomer.header': 'Shopper ETA To Customer',
  'connectOrdersDomain.ordersTable.columns.driverArrivalToStore.header': 'Shopper At Store',
  'connectOrdersDomain.ordersTable.columns.driverArrivalToCustomer.header': 'Shopper At Customer',
  'connectOrdersDomain.ordersTable.columns.driverDispatchTime.header': 'Shopper Dispatched',
  'connectOrdersDomain.ordersTable.lastUpdated': 'Last updated',
  'connectOrdersDomain.ordersTable.refresh': 'Refresh',
  'connectOrdersDomain.orderDetails.filters.orderId': 'Order ID',
  'connectOrdersDomain.orderDetails.filters.phoneNumber': 'Phone Number',
  'connectOrdersDomain.orderDetails.filters.placeholder': 'Search',
  'connectOrdersDomain.orderDetails.filterSearch.search': 'Search',
  'connectOrdersDomain.orderDetails.filterSearch.clear': 'Clear',
  'connectOrdersDomain.orderDetails.title': 'Order details',
  'connectOrdersDomain.orderDetails.lastmile.title': 'Order Details',
  'connectOrdersDomain.orderDetails.lastmile.unavailable': 'Not available',
  'connectOrdersDomain.orderDetails.lastmiledelivery.title': 'Last mile delivery orders',
  'connectOrdersDomain.orderDetails.lastmiledelivery.orderNumber': 'Order {orderId}',
  'connectOrdersDomain.orderDetails.orderInformation.title': 'Order information',
  'connectOrdersDomain.orderDetails.orderInformation.notavailable': 'Not available',
  'connectOrdersDomain.orderDetails.orderInformation.orderId': 'Order ID',
  'connectOrdersDomain.orderDetails.orderInformation.locationCode': 'Location code',
  'connectOrdersDomain.orderDetails.orderInformation.bagDetails.title': 'Bag details',
  'connectOrdersDomain.orderDetails.orderInformation.bagDetails.bagCount': 'Bag count',
  'connectOrdersDomain.orderDetails.orderInformation.bagDetails.bagLabel': 'Bag label',
  'connectOrdersDomain.orderDetails.orderInformation.bagDetails.itemCount': 'Item count',
  'connectOrdersDomain.orderDetails.orderInformation.bagDetails.itemWeight':
    'Item weight (in lbs.)',
  'connectOrdersDomain.orderDetails.orderInformation.alcoholic': 'This order includes alcohol',
  'connectOrdersDomain.orderDetails.orderInformation.transactionInformation.title':
    'Transaction information',
  'connectOrdersDomain.orderDetails.orderInformation.transactionInformation.initialTip':
    'Initial tip',
  'connectOrdersDomain.orderDetails.orderInformation.transactionInformation.initialTipValue':
    '${initialTip}',
  'connectOrdersDomain.orderDetails.orderInformation.transactionInformation.cartTotal':
    'Cart total',
  'connectOrdersDomain.orderDetails.orderInformation.transactionInformation.cartTotalValue':
    '${cartTotal}',
  'connectOrdersDomain.orderDetails.deliveryInformation.title': 'Delivery information',
  'connectOrdersDomain.orderDetails.deliveryInformation.deliveryWindow': 'Delivery window',
  'connectOrdersDomain.orderDetails.deliveryInformation.deliveryWindow.timeString':
    '{startTime} to {endTime}',
  'connectOrdersDomain.orderDetails.deliveryInformation.additionalDeliveryInformation.title':
    'Additional delivery information',
  'connectOrdersDomain.orderDetails.deliveryInformation.additionalDeliveryInformation.specialInstruction':
    'Special instructions',
  'connectOrdersDomain.orderDetails.deliveryInformation.additionalDeliveryInformation.leaveUnattended':
    'Leave unattended',
  'connectOrdersDomain.orderDetails.orderHistory.title': 'Order history',
  'connectOrdersDomain.orderDetails.orderHistory.columns.action.header': 'Action',
  'connectOrdersDomain.orderDetails.orderHistory.columns.dateOfChange.header': 'Date',
  'connectOrdersDomain.orderDetails.orderHistory.columns.timeOfChange.header': 'Time of Change',
  'connectOrdersDomain.orderDetails.orderHistory.columns.deliveryDate.header': 'Delivery Date',
  'connectOrdersDomain.orderDetails.orderHistory.columns.deliveryWindow.header': 'Delivery Window',
  'connectOrdersDomain.orderDetails.orderHistory.orderCancelled': 'Order Cancelled',
  'connectOrdersDomain.orderDetails.orderHistory.orderCreated': 'Order Created',
  'connectOrdersDomain.orderDetails.orderHistory.orderRescheduled': 'Order Rescheduled',
  'connectOrdersDomain.orderDetails.orderHistory.orderDelivered': 'Order Delivered',
  'connectOrdersDomain.reschedule.title': 'Reschedule Order',
  'connectOrdersDomain.reschedule.rescheduleOrderNumber': 'Reschedule Order {orderId}',
  'connectOrdersDomain.reschedule.customerInformation': 'Customer Information',
  'connectOrdersDomain.reschedule.firstName': 'First name',
  'connectOrdersDomain.reschedule.lastName': 'Last name',
  'connectOrdersDomain.reschedule.phoneNumber': 'Phone number',
  'connectOrdersDomain.reschedule.deliveryAddress': 'Delivery Address',
  'connectOrdersDomain.reschedule.streetName': 'Street name',
  'connectOrdersDomain.reschedule.includeBuildingNumber': 'include bulding number',
  'connectOrdersDomain.reschedule.inLbs': 'in lbs',
  'connectOrdersDomain.reschedule.unitNumber': 'Unit number',
  'connectOrdersDomain.reschedule.city': 'City',
  'connectOrdersDomain.reschedule.zipCode': 'Zip code',
  'connectOrdersDomain.reschedule.orderInformation': 'Order Information',
  'connectOrdersDomain.reschedule.locationCode': 'Location code',
  'connectOrdersDomain.reschedule.bagDetails': 'Bag details',
  'connectOrdersDomain.reschedule.bagCount': 'Bag count',
  'connectOrdersDomain.reschedule.itemCount': 'Item count',
  'connectOrdersDomain.reschedule.itemWeight': 'Item weight',
  'connectOrdersDomain.reschedule.includeAlcohol': 'This order includes Alcohol',
  'connectOrdersDomain.reschedule.transactionInformation': 'Transaction Information',
  'connectOrdersDomain.reschedule.initialTip': 'Initial tip',
  'connectOrdersDomain.reschedule.cartTotal': 'Cart total',
  'connectOrdersDomain.reschedule.deliveryInformation': 'Delivery Information',
  'connectOrdersDomain.reschedule.serviceOptionId': 'I have a Service Option ID',
  'connectOrdersDomain.reschedule.serviceOptionId.label': 'Service Option ID',
  'connectOrdersDomain.reschedule.deliveryWindowStart': 'Delivery window start',
  'connectOrdersDomain.reschedule.deliveryWindowEnd': 'Delivery window end',
  'connectOrdersDomain.reschedule.deliveryStartDate': 'Delivery Date Start',
  'connectOrdersDomain.reschedule.deliveryEndDate': 'Delivery Date End',
  'connectOrdersDomain.reschedule.deliveryTimeStart': 'Delivery Time Start',
  'connectOrdersDomain.reschedule.selectDeliveryDate': 'Select Delivery Date',
  'connectOrdersDomain.reschedule.selectDeliveryTime': 'Select Delivery Time',
  'connectOrdersDomain.reschedule.deliveryTimeEnd': 'Delivery Time End',
  'connectOrdersDomain.reschedule.deliveryDate': 'Delivery Date',
  'connectOrdersDomain.reschedule.deliveryTime': 'Delivery Time',
  'connectOrdersDomain.reschedule.notAvailable': 'Not available',
  'connectOrdersDomain.reschedule.cancelButton': 'Cancel Order',
  'connectOrdersDomain.reschedule.rescheduleButton': 'Reschedule Order',
  'connectOrdersDomain.reschedule.cancelOrderError': 'There is an error when cancelling order',
  'connectOrdersDomain.reschedule.rescheduleOrderError':
    'There is an error when rescheduling order',
  'connectOrdersDomain.reschedule.cancel': 'Cancel',
  'connectOrdersDomain.reschedule.reschedule': 'Reschedule',
  'connectOrdersDomain.reschedule.orderRescheduled': 'Order rescheduled',
  'connectOrdersDomain.reschedule.orderCancelled': 'Order canceled',
  'connectOrdersDomain.reopen.orderReopened': 'Order reopened',
  'connectOrdersDomain.reopen.title': 'Reopen Order {orderId}',
  'connectOrdersDomain.reopen.orderReopenError': 'There is an error when reopening order',
  'connectOrdersDomain.reopen.externalOrderId': 'New Order ID',
  'connectOrdersDomain.orderTracking.title': 'Order tracking',
  'connectOrdersDomain.orderTracking.trackingDetails.title': 'Tracking details',
  'connectOrdersDomain.orderTracking.trackingDetails.orderId': 'Order ID',
  'connectOrdersDomain.orderTracking.trackingDetails.pickupTime': 'Est. Pickup Time',
  'connectOrdersDomain.orderTracking.trackingDetails.deliveryTime': 'Est. Delivery Time',
  'connectOrdersDomain.orderTracking.trackingDetails.finalPickupTime': 'Pickup Time',
  'connectOrdersDomain.orderTracking.trackingDetails.finalDeliveryTime': 'Delivery Time',
  'connectOrdersDomain.orderTracking.trackingDetails.timeUnavailable': 'Waiting For Delivery',
  'connectOrdersDomain.orderTracking.trackingDetails.retrievingInformation':
    'Retrieving information...',
  'connectOrdersDomain.orderTracking.customerInformation.title': 'Customer information',
  'connectOrdersDomain.orderTracking.customerInformation.name': 'Name',
  'connectOrdersDomain.orderTracking.customerInformation.number': 'Contact Number',
  'connectOrdersDomain.orderTracking.customerInformation.address': 'Address',
  'connectOrdersDomain.orderTracking.shopperInformation.title': 'Shopper information',
  'connectOrdersDomain.orderTracking.shopperInformation.name': 'Name',
  'connectOrdersDomain.orderTracking.shopperInformation.shopperUnavailable': 'Waiting For Shopper',
  'connectOrdersDomain.orderTracking.carModelAndColor': 'Car Model and Color',
  'connectOrdersDomain.orderTracking.unavailable': 'Not available',
  'connectOrdersDomain.orderTracking.orderStatus': 'Order Status',
  'connectOrdersDomain.orderTracking.rescheduleButton': 'Reschedule',
  'connectOrdersDomain.orderTracking.rescheduledFlag': 'Rescheduled',
  'connectOrdersDomain.orderTracking.cancelButton': 'Cancel',
  'connectOrdersDomain.orderTracking.reopenButton': 'Reopen',
  'connectOrdersDomain.orderTracking.cancelOrderButton': 'Cancel Order',
  'connectOrdersDomain.orderTracking.canceledstatus.title': 'Canceled',
  'connectOrdersDomain.orderTracking.canceledByInstacart':
    '<strong>Canceled</strong> by Instacart -',
  'connectOrdersDomain.orderTracking.canceledByRetailer': '<strong>Canceled</strong> by Retailer',
  'connectOrdersDomain.orderTracking.cancelOrderModal.title': 'Cancel Order {orderId}',
  'connectOrdersDomain.orderTracking.cancelOrderModal.reason.duplicateOrder':
    'I created a duplicate order',
  'connectOrdersDomain.orderTracking.cancelOrderModal.reason.orderNotNeeded':
    "I don't need this order anymore",
  'connectOrdersDomain.orderTracking.cancelOrderModal.reason.changeOrder':
    'I want to change my order',
  'connectOrdersDomain.orderTracking.cancelOrderModal.reason.outOfStock': 'Items were out of stock',
  'connectOrdersDomain.orderTracking.cancelOrderModal.reason.deliveryTimeConflict':
    "New delivery time didn't work",
  'connectOrdersDomain.orderTracking.cancelOrderModal.reason.other': 'Other',
  'connectOrdersDomain.orderTracking.cancelOrderModal.reason.orderIssue':
    "There's a problem with my order",
  'connectOrdersDomain.orderTracking.cancelOrderModal.reasonForCancelling': 'Reason for Canceling',
  'connectOrdersDomain.orderTracking.cancelOrderModal.details': 'Enter any additional details',
  'connectOrdersDomain.tabs.ariaLabel': 'Order details tabs',
  'connectOrdersDomain.orderTracking.orderBeingDelivered':
    'This order can no longer be modified. If any changes are needed, please issue the necessary credit, refund, and create a new delivery order on the LMD Dashboard for delivery if needed.',
  'connectOrdersDomain.orderTracking.map.scheduledForDelivery': 'Scheduled for delivery',
  'connectOrdersDomain.orderTracking.map.outForDelivery': 'Delivery en route',
  'connectOrdersDomain.orderTracking.map.delivered': 'Delivered',
  'connectOrdersDomain.orderTracking.map.accuracySubtext':
    'Note: This map is updated every 3-5 mins',
  'connectOrdersDomain.ordersTable.emptyState':
    'No last mile delivery orders for the selected time period',
  'connectOrdersDomain.ordersTable.emptyStateSearch':
    'No last mile delivery orders for the order ID or phone number entered',
  'connectOrdersDomain.ordersTable.noFiltersSelected':
    'Please select a location or enter an order ID to search for orders',
  'connectOrdersDomain.orderTracking.stageOrderModal.title': 'Stage Order',
  'connectOrdersDomain.orderTracking.stageOrderModal.numItems': 'Number of Items',
  'connectOrdersDomain.orderTracking.stageOrderModal.numBags': 'Number of Bags',
  'connectOrdersDomain.orderTracking.stageOrderModal.bagLabel': 'Bag Label',
  'connectOrdersDomain.orderTracking.stageOrderModal.itemWeight': 'Item Weight',
  'connectOrdersDomain.orderTracking.stageOrderModal.optional': 'Optional',
  'connectOrdersDomain.orderTracking.stageOrderModal.abortStage': 'Cancel',
  'connectOrdersDomain.orderTracking.stageOrderModal.stage': 'Stage',
  'connectOrdersDomain.orderTracking.stageOrderButton': 'Stage Order',
  'connectOrdersDomain.stage.orderStageError': 'There is an error when staging order',
  'connectOrdersDomain.stage.orderStaged': 'Order staged',
  'ConnectLocationModal.createOrder.title': 'Create New Order',
  'ConnectLocationModal.createOrder.description.locationSelect':
    'Select a location to see available delivery dates and times.',
  'ConnectLocationModal.changeLocations.title': 'Change Locations',
  'ConnectLocationModal.changeLocations.description':
    'Changing locations will create a new order and discard your current order creation.',
  'ConnectLocationModal.field.locationCode': 'Location Code',
  'ConnectLocationModal.cancelButton': 'Cancel',
  'ConnectLocationModal.nextButton': 'Next',
  'ConnectLocationModal.errorMessage.title': 'Unable to create order',
  'ConnectLocationModal.errorMessage.description':
    'No Delivery Dates available with location code. Input a new code or contact CARE to create an order.',
  'ConnectOrdersCreateOrderErrorModal.confirmButton': 'Confirm',
  'ConnectOrdersCreateOrderErrorModal.invalidAddress': 'Invalid address info',
  'ConnectOrdersCreateOrderErrorModal.invalidUser': 'Invalid user info',
  'ConnectOrdersCreateOrderErrorModal.invalidLocationCode': 'Invalid location code',
  'ConnectOrdersCreateOrderErrorModal.invalidServiceOptionId': 'Invalid service option Id',
  'ConnectOrdersCreateOrderErrorModal.unsupportedLocale': 'Unsupported locale',
  'ConnectOrdersCreateOrderErrorModal.invalidStartOrEndAt': 'Invalid start or end at',
  'ConnectOrdersCreateOrderErrorModal.invalidPhoneNumber': 'Invalid phone number',
  'ConnectOrdersCreateOrderErrorModal.invalidStreetAddress': 'Invalid street address',
  'ConnectOrdersCreateOrderErrorModal.invalidPostalCode': 'Invalid postal code',
  'ConnectOrdersCreateOrderErrorModal.noDeliverySlotInWindow': 'No delivery slot found in window',
  'ConnectOrdersCreateOrderErrorModal.invalidAddressDeliveryNotSupported':
    'Provided address is too far from the selected store',
  'ConnectOrdersCreateOrderErrorModal.orderAlreadyExists': 'Order ID already in use',
  'ConnectOrdersCreateOrderErrorModal.error': 'Error: ',
  'connectOrdersDomain.deliverySlot.immediate': 'Immediate {hour} hour',
  'connectOrdersDomain.deliverySlot.eta': 'Eta {time}',
  'connectOrdersDomain.lastmiledelivery.orderStatus.orderCancelled': 'Order Cancelled',
  'connectOrdersDomain.lastmiledelivery.orderStatus.orderComplete': 'Order Complete',
  'connectOrdersDomain.lastmiledelivery.orderStatus.orderDelayed': 'Order Delayed',
  'connectOrdersDomain.lastmiledelivery.orderStatus.orderEnRoute': 'Order En Route',
  'connectOrdersDomain.lastmiledelivery.orderStatus.orderInProgress': 'Order In Progress',
  'connectOrdersDomain.lastmiledelivery.orderStatus.orderPlaced': 'Order Placed',
  'connectOrdersDomain.lastmiledelivery.orderStatus.waitingForDelivery': 'Waiting For Delivery',
  'connectOrdersDomain.lastmiledelivery.errorPage.title': 'An error occurred',
  'connectOrdersDomain.lastmiledelivery.errorPage.message':
    'There was an issue retrieving that information. Please try again.',
} as const

export default EN_US
