import React, { useMemo, useCallback } from 'react'
import { Select } from '../select/Select'
import { type SimpleOption } from '../select/utils/select.types'
import { type SimpleSelectProps, type SimpleSelectValueType } from './simpleSelect.types'

const resolveOption = <T extends SimpleOption<any>>(options: T[], value?: T['value']) =>
  options.find(option => value === option.value)

/**
 * @deprecated Prefer using the NewSelect component from @retailer-platform/shared-components
 */
export const SimpleSelect = <TValue extends SimpleSelectValueType = string>(
  props: SimpleSelectProps<TValue>
) => {
  const { value, onChange, defaultValue, options, ...rest } = props

  const resolvedValue = useMemo(() => resolveOption(options, value), [value, options])
  const resolvedDefaultValue = useMemo(
    () => resolveOption(options, defaultValue),
    [defaultValue, options]
  )

  const handleChange = useCallback(
    (option?: SimpleOption<TValue>) => onChange((option ? option.value : option) as TValue),
    [onChange]
  )

  return (
    <Select
      {...rest}
      onChange={handleChange}
      options={options}
      value={resolvedValue}
      defaultValue={resolvedDefaultValue}
      isMulti={false}
    />
  )
}
