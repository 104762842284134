import { type SimpleOption } from '@retailer-platform/shared-components/src/common/select/utils/select.types'
import type dayjs from 'dayjs'
import { ComparisonPeriod } from '@retailer-platform/shared-components/src/common/date-range-picker/utils/DateRangePickerPresets.utils'
import { type InsightsPortalValueInput, type InsightsPortalFilterSelectionInput } from '../api'

export const makeInsightsPortalValueInput = (value, valueType: keyof InsightsPortalValueInput) => ({
  [valueType]: value,
})

/*
  transforms an array of selected filter options into an IN_FILTER filter selection
  for insights portal queries
*/
export const transformOptionsToInsightsPortalInFilterSelection = (
  filterParam: SimpleOption[] | undefined,
  filterSelectionName: string,
  valueType: keyof InsightsPortalValueInput
): InsightsPortalFilterSelectionInput | undefined => {
  if (!filterParam || filterParam.length === 0) return

  return filterParam.reduce(
    (insightsPortalFilterSelection, selection) => {
      insightsPortalFilterSelection['inFilter'].values = [
        ...insightsPortalFilterSelection['inFilter'].values,
        makeInsightsPortalValueInput(selection.value, valueType),
      ]

      return insightsPortalFilterSelection
    },
    {
      name: filterSelectionName,
      inFilter: { values: [] },
    } as InsightsPortalFilterSelectionInput
  )
}

export const calculateComparisonPeriod = (
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  comparisonType: ComparisonPeriod = ComparisonPeriod.LastPeriod
) => {
  if (comparisonType === ComparisonPeriod.LastPeriod) {
    const differenceInDays = to.diff(from, 'days')
    const lastPeriodTo = from.subtract(1, 'second').endOf('day')
    const lastPeriodFrom = lastPeriodTo.subtract(differenceInDays, 'days').startOf('day')

    return {
      from: lastPeriodFrom,
      to: lastPeriodTo,
    }
  } else if (comparisonType === ComparisonPeriod.LastPeriodDayOfWeekMatch) {
    const differenceInDays = to.diff(from, 'days')
    const lastPeriodTo = from.subtract(1, 'second').endOf('day')
    const lastPeriodFrom = lastPeriodTo.subtract(differenceInDays, 'days').startOf('day')

    const forwardShift = (from.day() - lastPeriodFrom.day() + 7) % 7
    const backwardShift = -1 * ((lastPeriodFrom.day() - from.day() + 7) % 7)

    // if the forward day shift is less than the backward day shift, shift the last period forward
    if (Math.abs(forwardShift) < Math.abs(backwardShift)) {
      return {
        from: lastPeriodFrom.add(forwardShift, 'days'),
        to: lastPeriodTo.add(forwardShift, 'days'),
      }
    }

    return {
      from: lastPeriodFrom.add(backwardShift, 'days'),
      to: lastPeriodTo.add(backwardShift, 'days'),
    }
  } else if (comparisonType === ComparisonPeriod.LastYearDayOfWeekMatch) {
    // shift the last period forward to match the day of the week of the current period
    const dayDifference = (from.day() - from.subtract(1, 'year').day() + 7) % 7
    const lastPeriodTo = to.subtract(1, 'year').add(dayDifference, 'days')
    const lastPeriodFrom = from.subtract(1, 'year').add(dayDifference, 'days')

    return {
      from: lastPeriodFrom,
      to: lastPeriodTo,
    }
  } else {
    return {
      from: from.subtract(1, 'year'),
      to: to.subtract(1, 'year'),
    }
  }
}
