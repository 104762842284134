import { type DashRouteType, URL_RETAILER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_NOTIFICATIONS = `${URL_RETAILER_SCOPED}/notifications`
const URL_BANNER_NOTIFICATIONS_LIST = `${URL_NOTIFICATIONS}/list`
const URL_PLATFORM_NOTIFICATIONS_LIST = `${URL_NOTIFICATIONS}/platform/list`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'notifications'>
  | DashRouteType<'banner-notifications-list'>
  | DashRouteType<'platform-notifications-list'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  notifications: URL_NOTIFICATIONS,
  'banner-notifications-list': URL_BANNER_NOTIFICATIONS_LIST,
  'platform-notifications-list': URL_PLATFORM_NOTIFICATIONS_LIST,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
