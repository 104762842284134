import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_CUSTOMERS = `${URL_RETAILER_SCOPED}/customers`
const URL_CUSTOMERS_SITE_SCOPED = `${URL_CUSTOMERS}${URL_SITE_SCOPED}`

// Customers -> Search Customers
const URL_CUSTOMERS_SEARCH = `${URL_CUSTOMERS_SITE_SCOPED}/search`
const URL_CUSTOMERS_CUSTOMER_DETAILS = `${URL_CUSTOMERS_SEARCH}/:customerId/details`
const URL_CUSTOMERS_CUSTOMER_PRIVACY_REQUESTS = `${URL_CUSTOMERS_SEARCH}/:customerId/privacy-requests`

const URL_ORDERS_OPERATIONAL_DASHBOARD = `${URL_RETAILER_SCOPED}/orders-operational-dashboard`
const URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_SEARCH_TAB = `${URL_ORDERS_OPERATIONAL_DASHBOARD}/search/search`

// Customers -> Privacy Requests
const URL_CUSTOMERS_PRIVACY_REQUESTS = `${URL_CUSTOMERS_SITE_SCOPED}/privacy-requests`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'customers'>
  | DashRouteType<'customers-search'>
  | DashRouteType<'customers-customer-details'>
  | DashRouteType<'customers-customer-privacy-requests'>
  | DashRouteType<'customers-privacy-requests'>
  | DashRouteType<'customers-customer-orders'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  customers: URL_CUSTOMERS_SITE_SCOPED,
  'customers-search': URL_CUSTOMERS_SEARCH,
  'customers-privacy-requests': URL_CUSTOMERS_PRIVACY_REQUESTS,
  'customers-customer-details': URL_CUSTOMERS_CUSTOMER_DETAILS,
  'customers-customer-privacy-requests': URL_CUSTOMERS_CUSTOMER_PRIVACY_REQUESTS,
  'customers-customer-orders': URL_ORDERS_OPERATIONAL_DASHBOARD_SEARCH_PAGE_SEARCH_TAB,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
