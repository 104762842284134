import React, { useState, useEffect, type FunctionComponent } from 'react'
import { Loader } from '@retailer-platform/shared-components/src/common/loader/Loader'
import { ErrorPage } from '../../../gin-and-tonic/containers/error-page/ErrorPage'
import { usePartnerId } from '../../routing/params.hooks'
import { useMeasureTiming } from '../../events/hooks'
import { StaleDataNotification } from '../warehouse/StaleDataNotification'
import { PartnerContext, type PartnerContextValue } from './PartnerContext'
import { useFetchPartner } from './queries/partner'

interface Props {
  id: string
}

const initialState: PartnerContextValue = {
  loaded: false,
  loading: false,
  error: false,
  partnerId: undefined,
  partnerName: undefined,
  partnerType: undefined,
  partner: undefined,
  validId: undefined,
  locationFeatures: undefined,
  warehouses: undefined,
  featureToggles: undefined,
  featureEntitlements: undefined,
  hasIaNavV2: undefined,
}

const PartnerProvider: FunctionComponent<React.PropsWithChildren<Props>> = ({ children, id }) => {
  const triggerMeasureEnd = useMeasureTiming(
    {
      id: 'partner_provider.loaded',
      description:
        'Time taken to load application initialization data (retailer locations, retailer locales, etc) from PartnerProvider mount to full load. Note that there may be additional variance due to retailer loading time (not cached due to roulette flags living there)',
    },
    []
  )

  const {
    partner,
    partnerName,
    partnerType,
    warehouses,
    locationFeatures,
    featureToggles,
    featureEntitlements,
    loading,
    error,
    errorWhileRevalidating,
    refetch,
  } = useFetchPartner(id)

  const [contextValue, setContextValue] = useState<PartnerContextValue>({ ...initialState })
  const partnerId = usePartnerId()
  const hasIaNavV2 = !!featureToggles?.find(feature => feature.name === 'rt_ia_nav_v2')

  // when switching between partners, useEffect propagates stale data for the first render
  const switching = partnerId !== contextValue.partnerId

  useEffect(() => {
    if (partner) {
      setContextValue({
        loaded: true,
        partnerName,
        partnerType,
        partnerId,
        loading: false,
        error: false,
        partner,
        validId: true,
        warehouses,
        locationFeatures,
        featureToggles,
        featureEntitlements,
        hasIaNavV2,
      })
    }
  }, [
    partner,
    partnerType,
    featureToggles,
    partnerName,
    partnerId,
    featureEntitlements,
    warehouses,
    locationFeatures,
    hasIaNavV2,
  ])

  useEffect(() => {
    if (loading) {
      setContextValue({
        loaded: false,
        loading: true,
        partnerId: undefined,
        partnerName: undefined,
        partnerType: undefined,
        error: false,
        partner: undefined,
        validId: undefined,
        locationFeatures: undefined,
        warehouses: undefined,
        featureToggles: undefined,
        featureEntitlements: undefined,
        hasIaNavV2: undefined,
      })
    }
  }, [loading])

  useEffect(() => {
    if (error) {
      setContextValue({
        loaded: false,
        loading: false,
        partnerId: undefined,
        partnerName: undefined,
        partnerType: undefined,
        error: true,
        partner: undefined,
        validId: undefined,
        locationFeatures: undefined,
        warehouses: undefined,
        featureToggles: undefined,
        featureEntitlements: undefined,
        hasIaNavV2: undefined,
      })
    }
  }, [error])

  useEffect(() => {
    if (!loading && !error)
      triggerMeasureEnd({
        retailerId: id,
        cacheEnabled:
          !!featureToggles
            .map?.(v => v.name)
            .includes?.('rt_retailer_stale_while_revalidate_enabled') ?? false,
      })
  }, [error, featureToggles, id, loading, triggerMeasureEnd])

  if (error) return <ErrorPage action={() => refetch()} />

  return (
    <PartnerContext.Provider value={contextValue}>
      {errorWhileRevalidating && <StaleDataNotification refetch={refetch} />}

      {contextValue.loaded && !switching ? children : <Loader />}
    </PartnerContext.Provider>
  )
}

export default PartnerProvider
