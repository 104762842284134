import React, { useMemo, type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { type Partner } from '../../../__codegen__/api'
import { useDashMessages } from '../../../intl/intl.hooks'
import { PartnerType } from '../../../exports/utils'
import { MenuOption } from './MenuOption'

interface Props {
  partner: Pick<Partner, 'id' | 'name' | 'partnerType'> & {
    retailers: { id: string; name?: string; logo: { url: string } }[]
  }
  onPartnerRemoveFromRecents?: (id: string) => void
  debouncedSearchFilter?: string
}

const Heavy = styled.span({ fontWeight: 600 })

const createHighlightedTitleElement = (partner, debouncedSearchFilter) => {
  const match = partner?.name?.match(new RegExp(debouncedSearchFilter, 'i'))
  const matchIndex = match?.index
  const matchLength = match?.[0]?.length

  if (!match) {
    return partner?.name
  }

  const label = (
    <>
      {partner?.name?.slice(0, matchIndex)}
      <Heavy>{partner?.name?.slice(matchIndex, matchIndex + matchLength)}</Heavy>
      {partner?.name?.slice(matchIndex + matchLength)}
    </>
  )

  return label
}

export const PartnerOption: FunctionComponent<React.PropsWithChildren<Props>> = ({
  partner,
  onPartnerRemoveFromRecents,
  debouncedSearchFilter,
}) => {
  const message = useDashMessages({
    banners: 'selectPartner.banners',
    searchBanners: 'selectPartner.searchResults.banners',
    searchMatch: 'selectPartner.searchResults.match',
  })

  const numMatches = debouncedSearchFilter
    ? partner.retailers.filter(
        retailer =>
          retailer.id === debouncedSearchFilter ||
          retailer?.name?.match(new RegExp(debouncedSearchFilter, 'i'))
      ).length
    : null

  const numMatchText = numMatches ? (
    <>
      <Heavy>
        {numMatches} {message.searchBanners}
      </Heavy>{' '}
      {message.searchMatch} <Heavy>“{debouncedSearchFilter}”</Heavy>
    </>
  ) : null

  const logos = partner.retailers.map(e => e?.logo?.url).slice(0, 4)

  const label = useMemo(
    () => createHighlightedTitleElement(partner, debouncedSearchFilter),
    [partner, debouncedSearchFilter]
  )

  const labelTag = useMemo(() => {
    switch (partner.partnerType) {
      case PartnerType.IDP:
        return 'IDP'
      case PartnerType.Retailer:
        return 'Retailer'
      default:
        return null
    }
  }, [partner.partnerType])

  return (
    <MenuOption
      label={label}
      labelTag={labelTag}
      subLabel={numMatchText}
      farRightSubLabel={
        partner.partnerType === PartnerType.Retailer &&
        `${partner.retailers.length} ${message.banners}`
      }
      logos={logos}
      heavyLabel={!debouncedSearchFilter}
      onClose={
        onPartnerRemoveFromRecents &&
        (e => {
          onPartnerRemoveFromRecents(partner.id)
          e.stopPropagation()
        })
      }
    />
  )
}
