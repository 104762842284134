import { type SimpleOption } from '@retailer-platform/shared-components/src/common/select/utils/select.types'
import { type InsightsPortalValueInput, type InsightsPortalFilterSelectionInput } from '../../api'
import { makeInsightsPortalValueInput } from '../../utils/insights-portal.utils'

/* 
  transforms an array of selected filter options into an IN_FILTER filter selection 
  for insights portal queries
*/
export const optionsArrayToInFilterSelection =
  (valueType: keyof InsightsPortalValueInput) =>
  (
    filterParam: SimpleOption[],
    filterSelectionName: string
  ): InsightsPortalFilterSelectionInput => {
    if (!filterParam || filterParam.length === 0) return

    return filterParam.reduce(
      (insightsPortalFilterSelection, selection) => {
        insightsPortalFilterSelection['inFilter'].values = [
          ...insightsPortalFilterSelection['inFilter'].values,
          makeInsightsPortalValueInput(selection.value, valueType),
        ]

        return insightsPortalFilterSelection
      },
      {
        name: filterSelectionName,
        inFilter: { values: [] },
      } as InsightsPortalFilterSelectionInput
    )
  }

/* 
  transforms a single string options into an IN_FILTER filter selection 
  for insights portal queries
*/
export const stringToInFilterSelection =
  (valueType: keyof InsightsPortalValueInput) =>
  (filterParam: string, filterSelectionName: string): InsightsPortalFilterSelectionInput => {
    if (!filterParam || filterParam.length === 0) return

    return {
      name: filterSelectionName,
      inFilter: { values: [makeInsightsPortalValueInput(filterParam, valueType)] },
    }
  }
