import { useEffect, useMemo } from 'react'
import { type DashMessages } from '../../intl/intl.types'
import { useDashIntl } from '../../intl/intl.hooks'

export const _useDocumentTitleString = (titleString: string) => {
  useEffect(() => {
    const originalTitle = document.title
    document.title = titleString

    return () => {
      document.title = originalTitle
    }
  }, [titleString])
}

export const useDocumentTitle = (titleId: DashMessages) => {
  const intl = useDashIntl()

  const nextTitle = useMemo(() => intl.formatMessage({ id: titleId }), [intl, titleId])

  _useDocumentTitleString(nextTitle)
}
