import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'

interface SpacerProps {
  height?: Number
  width?: Number
}
export const Spacer = styled.div<SpacerProps>(({ height, width }) => ({
  margin: `${height || 0}px ${width || 0}px`,
}))

export const LinkText = styled.div({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: 'auto',
  marginRight: 0,
})

export const IconButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  minHeight: '28px',
  background: '#FFFFFF',
  borderRadius: '6px',
  padding: '6px',
  '&:hover': {
    backgroundColor: colors.GRAYSCALE.X20,
    cursor: 'pointer',
  },
})
