// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

export default FlexColumn
