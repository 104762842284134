import { type Moment } from 'moment'

export const dateRange = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
  LAST_7_DAYS: 'last_7_days',
  LAST_30_DAYS: 'last_30_days',
  LAST_90_DAYS: 'last_90_days',
  CUSTOM: 'custom',
} as const

export type DateRange = ValueOf<typeof dateRange>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DateRangeOption<TDateRange extends DateRange = any> = {
  value: TDateRange
  label: string
}

export type StartEndDate = {
  startDate: Moment | null
  endDate: Moment | null
}

export interface StrictStartEndDate extends StartEndDate {
  startDate: Moment
  endDate: Moment
}

export interface StringifiedStartEndDate {
  startDateStr: string
  endDateStr: string
}
