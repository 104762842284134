const EN_US = {
  'shoppingListDomain.title': 'Shopper list',
  'shoppingListDomain.page.description.title': 'Shopping list order for store associates',
  'shoppingListDomain.page.description.content':
    'Rearrange aisles to change the order of the shopping list your store associates see on the app. This can help you create a more efficient route.',
  'shoppingListDomain.page.notice.storeAssociates.title':
    'Shopping list order for store associates',
  'shoppingListDomain.page.notice.storeAssociates.content':
    'Rearrange aisles to change the order of the shopping list your store associates see on the app. This can help create a more efficient route.',
  'shoppingListDomain.page.actionBar.edit': 'Edit',
  'shoppingListDomain.page.actionBar.cancel': 'Cancel',
  'shoppingListDomain.page.actionBar.save': 'Save',
  'shoppingListDomain.page.orderList.notice.title':
    'Rearrange aisles by dragging rows up and down or using the dropdown selector',
  'shoppingListDomain.page.orderList.notice.content': 'When you’re done making edits, click save.',
  'shoppingListDomain.page.orderList.notice.corporateDropdownTitle':
    'Use the same aisle order for other stores',
  'shoppingListDomain.page.orderList.notice.corporateDropdownContent':
    'You can only do this for stores that have the same number and type of aisles.',
  'shoppingListDomain.table.header.column.position': 'Position in the shopping list',
  'shoppingListDomain.table.header.column.aisle': 'Aisle',
  'shoppingListDomain.modal.title.saveChanges': 'Save Changes?',
  'shoppingListDomain.modal.button.cancel': 'Cancel',
  'shoppingListDomain.modal.button.confirm': 'Confirm',
  'shoppingListDomain.modal.content.numberOfAisleChanged':
    '{numberOfAisleChanged} aisles have been rearranged',
  'shoppingListDomain.modal.content.numberOfStoreChanged':
    '{numberOfStoreChanged} stores will have the same aisle order',
  'shoppingListDomain.modal.content.effectiveTomorrow':
    'Changes go into effect in the Shopper App tomorrow, {date}.',
  'shoppingListDomain.page.banner.default':
    'This store is using the default list. Edit the aisle template below to customize the shopping list order.',
  'shoppingListDomain.page.banner.willGoLiveTomorrow':
    'The new shopping list order will go into effect  in the Shopper App tomorrow, {date}.',
  'shoppingListDomain.page.orderList.noData': 'The shopping list currently has no aisle data.',
  'shoppingListDomain.page.orderList.storesDropdown.defaultPlaceholder': 'Select Stores',
  'shoppingListDomain.page.orderList.storesDropdown.clear': 'Clear',
  'shoppingListDomain.page.orderList.storesDropdown.apply': 'Apply',
  'shoppingListDomain.page.orderList.storesDropdown.changesSaved': 'Changes saved',
  'shoppingListDomain.page.orderList.storesDropdown.failed':
    'Changes failed to save. Please try again later.',
} as const

export default EN_US
