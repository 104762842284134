import { type Theme as IdsTheme } from '@instacart/ids-core'

// When new nav is commited to:
// Move this declaration to the new Nav and rename to NavEntryPoint
export enum NavItem {
  Dashboard = 'dashboard',
  Reports = 'reports',
  Files = 'files',
  Shoppers = 'shoppers',
  Catalog = 'catalog',
  Admin = 'admin',
  Orders = 'orders',
  Settings = 'settings',
  Instacart = 'instacart',
  Help = 'help',
  Unata = 'unata',
  Connect = 'connect',
  Merchandising = 'merchandising',
  InsightsPortal = 'insightsPortal',
  StoreOperations = 'storeOperations',
  StorePerformance = 'storePerformance',
  DevPortal = 'devPortal',
  Storefront = 'storefront',
  DataAndAnalytics = 'dataAndAnalytics',
  RetailerFundedMarketing = 'retailerFundedMarketing',
  Customers = 'customers',
  BrandExplorer = 'brandExplorer',
  InventoryIntelligence = 'inventoryIntelligence',
  Developer = 'developer',
}

export enum NavSupportedEnvironment {
  Production = 'production',
  Dev = 'enterprise-dev',
}

// Used for the new IA Nav bar
export enum NavEntryPoint {
  AnalyticsStorefrontPerformance = 'analyticsStorefrontPerformance',
  AnalyticsCatalog = 'analyticsCatalog',
  AnalyticsMerchandising = 'analyticsMerchandising',
  AnalyticsMarketing = 'analyticsMarketing',
  AnalyticsReportsAndLogs = 'analyticsReportsAndLogs',

  Catalog = 'catalog',
  Merchandising = 'merchandising',
  Marketing = 'marketing',

  Operations = 'operations',
  OperationsGeneral = 'operationsGeneral',
  OperationsCustomerSupport = 'operationsCustomerSupport',
  OperationsLabor = 'operationsLabor',

  Developer = 'developer',

  UserMenuSettings = 'userMenuSettings',
  UserMenuUserManagement = 'userMenuUserManagement',
}

export type LocalNavTheme = IdsTheme & { inverted: boolean }
