import { FeatureToggle } from '../access-control/featureToggles'
import { InsightsPortalDataSource } from '../api'
import { useDomainPartnerAccessControl } from './domain/accessControl'

// Add dashboard-specific Clickhouse feature toggles here
const DASHBOARD_TO_CLICKHOUSE_FLIPPER: Record<string, FeatureToggle> = {
  'insights-portal/performance/overview': FeatureToggle.ClickhousePerformance,
  'insights-portal/performance/out-of-stock': FeatureToggle.ClickhouseOutOfStock,
  'insights-portal/performance/basket': FeatureToggle.ClickhouseBasket,
  'insights-portal/performance/search': FeatureToggle.ClickhouseSearch,
  'insights-portal/custom-report': FeatureToggle.ClickhouseDataExplorer,
  'insights-portal/executive-summary': FeatureToggle.ClickhouseExecutiveSummary,
}

export const useDataSource = (override?: InsightsPortalDataSource): InsightsPortalDataSource => {
  const hasAccess = useDomainPartnerAccessControl()

  if (override) {
    return override
  }

  // if the current page url contains one of the dashboard paths above, we'll check the corresponding featureToggle
  const featureToggle =
    DASHBOARD_TO_CLICKHOUSE_FLIPPER[
      Object.keys(DASHBOARD_TO_CLICKHOUSE_FLIPPER).find(d => window.location.pathname.includes(d))
    ]

  if (
    featureToggle &&
    hasAccess([
      {
        featureToggles: featureToggle,
      },
      {
        warehouseFeatureToggles: featureToggle,
      },
    ])
  ) {
    return InsightsPortalDataSource.Clickhouse
  }
}
