import {
  useGoToPathFactory,
  useScopedPathnameFactory,
  useCreateHrefFactory,
} from '@retailer-platform/dashboard/routing'
import { type DomainRouteName } from '../../routing/routes'

export const useDomainScopedPathname = useScopedPathnameFactory<DomainRouteName>()
export const useDomainGoToPath = useGoToPathFactory<DomainRouteName>()
export const useDomainCreateHref = useCreateHrefFactory<DomainRouteName>()
