import { SiteSelectOption } from '../site-select/SiteSelect'
import { type FilterSitesFn } from './SelectedSitePage'

// Instacart Marketplace site id
export const MARKETPLACE_SITE_ID = '1'

/**
 * Updates the SiteOptions to NOT allow any sites that have an isolated user context.
 */
export const DisallowIsolatedUserContexts: FilterSitesFn = siteOptions =>
  siteOptions.map(siteOption => {
    if (
      siteOption.selectOption === SiteSelectOption.ALLOW &&
      +siteOption.site.userIsolationContextId !== 1
    ) {
      // If the site is allowed but has a user isolation context, then we
      // should not allow it to be selected.
      return {
        ...siteOption,
        selectOption: SiteSelectOption.SELECT_NOT_APPLICABLE,
      }
    }

    return siteOption
  })

/**
 * Updates the SiteOptions to NOT allow any sites that DO NOT have an isolated user context.
 */
export const DisallowNonIsolatedUserContexts: FilterSitesFn = siteOptions =>
  siteOptions.map(siteOption => {
    if (
      siteOption.selectOption === SiteSelectOption.ALLOW &&
      +siteOption.site.userIsolationContextId === 1
    ) {
      // If the site is allowed but has no user isolation context, then we
      // should not allow it to be selected.
      return {
        ...siteOption,
        selectOption: SiteSelectOption.SELECT_NOT_APPLICABLE,
      }
    }

    return siteOption
  })

export const HideMarketplace: FilterSitesFn = siteOptions =>
  siteOptions.map(siteOption => {
    if (siteOption.site.id == MARKETPLACE_SITE_ID) {
      return {
        ...siteOption,
        selectOption: SiteSelectOption.HIDE,
      }
    }

    return siteOption
  })

/**
 * Updates the SiteOptions to HIDE any sites that are inactive.
 */
export const HideInactiveSite: FilterSitesFn = siteOptions =>
  siteOptions.map(siteOption => {
    if (siteOption.site.active === false) {
      return {
        ...siteOption,
        selectOption: SiteSelectOption.HIDE,
      }
    }

    return siteOption
  })
