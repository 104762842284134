import React, { type FunctionComponent, useCallback, useState } from 'react'
import { type RedirectProps } from 'react-router'
import { type DashRouteName } from '../../../utils/routing/routes'
import { DashRedirect } from '../../../legacy/dash-components/dash-redirect/DashRedirect'
import { TextButton, type TextButtonProps } from '../../common/text-button/TextButton'

type Props = Omit<TextButtonProps, 'type'> &
  (
    | {
        to?: undefined
        toRoute: DashRouteName
      }
    | {
        to: RedirectProps['to']
        toRoute?: undefined
      }
  )

export const LinkTextButton: FunctionComponent<React.PropsWithChildren<Props>> = ({
  to,
  toRoute,
  ...rest
}) => {
  const [clicked, setClicked] = useState<boolean>(false)
  const onClickHandler = useCallback(() => setClicked(true), [])

  if (clicked) {
    // Splitting this into two to make TypeScript happy
    // Our union type will be sufficient, but TypeScript can't resolve this automatically
    if (to) return <DashRedirect push to={to} />
    if (toRoute) return <DashRedirect push toRoute={toRoute} />
  }

  return <TextButton {...rest} onClick={onClickHandler} />
}
