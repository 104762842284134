import React, { useMemo } from 'react'
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  TextArea,
} from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { ButtonArrayComponent } from '../../RetailerSatisfactionSurveyComponents'
import { useDashMessages } from '../../../../../intl/intl.hooks'
import { useSurvey } from '../../RetailerSatisfactionSurveyContext'
import {
  RetailerFeedbackSurveyPages,
  type RetailerFeedbackSurveyState,
  type RetailerFeedbackSurveyProps,
} from '../RetailerFeedbackSurveyController'
import { useRetailerFeedbackSurveyStorage } from '../useRetailerFeedbackSurveyAccess'
import { trackEvent } from '../../../../../utils/events/trackEvent'

const SurveyInputContainer = styled.div({
  display: 'flex',
})

const Page1 = () => {
  const { surveyState, updateSurveyState, submitSurvey, goToPage } = useSurvey<
    RetailerFeedbackSurveyPages,
    RetailerFeedbackSurveyState,
    RetailerFeedbackSurveyProps
  >()
  const { data, setData } = useRetailerFeedbackSurveyStorage()
  const isOverallExperienceOptionSelected = surveyState.overallExperienceResponse !== ''

  const messages = useDashMessages(
    {
      title: 'retailerFeedback.userSurvey.page1.title',
      option1: 'retailerFeedback.userSurvey.page1.option1',
      option2: 'retailerFeedback.userSurvey.page1.option2',
      option3: 'retailerFeedback.userSurvey.page1.option3',
      option4: 'retailerFeedback.userSurvey.page1.option4',
      option5: 'retailerFeedback.userSurvey.page1.option5',
      submit: 'retailerFeedback.userSurvey.page1.submit',
      textAreaPlaceholder: 'retailerFeedback.userSurvey.page1.textAreaPlaceholder',
    },
    { br: <br /> }
  )

  const buttons = useMemo(
    () =>
      [
        { option: messages.option1 },
        { option: messages.option2 },
        { option: messages.option3 },
        { option: messages.option4 },
        { option: messages.option5 },
      ].map(({ option }) => ({
        text: option,
        onClick: () => {
          updateSurveyState('overallExperienceResponse', option)
        },
        selected: surveyState.overallExperienceResponse === option,
      })),
    [
      messages.option1,
      messages.option2,
      messages.option3,
      messages.option4,
      messages.option5,
      surveyState.overallExperienceResponse,
      updateSurveyState,
    ]
  )

  const onClickSubmit = () => {
    submitSurvey()
    trackEvent({
      id: 'app.retailerFeedback.survey.modal.submit',
      description: 'Tracks whenever the retailer feedback survey modal is submitted',
    })
    goToPage(RetailerFeedbackSurveyPages.Page2)
    setData({
      ...data,
      hasSubmittedSurvey: true,
    })
  }

  return (
    <>
      <ModalHeader css={{ lineHeight: '26px', padding: '26px 60px 24px' }}>
        {messages.title}
      </ModalHeader>
      <ModalBody data-testid="retailer-feedback-page-1" css={{ rowGap: '12px' }}>
        <ButtonArrayComponent buttons={buttons} />
        {isOverallExperienceOptionSelected && (
          <SurveyInputContainer>
            <TextArea
              data-testid="retailer-feedback-page-1-text-area"
              onChange={evt =>
                updateSurveyState('improveExperienceResponse', evt.currentTarget.value)
              }
              placeholder={messages.textAreaPlaceholder}
              css={{ minHeight: 125, fontSize: 15 }}
              autoFocus
            />
          </SurveyInputContainer>
        )}
      </ModalBody>
      {isOverallExperienceOptionSelected && (
        <ModalFooter>
          <Button
            data-testid="retailer-feedback-page-1-submit-btn"
            onClick={onClickSubmit}
            disabled={!surveyState.overallExperienceResponse}
          >
            {messages.submit}
          </Button>
        </ModalFooter>
      )}
    </>
  )
}

export default Page1
