import { type DashRouteType, URL_PARTNER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_WEBHOOK_CONFIGURATION = `${URL_PARTNER_SCOPED}/webhook-configuration`
const URL_WEBHOOK_CONFIGURATION_TABLE = `${URL_WEBHOOK_CONFIGURATION}/configurations`
const URL_WEBHOOK_CONFIGURATION_CREATE = `${URL_WEBHOOK_CONFIGURATION}/configurations/create`
const URL_WEBHOOK_CONFIGURATION_INFO = `${URL_WEBHOOK_CONFIGURATION}/configurations/:configurationId/info`
const URL_WEBHOOK_CONFIGURATION_EDIT = `${URL_WEBHOOK_CONFIGURATION}/configurations/:configurationId/edit`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'webhook-configuration'>
  | DashRouteType<'webhook-configurations-table'>
  | DashRouteType<'webhook-configuration-create'>
  | DashRouteType<'webhook-configuration-info'>
  | DashRouteType<'webhook-configuration-edit'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'webhook-configuration': URL_WEBHOOK_CONFIGURATION,
  'webhook-configurations-table': URL_WEBHOOK_CONFIGURATION_TABLE,
  'webhook-configuration-create': URL_WEBHOOK_CONFIGURATION_CREATE,
  'webhook-configuration-info': URL_WEBHOOK_CONFIGURATION_INFO,
  'webhook-configuration-edit': URL_WEBHOOK_CONFIGURATION_EDIT,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
