import gql from 'graphql-tag'
import { type Warehouse } from '../../../common/types'
import { toInteger } from '../../../../utils/parsing/numbers'
import { useGetRetailersForPartnerQuery } from '../../../../__codegen__/api'

export interface WarehousesData {
  partner: {
    warehouses: (Omit<Warehouse, 'id'> & { id: string })[]
  }
}

export interface WarehousesVariables {
  id?: number
}

export const useFetchWarehouses = (variables: WarehousesVariables) => {
  const { data, error, loading } = useGetRetailersForPartnerQuery({
    variables: { partnerId: variables?.id?.toString() },
    skip: !variables.id,
  })

  // Convert the id strings into integers
  const warehouses =
    data &&
    data.partner.retailers.map(({ id, ...rest }) => ({
      id: toInteger(id),
      ...rest,
    }))

  return {
    warehouses,
    error,
    loading,
  }
}
