import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { marketingAgentCalendarAccess } from '../access-control/marketingAgentCalendarAccess.configuration'

const MarketingAgentCalendarPage = lazy(
  () => import('../pages/marketing_agent/MarketingAgentCalendarPage')
)
const MarketingAgentCampaignViewPage = lazy(
  () => import('../pages/marketing_agent/MarketingAgentCampaignViewPage')
)

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const marketingAgentAdminRouter = (
  <DomainRoute route="app-admin-marketing-agent" accessControl={marketingAgentCalendarAccess}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-marketing-agent-calendar"
        component={MarketingAgentCalendarPage}
      />
      <DomainRoute
        exact
        route="app-admin-marketing-agent-campaigns-view"
        component={MarketingAgentCampaignViewPage}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
