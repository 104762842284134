import React, { type FunctionComponent } from 'react'
import { isElement } from 'react-is'
import { type SwitchProps, Switch } from 'react-router'
import { NotFoundPage } from '../not-found-page/NotFoundPage'
import { DashRoute } from '../dash-route/DashRoute'
import { useCoreContext } from '../../../utils/core/RPPCoreContext'
import { compactObject } from '../../../utils/objects/compact'
import { type DashRouteName } from '../../../utils/routing/routes'

interface Props extends SwitchProps {}

/**
 * The react-router `Switch` component requires `path` or `to`
 * to be set for it's internal implementation.
 *
 * This wrapper allows us to use our named routes with
 * the `DashRoute`/`DashRedirect` and the `Switch` functionality.
 *
 * We also semi-duplicate some access control logic here, because we
 * want `Switch` to be able to fall back to the "Not Found" page
 * if a user does not have access to a route. If `Switch` is able to
 * make a match we end up just displaying a blank screen instead.
 */
export const DashSwitch: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  ...rest
}) => {
  function renderChild(child: React.ReactNode) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { routesByName } = useCoreContext()
    if (!isElement(child)) return child

    // route is used by DashRoute
    // fromRoute and toRoute are used by DashRedirect
    const { route, fromRoute, toRoute, ...childProps } = child.props

    // If none of these are provided, we don't need to perform access control checks
    if (!route && !fromRoute && !toRoute) return child

    // Is this correct? what's the point of what was previously dash routes?
    const path = route ? routesByName[route as DashRouteName] : undefined
    const from = fromRoute ? routesByName[fromRoute as DashRouteName] : undefined
    const to = toRoute ? routesByName[toRoute as DashRouteName] : undefined

    // Add the path, from, and to props to the child route/redirect component
    const updatedChildProps = { ...childProps, ...compactObject({ path, from, to }) }
    return React.cloneElement(child, updatedChildProps)
  }

  return (
    <Switch {...rest}>
      {React.Children.map(children, renderChild)}
      <DashRoute component={NotFoundPage} />
    </Switch>
  )
}
