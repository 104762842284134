import { useCallback } from 'react'
import clientEnv from '../../../../../src/utils/global/clientEnv'
import { useCurrentAccountContext } from '../../../../utils/current-account/CurrentAccountContext'
import { useLocalForageKey } from '../../../../utils/local-storage/useLocalForageKey.hooks'

type RetailerFeedbackSurveyStorageProps = {
  hasSubmittedSurvey: boolean
  surveyPromptCount: number
  surveyLastSeenTime: number
}

export const MAX_MODAL_FREQUENCY_COUNT = 2
export const MODAL_FREQUENCY_DISPLAY_MILLISECONDS = 24 * 60 * 60 * 1000
export const SURVEY_END_DATE = new Date('2025-01-05')

const isCypressEnv = () =>
  clientEnv.PUBLIC_CLIENT_CYPRESS_TOKEN && clientEnv.PUBLIC_CLIENT_ENVIRONMENT !== 'production'

export const useRetailerFeedbackSurveyStorage = () => {
  const { data, setData, loading } =
    useLocalForageKey<RetailerFeedbackSurveyStorageProps>('retailerFeedbackSurvey')

  return { data, setData, loading }
}

export const useRetailerFeedbackSurveyAccess = () => {
  const { data, loading } = useRetailerFeedbackSurveyStorage()
  const currentAccount = useCurrentAccountContext()

  const isOptedOut = !!currentAccount?.account?.featureToggles?.find(
    feature => feature.name === 'rt_retailer_feedback_survey_ignore'
  )

  const shouldOpenModal = useCallback(() => {
    /**
     * Don't show the modal for cypress tests, or if the user has
     * been opted out via roulette toggle
     */
    if (loading || isCypressEnv() || !currentAccount || isOptedOut) {
      return false
    }

    const currentTime = Date.now()
    if (currentTime > SURVEY_END_DATE.getTime()) {
      // Only show for specified date range
      return false
    }

    // Don't prompt more than 2 times
    if (data?.surveyPromptCount != null && data?.surveyPromptCount >= MAX_MODAL_FREQUENCY_COUNT) {
      return false
    }

    const surveyLastSeenTime = data?.surveyLastSeenTime
    if (surveyLastSeenTime != null && isNaN(surveyLastSeenTime)) {
      return false
    }

    // Don't prompt if we already prompted in the last 24 hours
    if (
      surveyLastSeenTime != null &&
      currentTime - surveyLastSeenTime < MODAL_FREQUENCY_DISPLAY_MILLISECONDS
    ) {
      return false
    }

    return true
  }, [currentAccount, data?.surveyLastSeenTime, data?.surveyPromptCount, isOptedOut, loading])

  return shouldOpenModal
}

export const useOnRetailerFeedbackSurveyClose = () => {
  const { data, setData } = useRetailerFeedbackSurveyStorage()

  const onClose = useCallback(() => {
    let newPromptCount = (data?.surveyPromptCount ?? 0) + 1

    // If a user submitted the survey,
    // we'll set the seen count to the max to prevent the survey showing again
    if (data?.hasSubmittedSurvey) {
      newPromptCount = MAX_MODAL_FREQUENCY_COUNT
    }
    const newLastSeenTime = new Date().getTime()

    setData({
      ...data,
      surveyPromptCount: newPromptCount,
      surveyLastSeenTime: newLastSeenTime,
    })
  }, [data, setData])

  return onClose
}
