import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const storefrontOnboardingAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StorefrontOnboarding],
}

export const storefrontOnboardingSiteSetupAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.OnboardingSiteSetup],
}
