import { type FeatureToggle, type Flips } from '../../contexts/featureToggles/FeatureToggleContext'
import { type Permission, type FeatureEntitlements, type PartnerType } from '../../common/types'
import { type WarehouseLocationFeature } from '../../common/warehouseLocations'
import { type ICPlatformVersion } from '../../../utils/ic-platform/versioning'

type AccessControlRequiredValues<T> = T | AccessControlOneOf<T> | Array<T | AccessControlOneOf<T>>

export type AccessControlFeatureToggles<T = FeatureToggle> = AccessControlRequiredValues<T>
export type AccessControlPermissions<T = Permission> = AccessControlRequiredValues<T>
export type AccessControlFeatureEntitlements<T = string> = AccessControlRequiredValues<T>
export type AccessControlWarehouseLocationFeatures<T = WarehouseLocationFeature> =
  AccessControlRequiredValues<T>
export type AccessControlPartnerTypes<T = PartnerType> = AccessControlRequiredValues<T>
export interface AccessControlConfig<
  TFeatureToggle = FeatureToggle,
  TPermission = Permission,
  TWarehouseLocationFeature = WarehouseLocationFeature,
  TFeatureEntitlement = FeatureEntitlements,
  TPartnerType = PartnerType
> {
  featureToggles?: AccessControlFeatureToggles<TFeatureToggle>
  notFeatureToggles?: AccessControlFeatureToggles<TFeatureToggle>
  warehouseFeatureToggles?: AccessControlFeatureToggles<TFeatureToggle>
  notWarehouseFeatureToggles?: AccessControlFeatureToggles<TFeatureToggle>
  permissions?: AccessControlPermissions<TPermission>
  warehouseLocationFeatures?: AccessControlWarehouseLocationFeatures<TWarehouseLocationFeature>
  partnerTypes?: AccessControlPartnerTypes<TPartnerType>
  minICPlatformVersion?: ICPlatformVersion
  maxICPlatformVersion?: ICPlatformVersion
  featureEntitlementIds?: AccessControlFeatureEntitlements<TFeatureEntitlement>[]
}

export class AccessControlOneOf<T> {
  values: T[]

  constructor(values: T[]) {
    this.values = values
  }

  toJSON() {
    return { oneOf: this.values }
  }
}

export function hasValues<T>(values: T[], requiredValues: AccessControlRequiredValues<T>): boolean {
  if (requiredValues instanceof AccessControlOneOf) {
    return requiredValues.values.reduce<boolean>(
      (result, value) => result || hasValues(values, value),
      false
    )
  }

  if (Array.isArray(requiredValues)) {
    return requiredValues.reduce<boolean>(
      (result, value) => hasValues(values, value) && result,
      true
    )
  }

  return values?.includes(requiredValues) || false
}

export function featureTogglesToFlipsMapping(featureToggles?: FeatureToggle[]) {
  if (!featureToggles) {
    return {}
  }

  return featureToggles.reduce(
    (flips: Flips, featureToggle) => ({ ...flips, [featureToggle]: true }),
    {}
  )
}

export function hasFeatureToggle(
  featureToggles: Flips,
  requiredFlips: AccessControlFeatureToggles
): boolean {
  const flips = (Object.keys(featureToggles) as FeatureToggle[]).filter(n => featureToggles[n])

  return hasValues(flips, requiredFlips)
}

export function matchesPartnerType(
  partnerType: PartnerType,
  requiredPartnerType: AccessControlPartnerTypes
): boolean {
  return hasValues([partnerType], requiredPartnerType)
}

export function hasPermission(
  permissions: Permission[],
  requiredPermissions: AccessControlPermissions
): boolean {
  return hasValues(permissions, requiredPermissions)
}

export function hasWarehouseLocationFeature(
  features: WarehouseLocationFeature[],
  requiredFeatures: AccessControlWarehouseLocationFeatures
): boolean {
  return hasValues(features, requiredFeatures)
}
