import {
  transformFilterParamsToApiFilterSelectionsFactory,
  transformFilterParamsForApiConfig,
} from '../utils/insightsPortalSchemas.utils'
import { createSchemaConfig } from '../helpers/schema.helpers'
import { InsightsFormatterType } from '../types/schema.types'
import { Metrics, Dimensions } from './insightsPortalAdsSponosoredProduct.types'

export { Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  metricsConfig: {
    [Metrics.CLICKS]: {
      label: 'insightsPortalDomain.adsDashboard.metrics.CLICKS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.IMPRESSIONS]: {
      label: 'insightsPortalDomain.adsDashboard.metrics.IMPRESSIONS',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.AVG_CPC]: {
      label: 'insightsPortalDomain.adsDashboard.metrics.AVG_CPC',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.CTR]: {
      label: 'insightsPortalDomain.adsDashboard.metrics.CTR',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.REV_SHARE]: {
      label: 'insightsPortalDomain.adsDashboard.metrics.REV_SHARE',
      formatter: InsightsFormatterType.Currency,
    },
  },
  dimensions: Dimensions,
  dimensionsConfig: {
    [Dimensions.RETAILER]: {
      label: 'insightsPortalDomain.filters.demandSource.rsd',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.banners.label',
    },
    [Dimensions.RETAILER_LOCATION]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.RETAILER_LOCATION_NAME]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.DEMAND_SOURCE]: {
      label: 'insightsPortalDomain.filters.demandSource.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.filters.state.label',
    },
  },

  // TODO: deprecate
  groupBys: Dimensions,
  groupBysConfig: {
    [Dimensions.RETAILER]: {
      label: 'insightsPortalDomain.filters.demandSource.rsd',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.banners.label',
    },
    [Dimensions.RETAILER_LOCATION]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.RETAILER_LOCATION_NAME]: {
      label: 'insightsPortalDomain.filters.storeLocation.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.filters.state.label',
    },
    [Dimensions.DEMAND_SOURCE]: {
      label: 'insightsPortalDomain.filters.demandSource.label',
    },
  },
  filterConfigs: transformFilterParamsForApiConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    transformFilterParamsForApiConfig
  ),
})
