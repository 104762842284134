// @ts-nocheck legacy file transformed to TS
/* eslint-disable no-console */
import zipObject from 'lodash/zipObject'

const MOBILE_USER_AGENTS = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

function isMobile() {
  return MOBILE_USER_AGENTS.test(navigator.userAgent)
}

function bindSegment(method) {
  return (...args) => {
    const [event] = args
    let [, props] = args
    const debugAnalytics =
      process.env.NODE_ENV === 'development' || !!global.location.search.match('analytics_debug=1')

    props = { ...props }

    // Base props
    Object.assign(props, {
      platform: isMobile() ? 'mobile_web' : 'web',
    })

    if (debugAnalytics) {
      console.log('%c🌽 %s: %O %O', 'color: #43B02A; font-style:italic', method, event, props || '')
    }

    if (process.env.NODE_ENV !== 'production') return
    if (!global.analytics) return console.warn('Segment unavailable')

    global.analytics[method].call(global.analytics, event, props)
  }
}

const analyticsAPIs = ['page', 'identify', 'group', 'track']

export default zipObject(analyticsAPIs, analyticsAPIs.map(bindSegment))
