import React, { useMemo } from 'react'
import { Accordion, NewSelect, Text, spacing } from '@retailer-platform/shared-components'
import { useDashMessage, useDashMessages } from '../../../../intl/intl.hooks'
import { ICPlatformVersion } from '../../../../utils/ic-platform/versioning'
import { useAdminControls } from '../../../../utils/contexts/admin-controls/AdminControlsContext'
import { useICPlatformContext } from '../../../../utils/ic-platform/icPlatform.context'

const sfxVersionOptions = Object.entries(ICPlatformVersion)
  // TS enums also keep a value => key mapping, but we only want key => value, so we get rid of number-keyed entries
  .filter(([key]) => Number.isNaN(Number(key)))
  .map(([key, value]) => ({ label: key, value: value as ICPlatformVersion }))

export const ForceCurrentICPlatformVersion = () => {
  const { forcedCurrentICPlatformVersion, setTemporaryAdminControl } = useAdminControls()
  const { currentVersion } = useICPlatformContext()
  const defaultI18n = useDashMessage(
    'app.admin.controlPanel.setting.forceCurrentICPlatformVersion.default'
  )

  const i18n = useDashMessages(
    {
      forceCurrentICPlatformVersionTitle:
        'app.admin.controlPanel.setting.forceCurrentICPlatformVersion.title',
      forceCurrentICPlatformVersionDesc:
        'app.admin.controlPanel.setting.forceCurrentICPlatformVersion.description',
      note1: 'app.admin.controlPanel.setting.forceCurrentICPlatformVersion.note1',
      note2: 'app.admin.controlPanel.setting.forceCurrentICPlatformVersion.note2',
      note3: 'app.admin.controlPanel.setting.forceCurrentICPlatformVersion.note3',
      current: 'app.admin.controlPanel.setting.forceCurrentICPlatformVersion.current',
    },
    { version: ICPlatformVersion[forcedCurrentICPlatformVersion] || defaultI18n }
  )

  /**
   * We only want to show the options that are greater than or equal to the current version, as the rest
   * can be considered not relevant at this point.
   */
  const filteredICPlatformVersionOptions = useMemo(() => {
    if (!currentVersion) return sfxVersionOptions

    const [head, ...tail] = sfxVersionOptions.filter(
      versionObject => versionObject.value >= currentVersion
    )

    tail.unshift({
      ...head,
      label: `${head.label} (${i18n.current})`,
    })

    return tail
  }, [currentVersion, i18n])

  return (
    <Accordion showContentBackground={true} title={i18n.forceCurrentICPlatformVersionTitle}>
      <div css={{ display: 'flex', flexDirection: 'column', gap: spacing.X20 }}>
        <Text size="small">{i18n.forceCurrentICPlatformVersionDesc}</Text>

        <div
          css={{
            '&>*::before': { content: '"- "' },
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.X8,
          }}
        >
          <Text size="small" weight="bold">
            {i18n.note1}
          </Text>
          <Text size="small" weight="bold">
            {i18n.note2}
          </Text>
          <Text size="small" weight="bold">
            {i18n.note3}
          </Text>
        </div>

        <div css={{ padding: 10 }}>
          <NewSelect
            compact
            isClearable
            value={forcedCurrentICPlatformVersion}
            options={filteredICPlatformVersionOptions}
            styles={{ menuPortal: baseStyles => ({ ...baseStyles, zIndex: 101 }) }}
            menuPortalTarget={document.body}
            onChange={value => setTemporaryAdminControl('forcedCurrentICPlatformVersion', value)}
          />
        </div>
      </div>
    </Accordion>
  )
}
