import { createContext, useContext } from 'react'
import { type FetchCurrentEnterpriseAccountQuery } from '../../__codegen__/api'

export type CurrentAccountContextValue = {
  account: FetchCurrentEnterpriseAccountQuery['currentEnterpriseAccount']
}

export const CurrentAccountContext = createContext<CurrentAccountContextValue>(null)

export const useCurrentAccountContext = () => useContext(CurrentAccountContext)
