import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { approvalManagementAdminRouter } from './routing/approvalsManagementLibraryAdminRouter'
import { accountManagementAccessControl } from './access-control/viewApprovalsManagement'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'approvals-management-library',
  routes,
  translations: { EN_US },
  routers: {
    admin: [approvalManagementAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.ApprovalsManagement,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-approvals-management-library-approval-requests-search',
          labelId: 'approvalsManagementLibraryDomain.listPage.title',
          accessControl: accountManagementAccessControl,
        },
      ],
    },
  ],
  totem: {
    entity: 'approvals-management-library-dashboard-tool',
    routeOverrides: undefined,
    hasDevTooling: false,
  },
}

export default definition
