import { type DashRouteType, URL_PARTNER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_ADMIN_FULFILLMENT_TESTING_CENTER = `${URL_PARTNER_SCOPED}/fulfillment-testing-center`
const URL_ADMIN_FULFILLMENT_TESTING_CENTER_ORDERS = `${URL_ADMIN_FULFILLMENT_TESTING_CENTER}/orders`
const URL_ADMIN_FULFILLMENT_TESTING_CENTER_ORDER_SIMULATOR = `${URL_ADMIN_FULFILLMENT_TESTING_CENTER}/warehouse/:warehouse_id/orders/:orderId/order-simulator`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-dev-portal-fulfillment-testing-center'>
  | DashRouteType<'ftc-orders'>
  | DashRouteType<'ftc-order-simulator'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-dev-portal-fulfillment-testing-center': URL_ADMIN_FULFILLMENT_TESTING_CENTER,
  'ftc-orders': URL_ADMIN_FULFILLMENT_TESTING_CENTER_ORDERS,
  'ftc-order-simulator': URL_ADMIN_FULFILLMENT_TESTING_CENTER_ORDER_SIMULATOR,
  // @hygen:inject route-pair
}

export enum routeConfig {
  orders = 'ftc-orders',
  orderSimulator = 'ftc-order-simulator',
}

export type RouteSetIdentifier = DashRouteSet['identifier']
export type DomainRouteName = keyof typeof routes
