import React, { type FunctionComponent, useState, Fragment } from 'react'
import styled from '@emotion/styled'
import { fontWeight, colors, fontSize } from '@retailer-platform/shared-components'
import SelectPartner from '../../../common/select-partner/SelectPartner'
import { useOnEnterHandler } from '../../../../legacy/common/utils/keyboard/keyboard.utils'
import { usePartnerContext } from '../../../../utils/contexts/partner/PartnerContext.hooks'
import { CircleLogo } from '../../../common/select-partner/CircleLogo'
import { NavIconWrapper } from './NavIconWrapper'

const Container = styled.div({
  height: 42,
  color: colors.GRAYSCALE.X0,
  fontSize: fontSize.X15,
  fontWeight: fontWeight.SEMIBOLD,
  boxSizing: 'border-box',
  width: 256,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
})

export const NavSelect: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const [modalOpen, setModal] = useState(false)
  const openModal = () => setModal(true)
  const closeModal = () => setModal(false)
  const onEnterHandler = useOnEnterHandler(openModal)
  const partnerContext = usePartnerContext()

  const modalContent = <SelectPartner handleClose={closeModal} />

  return (
    <Fragment>
      <Container role="button" onClick={openModal} onKeyPress={onEnterHandler} tabIndex={0}>
        {partnerContext.partnerName && (
          <Fragment>
            <NavIconWrapper>
              <CircleLogo color="#000000">{partnerContext.partnerName[0]}</CircleLogo>
            </NavIconWrapper>
            {partnerContext.partnerName}
          </Fragment>
        )}
      </Container>
      {modalOpen && modalContent}
    </Fragment>
  )
}
