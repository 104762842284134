import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { auditLogsRouter } from './routing/auditLogsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { auditLogsAccessControl } from './access-control/auditLogsAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'audit-logs',
  totem: {
    entity: 'audit-logs-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [auditLogsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Admin,
      attachToV2: NavEntryPoint.UserMenuUserManagement,
      subNavItems: [
        {
          type: 'item',
          labelId: 'auditLogsDomain.title',
          route: 'audit-logs-list',
          accessControl: auditLogsAccessControl,
          id: 'audit-logs-list',
          position: 2,
          positionNavV2: 2,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
