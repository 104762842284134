import { type AccessControlConfig } from '../../components/AccessControl/accessControl.utils'
import { Permission } from '../../common/types'
import AccessControl from '../../components/AccessControl/AccessControl'
import { FeatureToggle } from '../../contexts/featureToggles/FeatureToggleContext'

export const OrdersDeliveryAccessControl: AccessControlConfig = {
  permissions: Permission.OrdersDelivery,
}

export const OrdersPickupAccessControl: AccessControlConfig = {
  permissions: Permission.OrdersPickup,
}

export const OrdersScanAndPayAccessControl: AccessControlConfig = {
  warehouseFeatureToggles: FeatureToggle.ScanAndPay,
}

export const OrderDetailAccessControl: AccessControlConfig = {
  permissions: AccessControl.oneOf([Permission.OrdersDelivery, Permission.OrdersPickup]),
}

export const OrdersScanAndPayOrderRefundAccessControl: AccessControlConfig = {
  warehouseFeatureToggles: FeatureToggle.ScanAndPayOrderRefund,
}
