import { CustomError } from 'ts-custom-error'

class HttpError extends CustomError {
  status: number

  headers: Headers

  url: string

  body?: JSONObject | undefined

  constructor(response: Response, body?: JSONObject | undefined) {
    super()

    this.status = response.status
    this.headers = response.headers
    this.url = response.url
    this.body = body
  }
}

export default HttpError
