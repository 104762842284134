import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

// Access control for wholesaler development
export const retailerFundedMarketingWholesalerEarlyAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.wholesalerCampaignsEnabled],
}

export const retailerFundedMarketingWholesalerAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.wholesalerCampaignsEnabled],
  featureToggles: [FeatureToggle.InsightsCampaignAnalytics],
  permissions: [
    Permission.RetailerFundedMarketingViewAccess,
    Permission.RetailerFundedMarketingInsights,
  ],
  notFeatureToggles: [FeatureToggle.retailerFundedMarketingUserDenyList],
  minICPlatformVersion: ICPlatformVersion.r2024_12_0,
}
