import { logDebugEvent } from '../core/factories/events/eventDebugging'
import { segmentAnalytics, SEGMENT_OPTIONS } from '../core/factories/events/segmentAnalytics'
import { type FetchCurrentEnterpriseAccountQuery } from '../../__codegen__/api'

const groupByPartner = (partner: { id: string }) => {
  const identifier = partner.id.toString()

  segmentAnalytics().group(identifier, partner, SEGMENT_OPTIONS)
  logDebugEvent('group', `Grouping the user with partner: ${identifier}`, partner)
}

const groupUser = ({
  internalAccessConfiguration: { assignedPartners },
}: FetchCurrentEnterpriseAccountQuery['currentEnterpriseAccount']) => {
  if (assignedPartners.length === 1) {
    // If a user has exactly one partner, we mark them as part of the analytics group
    groupByPartner(assignedPartners[0])
  } else {
    // Otherwise, we skip grouping them.
    logDebugEvent(
      'group',
      'Skipping grouping for user - did not have one partner',
      assignedPartners
    )
  }
}

// eslint-disable-next-line import/no-default-export
export default groupUser
