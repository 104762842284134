import React, { useMemo, type FunctionComponent } from 'react'
import { useRouteMatch } from 'react-router'
import { AuthenticationDomainRedirect } from '@retailer-platform/domain-authentication'
import { DashSwitch } from '../../../legacy/dash-components/dash-switch/DashSwitch'
import { DashRoute } from '../../../legacy/dash-components/dash-route/DashRoute'
import { DashRedirect } from '../../../legacy/dash-components/dash-redirect/DashRedirect'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'
import { RouteSuspense } from '../../../legacy/dash-components/route-suspense/RouteSuspense'
import { useCoreContext } from '../../../utils/core/RPPCoreContext'

export const PublicRoutes: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { loading, authorized } = useCurrentAccountDetails()
  const coreContext = useCoreContext()
  const publicRouters = useMemo(
    () => coreContext.publicRouters.map(el => React.cloneElement(el, { key: el.props.route })),
    [coreContext]
  )

  const publicLogInRouteMatch = useRouteMatch(
    coreContext.routesByName['app-public-authentication-log-in']
  )

  if (loading) {
    return null
  }

  // If already authorized, and they are heading to the log-in page, direct them to the redirect page
  if (authorized && publicLogInRouteMatch) {
    return <DashRedirect toRoute="app-halt-redirect" />
  }

  return (
    <RouteSuspense>
      <DashSwitch>
        <DashRoute route="public">
          <DashSwitch>
            {publicRouters}

            <AuthenticationDomainRedirect toRoute="app-public-authentication-log-in" />
          </DashSwitch>
        </DashRoute>
        <DashRedirect toRoute="public" />
      </DashSwitch>
    </RouteSuspense>
  )
}
