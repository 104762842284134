const EN_US = {
  'oauthApplicationsDomain.title': 'OAuth Applications',
  // OauthApplicationsListPage
  'oauthApplicationsDomain.oauthApplicationsListPage.title': 'OAuth applications',
  // OauthApplicationsGrid
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.name': 'Name',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.status': 'Status',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.environment': 'Environment',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.clientId': 'Client ID',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.created': 'Created',
  'oauthApplicationsDomain.oauthApplicationsGrid.columns.renewBy': 'Renew by',
  // OauthApplicationDisableModal
  'oauthApplicationsDomain.disableModal.title': 'Disable OAuth application',
  'oauthApplicationsDomain.disableModal.nameLabel': 'Name',
  'oauthApplicationsDomain.disableModal.text':
    'Disabling this OAuth application will take effect immediately and it will prevent new tokens from being issued for it. The existing tokens will remain valid until they expire.',
  'oauthApplicationsDomain.disableModal.fields.name.label': 'Name',
  'oauthApplicationsDomain.disableModal.cancelButton.text': 'Cancel',
  'oauthApplicationsDomain.disableModal.disableButton.text': 'Disable',
  // OauthApplicationEnableModal
  'oauthApplicationsDomain.enableModal.title': 'Enable OAuth application',
  'oauthApplicationsDomain.enableModal.nameLabel': 'Name',
  'oauthApplicationsDomain.enableModal.text':
    'Enabling this OAuth application will allow new tokens to be issued for it.',
  'oauthApplicationsDomain.enableModal.cancelButton.text': 'Cancel',
  'oauthApplicationsDomain.enableModal.enableButton.text': 'Enable',
  // OauthApplicationCloneModal
  'oauthApplicationsDomain.cloneModal.title': 'Clone OAuth application',
  'oauthApplicationsDomain.cloneModal.renameLabel': 'Rename*',
  'oauthApplicationsDomain.cloneModal.text':
    'Rename your cloned application; it will retain the same settings as the previous application. For any changes to settings, please contact an Instacart admin.',
  'oauthApplicationsDomain.cloneModal.cancelButton.text': 'Cancel',
  'oauthApplicationsDomain.cloneModal.cloneButton.text': 'Clone',
  // OauthApplicationCloneSuccessModal
  'oauthApplicationsDomain.cloneSuccessModal.title': 'Sucessfully cloned!',
  'oauthApplicationsDomain.cloneSuccessModal.description':
    'Please copy the codes below, this is the only time client secret will be visible.',
  'oauthApplicationsDomain.cloneSuccessModal.clientIdLabel': 'Client ID',
  'oauthApplicationsDomain.cloneSuccessModal.clientSecretLabel': 'Client Secret',
  'oauthApplicationsDomain.cloneSuccessModal.closeButton.text': 'Done',
  'oauthApplicationsDomain.cloneSuccessModal.clientSecretWarningBold': 'Save this secret!',
  'oauthApplicationsDomain.cloneSuccessModal.clientSecretWarning':
    "For security reasons, you won't be able to view this secret once you close this window.",
  // OauthApplicationDeleteModal
  'oauthApplicationsDomain.deleteModal.title': 'Delete OAuth application',
  'oauthApplicationsDomain.deleteModal.nameLabel': 'Name',
  'oauthApplicationsDomain.deleteModal.warning': 'Deleting an OAuth Application cannot be undone.',
  'oauthApplicationsDomain.deleteModal.text':
    'This action will initiate a background task to delete all tokens associated with this application, along with the application itself. This process may take several hours.',
  'oauthApplicationsDomain.deleteModal.additionalInfo':
    'During the deletion if any active tokens are found, the deletion process will fail. In this case the application will not be deleted, and the delete action should be retried.',
  'oauthApplicationsDomain.deleteModal.note':
    'To prevent this failure, ensure you wait sufficient time for all tokens to expire after disabling the OAuth application.',
  'oauthApplicationsDomain.deleteModal.confirmation':
    'Are you sure you want to proceed with the deletion?',
  'oauthApplicationsDomain.deleteModal.cancelButton.text': 'Cancel',
  'oauthApplicationsDomain.deleteModal.deleteButton.text': 'Delete',
} as const

export default EN_US
