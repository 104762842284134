import styled from '@emotion/styled'
import { themer, colors } from 'ic-snacks'

interface Props {
  active: boolean
}

const MenuItem = styled.span<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.GRAY_46};
  padding: 20px 30px;
  font-size: 14px;
  border-left: ${({ active }) =>
    `4px solid${active ? ` ${themer.get('colors', 'primaryForeground')}` : ' transparent'}`};
  cursor: pointer;
`

export default MenuItem
