import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { ViewType } from '@retailer-platform/dashboard/gin-and-tonic'
import { DomainRoute } from '../utils/domain/routingComponents'
import { retailerFundedMarketingAccessControl } from '../access-control/retailerFundedMarketingAccess.configuration'
import { retailerFundedMarketingHomeAccessControl } from '../access-control/retailerFundedMarketingHomeAccess.configuration'
import { retailerFundedMarketingTargetingAccessControl } from '../access-control/retailerFundedMarketingTargetingAccess.configuration'

const CampaignTemplatesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CampaignTemplatesPage" */
      '../pages/campaign-templates/CampaignTemplatesPage'
    )
)
const RetailerCampaignListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCampaignListPage" */
      '../pages/retailer-campaigns/list/RetailerCampaignListPage'
    )
)
const SegmentationListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SegmentationListPage" */
      '../pages/segmentation-list/SegmentationListPage'
    )
)
const SegmentationViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SegmentationViewPage" */
      '../pages/segmentation-view/SegmentationViewPage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const retailerFundedMarketingRouter = (
  <DomainRoute route="retailer-funded-marketing">
    <DashSwitch>
      <DomainRoute
        exact
        route="retailer-funded-marketing-site-scoped-list"
        component={RetailerCampaignListPage}
        accessControl={retailerFundedMarketingHomeAccessControl}
        scopePicker={{
          titles: ['retailerFundedMarketingDomain.listCampaign.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
        }}
      />
      <DomainRoute
        exact
        route="retailer-funded-marketing-site-scoped-campaign-templates"
        component={CampaignTemplatesPage}
        accessControl={retailerFundedMarketingAccessControl}
        scopePicker={{
          titles: ['retailerFundedMarketingDomain.create.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
        }}
      />
      <DomainRoute
        exact
        route="retailer-funded-marketing-segmentation-list"
        component={SegmentationListPage}
        accessControl={retailerFundedMarketingTargetingAccessControl}
        scopePicker={{
          titles: ['retailerFundedMarketingDomain.segmentation.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
        }}
      />
      <DomainRoute
        exact
        route="retailer-funded-marketing-segmentation-view"
        component={SegmentationViewPage}
        accessControl={retailerFundedMarketingTargetingAccessControl}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
