import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_STOREFRONT_ONBOARDING = `${URL_RETAILER_SCOPED}/storefront-onboarding`
const URL_SITE_SCOPED_ONBOARDING = `${URL_STOREFRONT_ONBOARDING}${URL_SITE_SCOPED}`
const URL_STOREFRONT_ONBOARDING_IOS = `${URL_SITE_SCOPED_ONBOARDING}/ios`
const URL_STOREFRONT_ONBOARDING_ANDROID = `${URL_SITE_SCOPED_ONBOARDING}/android`
const URL_STOREFRONT_ONBOARDING_WEB = `${URL_STOREFRONT_ONBOARDING}/web`
const URL_STOREFRONT_ONBOARDING_WEB_STATUS = `${URL_SITE_SCOPED_ONBOARDING}/web`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'storefront-onboarding'>
  | DashRouteType<'storefront-onboarding-web'>
  | DashRouteType<'storefront-onboarding-site-scoped'>
  | DashRouteType<'storefront-onboarding-ios'>
  | DashRouteType<'storefront-onboarding-android'>
  | DashRouteType<'storefront-onboarding-web-status'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'storefront-onboarding': URL_STOREFRONT_ONBOARDING,
  'storefront-onboarding-web': URL_STOREFRONT_ONBOARDING_WEB,
  'storefront-onboarding-site-scoped': URL_SITE_SCOPED_ONBOARDING,
  'storefront-onboarding-ios': URL_STOREFRONT_ONBOARDING_IOS,
  'storefront-onboarding-android': URL_STOREFRONT_ONBOARDING_ANDROID,
  'storefront-onboarding-web-status': URL_STOREFRONT_ONBOARDING_WEB_STATUS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
