import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { partnerOnboardingPublicRouter } from './routing/partnerOnboardingPublicRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import { EN_US } from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'partner-onboarding',
  totem: {
    entity: 'partner-onboarding-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    public: [partnerOnboardingPublicRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [],
}

export default definition
