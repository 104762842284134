import React from 'react'
import styled from '@emotion/styled'
import { ChevronDownIcon } from '@instacart/ids-core'
import { useIntl } from 'react-intl'
import { NavItemStyle, TextContainer } from '../NavItem'

const Container = styled.div({ cursor: 'pointer', userSelect: 'none' })

type Props = {
  title: string
  index: number
  currentlyOpen: number
  setCurrentlyOpen: (index: number) => void
  onClose?: () => void
}

export const CollapsibleOption: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
  index,
  currentlyOpen,
  setCurrentlyOpen,
}) => {
  const showChildren = currentlyOpen == index
  const intl = useIntl()

  return (
    <Container>
      <div
        css={{ position: 'relative', alignItems: 'center', width: '100%' }}
        onClick={() => {
          setCurrentlyOpen(showChildren ? -1 : index)
        }}
      >
        <NavItemStyle padding="8px 24px">
          <TextContainer>{intl.formatMessage({ id: title })}</TextContainer>
        </NavItemStyle>
        <div css={{ position: 'absolute', top: '12px', right: '24px' }}>
          <ChevronDownIcon
            size={16}
            css={{
              fill: '#0A5546',
              transition: 'transform 0.2s ease',
              transform: showChildren ? 'rotate(180deg)' : '',
            }}
          />
        </div>
      </div>
      {showChildren && children}
    </Container>
  )
}
