export enum Dimensions {
  ID = 'ID',
  DISPLAY_NAME = 'RETAILER_PRODUCT',
  OOS_AT_DATETIME = 'OOS_AT_DATETIME',
  LATEST_SIGNAL_AT_DATETIME = 'LATEST_SIGNAL_AT_DATETIME',
  SHOPPER_PHOTOS = 'SHOPPER_PHOTOS',
}

export enum GroupBys {
  ID = 'ID',
  DISPLAY_NAME = 'RETAILER_PRODUCT',
  OOS_AT_DATETIME = 'OOS_AT_DATETIME',
  LATEST_SIGNAL_AT_DATETIME = 'LATEST_SIGNAL_AT_DATETIME',
  SHOPPER_PHOTOS = 'SHOPPER_PHOTOS',
}
