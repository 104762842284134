import { useCallback, useMemo } from 'react'
import { type StoreGroupsFormikValues } from '../../../utils/form.types'
import { type StoreLocationDetails } from './locations.hooks'
import { type OnSelectedLocationsChange } from './locationSelector.types'

interface SelectorActionsProps {
  selectedLocationIds: StoreGroupsFormikValues['selectedLocationIds']
  onChange: OnSelectedLocationsChange
  locations: StoreLocationDetails[]
}

export const useSelectorActions = ({
  onChange,
  selectedLocationIds,
  locations,
}: SelectorActionsProps) => {
  const allLocationIdsSet = useMemo(() => new Set(locations.map(({ id }) => id)), [locations])
  const selectedLocationIdsSet = useMemo(() => new Set(selectedLocationIds), [selectedLocationIds])

  const isLocationSelected = useCallback(
    (id: string) => selectedLocationIdsSet.has(id),
    [selectedLocationIdsSet]
  )

  const handleToggleLocation = useCallback(
    (id: string) => {
      const nextIds = new Set(selectedLocationIdsSet.values())
      const isSelected = isLocationSelected(id)

      if (isSelected) {
        nextIds.delete(id)
      } else {
        nextIds.add(id)
      }

      onChange(nextIds)
    },
    [isLocationSelected, onChange, selectedLocationIdsSet]
  )

  // Note that the "All Location Ids" here is referring to all of the visible [filtered] locations
  // If someone hits the add all button, we want to combine the visible locations with any existing
  // selected locations [instead of overwriting any that the might currently have set]
  const handleAddAllLocations = useCallback(() => {
    const nextIds = new Set(selectedLocationIdsSet.values())
    allLocationIdsSet.forEach(id => nextIds.add(id))
    onChange(nextIds)
  }, [allLocationIdsSet, onChange, selectedLocationIdsSet])

  const addAllNumStores = useMemo(() => allLocationIdsSet.size, [allLocationIdsSet])
  const addAllDisabled = useMemo(
    () => addAllNumStores === selectedLocationIdsSet.size,
    [addAllNumStores, selectedLocationIdsSet]
  )

  return {
    isLocationSelected,
    handleToggleLocation,
    handleAddAllLocations,
    addAllNumStores,
    addAllDisabled,
  }
}
