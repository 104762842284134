import { type DashRouteType, URL_APP_ADMIN } from '@retailer-platform/dashboard/routing'

const URL_ADMIN_RECIPES_ADMIN = `${URL_APP_ADMIN}/recipes-admin`

// Recipe Library Routes
const URL_ADMIN_RECIPES_ADMIN_LIBRARY = `${URL_ADMIN_RECIPES_ADMIN}/library`
const URL_ADMIN_RECIPES_ADMIN_LIBRARY_CSV_INGESTION = `${URL_ADMIN_RECIPES_ADMIN_LIBRARY}/csv-ingestion`
const URL_ADMIN_RECIPES_ADMIN_LIBRARY_MANAGER = `${URL_ADMIN_RECIPES_ADMIN_LIBRARY}/manager`
const URL_ADMIN_RECIPES_ADMIN_LIBRARY_RECIPE_MANAGER = `${URL_ADMIN_RECIPES_ADMIN_LIBRARY}/manager/:recipeId`

export type DashRouteSet =
  | DashRouteType<'app-admin-recipes-admin'>
  | DashRouteType<'app-admin-recipes-admin-library-csv-ingestion'>
  | DashRouteType<'app-admin-recipes-admin-library-manager'>
  | DashRouteType<'app-admin-recipes-admin-library-manager-recipe'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-recipes-admin': URL_ADMIN_RECIPES_ADMIN,
  'app-admin-recipes-admin-library-csv-ingestion': URL_ADMIN_RECIPES_ADMIN_LIBRARY_CSV_INGESTION,
  'app-admin-recipes-admin-library-manager': URL_ADMIN_RECIPES_ADMIN_LIBRARY_MANAGER,
  'app-admin-recipes-admin-library-manager-recipe': URL_ADMIN_RECIPES_ADMIN_LIBRARY_RECIPE_MANAGER,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
