import styled from '@emotion/styled'
import {
  Badge,
  borderRadius,
  colors,
  fontWeight,
  spacing,
} from '@retailer-platform/shared-components'
import { fontSize } from '@retailer-platform/dashboard/gin-and-tonic'

export const Divider = styled.h2({
  width: '100%',
  textAlign: 'center',
  borderBottom: `1px dotted ${colors.GRAYSCALE.X30}`,
  lineHeight: '0.1em',
  margin: '10px 0 20px',
})

export const DividerText = styled.span({
  background: '#fff',
  padding: '0 10px',
  color: colors.GRAYSCALE.X50,
})

export const Version = styled.span({
  fontSize: fontSize.X23,
  fontWeight: fontWeight.REGULAR,
})

export const Ul = styled.ul({
  listStyle: 'initial',
  fontWeight: 'bolder',
})

export const Li = styled.li({
  margin: spacing.X8,
  marginLeft: spacing.X12,
  lineHeight: spacing.X20,
})

export const StateBadge = styled(Badge)<{ state?: string }>(({ state }) => {
  const colorStates = {
    approved: {
      backgroundColor: colors.SUCCESS.LIGHT,
      color: colors.SUCCESS.REGULAR,
    },
    warning: {
      backgroundColor: colors.LOYALTY.LIGHT,
      color: colors.LOYALTY.REGULAR,
    },
    rejected: {
      backgroundColor: colors.DETRIMENTAL.LIGHT,
      color: colors.DETRIMENTAL.REGULAR,
    },
  }

  return {
    ...(colorStates[state] ?? colorStates['warning']),
    padding: spacing.X12,
    fontSize: fontSize.X15,
    fontWeight: fontWeight.SEMIBOLD,
    whiteSpace: 'nowrap',
  }
})

export const PropertyValue = styled.div({
  height: '100%',
  padding: spacing.X12,
  fontSize: fontSize.X15,
  color: colors.GRAYSCALE.X70,
  backgroundColor: colors.GRAYSCALE.X10,
  borderRadius: borderRadius.X8,
  outline: 'none',
})
