// @ts-nocheck legacy file transformed to TS
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { DropdownMenu, Icon, colors } from 'ic-snacks'
import ButtonBase from './ButtonBase'

const MenuButton = styled(ButtonBase)`
  padding: 6px;
  color: ${colors.GRAY_20};

  &:hover {
    color: ${colors.GREEN_500};
  }
`

class MoreMenu extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    onSelect: PropTypes.func.isRequired,
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    testid: PropTypes.string,
  }

  static defaultProps = {
    margin: '0 -6px 0 0',
    testid: undefined,
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()
  }

  render() {
    return (
      <div
        style={{ position: 'relative', zIndex: 1, margin: this.props.margin }}
        data-testid={this.props.testid}
      >
        <DropdownMenu
          onSelect={this.props.onSelect}
          menuContainerStyle={{
            position: 'absolute',
            top: '-10px',
            left: '30px',
            width: '100px',
            padding: '0',
          }}
          triggerElement={
            <MenuButton>
              <Icon
                name="moreFilled"
                style={{
                  fontSize: '12px',
                  display: 'inline-block',
                  transform: 'rotate(90deg)',
                  cursor: 'pointer',
                }}
              />
            </MenuButton>
          }
        >
          {this.props.children}
        </DropdownMenu>
      </div>
    )
  }
}

export default MoreMenu
