import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'storeLocation',
    'WAREHOUSE_LOCATION',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'storeLocationCode',
    'WAREHOUSE_LOCATION_CODE',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'storeGroup',
    'WAREHOUSE_LOCATION_GROUP',
    optionsArrayToInFilterSelection('stringValue')
  ),

  // The API doesn't return product categories yet, but that we are planning to do that
  createFilterParamConfig(
    'L1Category',
    'L1_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L2Category',
    'L2_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L3Category',
    'L3_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L4Category',
    'L4_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L5Category',
    'L5_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L6Category',
    'L6_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),
]

export const inventoryIntelligenceOosSignalsSchemaFilterParamsConfig =
  transformFilterParamsForApiConfig

export const transformFilterSelectionsToInventoryIntelligenceOosSignalSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)

export const transformInventoryIntelligenceOosSignalsApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  WAREHOUSE_LOCATION: 'stringValue',
  WAREHOUSE_LOCATION_CODE: 'stringValue',
  WAREHOUSE_LOCATION_GROUP: 'stringValue',
  L1_CATEGORY: 'stringValue',
  L2_CATEGORY: 'stringValue',
  L3_CATEGORY: 'stringValue',
  L4_CATEGORY: 'stringValue',
  L5_CATEGORY: 'stringValue',
  L6_CATEGORY: 'stringValue',
} as const
