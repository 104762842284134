import { NewSelect, spacing } from '@retailer-platform/shared-components'
import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { type Moment } from 'moment'
import {
  ReportsNormalizedReportsListDateRangePresetSelect,
  DateRangePreset,
} from '../date-range-preset-select/ReportsNormalizedReportsListDateRangePresetSelect'
import { ReportsNormalizedReportsListCustomDateRange } from '../custom-date-range/ReportsNormalizedReportsListCustomDateRange'
import { type ReportTypeEnum } from '../../../../api'
import { ReportsNormalizedReportsListReportTypeSelect } from '../report-type-select/ReportsNormalizedReportsListReportTypeSelect'
import { ReportsNormalizedReportsListMergeButtonAndModal } from '../merge-button-and-modal/ReportsNormalizedReportsListMergeButtonAndModal'
import { useDomainMessage } from '../../../../utils/domain/intl'
import { type ReportTypeConfig } from '../../utils/ReportsNormalizedReportsList.utils'

interface DateRange {
  startDate: Moment
  endDate: Moment
}

interface ReportsNormalizedReportsListFiltersProps {
  reportCount: number
  partnerId: string
  retailerId: string
  dateRangePreset: DateRangePreset
  setDateRangePreset: (r: DateRangePreset) => void
  dateRange: DateRange
  setDateRange: (d: DateRange) => void
  reportType: ReportTypeEnum
  setReportType: (t: ReportTypeEnum) => void
  warehouseOptions?: Array<{ label: string; value: string }>
  setRetailerIds?: (r: string[]) => void
  retailerIds?: string[]
  reportTypesConfig: ReportTypeConfig
}

const StyledContainer = styled.div({
  display: 'flex',
  padding: spacing.X24,
  alignItems: 'center',
  height: 100,
  justifyContent: 'space-between',

  '& > .filter-container': {
    display: 'flex',

    '& > .filter-item': {
      width: 200,
      marginRight: spacing.X24,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
})

export const ReportsNormalizedReportsListFilters: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListFiltersProps>
> = ({
  reportCount,
  partnerId,
  retailerId,
  dateRangePreset,
  setDateRangePreset,
  dateRange,
  setDateRange,
  reportType,
  setReportType,
  warehouseOptions,
  setRetailerIds,
  retailerIds,
  reportTypesConfig,
}) => {
  const bannerLabel = useDomainMessage('reportsDomain.instacartManagedReports.filters.banner.label')

  return (
    <StyledContainer>
      <div className="filter-container">
        {warehouseOptions && (
          <div css={{ marginRight: spacing.X24, minWidth: 300 }}>
            <NewSelect
              options={warehouseOptions}
              compact
              isMulti
              onChange={val => setRetailerIds(val)}
              value={retailerIds}
              placeholder={bannerLabel}
            />
          </div>
        )}
        <div className="filter-item">
          <ReportsNormalizedReportsListReportTypeSelect
            reportTypesConfig={reportTypesConfig}
            selectedReportType={reportType}
            onChange={setReportType}
          />
        </div>

        <div className="filter-item">
          <ReportsNormalizedReportsListDateRangePresetSelect
            selectedDateRange={dateRangePreset}
            onChange={setDateRangePreset}
          />
        </div>

        {dateRangePreset === DateRangePreset.Custom && (
          <div className="filter-item">
            <ReportsNormalizedReportsListCustomDateRange
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDatesChange={setDateRange}
            />
          </div>
        )}
      </div>

      <ReportsNormalizedReportsListMergeButtonAndModal
        reportCount={reportCount}
        partnerId={partnerId}
        retailerId={retailerId}
        retailerIds={retailerIds}
        reportType={reportType}
        dateRangePreset={dateRangePreset}
        dateRange={dateRange}
      />
    </StyledContainer>
  )
}
