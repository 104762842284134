import { useCallback } from 'react'
import { type RetailerRouteComponentProps } from '../../../components/RetailerRoute'
import { URL_DASHBOARD, URL_BASE } from '../../../../utils/routing/routes'
import { url } from '../../../../utils/parsing/url'

interface Props {
  replace: RetailerRouteComponentProps['history']['replace']
  partnerId?: string
  retailerId?: string
}

// If we have the warehouse_id or partner_id params, we can use those
// to just redirect to the dashboard. Otherwise, we redirect to the URL_BASE
// [which redirects to the scope selection page if a user is logged in]
export const useHandleNotFoundClick = ({ replace, partnerId, retailerId }: Props) =>
  useCallback(() => {
    if (!partnerId || !retailerId) {
      return replace(URL_BASE)
    }

    const target = url(URL_DASHBOARD, { partner_id: partnerId, warehouse_id: retailerId })
    return replace(target)
  }, [partnerId, replace, retailerId])
