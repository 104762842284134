import type React from 'react'
import { DashRedirect } from '../../../../legacy/dash-components/dash-redirect/DashRedirect'
import { DashRoute } from '../../../../legacy/dash-components/dash-route/DashRoute'

type DomainRouteBaseProps = React.ComponentPropsWithoutRef<typeof DashRoute>
type WithDomainRouteProps<RouteNameT, TAccessControlConfig> = Omit<
  DomainRouteBaseProps,
  'route' | 'accessControl'
> & {
  route: RouteNameT
  accessControl?: TAccessControlConfig
}
export const domainRouteComponentFactory = <
  RouteNameT extends string,
  TAccessControlConfig
>(): React.FunctionComponent<
  React.PropsWithChildren<WithDomainRouteProps<RouteNameT, TAccessControlConfig>>
> => DashRoute as any

type DomainRedirectBaseProps = React.ComponentPropsWithoutRef<typeof DashRedirect>
type WithDomainRedirectProps<RouteNameT, TAccessControlConfig> = Omit<
  DomainRedirectBaseProps,
  'toRoute' | 'fromRoute' | 'accessControl'
> & {
  toRoute: RouteNameT
  fromRoute?: RouteNameT
  accessControl?: TAccessControlConfig
}

export const domainRedirectComponentFactory = <
  RouteNameT extends string,
  TAccessControlConfig
>(): React.FunctionComponent<
  React.PropsWithChildren<WithDomainRedirectProps<RouteNameT, TAccessControlConfig>>
> => DashRedirect as any
