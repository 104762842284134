import {
  type useGoToPathFn,
  baseUseGoToPath,
  type useCreateHrefFn,
  baseCreateHref,
} from './routing'
import {
  type useScopedPathnameFn,
  baseUseScopedPathname,
  baseUseGetScopedPathname,
  type useGetScopedPathnameFn,
} from './url'

export const useGoToPathFactory = <T extends string>(): useGoToPathFn<T> => baseUseGoToPath
export const useCreateHrefFactory = <T extends string>(): useCreateHrefFn<T> => baseCreateHref
export const useScopedPathnameFactory = <T extends string>(): useScopedPathnameFn<T> =>
  baseUseScopedPathname
export const useGetScopedPathnameFactory = <T extends string>(): useGetScopedPathnameFn<T> =>
  baseUseGetScopedPathname
