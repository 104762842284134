import React, { type FunctionComponent, useState, useCallback, Fragment } from 'react'
import { usePopper } from 'react-popper'
import styled, { type CSSObject } from '@emotion/styled'
import { type TestID } from '../../utils/testing/types'
import { borderRadius, colors, fontSize, fontWeight, spacing } from '../../foundation'
import { Portal } from '../portal/Portal'

export interface Props extends TestID {
  target: React.ReactNode
  children?: React.ReactNode
  placement?: 'top' | 'left' | 'right' | 'bottom'
  hideArrow?: boolean
  containerStyles?: CSSObject
  disable?: boolean
}

const DEFAULT_PLACEMENT = 'top'

const StyledPopper = styled.div((props: { overrideCss?: CSSObject }) => ({
  backgroundColor: colors.SECONDARY.DARK,
  color: colors.SECONDARY.LIGHT,
  borderRadius: borderRadius.X4,
  padding: spacing.X8,
  fontSize: fontSize.X12,
  fontWeight: fontWeight.SEMIBOLD,
  maxWidth: 200,
  lineHeight: '1.5em',
  zIndex: 301,
  ...props.overrideCss,
}))

const StyledArrow = styled.div(
  {
    width: 8,
    height: 8,

    ':before': {
      width: 8,
      height: 8,
      content: '" "',
      display: 'block',
      backgroundColor: colors.SECONDARY.DARK,
      transform: 'rotate(45deg)',
    },
  },

  (props: { placement: Props['placement'] }) => {
    // set the position of the arrow relative to the tooltip based on tooltip placement
    switch (props.placement) {
      case 'top':
        return { bottom: -4 }
      case 'left':
        return { right: -4 }
      case 'right':
        return { left: -4 }
      case 'bottom':
        return { top: -4 }
    }
  }
)

export const Tooltip: FunctionComponent<React.PropsWithChildren<Props>> = ({
  target,
  children,
  placement = DEFAULT_PLACEMENT,
  hideArrow,
  containerStyles,
  disable,
}) => {
  const [visible, setVisible] = useState(false)
  const showTooltip = useCallback(() => setVisible(true), [setVisible])
  const hideTooltip = useCallback(() => setVisible(false), [setVisible])

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'offset', options: { offset: [0, 4] } },
      { name: 'arrow', options: { element: arrowElement, padding: 4 } },
    ],
    placement,
    strategy: 'fixed',
  })

  return (
    <Fragment>
      <div ref={setReferenceElement} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {target}
      </div>

      {visible && !disable && (
        <Portal>
          <StyledPopper
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            overrideCss={containerStyles}
          >
            {children}
            {!hideArrow && (
              <StyledArrow ref={setArrowElement} style={styles.arrow} placement={placement} />
            )}
          </StyledPopper>
        </Portal>
      )}
    </Fragment>
  )
}
