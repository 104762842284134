// React context provider for integration configuration

import React, { createContext, useContext } from 'react'
import { Center, LoadingDots } from '@retailer-platform/shared-components'
import { type ApiSnapshot } from '../../api/get-snapshots/api'
import { ErrorPageAlert } from '../generic-error-page-alert/ErrorPageAlert'
import { useGetSnapshotsQuery } from '../../api'

const SnapshotsContext = createContext<ApiSnapshot[]>([])

export type SnapshotsProviderProps = {
  snapshotIds?: string
  snapshots?: ApiSnapshot[]
}

export const SnapshotsProvider: React.FC<React.PropsWithChildren<SnapshotsProviderProps>> = ({
  snapshotIds,
  snapshots,
  children,
}) => {
  if (!snapshotIds && !snapshots) {
    throw new Error('Snapshots or IDs must be provided')
  }

  const apiQueryState = useGetSnapshotsQuery({
    variables: {
      ids: snapshotIds!,
    },
    skip: !!snapshotIds,
  })

  const coalescedSnapshots = snapshots || apiQueryState.data?.approvalsManagementGetSnapshots

  if (apiQueryState.loading) {
    return (
      <Center fillParent>
        <LoadingDots />
      </Center>
    )
  }

  if (apiQueryState.error) {
    return <ErrorPageAlert error={apiQueryState.error} refetch={apiQueryState.refetch} />
  }

  if (!coalescedSnapshots) {
    throw new Error('Snapshots not found')
  }

  return (
    <SnapshotsContext.Provider value={coalescedSnapshots}>{children}</SnapshotsContext.Provider>
  )
}

export const useSnapshots = () => useContext(SnapshotsContext)
