import React, { Component, type InputHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

const InputContainer = styled.span<{ isFocused: boolean; fullWidth?: boolean }>`
  display: flex;
  background-color: ${colors.WHITE};
  border: 1px solid ${({ isFocused }) => (isFocused ? colors.GREEN_500 : colors.GRAY_74)};
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0px;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  outline: none;
  font-size: 14px;
  height: 38px;
`

const InputPrefixContainer = styled.span`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Input = styled.input`
  color: ${colors.GRAY_13};
  outline: none;
  border: none;
  padding-left: 10px;
  font-size: 14px;
  border-radius: 4px;
  height: 33px;
  margin: 0;
`

interface Props {
  readOnly?: boolean
  type: 'text' | 'number' | 'currency'
  value: string
  name: string
  elementAttributes?: InputHTMLAttributes<HTMLInputElement> & {
    'data-testid'?: string
  }
  prefix?: string | JSX.Element
  fullWidth?: boolean
  isValid?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface State {
  isFocused: boolean
}

class TextField extends Component<Props, State> {
  static defaultProps = {
    isValid: true,
    type: 'text',
  }

  readonly state: State = {
    isFocused: false,
  }

  handleFocus = () => {
    this.setState({
      isFocused: true,
    })
  }

  handleBlur = () => {
    this.setState({
      isFocused: false,
    })
  }

  render() {
    const { readOnly, type, value, name, elementAttributes, prefix, fullWidth } = this.props

    const { isFocused } = this.state

    return (
      <InputContainer isFocused={isFocused} fullWidth={fullWidth}>
        {prefix && <InputPrefixContainer>{prefix}</InputPrefixContainer>}
        <Input
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.props.onChange}
          type={type}
          readOnly={readOnly}
          name={name}
          value={value}
          {...elementAttributes}
        />
      </InputContainer>
    )
  }
}

export default TextField
