const EN_US = {
  'storeHoursBulkDomain.title': 'Store hours',
  'storeHoursBulkDomain.serviceType.delivery': 'Delivery',
  'storeHoursBulkDomain.regularHours': 'Regular hours',
  'storeHoursBulkDomain.specialHours': 'Special hours',
  'storeHoursBulkDomain.noHours': 'No hours',
  'storeHoursBulkDomain.noStoreHourData': 'No store hour data',
  'storeHoursBulkDomain.deliveryHours': 'Delivery hours',
  'storeHoursBulkDomain.stores': 'Stores',
  'storeHoursBulkDomain.xStores': '{count} stores',
  'storeHoursBulkDomain.specialHoliday': 'Special holiday',
  'storeHoursBulkDomain.actions.okay': 'Okay',
  'storeHoursBulkDomain.actions.close': 'Close',
  'storeHoursBulkDomain.actions.confirm': 'Confirm',
  'storeHoursBulkDomain.actions.cancel': 'Cancel',
  'storeHoursBulkDomain.actions.edit': 'Edit',
  'storeHoursBulkDomain.actions.editHours': 'Edit hours',
  'storeHoursBulkDomain.actions.editHoursForXStores': 'Edit Hours for {count} Stores',
  'storeHoursBulkDomain.breadcrumbs.root': 'Store Hours',
  'storeHoursBulkDomain.breadcrumbs.editX': 'Editing hours for {thing}',
  'storeHoursBulkDomain.breadcrumbs.editXStoresSelected': 'Editing hours for {count} stores',
  'storeHoursBulkDomain.breadcrumbs.updateHistoryX': 'Update history for {thing}',
  'storeHoursBulkDomain.breadcrumbs.updateHistoryXStoresSelected':
    'Update history for {count} stores',
  'storeHoursBulkDomain.breadcrumbs.updateHistory': 'Update History',
  'storeHoursBulkDomain.breadcrumbs.holidayHours': 'Holidays',
  'storeHoursBulkDomain.breadcrumbs.holiday': '{holidayName} ({date}) hours',
  'storeHoursBulkDomain.list.noResults':
    'No stores found, try changing or removing some of the filters.',
  'storeHoursBulkDomain.list.editHours': 'Edit hours',
  'storeHoursBulkDomain.list.code': 'Store ID',
  'storeHoursBulkDomain.list.storeName': 'Store name',
  'storeHoursBulkDomain.list.launchStatus': 'Launch status',
  'storeHoursBulkDomain.list.confirmationStatus': 'Confirmation status',
  'storeHoursBulkDomain.list.lastUpdated': 'Last updated',
  'storeHoursBulkDomain.list.address': 'Address',
  'storeHoursBulkDomain.list.day': 'day',
  'storeHoursBulkDomain.list.filters.search': 'Search stores (code, name, address)',
  'storeHoursBulkDomain.list.filters.storeGroup': 'Store Group',
  'storeHoursBulkDomain.list.filters.state': 'State',
  'storeHoursBulkDomain.list.filters.city': 'City',
  'storeHoursBulkDomain.list.filters.actions.groupEdit': 'Group edit',
  'storeHoursBulkDomain.list.filters.actions.editHours': 'Edit Hours',
  'storeHoursBulkDomain.list.filters.actions.editCount': 'Edit ({count})',
  'storeHoursBulkDomain.list.filters.actions.groupEdit.noCountError': 'Select some stores first',
  'storeHoursBulkDomain.list.filters.actions.updateHistory': 'Update history',
  'storeHoursBulkDomain.list.filters.actions.updateHistoryCount': 'Update history ({count})',
  'storeHoursBulkDomain.list.modal.header.deliveryHours': 'Delivery hours',
  'storeHoursBulkDomain.list.modal.header.regularHours': 'Regular hours',
  'storeHoursBulkDomain.list.modal.header.specialHours': 'Special hours',
  'storeHoursBulkDomain.list.modal.header.specialHours.append': 'Special hours to append',
  'storeHoursBulkDomain.list.modal.header.specialHours.replace': 'Special hours to replace',
  'storeHoursBulkDomain.list.modal.noUpdate': 'No changes',
  'storeHoursBulkDomain.list.modal.deleteAllRegularHours': 'All regular hours will be deleted',
  'storeHoursBulkDomain.list.modal.deleteAllSpecialHours': 'All special hours will be deleted',
  'storeHoursBulkDomain.list.modal.openAllDay': 'Open all day',
  'storeHoursBulkDomain.list.modal.closed': 'Closed',
  'storeHoursBulkDomain.list.modal.noHoursSet': 'No hours set',
  'storeHoursBulkDomain.list.modal.noSpecialHoursSet': 'No special hours set',
  'storeHoursBulkDomain.list.modal.replacingSpecialHoursWarning':
    'This will delete all existing special hours for these stores and replace them with the hours listed above',
  'storeHoursBulkDomain.list.modal.replacingSpecialHoursSameDayWarning':
    'Modifying or deleting special hours for today may impact any existing orders.',
  'storeHoursBulkDomain.list.selectPage': 'Select page ({totalStoresPage})',
  'storeHoursBulkDomain.list.xStoresSelected': '{storeCount} store(s) selected on this page',
  'storeHoursBulkDomain.list.selectAllStores': 'select all {totalStores} stores',
  'storeHoursBulkDomain.list.selectPage.tooltip':
    'Select page adds all locations from the current page to your selection',
  'storeHoursBulkDomain.list.selectPage.toast': 'Appended the current page to your selection',
  'storeHoursBulkDomain.list.selectAll': 'Select all ({totalStores})',
  'storeHoursBulkDomain.list.selectAll.tooltip':
    'Select all adds all locations from the current filters to your selection',
  'storeHoursBulkDomain.list.selectAll.toast': 'Appended all pages to your selection',
  'storeHoursBulkDomain.list.unSelectAll': 'Unselect all',
  'storeHoursBulkDomain.list.viewSelectedStores': 'View selected stores ({storeCount})',
  'storeHoursBulkDomain.list.sortBy': 'Sort by',
  'storeHoursBulkDomain.list.virtualRetailer.title': 'Virtual Retailer',
  'storeHoursBulkDomain.list.virtualRetailer.body':
    'The current selected retailer is a virtual retailer of retailer {parentRetailer}. Virtual retailers get their store hours from their parent.',
  'storeHoursBulkDomain.list.virtualRetailer.body.link': 'Go to the parent retailer',
  'storeHoursBulkDomain.list.locationsCountNotIdentified':
    '{storeCount} store locations in the update could not be identified',
  'storeHoursBulkDomain.list.virtualRetailer.error':
    'There was an error checking if your retailer is virtual, please try again later.',
  'storeHoursBulkDomain.edit.title': 'Edit store hours',
  'storeHoursBulkDomain.edit.missingPermission': 'You do not have permission to edit store hours',
  'storeHoursBulkDomain.edit.editThisStoresHours': "Edit this store's hours",
  'storeHoursBulkDomain.edit.cannotEditNotLaunched':
    "Cannot edit this store's hours: store is not launched",
  'storeHoursBulkDomain.edit.header.viewSelectedStores': 'View selected stores',
  'storeHoursBulkDomain.edit.specialHour.replaceTitle': 'Replace Special hours',
  'storeHoursBulkDomain.edit.specialHour.replaceTip':
    'Replacing special hours will remove any existing special hours before creating new special hours.',
  'storeHoursBulkDomain.edit.specialHour.appendTitle': 'Append Special hours',
  'storeHoursBulkDomain.edit.specialHour.appendTip':
    'Appending special hours will not remove any existing special hours.  Any existing special hours with matching dates will be updated.',
  'storeHoursBulkDomain.edit.specialHour.specialHourModeError':
    'Please select a special hour edit mode',
  'storeHoursBulkDomain.edit.specialHour.specialHourAppendModeError':
    'Please create a new special hour to use append mode',
  'storeHoursBulkDomain.edit.specialHour.replaceBulkEditDisabled':
    'Replacing all special hours is not allowed when editing multiple stores',
  'storeHoursBulkDomain.edit.addSpecialHour': 'New special hour',
  'storeHoursBulkDomain.edit.deleteSpecialHour': 'Delete special hour',
  'storeHoursBulkDomain.edit.replaceHoursWithContent': 'Replace existing {hours}',
  'storeHoursBulkDomain.edit.editHoursWithContent': 'Edit {hours}',
  'storeHoursBulkDomain.edit.validation.selectSomethingToUpdate':
    'Select hours to update before continuing',
  'storeHoursBulkDomain.edit.validation.fixFormErrors':
    'Fix the form errors above before continuing',
  'storeHoursBulkDomain.edit.validation.endTimeAfterOpenTime':
    'The close time must be after the open time',
  'storeHoursBulkDomain.edit.validation.lessThan24HoursWindowRequired':
    'The time range must be less than 24 hours',
  'storeHoursBulkDomain.edit.validation.narrowTimeWindow': 'This time range is less then 4 hours',
  'storeHoursBulkDomain.edit.validation.mustBeUniqueDate':
    'Each special hour must have a unique date',
  'storeHoursBulkDomain.edit.validation.notValidDate': 'This date is invalid',
  'storeHoursBulkDomain.edit.validation.crossDayOverlapError':
    "This time range conflicts with the next day's open time",
  'storeHoursBulkDomain.edit.validation.enterADescription': 'Enter a description',
  'storeHoursBulkDomain.edit.specialHoliday':
    "This is a recognized holiday. If you are open as usual, select 'Regular hours' for this day.",
  'storeHoursBulkDomain.edit.conflict.loading': 'Checking for store hour conflicts...',
  'storeHoursBulkDomain.edit.conflict.noConflict': 'No conflicts found. Good to go!',
  'storeHoursBulkDomain.edit.conflict.conflictFoundMultiple':
    "Some of these stores' hours have been edited within the last {days} days.",
  'storeHoursBulkDomain.edit.conflict.conflictFoundSingular':
    "This store's hours have been edited within the last {days} days.",
  'storeHoursBulkDomain.edit.conflict.conflictedStores': 'Recently edited stores',
  'storeHoursBulkDomain.edit.conflict.viewConflictedStores': 'View recently edited stores',
  'storeHoursBulkDomain.edit.conflict.error':
    'There was an unexpected error while checking for conflicts. The submit button has been disabled. Please try again later.',
  'storeHoursBulkDomain.updateHistory.viewThisStoreHistory': "View this store's update history",
  'storeHoursBulkDomain.updateHistory.displayingUpdatesLastXDays':
    'Displaying most recent 100 updates over the last {count} days',
  'storeHoursBulkDomain.updateHistory.updateSource': 'Update source',
  'storeHoursBulkDomain.updateHistory.author': 'Author',
  'storeHoursBulkDomain.updateHistory.modifiedStores': 'Modified stores',
  'storeHoursBulkDomain.updateHistory.status': 'Update status',
  'storeHoursBulkDomain.updateHistory.failedToFetch':
    'An unexpected error occurred while fetching the update history. Please try again later, or contact support if the problem persists.',
  'storeHoursBulkDomain.updateHistory.statusExplanation':
    'Store hours updates may take up to 20 minutes to post and up to 24 hours to apply to existing Instacart orders.',
  'storeHoursBulkDomain.updateHistory.createdAt': 'Created at',
  'storeHoursBulkDomain.updateHistory.acceptedAt': 'Accepted at',
  'storeHoursBulkDomain.updateHistory.pending': 'Update pending',
  'storeHoursBulkDomain.updateHistory.pendingExplanation':
    'This update is waiting to be processed. Larger updates may take up to a few hours before they are accepted.',
  'storeHoursBulkDomain.updateHistory.updateAccepted': 'Update accepted',
  'storeHoursBulkDomain.updateHistory.updateAcceptedExplanation':
    'After accepting new hour updates, our systems may take up to another 24 hours for Instacart orders to follow the new hours.',
  'storeHoursBulkDomain.updateHistory.failureContactSupport': 'Update failed - contact support',
  'storeHoursBulkDomain.updateHistory.viewStores': 'View updated stores ({count})',
  'storeHoursBulkDomain.updateHistory.viewFailedStores': 'View failed stores ({failedCount})',
  'storeHoursBulkDomain.updateHistory.noUpdateHistory': 'No update history',
  'storeHoursBulkDomain.updateHistory.noStores': 'No stores',
  'storeHoursBulkDomain.cancelChanges': 'Cancel changes',
  'storeHoursBulkDomain.cancelChanges.neverMind': 'Never mind',
  'storeHoursBulkDomain.cancelChanges.confirm': 'Are you sure you want to cancel these changes?',
  'storeHoursBulkDomain.reviewChanges': 'Review changes',
  'storeHoursBulkDomain.reviewChanges.backgroundProcessing':
    'Store hour updates are processed in the background',
  'storeHoursBulkDomain.reviewChanges.submit': 'Submit changes',
  'storeHoursBulkDomain.reviewChanges.errorTryAgainLater':
    'An unexpected error occurred while checking for recent changes. Submitting hours has been disabled. Please try again later, or contact support if the problem persists.',
  'storeHoursBulkDomain.updateResults.title': 'Store Hours Update',
  'storeHoursBulkDomain.updateResults.storeList': 'Store list page',
  'storeHoursBulkDomain.updateResults.updateHistory': 'View update history',
  'storeHoursBulkDomain.updateResults.success': 'Update sent successfully',
  'storeHoursBulkDomain.updateResults.successBody':
    'Your new store hours should be processed soon!',
  'storeHoursBulkDomain.updateResults.failure': 'Update failed',
  'storeHoursBulkDomain.updateResults.retry': 'Retry update',
  'storeHoursBulkDomain.openState.open': 'Open',
  'storeHoursBulkDomain.openState.open24Hours': 'Open 24 hours',
  'storeHoursBulkDomain.openState.closed': 'Closed',
  'storeHoursBulkDomain.dayOfWeek.DAY_OF_WEEK_UNSPECIFIED': 'Unspecified',
  'storeHoursBulkDomain.dayOfWeek.MONDAY': 'Monday',
  'storeHoursBulkDomain.dayOfWeek.TUESDAY': 'Tuesday',
  'storeHoursBulkDomain.dayOfWeek.WEDNESDAY': 'Wednesday',
  'storeHoursBulkDomain.dayOfWeek.THURSDAY': 'Thursday',
  'storeHoursBulkDomain.dayOfWeek.FRIDAY': 'Friday',
  'storeHoursBulkDomain.dayOfWeek.SATURDAY': 'Saturday',
  'storeHoursBulkDomain.dayOfWeek.SUNDAY': 'Sunday',
  'storeHoursBulkDomain.noLocationsSelectionDescription':
    'No stores have been selected. Please select at least one store on the Stores list page.',
  'storeHoursBulkDomain.locationNotFoundDescription':
    'One of the selected stores could not be found. Please try selecting one or more stores on the Stores list page again.',
  'storeHoursBulkDomain.placeholder.select': 'Select...',
  'storeHoursBulkDomain.placeholder.date': 'Date...',
  'storeHoursBulkDomain.placeholder.description': 'Description...',
  'storeHoursBulkDomain.updateHistory.title': 'Store hours update history',
  'storeHoursBulkDomain.holidays.title': 'Holiday hours',
  'storeHoursBulkDomain.holidays.holiday': 'Holiday',
  'storeHoursBulkDomain.holidays.date': 'Date',
  'storeHoursBulkDomain.holidays.date.unknown': 'Unknown date',
  'storeHoursBulkDomain.holidays.confirm': 'Confirm',
  'storeHoursBulkDomain.holidays.groupConfirm': 'Group confirm',
  'storeHoursBulkDomain.holidays.confirmCount': 'Confirm ({count})',
  'storeHoursBulkDomain.holidays.confirmHours': 'Confirm hours',
  'storeHoursBulkDomain.holidays.confirmHolidayHoursTitle': 'Confirm Holiday Hours',
  'storeHoursBulkDomain.holidays.confirmed': 'Confirmed',
  'storeHoursBulkDomain.holidays.unconfirmed': 'Unconfirmed',
  'storeHoursBulkDomain.holidays.confirmXStoresForDate':
    "Would you like to confirm the following {count} stores' hours for {holidayName} ({date})?",
  'storeHoursBulkDomain.holidays.list.displayingHolidaysInCountry':
    'Displaying holiday in {countryName}',
  'storeHoursBulkDomain.holidays.list.confirmedStores': 'Confirmed stores',
  'storeHoursBulkDomain.holidays.list.unconfirmedStores': 'Unconfirmed stores',
  'storeHoursBulkDomain.holidays.list.errorFetching':
    'There was a problem fetching the list of holidays. Please try again later, or contact support if the problem persists.',
  'storeHoursBulkDomain.holidays.list.emptyHolidayList':
    'There are no holidays available for this retailer. Is {retailerName} active with visible and active zones? If you believe this is incorrect, contact support if the problem persists.',
  'storeHoursBulkDomain.holidays.names.unknownHoliday': 'Unknown holiday',
  'storeHoursBulkDomain.holidays.names.new_years_day': "New Year's Day",
  'storeHoursBulkDomain.holidays.names.martin_luther_king_day': 'Martin Luther King, Jr. Day',
  'storeHoursBulkDomain.holidays.names.valentines_day': "Valentine's Day",
  'storeHoursBulkDomain.holidays.names.lunar_new_year': "Lunar New Year's Day",
  'storeHoursBulkDomain.holidays.names.family_day': 'Family Day',
  'storeHoursBulkDomain.holidays.names.presidents_day': "Presidents' Day",
  'storeHoursBulkDomain.holidays.names.st_patricks_day': "St. Patrick's Day",
  'storeHoursBulkDomain.holidays.names.good_friday': 'Good Friday',
  'storeHoursBulkDomain.holidays.names.easter_monday': 'Easter Monday',
  'storeHoursBulkDomain.holidays.names.easter_sunday': 'Easter Sunday',
  'storeHoursBulkDomain.holidays.names.mothers_day': "Mother's Day",
  'storeHoursBulkDomain.holidays.names.memorial_day': 'Memorial Day',
  'storeHoursBulkDomain.holidays.names.victoria_day': 'Victoria Day',
  'storeHoursBulkDomain.holidays.names.juneteeth': 'Juneteenth National Independence Day',
  'storeHoursBulkDomain.holidays.names.fathers_day': "Father's Day",
  'storeHoursBulkDomain.holidays.names.saint_jean_baptist_day': 'Fête Nationale',
  'storeHoursBulkDomain.holidays.names.canada_day': 'Canada Day',
  'storeHoursBulkDomain.holidays.names.independence_day': 'Independence Day',
  'storeHoursBulkDomain.holidays.names.civic_day': 'Civic Holiday',
  'storeHoursBulkDomain.holidays.names.labor_day': 'Labor Day',
  'storeHoursBulkDomain.holidays.names.labour_day': 'Labour Day',
  'storeHoursBulkDomain.holidays.names.national_day_for_truth_and_reconciliation':
    'National Day for Truth and Reconciliation',
  'storeHoursBulkDomain.holidays.names.thanksgiving_canada': 'Thanksgiving',
  'storeHoursBulkDomain.holidays.names.halloween': 'Halloween',
  'storeHoursBulkDomain.holidays.names.regatta_day': 'Regatta Day',
  'storeHoursBulkDomain.holidays.names.remembrance_day': 'Remembrance Day',
  'storeHoursBulkDomain.holidays.names.veterans_day': 'Veterans Day',
  'storeHoursBulkDomain.holidays.names.thanksgiving_us': 'Thanksgiving',
  'storeHoursBulkDomain.holidays.names.black_friday': 'Day after Thanksgiving (Black Friday)',
  'storeHoursBulkDomain.holidays.names.christmas_eve': 'Christmas Eve',
  'storeHoursBulkDomain.holidays.names.christmas': 'Christmas Day',
  'storeHoursBulkDomain.holidays.names.boxing_day': 'Boxing Day',
  'storeHoursBulkDomain.holidays.names.new_years_eve': "New Year's Eve",
  'storeHoursBulkDomain.holidays.stores.title': 'Holidays - Store List',
  'storeHoursBulkDomain.holidays.stores.confirm.successfulMessage':
    "{confirmedCount} store's hours have been successfully confirmed",
  'storeHoursBulkDomain.warehouse.active': 'Launched',
  'storeHoursBulkDomain.warehouse.inactive': 'Not launched',
  'storeHoursBulkDomain.confirmed': 'Confirmed',
  'storeHoursBulkDomain.notConfirmed': 'Not confirmed',
} as const

export default EN_US
