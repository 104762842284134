import { useDebouncedEffect } from '@retailer-platform/shared-components'
import { segmentAnalytics } from '../../../utils/core/factories/events/segmentAnalytics'
import { trackEvent } from '../../../utils/events/trackEvent'
import { useAbsoluteRouteMatch } from '../../../utils/routing/useAbsoluteRouteMatch.hooks'

export const useTrackPageView = () => {
  const currentMatch = useAbsoluteRouteMatch()

  useDebouncedEffect(
    () => {
      // Bail if there's no match
      if (!currentMatch) return

      const { params, isExact, ...matchData } = currentMatch

      segmentAnalytics().page()
      trackEvent({
        id: 'app.page_view',
        description: 'page view with full path match information',
        data: {
          // Note that this is flattened as per the recommendations here https://segment.com/academy/collecting-data/the-anatomy-of-a-track-call/
          ...params,
          ...matchData,
          isExactPathMatch: isExact,
        },
      })
    },
    [currentMatch],
    850
  )
}
