export enum PickupAnalyticsMetricTitles {
  PickupVolume = 'pickup_volume',
  WeightedAverageTimePerPickedItem = 'weighted_average_time_per_picked_item',
  WeightedAverageBatchAcceptance = 'weighted_average_batch_acceptance',
  WeightedAverageRating = 'weighted_average_rating',
  AverageShopperRating = 'avg_shopper_rating',
  AverageExperienceRating = 'avg_experience_rating',
  AverageWaitTime = 'average_wait_time',
  P75WaitTime = 'p75_wait_time',
  LatePercentage = 'late_percentage',
  UtilizationPercentage = 'utilization_rate',
}

export enum PickupAnalyticsChartTitles {
  HandoffTiming = 'handoff_timing',
  PickupVolumeTrend = 'pickup_volume_trend',
  DeliveryVolumeTrend = 'delivery_volume_trend',
  AllVolumeTrend = 'total_pickup_delivery_volume_trend',
  P75WaitTimeTrend = 'p75_wait_time_trend',
  PickupRating = 'pickup_rating',
}

export type PickupAnalyticsResponseChart = {
  type: 'charts'
  data: {
    title_key: PickupAnalyticsChartTitles
    chart_downloadable: true
    time_range: {
      this_period: {
        start: string
        end: string
      }
      last_period: {
        start: string
        end: string
      }
    }
    charts: Array<{
      type: 'line_chart'
      data_type: 'number' | 'seconds'
      title_key: PickupAnalyticsChartTitles
      series: [
        {
          label: string
          type: 'OK'
        }
      ]
      data: [
        Array<{
          x: string
          y: number
        }>
      ]
    }>
  }
}

export type PickupAnalyticsResponseMetric = {
  type: 'metrics'
  data: {
    metrics: Array<{
      type: 'metric'
      data: {
        title_key: PickupAnalyticsMetricTitles
        data_type: string
        desired_direction: 'positive' | 'negative'
        this_period: number
        last_period: number
        delivery?: {
          this_period: number
          last_period: number
        }
        total_pickup_delivery?: {
          this_period: number
          last_period: number
        }
        note: string | null
      }
    }>
  }
}

export type PickupAnalyticsApiData = [
  PickupAnalyticsResponseMetric,
  PickupAnalyticsResponseChart,
  PickupAnalyticsResponseChart
]

export type PickupAnalyticsMetric = {
  currentPeriodData: number
  lastPeriodData: number
  delivery?: {
    currentPeriodData: number
    lastPeriodData: number
  }
  total_pickup_delivery?: {
    currentPeriodData: number
    lastPeriodData: number
  }
}

export type PickupAnalyticsApiMetric = {
  this_period: number
  last_period: number
  delivery?: {
    this_period: number
    last_period: number
  }
  total_pickup_delivery?: {
    this_period: number
    last_period: number
  }
}

export type PickupAnalyticsMetrics = {
  secondsPerItem?: PickupAnalyticsMetric
  utilizationRate?: PickupAnalyticsMetric
  batchAcceptance?: PickupAnalyticsMetric
  shopperRating?: PickupAnalyticsMetric
  latePercentage?: PickupAnalyticsMetric
  p75WaitTime?: PickupAnalyticsMetric
  pickups?: PickupAnalyticsMetric
}

export type OverallPerformanceTabs =
  | PickupAnalyticsChartTitles.AllVolumeTrend
  | PickupAnalyticsChartTitles.DeliveryVolumeTrend
  | PickupAnalyticsChartTitles.PickupVolumeTrend

export type PickupAnalyticsChartData = Array<{
  x: string
  y: number
}>

export type PickupAnalyticsOverallPerformanceChartData = {
  [PickupAnalyticsChartTitles.AllVolumeTrend]?: PickupAnalyticsChartData
  [PickupAnalyticsChartTitles.DeliveryVolumeTrend]?: PickupAnalyticsChartData
  [PickupAnalyticsChartTitles.PickupVolumeTrend]?: PickupAnalyticsChartData
}

export type PickupAnalyticsViewData = {
  pickup: PickupAnalyticsMetric
  batchAcceptance: PickupAnalyticsMetric
  p75WaitTime: PickupAnalyticsMetric
  experienceRating: PickupAnalyticsMetric
  minutesPerItem: PickupAnalyticsMetric
  latePercentage: PickupAnalyticsMetric
  utilizationPercentage: PickupAnalyticsMetric
  shopperRating: PickupAnalyticsMetric
  overallPerformanceSeries: PickupAnalyticsOverallPerformanceChartData
  p75WaitTimeSeries: PickupAnalyticsChartData
}
