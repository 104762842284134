import { useCallback, useMemo } from 'react'
import { type Flips, type FeatureToggle } from '../../contexts/featureToggles/FeatureToggleContext'
import { type WarehouseContextValue } from '../../../utils/contexts/warehouse/WarehouseContext'
import { useCurrentAccountContext } from '../../../utils/current-account/CurrentAccountContext'
import { PartnerType, type Permission } from '../../common/types'
import {
  useMatchICPlatformVersion,
  ICPlatformVersionMatchOperator,
} from '../../../utils/ic-platform/versioning'
import {
  type AccessControlConfig,
  hasFeatureToggle,
  hasPermission,
  hasWarehouseLocationFeature,
  featureTogglesToFlipsMapping,
  matchesPartnerType,
} from './accessControl.utils'
import { type PartnerContextValue } from '../../../utils/contexts/partner/PartnerContext'
import { useHasFeatureEntitlements } from '../../../utils/entitlements/useHasFeatureEntitlements.hooks'

export function useAccessControlCommon(
  warehouseOrPartnerContext: WarehouseContextValue | PartnerContextValue
): (accessControlConfig: AccessControlConfig | AccessControlConfig[]) => boolean {
  const currentAccountContext = useCurrentAccountContext()
  const matchesICPlatformVersion = useMatchICPlatformVersion()

  const hasFeatureEntitlements = useHasFeatureEntitlements(warehouseOrPartnerContext)

  const allUserPermissions = useMemo(() => {
    const retailerApiPermissions =
      currentAccountContext?.account?.permissions.map(permission => permission.slug) || []

    return retailerApiPermissions as Permission[]
  }, [currentAccountContext])

  // Account Feature Toggles: Loaded via account query modern API
  const allUserFeatureToggles = useMemo(() => {
    const currentAccountFeatureToggles = (
      currentAccountContext?.account?.featureToggles || []
    ).reduce((flips, featureToggle) => {
      flips[featureToggle.name] = true
      return flips
    }, {}) as Flips

    return { ...currentAccountFeatureToggles }
  }, [currentAccountContext])

  // Warehouse Feature Toggles: Loaded via modern API
  const allWarehouseFeatureToggles = useMemo(() => {
    const warehouseFeatureToggles = (warehouseOrPartnerContext?.featureToggles || []).map(
      featureToggle => featureToggle.name
    ) as FeatureToggle[]

    return [...warehouseFeatureToggles]
  }, [warehouseOrPartnerContext])

  return useCallback(
    function hasAccess(accessControlConfig: AccessControlConfig | AccessControlConfig[]) {
      return (
        Array.isArray(accessControlConfig) ? accessControlConfig : [accessControlConfig]
      ).some(
        ({
          featureToggles,
          notFeatureToggles,
          warehouseFeatureToggles,
          notWarehouseFeatureToggles,
          permissions,
          partnerTypes,
          warehouseLocationFeatures,
          minICPlatformVersion,
          maxICPlatformVersion,
          featureEntitlementIds,
        }) => {
          if (featureToggles && !hasFeatureToggle(allUserFeatureToggles, featureToggles)) {
            return false
          }

          if (notFeatureToggles && hasFeatureToggle(allUserFeatureToggles, notFeatureToggles)) {
            return false
          }

          if (permissions && !hasPermission(allUserPermissions, permissions)) {
            return false
          }

          if (featureEntitlementIds && !hasFeatureEntitlements(featureEntitlementIds)) {
            return false
          }

          if (warehouseOrPartnerContext?.partnerType) {
            // if we are in the partner or warehouse context, only show tools matching the partner type
            if (
              partnerTypes &&
              !matchesPartnerType(
                warehouseOrPartnerContext.partnerType as PartnerType,
                partnerTypes
              )
            ) {
              return false
            }
            // if no partner types are explicitly specified for the tool, don't show for partnerType=IDP
            if (!partnerTypes && warehouseOrPartnerContext.partnerType == PartnerType.IDP) {
              return false
            }
          }

          if (
            warehouseOrPartnerContext?.validId &&
            (warehouseLocationFeatures || warehouseFeatureToggles || notWarehouseFeatureToggles)
          ) {
            if (warehouseOrPartnerContext === null) {
              throw new Error('PartnerContext / WarehouseContext required for access permissions')
            }

            /**
             * If the locations aren't loaded yet we throw this promise so
             * that the Suspense API can block the render. We don't need to resolve
             * this due to the Suspense implementation when the hook is re-run after
             * context updates.
             */
            if (!warehouseOrPartnerContext.loaded && !warehouseOrPartnerContext.error) {
              throw new Promise(() => {})
            }

            if (warehouseOrPartnerContext.error) {
              return false
            }

            if (
              warehouseLocationFeatures &&
              !hasWarehouseLocationFeature(
                warehouseOrPartnerContext.locationFeatures,
                warehouseLocationFeatures
              )
            ) {
              return false
            }

            if (warehouseFeatureToggles) {
              const flips = featureTogglesToFlipsMapping(allWarehouseFeatureToggles)

              if (!hasFeatureToggle(flips, warehouseFeatureToggles)) {
                return false
              }
            }

            if (notWarehouseFeatureToggles) {
              const flips = featureTogglesToFlipsMapping(allWarehouseFeatureToggles)

              if (hasFeatureToggle(flips, notWarehouseFeatureToggles)) {
                return false
              }
            }
          }
          // checking for explicit undefined because sometimes the enum for the versions is 0 and the && always returns false
          if (minICPlatformVersion !== undefined && !matchesICPlatformVersion(minICPlatformVersion))
            return false
          // checking for explicit undefined because sometimes the enum for the versions is 0 and the && always returns false
          if (
            maxICPlatformVersion !== undefined &&
            !matchesICPlatformVersion(
              maxICPlatformVersion,
              ICPlatformVersionMatchOperator.GreaterThanEqual
            )
          )
            return false

          return true
        }
      )
    },
    [
      allUserFeatureToggles,
      allUserPermissions,
      hasFeatureEntitlements,
      matchesICPlatformVersion,
      warehouseOrPartnerContext,
      allWarehouseFeatureToggles,
    ]
  )
}
