import React, { type FunctionComponent } from 'react'
import { type Interpolation, type Theme } from '@emotion/react'
import { CardWrapper, type CardWrapperProps } from './CardWrapper'
import { CardHeader, type CardHeaderProps } from './CardHeader'
import { CardBody } from './CardBody'

export interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>,
    Pick<CardWrapperProps, 'showDropShadow'>,
    Pick<CardHeaderProps, 'subtitle' | 'titleDivider'> {
  title?: string | React.ReactElement
  headerContent?: React.ReactElement
  bodyStyles?: Interpolation<Theme>
}

export const Card: FunctionComponent<React.PropsWithChildren<CardProps>> = ({
  headerContent,
  children,
  title,
  bodyStyles,
  ...rest
}) => (
  <CardWrapper {...rest}>
    {(title || headerContent) && (
      <CardHeader title={title} {...rest}>
        {headerContent}
      </CardHeader>
    )}
    <CardBody css={bodyStyles}>{children}</CardBody>
  </CardWrapper>
)
