import React from 'react'
import { type AsyncStatus, type WarehouseLocation, type InventoryArea } from '../../common/types'

export interface RegionLocationContextValue {
  regionStatus: AsyncStatus
  locationStatus: AsyncStatus
  inventoryAreaStatus: AsyncStatus
  locations: WarehouseLocation[]
  warehouseLocations: WarehouseLocation[]
  allWarehouseLocations: WarehouseLocation[]
  inventoryAreas: InventoryArea[]
}

export const RegionLocationContext = React.createContext<RegionLocationContextValue | null>(null)

RegionLocationContext.displayName = 'RegionLocationContext'
