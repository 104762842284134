import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const insightsPortalCampaignInsightsAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.Campaign],
    warehouseFeatureToggles: [FeatureToggle.InsightsCampaignInsights],
    notWarehouseFeatureToggles: [FeatureToggle.InsightsCampaignInsightsDisabledRetailers],
    notFeatureToggles: [FeatureToggle.InsightsCampaignInsightsDisabledRetailers],
  },
]
export const insightsPortalCampaignCombinedNonIAMilestone2AccessControl: DomainAccessControlConfig =
  [
    {
      permissions: [Permission.Campaign],
      warehouseFeatureToggles: [FeatureToggle.InsightsCampaignInsights],
      notWarehouseFeatureToggles: [FeatureToggle.InsightsCampaignInsightsDisabledRetailers],
      notFeatureToggles: [
        FeatureToggle.InsightsPortalIAMilestone2,
        FeatureToggle.InsightsCampaignInsightsDisabledRetailers,
      ],
    },
  ]

export const insightsPortalCampaignCombinedIAMilestone2AccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.Campaign],
    warehouseFeatureToggles: [FeatureToggle.InsightsCampaignInsights],
    notWarehouseFeatureToggles: [FeatureToggle.InsightsCampaignInsightsDisabledRetailers],
    notFeatureToggles: [FeatureToggle.InsightsCampaignInsightsDisabledRetailers],
    featureToggles: [FeatureToggle.InsightsPortalIAMilestone2],
  },
]
