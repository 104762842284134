import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import {
  ViewType,
  DisallowNonIsolatedUserContexts,
} from '@retailer-platform/dashboard/gin-and-tonic'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  customersAccessControl,
  customersPrivacyRequestsAccessControl,
} from '../access-control/customersAccess.configuration'

// @hygen:inject page-import
const CustomersSearchPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomersSearchPage" */
      '../pages/search/CustomersSearchPage'
    )
)
const CustomersPrivacyRequestsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomersPrivacyRequestsPage" */
      '../pages/privacy-requests/CustomersPrivacyRequestsPage'
    )
)
const CustomersCustomerPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomersCustomerPage" */
      '../pages/customer/CustomersCustomerPage'
    )
)

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const customersRouter = (
  <DomainRoute route="customers" accessControl={customersAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="customers-search"
        component={CustomersSearchPage}
        scopePicker={{
          titles: ['customersDomain.search.pageTitle'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
          storeConfigFilter: DisallowNonIsolatedUserContexts,
        }}
      />
      <DomainRoute
        exact
        route="customers-privacy-requests"
        component={CustomersPrivacyRequestsPage}
        accessControl={customersPrivacyRequestsAccessControl}
        scopePicker={{
          titles: ['customersDomain.privacyRequests.pageTitle'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
          storeConfigFilter: DisallowNonIsolatedUserContexts,
        }}
      />
      <DomainRoute
        exact
        route="customers-customer-details"
        component={CustomersCustomerPage}
        scopePicker={{
          hiddenScopePicker: true,
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
          storeConfigFilter: DisallowNonIsolatedUserContexts,
        }}
      />
      <DomainRoute
        exact
        route="customers-customer-privacy-requests"
        component={CustomersCustomerPage}
        accessControl={customersPrivacyRequestsAccessControl}
        scopePicker={{
          titles: ['customersDomain.privacyRequests.pageTitle'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
          storeConfigFilter: DisallowNonIsolatedUserContexts,
        }}
      />
      {/* @hygen:inject router-route
      injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
