const EN_US = {
  'insightsPortalDomain.filters': 'Filters',
  'insightsPortalDomain.filters.stores': 'Stores',
  'insightsPortalDomain.filters.region.label': 'Region',
  'insightsPortalDomain.filters.state.label': 'State',
  'campaigns.create.customCampaign.form.retailerScope.asyncSearch.noOptions.beforeSearch':
    'Enter a warehouse name',
  'campaigns.create.customCampaign.form.retailerScope.asyncSearch.noOptions.noResults':
    'No banner found',
  'campaigns.create.customCampaign.title': 'Create a custom campaign',
  'campaigns.create.customCampaign.breadcrumb1': 'Campaign',
  'campaigns.create.customCampaign.breadcrumb2': 'Create campaign',
  'campaigns.create.customCampaign.form.header': 'Create campaign',
  'campaigns.create.customCampaign.form.offerEnabled': 'Set up offer',
  'campaigns.create.customCampaign.form.offerDisabled': 'No offer',
  'campaigns.create.customCampaign.form.task.title': 'Task required',
  'campaigns.create.customCampaign.form.task.description':
    'Tasks that the customer needs to complete before receiving the offer, within the campaign active period.',
  'campaigns.create.customCampaign.form.task.descriptionProp.title': 'Task Description',
  'campaigns.create.customCampaign.form.task.descriptionProp.description': `
    This field is Shown Directly to users in CRM messages.<br></br>
    The message is received on task assignment.<br></br>
    It must be set to something accurately describing the task and parameters below.<br></br>
    For %-Off coupon: this must also include %-value<br></br>
    See: <dxgyCrmLink>DxGy CRM Doc</dxgyCrmLink> for more details.
  `,
  'campaigns.create.customCampaign.form.task.descriptionProp.hint': 'Do X to complete this task',
  'campaigns.create.customCampaign.form.task.options.noTaskRequired.label': 'No task required',
  'campaigns.create.customCampaign.form.task.options.loyaltySignUp.label': 'Loyalty card',
  'campaigns.create.customCampaign.form.task.options.loyaltySignUp.description':
    'Sign up or link a retailer loyalty account',
  'campaigns.create.customCampaign.form.task.options.accumulativeSpend.label': 'Accumulative Spend',
  'campaigns.create.customCampaign.form.task.options.accumulativeSpend.description':
    'Reach the target spend over time',
  'campaigns.create.customCampaign.form.task.options.addToFamilyAccount.label': 'Family account',
  'campaigns.create.customCampaign.form.task.options.addToFamilyAccount.description':
    'Start a family account and add a family member',
  'campaigns.create.customCampaign.form.task.options.clubRetailerOrder.label': 'Club order',
  'campaigns.create.customCampaign.form.task.options.clubRetailerOrder.description':
    'Place an order at a club retailer',
  'campaigns.create.customCampaign.form.task.options.collabOrder.label': 'Collab order',
  'campaigns.create.customCampaign.form.task.options.collabOrder.description':
    'Place an order in collaboration with a family member.',
  'campaigns.create.customCampaign.form.task.options.icMembershipStart.label': 'Instacart+',
  'campaigns.create.customCampaign.form.task.options.icMembershipStart.description':
    'Sign up for an Instacart+ membership',
  'campaigns.create.customCampaign.form.task.options.nonGroceryOrder.label': 'Non-grocery order',
  'campaigns.create.customCampaign.form.task.options.nonGroceryOrder.description':
    'Place an order at a non–grocery retailer',
  'campaigns.create.customCampaign.form.task.options.numOrder.label': 'Multiple orders',
  'campaigns.create.customCampaign.form.task.options.numOrder.description':
    'Place the specified number of orders',
  'campaigns.create.customCampaign.form.task.options.paymentMethod.label': 'Payment method order',
  'campaigns.create.customCampaign.form.task.options.paymentMethod.description':
    'Place an order with the specific payment method',
  'campaigns.create.customCampaign.form.task.param.completionRule.either': 'Link or sign up',
  'campaigns.create.customCampaign.form.task.param.completionRule.signUp': 'Sign up only',
  'campaigns.create.customCampaign.form.task.param.completionRule.linkExisting': 'Link existing',
  'campaigns.create.customCampaign.form.task.param.retailerSetType.all': 'All',
  'campaigns.create.customCampaign.form.task.param.retailerSetType.club': 'Club',
  'campaigns.create.customCampaign.form.task.param.retailerSetType.beauty': 'Beauty',
  'campaigns.create.customCampaign.form.task.param.retailerSetType.nonGrocery': 'Non-grocery',
  'campaigns.create.customCampaign.form.task.param.paymentMethod.2023':
    'partnerships mastercard embedded 2023',
  'campaigns.create.customCampaign.form.task.param.paymentMethod.2024':
    'partnerships mastercard embedded 2024',
  'campaigns.create.customCampaign.form.task.param.paymentMethod.other': 'Other payment method',
  'campaigns.create.customCampaign.form.task.param.optional': '(optional)',
  'campaigns.create.customCampaign.form.task.param.inputError':
    '{paramName} should be at least {minValue}',
  'campaigns.create.customCampaign.form.task.param.label.minBasketSize':
    'Min basket size in dollars',
  'campaigns.create.customCampaign.form.task.param.label.spendThresholdCents': 'Target spend',
  'campaigns.create.customCampaign.form.task.param.label.retailerSet': 'Retailer type',
  'campaigns.create.customCampaign.form.task.param.label.allowTrialMembership':
    'Include trial membership',
  'campaigns.create.customCampaign.form.task.param.label.completionRule': 'Completion rule',
  'campaigns.create.customCampaign.form.task.param.label.numOrder': 'Number of orders',
  'campaigns.create.customCampaign.form.task.param.label.isEvergreen':
    'Lifetime within campaign schedule',
  'campaigns.create.customCampaign.form.task.param.label.hasIcMembership': 'IC members only',
  'campaigns.create.customCampaign.form.task.param.label.paymentMethod': 'Payment method',
  'campaigns.create.customCampaign.form.task.nonOrderRetaledTasks.label': 'Non order related tasks',
  'campaigns.create.customCampaign.form.task.orderRetaledTasks.label': 'Order related tasks',
  'campaigns.create.customCampaign.form.task.orderRetaledTasks.info':
    'This is a single order task, you can configure additional conditions in the section below.',
  'campaigns.create.customCampaign.form.task.summary.loyaltyCard':
    '{completionRule} a retailer loyalty account.',
  'campaigns.create.customCampaign.form.task.summary.accumulativeSpend':
    'Reach the target spend: {targetSpend}.',
  'campaigns.create.customCampaign.form.task.summary.icMembershipStart':
    'Sign up for an Instacart+ membership. {allowTrialMembership}.',
  'campaigns.create.customCampaign.form.task.summary.numOrder': 'Place {numOrder} orders.',
  'campaigns.create.customCampaign.form.task.summary.clubRetailerOrder': 'Place a club order.',
  'campaigns.create.customCampaign.form.task.summary.collabOrder': 'Place a collab order.',
  'campaigns.create.customCampaign.form.task.summary.nonGroceryOrder': 'Place a non-grocery order.',
  'campaigns.create.customCampaign.form.task.summary.paymentMethod':
    'Checkout with {paymentMethod}.',
  'campaigns.create.customCampaign.form.offer.summary.offerDetails': 'Offer details:',
  'campaign.create.customCampaign.form.offer.summary.requirement': 'Requirement',
  'campaigns.create.customCampaign.form.offer.summary.card.title': 'Summary',
  'campaigns.create.customCampaign.form.offer.summary.card.terms.title': 'Terms apply',
  'campaigns.create.customCampaign.form.offer.summary.endingText': 'Conditions apply.',
  'campaigns.create.customCampaign.form.offer.summary.discountRewardType.dollarOff':
    '${discountAmount} off',
  'campaigns.create.customCampaign.form.offer.summary.discountRewardType.percentageOff':
    '{discountPercentage}% off',
  'campaigns.create.customCampaign.form.offer.summary.discountRewardType.dollarCreditBack':
    '${creditBackAmount} credit back from',
  'campaigns.create.customCampaign.form.offer.summary.discountRewardType.percentageCreditBack':
    '{creditBackPercentage}% credit back from',
  'campaigns.create.customCampaign.form.offer.summary.freeDelivery': 'Free delivery on',
  'campaigns.create.customCampaign.form.offer.summary.freePickup': 'Free pickup on',
  'campaigns.create.customCampaign.form.offer.summary.freeDeliveryOrPickup':
    'Free delivery or Free pickup on',
  'campaigns.create.customCampaign.form.offer.summary.deliveryOff': 'Delivery',
  'campaigns.create.customCampaign.form.offer.summary.pickupOff': 'Pickup',
  'campaigns.create.customCampaign.form.offer.summary.deliveryOrPickupOff': 'Delivery or pickup',
  'campaigns.create.customCampaign.form.offer.summary.serviceOff': 'Service fee',
  'campaigns.create.customCampaign.form.offer.summary.priorityOff': 'Priority fee',
  'campaigns.create.customCampaign.form.offer.summary.setPrice': 'Set price on',
  'campaigns.create.customCampaign.form.offer.summary.freeItem': 'Free item',
  'campaigns.create.customCampaign.form.offer.summary.feeDiscount': 'Fee discount',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.basket': 'basket',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.product': 'product',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.products': 'products',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.productsWithQuantity':
    '{rewardQuantityLimit, plural, =1 {# product} other {# products}}',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.productsInCollections':
    'products in the collections',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.productsInCollectionsWithQuantity':
    '{rewardQuantityLimit, plural, =1 {# product} other {# products}} in the collections',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.collections': 'collections',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.categories': 'categories',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.brands': 'brands',
  'campaigns.create.customCampaign.form.offer.summary.offerRewardType.retailerDepartments':
    'retailer departments',
  'campaigns.create.customCampaign.form.offer.summary.minSpend': ' of ${minimumSpend}+',
  'campaigns.create.customCampaign.form.offer.summary.noneRequirement': 'None',
  'campaigns.create.customCampaign.form.offer.summary.spend': 'Spend ${minimumSpend}',
  'campaigns.create.customCampaign.form.offer.summary.buyQuantity':
    'Buy {quantity, plural, =1 {# product} other {# products}}',
  'campaigns.create.customCampaign.form.offer.summary.minBuyQuantity':
    'Buy {quantity} or more products',
  'campaigns.create.customCampaign.form.offer.summary.discountMax':
    ' to a maximum of ${discountMaximum}',
  'campaigns.create.customCampaign.form.offer.summary.upTo': 'up to ${discountMaximum}',
  'campaigns.create.customCampaign.form.offer.summary.applicationMethod.coupon':
    'Immediate discount applied with coupon code{couponCode}',
  'campaigns.create.customCampaign.form.offer.summary.applicationMethod.autoApply':
    'Immediate discount applied',
  'campaigns.create.customCampaign.form.offer.summary.ApplicationMethod.RFMClickToRedeem':
    'Immediate discount applied with coupon redemption',
  'campaigns.create.customCampaign.form.offer.summary.collection':
    ' on {numOfSelectedCollections, plural, =1 {collection} other {collections}}',
  'campaigns.create.customCampaign.form.offer.summary.product':
    ' on {numOfSelectedProducts, plural, =1 {product} other {products}}:',
  'campaigns.create.customCampaign.form.offer.summary.retailerDepartment':
    ' on {numOfSelectedRetailerDepartment, plural, =1 {retailer department} other {retailer departments}}',
  'campaigns.create.customCampaign.form.offer.summary.collectionExclusion':
    ' excluding collections',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.delivery':
    ', delivery only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.pickup': ', pickup only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.icMemberOnly':
    ', IC+ members only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.loyaltyMemberOnly':
    ', Loyalty members only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.ApplePay':
    ', Apple pay payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.ChasePwp':
    ', Chase credit card payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.Ebt':
    ', EBT payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.GooglePay':
    ', Google pay payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.Hsa':
    ', HSA payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.HsaFsaCard':
    ', HSA FSA card payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.KlarnaMultiUse':
    ', Klarna Pay in Four payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.KlarnaSingleUse':
    ', Klarna Pay Now payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.Medicare':
    ', Medicare payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.Paypal':
    ', Paypal payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.paymentType.Venmo':
    ', Venmo payment only',
  'campaigns.create.customCampaign.form.offer.summary.additionalCondition.firstNOrders':
    ', First {orderCount} {orderCount, plural, =1 {order} other {orders}}',
  'campaigns.create.customCampaign.form.offer.summary.asCreditBack':
    'As credit back; Credits rewarded expire in {dayCount} days',
  'campaigns.create.customCampaign.form.offer.shortCut.label': 'Commonly used offers',
  'campaigns.create.customCampaign.form.offer.shortCut.group.basket': 'Basket offers',
  'campaigns.create.customCampaign.form.offer.shortCut.group.product': 'Product offers',
  'campaigns.create.customCampaign.form.offer.shortCut.group.other': 'Other shortcut types',
  'campaigns.create.customCampaign.form.offer.shortCut.dollarOffBasket.label':
    'Get $Y off basket when you spend $X',
  'campaigns.create.customCampaign.form.offer.shortCut.dollarOffBasket.description':
    'e.g. Get $10 off $35+',
  'campaigns.create.customCampaign.form.offer.shortCut.dollarOffBasketWithCollection.label':
    'Get $Y off collections when you spend $X',
  'campaigns.create.customCampaign.form.offer.shortCut.dollarOffBasketWithCollection.description':
    'e.g. Get $10 off $35+ on collections A, B, and C',
  'campaigns.create.customCampaign.form.offer.shortCut.percentageOffBasket.label':
    'Get Y% off basket up to $X maximum',
  'campaigns.create.customCampaign.form.offer.shortCut.percentageOffBasket.description':
    'e.g. Get 10% off up to $20',
  'campaigns.create.customCampaign.form.offer.shortCut.percentageOffBasketWithCollection.label':
    'Get Y% off collections up to $X maximum',
  'campaigns.create.customCampaign.form.offer.shortCut.percentageOffBasketWithCollection.description':
    'e.g. Get 10% off up to $20 on collections A, B, and C',
  'campaigns.create.customCampaign.form.offer.rewardType.label': 'Discount type',
  'campaigns.create.customCampaign.form.offer.rewardType.group.dollarOff': 'Dollar ($) off rewards',
  'campaigns.create.customCampaign.form.offer.rewardType.group.percentageOff':
    'Percentage (%) off rewards',
  'campaigns.create.customCampaign.form.offer.rewardType.group.other': 'Other reward types',
  'campaigns.create.customCampaign.form.offer.rewardType.dollarOffBasket.label':
    'Dollar off basket',
  'campaigns.create.customCampaign.form.offer.rewardType.dollarOffCollectionsOrProducts.label':
    'Dollar off products or collections',
  'campaigns.create.customCampaign.form.offer.rewardType.percentageOffBasket.label':
    'Percentage off basket',
  'campaigns.create.customCampaign.form.offer.rewardType.percentageOffCollectionsOrProducts.label':
    'Percentage off products or collections',
  'campaigns.create.customCampaign.form.offer.rewardType.setPriceProduct.label':
    'Set price on products',
  'campaigns.create.customCampaign.form.offer.rewardType.freeItem.label': 'Free item',
  'campaigns.create.customCampaign.form.offer.rewardType.freeDelivery.label': 'Free Delivery',
  'campaigns.create.customCampaign.form.offer.rewardType.freePickup.label': 'Free Pickup',
  'campaigns.create.customCampaign.form.offer.rewardType.freeDeliveryOrPickup.label':
    'Free Delivery or Free Pickup',
  'campaigns.create.customCampaign.form.offer.rewardType.feeDiscount.label': 'Fee Discount',
  'campaigns.create.customCampaign.form.offer.offerId.label': 'Offer id',
  'campaigns.create.customCampaign.form.offer.applicationMethod.label': 'Application method',
  'campaigns.create.customCampaign.form.offer.applicationMethod.autoApply.label': 'Auto apply',
  'campaigns.create.customCampaign.form.offer.applicationMethod.autoApply.description':
    'Applied automatically at checkout (available for all customers)',
  'campaigns.create.customCampaign.form.offer.applicationMethod.couponCode.label': 'Coupon code',
  'campaigns.create.customCampaign.form.offer.applicationMethod.couponCodeWithCode.label':
    'Coupon code with code {couponCode}',
  'campaigns.create.customCampaign.form.offer.applicationMethod.couponCode.description':
    'Customers must add a code once to redeem the offer. The offer will remain in their wallet as a coupon.',
  'campaigns.create.customCampaign.form.offer.applicationMethod.CheckoutCodeBasedDiscount.label':
    'Promo code at checkout',
  'campaigns.create.customCampaign.form.offer.applicationMethod.CheckoutCodeBasedDiscount.description':
    'Customers must add a code at checkout to activate the offer. The offer expires at the end of their session.',
  'campaigns.create.customCampaign.form.offer.applicationMethod.RFMClickToRedeem.label':
    'Click to redeem',
  'campaigns.create.customCampaign.form.offer.applicationMethod.RFMClickToRedeem.description':
    'Coupon redemption without custom coupon code',
  'campaigns.create.customCampaign.form.offer.feeType.free.deliveryFee.label': 'Free Delivery',
  'campaigns.create.customCampaign.form.offer.feeType.free.pickupFee.label': 'Free Pickup',
  'campaigns.create.customCampaign.form.offer.feeType.free.pickupOrDeliveryFee.label':
    'Free Pickup or delivery',
  'campaigns.create.customCampaign.form.offer.feeType.free.serviceFee.label': 'Service fee',
  'campaigns.create.customCampaign.form.offer.feeType.free.priorityFee.label': 'Priority fee',
  'campaigns.create.customCampaign.form.offer.feeType.label': 'Fee type',
  'campaigns.create.customCampaign.form.offer.feeType.deliveryFee.label': 'Delivery fee',
  'campaigns.create.customCampaign.form.offer.feeType.pickupFee.label': 'Pickup fee',
  'campaigns.create.customCampaign.form.offer.feeType.pickupOrDeliveryFee.label':
    'Pickup or delivery fee',
  'campaigns.create.customCampaign.form.offer.feeType.serviceFee.label': 'Service fee',
  'campaigns.create.customCampaign.form.offer.feeType.priorityFee.label': 'Priority fee',
  'campaigns.create.customCampaign.form.offer.dollarOrPercentageOff.discountCalculationType.free.label':
    'Free (100% off)',
  'campaigns.create.customCampaign.form.offer.dollarOrPercentageOff.discountCalculationType.amountOff.label':
    'Dollar off',
  'campaigns.create.customCampaign.form.offer.dollarOrPercentageOff.discountCalculationType.percentageOff.label':
    'Percentage off',
  'campaigns.create.customCampaign.form.offer.dollarOrPercentageOff.discountCalculationType.discountAmount.label':
    'Discount amount',
  'campaigns.create.customCampaign.form.offer.dollarOrPercentageOff.discountCalculationType.maxDiscountPerBasket.label':
    'Maximum reward amount per basket',
  'campaigns.create.customCampaign.form.offer.dollarOrPercentageOff.discountCalculationType.discountAmountMinError':
    'Amount must positive number',
  'campaigns.create.customCampaign.form.offer.reward.title': 'Customize your offer',
  'campaigns.create.customCampaign.form.offer.reward.subtitle':
    'Choose a reward and qualifying purchases',
  'campaigns.create.customCampaign.form.offer.reward.products': 'Promotional products',
  'campaigns.create.customCampaign.form.offer.reward.productsIncluded.title':
    'On select products (collections or products)',
  'campaigns.create.customCampaign.form.offer.reward.productsIncluded.subtitle':
    'Qualifying products',
  'campaigns.create.customCampaign.form.offer.reward.productsExcluded.title': 'Exclude collections',
  'campaigns.create.customCampaign.form.offer.reward.productsExcluded.subtitle':
    'Non-qualifying collections',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.collectionRadio.title':
    'Collections',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.icCollectionRadio.title':
    'IC Collections',
  'campaigns.create.customCampaign.form.offer.reward.cartRadio.title': 'Basket',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.retailerDepartmentRadio.title':
    'Retailer department',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.productRadio.title':
    'Products',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.productRadio.tooltip':
    'Up to {maxSelectedProducts} products',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.infoMessage':
    'Please use product collection to qualify more than {maxSelectedProducts} products.',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.selectedTotal':
    '{numSelectedProducts}/{maxSelectedProducts} products selected',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.search.placeholder':
    'Enter name, product ID, SKU or UPC',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.table.name': 'Product name',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.table.sku': 'SKU',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.table.rrc': 'RRC',
  'campaigns.create.customCampaign.form.offer.reward.productsSelection.table.productId':
    'Product ID',
  'campaigns.create.customCampaign.form.offer.reward.freeItem.quantity.title': 'Free item quantity',
  'campaigns.create.customCampaign.form.offer.condition.title': 'Requirements',
  'campaigns.create.customCampaign.form.offer.retailerDepartment.title': 'Select department',
  'campaigns.create.customCampaign.form.offer.condition.requirement.any': 'Any',
  'campaigns.create.customCampaign.form.offer.condition.requirement.minSpend': 'Minimum spend',
  'campaigns.create.customCampaign.form.offer.condition.requirement.minSpend.error':
    'Minimum spend is invalid',
  'campaigns.create.customCampaign.form.offer.condition.requirement.minQuantity':
    'Minimum product quantity',
  'campaigns.create.customCampaign.form.offer.condition.requirement.minQuantity.error':
    'Minimum product quantity is invalid',
  'campaigns.create.customCampaign.form.offer.condition.requirement.productQuantity':
    'Product Quantity',
  'campaigns.create.customCampaign.form.offer.condition.requirement.quantity': 'Set quantity',
  'campaigns.create.customCampaign.form.offer.condition.requirement.quantity.error':
    'Product quantity is invalid',
  'campaigns.create.customCampaign.form.offer.reward.includeConditionGroup':
    'Reward product(s) count toward required spend',
  'campaigns.create.customCampaign.form.offer.reward.excludeConditionGroup':
    'Reward product(s) do not count toward required spend',
  'campaigns.create.customCampaign.form.offer.reward.includeConditionGroupFreeItem':
    'Free qualifying product',
  'campaigns.create.customCampaign.form.offer.reward.excludeConditionGroupFreeItem':
    'Different free products or quantity from the qualifying products',
  'campaigns.create.customCampaign.form.offer.reward.rewardProducts': 'Reward',
  'campaigns.create.customCampaign.form.offer.reward.quantityLimit': 'Discount quantity limit',
  'campaigns.create.customCampaign.form.offer.reward.quantityLimit.products': 'Set quantity',
  'campaigns.create.customCampaign.form.offer.reward.quantityLimit.any': 'Any',
  'campaigns.create.customCampaign.form.offer.reward.group': 'Discount group',
  'campaigns.create.customCampaign.form.offer.condition.requirement.spend': 'Spend',
  'campaigns.create.customCampaign.form.offer.condition.requirement.spend.error':
    'Spend is invalid',
  'campaigns.create.customCampaign.form.offer.condition.requirement.partialApplication.label':
    'Only applies to sets of the minimum product quantity amount',
  'campaigns.create.customCampaign.form.offer.condition.requirement.partialApplication.description':
    'If checked, customers cannot partially claim the reward at the prorated price.',
  'campaigns.create.customCampaign.form.offer.condition.groupCriteria.required.error':
    'Select at least one group condition',
  'campaigns.create.customCampaign.form.offer.condition.groupCriteria.icCollectionQueryNotCalled.error':
    'There was an error IC Department not fetched. Please try again or contact support.',
  'campaigns.create.customCampaign.form.offer.condition.groupCriteria.icCollectionQueryLoading.error':
    'Please wait as we are fetching IC Department data.  Try again in a few seconds.',
  'campaigns.create.customCampaign.form.offer.condition.groupCriteria.icCollectionQueryError.error':
    'There was an error fetching IC Department. Please try again or contact support.',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType':
    'Payment type',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.anyPaymentType':
    'Any payment type',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.ApplePay.label':
    'Apple pay',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.ChasePwp.label':
    'Chase credit card',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.Ebt.label':
    'EBT',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.GooglePay.label':
    'Google pay',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.Hsa.label':
    'HSA',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.HsaFsaCard.label':
    'HSA FSA card',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.KlarnaMultiUse.label':
    'Klarna',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.Medicare.label':
    'Medicare',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.Paypal.label':
    'Paypal',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentType.Venmo.label':
    'Venmo',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentSingleUse':
    'Pay now (single-use)',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.paymentMultiUse':
    'Pay in four (multi-use)',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.fulfillmentType':
    'Fulfillment type',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.allOrders':
    'All orders',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.pickup': 'Pickup only',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.delivery':
    'Delivery only',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.icPlusMemberType':
    'IC+ status at the time of checkout',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.loyaltyMemberType':
    'Loyalty status at the time of checkout',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.any': 'Any',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.membersOnly':
    'Members Only',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.orderCount.label':
    'Order count restriction',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.noRestriction':
    'No restriction',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.restrictToFirstN.restrictToFirst':
    'Restrict to first ',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.restrictToFirstN.orders':
    ' order(s)',
  'campaigns.create.customCampaign.form.offer.condition.additionalCondition.restrictToFirstN.orderCountError':
    'Order count must be a positive integer',
  'campaigns.create.customCampaign.form.offer.terms.title': 'Terms and conditions',
  'campaigns.create.customCampaign.form.offer.terms.subtitle': 'Add your own fine print',
  'campaigns.create.customCampaign.form.offer.terms.addButton': 'Add',
  'campaigns.create.customCampaign.form.offer.terms.applyConditions': 'Apply conditions',
  'campaigns.create.customCampaign.form.offer.terms.cancel': 'Cancel',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.label': 'Offer period',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.description':
    'Set when the reward is valid',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.header.title': 'Scheduling limit',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.header.subtitle':
    'Choose when the offer is valid',

  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.summary.today': 'today',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.summary.neverEnds':
    'and never ends',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.summary.start':
    'Offer can be redeemed from {startDate} at {startTime} {timezoneAbbreviation} {endMessage}',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.summary.end':
    'to {endDate} at {endTime} {timezoneAbbreviation}',

  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.sameAsCampaign.checkbox':
    'Same as campaign dates',
  'campaigns.create.customCampaign.form.offer.terms.offerPeriod.sameAsCampaign.rangeError':
    'Date range must be within the campaign dates',
  'campaigns.create.customCampaign.form.offer.terms.basketRedemptionLimit.label':
    'Basket redemption limit',
  'campaigns.create.customCampaign.form.offer.terms.basketRedemptionLimit.description':
    'Set how many times this reward can be redeemed per transaction',
  'campaigns.create.customCampaign.form.offer.terms.basketRedemptionLimit.summary':
    'Limit {basketRedemptionLimit} per shopping trip',
  'campaigns.create.customCampaign.form.offer.terms.basketRedemptionLimit.title':
    'Basket redemption limit',
  'campaigns.create.customCampaign.form.offer.terms.basketRedemptionLimit.radioLabel':
    'Limit reward per basket',
  'campaigns.create.customCampaign.form.offer.terms.customerRedemptionLimit.label':
    'Customer redemption limit',
  'campaigns.create.customCampaign.form.offer.terms.customerRedemptionLimit.description':
    'Set how many times a customer can redeem',
  'campaigns.create.customCampaign.form.offer.terms.customerRedemptionLimit.summary':
    'Limit {customerRedemptionLimit} per customer account',
  'campaigns.create.customCampaign.form.offer.terms.customerRedemptionLimit.title':
    'Customer redemption limit',
  'campaigns.create.customCampaign.form.offer.terms.customerRedemptionLimit.radioLabel':
    'Limit per customer',
  'campaigns.create.customCampaign.form.offer.terms.offerRedemptionLimit.label':
    'Offer redemption limit',
  'campaigns.create.customCampaign.form.offer.terms.offerRedemptionLimit.description':
    'Set maximum number of times this offer can be redeemed over the course of this campaign',
  'campaigns.create.customCampaign.form.offer.terms.offerRedemptionLimit.summary':
    'Offer can be redeemed {offerRedemptionLimit} times total',
  'campaigns.create.customCampaign.form.offer.terms.offerRedemptionLimit.title':
    'Offer redemption limit',
  'campaigns.create.customCampaign.form.offer.terms.offerRedemptionLimit.radioLabel':
    'Limit total redemptions',
  'campaigns.create.customCampaign.form.offer.terms.couponDuration.label': 'Coupon duration',
  'campaigns.create.customCampaign.form.offer.terms.couponDuration.description':
    'Set the duration which the coupon will be valid for the customer from the time of clipping.',
  'campaigns.create.customCampaign.form.offer.terms.couponDuration.custom.summary':
    'Coupon can be used last up to {validForDays} days after being redeemed',
  'campaigns.create.customCampaign.form.offer.terms.couponDuration.campaignEndDate.summary':
    'Coupon can be used until {endDate} after being redeemed',
  'campaigns.create.customCampaign.form.offer.terms.couponDuration.title': 'Coupon duration',
  'campaigns.create.customCampaign.form.offer.terms.couponCount.label': 'Coupon count',
  'campaigns.create.customCampaign.form.offer.terms.couponCount.description':
    'How many coupons will customers get for this offer',
  'campaigns.create.customCampaign.form.offer.terms.couponCount.summary':
    '{couponCount} {couponCount, plural, =1 {coupon} other {coupons}} per offer redemption',
  'campaigns.create.customCampaign.form.offer.terms.couponCount.title': 'Coupon count',
  'campaigns.create.customCampaign.form.offer.terms.couponCount.explanation':
    'Upon promo code redemption, customer will get {couponCount, plural, =1 {# coupon} other {# coupons}} of {amountPerCoupon} off.',
  'campaigns.create.customCampaign.form.offer.terms.couponCount.error.min':
    'Coupon must be at least {couponCountMin}',
  'campaigns.create.customCampaign.form.offer.terms.couponCount.error.integer':
    'Coupon count must be a whole number',
  'campaigns.create.customCampaign.form.offer.terms.stackability.label': 'Offer stacking',
  'campaigns.create.customCampaign.form.offer.terms.stackability.description':
    'Specify whether the offer can be combined with other offers',
  'campaigns.create.customCampaign.form.offer.terms.stackability.summary.stackable':
    'Offer can be combined with other offers',
  'campaigns.create.customCampaign.form.offer.terms.stackability.summary.noneStackable':
    'Offer can not be combined with other offers',
  'campaigns.create.customCampaign.form.offer.terms.stackability.title': 'Offer stacking',
  'campaigns.create.customCampaign.form.offer.terms.stackability.checkbox':
    'Can be combined with other offers',
  'campaigns.create.customCampaign.form.offer.terms.customLegalField.label': 'Custom legal field',
  'campaigns.create.customCampaign.form.offer.terms.customLegalField.description':
    'Open text field',
  'campaigns.create.customCampaign.form.section.name.heading': 'Name',
  'campaigns.create.customCampaign.form.section.name.description':
    'This name is used to track your campaign.',
  'campaigns.create.customCampaign.form.section.name.placeholder': 'Enter a campaign name',
  'campaigns.create.customCampaign.form.section.placement.heading': 'Placement',
  'campaigns.create.customCampaign.form.section.placement.page.heading': 'Host page',
  'campaigns.create.customCampaign.form.section.placement.page.description': 'Homepage',
  'campaigns.create.promote.searchPageTypeHeading': 'Search',
  'campaigns.create.customCampaign.form.section.placement.placementType.heading': 'Placement type',
  'campaigns.create.customCampaign.form.section.placement.placementType.description':
    'Primary banner carousel',
  'campaigns.create.customCampaign.form.section.placement.carouselDetails.heading':
    'Banner carousel details',
  'campaigns.create.customCampaign.form.section.placement.carouselDetails.description':
    '{numberOfBanners} banners occupied',
  'campaigns.create.customCampaign.form.section.placement.rank.heading':
    'Choose where to insert your banner',
  'campaigns.create.customCampaign.form.section.placement.rank.firstLabel': 'Insert as first',
  'campaigns.create.customCampaign.form.section.placement.rank.lastLabel': 'Insert as last',
  'campaigns.create.customCampaign.form.section.placement.rank.footnote':
    'Inserts the banner as the first slot in the carousel and bumps the existing banners down.',
  'campaigns.create.customCampaign.form.section.duration.heading': 'Duration',
  'campaigns.create.customCampaign.form.section.duration.description':
    'Choose how long this campaign will appear.',
  'campaigns.create.customCampaign.form.section.duration.radio.ongoing.label': 'Ongoing',
  'campaigns.create.customCampaign.form.section.duration.radio.range.label': 'Select a date range',
  'campaigns.create.customCampaign.form.section.duration.checkbox.noEndDate.label': 'No end date',
  'campaigns.create.customCampaign.form.summary.sectionTitle': 'Summary',
  'campaigns.create.customCampaign.form.summary.field.name.label': 'Name',
  'campaigns.create.customCampaign.form.summary.field.name.placeholder': 'No name',
  'campaigns.create.customCampaign.form.summary.field.destination.label': 'Destination',
  'campaigns.create.customCampaign.form.summary.field.destination.placeholder': 'No destination',
  'campaigns.create.customCampaign.form.summary.field.placement.label': 'Placement',
  'campaigns.create.customCampaign.form.summary.field.placement.placeholder': 'No placement',
  'campaigns.create.customCampaign.form.summary.field.duration.label': 'Duration',
  'campaigns.create.customCampaign.form.summary.field.duration.value.ongoing': 'Ongoing',
  'campaigns.create.customCampaign.form.summary.field.duration.value.noEndDate': 'No end date',
  'campaigns.create.customCampaign.form.summary.field.duration.value.noStartDate': 'No start date',
  'campaigns.create.form.submit.error': 'Failed to create campaign.',
  'campaigns.create.storeFeature.error': 'Failed to create roulette feature.',
  'campaignManagement.customCampaign.title': 'Campaigns & Offers',
  'campaignManagement.customCampaign.button.create': 'Create campaign',
  'campaignManagement.customCampaign.table.title': 'Details',
  'campaignManagement.customCampaign.table.header': 'Partner',
  'campaignManagement.customCampaign.table.banner': 'Banner',
  'campaignManagement.customCampaign.table.site': 'Site',
  'campaignManagement.customCampaign.table.site.marketplace': 'Marketplace',
  'campaignManagement.customCampaign.table.site.whitelabel': 'Whitelabel',
  'campaignManagement.customCampaign.table.createdBy': 'Created by',
  'campaignManagement.customCampaign.table.visibleToRetailer': ' (visible to retailer)',
  'campaignManagement.customCampaign.table.hiddenFromRetailer': ' (hidden from retailer)',
  'global.campaigns.title': 'Global campaigns',
  'global.campaigns.tabs.campaignMoments': 'Campaign moments',
  'global.campaigns.tabs.campaignMoments.table.title': 'Details',
  'global.campaigns.tabs.campaignMoments.table.noResults': 'No campaign moments to display',
  'global.campaigns.tabs.campaignMoments.table.column.campaignMoments': 'Campaign moments',
  'global.campaigns.tabs.campaignMoments.table.column.status': 'Status',
  'global.campaigns.tabs.campaignMoments.table.column.start': 'Start date',
  'global.campaigns.tabs.campaignMoments.table.column.end': 'End date',
  'global.campaigns.tabs.campaignMoments.table.column.created': 'Created on',
  'global.campaigns.tabs.campaignMoments.table.column.participatingRetailers':
    'Participating retailers',
  'global.campaigns.tabs.retailerCampaigns': 'Retailer campaigns',
  'global.campaigns.tabs.campaignMoments.create': 'Create campaign moment',
  'global.campaigns.tabs.campaignMoments.create.name.description':
    'Name will be visible to all storefront customers.',
  'global.campaigns.tabs.campaignMoments.rootTitle': 'Retailer campaign moment',
  'global.campaigns.tabs.campaignMoments.create.title': 'Create',
  'global.campaigns.tabs.campaignMoments.create.title.new.moment': 'New moment',
  'global.campaigns.tabs.campaignMoments.create.campaignMomentSetUp': 'Campaign moment set up',
  'global.campaigns.tabs.campaignMoments.create.campaignMomentSetUp.error':
    'There was an error creating the campaign moment.',
  'global.campaigns.tabs.campaignMoments.edit.updateMoment.error': 'There was an error updating.',
  'global.campaigns.tabs.campaignMoments.view.cancelMoment.error':
    'There was an error deactivating the moment.',
  'global.campaigns.tabs.campaignMoments.getMomentParticipatingRetailers.error':
    'There was an error fetching participating retailers.',
  'global.campaigns.tabs.campaignMoments.updateMomentCampaignStatus.error':
    'There was an error tagging the campaign.',
  'global.campaigns.tabs.campaignMoments.updateMomentCampaignStatus.error.duplicated.campaign.templateId':
    'You cannot tag multiple campaigns with the same template type for a single retailer at a given moment. Please ensure only one template is tagged per retailer per moment.',
  'global.campaigns.tabs.campaignMoments.updateMomentCampaignStatus.error.invalid.campaign.templateId':
    'Cannot tag campaign with invalid campaign type. Only activation, churned, grow basket and custom campaigns are allowed.',
  'global.campaigns.tabs.campaignMoments.view.listMoments.error':
    'There was an error fetching moments.',
  'global.campaigns.tabs.campaignMoments.view.moment': 'Campaign moment',
  'global.campaigns.tabs.campaignMoments.view.participatingRetailers': 'Participating retailers',
  'global.campaigns.tabs.campaignMoments.view.deactivate': 'Deactivate moment',
  'global.campaigns.tabs.campaignMoments.view.edit': 'Edit moment',
  'global.campaigns.tabs.campaignMoments.view.deactivate.confirm': 'Confirm',
  'global.campaigns.tabs.campaignMoments.view.cancelMomentModal.title':
    'Confirm moment cancellation',
  'global.campaigns.tabs.campaignMoments.view.cancelMomentModal.cancel': 'Cancel',
  'global.campaigns.tabs.campaignMoments.view.cancelMomentModal.message1':
    'Are you sure you want to cancel',
  'global.campaigns.tabs.campaignMoments.view.cancelMomentModal.message2':
    "You won't be able to restore this moment.",
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.schedule.no.overlap':
    'Campaign Schedule has no overlap with Moment Schedule',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.title1':
    'Participating campaigns and retailers',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.title':
    '{total} Participating retailers',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.default.description':
    'No retailers have chosen to participate yet. Tip: encourage account managers to reach out to their retailers relationships.',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.default.button':
    'Manually curate retailer campaigns',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.toggle.off':
    'Toggle off campaign to remove from feature',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.review.retailers':
    'Review all retailers',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.collection.count.more':
    '+{productCount}',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.offer.percent': '%',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.offer.dollar': '$',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.offer':
    '{rewards} off ${condition} • {startDate} - {endDate}',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.toggle.off.title':
    'Remove retailer campaign from being featured',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.toggle.on.title':
    'Include retailer campaign in moment spotlight',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.modal.cancel': 'Cancel',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.modal.reject':
    'Reject Campaign',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.modal.feature':
    'Feature Campaign',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.modal.feature.campaign.content1':
    'Spotlight campaign',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.modal.feature.campaign.content2':
    'Be sure to let the retailer know that their campaign is being spotlighted',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.modal.content1':
    'Are you sure?',
  'global.campaigns.tabs.campaignMoments.view.participating.retailers.modal.content2':
    'Be sure to communicate to the retailer and set the right expectation on why their campaign is not being featured',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.label':
    'Manage participating retailers',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.description':
    'Select what retailers to promote',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.image.description':
    'No retailers have chosen to participate yet',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.moment.details.title':
    'Campaign Moment',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.moment.details.edit':
    'Edit',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.moment.details.name':
    'Name',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.moment.details.featureDate':
    'Feature date',
  'global.campaigns.tabs.campaignMoments.view.manage.participating.retailers.moment.details.submissionDeadline':
    'Retailer opt-in deadline ',
  'global.useSegmentCreation.error': 'Failed to create segment',
  'marketingAgent.title': 'Marketing agent',
  'marketingAgent.calendar.calculatePerformanceMetrics': 'Calculate Performance Metrics',
  'marketingAgent.calendar.validateSchedule': 'Validate Schedule',
  'marketingAgent.calendar.syncMarketingAgentCampaigns': 'Sync Marketing Agent Campaigns',
  'marketingAgent.calendar.overwriteLocalChanges': 'Overwrite Local Changes',
  'marketingAgent.calendar.keepLocalChanges': 'Keep Local Changes',
  'marketingAgent.calendar.view.validateSchedule.error':
    'There was an error validating the schedule. Please try again.',
  'marketingAgent.calendar.view.validateSchedule.success': 'Successfully validated the schedule.',
  'marketingAgent.calendar.modify.calculatePerformanceMetrics.error':
    'There was an error calculating performance metrics. Please try again.',
  'marketingAgent.calendar.modify.calculatePerformanceMetrics.success':
    'Successfully started calculating performance metrics.',
  'marketingAgent.calendar.modify.syncMarketingAgentCampaigns.success':
    'Successfully started syncing marketing agent campaigns.',
  'marketingAgent.calendar.modify.syncMarketingAgentCampaigns.error':
    'There was an error syncing marketing agent campaigns. Please try again.',
  'marketingAgent.calendar.overwriteLocalChanges.success':
    'Successfully started overwriting local changes with the latest data.',
  'marketingAgent.calendar.overwriteLocalChanges.error':
    'There was an error overwriting local changes with the latest data. Please try again.',
  'marketingAgent.campaign.saveMarketingAgentCampaign.success':
    'Successfully saved marketing agent campaign.',
  'marketingAgent.campaign.saveMarketingAgentCampaign.error':
    'There was an error saving marketing agent campaign. Please try again.',
  'marketingAgent.campaign.saveMarketingAgentCampaign.noChanges':
    'No changes detected. Nothing to save.',
  'marketingAgent.campaign.title': 'Marketing agent campaign',
  'marketingAgent.campaign.modules.details.isLocallyUpdated':
    'This campaign has been locally edited on MarketingAgentAI tooling on {locallyUpdatedAt} and may differ from Monday.com',
  'marketingAgent.campaign.modules.details.brazeSegmentIds': 'Braze Segment IDs',
  'marketingAgent.campaign.modules.details.taasSegmentIds': 'TaaS Segment IDs',
  'marketingAgent.campaign.modules.details.campaignBucket': 'Campaign Bucket',
  'marketingAgent.campaign.modules.details.campaignId': 'Campaign ID',
  'marketingAgent.campaign.modules.details.campaignName': 'Campaign Name',
  'marketingAgent.campaign.modules.details.description': 'Details of a MarketingAgentCampaign',
  'marketingAgent.campaign.modules.details.desiredAudienceSize': 'Desired Audience Size',
  'marketingAgent.campaign.modules.details.desiredBudgetInDollars': 'Desired Budget In Dollars',
  'marketingAgent.campaign.modules.details.maxRelevanceScore': 'Max Relevance Score',
  'marketingAgent.campaign.modules.details.minRelevanceScore': 'Min Relevance Score',
  'marketingAgent.campaign.modules.details.sendDates': 'Send Dates',
  'marketingAgent.campaign.modules.details.offerValue': 'Offer Value',
  'marketingAgent.campaign.modules.details.conversionPercentile': 'Conversion Percentile',
  'marketingAgent.campaign.modules.details.unsubscribePercentile': 'Unsubscribe Percentile',
  'marketingAgent.campaign.modules.details.spendPercentile': 'Spend Percentile',
  'marketingAgent.campaign.modules.details.noValueSupplied': 'No Value Supplied',
  'marketingAgent.campaign.modules.details.title': 'Campaign Details',
  'marketingAgent.campaign.modules.performanceMetrics.estimatedAudienceSize':
    'Estimated Audience Size',
  'marketingAgent.campaign.modules.performanceMetrics.estimatedUnsubscribers':
    'Estimated Unsubscribers',
  'marketingAgent.campaign.modules.performanceMetrics.estimatedSpend': 'Estimated Spend',
  'marketingAgent.campaign.modules.performanceMetrics.avgUnsubscribeRate':
    'Average Unsubscribe Rate',
  'marketingAgent.campaign.modules.performanceMetrics.avgConversionRate': 'Average Conversion Rate',
  'marketingAgent.campaign.modules.performanceMetrics.numSendDates': 'Number of Send Dates',
  'marketingAgent.campaign.modules.performanceMetrics.selectedSendDates': 'Selected Send Dates',
  'marketingAgent.campaign.modules.performanceMetrics.title': 'Performance Metrics',
  'marketingAgent.validationError.disclaimer':
    'Validation errors caused by relationships between campaigns can be viewed on the calendar page.',
  'marketingAgent.validationError.performanceMetricsNotCalculated':
    'Performance metrics have not been calculated. Please go back to the calendar and request them.',
  'marketingAgent.validationError.audienceMismatch':
    'The estimated audience size significantly differs from the target. Adjust targeting parameters to align with audience goals.',
  'marketingAgent.validationError.budgetMismatch':
    "The estimated budget exceeds the acceptable range. Review and adjust the campaign's budget allocation.",
  'marketingAgent.validationError.tooManyDailySends':
    'Daily send limit exceeded. Optimize send frequency or reduce the target audience to comply.',
  'marketingAgent.validationError.unspecified':
    'An unspecified validation error has occurred. Further investigation is required to resolve the issue.',
  'marketingAgent.campaign.modules.calculatePerformanceMetrics.error':
    'Error or delay in calculating performance metrics. Please review the data and try again.',
  'retailerFundedMarketingDomain.segmentation.table.title': 'Segments',
  'retailerFundedMarketingDomain.segmentation.table.no.result': 'No segments to display',
  'retailerFundedMarketingDomain.segmentation.table.description':
    'Reuse these segments from campaigns and offers to personalize your content for your customers. {readDocumentationURL}',
  'retailerFundedMarketingDomain.segmentation.table.readDocumentation': 'Read documentation',
  'retailerFundedMarketingDomain.segmentation.table.search.placeholder':
    'Search for a segment name',
  'retailerFundedMarketingDomain.segmentation.table.search.noOptions.beforeSearch':
    'Enter a segment name',
  'retailerFundedMarketingDomain.segmentation.table.search.noOptions.noResult': 'No segments found',
  'retailerFundedMarketingDomain.segmentation.table.grid.name': 'Segment name',
  'retailerFundedMarketingDomain.segmentation.table.grid.segmentType': 'Segment type',
  'retailerFundedMarketingDomain.segmentation.table.grid.segmentType.storeIds': 'Store IDs',
  'retailerFundedMarketingDomain.segmentation.table.grid.segmentType.userEmails': 'User emails',
  'retailerFundedMarketingDomain.segmentation.table.grid.author': 'Author',
  'retailerFundedMarketingDomain.segmentation.table.grid.modified': 'Modified date',
  'retailerFundedMarketingDomain.segmentation.table.grid.uploadedAt': 'Uploaded on',
  'retailerFundedMarketingDomain.segmentation.table.grid.segmentStatus': 'Status',
  'retailerFundedMarketingDomain.segmentation.title': 'Segment library',
  'retailerFundedMarketingDomain.segmentation.view.export.csv': 'Export as CSV',
  'retailerFundedMarketingDomain.segmentation.view.definition.tab.title': 'Segment Definition',
  'retailerFundedMarketingDomain.segmentation.view.definition.match': 'Match customers who',
  'retailerFundedMarketingDomain.segmentation.view.definition.match.store.group':
    'have these Store IDs available',
  'retailerFundedMarketingDomain.segmentation.view.storeGroup.name': 'Linked store group',
  'retailerFundedMarketingDomain.segmentation.view.storeGroup.ids': 'Included store ids',
  'retailerFundedMarketingDomain.segmentation.view.storeGroup.edit': 'Edit store group',
  'retailerFundedMarketingDomain.segmentation.view.storeGroup.error':
    'Linked store group not found',
  'retailerFundedMarketingDomain.segmentation.view.definition.match.user.email': 'has emails',
  'retailerFundedMarketingDomain.segmentation.list.create': 'Create',
  'retailerFundedMarketingDomain.segmentation.modal.title': 'Create segmentation from CSV',
  'retailerFundedMarketingDomain.segmentation.modal.segmentation.description':
    'This name will be used to reference the customers in this segment when you want to target an offer or a campaign.',
  'retailerFundedMarketingDomain.segmentation.modal.segment.name.label': 'Segment name',
  'retailerFundedMarketingDomain.segmentation.modal.segment.name': 'Name',
  'retailerFundedMarketingDomain.segmentation.modal.segment.name.error': 'Name is required',
  'retailerFundedMarketingDomain.segmentation.modal.segment.name.placeholder': 'Enter segment name',
  'retailerFundedMarketingDomain.segmentation.modal.segment.description.label': 'Description',
  'retailerFundedMarketingDomain.segmentation.modal.segment.description.optional': ' - optional',
  'retailerFundedMarketingDomain.segmentation.modal.segment.description.placeholder':
    'Explain what this segment is used for',
  'retailerFundedMarketingDomain.segmentation.modal.csv.label': 'CSV content',
  'retailerFundedMarketingDomain.segmentation.modal.csv.description':
    'What is the content of the CSV file?',
  'retailerFundedMarketingDomain.segmentation.modal.modal.csv.processingTime': 'Processing time',
  'retailerFundedMarketingDomain.segmentation.modal.modal.csv.processingTime.immediate':
    'immediate',
  'retailerFundedMarketingDomain.segmentation.modal.modal.csv.processingTime.delayHours':
    '{numHours} hours',
  'retailerFundedMarketingDomain.segmentation.modal.modal.csv.storeId.label': 'Store IDs',
  'retailerFundedMarketingDomain.segmentation.modal.csv.storeId.description':
    'CSV file must contain “Store ID” in one of its column name',
  'retailerFundedMarketingDomain.segmentation.modal.modal.csv.emails.label': 'Email IDs',
  'retailerFundedMarketingDomain.segmentation.modal.csv.emails.description':
    'CSV file must contain “emails” in one of its column name',
  'retailerFundedMarketingDomain.segmentation.modal.csv.emails.notice':
    'User emails may take {numHours} hours to process. Once complete, you can use the segment in your campaigns.',
  'retailerFundedMarketingDomain.segmentation.modal.upload.label': 'Upload file',
  'retailerFundedMarketingDomain.segmentation.modal.choose.file': 'Choose file',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.fileTypeError':
    'Incorrect file type uploaded',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.fileContentError':
    'Invalid file uploaded',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.uploadError':
    'Error uploading file',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.error': 'There was an error.',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.retry': 'Retry',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.no.valid.store.ids':
    'Segment can’t be created. There are no valid entries.',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.headerInvalid.emails':
    'CSV file must contain “emails” in one of its column name',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.unmatched':
    '{numUnmatched} entries were not found in our system',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.invalid.format':
    '{numInvalidFormat} entries were incorrectly formatted',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.duplicates':
    '{numduplicates} entries were duplicates',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.no.ignored':
    '{numIngored} entries were ignored: ',
  'retailerFundedMarketingDomain.segmentation.modal.upload.error.no.store.ids':
    'Segment cannot be created because we can’t find the “Store ID” column. It is case sensitive. Please ensure Column 1 is named “Store ID” and falls in Row 1',
  'retailerFundedMarketingDomain.segmentation.modal.processCSV.notifications.alertMessage':
    'Your CSV is uploading, please do not close this modal until the upload is complete.',
  'retailerFundedMarketingDomain.segmentation.modal.upload.storeGroups.infoMessage':
    'By uploading the store IDs, you will create a new store group. You can manage this group using the',
  'retailerFundedMarketingDomain.segmentation.modal.upload.storeGroups.storeGroupTool':
    'store group tool',
  'retailerFundedMarketingDomain.segmentation.modal.upload.storeGroups.notifications.createSuccess':
    'Store group has been created.',
  'retailerFundedMarketingDomain.segmentation.modal.upload.storeGroups.notifications.createFailure':
    'There was an error creating the store group.',
  'retailerFundedMarketingDomain.segmentation.modal.segment.creation.notifications.createFailure':
    'There was an error creating the segment.',
  'retailerFundedMarketingDomain.segmentation.modal.segment.creation.notifications.createSuccess':
    'Segment has been created successfully.',
  'retailerFundedMarketingDomain.segmentation.modal.uploadCSV.notifications.uploading':
    'Uploading CSV',
  'retailerFundedMarketingDomain.segmentation.modal.uploadCSV.notifications.success':
    'Successfully uploaded CSV',
  'retailerFundedMarketingDomain.segmentation.modal.uploadCSV.notifications.error':
    'Unable to upload CSV',
  'retailerFundedMarketingDomain.segmentation.modal.processCSV.notifications.begin':
    'Process CSV started',
  'retailerFundedMarketingDomain.segmentation.modal.processCSV.notifications.error':
    'Unable to process CSV',
  'retailerFundedMarketingDomain.segmentation.modal.upload.button': 'Choose file',
  'retailerFundedMarketingDomain.segmentation.modal.upload.dragDrop': 'Drag and Drop file here',
  'retailerFundedMarketingDomain.segmentation.modal.upload.or': 'or',
  'retailerFundedMarketingDomain.segmentation.modal.cancel': 'Cancel',
  'retailerFundedMarketingDomain.segmentation.modal.upload': 'Upload',
  'retailerFundedMarketingDomain.segmentation.modal.uploading': 'Uploading...',
  'retailerFundedMarketingDomain.lastUpdatedEveryHour.tooltip': 'The data is updated every hour',
  'retailerFundedMarketingDomain.filters.campaignName': 'Campaigns',
  'retailerFundedMarketingDomain.create.title': 'Campaigns & offers',
  'retailerFundedMarketingDomain.create.cancelButton': 'Cancel',
  'retailerFundedMarketingDomain.create.activateButton': 'Activate',
  'retailerFundedMarketingDomain.create.saveButton': 'Save',
  'retailerFundedMarketingDomain.create.saveDraftButton': 'Save Draft',
  'retailerFundedMarketingDomain.templates.title': 'Select a template',
  'retailerFundedMarketingDomain.templates.cancel': 'Cancel',
  'retailerFundedMarketingDomain.templates.create': 'Create campaign',
  'retailerFundedMarketingDomain.templates.moment.title': 'Join Instacart’s spotlights',
  'retailerFundedMarketingDomain.templates.moment.info':
    'Opt in to see your seasonal campaign on our homepage',
  'retailerFundedMarketingDomain.templates.viewMomentCampaign': 'View',
  'retailerFundedMarketingDomain.campaignDetailsHeader.newCustomerDiscount.label':
    'Acquire new customers',
  'retailerFundedMarketingDomain.campaignDetailsHeader.newCustomerDiscount.description':
    'Acquire new customers by offering a discount on their first order.',
  'retailerFundedMarketingDomain.campaignFormModule.warning.title': 'Your settings will reset',
  'retailerFundedMarketingDomain.campaignFormModule.warning.info.setup':
    'You are changing the campaign sites&banners options, the targeting and offer settings will be reset.',
  'retailerFundedMarketingDomain.campaignFormModule.warning.confirm': 'OK, confirm change',
  'retailerFundedMarketingDomain.campaignSetup.label': 'Campaign setup',
  'retailerFundedMarketingDomain.campaignSetup.banners': 'Banners',
  'retailerFundedMarketingDomain.campaignSetup.hideFromRetailerView': 'Hide from retailer view',
  'retailerFundedMarketingDomain.campaignSetup.hideFromRetailerViewTooltip':
    'If checked, the specified partner will not have management access to this campaign',
  'retailerFundedMarketingDomain.campaignSetup.placeholder': 'Set up the details of your campaign',
  'retailerFundedMarketingDomain.campaignSetup.summary.tags': 'Tags: {tags}',
  'retailerFundedMarketingDomain.campaignSetup.summary.banner': 'Banner: {banner}',
  'retailerFundedMarketingDomain.campaignSetup.summary.banners': 'Banners: {banners}',
  'retailerFundedMarketingDomain.campaignDetailsField.name.label': 'Name',
  'retailerFundedMarketingDomain.campaignDetailsField.name.placeholder': 'Create a campaign name',
  'retailerFundedMarketingDomain.campaignDetailsField.channel.label': 'Channel',
  'retailerFundedMarketingDomain.campaignDetailsField.channel.description': 'On Marketplace',
  'retailerFundedMarketingDomain.campaignDetailsField.audience.label': 'Audience',
  'retailerFundedMarketingDomain.campaignDetailsField.audience.description':
    'New Customer, All Locations (35)',
  'retailerFundedMarketingDomain.campaignDetailsField.offer.label': 'Offer',
  'retailerFundedMarketingDomain.campaignDetailsField.offer.description':
    '$5 off for orders $50 or more',
  'retailerFundedMarketingDomain.campaignDetailsField.duration.label': 'Duration',
  'retailerFundedMarketingDomain.campaignDetailsField.duration.description':
    'Starts 10/01/2020, Ends 10/31/2020',
  'retailerFundedMarketingDomain.campaignDetailsField.editButton': 'Edit',
  'retailerFundedMarketingDomain.title': 'Retailer Funded Marketing',
  'retailerFundedMarketingDomain.listCampaign.title': 'Campaigns & Offers',
  'retailerFundedMarketingDomain.listCampaign.created': 'Campaign activated successfully!',
  'retailerFundedMarketingDomain.listCampaign.viewCampaign': 'View Campaign',
  'retailerFundedMarketingDomain.listCampaign.filter.status': 'Campaign status',
  'retailerFundedMarketingDomain.listCampaign.filter.clearAll': 'Clear all',
  'retailerFundedMarketingDomain.listCampaign.message': 'Unable to get campaigns.',
  'retailerFundedMarketingDomain.listCampaign.tryAgain': 'Please try again',
  'retailerFundedMarketingDomain.listCampaign.search.placeholder': 'Search for a campaign',
  'retailerFundedMarketingDomain.listCampaign.filter.campaignTemplates.placeholder':
    'Campaign templates',
  'retailerFundedMarketingDomain.listCampaign.template.awareness': 'Awareness',
  'retailerFundedMarketingDomain.listCampaign.template.offer': 'Offer',
  'retailerFundedMarketingDomain.listCampaign.published': 'Published',
  'retailerFundedMarketingDomain.listCampaign.draft': 'Draft',
  'retailerFundedMarketingDomain.listCampaign.draft.deleteTitle': 'Deleting Draft',
  'retailerFundedMarketingDomain.listCampaign.draft.deleteBody':
    'Are you sure you want to delete this draft: {draftName}?',
  'retailerFundedMarketingDomain.listCampaign.draft.confirmDelete': 'Delete',
  'retailerFundedMarketingDomain.listCampaign.draft.cancelDelete': 'No, keep this draft',
  'retailerFundedMarketingDomain.listCampaign.moment.infoBanner':
    '{momentNumber} Instacart spotlights available, join to feature your seasonal campaign on Instacart’s homepage.',
  'retailerFundedMarketingDomain.listCampaign.moment.viewButton': 'View spotlights',
  'retailerFundedMarketingDomain.listCampaign.marketplace.title': 'Marketplace campaigns',
  'retailerFundedMarketingDomain.listCampaign.storefrontPro.title': 'Storefront Pro campaigns',
  'retailerFundedMarketingDomain.listCampaign.create': 'Create',
  'retailerFundedMarketingDomain.performance': 'Performance',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.metrics.spend': 'Spend',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.metrics.redemptions': 'Redemptions',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.metrics.attributedSales': 'Attributed sales',
  'retailerFundedMarketingDomain.rfmCampaignDashoard.metrics.roas': 'ROI',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.title': 'Campaign details',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.campaignName': 'Campaign name',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.campaignScope':
    'Sites & banners',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.campaignStatus': 'Status',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.template': 'Template',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.start': 'Start',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.end': 'End',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.budget': 'Budget',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.spend': 'Spend',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.redemption': 'Redemption',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.attGmv': 'Attributed GMV',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.roas': 'ROI',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.avgDailySpend':
    'Avg. Daily Spend',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.applications': 'Applications',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.redemptionRate':
    'Redemption Rate',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.activations': 'Activations',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.costPerActivation':
    'Cost per activation',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.outstandingOrders':
    'Outstanding Orders',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.outstandingSpend':
    'Outstanding Spend',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.newCampaigns':
    'Newly active campaigns will take over an hour to display performance data. Thanks for your patience.',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.view': 'View',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.export': 'Export',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.cancel': 'Cancel',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.downloadFileName':
    'Campaign performance data',
  'retailerFundedMarketingDomain.createCampaignModal.title': 'Create',
  'retailerFundedMarketingDomain.createCampaignModal.newCustomers.title': 'Acquire new customers',
  'retailerFundedMarketingDomain.createCampaignModal.newCustomers.subtitle':
    'Acquire new customers by offering a discount on their first order.',
  'retailerFundedMarketingDomain.createCampaignModal.newCustomers.targetDomain': 'On Marketplace',
  'retailerFundedMarketingDomain.createCampaignModal.customCampaign.title':
    'Create a custom campaign',
  'retailerFundedMarketingDomain.createCampaignModal.customCampaign.subtitle':
    'Have it your way. Create a campaign from scratch.',
  'retailerFundedMarketingDomain.createCampaignModal.customCampaign.targetDomain':
    'On Storefront Pro',
  'retailerFundedMarketingDomain.createCampaignModal.disclaimer1': 'Additional campaigns to come',
  'retailerFundedMarketingDomain.createCampaignModal.disclaimer2':
    'We will continue to build out more campaign templates as well as custom campaign functionality. To support development, connect with your Instacart Admin to share your unique campaign use cases.',
  'retailerFundedMarketingDomain.dollarAmountDiscount.percentageLow.alert.message':
    'It is recommended that the discount is greater than 5%. This can be achieved by either increasing the dollar off basket or by decreasing the minimum purchase amount.',
  'retailerFundedMarketingDomain.dollarAmountDiscount.percentageHigh.alert.message':
    'The discount per coupon is greater than 100%. Please decrease dollar off basket or increase minimum purchase amount.',
  'retailerFundedMarketingDomain.dollarAmountDiscount.title': 'Dollar amount discount',
  'retailerFundedMarketingDomain.dollarAmountDiscount.minimumPurchaseAmount.title':
    'Minimum purchase amount ($)',
  'retailerFundedMarketingDomain.dollarAmountDiscount.minimumPurchaseAmount.requireError':
    'Minimum spend requirement must be greater than $0',
  'retailerFundedMarketingDomain.dollarAmountDiscount.discoverability.title': 'Discoverability',
  'retailerFundedMarketingDomain.dollarAmountDiscount.discoverability.description':
    'Is this discoverable in the store, or is this hidden and used for a mechandised landing page such as QR code onboarding',
  'retailerFundedMarketingDomain.dollarAmountDiscount.validForDays.title':
    'Coupon Duration (Suggested: 7 days)',
  'retailerFundedMarketingDomain.dollarAmountDiscount.validForDays.description':
    'How long the coupon will be valid for the Customer from the time of their clipping',
  'retailerFundedMarketingDomain.dollarAmountDiscount.validForDays.errorMessage':
    'Please select a number in the range of 1 and 100',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCount.title':
    'Coupon Count (Suggested: 1)',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCount.description':
    'How many shopping trips the Customer can use this coupon on',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCount.errorMessage':
    'Please select a number in the range of 1 and 10',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCountPerBasket.title':
    'Maximum coupon redemptions per basket (Suggested: 1)',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCountPerBasket.description':
    'How many coupons the Customer can use on a basket',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCountPerBasket.errorMessage':
    'Please select a number in the range of 1 and 10',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCountGlobal.title':
    'Maximum coupon redemptions in total (Suggested: 10,000)',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCountGlobal.description':
    'How many coupons in total can be redeemed',
  'retailerFundedMarketingDomain.dollarAmountDiscount.couponCountGlobal.errorMessage':
    'Please select a number in the range of 1 and 1,000,000',
  'retailerFundedMarketingDomain.dollarAmountDiscount.summary.title': 'Summary',
  'retailerFundedMarketingDomain.EditAudienceModal.customers': 'Customers',
  'retailerFundedMarketingDomain.EditAudienceModal.locations': 'Locations',
  'retailerFundedMarketingDomain.EditAudienceModal.title': 'New Customers',
  'retailerFundedMarketingDomain.EditAudienceModal.save': 'Save',
  'retailerFundedMarketingDomain.EditAudienceModal.cancel': 'Cancel',
  'retailerFundedMarketingDomain.EditAudienceModal.clearAll': 'Clear All',
  'retailerFundedMarketingDomain.EditAudienceModal.audienceTitle': 'Audience',
  'retailerFundedMarketingDomain.EditAudienceModal.subtitle':
    "Only customers who haven't ordered from you before.",
  'retailerFundedMarketingDomain.EditAudienceModal.selectLocations': 'Select Locations',
  'retailerFundedMarketingDomain.cancelCampaignModal.title': 'Confirm campaign cancellation',
  'retailerFundedMarketingDomain.cancelCampaignModal.message1': 'Are you sure you want to cancel',
  'retailerFundedMarketingDomain.cancelCampaignModal.message2':
    "You won't be able to restore this campaign.",
  'retailerFundedMarketingDomain.cancelCampaignModal.confirm': 'Confirm',
  'retailerFundedMarketingDomain.getCampaignPage.cancel': 'Cancel',
  'retailerFundedMarketingDomain.getCampaignPage.duplicateCampaign': 'Duplicate Campaign',
  'retailerFundedMarketingDomain.getCampaignPage.campaigns': 'Campaigns',
  'retailerFundedMarketingDomain.getCampaignPage.editCampaign': 'Edit Campaign',
  'retailerFundedMarketingDomain.getCampaignPage.continueEdit': 'Continue edit',
  'retailerFundedMarketingDomain.getCampaignPage.deleteDraft': 'Delete draft',
  'retailerFundedMarketingDomain.getCampaignPage.deleteDraftSuccess': 'Campaign draft deleted.',
  'retailerFundedMarketingDomain.getCampaignPage.deleteDraftFail': 'Failed to delete draft.',
  'retailerFundedMarketingDomain.getCampaignPage.loadCampaignDraftFail':
    'Failed to load campaign draft.',
  'retailerFundedMarketingDomain.getCampaignPage.searchInputPlaceholder': 'Search',
  'retailerFundedMarketingDomain.getCampaignPage.createDraftFail':
    'Failed to create campaign draft.',
  'retailerFundedMarketingDomain.getCampaignPage.createDraftSuccess':
    'Successfully created campaign draft.',
  'retailerFundedMarketingDomain.getCampaignPage.updateDraftFail':
    'Failed to update campaign draft.',
  'retailerFundedMarketingDomain.getCampaignPage.updateDraftSuccess':
    'Successfully updated campaign draft.',
  'retailerFundedMarketingDomain.getCampaignPage.crm.sendSuccess':
    'Successfully triggered email send',
  'retailerFundedMarketingDomain.getCampaignPage.crm.sendFail': 'Failed to kick off email send',
  'retailerFundedMarketingDomain.getCampaignPage.crm.cancelSuccess':
    'Successfully cancelled email send',
  'retailerFundedMarketingDomain.getCampaignPage.crm.cancelFail': 'Failed to cancel email send',
  'retailerFundedMarketingDomain.getCampaignPage.noModifyOfferPermission':
    'You do not have permission to edit this campaign. Please contact your administrator for edit access.',
  'retailerFundedMarketingDomain.getCampaignPage.noOfferPermission':
    'You do not have permission to access this campaign. Please contact your administrator for the Offers Tooling access.',
  'retailerFundedMarketingDomain.editDurationModal.review.campaign.schedule.disclaimer':
    'Please review the tagged campaign schedules after updating the moment schedule.',
  'retailerFundedMarketingDomain.editDurationModal.moment.schedule.note':
    "If your campaign is set to end after the Instacart spotlight, your campaign will continue to run but won't be featured on Marketplace once the spotlight ends.",
  'retailerFundedMarketingDomain.editDurationModal.startDateTimeError':
    'Start date and time cannot be set in past.',
  'retailerFundedMarketingDomain.editDurationModal.activeDates': 'Active dates',
  'retailerFundedMarketingDomain.editDurationModal.startDate': 'Start date',
  'retailerFundedMarketingDomain.editDurationModal.startTime': 'Start time',
  'retailerFundedMarketingDomain.editDurationModal.setEndDate': 'Set End date',
  'retailerFundedMarketingDomain.editDurationModal.endDate': 'End date',
  'retailerFundedMarketingDomain.editDurationModal.endTime': 'End time',
  'retailerFundedMarketingDomain.editDurationModal.timezone': 'Timezone',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeekSchedule.title': 'Active days',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeekSchedule.allDays':
    'All days of the week',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeekSchedule.specificDays':
    'Specific day(s) of the week',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeekSchedule.selection.error':
    'At least one day of the week must be selected',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeek.sunday': 'Sun',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeek.monday': 'Mon',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeek.tuesday': 'Tue',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeek.wednesday': 'Wed',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeek.thursday': 'Thu',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeek.friday': 'Fri',
  'retailerFundedMarketingDomain.editDurationModal.dayOfWeek.saturday': 'Sat',
  'retailerFundedMarketingDomain.editDurationModal.setEndDateMessage':
    'Setting an end date could reduce the amount its potential revenue. Consider letting it run.',
  'retailerFundedMarketingDomain.segmentation.nav': 'Segments',
  'retailerFundedMarketingDomain.marketplaceCampaigns.nav': 'Marketplace campaigns',
  'retailerFundedMarketingDomain.storefrontProCampaigns.nav': 'Storefront Pro campaigns',
  'retailerFundedMarketingDomain.campaigns.phaseFour.nav': 'Campaigns & offers',
  'retailerFundedMarketingDomain.billingOption.title': 'Adjust billing',
  'retailerFundedMarketingDomain.billingOption.fullyInstacartFunded': 'Fully Instacart funded',
  'retailerFundedMarketingDomain.billingOption.fullyInstacartFunded.description':
    '100% funded by Instacart',
  'retailerFundedMarketingDomain.billingOption.fullyRetailerFunded': 'Fully retailer funded',
  'retailerFundedMarketingDomain.billingOption.fullyRetailerFunded.description':
    '100% funded by retailer/partner',
  'retailerFundedMarketingDomain.billingOption.fiftyfifty': '50/50 funded',
  'retailerFundedMarketingDomain.billingOption.fiftyfifty.description':
    '50% funded by retailer/partner / 50% funded by Instacart',
  'retailerFundedMarketingDomain.billingOption.partialRetailerFunded': 'Retailer funds',
  'retailerFundedMarketingDomain.billingOption.partialInstacartFunded': 'Instacart funds',
  'retailerFundedMarketingDomain.billingOption.retailerFundedNewCustomer':
    'Retailer funded - New customers only',
  'retailerFundedMarketingDomain.billingOption.retailerFundedNewCustomer.description':
    'Only new customers are funded by retailer/partner',
  'retailerFundedMarketingDomain.billingOption.retailerFundedExistingCustomer':
    'Retailer funded - Existing customers only',
  'retailerFundedMarketingDomain.billingOption.retailerFundedExistingCustomer.description':
    'Only existing customers are funded by retailer/partner',
  'retailerFundedMarketingDomain.edit.saveButton': 'Save',
  'retailerFundedMarketingDomain.campaignDetailsField.required.error': 'This field is required',
  'retailerFundedMarketingDomain.dollarAmountDiscount.helperContent.alert.title':
    'Not sure how much to discount?',
  'retailerFundedMarketingDomain.dollarAmountDiscount.helperContent.alert.message':
    '5-30% off is the sweet spot for attracting wonderfully hungry customers.',
  'retailerFundedMarketingDomain.couponDuration.info':
    'Please set a coupon duration that ends at least 1 day before the campaign end date.',
  'retailerFundedMarketingDomain.couponDuration.error':
    'Coupon duration must end at least 1 days before the campaign end date. It cannot exceed {maxDays} days',
  'retailerFundedMarketingDomain.campaignDetailsField.viewButton': 'View',
  'retailerFundedMarketingDomain.campaignDetailsField.locations': 'Locations',
  'retailerFundedMarketingDomain.campaignDetailsField.all.locations': 'All locations',
  'retailerFundedMarketingDomain.campaignDetailsField.no.locations': 'No Locations',
  'retailerFundedMarketingDomain.campaignDetailsField.new.customers': 'New Customers',
  'campaigns.calendar.campaignsCalendar': 'Campaigns Calendar',
  'campaigns.calendar.close': 'Close',
  'campaigns.templates.breadcrumb.create': 'Create',
  'campaigns.templates.title': 'Campaign Templates',
  'campaigns.templates.pageHeader': 'Grow your business with our campaign templates',
  'campaigns.templates.acquireNewCustomers.title': 'Acquire new customers',
  'campaigns.templates.acquireNewCustomers.description':
    'Acquire new customers by offering a discount.',
  'campaigns.templates.acquisition.title': 'Acquire customers',
  'campaigns.templates.acquisition.description':
    'Create an offer to attract new customers or win back those who have lapsed.',
  'campaigns.templates.growBasketSizes.title': 'Grow basket sizes',
  'campaigns.templates.growBasketSizes.description':
    'Grow basket sizes for a collection by offering a discount.',
  'campaigns.templates.custom.title': 'ICMS campaigns',
  'campaigns.templates.custom.description':
    'Create a campaign on Instacart marketplace from scratch.',
  'campaigns.templates.driveAwareness.title': 'Drive awareness',
  'campaigns.templates.driveAwareness.description':
    'Boost your storefront marketing with banner creatives',
  'campaigns.templates.winBackLapsedCustomers.title': 'Win back lapsed customers',
  'campaigns.templates.winBackLapsedCustomers.description':
    'Win back lapsed customers by offering a discount.',
  'campaigns.templates.driveLoyaltyRetention.title': 'Drive loyalty retention',
  'campaigns.templates.driveLoyaltyRetention.description':
    'Increase loyalty sign-ups by offering a discount.',
  'campaigns.templates.offer.title': 'Offer discount',
  'campaigns.templates.offer.description': 'Build an offer to incentivize customers to purchase.',
  'campaigns.templates.email.title': 'Engage with email or push',
  'campaigns.templates.email.description':
    'Incentivize customers through email and push notifications.',
  'campaigns.templates.offlineCrm.title': '(Offline CRM)',
  'campaigns.templates.offlineCrm.description':
    'Incentivize customers through email and push notifications (Offline CRM).',
  'campaigns.templates.lifecycle.title': 'Lifecycle',
  'campaigns.templates.lifecycle.description': 'Incentivize customers through lifecycle marketing.',
  'campaigns.templates.internalOnly.title': 'Only visible to Instacart employees',
  'campaigns.templates.searchPageMarketing.segmentName': 'Search Term Segment',
  'campaigns.templates.searchPageMarketing.notifySegmentCreationError': 'Segment creation failed',
  'campaigns.templates.searchPageMarketing.educationCard.title': 'Set your targeting',
  'campaigns.templates.searchPageMarketing.educationCard.description':
    'Choose the segment that fits your campaign objective.',
  'campaigns.templates.searchPageMarketing.description':
    "Get in front of customers as they're searching in real time.",
  'campaigns.templates.campaignMoment.title': 'Retailer campaign moment',
  'campaigns.templates.custom.campaign.title': 'Instacart created campaigns',
  'campaigns.templates.custom.campaign.description':
    'Instacart created this campaign, you can view detail but can’t edit anything',
  'campaigns.templates.moment.cardTitle.origin': "Join Instacart's spotlight: {momentName}",
  'campaigns.templates.moment.cardTitle.after': 'Spotlight {momentName}',
  'campaigns.templates.moment.cardDescription.origin':
    'Opt in to see your seasonal campaign on our homepage.',
  'campaigns.templates.moment.cardDescription.after': 'Chosen campaign: {campaignName}',
  'campaigns.templates.moment.formTitle': "Campaigns for Instacart's {momentName} spotlight",
  'campaigns.templates.moment.cardBanner.origin':
    'Get in early - submissions end {submissionDeadline}.',
  'campaigns.templates.moment.cardBanner.after': 'Spotlight date: {startDate} - {endDate}',
  'campaigns.templates.moment.infoBanner.create':
    'This campaign will be promoted in Instacart’s “{momentName}” feature if submitted before {submissionDeadline}.',
  'campaigns.templates.moment.infoBanner.edit':
    'This campaign will be featured in Instacart’s “{momentName}” feature between {startDate} - {endDate} or your campaign end date (whichever is earlier)',
  'campaigns.templates.searchPageMarketing.title': 'Search Page Marketing',
  'campaigns.templates.moment.infoBanner.learnMore': 'Learn More',
  'campaigns.templates.moment.modal.title.preCreate': 'How does it work',
  'campaigns.templates.moment.modal.title': "What's next?",
  'campaigns.templates.moment.modal.banner.title': "Get featured on Instacart's homepage",
  'campaigns.templates.moment.modal.feature.chooseCampaign.title': 'Choose campaign',
  'campaigns.templates.moment.modal.feature.chooseCampaign.description':
    'Choose or create a campaign relevant to the featured moment',
  'campaigns.templates.moment.modal.feature.getFeatured.title': 'Get featured',
  'campaigns.templates.moment.modal.feature.getFeatured.description':
    'Get highlighted on the Instacart’s homepage during the featured date',
  'campaigns.templates.moment.modal.feature.engageCustomers.title': 'Engage customers',
  'campaigns.templates.moment.modal.feature.engageCustomers.description':
    'Be notified when the feature is live and monitor your progress',
  'campaigns.templates.moment.modal.FAQ.title': 'Frequently asked questions',
  'campaigns.templates.moment.modal.FAQ.Q1.title.create':
    'Which campaigns should I submit to be featured?',
  'campaigns.templates.moment.modal.FAQ.Q1.answer.create':
    'Submit any campaign that you want tied to a seasonal or special moment, such as Valentine’s Day, the Superbowl, cold and flu season, etc. Featured campaigns will appear on the Instacart homepage for the duration of the seasonal or special moment.',
  'campaigns.templates.moment.modal.FAQ.Q1.title.view':
    'How do I know when my campaign is being featured?',
  'campaigns.templates.moment.modal.FAQ.Q1.answer.view':
    'Once you’ve opted-in to the seasonal or special moment, you can see all the campaigns that’ll be featured in it.',
  'campaigns.templates.moment.modal.FAQ.Q2.title': 'What does “getting featured” mean?',
  'campaigns.templates.moment.modal.FAQ.Q2.answer':
    'It means that your chosen campaign(s) will be highlighted on the Instacart homepage in a special  area. It will appear alongside other relevant campaigns.',
  'campaigns.templates.moment.modal.FAQ.Q3.title.create': 'Do all campaigns get featured?',
  'campaigns.templates.moment.modal.FAQ.Q3.answer.create':
    ' No. You must opt-in to a specific moment to get your campaign featured.',
  'campaigns.templates.moment.modal.FAQ.Q3.title.view':
    'I opted into the moment but I don’t see my campaign being featured.',
  'campaigns.templates.moment.modal.FAQ.Q3.answer.view':
    'Please reach out to your Account Manager for help.',
  'campaigns.templates.moment.modal.FAQ.Q4.title':
    'Will my campaign appear alongside campaigns from other retailers?',
  'campaigns.templates.moment.modal.FAQ.Q4.answer':
    'Yes. Other retailers may have featured campaigns that are also relevant to the seasonal or special moment. These campaigns may appear in the same area of the homepage as yours.',
  'campaigns.templates.moment.modal.footer.button': 'Create a new campaign',
  'campaigns.templates.moment.modal.footer.info1':
    'Have an existing campaign that you want to feature instead?',
  'campaigns.templates.moment.modal.footer.info2': 'Contact your Instacart partner.',
  'campaigns.templates.moment.unfeatureModal.title': 'You have a campaign being featured',
  'campaigns.templates.moment.unfeatureModal.subtitle': 'Featured campaign',
  'campaigns.templates.moment.unfeatureModal.product.amountOff': '${discount} off ${total}',
  'campaigns.templates.moment.unfeatureModal.product.percentageOff':
    '{discount}% off ${total} up to ${max}',
  'campaigns.templates.moment.unfeatureModal.info':
    'You have a campaign that is being featured for “{momentName}” already. To feature a different campaign, you have to remove the current campaign and create a new one.',
  'campaigns.templates.moment.unfeatureModal.button.view': 'View',
  'campaigns.templates.moment.unfeatureModal.unfeature': 'Unfeature campaign',
  'campaigns.templates.moment.unfeatureModal.confirmation.title': 'Are you sure?',
  'campaigns.templates.moment.unfeatureModal.confirmation.details':
    'When you remove this campaign from being featured, it will not be included on the Instacart homepage seasonal moment. You can choose a different campaign to be included. ',
  'campaigns.templates.moment.unfeatureModal.checkbox.label':
    'Keep campaign active on my storefront',
  'campaigns.templates.moment.unfeatureModal.checkbox.info':
    'If checked, your campaign will run on your storefront on the Instacart marketplace even though it won’t be included in the additional marketplace placements. ',
  'campaigns.templates.moment.unfeatureModal.button.cancel': 'Cancel',
  'retailerFundedMarketingDomain.errorMessage.moment.errorMessage':
    'Your request could not be completed. Moment {errorMessage}.',
  'retailerFundedMarketingDomain.campaignHeader.objective.label': 'Objective',
  'retailerFundedMarketingDomain.campaignHeader.campaignType.label': 'Campaign type',
  'retailerFundedMarketingDomain.campaignHeader.instacartFeature.label': 'Instacart feature',
  'retailerFundedMarketingDomain.campaignField.name.label': 'Name',
  'retailerFundedMarketingDomain.campaignField.name.placeholder': 'Enter a campaign name',
  'retailerFundedMarketingDomain.campaignField.required.error': 'This field is required',
  'retailerFundedMarketingDomain.campaignField.name.description':
    'This name is only visible to you, and not to customers.',
  'retailerFundedMarketingDomain.campaignField.couponCode.label': 'Coupon code',
  'retailerFundedMarketingDomain.campaignField.couponCode.placeholder':
    'Enter a unique coupon code',
  'retailerFundedMarketingDomain.campaignField.couponCode.description':
    'Customers must enter this code at checkout.',
  'retailerFundedMarketingDomain.errorMessage.genericError':
    'Your request could not be completed. Please try again.',
  'retailerFundedMarketingDomain.errorMessage.duplicateCouponCode':
    'Coupon code "{code}" already exists. Please pick a unique coupon code.',
  'retailerFundedMarketingDomain.errorMessage.notAuthorized':
    'You are not authorized to perform this action.',
  'retailerFundedMarketingDomain.campaignPlacements.title': 'What will customers see?',
  'retailerFundedMarketingDomain.campaignPlacements.description':
    'We’ll promote this campaign in pre-determined locations across marketplace.',
  'retailerFundedMarketingDomain.campaignPlacements.preview.button': 'Preview',
  'campaigns.templates.comingSoon.title': 'Coming soon',
  'campaigns.templates.comingSoon.description':
    'More campaigns to come. Share your use cases to help aid future development.',
  'retailerFundedMarketingDomain.campaignTargetingAudienceTitle.label': 'Audience',
  'retailerFundedMarketingDomain.campaignTargetingAudienceSubtitle.label':
    'This customer segment is pre-defined and cannot be changed.',
  'retailerFundedMarketingDomain.campaignTargetingLocationTitle.label': 'Regions',
  'retailerFundedMarketingDomain.campaignTargetingRegionsTitle.label': 'Regions',
  'retailerFundedMArketingDomain.campaignTargetingRegionsExpand.label': 'Show more',
  'retailerFundedMarketingDomain.campaignTargetingRegionsCollapse.label': 'Show less',
  'retailerFundedMarketingDomain.campaignTargetingLocationSubtitle.label':
    'Choose the locations you’d like to target for this campaign.',
  'retailerFundedMarketingDomain.campaignTargetingAudienceNewCustomersTitle.label': 'New customers',
  'retailerFundedMarketingDomain.campaignTargetingAudienceNewCustomersSubtitle.label':
    'Customers who haven’t ordered from you before.',
  'retailerFundedMarketingDomain.campaignTargetingAudienceAllCustomersTitle.label': 'All customers',
  'retailerFundedMarketingDomain.campaignTargetingAudienceAllCustomersDefaultText.label':
    'If no rules are defined, the default targeting audience will be all customers.',
  'retailerFundedMarketingDomain.campaignTargetingAudienceAllCustomersSubtitle.label':
    'Target all customers.',
  'retailerFundedMarketingDomain.campaignTargetingAudienceActiveCustomersTitle.label':
    'Active customers',
  'retailerFundedMarketingDomain.campaignTargetingAudienceExistingCustomersTitle.label':
    'Existing customers',
  'retailerFundedMarketingDomain.campaignTargetingAudienceExistingCustomersSubtitle.label':
    'Customers who have ordered from you in the past {lookbackWindowDaysInput} {lookbackWindowDays, plural, =1 {day} other {days}}.',
  'retailerFundedMarketingDomain.campaignTargetingAudienceLapsedCustomersTitle.label':
    'Lapsed customers',
  'retailerFundedMarketingDomain.campaignTargetingAudienceLapsedCustomersSubtitle.label':
    'Re-engage customers who haven’t purchased in {lookbackWindowDaysInput} {lookbackWindowDays, plural, =1 {day} other {days}}.',
  'retailerFundedMarketingDomain.campaignTargeting.title': 'Targeting',
  'retailerFundedMarketingDomain.campaignTargeting.subtitle': 'Choose your targeting rules.',
  'retailerFundedMarketingDomain.campaignTargeting.audienceTitle': 'Targeting',
  'retailerFundedMarketingDomain.campaignTargeting.audienceDescription':
    'What is the audience of this campaign?',
  'retailerFundedMarketingDomain.campaignTargeting.customer.addRuleSet': 'Add ruleset',
  'retailerFundedMarketingDomain.campaignTargeting.customer.addPredefinedSegment':
    'Add predefined segment',
  'retailerFundedMarketingDomain.campaignTargeting.customer.title': 'Audience',
  'retailerFundedMarketingDomain.campaignTargeting.customer.subtitle':
    'Criteria selected automatically based on your campaign objective',
  'retailerFundedMarketingDomain.campaignTargeting.customer.subtitle.multiConjunction':
    'Define your audience by using rules to create segments',
  'retailerFUndedMarketingDomain.campaignTargeting.customer.targeting criteria':
    'Targeting criteria',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.title':
    'Target Criteria',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.allCustomersLabel':
    'All customers',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.userLifeCycleLabel':
    'Lifecycle Segments',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.predefinedSegmentsLabel':
    'Predefined Segments',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.loyaltyStatusLabel':
    'By Loyalty Status',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.defineCustomSegmentsLabel':
    'Define a segment',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.loyaltyStatusRule':
    'are',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.loyaltyStatusRule.specificRetailer':
    'at {retailerName}',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.loyaltyStatusRule.retailerAgnostic':
    'at any retailers',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.loyaltyStatusDropdown.loyaltyLabel':
    'Loyalty members',
  'retailerFundedMarketingDomain.campaignTargeting.customer.customerDropdown.loyaltyStatusDropdown.nonLoyaltyLabel':
    'Non-loyalty members',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.ruleHeading':
    'Match customers who',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.lookbackWindowDaysError':
    'Lookback window days must be between {minDays} and {maxDays}',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.newCustomersRule':
    "haven't placed an order before",
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.churnedCustomersRule':
    "haven't placed an order in",
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.activeCustomersRule':
    'have placed an order in the last',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.customersWho':
    'customers who',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.days': 'day(s)',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.or': 'or',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.refineSelectionButton':
    'Refine selection',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.applyRefinementsButton':
    'Apply refinements',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.cancelRefinementsButton':
    'Cancel',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.minimumLifecycleSegmentsError':
    'At least one lifecycle segment must be selected',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.newCustomersOptionLabel':
    'New customers',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.newCustomersOptionDescription':
    "Customers who haven't placed an order before",
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.churnedCustomersOptionLabel':
    'Lapsed customers',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.churnedCustomersOptionDescription':
    "Customers who haven't placed an order in X days",
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.activeCustomersOptionLabel':
    'Active customers',
  'retailerFundedMarketingDomain.campaignTargeting.customer.userLifecycle.activeCustomersOptionDescription':
    'Customers who have placed an order in the last X days',
  'retailerFundedMarketingDomain.campaignTargeting.location.title': 'Browsing context',
  'retailerFundedMarketingDomain.campaignTargeting.location.subtitle':
    'Describe the locations where this is available.',
  'retailerFundedMarketingDomain.campaignTargeting.location.locationDropdown.title':
    'Target Criteria',
  'retailerFundedMarketingDomain.campaignTargeting.location.locationDropdown.everywhereLabel':
    'Everywhere',
  'retailerFundedMarketingDomain.campaignTargeting.location.locationDropdown.specificRegionLabel':
    'Select regions',
  'retailerFUndedMarketingDomain.campaignTargeting.location.specificRegionDropdown.title':
    'Match regions',
  'retailerFUndedMarketingDomain.campaignTargeting.location.specificRegionDropdown.selectRegion':
    'Regions',
  'retailerFundedMarketingDomain.campaignTargeting.predefinedSegments.title':
    'Select predefined segment',
  'retailerFundedMarketingDomain.campaignTargeting.predefinedSegments.placeholder':
    'Search for predefined segment by name',
  'retailerFundedMarketingDomain.campaignTargeting.predefinedSegments.lastModified':
    'Last modified',
  'retailerFundedMarketingDomain.campaignTargeting.defineASegment.NoRulesDefined':
    'No rules have been defined',
  'retailerFundedMarketingDomain.campaignTargetField.placeholder':
    'Enter or paste your keywords, one word or phrase per line, or separated by commas',
  'retailerFundedMarketingDomain.campaignTargetField.addKeywordsButton': 'Add keywords',
  'retailerFundedMarketingDomain.campaignTargetField.keywordLimit': 'Limit 20 keywords',
  'retailerFundedMarketingDomain.campaignTargetField.placeholder1':
    'Target will match on the keywords or phrases as entered ',
  'retailerFundedMarketingDomain.campaignTargetField.placeholder2': 'exactly ',
  'retailerFundedMarketingDomain.campaignTargetField.placeholder3':
    '(it is case sensitive, and does not include misspellings or synonyms).',
  'retailerFundedMarketingDomain.campaignSearchTargeting.subtitle':
    'Configure search keywords for this campaign',
  'retailerFundedMarketingDomain.campaignSearchTargeting.required.error':
    'Search Keywords are required.',
  'retailerFundedMarketingDomain.campaignTargetField.saveButton': 'Save',
  'retailerFundedMarketingDomain.campaignTargetField.clearAllButton': 'Clear All',
  'retailerFundedMarketingDomain.campaignTargetField.keywordLimitError':
    'These keywords were not added due to keyword limit. To add these keywords, delete some from the list below.',
  'retailerFundedMarketingDomain.campaignTargetField.showMoreButton': 'Show more...',
  'retailerFundedMarketingDomain.campaignTargetField.showLessButton': 'Show less...',
  'retailerFundedMarketingDomain.campaignTargetField.removeAllButton':
    'Remove all keywords ({count})',
  'retailerFundedMarketingDomain.campaignTargetField.editButton': 'Edit',
  'retailerFundedMarketingDomain.campaignTargetField.keywordLimitTooltip':
    'Keyword limit reached. Remove existing keywords to add new ones.',
  'retailerFundedMarketingDomain.campaignTargeting.allCustomers': 'All customers',
  'retailerFundedMarketingDomain.campaignTargeting.predefinedSegments': 'Predefined segments',
  'retailerFundedMarketingDomain.campaignTargeting.search.placeholder':
    'Search for predefined segments by name',
  'retailerFundedMarketingDomain.campaignTargeting.search.noResult': 'No Segments Found',
  'retailerFundedMarketingDomain.campaignTargeting.search.segmentName': 'Segment Name',
  'retailerFundedMarketingDomain.campaignTargeting.search.segmentSize': 'Segment Size',
  'retailerFundedMarketingDomain.campaignTargeting.segment.size.unknown': 'Unknown',
  'retailerFundedMarketingDomain.campaignTargeting.segment.size.estimation':
    'Estimated Segment Size:',
  'retailerFundedMarketingDomain.campaignTargeting.segment.size.getEstimate': 'Get Estimate',
  'retailerFundedMarketingDomain.campaignTargeting.segment.size.estimateErrorMessage':
    'Estimate unavailable, please try again.',
  'retailerFundedMarketingDomain.campaignTargeting.search.lastModified': 'Last modified',
  'retailerFundedMarketingDomain.campaignTargeting.createSegmentLink': 'Create a new segment',
  'retailerFundedMarketingDomain.campaignTargeting.builder.title':
    'Customers who match all of the rules below',
  'retailerFundedMarketingDomain.campaignTargeting.builder.title.multiConjunction':
    'Audience Segment {number}',
  'retailerFundedMarketingDomain.campaignTargeting.builder.multiConjunction.or': 'OR',
  'retailerFundedMarketingDomain.campaignTargeting.builder.emptyRules':
    'No rules have been defined',
  'retailerFundedMarketingDomain.campaignTargeting.builder.emptyRulesError':
    'At least one rule must be defined',
  'retailerFundedMarketingDomain.campaignTargeting.builder.chipError': 'Failed to load rule',
  'retailerFundedMarketingDomain.campaignTargeting.builder.formError':
    'Required fields not filled.',
  'retailerFundedMarketingDomain.campaignTargeting.builder.unknown': 'Other',
  'retailerFundedMarketingDomain.campaignTargeting.builder.orders': 'Orders',
  'retailerFundedMarketingDomain.campaignTargeting.builder.icOrders': 'Instacart orders',
  'retailerFundedMarketingDomain.campaignTargeting.builder.account': 'Account',
  'retailerFundedMarketingDomain.campaignTargeting.builder.locations': 'Locations',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.searchPlaceholder':
    'Search for a rule',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.searchEmpty':
    'No results',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.groupScopeAll': 'All',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.addRuleLabel':
    'Add rule',
  'retailerFUndedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.applyRulesLabel': 'Save',
  'retailerFUndedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.cancelChangesLabel':
    'Cancel',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.icOrdersGroupLabel':
    'Instacart Orders',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.ordersGroupLabel':
    'Orders',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.accountGroupLabel':
    'Account',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.locationsGroupLabel':
    'Locations',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.unknownGroupLabel':
    'Other',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.userOrderCountLabel':
    'Number of orders',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.lastOrderDateTimeUtcLabel':
    'Days since last order',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.loyaltyStatusLabel':
    'Loyalty membership',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.userEmailLabel':
    'Email id',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.storeGroupSegmentLabel':
    'Store id',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.storeGroupSegmentNotice':
    'To ensure the targeting rule applies to the selected store groups, the browsing context region was automatically set to {value}.',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.countryIdLabel':
    'Country id',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.unsupportedLabel':
    'Unsupported',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.timeSinceLastOrderCompletion':
    'Time since last order completion',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.daysSinceFirstOrder':
    'Days since first order',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.daysSinceLastPickup':
    'Days since last pickup',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.icFamilyAccountEnabled':
    'IC+ Family Account Enabled',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.icMember': 'IC+ Member',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.icMembershipRole':
    'IC+ Membership role',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.isLoggedIn':
    'Is logged in?',
  'retailerFundedMarketingDomain.campaignTargeting.builder.ruleTypeChooser.deviceType':
    'User device type',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderCount.placed': 'Placed',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderCount.orders': 'order(s)',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderCount.exactly': 'exactly',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderCount.greater': 'greater than',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderCount.less': 'less than',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderCount.unknown': 'Unknown',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderSince.have': 'Have',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderSince.haveNot': 'Have not',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderSince.placed':
    'placed an order in the last',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.orderSince.days': 'days',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.lastOrderDate.after': 'after',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.lastOrderDate.before': 'before',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.lastOrderDate.on': 'on',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.emailCsv.emailId': 'Has email id',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.emailCsv.emailId.placeholder':
    'Select email id CSV',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.loyalty.are': 'Are',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.loyalty.loyaltyMembers':
    'loyalty members',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.loyalty.nonLoyaltyMembers':
    'non loyalty members',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.storeGroup.inStore': 'In store',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.storeGroup.placeholder':
    'Select a store group',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.country.inCountry': 'Country',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.country.placeholder':
    'Select country',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.boolean.true': 'true',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.boolean.false': 'false',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.operator.notSet': 'NOT SET',
  'retailerFundedMarketingDomain.campaignTargeting.builder.rule.unknownRule': 'UNKNOWN',
  'retailerFundedMarketingDomain.campaignTargeting.builder.stringlist.placeholder':
    'Enter a comma separated list',
  'retailerFundedMarketingDomain.campaignTargeting.builder.multipleRules.warning':
    'To add multiple rules of the same type, create a new audience segment.',
  'retailerFundedMarketingDomain.campaignTargeting.summary.items.userOrderCount':
    'Placed {operator} {number} {number, plural, =1 {order} other {orders}}',
  'retailerFundedMarketingDomain.campaignTargeting.summary.items.userEmail': 'Have email id {name}',
  'retailerFundedMarketingDomain.campaignTargeting.summary.items.loyaltyStatus':
    'Are {negated, select, true {non loyalty} other {loyalty}} members',
  'retailerFundedMarketingDomain.campaignTargeting.summary.items.storeGroup': 'In store {name}',
  'retailerFundedMarketingDomain.campaignTargeting.summary.items.lastOrderSince':
    '{negated, select, true {Have not} other {Have}} placed an order in the last {days} {days, plural, =1 {day} other {days}}',
  'retailerFundedMarketingDomain.campaignTargeting.summary.items.countryId': 'Country {countries}',
  'retailerFundedMarketingDomain.campaignTargeting.summary.items.unsupported': 'Unsupported',
  'retailerFundedMarketingDomain.campaignTargeting.summary.group.unknown': 'Other:',
  'retailerFundedMarketingDomain.campaignTargeting.summary.group.account': 'Account:',
  'retailerFundedMarketingDomain.campaignTargeting.summary.group.orders': 'Orders:',
  'retailerFundedMarketingDomain.campaignTargeting.summary.group.icOrders': 'Instacart orders:',
  'retailerFundedMarketingDomain.campaignTargeting.summary.group.locations': 'Locations:',
  'retailerFundedMarketingDomain.campaignTargeting.summary.expand.label': 'Show more',
  'retailerFundedMarketingDomain.campaignTargeting.summary.collapse.label': 'Show less',
  'retailerFundedMarketingDomain.redemptionTermsTitle.label': 'Redemption terms',
  'retailerFundedMarketingDomain.redemptionTermsDescription.label':
    'Associated promotion redemptions will stop 7 days before the end date you’ve set.',
  'retailerFundedMarketingDomain.redemptionTermsDescription.label2':
    'Associated promotion redemptions will stop {offerDurationInDays, plural, =1 {# day} other {# days}} before the end date you’ve set.',
  'retailerFundedMarketingDomain.billingOption.custom': 'Custom funding split',
  'retailerFundedMarketingDomain.billingOption.custom.description':
    'Create specific funding percentages',
  'retailerFundedMarketingDomain.billingOption.instacartFundedPercentageTitle':
    'Instacart will fund (%)',
  'retailerFundedMarketingDomain.billingOption.retailerFundedPercentageTitle':
    'Retailer will fund (%)',
  'retailerFundedMarketingDomain.create.nextButton': 'Next',
  'retailerFundedMarketingDomain.create.reviewTitle': 'Let’s review',
  'retailerFundedMarketingDomain.create.reviewDescription':
    'Almost there! Take one last look before activating your campaign.',
  'retailerFundedMarketingDomain.create.reviewSaveDescription':
    'Almost there! Take one last look before saving your campaign.',
  'retailerFundedMarketingDomain.create.editButton': 'Edit',
  'retailerFundedMarketingDomain.create.saveDraft': 'Save as draft',
  'retailerFundedMarketingDomain.create.editDraft': 'Update draft',
  'retailerFundedMarketingDomain.create.locations': 'locations',
  'retailerFundedMarketingDomain.create.all.locations': 'all locations',
  'retailerFundedMarketingDomain.create.no.locations': 'no locations',
  'retailerFundedMarketingDomain.create.schedule.retailer-opt-in': 'Retailer opt-in deadline',
  'retailerFundedMarketingDomain.create.schedule': 'Schedule',
  'retailerFundedMarketingDomain.create.schedule.retailer-opt-in.description':
    'Applications after this end date will no longer be accepted. It is automatically set to end 1 business days before the start of the seasonal moment.',
  'retailerFundedMarketingDomain.create.schedule.retailer-opt-in.end.date': 'Application end date',
  'retailerFundedMarketingDomain.create.schedule.retailer-opt-in.endTime': 'End time',
  'retailerFundedMarketingDomain.create.schedule.retailer-opt-in.summary':
    'Ends {endDate} at {endTime} {timezoneAbbreviation}',
  'retailerFundedMarketingDomain.create.schedule.retailer-opt-in.promotion':
    'Tip: Encourage account managers to pitch participation to their retail partners.',
  'retailerFundedMarketingDomain.create.budgetAndSchedule': 'Budget & Schedule',
  'retailerFundedMarketingDomain.create.noEditWarning':
    'Please review the campaign details before publishing, you cannot edit this campaign after it’s published.',
  'retailerFundedMarketingDomain.getCampaignPage.deactivate': 'Deactivate campaign',
  'retailerFundedMarketingDomain.preview.header': 'What will customers see?',
  'retailerFundedMarketingDomain.preview.subtitle':
    'We’ll promote this campaign in pre-determined locations across marketplace.',
  'retailerFundedMarketingDomain.preview.marketplace.title': 'Instacart marketplace home',
  'retailerFundedMarketingDomain.preview.marketplace.description':
    'We’ll highlight the promotion next to your brand name.',
  'retailerFundedMarketingDomain.preview.storefront.title': 'Your brand’s page home',
  'retailerFundedMarketingDomain.preview.storefront.description':
    'We’ll display a banner across your brand’s entire storefront.',
  'retailerFundedMarketingDomain.preview.disclaimer':
    'Previews are for illustrative purposes only and are subject to change.',
  'retailerFundedMarketingDomain.preview.moment.infoBanner':
    'As a part of the seasonal feature, your campaign will be promoted across these additional placements',
  'retailerFundedMarketingDomain.preview.moment.campaignFeature.title':
    'Instacart campaign feature',
  'retailerFundedMarketingDomain.preview.moment.campaignFeature.description':
    'We’ll feature your campaign in a special seasonal moment on the Marketplace homepage.',
  'retailerFundedMarketingDomain.preview.moment.notify.title': 'Instacart campaign email and push',
  'retailerFundedMarketingDomain.preview.moment.notify.description':
    'We’ll include your brand in a special email and push notification promoting the seasonal moment.',
  'retailerFundedMarketingDomain.activatedSuccess.campaignMoment.title':
    'Your campaign moment has been activated!',
  'retailerFundedMarketingDomain.activatedSuccess.campaignMoment.description':
    'Retailers will see a special campaign tile in their campaign tool to opt-in to this moment. The deadline to participate is {submissionDeadline}. You can also manually curate retailer campaigns to showcase them.',
  'retailerFundedMarketingDomain.activatedSuccess.customCampaign.description':
    'Your campaign is scheduled to be active on {campaignStartDate}',
  'retailerFundedMarketingDomain.activatedSuccess.goToCampaignMoment': 'Go to Global Campaigns',
  'retailerFundedMarketingDomain.activatedSuccess.header': 'Activated',
  'retailerFundedMarketingDomain.activatedSuccess.title': 'Your campaign has been activated!',
  'retailerFundedMarketingDomain.activatedSuccess.description':
    'Great Job! Now you can monitor its performance, and make adjustments accordingly. Let’s try another!',
  'retailerFundedMarketingDomain.activatedSuccess.goToCampaigns': 'Go to campaigns',
  'retailerFundedMarketingDomain.activatedSuccess.shareYourFeedback': 'Share your feedback',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.view.phase4': 'View (Phase 4)',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.createDate': 'Created on',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.objective': 'Campaign Objective',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.fundingDetails': 'Funding',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.header.offerSummary': 'Offer',
  'retailerFundedMarketingDomain.homeBanner.endingSoonMessage':
    '{numberOfEndingSoonCampaigns, plural, =0 {# campaign is} =1 {# campaign is} other {# campaigns are}} ending in 7 days. Extend or update the {numberOfEndingSoonCampaigns, plural, =0 {campaign} =1 {campaign} other {campaigns}} for higher return spend.',
  'retailerFundedMarketingDomain.homeBanner.bannerMessage':
    'You are running {numberOfActiveCampaigns, plural, =1 {# active campaign} other {# active campaigns}} with {numberOfEndingSoonCampaigns, plural, =1 {# campaign} other {# campaigns}} ending soon',
  'retailerFundedMarketingDomain.addCollections': 'Add collections',
  'retailerFundedMarketingDomain.createCollection': 'Create a new collection',
  'retailerFundedMarketingDomain.products': 'Products',
  'retailerFundedMarketingDomain.collection': 'Collection',
  'retailerFundedMarketingDomain.collections': 'Collections',
  'retailerFundedMarketingDomain.allProduct': 'All',
  'retailerFundedMarketingDomain.addedCollections': 'Added collections',
  'retailerFundedMarketingDomain.collection.previewButton': 'Preview',
  'retailerFundedMarketingDomain.collection.addButton': 'Add',
  'retailerFundedMarketingDomain.create.searchCollections': 'Start typing a collection name...',
  'retailerFundedMarketingDomain.offer.title': 'Offer',
  'retailerFundedMarketingDomain.offer.subtitle':
    'Incentive to encourage customers to try your products.',
  'retailerFundedMarketingDomain.offer.summary.noOffer': 'No offer',
  'retailerFundedMarketingDomain.offer.radioButton.noOffer': 'No offer',
  'retailerFundedMarketingDomain.offer.radioButton.addOffer': 'Add an offer',
  'retailerFundedMarketingDomain.promotion.title': 'Promotion',
  'retailerFundedMarketingDomain.promotion.rewards.calculation': 'Calculation',
  'retailerFundedMarketingDomain.promotion.rewards.dollarOff': 'Dollar off',
  'retailerFundedMarketingDomain.promotion.rewards.dollarOffBasket': 'Dollar off basket',
  'retailerFundedMarketingDomain.promotion.rewards.dollarOffProductsOrCollections':
    'Dollar off products or collections',
  'retailerFundedMarketingDomain.promotion.rewards.percentageOff': 'Percentage off',
  'retailerFundedMarketingDomain.promotion.rewards.percentageOffBasket': 'Percentage off basket',
  'retailerFundedMarketingDomain.promotion.rewards.percentageOffProductsOrCollections':
    'Percentage off products or collections',
  'retailerFundedMarketingDomain.promotion.rewards.amount': 'Amount',
  'retailerFundedMarketingDomain.promotion.rewards.product.setPrice': 'Set price',
  'retailerFundedMarketingDomain.promotion.rewards.product.dollarOff':
    'Dollar off products or collections',
  'retailerFundedMarketingDomain.promotion.rewards.dollarOff.invalid':
    'Amount must be greater than 0',
  'retailerFundedMarketingDomain.promotion.rewards.product.percentOff':
    'Percentage off products or collections',
  'retailerFundedMarketingDomain.promotion.rewards.percentOff.invalid':
    'Percentage must be between 0 and 100',
  'retailerFundedMarketingDomain.promotion.rewards.custom': 'Custom',
  'retailerFundedMarketingDomain.promotion.rewards.invalidCurrency':
    'Invalid currency code set for the retailer',
  'retailerFundedMarketingDomain.promotion.rewards.promotionalProduct': 'Promotional products',
  'retailerFundedMarketingDomain.promotion.rewards.maxAmount.label': 'Maximum discount',
  'retailerFundedMarketingDomain.promotion.rewards.maxAmount': 'Maximum reward amount per basket',
  'retailerFundedMarketingDomain.promotion.rewards.maxAmount.placeholder':
    'Maximum discount amount',
  'retailerFundedMarketingDomain.promotion.rewards.quantityLimit.invalid':
    'Quantity Limit must be greater than 0',
  'retailerFundedMarketingDomain.promotion.rewards.creditback.expireIn': 'Credits expire in',
  'retailerFundedMarketingDomain.promotion.rewards.creditback.days': 'Days',
  'retailerFundedMarketingDomain.promotion.rewards.creditback.creditExpiresInReward':
    'Days after credits rewarded',
  'retailerFundedMarketingDomain.promotion.rewards.creditback.creditExpiresInError': 'Invalid days',
  'retailerFundedMarketingDomain.promotion.description':
    '{discountOff} off for orders {minimumPurchaseAmount} or more{extraMessage}.',
  'retailerFundedMarketingDomain.promotion.summary.rewards.title': 'Rewards:',
  'retailerFundedMarketingDomain.promotion.summary.rewards.description':
    '{discountOff} off order{extraMessage}',
  'retailerFundedMarketingDomain.promotion.summary.rewards.collection':
    ' on collection {collection}',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.title':
    'Redemption Conditions:',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.automaticallyApplied':
    'Applied automatically',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.couponCode':
    'CouponCode: {couponCode}',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.maximumDiscount':
    'Maximum {maximumDiscount} discount',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.minimumSpend':
    'Minimum {minimumPurchaseAmount} spend',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.maxRedemptionsCustomer':
    'Maximum {maximumRedemptionsCustomer} redemptions per customer',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.maxRedemptionsBasket':
    'Maximum {maximumRedemptionsBasket} redemptions per basket',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.couponDuration':
    'Coupon duration {couponValidForDays} days',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.discoverability':
    '{discoverability} discoverability',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.validDates.start':
    'Valid from {startDate}, {startTime} {timezoneAbbreviation} {endMessage}',
  'retailerFundedMarketingDomain.promotion.summary.redemptionConditions.validDates.end':
    'to {endDate} at {endTime} {timezoneAbbreviation}',
  'retailerFundedMarketingDomain.schedule.summary.today': 'today',
  'retailerFundedMarketingDomain.schedule.summary.neverEnds': 'never ends',
  'retailerFundedMarketingDomain.schedule.summary.start':
    'Starts {startDate} at {startTime} {timezoneAbbreviation} and {endMessage}',
  'retailerFundedMarketingDomain.schedule.summary.end':
    'ends {endDate} at {endTime} {timezoneAbbreviation}',
  'retailerFundedMarketingDomain.status.draft': 'Draft',
  'retailerFundedMarketingDomain.status.beta': 'Beta',
  'retailerFundedMarketingDomain.status.active': 'Active',
  'retailerFundedMarketingDomain.status.scheduled': 'Scheduled',
  'retailerFundedMarketingDomain.status.cancelled': 'Cancelled',
  'retailerFundedMarketingDomain.status.ended': 'Ended',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaignTitle':
    'Tag campaign (admin only)',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaignTitle.admin': 'Tag campaign',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.cancel': 'Cancel',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.save': 'Save',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.selectName': 'Campaign Tag',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.placeholder':
    'Choose campaign tag',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.error':
    'There was an error',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign':
    'Tag campaign (admin only)',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.admin': 'Tag campaign',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.overlap.error':
    'The campaign schedule does not overlap with the moment schedule for {momentName}',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.overlap.generic.error':
    'One or more moment schedules do not have overlap with the campaign schedule',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.viewOnly': 'View only',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.tagCampaign.viewOnlyTooltip':
    'You do not have permission to edit this campaign. Contact your administrator to be added to the role',
  'retailerFundedMarketingDomain.status.momentTag': 'Featured in "{momentName}"',
  'retailerFundedMarketingDomain.rfmCampaignDashboard.table.viewAnalytics': 'View Analytics',
  'retailerFundedMarketingDomain.discoverabilityOption.unset': 'Unset',
  'retailerFundedMarketingDomain.discoverabilityOption.unset.description': 'Unset',
  'retailerFundedMarketingDomain.discoverabilityOption.browse': 'Browse',
  'retailerFundedMarketingDomain.discoverabilityOption.browse.description':
    'Customers can find this offer through browsing.',
  'retailerFundedMarketingDomain.discoverabilityOption.directLink': 'Direct Link',
  'retailerFundedMarketingDomain.discoverabilityOption.directLink.description':
    'Customers can only find this offer through a link.',
  'retailerFundedMarketingDomain.discoverabilityOption.couponCode': 'Coupon Code',
  'retailerFundedMarketingDomain.discoverabilityOption.couponCode.description':
    'Customers can redeem this offer by entering a coupon code.',
  'retailerFundedMarketingDomain.discoverabilityOption.crm': 'Crm',
  'retailerFundedMarketingDomain.discoverabilityOption.offline': 'Offline',
  'retailerFundedMarketingDomain.discoverabilityOption.offline.description':
    'Campaign is assigned via an offline manual flow (ML, CSV, etc.)',
  'retailerFundedMarketingDomain.promote.description': 'Share your campaign with customers.',
  'retailerFundedMarketingDomain.promote.hostPage': 'Host page',
  'retailerFundedMarketingDomain.promote.hostPageInstructions':
    'Choose the page where you’d like your placement to appear.',
  'retailerFundedMarketingDomain.promote.selectPage': 'Select a page',
  'retailerFundedMarketingDomain.promote.selectPlacement': 'Select placement and location',
  'retailerFundedMarketingDomain.promote.addCreative': 'Add storefront creative',
  'retailerFundedMarketingDomain.promote.addPlacement': 'Add a placement',
  'retailerFundedMarketingDomain.promote.untitledPlacement': 'Untitled placement',
  'retailerFundedMarketingDomain.promote.selectPlacementInstructions':
    'Choose from the available placement types and their location on the page.',
  'retailerFundedMarketingDomain.promote.cancel': 'Cancel',
  'retailerFundedMarketingDomain.promote.launchCreativeBuilder': 'Launch creative builder',
  'retailerFundedMarketingDomain.promote.waysToPromote': 'Ways to promote your campaign',
  'retailerFundedMarketingDomain.promote.selectHostPage':
    'Select a host page in order to choose an available slot to use.',
  'retailerFundedMarketingDomain.promote.noSlots':
    "You haven't set up any slots on this page. Set up a new slot using the page builder.",
  'retailerFundedMarketingDomain.promote.getSlotsError.message': 'Unable to retrieve slots.',
  'retailerFundedMarketingDomain.promote.tryAgain': 'Please try again',
  'retailerFundedMarketingDomain.promote.launchPageBuilder': 'Launch page builder',
  'utm.campaign': 'Campaign',
  'utm.campaign.description': 'Campaign',
  'utm.content': 'Content',
  'utm.content.description': 'Content',
  'utm.medium': 'Medium',
  'utm.medium.description': 'Medium',
  'utm.source': 'Source',
  'utm.source.description': 'Source',
  'utm.term': 'Term',
  'utm.term.description': 'Term',
  'retailerFundedMarketingDomain.alcoholMinimumSpend':
    'Allow alcohol items to contribute to minimum spend',
  'retailerFundedMarketingDomain.alcoholReward':
    'If checked, reward will also apply to alcohol items where legally applicable',
  'retailerFundedMarketingDomain.additionalOrderCondition': 'Additional order condition',
  'retailerFundedMarketingDomain.asCreditBack': 'As credit back',
  'retailerFundedMarketingDomain.creditBackReward':
    'Customers will be rewarded the credits after the order is completed.',
  'retailerFundedMarketingDomain.budget.title': 'Budget',
  'retailerFundedMarketingDomain.budget.placeholder': 'Lifetime budget',
  'retailerFundedMarketingDomain.budget.summary.label': 'Lifetime budget: ',
  'retailerFundedMarketingDomain.budget.summary.placeholder': 'No budget set',
  'retailerFundedMarketingDomain.budget.summary.startDate.label': 'Start date: ',
  'retailerFundedMarketingDomain.budget.summary.endDate.label': 'End date: ',
  'retailerFundedMarketingDomain.budget.summary.at': 'at',
  'retailerFundedMarketingDomain.budget.managementInfo':
    'For reporting purposes only. You are advised to monitor spending, and to pause/stop campaign to stay within budget. Auto budget management coming soon.',
  'retailerFundedMarketingDomain.searchPromoteModule.title': 'Promote',
  'retailerFundedMarketingDomain.searchPromoteModule.description':
    'Share your campaign with customers.',
  'retailerFundedMarketingDomain.searchPromoteModule.required.error':
    'Search page creative is required.',
  'retailerFundedMarketingDomain.searchPromoteModule.creative.title': 'Search page creative',
  'retailerFundedMarketingDomain.searchPromoteModule.creative.untitled': 'Untitled',
  'retailerFundedMarketingDomain.searchPromoteModule.creative.onSearchResults':
    '{slotType} on search results',
  'retailerFundedMarketingDomain.searchPromoteModule.creative.edit': 'Edit',
  'retailerFundedMarketingDomain.searchPromote.cancel': 'Cancel',
  'retailerFundedMarketingDomain.searchPromote.save': 'Save',
  'retailerFundedMarketingDomain.searchPromote.added': 'Added',
  'retailerFundedMarketingDomain.promoteModule.title': 'Promote',
  'retailerFundedMarketingDomain.promoteModule.empty.title': "You haven't promoted anything yet",
  'retailerFundedMarketingDomain.promoteModule.empty.description':
    'Choose from the options above that match your objective',
  'retailerFundedMarketingDomain.promoteModule.description': 'Share your campaign with customers.',
  'retailerFundedMarketingDomain.promoteModule.storeType.title': 'Store Type',
  'retailerFundedMarketingDomain.promoteModule.storeType.30min': '30min',
  'retailerFundedMarketingDomain.promoteModule.storeType.1hr': '1hr',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.description':
    'We’ll promote this campaign in pre-determined locations across marketplace.',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.info':
    'Save the offer configuration. The promotion options will change depending on the offer you configure.',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.addPlacement': 'Add in-app placement',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.modal.header':
    'Promote on Instacart Home',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.modal.slotDescription':
    'One slot is available to use to feature your campaign on Instacart Home.',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.modal.slotHeader': 'Instacart Home',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.modal.slotName':
    'Universal value carousel',
  'retailerFundedMarketingDomain.customCampaignPromoteCreative.homepage': 'Homepage',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.modal.creativeBuilderButton':
    'Configure creative',
  'retailerFundedMarketingDomain.customCampaignPromoteModule.placementBuilder.header':
    'Configure creative for UVC',
  'retailerFundedMarketingDomain.promote.deletePlacement.tooltip':
    'To delete the placement, you have to deactivate the campaign.',
  'retailerFundedMarketingDomain.promote.slotName': 'Slot name:',
  'retailerFundedMarketingDomain.promote.creativeName': 'Creative name:',
  'retailerFundedMarketingDomain.promote.title': 'Title:',
  'retailerFundedMarketingDomain.promote.destination': 'Destination:',
  'retailerFundedMarketingDomain.promote.viewFullDetail': 'View full detail',
  'retailerFundedMarketingDomain.promote.hideFullDetail': 'Hide full detail',
  'retailerFundedMarketingDomain.promote.collection': 'Collection:',
  'retailerFundedMarketingDomain.promote.slotOccupied': 'This slot is occupied',
  'retailerFundedMarketingDomain.promote.noSlotsAvailable':
    'There are no slots available for this page. Please select another page.',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.title':
    'Choose where to feature your campaign',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.edit': 'Edit',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.email': 'Email',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.emailButton': 'Select an Email',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.notification': 'Push notification',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.canvas': 'Canvas',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.onPage': 'On-Page Creative',
  'retailerFundedMarketingDomain.promoteModule.surfaceSelection.added': 'Added',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.title.actionable': 'Add an Email',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.title.static': 'Email Preview',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.title.push.static': 'Email Preview',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.subtitle': 'Simple Setup',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.description':
    "Your content is all set and ready to go. We crafted it based on the awesome offer you've created. Enjoy!",
  'retailerFundedMarketingDomain.promoteModule.emailPreview.subject': 'Subject Line',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.preheader': 'Preheader',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.confirm': 'Add',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.cancel': 'Cancel',
  'retailerFundedMarketingDomain.promoteModule.emailPreview.template': 'Template',
  'retailerFundedMarketingDomain.promoteModule.preview': 'Preview',
  'retailerFundedMarketingDomain.promoteModule.required.error':
    'At least one creative must be added',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.homepage.title':
    'To the homepage',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.missingCreativeConfiguration.error':
    'Missing configuration for at least one creative.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.homepage.description':
    'Promote on the homepage',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.storefront.announcementBanner.title':
    'To the announcement banner',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.storefront.announcementBanner.description':
    'Promote on the announcement banner',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.title': 'On search pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.description':
    'Promote on search pages for up to 20 keywords',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.title': 'On browse pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.description':
    'Promote on department pages that customers browse',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.custom.title': 'On custom pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.custom.description':
    'Promote on custom landing pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.homepage.slotPickerModalTitle':
    'Choose a slot on the homepage',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.custom.slotPickerModalTitle':
    'Choose a slot on a custom page',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.slotPickerModalTitle':
    'Choose a slot on search pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.slotPickerModalTitle':
    'Choose a slot on browse pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.summaryHeading': 'Search',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.summaryHeading': 'Browse',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.custom.summaryHeading':
    'Custom Page',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.noCreativesConfigured':
    'No creatives configured',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.homepage.slotPickerLaunchBtnMessage':
    'Configure creative',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.custom.pickHostPage':
    'Select a host page',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.custom.pickHostPage.modal.error':
    'Sorry, something went wrong while loading custom page slots',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.slotPickerLaunchBtnMessage':
    'Configure creative',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.slotPickerConfigureLaterMessage':
    'Configure creative later',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.heading':
    'Search keyword',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.placeholder':
    'Enter or paste your keywords, one word or phrase per line, or separated by commas',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.removeAll':
    'Remove all keywords (%count%)',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.keywordRequiredError':
    'At least one keyword is required.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.keywordsOverflow':
    'One or more keywords could not be added. You cannot have more than {maxKeywords} keywords.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.keywordsDuplicate':
    'One or more keywords could not be added because they already exist in the list.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.description':
    'Matches keywords or phrases. Matching is not case-sensitive and does not include misspellings or synonyms.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.limit':
    'Limit 20 keywords',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.searchKeywords.addKeywords':
    'Add keywords',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseCollections.heading':
    'Target departments or collections',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseDepartments.heading':
    'Target departments',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseCollections.description':
    'Select the departments or collections where this campaign will be displayed.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseDepartments.description':
    'Select the departments where this campaign will be displayed.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseCollections.placeholder':
    'Start typing a department or collection name',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseDepartments.placeholder':
    'Search to add departments',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseDepartments.noneSelected':
    'No departments selected.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseDepartments.customSelected':
    "Your targeted departments include custom departments. Customers will not see the campaign if these custom departments end before the campaign's end date.",
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseDepartments.ongoing':
    'Ongoing',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseDepartments.departmentRequiredError':
    'At least one department is required.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.noLocation.creativeBuilderModalTitle':
    'Configure {slotType}',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.homepage.creativeBuilderModalTitle':
    'Configure {slotType} on the homepage',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.creativeBuilderModalTitle':
    'Configure {slotType} on search pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.creativeBuilderModalTitle':
    'Configure {slotType} on browse pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.customPage.creativeBuilderModalTitle':
    'Configure {slotType} on custom pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.creativeBuilderModal.createSuccess':
    '<bold>{placementDisplayName}</bold> added, you can access this creative in the <a>Creatives</a>.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.creativeBuilderModal.editSuccess':
    '<bold>{placementDisplayName}</bold> updated, this change will apply to any pages or campaigns that’s using this creative.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.announcementBanner.creativeBuilderModalTitle':
    'Configure announcement banner',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.homepage.slotCreativePickerPageType':
    'the homepage',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.slotCreativePickerPageType':
    'search pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.slotCreativePickerPageType':
    'browse pages',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.listTitle':
    'Search keywords',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.showAllListItems':
    'Show all keywords',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.search.showLessListItems':
    'Show less keywords',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseCollections.listTitle':
    'Target departments or collections',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.listTitle':
    'Target departments',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseCollections.showAllListItems':
    'Show all',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.showAllListItems':
    'Show all departments',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browseCollections.showLessListItems':
    'Show less',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.showLessListItems':
    'Show less departments',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.browse.customDepartment':
    'Custom department',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.confirmEditCreativeModal.heading':
    'Editing {creativeName}',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.confirmEditCreativeModal.bodyLine1':
    'This creative is used in other pages and campaigns, editing this will affect the other pages and campaigns. To edit this creative, head over to the creative builder.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.confirmEditCreativeModal.bodyLine2':
    'Alternatively, you can create a copy of this creative and edit it.',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.confirmEditCreativeModal.cancelCta':
    'Cancel',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.confirmEditCreativeModal.createCopyCta':
    'Create a copy',
  'retailerFundedMarketingDomain.promoteStorefrontCreativesModule.confirmEditCreativeModal.editCta':
    'Edit',
  'retailerFundedMarketingDomain.lookbackWindowDaysMinError':
    'Minimum {minLookbackWindowDays, plural, =1 {# day} other {# days}}',
  'retailerFundedMarketingDomain.lookbackWindowDaysMaxError':
    'Maximum {maxLookbackWindowDays, plural, =1 {# day} other {# days}}',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.error.title': 'There was an error',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.error.description':
    'There was an error fetching the status of the campaign. Please check back later.',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.title': 'CRM Status',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.sent': 'Sent: {sent}',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.failed': 'Failed: {failed}',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.pending': 'Pending: {pending}',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.total': 'Total users: {total}',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.cancel': 'Cancel',
  'retailerFundedMarketingDomain.campaignModules.crmStatus.trigger': 'Trigger',
  'retailerFundedMarketingDomain.campaignModules.roulette.label': 'Roulette Input',
  'retailerFundedMarketingDomain.campaignModules.roulette.description.default':
    'Enter the name of the Roulette you want to link to this campaign',
  'retailerFundedMarketingDomain.campaignModules.roulette.info':
    'Best practice: Putting campaigns behind holdouts',
  'retailerFundedMarketingDomain.campaignModules.roulette.info2': 'Refer to',
  'retailerFundedMarketingDomain.campaignModules.roulette.info3': 'this guide',
  'retailerFundedMarketingDomain.campaignModules.roulette.info4':
    'on when and how to put this campaign behind an ICMS program holdout.',
  'retailerFundedMarketingDomain.campaignModules.roulette.not.linked': 'No roulette selected',
  'retailerFundedMarketingDomain.campaignModules.roulette.search.feature.placeholder':
    'Search for a roulette experiment name',
  'retailerFundedMarketingDomain.campaignModules.roulette.search.variant.placeholder':
    'Select a variant name',
  'retailerFundedMarketingDomain.campaignModules.roulette.search.variant.error':
    'Feature variant is required when feature name is provided',
  'retailerFundedMarketingDomain.campaignModules.experiment.label': 'Experiment',
  'retailerFundedMarketingDomain.campaignModules.experiment.summary.precondition.title':
    'Has Precondition',
  'retailerFundedMarketingDomain.campaignModules.experiment.precondition.title':
    'Include a Precondition',
  'retailerFundedMarketingDomain.campaignModules.experiment.precondition.description':
    'Users will only receive the campaign if they meet the precondition',
  'retailerFundedMarketingDomain.campaignModules.experiment.description.title':
    '90/10 audience split test',
  'retailerFundedMarketingDomain.campaignModules.experiment.description.summary':
    'Test variants of this campaign and measure success.',
  'retailerFundedMarketingDomain.campaignModules.experiment.description.viewExperiment':
    'View in roulette',
  'retailerFundedMarketingDomain.campaignModules.experiment.description.subtitle':
    'We will automatically respect retailer and marketing holdouts.',
  'retailerFundedMarketingDomain.campaignModules.experiment.splitTest.title': 'Run a split test',
  'retailerFundedMarketingDomain.campaignModules.experiment.splitTest.subtitle':
    'We’ll show your split test to two separate audiences to give you data-driven results.',
  'retailerFundedMarketingDomain.campaignModules.experiment.splitTest.toggle':
    'Run a 90/10 split test',
  'retailerFundedMarketingDomain.campaignModules.experiment.splitTest.toggle.description':
    '90% of your targeted audience will see this campaign, 10% will not.',
  'retailerFundedMarketingDomain.campaignModules.experiment.holdouts.title': 'Holdouts',
  'retailerFundedMarketingDomain.campaignModules.experiment.holdouts.subtitle':
    'This experiment will respect the following holdouts:',
  'retailerFundedMarketingDomain.campaignModules.experiment.holdouts.crm': 'CRM Holdout',
  'retailerFundedMarketingDomain.campaignModules.experiment.holdouts.comarketing':
    'Co-marketing holdout',
  'retailerFundedMarketingDomain.campaignModules.experiment.holdouts.crm.tooltip':
    'A small percentage of users do not receive CRM campaigns in order to measure long-term impact. This campaign will respect that holdout.',
  'retailerFundedMarketingDomain.campaignModules.experiment.name.placeholder':
    'Enter a Roulette experiment name (without spaces eg. crm_expansion_launch_...)',
  'retailerFundedMarketingDomain.campaignModules.experiment.name.description':
    'If no name is provided we will provide a default that matches campaign experiment.',
  'retailerFundedMarketingDomain.campaignModules.experiment.holdouts.comarketing.tooltip':
    'A small percentage of users do not receive co-marketing campaigns in order to measure long-term impact. This campaign will respect that holdout.',
  'retailerFundedMarketingDomain.campaignModules.experiment.bestPractice.title':
    'Best practice: Experiment is on by default',
  'retailerFundedMarketingDomain.campaignModules.experiment.bestPractice.subtitle':
    'When you run an experiment, you can get a clearer idea of how much better your campaign is performing. However, you may still turn it off.',
  'retailerFundedMarketingDomain.promote.templateModal.title.email': 'Select an email template',
  'retailerFundedMarketingDomain.promote.templateModal.title.push': 'Select a push notification',
  'retailerFundedMarketingDomain.promote.templateModal.title.canvas': 'Select a canvas template',
  'retailerFundedMarketingDomain.promote.templateModal.selection.customCanvas': 'Custom Canvas',
  'retailerFundedMarketingDomain.promote.templateModal.subtitle': 'Select a template',
  'retailerFundedMarketingDomain.promote.templateModal.next': 'Next',
  'retailerFundedMarketingDomain.promote.templateModal.back': 'Back',
  'retailerFundedMarketingDomain.promote.templateModal.confirm': 'Confirm',
  'retailerFundedMarketingDomain.promote.templateModal.description':
    'Choose from our wonderful variety of templates.',
  'retailerFundedMarketingDomain.promote.templateModal.comingSoon': 'Preview coming soon!',
  'retailerFundedMarketingDomain.campaignTags.title': 'Campaign tags',
  'retailerFundedMarketingDomain.campaignTags.placeholder':
    'Enter campaign tags separated by commas',
  'retailerFundedMarketingDomain.adminConfigurations.title': 'Admin configurations',
  'retailerFundedMarketingDomain.adminConfigurations.description': 'For reporting purposes',
  'retailerFundedMarketingDomain.adminConfigurations.internalOnlyTag': 'Internal only',
  'retailerFundedMarketingDomain.adminConfigurations.costCenter.title': 'Cost Center Owner',
  'retailerFundedMarketingDomain.adminConfigurations.costCenter.placeholder':
    'Select a cost center owner for this campaign',
  'retailerFundedMarketingDomain.adminConfigurations.costCenter.option.incentives': 'Incentives',
  'retailerFundedMarketingDomain.adminConfigurations.costCenter.option.paidMarketing':
    'Paid Marketing',
  'retailerFundedMarketingDomain.adminConfigurations.costCenter.option.partnerships':
    'Partnerships',
  'retailerFundedMarketingDomain.adminConfigurations.costCenter.option.retailerMarketing':
    'Retailer Marketing',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.title':
    'Campaign Rollup Managed By Marketer',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.placeholder':
    'Select a category',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dxgyLifecycle':
    'DxGy Life Cycle',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dxgyHabituation':
    'DxGy Habituation',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dxgyRestaurant':
    'DxGy Restaurant',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dxgyBYO':
    'DxGy BYO',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dxgyICPlus':
    'DxGy IC+',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dxgyLoyalty':
    'DxGy Loyalty',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dailyMoments':
    'Daily Moments',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.dealWeeks':
    'Deal Weeks',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.evergreenRetailerAgnosticChurned':
    'Evergreen Retailer Agnostic Churned',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.partnershipsPlaceholder':
    'Partnerships Placeholder',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.icmsEvergreen':
    'ICMS Evergreen',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.icmsTest':
    'ICMS Test',
  'retailerFundedMarketingDomain.adminConfigurations.campaignRollupManagedByMarketer.option.icmsStrategic':
    'ICMS Strategic',
  'retailerFundedMarketingDomain.adminConfigurations.marketingId.title': 'Marketing Id',
  'retailerFundedMarketingDomain.adminConfigurations.marketingId.placeholder':
    'Enter an id, if applicable, for example comar_IM2058',
  'retailerFundedMarketingDomain.adminConfigurations.financeTypology.title': 'Finance Typology',
  'retailerFundedMarketingDomain.adminConfigurations.financeTypology.placeholder':
    'Select a finance typology for this campaign.  for example: ICMS',
  'retailerFundedMarketingDomain.promote.templatePreview.preselected.title':
    "We've made sure your messages match!",
  'retailerFundedMarketingDomain.promote.templatePreview.preselected.description.email':
    ' We chose an email template that goes well with the push notification template you added to this campaign.',
  'retailerFundedMarketingDomain.promote.templatePreview.preselected.description.push':
    ' We chose a push notification that goes well with the email template you added to this campaign.',
  'retailerFundedMarketingDomain.promote.crm.pushPreview.now': 'now',
  'retailerFundedMarketingDomain.promote.crm.addChannel': 'Add messaging channel',
  'retailerFundedMarketingDomain.promote.crm.emailButton.description': 'Send an email',
  'retailerFundedMarketingDomain.promote.crm.emailButton': 'Email',
  'retailerFundedMarketingDomain.promote.crm.pushButton': 'Push notification',
  'retailerFundedMarketingDomain.promote.crm.pushButton.description':
    'Send a push notification to customers who have the app',
  'retailerFundedMarketingDomain.promote.crm.canvasButton': 'Canvas',
  'retailerFundedMarketingDomain.promote.crm.canvasButton.description': 'Send a canvas',
  'retailerFundedMarketingDomain.billingOption.range.error':
    'Please enter a value between 0 and 100',
  'retailerFundedMarketingDomain.appName': 'Instacart',
  'retailerFundedMarketingDomain.components.campaignScopeSelect.retailerAgnostic':
    'Retailer Agnostic',
  'retailerFundedMarketingDomain.components.campaignScopeSelect.retailerAgnosticUS':
    'Retailer Agnostic US',
  'retailerFundedMarketingDomain.components.campaignScopeSelect.retailerAgnosticCA':
    'Retailer Agnostic Canada',
  'retailerFundedMarketingDomain.components.campaignScopeSelect.singleBanner': 'Single Banner',
  'retailerFundedMarketingDomain.components.campaignScopeSelect.multipleBanners':
    'Multiple Banners',
  'retailerFundedMarketingDomain.components.campaignScopeSelect.title': 'Campaign Scope',
  'retailerFundedMarketingDomain.components.campaignScopeSelect.noPermisison':
    'You do not have permission to create offers for ',
  'retailerFundedMarketingDomain.couponDuration.custom.label': 'Custom coupon duration limit',
  'retailerFundedMarketingDomain.couponDuration.custom.description':
    'Customers can use the coupons up to {validForDays} days after clipping, until {endDate}.',
  'retailerFundedMarketingDomain.couponDuration.campaignEndDate.label':
    'Coupon valid until campaign ends',
  'retailerFundedMarketingDomain.couponDuration.campaignEndDate.description':
    'Customers can use the coupon until {endDate}',
  'retailerFundedMarketingDomain.campaignField.couponDuration.exceeded.error':
    'Coupon duration cannot exceed the offer period. Set a number less than {validForDays}.',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.label':
    'Custom terms and conditions',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.title':
    'Custom terms and conditions',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.description':
    'Add custom terms and conditions for this offer',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.subtitle':
    'Override the system generated legal text with your own text for this offer',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.summary':
    'Custom terms and conditions',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.en_US.label':
    'Custom legal text - US EN',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.en_US.placeholder':
    'Enter custom legal text in English',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.es_US.label':
    'Custom legal text - US ES',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.es_US.placeholder':
    'Enter custom legal text in Spanish',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.fr_CA.label':
    'Custom legal text - CA FR',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.fr_CA.placeholder':
    'Enter custom legal text in Canadian French',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.en_CA.label':
    'Custom legal text - CA EN',
  'campaigns.create.customCampaign.form.offer.terms.customTermsAndConditions.en_CA.placeholder':
    'Enter custom legal text in Canadian English',
} as const

export default EN_US
