import React from 'react'
import styled from '@emotion/styled'
import { fontWeight, spacing } from '@retailer-platform/shared-components'
import { LoadingGenericBase } from '@instacart/ids-customers'

const StyledContainer = styled.div({
  textAlign: 'center',
  height: '100%',
  position: 'relative',

  '.header': {
    fontWeight: fontWeight.SEMIBOLD,
    margin: spacing.X20,
  },
})

export const LoadingPage: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ className }) => (
  <StyledContainer data-testid={'loading-page'} className={className}>
    <LoadingGenericBase />
  </StyledContainer>
)
