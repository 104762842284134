import { type SimpleOption } from '@retailer-platform/shared-components/src/common/select/utils/select.types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { type InsightsPortalFilterSelectionInput, type InsightsPortalValueInput } from '../../api'
import {
  type FilterParamsFilterNames,
  type FilterParamsState,
  type InsightsPortalApiFilterOptions,
} from '../../utils/filter-params/filterParams.types'
import { getInsightsValue } from '../../utils/helpers'
import { type InsightsPortalFilterTypes } from '../../utils/insights-portal.types'
import { transformOptionsToInsightsPortalInFilterSelection } from '../../utils/insights-portal.utils'
import {
  optionsArrayToInFilterSelection,
  stringToInFilterSelection,
} from './insightsPortalFilterTransforms'

dayjs.extend(utc)

type BaseMultiSelectFiltersConfig = {
  filterParamName: Extract<
    FilterParamsFilterNames,
    | 'retailer'
    | 'storeLocation'
    | 'storeLocationCode'
    | 'orderSource'
    | 'region'
    | 'state'
    | 'fulfillmentType'
    | 'loyalty'
    | 'alcohol'
    | 'express'
    | 'firstOrder'
    | 'convenience'
    | 'ebt'
    | 'L1Category'
    | 'L2Category'
    | 'L3Category'
    | 'L4Category'
    | 'L5Category'
    | 'L6Category'
    | 'partnerDivision'
    | 'demandSource'
    | 'fsaHsaOrder'
    | 'rxOrder'
  >
  insightsPortalFilterName: string
  filterType: InsightsPortalFilterTypes
  valueType: keyof InsightsPortalValueInput
}

// these are dynamic filters that will be used through all the dashboards
const baseMultiSelectFiltersConfig: BaseMultiSelectFiltersConfig[] = [
  {
    filterParamName: 'retailer',
    insightsPortalFilterName: 'WAREHOUSE',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'storeLocation',
    insightsPortalFilterName: 'WAREHOUSE_LOCATION',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'storeLocationCode',
    insightsPortalFilterName: 'WAREHOUSE_LOCATION_CODE',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'orderSource',
    insightsPortalFilterName: 'ORDER_SOURCE',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'region',
    insightsPortalFilterName: 'REGION',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'state',
    insightsPortalFilterName: 'WAREHOUSE_LOCATION_STATE',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'fulfillmentType',
    insightsPortalFilterName: 'FULFILLMENT_TYPE',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'alcohol',
    insightsPortalFilterName: 'ALCOHOL',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'L1Category',
    insightsPortalFilterName: 'L1_CATEGORY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'L2Category',
    insightsPortalFilterName: 'L2_CATEGORY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'L3Category',
    insightsPortalFilterName: 'L3_CATEGORY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'L4Category',
    insightsPortalFilterName: 'L4_CATEGORY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'L5Category',
    insightsPortalFilterName: 'L5_CATEGORY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'L6Category',
    insightsPortalFilterName: 'L6_CATEGORY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'express',
    insightsPortalFilterName: 'EXPRESS',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'convenience',
    insightsPortalFilterName: 'VIRTUAL_WAREHOUSE',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'loyalty',
    insightsPortalFilterName: 'LOYALTY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'ebt',
    insightsPortalFilterName: 'EBT',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'firstOrder',
    insightsPortalFilterName: 'FIRST_ORDER',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'partnerDivision',
    insightsPortalFilterName: 'PARTNER_GEO_DIVISION',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'demandSource',
    insightsPortalFilterName: 'DEMAND_SOURCE',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'fsaHsaOrder',
    insightsPortalFilterName: 'FSA_HSA_ORDER',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
  {
    filterParamName: 'rxOrder',
    insightsPortalFilterName: 'RX_ORDER',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
]

const singleSelectFiltersConfig = [
  {
    filterParamName: 'orderCurrency',
    insightsPortalFilterName: 'ORDER_CURRENCY',
    filterType: 'inFilter',
    valueType: 'stringValue',
  },
] as const

/*
  Transforms the filter params from the FilterParam Context into the filter selections
  expected for insights portal queries

  checks specs for examples of object shapes
*/

/*

  TODO: Deprecate this function and use the factory version (transformFilterParamsToApiFilterSelectionsFactory)
  so it's more flexible

*/
export const transformFilterParamsToApiFilterSelections = (
  allFilterParams: FilterParamsState['selectedFiltersValues'],
  dates: Omit<FilterParamsState['dates'], 'dateRange' | 'comparisonType' | 'weekStartDay'>
): InsightsPortalFilterSelectionInput[] => {
  // convert multi select options into insights portal api filters
  const multiSelects = baseMultiSelectFiltersConfig.map(filterSelectionConfig =>
    transformOptionsToInsightsPortalInFilterSelection(
      allFilterParams[filterSelectionConfig.filterParamName],
      filterSelectionConfig.insightsPortalFilterName,
      filterSelectionConfig.valueType
    )
  )

  const insightsPortalDateFormat = 'YYYY-MM-DDTHH:mm:ssZ'

  const dateFilter: InsightsPortalFilterSelectionInput = {
    name: 'DATE_PT',
    betweenFilter: {
      start: {
        timestampValue: dates.from.utc(true).format(insightsPortalDateFormat),
      },
      end: {
        timestampValue: dates.to.utc(true).format(insightsPortalDateFormat),
      },
    },
  }

  const orderCurrencyFilter = stringToInFilterSelection('stringValue')(
    allFilterParams['orderCurrency'],
    'ORDER_CURRENCY'
  )

  return [...multiSelects, orderCurrencyFilter, dateFilter].filter(Boolean)
}

export type BaseDynamicFilterOptions = {
  retailer: SimpleOption[]
  storeLocation: SimpleOption[]
  storeLocationCode: SimpleOption[]
  orderSource: SimpleOption[]
  state: SimpleOption[]
  region: SimpleOption[]
  fulfillmentType: SimpleOption[]
  orderCurrency: SimpleOption[]
}

/*
  Transforms the dynamic Insights Portal filter options into FE options for the dropdowns to use
*/
export const transformApiOptionsIntoBaseOptions = (
  apiOptions: InsightsPortalApiFilterOptions
): BaseDynamicFilterOptions => {
  const emptyOptions = {
    retailer: [],
    state: [],
    region: [],
    storeLocation: [],
    storeLocationCode: [],
    orderSource: [],
    fulfillmentType: [],
    orderCurrency: [],
  }

  if (!apiOptions) {
    return emptyOptions
  }

  // go through each dynamic filter, find the corresponding options in the API response
  // and convert it into the { label: string, value: string } options the dropdowns can use
  const orderDeliveriesFilterOptions = [
    ...baseMultiSelectFiltersConfig,
    ...singleSelectFiltersConfig,
  ].reduce((orderDeliveryOptions, filterSelectionConfig) => {
    const filterOptionsFromApi = apiOptions.find(
      apiOption => apiOption.name === filterSelectionConfig.insightsPortalFilterName
    )

    let filterOptions = []

    if (filterOptionsFromApi) {
      // convert api filter options into html select options
      filterOptions = filterOptionsFromApi.options
        .map(
          option =>
            option.rawValue && {
              label: option.displayValue,
              value: option.rawValue?.[filterSelectionConfig.valueType],
            }
        )
        .filter(Boolean)
    }

    orderDeliveryOptions[filterSelectionConfig.filterParamName] = filterOptions

    return orderDeliveryOptions
  }, {} as BaseDynamicFilterOptions)

  return {
    ...emptyOptions,
    ...orderDeliveriesFilterOptions,
  }
}

/*
  ** Usage

  use to create a configuration object that defines how to convert a filter provider param into
  the expected filter object the API expects

  Note: This is a function so we can do type inference. Not able to do type inference on an interface

  ** Arguments

  filterParamName: one of the filter parameters in FilterProvider
  insightsPortalFilterName: this should be the name of the filter on the schema in the backend
  transform: a function that takes in the filter selections for filterParamName in FilterProvider and returns a InsightsPortalFilterSelectionInput

*/
export function createFilterParamConfig<TFilterProviderParam extends FilterParamsFilterNames>(
  filterParamName: TFilterProviderParam,
  insightsPortalFilterName: string,
  transform: (
    filterSelections: FilterParamsState['selectedFiltersValues'][TFilterProviderParam],
    filterSelectionName: string
  ) => InsightsPortalFilterSelectionInput,
  filterLabel?: string
) {
  return {
    filterParamName,
    insightsPortalFilterName,
    transform,
    filterLabel,
  }
}

export const insightsPortalDateFormat = 'YYYY-MM-DDTHH:mm:ssZ'

/*
  Creates a function that can be used to transform the Filter Provider parameters into
  filter selections that the API schema expects.
*/
export const transformFilterParamsToApiFilterSelectionsFactory =
  (transformFilterParamsConfig: ReturnType<typeof createFilterParamConfig>[]) =>
  (
    allFilterParams: FilterParamsState['selectedFiltersValues'],
    dates: Omit<FilterParamsState['dates'], 'dateRange' | 'comparisonType' | 'weekStartDay'>
  ): InsightsPortalFilterSelectionInput[] => {
    const filterOptions = transformFilterParamsConfig.map(filterSelectionConfig =>
      filterSelectionConfig.transform(
        allFilterParams[filterSelectionConfig.filterParamName],
        filterSelectionConfig.insightsPortalFilterName
      )
    )

    const dateFilter: InsightsPortalFilterSelectionInput = {
      name: 'DATE_PT',
      betweenFilter: {
        start: {
          timestampValue: dates.from.utc(true).format(insightsPortalDateFormat),
        },
        end: {
          timestampValue: dates.to.utc(true).format(insightsPortalDateFormat),
        },
      },
    }

    return [...filterOptions, dateFilter].filter(Boolean)
  }

interface TransformApiOptionsConfig {
  [key: string]: string
}

/*
  transform the filter options from the api into a form the FE can use (ie: select options).
*/
// TODO: Tests
export function transformApiOptionsIntoDashboardOptions<
  TFilterConfig extends TransformApiOptionsConfig
>(
  apiOptions: InsightsPortalApiFilterOptions,
  schemaFilterConfig: TFilterConfig
): { [key in keyof TFilterConfig]: SimpleOption[] } {
  const emptyOptions = Object.keys(schemaFilterConfig).reduce(
    (options, filterName) => ({ ...options, [filterName]: [] }),
    {} as { [key in keyof TFilterConfig]: SimpleOption[] }
  )

  if (!apiOptions) {
    return emptyOptions
  }

  const filterOptions = Object.entries(schemaFilterConfig).reduce(
    (options, filterConfig: [keyof TFilterConfig, string]) => {
      const [filterName] = filterConfig

      const filterOptionsFromApi = apiOptions.find(apiOption => apiOption.name === filterName)

      let filterOptions: SimpleOption[] = []

      if (filterOptionsFromApi) {
        // convert api filter options into html select options
        filterOptions = filterOptionsFromApi.options
          // ignore options where the rawValue is undefined
          .filter(option => option.rawValue)
          .map(option => ({
            label: option.displayValue,
            value: getInsightsValue(option.rawValue),
          }))
      }

      options[filterName] = filterOptions

      return options
    },
    {} as { [key in keyof TFilterConfig]: SimpleOption[] }
  )

  return {
    ...emptyOptions,
    ...filterOptions,
  }
}
export const transformFilterParamsForApiConfig = [
  createFilterParamConfig(
    'storeLocation',
    'RETAILER_LOCATION',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'storeLocationName',
    'RETAILER_LOCATION_NAME',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('retailer', 'RETAILER', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'demandSource',
    'DEMAND_SOURCE',
    optionsArrayToInFilterSelection('stringValue')
  ),
]

export const transformFilterSelectionsToAdsMetricFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)
