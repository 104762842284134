import { useCurrentAccountDetails } from '../../../../api/current-account/currentAccount.hooks'
import { usePartnerContext } from '../../../../utils/contexts/partner/PartnerContext.hooks'

export const useRequiredNavDataLoadingStatus = () => {
  // the nav uses these contexts to determine which nav items to render
  // we wait for them to be loaded to avoid UI flashing
  const { loading } = useCurrentAccountDetails()
  const partnerContext = usePartnerContext()

  if (loading || !partnerContext.partner) {
    return {
      loading: true,
      error: null,
    }
  }

  return {
    loading: false,
    error: null,
  }
}

// Admin nav requires account (to load feature toggles and permissions)
export const useRequiredAdminNavDataLoadingStatus = () => {
  const { loading } = useCurrentAccountDetails()

  return {
    loading,
    error: null,
  }
}
