import { useEffect, useState } from 'react'
import { usePartnerId } from '@retailer-platform/dashboard/routing'
import { errors } from '@retailer-platform/dashboard/errors'
import { useCheckLaunchOnboardingProgressByPartnerQuery } from '../api'
import { WORKFLOW_STATE } from './constants'

export const useCheckLaunchOnboardingProgress = (skip?: boolean) => {
  const partnerId = usePartnerId()
  const { data, loading, error } = useCheckLaunchOnboardingProgressByPartnerQuery({
    variables: { partnerId },
    skip: skip,
  })

  const [hasLaunchOnboarding, setHasLaunchOnboarding] = useState(false)
  const [hasInProgressLaunchOnboarding, setHasInProgressLaunchOnboarding] = useState(false)
  const [workflowState, setWorkflowState] = useState('')

  useEffect(() => {
    if (error) {
      errors.captureMessage(
        'useCheckLaunchOnboardingProgressByPartnerQuery call returned an error',
        {
          level: 'error',
        }
      )
    }
  }, [error])

  useEffect(() => {
    setWorkflowState(data?.onboardingServiceCheckLaunchOnboardingProgressByPartner?.workflowState)
    if (workflowState) {
      setHasLaunchOnboarding(true)
      // the launch onboarding is considered in progress unless it's launched
      if (workflowState != WORKFLOW_STATE.LAUNCHED) {
        setHasInProgressLaunchOnboarding(true)
      }
    }
  }, [data, workflowState])

  return {
    loading,
    hasInProgressLaunchOnboarding,
    hasLaunchOnboarding,
    workflowState,
    error,
  }
}
