import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'

interface UseIsLoggedInResult {
  isLoggedIn: boolean
  loading: boolean
}

/**
 * Determines if the current user is logged in or not by checking if they can make an API call requiring authentication
 * Then the resulting account data is checked for the viewRetailerDashboardSection policy, which all accounts viewing the dashboard should have.
 * @returns {UseIsLoggedInResult} - isLoggedIn: boolean, loading: boolean
 */
export const useIsLoggedIn = (): UseIsLoggedInResult => {
  const { error, loading, account } = useCurrentAccountDetails({ fetchPolicy: 'cache-and-network' })
  return {
    isLoggedIn: !error && !loading && account?.policies.viewRetailerDashboardSection,
    loading,
  }
}
