import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const emergingBrandsPortalAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.EmergingBrandsPortal],
    warehouseFeatureToggles: FeatureToggle.emergingBrandsPortal,
  },
  {
    permissions: [Permission.EmergingBrandsPortal],
    notWarehouseFeatureToggles: FeatureToggle.noAccessEmergingBrandPortal,
  },
]
