import { useCallback } from 'react'
import {
  type Instacart_Catalog_Admin_V1_TempOverrideEntityInput_Input,
  useCreateTempOverrideMutation,
} from '../../../../__codegen__/api'

export const useCreateTempOverride = () => {
  const [createTempOverride, { data, called, loading, error }] = useCreateTempOverrideMutation()

  const createTempOverrideCall = useCallback(
    (
      productId: string,
      retailerId: string,
      localeId: number,
      surfaceId: number,
      itemIds: number[],
      tombstoneItemIds: number[]
    ) => {
      const input: Array<Instacart_Catalog_Admin_V1_TempOverrideEntityInput_Input> = itemIds.map(
        i => ({
          entityId: {
            retailerId: retailerId,
            productId: productId,
            localeId: localeId.toString(),
            surfaceId: surfaceId.toString(),
            itemId: i.toString(),
          },
          tempOverrideAttrs: [
            { attribute: '{"available": false}' },
            { attribute: '{"in_assortment": false}' },
          ],
        })
      )
      const tombstoned = tombstoneItemIds.map(i => ({
        entityId: {
          retailerId: retailerId,
          productId: productId,
          localeId: localeId.toString(),
          surfaceId: surfaceId.toString(),
          itemId: i.toString(),
        },
        tempOverrideAttrs: [
          { attribute: '{"available": false}', tombstone: true },
          { attribute: '{"in_assortment": false}', tombstone: true },
        ],
      }))
      createTempOverride({
        variables: {
          data: input.concat(tombstoned),
        },
      })
    },
    [createTempOverride]
  )

  return {
    createTempOverrideCall,
    success: Boolean(data),
    loading,
    called,
    error,
  }
}
