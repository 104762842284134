import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { featureEntitlementsManagementAdminRouter } from './routing/featureEntitlementsManagementAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { FeatureEntitlementManagementAccessConfig } from './access-control/featureEntitlementsManagementAdminAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'feature-entitlements-management',
  totem: {
    entity: 'feature-entitlements-management-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [featureEntitlementsManagementAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.RetailerManagement,
      attachToV2: AdminNavEntryPointV2.UserMenu,
      subNavItems: [
        {
          route: 'app-admin-feature-entitlements-management-entitlements',
          labelId: 'featureEntitlementsManagementDomain.title',
          accessControl: FeatureEntitlementManagementAccessConfig,
          positionNavV2: 1,
        },
      ],
    },
  ],
}

export default definition
