import { type ScopedRouteParams } from '@retailer-platform/dashboard/utils'

export type Shopper = {
  id: number
  first_name: string
  last_name: string
  email: string | null
  phone: string | null
  warehouse_location_name: string | null
}

export enum ShiftType {
  Picking = 'picking_only',
  Running = 'runner',
  // TODO: this variant gets replaced by the "Driving" variant, as they have the same value. Please fix this!
  FullService = 'normal',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Driving = 'normal',
}

export enum AllocationType {
  Dedicated = 'dedicated',
  OnCall = 'on_call',
}

export interface ShiftsRouteQuery {
  date?: string
  warehouse_location_id?: string
  week?: string
}

export interface ShiftsRouteParams extends ScopedRouteParams {}

export interface ShiftsApiResponseStaffingLevels {
  starts_at: string
  ends_at: string
  num_pickers: string
  dedicated_pickers?: string
  oncall_pickers?: string
  num_runners: string
  num_full_service: string
  min_staffing_hour: boolean
}

export interface ShiftsApiResponseData {
  shoppers: ShopperWithShifts[]
  staffing_levels: ShiftsApiResponseStaffingLevels[]
  delivery_hours: DeliveryHours
}

export interface ShiftsApiRequestQuery {
  warehouse_location_id: string
  date: string
  device_prioritization_enabled: boolean
}

export type CreateShopperShiftResponse = {
  shopper_shift: { id: number }
}

export interface Shift {
  id?: number
  starts_at: string
  ends_at: string
  shift_type: ShiftType
  busy?: boolean
  recurring_shift_id?: number
  recur_start_date?: string
  recur_end_date?: string
  shift_start_seconds_since_midnight?: number
  shift_length_seconds?: number
  days_of_week: number[]
  placeholder?: boolean
  on_call?: boolean
}

export interface UpdateShopperShiftRequestData extends Shift {
  warehouse_location_id: number
  driver_id: number
  date: string
}

export interface DeleteShopperShiftRequestData {
  warehouse_location_id: number
  driver_id: number
}

export interface TransformedShift extends Omit<Shift, 'starts_at' | 'ends_at'> {
  starts_at: Date
  ends_at: Date
}

export interface ShopperWithShifts {
  id: number
  first_name: string
  last_name: string
  shifts: Shift[]
}

export interface DeliveryHours {
  start: string
  end: string
  zone: string
}
