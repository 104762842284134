import { useCurrentAccountDetails } from '../../../../../api/current-account/currentAccount.hooks'
import { useIsLoggedIn } from '../../../../../gin-and-tonic/utils/access/useIsLoggedIn'

export const useHaltRedirectAccessDetails = () => {
  const { loading: loadingLoggedIn, isLoggedIn } = useIsLoggedIn()

  const { loading: loadingAccount, account } = useCurrentAccountDetails({
    fetchPolicy: 'network-only',
  })

  const loading = loadingAccount || loadingLoggedIn

  const retailerDashboardAccess = account?.policies.viewRetailerDashboardSection
  const canAccessRetailerDashboard = retailerDashboardAccess && isLoggedIn
  const authServiceRequired = account?.authServiceRequired
  const isInternal = account?.isInternal

  return {
    loading,
    canAccessRetailerDashboard,
    authServiceRequired,
    isInternal,
  }
}
