import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import { type EmotionStyles } from '../../utils/styling/types'
import { fontSize } from '../../foundation/fontSize'
import { fontWeight } from '../../foundation/fontWeight'
import { type DashMessages } from '../../../intl/intl.types'
import { useDashMessage } from '../../../intl/intl.hooks'
import { type TestID } from '../../utils/testing/types'
import { spacing } from '../../foundation/spacing'

export interface Props extends EmotionStyles, TestID {
  messageId?: DashMessages
  className?: string
}

const headerStyles = css({
  margin: spacing.X8,
  fontSize: fontSize.X23,
  fontWeight: fontWeight.BOLD,
})

export const Header: FunctionComponent<React.PropsWithChildren<Props>> = ({
  messageId,
  styles,
  children,
  ...rest
}) => {
  const message = useDashMessage(messageId)

  return (
    <div css={css(headerStyles, styles)} {...rest}>
      {message}
      {children}
    </div>
  )
}
