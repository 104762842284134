import { createContext, useContext } from 'react'

export type UserFeedbackContextValue = {
  isFeedbackButtonShown: boolean
  showFeedbackButton: () => void
  hideFeedbackButton: () => void
}

export const UserFeedbackContext = createContext<UserFeedbackContextValue>(null)

export const useUserFeedbackContext = () => useContext(UserFeedbackContext)
