const EN_US = {
  'productsDomain.title': 'Products',
  'productsDomain.breadcrumbs.root': 'Search Products',
  'productsDomain.view.title': 'Product details',
  'productsDomain.view.header.editProduct': 'Edit Product',
  'productsDomain.view.header.editImages': 'Edit Images',
  'productsDomain.view.header.overrides': 'Temporary Product Overrides',
  'productsDomain.view.header.actions': 'Actions',
  'productsDomain.view.errors.notFound.body':
    "Seems like we don't have information for this product in the selected locale yet. Try selecting a different locale below.",
  'productsDomain.view.errors.notFound.afterAction':
    'If the problem persists, or you believe this to be a mistake, please contact support.',
  'productsDomain.view.tabs.ariaLabel': 'Product tabs',
  'productsDomain.view.tabs.details': 'Details',
  'productsDomain.view.tabs.aisles': 'Departments & Aisles',
  'productsDomain.view.tabs.storeLocations': 'Store Locations',
  'productsDomain.view.tabs.attributeOverrides': 'Attribute Overrides',
  'productsDomain.view.storeLocations.filters.title': 'Filters',
  'productsDomain.view.storeLocations.filters.isAvailable': 'Is Available',
  'productsDomain.view.storeLocations.filters.hasSalePricing': 'Has Sale Pricing',
  'productsDomain.view.storeLocations.filters.hasAvailabilityScoreOverride':
    'Has Disable Availability Prediction',
  'productsDomain.view.storeLocations.table.columns.store': 'Store',
  'productsDomain.view.storeLocations.table.columns.available': 'Available',
  'productsDomain.view.storeLocations.table.columns.inAssortment': 'In-assortment',
  'productsDomain.view.storeLocations.table.columns.availableScoreOverride':
    'Disable Availability Prediction',
  'productsDomain.view.storeLocations.table.columns.taxable': 'Taxable',
  'productsDomain.view.storeLocations.table.columns.perWeightAdjusted': 'Par Weight Adjusted',
  'productsDomain.view.storeLocations.table.columns.standardPrice': 'Standard Price',
  'productsDomain.view.storeLocations.table.columns.displayedPrice': 'Displayed Price',
  'productsDomain.view.storeLocations.table.columns.pricedBy': 'Priced By',
  'productsDomain.view.storeLocations.table.columns.actions': 'Actions',
  'productsDomain.view.storeLocations.table.none': 'None',
  'productsDomain.view.storeLocations.table.details': 'Details',
  'productsDomain.view.storeLocations.override.modal.error.text':
    'There was an error while submitting your availability override request',
  'productsDomain.view.storeLocations.override.modal.title': 'Temporary Availability Overrides',
  'productsDomain.view.storeLocations.override.modal.subtitle': 'Surface: ',
  'productsDomain.view.storeLocations.override.modal.warning.text':
    'This take down is temporary and will revert to retailer files after 2 days.',
  'productsDomain.view.storeLocations.override.modal.only.selected.filter': 'Only selected?',
  'productsDomain.view.storeLocations.override.modal.submit.button': 'Submit',
  'productsDomain.view.storeLocations.override.modal.cancel.button': 'Cancel',
  'productsDomain.view.storeLocations.override.modal.no.store': 'No store locations to show',
  'productsDomain.view.storeLocations.modal.success': 'Items have been made unavailable',
  'productsDomain.view.storeLocations.override.alert':
    'One or more store locations have had items made temporarily unavailable. Items will be\n' +
    '          made available again or be able to be reset by inventory files on',
  'productsDomain.view.storeLocations.override.alert.in.row':
    'The item at this store has been made unavailable via a temporary override. Items ' +
    'will be made available again or be able to be reset by inventory files on',
  'productsDomain.view.delayedWarning': 'Note that updates on this page can be delayed.',
  'productsDomain.view.details.surfaces.marketplace': 'Marketplace',
  'productsDomain.view.details.surfaces.storefrontPro': 'Storefront Pro',
  'productsDomain.view.details.surfaces.select': 'Select Store',
  'productsDomain.view.details.sections.productName': 'Product Name',
  'productsDomain.view.details.sections.brandName': 'Brand Name',
  'productsDomain.view.details.sections.pricedBy': 'Priced By',
  'productsDomain.view.details.sections.description': 'Description',
  'productsDomain.view.details.sections.urls': 'Urls',
  'productsDomain.view.details.sections.retailerReferenceCode': 'Retailer Reference Code',
  'productsDomain.view.details.sections.productCodes': 'Product Codes',
  'productsDomain.view.details.sections.unit': 'Unit',
  'productsDomain.view.details.sections.parWeightAdjusted': 'Par Weight Adjusted',
  'productsDomain.view.details.sections.directions': 'Directions',
  'productsDomain.view.details.sections.ingredients': 'Ingredients',
  'productsDomain.view.details.sections.servingSize': 'Serving Size',
  'productsDomain.view.details.sections.servingsPerContainer': 'Servings Per Container',
  'productsDomain.view.details.sections.retailerDepartment': 'Retailer Department',
  'productsDomain.view.details.sections.category': 'Category',
  'productsDomain.view.details.sections.size': 'Size',
  'productsDomain.view.details.sections.dietaryAttributes': 'Dietary Attributes',
  'productsDomain.view.details.sections.overrideMsg': 'Overrides To: ',
  'productsDomain.view.details.sections.overrideExpiry': 'Expires: ',
  'productsDomain.view.details.sections.productEdit': 'Edit Product',
  'productsDomain.view.details.overrides.warningMsg':
    'There is a temporary override being applied to attributes of this product. It will revert back to the original value or by values from new files submitted after ',
  'productsDomain.view.details.sections.inAssortment': 'In Assortment',
  'productsDomain.view.details.sections.attributes': 'Attributes',
  'productsDomain.view.details.sections.attributes.glutenFree': 'Gluten-Free',
  'productsDomain.view.details.sections.attributes.lowFat': 'Low Fat',
  'productsDomain.view.details.sections.attributes.alcoholic': 'Alcoholic',
  'productsDomain.view.details.sections.attributes.kosher': 'Kosher',
  'productsDomain.view.details.sections.attributes.organic': 'Organic',
  'productsDomain.view.details.sections.attributes.vegan': 'Vegan',
  'productsDomain.view.details.sections.attributes.sugarFree': 'Sugar-Free',
  'productsDomain.view.details.sections.attributes.vegetarian': 'Vegetarian',
  'productsDomain.view.details.sections.details': 'Details',
  'productsDomain.view.details.sections.override.has': 'Override Till',
  'productsDomain.view.details.sections.override.none': 'No override found',
  'productsDomain.view.details.titles.productInfo': 'Product Info',
  'productsDomain.view.details.overrides.warning':
    'This update is temporary and will be overwritten by ingestion after 2 days.',
  'productsDomain.view.details.overrides.displayName': 'Display Name:',
  'productsDomain.view.details.overrides.heroImage': 'Product Image:',
  'productsDomain.view.details.overrides.description': 'Description:',
  'productsDomain.view.details.overrides.submit': 'Submit',
  'productsDomain.view.details.overrides.cancel': 'Cancel',
  'productsDomain.view.details.overrides.title': 'Temporary Product Overrides',
  'productsDomain.view.details.overrides.errorMsg':
    'There was an error submitting your change. Please try again later.',
  'productsDomain.view.details.titles.productInfoDescription':
    'Only certain attributes can be overriden for this product.',
  'productsDomain.view.details.titles.images': 'Images',

  'productsDomain.view.details.sections.images.primaryImages': 'Primary Images',
  'productsDomain.view.details.sections.images.secondaryImages': 'Secondary Images',
  // image types
  'productsDomain.view.details.sections.images.hero': 'Hero',
  'productsDomain.view.details.sections.images.angleLeft': 'Left',
  'productsDomain.view.details.sections.images.angleRight': 'Right',
  'productsDomain.view.details.sections.images.angleBack': 'Back',
  'productsDomain.view.details.sections.images.angleTop': 'Top',
  'productsDomain.view.details.sections.images.ingredients': 'Ingredients',
  'productsDomain.view.details.sections.images.nutrition': 'Nutrition',
  'productsDomain.view.details.sections.images.supplements': 'Supplements',
  'productsDomain.view.details.sections.images.drugs': 'Drugs',
  'productsDomain.view.details.sections.images.lifestyle': 'Lifestyle',
  // end image types
  'productsDomain.view.details.sections.images.notProvided': 'Not provided',
  'productsDomain.view.edit-images.secondaryImages': 'Secondary Images (Optional)',
  'productsDomain.view.edit-images.images.errors': 'You must upload a new image.',
  'productsDomain.view.edit-images.images.form.error':
    'One or more fields have errors. Please fix before proceeding.',
  'productsDomain.view.edit-images.images.form.ready': 'No conflicts found. Good to go!',
  'productsDomain.view.edit-images.images.form.processingTime':
    'This change will be effective in 8 hours',
  'productsDomain.view.edit-images.images.form.save': 'Save',
  'productsDomain.view.edit-images.images.form.updated':
    'Image edit updated. This change will be live in 8 hours!',
  'productsDomain.view.edit-images.images.form.saveError':
    'Unexpected error while saving images, please try again later.',

  'productsDomain.search.title': 'Product search',
  'productsDomain.search.actions.reportAProblem': 'Report a problem',
  'productsDomain.search.actions.actionsItem': 'Actions',
  'productsDomain.search.actions.reportAProblemItem': 'Report A Problem',
  'productsDomain.search.actions.bulkEditItem': 'Bulk Edit',
  'productsDomain.search.actions.editInAssortmentItem': "Edit 'In Assortment'",
  'productsDomain.search.actions.disableAvailabilityItem': 'Disable Availability Prediction',
  'productsDomain.search.reportAProblem.modal.placeholders.description': 'Description',
  'productsDomain.search.reportAProblem.modal.placeholders.problemType': 'Problem Type',
  'productsDomain.search.reportAProblem.modal.title':
    'Report a problem with {count, plural, one {# product} other {# products}}',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_ALCOHOL.label': 'Product alcohol',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_ALCOHOL.description':
    'Discrepancy with ABV, alcohol flag (products that are not triggering the age verification will be prioritized).',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_BRAND.label': 'Product brand',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_BRAND.description':
    'Discrepancy with the Brand being displayed.',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_CHARACTERISTICS.label':
    'Product characteristics',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_CHARACTERISTICS.description':
    'Discrepancy with product claims (ex. organic, Gluten free, sugar free, etc).',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_DETAILS.label':
    'Product name or description',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_DETAILS.description':
    'Product name or description is incorrect.',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_IMAGE.label': 'Product image',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_IMAGE.description':
    'Incorrect product image.',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_OTHER.label': 'Product other',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_OTHER.description':
    "If your issue doesn't fall under any of the above, you can select Others. This is NOT to be used as the combination of existing problem types above. A description must be provided.",
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_SIZE.label': 'Product size',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_SIZE.description':
    'Discrepancy with size, unit count, quantity, unit of measure.',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_TAXONOMY.label': 'Product taxonomy',
  'productsDomain.search.reportAProblem.PROBLEM_TYPE_PRODUCT_TAXONOMY.description':
    'Incorrect taxonomy. Incorrect category in the storefront.',
  'productsDomain.search.edit.product': 'Edit product',
  'productsDomain.search.edit.title':
    'Set primary department and aisle for {count, plural, one {# product} other {# products}}',
  'productsDomain.search.edit.selectDepartment': 'Select department',
  'productsDomain.search.edit.selectAisle': 'Select aisle',
  'productsDomain.search.edit.existingAislesNote':
    'Note that existing aisles will be preserved as secondary aisles.',
  'productsDomain.search.edit.successNotification.header': 'All set!',
  'productsDomain.search.edit.successNotification.content':
    '{count} {count, plural, one {product was} other {products were}} updated.',
  'productsDomain.search.edit.errorNotification.header': 'Something went wrong',
  'productsDomain.search.edit.errorNotification.content': 'The selected items were not updated.',
  'productsDomain.search.edit.inAssortment': 'Edit Assortment',
  'productsDomain.search.edit.inAssortment.title': "Edit 'in assortment'",
  'productsDomain.search.edit.inAssortment.description':
    'By marking ‘not in assortment’, this product will not show up in the search result or be part of any collections on your storefront.',
  'productsDomain.search.edit.inAssortment.productDetail': 'Product Detail',
  'productsDomain.search.edit.inAssortment.upc': 'UPC',
  'productsDomain.search.edit.inAssortment.productCount':
    'This will edit {count} {count, plural, one {product} other {products}}.',
  'productsDomain.search.edit.inAssortment.ongoing': 'Ongoing',
  'productsDomain.search.edit.inAssortment.selectDateRange': 'Select a date range',
  'productsDomain.search.edit.inAssortment.updateError':
    'Something went wrong, please try again later.',
  'productsDomain.search.edit.inAssortment.updateSuccess':
    'In assortment updated. This change will be live in 24 hours.',
  'productsDomain.search.edit.availabilityScoreOverride.title': 'Disable Availability Prediction',
  'productsDomain.search.edit.availabilityScoreOverride.description.part1':
    'By disabling availability prediction, the selected product, if purchasable, will always considered to be in stock.',
  'productsDomain.search.edit.availabilityScoreOverride.description.part2':
    'To instead override the availability prediction for individual store locations of a product, please go to the Store Locations tab for that product.',
  'productsDomain.search.edit.availabilityScoreOverride.description.part3':
    'Disabling the availability prediction must have a start and end date, and the end date cannot be more than 3 months away from the start date.',
  'productsDomain.search.edit.availabilityScoreOverride.updateSuccess':
    'Disable successful. This change will be live in 6 hours.',
  'productsDomain.search.edit.availabilityScoreOverride.dateLimitError':
    'Availability prediction cannot be disabled for more than 90 days.',
  'productsDomain.search.edit.availabilityScoreOverride.countTooHighError':
    'Too many products with disable active.',
  'productsDomain.search.edit.availabilityScoreOverride.updateError':
    'Something went wrong, please try again later.',
  'productsDomain.search.filters.departmentsAndAisles': 'Departments & Aisles',
  'productsDomain.search.filters.productCreationDate': 'Product creation date',
  'productsDomain.search.filters.productCreated': 'Product created',
  'productsDomain.search.filters.departmentsAndAisles.all': 'Show all',
  'productsDomain.search.filters.isPrivateLabelQuestion': 'Is private label product?',
  'productsDomain.search.filters.hasPrimaryImageQuestion': 'Has primary image?',
  'productsDomain.search.filters.inAssortment': 'Is in assortment?',
  'productsDomain.search.yes': 'Yes',
  'productsDomain.search.no': 'No',

  'productsDomain.search.hints.name': 'bananas, granola...',

  'productsDomain.search.columns.productDisplayName': 'Product name',
  'productsDomain.search.columns.name': 'Name',
  'productsDomain.search.columns.upc': 'UPC',
  'productsDomain.search.columns.rrcsku': 'RRC/SKU',
  'productsDomain.search.columns.productId': 'Product Id',
  'productsDomain.search.columns.primaryDeptAisle': 'Primary Department / Aisle',
  'productsDomain.search.columns.priceLow': 'Price (low)',
  'productsDomain.search.columns.priceHigh': 'Price (high)',
  'productsDomain.search.columns.availability': 'Availability (stores)',
  'productsDomain.search.columns.image': 'Image',
  'productsDomain.search.columns.created': 'Created',
  'productsDomain.search.columns.updated': 'Updated',
  'productsDomain.search.columns.inAssortment': 'In Assortment',
  'productsDomain.search.columns.availabilityScoreOverride':
    'Disable Availability Prediction (stores)',
  'productsDomain.search.columns.size': 'Size',
  'productsDomain.search.noResults': 'No results',
  'productsDomain.search.error': 'An error occurred while fetching your results.',
  'productsDomain.search.error.tooManyTerms':
    'You searched more than 100 codes, you can only search for 100 codes at a time, but all results can be Downloaded using the Download button',
  'productsDomain.search.error.invalidProductId': 'Invalid product ID was entered',
  'productsDomain.search.clearAll': 'Clear All',
  'productsDomain.search.clear': 'Clear',
  'productsDomain.search.failedToLoad': 'Data failed to load - click to retry',
  'productsDomain.search.dateUnavailable': 'No date available.',
  'productsDomain.search.notAvailable': 'N/A',
  'productsDomain.search.makeSearch':
    'Search products by typing in the search bar above and pressing enter',
  'productsDomain.search.makeSearch.upc.tip':
    'You can only search for 100 codes at a time, but all results are included in the download',
  'productsDomain.search.makeSearch.sku.tip':
    'You can only search for 100 codes at a time, but all results are included in the download',

  'productsDomain.search.download.product': 'Download Retailer Results',
  'productsDomain.search.download.item': 'Download Store Results',
  'productsDomain.search.download.confirmation':
    'Downloading CSV (this could take a couple minutes)',
  'productsDomain.search.download.validation': 'Invalid search term for download',
  'productsDomain.search.download.error': 'Error triggering download',
  'productsDomain.search.download.timeout': 'Downloading file timed out',
  'productsDomain.search.download.block':
    'Leaving the page will cancel the download. Are you sure you want to leave?',

  'productsDomain.itemAnomaly.reports.title': 'Item anomaly reports',
  'productsDomain.itemAnomaly.reports.sidebar.title': 'Available Reports',
  'productsDomain.itemAnomaly.reports.sidebar.hiddenItems': 'Hidden Items',
  'productsDomain.itemAnomaly.reports.filter.placeholder': 'Choose Date Range...',
  'productsDomain.itemAnomaly.reports.table.noData': 'No Data',
  'productsDomain.itemAnomaly.reports.putItemBack.button': 'Put Items Back',
  'productsDomain.itemAnomaly.reports.hiddenItems.columns.date': 'Date',
  'productsDomain.itemAnomaly.reports.hiddenItems.columns.count': 'Hidden Items Count',
  'productsDomain.itemAnomaly.reports.hiddenItems.download': 'Download Report',
  'productsDomain.itemAnomaly.reports.hiddenItems.download.confirmation':
    'Downloading CSV (this could take a couple minutes)',
  'productsDomain.itemAnomaly.reports.hiddenItems.download.error': 'Error triggering download',
  'productsDomain.itemAnomaly.reports.hiddenItems.download.timeout': 'Downloading file timed out',
  'productsDomain.itemAnomaly.putItemBack.modal.title': 'Put Items Back Upload',
  'productsDomain.itemAnomaly.putItemBack.modal.uploadZone.text':
    'Drag and drop a CSV here, or {link}',
  'productsDomain.itemAnomaly.putItemBack.modal.uploadZone.linkText': 'browse your files',
  'productsDomain.itemAnomaly.putItemBack.modal.wrongFileFormat': '{name} is not a CSV file.',
  'productsDomain.itemAnomaly.putItemBack.modal.uploadError': 'Error occurred during uploading.',
  'productsDomain.itemAnomaly.putItemBack.modal.processing': 'Processing...',
  'productsDomain.itemAnomaly.putItemBack.modal.uploadComplete':
    'Upload complete. Please stand by...',
  'productsDomain.itemAnomaly.putItemBack.modal.requirements.title': 'Requirements',
  'productsDomain.itemAnomaly.putItemBack.modal.requirements.0':
    'The CSV should contain an "item_id" column, with a list of item IDs of items that should be brought back up.',

  'productsDomain.attributeOverride.editAttribute.title': 'Override Product Info',
  'productsDomain.attributeOverride.attributes.label': 'Attribute',
  'productsDomain.attributeOverride.attributes.description': 'Attribute Name',
  'productsDomain.attributeOverride.attributes.placeholder': 'Attribute Name',
  'productsDomain.attributeOverride.sources.placeholder': 'Sources To Override',
  'productsDomain.attributeOverride.sources.label': 'Source',
  'productsDomain.attributeOverride.sources.description': 'Select source you want to override',
  'productsDomain.attributeOverride.overrideType.placeholder': 'Exclude/Replace',
  'productsDomain.attributeOverride.overrideType.exclude': 'Exclude',
  'productsDomain.attributeOverride.overrideType.replace': 'Replace',
  'productsDomain.attributeOverride.action.label': 'Action',
  'productsDomain.attributeOverride.action.description':
    'Select whether you want to hide/set value',
  'productsDomain.attributeOverride.saveChanges.button': 'Save Changes',
  'productsDomain.attributeOverride.cancel.button': 'Cancel',
  'productsDomain.attributeOverride.editExpiryDate.label': 'Effective Till',
  'productsDomain.attributeOverride.expiryOption.label':
    'No, end date, this is a permanent override.',
  'productsDomain.attributeOverride.comment.label': 'Comment',
  'productsDomain.attributeOverride.comment.placeholder': 'Comment',
  'productsDomain.attributeOverride.ticketEntry.label': 'Ticket Entry',
  'productsDomain.attributeOverride.ticketEntry.placeholder': 'Ticket Entry (optional)',
  'productsDomain.attributeOverride.affect.label': 'Override will take up to 6 hours to apply',
  'productsDomain.attributeOverride.acknowledgement.label':
    'I acknowledge that this override will be permanent',
  'productsDomain.attributeOverride.value.label': 'Value',
  'productsDomain.attributeOverride.value.placeholder': 'Please select the attribute',
  'productsDomain.attributeOverride.value.dropdown.option.true': 'True',
  'productsDomain.attributeOverride.value.dropdown.option.false': 'False',
  'productsDomain.attributeOverride.success.message':
    'Edit complete, please allow couple hours for edit to populate',
  'productsDomain.attributeOverride.loadingError.message':
    'Unexpected error happened while loading the Attribute Edit view, please try again later.',
  'productsDomain.attributeOverride.createOverride.errorMessage.generic':
    'Something went wrong while editing the attribute, please try again later',
  'productsDomain.attributeOverride.createOverride.errorMessage.duplicateEntry':
    'This attribute override already exists',
  'productsDomain.view.tabs.attributeOverrides.list.column.attributeName': 'Attribute Name',
  'productsDomain.view.tabs.attributeOverrides.list.column.createdBy': 'Created By',
  'productsDomain.view.tabs.attributeOverrides.list.column.reason': 'Reason',
  'productsDomain.view.tabs.attributeOverrides.list.column.expiryDate': 'Expiry Date',
  'productsDomain.view.tabs.attributeOverrides.list.column.overrideType': 'Override Type',
  'productsDomain.view.tabs.attributeOverrides.list.column.overrideValue': 'Override Value',
  'productsDomain.view.tabs.attributeOverrides.list.column.sourceType': 'Source Type',
  'productsDomain.view.tabs.attributeOverrides.list.column.sourceValue': 'Source Value',
  'productsDomain.view.tabs.attributeOverrides.list.column.ticketUrl': 'Ticket URL',
  'productsDomain.view.tabs.attributeOverrides.loadingError.message':
    'Error while loading the attributes, please try again later.',
  'productsDomain.view.tabs.attributeOverrides.unknownAttributeName': 'Unknown attribute',
  'productsDomain.attributeOverride.sources.allSources': 'All Sources',
  'productsDomain.view.tabs.attributeOverrides.noOverrides':
    "Attribute overries don't exist for this product",

  'productsDomain.view.collections.alert': `Only manually defined collections are displayed in the table below`,
  'productsDomain.view.collections.table.empty': `No collections found`,
  'productsDomain.view.collections.table.view': `View`,
  'productsDomain.view.collections.table.collectionId': `Collection ID`,
  'productsDomain.view.collections.table.collectionName': `Name`,
  'productsDomain.view.tabs.collections': `Collections`,
} as const

export default EN_US
