import { type AccessControlConfig } from '../../components/AccessControl/accessControl.utils'
import { Permission } from '../../common/types'

export const StoreGroupsSectionAccessControl: AccessControlConfig = {
  permissions: Permission.ManageStoreGroups,
}

// Store Operations is the new nav group.
// Delete this once `dashboard_store_ops_nav` feature toggle is fully enabled.
export const StoreGroupsSectionNavigationControl: AccessControlConfig = {
  ...StoreGroupsSectionAccessControl,
}
