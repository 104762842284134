import { useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import { type SelectProps, type BaseOption, type SelectOptionsType } from './select.types'
import { isGroupedOptionsList } from './select.utils'

export const useSelectBehaviour = <TOption extends BaseOption>({
  error,
  errorMessage,
  loading,
  loadingMessage,
  options,
  sortBy: sortByValue,
}: SelectProps<TOption>) => {
  const normalizedSortByValue = useMemo(() => {
    if (!sortByValue) return undefined

    return Array.isArray(sortByValue) ? sortByValue : [sortByValue]
  }, [sortByValue])

  // Sorts and filters options
  const filteredOptions: SelectOptionsType<TOption> = useMemo(() => {
    if (!normalizedSortByValue) return options

    if (!isGroupedOptionsList(options)) {
      return sortBy(options, normalizedSortByValue)
    }

    // Grouped List
    // Sort options within groups if necessary
    return options.map(group => {
      const nextGroupedOptions = sortBy(group.options, normalizedSortByValue)

      return {
        ...group,
        options: nextGroupedOptions,
      }
    })
  }, [normalizedSortByValue, options])

  const reactSelectOverrides = useMemo(() => {
    if (error) {
      return {
        placeholder: errorMessage || '',
        value: null,
        isDisabled: true,
      }
    }

    if (loading) {
      return {
        placeholder: loadingMessage || '',
        value: null,
        isLoading: true,
        isDisabled: true,
      }
    }

    return {}
  }, [error, errorMessage, loading, loadingMessage])

  return {
    filteredOptions,
    reactSelectOverrides,
  }
}
