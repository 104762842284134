import React, { type FunctionComponent, type CSSProperties } from 'react'
import { css, type CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import { INSTACART_SANS_THEME_TYPOGRAPHY } from '@instacart/ids-core'
import { LoadingLockupTextBase } from '@instacart/ids-customers'
import { colors } from '../../../foundation/colors'
import { fontWeight } from '../../../foundation/fontWeight'
import { spacing } from '../../../foundation/spacing'
import {
  GRID_ROW_HEIGHT,
  GRID_FONT_SIZE,
  GRID_HEADER_ROW_HEIGHT,
  GRID_COMPACT_ROW_HEIGHT,
  GRID_COMPACT_HEADER_ROW_HEIGHT,
} from './grid.constants'
import { getBodyRowHeight, getHeaderRowHeight } from './gridCompact.utils'

const sharedCellStyles = css({
  fontSize: GRID_FONT_SIZE,
  color: colors.GRAYSCALE.X70,
  verticalAlign: 'middle',
  paddingLeft: spacing.X8,
  paddingRight: spacing.X8,
  paddingTop: spacing.X4,
  paddingBottom: spacing.X4,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  minWidth: 0,
  backgroundColor: colors.GRAYSCALE.X0,
})

export const gridBodyCellStyles = css(sharedCellStyles, {
  ...INSTACART_SANS_THEME_TYPOGRAPHY['bodyMedium2'],
})

export const gridCompactBodyCellStyles = css({
  paddingLeft: spacing.X16,
  paddingRight: spacing.X16,
  paddingTop: spacing.X8,
  paddingBottom: spacing.X8,
  ...INSTACART_SANS_THEME_TYPOGRAPHY['bodySmall2'],
})

export const gridHeaderCellStyles = css(sharedCellStyles, {
  alignContent: 'left',
  fontWeight: fontWeight.SEMIBOLD,
  whiteSpace: 'normal',
})
export const gridCompactHeaderCellStyles = css({
  paddingLeft: spacing.X16,
  paddingRight: spacing.X16,

  ...INSTACART_SANS_THEME_TYPOGRAPHY['subtitleSmall'],
})

export const gridSortableCellStyles = css({})

export const gridSortableHeaderStyles = css({
  '&:hover': {
    backgroundColor: colors.GRAYSCALE.X10,
  },
})

export const gridSortedCellStyles = css({})

export const gridSortedHeaderCellStyles = css({
  backgroundColor: colors.GRAYSCALE.X10,
})

const sharedRowStyles = css({
  height: GRID_ROW_HEIGHT,
  verticalAlign: 'middle',
  borderBottomWidth: 1,
})

export const gridBodyRowStyles = css(sharedRowStyles, {
  borderBottomStyle: 'solid',
  borderBottomColor: colors.GRAYSCALE.X20,
  backgroundColor: colors.GRAYSCALE.X0,
})

export const gridCompactBodyRowStyles = css(sharedRowStyles, {
  height: GRID_COMPACT_ROW_HEIGHT,
})

export const gridHeaderRowStyles = css(sharedRowStyles, {
  height: GRID_HEADER_ROW_HEIGHT,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: colors.GRAYSCALE.X20,
})

export const gridCompactHeaderRowStyles = css({
  height: GRID_COMPACT_HEADER_ROW_HEIGHT,
})

export const gridLoadingRowStyles = css(sharedRowStyles, {
  paddingLeft: spacing.X8,
  paddingRight: spacing.X16,
  paddingTop: spacing.X12,
  paddingBottom: spacing.X16,
})

export const gridOuterStyles = css({
  minWidth: '100%',
  backgroundColor: colors.GRAYSCALE.X0,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
})

const sharedBodyContentStyles: CSSProperties = {
  overflowY: 'scroll',
}

// Is a style object prop instead of an emotion css object
export const windowStyleOverrides: CSSProperties = {
  ...sharedBodyContentStyles,
}

export const gridErrorMessageStyles = css({
  ...(sharedBodyContentStyles as CSSObject),
  height: '100%',
})

export const gridEmptyStateStyles = css({
  ...(sharedBodyContentStyles as CSSObject),
  height: '100%',
})

export const GridStickyRow = styled.div<{ index: number; compact?: boolean }>(
  {
    position: 'sticky',
    zIndex: 4,
    left: 0,
    width: 'fit-content',
    minWidth: '100%',
  },
  ({ index, compact }) => ({
    top: index * getHeaderRowHeight(compact),
    height: getHeaderRowHeight(compact),
  })
)

export const GridRowsContainer = styled.div<{ rowOffset: number; compact?: boolean }>(
  {
    position: 'relative',
  },
  ({ rowOffset, compact }) => ({
    paddingTop: getHeaderRowHeight(compact) * rowOffset,
  })
)

export const GridCellLoading: FunctionComponent = ({ compact }: { compact?: boolean }) => (
  <div css={{ flex: 1 }} data-testid="grid-loading-cell">
    <LoadingLockupTextBase css={{ height: getBodyRowHeight(compact) / 2 }} />
  </div>
)
