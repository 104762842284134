import React, { lazy, type FunctionComponent } from 'react'
import { Redirect } from 'react-router'
import { URL_GENERATED_REPORTS } from '../../../utils/routing/routes'
import RetailerSwitch from '../../components/RetailerSwitch'
import RetailerRoute from '../../components/RetailerRoute'
const GeneratedReportsList = lazy(() => import('./components/list/GeneratedReportsList'))
const GeneratedReportsAdd = lazy(() => import('./components/add/GeneratedReportsAdd'))

export const GeneratedReportsSection: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <RetailerSwitch>
    <RetailerRoute
      exact
      route="generated-reports"
      component={GeneratedReportsList}
      scopePicker={{
        titles: ['generatedReports.title'],
      }}
    />
    <RetailerRoute exact route="generated-reports-add" component={GeneratedReportsAdd} />
    <Redirect to={URL_GENERATED_REPORTS} />
  </RetailerSwitch>
)
