export enum Permission {
  // Legacy: Deprecate. Not used
  CampaignManagement = 'campaign-management',
  CampaignManagementView = 'campaign_management.view',
  CampaignManagementModify = 'campaign_management.modify',
  DraftSelectionView = 'draft_selection.view',
  DraftSelectionModify = 'draft_selection.modify',
  // same permission in retailer collection flyers access
  Flyers = 'flyers.view',
  PageBuilderModify = 'page_builder.modify',
  PageBuilderView = 'page_builder.view',
}
