import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const insightsPortalCollectionInsightsAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.Collections],
    featureToggles: [FeatureToggle.InsightsCollectionInsights],
    warehouseFeatureToggles: [FeatureToggle.RetailerCollectionsV2Ux],
  },
]

export const insightsPortalCollectionsIAMilestone2AccessControl: DomainAccessControlConfig = {
  featureToggles: [
    FeatureToggle.InsightsPortalIAMilestone2,
    FeatureToggle.InsightsCollectionInsights,
  ],
  permissions: [Permission.Collections],
}
