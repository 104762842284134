import { isString } from 'util'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataFetchingState<TData = {}> =
  | {
      data?: TData
      error: Error
      loading: false
    }
  | {
      data?: TData
      error: undefined
      loading: true
    }
  | {
      data: TData
      error: undefined
      loading: false
    }

type DataFetchingOptions<TData> = { previousState?: DataFetchingState<TData> }

export const dataFetchingError = <TData extends object>(
  errorInstance?: Error | string,
  opts: DataFetchingOptions<TData> = {}
): DataFetchingState<TData> => {
  let error: Error

  if (errorInstance === undefined || isString(errorInstance)) {
    error = new Error(errorInstance as string)
  } else {
    error = errorInstance
  }

  return {
    data: undefined,
    ...(opts.previousState || {}),
    loading: false,
    error,
  }
}

export const dataFetchingLoading = <TData extends object>(
  opts: DataFetchingOptions<TData> = {}
): DataFetchingState<TData> => ({
  data: undefined,
  ...(opts.previousState || {}),
  error: undefined,
  loading: true,
})

export const dataFetchingSuccess = <TData extends object>(
  data: TData,
  opts: DataFetchingOptions<TData> = {}
): DataFetchingState<TData> => ({
  ...(opts.previousState || {}),
  error: undefined,
  loading: false,
  data,
})
