import React, { type FunctionComponent } from 'react'
import Table from '../../../../../../../../components/Table/Table'
import { type StoreLocationDetails } from '../../utils/locations.hooks'
import { useStoreGroupFormLocationTableColumns } from './utils/tableColumns.hooks'
import { useStoreGroupsFormLocationPagination } from './utils/tablePagination.hooks'

interface Props {
  locations: StoreLocationDetails[]
  isLocationSelected: (id: string) => boolean
  handleToggleLocation: (id: string) => void
  showActionButtons?: boolean
}

const getTableProps = () => ({
  style: {
    height: 'auto',
  },
})

export const StoreGroupsFormLocationSelectorTable: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ locations, isLocationSelected, handleToggleLocation, showActionButtons = true }) => {
  const columns = useStoreGroupFormLocationTableColumns({
    isLocationSelected,
    handleToggleLocation,
    showActionButtons,
  })

  const { data, meta, onPageChange, minRows } = useStoreGroupsFormLocationPagination({
    locations,
  })

  return (
    <Table
      columns={columns}
      data={data}
      meta={meta}
      onPageChange={onPageChange}
      getTableProps={getTableProps}
      minRows={minRows}
      paginated
    />
  )
}
