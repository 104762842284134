export enum Permission {
  /**
   * Permission used to grant access for viewing store hours.
   */
  WarehouseLocationHoursView = 'warehouse_location_hours.view',

  /**
   * Permissions used to grant access for modifying store hours.
   */
  WarehouseLocationHoursModify = 'warehouse_location_hours.modify',
}
