import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_EVERSIGHT = `${URL_RETAILER_SCOPED}/eversight`
// @hygen:inject scoped-urls

const URL_ADMIN_EVERSIGHT = `${URL_APP_ADMIN}/eversight`
// @hygen:inject admin-urls

export type DashRouteSet = DashRouteType<'eversight'> | DashRouteType<'app-admin-eversight'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  eversight: URL_EVERSIGHT,
  'app-admin-eversight': URL_ADMIN_EVERSIGHT,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
