import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { notificationsRouter } from './routing/notificationsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { notificationsAccessControl } from './access-control/notificationsAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'notifications',
  totem: {
    entity: 'notifications-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [notificationsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Settings,
      attachToV2: NavEntryPoint.UserMenuUserManagement,
      subNavItems: [
        {
          type: 'item',
          labelId: 'notificationsDomain.list.bannerNotifications.pageTitle',
          // the name of the route the user should be brought to when clicking
          // on this navigation button
          route: 'banner-notifications-list',
          // the accessControl configuration that determins if this nav item
          // should be displayed to the user
          accessControl: notificationsAccessControl,
          positionNavV2: 3,
        },
        {
          type: 'item',
          labelId: 'notificationsDomain.list.platformNotifications.pageTitle',
          // the name of the route the user should be brought to when clicking
          // on this navigation button
          route: 'platform-notifications-list',
          // the accessControl configuration that determins if this nav item
          // should be displayed to the user
          accessControl: notificationsAccessControl,
          positionNavV2: 3.1,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
