import React, { type CSSProperties, type FunctionComponent, useCallback, useMemo } from 'react'
import { Checkbox, colors, spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
// eslint-disable-next-line workspaces/no-relative-imports
import { useDomainMessages } from '../../../../../../../../../domains/products/src/utils/domain/intl'
import { ProductLocationsTableColumnSortIcon } from './ProductLocationsTableColumnSortIcon'
import useAccessControl from "../../../../../components/AccessControl/useAccessControl.ts";

export enum ProductLocationsTableColumnSortEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ProductLocationsTableColumnSortState {
  column: string
  order: ProductLocationsTableColumnSortEnum
}

export interface ProductLocationTableColumnParameter {
  key: string
  element: Element | JSX.Element
  styles?: CSSProperties
  onClick?: () => void
}

export const ProductLocationsTableTh = styled.th({
  padding: spacing.X12,
  paddingLeft: spacing.X16,
  paddingRight: spacing.X16,
  fontWeight: 600,
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  boxShadow: `inset 0 1px 0 ${colors.GRAYSCALE.X20}, inset 0 -4px 0 ${colors.GRAYSCALE.X20}`,
  zIndex: 1,
  position: 'sticky',
  top: 0,
  textAlign: 'left',
  cursor: 'pointer',
  userSelect: 'none',
  width: '1%',
  minWidth: '130px',
  background: colors.GRAYSCALE.X0,
  ':hover': {
    background: colors.GRAYSCALE.X20,
  },
})

interface Props {
  areAllItemsSelected: () => boolean
  handleSelectAllClick: () => void
  sortState: ProductLocationsTableColumnSortState
  setSortState: (sortState: ProductLocationsTableColumnSortState) => void
  showAvailabilityScoreOverride: boolean
}

export const ProductLocationsTableColumns: FunctionComponent<React.PropsWithChildren<Props>> = ({
  areAllItemsSelected,
  handleSelectAllClick,
  setSortState,
  sortState,
  showAvailabilityScoreOverride,
}) => {
  const isAllSelected = areAllItemsSelected()
  const hasAccess = useAccessControl()
  const sproutsEnabled = hasAccess([
    {
      // @ts-expect-error, throwaway feature toggle
      warehouseFeatureToggles: ['rt_catalog_sprouts_imporved_product_search'],
    },
  ])

  const i18n = useDomainMessages({
    store: 'productsDomain.view.storeLocations.table.columns.store',
    available: 'productsDomain.view.storeLocations.table.columns.available',
    inAssortment: 'productsDomain.view.storeLocations.table.columns.inAssortment',
    availabilityScoreOverride:
      'productsDomain.view.storeLocations.table.columns.availableScoreOverride',
    taxable: 'productsDomain.view.storeLocations.table.columns.taxable',
    perWeightAdjusted: 'productsDomain.view.storeLocations.table.columns.perWeightAdjusted',
    standardPrice: 'productsDomain.view.storeLocations.table.columns.standardPrice',
    displayedPrice: 'productsDomain.view.storeLocations.table.columns.displayedPrice',
    pricedBy: 'productsDomain.view.storeLocations.table.columns.pricedBy',
    actions: 'productsDomain.view.storeLocations.table.columns.actions',
  })

  const onColumnClick = useCallback(
    (column: string) => {
      setSortState({
        column,
        order:
          sortState.column != column || sortState.order === ProductLocationsTableColumnSortEnum.ASC
            ? ProductLocationsTableColumnSortEnum.DESC
            : ProductLocationsTableColumnSortEnum.ASC,
      })
    },
    [setSortState, sortState]
  )

  const columns: ProductLocationTableColumnParameter[] = useMemo(() => {
    let columns: ProductLocationTableColumnParameter[] = [
      {
        key: 'select',
        element: (
          <Checkbox
            id="checkboxSelectAll"
            checked={isAllSelected}
            onChange={() => handleSelectAllClick()}
          />
        ),
        styles: { minWidth: 0 },
        onClick: () => handleSelectAllClick(),
      },
      {
        key: 'store',
        element: (
          <>
            {i18n.store}
            <ProductLocationsTableColumnSortIcon state={sortState} attribute={'inventory_area'} />
          </>
        ),
        styles: { width: '100%' },
        onClick: () => onColumnClick('inventory_area'),
      },
      {
        key: 'available',
        element: (
          <>
            {i18n.available}
            <ProductLocationsTableColumnSortIcon state={sortState} attribute={'available'} />
          </>
        ),
        onClick: () => onColumnClick('available'),
      },
    ]

    if (sproutsEnabled) {
      columns.push({
        key: 'in-assortment',
        element: (
          <>
            {i18n.inAssortment}
            <ProductLocationsTableColumnSortIcon state={sortState} attribute={'in_assortment'} />
          </>
        ),
        onClick: () => onColumnClick('available'),
      })
    }

    if (showAvailabilityScoreOverride) {
      columns.push({
        key: 'availabilityScoreOverride',
        element: (
          <>
            {i18n.availabilityScoreOverride}
            <ProductLocationsTableColumnSortIcon
              state={sortState}
              attribute={'availabilityScoreOverride'}
            />
          </>
        ),
        onClick: () => onColumnClick('availabilityScoreOverride'),
      })
    }
    columns = [
      ...columns,
      ...[
        {
          key: 'taxable',
          element: (
            <>
              {i18n.taxable}
              <ProductLocationsTableColumnSortIcon state={sortState} attribute={'taxable'} />
            </>
          ),
          onClick: () => onColumnClick('taxable'),
        },
        {
          key: 'perWeightAdjusted',
          element: (
            <>
              {i18n.perWeightAdjusted}
              <ProductLocationsTableColumnSortIcon state={sortState} attribute={'product_type'} />
            </>
          ),
          onClick: () => onColumnClick('product_type'),
        },
        {
          key: 'standardPrice',
          element: (
            <>
              {i18n.standardPrice}
              <ProductLocationsTableColumnSortIcon
                state={sortState}
                attribute={'cost_price_per_unit'}
              />
            </>
          ),
          onClick: () => onColumnClick('cost_price_per_unit'),
        },
        {
          key: 'displayedPrice',
          element: (
            <>
              {i18n.displayedPrice}
              <ProductLocationsTableColumnSortIcon state={sortState} attribute={'price'} />
            </>
          ),
          onClick: () => onColumnClick('price'),
        },
        {
          key: 'pricedBy',
          element: (
            <>
              {i18n.pricedBy}
              <ProductLocationsTableColumnSortIcon state={sortState} attribute={'pricedBy'} />
            </>
          ),
          onClick: () => onColumnClick('pricedBy'),
        },
        {
          key: 'actions',
          element: <>{i18n.actions}</>,
        },
      ],
    ]
    return columns
  }, [
    handleSelectAllClick,
    i18n,
    isAllSelected,
    onColumnClick,
    sortState,
    showAvailabilityScoreOverride,
  ])

  return (
    <>
      {columns.map(column => {
        return (
          <ProductLocationsTableTh
            key={column.key}
            style={column.styles ?? {}}
            onClick={column?.onClick}
          >
            <>{column.element}</>
          </ProductLocationsTableTh>
        )
      })}
    </>
  )
}
