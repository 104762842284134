import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { storeHoursBulkRouter } from './routing/storeHoursBulkRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { storeHoursBulkAccessControlView } from './access-control/storeHoursBulkAccess.configuration'
import { holidaysListAccessControl } from './access-control/holidayListAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'store-hours-bulk',
  totem: {
    entity: 'store-hours-bulk-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [storeHoursBulkRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.StoreOperations,
      attachToV2: NavEntryPoint.OperationsGeneral,
      subNavItems: [
        {
          type: 'item',
          labelId: 'storeHoursBulkDomain.title',
          // the name of the route the user should be brought to when clicking
          // on this navigation button
          route: 'store-hours-bulk-list',
          position: 5,
          positionNavV2: 3,
          // the accessControl configuration that determines if this nav item
          // should be displayed to the user
          accessControl: storeHoursBulkAccessControlView,
        },
        {
          type: 'item',
          labelId: 'storeHoursBulkDomain.holidays.title',
          // the name of the route the user should be brought to when clicking
          // on this navigation button
          route: 'holiday-list',
          position: 6,
          positionNavV2: 4,

          // the accessControl configuration that determines if this nav item
          // should be displayed to the user
          accessControl: holidaysListAccessControl,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
