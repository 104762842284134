import { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  caperAccessControl,
  shelfLabelsAccessControl,
  uposStatusAccessControl,
  uposRetailerAccessControl,
  uposStoreAccessControl,
} from '../access-control/connectedStoresAccess.configuration'
import Toasts from '../toasts'

// Caper
const ConnectedStoresAdminCaperHomePage = lazy(
  () => import('../pages/caper/ConnectedStoresAdminCaperHomePage')
)
const ConnectedStoresAdminCaperConfigPage = lazy(
  () => import('../pages/caper/ConnectedStoresAdminCaperConfigPage')
)
const ConnectedStoresAdminCaperCreateEntityPage = lazy(
  () => import('../pages/caper/ConnectedStoresAdminCaperCreateEntityPage')
)

// Upos
const ConnectedStoresAdminUposStatusPage = lazy(() => import('../pages/upos/status'))
const ConnectedStoresAdminUposRetailersPage = lazy(() => import('../pages/upos/retailers'))
const ConnectedStoresAdminUposProfilesPage = lazy(() => import('../pages/upos/profiles'))
const ConnectedStoresAdminUposStoresPage = lazy(() => import('../pages/upos/stores'))
const ConnectedStoresAdminUposIntegrationsPage = lazy(() => import('../pages/upos/integrations'))

// Shelf Labels
const ConnectedStoresAdminShelfLabelsStorePage = lazy(() => import('../pages/shelf-labels/store'))
const ConnectedStoresAdminShelfLabelsConnectionPage = lazy(
  () => import('../pages/shelf-labels/connection')
)
const ConnectedStoresAdminShelfLabelsSyncStrategyPage = lazy(
  () => import('../pages/shelf-labels/syncStrategy')
)
// @hygen:inject../pages/shelf-labels

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
// All the @ts-ignore are because DomainRoute is not smart enough to know about
// the params.
export const connectedStoresAdminRouter = (
  <DomainRoute route="app-admin-connected-stores">
    <Toasts />
    <DashSwitch>
      {/* Caper */}
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper"
        component={ConnectedStoresAdminCaperHomePage}
        accessControl={caperAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper-config"
        // @ts-ignore
        component={ConnectedStoresAdminCaperConfigPage}
        accessControl={caperAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper-config-create"
        // @ts-ignore
        component={ConnectedStoresAdminCaperCreateEntityPage}
        accessControl={caperAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper-config-partner"
        // @ts-ignore
        component={ConnectedStoresAdminCaperConfigPage}
        accessControl={caperAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper-config-retailer"
        // @ts-ignore
        component={ConnectedStoresAdminCaperConfigPage}
        accessControl={caperAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper-config-store"
        // @ts-ignore
        component={ConnectedStoresAdminCaperConfigPage}
        accessControl={caperAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-caper-config-device"
        // @ts-ignore
        component={ConnectedStoresAdminCaperConfigPage}
        accessControl={caperAccessControl}
      />
      {/* Upos */}
      <DomainRoute
        exact
        route="app-admin-connected-stores-upos-status"
        // @ts-ignore
        component={ConnectedStoresAdminUposStatusPage}
        accessControl={uposStatusAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-upos-retailers"
        // @ts-ignore
        component={ConnectedStoresAdminUposRetailersPage}
        accessControl={uposRetailerAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-upos-profiles"
        // @ts-ignore
        component={ConnectedStoresAdminUposProfilesPage}
        accessControl={uposRetailerAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-upos-stores"
        // @ts-ignore
        component={ConnectedStoresAdminUposStoresPage}
        accessControl={uposStoreAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-upos-integrations"
        // @ts-ignore
        component={ConnectedStoresAdminUposIntegrationsPage}
        accessControl={uposStoreAccessControl}
      />
      {/* Shelf Labels */}
      <DomainRoute
        exact
        route="app-admin-connected-stores-shelf-labels-stores"
        // @ts-ignore
        component={ConnectedStoresAdminShelfLabelsStorePage}
        accessControl={shelfLabelsAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-shelf-labels-connections"
        // @ts-ignore
        component={ConnectedStoresAdminShelfLabelsConnectionPage}
        accessControl={shelfLabelsAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-connected-stores-shelf-labels-sync-strategies"
        // @ts-ignore
        component={ConnectedStoresAdminShelfLabelsSyncStrategyPage}
        accessControl={shelfLabelsAccessControl}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
