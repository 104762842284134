const EN_US = {
  'draftManagementDomain.title': 'Draft Management',

  'draftManagementDomain.headers.currentValue': 'Current',
  'draftManagementDomain.headers.newValue': 'New',
  'draftManagementDomain.headers.currentlyPublished': 'Currently published',
  'draftManagementDomain.headers.newChanges': 'New changes',
  'draftManagementDomain.headers.confirmDraftChanges': 'Confirm changes',

  'draftManagementDomain.messages.draftsAreIdentical': 'There are no changes',
  'draftManagementDomain.messages.removed': 'removed',
  'draftManagementDomain.messages.published': 'Published',
  'draftManagementDomain.messages.saved': 'Saved',
  'draftManagementDomain.messages.created': 'Created',
  'draftManagementDomain.messages.publishedDraft': 'Published',
  'draftManagementDomain.messages.savedDraft': 'Saved',
  'draftManagementDomain.messages.successfullySavedDraft': 'Your draft has been saved!',
  'draftManagementDomain.messages.successfullyPublishedDraft': 'Your draft has been published!',
  'draftManagementDomain.messages.successfullyCreatedDraft': 'Your draft has been created!',
  'draftManagementDomain.messages.draftAlreadyExists': 'You have an existing unpublished draft.',
  'draftManagementDomain.messages.lastEdited': 'Last edited:',
  'draftManagementDomain.messages.by': 'by',

  'draftManagementDomain.draftNameModal.body1': 'Draft name',
  'draftManagementDomain.draftNameModal.body2': 'Keep the name short and simple',

  'draftManagementDomain.errors.error': 'Error',
  'draftManagementDomain.errors.failedToSaveDraft': 'Your draft failed to save.',
  'draftManagementDomain.errors.failedToPublishDraft': 'Your draft failed to publish.',
  'draftManagementDomain.errors.failedToCreateDraft': 'Your draft failed to create.',

  'draftManagementDomain.draftsListTable.emptyMsg': 'There are currently no drafts to view',
  'draftManagementDomain.draftsListTable.errorMsg': 'Error loading drafts',

  'draftManagementDomain.draftsListTable.columns.name': 'Name',
  'draftManagementDomain.draftsListTable.columns.draftId': 'Draft ID',
  'draftManagementDomain.draftsListTable.columns.namespace': 'Namespace',
  'draftManagementDomain.draftsListTable.columns.createdAt': 'Created at',
  'draftManagementDomain.draftsListTable.columns.publishedAt': 'Published at',
  'draftManagementDomain.draftsListTable.columns.deletedAt': 'Deleted at',
  'draftManagementDomain.draftsListTable.columns.createdBy': 'Created by',
  'draftManagementDomain.draftsListTable.columns.publishedBy': 'Published by',
  'draftManagementDomain.draftsListTable.columns.deletedBy': 'Deleted by',
  'draftManagementDomain.draftsListTable.columns.updatedAt': 'Updated at',
  'draftManagementDomain.draftsListTable.columns.status': 'Status',
  'draftManagementDomain.draftsListTable.columns.createdFrom': 'Created from',

  'draftManagementDomain.draftsListTable.status.published': 'Published',
  'draftManagementDomain.draftsListTable.status.draft': 'Draft',

  'draftManagementDomain.actions.edit': 'Edit',
  'draftManagementDomain.actions.preview': 'Preview',
  'draftManagementDomain.actions.create': 'Create',
  'draftManagementDomain.actions.cancel': 'Cancel',
  'draftManagementDomain.actions.newDraft': 'New Draft',
  'draftManagementDomain.actions.viewChanges': 'View changes',
  'draftManagementDomain.actions.deleteDraft': 'Delete draft',
  'draftManagementDomain.actions.saveAndPublish': 'Save & Publish',
  'draftManagementDomain.actions.save': 'Save',
  'draftManagementDomain.actions.continueToEdit': 'Continue to edit',
  'draftManagementDomain.preview.errorNotification.title': 'Unable to open preview',
  'draftManagementDomain.preview.errorNotification.body':
    'Unable to open preview in non-production environment',
} as const

export default EN_US
