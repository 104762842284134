import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const storeInterventionsViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreInterventionsView],
}

export const storeInterventionsModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreInterventionsModify],
}

export const storeInterventionsCreateNewRequestAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreInterventionsCreateNewRequest],
}
