import { withPaginationDetails } from "@retailer-platform/dashboard/utils";
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@retailer-platform/dashboard/apollo';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: string;
  CustomersBigInt: any;
  CustomersDateTime: any;
  DateTime: any;
  GoogleProtobufAnyScalar: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  JSONObject: any;
  LegacyDate: any;
  Time: string;
  True: true;
  Url: any;
  google_protobuf_Empty: any;
  google_protobuf_Empty_Input: any;
  instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext: any;
  instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext_Input: any;
  instacart_ads_targeting_v1_NewToInstacartCondition: any;
  instacart_ads_targeting_v1_NewToInstacartCondition_Input: any;
  instacart_caper_admin_v1_GetAllEntitiesRequest_Input: any;
  instacart_caper_admin_v1_GetConfigKeysRequest_Input: any;
  instacart_caper_admin_v1_UpdateConfigResponse: any;
  instacart_customers_basket_products_orchestrators_v1_GetBasketProductsRequest_NoUser_Input: any;
  instacart_customers_business_admin_v1_DeleteBusinessProgramContentResponse: any;
  instacart_customers_business_admin_v1_DeleteBusinessProgramOfferResponse: any;
  instacart_customers_business_admin_v1_DeleteBusinessProgramResponse: any;
  instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_ICMembershipExcluded: any;
  instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_ICMembershipRequired: any;
  instacart_customers_commerce_types_v1_SlimOffer_UncategorizedScenario: any;
  instacart_customers_commerce_types_v1_SlimOffer_UncategorizedScenario_Input: any;
  instacart_customers_recipes_v1_GetCoreIngredientsRequest_Input: any;
  instacart_enterprise_boba_core_types_v1_OfferDetails_UncategorizedScenario: any;
  instacart_logistics_v1_ListCreatorsRequest_Input: any;
  instacart_logistics_v1_ListTagsRequest_Input: any;
};

export type AccessControlAuthorizationAuthorizationCheck = {
  __typename?: 'AccessControlAuthorizationAuthorizationCheck';
  /** Determines whether the current account has access to the given rules */
  authorized: Scalars['Boolean'];
  /** An array of reasons why validation failed, if any */
  reasons: Array<RejectionReasonEnum>;
};

export type AccessControlConfiguration = {
  __typename?: 'AccessControlConfiguration';
  /** The policies associated with the account */
  policies: AccessControlPolicies;
  /** The access control details for the retailer */
  retailerDetails: AccessControlRetailerDetails;
};


export type AccessControlConfigurationRetailerDetailsArgs = {
  retailerId: Scalars['ID'];
};

export type AccessControlPolicies = {
  __typename?: 'AccessControlPolicies';
  /** Permission to view the admin tools section */
  viewAdminToolsSection: Scalars['Boolean'];
  /** Permission to view the retailer dashboard section */
  viewRetailerDashboardSection: Scalars['Boolean'];
};

export type AccessControlRetailerDetails = {
  __typename?: 'AccessControlRetailerDetails';
  /** The policies associated with the retailer */
  policies: AccessControlRetailerPolicies;
};

export type AccessControlRetailerPolicies = {
  __typename?: 'AccessControlRetailerPolicies';
  /** Permission to modify retailer location groups */
  modifyRetailerLocationGroups: Scalars['Boolean'];
  /** Permission to modify retailer reports */
  modifyRetailerReports: Scalars['Boolean'];
  /** Permission to view retailer location groups */
  viewRetailerLocationGroups: Scalars['Boolean'];
  /** Permission to view retailer reports */
  viewRetailerReports: Scalars['Boolean'];
};

export type AccessToken = {
  __typename?: 'AccessToken';
  /** The account associated with the token */
  enterpriseAccount?: Maybe<EnterpriseAccount>;
  /** A unique token for authentication purposes */
  token: Scalars['ID'];
};

export type AccountOrdersEmptyState = {
  __typename?: 'AccountOrdersEmptyState';
  enabled?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type AccountRequestAccountRequest = {
  __typename?: 'AccountRequestAccountRequest';
  /** Canonical account ID */
  accountCanonicalId?: Maybe<Scalars['String']>;
  /** Time this request was created */
  createdAt: Scalars['Time'];
  /** Country associated with this retailer */
  email: Scalars['String'];
  /** Country associated with this retailer */
  fullName: Scalars['String'];
  /** A unique identifier for the retailer */
  id: Scalars['ID'];
  /** justification from retailer employee for account creation */
  requestReason: Scalars['String'];
  /** Current state of the request */
  requestState: RequestStateEnum;
  /** The name of the retailer */
  retailerId: Scalars['ID'];
  /** Requested role IDs */
  roles: Array<EnterpriseRole>;
};

export type AccountRequestPreviousRequestExists = {
  __typename?: 'AccountRequestPreviousRequestExists';
  /** Does a previous account request exist */
  requestExists: Scalars['Boolean'];
};

export enum AccountStatusEnum {
  /** Active status */
  Active = 'ACTIVE',
  /** Disabled status */
  Disabled = 'DISABLED',
  /** Pending status */
  Pending = 'PENDING'
}

export type AddRetailerCampaignTreatmentsRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  treatments?: InputMaybe<Array<InputMaybe<CreateRetailerCampaignTreatmentParamsInput>>>;
};

export type AddRetailerCampaignTreatmentsResponse = {
  __typename?: 'AddRetailerCampaignTreatmentsResponse';
  treatments?: Maybe<Array<Maybe<RetailerCampaignTreatment>>>;
};

export type Address = {
  __typename?: 'Address';
  apartmentNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

export type AdminRequestContextInput = {
  retailerAppId?: InputMaybe<Scalars['BigInt']>;
};

export type AdsSchema = {
  __typename?: 'AdsSchema';
  allowAlcoholSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowBiaPageSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowBrowseSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowCartSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowCheckoutSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowItemDetailsSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowSearchGridSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowSearchOtherSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowSponsoredProducts?: Maybe<Scalars['Boolean']>;
  allowStorefrontSponsoredProducts?: Maybe<Scalars['Boolean']>;
};

export type AdvanceBatchStateRequestInput = {
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  batchId?: InputMaybe<Scalars['BigInt']>;
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  workflowState?: InputMaybe<Scalars['String']>;
};

export type AdvanceBatchStateResponse = {
  __typename?: 'AdvanceBatchStateResponse';
  id?: Maybe<Scalars['BigInt']>;
  workflowState?: Maybe<Scalars['String']>;
};

/** Defines a single affecting scope for querying approval requests. */
export type AffectingScopeInput = {
  /** Filter by partner IDs affected by the approval request. */
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter by retailer IDs affected by the approval request. */
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter by retailer location IDs affected by the approval request. */
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter by store configuration IDs affected by the approval request. */
  storeConfigIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Defines filtering criteria for affecting scopes in a request to list approval requests. */
export type AffectingScopeSearchInput = {
  /** Returns ARs that match ANY of the provided AffectingScopes. To match, the AR's AffectingScope must be a superset of the provided AffectingScope. */
  affectingScopes?: InputMaybe<Array<AffectingScopeInput>>;
  /** Returns ARs that have no AffectingScope; i.e., empty partners/retailers/locations/store-configs. */
  includeGlobalScope?: InputMaybe<Scalars['Boolean']>;
};

export type AisleOrder = {
  __typename?: 'AisleOrder';
  inventoryAreaAisleId?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['BigInt']>;
};

export type AisleOrderInput = {
  inventoryAreaAisleId?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['BigInt']>;
};

export type Analytics = {
  __typename?: 'Analytics';
  cadence?: Maybe<Cadence>;
  dateRange?: Maybe<InstacartAdsSharedV1DateRange>;
  entity?: Maybe<Entity>;
  metric?: Maybe<Metric>;
  valueToDate?: Maybe<Array<Maybe<AnalyticsUnit>>>;
  valueToDateV2?: Maybe<Array<Maybe<AnalyticsUnitV2>>>;
};

export type AnalyticsUnit = {
  __typename?: 'AnalyticsUnit';
  date?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type AnalyticsUnitV2 = {
  __typename?: 'AnalyticsUnitV2';
  date?: Maybe<GoogleTypeDate>;
  value?: Maybe<Scalars['Float']>;
};

export type AnomalousItemDateCount = {
  __typename?: 'AnomalousItemDateCount';
  count?: Maybe<Scalars['BigInt']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type ApiIntegrationStoreLocation = {
  __typename?: 'ApiIntegrationStoreLocation';
  active?: Maybe<Scalars['Boolean']>;
  currentlySynced?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Defines the current state of the Approval Request. */
export enum ApprovalRequestState {
  /** Approval Request has been approved. */
  Approved = 'APPROVED',
  /** Approval Request has been cancelled. */
  Cancelled = 'CANCELLED',
  /** Approval Request is pending. */
  Pending = 'PENDING',
  /** Approval Request has been published. */
  Published = 'PUBLISHED',
  /** Approval Request is in the process of being published. */
  Publishing = 'PUBLISHING'
}

/** Defines the resources that the Approval Request affects. */
export type ApprovalsManagementTypesAffectingScope = {
  __typename?: 'ApprovalsManagementTypesAffectingScope';
  /** The partner IDs affected by the approval request */
  partnerIds: Array<Scalars['String']>;
  /** The retailer IDs affected by the approval request */
  retailerIds: Array<Scalars['String']>;
  /** The retailer location IDs affected by the approval request */
  retailerLocationIds: Array<Scalars['String']>;
  /** The store configuration IDs affected by the approval request */
  storeConfigIds: Array<Scalars['String']>;
};

/** The Approval Request object. */
export type ApprovalsManagementTypesApprovalRequest = {
  __typename?: 'ApprovalsManagementTypesApprovalRequest';
  /** The resources affected by the changes underlying this approval request */
  affectingScope: ApprovalsManagementTypesAffectingScope;
  /** The base snapshot */
  baseSnapshot?: Maybe<ApprovalsManagementTypesContentSnapshotShallow>;
  /** Foreign keys pointing to the record(s) in the content's database */
  contentIds?: Maybe<Array<Scalars['String']>>;
  /** The content type, defined statically in AMS */
  contentType: Scalars['String'];
  /** The creation time of the approval request */
  createdAt: Scalars['ISO8601DateTime'];
  /** The user who created the approval request */
  createdByUser: EnterpriseAccount;
  /** The unique UUID for the Approval Request */
  id: Scalars['ID'];
  /** Used to de-duplicate Approval Requests */
  idempotencyId: Scalars['String'];
  /** Optional opaque metadata for the Approval Request */
  metadata?: Maybe<Scalars['JSON']>;
  /** A user-provided name/description for the Approval Request */
  name: Scalars['String'];
  /** The current state of the approval request */
  requestState: ApprovalRequestState;
  /** Reviews posted on this approval request */
  reviews: Array<ApprovalsManagementTypesUserReviewReference>;
  /** The list of revision snapshots */
  revisionSnapshots?: Maybe<Array<ApprovalsManagementTypesContentSnapshotShallow>>;
};

/** A shallow snapshot, used primarily for referencing content revisions. */
export type ApprovalsManagementTypesContentSnapshot = {
  __typename?: 'ApprovalsManagementTypesContentSnapshot';
  /** The ID of the request associated with the snapshot */
  approvalRequestId: Scalars['ID'];
  /** The content of the snapshot, serialized as a string */
  contentBytes: Scalars['String'];
  /** Metadata associated with the content at the time of the snapshot */
  contentMetadata: Scalars['JSON'];
  /** The time at which the snapshot was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The unique identifier of the snapshot */
  id: Scalars['ID'];
  /** The ID of the review associated with the snapshot */
  reviewId?: Maybe<Scalars['ID']>;
  /** The type of the snapshot: [BASE, REVISION] */
  snapshotType: ContentSnapshot;
};

/** A shallow snapshot, used primarily for referencing content revisions. */
export type ApprovalsManagementTypesContentSnapshotShallow = {
  __typename?: 'ApprovalsManagementTypesContentSnapshotShallow';
  /** The ID of the request associated with the snapshot */
  approvalRequestId: Scalars['ID'];
  /** Metadata associated with the content at the time of the snapshot */
  contentMetadata: Scalars['JSON'];
  /** The time at which the snapshot was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The unique identifier of the snapshot */
  id: Scalars['ID'];
  /** The ID of the review associated with the snapshot */
  reviewId?: Maybe<Scalars['ID']>;
  /** The type of the snapshot: [BASE, REVISION] */
  snapshotType: ContentSnapshot;
};

/** Describes the actions a user is permitted to take on an approval request */
export type ApprovalsManagementTypesUserPermittedActions = {
  __typename?: 'ApprovalsManagementTypesUserPermittedActions';
  /** The unique ID of the Approval Request. */
  approvalRequestId: Scalars['ID'];
  /** Whether the user can approve the approval request. */
  canApprove: Scalars['Boolean'];
  /** Whether the user can cancel the approval request. */
  canCancel: Scalars['Boolean'];
  /** Whether the user can comment on the approval request. */
  canComment: Scalars['Boolean'];
  /** Whether the user can publish the approval request. */
  canPublish: Scalars['Boolean'];
  /** Whether the user can revise the approval request. */
  canRevise: Scalars['Boolean'];
};

/** The content of a user's review. */
export type ApprovalsManagementTypesUserReviewContent = {
  __typename?: 'ApprovalsManagementTypesUserReviewContent';
  /** The action the user performed */
  action: ReviewAction;
  /** Optional user comment */
  comment?: Maybe<Scalars['String']>;
};

/** A User Review Content with a unique identifier. */
export type ApprovalsManagementTypesUserReviewReference = {
  __typename?: 'ApprovalsManagementTypesUserReviewReference';
  /** The ID of the Approval Request this review is posted on */
  arId: Scalars['ID'];
  /** The time the review was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** The ID of the User Review */
  id: Scalars['ID'];
  /** The User Review content */
  reviewContent: ApprovalsManagementTypesUserReviewContent;
  /** The user that made the review */
  reviewedBy: EnterpriseAccount;
  /** The revision snapshot, if the review was posting a new revision */
  revisionSnapshot?: Maybe<ApprovalsManagementTypesContentSnapshot>;
  /** Time the review was last updated */
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum AreaOfInterest {
  AreaOfInterestUnknown = 'AREA_OF_INTEREST_UNKNOWN',
  Claims = 'CLAIMS',
  DistributionVolume = 'DISTRIBUTION_VOLUME',
  ItemImage = 'ITEM_IMAGE',
  ProductSales = 'PRODUCT_SALES',
  UnitLevelPricing = 'UNIT_LEVEL_PRICING'
}

export enum AssetType {
  Favicon = 'FAVICON',
  Font = 'FONT',
  ItemAttributeIcon = 'ITEM_ATTRIBUTE_ICON',
  LandingPageBackgroundImage = 'LANDING_PAGE_BACKGROUND_IMAGE',
  Logo = 'LOGO',
  MobileBackgroundImage = 'MOBILE_BACKGROUND_IMAGE',
  MobileLogo = 'MOBILE_LOGO',
  RewardsProgramLogo = 'REWARDS_PROGRAM_LOGO',
  Unknown = 'UNKNOWN'
}

/** Autogenerated return type of AssignPermissionToRole */
export type AssignPermissionToRolePayload = {
  __typename?: 'AssignPermissionToRolePayload';
  /** The role with the new permissions */
  role: EnterpriseRole;
};

export type AssignVirtualBatchRequestInput = {
  batchId?: InputMaybe<Scalars['BigInt']>;
  batchType?: InputMaybe<BatchType>;
  driverId?: InputMaybe<Scalars['BigInt']>;
  order?: InputMaybe<OrderInput>;
  shopperId?: InputMaybe<Scalars['BigInt']>;
};

export type AssignVirtualBatchResponse = {
  __typename?: 'AssignVirtualBatchResponse';
  id?: Maybe<Scalars['BigInt']>;
  workflowState?: Maybe<Scalars['String']>;
};

export enum AtomsColor {
  AtomsColorUnknown = 'ATOMS_COLOR_UNKNOWN',
  BrandHighlightDark = 'BRAND_HIGHLIGHT_DARK',
  BrandHighlightLight = 'BRAND_HIGHLIGHT_LIGHT',
  BrandHighlightRegular = 'BRAND_HIGHLIGHT_REGULAR',
  BrandLoyaltyDark = 'BRAND_LOYALTY_DARK',
  BrandLoyaltyLight = 'BRAND_LOYALTY_LIGHT',
  BrandLoyaltyRegular = 'BRAND_LOYALTY_REGULAR',
  BrandPrimaryDark = 'BRAND_PRIMARY_DARK',
  BrandPrimaryExtraDark = 'BRAND_PRIMARY_EXTRA_DARK',
  BrandPrimaryRegular = 'BRAND_PRIMARY_REGULAR',
  BrandPromotionalDark = 'BRAND_PROMOTIONAL_DARK',
  BrandPromotionalLight = 'BRAND_PROMOTIONAL_LIGHT',
  BrandPromotionalRegular = 'BRAND_PROMOTIONAL_REGULAR',
  BrandSecondaryDark = 'BRAND_SECONDARY_DARK',
  BrandSecondaryLight = 'BRAND_SECONDARY_LIGHT',
  BrandSecondaryRegular = 'BRAND_SECONDARY_REGULAR',
  BrandTertiaryRegular = 'BRAND_TERTIARY_REGULAR',
  MaxYellow = 'MAX_YELLOW',
  PlusDark = 'PLUS_DARK',
  PlusExtraDark = 'PLUS_EXTRA_DARK',
  PlusExtraLight = 'PLUS_EXTRA_LIGHT',
  PlusLight = 'PLUS_LIGHT',
  PlusRegular = 'PLUS_REGULAR',
  SystemDetrimentalDark = 'SYSTEM_DETRIMENTAL_DARK',
  SystemDetrimentalExtraDark = 'SYSTEM_DETRIMENTAL_EXTRA_DARK',
  SystemDetrimentalLight = 'SYSTEM_DETRIMENTAL_LIGHT',
  SystemDetrimentalRegular = 'SYSTEM_DETRIMENTAL_REGULAR',
  SystemGrayscale00 = 'SYSTEM_GRAYSCALE00',
  SystemGrayscale10 = 'SYSTEM_GRAYSCALE10',
  SystemGrayscale20 = 'SYSTEM_GRAYSCALE20',
  SystemGrayscale30 = 'SYSTEM_GRAYSCALE30',
  SystemGrayscale50 = 'SYSTEM_GRAYSCALE50',
  SystemGrayscale70 = 'SYSTEM_GRAYSCALE70',
  SystemSuccessDark = 'SYSTEM_SUCCESS_DARK',
  SystemSuccessLight = 'SYSTEM_SUCCESS_LIGHT',
  SystemSuccessRegular = 'SYSTEM_SUCCESS_REGULAR'
}

export type AttributeData = {
  __typename?: 'AttributeData';
  attributeName?: Maybe<Scalars['String']>;
  attributeValue?: Maybe<Scalars['String']>;
  productSourceId?: Maybe<Scalars['BigInt']>;
  sourceId?: Maybe<Scalars['BigInt']>;
  sourceType?: Maybe<Scalars['String']>;
};

export type AuditBatch = {
  __typename?: 'AuditBatch';
  allowSuggestedValues?: Maybe<Scalars['Boolean']>;
  auditableTaskCount?: Maybe<Scalars['BigInt']>;
  auditedTaskCount?: Maybe<Scalars['BigInt']>;
  batchId?: Maybe<Scalars['BigInt']>;
  batchMetadata?: Maybe<Scalars['String']>;
  batchState?: Maybe<Scalars['String']>;
  batchUuid?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extComment?: Maybe<Scalars['String']>;
  intComment?: Maybe<Scalars['String']>;
  isExplicitFailedRedrive?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['String']>;
  recommendedSampleSize?: Maybe<Scalars['BigInt']>;
  sampleTaskCount?: Maybe<Scalars['BigInt']>;
  sampleTasks?: Maybe<Array<Maybe<AuditTask>>>;
  secondaryStateId?: Maybe<Scalars['BigInt']>;
  slaDueDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['BigInt']>;
  workflow?: Maybe<Scalars['String']>;
};

export type AuditDetectionRequestInput = {
  auditedBy?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  state?: InputMaybe<DetectionStatusState>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type AuditDetectionResponse = {
  __typename?: 'AuditDetectionResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export enum AuditLogEntityTypeEnum {
  /** Account entity */
  Account = 'ACCOUNT',
  /** Aisle entity */
  Aisle = 'AISLE',
  /** Collection entity */
  Collection = 'COLLECTION',
  /** ContentPage entity */
  ContentPage = 'CONTENT_PAGE',
  /** ContentPageVersion entity */
  ContentPageVersion = 'CONTENT_PAGE_VERSION',
  /** ContentPageVersionMeta entity */
  ContentPageVersionMeta = 'CONTENT_PAGE_VERSION_META',
  /** Department entity */
  Department = 'DEPARTMENT',
  /** DepartmentNavigation entity */
  DepartmentNavigation = 'DEPARTMENT_NAVIGATION',
  /** Item entity */
  Item = 'ITEM',
  /** Placement entity */
  Placement = 'PLACEMENT',
  /** Product entity */
  Product = 'PRODUCT',
  /** RetailerCampaign entity */
  RetailerCampaign = 'RETAILER_CAMPAIGN',
  /** Role entity */
  Role = 'ROLE'
}

export type AuditLogsAuditLog = {
  __typename?: 'AuditLogsAuditLog';
  /** The email of the account that made the change */
  accountEmail: Scalars['String'];
  /** The UUID of the account that made the change */
  accountUuid: Scalars['ID'];
  /** The entities related to this change */
  entities?: Maybe<Array<AuditLogsAuditLogEntity>>;
  /** Event name */
  eventName?: Maybe<Scalars['String']>;
  /** Time that the audit log event took place */
  eventTime: Scalars['ISO8601DateTime'];
  /** The internal unique identifier for the audit log */
  id: Scalars['ID'];
  /** Whether or not the change was successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** The connection type for AuditLogsAuditLog. */
export type AuditLogsAuditLogConnection = {
  __typename?: 'AuditLogsAuditLogConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuditLogsAuditLogEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AuditLogsAuditLog>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AuditLogsAuditLogEdge = {
  __typename?: 'AuditLogsAuditLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AuditLogsAuditLog>;
};

export type AuditLogsAuditLogEntity = {
  __typename?: 'AuditLogsAuditLogEntity';
  /** The entity display name */
  entityDisplayName?: Maybe<Scalars['String']>;
  /** The identifier of the entity */
  entityIds: Array<Scalars['ID']>;
  /** The entity type */
  entityType: AuditLogEntityTypeEnum;
  /** The internal unique identifier for the audit log entity */
  id: Scalars['ID'];
};

export type AuditTask = {
  __typename?: 'AuditTask';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<EntityId>;
  extComment?: Maybe<Scalars['String']>;
  inputData?: Maybe<Scalars['String']>;
  intComment?: Maybe<Scalars['String']>;
  taskComponents?: Maybe<Array<Maybe<AuditTaskTaskComponent>>>;
  taskState?: Maybe<Scalars['String']>;
  taskUuid?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['BigInt']>;
  workflow?: Maybe<Scalars['String']>;
};

export type AuditTaskTaskComponent = {
  __typename?: 'AuditTaskTaskComponent';
  explicitAuditFailed?: Maybe<Scalars['Boolean']>;
  extComment?: Maybe<Scalars['String']>;
  intComment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  suggestedValue?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueType?: Maybe<Scalars['String']>;
};

export type AuditWorkflow = {
  __typename?: 'AuditWorkflow';
  approvedTaskCount?: Maybe<Scalars['BigInt']>;
  auditableTaskCount?: Maybe<Scalars['BigInt']>;
  defaultSamplePct?: Maybe<Scalars['Float']>;
  defaultSlaDays?: Maybe<Scalars['BigInt']>;
  failOverPct?: Maybe<Scalars['Float']>;
  firstPendingSlaDueDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  rejectedTaskCount?: Maybe<Scalars['BigInt']>;
};

export type AuthUserData = {
  __typename?: 'AuthUserData';
  /** Whether the user uses a password or not */
  usesPassword: Scalars['Boolean'];
};

export type Authentication = {
  __typename?: 'Authentication';
  postAuthPaths?: Maybe<PostAuthPaths>;
};

export type AuthenticationInput = {
  postAuthPaths?: InputMaybe<PostAuthPathsInput>;
};

/** The method of authentication associated with an account */
export type AuthenticationInterface = {
  /** Unique authentication identifier */
  authenticationIdentifier: Scalars['ID'];
};

export type AuthorizationContextInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Banner = {
  __typename?: 'Banner';
  action?: Maybe<Scalars['String']>;
  actionData?: Maybe<Scalars['String']>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  buttonStyle?: Maybe<ButtonStyle>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaColorHex?: Maybe<Scalars['String']>;
  dataQuery?: Maybe<DataQuery>;
  id?: Maybe<Scalars['BigInt']>;
  imageAltText?: Maybe<Scalars['String']>;
  imageMobileUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  locale?: Maybe<InstacartCustomersSharedV1Locale>;
  status?: Maybe<Status>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  taglineColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type BannerAction = {
  __typename?: 'BannerAction';
  dismissBusinessIdentication?: Maybe<DismissBusinessIdentication>;
  doNotNavigate?: Maybe<DoNotNavigate>;
  navigateToAskInstacart?: Maybe<NavigateToAskInstacart>;
  navigateToAuthentication?: Maybe<NavigateToAuthentication>;
  navigateToBenefitsModal?: Maybe<NavigateToBenefitsModal>;
  navigateToCategorySurface?: Maybe<NavigateToCategorySurface>;
  navigateToCollaborativeShopInfoModal?: Maybe<NavigateToCollaborativeShopInfoModal>;
  navigateToCollection?: Maybe<NavigateToCollection>;
  navigateToCollectionHub?: Maybe<NavigateToCollectionHub>;
  navigateToContainerPathViaStoreselector?: Maybe<NavigateToContainerPathViaStoreSelector>;
  navigateToContentPage?: Maybe<NavigateToContentPage>;
  navigateToCouponRedemption?: Maybe<NavigateToCouponRedemption>;
  navigateToDepartment?: Maybe<NavigateToDepartment>;
  navigateToExpressAccountPage?: Maybe<NavigateToExpressAccountPage>;
  navigateToFlyers?: Maybe<NavigateToFlyers>;
  navigateToHouseholdAccountSharingFlow?: Maybe<NavigateToHouseholdAccountSharingFlow>;
  navigateToPickupStoreselector?: Maybe<NavigateToPickupStoreSelector>;
  navigateToProduct?: Maybe<NavigateToProduct>;
  navigateToPromoModal?: Maybe<NavigateToPromoModal>;
  navigateToPromotionDetail?: Maybe<NavigateToPromotionDetail>;
  navigateToRetailerCollection?: Maybe<NavigateToRetailerCollection>;
  navigateToRetailerCollectionViaStoreselector?: Maybe<NavigateToRetailerCollectionViaStoreSelector>;
  navigateToSsaStoreselector?: Maybe<NavigateToSsaStoreSelector>;
  navigateToStorefront?: Maybe<NavigateToStorefront>;
  navigateToStoreselector?: Maybe<NavigateToStoreSelector>;
  navigateToUrl?: Maybe<NavigateToUrl>;
  openExpressPaidModal?: Maybe<OpenExpressPaidModal>;
  openExpressTrialModal?: Maybe<OpenExpressTrialModal>;
  optInUser?: Maybe<OptInUser>;
  redeemPartnershipOffer?: Maybe<RedeemPartnershipOffer>;
  renewSubscriptionIntoAnnual?: Maybe<RenewSubscriptionIntoAnnual>;
  renewSubscriptionIntoMonthly?: Maybe<RenewSubscriptionIntoMonthly>;
};

export type BannerActionInput = {
  dismissBusinessIdentication?: InputMaybe<DismissBusinessIdenticationInput>;
  doNotNavigate?: InputMaybe<DoNotNavigateInput>;
  navigateToAskInstacart?: InputMaybe<NavigateToAskInstacartInput>;
  navigateToAuthentication?: InputMaybe<NavigateToAuthenticationInput>;
  navigateToBenefitsModal?: InputMaybe<NavigateToBenefitsModalInput>;
  navigateToCategorySurface?: InputMaybe<NavigateToCategorySurfaceInput>;
  navigateToCollaborativeShopInfoModal?: InputMaybe<NavigateToCollaborativeShopInfoModalInput>;
  navigateToCollection?: InputMaybe<NavigateToCollectionInput>;
  navigateToCollectionHub?: InputMaybe<NavigateToCollectionHubInput>;
  navigateToContainerPathViaStoreselector?: InputMaybe<NavigateToContainerPathViaStoreSelectorInput>;
  navigateToContentPage?: InputMaybe<NavigateToContentPageInput>;
  navigateToCouponRedemption?: InputMaybe<NavigateToCouponRedemptionInput>;
  navigateToDepartment?: InputMaybe<NavigateToDepartmentInput>;
  navigateToExpressAccountPage?: InputMaybe<NavigateToExpressAccountPageInput>;
  navigateToFlyers?: InputMaybe<NavigateToFlyersInput>;
  navigateToHouseholdAccountSharingFlow?: InputMaybe<NavigateToHouseholdAccountSharingFlowInput>;
  navigateToPickupStoreselector?: InputMaybe<NavigateToPickupStoreSelectorInput>;
  navigateToProduct?: InputMaybe<NavigateToProductInput>;
  navigateToPromoModal?: InputMaybe<NavigateToPromoModalInput>;
  navigateToPromotionDetail?: InputMaybe<NavigateToPromotionDetailInput>;
  navigateToRetailerCollection?: InputMaybe<NavigateToRetailerCollectionInput>;
  navigateToRetailerCollectionViaStoreselector?: InputMaybe<NavigateToRetailerCollectionViaStoreSelectorInput>;
  navigateToSsaStoreselector?: InputMaybe<NavigateToSsaStoreSelectorInput>;
  navigateToStorefront?: InputMaybe<NavigateToStorefrontInput>;
  navigateToStoreselector?: InputMaybe<NavigateToStoreSelectorInput>;
  navigateToUrl?: InputMaybe<NavigateToUrlInput>;
  openExpressPaidModal?: InputMaybe<OpenExpressPaidModalInput>;
  openExpressTrialModal?: InputMaybe<OpenExpressTrialModalInput>;
  optInUser?: InputMaybe<OptInUserInput>;
  redeemPartnershipOffer?: InputMaybe<RedeemPartnershipOfferInput>;
  renewSubscriptionIntoAnnual?: InputMaybe<RenewSubscriptionIntoAnnualInput>;
  renewSubscriptionIntoMonthly?: InputMaybe<RenewSubscriptionIntoMonthlyInput>;
};

export type BannerInput = {
  action?: InputMaybe<Scalars['String']>;
  actionData?: InputMaybe<Scalars['String']>;
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  buttonStyle?: InputMaybe<ButtonStyle>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  dataQuery?: InputMaybe<DataQueryInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  imageMobileUrl?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  status?: InputMaybe<Status>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<Scalars['String']>;
  taglineColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export enum BannerLocaleEnum {
  /** Canadian English */
  EnCa = 'EN_CA',
  /** American English */
  EnUs = 'EN_US',
  /** Canadian French */
  FrCa = 'FR_CA'
}

export type BannerType = {
  __typename?: 'BannerType';
  compactBanner?: Maybe<CompactBanner>;
  defaultBanner?: Maybe<Banner>;
  heroBanner?: Maybe<HeroBanner>;
  imageForward?: Maybe<ImageForward>;
  imageForwardFlex?: Maybe<ImageForwardFlex>;
  infoSheet?: Maybe<StandardInfoModalSheet>;
  logoWithTextBanner?: Maybe<LogoWithTextBanner>;
  secondaryBanner?: Maybe<SecondaryBanner>;
  textWithThumbnail?: Maybe<TextWithThumbnail>;
  textWithThumbnailFlex?: Maybe<TextWithThumbnailFlex>;
  unknownBanner?: Maybe<UnknownBanner>;
};

export type BannerTypeInput = {
  compactBanner?: InputMaybe<CompactBannerInput>;
  defaultBanner?: InputMaybe<BannerInput>;
  heroBanner?: InputMaybe<HeroBannerInput>;
  imageForward?: InputMaybe<ImageForwardInput>;
  imageForwardFlex?: InputMaybe<ImageForwardFlexInput>;
  infoSheet?: InputMaybe<StandardInfoModalSheetInput>;
  logoWithTextBanner?: InputMaybe<LogoWithTextBannerInput>;
  secondaryBanner?: InputMaybe<SecondaryBannerInput>;
  textWithThumbnail?: InputMaybe<TextWithThumbnailInput>;
  textWithThumbnailFlex?: InputMaybe<TextWithThumbnailFlexInput>;
  unknownBanner?: InputMaybe<UnknownBannerInput>;
};

export enum BannerVariant {
  AsyncImage = 'ASYNC_IMAGE',
  AsyncModal = 'ASYNC_MODAL',
  BannerRow = 'BANNER_ROW',
  BottomTracker = 'BOTTOM_TRACKER',
  BusinessIdentificationShopBanner = 'BUSINESS_IDENTIFICATION_SHOP_BANNER',
  CarouselCard = 'CAROUSEL_CARD',
  CartTracker = 'CART_TRACKER',
  CategoryHub = 'CATEGORY_HUB',
  ChurnedUserSurvey = 'CHURNED_USER_SURVEY',
  Coachmark = 'COACHMARK',
  CompactBanner = 'COMPACT_BANNER',
  FeedUnit = 'FEED_UNIT',
  FullWidth = 'FULL_WIDTH',
  Gamification = 'GAMIFICATION',
  Header = 'HEADER',
  HeroBanner = 'HERO_BANNER',
  Image = 'IMAGE',
  ImageForward = 'IMAGE_FORWARD',
  ImageForwardFlex = 'IMAGE_FORWARD_FLEX',
  ImageTileCard = 'IMAGE_TILE_CARD',
  InformationModal = 'INFORMATION_MODAL',
  LandingFaq = 'LANDING_FAQ',
  LandingHero = 'LANDING_HERO',
  LandingModule = 'LANDING_MODULE',
  LandingValueProp = 'LANDING_VALUE_PROP',
  Large = 'LARGE',
  LogoWithText = 'LOGO_WITH_TEXT',
  Mega = 'MEGA',
  MultiOfferSheet = 'MULTI_OFFER_SHEET',
  NavigationBanner = 'NAVIGATION_BANNER',
  OccasionHub = 'OCCASION_HUB',
  OffersForYouCard = 'OFFERS_FOR_YOU_CARD',
  OfferCard = 'OFFER_CARD',
  OverHeader = 'OVER_HEADER',
  PromotionSheetCard = 'PROMOTION_SHEET_CARD',
  QuickLinkPill = 'QUICK_LINK_PILL',
  RetailerStory = 'RETAILER_STORY',
  RichInformationModal = 'RICH_INFORMATION_MODAL',
  RichText = 'RICH_TEXT',
  SecondaryBanner = 'SECONDARY_BANNER',
  StandardInformationModal = 'STANDARD_INFORMATION_MODAL',
  StoreMenuCard = 'STORE_MENU_CARD',
  Survey = 'SURVEY',
  Text = 'TEXT',
  TextIconHeader = 'TEXT_ICON_HEADER',
  TextSlim = 'TEXT_SLIM',
  TextWithThumbnail = 'TEXT_WITH_THUMBNAIL',
  TextWithThumbnailFlex = 'TEXT_WITH_THUMBNAIL_FLEX',
  TitledMedia = 'TITLED_MEDIA',
  ValueCarouselCard = 'VALUE_CAROUSEL_CARD'
}

export type BatchGetUsersByIdsStrictRequestInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
};

export type BatchGetUsersByIdsStrictResponse = {
  __typename?: 'BatchGetUsersByIdsStrictResponse';
  users?: Maybe<Array<Maybe<User>>>;
};

export enum BatchType {
  BatchTypeUnspecified = 'BATCH_TYPE_UNSPECIFIED',
  Delivery = 'DELIVERY',
  DeliveryOnly = 'DELIVERY_ONLY',
  DriveAndPick = 'DRIVE_AND_PICK'
}

export type BatchUpdatePlacementsRequestInput = {
  layout?: InputMaybe<Layout>;
  placements?: InputMaybe<Array<InputMaybe<PlacementInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type BatchUpdateSlotCreativesRequestInput = {
  slotCreatives?: InputMaybe<Array<InputMaybe<SlotCreativeInput>>>;
};

export type BatchUpdateSlotCreativesResponse = {
  __typename?: 'BatchUpdateSlotCreativesResponse';
  slotCreatives?: Maybe<Array<Maybe<SlotCreative>>>;
};

export type BorderRadiusSchema = {
  __typename?: 'BorderRadiusSchema';
  isCircle?: Maybe<Scalars['Boolean']>;
  radius?: Maybe<Scalars['BigInt']>;
};

export enum BotAccountAssignableRoleEnum {
  /** Engineer role */
  Engineer = 'ENGINEER',
  /** Admin role */
  NonEngineer = 'NON_ENGINEER'
}

export enum BotAccountOperationTypeEnum {
  /** Account was created */
  Created = 'CREATED',
  /** Account was updated */
  Updated = 'UPDATED'
}

/** Autogenerated return type of BotEnterpriseAccountDisable */
export type BotEnterpriseAccountDisablePayload = {
  __typename?: 'BotEnterpriseAccountDisablePayload';
  /** The newly created or updated account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of BotEnterpriseAccountUpsert */
export type BotEnterpriseAccountUpsertPayload = {
  __typename?: 'BotEnterpriseAccountUpsertPayload';
  /** The newly created or updated account object */
  enterpriseAccount: EnterpriseAccount;
  /** What kind of operation was executed */
  operationType: BotAccountOperationTypeEnum;
};

export type Brand = {
  __typename?: 'Brand';
  brandId?: Maybe<Scalars['BigInt']>;
  brandName?: Maybe<Scalars['String']>;
};

export type BrandContact = {
  __typename?: 'BrandContact';
  email?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type BrandContactRequestInput = {
  accountInformation?: InputMaybe<IppAccountInformationInput>;
  areasOfInterest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  areasOfInterestEnums?: InputMaybe<Array<InputMaybe<AreaOfInterest>>>;
  brandProfileId?: InputMaybe<Scalars['String']>;
  interestedSellSheetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDebug?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
};

export type BrandContactResponse = {
  __typename?: 'BrandContactResponse';
  brandContactId?: Maybe<Scalars['String']>;
};

export type BrandNameMapping = {
  __typename?: 'BrandNameMapping';
  brandName?: Maybe<Scalars['String']>;
  brandProfileId?: Maybe<Scalars['String']>;
};

export type BrandProfileAttributes = {
  __typename?: 'BrandProfileAttributes';
  aboutBrand?: Maybe<Scalars['String']>;
  brandContacts?: Maybe<Array<Maybe<BrandContact>>>;
  brandName?: Maybe<Scalars['String']>;
  certifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  distributors?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  imageAssets?: Maybe<Array<Maybe<ImageAsset>>>;
  regionalAreas?: Maybe<Array<Maybe<InstacartAdsNexusV1RegionalArea>>>;
  storeTypes?: Maybe<Array<Maybe<InstacartAdsNexusV1StoreType>>>;
  website?: Maybe<Scalars['String']>;
};

export type BrandProfileAttributesRequestInput = {
  brandProfileId?: InputMaybe<Scalars['String']>;
};

export type BrandProfileAttributesResponse = {
  __typename?: 'BrandProfileAttributesResponse';
  brandProfileAttributes?: Maybe<BrandProfileAttributes>;
};

export type BrandProfileSellSheetCardsRequestInput = {
  brandProfileId?: InputMaybe<Scalars['String']>;
  paginationRequest?: InputMaybe<InstacartAdsSharedV1PaginationRequestInput>;
};

export type BrandProfileSellSheetCardsResponse = {
  __typename?: 'BrandProfileSellSheetCardsResponse';
  paginationResponse?: Maybe<InstacartAdsSharedV1PaginationResponse>;
  sellSheetCards?: Maybe<Array<Maybe<SellSheetCard>>>;
};

export type BrandSellSheetsAttributesRequestInput = {
  brandProfileId?: InputMaybe<Scalars['String']>;
};

export type BrandSellSheetsAttributesResponse = {
  __typename?: 'BrandSellSheetsAttributesResponse';
  sellSheetsOverviewAttributes?: Maybe<Array<Maybe<SellSheetOverviewAttributes>>>;
};

export type BrandingSchema = {
  __typename?: 'BrandingSchema';
  customImages?: Maybe<CustomImages>;
  favicon?: Maybe<Scalars['String']>;
  loadingIcons?: Maybe<Array<Maybe<Scalars['String']>>>;
  logos?: Maybe<Logos>;
  retailerOverrides?: Maybe<Array<Maybe<RetailerOverride>>>;
};

export enum BusinessDerivedAttributesSourceType {
  LangchainAi = 'LANGCHAIN_AI'
}

export type BusinessSchema = {
  __typename?: 'BusinessSchema';
  businessAccountOnly?: Maybe<Scalars['Boolean']>;
  enableDefaultBusinessFeatures?: Maybe<Scalars['Boolean']>;
};

export enum ButtonStyle {
  Detrimental = 'DETRIMENTAL',
  Plus = 'PLUS',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export enum ButtonVariantEnum {
  /** Use the dismiss button style */
  Dismiss = 'DISMISS',
  /** Use the link button style */
  Link = 'LINK',
  /** Use the plus button style */
  Plus = 'PLUS',
  /** Use the primary button style */
  Primary = 'PRIMARY',
  /** Use the secondary button style */
  Secondary = 'SECONDARY'
}

export enum Cadence {
  Weekly = 'WEEKLY'
}

export type CallbackConfigurationInput = {
  /** The access token TTL */
  accessTokenTtl?: InputMaybe<Scalars['Int']>;
  /** The authentication URI */
  authUri?: InputMaybe<Scalars['String']>;
  /** The possible statuses to enable */
  availableStatuses?: InputMaybe<Array<Scalars['String']>>;
  /** The callback URI */
  callbackUri?: InputMaybe<Scalars['String']>;
  /** The client id */
  credentialsClientId?: InputMaybe<Scalars['String']>;
  /** The client id */
  credentialsClientSecret?: InputMaybe<Scalars['String']>;
  /** The id */
  id: Scalars['ID'];
  /** The enabled statusers */
  statusesOfInterest?: InputMaybe<Array<Scalars['String']>>;
};

export enum CampaignField {
  OfferData = 'OFFER_DATA',
  RetailerData = 'RETAILER_DATA'
}

export type CampaignFilter = {
  __typename?: 'CampaignFilter';
  filterType?: Maybe<FilterType>;
  filterValue?: Maybe<Scalars['String']>;
};

export type CampaignFilterInput = {
  filterType?: InputMaybe<FilterType>;
  filterValue?: InputMaybe<Scalars['String']>;
};

export enum CampaignObjective {
  Acquisition = 'ACQUISITION',
  Activation = 'ACTIVATION',
  Awareness = 'AWARENESS',
  Custom = 'CUSTOM',
  Invalid = 'INVALID',
  Loyalty = 'LOYALTY',
  Merchandising = 'MERCHANDISING',
  Moment = 'MOMENT',
  Occasion = 'OCCASION',
  Reactivation = 'REACTIVATION'
}

export enum CampaignProgress {
  /** The campaign has been cancelled */
  Cancelled = 'cancelled',
  /** The campaign has completed successfully */
  Completed = 'completed',
  /** The campaign is waiting for the segment export to complete */
  ExportingSegment = 'exporting_segment',
  /** The campaign has failed for some users */
  Failed = 'failed',
  /** The campaign is in progress */
  InProgress = 'in_progress',
  /** The campaign is downloading and parsing the segment from S3 */
  LoadingSegment = 'loading_segment',
  /** The campaign has completed for some users */
  PartiallyCompleted = 'partially_completed',
  /** The campaign is not currently running */
  Pending = 'pending'
}

export type CampaignReference = {
  __typename?: 'CampaignReference';
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
};

export type CampaignReferenceInput = {
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export type CampaignRouletteFeature = {
  __typename?: 'CampaignRouletteFeature';
  featureName?: Maybe<Scalars['String']>;
  featureVariant?: Maybe<Scalars['String']>;
};

export type CampaignRouletteFeatureInput = {
  featureName?: InputMaybe<Scalars['String']>;
  featureVariant?: InputMaybe<Scalars['String']>;
};

export type CampaignTreatmentTask = {
  __typename?: 'CampaignTreatmentTask';
  definitionParams?: Maybe<Scalars['JSONObject']>;
  definitionType?: Maybe<Scalars['String']>;
  treatmentPolicyId?: Maybe<Scalars['String']>;
};

export type CampaignTreatmentTaskRequestParamsInput = {
  definitionParams?: InputMaybe<Scalars['JSONObject']>;
  definitionType?: InputMaybe<Scalars['String']>;
  taskDescription?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CancelCampaign */
export type CancelCampaignPayload = {
  __typename?: 'CancelCampaignPayload';
  /** Whether it succesfully cancelled */
  success: Scalars['Boolean'];
};

export type CancelMomentRequestInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
};

export type CancelMomentResponse = {
  __typename?: 'CancelMomentResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type CancelOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type CancelOrderResponse = {
  __typename?: 'CancelOrderResponse';
  instrumentationData?: Maybe<Scalars['String']>;
  order?: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
  orderItems?: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItem>>>;
};

export type CancelRetailerCampaignRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CancelRetailerCampaignResponse = {
  __typename?: 'CancelRetailerCampaignResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type CardList = {
  __typename?: 'CardList';
  action?: Maybe<Scalars['String']>;
  actionData?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaVisibilityLimit?: Maybe<Scalars['BigInt']>;
  ctaVisibilityMin?: Maybe<Scalars['BigInt']>;
  dataQuery?: Maybe<DataQuery>;
  dataQueryValue?: Maybe<Scalars['JSONObject']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  layout?: Maybe<Layout>;
  locale?: Maybe<InstacartCustomersSharedV1Locale>;
  placementId?: Maybe<Scalars['BigInt']>;
  requestId?: Maybe<Scalars['String']>;
  settingsValue?: Maybe<Scalars['JSONObject']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<CardListVariant>;
};

export type CardListInput = {
  action?: InputMaybe<Scalars['String']>;
  actionData?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaVisibilityLimit?: InputMaybe<Scalars['BigInt']>;
  ctaVisibilityMin?: InputMaybe<Scalars['BigInt']>;
  dataQuery?: InputMaybe<DataQueryInput>;
  dataQueryValue?: InputMaybe<Scalars['JSONObject']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  layout?: InputMaybe<Layout>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  requestId?: InputMaybe<Scalars['String']>;
  settingsValue?: InputMaybe<Scalars['JSONObject']>;
  title?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<CardListVariant>;
};

export enum CardListVariant {
  AsyncPlacements = 'ASYNC_PLACEMENTS',
  CardList = 'CARD_LIST',
  GridList = 'GRID_LIST',
  HomeSpotlight = 'HOME_SPOTLIGHT',
  ImageTileList = 'IMAGE_TILE_LIST',
  ItemBundles = 'ITEM_BUNDLES',
  LandingFaqsList = 'LANDING_FAQS_LIST',
  LandingRetailersList = 'LANDING_RETAILERS_LIST',
  LandingValuePropsList = 'LANDING_VALUE_PROPS_LIST',
  MarketplaceList = 'MARKETPLACE_LIST',
  PillList = 'PILL_LIST',
  QuickLinkList = 'QUICK_LINK_LIST',
  RetailerForwardList = 'RETAILER_FORWARD_LIST',
  RetailerStoriesList = 'RETAILER_STORIES_LIST',
  Slot = 'SLOT',
  StoreDirectoryStoreForwardList = 'STORE_DIRECTORY_STORE_FORWARD_LIST',
  StoreForwardList = 'STORE_FORWARD_LIST',
  StoreMenuList = 'STORE_MENU_LIST',
  StoreSpotlight = 'STORE_SPOTLIGHT',
  TaskCenter = 'TASK_CENTER',
  TileGridList = 'TILE_GRID_LIST',
  TileList = 'TILE_LIST',
  WindowShoppingList = 'WINDOW_SHOPPING_LIST'
}

export type CartEmptyState = {
  __typename?: 'CartEmptyState';
  enabled?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type CashflowAuditItem = {
  __typename?: 'CashflowAuditItem';
  cashflowAuditItemId?: Maybe<Scalars['String']>;
};

export type CatalogAisle = {
  __typename?: 'CatalogAisle';
  /** The department associated with this aisle */
  department: CatalogDepartment;
  /** A unique identifier for the aisle */
  id: Scalars['ID'];
  /** The aisle's name - ('Candy & Chocolates', 'Chips & Pretzels', ...) */
  name: Scalars['String'];
};

export type CatalogClassificationInfo = {
  __typename?: 'CatalogClassificationInfo';
  /** The associated code (Could be a UPC - '034000170388', PLU - '1147', or In-House 'AB008') */
  code: Scalars['String'];
  /** The associated retailer (for in-house products) */
  retailer?: Maybe<Retailer>;
  /** The type of classified code (universal, commodity, in-house, ...) */
  type: ClassifiedTypeEnum;
};

export type CatalogDepartment = {
  __typename?: 'CatalogDepartment';
  /** An array of the department's aisles */
  aisles: Array<CatalogAisle>;
  /** A unique identifier for the department */
  id: Scalars['ID'];
  /** The department's name - ('Snacks', 'Meat & Seafood', ...) */
  name: Scalars['String'];
};

export type CatalogProduct = {
  __typename?: 'CatalogProduct';
  /** The classified code/type associated with the product (contains UPC details) */
  classificationInfo: CatalogClassificationInfo;
  /**
   * The classified code associated with the product (Also referred to as UPC - '034000170388')
   * @deprecated Use `classification info` instead, as it has more details than `classified_code`
   */
  classifiedCode?: Maybe<Scalars['String']>;
  /** Details about the product, e.g. 'Brown, overripe bananas, suitable for baking */
  description?: Maybe<Scalars['String']>;
  /** The product's name - 'Hershey's Extra Large Chocolate Bar' */
  displayName: Scalars['String'];
  /** A unique identifier for the product */
  id: Scalars['ID'];
  /**
   * The product's name - 'Hershey's Extra Large Chocolate Bar'
   * @deprecated Use `display name` instead, as it has more details than `name`
   */
  name: Scalars['String'];
  /** An absolute url of the primary image associated with the product - 'https://www.example.com/etc' */
  primaryImageUrl?: Maybe<Scalars['String']>;
};

export type CatalogRetailerProduct = {
  __typename?: 'CatalogRetailerProduct';
  /** The available item count of the retailer product */
  availableItemsCount?: Maybe<Scalars['Int']>;
  /** The details of the retailer product */
  details?: Maybe<Scalars['String']>;
  /** The display_name of the retailer product */
  displayName?: Maybe<Scalars['String']>;
  /** A unique identifier for the retailer product */
  id: Scalars['ID'];
  /** The Url of the retailer product's primary image */
  primaryImageUrl?: Maybe<Scalars['Url']>;
  /** The unique identifier for the associated product */
  productId?: Maybe<Scalars['ID']>;
  /** The size of the retailer product (e.g., 4 oz) */
  size?: Maybe<Scalars['String']>;
  /** The unit count of the retailer product */
  unitCount?: Maybe<Scalars['Int']>;
};

/** The connection type for CatalogRetailerProduct. */
export type CatalogRetailerProductConnection = {
  __typename?: 'CatalogRetailerProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CatalogRetailerProductEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CatalogRetailerProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CatalogRetailerProductEdge = {
  __typename?: 'CatalogRetailerProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CatalogRetailerProduct>;
};

export type CatalogSchema = {
  __typename?: 'CatalogSchema';
  _?: Maybe<Scalars['Boolean']>;
};

export type CatalogSearchDownloadsCatalogSearchDownloadSignedUrl = {
  __typename?: 'CatalogSearchDownloadsCatalogSearchDownloadSignedUrl';
  /** Signed url for the object */
  signedUrl: Scalars['String'];
};

export type CatalogTaxonomyNode = {
  __typename?: 'CatalogTaxonomyNode';
  /** A unique identifier for the taxonomy node */
  id: Scalars['ID'];
  /** The taxonomy nodes's name - ('Food', 'Meat', ...) */
  name: Scalars['String'];
  /** The parent taxonomy node */
  parentId: Scalars['ID'];
};

export enum CategorySurfaceType {
  AlcoholSurface = 'ALCOHOL_SURFACE',
  RetailerRecommendation = 'RETAILER_RECOMMENDATION'
}

export type CheckIfLaunchOnboardingInProgressByPartnerRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type CheckIfLaunchOnboardingInProgressByPartnerResponse = {
  __typename?: 'CheckIfLaunchOnboardingInProgressByPartnerResponse';
  hasInProgressLaunchOnboarding?: Maybe<Scalars['Boolean']>;
  workflowState?: Maybe<Scalars['String']>;
};

export type CheckLaunchOnboardingProgressByPartnerRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type CheckLaunchOnboardingProgressByPartnerResponse = {
  __typename?: 'CheckLaunchOnboardingProgressByPartnerResponse';
  workflowState?: Maybe<Scalars['String']>;
};

export type CheckoutSchema = {
  __typename?: 'CheckoutSchema';
  giftingEnabled?: Maybe<Scalars['Boolean']>;
  multiStepTippingEnabled?: Maybe<Scalars['Boolean']>;
  noRushSameDayDeliveryEnabled?: Maybe<Scalars['Boolean']>;
  smsMarketingOptIn?: Maybe<Scalars['Boolean']>;
  trackExtUserId?: Maybe<Scalars['Boolean']>;
};

export enum ClassifiedTypeEnum {
  /** A commodity (bananas, apples, ...) */
  Commodity = 'COMMODITY',
  /** A product that is specific to a retailer (not universal) */
  InHouse = 'IN_HOUSE',
  /** A universal product (standard product with a UPC) */
  Universal = 'UNIVERSAL',
  /** The classification type is not known */
  Unknown = 'UNKNOWN'
}

export type ClearOverrideHoursCriteriaInput = {
  date?: InputMaybe<GoogleTypeDateInput>;
  serviceType?: InputMaybe<RetailerServiceType>;
};

export type ClientConfiguration = {
  __typename?: 'ClientConfiguration';
  applicationType?: Maybe<Scalars['String']>;
  clientConfigurationId?: Maybe<Scalars['Int']>;
  retailerName?: Maybe<Scalars['String']>;
  retailerSlug?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSONObject']>;
  storeConfigurationIds?: Maybe<Scalars['String']>;
  userIsolationContextId?: Maybe<Scalars['Int']>;
};

export type ClientConfigurationInput = {
  applicationType?: InputMaybe<Scalars['String']>;
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  retailerName?: InputMaybe<Scalars['String']>;
  retailerSlug?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSONObject']>;
  storeConfigurationIds?: InputMaybe<Scalars['String']>;
  userIsolationContextId?: InputMaybe<Scalars['Int']>;
};

export enum ClientContextClient {
  Ipp = 'IPP',
  Unknown = 'UNKNOWN'
}

export type ClientContextInput = {
  canonicalId?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientContextClient>;
  email?: InputMaybe<Scalars['String']>;
};

export type ClientInput = {
  appName?: InputMaybe<Scalars['String']>;
  contextJson?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};

export type Collection = {
  __typename?: 'Collection';
  active?: Maybe<Scalars['Boolean']>;
  activeAdminOnly?: Maybe<Scalars['Boolean']>;
  collectionStoreConfigurations?: Maybe<Array<Maybe<CollectionStoreConfiguration>>>;
  collectionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  excludedRetailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  headerImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  imagePath?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['BigInt']>;
  mobileHeaderImageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nestedCollections?: Maybe<Array<Maybe<Collection>>>;
  parentId?: Maybe<Scalars['BigInt']>;
  position?: Maybe<Scalars['BigInt']>;
  products?: Maybe<Array<Maybe<Product>>>;
  replaceDepartmentIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  requiredAlcoholContext?: Maybe<Scalars['String']>;
  requiredRetailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  searchThumbWideImageUrl?: Maybe<Scalars['String']>;
  showDepartmentTile?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionStoreConfiguration = {
  __typename?: 'CollectionStoreConfiguration';
  id?: Maybe<Scalars['BigInt']>;
  pinned?: Maybe<Scalars['Boolean']>;
};

export type ColorsSchema = {
  __typename?: 'ColorsSchema';
  brandExpressDark?: Maybe<Scalars['String']>;
  brandExpressExtraDark?: Maybe<Scalars['String']>;
  brandExpressLight?: Maybe<Scalars['String']>;
  brandExpressRegular?: Maybe<Scalars['String']>;
  brandHighlightDark?: Maybe<Scalars['String']>;
  brandHighlightLight?: Maybe<Scalars['String']>;
  brandHighlightRegular?: Maybe<Scalars['String']>;
  brandLoyaltyDark?: Maybe<Scalars['String']>;
  brandLoyaltyLight?: Maybe<Scalars['String']>;
  brandLoyaltyRegular?: Maybe<Scalars['String']>;
  brandMaxDark?: Maybe<Scalars['String']>;
  brandMaxLight?: Maybe<Scalars['String']>;
  brandPrimaryDark?: Maybe<Scalars['String']>;
  brandPrimaryExtraDark?: Maybe<Scalars['String']>;
  brandPrimaryRegular?: Maybe<Scalars['String']>;
  brandPromotionalDark?: Maybe<Scalars['String']>;
  brandPromotionalLight?: Maybe<Scalars['String']>;
  brandPromotionalRegular?: Maybe<Scalars['String']>;
  brandSecondaryDark?: Maybe<Scalars['String']>;
  brandSecondaryLight?: Maybe<Scalars['String']>;
  brandSecondaryRegular?: Maybe<Scalars['String']>;
  brandTertiaryLight?: Maybe<Scalars['String']>;
  brandTertiaryRegular?: Maybe<Scalars['String']>;
  highContrastColors?: Maybe<HighContrastColorsSchema>;
  plusDark?: Maybe<Scalars['String']>;
  plusExtraDark?: Maybe<Scalars['String']>;
  plusExtraLight?: Maybe<Scalars['String']>;
  plusLight?: Maybe<Scalars['String']>;
  plusRegular?: Maybe<Scalars['String']>;
  systemDetrimentalDark?: Maybe<Scalars['String']>;
  systemDetrimentalExtraDark?: Maybe<Scalars['String']>;
  systemDetrimentalLight?: Maybe<Scalars['String']>;
  systemDetrimentalRegular?: Maybe<Scalars['String']>;
  systemGrayscale_00?: Maybe<Scalars['String']>;
  systemGrayscale_10?: Maybe<Scalars['String']>;
  systemGrayscale_20?: Maybe<Scalars['String']>;
  systemGrayscale_30?: Maybe<Scalars['String']>;
  systemGrayscale_50?: Maybe<Scalars['String']>;
  systemGrayscale_70?: Maybe<Scalars['String']>;
  systemSuccessDark?: Maybe<Scalars['String']>;
  systemSuccessLight?: Maybe<Scalars['String']>;
  systemSuccessRegular?: Maybe<Scalars['String']>;
  tertiaryRegular?: Maybe<Scalars['String']>;
};

export type CombineAuditBatchesRequestInput = {
  batchUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<Scalars['String']>;
  workflow?: InputMaybe<Scalars['String']>;
};

export type CombineAuditBatchesResponse = {
  __typename?: 'CombineAuditBatchesResponse';
  batchUuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type CompactBanner = {
  __typename?: 'CompactBanner';
  backgroundColorHex?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaBackgroundColorHex?: Maybe<Scalars['String']>;
  ctaColorHex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type CompactBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ConfigurableButtonInput = {
  /** Background color of the button */
  backgroundColor?: InputMaybe<Scalars['String']>;
  /** Color of the button text */
  color?: InputMaybe<Scalars['String']>;
  /** The button text */
  label: Scalars['String'];
  /** The button style to use */
  styleVariant?: InputMaybe<ButtonVariantEnum>;
};

export type ConfigurableTextInput = {
  /** The color of the text (Eg. #FFFFFF) */
  color: Scalars['String'];
  /** The text to display */
  text: Scalars['String'];
};

export type ConfirmedStoreHours = {
  __typename?: 'ConfirmedStoreHours';
  confirmed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<GoogleTypeDate>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
};

export type ConfirmedStoreHoursInput = {
  confirmed?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type ConnectApiKey = {
  __typename?: 'ConnectApiKey';
  applicationId?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiresIn?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  ipWhitelist?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  rotatedAt?: Maybe<Scalars['DateTime']>;
  rotatedFromId?: Maybe<Scalars['BigInt']>;
  scopes?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ConnectRetailerCallbackConfiguration = {
  __typename?: 'ConnectRetailerCallbackConfiguration';
  accessTokenTtl?: Maybe<Scalars['Int']>;
  authUri?: Maybe<Scalars['String']>;
  clientConfigurationId?: Maybe<Scalars['BigInt']>;
  credentials?: Maybe<ConnectRetailerCallbackCredentials>;
  enabled?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  returnsPaymentAck?: Maybe<Scalars['Boolean']>;
  v1Settings?: Maybe<ConnectRetailerCallbackV1Settings>;
  v2Settings?: Maybe<ConnectRetailerCallbackV2Settings>;
};

export type ConnectRetailerCallbackConfigurationInput = {
  accessTokenTtl?: InputMaybe<Scalars['Int']>;
  authUri?: InputMaybe<Scalars['String']>;
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
  credentials?: InputMaybe<ConnectRetailerCallbackCredentialsInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  environment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  returnsPaymentAck?: InputMaybe<Scalars['Boolean']>;
  v1Settings?: InputMaybe<ConnectRetailerCallbackV1SettingsInput>;
  v2Settings?: InputMaybe<ConnectRetailerCallbackV2SettingsInput>;
};

export type ConnectRetailerCallbackCredentials = {
  __typename?: 'ConnectRetailerCallbackCredentials';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  clientSecretEnvKey?: Maybe<Scalars['String']>;
  customKey?: Maybe<Scalars['String']>;
  customTokenEnvKey?: Maybe<Scalars['String']>;
  grantType?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type ConnectRetailerCallbackCredentialsInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clientSecretEnvKey?: InputMaybe<Scalars['String']>;
  customKey?: InputMaybe<Scalars['String']>;
  customTokenEnvKey?: InputMaybe<Scalars['String']>;
  grantType?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
};

export type ConnectRetailerCallbackV1Settings = {
  __typename?: 'ConnectRetailerCallbackV1Settings';
  callbackUri?: Maybe<Scalars['String']>;
  statusesOfInterest?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConnectRetailerCallbackV1SettingsInput = {
  callbackUri?: InputMaybe<Scalars['String']>;
  statusesOfInterest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ConnectRetailerCallbackV2Settings = {
  __typename?: 'ConnectRetailerCallbackV2Settings';
  callbackUri?: Maybe<Scalars['String']>;
  orderLocationCallbackFrequency?: Maybe<Scalars['BigInt']>;
  statusesOfInterest?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConnectRetailerCallbackV2SettingsInput = {
  callbackUri?: InputMaybe<Scalars['String']>;
  orderLocationCallbackFrequency?: InputMaybe<Scalars['BigInt']>;
  statusesOfInterest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ConnectionMeta = {
  __typename?: 'ConnectionMeta';
  /** The total number of connection elements */
  totalCount: Scalars['Int'];
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContentManagementAnnouncementBannerGenericImages = {
  __typename?: 'ContentManagementAnnouncementBannerGenericImages';
  /** The foreground image url in the placement to be used on desktop clients */
  desktop?: Maybe<Scalars['Url']>;
  /** The foreground image url in the placement to be used on mobile clients */
  mobile: Scalars['Url'];
};

export type ContentManagementConfigurableButton = {
  __typename?: 'ContentManagementConfigurableButton';
  /** The background color of the button (Eg. #FFFFFF) */
  backgroundColor?: Maybe<Scalars['String']>;
  /** Color of the button text (Eg. #000000) */
  color?: Maybe<Scalars['String']>;
  /** The button text */
  label: Scalars['String'];
  /** The button style to use */
  styleVariant: ButtonVariantEnum;
};

export type ContentManagementConfigurableText = {
  __typename?: 'ContentManagementConfigurableText';
  /** The color of the text (Eg. #FFFFFF) */
  color: Scalars['String'];
  /** The text to display */
  text?: Maybe<Scalars['String']>;
};

export type ContentManagementFlyoutPlacement = {
  __typename?: 'ContentManagementFlyoutPlacement';
  /** A unique identifier of the banner associated with the placement */
  bannerId?: Maybe<Scalars['ID']>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** The style and text of the subheading */
  descriptionText: ContentManagementConfigurableText;
  /** The style and text of the secondary text */
  disclaimerText?: Maybe<ContentManagementConfigurableText>;
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment?: Maybe<ContentManagementPlacementExperiment>;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The possible images to display */
  images: ContentManagementAnnouncementBannerGenericImages;
  /** The retailers that this placement should be displayed on */
  includedRetailerIds: Array<Scalars['Int']>;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds: Array<Scalars['Int']>;
  /** The layout the placement should appear on */
  layout: Scalars['String'];
  /** The style and text of the button within the placement */
  linkButton?: Maybe<ContentManagementConfigurableButton>;
  /** Where interacting with the placement leads */
  linkDestination?: Maybe<ContentManagementPlacementDestination>;
  /** Locale */
  locale: BannerLocaleEnum;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The style and text of the button within the placement */
  primaryButton?: Maybe<ContentManagementConfigurableButton>;
  /** Where interacting with the placement leads */
  primaryDestination?: Maybe<ContentManagementPlacementDestination>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The style and text of the button within the placement */
  secondaryButton?: Maybe<ContentManagementConfigurableButton>;
  /** Where interacting with the placement leads */
  secondaryDestination?: Maybe<ContentManagementPlacementDestination>;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The layout the placement should appear on */
  stringLayout: Scalars['String'];
  /** The style and text of the heading */
  titleText: ContentManagementConfigurableText;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
  /** The variant of the banner */
  variant: Scalars['String'];
};

export type ContentManagementHeroBannerPlacement = {
  __typename?: 'ContentManagementHeroBannerPlacement';
  /** The color of the background for text images (Eg. #FFFFFF) */
  backgroundColor?: Maybe<Scalars['String']>;
  /** A unique identifier of the banner associated with the placement */
  bannerId?: Maybe<Scalars['ID']>;
  /** The style and text of the button within the placement */
  button?: Maybe<ContentManagementConfigurableButton>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** Where interacting with the placement leads */
  destination?: Maybe<ContentManagementPlacementDestination>;
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment?: Maybe<ContentManagementPlacementExperiment>;
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The possible images to display */
  images: ContentManagementAnnouncementBannerGenericImages;
  /** The retailers that this placement should be displayed on */
  includedRetailerIds: Array<Scalars['Int']>;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds: Array<Scalars['Int']>;
  /** The layout the placement should appear on */
  layout: Scalars['String'];
  /** Locale */
  locale: BannerLocaleEnum;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The layout the placement should appear on */
  stringLayout: Scalars['String'];
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
  /** The color of the text area (Eg. #FFFFFF) */
  textareaColor?: Maybe<Scalars['String']>;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
  /** The variant of the banner */
  variant: Scalars['String'];
};

export type ContentManagementPbiConfiguration = {
  __typename?: 'ContentManagementPbiConfiguration';
  /** The url for the retailer's pbi instance */
  domainName: Scalars['String'];
  /** The unique slug for the retailer */
  slug: Scalars['String'];
};

export type ContentManagementPlacementDestination = {
  __typename?: 'ContentManagementPlacementDestination';
  /** The human readable value of the target */
  displayValue?: Maybe<Scalars['String']>;
  /** The target destination of interacting with the placement. Can be an ID of a product/collection/department, or a URL */
  target: Scalars['String'];
  /** What the corresponding target refers to */
  type: PlacementDestinationTypeEnum;
};

export type ContentManagementPlacementExperiment = {
  __typename?: 'ContentManagementPlacementExperiment';
  /** Experiment Assignment or Lookup */
  accessMethod?: Maybe<PlacementExperimentMethodEnum>;
  /** Name of the Experiment */
  name?: Maybe<Scalars['String']>;
  /** Variant of the Experiment for which placement should be visible */
  variant?: Maybe<Scalars['String']>;
};

export type ContentManagementPlacementImages = {
  __typename?: 'ContentManagementPlacementImages';
  /** The image url of the placement to display for large viewports */
  large?: Maybe<Scalars['Url']>;
  /** The image url of the placement to display for small viewports */
  small?: Maybe<Scalars['Url']>;
};

export type ContentManagementPlacementSchedule = {
  __typename?: 'ContentManagementPlacementSchedule';
  /** When the placement should stop being active */
  endDate?: Maybe<Scalars['Time']>;
  /** When the placement should become active */
  startDate: Scalars['Time'];
  /** The timezone in which the banner will stop being active at midnight */
  timezone?: Maybe<Scalars['String']>;
};

export type ContentManagementSecondaryBannerPlacement = {
  __typename?: 'ContentManagementSecondaryBannerPlacement';
  /** The color of the background for text images (Eg. #FFFFFF) */
  backgroundColor?: Maybe<Scalars['String']>;
  /** A unique identifier of the banner associated with the placement */
  bannerId?: Maybe<Scalars['ID']>;
  /** The style and text of the button within the placement */
  button?: Maybe<ContentManagementConfigurableButton>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** Where interacting with the placement leads */
  destination?: Maybe<ContentManagementPlacementDestination>;
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment?: Maybe<ContentManagementPlacementExperiment>;
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The possible images to display */
  images: ContentManagementAnnouncementBannerGenericImages;
  /** The retailers that this placement should be displayed on */
  includedRetailerIds: Array<Scalars['Int']>;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds: Array<Scalars['Int']>;
  /** The layout the placement should appear on */
  layout: Scalars['String'];
  /** Locale */
  locale: BannerLocaleEnum;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The style and text of the secondary text */
  secondaryText?: Maybe<ContentManagementConfigurableText>;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The layout the placement should appear on */
  stringLayout: Scalars['String'];
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
  /** The variant of the banner */
  variant: Scalars['String'];
};

export type ContentManagementStorefrontPlacement = {
  __typename?: 'ContentManagementStorefrontPlacement';
  /** The color of the background for text images (Eg. #FFFFFF) */
  backgroundColor?: Maybe<Scalars['String']>;
  /** A unique identifier of the banner associated with the placement */
  bannerId?: Maybe<Scalars['ID']>;
  /** The banners configured to the placement */
  banners: Array<ContentManagementStorefrontPlacementBanner>;
  /** The style and text of the button within the placement */
  button?: Maybe<ContentManagementConfigurableButton>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** The style and text of the description */
  descriptionText: ContentManagementConfigurableText;
  /** Where interacting with the placement leads */
  destination?: Maybe<ContentManagementPlacementDestination>;
  /** Whether or not the placement can be dismissed */
  dismissable: Scalars['Boolean'];
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: ContentManagementPlacementImages;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds?: Maybe<Array<Scalars['Int']>>;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** A unique identifier for the retailer */
  retailerId: Scalars['ID'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
};

export type ContentManagementStorefrontPlacementBanner = {
  __typename?: 'ContentManagementStorefrontPlacementBanner';
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor?: Maybe<Scalars['String']>;
  /** The style and text of the button within the placement */
  button?: Maybe<ContentManagementConfigurableButton>;
  /** The style and text of the description */
  descriptionText: ContentManagementConfigurableText;
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier of the banner associated with the placement */
  id?: Maybe<Scalars['ID']>;
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: ContentManagementPlacementImages;
  /** The banner's locale */
  locale?: Maybe<BannerLocaleEnum>;
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
};

/** The connection type for ContentManagementStorefrontPlacement. */
export type ContentManagementStorefrontPlacementConnection = {
  __typename?: 'ContentManagementStorefrontPlacementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentManagementStorefrontPlacementEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ContentManagementStorefrontPlacement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContentManagementStorefrontPlacementEdge = {
  __typename?: 'ContentManagementStorefrontPlacementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ContentManagementStorefrontPlacement>;
};

export type ContentManagementUnknownBannerPlacement = {
  __typename?: 'ContentManagementUnknownBannerPlacement';
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment?: Maybe<ContentManagementPlacementExperiment>;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
};

export type ContentManagementVisibilityConditionCountryIds = {
  __typename?: 'ContentManagementVisibilityConditionCountryIds';
  /** list of country ids */
  countryIds: Array<Scalars['Int']>;
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionDayOfWeek = {
  __typename?: 'ContentManagementVisibilityConditionDayOfWeek';
  /** list of days within the week */
  days: Array<Scalars['String']>;
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionExperimentVariant = {
  __typename?: 'ContentManagementVisibilityConditionExperimentVariant';
  /** The method of the experiment */
  experimentMethod: PlacementExperimentMethodEnum;
  /** The name of the experiment */
  experimentName: Scalars['String'];
  /** The name of the experiment variant */
  experimentVariant: Scalars['String'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionExpressMembership = {
  __typename?: 'ContentManagementVisibilityConditionExpressMembership';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Whether the user should be an express member */
  membershipStatus: Scalars['Boolean'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: Maybe<Scalars['ID']>;
};

export type ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout = {
  __typename?: 'ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: Maybe<Scalars['ID']>;
};

export type ContentManagementVisibilityConditionMatchesAnyRetailerId = {
  __typename?: 'ContentManagementVisibilityConditionMatchesAnyRetailerId';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of retailer ids */
  retailerIds: Array<Scalars['Int']>;
};

export type ContentManagementVisibilityConditionMinimumPlatformVersion = {
  __typename?: 'ContentManagementVisibilityConditionMinimumPlatformVersion';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** The platform the version is being checked for */
  platform: PlacementPlatformEnum;
  /** Version used for the check */
  version: Scalars['String'];
};

export type ContentManagementVisibilityConditionNewVerticalUserTargeting = {
  __typename?: 'ContentManagementVisibilityConditionNewVerticalUserTargeting';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** The module type */
  moduleType: Scalars['String'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionRetailerAvailability = {
  __typename?: 'ContentManagementVisibilityConditionRetailerAvailability';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** A unique identifier of the retailer associated with the visibility condition */
  retailerId: Scalars['Int'];
};

export type ContentManagementVisibilityConditionShopped = {
  __typename?: 'ContentManagementVisibilityConditionShopped';
  /** Id of the country where the check is to be performed */
  countryId: Scalars['Int'];
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Number of days in the past to check */
  numberOfDays: Scalars['Int'];
  /** Number of times in the past to check */
  numberOfTimes: Scalars['Int'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId: Scalars['Int'];
};

export type ContentManagementVisibilityConditionShoppedAtRetailer = {
  __typename?: 'ContentManagementVisibilityConditionShoppedAtRetailer';
  /** Id of the country where the check is to be performed */
  countryId: Scalars['Int'];
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Number of days in the past to check */
  numberOfDays: Scalars['Int'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the Retailer */
  retailerId: Scalars['Int'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId: Scalars['Int'];
};

export type ContentManagementVisibilityConditionUnknownVisibilityCondition = {
  __typename?: 'ContentManagementVisibilityConditionUnknownVisibilityCondition';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionUserInteractionLimit = {
  __typename?: 'ContentManagementVisibilityConditionUserInteractionLimit';
  /** Number of seconds to wait before resetting the counter */
  countCooldownSec?: Maybe<Scalars['Int']>;
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Maximum number of dismiss events */
  maxDismissedCount?: Maybe<Scalars['Int']>;
  /** Maximum number of click events */
  maxEngagementsCount?: Maybe<Scalars['Int']>;
  /** Maximum number of view events */
  maxImpressionsCount?: Maybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionUtmParameters = {
  __typename?: 'ContentManagementVisibilityConditionUtmParameters';
  /** coupon_code */
  couponCode: Scalars['String'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** utm_campaign */
  utmCampaign: Scalars['String'];
  /** utm_content */
  utmContent: Scalars['String'];
  /** utm_medium */
  utmMedium: Scalars['String'];
  /** utm_source */
  utmSource: Scalars['String'];
  /** utm_term */
  utmTerm: Scalars['String'];
};

export type ContentManagementVisibilityConditionWhitelistedPostalCodes = {
  __typename?: 'ContentManagementVisibilityConditionWhitelistedPostalCodes';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of postal codes */
  postalCodes: Array<Scalars['String']>;
};

export type ContentManagementVisibilityConditionWhitelistedZones = {
  __typename?: 'ContentManagementVisibilityConditionWhitelistedZones';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of zone ids */
  zones: Array<Scalars['String']>;
};

export type ContentPage = {
  __typename?: 'ContentPage';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  internalName?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  slug?: Maybe<Scalars['String']>;
  storeConfigurationId?: Maybe<Scalars['BigInt']>;
};

export type ContentPageMetaInfoStatusFound302 = {
  __typename?: 'ContentPageMetaInfoStatusFound302';
  redirectUrl?: Maybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusFound302Input = {
  redirectUrl?: InputMaybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusMovedPermanently301 = {
  __typename?: 'ContentPageMetaInfoStatusMovedPermanently301';
  redirectUrl?: Maybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusMovedPermanently301Input = {
  redirectUrl?: InputMaybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusNotFound404 = {
  __typename?: 'ContentPageMetaInfoStatusNotFound404';
  _?: Maybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusNotFound404Input = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusOk200 = {
  __typename?: 'ContentPageMetaInfoStatusOk200';
  _?: Maybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusOk200Input = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusOneOf = {
  __typename?: 'ContentPageMetaInfoStatusOneOf';
  found302?: Maybe<ContentPageMetaInfoStatusFound302>;
  movedPermanently301?: Maybe<ContentPageMetaInfoStatusMovedPermanently301>;
  notFound404?: Maybe<ContentPageMetaInfoStatusNotFound404>;
  ok200?: Maybe<ContentPageMetaInfoStatusOk200>;
};

export type ContentPageMetaInfoStatusOneOfInput = {
  found302?: InputMaybe<ContentPageMetaInfoStatusFound302Input>;
  movedPermanently301?: InputMaybe<ContentPageMetaInfoStatusMovedPermanently301Input>;
  notFound404?: InputMaybe<ContentPageMetaInfoStatusNotFound404Input>;
  ok200?: InputMaybe<ContentPageMetaInfoStatusOk200Input>;
};

export type ContentPageV2 = {
  __typename?: 'ContentPageV2';
  activeVersionId?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  internalName?: Maybe<Scalars['String']>;
  pageType?: Maybe<Scalars['String']>;
  retailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  slug?: Maybe<Scalars['String']>;
  storeConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type ContentPageVersion = {
  __typename?: 'ContentPageVersion';
  contentPageId?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  previewToken?: Maybe<Scalars['String']>;
  routingStatus?: Maybe<ContentPageMetaInfoStatusOneOf>;
  seoCanonicalUrl?: Maybe<Scalars['String']>;
  seoNoIndex?: Maybe<Scalars['Boolean']>;
  trackingProperties?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContentPageVersionMeta = {
  __typename?: 'ContentPageVersionMeta';
  contentPageVersionId?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  locale?: Maybe<InstacartCustomersSharedV1Locale>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Defines filtering criteria based on content attributes in a request to list approval requests. */
export type ContentSearchInput = {
  /** Returns ARs that match the provided content_type. */
  equalsContentType?: InputMaybe<Scalars['String']>;
  /** Returns ARs that contain a superset of the provided set of IDs. */
  hasContentIds?: InputMaybe<Array<Scalars['String']>>;
};

/** The type of content snapshot. */
export enum ContentSnapshot {
  /** A snapshot of the original content as it currently is published today. */
  Base = 'BASE',
  /** A snapshot of the proposed changes to the content. */
  Revision = 'REVISION'
}

export type ContractRenderData = {
  __typename?: 'ContractRenderData';
  companyAddress?: Maybe<Scalars['String']>;
  companyContactName?: Maybe<Scalars['String']>;
  companyContactTitle?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currentDate?: Maybe<Scalars['String']>;
  stateOfIncorporation?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  /** country code */
  alpha2?: Maybe<Scalars['String']>;
  /** Unique identifier */
  id: Scalars['ID'];
  /** country name */
  name: Scalars['String'];
};

export type CountryIdsInput = {
  /** list of country ids */
  countryIds: Array<Scalars['Int']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of CountryIdsVisibilityConditionCreate */
export type CountryIdsVisibilityConditionCreatePayload = {
  __typename?: 'CountryIdsVisibilityConditionCreatePayload';
  /** The newly created CountryIds visibility condition */
  countryIdsVisibilityCondition: ContentManagementVisibilityConditionCountryIds;
};

/** Autogenerated return type of CountryIdsVisibilityConditionUpdate */
export type CountryIdsVisibilityConditionUpdatePayload = {
  __typename?: 'CountryIdsVisibilityConditionUpdatePayload';
  /** The newly updated CountryIds visibility condition */
  countryIdsVisibilityCondition: ContentManagementVisibilityConditionCountryIds;
};

export type CreateClientConfigurationRequestInput = {
  allowExistingUserIsolationContextId?: InputMaybe<Scalars['Boolean']>;
  clientConfiguration?: InputMaybe<ClientConfigurationInput>;
};

export type CreateClientConfigurationResponse = {
  __typename?: 'CreateClientConfigurationResponse';
  clientConfiguration?: Maybe<ClientConfiguration>;
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateConnectRetailerCallbackConfigurationRequestInput = {
  callbackConfiguration?: InputMaybe<ConnectRetailerCallbackConfigurationInput>;
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateConnectRetailerCallbackConfigurationResponse = {
  __typename?: 'CreateConnectRetailerCallbackConfigurationResponse';
  callbackConfiguration?: Maybe<ConnectRetailerCallbackConfiguration>;
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateContentPageRequestInput = {
  internalName?: InputMaybe<Scalars['String']>;
  layout?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateContentPageRequestV2Input = {
  internalName?: InputMaybe<Scalars['String']>;
  pageType?: InputMaybe<Scalars['String']>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  slug?: InputMaybe<Scalars['String']>;
  storeConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  teamOwner?: InputMaybe<Scalars['String']>;
};

export type CreateContentPageResponse = {
  __typename?: 'CreateContentPageResponse';
  contentPage?: Maybe<ContentPage>;
};

export type CreateContentPageResponseV2 = {
  __typename?: 'CreateContentPageResponseV2';
  contentPage?: Maybe<ContentPageV2>;
};

export type CreateContentPageVersionMetaRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  description?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateContentPageVersionMetaResponse = {
  __typename?: 'CreateContentPageVersionMetaResponse';
  contentPageVersionMeta?: Maybe<ContentPageVersionMeta>;
};

export type CreateContentPageVersionRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  baseContentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  routingStatus?: InputMaybe<ContentPageMetaInfoStatusOneOfInput>;
  seoCanonicalUrl?: InputMaybe<Scalars['String']>;
  seoNoIndex?: InputMaybe<Scalars['Boolean']>;
  trackingProperties?: InputMaybe<Scalars['JSONObject']>;
};

export type CreateContentPageVersionResponse = {
  __typename?: 'CreateContentPageVersionResponse';
  contentPageVersion?: Maybe<ContentPageVersion>;
};

export type CreateDetectionRequestInput = {
  attributes?: InputMaybe<DetectionVersionAttributesInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateDetectionResponse = {
  __typename?: 'CreateDetectionResponse';
  id?: Maybe<Scalars['BigInt']>;
  version?: Maybe<Scalars['BigInt']>;
};

export type CreateDetectionVersionRequestInput = {
  attributes?: InputMaybe<DetectionVersionAttributesInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type CreateDetectionVersionResponse = {
  __typename?: 'CreateDetectionVersionResponse';
  version?: Maybe<Scalars['BigInt']>;
};

export type CreateDriverRequestInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateDriverResponse = {
  __typename?: 'CreateDriverResponse';
  id?: Maybe<Scalars['BigInt']>;
};

export type CreateImageUploadBatchRequestInput = {
  imagesAwsDetails?: InputMaybe<ImagesAwsDetailsInput>;
  imagesZipKey?: InputMaybe<Scalars['String']>;
  importType?: InputMaybe<Scalars['String']>;
  localeId?: InputMaybe<Scalars['BigInt']>;
  sourceType?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateImageUploadBatchResponse = {
  __typename?: 'CreateImageUploadBatchResponse';
  imageUploadBatchId?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['String']>;
};

export type CreateLastmileOrderFromDashboardRequestInput = {
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  order?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1LastMileOrderDashboardCreationParamsInput>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateLastmileOrderFromDashboardResponse = {
  __typename?: 'CreateLastmileOrderFromDashboardResponse';
  order?: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

export type CreateLayoutVariantRequestInput = {
  layoutVariant?: InputMaybe<LayoutVariantInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateLayoutVariantResponse = {
  __typename?: 'CreateLayoutVariantResponse';
  layoutVariant?: Maybe<LayoutVariant>;
};

export type CreateMomentRequestInput = {
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignSubmissionDeadline?: InputMaybe<Scalars['DateTime']>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  templateId?: InputMaybe<Scalars['String']>;
};

export type CreateMomentResponse = {
  __typename?: 'CreateMomentResponse';
  moment?: Maybe<Moment>;
};

export type CreateNavigationPreviewRequestInput = {
  navigation?: InputMaybe<NavigationInput>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateNavigationPreviewResponse = {
  __typename?: 'CreateNavigationPreviewResponse';
  previewToken?: Maybe<Scalars['String']>;
};

export type CreateNavigationRequestInput = {
  layout?: InputMaybe<Scalars['String']>;
  navigation?: InputMaybe<NavigationInput>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateNavigationResponse = {
  __typename?: 'CreateNavigationResponse';
  navigation?: Maybe<Navigation>;
};

export type CreateOnboardingStoreLocationRequestInput = {
  address?: InputMaybe<AddressInput>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOnboardingStoreLocationResponse = {
  __typename?: 'CreateOnboardingStoreLocationResponse';
  onboarding?: Maybe<Onboarding>;
};

export type CreateOosAcknowledgmentRequestInput = {
  oosId?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateOosAcknowledgmentResponse = {
  __typename?: 'CreateOosAcknowledgmentResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type CreateOrUpdateLinkAutosuggestionAdminParametersInput = {
  linkAutosuggestion?: InputMaybe<LinkAutosuggestionAdminInput>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateOrUpdateLinkAutosuggestionAdminRequestInput = {
  parameters?: InputMaybe<CreateOrUpdateLinkAutosuggestionAdminParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type CreateOrUpdateLinkAutosuggestionAdminResponse = {
  __typename?: 'CreateOrUpdateLinkAutosuggestionAdminResponse';
  linkAutosuggestion?: Maybe<LinkAutosuggestionAdmin>;
};

export type CreateOrUpdateReturnRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  returnData?: InputMaybe<ProposedReturnInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateOrUpdateReturnResponse = {
  __typename?: 'CreateOrUpdateReturnResponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<ResponseStatus>;
};

export type CreatePartnerApiKeyRequestInput = {
  environment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type CreatePiiRequestForRetailerRequestInput = {
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  requestType?: InputMaybe<RequestType>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerNotes?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type CreatePiiRequestForRetailerResponse = {
  __typename?: 'CreatePiiRequestForRetailerResponse';
  piiRequestCreationStatus?: Maybe<PiiRequestCreationStatus>;
  piiRequestId?: Maybe<Scalars['BigInt']>;
};

export type CreatePlacementContentRequestInput = {
  adminContext?: InputMaybe<PlacementAdminContextInput>;
  content?: InputMaybe<PlacementContentInput>;
};

export type CreatePlacementContentResponse = {
  __typename?: 'CreatePlacementContentResponse';
  content?: Maybe<PlacementContent>;
};

export type CreatePlacementRequestInput = {
  placement?: InputMaybe<PlacementInput>;
};

export type CreatePlacementResponse = {
  __typename?: 'CreatePlacementResponse';
  placement?: Maybe<Placement>;
};

export type CreateRetailerCampaignRequestInput = {
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignReference?: InputMaybe<CampaignReferenceInput>;
  countryId?: InputMaybe<Scalars['String']>;
  countryIdValue?: InputMaybe<Scalars['BigInt']>;
  couponCount?: InputMaybe<Scalars['BigInt']>;
  creatorIppUserId?: InputMaybe<Scalars['BigInt']>;
  discountPolicyIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<CampaignFilterInput>>>;
  ippUserCanonicalUuid?: InputMaybe<Scalars['String']>;
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  marketingMessagingDetails?: InputMaybe<MarketingMessagingDetailsInput>;
  name?: InputMaybe<Scalars['String']>;
  narrowSearchTerms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offerDurationInDays?: InputMaybe<Scalars['BigInt']>;
  offers?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferInput>>>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerAgnostic?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  rouletteFeature?: InputMaybe<CampaignRouletteFeatureInput>;
  schedule?: InputMaybe<Scalars['String']>;
  segmentId?: InputMaybe<Scalars['String']>;
  separateTreatmentPolicies?: InputMaybe<Scalars['Boolean']>;
  skipCreateCouponCode?: InputMaybe<Scalars['Boolean']>;
  slotIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  targeting?: InputMaybe<Array<InputMaybe<Targeting>>>;
  targetingRules?: InputMaybe<Array<InputMaybe<TargetingRulesInput>>>;
  templateId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  treatmentTask?: InputMaybe<CampaignTreatmentTaskRequestParamsInput>;
  validForDays?: InputMaybe<Scalars['BigInt']>;
};

export type CreateRetailerCampaignTreatmentParamsInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  treatmentDetails?: InputMaybe<Array<InputMaybe<RetailerCampaignTreatmentDetailsInput>>>;
  treatmentType?: InputMaybe<RetailerCampaignTreatmentType>;
};

export type CreateRoleInput = {
  /** A description of the role */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the role */
  name: Scalars['String'];
  partnerId?: InputMaybe<Scalars['ID']>;
  /** The permissions associated with this role */
  permissions?: InputMaybe<Array<PermissionInput>>;
  retailerId?: InputMaybe<Scalars['ID']>;
};

export type CreateSignupRequestInput = {
  email?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  signupPartnerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateSignupResponse = {
  __typename?: 'CreateSignupResponse';
  token?: Maybe<Scalars['String']>;
};

export type CreateSlotCreativeRequestInput = {
  creativeId?: InputMaybe<Scalars['BigInt']>;
  creativeRank?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['BigInt']>;
  treatmentPolicyId?: InputMaybe<Scalars['String']>;
};

export type CreateSlotCreativeResponse = {
  __typename?: 'CreateSlotCreativeResponse';
  slotCreative?: Maybe<SlotCreative>;
};

export type CreateSlotRequestInput = {
  description?: InputMaybe<Scalars['String']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  legacySlotId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateSlotResponse = {
  __typename?: 'CreateSlotResponse';
  slot?: Maybe<Slot>;
};

export type CreateStoreConfigurationDraftRequestInput = {
  draftName?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateStoreConfigurationDraftResponse = {
  __typename?: 'CreateStoreConfigurationDraftResponse';
  domainConfigurations?: Maybe<DomainConfiguration>;
  draftId?: Maybe<Scalars['String']>;
  draftName?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorReason?: Maybe<ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
  storeConfigurationId?: Maybe<Scalars['BigInt']>;
};

export type CreateStoreConfigurationRequestInput = {
  allowExistingUserIsolationContextId?: InputMaybe<Scalars['Boolean']>;
  storeConfiguration?: InputMaybe<StoreConfigurationInput>;
};

export type CreateStoreConfigurationResponse = {
  __typename?: 'CreateStoreConfigurationResponse';
  errorMessage?: Maybe<Scalars['String']>;
  storeConfiguration?: Maybe<StoreConfiguration>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateThemePageRequestInput = {
  themePage?: InputMaybe<ThemePageInput>;
};

export type CreateThemePageResponse = {
  __typename?: 'CreateThemePageResponse';
  themePage?: Maybe<ThemePage>;
};

export type CreateTicketRequestInput = {
  assignee?: InputMaybe<UserInput>;
  client?: InputMaybe<ClientInput>;
  description?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  entityType?: InputMaybe<EntityType>;
  localeCode?: InputMaybe<Scalars['String']>;
  problemType?: InputMaybe<ProblemType>;
  reportedOnBehalfOf?: InputMaybe<UserInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateTicketResponse = {
  __typename?: 'CreateTicketResponse';
  cptTicketId?: Maybe<Scalars['BigInt']>;
};

export type CreateValueBasedOverrideRequestInput = {
  valueBasedOverride?: InputMaybe<ValueBasedOverrideRequestInput>;
};

export type CreateValueBasedOverrideResponse = {
  __typename?: 'CreateValueBasedOverrideResponse';
  valueBasedOverride?: Maybe<ValueBasedOverrideResponse>;
};

export type CustomFontFamilySchema = {
  __typename?: 'CustomFontFamilySchema';
  customFontFamily?: Maybe<FontFamily>;
};

export type CustomImages = {
  __typename?: 'CustomImages';
  accountOrdersEmptyState?: Maybe<AccountOrdersEmptyState>;
  cartEmptyState?: Maybe<CartEmptyState>;
};

export enum CustomersAddressType {
  AddressTypeUnspecified = 'ADDRESS_TYPE_UNSPECIFIED',
  Business = 'BUSINESS',
  Residential = 'RESIDENTIAL'
}

export type CustomersAdminRequestContextInput = {
  retailerAppId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersAmounts = {
  __typename?: 'CustomersAmounts';
  amount?: Maybe<CustomersGoogleTypeMoney>;
  labelInEnglish?: Maybe<Scalars['String']>;
};

export type CustomersContext = {
  __typename?: 'CustomersContext';
  clientConfigurationId?: Maybe<Scalars['Int']>;
  clientName?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  storeConfigurationId?: Maybe<Scalars['Int']>;
};

export type CustomersGetHandlingDetailsRequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetHandlingDetailsResponse = {
  __typename?: 'CustomersGetHandlingDetailsResponse';
  handlingDetails?: Maybe<CustomersGetHandlingDetailsResponseHandlingDetails>;
  handlingDetailsByOrderReference?: Maybe<CustomersGetHandlingDetailsResponseHandlingDetails>;
};

export type CustomersGetHandlingDetailsResponseHandlingDetails = {
  __typename?: 'CustomersGetHandlingDetailsResponseHandlingDetails';
  acceptableRetailerLocationIds?: Maybe<Array<Maybe<Scalars['CustomersBigInt']>>>;
  delivery?: Maybe<CustomersGetHandlingDetailsResponseHandlingDetailsDelivery>;
  pickup?: Maybe<CustomersGetHandlingDetailsResponseHandlingDetailsPickup>;
  specialRequirements?: Maybe<Array<Maybe<CustomersGetHandlingDetailsResponseHandlingDetailsSpecialRequirement>>>;
};

export type CustomersGetHandlingDetailsResponseHandlingDetailsDelivery = {
  __typename?: 'CustomersGetHandlingDetailsResponseHandlingDetailsDelivery';
  address?: Maybe<CustomersGoogleTypePostalAddress>;
  addressId?: Maybe<Scalars['CustomersBigInt']>;
  addressType?: Maybe<CustomersAddressType>;
  customerManualLatLng?: Maybe<CustomersGoogleTypeLatLng>;
  doorCode?: Maybe<Scalars['String']>;
  dropoffLocation?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  latLng?: Maybe<CustomersGoogleTypeLatLng>;
  notes?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersGetHandlingDetailsResponseHandlingDetailsPickup = {
  __typename?: 'CustomersGetHandlingDetailsResponseHandlingDetailsPickup';
  address?: Maybe<CustomersGoogleTypePostalAddress>;
  addressId?: Maybe<Scalars['CustomersBigInt']>;
  instructions?: Maybe<Scalars['String']>;
  latLng?: Maybe<CustomersGoogleTypeLatLng>;
};

export enum CustomersGetHandlingDetailsResponseHandlingDetailsSpecialRequirement {
  AlcoholCompliant = 'ALCOHOL_COMPLIANT',
  AllowUnattendedTransition = 'ALLOW_UNATTENDED_TRANSITION',
  CertifiedDelivery = 'CERTIFIED_DELIVERY',
  DisableShopperAdds = 'DISABLE_SHOPPER_ADDS',
  EbtRetailerLocationConstrained = 'EBT_RETAILER_LOCATION_CONSTRAINED',
  IncludesEbtPayment = 'INCLUDES_EBT_PAYMENT',
  OrderAhead = 'ORDER_AHEAD',
  OrderCreatedWithBigBulky = 'ORDER_CREATED_WITH_BIG_BULKY',
  RequiresShopperBgcProcess_1 = 'REQUIRES_SHOPPER_BGC_PROCESS_1',
  RestrictedOtcCompliant = 'RESTRICTED_OTC_COMPLIANT',
  RxCompliant = 'RX_COMPLIANT',
  RxOnlyCompliant = 'RX_ONLY_COMPLIANT',
  SpecialRequirementUnspecified = 'SPECIAL_REQUIREMENT_UNSPECIFIED',
  UnattendedDelivery = 'UNATTENDED_DELIVERY'
}

export type CustomersGetOrderAmountsSummaryV0RequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetOrderAmountsSummaryV0Response = {
  __typename?: 'CustomersGetOrderAmountsSummaryV0Response';
  orderAmountsSummary?: Maybe<CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary>;
  orderAmountsSummaryByOrderReference?: Maybe<CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary>;
};

export type CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary = {
  __typename?: 'CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary';
  orderAmounts?: Maybe<Array<Maybe<CustomersAmounts>>>;
};

export type CustomersGetOrderRequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetOrderResponse = {
  __typename?: 'CustomersGetOrderResponse';
  order?: Maybe<CustomersOrder>;
  ordersByOrderReference?: Maybe<CustomersOrder>;
};

export type CustomersGetPaymentMethodSummaryRequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetPaymentMethodSummaryResponse = {
  __typename?: 'CustomersGetPaymentMethodSummaryResponse';
  paymentMethodSummariesByOrderReference?: Maybe<CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary>;
  paymentMethodSummary?: Maybe<CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary>;
};

export type CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary = {
  __typename?: 'CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary';
  paymentMethod?: Maybe<Array<Maybe<CustomersGetPaymentMethodSummaryResponsePaymentMethodSummaryPaymentMethod>>>;
};

export type CustomersGetPaymentMethodSummaryResponsePaymentMethodSummaryPaymentMethod = {
  __typename?: 'CustomersGetPaymentMethodSummaryResponsePaymentMethodSummaryPaymentMethod';
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CustomersGoogleTypeDate = {
  __typename?: 'CustomersGoogleTypeDate';
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type CustomersGoogleTypeLatLng = {
  __typename?: 'CustomersGoogleTypeLatLng';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CustomersGoogleTypeMoney = {
  __typename?: 'CustomersGoogleTypeMoney';
  currencyCode?: Maybe<Scalars['String']>;
  nanos?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersGoogleTypePostalAddress = {
  __typename?: 'CustomersGoogleTypePostalAddress';
  addressLines?: Maybe<Array<Maybe<Scalars['String']>>>;
  administrativeArea?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  regionCode?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  sortingCode?: Maybe<Scalars['String']>;
  sublocality?: Maybe<Scalars['String']>;
};

export enum CustomersInstacartCustomersSharedV1FulfillmentTypeWrapperFulfillmentType {
  AffordableDelivery = 'AFFORDABLE_DELIVERY',
  Asap = 'ASAP',
  Delivery = 'DELIVERY',
  DeliveryOnly = 'DELIVERY_ONLY',
  DirectToConsumer = 'DIRECT_TO_CONSUMER',
  Eta = 'ETA',
  FiveHour = 'FIVE_HOUR',
  FulfillmentTypeUnspecified = 'FULFILLMENT_TYPE_UNSPECIFIED',
  HyperFast = 'HYPER_FAST',
  LimitedAvailability = 'LIMITED_AVAILABILITY',
  OneHour = 'ONE_HOUR',
  Pickup = 'PICKUP',
  PickupEta = 'PICKUP_ETA',
  PriorityEta = 'PRIORITY_ETA',
  RetailerInstore = 'RETAILER_INSTORE',
  RetailerManaged = 'RETAILER_MANAGED',
  ScanAndPay = 'SCAN_AND_PAY',
  Scheduled = 'SCHEDULED',
  ThreeHour = 'THREE_HOUR',
  TwoHour = 'TWO_HOUR',
  Ultrafast = 'ULTRAFAST',
  VirtualExpress = 'VIRTUAL_EXPRESS',
  VirtualRetailerMembership = 'VIRTUAL_RETAILER_MEMBERSHIP'
}

export type CustomersInstacartCustomersSharedV1PageInfo = {
  __typename?: 'CustomersInstacartCustomersSharedV1PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type CustomersInstacartCustomersSharedV1PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type CustomersInstacartCustomersSharedV1RequestContextInput = {
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export enum CustomersInstacartCustomersSharedV1ServiceType {
  Delivery = 'DELIVERY',
  Instore = 'INSTORE',
  Pickup = 'PICKUP',
  ScanAndPay = 'SCAN_AND_PAY',
  ServiceTypeUnspecified = 'SERVICE_TYPE_UNSPECIFIED',
  Virtual = 'VIRTUAL'
}

export type CustomersInstacartInternalRequestContextInput = {
  instacartInternalAppId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersListOrdersRequestFilterSetDateRangeInput = {
  from?: InputMaybe<Scalars['CustomersDateTime']>;
  to?: InputMaybe<Scalars['CustomersDateTime']>;
};

export type CustomersListOrdersRequestFilterSetInput = {
  addressId?: InputMaybe<Scalars['CustomersBigInt']>;
  creationDateRange?: InputMaybe<CustomersListOrdersRequestFilterSetDateRangeInput>;
  fulfillmentDateRange?: InputMaybe<CustomersListOrdersRequestFilterSetDateRangeInput>;
  orderLifecycleDateRange?: InputMaybe<CustomersListOrdersRequestFilterSetDateRangeInput>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<CustomersListOrdersRequestFilterSetOrderStatusCriteria>>>;
  query?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  serviceType?: InputMaybe<CustomersInstacartCustomersSharedV1ServiceType>;
};

export enum CustomersListOrdersRequestFilterSetOrderStatusCriteria {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  OrderStatusCriteriaUnspecified = 'ORDER_STATUS_CRITERIA_UNSPECIFIED'
}

export type CustomersListOrdersRequestInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  filters?: InputMaybe<CustomersListOrdersRequestFilterSetInput>;
  instacartInternalRequestContext?: InputMaybe<CustomersInstacartInternalRequestContextInput>;
  pagination?: InputMaybe<CustomersInstacartCustomersSharedV1PaginationInput>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
  userId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersListOrdersResponse = {
  __typename?: 'CustomersListOrdersResponse';
  orderSummaries?: Maybe<Array<Maybe<CustomersListOrdersResponseOrderSummary>>>;
  orders?: Maybe<Array<Maybe<CustomersOrder>>>;
  pageInfo?: Maybe<CustomersInstacartCustomersSharedV1PageInfo>;
};

export type CustomersListOrdersResponseOrderSummary = {
  __typename?: 'CustomersListOrdersResponseOrderSummary';
  creationDate?: Maybe<Scalars['CustomersDateTime']>;
  externalId?: Maybe<Scalars['String']>;
  fulfillmentDate?: Maybe<Scalars['CustomersDateTime']>;
  fulfillmentType?: Maybe<CustomersInstacartCustomersSharedV1FulfillmentTypeWrapperFulfillmentType>;
  id?: Maybe<Scalars['CustomersBigInt']>;
  legacyOrderId?: Maybe<Scalars['CustomersBigInt']>;
  orderReference?: Maybe<Scalars['String']>;
  retailerLocationId?: Maybe<Scalars['CustomersBigInt']>;
  totalAmount?: Maybe<CustomersGoogleTypeMoney>;
  userId?: Maybe<Scalars['CustomersBigInt']>;
  workflowState?: Maybe<CustomersWorkflowState>;
};

export type CustomersOrder = {
  __typename?: 'CustomersOrder';
  businessId?: Maybe<Scalars['CustomersBigInt']>;
  canceledAt?: Maybe<Scalars['CustomersDateTime']>;
  checkoutRetailerLocationId?: Maybe<Scalars['CustomersBigInt']>;
  clientConfigurationId?: Maybe<Scalars['CustomersBigInt']>;
  closestRetailerLocationId?: Maybe<Scalars['CustomersBigInt']>;
  communicationLocale?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['CustomersDateTime']>;
  countryId?: Maybe<Scalars['CustomersBigInt']>;
  creationDate?: Maybe<Scalars['CustomersDateTime']>;
  customerOrderNumber?: Maybe<Scalars['CustomersBigInt']>;
  deliveredAt?: Maybe<Scalars['CustomersDateTime']>;
  deliveryStartedAt?: Maybe<Scalars['CustomersDateTime']>;
  expressSubscriptionId?: Maybe<Scalars['CustomersBigInt']>;
  finalizedAt?: Maybe<Scalars['CustomersDateTime']>;
  fulfillmentType?: Maybe<CustomersInstacartCustomersSharedV1FulfillmentTypeWrapperFulfillmentType>;
  fulfillmentWindow?: Maybe<CustomersOrderOrderFulfillmentWindow>;
  id?: Maybe<Scalars['CustomersBigInt']>;
  legacyOrderId?: Maybe<Scalars['CustomersBigInt']>;
  loyaltyNumber?: Maybe<Scalars['String']>;
  orderAhead?: Maybe<Scalars['Boolean']>;
  orderReference?: Maybe<Scalars['String']>;
  plannedDeliveredAt?: Maybe<Scalars['CustomersDateTime']>;
  plannedDeliveryStartedAt?: Maybe<Scalars['CustomersDateTime']>;
  retailerExternalOrderId?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['CustomersBigInt']>;
  retailerOriginatedData?: Maybe<CustomersOrderRetailerOriginatedData>;
  retailerReceiptBarcodeValue?: Maybe<Scalars['String']>;
  retailerReferenceId?: Maybe<Scalars['String']>;
  serviceOptionId?: Maybe<Scalars['CustomersBigInt']>;
  serviceType?: Maybe<CustomersInstacartCustomersSharedV1ServiceType>;
  shoppedRetailerLocationId?: Maybe<Scalars['CustomersBigInt']>;
  shopperOriginatedData?: Maybe<CustomersOrderShopperOriginatedData>;
  storeConfigurationId?: Maybe<Scalars['CustomersBigInt']>;
  subWorkflowState?: Maybe<CustomersSubWorkflowState>;
  userBirthday?: Maybe<CustomersGoogleTypeDate>;
  userId?: Maybe<Scalars['CustomersBigInt']>;
  workflowState?: Maybe<CustomersWorkflowState>;
  zoneId?: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersOrderOrderFulfillmentWindow = {
  __typename?: 'CustomersOrderOrderFulfillmentWindow';
  windowEnd?: Maybe<Scalars['CustomersDateTime']>;
  windowStart?: Maybe<Scalars['CustomersDateTime']>;
};

export type CustomersOrderRetailerOriginatedData = {
  __typename?: 'CustomersOrderRetailerOriginatedData';
  bagLabel?: Maybe<Scalars['String']>;
  barcodeIdentifier?: Maybe<Scalars['String']>;
};

export type CustomersOrderShopperOriginatedData = {
  __typename?: 'CustomersOrderShopperOriginatedData';
  customerBagCount?: Maybe<Scalars['Int']>;
};

export enum CustomersOrdersSortByDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  DirectionUnspecified = 'DIRECTION_UNSPECIFIED'
}

export type CustomersOrdersSortByInput = {
  direction?: InputMaybe<CustomersOrdersSortByDirection>;
  name?: InputMaybe<CustomersOrdersSortByOrdersAttributes>;
};

export enum CustomersOrdersSortByOrdersAttributes {
  OrdersAttributesUnspecified = 'ORDERS_ATTRIBUTES_UNSPECIFIED',
  OrderDeliveryId = 'ORDER_DELIVERY_ID',
  WindowEndsAt = 'WINDOW_ENDS_AT'
}

export type CustomersQueryOrderReferenceRequestInput = {
  orderDeliveryId?: InputMaybe<Scalars['CustomersBigInt']>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersQueryOrderReferenceResponse = {
  __typename?: 'CustomersQueryOrderReferenceResponse';
  orderReference?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersQueryOrdersRequestInput = {
  ordersSortBy?: InputMaybe<CustomersOrdersSortByInput>;
  pagination?: InputMaybe<CustomersInstacartCustomersSharedV1PaginationInput>;
  scenarioActivePickupOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActivePickupOrdersAtRetailerLocationInput>;
  scenarioActiveScanAndPayOrders?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveScanAndPayOrdersInput>;
  scenarioActiveShopperFulfillDeliveryOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveShopperFulfillDeliveryOrdersAtRetailerLocationInput>;
  scenarioActiveStoreManagerOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveStoreManagerOrdersAtRetailerLocationInput>;
  scenarioActiveUltrafastOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveUltrafastOrdersAtRetailerLocationInput>;
  scenarioCompletedScanAndPayOrders?: InputMaybe<CustomersQueryOrdersRequestScenarioCompletedScanAndPayOrdersInput>;
  scenarioEnterpriseRppOrdersByCreationDate?: InputMaybe<CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByCreationDateInput>;
  scenarioEnterpriseRppOrdersByFulfillmentDate?: InputMaybe<CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByFulfillmentDateInput>;
  scenarioEnterpriseRppOrdersByOrderIdentifier?: InputMaybe<CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByOrderIdentifierInput>;
  scenarioOrdersById?: InputMaybe<CustomersQueryOrdersRequestScenarioOrdersByIdInput>;
};

export type CustomersQueryOrdersRequestScenarioActivePickupOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
};

export type CustomersQueryOrdersRequestScenarioActiveScanAndPayOrdersInput = {
  createdAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersQueryOrdersRequestScenarioActiveShopperFulfillDeliveryOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationZoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
};

export type CustomersQueryOrdersRequestScenarioActiveStoreManagerOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationZoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
};

export type CustomersQueryOrdersRequestScenarioActiveUltrafastOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationZoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
};

export type CustomersQueryOrdersRequestScenarioCompletedScanAndPayOrdersInput = {
  createdAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByCreationDateInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  creationDateRange?: InputMaybe<CustomersTimestampRangeInput>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<CustomersRppOrderStatusCriteria>>>;
  orderType?: InputMaybe<CustomersRppOrderType>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
};

export type CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByFulfillmentDateInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<CustomersRppOrderStatusCriteria>>>;
  orderType?: InputMaybe<CustomersRppOrderType>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
};

export type CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByOrderIdentifierInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  orderIdentifier?: InputMaybe<Scalars['String']>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersQueryOrdersRequestScenarioOrdersByIdInput = {
  orderDeliveryIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
};

export type CustomersQueryOrdersResponse = {
  __typename?: 'CustomersQueryOrdersResponse';
  orders?: Maybe<Array<Maybe<CustomersOrder>>>;
  pageInfo?: Maybe<CustomersInstacartCustomersSharedV1PageInfo>;
};

export enum CustomersRppOrderStatusCriteria {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Delivering = 'DELIVERING',
  New = 'NEW',
  Processing = 'PROCESSING',
  ReadyForPickup = 'READY_FOR_PICKUP',
  RppOrderStatusCriteriaUnspecified = 'RPP_ORDER_STATUS_CRITERIA_UNSPECIFIED',
  Shopping = 'SHOPPING'
}

export enum CustomersRppOrderType {
  Delivery = 'DELIVERY',
  LastMileDelivery = 'LAST_MILE_DELIVERY',
  Pickup = 'PICKUP',
  RppOrderTypeUnspecified = 'RPP_ORDER_TYPE_UNSPECIFIED'
}

export enum CustomersSubWorkflowState {
  PickingEarly = 'PICKING_EARLY',
  PickingFinal = 'PICKING_FINAL',
  PickingPause = 'PICKING_PAUSE',
  StagedConfirmedHandoffToPartnerForDelivery = 'STAGED_CONFIRMED_HANDOFF_TO_PARTNER_FOR_DELIVERY',
  SubWorkflowStateNone = 'SUB_WORKFLOW_STATE_NONE',
  SubWorkflowStateUnspecified = 'SUB_WORKFLOW_STATE_UNSPECIFIED'
}

export type CustomersTimestampRangeInput = {
  from?: InputMaybe<Scalars['CustomersDateTime']>;
  to?: InputMaybe<Scalars['CustomersDateTime']>;
};

export enum CustomersWorkflowState {
  Acknowledged = 'ACKNOWLEDGED',
  AcknowledgedForDelivery = 'ACKNOWLEDGED_FOR_DELIVERY',
  AcknowledgedForRunning = 'ACKNOWLEDGED_FOR_RUNNING',
  AtStore = 'AT_STORE',
  BagsCheck = 'BAGS_CHECK',
  BagsVerified = 'BAGS_VERIFIED',
  BrandNew = 'BRAND_NEW',
  Canceled = 'CANCELED',
  Checkout = 'CHECKOUT',
  CustomerSamples = 'CUSTOMER_SAMPLES',
  Delivered = 'DELIVERED',
  Delivering = 'DELIVERING',
  Notified = 'NOTIFIED',
  PendingIdentityVerification = 'PENDING_IDENTITY_VERIFICATION',
  PendingReschedule = 'PENDING_RESCHEDULE',
  Picking = 'PICKING',
  ReceiptSanityCheck = 'RECEIPT_SANITY_CHECK',
  Returning = 'RETURNING',
  RunnerAwaiting = 'RUNNER_AWAITING',
  RunnerNotFound = 'RUNNER_NOT_FOUND',
  RxCheckout = 'RX_CHECKOUT',
  RxPicking = 'RX_PICKING',
  Staged = 'STAGED',
  StagedForCbt = 'STAGED_FOR_CBT',
  StagedForDelivery = 'STAGED_FOR_DELIVERY',
  StagingForCbt = 'STAGING_FOR_CBT',
  VerifyingReplacements = 'VERIFYING_REPLACEMENTS',
  WaitingForDelivery = 'WAITING_FOR_DELIVERY',
  WorkflowStateUnspecified = 'WORKFLOW_STATE_UNSPECIFIED'
}

export type DataEntryItem = {
  __typename?: 'DataEntryItem';
  deiId?: Maybe<Scalars['String']>;
};

export type DataItem = {
  __typename?: 'DataItem';
  groupByName?: Maybe<Scalars['String']>;
  metricName?: Maybe<Scalars['String']>;
  value?: Maybe<InsightsPortalValue>;
};

export type DataQuery = {
  __typename?: 'DataQuery';
  resourceDataQuery?: Maybe<ResourceDataQuery>;
};

export type DataQueryInput = {
  resourceDataQuery?: InputMaybe<ResourceDataQueryInput>;
};

/** Attributes for a date range filter */
export type DateRange = {
  /** End date of date range */
  endDate: Scalars['ISO8601Date'];
  /** Start date of date range */
  startDate: Scalars['ISO8601Date'];
};

export type DateRangeInput = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type DayOfWeekInput = {
  /** list of days within the week */
  days: Array<Scalars['String']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of DayOfWeekVisibilityConditionCreate */
export type DayOfWeekVisibilityConditionCreatePayload = {
  __typename?: 'DayOfWeekVisibilityConditionCreatePayload';
  /** The newly created express_membership visibility condition */
  dayOfWeekVisibilityCondition: ContentManagementVisibilityConditionDayOfWeek;
};

/** Autogenerated return type of DayOfWeekVisibilityConditionUpdate */
export type DayOfWeekVisibilityConditionUpdatePayload = {
  __typename?: 'DayOfWeekVisibilityConditionUpdatePayload';
  /** The newly created shopped_at_retailer visibility condition */
  dayOfWeekVisibilityCondition: ContentManagementVisibilityConditionDayOfWeek;
};

export type DeleteApiSettingRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteApiSettingResponse = {
  __typename?: 'DeleteApiSettingResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteConnectRetailerCallbackConfigurationRequestInput = {
  callbackConfigurationId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteConnectRetailerCallbackConfigurationResponse = {
  __typename?: 'DeleteConnectRetailerCallbackConfigurationResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteContentPageRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteContentPageResponse = {
  __typename?: 'DeleteContentPageResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type DeleteContentPageVersionRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteContentPageVersionResponse = {
  __typename?: 'DeleteContentPageVersionResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type DeleteImageRequestInput = {
  imageId?: InputMaybe<Scalars['Int']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteImageResponse = {
  __typename?: 'DeleteImageResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type DeleteLaunchOnboardingWarehouseLocationRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLaunchOnboardingWarehouseLocationResponse = {
  __typename?: 'DeleteLaunchOnboardingWarehouseLocationResponse';
  launchOnboarding?: Maybe<LaunchOnboarding>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteLayoutVariantRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLayoutVariantResponse = {
  __typename?: 'DeleteLayoutVariantResponse';
  response?: Maybe<Scalars['Boolean']>;
};

export type DeleteLinkAutosuggestionAdminParametersInput = {
  linkId?: InputMaybe<Scalars['BigInt']>;
  linkName?: InputMaybe<Scalars['String']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLinkAutosuggestionAdminRequestInput = {
  parameters?: InputMaybe<DeleteLinkAutosuggestionAdminParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLinkAutosuggestionAdminResponse = {
  __typename?: 'DeleteLinkAutosuggestionAdminResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteNavigationRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteNavigationResponse = {
  __typename?: 'DeleteNavigationResponse';
  response?: Maybe<Scalars['Boolean']>;
};

export type DeletePlacementRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeletePlacementResponse = {
  __typename?: 'DeletePlacementResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type DeleteSlotCreativeRequestInput = {
  creativeId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteSlotCreativeResponse = {
  __typename?: 'DeleteSlotCreativeResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type DeleteSlotRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteSlotResponse = {
  __typename?: 'DeleteSlotResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export enum DetectionStatusState {
  DetectionStatusStateAdminAudit = 'DETECTION_STATUS_STATE_ADMIN_AUDIT',
  DetectionStatusStateApproved = 'DETECTION_STATUS_STATE_APPROVED',
  DetectionStatusStateRejected = 'DETECTION_STATUS_STATE_REJECTED',
  DetectionStatusStateRunningAutomatedTests = 'DETECTION_STATUS_STATE_RUNNING_AUTOMATED_TESTS',
  DetectionStatusStateUnknown = 'DETECTION_STATUS_STATE_UNKNOWN'
}

export type DetectionVersionAttributes = {
  __typename?: 'DetectionVersionAttributes';
  cadence?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdWhy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  fixDefectTag?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['BigInt']>;
  owner?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type DetectionVersionAttributesInput = {
  cadence?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  createdWhy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  fixDefectTag?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  owner?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type DetectionVersionState = {
  __typename?: 'DetectionVersionState';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  metadata?: Maybe<Scalars['String']>;
  state?: Maybe<DetectionStatusState>;
};

export type DetectionVersionStats = {
  __typename?: 'DetectionVersionStats';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  metadata?: Maybe<Scalars['String']>;
  stats?: Maybe<Scalars['String']>;
};

export enum DeviceTypeEnum {
  /** Phone plan */
  Phone = 'phone',
  /** Wi-Fi */
  Wifi = 'wifi'
}

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DisablePlacementContentRequestInput = {
  adminContext?: InputMaybe<PlacementAdminContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DisablePlacementContentResponse = {
  __typename?: 'DisablePlacementContentResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type DisableThemePageRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DisableThemePageResponse = {
  __typename?: 'DisableThemePageResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export type DiscountDiscountablesData = {
  __typename?: 'DiscountDiscountablesData';
  appliedAmount?: Maybe<GoogleTypeMoney>;
  discountableId?: Maybe<Scalars['BigInt']>;
  discountableType?: Maybe<DiscountDiscountablesDataDiscountableType>;
  id?: Maybe<Scalars['BigInt']>;
  offerId?: Maybe<Scalars['BigInt']>;
  orderId?: Maybe<Scalars['BigInt']>;
};

export enum DiscountDiscountablesDataDiscountableType {
  BasketLevelOffer = 'BASKET_LEVEL_OFFER',
  CustomerFeeOffer = 'CUSTOMER_FEE_OFFER',
  DiscountableTypeUnspecified = 'DISCOUNTABLE_TYPE_UNSPECIFIED',
  ItemLevelOffer = 'ITEM_LEVEL_OFFER'
}

export enum Discoverability {
  AdminOnly = 'ADMIN_ONLY',
  Browse = 'BROWSE',
  CouponCode = 'COUPON_CODE',
  Crm = 'CRM',
  DirectLink = 'DIRECT_LINK',
  DiscoverabilityUnknown = 'DISCOVERABILITY_UNKNOWN'
}

export type DismissBusinessIdentication = {
  __typename?: 'DismissBusinessIdentication';
  id?: Maybe<Scalars['BigInt']>;
  source?: Maybe<BusinessDerivedAttributesSourceType>;
};

export type DismissBusinessIdenticationInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<BusinessDerivedAttributesSourceType>;
};

export type DoNotNavigate = {
  __typename?: 'DoNotNavigate';
  recordClickAttempt?: Maybe<Scalars['Boolean']>;
};

export type DoNotNavigateInput = {
  recordClickAttempt?: InputMaybe<Scalars['Boolean']>;
};

export type DomainConfiguration = {
  __typename?: 'DomainConfiguration';
  ads?: Maybe<AdsSchema>;
  branding?: Maybe<BrandingSchema>;
  business?: Maybe<BusinessSchema>;
  catalog?: Maybe<CatalogSchema>;
  checkout?: Maybe<CheckoutSchema>;
  icplus?: Maybe<IcPlusSchema>;
  idsTheme?: Maybe<IdsThemeSchema>;
  inStore?: Maybe<InStoreSchema>;
  landing?: Maybe<LandingSchema>;
  loyalty?: Maybe<LoyaltySchema>;
  offers?: Maybe<OffersSchema>;
  orders?: Maybe<OrdersSchema>;
  payments?: Maybe<PaymentsSchema>;
  pricing?: Maybe<PricingSchema>;
  storefront?: Maybe<StorefrontSchema>;
  theme?: Maybe<ThemeSchema>;
  users?: Maybe<UsersSchema>;
};

export type DownloadCatalogSearchResultsRequestInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  costUnitType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  creationDate?: InputMaybe<DateRangeInput>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  isParWeighted?: InputMaybe<Scalars['Boolean']>;
  itemFilename?: InputMaybe<Scalars['String']>;
  itemsOnPromoDateRange?: InputMaybe<DateRangeInput>;
  itemsOnSaleDateRange?: InputMaybe<DateRangeInput>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  loyaltyPricing?: InputMaybe<Scalars['Boolean']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  primaryImageUrl?: InputMaybe<Scalars['Boolean']>;
  productAvailability?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productFilename?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  query?: InputMaybe<Scalars['String']>;
  requestItemData?: InputMaybe<Scalars['Boolean']>;
  requestProductData?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerReferenceCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  snapEbtEligible?: InputMaybe<Scalars['Boolean']>;
  storeBrandProduct?: InputMaybe<Scalars['Boolean']>;
};

export type DownloadCatalogSearchResultsResponse = {
  __typename?: 'DownloadCatalogSearchResultsResponse';
  filename?: Maybe<Scalars['String']>;
};

export type EbpAnalyticsRequestInput = {
  dateRange?: InputMaybe<InstacartAdsSharedV1DateRangeInput>;
  entity?: InputMaybe<Entity>;
  entityId?: InputMaybe<EntityIdInput>;
  metric?: InputMaybe<Metric>;
};

export type EbpAnalyticsResponse = {
  __typename?: 'EbpAnalyticsResponse';
  analytics?: Maybe<Analytics>;
};

export type EnterpriseAcceptedPartnerAgreement = {
  __typename?: 'EnterpriseAcceptedPartnerAgreement';
  createdAt: Scalars['Time'];
  partnerId: Scalars['ID'];
};

export type EnterpriseAccount = {
  __typename?: 'EnterpriseAccount';
  /** The terms accepted by the account */
  acceptedPartnerAgreements?: Maybe<Array<EnterpriseAcceptedPartnerAgreement>>;
  /** Whether or not the user is fully set up */
  accountStateValid?: Maybe<Scalars['Boolean']>;
  /** Whether account must use SSO/auth service for authentication */
  authServiceRequired: Scalars['Boolean'];
  /** The user data from the auth service */
  authUserData?: Maybe<AuthUserData>;
  /** The associated authentication */
  authentication?: Maybe<AuthenticationInterface>;
  /** Whether account can be activated/reactivated by the current user */
  canSetDisabled: Scalars['Boolean'];
  /** The external unique identifier for the enterprise account */
  canonicalId: Scalars['ID'];
  /** When the account was created */
  createdAt: Scalars['Time'];
  /** Whether account is active */
  disabled: Scalars['Boolean'];
  /** The email associated with the account */
  email?: Maybe<Scalars['String']>;
  /** The feature experiments associated with the account */
  featureExperiments: Array<FeatureExperiment>;
  /** The feature toggles associated with the account */
  featureToggles: Array<FeatureToggle>;
  /** Given name of user */
  givenName?: Maybe<Scalars['String']>;
  /** The internal unique identifier for the enterprise account */
  id: Scalars['ID'];
  /** The associated internal access configuration for the account */
  internalAccessConfiguration: EnterpriseInternalAccessConfiguration;
  /** Whether account is editable by the current user */
  isEditable: Scalars['Boolean'];
  /** Whether account is an internal account */
  isInternal: Scalars['Boolean'];
  /** When MFA was disabled for the account */
  mfaDeactivatedAt?: Maybe<Scalars['Time']>;
  /** Whether account has MFA enabled */
  mfaEnabled: Scalars['Boolean'];
  /** When MFA was enabled for the account */
  mfaEnabledAt?: Maybe<Scalars['Time']>;
  /** The permissions associated with this account */
  permissions: Array<EnterprisePermission>;
  /** Phone number for the user */
  phone?: Maybe<Scalars['String']>;
  /** The policies associated with the account */
  policies: AccessControlPolicies;
  /** The roles associated with this account */
  roles: Array<EnterpriseRole>;
  /** Surname of user */
  surname?: Maybe<Scalars['String']>;
  /** The title of the user */
  title?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EnterpriseAccountAcceptPartnerAgreement */
export type EnterpriseAccountAcceptPartnerAgreementPayload = {
  __typename?: 'EnterpriseAccountAcceptPartnerAgreementPayload';
  acceptedPartnerAgreement: EnterpriseAcceptedPartnerAgreement;
};

/** Autogenerated return type of EnterpriseAccountAccountSetup */
export type EnterpriseAccountAccountSetupPayload = {
  __typename?: 'EnterpriseAccountAccountSetupPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

/** Autogenerated return type of EnterpriseAccountAuthenticateEmailPassword */
export type EnterpriseAccountAuthenticateEmailPasswordPayload = {
  __typename?: 'EnterpriseAccountAuthenticateEmailPasswordPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

/** Autogenerated return type of EnterpriseAccountAuthenticatePreflight */
export type EnterpriseAccountAuthenticatePreflightPayload = {
  __typename?: 'EnterpriseAccountAuthenticatePreflightPayload';
  canRequestAccount: Scalars['Boolean'];
  useAuthService: Scalars['Boolean'];
};

/** The connection type for EnterpriseAccount. */
export type EnterpriseAccountConnection = {
  __typename?: 'EnterpriseAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EnterpriseAccountEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EnterpriseAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of EnterpriseAccountCreateNoPartnerAccess */
export type EnterpriseAccountCreateNoPartnerAccessPayload = {
  __typename?: 'EnterpriseAccountCreateNoPartnerAccessPayload';
  /** The newly created account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountCreate */
export type EnterpriseAccountCreatePayload = {
  __typename?: 'EnterpriseAccountCreatePayload';
  /** The newly created account object */
  enterpriseAccount: EnterpriseAccount;
};

/** An edge in a connection. */
export type EnterpriseAccountEdge = {
  __typename?: 'EnterpriseAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EnterpriseAccount>;
};

/** Autogenerated return type of EnterpriseAccountMfaDeactivate */
export type EnterpriseAccountMfaDeactivatePayload = {
  __typename?: 'EnterpriseAccountMfaDeactivatePayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountMfaEnable */
export type EnterpriseAccountMfaEnablePayload = {
  __typename?: 'EnterpriseAccountMfaEnablePayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountMfaReset */
export type EnterpriseAccountMfaResetPayload = {
  __typename?: 'EnterpriseAccountMfaResetPayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountPartnerOnboardingRequestAccountSetupEmail */
export type EnterpriseAccountPartnerOnboardingRequestAccountSetupEmailPayload = {
  __typename?: 'EnterpriseAccountPartnerOnboardingRequestAccountSetupEmailPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountRequestAccountSetupEmail */
export type EnterpriseAccountRequestAccountSetupEmailPayload = {
  __typename?: 'EnterpriseAccountRequestAccountSetupEmailPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountRequestEmailVerification */
export type EnterpriseAccountRequestEmailVerificationPayload = {
  __typename?: 'EnterpriseAccountRequestEmailVerificationPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountRequestPasswordReset */
export type EnterpriseAccountRequestPasswordResetPayload = {
  __typename?: 'EnterpriseAccountRequestPasswordResetPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountResetPassword */
export type EnterpriseAccountResetPasswordPayload = {
  __typename?: 'EnterpriseAccountResetPasswordPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

/** Autogenerated return type of EnterpriseAccountSetDisabled */
export type EnterpriseAccountSetDisabledPayload = {
  __typename?: 'EnterpriseAccountSetDisabledPayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountSignUpEmailPassword */
export type EnterpriseAccountSignUpEmailPasswordPayload = {
  __typename?: 'EnterpriseAccountSignUpEmailPasswordPayload';
  /** The newly created Account */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountUpdatePassword */
export type EnterpriseAccountUpdatePasswordPayload = {
  __typename?: 'EnterpriseAccountUpdatePasswordPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountUpdate */
export type EnterpriseAccountUpdatePayload = {
  __typename?: 'EnterpriseAccountUpdatePayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountUpdateRoles */
export type EnterpriseAccountUpdateRolesPayload = {
  __typename?: 'EnterpriseAccountUpdateRolesPayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountVerifyAccountSetupToken */
export type EnterpriseAccountVerifyAccountSetupTokenPayload = {
  __typename?: 'EnterpriseAccountVerifyAccountSetupTokenPayload';
  /** Email of the verified account */
  email: Scalars['String'];
  /** List of partners with partner id and name */
  partners: Array<Partner>;
};

/** Autogenerated return type of EnterpriseAccountVerifyEmail */
export type EnterpriseAccountVerifyEmailPayload = {
  __typename?: 'EnterpriseAccountVerifyEmailPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

export type EnterpriseAuthenticationEmailPassword = AuthenticationInterface & {
  __typename?: 'EnterpriseAuthenticationEmailPassword';
  /** Unique authentication identifier */
  authenticationIdentifier: Scalars['ID'];
  /** Whether or not the email has been verified */
  emailVerified: Scalars['Boolean'];
  /** Whether or not the password has been set */
  passwordSet: Scalars['Boolean'];
};

export type EnterpriseInternalAccessConfiguration = {
  __typename?: 'EnterpriseInternalAccessConfiguration';
  /** The associated assigned partners */
  assignedPartners: Array<Partner>;
  /** The associated assigned retailer locations */
  assignedRetailerLocations: Array<RetailerLocation>;
  /** The associated assigned retailers */
  assignedRetailers: Array<Retailer>;
  /** Whether access is granted for every partner, retailer and location */
  hasAllPartnersFullAccess: Scalars['Boolean'];
  /** Whether access is granted for every retailer and location for partners assigned */
  hasFullPartnerAccess: Scalars['Boolean'];
  /** Whether access is granted for every location for retailers assigned */
  hasFullRetailerAccess: Scalars['Boolean'];
  /** Whether access is not granted to any partner, retailer or location */
  hasNothingAccessible: Scalars['Boolean'];
};

export type EnterprisePermission = {
  __typename?: 'EnterprisePermission';
  /** A description of the permission */
  description?: Maybe<Scalars['String']>;
  /** Indicates if the Permission can be used to assign to a Retailer role */
  isRetailerRoleAssignable: Scalars['Boolean'];
  /** A unique identifier for the permission */
  slug: Scalars['ID'];
};

export type EnterpriseRole = {
  __typename?: 'EnterpriseRole';
  /** The number of accounts currently attached to the role */
  attachedAccounts?: Maybe<Scalars['Int']>;
  /** A description of the role */
  description?: Maybe<Scalars['String']>;
  /** The role ID */
  id: Scalars['ID'];
  /** Whether this role can only be assigned to internal IC employees */
  internalOnly: Scalars['Boolean'];
  /** The name of the role */
  name: Scalars['String'];
  partnerId?: Maybe<Scalars['ID']>;
  /** The permissions associated with this role */
  permissions: Array<EnterprisePermission>;
  retailerId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the role */
  slug: Scalars['ID'];
  updatedAt: Scalars['Time'];
};

export type EnterpriseWelcomeModal = {
  __typename?: 'EnterpriseWelcomeModal';
  /** slug identifying type of welcome modal to be shown. */
  slug: Scalars['String'];
};

export enum Entity {
  Product = 'PRODUCT',
  SellSheet = 'SELL_SHEET'
}

export type EntityId = {
  __typename?: 'EntityId';
  cashflowAuditItem?: Maybe<CashflowAuditItem>;
  dataEntryItem?: Maybe<DataEntryItem>;
  itemId?: Maybe<ItemId>;
  localeProduct?: Maybe<LocaleProduct>;
  localeRetailerProduct?: Maybe<LocaleRetailerProduct>;
  outsourceDataEntryItem?: Maybe<OutsourceDataEntryItem>;
  productId?: Maybe<ProductId>;
  recipeEntryItem?: Maybe<RecipeEntryItem>;
  retailerProductId?: Maybe<RetailerProductId>;
  upc?: Maybe<Upc>;
};

export type EntityIdInput = {
  int64Id?: InputMaybe<Scalars['BigInt']>;
  itemId?: InputMaybe<ItemIdInput>;
  productId?: InputMaybe<ProductIdInput>;
  retailerProductId?: InputMaybe<RetailerProductIdInput>;
  stringId?: InputMaybe<Scalars['String']>;
  upc?: InputMaybe<UpcInput>;
};

export enum EntityStatus {
  EntityStatusCancelled = 'ENTITY_STATUS_CANCELLED',
  EntityStatusCompleted = 'ENTITY_STATUS_COMPLETED',
  EntityStatusDeduplicated = 'ENTITY_STATUS_DEDUPLICATED',
  EntityStatusEntityNotFound = 'ENTITY_STATUS_ENTITY_NOT_FOUND',
  EntityStatusFailed = 'ENTITY_STATUS_FAILED',
  EntityStatusFailedUncaught = 'ENTITY_STATUS_FAILED_UNCAUGHT',
  EntityStatusInProgress = 'ENTITY_STATUS_IN_PROGRESS',
  EntityStatusNonNormalizedUpc = 'ENTITY_STATUS_NON_NORMALIZED_UPC',
  EntityStatusStarted = 'ENTITY_STATUS_STARTED',
  EntityStatusSubmittedToFixService = 'ENTITY_STATUS_SUBMITTED_TO_FIX_SERVICE',
  EntityStatusUnknown = 'ENTITY_STATUS_UNKNOWN',
  EntityStatusUnknownError = 'ENTITY_STATUS_UNKNOWN_ERROR',
  EntityStatusUpcCollision = 'ENTITY_STATUS_UPC_COLLISION',
  EntityStatusWarning = 'ENTITY_STATUS_WARNING',
  EntityStatusWontDo = 'ENTITY_STATUS_WONT_DO'
}

export enum EntityType {
  EntityTypeItemIds = 'ENTITY_TYPE_ITEM_IDS',
  EntityTypeProductIds = 'ENTITY_TYPE_PRODUCT_IDS',
  EntityTypeRetailerProductIds = 'ENTITY_TYPE_RETAILER_PRODUCT_IDS',
  EntityTypeUnknown = 'ENTITY_TYPE_UNKNOWN',
  EntityTypeUpcs = 'ENTITY_TYPE_UPCS'
}

export enum ErrorReason {
  DraftErrorConflict = 'DRAFT_ERROR_CONFLICT',
  DraftErrorNotFound = 'DRAFT_ERROR_NOT_FOUND',
  DraftErrorUnexpectedState = 'DRAFT_ERROR_UNEXPECTED_STATE',
  ErrorEnumUnknown = 'ERROR_ENUM_UNKNOWN',
  ErrorReasonBadRequest = 'ERROR_REASON_BAD_REQUEST',
  ErrorReasonModificationNotPermitted = 'ERROR_REASON_MODIFICATION_NOT_PERMITTED',
  ErrorReasonUnknown = 'ERROR_REASON_UNKNOWN'
}

export enum ExperimentMethod {
  Assign = 'ASSIGN',
  ExperimentMethodUnspecified = 'EXPERIMENT_METHOD_UNSPECIFIED',
  Lookup = 'LOOKUP',
  Preview = 'PREVIEW'
}

export type ExperimentVariantInput = {
  /** The method of the experiment */
  experimentMethod: PlacementExperimentMethodEnum;
  /** The name of the experiment */
  experimentName: Scalars['String'];
  /** The name of the experiment variant */
  experimentVariant: Scalars['String'];
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of ExperimentVariantVisibilityConditionCreate */
export type ExperimentVariantVisibilityConditionCreatePayload = {
  __typename?: 'ExperimentVariantVisibilityConditionCreatePayload';
  /** The newly created ExperimentVariant visibility condition */
  experimentVariantVisibilityCondition: ContentManagementVisibilityConditionExperimentVariant;
};

/** Autogenerated return type of ExperimentVariantVisibilityConditionUpdate */
export type ExperimentVariantVisibilityConditionUpdatePayload = {
  __typename?: 'ExperimentVariantVisibilityConditionUpdatePayload';
  /** The newly updated ExperimentVariant visibility condition */
  experimentVariantVisibilityCondition: ContentManagementVisibilityConditionExperimentVariant;
};

export type ExpressMembershipInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** Whether the user should be an express member */
  membershipStatus: Scalars['Boolean'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ExpressMembershipVisibilityConditionCreate */
export type ExpressMembershipVisibilityConditionCreatePayload = {
  __typename?: 'ExpressMembershipVisibilityConditionCreatePayload';
  /** The newly created express_membership visibility condition */
  expressMembershipVisibilityCondition: ContentManagementVisibilityConditionExpressMembership;
};

/** Autogenerated return type of ExpressMembershipVisibilityConditionUpdate */
export type ExpressMembershipVisibilityConditionUpdatePayload = {
  __typename?: 'ExpressMembershipVisibilityConditionUpdatePayload';
  /** The newly created express_membership visibility condition */
  expressMembershipVisibilityCondition: ContentManagementVisibilityConditionExpressMembership;
};

export type ExpressNonmemberLongTermHoldoutInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ExpressNonmemberLongTermHoldoutVisibilityConditionCreate */
export type ExpressNonmemberLongTermHoldoutVisibilityConditionCreatePayload = {
  __typename?: 'ExpressNonmemberLongTermHoldoutVisibilityConditionCreatePayload';
  /** The newly created ExpressNonmemberLongTermHoldout visibility condition */
  expressNonmemberLongTermHoldoutVisibilityCondition: ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout;
};

export type FailedUpdate = {
  __typename?: 'FailedUpdate';
  reason?: Maybe<Scalars['String']>;
  retailerLocationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type FeatureEntitlement = {
  __typename?: 'FeatureEntitlement';
  /** The unique referable identifier of this entitlement */
  id: Scalars['String'];
  /** The display name of this entitlement */
  name: Scalars['String'];
};

export type FeatureExperiment = {
  __typename?: 'FeatureExperiment';
  /** The name of this feature */
  name: Scalars['String'];
  /** The variant picked */
  variant: Scalars['String'];
};

export type FeatureToggle = {
  __typename?: 'FeatureToggle';
  /** The name of this feature toggle */
  name: Scalars['String'];
};

export type FeatureTogglesEnabledCheck = {
  __typename?: 'FeatureTogglesEnabledCheck';
  /** Whether the feature toggle is enabled */
  enabled: Scalars['Boolean'];
};

export enum FilterType {
  FilterTypeAdminOnly = 'FILTER_TYPE_ADMIN_ONLY',
  FilterTypeCollectionId = 'FILTER_TYPE_COLLECTION_ID',
  FilterTypeCollectionSlug = 'FILTER_TYPE_COLLECTION_SLUG',
  FilterTypeCreatedBy = 'FILTER_TYPE_CREATED_BY',
  FilterTypeMoment = 'FILTER_TYPE_MOMENT',
  FilterTypeMomentApproved = 'FILTER_TYPE_MOMENT_APPROVED',
  FilterTypeMomentRejected = 'FILTER_TYPE_MOMENT_REJECTED',
  FilterTypeMomentSubmitted = 'FILTER_TYPE_MOMENT_SUBMITTED',
  FilterTypeOccasion = 'FILTER_TYPE_OCCASION',
  FilterTypeOccasionApproved = 'FILTER_TYPE_OCCASION_APPROVED',
  FilterTypeOccasionRejected = 'FILTER_TYPE_OCCASION_REJECTED',
  FilterTypeOccasionSubmitted = 'FILTER_TYPE_OCCASION_SUBMITTED',
  FilterTypePartnershipOfferId = 'FILTER_TYPE_PARTNERSHIP_OFFER_ID',
  FilterTypeTag = 'FILTER_TYPE_TAG',
  FilterTypeUnknown = 'FILTER_TYPE_UNKNOWN'
}

export type FlattenedTaxonomy = {
  __typename?: 'FlattenedTaxonomy';
  createdAt?: Maybe<Scalars['DateTime']>;
  productCategoryId?: Maybe<Scalars['BigInt']>;
  productCategoryPath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlattenedTaxonomyFilterInput = {
  updatedSince?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of FlyoutPlacementCreate */
export type FlyoutPlacementCreatePayload = {
  __typename?: 'FlyoutPlacementCreatePayload';
  /** The newly created flyout placement */
  flyoutPlacement: ContentManagementFlyoutPlacement;
};

export type FlyoutPlacementInput = {
  /** The style and text of the subheading */
  descriptionText: ConfigurableTextInput;
  /** The style and text of the secondary text */
  disclaimerText?: InputMaybe<ConfigurableTextInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Information of Experiment associated with the placement */
  experiment?: InputMaybe<PlacementExperimentInput>;
  /** The images on the banner */
  images: GenericImagesInput;
  /** The retailers that this placement should be displayed on */
  includedRetailerIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The layout the placement should appear on */
  layout?: InputMaybe<Scalars['String']>;
  /** The style and text of the button within the placement */
  linkButton: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  linkDestination?: InputMaybe<PlacementDestinationInput>;
  /** Locale */
  locale: BannerLocaleEnum;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The style and text of the button within the placement */
  primaryButton: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  primaryDestination?: InputMaybe<PlacementDestinationInput>;
  /** The priority rank of the placement */
  rank?: InputMaybe<Scalars['Int']>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The style and text of the button within the placement */
  secondaryButton: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  secondaryDestination?: InputMaybe<PlacementDestinationInput>;
  /** The layout the placement should appear on */
  stringLayout?: InputMaybe<Scalars['String']>;
  /** The style and text of the heading */
  titleText: ConfigurableTextInput;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** The variant of the banner */
  variant?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of FlyoutPlacementUpdate */
export type FlyoutPlacementUpdatePayload = {
  __typename?: 'FlyoutPlacementUpdatePayload';
  /** The updated flyout placement */
  flyoutPlacement: ContentManagementFlyoutPlacement;
};

export type FontFamily = {
  __typename?: 'FontFamily';
  files?: Maybe<FontFiles>;
  name?: Maybe<Scalars['String']>;
};

export type FontFile = {
  __typename?: 'FontFile';
  otf?: Maybe<Scalars['String']>;
  ttf?: Maybe<Scalars['String']>;
  woff?: Maybe<Scalars['String']>;
  woff2?: Maybe<Scalars['String']>;
};

export type FontFiles = {
  __typename?: 'FontFiles';
  bold?: Maybe<FontFile>;
  normal?: Maybe<FontFile>;
  semiBold?: Maybe<FontFile>;
};

export type Footer = {
  __typename?: 'Footer';
  variant?: Maybe<FooterVariant>;
};

export enum FooterVariant {
  MultiColumn = 'MULTI_COLUMN',
  NoFooter = 'NO_FOOTER',
  VariantUnknown = 'VARIANT_UNKNOWN'
}

export enum FulfillmentModelEnum {
  /** Carrotwork */
  Carrotwork = 'CARROTWORK',
  /** In store shoppers */
  Iss = 'ISS',
  /** Locker */
  Locker = 'LOCKER',
  /** Partner managed */
  Retailer = 'RETAILER'
}

export type GenerateBatchRequestInput = {
  adminRequestContext?: InputMaybe<AdminRequestContextInput>;
  batchType?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Scalars['BigInt']>;
  orders?: InputMaybe<Array<InputMaybe<OrderInput>>>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  shopperId?: InputMaybe<Scalars['BigInt']>;
  workflowState?: InputMaybe<Scalars['String']>;
};

export type GenerateBatchResponse = {
  __typename?: 'GenerateBatchResponse';
  id?: Maybe<Scalars['BigInt']>;
  workflowState?: Maybe<Scalars['String']>;
};

export type GenericImagesInput = {
  /** The image url of the placement to display for large viewports */
  desktop?: InputMaybe<Scalars['Url']>;
  /** The image url of the placement to display for small viewports */
  mobile: Scalars['Url'];
};

export type GetAcknowledgedOosIdsRequestInput = {
  retailerId?: InputMaybe<Scalars['String']>;
};

export type GetAcknowledgedOosIdsResponse = {
  __typename?: 'GetAcknowledgedOosIdsResponse';
  oosIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetAnomalousItemsListRequestInput = {
  currentOnly?: InputMaybe<Scalars['Boolean']>;
  filterEndAtDate?: InputMaybe<Scalars['DateTime']>;
  filterStartAtDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetAnomalousItemsListResponse = {
  __typename?: 'GetAnomalousItemsListResponse';
  dateCounts?: Maybe<Array<Maybe<AnomalousItemDateCount>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type GetAnomalousItemsReportRequestInput = {
  currentOnly?: InputMaybe<Scalars['Boolean']>;
  filterEndAtDate?: InputMaybe<Scalars['DateTime']>;
  filterStartAtDate?: InputMaybe<Scalars['DateTime']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  selectedDates?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type GetAnomalousItemsReportResponse = {
  __typename?: 'GetAnomalousItemsReportResponse';
  filename?: Maybe<Scalars['String']>;
};

export type GetApiSettingsRequestInput = {
  apiType?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetApiSettingsResponse = {
  __typename?: 'GetApiSettingsResponse';
  apiSettings?: Maybe<Array<Maybe<GetApiSettingsResponseApiSetting>>>;
};

export type GetApiSettingsResponseApiSetting = {
  __typename?: 'GetApiSettingsResponseApiSetting';
  apiType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cron?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  integrationType?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['BigInt']>;
  storeLocationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetApprovalRequestsRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetApprovalRequestsResponse = {
  __typename?: 'GetApprovalRequestsResponse';
  approvalRequests?: Maybe<Array<Maybe<LaunchOnboardingApprovalRequest>>>;
};

export type GetAuditBatchMetadataRequestInput = {
  client?: InputMaybe<Scalars['String']>;
};

export type GetAuditBatchMetadataResponse = {
  __typename?: 'GetAuditBatchMetadataResponse';
  secondaryStates?: Maybe<Array<Maybe<GetAuditBatchMetadataResponseSecondaryState>>>;
};

export type GetAuditBatchMetadataResponseSecondaryState = {
  __typename?: 'GetAuditBatchMetadataResponseSecondaryState';
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};

export type GetAuditBatchRequestInput = {
  batchUuid?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
  orderByComponent?: InputMaybe<OrderByComponentInput>;
  page?: InputMaybe<PaginationInput>;
  returnSampleTasks?: InputMaybe<Scalars['Boolean']>;
};

export type GetAuditBatchResponse = {
  __typename?: 'GetAuditBatchResponse';
  auditBatch?: Maybe<AuditBatch>;
};

export type GetAuditTaskRequestInput = {
  client?: InputMaybe<Scalars['String']>;
  taskUuid?: InputMaybe<Scalars['String']>;
};

export type GetAuditTaskResponse = {
  __typename?: 'GetAuditTaskResponse';
  auditTask?: Maybe<AuditTask>;
};

export type GetAuthenticationUrlRequestInput = {
  errorRedirectUrl?: InputMaybe<Scalars['String']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  successRedirectUrl?: InputMaybe<Scalars['String']>;
};

export type GetAuthenticationUrlResponse = {
  __typename?: 'GetAuthenticationUrlResponse';
  authenticationUrl?: Maybe<Scalars['String']>;
};

export type GetAvailabilityScoreOverrideRequestInput = {
  entityId?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetAvailabilityScoreOverrideResponse = {
  __typename?: 'GetAvailabilityScoreOverrideResponse';
  entities?: Maybe<Array<Maybe<GetAvailabilityScoreOverrideResponseOverrideEntity>>>;
};

export type GetAvailabilityScoreOverrideResponseOverrideEntity = {
  __typename?: 'GetAvailabilityScoreOverrideResponseOverrideEntity';
  entityId?: Maybe<EntityId>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type GetAvailableStoreLocationsRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetAvailableStoreLocationsResponse = {
  __typename?: 'GetAvailableStoreLocationsResponse';
  storeLocations?: Maybe<Array<Maybe<ApiIntegrationStoreLocation>>>;
};

export type GetCatalogFaqFileRequestInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type GetCatalogFaqFileResponse = {
  __typename?: 'GetCatalogFaqFileResponse';
  file?: Maybe<Scalars['String']>;
};

export type GetCollectionsRequestInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  admin?: InputMaybe<Scalars['Boolean']>;
  collectionTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludeDynamicRetailerCollections?: InputMaybe<Scalars['Boolean']>;
  excludedSourceTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includeAlcohol?: InputMaybe<Scalars['Boolean']>;
  includedSourceTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderBy?: InputMaybe<InstacartCustomersSharedV1OrderInput>;
  parentId?: InputMaybe<Scalars['String']>;
  replaceDepartmentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  showDepartmentTile?: InputMaybe<Scalars['Boolean']>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  useFullNamePath?: InputMaybe<Scalars['Boolean']>;
  useRetailerTypeMapping?: InputMaybe<Scalars['Boolean']>;
};

export type GetCollectionsResponse = {
  __typename?: 'GetCollectionsResponse';
  collections?: Maybe<Array<Maybe<Collection>>>;
};

export type GetConfirmedStoreHoursRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type GetConfirmedStoreHoursResponse = {
  __typename?: 'GetConfirmedStoreHoursResponse';
  confirmations?: Maybe<Array<Maybe<ConfirmedStoreHours>>>;
};

export type GetConnectRetailerCallbackConfigurationByIdRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type GetConnectRetailerCallbackConfigurationByIdResponse = {
  __typename?: 'GetConnectRetailerCallbackConfigurationByIdResponse';
  callbackConfiguration?: Maybe<ConnectRetailerCallbackConfiguration>;
};

export type GetConnectRetailerCallbackConfigurationsRequestInput = {
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
  environment?: InputMaybe<Scalars['String']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GetConnectRetailerCallbackConfigurationsResponse = {
  __typename?: 'GetConnectRetailerCallbackConfigurationsResponse';
  callbackConfigurations?: Maybe<Array<Maybe<ConnectRetailerCallbackConfiguration>>>;
};

export type GetConnectSettingsRequestInput = {
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
};

export type GetConnectSettingsResponse = {
  __typename?: 'GetConnectSettingsResponse';
  enableEtaServiceOptions?: Maybe<Scalars['Boolean']>;
  postCheckoutAppDomain?: Maybe<Scalars['String']>;
};

export type GetCustomersContextRequestInput = {
  parameters?: InputMaybe<GetCustomersContextRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetCustomersContextRequestParametersInput = {
  partnerId?: InputMaybe<Scalars['Int']>;
};

export type GetCustomersContextResponse = {
  __typename?: 'GetCustomersContextResponse';
  contexts?: Maybe<Array<Maybe<CustomersContext>>>;
};

export type GetDashboardOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetDashboardOrderResponse = {
  __typename?: 'GetDashboardOrderResponse';
  dashboardOrder?: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrder>;
};

export type GetDashboardOrdersRequestFilterSetInput = {
  orderIdentifier?: InputMaybe<Scalars['String']>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<GetDashboardOrdersRequestFilterSetOrderStatusCriteria>>>;
  orderType?: InputMaybe<GetDashboardOrdersRequestFilterSetOrderType>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  windowEndsAtRange?: InputMaybe<GetDashboardOrdersRequestFilterSetTimestampRangeInput>;
};

export enum GetDashboardOrdersRequestFilterSetOrderStatusCriteria {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Delivering = 'DELIVERING',
  New = 'NEW',
  OrderStatusCriteriaUnspecified = 'ORDER_STATUS_CRITERIA_UNSPECIFIED',
  Processing = 'PROCESSING',
  ReadyForPickup = 'READY_FOR_PICKUP',
  Shopping = 'SHOPPING'
}

export enum GetDashboardOrdersRequestFilterSetOrderType {
  Delivery = 'DELIVERY',
  LastMileDelivery = 'LAST_MILE_DELIVERY',
  OrderTypeUnspecified = 'ORDER_TYPE_UNSPECIFIED',
  Pickup = 'PICKUP'
}

export type GetDashboardOrdersRequestFilterSetTimestampRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetDashboardOrdersRequestInput = {
  filterSet?: InputMaybe<GetDashboardOrdersRequestFilterSetInput>;
  ordersSortBy?: InputMaybe<GetDashboardOrdersRequestOrdersSortByInput>;
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
};

export enum GetDashboardOrdersRequestOrdersSortByDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  DirectionUnspecified = 'DIRECTION_UNSPECIFIED'
}

export type GetDashboardOrdersRequestOrdersSortByInput = {
  direction?: InputMaybe<GetDashboardOrdersRequestOrdersSortByDirection>;
  name?: InputMaybe<GetDashboardOrdersRequestOrdersSortByOrdersAttributes>;
};

export enum GetDashboardOrdersRequestOrdersSortByOrdersAttributes {
  OrdersAttributesUnspecified = 'ORDERS_ATTRIBUTES_UNSPECIFIED',
  OrderDeliveryId = 'ORDER_DELIVERY_ID',
  WindowEndsAt = 'WINDOW_ENDS_AT'
}

export type GetDashboardOrdersResponse = {
  __typename?: 'GetDashboardOrdersResponse';
  dashboardOrderSummary?: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummary>>>;
  pageInfo?: Maybe<InstacartCustomersSharedV1PageInfo>;
};

export type GetDetectionRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type GetDetectionResponse = {
  __typename?: 'GetDetectionResponse';
  name?: Maybe<Scalars['String']>;
  versions?: Maybe<Array<Maybe<GetDetectionResponseDetectionVersion>>>;
};

export type GetDetectionResponseDetectionVersion = {
  __typename?: 'GetDetectionResponseDetectionVersion';
  attributes?: Maybe<DetectionVersionAttributes>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  states?: Maybe<Array<Maybe<DetectionVersionState>>>;
  stats?: Maybe<Array<Maybe<DetectionVersionStats>>>;
  tombstoned?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['BigInt']>;
};

export type GetFlattenedTaxonomiesParametersInput = {
  filter?: InputMaybe<FlattenedTaxonomyFilterInput>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetFlattenedTaxonomiesRequestInput = {
  parameters?: InputMaybe<GetFlattenedTaxonomiesParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetFlattenedTaxonomiesResponse = {
  __typename?: 'GetFlattenedTaxonomiesResponse';
  taxonomies?: Maybe<Array<Maybe<FlattenedTaxonomy>>>;
};

export type GetHolidaysStatusRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetHolidaysStatusResponse = {
  __typename?: 'GetHolidaysStatusResponse';
  holidays?: Maybe<Array<Maybe<HolidayStatus>>>;
};

export type GetImageUploadBatchDetailsRequestInput = {
  batchId?: InputMaybe<Scalars['BigInt']>;
  page?: InputMaybe<Scalars['BigInt']>;
  pageSize?: InputMaybe<Scalars['BigInt']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type GetImageUploadBatchDetailsResponse = {
  __typename?: 'GetImageUploadBatchDetailsResponse';
  imageUploadBatchDetails?: Maybe<Array<Maybe<ImageUploadBatchDetails>>>;
  imageUploadBatchMetadata?: Maybe<ImageUploadBatchMetadata>;
};

export type GetImageUploadBatchesRequestInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['BigInt']>;
  pageSize?: InputMaybe<Scalars['BigInt']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  timeRange?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type GetImageUploadBatchesResponse = {
  __typename?: 'GetImageUploadBatchesResponse';
  imageUploadBatch?: Maybe<Array<Maybe<ImageUploadBatch>>>;
  imageUploadBatchMetadata?: Maybe<ImageUploadBatchMetadata>;
};

export type GetIsGrocerRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetIsGrocerResponse = {
  __typename?: 'GetIsGrocerResponse';
  isGrocer?: Maybe<Scalars['Boolean']>;
};

export type GetItemsRequestInput = {
  parameters?: InputMaybe<GetItemsRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetItemsRequestParametersInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  offset?: InputMaybe<Scalars['BigInt']>;
  primaryAisleId?: InputMaybe<Scalars['BigInt']>;
  primaryDepartmentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetItemsResponse = {
  __typename?: 'GetItemsResponse';
  results?: Maybe<Array<Maybe<Item>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type GetLaunchOnboardingRequestInput = {
  includeApprovalRequests?: InputMaybe<Scalars['Boolean']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type GetLaunchOnboardingResponse = {
  __typename?: 'GetLaunchOnboardingResponse';
  launchOnboarding?: Maybe<LaunchOnboarding>;
};

export type GetLaunchOnboardingWarehouseLocationsRequestInput = {
  pagination?: InputMaybe<PaginationDetailsInput>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  sorting?: InputMaybe<SortingDetailsInput>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type GetLaunchOnboardingWarehouseLocationsResponse = {
  __typename?: 'GetLaunchOnboardingWarehouseLocationsResponse';
  pagination?: Maybe<PaginationDetails>;
  warehouseLocations?: Maybe<Array<Maybe<LaunchOnboardingWarehouseLocation>>>;
};

export type GetLayoutVariantsRequestInput = {
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Status>;
};

export type GetLayoutVariantsResponse = {
  __typename?: 'GetLayoutVariantsResponse';
  layoutVariants?: Maybe<Array<Maybe<LayoutVariant>>>;
};

export type GetLegacyElasticsearchRequestInput = {
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  itemIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetLegacyItemsShoppersRequestBoostWhereBoostObjectInput = {
  factor?: InputMaybe<Scalars['BigInt']>;
  value?: InputMaybe<Scalars['BigInt']>;
};

export type GetLegacyItemsShoppersRequestBoostWhereInput = {
  primaryDepartmentId?: InputMaybe<GetLegacyItemsShoppersRequestBoostWhereBoostObjectInput>;
};

export type GetLegacyItemsShoppersRequestInput = {
  aisleId?: InputMaybe<Scalars['BigInt']>;
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  anyMatchingProductCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  available?: InputMaybe<Scalars['Boolean']>;
  boostWhere?: InputMaybe<GetLegacyItemsShoppersRequestBoostWhereInput>;
  departmentId?: InputMaybe<Scalars['BigInt']>;
  filters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  itemIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  limit?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<InstacartCustomersSharedV1OrderInput>;
  productCodes?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  query?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerReferenceCode?: InputMaybe<Scalars['String']>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  tsqueryQuery?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type GetLegacyProductsRequestInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  availableInventoryAreaIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  inventoryAreaIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  query?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
};

export type GetLocalesByRetailerRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetLocalesByRetailerResponse = {
  __typename?: 'GetLocalesByRetailerResponse';
  retailerLocales?: Maybe<Array<Maybe<RetailerLocale>>>;
};

export type GetManagedAttributeValuesForRetailerRequestInput = {
  attributeName?: InputMaybe<Scalars['String']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetManagedAttributeValuesForRetailerResponse = {
  __typename?: 'GetManagedAttributeValuesForRetailerResponse';
  managedAttributeValues?: Maybe<Array<Maybe<ManagedAttributeValue>>>;
};

export type GetMomentParticipatingRetailersRequestInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetMomentParticipatingRetailersResponse = {
  __typename?: 'GetMomentParticipatingRetailersResponse';
  participatingRetailerCampaigns?: Maybe<RetailerCampaign>;
  retailerMomentCampaigns?: Maybe<Array<Maybe<RetailerMomentCampaign>>>;
};

export type GetMomentRequestInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
};

export type GetMomentResponse = {
  __typename?: 'GetMomentResponse';
  moment?: Maybe<Moment>;
};

export type GetMomentsRequestInput = {
  momentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type GetMomentsResponse = {
  __typename?: 'GetMomentsResponse';
  moments?: Maybe<Array<Maybe<Moment>>>;
};

export type GetNavigationRequestInput = {
  layout?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetNavigationResponse = {
  __typename?: 'GetNavigationResponse';
  navigation?: Maybe<Navigation>;
};

export type GetNextAuditBatchRequestInput = {
  userUuid?: InputMaybe<Scalars['String']>;
  workflow?: InputMaybe<Scalars['String']>;
};

export type GetNextAuditBatchResponse = {
  __typename?: 'GetNextAuditBatchResponse';
  batchUuid?: Maybe<Scalars['String']>;
};

export type GetOnboardingRequestInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type GetOnboardingResponse = {
  __typename?: 'GetOnboardingResponse';
  onboarding?: Maybe<Onboarding>;
};

export type GetOrderDiscountDetailsRequestInput = {
  orderId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderDiscountDetailsResponse = {
  __typename?: 'GetOrderDiscountDetailsResponse';
  discountDiscountables?: Maybe<Array<Maybe<DiscountDiscountablesData>>>;
};

export type GetOrderFulfillmentOverviewRequestInput = {
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderFulfillmentOverviewResponse = {
  __typename?: 'GetOrderFulfillmentOverviewResponse';
  activeShopper?: Maybe<Scalars['Int']>;
  isFullService?: Maybe<Scalars['Boolean']>;
  shoppers?: Maybe<Array<Maybe<ShopperOrderProfile>>>;
  warehouseLocationId?: Maybe<Scalars['BigInt']>;
};

export type GetOrderLocationRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderLocationResponse = {
  __typename?: 'GetOrderLocationResponse';
  shopperCoordinates?: Maybe<GoogleTypeLatLng>;
};

export type GetOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderResponse = {
  __typename?: 'GetOrderResponse';
  order?: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
  orderItems?: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItem>>>;
};

export type GetOrderStateChangesRequestInput = {
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
};

export type GetOrderStateChangesResponse = {
  __typename?: 'GetOrderStateChangesResponse';
  currentState?: Maybe<GetOrderStateChangesResponseState>;
  stateChanges?: Maybe<Array<Maybe<GetOrderStateChangesResponseState>>>;
};

export type GetOrderStateChangesResponseState = {
  __typename?: 'GetOrderStateChangesResponseState';
  issues?: Maybe<Array<Maybe<GetOrderStateChangesResponseStateIssue>>>;
  stateDisplayName?: Maybe<Scalars['String']>;
  stateType?: Maybe<GetOrderStateChangesResponseStateStateType>;
  transitionedAt?: Maybe<Scalars['DateTime']>;
  transitionedEta?: Maybe<Scalars['DateTime']>;
};

export type GetOrderStateChangesResponseStateIssue = {
  __typename?: 'GetOrderStateChangesResponseStateIssue';
  issueDescription?: Maybe<Scalars['String']>;
  issueType?: Maybe<GetOrderStateChangesResponseStateIssueIssueType>;
};

export enum GetOrderStateChangesResponseStateIssueIssueType {
  IssueTypeCanceled = 'ISSUE_TYPE_CANCELED',
  IssueTypeDelayed = 'ISSUE_TYPE_DELAYED',
  IssueTypeFailedDelivery = 'ISSUE_TYPE_FAILED_DELIVERY',
  IssueTypePartiallyDelivery = 'ISSUE_TYPE_PARTIALLY_DELIVERY',
  IssueTypeUnknown = 'ISSUE_TYPE_UNKNOWN'
}

export enum GetOrderStateChangesResponseStateStateType {
  StateTypeArrived = 'STATE_TYPE_ARRIVED',
  StateTypeAssigned = 'STATE_TYPE_ASSIGNED',
  StateTypeAtStore = 'STATE_TYPE_AT_STORE',
  StateTypeAwaitingReturn = 'STATE_TYPE_AWAITING_RETURN',
  StateTypeBagsPickedUp = 'STATE_TYPE_BAGS_PICKED_UP',
  StateTypeCheckingOut = 'STATE_TYPE_CHECKING_OUT',
  StateTypeDelivered = 'STATE_TYPE_DELIVERED',
  StateTypeInTransit = 'STATE_TYPE_IN_TRANSIT',
  StateTypeOrderPlaced = 'STATE_TYPE_ORDER_PLACED',
  StateTypePicking = 'STATE_TYPE_PICKING',
  StateTypeReadyForPickup = 'STATE_TYPE_READY_FOR_PICKUP',
  StateTypeReturnCompleted = 'STATE_TYPE_RETURN_COMPLETED',
  StateTypeReturnInProgress = 'STATE_TYPE_RETURN_IN_PROGRESS',
  StateTypeRunningOrder = 'STATE_TYPE_RUNNING_ORDER',
  StateTypeStaging = 'STATE_TYPE_STAGING',
  StateTypeUnknown = 'STATE_TYPE_UNKNOWN'
}

export type GetParkingSettingsRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<PickupType>;
};

export type GetParkingSettingsResponse = {
  __typename?: 'GetParkingSettingsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageConfigs?: Maybe<Array<Maybe<ImageConfig>>>;
  instruction?: Maybe<Scalars['String']>;
  retailerLocationConfig?: Maybe<RetailerLocationInfo>;
};

export type GetPartnerApiKeysRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetPartnerApiKeysResponse = {
  __typename?: 'GetPartnerApiKeysResponse';
  connectApiKeys?: Maybe<Array<Maybe<ConnectApiKey>>>;
};

export type GetPartnerIdRequestInput = {
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
  retailerAppId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetPartnerIdResponse = {
  __typename?: 'GetPartnerIdResponse';
  partnerId?: Maybe<Scalars['BigInt']>;
};

export type GetPickupSettingsMetadataRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetPickupSettingsMetadataResponse = {
  __typename?: 'GetPickupSettingsMetadataResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCurbsidePickupEnabled?: Maybe<Scalars['Boolean']>;
  isReadyForPickup?: Maybe<Scalars['Boolean']>;
  isSettingsOnboardingComplete?: Maybe<Scalars['Boolean']>;
  pickupEnableDate?: Maybe<GoogleTypeDate>;
};

export type GetPiiRequestsForRetailerRequestInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  requestCreatedAfter?: InputMaybe<Scalars['DateTime']>;
  requestCreatedBefore?: InputMaybe<Scalars['DateTime']>;
  requestTypes?: InputMaybe<Array<InputMaybe<RequestType>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
  workflowStatus?: InputMaybe<Array<InputMaybe<WorkflowStatus>>>;
};

export type GetPiiRequestsForRetailerResponse = {
  __typename?: 'GetPiiRequestsForRetailerResponse';
  piiRequests?: Maybe<Array<Maybe<PiiRequest>>>;
};

export type GetPlacementContentByIdRequestInput = {
  generateVisibilityConditions?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type GetPlacementContentByIdResponse = {
  __typename?: 'GetPlacementContentByIdResponse';
  content?: Maybe<PlacementContent>;
};

export type GetPlacementsRequestInput = {
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  enabledOnly?: InputMaybe<Scalars['Boolean']>;
  layout?: InputMaybe<Layout>;
  layoutVariantId?: InputMaybe<Scalars['BigInt']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  locale?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  placementIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  platform?: InputMaybe<Array<InputMaybe<PlatformType>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Direction>;
  status?: InputMaybe<Array<InputMaybe<Status>>>;
  stringLayout?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
  variant?: InputMaybe<Array<InputMaybe<BannerVariant>>>;
};

export type GetPlatformVersionsRequestInput = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export type GetPlatformVersionsResponse = {
  __typename?: 'GetPlatformVersionsResponse';
  nextPlatformVersion?: Maybe<Scalars['String']>;
  nextPlatformVersionRouletteName?: Maybe<Scalars['String']>;
  platformVersion?: Maybe<Scalars['String']>;
  platformVersionRouletteName?: Maybe<Scalars['String']>;
};

export type GetProductDataRequestInput = {
  productId?: InputMaybe<ProductIdInput>;
};

export type GetProductDataResponse = {
  __typename?: 'GetProductDataResponse';
  productData?: Maybe<Array<Maybe<ProductData>>>;
  productId?: Maybe<ProductId>;
};

export type GetProductSourcesRequestInput = {
  sourceLevel?: InputMaybe<SourceLevel>;
};

export type GetProductSourcesResponse = {
  __typename?: 'GetProductSourcesResponse';
  sourceCount?: Maybe<Scalars['BigInt']>;
  sources?: Maybe<Array<Maybe<ProductSource>>>;
};

export type GetRequestContextsRequestInput = {
  partnerId?: InputMaybe<Scalars['Int']>;
  retailerId?: InputMaybe<Scalars['Int']>;
};

export type GetRequestContextsResponse = {
  __typename?: 'GetRequestContextsResponse';
  requestContexts?: Maybe<Array<Maybe<InstacartCustomersSharedV1RequestContext>>>;
};

export type GetRetailerAisleTemplateRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  shopperType?: InputMaybe<ShopperType>;
};

export type GetRetailerAisleTemplateResponse = {
  __typename?: 'GetRetailerAisleTemplateResponse';
  activeAt?: Maybe<Scalars['DateTime']>;
  aisleOrders?: Maybe<Array<Maybe<AisleOrder>>>;
  aisleTemplateId?: Maybe<Scalars['BigInt']>;
  layoutId?: Maybe<Scalars['String']>;
};

export type GetRetailerCampaignRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerLocationByLayoutRequestInput = {
  layoutId?: InputMaybe<Scalars['String']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerLocationByLayoutResponse = {
  __typename?: 'GetRetailerLocationByLayoutResponse';
  retailerLocationAisles?: Maybe<Array<Maybe<RetailerLocationAisle>>>;
};

export type GetRetailerLocationsByLocationCodesRequestInput = {
  locationCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  skipCache?: InputMaybe<Scalars['Boolean']>;
  skipStatusCheck?: InputMaybe<Scalars['Boolean']>;
};

export type GetRetailerLocationsByLocationCodesResponse = {
  __typename?: 'GetRetailerLocationsByLocationCodesResponse';
  retailerLocations?: Maybe<Array<Maybe<RetailerLocationDetails>>>;
};

export type GetRetailerLocationsDetailsRequestInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type GetRetailerLocationsDetailsResponse = {
  __typename?: 'GetRetailerLocationsDetailsResponse';
  retailerLocations?: Maybe<Array<Maybe<RetailerLocationDetails>>>;
};

export type GetRetailerProductAssortmentRequestInput = {
  entityId?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  filter?: InputMaybe<RetailerProductAssortmentFilterInput>;
};

export type GetRetailerProductAssortmentResponse = {
  __typename?: 'GetRetailerProductAssortmentResponse';
  retailerProductAssortment?: Maybe<Array<Maybe<RetailerProductAssortment>>>;
};

export type GetRetailerProductDataRequestInput = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerProductDataResponse = {
  __typename?: 'GetRetailerProductDataResponse';
  retailerProductData?: Maybe<Array<Maybe<RetailerProductData>>>;
};

export type GetRetailerProductsRequestInput = {
  parameters?: InputMaybe<GetRetailerProductsRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerProductsRequestParametersDateTimeRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetRetailerProductsRequestParametersInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  hasPrimaryImageUrl?: InputMaybe<Scalars['Boolean']>;
  includeManagedAttributeValues?: InputMaybe<Scalars['Boolean']>;
  isStoreBrandProduct?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  offset?: InputMaybe<Scalars['BigInt']>;
  omniSearchTerm?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderBy?: InputMaybe<InstacartCustomersSharedV1OrderInput>;
  primaryAisleId?: InputMaybe<Scalars['BigInt']>;
  primaryDepartmentId?: InputMaybe<Scalars['BigInt']>;
  productCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productCreationDateRange?: InputMaybe<GetRetailerProductsRequestParametersDateTimeRangeInput>;
  query?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerProductIds?: InputMaybe<Array<InputMaybe<GetRetailerProductsRequestParametersRetailerProductIdInput>>>;
  retailerReferenceCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  surfaceIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  withUnclassifiedProducts?: InputMaybe<Scalars['Boolean']>;
};

export type GetRetailerProductsRequestParametersRetailerProductIdInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerProductsResponse = {
  __typename?: 'GetRetailerProductsResponse';
  results?: Maybe<Array<Maybe<RetailerProduct>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type GetRetailerRegionsRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  skipCache?: InputMaybe<Scalars['Boolean']>;
};

export type GetRetailerRegionsResponse = {
  __typename?: 'GetRetailerRegionsResponse';
  regions?: Maybe<Array<Maybe<Region>>>;
};

export type GetRppConfigurableItemsRequestInput = {
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRppConfigurableItemsResponse = {
  __typename?: 'GetRppConfigurableItemsResponse';
  configurableProducts?: Maybe<Scalars['String']>;
};

export type GetServiceOptionsFiltersInput = {
  addressId?: InputMaybe<Scalars['BigInt']>;
  cartTotal?: InputMaybe<Scalars['Float']>;
  earliestOptionStartAt?: InputMaybe<Scalars['DateTime']>;
  itemsCount?: InputMaybe<Scalars['Int']>;
  postalCode?: InputMaybe<Scalars['String']>;
  serviceType?: InputMaybe<Scalars['String']>;
  unitsCount?: InputMaybe<Scalars['Float']>;
  warehouseId?: InputMaybe<Scalars['Int']>;
  warehouseLocationId?: InputMaybe<Scalars['BigInt']>;
  withEtaOptions?: InputMaybe<Scalars['Boolean']>;
  withPriorityEtaOptions?: InputMaybe<Scalars['Boolean']>;
};

export type GetServiceOptionsRequestInput = {
  filters?: InputMaybe<GetServiceOptionsFiltersInput>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetServiceOptionsResponse = {
  __typename?: 'GetServiceOptionsResponse';
  serviceOptions?: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOption>>>;
};

export type GetSettingRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  settingName?: InputMaybe<Scalars['String']>;
};

export type GetSettingResponse = {
  __typename?: 'GetSettingResponse';
  settingRecord?: Maybe<RetailerSettingRecord>;
};

export type GetSignupPartnerRequestInput = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GetSignupPartnerResponse = {
  __typename?: 'GetSignupPartnerResponse';
  signupPartner?: Maybe<SignupPartner>;
};

export type GetSlotByIdRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type GetSlotByIdResponse = {
  __typename?: 'GetSlotByIdResponse';
  slot?: Maybe<Slot>;
};

export type GetStagingSettingsRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetStagingSettingsResponse = {
  __typename?: 'GetStagingSettingsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  freezer?: Maybe<Array<Maybe<Scalars['String']>>>;
  fridge?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  retailerLocationConfig?: Maybe<RetailerLocationInfo>;
  shelf?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetStoreConfigurationDraftsRequestInput = {
  includeOutdatedDrafts?: InputMaybe<Scalars['Boolean']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetStoreConfigurationDraftsResponse = {
  __typename?: 'GetStoreConfigurationDraftsResponse';
  drafts?: Maybe<Array<Maybe<StoreConfigurationDraft>>>;
  errorMessage?: Maybe<Scalars['String']>;
  errorReason?: Maybe<ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type GetStoreHoursByDateRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  verticalType?: InputMaybe<VerticalType>;
};

export type GetStoreHoursByDateResponse = {
  __typename?: 'GetStoreHoursByDateResponse';
  storeHours?: Maybe<Array<Maybe<StoreHoursByDate>>>;
};

export type GetStoreHoursByDayOfWeekRequestInput = {
  daysOfWeek?: InputMaybe<Array<InputMaybe<GoogleTypeDayOfWeek>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  verticalType?: InputMaybe<VerticalType>;
};

export type GetStoreHoursByDayOfWeekResponse = {
  __typename?: 'GetStoreHoursByDayOfWeekResponse';
  storeHours?: Maybe<Array<Maybe<StoreHoursByDayOfWeek>>>;
};

export type GetStoreHoursByZoneRetailerRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  includeClosedRetailer?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  verticalType?: InputMaybe<VerticalType>;
  zoneId?: InputMaybe<Scalars['BigInt']>;
};

export type GetStoreHoursByZoneRetailerResponse = {
  __typename?: 'GetStoreHoursByZoneRetailerResponse';
  storeHours?: Maybe<Array<Maybe<StoreHoursByZoneRetailer>>>;
};

export type GetStoreHoursRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  serviceTypes?: InputMaybe<Array<InputMaybe<RetailerServiceType>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type GetStoreHoursResponse = {
  __typename?: 'GetStoreHoursResponse';
  storeHours?: Maybe<Array<Maybe<StoreHours>>>;
};

export type GetStoreHoursUpdateTasksRequestInput = {
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type GetStoreHoursUpdateTasksResponse = {
  __typename?: 'GetStoreHoursUpdateTasksResponse';
  tasks?: Maybe<Array<Maybe<StoreHoursUpdateTask>>>;
};

export type GetThemePageRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GetThemePageResponse = {
  __typename?: 'GetThemePageResponse';
  themePage?: Maybe<ThemePage>;
};

export type GetTicketRequestInput = {
  client?: InputMaybe<ClientInput>;
  cptTicketId?: InputMaybe<Scalars['BigInt']>;
};

export type GetTicketResponse = {
  __typename?: 'GetTicketResponse';
  comments?: Maybe<Array<Maybe<ProblemTrackerTicketComment>>>;
  entities?: Maybe<Array<Maybe<ProblemTrackerEntityStatus>>>;
  ticket?: Maybe<ProblemTrackerTicket>;
  watchers?: Maybe<Array<Maybe<User>>>;
};

export type GetUserByIdFilteredByIsolationContextParametersInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
};

export type GetUserByIdFilteredByIsolationContextRequestInput = {
  parameters?: InputMaybe<GetUserByIdFilteredByIsolationContextParametersInput>;
};

export type GetValueBasedOverridesRequestInput = {
  entityId?: InputMaybe<EntityIdInput>;
};

export type GetValueBasedOverridesResponse = {
  __typename?: 'GetValueBasedOverridesResponse';
  entityId?: Maybe<EntityId>;
  valueBasedOverride?: Maybe<Array<Maybe<ValueBasedOverrideResponse>>>;
};

export type GetVirtualRetailersRequestInput = {
  parameters?: InputMaybe<GetVirtualRetailersRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetVirtualRetailersRequestParametersInput = {
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type GetVirtualRetailersResponse = {
  __typename?: 'GetVirtualRetailersResponse';
  virtualRetailers?: Maybe<Array<Maybe<VirtualRetailer>>>;
};

export type GoogleTypeDate = {
  __typename?: 'GoogleTypeDate';
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type GoogleTypeDateInput = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export enum GoogleTypeDayOfWeek {
  DayOfWeekUnspecified = 'DAY_OF_WEEK_UNSPECIFIED',
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type GoogleTypeLatLng = {
  __typename?: 'GoogleTypeLatLng';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GoogleTypeMoney = {
  __typename?: 'GoogleTypeMoney';
  currencyCode?: Maybe<Scalars['String']>;
  nanos?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['BigInt']>;
};

export type GoogleTypeMoneyInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  nanos?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['BigInt']>;
};

export type GoogleTypeTimeOfDay = {
  __typename?: 'GoogleTypeTimeOfDay';
  hours?: Maybe<Scalars['Int']>;
  minutes?: Maybe<Scalars['Int']>;
  nanos?: Maybe<Scalars['Int']>;
  seconds?: Maybe<Scalars['Int']>;
};

export type GoogleTypeTimeOfDayInput = {
  hours?: InputMaybe<Scalars['Int']>;
  minutes?: InputMaybe<Scalars['Int']>;
  nanos?: InputMaybe<Scalars['Int']>;
  seconds?: InputMaybe<Scalars['Int']>;
};

export type GrantImpersonationRequestInput = {
  clientContext?: InputMaybe<ClientContextInput>;
  impersonatedUserId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GrantImpersonationResponse = {
  __typename?: 'GrantImpersonationResponse';
  authorizationCode?: Maybe<Scalars['String']>;
};

export type Header = {
  __typename?: 'Header';
  desktopHeader?: Maybe<UnionType>;
  mobileHeader?: Maybe<UnionType>;
};

export type HealthCheck = {
  __typename?: 'HealthCheck';
  /** Server health status */
  alive: Scalars['True'];
  /** Postgresql health status */
  postgresql?: Maybe<Scalars['Boolean']>;
  /** Redis health status */
  redis?: Maybe<Scalars['Boolean']>;
  /** Snowflake health status */
  snowflake?: Maybe<Scalars['Boolean']>;
};

export type HeroBanner = {
  __typename?: 'HeroBanner';
  backgroundColorHex?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaBackgroundColorHex?: Maybe<Scalars['String']>;
  ctaColorHex?: Maybe<Scalars['String']>;
  desktopImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  locale?: Maybe<Scalars['String']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  textAreaColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type HeroBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  locale?: InputMaybe<Scalars['String']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  textAreaColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

/** Autogenerated return type of HeroBannerPlacementCreate */
export type HeroBannerPlacementCreatePayload = {
  __typename?: 'HeroBannerPlacementCreatePayload';
  /** The newly created hero_banner placement */
  heroBannerPlacement: ContentManagementHeroBannerPlacement;
};

export type HeroBannerPlacementInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  destination?: InputMaybe<PlacementDestinationInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Information of Experiment associated with the placement */
  experiment?: InputMaybe<PlacementExperimentInput>;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** The images on the banner */
  images: GenericImagesInput;
  /** The retailers that this placement should be displayed on */
  includedRetailerIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The layout the placement should appear on */
  layout?: InputMaybe<Scalars['String']>;
  /** Locale */
  locale?: InputMaybe<BannerLocaleEnum>;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank?: InputMaybe<Scalars['Int']>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The layout the placement should appear on */
  stringLayout?: InputMaybe<Scalars['String']>;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
  /** The color of the text area for placements (Eg. #FFFFFF) */
  textareaColor?: InputMaybe<Scalars['String']>;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** The variant of the banner */
  variant?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of HeroBannerPlacementUpdate */
export type HeroBannerPlacementUpdatePayload = {
  __typename?: 'HeroBannerPlacementUpdatePayload';
  /** The updated hero_banner placement */
  heroBannerPlacement: ContentManagementHeroBannerPlacement;
};

export type HighContrastColorsSchema = {
  __typename?: 'HighContrastColorsSchema';
  brandExpressDark?: Maybe<Scalars['String']>;
  brandExpressExtraDark?: Maybe<Scalars['String']>;
  brandExpressLight?: Maybe<Scalars['String']>;
  brandExpressRegular?: Maybe<Scalars['String']>;
  brandHighlightDark?: Maybe<Scalars['String']>;
  brandHighlightLight?: Maybe<Scalars['String']>;
  brandHighlightRegular?: Maybe<Scalars['String']>;
  brandLoyaltyDark?: Maybe<Scalars['String']>;
  brandLoyaltyLight?: Maybe<Scalars['String']>;
  brandLoyaltyRegular?: Maybe<Scalars['String']>;
  brandMaxDark?: Maybe<Scalars['String']>;
  brandMaxLight?: Maybe<Scalars['String']>;
  brandPrimaryDark?: Maybe<Scalars['String']>;
  brandPrimaryExtraDark?: Maybe<Scalars['String']>;
  brandPrimaryRegular?: Maybe<Scalars['String']>;
  brandPromotionalDark?: Maybe<Scalars['String']>;
  brandPromotionalLight?: Maybe<Scalars['String']>;
  brandPromotionalRegular?: Maybe<Scalars['String']>;
  brandSecondaryDark?: Maybe<Scalars['String']>;
  brandSecondaryLight?: Maybe<Scalars['String']>;
  brandSecondaryRegular?: Maybe<Scalars['String']>;
};

export type HistoricalPerformanceOverviewRecord = {
  __typename?: 'HistoricalPerformanceOverviewRecord';
  currentValue?: Maybe<InstacartTypesV1BigDecimal>;
  metricType?: Maybe<MetricType>;
  pastValue?: Maybe<InstacartTypesV1BigDecimal>;
};

export type HistoricalStorePerformanceRecord = {
  __typename?: 'HistoricalStorePerformanceRecord';
  dedicatedMatchRate?: Maybe<InstacartTypesV1BigDecimal>;
  dedicatedUtilizationRate?: Maybe<InstacartTypesV1BigDecimal>;
  fiveHourAvailabilityRate?: Maybe<InstacartTypesV1BigDecimal>;
  fssOrdersCount?: Maybe<Scalars['Int']>;
  matchRate?: Maybe<InstacartTypesV1BigDecimal>;
  name?: Maybe<Scalars['String']>;
  partnerPickOrdersCount?: Maybe<Scalars['Int']>;
  recommendedHours?: Maybe<InstacartTypesV1BigDecimal>;
  scheduledGaps?: Maybe<Scalars['Int']>;
  scheduledHours?: Maybe<InstacartTypesV1BigDecimal>;
  utilizationRate?: Maybe<InstacartTypesV1BigDecimal>;
  warehouseLocationId?: Maybe<Scalars['BigInt']>;
};

export type HistoricalStorePerformanceRequestInput = {
  devicePrioritizationEnabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<GoogleTypeDateInput>;
  metricThresholds?: InputMaybe<Array<InputMaybe<MetricThresholdInput>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type HistoricalStorePerformanceResponse = {
  __typename?: 'HistoricalStorePerformanceResponse';
  metrics?: Maybe<Array<Maybe<HistoricalStorePerformanceRecord>>>;
  overviewMetrics?: Maybe<Array<Maybe<HistoricalPerformanceOverviewRecord>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  warehouseId?: Maybe<Scalars['BigInt']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  date?: Maybe<GoogleTypeDate>;
  holidaySlug?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HolidayStatus = {
  __typename?: 'HolidayStatus';
  confirmedRetailerLocationCount?: Maybe<Scalars['Int']>;
  holiday?: Maybe<Holiday>;
  unconfirmedRetailerLocationCount?: Maybe<Scalars['Int']>;
};

/** Types of Home Placements which may be returned */
export type HomePlacement = ContentManagementFlyoutPlacement | ContentManagementHeroBannerPlacement | ContentManagementSecondaryBannerPlacement | ContentManagementUnknownBannerPlacement;

export type HubPaginationMeta = {
  __typename?: 'HubPaginationMeta';
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type IcPlusSchema = {
  __typename?: 'IcPlusSchema';
  enabledFeatures?: Maybe<Array<Maybe<IcPlusSchemaIcPlusFeature>>>;
};

export enum IcPlusSchemaIcPlusFeature {
  CreditBack = 'CREDIT_BACK',
  EnterpriseUpsell = 'ENTERPRISE_UPSELL',
  Unknown = 'UNKNOWN',
  Upsell = 'UPSELL'
}

export type IdsThemeSchema = {
  __typename?: 'IdsThemeSchema';
  buttonBorderRadius?: Maybe<BorderRadiusSchema>;
  colors?: Maybe<ColorsSchema>;
  fonts?: Maybe<Array<Maybe<CustomFontFamilySchema>>>;
  inputBorderRadius?: Maybe<BorderRadiusSchema>;
  radius?: Maybe<RadiusSchema>;
  typography?: Maybe<TypographySchema>;
};

export type ImageAsset = {
  __typename?: 'ImageAsset';
  altText?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageConfig = {
  __typename?: 'ImageConfig';
  caption?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type ImageConfigInput = {
  caption?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ImageForward = {
  __typename?: 'ImageForward';
  backgroundColorHex?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaBackgroundColorHex?: Maybe<Scalars['String']>;
  ctaColorHex?: Maybe<Scalars['String']>;
  desktopImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type ImageForwardFlex = {
  __typename?: 'ImageForwardFlex';
  backgroundColorHex?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaBackgroundColorHex?: Maybe<Scalars['String']>;
  ctaColorHex?: Maybe<Scalars['String']>;
  desktopImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type ImageForwardFlexInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ImageForwardInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ImageUploadBatch = {
  __typename?: 'ImageUploadBatch';
  batchId?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  processedImageUploads?: Maybe<Scalars['BigInt']>;
  state?: Maybe<Scalars['String']>;
  successfulImageUploads?: Maybe<Scalars['BigInt']>;
  totalImageUploads?: Maybe<Scalars['BigInt']>;
};

export type ImageUploadBatchDetails = {
  __typename?: 'ImageUploadBatchDetails';
  filename?: Maybe<Scalars['String']>;
  imageUploadId?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
  productName?: Maybe<Scalars['String']>;
};

export type ImageUploadBatchMetadata = {
  __typename?: 'ImageUploadBatchMetadata';
  perPage?: Maybe<Scalars['BigInt']>;
  totalCount?: Maybe<Scalars['BigInt']>;
  totalPages?: Maybe<Scalars['BigInt']>;
};

export type ImagesAwsDetailsInput = {
  bucket?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};

export type InStoreSchema = {
  __typename?: 'InStoreSchema';
  inStoreModeEnabled?: Maybe<Scalars['Boolean']>;
  scanItemBarcodeEnabled?: Maybe<Scalars['Boolean']>;
};

export type IndexBrandNamesRequestInput = {
  isDebug?: InputMaybe<Scalars['Boolean']>;
  superCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IndexBrandNamesResponse = {
  __typename?: 'IndexBrandNamesResponse';
  brandNameMappings?: Maybe<Array<Maybe<BrandNameMapping>>>;
  brandProfileIdToName?: Maybe<Scalars['String']>;
};

export type IndexEbpCategoriesRequestInput = {
  brandProfileIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDebug?: InputMaybe<Scalars['Boolean']>;
};

export type IndexEbpCategoriesResponse = {
  __typename?: 'IndexEbpCategoriesResponse';
  superCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  superCategoryIdToString?: Maybe<Scalars['String']>;
};

export type InsightsPortalAuthorizationContextInput = {
  accountId?: InputMaybe<Scalars['BigInt']>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type InsightsPortalComparison = {
  __typename?: 'InsightsPortalComparison';
  absoluteChange?: Maybe<Scalars['String']>;
  percentChange?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum InsightsPortalComparisonPeriod {
  LastPeriod = 'LAST_PERIOD',
  LastPeriodDayOfWeekMatch = 'LAST_PERIOD_DAY_OF_WEEK_MATCH',
  LastYear = 'LAST_YEAR',
  LastYearDayOfWeekMatch = 'LAST_YEAR_DAY_OF_WEEK_MATCH'
}

export type InsightsPortalCreateCustomReportRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  comparisonPeriod?: InputMaybe<InsightsPortalComparisonPeriod>;
  dashboardSlug?: InputMaybe<Scalars['String']>;
  filterSelectionOptionsList?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionOptionsInput>>>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  groupBys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  orderBys?: InputMaybe<Array<InputMaybe<InsightsPortalOrderByInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalCreateCustomReportResponse = {
  __typename?: 'InsightsPortalCreateCustomReportResponse';
  customReport?: Maybe<InsightsPortalCustomReport>;
  success?: Maybe<Scalars['Boolean']>;
};

export type InsightsPortalCustomReport = {
  __typename?: 'InsightsPortalCustomReport';
  comparisonPeriod?: Maybe<InsightsPortalComparisonPeriod>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dashboardSlug?: Maybe<Scalars['String']>;
  downloadUrl?: Maybe<Scalars['String']>;
  fileGenerationStatus?: Maybe<InsightsPortalCustomReportFileGenerationStatus>;
  filterSelectionOptionsList?: Maybe<Array<Maybe<InsightsPortalFilterSelectionOptions>>>;
  filterSelections?: Maybe<Scalars['String']>;
  groupBys?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['BigInt']>;
  metrics?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  orderBys?: Maybe<Array<Maybe<InsightsPortalOrderBy>>>;
  schema?: Maybe<Scalars['String']>;
};

export enum InsightsPortalCustomReportFileGenerationStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Ready = 'READY'
}

export enum InsightsPortalDataSource {
  Clickhouse = 'CLICKHOUSE',
  Default = 'DEFAULT',
  Snowflake = 'SNOWFLAKE'
}

export type InsightsPortalFilterOption = {
  __typename?: 'InsightsPortalFilterOption';
  displayValue?: Maybe<Scalars['String']>;
  idValue?: Maybe<Scalars['String']>;
  rawValue?: Maybe<InsightsPortalValue>;
};

export type InsightsPortalFilterOptionsRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  owner?: InputMaybe<InsightsPortalOwner>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
  whitelistFilterOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InsightsPortalFilterOptionsResponse = {
  __typename?: 'InsightsPortalFilterOptionsResponse';
  filterOptions?: Maybe<Array<Maybe<InsightsPortalFilterOptionsResponseFilterOptions>>>;
};

export type InsightsPortalFilterOptionsResponseFilterOptions = {
  __typename?: 'InsightsPortalFilterOptionsResponseFilterOptions';
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<InsightsPortalFilterOption>>>;
};

export type InsightsPortalFilterSelectionBetweenFilterInput = {
  end?: InputMaybe<InsightsPortalValueInput>;
  start?: InputMaybe<InsightsPortalValueInput>;
};

export type InsightsPortalFilterSelectionDateFilterInput = {
  comparisonEnd?: InputMaybe<Scalars['DateTime']>;
  comparisonStart?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type InsightsPortalFilterSelectionDimensionFilterInput = {
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InsightsPortalFilterSelectionEqualFilterInput = {
  value?: InputMaybe<InsightsPortalValueInput>;
};

export type InsightsPortalFilterSelectionInFilterInput = {
  values?: InputMaybe<Array<InputMaybe<InsightsPortalValueInput>>>;
};

export type InsightsPortalFilterSelectionInput = {
  betweenFilter?: InputMaybe<InsightsPortalFilterSelectionBetweenFilterInput>;
  dateFilter?: InputMaybe<InsightsPortalFilterSelectionDateFilterInput>;
  dimFilter?: InputMaybe<InsightsPortalFilterSelectionDimensionFilterInput>;
  equalFilter?: InputMaybe<InsightsPortalFilterSelectionEqualFilterInput>;
  inFilter?: InputMaybe<InsightsPortalFilterSelectionInFilterInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type InsightsPortalFilterSelectionOption = {
  __typename?: 'InsightsPortalFilterSelectionOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type InsightsPortalFilterSelectionOptionInput = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type InsightsPortalFilterSelectionOptions = {
  __typename?: 'InsightsPortalFilterSelectionOptions';
  dimension?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<InsightsPortalFilterSelectionOption>>>;
};

export type InsightsPortalFilterSelectionOptionsInput = {
  dimension?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionOptionInput>>>;
};

export type InsightsPortalGetCustomReportRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
};

export type InsightsPortalGetCustomReportResponse = {
  __typename?: 'InsightsPortalGetCustomReportResponse';
  customReport?: Maybe<InsightsPortalCustomReport>;
};

export type InsightsPortalListCustomReportsRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dashboardSlug?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type InsightsPortalListCustomReportsResponse = {
  __typename?: 'InsightsPortalListCustomReportsResponse';
  customReports?: Maybe<Array<Maybe<InsightsPortalCustomReport>>>;
};

export type InsightsPortalMetricValue = {
  __typename?: 'InsightsPortalMetricValue';
  metric?: Maybe<Scalars['String']>;
  value?: Maybe<InsightsPortalValue>;
};

export type InsightsPortalMetricValuesRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  owner?: InputMaybe<InsightsPortalOwner>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalMetricValuesResponse = {
  __typename?: 'InsightsPortalMetricValuesResponse';
  metricValues?: Maybe<Array<Maybe<InsightsPortalMetricValue>>>;
};

export type InsightsPortalMultiDimensionDatasetRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  dimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  includeCount?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBys?: InputMaybe<Array<InputMaybe<InsightsPortalOrderByInput>>>;
  owner?: InputMaybe<InsightsPortalOwner>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalMultiDimensionDatasetResponse = {
  __typename?: 'InsightsPortalMultiDimensionDatasetResponse';
  headers?: Maybe<Array<Maybe<InsightsPortalMultiDimensionDatasetResponseHeader>>>;
  rows?: Maybe<Array<Maybe<InsightsPortalMultiDimensionDatasetResponseRow>>>;
  totalRows?: Maybe<Scalars['BigInt']>;
};

export type InsightsPortalMultiDimensionDatasetResponseHeader = {
  __typename?: 'InsightsPortalMultiDimensionDatasetResponseHeader';
  dimensionName?: Maybe<Scalars['String']>;
  metricName?: Maybe<Scalars['String']>;
};

export type InsightsPortalMultiDimensionDatasetResponseRow = {
  __typename?: 'InsightsPortalMultiDimensionDatasetResponseRow';
  items?: Maybe<Array<Maybe<Item>>>;
};

export type InsightsPortalMultiGroupRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  groupBys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['BigInt']>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBys?: InputMaybe<Array<InputMaybe<InsightsPortalOrderByInput>>>;
  owner?: InputMaybe<InsightsPortalOwner>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalMultiGroupResponse = {
  __typename?: 'InsightsPortalMultiGroupResponse';
  dataHeaders?: Maybe<Array<Maybe<InsightsPortalMultiGroupResponseDataHeaders>>>;
  dataRows?: Maybe<Array<Maybe<InsightsPortalMultiGroupResponseDataRow>>>;
  totalRows?: Maybe<Scalars['BigInt']>;
};

export type InsightsPortalMultiGroupResponseDataHeaders = {
  __typename?: 'InsightsPortalMultiGroupResponseDataHeaders';
  groupByName?: Maybe<Scalars['String']>;
  metricName?: Maybe<Scalars['String']>;
};

export type InsightsPortalMultiGroupResponseDataRow = {
  __typename?: 'InsightsPortalMultiGroupResponseDataRow';
  dataItems?: Maybe<Array<Maybe<DataItem>>>;
};

export type InsightsPortalOrderBy = {
  __typename?: 'InsightsPortalOrderBy';
  columnName?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<InsightsPortalOrderDirection>;
};

export type InsightsPortalOrderByInput = {
  columnName?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<InsightsPortalOrderDirection>;
};

export enum InsightsPortalOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE'
}

export enum InsightsPortalOwner {
  RetailerTools = 'RETAILER_TOOLS',
  Unspecified = 'UNSPECIFIED'
}

export type InsightsPortalQueryTagInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum InsightsPortalSchema {
  AdsCoupon = 'ADS_COUPON',
  AdsDisplay = 'ADS_DISPLAY',
  AdsDisplayBanner = 'ADS_DISPLAY_BANNER',
  AdsOverview = 'ADS_OVERVIEW',
  AdsSponsoredProduct = 'ADS_SPONSORED_PRODUCT',
  AdsSponsoredProductHistoricalGrowth = 'ADS_SPONSORED_PRODUCT_HISTORICAL_GROWTH',
  AdsSponsoredProductV2 = 'ADS_SPONSORED_PRODUCT_V2',
  AggMerchCollections = 'AGG_MERCH_COLLECTIONS',
  AggMerchCollectionsHistoricalGrowth = 'AGG_MERCH_COLLECTIONS_HISTORICAL_GROWTH',
  AggMerchCollectionsRollup = 'AGG_MERCH_COLLECTIONS_ROLLUP',
  AggMerchCollectionsRollupHistoricalGrowth = 'AGG_MERCH_COLLECTIONS_ROLLUP_HISTORICAL_GROWTH',
  AggMerchPlacements = 'AGG_MERCH_PLACEMENTS',
  AggMerchPlacementsHistoricalGrowth = 'AGG_MERCH_PLACEMENTS_HISTORICAL_GROWTH',
  AggWarehouseLocationCampaignPerformance = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE',
  AggWarehouseLocationCampaignPerformanceHistoricalGrowth = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE_HISTORICAL_GROWTH',
  AggWarehouseLocationCampaignPerformanceStitchOrderDelivery = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY',
  AggWarehouseLocationCampaignPerformanceStitchOrderDeliveryHistoricalGrowth = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY_HISTORICAL_GROWTH',
  AggWarehouseLocationPerformance = 'AGG_WAREHOUSE_LOCATION_PERFORMANCE',
  AggWarehouseLocationPerformanceHistoricalGrowth = 'AGG_WAREHOUSE_LOCATION_PERFORMANCE_HISTORICAL_GROWTH',
  AggWarehousePerformance = 'AGG_WAREHOUSE_PERFORMANCE',
  FactCampaignImpressionsPerformance = 'FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE',
  FactCampaignImpressionsPerformanceHistoricalGrowth = 'FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE_HISTORICAL_GROWTH',
  FactCampaignPerformance = 'FACT_CAMPAIGN_PERFORMANCE',
  FactCampaignPerformanceStitchFactCampaignImpressionsPerformance = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE',
  FactCampaignPerformanceStitchFactCampaignImpressionsPerformanceHistoricalGrowth = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE_HISTORICAL_GROWTH',
  FactCampaignPerformanceStitchOrderDelivery = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY',
  FactCampaignPerformanceStitchOrderDeliveryHistoricalGrowth = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY_HISTORICAL_GROWTH',
  FutureScheduledOrders = 'FUTURE_SCHEDULED_ORDERS',
  ImageCoverage = 'IMAGE_COVERAGE',
  ImageCoverageClickhouse = 'IMAGE_COVERAGE_CLICKHOUSE',
  ImageCoverageDemo = 'IMAGE_COVERAGE_DEMO',
  ImageCoverageHistoricalGrowth = 'IMAGE_COVERAGE_HISTORICAL_GROWTH',
  ImageCoverageHistoricalGrowthClickhouse = 'IMAGE_COVERAGE_HISTORICAL_GROWTH_CLICKHOUSE',
  InventoryIntelligenceOosSignalsAgg = 'INVENTORY_INTELLIGENCE_OOS_SIGNALS_AGG',
  MissingImage_90DayRetailerInsights = 'MISSING_IMAGE_90DAY_RETAILER_INSIGHTS',
  MissingImage_90DayRetailerInsightsClickhouse = 'MISSING_IMAGE_90DAY_RETAILER_INSIGHTS_CLICKHOUSE',
  OrderDelivery = 'ORDER_DELIVERY',
  OrderDeliveryClickhouse = 'ORDER_DELIVERY_CLICKHOUSE',
  OrderDeliveryDemo = 'ORDER_DELIVERY_DEMO',
  OrderDeliveryHistoricalGrowth = 'ORDER_DELIVERY_HISTORICAL_GROWTH',
  OrderDeliveryHistoricalGrowthClickhouse = 'ORDER_DELIVERY_HISTORICAL_GROWTH_CLICKHOUSE',
  OrderItem = 'ORDER_ITEM',
  OrderItemClickhouse = 'ORDER_ITEM_CLICKHOUSE',
  OrderItemDemo = 'ORDER_ITEM_DEMO',
  OrderItemHistoricalGrowth = 'ORDER_ITEM_HISTORICAL_GROWTH',
  OrderItemHistoricalGrowthClickhouse = 'ORDER_ITEM_HISTORICAL_GROWTH_CLICKHOUSE',
  OrderItemStitchProductByCategoryBenchmark = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK',
  OrderItemStitchProductByCategoryBenchmarkClickhouse = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK_CLICKHOUSE',
  OrderItemStitchProductByCategoryBenchmarkHistoricalGrowth = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK_HISTORICAL_GROWTH',
  OrderItemStitchProductByCategoryBenchmarkHistoricalGrowthClickhouse = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK_HISTORICAL_GROWTH_CLICKHOUSE',
  ProductByCategoryBenchmark = 'PRODUCT_BY_CATEGORY_BENCHMARK',
  ProductByCategoryBenchmarkClickhouse = 'PRODUCT_BY_CATEGORY_BENCHMARK_CLICKHOUSE',
  ProductByPartnerBenchmark = 'PRODUCT_BY_PARTNER_BENCHMARK',
  ProductByPartnerBenchmarkClickhouse = 'PRODUCT_BY_PARTNER_BENCHMARK_CLICKHOUSE',
  SearchTerm = 'SEARCH_TERM',
  SearchTermByPartnerBenchmark = 'SEARCH_TERM_BY_PARTNER_BENCHMARK',
  SearchTermByPartnerBenchmarkClickhouse = 'SEARCH_TERM_BY_PARTNER_BENCHMARK_CLICKHOUSE',
  SearchTermByTermBenchmark = 'SEARCH_TERM_BY_TERM_BENCHMARK',
  SearchTermByTermBenchmarkClickhouse = 'SEARCH_TERM_BY_TERM_BENCHMARK_CLICKHOUSE',
  SearchTermClickhouse = 'SEARCH_TERM_CLICKHOUSE',
  SearchTermDemo = 'SEARCH_TERM_DEMO',
  SearchTermHistoricalGrowth = 'SEARCH_TERM_HISTORICAL_GROWTH',
  SearchTermHistoricalGrowthClickhouse = 'SEARCH_TERM_HISTORICAL_GROWTH_CLICKHOUSE',
  SearchTermStitchSearchTermByTermBenchmark = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK',
  SearchTermStitchSearchTermByTermBenchmarkClickhouse = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK_CLICKHOUSE',
  SearchTermStitchSearchTermByTermBenchmarkHistoricalGrowth = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK_HISTORICAL_GROWTH',
  SearchTermStitchSearchTermByTermBenchmarkHistoricalGrowthClickhouse = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK_HISTORICAL_GROWTH_CLICKHOUSE',
  Unknown = 'UNKNOWN'
}

export type InsightsPortalSchemaMetadataRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalSchemaMetadataResponse = {
  __typename?: 'InsightsPortalSchemaMetadataResponse';
  dimensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupBys?: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

export type InsightsPortalSetCustomReportDisabledRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
};

export type InsightsPortalSetCustomReportDisabledResponse = {
  __typename?: 'InsightsPortalSetCustomReportDisabledResponse';
  customReport?: Maybe<InsightsPortalCustomReport>;
  success?: Maybe<Scalars['Boolean']>;
};

export type InsightsPortalSnowflakeHeartbeatRequestInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type InsightsPortalSnowflakeHeartbeatResponse = {
  __typename?: 'InsightsPortalSnowflakeHeartbeatResponse';
  authenticated?: Maybe<Scalars['Boolean']>;
};

export type InsightsPortalUpdateCustomReportRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  comparisonPeriod?: InputMaybe<InsightsPortalComparisonPeriod>;
  filterSelectionOptionsList?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionOptionsInput>>>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  id?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalUpdateCustomReportResponse = {
  __typename?: 'InsightsPortalUpdateCustomReportResponse';
  customReport?: Maybe<InsightsPortalCustomReport>;
  success?: Maybe<Scalars['Boolean']>;
};

export type InsightsPortalValue = {
  __typename?: 'InsightsPortalValue';
  doubleValue?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['BigInt']>;
  stringValue?: Maybe<Scalars['String']>;
  timestampValue?: Maybe<Scalars['DateTime']>;
};

export type InsightsPortalValueInput = {
  doubleValue?: InputMaybe<Scalars['Float']>;
  intValue?: InputMaybe<Scalars['BigInt']>;
  stringValue?: InputMaybe<Scalars['String']>;
  timestampValue?: InputMaybe<Scalars['DateTime']>;
};

export enum InstacartAdsNexusV1RegionalArea {
  Midwest = 'MIDWEST',
  Mountain = 'MOUNTAIN',
  Northeast = 'NORTHEAST',
  Pacific = 'PACIFIC',
  RegionalAreaUnknown = 'REGIONAL_AREA_UNKNOWN',
  Southeast = 'SOUTHEAST',
  Southwest = 'SOUTHWEST'
}

export enum InstacartAdsNexusV1StoreType {
  Alcohol = 'ALCOHOL',
  Asian = 'ASIAN',
  Baby = 'BABY',
  ClubWarehouse = 'CLUB_WAREHOUSE',
  Convenience = 'CONVENIENCE',
  ConventionalTraditional = 'CONVENTIONAL_TRADITIONAL',
  Coop = 'COOP',
  DepartmentStore = 'DEPARTMENT_STORE',
  Drugstore = 'DRUGSTORE',
  HomeKitchenBath = 'HOME_KITCHEN_BATH',
  LatinAmerican = 'LATIN_AMERICAN',
  LocalSpecialty = 'LOCAL_SPECIALTY',
  NaturalOrganic = 'NATURAL_ORGANIC',
  Other = 'OTHER',
  Pet = 'PET',
  StoreTypeUnknown = 'STORE_TYPE_UNKNOWN',
  Supplement = 'SUPPLEMENT',
  Value = 'VALUE'
}

export type InstacartAdsSharedV1DateRange = {
  __typename?: 'InstacartAdsSharedV1DateRange';
  endDate?: Maybe<GoogleTypeDate>;
  startDate?: Maybe<GoogleTypeDate>;
};

export type InstacartAdsSharedV1DateRangeInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type InstacartAdsSharedV1PaginationRequestInput = {
  page?: InputMaybe<Scalars['BigInt']>;
  resultsPerPage?: InputMaybe<Scalars['BigInt']>;
};

export type InstacartAdsSharedV1PaginationResponse = {
  __typename?: 'InstacartAdsSharedV1PaginationResponse';
  page?: Maybe<Scalars['BigInt']>;
  resultsPerPage?: Maybe<Scalars['BigInt']>;
  totalPages?: Maybe<Scalars['BigInt']>;
};

export enum InstacartCustomersCommerceTypesV1FundingSource {
  Cpg = 'CPG',
  FundingSourceUnspecified = 'FUNDING_SOURCE_UNSPECIFIED',
  Instacart = 'INSTACART',
  Others = 'OTHERS',
  Retailer = 'RETAILER'
}

export type InstacartCustomersCommerceTypesV1SlimOffer = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOffer';
  buyMAmountGetNQtyWithXPercentOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetNQtyWithXPercentOffScenario>;
  buyMAmountGetXAmountOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenario>;
  buyMAmountGetXPercentOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenario>;
  buyMAmountOfAGetBForXAmountScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetBForXAmountScenario>;
  buyMAmountOfAGetXAmountOffOnBScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXAmountOffOnBScenario>;
  buyMAmountOfAGetXPercentOffOnBScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXPercentOffOnBScenario>;
  buyMQtyForXAmountScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenario>;
  buyMQtyGetNForFreeScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenario>;
  buyMQtyGetNQtyForXAmountScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenario>;
  buyMQtyGetNQtyWithXAmountOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXAmountOffScenario>;
  buyMQtyGetNQtyWithXPercentOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXPercentOffScenario>;
  buyMQtyGetXAmountOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenario>;
  buyMQtyGetXPercentOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenario>;
  buyMQtyOfAGetBForXAmountScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetBForXAmountScenario>;
  buyMQtyOfAGetXAmountOffOnBScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXAmountOffOnBScenario>;
  buyMQtyOfAGetXPercentOffOnBScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXPercentOffOnBScenario>;
  buyMQtyOrMoreGetXPercentOffScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenario>;
  buyMRangeQtyGetXPercentOffTieredScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenario>;
  conditionRewardString?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountCategoryId?: Maybe<Scalars['BigInt']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingDetail?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferFundingDetail>;
  imageUrl?: Maybe<Scalars['String']>;
  metadata?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferMetadata>;
  name?: Maybe<Scalars['String']>;
  offerConditions?: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferCondition>>>;
  offerExternalReference?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
  offerRequestKey?: Maybe<Scalars['String']>;
  offerRewards?: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferReward>>>;
  offerSource?: Maybe<Scalars['String']>;
  offerStackability?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferStackability>;
  offerType?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferType>;
  promoCode?: Maybe<Scalars['String']>;
  redemptionLimits?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimits>;
  startsAt?: Maybe<Scalars['DateTime']>;
  terms?: Maybe<Scalars['String']>;
  uncategorizedScenario?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenario>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetNQtyWithXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetNQtyWithXPercentOffScenario';
  mAmount?: Maybe<GoogleTypeMoney>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetNQtyWithXPercentOffScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenario';
  mAmount?: Maybe<GoogleTypeMoney>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenario';
  mAmount?: Maybe<GoogleTypeMoney>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetBForXAmountScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetBForXAmountScenario';
  mAmount?: Maybe<GoogleTypeMoney>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetBForXAmountScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXAmountOffOnBScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXAmountOffOnBScenario';
  mAmount?: Maybe<GoogleTypeMoney>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXAmountOffOnBScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXPercentOffOnBScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXPercentOffOnBScenario';
  mAmount?: Maybe<GoogleTypeMoney>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXPercentOffOnBScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXAmountOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXAmountOffScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXAmountOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXPercentOffScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXPercentOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetBForXAmountScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetBForXAmountScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetBForXAmountScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXAmountOffOnBScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXAmountOffOnBScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xAmount?: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXAmountOffOnBScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXPercentOffOnBScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXPercentOffOnBScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  nQty?: Maybe<InstacartTypesV1BigDecimal>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXPercentOffOnBScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenario';
  mQty?: Maybe<InstacartTypesV1BigDecimal>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenario';
  mQtyMax?: Maybe<InstacartTypesV1BigDecimal>;
  mQtyMin?: Maybe<InstacartTypesV1BigDecimal>;
  xPercent?: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenarioInput = {
  mQtyMax?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  mQtyMin?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenario';
  currentTier?: Maybe<Scalars['BigInt']>;
  tiers?: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenario>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenarioInput = {
  currentTier?: InputMaybe<Scalars['BigInt']>;
  tiers?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenarioInput>>>;
};

export enum InstacartCustomersCommerceTypesV1SlimOfferDiscountRewardType {
  AmountOff = 'AMOUNT_OFF',
  DiscountRewardTypeUnspecified = 'DISCOUNT_REWARD_TYPE_UNSPECIFIED',
  PercentMille = 'PERCENT_MILLE',
  PercentOff = 'PERCENT_OFF',
  SetPrice = 'SET_PRICE',
  SetPricePerUnit = 'SET_PRICE_PER_UNIT'
}

export type InstacartCustomersCommerceTypesV1SlimOfferFundingDetail = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferFundingDetail';
  budgetLimit?: Maybe<GoogleTypeMoney>;
  fundingProviderInfo?: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformation>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferFundingDetailInput = {
  budgetLimit?: InputMaybe<GoogleTypeMoneyInput>;
  fundingProviderInfo?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformationInput>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformation = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformation';
  percent?: Maybe<Scalars['BigInt']>;
  source?: Maybe<InstacartCustomersCommerceTypesV1FundingSource>;
  sourceName?: Maybe<Scalars['String']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformationInput = {
  percent?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<InstacartCustomersCommerceTypesV1FundingSource>;
  sourceName?: InputMaybe<Scalars['String']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferInput = {
  buyMAmountGetNQtyWithXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetNQtyWithXPercentOffScenarioInput>;
  buyMAmountGetXAmountOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenarioInput>;
  buyMAmountGetXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenarioInput>;
  buyMAmountOfAGetBForXAmountScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetBForXAmountScenarioInput>;
  buyMAmountOfAGetXAmountOffOnBScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXAmountOffOnBScenarioInput>;
  buyMAmountOfAGetXPercentOffOnBScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountOfAGetXPercentOffOnBScenarioInput>;
  buyMQtyForXAmountScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenarioInput>;
  buyMQtyGetNForFreeScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenarioInput>;
  buyMQtyGetNQtyForXAmountScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenarioInput>;
  buyMQtyGetNQtyWithXAmountOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXAmountOffScenarioInput>;
  buyMQtyGetNQtyWithXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyWithXPercentOffScenarioInput>;
  buyMQtyGetXAmountOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenarioInput>;
  buyMQtyGetXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenarioInput>;
  buyMQtyOfAGetBForXAmountScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetBForXAmountScenarioInput>;
  buyMQtyOfAGetXAmountOffOnBScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXAmountOffOnBScenarioInput>;
  buyMQtyOfAGetXPercentOffOnBScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOfAGetXPercentOffOnBScenarioInput>;
  buyMQtyOrMoreGetXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenarioInput>;
  buyMRangeQtyGetXPercentOffTieredScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenarioInput>;
  conditionRewardString?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCategoryId?: InputMaybe<Scalars['BigInt']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  fundingDetail?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferFundingDetailInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferMetadataInput>;
  name?: InputMaybe<Scalars['String']>;
  offerConditions?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferConditionInput>>>;
  offerExternalReference?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['String']>;
  offerRequestKey?: InputMaybe<Scalars['String']>;
  offerRewards?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRewardInput>>>;
  offerSource?: InputMaybe<Scalars['String']>;
  offerStackability?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferStackability>;
  offerType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferType>;
  promoCode?: InputMaybe<Scalars['String']>;
  redemptionLimits?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimitsInput>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  terms?: InputMaybe<Scalars['String']>;
  uncategorizedScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenarioInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferMetadata = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferMetadata';
  termsAndConditions?: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferTermsAndConditions>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferMetadataInput = {
  termsAndConditions?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferTermsAndConditionsInput>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferCondition = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferOfferCondition';
  allowPartialApplication?: Maybe<Scalars['Boolean']>;
  collectionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  conditionProductIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  excludedCollectionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  maximumQuantity?: Maybe<Scalars['BigInt']>;
  minimumQuantity?: Maybe<Scalars['BigInt']>;
  minimumSpend?: Maybe<GoogleTypeMoney>;
  offerConditionType?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferConditionType>;
  serviceType?: Maybe<InstacartCustomersSharedV1ServiceType>;
  taxonomyNodeIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferConditionInput = {
  allowPartialApplication?: InputMaybe<Scalars['Boolean']>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  conditionProductIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedCollectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  maximumQuantity?: InputMaybe<Scalars['BigInt']>;
  minimumQuantity?: InputMaybe<Scalars['BigInt']>;
  minimumSpend?: InputMaybe<GoogleTypeMoneyInput>;
  offerConditionType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferConditionType>;
  serviceType?: InputMaybe<InstacartCustomersSharedV1ServiceType>;
  taxonomyNodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export enum InstacartCustomersCommerceTypesV1SlimOfferOfferConditionType {
  OfferConditionTypeBasket = 'OFFER_CONDITION_TYPE_BASKET',
  OfferConditionTypeBrands = 'OFFER_CONDITION_TYPE_BRANDS',
  OfferConditionTypeCategories = 'OFFER_CONDITION_TYPE_CATEGORIES',
  OfferConditionTypeCollections = 'OFFER_CONDITION_TYPE_COLLECTIONS',
  OfferConditionTypeCommerceClassifications = 'OFFER_CONDITION_TYPE_COMMERCE_CLASSIFICATIONS',
  OfferConditionTypeFees = 'OFFER_CONDITION_TYPE_FEES',
  OfferConditionTypeProducts = 'OFFER_CONDITION_TYPE_PRODUCTS',
  OfferConditionTypeRegions = 'OFFER_CONDITION_TYPE_REGIONS',
  OfferConditionTypeUnspecified = 'OFFER_CONDITION_TYPE_UNSPECIFIED'
}

export type InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimits = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimits';
  redemptionLimit?: Maybe<Scalars['BigInt']>;
  redemptionLimitPerOrder?: Maybe<Scalars['BigInt']>;
  redemptionLimitPerUser?: Maybe<Scalars['BigInt']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimitsInput = {
  redemptionLimit?: InputMaybe<Scalars['BigInt']>;
  redemptionLimitPerOrder?: InputMaybe<Scalars['BigInt']>;
  redemptionLimitPerUser?: InputMaybe<Scalars['BigInt']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferReward = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferOfferReward';
  amountDiscount?: Maybe<GoogleTypeMoney>;
  discountRewardType?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferDiscountRewardType>;
  includeConditionGroup?: Maybe<Scalars['Boolean']>;
  maximumDiscount?: Maybe<GoogleTypeMoney>;
  offerPrice?: Maybe<GoogleTypeMoney>;
  offerRewardType?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRewardType>;
  percentDiscount?: Maybe<InstacartTypesV1BigDecimal>;
  quantityLimit?: Maybe<Scalars['BigInt']>;
  rewardProductIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  serviceItemType?: Maybe<InstacartCustomersCommerceTypesV1SlimOfferServiceItemType>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferRewardInput = {
  amountDiscount?: InputMaybe<GoogleTypeMoneyInput>;
  discountRewardType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferDiscountRewardType>;
  includeConditionGroup?: InputMaybe<Scalars['Boolean']>;
  maximumDiscount?: InputMaybe<GoogleTypeMoneyInput>;
  offerPrice?: InputMaybe<GoogleTypeMoneyInput>;
  offerRewardType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRewardType>;
  percentDiscount?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  quantityLimit?: InputMaybe<Scalars['BigInt']>;
  rewardProductIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  serviceItemType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferServiceItemType>;
};

export enum InstacartCustomersCommerceTypesV1SlimOfferOfferRewardType {
  OfferRewardTypeBasket = 'OFFER_REWARD_TYPE_BASKET',
  OfferRewardTypeBrands = 'OFFER_REWARD_TYPE_BRANDS',
  OfferRewardTypeCategories = 'OFFER_REWARD_TYPE_CATEGORIES',
  OfferRewardTypeCollections = 'OFFER_REWARD_TYPE_COLLECTIONS',
  OfferRewardTypeProducts = 'OFFER_REWARD_TYPE_PRODUCTS',
  OfferRewardTypeServiceItem = 'OFFER_REWARD_TYPE_SERVICE_ITEM',
  OfferRewardTypeUnspecified = 'OFFER_REWARD_TYPE_UNSPECIFIED'
}

export enum InstacartCustomersCommerceTypesV1SlimOfferOfferStackability {
  AlwaysStackable = 'ALWAYS_STACKABLE',
  NonStackable = 'NON_STACKABLE',
  OfferStackabilityUnspecified = 'OFFER_STACKABILITY_UNSPECIFIED',
  Stackable = 'STACKABLE'
}

export enum InstacartCustomersCommerceTypesV1SlimOfferOfferType {
  AutoApply = 'AUTO_APPLY',
  CheckoutCodeBasedDiscount = 'CHECKOUT_CODE_BASED_DISCOUNT',
  Coupon = 'COUPON',
  OfferTypeUnspecified = 'OFFER_TYPE_UNSPECIFIED'
}

export enum InstacartCustomersCommerceTypesV1SlimOfferServiceItemType {
  DeliveryFee = 'DELIVERY_FEE',
  ServiceItemTypeUnspecified = 'SERVICE_ITEM_TYPE_UNSPECIFIED'
}

export type InstacartCustomersCommerceTypesV1SlimOfferTermsAndConditions = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferTermsAndConditions';
  locale?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferTermsAndConditionsInput = {
  locale?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenario';
  _?: Maybe<Scalars['Boolean']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenarioInput = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export enum InstacartCustomersSharedV1Locale {
  EnAu = 'EN_AU',
  EnCa = 'EN_CA',
  EnUs = 'EN_US',
  EsUs = 'ES_US',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  LocaleUnspecified = 'LOCALE_UNSPECIFIED'
}

export enum InstacartCustomersSharedV1OrderAttributeDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type InstacartCustomersSharedV1OrderAttributeInput = {
  direction?: InputMaybe<InstacartCustomersSharedV1OrderAttributeDirection>;
  name?: InputMaybe<Scalars['String']>;
};

export type InstacartCustomersSharedV1OrderInput = {
  attributes?: InputMaybe<Array<InputMaybe<InstacartCustomersSharedV1OrderAttributeInput>>>;
};

export type InstacartCustomersSharedV1PageInfo = {
  __typename?: 'InstacartCustomersSharedV1PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type InstacartCustomersSharedV1PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type InstacartCustomersSharedV1RequestContext = {
  __typename?: 'InstacartCustomersSharedV1RequestContext';
  clientConfigurationId?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['Int']>;
  storeConfigurationId?: Maybe<Scalars['Int']>;
};

export type InstacartCustomersSharedV1RequestContextInput = {
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export enum InstacartCustomersSharedV1ServiceType {
  Delivery = 'DELIVERY',
  Instore = 'INSTORE',
  Pickup = 'PICKUP',
  ScanAndPay = 'SCAN_AND_PAY',
  ServiceTypeUnspecified = 'SERVICE_TYPE_UNSPECIFIED',
  Virtual = 'VIRTUAL'
}

export type InstacartEnterpriseBobaCoreTypesV1AddressDashboardCreationParamsInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1AddressInformation = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1AddressInformation';
  city?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1CustomerInformation = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1CustomerInformation';
  customerEmail?: Maybe<Scalars['String']>;
  customerFirstName?: Maybe<Scalars['String']>;
  customerFullName?: Maybe<Scalars['String']>;
  customerLastName?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrder = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrder';
  addressInformation?: Maybe<InstacartEnterpriseBobaCoreTypesV1AddressInformation>;
  addressLatitude?: Maybe<Scalars['Float']>;
  addressLongitude?: Maybe<Scalars['Float']>;
  alcoholic?: Maybe<Scalars['Boolean']>;
  areDeliveryOrderPhotosExpired?: Maybe<Scalars['Boolean']>;
  cancellationOrigin?: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderCancellationOrigin>;
  cancellationReason?: Maybe<Scalars['String']>;
  carModelAndColor?: Maybe<Scalars['String']>;
  customerContext?: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderCustomerContext>;
  customerInformation?: Maybe<InstacartEnterpriseBobaCoreTypesV1CustomerInformation>;
  dashboardOptions?: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderOptions>;
  deliveryPhotos?: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderDeliveryPhoto>>>;
  displayId?: Maybe<Scalars['BigInt']>;
  estimatedDeliveryTime?: Maybe<Scalars['DateTime']>;
  estimatedPickupTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  formattedTipAmount?: Maybe<Scalars['String']>;
  fulfillmentDetails?: Maybe<InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails>;
  id?: Maybe<Scalars['BigInt']>;
  initialTip?: Maybe<Scalars['Float']>;
  isExpress?: Maybe<Scalars['Boolean']>;
  orderDeliveryId?: Maybe<Scalars['BigInt']>;
  orderHistoryEvents?: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderHistoryEvent>>>;
  previouslyRescheduled?: Maybe<Scalars['Boolean']>;
  retailerProvidedAmounts?: Maybe<InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmounts>;
  shopperAvatarUrl?: Maybe<Scalars['String']>;
  shopperFirstName?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['BigInt']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseLocationId?: Maybe<Scalars['BigInt']>;
  warehouseLocationName?: Maybe<Scalars['String']>;
  workflowState?: Maybe<Scalars['String']>;
};

export enum InstacartEnterpriseBobaCoreTypesV1DashboardOrderCancellationOrigin {
  CancellationOriginUnspecified = 'CANCELLATION_ORIGIN_UNSPECIFIED',
  Customer = 'CUSTOMER',
  Instacart = 'INSTACART',
  Other = 'OTHER',
  Retailer = 'RETAILER',
  Shopper = 'SHOPPER'
}

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderCustomerContext = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderCustomerContext';
  clientConfigurationId?: Maybe<Scalars['Int']>;
  countryId?: Maybe<Scalars['Int']>;
  storeConfigurationId?: Maybe<Scalars['Int']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderDeliveryPhoto = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderDeliveryPhoto';
  id?: Maybe<Scalars['BigInt']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderOptions = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderOptions';
  leaveUnattended?: Maybe<Scalars['Boolean']>;
  specialInstructions?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummary = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummary';
  bagCount?: Maybe<Scalars['Int']>;
  bagLabel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryWindow?: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryDeliveryWindow>;
  fulfillmentTimes?: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryFulfillmentTimes>;
  id?: Maybe<Scalars['BigInt']>;
  legacyOrderId?: Maybe<Scalars['BigInt']>;
  orderReference?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryOrderStatus>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  retailerReferenceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryDeliveryWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryDeliveryWindow';
  windowEndsAt?: Maybe<Scalars['DateTime']>;
  windowStartsAt?: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryFulfillmentTimes = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryFulfillmentTimes';
  shopperDeliveredAt?: Maybe<Scalars['DateTime']>;
  shopperDispatchedAt?: Maybe<Scalars['DateTime']>;
  shopperEstimatedDeliveredAt?: Maybe<Scalars['DateTime']>;
  shopperEstimatedPickedAt?: Maybe<Scalars['DateTime']>;
  shopperPickedAt?: Maybe<Scalars['DateTime']>;
  shopperStartedDeliveryAt?: Maybe<Scalars['DateTime']>;
};

export enum InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryOrderStatus {
  OrderCancelled = 'ORDER_CANCELLED',
  OrderComplete = 'ORDER_COMPLETE',
  OrderDelayed = 'ORDER_DELAYED',
  OrderEnRoute = 'ORDER_EN_ROUTE',
  OrderInProgress = 'ORDER_IN_PROGRESS',
  OrderPlaced = 'ORDER_PLACED',
  WaitingForDelivery = 'WAITING_FOR_DELIVERY'
}

export type InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails';
  addressId?: Maybe<Scalars['BigInt']>;
  fulfillmentType?: Maybe<Scalars['String']>;
  numOfBags?: Maybe<Scalars['BigInt']>;
  serviceOptionId?: Maybe<Scalars['BigInt']>;
  warehouseLocationId?: Maybe<Scalars['BigInt']>;
  windowEndsAt?: Maybe<Scalars['DateTime']>;
  windowStartsAt?: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ItemReplacement = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ItemReplacement';
  itemRrc?: Maybe<Scalars['String']>;
  itemUpc?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1LastMileOrderDashboardCreationParamsInput = {
  addressCreationParams?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1AddressDashboardCreationParamsInput>;
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  endAt?: InputMaybe<Scalars['String']>;
  externalData?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput>;
  initialTip?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  locationCode?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderOptionsInput>;
  retailerProvidedAmounts?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmountsInput>;
  serviceOptionId?: InputMaybe<Scalars['BigInt']>;
  serviceOptionReference?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
  userBirthday?: InputMaybe<Scalars['String']>;
  userCreationParams?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1UserDashboardCreationParamsInput>;
  userPhone?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ModificationEventInput = {
  reason?: InputMaybe<Scalars['String']>;
  reasonDetailed?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventUserType>;
};

export enum InstacartEnterpriseBobaCoreTypesV1ModificationEventUserType {
  Rpp = 'RPP',
  UserTypeUnspecified = 'USER_TYPE_UNSPECIFIED'
}

export type InstacartEnterpriseBobaCoreTypesV1Order = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1Order';
  canceledAt?: Maybe<Scalars['DateTime']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationType?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveredAt?: Maybe<Scalars['DateTime']>;
  deliveryEta?: Maybe<Scalars['DateTime']>;
  displayId?: Maybe<Scalars['BigInt']>;
  externalData?: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalData>;
  fulfillmentDetails?: Maybe<InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails>;
  id?: Maybe<Scalars['BigInt']>;
  isExpress?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  loyaltyCardId?: Maybe<Scalars['BigInt']>;
  orderDeliveryId?: Maybe<Scalars['BigInt']>;
  postCheckoutLink?: Maybe<Scalars['String']>;
  statusUrl?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
  warehouseId?: Maybe<Scalars['BigInt']>;
  warnings?: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderWarning>>>;
  workflowState?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderExternalData = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderExternalData';
  externalId?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput = {
  externalId?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderHistoryEvent = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderHistoryEvent';
  action?: Maybe<Scalars['String']>;
  actionedAt?: Maybe<Scalars['DateTime']>;
  windowEndsAt?: Maybe<Scalars['DateTime']>;
  windowStartsAt?: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItem = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItem';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalData?: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemExternalData>;
  id?: Maybe<Scalars['BigInt']>;
  isAddOnItem?: Maybe<Scalars['Boolean']>;
  itemCodes?: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemCodes>;
  itemId?: Maybe<Scalars['BigInt']>;
  itemProperties?: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemProperties>;
  orderId?: Maybe<Scalars['BigInt']>;
  pickedAt?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemQuantity>;
  replacementItem?: Maybe<InstacartEnterpriseBobaCoreTypesV1ItemReplacement>;
  replacementPolicy?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  substituteId?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemCodes = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemCodes';
  deliveredRrc?: Maybe<Scalars['String']>;
  deliveredUpc?: Maybe<Scalars['String']>;
  requestedRrc?: Maybe<Scalars['String']>;
  requestedUpc?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemExternalData = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemExternalData';
  itemRrc?: Maybe<Scalars['String']>;
  itemUpc?: Maybe<Scalars['String']>;
  lineNum?: Maybe<Scalars['String']>;
  scanCode?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemProperties = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemProperties';
  name?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['BigInt']>;
  productType?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemQuantity = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemQuantity';
  countDelivered?: Maybe<Scalars['Int']>;
  countOrdered?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  weightDelivered?: Maybe<Scalars['Float']>;
  weightOrdered?: Maybe<Scalars['Float']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderOptionsInput = {
  leaveUnattended?: InputMaybe<Scalars['Boolean']>;
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderWarning = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderWarning';
  errorCode?: Maybe<Scalars['Int']>;
  itemCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ReopenLastmileOrderParamsInput = {
  canceledExternalOrderId?: InputMaybe<Scalars['String']>;
  canceledOrderId?: InputMaybe<Scalars['BigInt']>;
  endAt?: InputMaybe<Scalars['String']>;
  externalData?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput>;
  serviceOptionId?: InputMaybe<Scalars['BigInt']>;
  serviceOptionReference?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1RescheduleOrderParamsInput = {
  endAt?: InputMaybe<Scalars['String']>;
  externalData?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  serviceOptionId?: InputMaybe<Scalars['BigInt']>;
  serviceOptionReference?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmounts = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmounts';
  bagLabel?: Maybe<Scalars['String']>;
  bagScanCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  bagsCount?: Maybe<Scalars['Int']>;
  cartTotal?: Maybe<Scalars['Float']>;
  itemsCount?: Maybe<Scalars['Int']>;
  itemsWeight?: Maybe<Scalars['Float']>;
};

export type InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmountsInput = {
  bagLabel?: InputMaybe<Scalars['String']>;
  bagScanCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  cartTotal?: InputMaybe<Scalars['Float']>;
  itemsCount?: InputMaybe<Scalars['Int']>;
  itemsWeight?: InputMaybe<Scalars['Float']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOption = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOption';
  asapWindow?: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionAsapWindow>;
  availability?: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionAvailability>;
  date?: Maybe<GoogleTypeDate>;
  id?: Maybe<Scalars['BigInt']>;
  immediateWindow?: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionImmediateWindow>;
  scheduledWindow?: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionScheduledWindow>;
  serviceOptionReference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseLocationId?: Maybe<Scalars['Int']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionAsapWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionAsapWindow';
  asap?: Maybe<Scalars['Boolean']>;
  descriptor?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionAvailability = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionAvailability';
  available?: Maybe<Scalars['Boolean']>;
  itemCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionImmediateWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionImmediateWindow';
  descriptor?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  immediateHour?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionScheduledWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionScheduledWindow';
  descriptor?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1UserDashboardCreationParamsInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  userPhone?: InputMaybe<Scalars['String']>;
};

export type InstacartStoreConfiguration = {
  __typename?: 'InstacartStoreConfiguration';
  /** Indicates if the Store Configuration is active or not */
  active: Scalars['Boolean'];
  /** Flag to indicate if this StoreConfiguration is associated to all warehouses */
  allowAllWarehouses: Scalars['Boolean'];
  /** The brand name as customers would see on Instacart, PBI, etc */
  brandedName: Scalars['String'];
  /** When the Store Configuration was created */
  createdAt: Scalars['Time'];
  /** Unata, etc... */
  deApplicationPlatform?: Maybe<Scalars['String']>;
  /** The domain name of the Storefront (eg. costco.ca) */
  domainName?: Maybe<Scalars['String']>;
  /** A unique identifier for the Store Configuration */
  id: Scalars['ID'];
  /** Legal data */
  legal?: Maybe<InstacartStoreConfigurationLegal>;
  /** The primary logo URL */
  logoUrl: Scalars['String'];
  /** The rules on how Partners can access this Store Configuration */
  partnerAccessSettings: InstacartStoreConfigurationPartnerAccessSettings;
  /** The unique slug for this Store Configuration */
  slug: Scalars['String'];
  /** When the Store Configuration was last updated */
  updatedAt: Scalars['Time'];
  /** Indicates if the user is permitted to modify data related to this Store Configuration */
  userAccessToModify: Scalars['Boolean'];
  /** The unique identifier for the User Isolation Context this Store Configuration uses */
  userIsolationContextId: Scalars['ID'];
  /** The list of associated warehouses */
  whitelistedWarehouseIds?: Maybe<Array<Scalars['ID']>>;
};

export type InstacartStoreConfigurationLegal = {
  __typename?: 'InstacartStoreConfigurationLegal';
  /** The shorter name for business entity */
  shortName: Scalars['String'];
};

export type InstacartStoreConfigurationModificationRules = {
  __typename?: 'InstacartStoreConfigurationModificationRules';
  /** The list of Partner IDs who are permitted to make modificaftions to this Store Configuration */
  allowlistPartnerIds?: Maybe<Array<Scalars['ID']>>;
};

export type InstacartStoreConfigurationPartnerAccessSettings = {
  __typename?: 'InstacartStoreConfigurationPartnerAccessSettings';
  /** The ruleset for allowing modifications for this Store Configuration */
  modificationRules?: Maybe<InstacartStoreConfigurationModificationRules>;
  /** True if Partners are allow to view this Store Configuration on IPP */
  visibleToPartnersOnIpp: Scalars['Boolean'];
};

export type InstacartTypesV1BigDecimal = {
  __typename?: 'InstacartTypesV1BigDecimal';
  nanos?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['BigInt']>;
};

export type InstacartTypesV1BigDecimalInput = {
  nanos?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['BigInt']>;
};

export type InstacartTypesV1MeasuredQuantityInput = {
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<InstacartTypesV1MeasurementUnitInput>;
};

export enum InstacartTypesV1MeasurementUnitCostUnit {
  CostUnitUnspecified = 'COST_UNIT_UNSPECIFIED',
  Each = 'EACH',
  FlOz = 'FL_OZ',
  Gallon = 'GALLON',
  Gram = 'GRAM',
  Kg = 'KG',
  Lb = 'LB',
  Liter = 'LITER',
  Ml = 'ML',
  Oz = 'OZ',
  Pint = 'PINT',
  Quart = 'QUART'
}

export type InstacartTypesV1MeasurementUnitInput = {
  costUnit?: InputMaybe<InstacartTypesV1MeasurementUnitCostUnit>;
  unitCode?: InputMaybe<Scalars['String']>;
};

export type InstacartTypesV1RequestContextInput = {
  applicationId?: InputMaybe<Scalars['Int']>;
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export type IppAccountInformationInput = {
  canonicalId?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type IroncladData = {
  __typename?: 'IroncladData';
  isaGroupKey?: Maybe<Scalars['String']>;
  ndaGroupKey?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  useIronclad?: Maybe<Scalars['Boolean']>;
};

export type Item = {
  __typename?: 'Item';
  comparison?: Maybe<InsightsPortalComparison>;
  dimensionName?: Maybe<Scalars['String']>;
  localeCode?: Maybe<Scalars['String']>;
  metricName?: Maybe<Scalars['String']>;
  primaryAisleId?: Maybe<Scalars['BigInt']>;
  primaryDepartmentId?: Maybe<Scalars['BigInt']>;
  primaryImageUrl?: Maybe<Scalars['String']>;
  productCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  productDisplayName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['BigInt']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerReferenceCode?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ItemId = {
  __typename?: 'ItemId';
  itemId?: Maybe<Scalars['BigInt']>;
};

export type ItemIdInput = {
  itemId?: InputMaybe<Scalars['BigInt']>;
};

export type ItemList = {
  __typename?: 'ItemList';
  cta?: Maybe<Scalars['String']>;
  ctaVisibilityMin?: Maybe<Scalars['BigInt']>;
  dataQuery?: Maybe<DataQuery>;
  dataQueryValue?: Maybe<Scalars['JSONObject']>;
  disclaimer?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  placementId?: Maybe<Scalars['BigInt']>;
  selectable?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<ItemListVariant>;
};

export type ItemListInput = {
  cta?: InputMaybe<Scalars['String']>;
  ctaVisibilityMin?: InputMaybe<Scalars['BigInt']>;
  dataQuery?: InputMaybe<DataQueryInput>;
  dataQueryValue?: InputMaybe<Scalars['JSONObject']>;
  disclaimer?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  selectable?: InputMaybe<Scalars['Boolean']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<ItemListVariant>;
};

export enum ItemListVariant {
  CarouselItemList = 'CAROUSEL_ITEM_LIST',
  CategoryForwardList = 'CATEGORY_FORWARD_LIST',
  CompactShoppable = 'COMPACT_SHOPPABLE',
  CouponList = 'COUPON_LIST',
  DoubleDeckerItemList = 'DOUBLE_DECKER_ITEM_LIST',
  FilterableItemList = 'FILTERABLE_ITEM_LIST',
  GridCouponList = 'GRID_COUPON_LIST',
  GridItemList = 'GRID_ITEM_LIST',
  ItemForwardList = 'ITEM_FORWARD_LIST',
  ItemList = 'ITEM_LIST',
  UnspecifiedItemList = 'UNSPECIFIED_ITEM_LIST'
}

export type ItemsSchema = {
  __typename?: 'ItemsSchema';
  promoPricingBackgroundColor?: Maybe<AtomsColor>;
  promoPricingTextColor?: Maybe<AtomsColor>;
};

export type KnowledgeOwlConfiguration = {
  __typename?: 'KnowledgeOwlConfiguration';
  knowledgeOwl: KnowledgeOwlType;
};

export type KnowledgeOwlType = {
  __typename?: 'KnowledgeOwlType';
  /** Knowledge Base Domain */
  domain: Scalars['String'];
  /** OAuth Token */
  oauthToken: Scalars['String'];
  /** Knowledge Base Project ID */
  projectId: Scalars['String'];
};

export type LandingSchema = {
  __typename?: 'LandingSchema';
  landingSkipPostalCode?: Maybe<Scalars['Boolean']>;
};

export type LaunchOnboarding = {
  __typename?: 'LaunchOnboarding';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByUserId?: Maybe<Scalars['BigInt']>;
  entityName?: Maybe<Scalars['String']>;
  expansionsOwnerUserId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  ippAccountUuid?: Maybe<Scalars['String']>;
  partner?: Maybe<LaunchOnboardingPartner>;
  tamOnboardingOwnerUserId?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  warehouse?: Maybe<LaunchOnboardingWarehouse>;
  warehouseLocations?: Maybe<Array<Maybe<LaunchOnboardingWarehouseLocation>>>;
  workflowState?: Maybe<Scalars['String']>;
};

export type LaunchOnboardingAddress = {
  __typename?: 'LaunchOnboardingAddress';
  apartmentNumber?: Maybe<Scalars['String']>;
  approvalRequests?: Maybe<Array<Maybe<LaunchOnboardingApprovalRequest>>>;
  approved?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type LaunchOnboardingAddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']>;
  approvalRequests?: InputMaybe<Array<InputMaybe<LaunchOnboardingApprovalRequestInput>>>;
  approved?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

export type LaunchOnboardingApprovalRequest = {
  __typename?: 'LaunchOnboardingApprovalRequest';
  approveableId?: Maybe<Scalars['BigInt']>;
  approveableType?: Maybe<Scalars['String']>;
  attributeName?: Maybe<Scalars['String']>;
  attributeValue?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type LaunchOnboardingApprovalRequestInput = {
  approveableId?: InputMaybe<Scalars['BigInt']>;
  approveableType?: InputMaybe<Scalars['String']>;
  attributeName?: InputMaybe<Scalars['String']>;
  attributeValue?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type LaunchOnboardingPartner = {
  __typename?: 'LaunchOnboardingPartner';
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  partnerType?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LaunchOnboardingWarehouse = {
  __typename?: 'LaunchOnboardingWarehouse';
  allowAlcohol?: Maybe<Scalars['Boolean']>;
  approvalRequests?: Maybe<Array<Maybe<LaunchOnboardingApprovalRequest>>>;
  approved?: Maybe<Scalars['Boolean']>;
  backgroundColor?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  certifiedDeliveryThreshold?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['BigInt']>;
  franchiseeId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  logo?: Maybe<Scalars['String']>;
  logoBackgroundColor?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pricingPolicy?: Maybe<Scalars['String']>;
  pricingPolicyText?: Maybe<Scalars['String']>;
  retailerType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  visible?: Maybe<Scalars['Boolean']>;
  warehouseLocations?: Maybe<Array<Maybe<LaunchOnboardingWarehouseLocation>>>;
};

export type LaunchOnboardingWarehouseLocation = {
  __typename?: 'LaunchOnboardingWarehouseLocation';
  address?: Maybe<LaunchOnboardingAddress>;
  approvalRequests?: Maybe<Array<Maybe<LaunchOnboardingApprovalRequest>>>;
  approvalRequestsStatus?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  inventoryAreaId?: Maybe<Scalars['BigInt']>;
  isochroneData?: Maybe<Scalars['String']>;
  locationCode?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum LaunchOnboardingWorkflowStateAction {
  LaunchOnboardingWorkflowStateActionCompleteInitialOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_COMPLETE_INITIAL_ONBOARDING',
  LaunchOnboardingWorkflowStateActionCompleteLaunchOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_COMPLETE_LAUNCH_ONBOARDING',
  LaunchOnboardingWorkflowStateActionCreateIppAccount = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_CREATE_IPP_ACCOUNT',
  LaunchOnboardingWorkflowStateActionLaunch = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_LAUNCH',
  LaunchOnboardingWorkflowStateActionProvideInitialWarehouseInfo = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_INITIAL_WAREHOUSE_INFO',
  LaunchOnboardingWorkflowStateActionProvideInitialWarehouseLocationsInfo = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_INITIAL_WAREHOUSE_LOCATIONS_INFO',
  LaunchOnboardingWorkflowStateActionSendEmailInvite = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_SEND_EMAIL_INVITE',
  LaunchOnboardingWorkflowStateActionStartInitialOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_START_INITIAL_ONBOARDING',
  LaunchOnboardingWorkflowStateActionStartLaunchOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_START_LAUNCH_ONBOARDING',
  LaunchOnboardingWorkflowStateActionUnknown = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_UNKNOWN'
}

export enum Layout {
  ActionSheet = 'ACTION_SHEET',
  BloomTargetStorefront = 'BLOOM_TARGET_STOREFRONT',
  BloomTargetStorefrontDealsLists = 'BLOOM_TARGET_STOREFRONT_DEALS_LISTS',
  BrowseDepartments = 'BROWSE_DEPARTMENTS',
  BrowseFeed = 'BROWSE_FEED',
  CampaignPlacement = 'CAMPAIGN_PLACEMENT',
  CartMessages = 'CART_MESSAGES',
  CartMessagesNonIncentives = 'CART_MESSAGES_NON_INCENTIVES',
  CartRecommendations = 'CART_RECOMMENDATIONS',
  CheckoutAisle = 'CHECKOUT_AISLE',
  CheckoutAisleNewUser = 'CHECKOUT_AISLE_NEW_USER',
  CollectionDepartment = 'COLLECTION_DEPARTMENT',
  CollectionHub = 'COLLECTION_HUB',
  CollectionHubOnLoad = 'COLLECTION_HUB_ON_LOAD',
  CreativeManager = 'CREATIVE_MANAGER',
  CrossRetailerSearchEyebrow = 'CROSS_RETAILER_SEARCH_EYEBROW',
  CustomStorefront = 'CUSTOM_STOREFRONT',
  DealsTabHero = 'DEALS_TAB_HERO',
  DealsTabSecondary = 'DEALS_TAB_SECONDARY',
  EntStorefront = 'ENT_STOREFRONT',
  FlyerDestination = 'FLYER_DESTINATION',
  FlyoutModal = 'FLYOUT_MODAL',
  Home = 'HOME',
  HomeCategoryForward = 'HOME_CATEGORY_FORWARD',
  HomeEyebrow = 'HOME_EYEBROW',
  HomeFeed = 'HOME_FEED',
  HomeFeedAlcohol = 'HOME_FEED_ALCOHOL',
  HomeFeedBackToSchool = 'HOME_FEED_BACK_TO_SCHOOL',
  HomeFeedBeauty = 'HOME_FEED_BEAUTY',
  HomeFeedBecauseYouBought = 'HOME_FEED_BECAUSE_YOU_BOUGHT',
  HomeFeedConvenience = 'HOME_FEED_CONVENIENCE',
  HomeFeedDefault = 'HOME_FEED_DEFAULT',
  HomeFeedGifts = 'HOME_FEED_GIFTS',
  HomeFeedGrocery = 'HOME_FEED_GROCERY',
  HomeFeedHeader = 'HOME_FEED_HEADER',
  HomeFeedNewUser = 'HOME_FEED_NEW_USER',
  HomeFeedOpenLate = 'HOME_FEED_OPEN_LATE',
  HomeFeedPets = 'HOME_FEED_PETS',
  HomeFeedPharmacy = 'HOME_FEED_PHARMACY',
  HomeFeedUseCaseTiles = 'HOME_FEED_USE_CASE_TILES',
  HomeFeedWholesale = 'HOME_FEED_WHOLESALE',
  HomeHero = 'HOME_HERO',
  HomeItemForward = 'HOME_ITEM_FORWARD',
  HomeMarketplace = 'HOME_MARKETPLACE',
  HomeOnLoad = 'HOME_ON_LOAD',
  HomeOnLoadIncentive = 'HOME_ON_LOAD_INCENTIVE',
  HomeSecondary = 'HOME_SECONDARY',
  HomeSuperSaver = 'HOME_SUPER_SAVER',
  Hub = 'HUB',
  HubHeader = 'HUB_HEADER',
  InStoreDeals = 'IN_STORE_DEALS',
  IsolatedStorefront = 'ISOLATED_STOREFRONT',
  ItemDetailsRecommendations = 'ITEM_DETAILS_RECOMMENDATIONS',
  ItemDetailsRecommendationsPostAtc = 'ITEM_DETAILS_RECOMMENDATIONS_POST_ATC',
  LandingContentPage = 'LANDING_CONTENT_PAGE',
  MealsTabOnStorefront = 'MEALS_TAB_ON_STOREFRONT',
  NewVerticalsStorefront = 'NEW_VERTICALS_STOREFRONT',
  OrderStatusRecommendations = 'ORDER_STATUS_RECOMMENDATIONS',
  OrderStatusSecondary = 'ORDER_STATUS_SECONDARY',
  PostCheckoutRecommendations = 'POST_CHECKOUT_RECOMMENDATIONS',
  PostCheckoutSheet = 'POST_CHECKOUT_SHEET',
  PostCheckoutSlot_1 = 'POST_CHECKOUT_SLOT_1',
  PromotionSheet = 'PROMOTION_SHEET',
  RecommendationsTray = 'RECOMMENDATIONS_TRAY',
  RetailerStories = 'RETAILER_STORIES',
  Search = 'SEARCH',
  ShopContentPage = 'SHOP_CONTENT_PAGE',
  Storefront = 'STOREFRONT',
  StorefrontCoachmark = 'STOREFRONT_COACHMARK',
  StorefrontEyebrow = 'STOREFRONT_EYEBROW',
  StorefrontHero = 'STOREFRONT_HERO',
  StorefrontNavigationAccount = 'STOREFRONT_NAVIGATION_ACCOUNT',
  StorefrontNavigationEmbeddedApp = 'STOREFRONT_NAVIGATION_EMBEDDED_APP',
  StorefrontNavigationFooter = 'STOREFRONT_NAVIGATION_FOOTER',
  StorefrontNavigationGrid = 'STOREFRONT_NAVIGATION_GRID',
  StorefrontNavigationHeader = 'STOREFRONT_NAVIGATION_HEADER',
  StorefrontNavigationItem = 'STOREFRONT_NAVIGATION_ITEM',
  StorefrontNavigationNativeTabbar = 'STOREFRONT_NAVIGATION_NATIVE_TABBAR',
  StorefrontNavigationSocialIcons = 'STOREFRONT_NAVIGATION_SOCIAL_ICONS',
  StorefrontNavigationV2 = 'STOREFRONT_NAVIGATION_V2',
  StorefrontNavigationWarehouseSelector = 'STOREFRONT_NAVIGATION_WAREHOUSE_SELECTOR',
  StorefrontNewUser = 'STOREFRONT_NEW_USER',
  StorefrontOnLoad = 'STOREFRONT_ON_LOAD',
  StorefrontOverHeader = 'STOREFRONT_OVER_HEADER',
  StorefrontSuperSaver = 'STOREFRONT_SUPER_SAVER',
  TreatmentOffersForYou = 'TREATMENT_OFFERS_FOR_YOU',
  TreatmentOffersForYouNonIncentive = 'TREATMENT_OFFERS_FOR_YOU_NON_INCENTIVE',
  TreatmentSheet = 'TREATMENT_SHEET',
  TreatmentTracker = 'TREATMENT_TRACKER',
  TreatmentTrackerNonIncentive = 'TREATMENT_TRACKER_NON_INCENTIVE',
  UniversalValueCarousel = 'UNIVERSAL_VALUE_CAROUSEL',
  UniversalValueCarouselIncentive = 'UNIVERSAL_VALUE_CAROUSEL_INCENTIVE',
  UniversalValueCarouselNonIncentive = 'UNIVERSAL_VALUE_CAROUSEL_NON_INCENTIVE',
  YourItems = 'YOUR_ITEMS'
}

export type LayoutVariant = {
  __typename?: 'LayoutVariant';
  contentPageId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  placements?: Maybe<Array<Maybe<Placement>>>;
  platform?: Maybe<Array<Maybe<PlatformType>>>;
  previewToken?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Status>;
};

export type LayoutVariantInput = {
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  placements?: InputMaybe<Array<InputMaybe<PlacementInput>>>;
  platform?: InputMaybe<Array<InputMaybe<PlatformType>>>;
  previewToken?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Status>;
};

export type LegacyBaseImage = {
  __typename?: 'LegacyBaseImage';
  /** The image url */
  url?: Maybe<Scalars['String']>;
};

export type LegacyCountry = {
  __typename?: 'LegacyCountry';
  /** Unique identifier */
  id: Scalars['ID'];
  /** country name */
  name: Scalars['String'];
};

export type LegacyDeliveryHours = {
  __typename?: 'LegacyDeliveryHours';
  /** Date of Delivery Hours */
  date: Scalars['LegacyDate'];
  /** Ending hour of Delivery Hours on specified date */
  endsAtHour: Scalars['Int'];
  /** Starting hour of Delivery Hours on specified date */
  startsAtHour: Scalars['Int'];
  /** The associated warehouse of the delivery hours */
  warehouse: LegacyWarehouse;
};

export type LegacyElasticsearchResponse = {
  __typename?: 'LegacyElasticsearchResponse';
  docs?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
};

export type LegacyHealthCheck = {
  __typename?: 'LegacyHealthCheck';
  /** Server health status */
  ok: Scalars['Boolean'];
};

export type LegacyItemTyped = {
  __typename?: 'LegacyItemTyped';
  aisleId?: Maybe<Scalars['BigInt']>;
  aisleName?: Maybe<Scalars['String']>;
  alcoholic?: Maybe<Scalars['Boolean']>;
  allergensKeywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  available?: Maybe<Scalars['Boolean']>;
  brandName?: Maybe<Scalars['String']>;
  configurableProducts?: Maybe<Scalars['JSONObject']>;
  costPricePerUnit?: Maybe<InstacartTypesV1BigDecimal>;
  costUnit?: Maybe<Scalars['String']>;
  dairyFree?: Maybe<Scalars['Boolean']>;
  departmentId?: Maybe<Scalars['BigInt']>;
  departmentName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fullPrice?: Maybe<InstacartTypesV1BigDecimal>;
  halal?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  inStoreLocation?: Maybe<Scalars['String']>;
  inventoryAreaId?: Maybe<Scalars['BigInt']>;
  isCatering?: Maybe<Scalars['Boolean']>;
  isHot?: Maybe<Scalars['Boolean']>;
  isPackagedByWeight?: Maybe<Scalars['Boolean']>;
  ketoDiet?: Maybe<Scalars['Boolean']>;
  largeImageUrl?: Maybe<Scalars['String']>;
  localeCode?: Maybe<Scalars['String']>;
  lowSodium?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nonenumeratedManagedAttributes?: Maybe<NonenumeratedManagedAttributes>;
  normalizedHeightCm?: Maybe<InstacartTypesV1BigDecimal>;
  normalizedLengthCm?: Maybe<InstacartTypesV1BigDecimal>;
  normalizedWeightG?: Maybe<InstacartTypesV1BigDecimal>;
  normalizedWidthCm?: Maybe<InstacartTypesV1BigDecimal>;
  parWeight?: Maybe<InstacartTypesV1BigDecimal>;
  price?: Maybe<InstacartTypesV1BigDecimal>;
  pricePerUnit?: Maybe<InstacartTypesV1BigDecimal>;
  primaryImageUrl?: Maybe<Scalars['String']>;
  productCategory?: Maybe<ProductCategory>;
  productCategoryTaxonomy?: Maybe<ProductCategoryTaxonomy>;
  productCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  productEnrichmentKeywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  productId?: Maybe<Scalars['BigInt']>;
  productType?: Maybe<Scalars['String']>;
  requestUnit?: Maybe<LegacyItemTypedRequestUnit>;
  restrictedOtc?: Maybe<Scalars['Boolean']>;
  restrictedReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  retailerLookupCode?: Maybe<Scalars['String']>;
  retailerMetadata?: Maybe<LegacyItemTypedLegacyRetailerMetadataTyped>;
  retailerOmsEligible?: Maybe<Scalars['Boolean']>;
  retailerProductSize?: Maybe<Scalars['String']>;
  retailerReferenceCode?: Maybe<Scalars['String']>;
  retailerSpecificMetadata?: Maybe<LegacyItemTypedLegacyRetailerSpecificMetadataTyped>;
  shopperHeroImageUrl?: Maybe<Scalars['String']>;
  shopperHeroLargeImageUrl?: Maybe<Scalars['String']>;
  shouldScan?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['String']>;
  sizeUomVariable?: Maybe<Scalars['String']>;
  storageTemp?: Maybe<Scalars['String']>;
  sugarFree?: Maybe<Scalars['Boolean']>;
  suggestedReplacementIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  syntheticCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  tobacco?: Maybe<Scalars['Boolean']>;
  unitCount?: Maybe<Scalars['BigInt']>;
  unitPrice?: Maybe<InstacartTypesV1BigDecimal>;
  unlisted?: Maybe<Scalars['Boolean']>;
  usaSnapEligible?: Maybe<Scalars['Boolean']>;
  variantDimensionValues?: Maybe<Array<Maybe<LegacyItemTypedLegacyVariantDimensionValueTyped>>>;
  warehouseId?: Maybe<Scalars['BigInt']>;
};

export type LegacyItemTypedLegacyRetailerMetadataTyped = {
  __typename?: 'LegacyItemTypedLegacyRetailerMetadataTyped';
  aldiProductCode?: Maybe<Scalars['String']>;
};

export type LegacyItemTypedLegacyRetailerSpecificMetadataTyped = {
  __typename?: 'LegacyItemTypedLegacyRetailerSpecificMetadataTyped';
  aldiProductCode?: Maybe<Scalars['String']>;
};

export type LegacyItemTypedLegacyVariantDimensionValueTyped = {
  __typename?: 'LegacyItemTypedLegacyVariantDimensionValueTyped';
  heading?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum LegacyItemTypedRequestUnit {
  Each = 'EACH',
  Kg = 'KG',
  Lb = 'LB',
  Package = 'PACKAGE',
  RequestUnitUnspecified = 'REQUEST_UNIT_UNSPECIFIED'
}

export type LegacyItemsTyped = {
  __typename?: 'LegacyItemsTyped';
  items?: Maybe<Array<Maybe<LegacyItemTyped>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type LegacyPartner = {
  __typename?: 'LegacyPartner';
  /** The Partner's unique identifier  */
  id: Scalars['ID'];
  /** The name of the Partner */
  name: Scalars['String'];
  /** The associated Warehouses */
  warehouses: Array<LegacyWarehouse>;
};

export type LegacyPartnerUser = {
  __typename?: 'LegacyPartnerUser';
  /** The user's given name */
  firstName: Scalars['String'];
};

export type LegacyProducts = {
  __typename?: 'LegacyProducts';
  items?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
  totalPages?: Maybe<Scalars['BigInt']>;
};

export type LegacyWarehouse = {
  __typename?: 'LegacyWarehouse';
  /** Country of Warehouse */
  country?: Maybe<LegacyCountry>;
  /** Warehouse early picking hour [if one is set] */
  earlyPickingHour?: Maybe<Scalars['Int']>;
  /** The Warehouse's unique identifier */
  id: Scalars['ID'];
  /** The Warehouses' logo */
  logo?: Maybe<LegacyWarehouseLogo>;
  /** The name of the Warehouse */
  name?: Maybe<Scalars['String']>;
  /** The associated Partner */
  partner?: Maybe<LegacyPartner>;
};

export type LegacyWarehouseLocation = {
  __typename?: 'LegacyWarehouseLocation';
  /** The delivery hours of the Warehouse Location */
  deliveryHours: Array<LegacyDeliveryHours>;
  /** The unique ID */
  id: Scalars['ID'];
};


export type LegacyWarehouseLocationDeliveryHoursArgs = {
  dates: Array<Scalars['LegacyDate']>;
};

export type LegacyWarehouseLogo = {
  __typename?: 'LegacyWarehouseLogo';
  /** A header-specific version of the logo */
  header?: Maybe<LegacyBaseImage>;
  /** A tiny version of the logo */
  tiny?: Maybe<LegacyBaseImage>;
  /** The image url */
  url?: Maybe<Scalars['String']>;
  /** The whitelabeled logo */
  whiteLabelLandingPage?: Maybe<LegacyBaseImage>;
};

export enum LegalFilterType {
  FullyFiltered = 'FULLY_FILTERED',
  LegalFilterTypeUnknown = 'LEGAL_FILTER_TYPE_UNKNOWN',
  NotFiltered = 'NOT_FILTERED',
  PartiallyFiltered = 'PARTIALLY_FILTERED'
}

export type LinkAutosuggestion = {
  __typename?: 'LinkAutosuggestion';
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  linkDescription?: Maybe<Scalars['String']>;
  linkId?: Maybe<Scalars['BigInt']>;
  linkImageUrl?: Maybe<Scalars['String']>;
  linkName?: Maybe<Scalars['String']>;
  linkTitle?: Maybe<Scalars['String']>;
  linkType?: Maybe<LinkAutosuggestionLinkType>;
  linkUrl?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type LinkAutosuggestionAdmin = {
  __typename?: 'LinkAutosuggestionAdmin';
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkAutosuggestion?: Maybe<LinkAutosuggestion>;
};

export type LinkAutosuggestionAdminInput = {
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkAutosuggestion?: InputMaybe<LinkAutosuggestionInput>;
};

export type LinkAutosuggestionInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  linkDescription?: InputMaybe<Scalars['String']>;
  linkId?: InputMaybe<Scalars['BigInt']>;
  linkImageUrl?: InputMaybe<Scalars['String']>;
  linkName?: InputMaybe<Scalars['String']>;
  linkTitle?: InputMaybe<Scalars['String']>;
  linkType?: InputMaybe<LinkAutosuggestionLinkType>;
  linkUrl?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export enum LinkAutosuggestionLinkType {
  LinkTypeHard = 'LINK_TYPE_HARD',
  LinkTypeSoft = 'LINK_TYPE_SOFT',
  LinkTypeUnknown = 'LINK_TYPE_UNKNOWN'
}

export type ListAuditBatchesRequestInput = {
  batchMetadata?: InputMaybe<Scalars['String']>;
  batchSecondaryStateIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  batchStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page?: InputMaybe<PaginationInput>;
  showReassignmentMappings?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  workflows?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListAuditBatchesResponse = {
  __typename?: 'ListAuditBatchesResponse';
  auditBatches?: Maybe<Array<Maybe<AuditBatch>>>;
  totalResultCount?: Maybe<Scalars['BigInt']>;
  workflowReassignmentMappings?: Maybe<Array<Maybe<ListAuditBatchesResponseWorkflowReassignmentMapping>>>;
};

export type ListAuditBatchesResponseWorkflowReassignmentMapping = {
  __typename?: 'ListAuditBatchesResponseWorkflowReassignmentMapping';
  fromWorkflow?: Maybe<Scalars['String']>;
  toWorkflows?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ListAuditWorkflowsRequestInput = {
  batchStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  returnTaskInformation?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  workflows?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListAuditWorkflowsResponse = {
  __typename?: 'ListAuditWorkflowsResponse';
  auditWorkflows?: Maybe<Array<Maybe<AuditWorkflow>>>;
};

export type ListContentPageVersionMetasRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
};

export type ListContentPageVersionMetasResponse = {
  __typename?: 'ListContentPageVersionMetasResponse';
  contentPageVersionMetas?: Maybe<Array<Maybe<ContentPageVersionMeta>>>;
};

export type ListContentPageVersionsRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type ListContentPageVersionsResponse = {
  __typename?: 'ListContentPageVersionsResponse';
  contentPageVersions?: Maybe<Array<Maybe<ContentPageVersion>>>;
};

export type ListContentPagesRequestV2Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  pageType?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type ListContentPagesResponseV2 = {
  __typename?: 'ListContentPagesResponseV2';
  contentPages?: Maybe<Array<Maybe<ContentPageV2>>>;
};

export type ListDetectionsRequestFiltersInput = {
  hideRejectedVersions?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
};

export type ListDetectionsRequestInput = {
  filters?: InputMaybe<ListDetectionsRequestFiltersInput>;
  page?: InputMaybe<PaginationInput>;
};

export type ListDetectionsResponse = {
  __typename?: 'ListDetectionsResponse';
  detections?: Maybe<Array<Maybe<ListDetectionsResponseDetection>>>;
  totalResultCount?: Maybe<Scalars['BigInt']>;
};

export type ListDetectionsResponseDetection = {
  __typename?: 'ListDetectionsResponseDetection';
  createdAt?: Maybe<Scalars['DateTime']>;
  currentAttributes?: Maybe<DetectionVersionAttributes>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<DetectionStatusState>;
  tombstone?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedWhy?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['BigInt']>;
};

export type ListLinkAutosuggestionAdminParametersInput = {
  keyword?: InputMaybe<Scalars['String']>;
  linkIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  linkNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListLinkAutosuggestionAdminRequestInput = {
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
  parameters?: InputMaybe<ListLinkAutosuggestionAdminParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type ListLinkAutosuggestionAdminResponse = {
  __typename?: 'ListLinkAutosuggestionAdminResponse';
  linkAutosuggestions?: Maybe<Array<Maybe<LinkAutosuggestionAdmin>>>;
  pageInfo?: Maybe<InstacartCustomersSharedV1PageInfo>;
};

export type ListMomentsRequestFilterSetInput = {
  uiStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListMomentsRequestInput = {
  filters?: InputMaybe<ListMomentsRequestFilterSetInput>;
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
};

export type ListMomentsResponse = {
  __typename?: 'ListMomentsResponse';
  moments?: Maybe<Array<Maybe<Moment>>>;
  pageInfo?: Maybe<InstacartCustomersSharedV1PageInfo>;
};

export type ListPlacementContentParamsInput = {
  campaignEndsAfter?: InputMaybe<Scalars['DateTime']>;
  campaignEndsBefore?: InputMaybe<Scalars['DateTime']>;
  campaignStartsAfter?: InputMaybe<Scalars['DateTime']>;
  campaignStartsBefore?: InputMaybe<Scalars['DateTime']>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  createdAfter?: InputMaybe<Scalars['DateTime']>;
  createdBefore?: InputMaybe<Scalars['DateTime']>;
  enabledOnly?: InputMaybe<Scalars['Boolean']>;
  excludedLayoutVariants?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedLayouts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludedNamesRegex?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludedPlatforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludedRetailers?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  experimentNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includedLayoutVariants?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includedLayouts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includedPlatforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includedRetailers?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  internalNameRegex?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pagination?: InputMaybe<PlacementContentRequestPaginationInput>;
  parentPlacementId?: InputMaybe<Scalars['BigInt']>;
  placementIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  placementSurface?: InputMaybe<PlacementContentSurfaceInput>;
  placementTypeVariants?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placementTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListPlacementContentRequestInput = {
  excludingLocales?: InputMaybe<InstacartCustomersSharedV1Locale>;
  params?: InputMaybe<ListPlacementContentParamsInput>;
};

export type ListPlacementContentResponse = {
  __typename?: 'ListPlacementContentResponse';
  contents?: Maybe<Array<Maybe<PlacementContent>>>;
  pagination?: Maybe<PlacementContentResponsePagination>;
};

export type ListRetailerCampaignTreatmentsRequestInput = {
  retailerCampaignIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListRetailerCampaignTreatmentsResponse = {
  __typename?: 'ListRetailerCampaignTreatmentsResponse';
  treatments?: Maybe<Array<Maybe<RetailerCampaignTreatment>>>;
};

export type ListRetailerCampaignsRequestFilterSetInput = {
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignReference?: InputMaybe<CampaignReferenceInput>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  excludeRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  filters?: InputMaybe<Array<InputMaybe<CampaignFilterInput>>>;
  regions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  uiStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListRetailerCampaignsRequestInput = {
  filters?: InputMaybe<ListRetailerCampaignsRequestFilterSetInput>;
  includeFields?: InputMaybe<Array<InputMaybe<CampaignField>>>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListRetailerCampaignsResponse = {
  __typename?: 'ListRetailerCampaignsResponse';
  pageInfo?: Maybe<InstacartCustomersSharedV1PageInfo>;
  retailerCampaigns?: Maybe<Array<Maybe<RetailerCampaign>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type ListThemePagesRequestInput = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  includeSections?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type ListThemePagesResponse = {
  __typename?: 'ListThemePagesResponse';
  themePages?: Maybe<Array<Maybe<ThemePage>>>;
};

export type ListTicketsRequestInput = {
  associatedUser?: InputMaybe<UserInput>;
  client?: InputMaybe<ClientInput>;
  createdBy?: InputMaybe<UserInput>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  entityType?: InputMaybe<EntityType>;
  getSummaryStatus?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<PaginationInput>;
  problemType?: InputMaybe<ProblemType>;
  reportedOnBehalfOf?: InputMaybe<UserInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  severity?: InputMaybe<Scalars['BigInt']>;
  sourceAppNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type ListTicketsResponse = {
  __typename?: 'ListTicketsResponse';
  includesOnBehalfOf?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Pagination>;
  tickets?: Maybe<Array<Maybe<ProblemTrackerTicket>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type ListUserSettingsRequestInput = {
  appNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<ClientInput>;
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListUserSettingsResponse = {
  __typename?: 'ListUserSettingsResponse';
  settings?: Maybe<Array<Maybe<UserSetting>>>;
};

export type ListUsersByPersonalIdentifiersStrictRequestInput = {
  firstName?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
};

export type ListUsersByPersonalIdentifiersStrictResponse = {
  __typename?: 'ListUsersByPersonalIdentifiersStrictResponse';
  pageInfo?: Maybe<InstacartCustomersSharedV1PageInfo>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type LocaleProduct = {
  __typename?: 'LocaleProduct';
  localeId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type LocaleRetailerProduct = {
  __typename?: 'LocaleRetailerProduct';
  localeId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['String']>;
};

export enum LocationFeatureEnum {
  /** Indicator whether retailer does alcohol delivery */
  RetailerAlcoholDelivery = 'RETAILER_ALCOHOL_DELIVERY',
  /** Indicator of whether retailer location does pickup */
  RetailerPickup = 'RETAILER_PICKUP'
}

export type Logo = {
  __typename?: 'Logo';
  /** full url of the image */
  url?: Maybe<Scalars['String']>;
};

export type LogoWithTextBanner = {
  __typename?: 'LogoWithTextBanner';
  backgroundColorHex?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  id?: Maybe<Scalars['BigInt']>;
  imageAltText?: Maybe<Scalars['String']>;
  imageMobileUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type LogoWithTextBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  imageMobileUrl?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type Logos = {
  __typename?: 'Logos';
  defaultSiteLogoImageUrl?: Maybe<Scalars['String']>;
  mobileBackgroundUrl?: Maybe<Scalars['String']>;
  mobileLogoUrl?: Maybe<Scalars['String']>;
  showPoweredByBranding?: Maybe<Scalars['Boolean']>;
};

export type LoyaltySchema = {
  __typename?: 'LoyaltySchema';
  showLoyaltyActionsInList?: Maybe<Scalars['Boolean']>;
};

export type ManagedAttributeValue = {
  __typename?: 'ManagedAttributeValue';
  valueDisplayName?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['BigInt']>;
};

export type MarketingFeatureTypesBrazeSegment = {
  __typename?: 'MarketingFeatureTypesBrazeSegment';
  /** Warehouse ids the segment is visible by */
  accessibleWarehouseIds: Array<Scalars['Int']>;
  /** A unique identifier for the braze segment. For Braze's API */
  brazeSegmentId: Scalars['String'];
  /** The role ID */
  id: Scalars['ID'];
  /** The visible name of the segment */
  segmentName: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type MarketingFeatureTypesBrazeSegmentSize = {
  __typename?: 'MarketingFeatureTypesBrazeSegmentSize';
  /** The size of the segment */
  size: Scalars['Int'];
};

export type MarketingFeatureTypesCampaignConfiguration = {
  __typename?: 'MarketingFeatureTypesCampaignConfiguration';
  /** The id of the campaign returned from the campaign domain */
  campaignId: Scalars['ID'];
  /** The name of the experiment to run the campaign for */
  experimentName?: Maybe<Scalars['String']>;
  /** The push_notification id of the push notification template returned */
  pushNotificationId?: Maybe<Scalars['String']>;
  /** A unique identifier for the segment. Currently for Braze's API */
  segmentId: Scalars['String'];
  /** The external id of the template returned */
  templateId?: Maybe<Scalars['String']>;
  /** The warehouse id to run the campaign for */
  warehouseId: Scalars['ID'];
};

export type MarketingFeatureTypesCampaignStatus = {
  __typename?: 'MarketingFeatureTypesCampaignStatus';
  /** The name of the associated experiment */
  experimentName?: Maybe<Scalars['String']>;
  /** The id of the braze template */
  failedUserCount?: Maybe<Scalars['Int']>;
  /** The id of the braze template */
  pendingUserCount?: Maybe<Scalars['Int']>;
  /** The id of the associated push notification */
  pushNotificationId?: Maybe<Scalars['ID']>;
  /** The send types of the campaign */
  sendTypes?: Maybe<Array<Send>>;
  /** The id of the push notification */
  sentUserCount?: Maybe<Scalars['Int']>;
  /** The internal id of the template */
  status: CampaignProgress;
  /** The id of the associated template */
  templateId?: Maybe<Scalars['ID']>;
  /** The name of the template */
  totalUserCount: Scalars['Int'];
};

export type MarketingFeatureTypesEmailTemplatePreview = {
  __typename?: 'MarketingFeatureTypesEmailTemplatePreview';
  /** The full html preview of the email body */
  body: Scalars['String'];
  /** the preheader of the email */
  preheader?: Maybe<Scalars['String']>;
  /** The subject of the email */
  subject?: Maybe<Scalars['String']>;
  /** The external id of the template returned */
  templateId?: Maybe<Scalars['String']>;
  /** The name of the template */
  templateName?: Maybe<Scalars['String']>;
  /** Variables used in the template */
  variables?: Maybe<Array<Scalars['String']>>;
};

export type MarketingFeatureTypesPushTemplatePreview = {
  __typename?: 'MarketingFeatureTypesPushTemplatePreview';
  /** The body of the push notification returned */
  body?: Maybe<Scalars['String']>;
  /** The id of the push notification returned */
  id: Scalars['String'];
  /** The title of the push notification returned */
  title?: Maybe<Scalars['String']>;
};

export type MarketingFeatureTypesTemplate = {
  __typename?: 'MarketingFeatureTypesTemplate';
  /** The id of the braze template */
  brazeTemplateId?: Maybe<Scalars['String']>;
  /** The name of the template */
  emailTemplateName: Scalars['String'];
  /** The internal id of the template */
  id: Scalars['ID'];
  /** Preview of the template */
  preview?: Maybe<MarketingFeatureTypesTemplatePreview>;
  /** The id of the push notification */
  pushNotificationId?: Maybe<Scalars['String']>;
};


export type MarketingFeatureTypesTemplatePreviewArgs = {
  useCache?: InputMaybe<Scalars['Boolean']>;
  variables?: InputMaybe<TemplateVariablesInput>;
};

export type MarketingFeatureTypesTemplatePreview = {
  __typename?: 'MarketingFeatureTypesTemplatePreview';
  email?: Maybe<MarketingFeatureTypesEmailTemplatePreview>;
  push?: Maybe<MarketingFeatureTypesPushTemplatePreview>;
};

export type MarketingMessagingDetailsInput = {
  experimentName?: InputMaybe<Scalars['String']>;
  includeExperiment?: InputMaybe<Scalars['Boolean']>;
  pushNotificationId?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateVariables?: InputMaybe<Scalars['JSONObject']>;
  userEmailOverrides?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MatchesAnyRetailerIdInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of retailer ids */
  retailerIds: Array<Scalars['Int']>;
};

/** Autogenerated return type of MatchesAnyRetailerIdVisibilityConditionCreate */
export type MatchesAnyRetailerIdVisibilityConditionCreatePayload = {
  __typename?: 'MatchesAnyRetailerIdVisibilityConditionCreatePayload';
  /** The newly created MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityCondition: ContentManagementVisibilityConditionMatchesAnyRetailerId;
};

/** Autogenerated return type of MatchesAnyRetailerIdVisibilityConditionUpdate */
export type MatchesAnyRetailerIdVisibilityConditionUpdatePayload = {
  __typename?: 'MatchesAnyRetailerIdVisibilityConditionUpdatePayload';
  /** The newly updated MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityCondition: ContentManagementVisibilityConditionMatchesAnyRetailerId;
};

export enum MergeableReportTypeEnum {
  /** Retailer Tax Logs */
  RetailerTaxLogs = 'RETAILER_TAX_LOGS',
  /** Shopper Reimbursements Monthly */
  ShopperReimbursementsMonthly = 'SHOPPER_REIMBURSEMENTS_MONTHLY',
  /** Taxes Paid at POS */
  TaxesPaidAtPos = 'TAXES_PAID_AT_POS'
}

export enum Metric {
  Orders = 'ORDERS',
  OrderPerStore = 'ORDER_PER_STORE',
  Sales = 'SALES',
  SalesPerStore = 'SALES_PER_STORE',
  Stores = 'STORES'
}

export type MetricThresholdInput = {
  metricType?: InputMaybe<MetricType>;
  threshold?: InputMaybe<Scalars['BigInt']>;
};

export enum MetricType {
  DedicatedMatchRate = 'DEDICATED_MATCH_RATE',
  DedicatedUtilizationRate = 'DEDICATED_UTILIZATION_RATE',
  Default = 'DEFAULT',
  FiveHourAvailabilityRate = 'FIVE_HOUR_AVAILABILITY_RATE',
  FulfilledOrders = 'FULFILLED_ORDERS',
  MatchRate = 'MATCH_RATE',
  RecommendedHours = 'RECOMMENDED_HOURS',
  ScheduledGaps = 'SCHEDULED_GAPS',
  ScheduledHours = 'SCHEDULED_HOURS',
  StaffingRate = 'STAFFING_RATE',
  UtilizationRate = 'UTILIZATION_RATE'
}

export type MinimumPlatformVersionInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: InputMaybe<Scalars['ID']>;
  /** The platform the version is being checked for */
  platform: PlacementPlatformEnum;
  /** Version used for the check */
  version: Scalars['String'];
};

/** Autogenerated return type of MinimumPlatformVersionVisibilityConditionCreate */
export type MinimumPlatformVersionVisibilityConditionCreatePayload = {
  __typename?: 'MinimumPlatformVersionVisibilityConditionCreatePayload';
  /** The newly created minimum_platform_version visibility condition */
  minimumPlatformVersionVisibilityCondition: ContentManagementVisibilityConditionMinimumPlatformVersion;
};

/** Autogenerated return type of MinimumPlatformVersionVisibilityConditionUpdate */
export type MinimumPlatformVersionVisibilityConditionUpdatePayload = {
  __typename?: 'MinimumPlatformVersionVisibilityConditionUpdatePayload';
  /** The newly created minimum_platform_version visibility condition */
  minimumPlatformVersionVisibilityCondition: ContentManagementVisibilityConditionMinimumPlatformVersion;
};

export type Moment = {
  __typename?: 'Moment';
  campaigns?: Maybe<Array<Maybe<RetailerCampaign>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  momentCampaignId?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  submissionDeadline?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MomentCampaignStatusInput = {
  campaignId?: InputMaybe<Scalars['BigInt']>;
  momentStatus?: InputMaybe<Array<InputMaybe<StatusMapInput>>>;
};

export type MoveRetailerAssetsFromFilestackBucketRequestInput = {
  assetType?: InputMaybe<AssetType>;
  isPublicAccessible?: InputMaybe<Scalars['Boolean']>;
  objectKeyMappings?: InputMaybe<Array<InputMaybe<ObjectKeyMappingInput>>>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type MoveRetailerAssetsFromFilestackBucketResponse = {
  __typename?: 'MoveRetailerAssetsFromFilestackBucketResponse';
  assetUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutableS3Asset = {
  __typename?: 'MutableS3Asset';
  /** The s3 file exists */
  exists: Scalars['Boolean'];
  /** The idenfitier for the asset */
  id: Scalars['String'];
  /** The s3 read url for getting the asset */
  s3ReadUrl?: Maybe<Scalars['String']>;
  /** The s3 upload url for putting the asset */
  s3WriteUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  apiIntegrationsServiceDeleteApiSetting?: Maybe<DeleteApiSettingResponse>;
  apiIntegrationsServiceUpdateApiSetting?: Maybe<UpdateApiSettingResponse>;
  /** Adds a review to an approval request. */
  approvalsManagementAddReview?: Maybe<ReviewAddPayload>;
  /** Deletes an existing review. */
  approvalsManagementDeleteReview?: Maybe<ReviewDeletePayload>;
  /** Edits an existing review. */
  approvalsManagementEditReview?: Maybe<ReviewEditPayload>;
  /** Publishes an approved approval request. */
  approvalsManagementPublishApprovalRequest?: Maybe<PublishApprovalRequestPayload>;
  /** Assign a permission to a role */
  assignPermissionToRole?: Maybe<AssignPermissionToRolePayload>;
  /** Disable an enterprise account and associated alias accounts by its email */
  botEnterpriseAccountDisable?: Maybe<BotEnterpriseAccountDisablePayload>;
  /** Create/update a new enterprise account by its email */
  botEnterpriseAccountUpsert?: Maybe<BotEnterpriseAccountUpsertPayload>;
  /** Update an existing callback configuration */
  callbackConfigurationUpdate?: Maybe<PartnerCallbackConfigurationUpdatePayload>;
  catalogProblemTrackerServiceCreateTicket?: Maybe<CreateTicketResponse>;
  configurableItemsServicePublishRppConfigurableItems?: Maybe<PublishRppConfigurableItemsResponse>;
  connectPlatformServiceAdvanceBatchState?: Maybe<AdvanceBatchStateResponse>;
  connectPlatformServiceAssignVirtualBatch?: Maybe<AssignVirtualBatchResponse>;
  connectPlatformServiceCreateDriver?: Maybe<CreateDriverResponse>;
  connectPlatformServiceCreatePartnerApiKey?: Maybe<ConnectApiKey>;
  connectPlatformServiceGenerateBatch?: Maybe<GenerateBatchResponse>;
  connectPlatformServiceRevokePartnerApiKey?: Maybe<RevokePartnerApiKeyResponse>;
  connectPlatformServiceUpdateShopperOrderItem?: Maybe<UpdateShopperOrderItemResponse>;
  contentManagementAdminServiceBatchUpdateSlotCreatives?: Maybe<BatchUpdateSlotCreativesResponse>;
  contentManagementAdminServiceCreateContentPage?: Maybe<CreateContentPageResponse>;
  contentManagementAdminServiceCreateContentPageV2?: Maybe<CreateContentPageResponseV2>;
  contentManagementAdminServiceCreateContentPageVersion?: Maybe<CreateContentPageVersionResponse>;
  contentManagementAdminServiceCreateContentPageVersionMeta?: Maybe<CreateContentPageVersionMetaResponse>;
  contentManagementAdminServiceCreateNavigation?: Maybe<CreateNavigationResponse>;
  contentManagementAdminServiceCreateNavigationPreview?: Maybe<CreateNavigationPreviewResponse>;
  contentManagementAdminServiceCreatePlacementContent?: Maybe<CreatePlacementContentResponse>;
  contentManagementAdminServiceCreateSlot?: Maybe<CreateSlotResponse>;
  contentManagementAdminServiceCreateSlotCreative?: Maybe<CreateSlotCreativeResponse>;
  contentManagementAdminServiceDeleteContentPage?: Maybe<DeleteContentPageResponse>;
  contentManagementAdminServiceDeleteContentPageVersion?: Maybe<DeleteContentPageVersionResponse>;
  contentManagementAdminServiceDeleteNavigation?: Maybe<DeleteNavigationResponse>;
  contentManagementAdminServiceDeleteSlot?: Maybe<DeleteSlotResponse>;
  contentManagementAdminServiceDeleteSlotCreative?: Maybe<DeleteSlotCreativeResponse>;
  contentManagementAdminServiceDisablePlacementContent?: Maybe<DisablePlacementContentResponse>;
  contentManagementAdminServiceUpdateContentPageV2?: Maybe<UpdateContentPageResponseV2>;
  contentManagementAdminServiceUpdateContentPageVersion?: Maybe<UpdateContentPageVersionResponse>;
  contentManagementAdminServiceUpdateContentPageVersionMeta?: Maybe<UpdateContentPageVersionMetaResponse>;
  contentManagementAdminServiceUpdateNavigation?: Maybe<UpdateNavigationResponse>;
  contentManagementAdminServiceUpdatePlacementContent?: Maybe<UpdatePlacementContentResponse>;
  contentManagementServiceBatchUpdatePlacements?: Maybe<PlacementsResponse>;
  contentManagementServiceCreateLayoutVariant?: Maybe<CreateLayoutVariantResponse>;
  contentManagementServiceCreatePlacement?: Maybe<CreatePlacementResponse>;
  contentManagementServiceCreateThemePage?: Maybe<CreateThemePageResponse>;
  contentManagementServiceDeleteLayoutVariant?: Maybe<DeleteLayoutVariantResponse>;
  contentManagementServiceDeletePlacement?: Maybe<DeletePlacementResponse>;
  contentManagementServiceDisableThemePage?: Maybe<DisableThemePageResponse>;
  contentManagementServiceUpdateLayoutVariant?: Maybe<UpdateLayoutVariantResponse>;
  contentManagementServiceUpdateThemePage?: Maybe<UpdateThemePageResponse>;
  /** Create a CountryIds visibility condition */
  countryIdsVisibilityConditionCreate?: Maybe<CountryIdsVisibilityConditionCreatePayload>;
  /** Update a CountryIds visibility condition */
  countryIdsVisibilityConditionUpdate?: Maybe<CountryIdsVisibilityConditionUpdatePayload>;
  /** Create a ExpressMembership visibility condition */
  dayOfWeekVisibilityConditionCreate?: Maybe<DayOfWeekVisibilityConditionCreatePayload>;
  /** Update an existing ShoppedAtRetailer visibility condition */
  dayOfWeekVisibilityConditionUpdate?: Maybe<DayOfWeekVisibilityConditionUpdatePayload>;
  demeterServiceAuditDetection?: Maybe<AuditDetectionResponse>;
  demeterServiceCombineAuditBatches?: Maybe<CombineAuditBatchesResponse>;
  demeterServiceCreateDetection?: Maybe<CreateDetectionResponse>;
  demeterServiceCreateDetectionVersion?: Maybe<CreateDetectionVersionResponse>;
  demeterServiceReassignAuditBatchWorkflow?: Maybe<ReassignAuditBatchWorkflowResponse>;
  demeterServiceTombstoneDetectionVersion?: Maybe<TombstoneDetectionVersionResponse>;
  demeterServiceUpdateAuditBatch?: Maybe<UpdateAuditBatchResponse>;
  demeterServiceUpdateAuditTask?: Maybe<UpdateAuditTaskResponse>;
  emergingBrandPortalServiceContactBrand?: Maybe<BrandContactResponse>;
  /** Accept a partner agreement for the current account */
  enterpriseAccountAcceptPartnerAgreement?: Maybe<EnterpriseAccountAcceptPartnerAgreementPayload>;
  /** Set up a new account using an account setup token */
  enterpriseAccountAccountSetup?: Maybe<EnterpriseAccountAccountSetupPayload>;
  /** Authenticate an account using an email and password */
  enterpriseAccountAuthenticateEmailPassword?: Maybe<EnterpriseAccountAuthenticateEmailPasswordPayload>;
  /** Preflight authentication info per email address */
  enterpriseAccountAuthenticatePreflight?: Maybe<EnterpriseAccountAuthenticatePreflightPayload>;
  /** Create a new enterprise account */
  enterpriseAccountCreate?: Maybe<EnterpriseAccountCreatePayload>;
  /** Create a new enterprise account that can only access documentation */
  enterpriseAccountCreateNoPartnerAccess?: Maybe<EnterpriseAccountCreateNoPartnerAccessPayload>;
  /** Deactivate MFA for an account */
  enterpriseAccountMfaDeactivate?: Maybe<EnterpriseAccountMfaDeactivatePayload>;
  /** Enable MFA for an account */
  enterpriseAccountMfaEnable?: Maybe<EnterpriseAccountMfaEnablePayload>;
  /** Reset MFA for an account */
  enterpriseAccountMfaReset?: Maybe<EnterpriseAccountMfaResetPayload>;
  /** Partner Onboarding new account token request, generate new email that will be sent to an Account's email address */
  enterpriseAccountPartnerOnboardingRequestAccountSetupEmail?: Maybe<EnterpriseAccountPartnerOnboardingRequestAccountSetupEmailPayload>;
  /** Request that an account setup email be sent to an Account's email address */
  enterpriseAccountRequestAccountSetupEmail?: Maybe<EnterpriseAccountRequestAccountSetupEmailPayload>;
  /** Request that a verification email be sent to an Account's email address */
  enterpriseAccountRequestEmailVerification?: Maybe<EnterpriseAccountRequestEmailVerificationPayload>;
  /** Request that a password reset email be sent to an Account's email address */
  enterpriseAccountRequestPasswordReset?: Maybe<EnterpriseAccountRequestPasswordResetPayload>;
  /** Set/Reset an account's password using a password reset token */
  enterpriseAccountResetPassword?: Maybe<EnterpriseAccountResetPasswordPayload>;
  /** Sets the disabled flag on an account to true/false */
  enterpriseAccountSetDisabled?: Maybe<EnterpriseAccountSetDisabledPayload>;
  /** Sign up for an account using an email */
  enterpriseAccountSignUpEmailPassword?: Maybe<EnterpriseAccountSignUpEmailPasswordPayload>;
  /** Update an enterprise account */
  enterpriseAccountUpdate?: Maybe<EnterpriseAccountUpdatePayload>;
  /** Update the password for the currently logged-in account */
  enterpriseAccountUpdatePassword?: Maybe<EnterpriseAccountUpdatePasswordPayload>;
  /** Update an enterprise account's roles */
  enterpriseAccountUpdateRoles?: Maybe<EnterpriseAccountUpdateRolesPayload>;
  /** Verify an account via account setup token */
  enterpriseAccountVerifyAccountSetupToken?: Maybe<EnterpriseAccountVerifyAccountSetupTokenPayload>;
  /** Verify an account's email using a verification token */
  enterpriseAccountVerifyEmail?: Maybe<EnterpriseAccountVerifyEmailPayload>;
  /** Create a ExperimentVariant visibility condition */
  experimentVariantVisibilityConditionCreate?: Maybe<ExperimentVariantVisibilityConditionCreatePayload>;
  /** Update a ExperimentVariant visibility condition */
  experimentVariantVisibilityConditionUpdate?: Maybe<ExperimentVariantVisibilityConditionUpdatePayload>;
  /** Create a ExpressMembership visibility condition */
  expressMembershipVisibilityConditionCreate?: Maybe<ExpressMembershipVisibilityConditionCreatePayload>;
  /** Update an existing ExpressMembership visibility condition */
  expressMembershipVisibilityConditionUpdate?: Maybe<ExpressMembershipVisibilityConditionUpdatePayload>;
  /** Create an ExpressNonmemberLongTermHoldout visibility condition */
  expressNonmemberLongTermHoldoutVisibilityConditionCreate?: Maybe<ExpressNonmemberLongTermHoldoutVisibilityConditionCreatePayload>;
  /** Create a new flyout placement */
  flyoutPlacementCreate?: Maybe<FlyoutPlacementCreatePayload>;
  /** Update an existing flyout placement */
  flyoutPlacementUpdate?: Maybe<FlyoutPlacementUpdatePayload>;
  /** Create a new home placement with HeroBanner banner */
  heroBannerPlacementCreate?: Maybe<HeroBannerPlacementCreatePayload>;
  /** Update an existing hero_banner placement placement */
  heroBannerPlacementUpdate?: Maybe<HeroBannerPlacementUpdatePayload>;
  insightsPortalServiceCreateInsightsPortalCustomReport?: Maybe<InsightsPortalCreateCustomReportResponse>;
  insightsPortalServiceSetInsightsPortalCustomReportDisabled?: Maybe<InsightsPortalSetCustomReportDisabledResponse>;
  insightsPortalServiceUpdateInsightsPortalCustomReport?: Maybe<InsightsPortalUpdateCustomReportResponse>;
  /**
   * CREATE
   *
   * The response will have a newly-minted ID, which always have version_number
   * 1, and the definition will be the same as what was passed in the request.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_CreateSegment?: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  /**
   * DELETE
   *
   * The response will have the version number one greater than the request,
   * and the definition will be empty.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_DeleteSegment?: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  /**
   * UDPATE
   *
   * The response will have the version number one greater than the request,
   * and the definition will be the same as what was passed in the request.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_UpdateSegment?: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  instacart_ads_v3_RetailerBlockedAdsService_RetailerBlockAd?: Maybe<Instacart_Ads_V3_RetailerBlockAdResponse>;
  /** Updates configs for an entity, can include adding, editing, or deleting a config */
  instacart_caper_admin_v1_ConfigManagementService_UpdateConfig?: Maybe<Scalars['instacart_caper_admin_v1_UpdateConfigResponse']>;
  /** The SetAuditFindings API persists audit findings */
  instacart_cashflow_nutella_v1_NutellaService_SetAuditFindings?: Maybe<Instacart_Cashflow_Nutella_V1_SetAuditFindingsResponse>;
  /** Create Item Bring Back Requests from a CSV uploaded to Filestack */
  instacart_catalog_admin_v1_TakeDownService_ItemBringBackBatchCsv?: Maybe<Instacart_Catalog_Admin_V1_ItemBringBackBatchCsvResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_CreateBusinessProgram?: Maybe<Instacart_Customers_Business_Admin_V1_CreateBusinessProgramResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_CreateBusinessProgramContent?: Maybe<Instacart_Customers_Business_Admin_V1_CreateBusinessProgramContentResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_CreateBusinessProgramOffer?: Maybe<Instacart_Customers_Business_Admin_V1_CreateBusinessProgramOfferResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_DeleteBusinessProgram?: Maybe<Scalars['instacart_customers_business_admin_v1_DeleteBusinessProgramResponse']>;
  instacart_customers_business_admin_v1_BusinessAdminService_DeleteBusinessProgramContent?: Maybe<Scalars['instacart_customers_business_admin_v1_DeleteBusinessProgramContentResponse']>;
  instacart_customers_business_admin_v1_BusinessAdminService_DeleteBusinessProgramOffer?: Maybe<Scalars['instacart_customers_business_admin_v1_DeleteBusinessProgramOfferResponse']>;
  instacart_customers_business_admin_v1_BusinessAdminService_UpdateBusinessProgram?: Maybe<Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_UpdateBusinessProgramContent?: Maybe<Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramContentResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_UpdateBusinessProgramOffer?: Maybe<Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramOfferResponse>;
  instacart_customers_campaigns_v1_CampaignsService_BulkUpdateCampaignSlotRanking?: Maybe<Instacart_Customers_Campaigns_V1_BulkUpdateCampaignSlotRankingResponse>;
  instacart_customers_campaigns_v1_CampaignsService_ProcessEmailCsv?: Maybe<Instacart_Customers_Campaigns_V1_ProcessEmailCsvResponse>;
  instacart_customers_commerce_discount_v1_CommerceDiscountService_CreateValueGrantPolicy?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreateValueGrantPolicyResponse>;
  instacart_customers_commerce_discount_v1_CommerceDiscountService_UpdateValueGrantPolicy?: Maybe<Instacart_Customers_Commerce_Discount_V1_UpdateValueGrantPolicyResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_AddRetailerCampaignTreatments?: Maybe<Instacart_Customers_Coupons_V1_AddRetailerCampaignTreatmentsResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_CancelMoment?: Maybe<Instacart_Customers_Coupons_V1_CancelMomentResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_CancelRetailerCampaign?: Maybe<Instacart_Customers_Coupons_V1_CancelRetailerCampaignResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_CreateMoment?: Maybe<Instacart_Customers_Coupons_V1_CreateMomentResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_CreateRetailerCampaign?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_UpdateMoment?: Maybe<Instacart_Customers_Coupons_V1_UpdateMomentResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_UpdateMomentCampaignStatus?: Maybe<Instacart_Customers_Coupons_V1_UpdateMomentCampaignStatusResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_UpdateRetailerCampaign?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_UpdateRetailerCampaignTreatments?: Maybe<Instacart_Customers_Coupons_V1_UpdateRetailerCampaignTreatmentsResponse>;
  instacart_customers_partnership_offer_v1_PartnershipOfferService_CreateRedemptionUrls?: Maybe<Instacart_Customers_Partnership_Offer_V1_CreateRedemptionUrlsResponse>;
  instacart_customers_recipes_v1_RecipesService_IngestRecipesCsv?: Maybe<Instacart_Customers_Recipes_V1_IngestRecipesCsvResponse>;
  instacart_customers_recipes_v1_RecipesService_UpdateRecipes?: Maybe<Instacart_Customers_Recipes_V1_UpdateRecipesResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_CreateFlyerClickableArea?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateFlyerClickableAreaResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_DeleteFlyerClickableAreas?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteFlyerClickableAreasResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_UpdateFlyerClickableArea?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyerClickableAreaResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_UpdateFlyers?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyersResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_CreateCollection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_CreateInvfMapping?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_CreateRootNode?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_DeleteCollection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_DeleteInvfMapping?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_DeleteNavigationNode?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_DuplicateDepartment?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DuplicateDepartmentResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_OrderNavigationNodes?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_QueueNavigationMigration?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpdateCollection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpdateCollectionProducts?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpdateNavigationConfiguration?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpsertNavigationNode?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeResponse>;
  instacart_customers_retailer_management_orchestrator_v1_RetailerManagementService_DeleteIppDraft?: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftResponse>;
  instacart_customers_retailer_management_orchestrator_v1_RetailerManagementService_PublishIppDraft?: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftResponse>;
  instacart_customers_retailer_management_orchestrator_v1_RetailerManagementService_RevertIppDraft?: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_CreateIppDraft?: Maybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_DeleteIppDraft?: Maybe<Instacart_Customers_Retailer_Management_V1_DeleteIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_PublishIppDraft?: Maybe<Instacart_Customers_Retailer_Management_V1_PublishIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_RevertIppDraft?: Maybe<Instacart_Customers_Retailer_Management_V1_RevertIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_UpdateIppDraftContent?: Maybe<Instacart_Customers_Retailer_Management_V1_UpdateIppDraftContentResponse>;
  instacart_customers_retailer_management_v1_IppDraftPreviewService_CreateIppDraftPreview?: Maybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewResponse>;
  /** Cancels an order via an OrderCancellationPolicy. */
  instacart_enterprise_frigate_fulfillment_v1_DashboardOrderService_CancelDashboardOrder?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_CancelDashboardOrderResponse>;
  /** Create a new request note */
  instacart_logistics_marketplace_ops_v1_MarketplaceOps_CreateRequestNote?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_CreateRequestNoteResponse>;
  /** Delete an existing note */
  instacart_logistics_marketplace_ops_v1_MarketplaceOps_DeleteRequestNote?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_DeleteRequestNoteResponse>;
  /** Update an existing note */
  instacart_logistics_marketplace_ops_v1_MarketplaceOps_UpdateRequestNote?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateRequestNoteResponse>;
  /** Adding a request note to OpsEscalationRequest */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_AddRequestNoteToOpsEscalationRequest?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_AddRequestNoteToOpsEscalationRequestResponse>;
  /** Update the status of multiple OpsEscalationRequests */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_BulkUpdateOpsEscalationRequestStatus?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_BulkUpdateOpsEscalationRequestStatusResponse>;
  /** Bulk create OpsEscalationRequests */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_CreateOpsEscalationRequests?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_CreateOpsEscalationRequestsResponse>;
  /** Deleting request notes (bulk) */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_DeleteOpsEscalationRequestRequestNotes?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestRequestNotesResponse>;
  /** Bulk delete OpsEscalationRequests by IDs */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_DeleteOpsEscalationRequests?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestsResponse>;
  /** Updating a request note */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_UpdateOpsEscalationRequestRequestNote?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestRequestNoteResponse>;
  /** Update the status of an OpsEscalationRequest */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_UpdateOpsEscalationRequestStatus?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestStatusResponse>;
  /** Bulk update OpsEscalationRequests */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_UpdateOpsEscalationRequests?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestsResponse>;
  /** Add 1 or more locations to an existing event */
  instacart_logistics_v1_FulfillmentEvents_AddLocationsToParentEvent?: Maybe<Instacart_Logistics_V1_AddLocationsToParentEventResponse>;
  /** Cancel/stop a list of fulfillment events */
  instacart_logistics_v1_FulfillmentEvents_CancelFulfillmentEvents?: Maybe<Instacart_Logistics_V1_CancelFulfillmentEventsResponse>;
  /** Create a new closure event */
  instacart_logistics_v1_FulfillmentEvents_CreateClosureEvent?: Maybe<Instacart_Logistics_V1_CreateClosureEventResponse>;
  /** Create a new fulfillment capacity-impacting event */
  instacart_logistics_v1_FulfillmentEvents_CreateFulfillmentEvent?: Maybe<Instacart_Logistics_V1_CreateFulfillmentEventResponse>;
  /** Create a new restricted availability event */
  instacart_logistics_v1_FulfillmentEvents_CreateRestrictedAvailabilityEvent?: Maybe<Instacart_Logistics_V1_CreateRestrictedAvailabilityEventResponse>;
  /** Update a closure event */
  instacart_logistics_v1_FulfillmentEvents_UpdateClosureEvents?: Maybe<Instacart_Logistics_V1_UpdateClosureEventsResponse>;
  /** Update a fulfillment capacity-impacting event */
  instacart_logistics_v1_FulfillmentEvents_UpdateFulfillmentEvents?: Maybe<Instacart_Logistics_V1_UpdateFulfillmentEventsResponse>;
  /** Update a restricted availability event */
  instacart_logistics_v1_FulfillmentEvents_UpdateRestrictedAvailabilityEvents?: Maybe<Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_CreateInsightsPortalCustomExport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomExportResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_CreateInsightsPortalCustomReport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomReportResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_DeleteInsightsPortalCustomExport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalDeleteCustomExportResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_RegenerateInsightsPortalCustomExport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalRegenerateCustomExportResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_SetInsightsPortalCustomReportDisabled?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalSetCustomReportDisabledResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_UpdateInsightsPortalCustomReport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalUpdateCustomReportResponse>;
  instacart_retailers_v1_RetailersService_UpsertWidgetsConfig?: Maybe<Instacart_Retailers_V1_UpsertWidgetsConfigResponse>;
  inventoryIntelligenceServiceCreateOosAcknowledgment?: Maybe<CreateOosAcknowledgmentResponse>;
  linkAutosuggestServiceCreateOrUpdateLinkAutosuggestionAdmin?: Maybe<CreateOrUpdateLinkAutosuggestionAdminResponse>;
  linkAutosuggestServiceDeleteLinkAutosuggestionAdmin?: Maybe<DeleteLinkAutosuggestionAdminResponse>;
  /** Cancels a marketing email campaign in progress */
  marketingFeatureCancelCampaign?: Maybe<CancelCampaignPayload>;
  /** Starts a marketing email campaign */
  marketingFeatureStartCampaign?: Maybe<StartCampaignPayload>;
  /** Triggers the send for an existing marketing campaign */
  marketingFeatureTriggerCampaign?: Maybe<TriggerCampaignPayload>;
  /** Create a MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityConditionCreate?: Maybe<MatchesAnyRetailerIdVisibilityConditionCreatePayload>;
  /** Update a MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityConditionUpdate?: Maybe<MatchesAnyRetailerIdVisibilityConditionUpdatePayload>;
  /** Create a MinimumPlatformVersion visibility condition */
  minimumPlatformVersionVisibilityConditionCreate?: Maybe<MinimumPlatformVersionVisibilityConditionCreatePayload>;
  /** Update an existing MinimumPlatformVersion visibility condition */
  minimumPlatformVersionVisibilityConditionUpdate?: Maybe<MinimumPlatformVersionVisibilityConditionUpdatePayload>;
  /** Create a NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityConditionCreate?: Maybe<NewVerticalUserTargetingVisibilityConditionCreatePayload>;
  /** Update a NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityConditionUpdate?: Maybe<NewVerticalUserTargetingVisibilityConditionUpdatePayload>;
  /** Merges two or more normalized-reports by combining their contents into one file */
  normalizedReportsMerge?: Maybe<NormalizedReportsMergePayload>;
  /** Subscribe an account to a notification type for a retailer */
  notificationSubscriptionCreate?: Maybe<NotificationSubscriptionCreatePayload>;
  /** Unsubscribe an account to a notification type for a retailer */
  notificationSubscriptionDelete?: Maybe<NotificationSubscriptionDeletePayload>;
  /** Sets properties for mobile apps */
  onboardingMobileAppPropertiesUpdate?: Maybe<SetMobilePropertiesPayload>;
  onboardingServiceCreateOnboardingStoreLocation?: Maybe<CreateOnboardingStoreLocationResponse>;
  onboardingServiceCreateSignup?: Maybe<CreateSignupResponse>;
  onboardingServiceDeleteLaunchOnboardingWarehouseLocation?: Maybe<DeleteLaunchOnboardingWarehouseLocationResponse>;
  onboardingServiceUpdateLaunchOnboardingWarehouseInfo?: Maybe<UpdateLaunchOnboardingWarehouseInfoResponse>;
  onboardingServiceUpdateLaunchOnboardingWarehouseLocationInfo?: Maybe<UpdateLaunchOnboardingWarehouseLocationInfoResponse>;
  onboardingServiceUpdateLaunchOnboardingWorkflowState?: Maybe<UpdateLaunchOnboardingWorkflowStateResponse>;
  onboardingServiceUpdateOnboarding?: Maybe<UpdateOnboardingResponse>;
  onboardingServiceUpdateOnboardingBillingAddress?: Maybe<UpdateOnboardingBillingAddressResponse>;
  onboardingServiceUpdateOnboardingWorkflowState?: Maybe<UpdateOnboardingWorkflowStateResponse>;
  /** Trigger a workflow */
  onboardingTriggerWorkflow?: Maybe<TriggerWorkflowPayload>;
  orderServiceCancelOrder?: Maybe<CancelOrderResponse>;
  orderServiceCreateLastmileOrderFromDashboard?: Maybe<CreateLastmileOrderFromDashboardResponse>;
  orderServiceReopenLastmileOrder?: Maybe<ReopenLastmileOrderResponse>;
  orderServiceRescheduleOrder?: Maybe<RescheduleOrderResponse>;
  orderServiceStageLastmileOrder?: Maybe<StageLastmileOrderResponse>;
  /** Sends a test callback for a partner */
  partnerCallbackCreate?: Maybe<PartnerCallbackCreatePayload>;
  /** Create a new partner pick device */
  partnerPickDeviceCreate?: Maybe<PartnerPickDeviceCreatePayload>;
  /** Update an existing partner pick device's status */
  partnerPickDeviceSetStatus?: Maybe<PartnerPickDeviceSetStatusPayload>;
  /** Update an existing partner pick device */
  partnerPickDeviceUpdate?: Maybe<PartnerPickDeviceUpdatePayload>;
  /** Create a new partner pick recurring shift */
  partnerPickRecurringShiftCreate?: Maybe<PartnerPickRecurringShiftCreatePayload>;
  /** Delete a partner pick recurring shift by id, from some date onward */
  partnerPickRecurringShiftDelete?: Maybe<PartnerPickRecurringShiftDeletePayload>;
  /** Update an existing partner pick recurring shift */
  partnerPickRecurringShiftUpdate?: Maybe<PartnerPickRecurringShiftUpdatePayload>;
  privacyServiceCreatePiiRequestForRetailer?: Maybe<CreatePiiRequestForRetailerResponse>;
  /** Create a new report request */
  reportRequestCreate?: Maybe<ReportRequestCreatePayload>;
  retailerAislesUpdateRetailerAisleTemplate?: Maybe<UpdateRetailerAisleTemplateResponse>;
  /** Create a RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityConditionCreate?: Maybe<RetailerAvailabilityVisibilityConditionCreatePayload>;
  /** Update a RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityConditionUpdate?: Maybe<RetailerAvailabilityVisibilityConditionUpdatePayload>;
  retailerCampaignServiceAddRetailerCampaignTreatments?: Maybe<AddRetailerCampaignTreatmentsResponse>;
  retailerCampaignServiceCancelMoment?: Maybe<CancelMomentResponse>;
  retailerCampaignServiceCancelRetailerCampaign?: Maybe<CancelRetailerCampaignResponse>;
  retailerCampaignServiceCreateMoment?: Maybe<CreateMomentResponse>;
  retailerCampaignServiceCreateRetailerCampaign?: Maybe<RetailerCampaignResponse>;
  retailerCampaignServiceUpdateMoment?: Maybe<UpdateMomentResponse>;
  retailerCampaignServiceUpdateMomentCampaignStatus?: Maybe<UpdateMomentCampaignStatusResponse>;
  retailerCampaignServiceUpdateRetailerCampaign?: Maybe<RetailerCampaignResponse>;
  retailerCampaignServiceUpdateRetailerCampaignTreatments?: Maybe<UpdateRetailerCampaignTreatmentsResponse>;
  /** Create a new retailer location group */
  retailerLocationGroupCreate?: Maybe<RetailerLocationGroupCreatePayload>;
  /** Delete an existing retailer location group */
  retailerLocationGroupDelete?: Maybe<RetailerLocationGroupDeletePayload>;
  /** Update an existing retailer location group */
  retailerLocationGroupUpdate?: Maybe<RetailerLocationGroupUpdatePayload>;
  retailerManagementServiceCreateClientConfiguration?: Maybe<CreateClientConfigurationResponse>;
  retailerManagementServiceCreateConnectRetailerCallbackConfiguration?: Maybe<CreateConnectRetailerCallbackConfigurationResponse>;
  retailerManagementServiceCreateStoreConfiguration?: Maybe<CreateStoreConfigurationResponse>;
  retailerManagementServiceDeleteConnectRetailerCallbackConfiguration?: Maybe<DeleteConnectRetailerCallbackConfigurationResponse>;
  retailerManagementServiceUpdateConnectRetailerCallbackConfiguration?: Maybe<UpdateConnectRetailerCallbackConfigurationResponse>;
  /** Set MFA enabled status for a retailer */
  retailerMfaSetEnabled?: Maybe<RetailerMfaSetEnabledPayload>;
  retailerProductAttributeEditServiceUpdateAvailabilityScoreOverride?: Maybe<UpdateAvailabilityScoreOverrideResponse>;
  retailerProductAttributeEditServiceUpdateRetailerProductVisibilities?: Maybe<UpdateRetailerProductVisibilitiesResponse>;
  /** Submit the results of the Retailer Satisfication Survey form */
  retailerSatisfactionSurveySubmissionCreate?: Maybe<SubmissionCreatePayload>;
  retailerSettingsServiceDeleteImage?: Maybe<DeleteImageResponse>;
  retailerSettingsServiceUpdateParkingSettings?: Maybe<UpdateParkingSettingsResponse>;
  retailerSettingsServiceUpdateStagingSettings?: Maybe<UpdateStagingSettingsResponse>;
  retailerStoreHoursUpdateConfirmStoreHours?: Maybe<UpdateConfirmedStoreHoursResponse>;
  retailerStoreHoursUpdateStoreHours?: Maybe<UpdateStoreHoursResponse>;
  retailersServiceMoveRetailerAssetsFromFilestackBucket?: Maybe<MoveRetailerAssetsFromFilestackBucketResponse>;
  returnsServiceCreateOrUpdateReturn?: Maybe<CreateOrUpdateReturnResponse>;
  /** Create a role */
  roleCreate?: Maybe<RoleCreatePayload>;
  /** Delete a role */
  roleDelete?: Maybe<RoleDeletePayload>;
  /** Update a role */
  roleUpdate?: Maybe<RoleUpdatePayload>;
  rppBulkImageUploadsServiceCreateImageUploadBatch?: Maybe<CreateImageUploadBatchResponse>;
  /** Simulate an event for an existing sandbox order */
  sandboxOrderSimulateEvent?: Maybe<PartnerSandboxOrderSimulateEventPayload>;
  /** Create a new home placement with SecondaryBanner banner */
  secondaryBannerPlacementCreate?: Maybe<SecondaryBannerPlacementCreatePayload>;
  /** Update an existing secondary_banner placement placement */
  secondaryBannerPlacementUpdate?: Maybe<SecondaryBannerPlacementUpdatePayload>;
  /** Create a ShoppedAtRetailer visibility condition */
  shoppedAtRetailerVisibilityConditionCreate?: Maybe<ShoppedAtRetailerVisibilityConditionCreatePayload>;
  /** Update an existing ShoppedAtRetailer visibility condition */
  shoppedAtRetailerVisibilityConditionUpdate?: Maybe<ShoppedAtRetailerVisibilityConditionUpdatePayload>;
  /** Create a shopped visibility condition */
  shoppedVisibilityConditionCreate?: Maybe<ShoppedVisibilityConditionCreatePayload>;
  /** Update an existing Shopped visibility condition */
  shoppedVisibilityConditionUpdate?: Maybe<ShoppedVisibilityConditionUpdatePayload>;
  storeConfigurationsDraftServiceCreateStoreConfigurationDraft?: Maybe<CreateStoreConfigurationDraftResponse>;
  storeConfigurationsDraftServicePublishStoreConfigurationDraft?: Maybe<PublishStoreConfigurationDraftResponse>;
  storeConfigurationsDraftServiceRollbackStoreConfiguration?: Maybe<RollbackStoreConfigurationResponse>;
  storeConfigurationsDraftServiceUpdateStoreConfigurationDraft?: Maybe<UpdateStoreConfigurationDraftResponse>;
  /** Create a new storefront placement */
  storefrontPlacementCreate?: Maybe<StorefrontPlacementCreatePayload>;
  /** Delete an existing storefront placement */
  storefrontPlacementDelete?: Maybe<StorefrontPlacementDeletePayload>;
  /** Update an existing storefront placement placement */
  storefrontPlacementUpdate?: Maybe<StorefrontPlacementUpdatePayload>;
  /** Create a UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityConditionCreate?: Maybe<UserInteractionLimitVisibilityConditionCreatePayload>;
  /** Update a UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityConditionUpdate?: Maybe<UserInteractionLimitVisibilityConditionUpdatePayload>;
  userSettingServiceSetUserSetting?: Maybe<SetUserSettingResponse>;
  usersServiceGrantImpersonation?: Maybe<GrantImpersonationResponse>;
  /** Create a UtmParameters visibility condition */
  utmParametersVisibilityConditionCreate?: Maybe<UtmParametersVisibilityConditionCreatePayload>;
  /** Update a UtmParameters visibility condition */
  utmParametersVisibilityConditionUpdate?: Maybe<UtmParametersVisibilityConditionUpdatePayload>;
  valueBasedOverrideServiceCreateValueBasedOverride?: Maybe<CreateValueBasedOverrideResponse>;
  /** Delete an existing visibility condition */
  visibilityConditionDelete?: Maybe<VisibilityConditionDeletePayload>;
  /** Create a WhitelistedPostalCodes visibility condition */
  whitelistedPostalCodesVisibilityConditionCreate?: Maybe<WhitelistedPostalCodesVisibilityConditionCreatePayload>;
  /** Update an existing WhitelistedPostalCodes visibility condition */
  whitelistedPostalCodesVisibilityConditionUpdate?: Maybe<WhitelistedPostalCodesVisibilityConditionUpdatePayload>;
  /** Create a WhitelistedZones visibility condition */
  whitelistedZonesVisibilityConditionCreate?: Maybe<WhitelistedZonesVisibilityConditionCreatePayload>;
  /** Update a WhitelistedZones visibility condition */
  whitelistedZonesVisibilityConditionUpdate?: Maybe<WhitelistedZonesVisibilityConditionUpdatePayload>;
  /** Update the wizard progress for the currently logged in account */
  wizardProgressUpdate?: Maybe<WizardProgressUpdatePayload>;
};


export type MutationApiIntegrationsServiceDeleteApiSettingArgs = {
  input?: InputMaybe<DeleteApiSettingRequestInput>;
};


export type MutationApiIntegrationsServiceUpdateApiSettingArgs = {
  input?: InputMaybe<UpdateApiSettingRequestInput>;
};


export type MutationApprovalsManagementAddReviewArgs = {
  input: ReviewAddInput;
};


export type MutationApprovalsManagementDeleteReviewArgs = {
  input: ReviewDeleteInput;
};


export type MutationApprovalsManagementEditReviewArgs = {
  input: ReviewEditInput;
};


export type MutationApprovalsManagementPublishApprovalRequestArgs = {
  input: PublishApprovalRequestInput;
};


export type MutationAssignPermissionToRoleArgs = {
  permission: PermissionInput;
  role: RoleInput;
};


export type MutationBotEnterpriseAccountDisableArgs = {
  email: Scalars['String'];
};


export type MutationBotEnterpriseAccountUpsertArgs = {
  email: Scalars['String'];
  extraRoleSlugs?: InputMaybe<Array<Scalars['String']>>;
  givenName: Scalars['String'];
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  readOnly?: InputMaybe<Scalars['Boolean']>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  role: BotAccountAssignableRoleEnum;
  slackParentMessageTs?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};


export type MutationCallbackConfigurationUpdateArgs = {
  id: Scalars['ID'];
  updatedCallbackConfiguration: CallbackConfigurationInput;
};


export type MutationCatalogProblemTrackerServiceCreateTicketArgs = {
  input?: InputMaybe<CreateTicketRequestInput>;
};


export type MutationConfigurableItemsServicePublishRppConfigurableItemsArgs = {
  input?: InputMaybe<PublishRppConfigurableItemsRequestInput>;
};


export type MutationConnectPlatformServiceAdvanceBatchStateArgs = {
  input?: InputMaybe<AdvanceBatchStateRequestInput>;
};


export type MutationConnectPlatformServiceAssignVirtualBatchArgs = {
  input?: InputMaybe<AssignVirtualBatchRequestInput>;
};


export type MutationConnectPlatformServiceCreateDriverArgs = {
  input?: InputMaybe<CreateDriverRequestInput>;
};


export type MutationConnectPlatformServiceCreatePartnerApiKeyArgs = {
  input?: InputMaybe<CreatePartnerApiKeyRequestInput>;
};


export type MutationConnectPlatformServiceGenerateBatchArgs = {
  input?: InputMaybe<GenerateBatchRequestInput>;
};


export type MutationConnectPlatformServiceRevokePartnerApiKeyArgs = {
  input?: InputMaybe<RevokePartnerApiKeyRequestInput>;
};


export type MutationConnectPlatformServiceUpdateShopperOrderItemArgs = {
  input?: InputMaybe<UpdateShopperOrderItemRequestInput>;
};


export type MutationContentManagementAdminServiceBatchUpdateSlotCreativesArgs = {
  input?: InputMaybe<BatchUpdateSlotCreativesRequestInput>;
};


export type MutationContentManagementAdminServiceCreateContentPageArgs = {
  input?: InputMaybe<CreateContentPageRequestInput>;
};


export type MutationContentManagementAdminServiceCreateContentPageV2Args = {
  input?: InputMaybe<CreateContentPageRequestV2Input>;
};


export type MutationContentManagementAdminServiceCreateContentPageVersionArgs = {
  input?: InputMaybe<CreateContentPageVersionRequestInput>;
};


export type MutationContentManagementAdminServiceCreateContentPageVersionMetaArgs = {
  input?: InputMaybe<CreateContentPageVersionMetaRequestInput>;
};


export type MutationContentManagementAdminServiceCreateNavigationArgs = {
  input?: InputMaybe<CreateNavigationRequestInput>;
};


export type MutationContentManagementAdminServiceCreateNavigationPreviewArgs = {
  input?: InputMaybe<CreateNavigationPreviewRequestInput>;
};


export type MutationContentManagementAdminServiceCreatePlacementContentArgs = {
  input?: InputMaybe<CreatePlacementContentRequestInput>;
};


export type MutationContentManagementAdminServiceCreateSlotArgs = {
  input?: InputMaybe<CreateSlotRequestInput>;
};


export type MutationContentManagementAdminServiceCreateSlotCreativeArgs = {
  input?: InputMaybe<CreateSlotCreativeRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteContentPageArgs = {
  input?: InputMaybe<DeleteContentPageRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteContentPageVersionArgs = {
  input?: InputMaybe<DeleteContentPageVersionRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteNavigationArgs = {
  input?: InputMaybe<DeleteNavigationRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteSlotArgs = {
  input?: InputMaybe<DeleteSlotRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteSlotCreativeArgs = {
  input?: InputMaybe<DeleteSlotCreativeRequestInput>;
};


export type MutationContentManagementAdminServiceDisablePlacementContentArgs = {
  input?: InputMaybe<DisablePlacementContentRequestInput>;
};


export type MutationContentManagementAdminServiceUpdateContentPageV2Args = {
  input?: InputMaybe<UpdateContentPageRequestV2Input>;
};


export type MutationContentManagementAdminServiceUpdateContentPageVersionArgs = {
  input?: InputMaybe<UpdateContentPageVersionRequestInput>;
};


export type MutationContentManagementAdminServiceUpdateContentPageVersionMetaArgs = {
  input?: InputMaybe<UpdateContentPageVersionMetaRequestInput>;
};


export type MutationContentManagementAdminServiceUpdateNavigationArgs = {
  input?: InputMaybe<UpdateNavigationRequestInput>;
};


export type MutationContentManagementAdminServiceUpdatePlacementContentArgs = {
  input?: InputMaybe<UpdatePlacementContentRequestInput>;
};


export type MutationContentManagementServiceBatchUpdatePlacementsArgs = {
  input?: InputMaybe<BatchUpdatePlacementsRequestInput>;
};


export type MutationContentManagementServiceCreateLayoutVariantArgs = {
  input?: InputMaybe<CreateLayoutVariantRequestInput>;
};


export type MutationContentManagementServiceCreatePlacementArgs = {
  input?: InputMaybe<CreatePlacementRequestInput>;
};


export type MutationContentManagementServiceCreateThemePageArgs = {
  input?: InputMaybe<CreateThemePageRequestInput>;
};


export type MutationContentManagementServiceDeleteLayoutVariantArgs = {
  input?: InputMaybe<DeleteLayoutVariantRequestInput>;
};


export type MutationContentManagementServiceDeletePlacementArgs = {
  input?: InputMaybe<DeletePlacementRequestInput>;
};


export type MutationContentManagementServiceDisableThemePageArgs = {
  input?: InputMaybe<DisableThemePageRequestInput>;
};


export type MutationContentManagementServiceUpdateLayoutVariantArgs = {
  input?: InputMaybe<UpdateLayoutVariantRequestInput>;
};


export type MutationContentManagementServiceUpdateThemePageArgs = {
  input?: InputMaybe<UpdateThemePageRequestInput>;
};


export type MutationCountryIdsVisibilityConditionCreateArgs = {
  countryIdsVisibilityConditionData: CountryIdsInput;
};


export type MutationCountryIdsVisibilityConditionUpdateArgs = {
  countryIdsVisibilityConditionData: CountryIdsInput;
};


export type MutationDayOfWeekVisibilityConditionCreateArgs = {
  dayOfWeekVisibilityConditionData: DayOfWeekInput;
};


export type MutationDayOfWeekVisibilityConditionUpdateArgs = {
  dayOfWeekVisibilityConditionData: DayOfWeekInput;
};


export type MutationDemeterServiceAuditDetectionArgs = {
  input?: InputMaybe<AuditDetectionRequestInput>;
};


export type MutationDemeterServiceCombineAuditBatchesArgs = {
  input?: InputMaybe<CombineAuditBatchesRequestInput>;
};


export type MutationDemeterServiceCreateDetectionArgs = {
  input?: InputMaybe<CreateDetectionRequestInput>;
};


export type MutationDemeterServiceCreateDetectionVersionArgs = {
  input?: InputMaybe<CreateDetectionVersionRequestInput>;
};


export type MutationDemeterServiceReassignAuditBatchWorkflowArgs = {
  input?: InputMaybe<ReassignAuditBatchWorkflowRequestInput>;
};


export type MutationDemeterServiceTombstoneDetectionVersionArgs = {
  input?: InputMaybe<TombstoneDetectionVersionRequestInput>;
};


export type MutationDemeterServiceUpdateAuditBatchArgs = {
  input?: InputMaybe<UpdateAuditBatchRequestInput>;
};


export type MutationDemeterServiceUpdateAuditTaskArgs = {
  input?: InputMaybe<UpdateAuditTaskRequestInput>;
};


export type MutationEmergingBrandPortalServiceContactBrandArgs = {
  input?: InputMaybe<BrandContactRequestInput>;
};


export type MutationEnterpriseAccountAcceptPartnerAgreementArgs = {
  partnerId: Scalars['ID'];
};


export type MutationEnterpriseAccountAccountSetupArgs = {
  givenName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  rawToken: Scalars['String'];
  surname?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountAuthenticateEmailPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountAuthenticatePreflightArgs = {
  email: Scalars['String'];
};


export type MutationEnterpriseAccountCreateArgs = {
  accountRequestId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  givenName: Scalars['String'];
  hasAllPartnersFullAccess: Scalars['Boolean'];
  hasFullPartnerAccess: Scalars['Boolean'];
  hasFullRetailerAccess: Scalars['Boolean'];
  partnerIds: Array<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  retailerIds: Array<Scalars['ID']>;
  retailerLocationIds: Array<Scalars['ID']>;
  roleIds: Array<Scalars['ID']>;
  surname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountCreateNoPartnerAccessArgs = {
  email: Scalars['String'];
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationEnterpriseAccountMfaDeactivateArgs = {
  canonicalId: Scalars['ID'];
};


export type MutationEnterpriseAccountMfaEnableArgs = {
  canonicalId: Scalars['ID'];
};


export type MutationEnterpriseAccountMfaResetArgs = {
  canonicalId: Scalars['ID'];
};


export type MutationEnterpriseAccountPartnerOnboardingRequestAccountSetupEmailArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountRequestAccountSetupEmailArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountRequestEmailVerificationArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountRequestPasswordResetArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountResetPasswordArgs = {
  password: Scalars['String'];
  rawToken: Scalars['String'];
};


export type MutationEnterpriseAccountSetDisabledArgs = {
  accountCanonicalId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationEnterpriseAccountSignUpEmailPasswordArgs = {
  email: Scalars['String'];
  givenName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountUpdateArgs = {
  canonicalId: Scalars['ID'];
  givenName: Scalars['String'];
  hasAllPartnersFullAccess: Scalars['Boolean'];
  hasFullPartnerAccess: Scalars['Boolean'];
  hasFullRetailerAccess: Scalars['Boolean'];
  partnerIds: Array<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  retailerIds: Array<Scalars['ID']>;
  retailerLocationIds: Array<Scalars['ID']>;
  roleIds: Array<Scalars['ID']>;
  surname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountUpdatePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationEnterpriseAccountUpdateRolesArgs = {
  addRoleIds?: InputMaybe<Array<Scalars['ID']>>;
  canonicalId: Scalars['ID'];
  removeRoleIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationEnterpriseAccountVerifyAccountSetupTokenArgs = {
  rawToken: Scalars['String'];
};


export type MutationEnterpriseAccountVerifyEmailArgs = {
  rawToken: Scalars['String'];
};


export type MutationExperimentVariantVisibilityConditionCreateArgs = {
  experimentVariantVisibilityConditionData: ExperimentVariantInput;
};


export type MutationExperimentVariantVisibilityConditionUpdateArgs = {
  experimentVariantVisibilityConditionData: ExperimentVariantInput;
};


export type MutationExpressMembershipVisibilityConditionCreateArgs = {
  expressMembershipVisibilityConditionData: ExpressMembershipInput;
};


export type MutationExpressMembershipVisibilityConditionUpdateArgs = {
  expressMembershipVisibilityConditionData: ExpressMembershipInput;
};


export type MutationExpressNonmemberLongTermHoldoutVisibilityConditionCreateArgs = {
  expressNonmemberLongTermHoldoutVisibilityConditionData: ExpressNonmemberLongTermHoldoutInput;
};


export type MutationFlyoutPlacementCreateArgs = {
  flyoutPlacementData: FlyoutPlacementInput;
};


export type MutationFlyoutPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  flyoutPlacementData: FlyoutPlacementInput;
  id: Scalars['ID'];
};


export type MutationHeroBannerPlacementCreateArgs = {
  heroBannerPlacementData: HeroBannerPlacementInput;
};


export type MutationHeroBannerPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  heroBannerPlacementData: HeroBannerPlacementInput;
  id: Scalars['ID'];
};


export type MutationInsightsPortalServiceCreateInsightsPortalCustomReportArgs = {
  input?: InputMaybe<InsightsPortalCreateCustomReportRequestInput>;
};


export type MutationInsightsPortalServiceSetInsightsPortalCustomReportDisabledArgs = {
  input?: InputMaybe<InsightsPortalSetCustomReportDisabledRequestInput>;
};


export type MutationInsightsPortalServiceUpdateInsightsPortalCustomReportArgs = {
  input?: InputMaybe<InsightsPortalUpdateCustomReportRequestInput>;
};


export type MutationInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_CreateSegmentArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_CreateSegmentRequest_Input>;
};


export type MutationInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_DeleteSegmentArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_DeleteSegmentRequest_Input>;
};


export type MutationInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_UpdateSegmentArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_UpdateSegmentRequest_Input>;
};


export type MutationInstacart_Ads_V3_RetailerBlockedAdsService_RetailerBlockAdArgs = {
  input?: InputMaybe<Instacart_Ads_V3_RetailerBlockAdRequest_Input>;
};


export type MutationInstacart_Caper_Admin_V1_ConfigManagementService_UpdateConfigArgs = {
  input?: InputMaybe<Instacart_Caper_Admin_V1_UpdateConfigRequest_Input>;
};


export type MutationInstacart_Cashflow_Nutella_V1_NutellaService_SetAuditFindingsArgs = {
  input?: InputMaybe<Instacart_Cashflow_Nutella_V1_SetAuditFindingsRequest_Input>;
};


export type MutationInstacart_Catalog_Admin_V1_TakeDownService_ItemBringBackBatchCsvArgs = {
  input?: InputMaybe<Instacart_Catalog_Admin_V1_ItemBringBackBatchCsvRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_CreateBusinessProgramArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_CreateBusinessProgramRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_CreateBusinessProgramContentArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_CreateBusinessProgramContentRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_CreateBusinessProgramOfferArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_CreateBusinessProgramOfferRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_DeleteBusinessProgramArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_DeleteBusinessProgramRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_DeleteBusinessProgramContentArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_DeleteBusinessProgramContentRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_DeleteBusinessProgramOfferArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_DeleteBusinessProgramOfferRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_UpdateBusinessProgramArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_UpdateBusinessProgramContentArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramContentRequest_Input>;
};


export type MutationInstacart_Customers_Business_Admin_V1_BusinessAdminService_UpdateBusinessProgramOfferArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramOfferRequest_Input>;
};


export type MutationInstacart_Customers_Campaigns_V1_CampaignsService_BulkUpdateCampaignSlotRankingArgs = {
  input?: InputMaybe<Instacart_Customers_Campaigns_V1_BulkUpdateCampaignSlotRankingRequest_Input>;
};


export type MutationInstacart_Customers_Campaigns_V1_CampaignsService_ProcessEmailCsvArgs = {
  input?: InputMaybe<Instacart_Customers_Campaigns_V1_ProcessEmailCsvRequest_Input>;
};


export type MutationInstacart_Customers_Commerce_Discount_V1_CommerceDiscountService_CreateValueGrantPolicyArgs = {
  input?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreateValueGrantPolicyRequest_Input>;
};


export type MutationInstacart_Customers_Commerce_Discount_V1_CommerceDiscountService_UpdateValueGrantPolicyArgs = {
  input?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_UpdateValueGrantPolicyRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_AddRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_AddRetailerCampaignTreatmentsRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_CancelMomentArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_CancelMomentRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_CancelRetailerCampaignArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_CancelRetailerCampaignRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_CreateMomentArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_CreateMomentRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_CreateRetailerCampaignArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_CreateRetailerCampaignRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_UpdateMomentArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_UpdateMomentRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_UpdateMomentCampaignStatusArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_UpdateMomentCampaignStatusRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_UpdateRetailerCampaignArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_UpdateRetailerCampaignRequest_Input>;
};


export type MutationInstacart_Customers_Coupons_V1_RetailerCampaignService_UpdateRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_UpdateRetailerCampaignTreatmentsRequest_Input>;
};


export type MutationInstacart_Customers_Partnership_Offer_V1_PartnershipOfferService_CreateRedemptionUrlsArgs = {
  input?: InputMaybe<Instacart_Customers_Partnership_Offer_V1_CreateRedemptionUrlsRequest_Input>;
};


export type MutationInstacart_Customers_Recipes_V1_RecipesService_IngestRecipesCsvArgs = {
  input?: InputMaybe<Instacart_Customers_Recipes_V1_IngestRecipesCsvRequest_Input>;
};


export type MutationInstacart_Customers_Recipes_V1_RecipesService_UpdateRecipesArgs = {
  input?: InputMaybe<Instacart_Customers_Recipes_V1_UpdateRecipesRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_CreateFlyerClickableAreaArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateFlyerClickableAreaRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_DeleteFlyerClickableAreasArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteFlyerClickableAreasRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_UpdateFlyerClickableAreaArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyerClickableAreaRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_UpdateFlyersArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyersRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_CreateCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_CreateInvfMappingArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_CreateRootNodeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_DeleteCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_DeleteInvfMappingArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_DeleteNavigationNodeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_DuplicateDepartmentArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DuplicateDepartmentRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_OrderNavigationNodesArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_QueueNavigationMigrationArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpdateCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpdateCollectionProductsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpdateNavigationConfigurationArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpsertNavigationNodeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_Orchestrator_V1_RetailerManagementService_DeleteIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_Orchestrator_V1_RetailerManagementService_PublishIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_Orchestrator_V1_RetailerManagementService_RevertIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_CreateIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_DeleteIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_DeleteIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_PublishIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_PublishIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_RevertIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_RevertIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_UpdateIppDraftContentArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_UpdateIppDraftContentRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftPreviewService_CreateIppDraftPreviewArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewRequest_Input>;
};


export type MutationInstacart_Enterprise_Frigate_Fulfillment_V1_DashboardOrderService_CancelDashboardOrderArgs = {
  input?: InputMaybe<Instacart_Enterprise_Frigate_Fulfillment_V1_CancelDashboardOrderRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_MarketplaceOps_CreateRequestNoteArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_CreateRequestNoteRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_MarketplaceOps_DeleteRequestNoteArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_DeleteRequestNoteRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_MarketplaceOps_UpdateRequestNoteArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateRequestNoteRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_AddRequestNoteToOpsEscalationRequestArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_AddRequestNoteToOpsEscalationRequestRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_BulkUpdateOpsEscalationRequestStatusArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_BulkUpdateOpsEscalationRequestStatusRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_CreateOpsEscalationRequestsArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_CreateOpsEscalationRequestsRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_DeleteOpsEscalationRequestRequestNotesArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestRequestNotesRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_DeleteOpsEscalationRequestsArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestsRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_UpdateOpsEscalationRequestRequestNoteArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestRequestNoteRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_UpdateOpsEscalationRequestStatusArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestStatusRequest_Input>;
};


export type MutationInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_UpdateOpsEscalationRequestsArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestsRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_AddLocationsToParentEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_AddLocationsToParentEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_CancelFulfillmentEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_CancelFulfillmentEventsRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_CreateClosureEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_CreateClosureEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_CreateFulfillmentEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_CreateFulfillmentEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_CreateRestrictedAvailabilityEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_CreateRestrictedAvailabilityEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_UpdateClosureEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_UpdateClosureEventsRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_UpdateFulfillmentEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_UpdateFulfillmentEventsRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_UpdateRestrictedAvailabilityEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsRequest_Input>;
};


export type MutationInstacart_Retailer_Tools_V2_InsightsPortalService_CreateInsightsPortalCustomExportArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomExportRequest_Input>;
};


export type MutationInstacart_Retailer_Tools_V2_InsightsPortalService_CreateInsightsPortalCustomReportArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomReportRequest_Input>;
};


export type MutationInstacart_Retailer_Tools_V2_InsightsPortalService_DeleteInsightsPortalCustomExportArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalDeleteCustomExportRequest_Input>;
};


export type MutationInstacart_Retailer_Tools_V2_InsightsPortalService_RegenerateInsightsPortalCustomExportArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalRegenerateCustomExportRequest_Input>;
};


export type MutationInstacart_Retailer_Tools_V2_InsightsPortalService_SetInsightsPortalCustomReportDisabledArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalSetCustomReportDisabledRequest_Input>;
};


export type MutationInstacart_Retailer_Tools_V2_InsightsPortalService_UpdateInsightsPortalCustomReportArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalUpdateCustomReportRequest_Input>;
};


export type MutationInstacart_Retailers_V1_RetailersService_UpsertWidgetsConfigArgs = {
  input?: InputMaybe<Instacart_Retailers_V1_UpsertWidgetsConfigRequest_Input>;
};


export type MutationInventoryIntelligenceServiceCreateOosAcknowledgmentArgs = {
  input?: InputMaybe<CreateOosAcknowledgmentRequestInput>;
};


export type MutationLinkAutosuggestServiceCreateOrUpdateLinkAutosuggestionAdminArgs = {
  input?: InputMaybe<CreateOrUpdateLinkAutosuggestionAdminRequestInput>;
};


export type MutationLinkAutosuggestServiceDeleteLinkAutosuggestionAdminArgs = {
  input?: InputMaybe<DeleteLinkAutosuggestionAdminRequestInput>;
};


export type MutationMarketingFeatureCancelCampaignArgs = {
  campaignId: Scalars['ID'];
};


export type MutationMarketingFeatureStartCampaignArgs = {
  campaign: StartCampaignInput;
};


export type MutationMarketingFeatureTriggerCampaignArgs = {
  campaignId: Scalars['ID'];
};


export type MutationMatchesAnyRetailerIdVisibilityConditionCreateArgs = {
  matchesAnyRetailerIdVisibilityConditionData: MatchesAnyRetailerIdInput;
};


export type MutationMatchesAnyRetailerIdVisibilityConditionUpdateArgs = {
  matchesAnyRetailerIdVisibilityConditionData: MatchesAnyRetailerIdInput;
};


export type MutationMinimumPlatformVersionVisibilityConditionCreateArgs = {
  minimumPlatformVersionVisibilityConditionData: MinimumPlatformVersionInput;
};


export type MutationMinimumPlatformVersionVisibilityConditionUpdateArgs = {
  minimumPlatformVersionVisibilityConditionData: MinimumPlatformVersionInput;
};


export type MutationNewVerticalUserTargetingVisibilityConditionCreateArgs = {
  newVerticalUserTargetingVisibilityConditionData: NewVerticalUserTargetingInput;
};


export type MutationNewVerticalUserTargetingVisibilityConditionUpdateArgs = {
  newVerticalUserTargetingVisibilityConditionData: NewVerticalUserTargetingInput;
};


export type MutationNormalizedReportsMergeArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  partnerId: Scalars['ID'];
  reportType: MergeableReportTypeEnum;
  retailerId: Scalars['ID'];
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate: Scalars['ISO8601Date'];
};


export type MutationNotificationSubscriptionCreateArgs = {
  accountId: Scalars['ID'];
  notificationTypeId: Scalars['ID'];
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type MutationNotificationSubscriptionDeleteArgs = {
  accountId: Scalars['ID'];
  notificationTypeId: Scalars['ID'];
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type MutationOnboardingMobileAppPropertiesUpdateArgs = {
  mobileAppProperties: Array<PropertyInput>;
  os: Os;
  storeConfigurationId: Scalars['Int'];
};


export type MutationOnboardingServiceCreateOnboardingStoreLocationArgs = {
  input?: InputMaybe<CreateOnboardingStoreLocationRequestInput>;
};


export type MutationOnboardingServiceCreateSignupArgs = {
  input?: InputMaybe<CreateSignupRequestInput>;
};


export type MutationOnboardingServiceDeleteLaunchOnboardingWarehouseLocationArgs = {
  input?: InputMaybe<DeleteLaunchOnboardingWarehouseLocationRequestInput>;
};


export type MutationOnboardingServiceUpdateLaunchOnboardingWarehouseInfoArgs = {
  input?: InputMaybe<UpdateLaunchOnboardingWarehouseInfoRequestInput>;
};


export type MutationOnboardingServiceUpdateLaunchOnboardingWarehouseLocationInfoArgs = {
  input?: InputMaybe<UpdateLaunchOnboardingWarehouseLocationInfoRequestInput>;
};


export type MutationOnboardingServiceUpdateLaunchOnboardingWorkflowStateArgs = {
  input?: InputMaybe<UpdateLaunchOnboardingWorkflowStateRequestInput>;
};


export type MutationOnboardingServiceUpdateOnboardingArgs = {
  input?: InputMaybe<UpdateOnboardingRequestInput>;
};


export type MutationOnboardingServiceUpdateOnboardingBillingAddressArgs = {
  input?: InputMaybe<UpdateOnboardingBillingAddressRequestInput>;
};


export type MutationOnboardingServiceUpdateOnboardingWorkflowStateArgs = {
  input?: InputMaybe<UpdateOnboardingWorkflowStateRequestInput>;
};


export type MutationOnboardingTriggerWorkflowArgs = {
  storeConfigurationId: Scalars['Int'];
  workflowName: Scalars['String'];
  workflowParameters: Array<WorkflowParameter>;
};


export type MutationOrderServiceCancelOrderArgs = {
  input?: InputMaybe<CancelOrderRequestInput>;
};


export type MutationOrderServiceCreateLastmileOrderFromDashboardArgs = {
  input?: InputMaybe<CreateLastmileOrderFromDashboardRequestInput>;
};


export type MutationOrderServiceReopenLastmileOrderArgs = {
  input?: InputMaybe<ReopenLastmileOrderRequestInput>;
};


export type MutationOrderServiceRescheduleOrderArgs = {
  input?: InputMaybe<RescheduleOrderRequestInput>;
};


export type MutationOrderServiceStageLastmileOrderArgs = {
  input?: InputMaybe<StageLastmileOrderRequestInput>;
};


export type MutationPartnerCallbackCreateArgs = {
  event: Scalars['String'];
  orderId: Scalars['String'];
  partnerId: Scalars['ID'];
};


export type MutationPartnerPickDeviceCreateArgs = {
  deviceType: DeviceTypeEnum;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isPartnerPickStoreManager: Scalars['Boolean'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  warehouseLocationId: Scalars['ID'];
};


export type MutationPartnerPickDeviceSetStatusArgs = {
  active: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationPartnerPickDeviceUpdateArgs = {
  deviceType?: InputMaybe<DeviceTypeEnum>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isPartnerPickStoreManager?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  warehouseLocationId?: InputMaybe<Scalars['ID']>;
};


export type MutationPartnerPickRecurringShiftCreateArgs = {
  daysOfWeek: Array<Scalars['Int']>;
  driverId: Scalars['ID'];
  onCall?: InputMaybe<Scalars['Boolean']>;
  recurEndDate?: InputMaybe<Scalars['String']>;
  recurStartDate: Scalars['String'];
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  shiftType: ShiftTypeEnum;
  warehouseLocationId: Scalars['ID'];
};


export type MutationPartnerPickRecurringShiftDeleteArgs = {
  deleteShiftsFromDate: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationPartnerPickRecurringShiftUpdateArgs = {
  daysOfWeek?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['ID'];
  onCall?: InputMaybe<Scalars['Boolean']>;
  recurEndDate?: InputMaybe<Scalars['String']>;
  recurStartDate?: InputMaybe<Scalars['String']>;
  shiftLengthSeconds?: InputMaybe<Scalars['Int']>;
  shiftStartSecondsSinceMidnight?: InputMaybe<Scalars['Int']>;
};


export type MutationPrivacyServiceCreatePiiRequestForRetailerArgs = {
  input?: InputMaybe<CreatePiiRequestForRetailerRequestInput>;
};


export type MutationReportRequestCreateArgs = {
  associatedObjectId: Scalars['ID'];
  associatedObjectType: ReportRequestAssociatedObjectTypeEnum;
  filters?: InputMaybe<ReportFilters>;
  name: Scalars['String'];
  reportId: Scalars['ID'];
};


export type MutationRetailerAislesUpdateRetailerAisleTemplateArgs = {
  input?: InputMaybe<UpdateRetailerAisleTemplateRequestInput>;
};


export type MutationRetailerAvailabilityVisibilityConditionCreateArgs = {
  retailerAvailabilityVisibilityConditionData: RetailerAvailabilityInput;
};


export type MutationRetailerAvailabilityVisibilityConditionUpdateArgs = {
  retailerAvailabilityVisibilityConditionData: RetailerAvailabilityInput;
};


export type MutationRetailerCampaignServiceAddRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<AddRetailerCampaignTreatmentsRequestInput>;
};


export type MutationRetailerCampaignServiceCancelMomentArgs = {
  input?: InputMaybe<CancelMomentRequestInput>;
};


export type MutationRetailerCampaignServiceCancelRetailerCampaignArgs = {
  input?: InputMaybe<CancelRetailerCampaignRequestInput>;
};


export type MutationRetailerCampaignServiceCreateMomentArgs = {
  input?: InputMaybe<CreateMomentRequestInput>;
};


export type MutationRetailerCampaignServiceCreateRetailerCampaignArgs = {
  input?: InputMaybe<CreateRetailerCampaignRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateMomentArgs = {
  input?: InputMaybe<UpdateMomentRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateMomentCampaignStatusArgs = {
  input?: InputMaybe<UpdateMomentCampaignStatusRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateRetailerCampaignArgs = {
  input?: InputMaybe<UpdateRetailerCampaignRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<UpdateRetailerCampaignTreatmentsRequestInput>;
};


export type MutationRetailerLocationGroupCreateArgs = {
  name: Scalars['String'];
  partnerId: Scalars['ID'];
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRetailerLocationGroupDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationRetailerLocationGroupUpdateArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRetailerManagementServiceCreateClientConfigurationArgs = {
  input?: InputMaybe<CreateClientConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceCreateConnectRetailerCallbackConfigurationArgs = {
  input?: InputMaybe<CreateConnectRetailerCallbackConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceCreateStoreConfigurationArgs = {
  input?: InputMaybe<CreateStoreConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceDeleteConnectRetailerCallbackConfigurationArgs = {
  input?: InputMaybe<DeleteConnectRetailerCallbackConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceUpdateConnectRetailerCallbackConfigurationArgs = {
  input?: InputMaybe<UpdateConnectRetailerCallbackConfigurationRequestInput>;
};


export type MutationRetailerMfaSetEnabledArgs = {
  retailerId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationRetailerProductAttributeEditServiceUpdateAvailabilityScoreOverrideArgs = {
  input?: InputMaybe<UpdateAvailabilityScoreOverrideRequestInput>;
};


export type MutationRetailerProductAttributeEditServiceUpdateRetailerProductVisibilitiesArgs = {
  input?: InputMaybe<UpdateRetailerProductVisibilitiesRequestInput>;
};


export type MutationRetailerSatisfactionSurveySubmissionCreateArgs = {
  accountId: Scalars['ID'];
  milestoneSlug?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  shownAt: Scalars['String'];
  submittedAt: Scalars['String'];
  surveyResponses: Array<RetailerSatisfactionSurveyResponse>;
  urlPath: Scalars['String'];
};


export type MutationRetailerSettingsServiceDeleteImageArgs = {
  input?: InputMaybe<DeleteImageRequestInput>;
};


export type MutationRetailerSettingsServiceUpdateParkingSettingsArgs = {
  input?: InputMaybe<UpdateParkingSettingsRequestInput>;
};


export type MutationRetailerSettingsServiceUpdateStagingSettingsArgs = {
  input?: InputMaybe<UpdateStagingSettingsRequestInput>;
};


export type MutationRetailerStoreHoursUpdateConfirmStoreHoursArgs = {
  input?: InputMaybe<UpdateConfirmedStoreHoursRequestInput>;
};


export type MutationRetailerStoreHoursUpdateStoreHoursArgs = {
  input?: InputMaybe<UpdateStoreHoursRequestInput>;
};


export type MutationRetailersServiceMoveRetailerAssetsFromFilestackBucketArgs = {
  input?: InputMaybe<MoveRetailerAssetsFromFilestackBucketRequestInput>;
};


export type MutationReturnsServiceCreateOrUpdateReturnArgs = {
  input?: InputMaybe<CreateOrUpdateReturnRequestInput>;
};


export type MutationRoleCreateArgs = {
  role: CreateRoleInput;
};


export type MutationRoleDeleteArgs = {
  roleId: Scalars['ID'];
};


export type MutationRoleUpdateArgs = {
  role: UpdateRoleInput;
};


export type MutationRppBulkImageUploadsServiceCreateImageUploadBatchArgs = {
  input?: InputMaybe<CreateImageUploadBatchRequestInput>;
};


export type MutationSandboxOrderSimulateEventArgs = {
  callbackEvent?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  partnerId: Scalars['String'];
  simulationEvent: Scalars['String'];
  simulationEventArgs?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSecondaryBannerPlacementCreateArgs = {
  secondaryBannerPlacementData: SecondaryBannerPlacementInput;
};


export type MutationSecondaryBannerPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  id: Scalars['ID'];
  secondaryBannerPlacementData: SecondaryBannerPlacementInput;
};


export type MutationShoppedAtRetailerVisibilityConditionCreateArgs = {
  shoppedAtRetailerVisibilityConditionData: ShoppedAtRetailerInput;
};


export type MutationShoppedAtRetailerVisibilityConditionUpdateArgs = {
  shoppedAtRetailerVisibilityConditionData: ShoppedAtRetailerInput;
};


export type MutationShoppedVisibilityConditionCreateArgs = {
  shoppedVisibilityConditionData: ShoppedInput;
};


export type MutationShoppedVisibilityConditionUpdateArgs = {
  shoppedVisibilityConditionData: ShoppedInput;
};


export type MutationStoreConfigurationsDraftServiceCreateStoreConfigurationDraftArgs = {
  input?: InputMaybe<CreateStoreConfigurationDraftRequestInput>;
};


export type MutationStoreConfigurationsDraftServicePublishStoreConfigurationDraftArgs = {
  input?: InputMaybe<PublishStoreConfigurationDraftRequestInput>;
};


export type MutationStoreConfigurationsDraftServiceRollbackStoreConfigurationArgs = {
  input?: InputMaybe<RollbackStoreConfigurationRequestInput>;
};


export type MutationStoreConfigurationsDraftServiceUpdateStoreConfigurationDraftArgs = {
  input?: InputMaybe<UpdateStoreConfigurationDraftRequestInput>;
};


export type MutationStorefrontPlacementCreateArgs = {
  retailerId: Scalars['ID'];
  storefrontPlacementData: StorefrontPlacementInput;
};


export type MutationStorefrontPlacementDeleteArgs = {
  id: Scalars['ID'];
  retailerId: Scalars['ID'];
};


export type MutationStorefrontPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  id: Scalars['ID'];
  retailerId: Scalars['ID'];
  storefrontPlacementData: StorefrontPlacementInput;
};


export type MutationUserInteractionLimitVisibilityConditionCreateArgs = {
  userInteractionLimitVisibilityConditionData: UserInteractionLimitInput;
};


export type MutationUserInteractionLimitVisibilityConditionUpdateArgs = {
  userInteractionLimitVisibilityConditionData: UserInteractionLimitInput;
};


export type MutationUserSettingServiceSetUserSettingArgs = {
  input?: InputMaybe<SetUserSettingRequestInput>;
};


export type MutationUsersServiceGrantImpersonationArgs = {
  input?: InputMaybe<GrantImpersonationRequestInput>;
};


export type MutationUtmParametersVisibilityConditionCreateArgs = {
  utmParametersVisibilityConditionData: UtmParametersInput;
};


export type MutationUtmParametersVisibilityConditionUpdateArgs = {
  utmParametersVisibilityConditionData: UtmParametersInput;
};


export type MutationValueBasedOverrideServiceCreateValueBasedOverrideArgs = {
  input?: InputMaybe<CreateValueBasedOverrideRequestInput>;
};


export type MutationVisibilityConditionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationWhitelistedPostalCodesVisibilityConditionCreateArgs = {
  whitelistedPostalCodesVisibilityConditionData: WhitelistedPostalCodesInput;
};


export type MutationWhitelistedPostalCodesVisibilityConditionUpdateArgs = {
  whitelistedPostalCodesVisibilityConditionData: WhitelistedPostalCodesInput;
};


export type MutationWhitelistedZonesVisibilityConditionCreateArgs = {
  whitelistedZonesVisibilityConditionData: WhitelistedZonesInput;
};


export type MutationWhitelistedZonesVisibilityConditionUpdateArgs = {
  whitelistedZonesVisibilityConditionData: WhitelistedZonesInput;
};


export type MutationWizardProgressUpdateArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type NavigateToAskInstacart = {
  __typename?: 'NavigateToAskInstacart';
  prompt?: Maybe<Scalars['String']>;
  useBusinessDerivedAttributes?: Maybe<Scalars['Boolean']>;
};

export type NavigateToAskInstacartInput = {
  prompt?: InputMaybe<Scalars['String']>;
  useBusinessDerivedAttributes?: InputMaybe<Scalars['Boolean']>;
};

export type NavigateToAuthentication = {
  __typename?: 'NavigateToAuthentication';
  authentication?: Maybe<Authentication>;
};

export type NavigateToAuthenticationInput = {
  authentication?: InputMaybe<AuthenticationInput>;
};

export type NavigateToBenefitsModal = {
  __typename?: 'NavigateToBenefitsModal';
  id?: Maybe<Scalars['BigInt']>;
};

export type NavigateToBenefitsModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToCategorySurface = {
  __typename?: 'NavigateToCategorySurface';
  categorySurfaceType?: Maybe<CategorySurfaceType>;
};

export type NavigateToCategorySurfaceInput = {
  categorySurfaceType?: InputMaybe<CategorySurfaceType>;
};

export type NavigateToCollaborativeShopInfoModal = {
  __typename?: 'NavigateToCollaborativeShopInfoModal';
  id?: Maybe<Scalars['BigInt']>;
};

export type NavigateToCollaborativeShopInfoModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToCollection = {
  __typename?: 'NavigateToCollection';
  legacyPath?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type NavigateToCollectionHub = {
  __typename?: 'NavigateToCollectionHub';
  category?: Maybe<Scalars['String']>;
  collection?: Maybe<Scalars['String']>;
};

export type NavigateToCollectionHubInput = {
  category?: InputMaybe<Scalars['String']>;
  collection?: InputMaybe<Scalars['String']>;
};

export type NavigateToCollectionInput = {
  legacyPath?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type NavigateToContainerPathViaStoreSelector = {
  __typename?: 'NavigateToContainerPathViaStoreSelector';
  retailers?: Maybe<Array<Maybe<RetailerContainerPath>>>;
};

export type NavigateToContainerPathViaStoreSelectorInput = {
  retailers?: InputMaybe<Array<InputMaybe<RetailerContainerPathInput>>>;
};

export type NavigateToContentPage = {
  __typename?: 'NavigateToContentPage';
  slug?: Maybe<Scalars['String']>;
};

export type NavigateToContentPageInput = {
  slug?: InputMaybe<Scalars['String']>;
};

export type NavigateToCouponRedemption = {
  __typename?: 'NavigateToCouponRedemption';
  couponCode?: Maybe<Scalars['String']>;
  landingUrl?: Maybe<Scalars['String']>;
  validateDays?: Maybe<Scalars['BigInt']>;
  visibleAfterRedeem?: Maybe<Scalars['Boolean']>;
};

export type NavigateToCouponRedemptionInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  validateDays?: InputMaybe<Scalars['BigInt']>;
  visibleAfterRedeem?: InputMaybe<Scalars['Boolean']>;
};

export type NavigateToDepartment = {
  __typename?: 'NavigateToDepartment';
  departmentId?: Maybe<Scalars['BigInt']>;
};

export type NavigateToDepartmentInput = {
  departmentId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToExpressAccountPage = {
  __typename?: 'NavigateToExpressAccountPage';
  id?: Maybe<Scalars['BigInt']>;
};

export type NavigateToExpressAccountPageInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToFlyers = {
  __typename?: 'NavigateToFlyers';
  tag?: Maybe<Scalars['String']>;
};

export type NavigateToFlyersInput = {
  tag?: InputMaybe<Scalars['String']>;
};

export type NavigateToHouseholdAccountSharingFlow = {
  __typename?: 'NavigateToHouseholdAccountSharingFlow';
  id?: Maybe<Scalars['BigInt']>;
};

export type NavigateToHouseholdAccountSharingFlowInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToPickupStoreSelector = {
  __typename?: 'NavigateToPickupStoreSelector';
  showDistance?: Maybe<Scalars['Boolean']>;
};

export type NavigateToPickupStoreSelectorInput = {
  showDistance?: InputMaybe<Scalars['Boolean']>;
};

export type NavigateToProduct = {
  __typename?: 'NavigateToProduct';
  productId?: Maybe<Scalars['BigInt']>;
};

export type NavigateToProductInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToPromoModal = {
  __typename?: 'NavigateToPromoModal';
  discountPolicyId?: Maybe<Scalars['BigInt']>;
};

export type NavigateToPromoModalInput = {
  discountPolicyId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToPromotionDetail = {
  __typename?: 'NavigateToPromotionDetail';
  id?: Maybe<Scalars['BigInt']>;
};

export type NavigateToPromotionDetailInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToRetailerCollection = {
  __typename?: 'NavigateToRetailerCollection';
  retailerId?: Maybe<Scalars['BigInt']>;
  slug?: Maybe<Scalars['String']>;
};

export type NavigateToRetailerCollectionInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type NavigateToRetailerCollectionViaStoreSelector = {
  __typename?: 'NavigateToRetailerCollectionViaStoreSelector';
  retailerCollections?: Maybe<Array<Maybe<RetailerCollection>>>;
};

export type NavigateToRetailerCollectionViaStoreSelectorInput = {
  retailerCollections?: InputMaybe<Array<InputMaybe<RetailerCollectionInput>>>;
};

export type NavigateToSsaStoreSelector = {
  __typename?: 'NavigateToSsaStoreSelector';
  serviceAreaType?: Maybe<Scalars['String']>;
};

export type NavigateToSsaStoreSelectorInput = {
  serviceAreaType?: InputMaybe<Scalars['String']>;
};

export type NavigateToStoreSelector = {
  __typename?: 'NavigateToStoreSelector';
  retailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  storeForwardModuleType?: Maybe<Scalars['String']>;
};

export type NavigateToStoreSelectorInput = {
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  storeForwardModuleType?: InputMaybe<Scalars['String']>;
};

export type NavigateToStorefront = {
  __typename?: 'NavigateToStorefront';
  retailerId?: Maybe<Scalars['BigInt']>;
};

export type NavigateToStorefrontInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToUrl = {
  __typename?: 'NavigateToUrl';
  openInNewTab?: Maybe<Scalars['Boolean']>;
  openInNewWindow?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type NavigateToUrlInput = {
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Navigation = {
  __typename?: 'Navigation';
  id?: Maybe<Scalars['BigInt']>;
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  locale?: Maybe<InstacartCustomersSharedV1Locale>;
  logoUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
};

export type NavigationAction = {
  __typename?: 'NavigationAction';
  data?: Maybe<Scalars['JSONObject']>;
  typeName?: Maybe<Scalars['String']>;
};

export type NavigationActionInput = {
  data?: InputMaybe<Scalars['JSONObject']>;
  typeName?: InputMaybe<Scalars['String']>;
};

export type NavigationInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  items?: InputMaybe<Array<InputMaybe<NavigationItemInput>>>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  logoUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  actions?: Maybe<Array<Maybe<NavigationAction>>>;
  groupPosition?: Maybe<Scalars['String']>;
  grouping?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  isNested?: Maybe<Scalars['Boolean']>;
  itemType?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  position?: Maybe<Scalars['BigInt']>;
  text?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type NavigationItemInput = {
  actions?: InputMaybe<Array<InputMaybe<NavigationActionInput>>>;
  groupPosition?: InputMaybe<Scalars['String']>;
  grouping?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isNested?: InputMaybe<Scalars['Boolean']>;
  itemType?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  position?: InputMaybe<Scalars['BigInt']>;
  text?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
};

export type NewVerticalUserTargetingInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** The module type */
  moduleType: Scalars['String'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of NewVerticalUserTargetingVisibilityConditionCreate */
export type NewVerticalUserTargetingVisibilityConditionCreatePayload = {
  __typename?: 'NewVerticalUserTargetingVisibilityConditionCreatePayload';
  /** The newly created NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityCondition: ContentManagementVisibilityConditionNewVerticalUserTargeting;
};

/** Autogenerated return type of NewVerticalUserTargetingVisibilityConditionUpdate */
export type NewVerticalUserTargetingVisibilityConditionUpdatePayload = {
  __typename?: 'NewVerticalUserTargetingVisibilityConditionUpdatePayload';
  /** The newly updated NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityCondition: ContentManagementVisibilityConditionNewVerticalUserTargeting;
};

export type NonenumeratedManagedAttributes = {
  __typename?: 'NonenumeratedManagedAttributes';
  digitalScaleRequired?: Maybe<Scalars['Boolean']>;
  lookupCodePluOnly?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Autogenerated return type of NormalizedReportsMerge */
export type NormalizedReportsMergePayload = {
  __typename?: 'NormalizedReportsMergePayload';
  /** Merge successful */
  success: Scalars['Boolean'];
};

export type NormalizedReportsNormalizedReport = {
  __typename?: 'NormalizedReportsNormalizedReport';
  /** Name of the bucket where the report comes from */
  bucketName: Scalars['String'];
  /** Date at which the report was created in YYYY-MM-DD format */
  createdAt: Scalars['Time'];
  /** Type of the file */
  fileType: Scalars['String'];
  /** Name of the file */
  name: Scalars['String'];
  /** Type of the report */
  reportType: ReportTypeEnum;
  /** Retailer ID */
  retailerId: Scalars['String'];
  /** File path to the object in the bucket */
  s3Key: Scalars['String'];
  /** Size in bytes of the object */
  size: Scalars['Int'];
};

/** The connection type for NormalizedReportsNormalizedReport. */
export type NormalizedReportsNormalizedReportConnection = {
  __typename?: 'NormalizedReportsNormalizedReportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NormalizedReportsNormalizedReportEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NormalizedReportsNormalizedReport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NormalizedReportsNormalizedReportEdge = {
  __typename?: 'NormalizedReportsNormalizedReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NormalizedReportsNormalizedReport>;
};

export type NormalizedReportsNormalizedReportSignedUrl = {
  __typename?: 'NormalizedReportsNormalizedReportSignedUrl';
  /** Signed url for the object */
  signedUrl: Scalars['String'];
};

export type NormalizedReportsReportType = {
  __typename?: 'NormalizedReportsReportType';
  /** Report type label */
  label: Scalars['String'];
  /** The report type value */
  value: ReportTypeEnum;
};

/** Autogenerated return type of NotificationSubscriptionCreate */
export type NotificationSubscriptionCreatePayload = {
  __typename?: 'NotificationSubscriptionCreatePayload';
  /** Subscription successful */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of NotificationSubscriptionDelete */
export type NotificationSubscriptionDeletePayload = {
  __typename?: 'NotificationSubscriptionDeletePayload';
  /** Unsubscribe successful */
  success: Scalars['Boolean'];
};

export type NotificationsNotificationType = {
  __typename?: 'NotificationsNotificationType';
  /** Description of notification type, ie: Inventory File Normalization */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If the user making the request is eligible to subscribe to this notification */
  isEligible: Scalars['Boolean'];
  /** If the user making the request is subscribed to the notification type for the specified retailer */
  isSubscribed: Scalars['Boolean'];
  /** Name of notification type, ie: Inventory File Normalization */
  name: Scalars['String'];
};

export type NotificationsNotificationTypeAccount = {
  __typename?: 'NotificationsNotificationTypeAccount';
  /** Account ID of user */
  accountId: Scalars['ID'];
  /** Email of user */
  email: Scalars['ID'];
  /** Given name of user */
  givenName: Scalars['String'];
  /** If the user is eligible to subscribe to this notification */
  isEligible: Scalars['Boolean'];
  /** If the user is subscribed to the notification type for the specified retailer */
  isSubscribed: Scalars['Boolean'];
  /** Surname of user */
  surname?: Maybe<Scalars['String']>;
};

export type ObjectKeyMappingInput = {
  destinationObjectKey?: InputMaybe<Scalars['String']>;
  sourceObjectKey?: InputMaybe<Scalars['String']>;
};

export type OffersSchema = {
  __typename?: 'OffersSchema';
  disableFlierPlacement?: Maybe<Scalars['Boolean']>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  billingAddress?: Maybe<Address>;
  contactInfo?: Maybe<Contact>;
  contractRenderData?: Maybe<ContractRenderData>;
  corporationType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  duns?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  exclusivity?: Maybe<Scalars['String']>;
  ironcladData?: Maybe<IroncladData>;
  isaImageSignedUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  isaPdfSignedUrl?: Maybe<Scalars['String']>;
  ndaImageSignedUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  ndaPdfSignedUrl?: Maybe<Scalars['String']>;
  onboardingType?: Maybe<Scalars['String']>;
  posSystemName?: Maybe<Scalars['String']>;
  signupPartner?: Maybe<SignupPartner>;
  stateOfIncorporation?: Maybe<Scalars['String']>;
  storeCount?: Maybe<Scalars['String']>;
  storeLocation?: Maybe<Address>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  w9Form?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  workflowState?: Maybe<Scalars['String']>;
};

export type OnboardingFeatureTypesMobileAppConfig = {
  __typename?: 'OnboardingFeatureTypesMobileAppConfig';
  /** The asset information for uploaded items */
  assets: Array<MutableS3Asset>;
  /** The properties for the app */
  properties: Array<OnboardingFeatureTypesProperty>;
};

export type OnboardingFeatureTypesProperty = {
  __typename?: 'OnboardingFeatureTypesProperty';
  /** The property name */
  name: Scalars['String'];
  /** The property value */
  value?: Maybe<Scalars['JSON']>;
};

export type OnboardingFeatureTypesWorkflow = {
  __typename?: 'OnboardingFeatureTypesWorkflow';
  /** The time the workflow was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The workflow id */
  id: Scalars['Int'];
  /** The workflow being run */
  name: Scalars['String'];
  /** The status of the workflow */
  status: Scalars['String'];
  /** The time the status was last updated */
  statusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** The url to the workflow */
  url?: Maybe<Scalars['String']>;
};

export enum OnboardingWorkflowStateAction {
  OnboardingWorkflowStateActionComplete = 'ONBOARDING_WORKFLOW_STATE_ACTION_COMPLETE',
  OnboardingWorkflowStateActionProvideBillingAddress = 'ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_BILLING_ADDRESS',
  OnboardingWorkflowStateActionProvideOnboardingInfo = 'ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_ONBOARDING_INFO',
  OnboardingWorkflowStateActionProvideStoreLocations = 'ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_STORE_LOCATIONS',
  OnboardingWorkflowStateActionSignIsa = 'ONBOARDING_WORKFLOW_STATE_ACTION_SIGN_ISA',
  OnboardingWorkflowStateActionSignNda = 'ONBOARDING_WORKFLOW_STATE_ACTION_SIGN_NDA',
  OnboardingWorkflowStateActionStart = 'ONBOARDING_WORKFLOW_STATE_ACTION_START',
  OnboardingWorkflowStateActionUnknown = 'ONBOARDING_WORKFLOW_STATE_ACTION_UNKNOWN',
  OnboardingWorkflowStateActionViewIsa = 'ONBOARDING_WORKFLOW_STATE_ACTION_VIEW_ISA',
  OnboardingWorkflowStateActionViewNda = 'ONBOARDING_WORKFLOW_STATE_ACTION_VIEW_NDA'
}

export type OpenExpressPaidModal = {
  __typename?: 'OpenExpressPaidModal';
  id?: Maybe<Scalars['BigInt']>;
};

export type OpenExpressPaidModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type OpenExpressTrialModal = {
  __typename?: 'OpenExpressTrialModal';
  id?: Maybe<Scalars['BigInt']>;
};

export type OpenExpressTrialModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type OperationalHours = {
  __typename?: 'OperationalHours';
  displayableTimePeriods?: Maybe<Array<Maybe<TimePeriod>>>;
  isCrossDay?: Maybe<Scalars['Boolean']>;
  isOpenAllDay?: Maybe<Scalars['Boolean']>;
  timePeriods?: Maybe<Array<Maybe<TimePeriod>>>;
};

export type OptInUser = {
  __typename?: 'OptInUser';
  id?: Maybe<Scalars['BigInt']>;
  optOut?: Maybe<Scalars['Boolean']>;
};

export type OptInUserInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  optOut?: InputMaybe<Scalars['Boolean']>;
};

export type OrderByComponentInput = {
  asc?: InputMaybe<Scalars['Boolean']>;
  component?: InputMaybe<Scalars['String']>;
};

export type OrderInput = {
  checkoutRetailerLocationId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  shoppedRetailerLocationId?: InputMaybe<Scalars['BigInt']>;
  zoneId?: InputMaybe<Scalars['BigInt']>;
};

export type OrderSources = {
  __typename?: 'OrderSources';
  /** Order Sources */
  orderSources?: Maybe<Array<Scalars['String']>>;
};

export type OrdersSchema = {
  __typename?: 'OrdersSchema';
  disablePostCheckoutDeliveryFallbackImage?: Maybe<Scalars['Boolean']>;
  disableSpecialRequests?: Maybe<Scalars['Boolean']>;
  tlogIngestionEnabled?: Maybe<Scalars['Boolean']>;
};

export enum Os {
  /** Android app */
  Android = 'android',
  /** iOS app */
  IOs = 'iOS'
}

export type OutsourceDataEntryItem = {
  __typename?: 'OutsourceDataEntryItem';
  outsourceItemId?: Maybe<Scalars['String']>;
};

export type OverrideHours = {
  __typename?: 'OverrideHours';
  date?: Maybe<GoogleTypeDate>;
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<GoogleTypeTimeOfDay>;
  serviceType?: Maybe<RetailerServiceType>;
  startTime?: Maybe<GoogleTypeTimeOfDay>;
};

export type OverrideHoursInput = {
  date?: InputMaybe<GoogleTypeDateInput>;
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
  serviceType?: InputMaybe<RetailerServiceType>;
  startTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
};

export enum OverrideType {
  OverrideTypeExclude = 'OVERRIDE_TYPE_EXCLUDE',
  OverrideTypeReplace = 'OVERRIDE_TYPE_REPLACE',
  OverrideTypeUnknown = 'OVERRIDE_TYPE_UNKNOWN'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  limit?: Maybe<Scalars['BigInt']>;
  offset?: Maybe<Scalars['BigInt']>;
};

export type PaginationDetails = {
  __typename?: 'PaginationDetails';
  page?: Maybe<Scalars['BigInt']>;
  perPage?: Maybe<Scalars['BigInt']>;
  totalCount?: Maybe<Scalars['BigInt']>;
  totalPages?: Maybe<Scalars['BigInt']>;
};

export type PaginationDetailsInput = {
  page?: InputMaybe<Scalars['BigInt']>;
  perPage?: InputMaybe<Scalars['BigInt']>;
  totalCount?: InputMaybe<Scalars['BigInt']>;
  totalPages?: InputMaybe<Scalars['BigInt']>;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
};

export type Partner = {
  __typename?: 'Partner';
  /** A unique identifier for the partner */
  id: Scalars['ID'];
  /** The name of the partner */
  name: Scalars['String'];
  /** A unique identifier for the partner */
  partnerId?: Maybe<Scalars['ID']>;
  /** The type of partner */
  partnerType?: Maybe<Scalars['String']>;
  /** List of retailer IDs for this partner */
  retailerIds: Array<Scalars['ID']>;
  /** The retailers belonging to this partner */
  retailers: Array<Retailer>;
  /** A sandbox order belonging to the partner */
  sandboxOrder?: Maybe<PartnerSandboxOrder>;
};


export type PartnerSandboxOrderArgs = {
  id: Scalars['String'];
};

export type PartnerCallbackConfiguration = {
  __typename?: 'PartnerCallbackConfiguration';
  /** The access token TTL */
  accessTokenTtl: Scalars['Int'];
  /** The authentication URI */
  authUri: Scalars['String'];
  /** The possible statuses to enable */
  availableStatuses: Array<Scalars['String']>;
  /** The callback URI */
  callbackUri: Scalars['String'];
  /** The client id */
  credentialsClientId: Scalars['String'];
  /** The client credentials_client_secret */
  credentialsClientSecret: Scalars['String'];
  /** The environment that the configuration belongs to */
  environment: Scalars['String'];
  /** A unique identifier for the callback configuration */
  id: Scalars['ID'];
  /** The enabled statusers */
  statusesOfInterest: Array<Scalars['String']>;
};

/** Autogenerated return type of PartnerCallbackConfigurationUpdate */
export type PartnerCallbackConfigurationUpdatePayload = {
  __typename?: 'PartnerCallbackConfigurationUpdatePayload';
  /** The updated callback configuration */
  callbackConfiguration: PartnerCallbackConfiguration;
};

/** Autogenerated return type of PartnerCallbackCreate */
export type PartnerCallbackCreatePayload = {
  __typename?: 'PartnerCallbackCreatePayload';
  /** The response(s) from the callback */
  callbackResponse: Array<PartnerCallbackResponse>;
};

export type PartnerCallbackResponse = {
  __typename?: 'PartnerCallbackResponse';
  /** The body that was sent */
  body: Scalars['String'];
  /** The headers that were sent */
  headers: Scalars['String'];
  /** The type of the notification log */
  logType: Scalars['String'];
  /** The response body */
  responseBody: Scalars['String'];
  /** The response code */
  responseCode: Scalars['String'];
  /** The time when the callback was sent */
  timestamp: Scalars['String'];
  /** The url the callback was sent to */
  url: Scalars['String'];
};

/** The connection type for Partner. */
export type PartnerConnection = {
  __typename?: 'PartnerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PartnerEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Partner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PartnerEdge = {
  __typename?: 'PartnerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Partner>;
};

export type PartnerPickCapacityGetCapacityRecommendationsResponse = {
  __typename?: 'PartnerPickCapacityGetCapacityRecommendationsResponse';
  allowedRecommendationTypes: Array<RecommendationTypeEnum>;
  recommendations: Array<PartnerPickCapacityRecommendation>;
};

export type PartnerPickCapacityRecommendation = {
  __typename?: 'PartnerPickCapacityRecommendation';
  date: Scalars['ISO8601Date'];
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  recommendationType: RecommendationTypeEnum;
  retailerLocationId: Scalars['ID'];
  shiftType: ShiftTypeEnum;
  startsAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of PartnerPickDeviceCreate */
export type PartnerPickDeviceCreatePayload = {
  __typename?: 'PartnerPickDeviceCreatePayload';
  /** The newly created device */
  partnerPickDevice: PartnerPickDevicesDevice;
};

/** Autogenerated return type of PartnerPickDeviceSetStatus */
export type PartnerPickDeviceSetStatusPayload = {
  __typename?: 'PartnerPickDeviceSetStatusPayload';
  /** The updated device */
  partnerPickDevice: PartnerPickDevicesDevice;
};

/** Autogenerated return type of PartnerPickDeviceUpdate */
export type PartnerPickDeviceUpdatePayload = {
  __typename?: 'PartnerPickDeviceUpdatePayload';
  /** The updated device */
  partnerPickDevice: PartnerPickDevicesDevice;
};

export type PartnerPickDevicesDevice = {
  __typename?: 'PartnerPickDevicesDevice';
  /** Status for the device */
  active: Scalars['Boolean'];
  /** Type of device */
  deviceType: DeviceTypeEnum;
  /** Email address for the device */
  email: Scalars['String'];
  /** First name for the device */
  firstName: Scalars['String'];
  /** A unique identifier for the device */
  id: Scalars['ID'];
  /** Whether the device belongs to a store manager */
  isPartnerPickStoreManager: Scalars['Boolean'];
  /** Last name for the device */
  lastName: Scalars['String'];
  /** marqeta cards metatdata for the device */
  marqetaCardsMetadata?: Maybe<PartnerPickDevicesMarqetaCardsMetadata>;
  /** Phone number for the device */
  phone: Scalars['String'];
};

export type PartnerPickDevicesDeviceSearch = {
  __typename?: 'PartnerPickDevicesDeviceSearch';
  devices: Array<PartnerPickDevicesDevice>;
  meta: HubPaginationMeta;
};

export type PartnerPickDevicesMarqetaCardsMetadata = {
  __typename?: 'PartnerPickDevicesMarqetaCardsMetadata';
  digital?: Maybe<Scalars['Int']>;
  inactive?: Maybe<Scalars['Int']>;
  physical?: Maybe<Scalars['Int']>;
  suspended?: Maybe<Scalars['Int']>;
  terminated?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of PartnerPickRecurringShiftCreate */
export type PartnerPickRecurringShiftCreatePayload = {
  __typename?: 'PartnerPickRecurringShiftCreatePayload';
  /** The newly created recurring shift */
  partnerPickRecurringShift: PartnerPickRecurringShiftsRecurringShift;
};

/** Autogenerated return type of PartnerPickRecurringShiftDelete */
export type PartnerPickRecurringShiftDeletePayload = {
  __typename?: 'PartnerPickRecurringShiftDeletePayload';
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether the deletion succeeded or not */
  success: Scalars['True'];
};

/** Autogenerated return type of PartnerPickRecurringShiftUpdate */
export type PartnerPickRecurringShiftUpdatePayload = {
  __typename?: 'PartnerPickRecurringShiftUpdatePayload';
  /** The updated recurring shift */
  partnerPickRecurringShift: PartnerPickRecurringShiftsRecurringShift;
};

export type PartnerPickRecurringShiftsConflict = {
  __typename?: 'PartnerPickRecurringShiftsConflict';
  recurringShiftConflicts: Array<PartnerPickRecurringShiftsRecurringShiftConflict>;
  singleShiftConflicts: Array<PartnerPickRecurringShiftsSingleShiftConflict>;
};

export type PartnerPickRecurringShiftsRecurringShift = {
  __typename?: 'PartnerPickRecurringShiftsRecurringShift';
  /** an array of Ruby Date wday that a shift recurs on (0-6, Sunday is zero) */
  daysOfWeek: Array<Scalars['Int']>;
  driverId: Scalars['ID'];
  driverShiftId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  onCall?: Maybe<Scalars['Boolean']>;
  recurEndDate?: Maybe<Scalars['ISO8601Date']>;
  recurStartDate?: Maybe<Scalars['ISO8601Date']>;
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  shiftType: ShiftTypeEnum;
};

export type PartnerPickRecurringShiftsRecurringShiftConflict = {
  __typename?: 'PartnerPickRecurringShiftsRecurringShiftConflict';
  /** Days of the week when a shift occurs, represented as an Int (0-6). Sunday is 0 */
  daysOfWeek: Array<Scalars['Int']>;
  id: Scalars['ID'];
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  shiftType: ShiftTypeEnum;
};

export type PartnerPickRecurringShiftsSingleShiftConflict = {
  __typename?: 'PartnerPickRecurringShiftsSingleShiftConflict';
  date: Scalars['ISO8601Date'];
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  shiftType: ShiftTypeEnum;
  startsAt: Scalars['ISO8601DateTime'];
};

export type PartnerSandboxOrder = {
  __typename?: 'PartnerSandboxOrder';
  /** The logs from callback attempts for the sandbox order */
  callbackLogs?: Maybe<Array<PartnerSandboxOrderCallbackLog>>;
  /** Timestamp when the sandbox order was created */
  createdAt: Scalars['String'];
  /** Timestamp when the sandbox order was delivered */
  deliveredAt?: Maybe<Scalars['String']>;
  /** A unique identifier for the sandbox order */
  id: Scalars['ID'];
  /** Items within the sandbox order */
  items: Array<PartnerSandboxOrderItem>;
  /** Events that can be simulated on this order */
  simulationEvents: Array<PartnerSandboxOrderSimulationEvent>;
  /** The fulfillment status of the sandbox order */
  status: Scalars['String'];
  /** Timestamp when the sandbox order delivery window ends */
  windowEndsAt: Scalars['String'];
  /** Timestamp when the sandbox order delivery window starts */
  windowStartsAt: Scalars['String'];
};

export type PartnerSandboxOrderCallbackLog = {
  __typename?: 'PartnerSandboxOrderCallbackLog';
  /** The event that the callback is for */
  event: Scalars['String'];
  /** The response for the callback */
  logs?: Maybe<Array<PartnerCallbackResponse>>;
};

export type PartnerSandboxOrderItem = {
  __typename?: 'PartnerSandboxOrderItem';
  /** Retailer reference code to identify the item */
  itemRrc?: Maybe<Scalars['String']>;
  /** Universal product code to identify the item */
  itemUpc?: Maybe<Scalars['String']>;
  /** The unique line number decided by the partner */
  lineNum: Scalars['String'];
  /** The quantity of items for the order */
  qty: Scalars['Float'];
  /** The quantity unit the quantity value is in */
  qtyUnit: Scalars['String'];
  /** Whether or not this item was replaced during fulfillment */
  replaced: Scalars['Boolean'];
};

/** Autogenerated return type of PartnerSandboxOrderSimulateEvent */
export type PartnerSandboxOrderSimulateEventPayload = {
  __typename?: 'PartnerSandboxOrderSimulateEventPayload';
  /** The order that went through simulation */
  sandboxOrder: PartnerSandboxOrder;
};

export type PartnerSandboxOrderSimulationEvent = {
  __typename?: 'PartnerSandboxOrderSimulationEvent';
  /** Arguments required when simulating this event */
  arguments: Array<Scalars['String']>;
  /** The name of this simulation event */
  event: Scalars['String'];
  /** The ID of the sandbox order these simulation events belong to */
  orderId: Scalars['String'];
  /** The result when this event is simulated */
  outcome: PartnerSandboxOrderSimulationEventOutcome;
};

export type PartnerSandboxOrderSimulationEventOutcome = {
  __typename?: 'PartnerSandboxOrderSimulationEventOutcome';
  /** A callback that will be triggered when the event is simulated */
  callback?: Maybe<Scalars['String']>;
  /** The status the order will be when the event is simulated */
  orderStatus: Scalars['String'];
};

export type PaymentsSchema = {
  __typename?: 'PaymentsSchema';
  useSiteNameForIcWalletBranding?: Maybe<Scalars['Boolean']>;
};

export type Permission = {
  __typename?: 'Permission';
  /** Indicates if the permission can be used for Retailer custom roles */
  isRetailerRoleAssignable: Scalars['Boolean'];
  /** A unique identifier for the permission */
  slug: Scalars['ID'];
};

export type PermissionInput = {
  /** A unique identifier for the permission */
  slug: Scalars['ID'];
};

export enum PickingModelEnum {
  /** Carrotwork */
  Carrotwork = 'CARROTWORK',
  /** Full service shoppers */
  FullService = 'FULL_SERVICE',
  /** In store shoppers */
  Iss = 'ISS',
  /** Partner managed */
  Retailer = 'RETAILER'
}

export enum PickupType {
  PickupTypeCurbside = 'PICKUP_TYPE_CURBSIDE',
  PickupTypeInStore = 'PICKUP_TYPE_IN_STORE',
  PickupTypeUnknown = 'PICKUP_TYPE_UNKNOWN'
}

export type PiiRequest = {
  __typename?: 'PiiRequest';
  createdAt?: Maybe<Scalars['DateTime']>;
  downloadLink?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['BigInt']>;
  requestType?: Maybe<RequestType>;
  retailerNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['BigInt']>;
  workflowStatus?: Maybe<WorkflowStatus>;
};

export enum PiiRequestCreationStatus {
  PiiRequestCreationStatusDuplicateError = 'PII_REQUEST_CREATION_STATUS_DUPLICATE_ERROR',
  PiiRequestCreationStatusInternalError = 'PII_REQUEST_CREATION_STATUS_INTERNAL_ERROR',
  PiiRequestCreationStatusNotFound = 'PII_REQUEST_CREATION_STATUS_NOT_FOUND',
  PiiRequestCreationStatusSuccess = 'PII_REQUEST_CREATION_STATUS_SUCCESS',
  PiiRequestCreationStatusUnspecified = 'PII_REQUEST_CREATION_STATUS_UNSPECIFIED',
  PiiRequestCreationStatusUserNotFound = 'PII_REQUEST_CREATION_STATUS_USER_NOT_FOUND',
  PiiRequestCreationStatusUserStoreConfigurationNotFound = 'PII_REQUEST_CREATION_STATUS_USER_STORE_CONFIGURATION_NOT_FOUND'
}

export type Placement = {
  __typename?: 'Placement';
  banner?: Maybe<BannerType>;
  banners?: Maybe<Array<Maybe<BannerType>>>;
  cardList?: Maybe<CardList>;
  contentPageVersionId?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  excludedRetailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  experimentMethod?: Maybe<ExperimentMethod>;
  experimentName?: Maybe<Scalars['String']>;
  experimentVariant?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  includedRetailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  internalName?: Maybe<Scalars['String']>;
  itemList?: Maybe<ItemList>;
  layout?: Maybe<Layout>;
  layoutVariantId?: Maybe<Scalars['BigInt']>;
  platform?: Maybe<Array<Maybe<PlatformType>>>;
  rank?: Maybe<Scalars['BigInt']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Status>;
  stringLayout?: Maybe<Scalars['String']>;
  teamOwner?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userOwner?: Maybe<Scalars['BigInt']>;
  widget?: Maybe<Widget>;
};

export enum PlacementAdminContextAudience {
  AdminContextAudienceUnspecified = 'ADMIN_CONTEXT_AUDIENCE_UNSPECIFIED',
  InstacartAdmins = 'INSTACART_ADMINS',
  ThirdPartyAdmins = 'THIRD_PARTY_ADMINS'
}

export type PlacementAdminContextInput = {
  audience?: InputMaybe<PlacementAdminContextAudience>;
};

export type PlacementContent = {
  __typename?: 'PlacementContent';
  contentPageVersionId?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  excludedRetailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  experimentMethod?: Maybe<Scalars['String']>;
  experimentName?: Maybe<Scalars['String']>;
  experimentVariant?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  includedRetailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  internalName?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['String']>;
  layoutVariantId?: Maybe<Scalars['BigInt']>;
  parentPlacementId?: Maybe<Scalars['BigInt']>;
  placementBucketId?: Maybe<Scalars['BigInt']>;
  placementType?: Maybe<Scalars['String']>;
  placementTypeData?: Maybe<Array<Maybe<PlacementContentDataStruct>>>;
  platform?: Maybe<Array<Maybe<Scalars['String']>>>;
  rank?: Maybe<Scalars['BigInt']>;
  slotCreatives?: Maybe<Array<Maybe<SlotCreative>>>;
  slots?: Maybe<Array<Maybe<Slot>>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  teamOwner?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userOwner?: Maybe<Scalars['String']>;
  visibilityConditions?: Maybe<Array<Maybe<VisibilityConditionStruct>>>;
};

export type PlacementContentDataStruct = {
  __typename?: 'PlacementContentDataStruct';
  contentData?: Maybe<Scalars['JSONObject']>;
  contentDataId?: Maybe<Scalars['BigInt']>;
  contentType?: Maybe<Scalars['String']>;
  contentVariant?: Maybe<Scalars['String']>;
};

export type PlacementContentDataStructInput = {
  contentData?: InputMaybe<Scalars['JSONObject']>;
  contentDataId?: InputMaybe<Scalars['BigInt']>;
  contentType?: InputMaybe<Scalars['String']>;
  contentVariant?: InputMaybe<Scalars['String']>;
};

export type PlacementContentInput = {
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  excludedRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  experimentMethod?: InputMaybe<Scalars['String']>;
  experimentName?: InputMaybe<Scalars['String']>;
  experimentVariant?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  includedRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  internalName?: InputMaybe<Scalars['String']>;
  layout?: InputMaybe<Scalars['String']>;
  layoutVariantId?: InputMaybe<Scalars['BigInt']>;
  parentPlacementId?: InputMaybe<Scalars['BigInt']>;
  placementBucketId?: InputMaybe<Scalars['BigInt']>;
  placementType?: InputMaybe<Scalars['String']>;
  placementTypeData?: InputMaybe<Array<InputMaybe<PlacementContentDataStructInput>>>;
  platform?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rank?: InputMaybe<Scalars['BigInt']>;
  slotCreatives?: InputMaybe<Array<InputMaybe<SlotCreativeInput>>>;
  slots?: InputMaybe<Array<InputMaybe<SlotInput>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  teamOwner?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userOwner?: InputMaybe<Scalars['String']>;
  visibilityConditions?: InputMaybe<Array<InputMaybe<VisibilityConditionStructInput>>>;
};

export type PlacementContentRequestPagination = {
  __typename?: 'PlacementContentRequestPagination';
  orderBy?: Maybe<Array<Maybe<PlacementContentRequestPaginationOrder>>>;
  pageNumber?: Maybe<Scalars['BigInt']>;
  pageSize?: Maybe<Scalars['BigInt']>;
};

export type PlacementContentRequestPaginationInput = {
  orderBy?: InputMaybe<Array<InputMaybe<PlacementContentRequestPaginationOrderInput>>>;
  pageNumber?: InputMaybe<Scalars['BigInt']>;
  pageSize?: InputMaybe<Scalars['BigInt']>;
};

export type PlacementContentRequestPaginationOrder = {
  __typename?: 'PlacementContentRequestPaginationOrder';
  descending?: Maybe<Scalars['Boolean']>;
  placementId?: Maybe<Scalars['BigInt']>;
  placementRank?: Maybe<Scalars['BigInt']>;
};

export type PlacementContentRequestPaginationOrderInput = {
  descending?: InputMaybe<Scalars['Boolean']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  placementRank?: InputMaybe<Scalars['BigInt']>;
};

export type PlacementContentResponsePagination = {
  __typename?: 'PlacementContentResponsePagination';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  requestPagination?: Maybe<PlacementContentRequestPagination>;
};

export type PlacementContentSurfaceInput = {
  surfaceIdentifier?: InputMaybe<Scalars['String']>;
  surfaceType?: InputMaybe<Scalars['String']>;
};

export type PlacementDestinationInput = {
  /** The target destination of interacting with the placement. Can be an ID of a product/collection/department, or a URL */
  target: Scalars['String'];
  /** What the corresponding target refers to */
  type: PlacementDestinationTypeEnum;
};

export enum PlacementDestinationTypeEnum {
  /** The placement links to a collection */
  Collection = 'COLLECTION',
  /** The placement links to a collection hub */
  CollectionHub = 'COLLECTION_HUB',
  /** The placement links to containers via a store selector */
  ContainerPathViaStoreSelector = 'CONTAINER_PATH_VIA_STORE_SELECTOR',
  /** The placement links to a department */
  Department = 'DEPARTMENT',
  /** The placement does not have an action */
  DoNotNavigate = 'DO_NOT_NAVIGATE',
  /** The placement links to an external url */
  External = 'EXTERNAL',
  /** The placement links to the pickup store selector */
  PickupStoreselector = 'PICKUP_STORESELECTOR',
  /** The placement links to a product */
  Product = 'PRODUCT',
  /** The placement links to the SSA store selector */
  SsaStoreselector = 'SSA_STORESELECTOR',
  /** The placement links to a storefront */
  Storefront = 'STOREFRONT',
  /** The placement links to a store selector */
  StoreSelector = 'STORE_SELECTOR',
  /** Unfamiliar Banner Action */
  Unknown = 'UNKNOWN'
}

export type PlacementExperimentInput = {
  /** Experiment Assignment or Lookup */
  accessMethod?: InputMaybe<PlacementExperimentMethodEnum>;
  /** Name of the Experiment */
  name: Scalars['String'];
  /** Variant of the Experiment for which placement should be visible */
  variant: Scalars['String'];
};

export enum PlacementExperimentMethodEnum {
  /** Whether variant assignment should happen */
  Assign = 'ASSIGN',
  /** Not specified */
  ExperimentMethodUnspecified = 'EXPERIMENT_METHOD_UNSPECIFIED',
  /** Whether a variant lookup should occur */
  Lookup = 'LOOKUP',
  /** Whether a variant preview should occur */
  Preview = 'PREVIEW'
}

export type PlacementFilterInput = {
  /** The locale of the placement (Eg. en_US) */
  locale?: InputMaybe<Scalars['String']>;
  /** The platform(s) the placement is configured for (Eg. [MOBILE, WEB]) */
  platform?: InputMaybe<Array<PlacementPlatformEnum>>;
  /** A term to use to search for a placement */
  searchTerm?: InputMaybe<Scalars['String']>;
  /** The current status of the placement (Eg. ACTIVE) */
  status?: InputMaybe<PlacementStatusEnum>;
  /** The type of the placement (Eg. TEXT) */
  type?: InputMaybe<PlacementTypeEnum>;
};

export type PlacementImagesInput = {
  /** The image url of the placement to display for large viewports */
  large: Scalars['Url'];
  /** The image url of the placement to display for small viewports */
  small: Scalars['Url'];
};

export type PlacementInput = {
  banner?: InputMaybe<BannerTypeInput>;
  banners?: InputMaybe<Array<InputMaybe<BannerTypeInput>>>;
  cardList?: InputMaybe<CardListInput>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  excludedRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  experimentMethod?: InputMaybe<ExperimentMethod>;
  experimentName?: InputMaybe<Scalars['String']>;
  experimentVariant?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  includedRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  internalName?: InputMaybe<Scalars['String']>;
  itemList?: InputMaybe<ItemListInput>;
  layout?: InputMaybe<Layout>;
  layoutVariantId?: InputMaybe<Scalars['BigInt']>;
  platform?: InputMaybe<Array<InputMaybe<PlatformType>>>;
  rank?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Status>;
  stringLayout?: InputMaybe<Scalars['String']>;
  teamOwner?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userOwner?: InputMaybe<Scalars['BigInt']>;
  widget?: InputMaybe<WidgetInput>;
};

export enum PlacementPlatformEnum {
  /** This placement should display on android */
  Android = 'ANDROID',
  /** This placement should display on ios */
  Ios = 'IOS',
  /** This placement should display on mobile */
  Mobile = 'MOBILE',
  /** This placement should display on web */
  Web = 'WEB'
}

export type PlacementScheduleInput = {
  /** When the placement should stop being active */
  endDate?: InputMaybe<Scalars['Time']>;
  /** When the placement should become active */
  startDate: Scalars['Time'];
  /** The timezone in which the banner will stop being active at midnight */
  timezone: Scalars['String'];
};

export type PlacementSortInput = {
  /** The direction to sort */
  direction?: InputMaybe<PlacementSortInputDirectionEnum>;
  /** The field to sort on */
  field: PlacementSortInputFieldEnum;
};

export enum PlacementSortInputDirectionEnum {
  /** Sort the results in ascending order */
  Asc = 'ASC',
  /** Sort the results in descending order */
  Desc = 'DESC'
}

export enum PlacementSortInputFieldEnum {
  /** Sort the results based on the status of the placement */
  CreatedAt = 'CREATED_AT',
  /** Sort the results based on the status of the placement */
  Status = 'STATUS'
}

export enum PlacementStatusEnum {
  /** The placement is currently active */
  Active = 'ACTIVE',
  /** The placement is not currently active */
  Inactive = 'INACTIVE',
  /** The placement is currently the live banner */
  Live = 'LIVE'
}

export enum PlacementTypeEnum {
  /** This is ASYNC_IMAGE placement */
  AsyncImage = 'ASYNC_IMAGE',
  /** This is ASYNC_MODAL placement */
  AsyncModal = 'ASYNC_MODAL',
  /** This is CAROUSEL_CARD placement */
  CarouselCard = 'CAROUSEL_CARD',
  /** This is CATEGORY_HUB placement */
  CategoryHub = 'CATEGORY_HUB',
  /** This is HEADER placement */
  Header = 'HEADER',
  /** This placement displays a HeroBanner Home Announcement Banner */
  HeroBanner = 'HERO_BANNER',
  /** This placement displays an image */
  Image = 'IMAGE',
  /** This placement displays an ImageForward Home Announcement Banner */
  ImageForward = 'IMAGE_FORWARD',
  /** This placement displays a ImageForwardFlex Home Announcement Banner */
  ImageForwardFlex = 'IMAGE_FORWARD_FLEX',
  /** This is INFORMATION_MODAL placement */
  InformationModal = 'INFORMATION_MODAL',
  /** This is LARGE placement */
  Large = 'LARGE',
  /** This is LOGO_WITH_TEXT placement */
  LogoWithText = 'LOGO_WITH_TEXT',
  /** This is MEGA placement */
  Mega = 'MEGA',
  /** This is OCCASION_HUB placement */
  OccasionHub = 'OCCASION_HUB',
  /** This is RICH_TEXT placement */
  RichText = 'RICH_TEXT',
  /** This placement displays a SecondaryBanner Home Announcement Banner */
  SecondaryBanner = 'SECONDARY_BANNER',
  /** This is flyout placement */
  StandardInformationModal = 'STANDARD_INFORMATION_MODAL',
  /** This is SURVEY placement */
  Survey = 'SURVEY',
  /** This placement displays text */
  Text = 'TEXT',
  /** This is TEXT_SLIM placement */
  TextSlim = 'TEXT_SLIM',
  /** This placement displays a TextWithThumbnail Home Announcement Banner */
  TextWithThumbnail = 'TEXT_WITH_THUMBNAIL',
  /** This placement displays a TextWithThumbnailFlex Home Announcement Banner */
  TextWithThumbnailFlex = 'TEXT_WITH_THUMBNAIL_FLEX',
  /** This is TITLED_MEDIA placement */
  TitledMedia = 'TITLED_MEDIA',
  /** This placement displays an ImageForward Home Announcement Banner */
  Unknown = 'UNKNOWN'
}

export type PlacementsResponse = {
  __typename?: 'PlacementsResponse';
  placements?: Maybe<Array<Maybe<Placement>>>;
};

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type PostAuthPaths = {
  __typename?: 'PostAuthPaths';
  loginPath?: Maybe<Scalars['String']>;
  signupPath?: Maybe<Scalars['String']>;
};

export type PostAuthPathsInput = {
  loginPath?: InputMaybe<Scalars['String']>;
  signupPath?: InputMaybe<Scalars['String']>;
};

export type PricingSchema = {
  __typename?: 'PricingSchema';
  _?: Maybe<Scalars['Boolean']>;
};

export type ProblemTrackerEntityStatus = {
  __typename?: 'ProblemTrackerEntityStatus';
  calculatedEntityId?: Maybe<EntityId>;
  createdAt?: Maybe<Scalars['DateTime']>;
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  providedEntityId?: Maybe<EntityId>;
  providedEntityType?: Maybe<EntityType>;
  status?: Maybe<EntityStatus>;
  ticketId?: Maybe<Scalars['BigInt']>;
};

export type ProblemTrackerTicket = {
  __typename?: 'ProblemTrackerTicket';
  assignee?: Maybe<User>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  entities?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  entityType?: Maybe<EntityType>;
  fixTaskGroupUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  localeCode?: Maybe<Scalars['String']>;
  problemType?: Maybe<ProblemType>;
  reportedOnBehalfOf?: Maybe<User>;
  retailerId?: Maybe<Scalars['BigInt']>;
  severity?: Maybe<Scalars['BigInt']>;
  sourceAppName?: Maybe<Scalars['String']>;
  status?: Maybe<TicketStatus>;
  summaryStatus?: Maybe<Array<Maybe<SummaryStatus>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProblemTrackerTicketComment = {
  __typename?: 'ProblemTrackerTicketComment';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['BigInt']>;
};

export enum ProblemType {
  ProblemTypeOther = 'PROBLEM_TYPE_OTHER',
  ProblemTypeProductAlcohol = 'PROBLEM_TYPE_PRODUCT_ALCOHOL',
  ProblemTypeProductAvailability = 'PROBLEM_TYPE_PRODUCT_AVAILABILITY',
  ProblemTypeProductBrand = 'PROBLEM_TYPE_PRODUCT_BRAND',
  ProblemTypeProductCharacteristics = 'PROBLEM_TYPE_PRODUCT_CHARACTERISTICS',
  ProblemTypeProductClassification = 'PROBLEM_TYPE_PRODUCT_CLASSIFICATION',
  ProblemTypeProductDetails = 'PROBLEM_TYPE_PRODUCT_DETAILS',
  ProblemTypeProductIdentity = 'PROBLEM_TYPE_PRODUCT_IDENTITY',
  ProblemTypeProductImage = 'PROBLEM_TYPE_PRODUCT_IMAGE',
  ProblemTypeProductSize = 'PROBLEM_TYPE_PRODUCT_SIZE',
  ProblemTypeProductTaxonomy = 'PROBLEM_TYPE_PRODUCT_TAXONOMY',
  ProblemTypeUnknown = 'PROBLEM_TYPE_UNKNOWN'
}

export type Product = {
  __typename?: 'Product';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryImageUrl?: Maybe<Scalars['String']>;
};

export type ProductAnalyticSummaryRequestInput = {
  dateRange?: InputMaybe<InstacartAdsSharedV1DateRangeInput>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductAnalyticsSummary = {
  __typename?: 'ProductAnalyticsSummary';
  avgOrdersPerStore?: Maybe<Scalars['Float']>;
  avgSalesPerStoreUsd?: Maybe<Scalars['Float']>;
  avgStoreCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['BigInt']>;
  totalOrders?: Maybe<Scalars['BigInt']>;
  totalSalesUsd?: Maybe<Scalars['Float']>;
  totalStores?: Maybe<Scalars['BigInt']>;
};

export type ProductAnalyticsSummaryGrowthMetrics = {
  __typename?: 'ProductAnalyticsSummaryGrowthMetrics';
  avgOrdersPerStoreGrowth?: Maybe<Scalars['Float']>;
  avgSalesPerStoreUsdGrowth?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['BigInt']>;
  totalOrdersGrowth?: Maybe<Scalars['Float']>;
  totalSalesUsdGrowth?: Maybe<Scalars['Float']>;
  totalStoresGrowth?: Maybe<Scalars['Float']>;
};

export type ProductAnalyticsSummaryResponse = {
  __typename?: 'ProductAnalyticsSummaryResponse';
  analyticsSummary?: Maybe<ProductAnalyticsSummary>;
  growthMetrics?: Maybe<ProductAnalyticsSummaryGrowthMetrics>;
  previousPeriodMetrics?: Maybe<ProductAnalyticsSummary>;
};

export type ProductAttributes = {
  __typename?: 'ProductAttributes';
  avgUnitPrice?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  legalFilterType?: Maybe<LegalFilterType>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  unitCount?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
};

export type ProductAttributesRequestInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductAttributesResponse = {
  __typename?: 'ProductAttributesResponse';
  productAttributes?: Maybe<ProductAttributes>;
};

export type ProductBrandProfileAttributesRequestInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductBrandProfileAttributesResponse = {
  __typename?: 'ProductBrandProfileAttributesResponse';
  brandProfileAttributes?: Maybe<BrandProfileAttributes>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  l1Category?: Maybe<Scalars['String']>;
  l1CategoryId?: Maybe<Scalars['BigInt']>;
  l2Category?: Maybe<Scalars['String']>;
  l2CategoryId?: Maybe<Scalars['BigInt']>;
  l3Category?: Maybe<Scalars['String']>;
  l3CategoryId?: Maybe<Scalars['BigInt']>;
  l4Category?: Maybe<Scalars['String']>;
  l4CategoryId?: Maybe<Scalars['BigInt']>;
  l5Category?: Maybe<Scalars['String']>;
  l5CategoryId?: Maybe<Scalars['BigInt']>;
  l6Category?: Maybe<Scalars['String']>;
  l6CategoryId?: Maybe<Scalars['BigInt']>;
  productCategory?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['BigInt']>;
};

export type ProductCategoryTaxonomy = {
  __typename?: 'ProductCategoryTaxonomy';
  l1?: Maybe<Scalars['String']>;
  l2?: Maybe<Scalars['String']>;
  l3?: Maybe<Scalars['String']>;
  l4?: Maybe<Scalars['String']>;
  l5?: Maybe<Scalars['String']>;
  l6?: Maybe<Scalars['String']>;
};

export type ProductData = {
  __typename?: 'ProductData';
  attributeData?: Maybe<Array<Maybe<AttributeData>>>;
  localeId?: Maybe<Scalars['String']>;
};

export type ProductDisplay = {
  __typename?: 'ProductDisplay';
  imageAsset?: Maybe<ImageAsset>;
  productId?: Maybe<Scalars['BigInt']>;
};

export type ProductId = {
  __typename?: 'ProductId';
  locale?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['BigInt']>;
};

export type ProductIdInput = {
  locale?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductSellSheetCardsRequestInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductSellSheetCardsResponse = {
  __typename?: 'ProductSellSheetCardsResponse';
  sellSheetCards?: Maybe<Array<Maybe<SellSheetCard>>>;
};

export type ProductSource = {
  __typename?: 'ProductSource';
  defaultPriority?: Maybe<Scalars['BigInt']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sourceLevel?: Maybe<SourceLevel>;
};

export type ProductSummaryMetrics = {
  __typename?: 'ProductSummaryMetrics';
  avgSalesPerStoreUsd?: Maybe<Scalars['Float']>;
  dateRange?: Maybe<InstacartAdsSharedV1DateRange>;
  storeCount?: Maybe<Scalars['BigInt']>;
  totalSalesUsd?: Maybe<Scalars['Float']>;
};

export type ProductSummaryTraits = {
  __typename?: 'ProductSummaryTraits';
  isCarriedByRetailer?: Maybe<Scalars['Boolean']>;
  productAttributes?: Maybe<ProductAttributes>;
  productSummaryMetrics?: Maybe<ProductSummaryMetrics>;
};

export type PropertyInput = {
  /** The property name */
  name: Scalars['String'];
  /** The property value */
  value?: InputMaybe<Scalars['JSON']>;
};

export type ProposedReturnInput = {
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  returnItems?: InputMaybe<Array<InputMaybe<ReturnItemInput>>>;
  returnStatus?: InputMaybe<ProposedReturnStatus>;
  returnType?: InputMaybe<ProposedReturnType>;
};

export enum ProposedReturnStatus {
  Accepted = 'Accepted',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Pending = 'Pending',
  ProposedReturnStatusUnspecified = 'ProposedReturnStatusUnspecified',
  Refunded = 'Refunded'
}

export enum ProposedReturnType {
  Manual = 'Manual',
  ProposedReturnTypeUnspecified = 'ProposedReturnTypeUnspecified',
  RetailerIntegration = 'RetailerIntegration'
}

export type PublicRetailer = {
  __typename?: 'PublicRetailer';
  /** Country associated with this retailer */
  country: Country;
  /** A unique identifier for the retailer */
  id: Scalars['ID'];
  /** The name of the retailer */
  name: Scalars['String'];
};

/** Input parameters for publishing an approval request */
export type PublishApprovalRequestInput = {
  /** The unique ID of the Approval Request to publish. */
  approvalRequestId: Scalars['ID'];
  /** Flag to indicate if the publish action should proceed despite normal restrictions. */
  isBreakGlass?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of PublishApprovalRequest */
export type PublishApprovalRequestPayload = {
  __typename?: 'PublishApprovalRequestPayload';
  approvalRequest: ApprovalsManagementTypesApprovalRequest;
};

export type PublishRppConfigurableItemsRequestInput = {
  configurableProducts?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type PublishRppConfigurableItemsResponse = {
  __typename?: 'PublishRppConfigurableItemsResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type PublishStoreConfigurationDraftRequestInput = {
  draftId?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type PublishStoreConfigurationDraftResponse = {
  __typename?: 'PublishStoreConfigurationDraftResponse';
  errorMessage?: Maybe<Scalars['String']>;
  errorReason?: Maybe<ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  _noop?: Maybe<Scalars['String']>;
  /** The access control details for the account */
  accessControlConfiguration: AccessControlConfiguration;
  /** Check if account request for given email already exists */
  accountRequestPreviousRequestExistByEmail: AccountRequestPreviousRequestExists;
  /** Search for retailers matching a set of criteria - without an account */
  accountRequestRetailerSearch: Array<PublicRetailer>;
  /** Search for an previously created Account Request by ID */
  accountRequestSearchById: AccountRequestAccountRequest;
  /** Check if an account token (reset password or verify email) is valid */
  accountTokenValidate: TokensValidation;
  /** Fetch list of users eligible to subscribe to an event. Also indicates if they are currently subscribed. */
  accountsByNotificationType: Array<NotificationsNotificationTypeAccount>;
  apiIntegrationsServiceGetApiSettings?: Maybe<GetApiSettingsResponse>;
  apiIntegrationsServiceGetAuthenticationUrl?: Maybe<GetAuthenticationUrlResponse>;
  apiIntegrationsServiceGetAvailableStoreLocations?: Maybe<GetAvailableStoreLocationsResponse>;
  /** Retrieves a specific approval request by its ID. */
  approvalsManagementGetRequest?: Maybe<ApprovalsManagementTypesApprovalRequest>;
  /** Retrieves a list of content snapshots by their IDs. */
  approvalsManagementGetSnapshots: Array<ApprovalsManagementTypesContentSnapshot>;
  /** Retrieves permitted actions for a user on a set of approval requests. */
  approvalsManagementGetUserPermittedActions: Array<ApprovalsManagementTypesUserPermittedActions>;
  /** Retrieves a list of approval requests. */
  approvalsManagementListRequests: Array<ApprovalsManagementTypesApprovalRequest>;
  assignmentsGetOrderFulfillmentOverview?: Maybe<GetOrderFulfillmentOverviewResponse>;
  /** Search for Audit Logs */
  auditLogSearch: AuditLogsAuditLogConnection;
  availabilityGetStoreHoursByDate?: Maybe<GetStoreHoursByDateResponse>;
  availabilityGetStoreHoursByDayOfWeek?: Maybe<GetStoreHoursByDayOfWeekResponse>;
  availabilityGetStoreHoursByZoneRetailer?: Maybe<GetStoreHoursByZoneRetailerResponse>;
  /** Fetch a specific aisle by id */
  catalogAisle: CatalogAisle;
  /** Fetch a specific department by id */
  catalogDepartment: CatalogDepartment;
  catalogProblemTrackerServiceGetTicket?: Maybe<GetTicketResponse>;
  catalogProblemTrackerServiceListTickets?: Maybe<ListTicketsResponse>;
  /** Retrieve products by IDs */
  catalogProducts: Array<CatalogProduct>;
  /** Retrieve a retailer-product by its ID */
  catalogRetailerProduct: CatalogRetailerProduct;
  /** Search for retailer-products matching a set of criteria */
  catalogRetailerProductSearch: CatalogRetailerProductConnection;
  /** Fetch a signed url for an S3 report */
  catalogSearchDownloadSignedUrl: CatalogSearchDownloadsCatalogSearchDownloadSignedUrl;
  catalogToolsApiServiceDownloadCatalogSearchResults?: Maybe<DownloadCatalogSearchResultsResponse>;
  catalogToolsApiServiceGetAnomalousItemsList?: Maybe<GetAnomalousItemsListResponse>;
  catalogToolsApiServiceGetAnomalousItemsReport?: Maybe<GetAnomalousItemsReportResponse>;
  catalogToolsApiServiceGetLocalesByRetailer?: Maybe<GetLocalesByRetailerResponse>;
  catalogToolsApiServiceGetRetailerProductData?: Maybe<GetRetailerProductDataResponse>;
  collectionsServiceGetCollections?: Maybe<GetCollectionsResponse>;
  commerceInvoicingServiceGetOrderDiscountDetails?: Maybe<GetOrderDiscountDetailsResponse>;
  configurableItemsServiceGetRppConfigurableItems?: Maybe<GetRppConfigurableItemsResponse>;
  connectPlatformServiceGetConnectSettings?: Maybe<GetConnectSettingsResponse>;
  connectPlatformServiceGetPartnerApiKeys?: Maybe<GetPartnerApiKeysResponse>;
  connectPlatformServiceGetPartnerId?: Maybe<GetPartnerIdResponse>;
  connectPlatformServiceGetRequestContexts?: Maybe<GetRequestContextsResponse>;
  contentManagementAdminServiceGetNavigation?: Maybe<GetNavigationResponse>;
  contentManagementAdminServiceGetPlacementContentById?: Maybe<GetPlacementContentByIdResponse>;
  contentManagementAdminServiceGetSlotById?: Maybe<GetSlotByIdResponse>;
  contentManagementAdminServiceListContentPageVersionMetas?: Maybe<ListContentPageVersionMetasResponse>;
  contentManagementAdminServiceListContentPageVersions?: Maybe<ListContentPageVersionsResponse>;
  contentManagementAdminServiceListContentPagesV2?: Maybe<ListContentPagesResponseV2>;
  contentManagementAdminServiceListPlacementContent?: Maybe<ListPlacementContentResponse>;
  contentManagementServiceGetLayoutVariants?: Maybe<GetLayoutVariantsResponse>;
  contentManagementServiceGetPlacements?: Maybe<PlacementsResponse>;
  contentManagementServiceGetThemePage?: Maybe<GetThemePageResponse>;
  contentManagementServiceListThemePages?: Maybe<ListThemePagesResponse>;
  /** The currently authenticated account */
  currentEnterpriseAccount: EnterpriseAccount;
  /** Check if user has access to the given arguments */
  currentEnterpriseAccountAuthorizationCheck: AccessControlAuthorizationAuthorizationCheck;
  customersOrdersServiceGetHandlingDetails?: Maybe<CustomersGetHandlingDetailsResponse>;
  customersOrdersServiceGetOrder?: Maybe<CustomersGetOrderResponse>;
  customersOrdersServiceGetOrderAmountsSummaryV0?: Maybe<CustomersGetOrderAmountsSummaryV0Response>;
  customersOrdersServiceGetPaymentMethodSummary?: Maybe<CustomersGetPaymentMethodSummaryResponse>;
  customersOrdersServiceListOrders?: Maybe<CustomersListOrdersResponse>;
  customersOrdersServiceQueryOrderReference?: Maybe<CustomersQueryOrderReferenceResponse>;
  customersOrdersServiceQueryOrders?: Maybe<CustomersQueryOrdersResponse>;
  demeterServiceGetAuditBatch?: Maybe<GetAuditBatchResponse>;
  demeterServiceGetAuditBatchMetadata?: Maybe<GetAuditBatchMetadataResponse>;
  demeterServiceGetAuditTask?: Maybe<GetAuditTaskResponse>;
  demeterServiceGetDetection?: Maybe<GetDetectionResponse>;
  demeterServiceGetNextAuditBatch?: Maybe<GetNextAuditBatchResponse>;
  demeterServiceListAuditBatches?: Maybe<ListAuditBatchesResponse>;
  demeterServiceListAuditWorkflows?: Maybe<ListAuditWorkflowsResponse>;
  demeterServiceListDetections?: Maybe<ListDetectionsResponse>;
  emergingBrandPortalServiceGetBrandProfileAttributes?: Maybe<BrandProfileAttributesResponse>;
  emergingBrandPortalServiceGetBrandProfileSellSheetCards?: Maybe<BrandProfileSellSheetCardsResponse>;
  emergingBrandPortalServiceGetBrandSellSheetsAttributes?: Maybe<BrandSellSheetsAttributesResponse>;
  emergingBrandPortalServiceGetEBPAnalytics?: Maybe<EbpAnalyticsResponse>;
  emergingBrandPortalServiceGetEBPProductAnalyticsSummary?: Maybe<ProductAnalyticsSummaryResponse>;
  emergingBrandPortalServiceGetEBPProductAttributes?: Maybe<ProductAttributesResponse>;
  emergingBrandPortalServiceGetIndexBrandNames?: Maybe<IndexBrandNamesResponse>;
  emergingBrandPortalServiceGetIndexEBPCategories?: Maybe<IndexEbpCategoriesResponse>;
  emergingBrandPortalServiceGetIndexSellSheetCards?: Maybe<SellSheetCardsResponse>;
  emergingBrandPortalServiceGetProductBrandProfileAttributes?: Maybe<ProductBrandProfileAttributesResponse>;
  emergingBrandPortalServiceGetProductSellSheetCards?: Maybe<ProductSellSheetCardsResponse>;
  emergingBrandPortalServiceGetSellSheetAnalyticsSummary?: Maybe<SellSheetAnalyticsSummaryResponse>;
  emergingBrandPortalServiceGetSellSheetAttributes?: Maybe<SellSheetAttributesResponse>;
  emergingBrandPortalServiceGetSellSheetBrandProfileAttributes?: Maybe<SellSheetBrandProfileAttributesResponse>;
  emergingBrandPortalServiceGetSellSheetProductsAttributes?: Maybe<SellSheetProductsAttributesResponse>;
  emergingBrandPortalServiceGetSellSheetProductsSummaryTraits?: Maybe<SellSheetProductsSummaryTraitsResponse>;
  /** Fetch an EnterpriseAccount by its canonical id */
  enterpriseAccount: EnterpriseAccount;
  /** Search for EnterpriseAccounts matching a set of criteria */
  enterpriseAccountSearch: EnterpriseAccountConnection;
  /** Check whether a given feature flag is fully/publicly enabled. */
  featureToggleFullyEnabled: FeatureTogglesEnabledCheck;
  /** Check the health of the server */
  healthCheck: HealthCheck;
  historicalStorePerformanceServiceGetAggregatedHistoricalStorePerformance?: Maybe<HistoricalStorePerformanceResponse>;
  /** Fetch a list of Marketplace Home placements */
  homePlacementSearch: Array<HomePlacement>;
  /** Check if user has access to the given arguments */
  insightsPortalAuthorizationCheck: AccessControlAuthorizationAuthorizationCheck;
  insightsPortalServiceGetInsightsPortalCustomReport?: Maybe<InsightsPortalGetCustomReportResponse>;
  insightsPortalServiceGetInsightsPortalFilterOptions?: Maybe<InsightsPortalFilterOptionsResponse>;
  insightsPortalServiceGetInsightsPortalMetricValues?: Maybe<InsightsPortalMetricValuesResponse>;
  insightsPortalServiceGetInsightsPortalMultiDimensionDataset?: Maybe<InsightsPortalMultiDimensionDatasetResponse>;
  insightsPortalServiceGetInsightsPortalMultiGroupData?: Maybe<InsightsPortalMultiGroupResponse>;
  insightsPortalServiceGetInsightsPortalSchemaMetadata?: Maybe<InsightsPortalSchemaMetadataResponse>;
  insightsPortalServiceGetInsightsPortalSnowflakeHeartbeat?: Maybe<InsightsPortalSnowflakeHeartbeatResponse>;
  insightsPortalServiceListInsightsPortalCustomReports?: Maybe<InsightsPortalListCustomReportsResponse>;
  /**
   * READ (Batch)
   *
   * Returns the set of segments matching the given context. Useful for
   * finding, e.g., all targeting segments in use by a particular retailer.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_GetAllSegmentDefinitions?: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentStates>;
  /**
   * READ
   *
   * The response will have the same version number as the request (or the
   * greatest available if none was specified; see below) and the definition
   * associated with that version.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_GetSegmentDefinition?: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  instacart_ads_v3_EmergingBrandPortalService_GetIndexBrandNames?: Maybe<Instacart_Ads_V3_IndexBrandNamesResponse>;
  instacart_ads_v3_EmergingBrandPortalService_GetProductAdditionalInfo?: Maybe<Instacart_Ads_V3_ProductAdditionalInfoResponse>;
  instacart_ads_v3_RetailerBlockedAdsService_GetRetailerBlockedAdIds?: Maybe<Instacart_Ads_V3_GetRetailerBlockedAdIdsResponse>;
  instacart_ads_v3_RetailerReviewToolService_GetDisplayPreviewData?: Maybe<Instacart_Ads_V3_GetDisplayPreviewDataResponse>;
  /** Returns all Caper entities (partner, retailer, store, device) and their configs */
  instacart_caper_admin_v1_ConfigManagementService_GetAllEntities?: Maybe<Instacart_Caper_Admin_V1_GetAllEntitiesResponse>;
  /** Returns all available configuration keys and metadata about them */
  instacart_caper_admin_v1_ConfigManagementService_GetConfigKeys?: Maybe<Instacart_Caper_Admin_V1_GetConfigKeysResponse>;
  /** The GetAuditReview API returns an audit review */
  instacart_cashflow_nutella_v1_NutellaService_GetAuditReview?: Maybe<Instacart_Cashflow_Nutella_V1_GetAuditReviewResponse>;
  /** The GetNextAuditReview API returns the current audit review assigned to a user or returns the next unassigned audit review */
  instacart_cashflow_nutella_v1_NutellaService_GetNextAuditReview?: Maybe<Instacart_Cashflow_Nutella_V1_GetNextAuditReviewResponse>;
  /** The GetWorkflowMetadata API returns metadata for a workflow */
  instacart_cashflow_nutella_v1_NutellaService_GetWorkflowMetadata?: Maybe<Instacart_Cashflow_Nutella_V1_GetWorkflowMetadataResponse>;
  /** Get tender transactions for a list of WHLs */
  instacart_checkout_v1_PosDataService_GetTenderTransactionsByWhlIds?: Maybe<Instacart_Fulfillment_Shared_V1_GetTenderTransactionsByWhlIdsResponse>;
  /** get basket product. */
  instacart_customers_basket_products_orchestrators_v1_BasketProductsOrchestratorsService_GetBasketProducts?: Maybe<Instacart_Customers_Basket_Products_Orchestrators_V1_GetBasketProductsReponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_GetBusinessProgram?: Maybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_GetBusinessProgramContent?: Maybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_GetBusinessProgramContentByProgramId?: Maybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentByProgramIdResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_GetBusinessProgramOffer?: Maybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramOfferResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_GetBusinessProgramOffersByProgramId?: Maybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramOffersByProgramIdResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_ListBusinessProgram?: Maybe<Instacart_Customers_Business_Admin_V1_ListBusinessProgramResponse>;
  instacart_customers_business_admin_v1_BusinessAdminService_ListBusinesses?: Maybe<Instacart_Customers_Business_Admin_V1_ListBusinessesResponse>;
  instacart_customers_campaigns_v1_CampaignsService_GetBucketDownloadUrl?: Maybe<Instacart_Customers_Campaigns_V1_GetBucketDownloadUrlResponse>;
  instacart_customers_campaigns_v1_CampaignsService_GetBucketUploadUrl?: Maybe<Instacart_Customers_Campaigns_V1_GetBucketUploadUrlResponse>;
  instacart_customers_campaigns_v1_CampaignsService_GetCsvFoundEntriesCount?: Maybe<Instacart_Customers_Campaigns_V1_GetCsvFoundEntriesCountResponse>;
  instacart_customers_campaigns_v1_CampaignsService_GetCsvProcessingSummary?: Maybe<Instacart_Customers_Campaigns_V1_GetCsvProcessingSummaryResponse>;
  instacart_customers_campaigns_v1_CampaignsService_GetGroupedSlots?: Maybe<Instacart_Customers_Campaigns_V1_GetGroupedSlotsResponse>;
  instacart_customers_commerce_discount_v1_CommerceDiscountService_GetValueGrantPolicyFacade?: Maybe<Instacart_Customers_Commerce_Discount_V1_GetValueGrantPolicyFacadeResponse>;
  instacart_customers_coupons_v1_CouponsDomainService_GetCouponCodeExists?: Maybe<Instacart_Customers_Coupons_V1_GetCouponCodeExistsResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_GetMoment?: Maybe<Instacart_Customers_Coupons_V1_GetMomentResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_GetMomentParticipatingRetailers?: Maybe<Instacart_Customers_Coupons_V1_GetMomentParticipatingRetailersResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_GetMoments?: Maybe<Instacart_Customers_Coupons_V1_GetMomentsResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_GetRetailerCampaign?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_ListMoments?: Maybe<Instacart_Customers_Coupons_V1_ListMomentsResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_ListRetailerCampaignTreatments?: Maybe<Instacart_Customers_Coupons_V1_ListRetailerCampaignTreatmentsResponse>;
  instacart_customers_coupons_v1_RetailerCampaignService_ListRetailerCampaigns?: Maybe<Instacart_Customers_Coupons_V1_ListRetailerCampaignsResponse>;
  instacart_customers_express_v1_ExpressService_GetCurrentExpressMemberStatusForUser?: Maybe<Instacart_Customers_Express_V1_GetCurrentExpressMemberStatusForUserResponse>;
  /** Get loyalty cards by card number. */
  instacart_customers_loyalty_v1_LoyaltyService_GetLoyaltyCardsByCardNumber?: Maybe<Instacart_Customers_Loyalty_V1_GetLoyaltyCardsByCardNumberResponse>;
  /** Get loyalty cards for a user. */
  instacart_customers_loyalty_v1_LoyaltyService_GetLoyaltyCardsForUser?: Maybe<Instacart_Customers_Loyalty_V1_GetLoyaltyCardsForUserResponse>;
  /** API to return order items details. */
  instacart_customers_orders_v1_OrdersService_GetOrderLines?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse>;
  /** Countries API */
  instacart_customers_partners_v1_PartnersService_GetCountries?: Maybe<Instacart_Customers_Partners_V1_GetCountriesResponse>;
  /**
   * PostalCodes API
   * Search postal codes by name or id. Not recommended unless you can't use GetPostalCodesByZones, as
   * the list can be very long.
   */
  instacart_customers_partners_v1_PartnersService_GetPostalCodes?: Maybe<Instacart_Customers_Partners_V1_GetPostalCodesResponse>;
  /** Search postal codes within a zone. This is the preferred method for searching postal codes. */
  instacart_customers_partners_v1_PartnersService_GetPostalCodesByZones?: Maybe<Instacart_Customers_Partners_V1_GetPostalCodesByZonesResponse>;
  /** Regions API */
  instacart_customers_partners_v1_PartnersService_GetRegions?: Maybe<Instacart_Customers_Partners_V1_GetRegionsResponse>;
  /** RetailerLocations aka WarehouseLocations API */
  instacart_customers_partners_v1_PartnersService_GetRetailerLocations?: Maybe<Instacart_Customers_Partners_V1_GetRetailerLocationsResponse>;
  instacart_customers_partners_v1_PartnersService_GetRetailerLocationsByRetailer?: Maybe<Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerResponse>;
  instacart_customers_partners_v1_PartnersService_GetRetailerZones?: Maybe<Instacart_Customers_Partners_V1_GetRetailerZonesResponse>;
  instacart_customers_partners_v1_PartnersService_GetRetailers?: Maybe<Instacart_Customers_Partners_V1_GetRetailersResponse>;
  /**
   * Zones API
   * Gets all zones, optionally filtered by name. Using GetZonesByRegion is preferred where possible as
   * the list of zones is very long when not filtered by region.
   */
  instacart_customers_partners_v1_PartnersService_GetZones?: Maybe<Instacart_Customers_Partners_V1_GetZonesResponse>;
  /** Gets all zones in a region, optionally filtered by name. Preferred where possible. */
  instacart_customers_partners_v1_PartnersService_GetZonesByRegion?: Maybe<Instacart_Customers_Partners_V1_GetZonesByRegionResponse>;
  instacart_customers_partnership_offer_v1_PartnershipOfferService_GetPartnershipManagementUrlDownloadLink?: Maybe<Instacart_Customers_Partnership_Offer_V1_GetPartnershipManagementUrlDownloadLinkResponse>;
  instacart_customers_partnership_offer_v1_PartnershipOfferService_ListPartnershipManagementUrls?: Maybe<Instacart_Customers_Partnership_Offer_V1_ListPartnershipManagementUrlsResponse>;
  instacart_customers_recipes_v1_RecipesService_GetCategoriesByName?: Maybe<Instacart_Customers_Recipes_V1_GetCategoriesByNameResponse>;
  instacart_customers_recipes_v1_RecipesService_GetCoreIngredients?: Maybe<Instacart_Customers_Recipes_V1_GetCoreIngredientsResponse>;
  instacart_customers_recipes_v1_RecipesService_GetRecipeById?: Maybe<Instacart_Customers_Recipes_V1_GetRecipeByIdResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_GetFlyer?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_GetFlyerActivities?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerActivitiesResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_GetFlyerClickableAreas?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerClickableAreasResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_GetFlyerTags?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerTagsResponse>;
  instacart_customers_retailer_collections_admin_v1_FlyerService_ListFlyers?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListFlyersResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetCollection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetCollectionRules?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetNavigationTree?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetRetailerCsv?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_ListCollectionProducts?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_ListCollections?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_ListRetailerDepartments?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListRetailerDepartmentsResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_SearchCollectionProducts?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_GetIppDraftById?: Maybe<Instacart_Customers_Retailer_Management_V1_GetIppDraftByIdResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_ListIppDrafts?: Maybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsResponse>;
  instacart_customers_retailer_management_v1_IppDraftPreviewService_GetIppPreviewByToken?: Maybe<Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenResponse>;
  instacart_customers_retailer_management_v1_IppDraftPreviewService_GetPreviewAuthToken?: Maybe<Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenResponse>;
  instacart_enterprise_boba_core_offers_v1_OfferService_GetOrderDiscountInfo?: Maybe<Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoResponse>;
  /** fetches a Dashboard Order. */
  instacart_enterprise_frigate_fulfillment_v1_DashboardOrderService_GetDashboardOrder?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_GetDashboardOrderResponse>;
  /** Get a list of OrderCancellationPolicies in order to cancel an order. */
  instacart_enterprise_frigate_fulfillment_v1_DashboardOrderService_GetOrderCancellationPolicyList?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_GetOrderCancellationPolicyListResponse>;
  /** Get messages for conversation */
  instacart_enterprise_frigate_post_checkout_v1_EnterpriseChatService_GetEnterpriseChatMessageList?: Maybe<Instacart_Enterprise_Frigate_Post_Checkout_V1_GetEnterpriseChatMessageListResponse>;
  /** Gets the status of a campaign */
  instacart_growth_crm_messaging_service_v1_CRMMessagingService_GetCampaignStatus?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_GetCampaignStatusResponse>;
  /** Gets the size of a segment */
  instacart_growth_crm_messaging_service_v1_CRMMessagingService_GetSegmentSize?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_GetSegmentSizeResponse>;
  /** Gets a template */
  instacart_growth_crm_messaging_service_v1_CRMMessagingService_GetTemplate?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_GetTemplateResponse>;
  /** Lists segments for a warehouse */
  instacart_growth_crm_messaging_service_v1_CRMMessagingService_ListSegments?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_ListSegmentsResponse>;
  /** Lists templates */
  instacart_growth_crm_messaging_service_v1_CRMMessagingService_ListTemplates?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_ListTemplatesResponse>;
  /** Get the details of an Entity */
  instacart_infra_totem_v1_TotemService_GetEntity?: Maybe<Instacart_Infra_Totem_V1_GetEntityResponse>;
  /** Get the details of a system */
  instacart_infra_totem_v1_TotemService_GetSystem?: Maybe<Instacart_Infra_Totem_V1_GetSystemResponse>;
  /** Get a single OpsEscalationRequest by ID. */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_GetOpsEscalationRequest?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_GetOpsEscalationRequestResponse>;
  /** List all OpsEscalationRequests matching the one in request based on Warehouse Location IDs */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_ListDuplicateOpsEscalationRequests?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_ListDuplicateOpsEscalationRequestsResponse>;
  /** List all OpsEscalationRequests that match the given criteria */
  instacart_logistics_marketplace_ops_v1_OpsEscalationRequests_ListOpsEscalationRequests?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_ListOpsEscalationRequestsResponse>;
  instacart_logistics_v1_FulfillmentEvents_GetFulfillmentEvent?: Maybe<Instacart_Logistics_V1_GetFulfillmentEventResponse>;
  /** List all child fulfillment events for a specified parent event that match the given criteria */
  instacart_logistics_v1_FulfillmentEvents_ListChildFulfillmentEvents?: Maybe<Instacart_Logistics_V1_ListChildFulfillmentEventsResponse>;
  /** List all creators */
  instacart_logistics_v1_FulfillmentEvents_ListCreators?: Maybe<Instacart_Logistics_V1_ListCreatorsResponse>;
  /** List all fulfillment events that match the given criteria */
  instacart_logistics_v1_FulfillmentEvents_ListFulfillmentEvents?: Maybe<Instacart_Logistics_V1_ListFulfillmentEventsResponse>;
  /** List all tags */
  instacart_logistics_v1_FulfillmentEvents_ListTags?: Maybe<Instacart_Logistics_V1_ListTagsResponse>;
  /** Validate a new closure event */
  instacart_logistics_v1_FulfillmentEvents_ValidateNewClosureEvent?: Maybe<Instacart_Logistics_V1_ValidateNewClosureEventResponse>;
  /** Validate a new fulfillment capacity-impacting event */
  instacart_logistics_v1_FulfillmentEvents_ValidateNewFulfillmentEvent?: Maybe<Instacart_Logistics_V1_ValidateNewFulfillmentEventResponse>;
  /** Validate a new restricted availability event */
  instacart_logistics_v1_FulfillmentEvents_ValidateNewRestrictedAvailabilityEvent?: Maybe<Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventResponse>;
  /** Retrieves a change approval request by its ID */
  instacart_partners_data_ingestion_v1_ChangeApprovalService_GetChangeApprovalRequest?: Maybe<Instacart_Partners_Data_Ingestion_V1_GetChangeApprovalRequestResponse>;
  /** Get partner configurations for a given configurable information from PartnerConfiguration table */
  instacart_partners_partners_v1_PartnerConfigurationsService_GetPartnerConfigurations?: Maybe<Instacart_Partners_Partners_V1_GetPartnerConfigurationsResponse>;
  instacart_retailer_tools_growth_targeting_v1_GrowthTargetingToolingService_GetTargetingFeatures?: Maybe<Instacart_Ads_Platform_Taas_V1_GetAllSupportedFeaturesResponse>;
  /** fetches detailed entitlement data for a single entitlement */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetEntitlementDetails?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsResponse>;
  /** fetches shallow entitlement data for many entitlements */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetEntitlements?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsResponse>;
  /** fetches detailed package data for a single package */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetPackageDetails?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsResponse>;
  /** fetches shallow package data for many packages */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetPackages?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesResponse>;
  /** fetches detailed entitlement profile data for a single retailer */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetRetailerEntitlementProfileDetails?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsResponse>;
  /** fetches shallow entitlement profile data for many retailers */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetRetailerEntitlementProfiles?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_DownloadInsightsPortalCustomExport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalDownloadCustomExportResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_GetInsightsPortalCustomReport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalGetCustomReportResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_ListInsightsPortalCustomExports?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalListCustomExportsResponse>;
  instacart_retailer_tools_v2_InsightsPortalService_ListInsightsPortalCustomReports?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalListCustomReportsResponse>;
  instacart_retailers_v1_RetailersService_GetWidgetsConfig?: Maybe<Instacart_Retailers_V1_GetWidgetsConfigResponse>;
  instacart_retailers_v1_RetailersService_ValidateWidgetsConfig?: Maybe<Instacart_Retailers_V1_ValidateWidgetsConfigResponse>;
  /** Get the specific version of a feature by its name */
  instacart_roulette_v1_RouletteService_GetFeatureByNameAndVersion?: Maybe<Instacart_Roulette_V1_GetFeatureByNameAndVersionResponse>;
  /** Retrieves all active features matching the given request criteria. */
  instacart_roulette_v1_RouletteService_ListFeatures?: Maybe<Instacart_Roulette_V1_GetFeaturesResponse>;
  /** Search Features */
  instacart_roulette_v1_RouletteService_SearchFeatures?: Maybe<Instacart_Roulette_V1_SearchFeaturesResponse>;
  inventoryIntelligenceServiceGetAcknowledgedOosIds?: Maybe<GetAcknowledgedOosIdsResponse>;
  /** Fetch KnowledgeOwl Configuration */
  knowledgeOwlConfiguration: KnowledgeOwlConfiguration;
  /** Retrieve the current partner user */
  legacyCurrentPartnerUser: LegacyPartnerUser;
  /** Check the health of the server */
  legacyHealthCheck: LegacyHealthCheck;
  /** Query a Partner by id */
  legacyPartner: LegacyPartner;
  /** Query a Warehouse by id */
  legacyWarehouse: LegacyWarehouse;
  /** Query a WarehouseLocation by id */
  legacyWarehouseLocation: LegacyWarehouseLocation;
  linkAutosuggestServiceListLinkAutosuggestionAdmin?: Maybe<ListLinkAutosuggestionAdminResponse>;
  /** Retrieve the size of the Braze Segment */
  marketingFeatureBrazeSegmentSize: MarketingFeatureTypesBrazeSegmentSize;
  /** Retrieve a list of available braze segments for the retailer */
  marketingFeatureBrazeSegments: Array<MarketingFeatureTypesBrazeSegment>;
  /** Fetches the status of a marketing campaign */
  marketingFeatureCampaignStatus: MarketingFeatureTypesCampaignStatus;
  /** Fetches an email template */
  marketingFeatureTemplate: MarketingFeatureTypesTemplate;
  /** Retrieves a list of email templates */
  marketingFeatureTemplateSearch: Array<MarketingFeatureTypesTemplate>;
  /** Fetch a signed url for a single S3 report */
  normalizedReportSignedUrl: NormalizedReportsNormalizedReportSignedUrl;
  /** Fetch a list of S3 report types */
  normalizedReportTypes: Array<NormalizedReportsReportType>;
  /** Fetch a list of S3 reports */
  normalizedReportsSearch: NormalizedReportsNormalizedReportConnection;
  /** Fetch list of notification types for a retailer */
  notificationTypesForCurrentAccountOnRetailer: Array<NotificationsNotificationType>;
  /** Get mobile app config */
  onboardingMobileAppConfig: OnboardingFeatureTypesMobileAppConfig;
  onboardingServiceCheckIfLaunchOnboardingInProgressByPartner?: Maybe<CheckIfLaunchOnboardingInProgressByPartnerResponse>;
  onboardingServiceCheckLaunchOnboardingProgressByPartner?: Maybe<CheckLaunchOnboardingProgressByPartnerResponse>;
  onboardingServiceGetApprovalRequests?: Maybe<GetApprovalRequestsResponse>;
  onboardingServiceGetCatalogFaqFile?: Maybe<GetCatalogFaqFileResponse>;
  onboardingServiceGetLaunchOnboarding?: Maybe<GetLaunchOnboardingResponse>;
  onboardingServiceGetLaunchOnboardingWarehouseLocations?: Maybe<GetLaunchOnboardingWarehouseLocationsResponse>;
  onboardingServiceGetOnboarding?: Maybe<GetOnboardingResponse>;
  onboardingServiceGetSignupPartner?: Maybe<GetSignupPartnerResponse>;
  /** Get workflow details */
  onboardingWorkflow: Array<OnboardingFeatureTypesWorkflow>;
  orderServiceGetDashboardOrder?: Maybe<GetDashboardOrderResponse>;
  orderServiceGetDashboardOrders?: Maybe<GetDashboardOrdersResponse>;
  orderServiceGetOrder?: Maybe<GetOrderResponse>;
  orderServiceGetOrderLocation?: Maybe<GetOrderLocationResponse>;
  orderServiceGetOrderStateChanges?: Maybe<GetOrderStateChangesResponse>;
  /** Fetch Types of Order Sources for a given retailer */
  orderSources: OrderSources;
  /** Fetch a specific partner by id */
  partner: Partner;
  /** Fetch a specific partner pick device by id */
  partnerPickDevice: PartnerPickDevicesDevice;
  /** Fetch list of partner pick devices */
  partnerPickDeviceSearch: PartnerPickDevicesDeviceSearch;
  /** Fetch capacity recommendations for a given retailer location and date */
  partnerPickGetCapacityRecommendations: PartnerPickCapacityGetCapacityRecommendationsResponse;
  /** Check if recurring_shift being created will conflict with existing shifts */
  partnerPickRecurringShiftConflictCheck: PartnerPickRecurringShiftsConflict;
  /** Search for partners (and its warehouses) matching a set of criteria. Returns a set of partners */
  partnerRetailerSearch: PartnerConnection;
  /** Search for partners matching a set of criteria */
  partnerSearch: PartnerConnection;
  /** Search for partners matching a set of criteria - returns an array instead of a connection type */
  partnerSearchArray: Array<Partner>;
  /** Fetches multiple partners by their ids */
  partners: Array<Partner>;
  /** Fetches the list of permissions available for the current user */
  permissions: Array<Permission>;
  /** Retrieve pickup retailer location groups that belong to a specific retailer */
  pickupRetailerLocationGroup: Array<RetailersPickupRetailerLocationGroup>;
  privacyServiceGetPiiRequestsForRetailer?: Maybe<GetPiiRequestsForRetailerResponse>;
  productDataServiceGetProductData?: Maybe<GetProductDataResponse>;
  productSourceServiceGetProductSources?: Maybe<GetProductSourcesResponse>;
  productsServiceGetFlattenedTaxonomies?: Maybe<GetFlattenedTaxonomiesResponse>;
  productsServiceGetLegacyElasticsearch?: Maybe<LegacyElasticsearchResponse>;
  productsServiceGetLegacyItemsShoppers?: Maybe<LegacyItemsTyped>;
  productsServiceGetLegacyProducts?: Maybe<LegacyProducts>;
  productsServiceGetManagedAttributeValuesForRetailer?: Maybe<GetManagedAttributeValuesForRetailerResponse>;
  productsServiceSearchBrands?: Maybe<SearchBrandsResponse>;
  /** Retrieve a list of reports requested by the retailer */
  reportRequests: ReportsReportRequestConnection;
  /** Retrieve a report view by report view id */
  reportView: ReportViewsInterface;
  /** Retrieve a list of available reports to request */
  reports: Array<ReportsReport>;
  /** Fetch a specific retailer by id */
  retailer: Retailer;
  retailerAislesGetRetailerAisleTemplate?: Maybe<GetRetailerAisleTemplateResponse>;
  retailerAislesGetRetailerLocationByLayout?: Maybe<GetRetailerLocationByLayoutResponse>;
  retailerCampaignServiceGetMoment?: Maybe<GetMomentResponse>;
  retailerCampaignServiceGetMomentParticipatingRetailers?: Maybe<GetMomentParticipatingRetailersResponse>;
  retailerCampaignServiceGetMoments?: Maybe<GetMomentsResponse>;
  retailerCampaignServiceGetRetailerCampaign?: Maybe<RetailerCampaignResponse>;
  retailerCampaignServiceListMoments?: Maybe<ListMomentsResponse>;
  retailerCampaignServiceListRetailerCampaignTreatments?: Maybe<ListRetailerCampaignTreatmentsResponse>;
  retailerCampaignServiceListRetailerCampaigns?: Maybe<ListRetailerCampaignsResponse>;
  /** Fetch a specific retailer location by id */
  retailerLocation: RetailerLocation;
  /** Retrieve a retailer location group by id */
  retailerLocationGroup: RetailersRetailerLocationGroup;
  /** Retrieve retailer location groups that belong to a specific retailer */
  retailerLocationGroupsByRetailer: RetailersRetailerLocationGroupConnection;
  /** Search for retailer locations matching a set of criteria */
  retailerLocationSearch: RetailerLocationConnection;
  /** Search for retailer locations matching a set of criteria - returns an array instead of a connection type */
  retailerLocationSearchArray: Array<RetailerLocation>;
  retailerManagementServiceGetConnectRetailerCallbackConfigurationById?: Maybe<GetConnectRetailerCallbackConfigurationByIdResponse>;
  retailerManagementServiceGetConnectRetailerCallbackConfigurations?: Maybe<GetConnectRetailerCallbackConfigurationsResponse>;
  /** Fetch a Retailer's MFA configuration settings by id */
  retailerMfaConfig: RetailerMfaConfig;
  /** Fetch pickup metadata for a retailer by its id */
  retailerPickupMetadata: RetailersRetailerPickupMetadata;
  retailerProductAttributeEditServiceGetAvailabilityScoreOverride?: Maybe<GetAvailabilityScoreOverrideResponse>;
  retailerProductAttributeEditServiceGetRetailerProductAssortment?: Maybe<GetRetailerProductAssortmentResponse>;
  /** Search for retailers matching a set of criteria */
  retailerSearch: RetailerConnection;
  /** Search for retailers matching a set of criteria - returns an array instead of a connection type */
  retailerSearchArray: Array<Retailer>;
  retailerSettingServiceGetSetting?: Maybe<GetSettingResponse>;
  retailerSettingsServiceGetIsGrocer?: Maybe<GetIsGrocerResponse>;
  retailerSettingsServiceGetParkingSettings?: Maybe<GetParkingSettingsResponse>;
  retailerSettingsServiceGetPickupSettingsMetadata?: Maybe<GetPickupSettingsMetadataResponse>;
  retailerSettingsServiceGetStagingSettings?: Maybe<GetStagingSettingsResponse>;
  retailerStoreHoursGetConfirmedStoreHours?: Maybe<GetConfirmedStoreHoursResponse>;
  retailerStoreHoursGetRetailerHolidaysStatus?: Maybe<GetHolidaysStatusResponse>;
  retailerStoreHoursGetStoreHours?: Maybe<GetStoreHoursResponse>;
  retailerStoreHoursGetStoreHoursUpdateTasks?: Maybe<GetStoreHoursUpdateTasksResponse>;
  retailerToolsServiceGetCustomersContext?: Maybe<GetCustomersContextResponse>;
  retailerToolsServiceGetItems?: Maybe<GetItemsResponse>;
  retailerToolsServiceGetRetailerProducts?: Maybe<GetRetailerProductsResponse>;
  retailerToolsServiceGetVirtualRetailers?: Maybe<GetVirtualRetailersResponse>;
  retailersServiceGetPlatformVersions?: Maybe<GetPlatformVersionsResponse>;
  retailersServiceGetRetailerLocationsByLocationCodes?: Maybe<GetRetailerLocationsByLocationCodesResponse>;
  retailersServiceGetRetailerLocationsDetails?: Maybe<GetRetailerLocationsDetailsResponse>;
  retailersServiceGetRetailerRegions?: Maybe<GetRetailerRegionsResponse>;
  /** Search for roles */
  roleSearch: Array<EnterpriseRole>;
  rppBulkImageUploadsServiceGetImageUploadBatchDetails?: Maybe<GetImageUploadBatchDetailsResponse>;
  rppBulkImageUploadsServiceGetImageUploadBatches?: Maybe<GetImageUploadBatchesResponse>;
  serviceOptionServiceGetServiceOptions?: Maybe<GetServiceOptionsResponse>;
  /** Fetch Store Configuration by Id */
  storeConfigurationById: InstacartStoreConfiguration;
  /** Fetch list of Store Configurations by Partner/Retailer IDs */
  storeConfigurationsByRetailerIds: Array<InstacartStoreConfiguration>;
  storeConfigurationsDraftServiceGetStoreConfigurationDrafts?: Maybe<GetStoreConfigurationDraftsResponse>;
  storeConfigurationsDraftServiceReadLatestStoreConfigurationDraft?: Maybe<ReadLatestStoreConfigurationDraftResponse>;
  storeConfigurationsDraftServiceReadStoreConfiguration?: Maybe<ReadStoreConfigurationResponse>;
  storePerformanceServiceGetAggregatedStorePerformance?: Maybe<StorePerformanceResponse>;
  /** Fetch a storefront placement by id */
  storefrontPlacement: ContentManagementStorefrontPlacement;
  /** Fetch a list of storefront placements */
  storefrontPlacementSearch: ContentManagementStorefrontPlacementConnection;
  /** Fetch taxonomy nodes */
  taxonomyNodes: Array<CatalogTaxonomyNode>;
  userSettingServiceListUserSettings?: Maybe<ListUserSettingsResponse>;
  usersServiceBatchGetUsersByIdsStrict?: Maybe<BatchGetUsersByIdsStrictResponse>;
  usersServiceGetUserByIdFilteredByIsolationContext?: Maybe<UserResponse>;
  usersServiceListUsersByPersonalIdentifiersStrict?: Maybe<ListUsersByPersonalIdentifiersStrictResponse>;
  valueBasedOverrideServiceGetValueBasedOverrides?: Maybe<GetValueBasedOverridesResponse>;
  /** Fetch visibility conditions */
  visibilityConditionSearch: Array<VisibilityCondition>;
  /** Returns a WelcomeModal that should be shown to current user */
  welcomeModal: EnterpriseWelcomeModal;
  /** Find progress of wizard for given partner/retailer/location and type */
  wizardProgress?: Maybe<WizardsWizardProgress>;
};


export type QueryAccountRequestPreviousRequestExistByEmailArgs = {
  email: Scalars['String'];
};


export type QueryAccountRequestRetailerSearchArgs = {
  namePrefix: Scalars['String'];
};


export type QueryAccountRequestSearchByIdArgs = {
  accountRequestId: Scalars['ID'];
};


export type QueryAccountTokenValidateArgs = {
  rawToken: Scalars['String'];
  tokenType: TokenTypeEnum;
};


export type QueryAccountsByNotificationTypeArgs = {
  ilikeEmail?: InputMaybe<Scalars['String']>;
  notificationTypeId: Scalars['ID'];
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type QueryApiIntegrationsServiceGetApiSettingsArgs = {
  input?: InputMaybe<GetApiSettingsRequestInput>;
};


export type QueryApiIntegrationsServiceGetAuthenticationUrlArgs = {
  input?: InputMaybe<GetAuthenticationUrlRequestInput>;
};


export type QueryApiIntegrationsServiceGetAvailableStoreLocationsArgs = {
  input?: InputMaybe<GetAvailableStoreLocationsRequestInput>;
};


export type QueryApprovalsManagementGetRequestArgs = {
  arId: Scalars['ID'];
};


export type QueryApprovalsManagementGetSnapshotsArgs = {
  snapshotIds: Array<Scalars['ID']>;
};


export type QueryApprovalsManagementGetUserPermittedActionsArgs = {
  approvalRequestIds: Array<Scalars['ID']>;
};


export type QueryApprovalsManagementListRequestsArgs = {
  affectingScopeSearch?: InputMaybe<AffectingScopeSearchInput>;
  contentSearches?: InputMaybe<Array<ContentSearchInput>>;
  ilikeName?: InputMaybe<Scalars['String']>;
  includesStates?: InputMaybe<Array<ApprovalRequestState>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAssignmentsGetOrderFulfillmentOverviewArgs = {
  input?: InputMaybe<GetOrderFulfillmentOverviewRequestInput>;
};


export type QueryAuditLogSearchArgs = {
  accountEmails?: InputMaybe<Array<Scalars['String']>>;
  accountUuids?: InputMaybe<Array<Scalars['ID']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<Array<Scalars['ID']>>;
  entityType?: InputMaybe<AuditLogEntityTypeEnum>;
  eventName?: InputMaybe<Scalars['String']>;
  eventTimeEndAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  eventTimeStartAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryAvailabilityGetStoreHoursByDateArgs = {
  input?: InputMaybe<GetStoreHoursByDateRequestInput>;
};


export type QueryAvailabilityGetStoreHoursByDayOfWeekArgs = {
  input?: InputMaybe<GetStoreHoursByDayOfWeekRequestInput>;
};


export type QueryAvailabilityGetStoreHoursByZoneRetailerArgs = {
  input?: InputMaybe<GetStoreHoursByZoneRetailerRequestInput>;
};


export type QueryCatalogAisleArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogDepartmentArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogProblemTrackerServiceGetTicketArgs = {
  input?: InputMaybe<GetTicketRequestInput>;
};


export type QueryCatalogProblemTrackerServiceListTicketsArgs = {
  input?: InputMaybe<ListTicketsRequestInput>;
};


export type QueryCatalogProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryCatalogRetailerProductArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogRetailerProductSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  aisleIds?: InputMaybe<Array<Scalars['ID']>>;
  available?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  localeCode?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
  searchLimit?: InputMaybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
  searchType: Scalars['String'];
};


export type QueryCatalogSearchDownloadSignedUrlArgs = {
  s3Key: Scalars['String'];
};


export type QueryCatalogToolsApiServiceDownloadCatalogSearchResultsArgs = {
  input?: InputMaybe<DownloadCatalogSearchResultsRequestInput>;
};


export type QueryCatalogToolsApiServiceGetAnomalousItemsListArgs = {
  input?: InputMaybe<GetAnomalousItemsListRequestInput>;
};


export type QueryCatalogToolsApiServiceGetAnomalousItemsReportArgs = {
  input?: InputMaybe<GetAnomalousItemsReportRequestInput>;
};


export type QueryCatalogToolsApiServiceGetLocalesByRetailerArgs = {
  input?: InputMaybe<GetLocalesByRetailerRequestInput>;
};


export type QueryCatalogToolsApiServiceGetRetailerProductDataArgs = {
  input?: InputMaybe<GetRetailerProductDataRequestInput>;
};


export type QueryCollectionsServiceGetCollectionsArgs = {
  input?: InputMaybe<GetCollectionsRequestInput>;
};


export type QueryCommerceInvoicingServiceGetOrderDiscountDetailsArgs = {
  input?: InputMaybe<GetOrderDiscountDetailsRequestInput>;
};


export type QueryConfigurableItemsServiceGetRppConfigurableItemsArgs = {
  input?: InputMaybe<GetRppConfigurableItemsRequestInput>;
};


export type QueryConnectPlatformServiceGetConnectSettingsArgs = {
  input?: InputMaybe<GetConnectSettingsRequestInput>;
};


export type QueryConnectPlatformServiceGetPartnerApiKeysArgs = {
  input?: InputMaybe<GetPartnerApiKeysRequestInput>;
};


export type QueryConnectPlatformServiceGetPartnerIdArgs = {
  input?: InputMaybe<GetPartnerIdRequestInput>;
};


export type QueryConnectPlatformServiceGetRequestContextsArgs = {
  input?: InputMaybe<GetRequestContextsRequestInput>;
};


export type QueryContentManagementAdminServiceGetNavigationArgs = {
  input?: InputMaybe<GetNavigationRequestInput>;
};


export type QueryContentManagementAdminServiceGetPlacementContentByIdArgs = {
  input?: InputMaybe<GetPlacementContentByIdRequestInput>;
};


export type QueryContentManagementAdminServiceGetSlotByIdArgs = {
  input?: InputMaybe<GetSlotByIdRequestInput>;
};


export type QueryContentManagementAdminServiceListContentPageVersionMetasArgs = {
  input?: InputMaybe<ListContentPageVersionMetasRequestInput>;
};


export type QueryContentManagementAdminServiceListContentPageVersionsArgs = {
  input?: InputMaybe<ListContentPageVersionsRequestInput>;
};


export type QueryContentManagementAdminServiceListContentPagesV2Args = {
  input?: InputMaybe<ListContentPagesRequestV2Input>;
};


export type QueryContentManagementAdminServiceListPlacementContentArgs = {
  input?: InputMaybe<ListPlacementContentRequestInput>;
};


export type QueryContentManagementServiceGetLayoutVariantsArgs = {
  input?: InputMaybe<GetLayoutVariantsRequestInput>;
};


export type QueryContentManagementServiceGetPlacementsArgs = {
  input?: InputMaybe<GetPlacementsRequestInput>;
};


export type QueryContentManagementServiceGetThemePageArgs = {
  input?: InputMaybe<GetThemePageRequestInput>;
};


export type QueryContentManagementServiceListThemePagesArgs = {
  input?: InputMaybe<ListThemePagesRequestInput>;
};


export type QueryCurrentEnterpriseAccountAuthorizationCheckArgs = {
  accountFeatureToggles?: InputMaybe<Array<Scalars['String']>>;
  approvalRequestIds?: InputMaybe<Array<Scalars['ID']>>;
  featureEntitlementIds?: InputMaybe<Array<Scalars['ID']>>;
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
  requireAllPartnersFullAccess?: InputMaybe<Scalars['Boolean']>;
  requireFullPartnerAccess?: InputMaybe<Scalars['Boolean']>;
  requireFullRetailerAccess?: InputMaybe<Scalars['Boolean']>;
  requireWriteAccess?: InputMaybe<Scalars['Boolean']>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
  storeConfigIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryCustomersOrdersServiceGetHandlingDetailsArgs = {
  input?: InputMaybe<CustomersGetHandlingDetailsRequestInput>;
};


export type QueryCustomersOrdersServiceGetOrderArgs = {
  input?: InputMaybe<CustomersGetOrderRequestInput>;
};


export type QueryCustomersOrdersServiceGetOrderAmountsSummaryV0Args = {
  input?: InputMaybe<CustomersGetOrderAmountsSummaryV0RequestInput>;
};


export type QueryCustomersOrdersServiceGetPaymentMethodSummaryArgs = {
  input?: InputMaybe<CustomersGetPaymentMethodSummaryRequestInput>;
};


export type QueryCustomersOrdersServiceListOrdersArgs = {
  input?: InputMaybe<CustomersListOrdersRequestInput>;
};


export type QueryCustomersOrdersServiceQueryOrderReferenceArgs = {
  input?: InputMaybe<CustomersQueryOrderReferenceRequestInput>;
};


export type QueryCustomersOrdersServiceQueryOrdersArgs = {
  input?: InputMaybe<CustomersQueryOrdersRequestInput>;
};


export type QueryDemeterServiceGetAuditBatchArgs = {
  input?: InputMaybe<GetAuditBatchRequestInput>;
};


export type QueryDemeterServiceGetAuditBatchMetadataArgs = {
  input?: InputMaybe<GetAuditBatchMetadataRequestInput>;
};


export type QueryDemeterServiceGetAuditTaskArgs = {
  input?: InputMaybe<GetAuditTaskRequestInput>;
};


export type QueryDemeterServiceGetDetectionArgs = {
  input?: InputMaybe<GetDetectionRequestInput>;
};


export type QueryDemeterServiceGetNextAuditBatchArgs = {
  input?: InputMaybe<GetNextAuditBatchRequestInput>;
};


export type QueryDemeterServiceListAuditBatchesArgs = {
  input?: InputMaybe<ListAuditBatchesRequestInput>;
};


export type QueryDemeterServiceListAuditWorkflowsArgs = {
  input?: InputMaybe<ListAuditWorkflowsRequestInput>;
};


export type QueryDemeterServiceListDetectionsArgs = {
  input?: InputMaybe<ListDetectionsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetBrandProfileAttributesArgs = {
  input?: InputMaybe<BrandProfileAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetBrandProfileSellSheetCardsArgs = {
  input?: InputMaybe<BrandProfileSellSheetCardsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetBrandSellSheetsAttributesArgs = {
  input?: InputMaybe<BrandSellSheetsAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetEbpAnalyticsArgs = {
  input?: InputMaybe<EbpAnalyticsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetEbpProductAnalyticsSummaryArgs = {
  input?: InputMaybe<ProductAnalyticSummaryRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetEbpProductAttributesArgs = {
  input?: InputMaybe<ProductAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetIndexBrandNamesArgs = {
  input?: InputMaybe<IndexBrandNamesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetIndexEbpCategoriesArgs = {
  input?: InputMaybe<IndexEbpCategoriesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetIndexSellSheetCardsArgs = {
  input?: InputMaybe<SellSheetCardsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetProductBrandProfileAttributesArgs = {
  input?: InputMaybe<ProductBrandProfileAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetProductSellSheetCardsArgs = {
  input?: InputMaybe<ProductSellSheetCardsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetAnalyticsSummaryArgs = {
  input?: InputMaybe<SellSheetAnalyticsSummaryRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetAttributesArgs = {
  input?: InputMaybe<SellSheetAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetBrandProfileAttributesArgs = {
  input?: InputMaybe<SellSheetBrandProfileAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetProductsAttributesArgs = {
  input?: InputMaybe<SellSheetProductsAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetProductsSummaryTraitsArgs = {
  input?: InputMaybe<SellSheetProductsSummaryTraitsRequestInput>;
};


export type QueryEnterpriseAccountArgs = {
  canonicalId?: InputMaybe<Scalars['ID']>;
};


export type QueryEnterpriseAccountSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  canonicalIds?: InputMaybe<Array<Scalars['ID']>>;
  email?: InputMaybe<Scalars['String']>;
  excludeInternalUsers?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<AccountStatusEnum>;
};


export type QueryFeatureToggleFullyEnabledArgs = {
  featureName: Scalars['String'];
};


export type QueryHealthCheckArgs = {
  metricsToken?: InputMaybe<Scalars['String']>;
};


export type QueryHistoricalStorePerformanceServiceGetAggregatedHistoricalStorePerformanceArgs = {
  input?: InputMaybe<HistoricalStorePerformanceRequestInput>;
};


export type QueryHomePlacementSearchArgs = {
  filter?: InputMaybe<PlacementFilterInput>;
  placementId?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<PlacementSortInput>;
};


export type QueryInsightsPortalAuthorizationCheckArgs = {
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryInsightsPortalServiceGetInsightsPortalCustomReportArgs = {
  input?: InputMaybe<InsightsPortalGetCustomReportRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalFilterOptionsArgs = {
  input?: InputMaybe<InsightsPortalFilterOptionsRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalMetricValuesArgs = {
  input?: InputMaybe<InsightsPortalMetricValuesRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalMultiDimensionDatasetArgs = {
  input?: InputMaybe<InsightsPortalMultiDimensionDatasetRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalMultiGroupDataArgs = {
  input?: InputMaybe<InsightsPortalMultiGroupRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalSchemaMetadataArgs = {
  input?: InputMaybe<InsightsPortalSchemaMetadataRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalSnowflakeHeartbeatArgs = {
  input?: InputMaybe<InsightsPortalSnowflakeHeartbeatRequestInput>;
};


export type QueryInsightsPortalServiceListInsightsPortalCustomReportsArgs = {
  input?: InputMaybe<InsightsPortalListCustomReportsRequestInput>;
};


export type QueryInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_GetAllSegmentDefinitionsArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_GetAllSegmentDefinitionsRequest_Input>;
};


export type QueryInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_GetSegmentDefinitionArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_GetSegmentDefinitionRequest_Input>;
};


export type QueryInstacart_Ads_V3_EmergingBrandPortalService_GetIndexBrandNamesArgs = {
  input?: InputMaybe<Instacart_Ads_V3_IndexBrandNamesRequest_Input>;
};


export type QueryInstacart_Ads_V3_EmergingBrandPortalService_GetProductAdditionalInfoArgs = {
  input?: InputMaybe<Instacart_Ads_V3_ProductAdditionalInfoRequest_Input>;
};


export type QueryInstacart_Ads_V3_RetailerBlockedAdsService_GetRetailerBlockedAdIdsArgs = {
  input?: InputMaybe<Instacart_Ads_V3_GetRetailerBlockedAdIdsRequest_Input>;
};


export type QueryInstacart_Ads_V3_RetailerReviewToolService_GetDisplayPreviewDataArgs = {
  input?: InputMaybe<Instacart_Ads_V3_GetDisplayPreviewDataRequest_Input>;
};


export type QueryInstacart_Caper_Admin_V1_ConfigManagementService_GetAllEntitiesArgs = {
  input?: InputMaybe<Scalars['instacart_caper_admin_v1_GetAllEntitiesRequest_Input']>;
};


export type QueryInstacart_Caper_Admin_V1_ConfigManagementService_GetConfigKeysArgs = {
  input?: InputMaybe<Scalars['instacart_caper_admin_v1_GetConfigKeysRequest_Input']>;
};


export type QueryInstacart_Cashflow_Nutella_V1_NutellaService_GetAuditReviewArgs = {
  input?: InputMaybe<Instacart_Cashflow_Nutella_V1_GetAuditReviewRequest_Input>;
};


export type QueryInstacart_Cashflow_Nutella_V1_NutellaService_GetNextAuditReviewArgs = {
  input?: InputMaybe<Instacart_Cashflow_Nutella_V1_GetNextAuditReviewRequest_Input>;
};


export type QueryInstacart_Cashflow_Nutella_V1_NutellaService_GetWorkflowMetadataArgs = {
  input?: InputMaybe<Instacart_Cashflow_Nutella_V1_GetWorkflowMetadataRequest_Input>;
};


export type QueryInstacart_Checkout_V1_PosDataService_GetTenderTransactionsByWhlIdsArgs = {
  input?: InputMaybe<Instacart_Fulfillment_Shared_V1_GetTenderTransactionsByWhlIdsRequest_Input>;
};


export type QueryInstacart_Customers_Basket_Products_Orchestrators_V1_BasketProductsOrchestratorsService_GetBasketProductsArgs = {
  input?: InputMaybe<Instacart_Customers_Basket_Products_Orchestrators_V1_GetBasketProductsRequest_Input>;
};


export type QueryInstacart_Customers_Business_Admin_V1_BusinessAdminService_GetBusinessProgramArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramRequest_Input>;
};


export type QueryInstacart_Customers_Business_Admin_V1_BusinessAdminService_GetBusinessProgramContentArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentRequest_Input>;
};


export type QueryInstacart_Customers_Business_Admin_V1_BusinessAdminService_GetBusinessProgramContentByProgramIdArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentByProgramIdRequest_Input>;
};


export type QueryInstacart_Customers_Business_Admin_V1_BusinessAdminService_GetBusinessProgramOfferArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramOfferRequest_Input>;
};


export type QueryInstacart_Customers_Business_Admin_V1_BusinessAdminService_GetBusinessProgramOffersByProgramIdArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_GetBusinessProgramOffersByProgramIdRequest_Input>;
};


export type QueryInstacart_Customers_Business_Admin_V1_BusinessAdminService_ListBusinessProgramArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_ListBusinessProgramRequest_Input>;
};


export type QueryInstacart_Customers_Business_Admin_V1_BusinessAdminService_ListBusinessesArgs = {
  input?: InputMaybe<Instacart_Customers_Business_Admin_V1_ListBusinessesRequest_Input>;
};


export type QueryInstacart_Customers_Campaigns_V1_CampaignsService_GetBucketDownloadUrlArgs = {
  input?: InputMaybe<Instacart_Customers_Campaigns_V1_GetBucketDownloadUrlRequest_Input>;
};


export type QueryInstacart_Customers_Campaigns_V1_CampaignsService_GetBucketUploadUrlArgs = {
  input?: InputMaybe<Instacart_Customers_Campaigns_V1_GetBucketUploadUrlRequest_Input>;
};


export type QueryInstacart_Customers_Campaigns_V1_CampaignsService_GetCsvFoundEntriesCountArgs = {
  input?: InputMaybe<Instacart_Customers_Campaigns_V1_GetCsvFoundEntriesCountRequest_Input>;
};


export type QueryInstacart_Customers_Campaigns_V1_CampaignsService_GetCsvProcessingSummaryArgs = {
  input?: InputMaybe<Instacart_Customers_Campaigns_V1_GetCsvProcessingSummaryRequest_Input>;
};


export type QueryInstacart_Customers_Campaigns_V1_CampaignsService_GetGroupedSlotsArgs = {
  input?: InputMaybe<Instacart_Customers_Campaigns_V1_GetGroupedSlotsRequest_Input>;
};


export type QueryInstacart_Customers_Commerce_Discount_V1_CommerceDiscountService_GetValueGrantPolicyFacadeArgs = {
  input?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GetValueGrantPolicyFacadeRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_CouponsDomainService_GetCouponCodeExistsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_GetCouponCodeExistsRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_RetailerCampaignService_GetMomentArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_GetMomentRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_RetailerCampaignService_GetMomentParticipatingRetailersArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_GetMomentParticipatingRetailersRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_RetailerCampaignService_GetMomentsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_GetMomentsRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_RetailerCampaignService_GetRetailerCampaignArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_GetRetailerCampaignRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_RetailerCampaignService_ListMomentsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_ListMomentsRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_RetailerCampaignService_ListRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_ListRetailerCampaignTreatmentsRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_RetailerCampaignService_ListRetailerCampaignsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_ListRetailerCampaignsRequest_Input>;
};


export type QueryInstacart_Customers_Express_V1_ExpressService_GetCurrentExpressMemberStatusForUserArgs = {
  input?: InputMaybe<Instacart_Customers_Express_V1_GetCurrentExpressMemberStatusForUserRequest_Input>;
};


export type QueryInstacart_Customers_Loyalty_V1_LoyaltyService_GetLoyaltyCardsByCardNumberArgs = {
  input?: InputMaybe<Instacart_Customers_Loyalty_V1_GetLoyaltyCardsByCardNumberRequest_Input>;
};


export type QueryInstacart_Customers_Loyalty_V1_LoyaltyService_GetLoyaltyCardsForUserArgs = {
  input?: InputMaybe<Instacart_Customers_Loyalty_V1_GetLoyaltyCardsForUserRequest_Input>;
};


export type QueryInstacart_Customers_Orders_V1_OrdersService_GetOrderLinesArgs = {
  input?: InputMaybe<Instacart_Customers_Orders_V1_GetOrderLinesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetCountriesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetCountriesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetPostalCodesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetPostalCodesByZonesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRegionsArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRegionsRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRetailerLocationsArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRetailerLocationsByRetailerArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRetailerZonesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerZonesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRetailersArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailersRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetZonesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetZonesByRegionArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesByRegionRequest_Input>;
};


export type QueryInstacart_Customers_Partnership_Offer_V1_PartnershipOfferService_GetPartnershipManagementUrlDownloadLinkArgs = {
  input?: InputMaybe<Instacart_Customers_Partnership_Offer_V1_GetPartnershipManagementUrlDownloadLinkRequest_Input>;
};


export type QueryInstacart_Customers_Partnership_Offer_V1_PartnershipOfferService_ListPartnershipManagementUrlsArgs = {
  input?: InputMaybe<Instacart_Customers_Partnership_Offer_V1_ListPartnershipManagementUrlsRequest_Input>;
};


export type QueryInstacart_Customers_Recipes_V1_RecipesService_GetCategoriesByNameArgs = {
  input?: InputMaybe<Instacart_Customers_Recipes_V1_GetCategoriesByNameRequest_Input>;
};


export type QueryInstacart_Customers_Recipes_V1_RecipesService_GetCoreIngredientsArgs = {
  input?: InputMaybe<Scalars['instacart_customers_recipes_v1_GetCoreIngredientsRequest_Input']>;
};


export type QueryInstacart_Customers_Recipes_V1_RecipesService_GetRecipeByIdArgs = {
  input?: InputMaybe<Instacart_Customers_Recipes_V1_GetRecipeByIdRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_GetFlyerArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_GetFlyerActivitiesArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerActivitiesRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_GetFlyerClickableAreasArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerClickableAreasRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_GetFlyerTagsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerTagsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_FlyerService_ListFlyersArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListFlyersRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetCollectionRulesArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetNavigationTreeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetRetailerCsvArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_ListCollectionProductsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_ListCollectionsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_ListRetailerDepartmentsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListRetailerDepartmentsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_SearchCollectionProductsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_GetIppDraftByIdArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_GetIppDraftByIdRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_ListIppDraftsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Management_V1_IppDraftPreviewService_GetIppPreviewByTokenArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Management_V1_IppDraftPreviewService_GetPreviewAuthTokenArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenRequest_Input>;
};


export type QueryInstacart_Enterprise_Boba_Core_Offers_V1_OfferService_GetOrderDiscountInfoArgs = {
  input?: InputMaybe<Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoRequest_Input>;
};


export type QueryInstacart_Enterprise_Frigate_Fulfillment_V1_DashboardOrderService_GetDashboardOrderArgs = {
  input?: InputMaybe<Instacart_Enterprise_Frigate_Fulfillment_V1_GetDashboardOrderRequest_Input>;
};


export type QueryInstacart_Enterprise_Frigate_Fulfillment_V1_DashboardOrderService_GetOrderCancellationPolicyListArgs = {
  input?: InputMaybe<Instacart_Enterprise_Frigate_Fulfillment_V1_GetOrderCancellationPolicyListRequest_Input>;
};


export type QueryInstacart_Enterprise_Frigate_Post_Checkout_V1_EnterpriseChatService_GetEnterpriseChatMessageListArgs = {
  input?: InputMaybe<Instacart_Enterprise_Frigate_Post_Checkout_V1_GetEnterpriseChatMessageListRequest_Input>;
};


export type QueryInstacart_Growth_Crm_Messaging_Service_V1_CrmMessagingService_GetCampaignStatusArgs = {
  input?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_GetCampaignStatusRequest_Input>;
};


export type QueryInstacart_Growth_Crm_Messaging_Service_V1_CrmMessagingService_GetSegmentSizeArgs = {
  input?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_GetSegmentSizeRequest_Input>;
};


export type QueryInstacart_Growth_Crm_Messaging_Service_V1_CrmMessagingService_GetTemplateArgs = {
  input?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_GetTemplateRequest_Input>;
};


export type QueryInstacart_Growth_Crm_Messaging_Service_V1_CrmMessagingService_ListSegmentsArgs = {
  input?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_ListSegmentsRequest_Input>;
};


export type QueryInstacart_Growth_Crm_Messaging_Service_V1_CrmMessagingService_ListTemplatesArgs = {
  input?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_ListTemplatesRequest_Input>;
};


export type QueryInstacart_Infra_Totem_V1_TotemService_GetEntityArgs = {
  input?: InputMaybe<Instacart_Infra_Totem_V1_GetEntityRequest_Input>;
};


export type QueryInstacart_Infra_Totem_V1_TotemService_GetSystemArgs = {
  input?: InputMaybe<Instacart_Infra_Totem_V1_GetSystemRequest_Input>;
};


export type QueryInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_GetOpsEscalationRequestArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_GetOpsEscalationRequestRequest_Input>;
};


export type QueryInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_ListDuplicateOpsEscalationRequestsArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_ListDuplicateOpsEscalationRequestsRequest_Input>;
};


export type QueryInstacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequests_ListOpsEscalationRequestsArgs = {
  input?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_ListOpsEscalationRequestsRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_GetFulfillmentEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_GetFulfillmentEventRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ListChildFulfillmentEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ListChildFulfillmentEventsRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ListCreatorsArgs = {
  input?: InputMaybe<Scalars['instacart_logistics_v1_ListCreatorsRequest_Input']>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ListFulfillmentEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ListFulfillmentEventsRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ListTagsArgs = {
  input?: InputMaybe<Scalars['instacart_logistics_v1_ListTagsRequest_Input']>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ValidateNewClosureEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ValidateNewClosureEventRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ValidateNewFulfillmentEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ValidateNewFulfillmentEventRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ValidateNewRestrictedAvailabilityEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventRequest_Input>;
};


export type QueryInstacart_Partners_Data_Ingestion_V1_ChangeApprovalService_GetChangeApprovalRequestArgs = {
  input?: InputMaybe<Instacart_Partners_Data_Ingestion_V1_GetChangeApprovalRequestRequest_Input>;
};


export type QueryInstacart_Partners_Partners_V1_PartnerConfigurationsService_GetPartnerConfigurationsArgs = {
  input?: InputMaybe<Instacart_Partners_Partners_V1_GetPartnerConfigurationsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Growth_Targeting_V1_GrowthTargetingToolingService_GetTargetingFeaturesArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Growth_Targeting_V1_GetTargetingFeaturesRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetEntitlementDetailsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetEntitlementsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetPackageDetailsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetPackagesArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetRetailerEntitlementProfileDetailsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetRetailerEntitlementProfilesArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_V2_InsightsPortalService_DownloadInsightsPortalCustomExportArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalDownloadCustomExportRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_V2_InsightsPortalService_GetInsightsPortalCustomReportArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalGetCustomReportRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_V2_InsightsPortalService_ListInsightsPortalCustomExportsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalListCustomExportsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_V2_InsightsPortalService_ListInsightsPortalCustomReportsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalListCustomReportsRequest_Input>;
};


export type QueryInstacart_Retailers_V1_RetailersService_GetWidgetsConfigArgs = {
  input?: InputMaybe<Instacart_Retailers_V1_GetWidgetsConfigRequest_Input>;
};


export type QueryInstacart_Retailers_V1_RetailersService_ValidateWidgetsConfigArgs = {
  input?: InputMaybe<Instacart_Retailers_V1_ValidateWidgetsConfigRequest_Input>;
};


export type QueryInstacart_Roulette_V1_RouletteService_GetFeatureByNameAndVersionArgs = {
  input?: InputMaybe<Instacart_Roulette_V1_GetFeatureByNameAndVersionRequest_Input>;
};


export type QueryInstacart_Roulette_V1_RouletteService_ListFeaturesArgs = {
  input?: InputMaybe<Instacart_Roulette_V1_GetFeaturesRequest_Input>;
};


export type QueryInstacart_Roulette_V1_RouletteService_SearchFeaturesArgs = {
  input?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Input>;
};


export type QueryInventoryIntelligenceServiceGetAcknowledgedOosIdsArgs = {
  input?: InputMaybe<GetAcknowledgedOosIdsRequestInput>;
};


export type QueryLegacyPartnerArgs = {
  id: Scalars['ID'];
};


export type QueryLegacyWarehouseArgs = {
  id: Scalars['ID'];
};


export type QueryLegacyWarehouseLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLinkAutosuggestServiceListLinkAutosuggestionAdminArgs = {
  input?: InputMaybe<ListLinkAutosuggestionAdminRequestInput>;
};


export type QueryMarketingFeatureBrazeSegmentSizeArgs = {
  segmentId: Scalars['ID'];
};


export type QueryMarketingFeatureCampaignStatusArgs = {
  campaignId: Scalars['ID'];
};


export type QueryMarketingFeatureTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  pushNotificationId?: InputMaybe<Scalars['ID']>;
};


export type QueryNormalizedReportSignedUrlArgs = {
  bucketName: Scalars['String'];
  s3Key: Scalars['String'];
};


export type QueryNormalizedReportTypesArgs = {
  partnerId: Scalars['ID'];
};


export type QueryNormalizedReportsSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId: Scalars['ID'];
  reportType?: InputMaybe<ReportTypeEnum>;
  retailerId: Scalars['ID'];
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate: Scalars['ISO8601Date'];
};


export type QueryNotificationTypesForCurrentAccountOnRetailerArgs = {
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type QueryOnboardingMobileAppConfigArgs = {
  os: Os;
  storeConfigurationId: Scalars['Int'];
};


export type QueryOnboardingServiceCheckIfLaunchOnboardingInProgressByPartnerArgs = {
  input?: InputMaybe<CheckIfLaunchOnboardingInProgressByPartnerRequestInput>;
};


export type QueryOnboardingServiceCheckLaunchOnboardingProgressByPartnerArgs = {
  input?: InputMaybe<CheckLaunchOnboardingProgressByPartnerRequestInput>;
};


export type QueryOnboardingServiceGetApprovalRequestsArgs = {
  input?: InputMaybe<GetApprovalRequestsRequestInput>;
};


export type QueryOnboardingServiceGetCatalogFaqFileArgs = {
  input?: InputMaybe<GetCatalogFaqFileRequestInput>;
};


export type QueryOnboardingServiceGetLaunchOnboardingArgs = {
  input?: InputMaybe<GetLaunchOnboardingRequestInput>;
};


export type QueryOnboardingServiceGetLaunchOnboardingWarehouseLocationsArgs = {
  input?: InputMaybe<GetLaunchOnboardingWarehouseLocationsRequestInput>;
};


export type QueryOnboardingServiceGetOnboardingArgs = {
  input?: InputMaybe<GetOnboardingRequestInput>;
};


export type QueryOnboardingServiceGetSignupPartnerArgs = {
  input?: InputMaybe<GetSignupPartnerRequestInput>;
};


export type QueryOnboardingWorkflowArgs = {
  filter?: InputMaybe<WorkflowQueryFilter>;
  storeConfigurationId: Scalars['Int'];
};


export type QueryOrderServiceGetDashboardOrderArgs = {
  input?: InputMaybe<GetDashboardOrderRequestInput>;
};


export type QueryOrderServiceGetDashboardOrdersArgs = {
  input?: InputMaybe<GetDashboardOrdersRequestInput>;
};


export type QueryOrderServiceGetOrderArgs = {
  input?: InputMaybe<GetOrderRequestInput>;
};


export type QueryOrderServiceGetOrderLocationArgs = {
  input?: InputMaybe<GetOrderLocationRequestInput>;
};


export type QueryOrderServiceGetOrderStateChangesArgs = {
  input?: InputMaybe<GetOrderStateChangesRequestInput>;
};


export type QueryOrderSourcesArgs = {
  retailerId: Scalars['ID'];
};


export type QueryPartnerArgs = {
  id: Scalars['ID'];
};


export type QueryPartnerPickDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryPartnerPickDeviceSearchArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  warehouseLocationId: Scalars['ID'];
};


export type QueryPartnerPickGetCapacityRecommendationsArgs = {
  date: Scalars['ISO8601Date'];
  retailerLocationId: Scalars['ID'];
};


export type QueryPartnerPickRecurringShiftConflictCheckArgs = {
  daysOfWeek: Array<Scalars['Int']>;
  driverId: Scalars['ID'];
  existingDriverShiftId?: InputMaybe<Scalars['ID']>;
  existingRecurringShiftId?: InputMaybe<Scalars['ID']>;
  recurEndDate?: InputMaybe<Scalars['String']>;
  recurStartDate?: InputMaybe<Scalars['String']>;
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  warehouseLocationId: Scalars['ID'];
};


export type QueryPartnerRetailerSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryPartnerSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryPartnerSearchArrayArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryPartnersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPermissionsArgs = {
  retailerId?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['ID']>;
};


export type QueryPickupRetailerLocationGroupArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  retailerId: Scalars['ID'];
};


export type QueryPrivacyServiceGetPiiRequestsForRetailerArgs = {
  input?: InputMaybe<GetPiiRequestsForRetailerRequestInput>;
};


export type QueryProductDataServiceGetProductDataArgs = {
  input?: InputMaybe<GetProductDataRequestInput>;
};


export type QueryProductSourceServiceGetProductSourcesArgs = {
  input?: InputMaybe<GetProductSourcesRequestInput>;
};


export type QueryProductsServiceGetFlattenedTaxonomiesArgs = {
  input?: InputMaybe<GetFlattenedTaxonomiesRequestInput>;
};


export type QueryProductsServiceGetLegacyElasticsearchArgs = {
  input?: InputMaybe<GetLegacyElasticsearchRequestInput>;
};


export type QueryProductsServiceGetLegacyItemsShoppersArgs = {
  input?: InputMaybe<GetLegacyItemsShoppersRequestInput>;
};


export type QueryProductsServiceGetLegacyProductsArgs = {
  input?: InputMaybe<GetLegacyProductsRequestInput>;
};


export type QueryProductsServiceGetManagedAttributeValuesForRetailerArgs = {
  input?: InputMaybe<GetManagedAttributeValuesForRetailerRequestInput>;
};


export type QueryProductsServiceSearchBrandsArgs = {
  input?: InputMaybe<SearchBrandsRequestInput>;
};


export type QueryReportRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
};


export type QueryReportViewArgs = {
  reportViewId: Scalars['ID'];
};


export type QueryRetailerArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerAislesGetRetailerAisleTemplateArgs = {
  input?: InputMaybe<GetRetailerAisleTemplateRequestInput>;
};


export type QueryRetailerAislesGetRetailerLocationByLayoutArgs = {
  input?: InputMaybe<GetRetailerLocationByLayoutRequestInput>;
};


export type QueryRetailerCampaignServiceGetMomentArgs = {
  input?: InputMaybe<GetMomentRequestInput>;
};


export type QueryRetailerCampaignServiceGetMomentParticipatingRetailersArgs = {
  input?: InputMaybe<GetMomentParticipatingRetailersRequestInput>;
};


export type QueryRetailerCampaignServiceGetMomentsArgs = {
  input?: InputMaybe<GetMomentsRequestInput>;
};


export type QueryRetailerCampaignServiceGetRetailerCampaignArgs = {
  input?: InputMaybe<GetRetailerCampaignRequestInput>;
};


export type QueryRetailerCampaignServiceListMomentsArgs = {
  input?: InputMaybe<ListMomentsRequestInput>;
};


export type QueryRetailerCampaignServiceListRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<ListRetailerCampaignTreatmentsRequestInput>;
};


export type QueryRetailerCampaignServiceListRetailerCampaignsArgs = {
  input?: InputMaybe<ListRetailerCampaignsRequestInput>;
};


export type QueryRetailerLocationArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerLocationGroupArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerLocationGroupsByRetailerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerLocationSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeInactiveLocations?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  pickup?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerLocationSearchArrayArgs = {
  includeInactiveLocations?: InputMaybe<Scalars['Boolean']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  pickup?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerManagementServiceGetConnectRetailerCallbackConfigurationByIdArgs = {
  input?: InputMaybe<GetConnectRetailerCallbackConfigurationByIdRequestInput>;
};


export type QueryRetailerManagementServiceGetConnectRetailerCallbackConfigurationsArgs = {
  input?: InputMaybe<GetConnectRetailerCallbackConfigurationsRequestInput>;
};


export type QueryRetailerMfaConfigArgs = {
  retailerId: Scalars['ID'];
};


export type QueryRetailerPickupMetadataArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerProductAttributeEditServiceGetAvailabilityScoreOverrideArgs = {
  input?: InputMaybe<GetAvailabilityScoreOverrideRequestInput>;
};


export type QueryRetailerProductAttributeEditServiceGetRetailerProductAssortmentArgs = {
  input?: InputMaybe<GetRetailerProductAssortmentRequestInput>;
};


export type QueryRetailerSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerSearchArrayArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerSettingServiceGetSettingArgs = {
  input?: InputMaybe<GetSettingRequestInput>;
};


export type QueryRetailerSettingsServiceGetIsGrocerArgs = {
  input?: InputMaybe<GetIsGrocerRequestInput>;
};


export type QueryRetailerSettingsServiceGetParkingSettingsArgs = {
  input?: InputMaybe<GetParkingSettingsRequestInput>;
};


export type QueryRetailerSettingsServiceGetPickupSettingsMetadataArgs = {
  input?: InputMaybe<GetPickupSettingsMetadataRequestInput>;
};


export type QueryRetailerSettingsServiceGetStagingSettingsArgs = {
  input?: InputMaybe<GetStagingSettingsRequestInput>;
};


export type QueryRetailerStoreHoursGetConfirmedStoreHoursArgs = {
  input?: InputMaybe<GetConfirmedStoreHoursRequestInput>;
};


export type QueryRetailerStoreHoursGetRetailerHolidaysStatusArgs = {
  input?: InputMaybe<GetHolidaysStatusRequestInput>;
};


export type QueryRetailerStoreHoursGetStoreHoursArgs = {
  input?: InputMaybe<GetStoreHoursRequestInput>;
};


export type QueryRetailerStoreHoursGetStoreHoursUpdateTasksArgs = {
  input?: InputMaybe<GetStoreHoursUpdateTasksRequestInput>;
};


export type QueryRetailerToolsServiceGetCustomersContextArgs = {
  input?: InputMaybe<GetCustomersContextRequestInput>;
};


export type QueryRetailerToolsServiceGetItemsArgs = {
  input?: InputMaybe<GetItemsRequestInput>;
};


export type QueryRetailerToolsServiceGetRetailerProductsArgs = {
  input?: InputMaybe<GetRetailerProductsRequestInput>;
};


export type QueryRetailerToolsServiceGetVirtualRetailersArgs = {
  input?: InputMaybe<GetVirtualRetailersRequestInput>;
};


export type QueryRetailersServiceGetPlatformVersionsArgs = {
  input?: InputMaybe<GetPlatformVersionsRequestInput>;
};


export type QueryRetailersServiceGetRetailerLocationsByLocationCodesArgs = {
  input?: InputMaybe<GetRetailerLocationsByLocationCodesRequestInput>;
};


export type QueryRetailersServiceGetRetailerLocationsDetailsArgs = {
  input?: InputMaybe<GetRetailerLocationsDetailsRequestInput>;
};


export type QueryRetailersServiceGetRetailerRegionsArgs = {
  input?: InputMaybe<GetRetailerRegionsRequestInput>;
};


export type QueryRoleSearchArgs = {
  assignableByAccountUuid?: InputMaybe<Scalars['ID']>;
  attainableByAccountUuid?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  includeInstacartManaged?: InputMaybe<Scalars['Boolean']>;
  instacartManagedOnly?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryRppBulkImageUploadsServiceGetImageUploadBatchDetailsArgs = {
  input?: InputMaybe<GetImageUploadBatchDetailsRequestInput>;
};


export type QueryRppBulkImageUploadsServiceGetImageUploadBatchesArgs = {
  input?: InputMaybe<GetImageUploadBatchesRequestInput>;
};


export type QueryServiceOptionServiceGetServiceOptionsArgs = {
  input?: InputMaybe<GetServiceOptionsRequestInput>;
};


export type QueryStoreConfigurationByIdArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type QueryStoreConfigurationsByRetailerIdsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  retailerIds: Array<Scalars['ID']>;
  storeConfigRelationship: StoreConfigurationRelationshipEnum;
};


export type QueryStoreConfigurationsDraftServiceGetStoreConfigurationDraftsArgs = {
  input?: InputMaybe<GetStoreConfigurationDraftsRequestInput>;
};


export type QueryStoreConfigurationsDraftServiceReadLatestStoreConfigurationDraftArgs = {
  input?: InputMaybe<ReadLatestStoreConfigurationDraftRequestInput>;
};


export type QueryStoreConfigurationsDraftServiceReadStoreConfigurationArgs = {
  input?: InputMaybe<ReadStoreConfigurationRequestInput>;
};


export type QueryStorePerformanceServiceGetAggregatedStorePerformanceArgs = {
  input?: InputMaybe<StorePerformanceRequestInput>;
};


export type QueryStorefrontPlacementArgs = {
  id: Scalars['ID'];
  retailerId: Scalars['ID'];
};


export type QueryStorefrontPlacementSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PlacementFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
  sort?: InputMaybe<PlacementSortInput>;
};


export type QueryUserSettingServiceListUserSettingsArgs = {
  input?: InputMaybe<ListUserSettingsRequestInput>;
};


export type QueryUsersServiceBatchGetUsersByIdsStrictArgs = {
  input?: InputMaybe<BatchGetUsersByIdsStrictRequestInput>;
};


export type QueryUsersServiceGetUserByIdFilteredByIsolationContextArgs = {
  input?: InputMaybe<GetUserByIdFilteredByIsolationContextRequestInput>;
};


export type QueryUsersServiceListUsersByPersonalIdentifiersStrictArgs = {
  input?: InputMaybe<ListUsersByPersonalIdentifiersStrictRequestInput>;
};


export type QueryValueBasedOverrideServiceGetValueBasedOverridesArgs = {
  input?: InputMaybe<GetValueBasedOverridesRequestInput>;
};


export type QueryVisibilityConditionSearchArgs = {
  placementId?: InputMaybe<Scalars['ID']>;
  visibilityConditionId?: InputMaybe<Scalars['ID']>;
};


export type QueryWelcomeModalArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerLocationId?: InputMaybe<Scalars['ID']>;
};


export type QueryWizardProgressArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerLocationId: Scalars['ID'];
  wizardType: WizardTypeEnum;
};

export type RadiusSchema = {
  __typename?: 'RadiusSchema';
  r0?: Maybe<Scalars['BigInt']>;
  r4?: Maybe<Scalars['BigInt']>;
  r8?: Maybe<Scalars['BigInt']>;
  r12?: Maybe<Scalars['BigInt']>;
  r24?: Maybe<Scalars['BigInt']>;
};

export type ReadLatestStoreConfigurationDraftRequestInput = {
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type ReadLatestStoreConfigurationDraftResponse = {
  __typename?: 'ReadLatestStoreConfigurationDraftResponse';
  domainConfigurations?: Maybe<DomainConfiguration>;
  draftId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorReason?: Maybe<ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type ReadStoreConfigurationRequestInput = {
  draftId?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type ReadStoreConfigurationResponse = {
  __typename?: 'ReadStoreConfigurationResponse';
  domainConfigurations?: Maybe<DomainConfiguration>;
  draftId?: Maybe<Scalars['String']>;
  draftName?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorReason?: Maybe<ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
  parentDraftId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  storeConfigurationId?: Maybe<Scalars['BigInt']>;
};

export type ReassignAuditBatchWorkflowRequestInput = {
  batchUuid?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
  newWorkflow?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type ReassignAuditBatchWorkflowResponse = {
  __typename?: 'ReassignAuditBatchWorkflowResponse';
  completedAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['BigInt']>;
};

export type RecipeEntryItem = {
  __typename?: 'RecipeEntryItem';
  recipeId?: Maybe<Scalars['String']>;
};

export enum RecommendationTypeEnum {
  DemandSurge = 'DEMAND_SURGE',
  NoRunner = 'NO_RUNNER'
}

export type RedeemPartnershipOffer = {
  __typename?: 'RedeemPartnershipOffer';
  partnershipBenefitName?: Maybe<Scalars['String']>;
  partnershipOfferName?: Maybe<Scalars['String']>;
};

export type RedeemPartnershipOfferInput = {
  partnershipBenefitName?: InputMaybe<Scalars['String']>;
  partnershipOfferName?: InputMaybe<Scalars['String']>;
};

export type Region = {
  __typename?: 'Region';
  id?: Maybe<Scalars['BigInt']>;
  locationCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type RegularHours = {
  __typename?: 'RegularHours';
  dayOfWeek?: Maybe<GoogleTypeDayOfWeek>;
  endTime?: Maybe<GoogleTypeTimeOfDay>;
  serviceType?: Maybe<RetailerServiceType>;
  startTime?: Maybe<GoogleTypeTimeOfDay>;
};

export type RegularHoursInput = {
  dayOfWeek?: InputMaybe<GoogleTypeDayOfWeek>;
  endTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
  serviceType?: InputMaybe<RetailerServiceType>;
  startTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
};

export enum RejectionReasonEnum {
  /** Whether the authorization check failed because of lack of all partners full access */
  NoAllPartnersFullAccess = 'no_all_partners_full_access',
  /** Whether the authorization check failed because of lack of full partner access */
  NoFullPartnerAccess = 'no_full_partner_access',
  /** Whether the authorization check failed because of lack of full retailer access */
  NoFullRetailerAccess = 'no_full_retailer_access',
  /** Whether the authorization check failed because of lack of write access */
  NoWriteAccess = 'no_write_access',
  /** Whether the authorization check failed because of inaccessible approval requests */
  UnauthorizedApprovalRequests = 'unauthorized_approval_requests',
  /** Whether the authorization check failed because of unauthorized partners */
  UnauthorizedPartners = 'unauthorized_partners',
  /** Whether the authorization check failed because of unauthorized retailer locations */
  UnauthorizedRetailerLocations = 'unauthorized_retailer_locations',
  /** Whether the authorization check failed because of unauthorized retailers */
  UnauthorizedRetailers = 'unauthorized_retailers',
  /** Whether the authorization check failed because of unauthorized access to store configuration */
  UnauthorizedStoreConfigs = 'unauthorized_store_configs',
  /** Whether the authorization check failed because missing entitlements */
  UnmetFeatureEntitlements = 'unmet_feature_entitlements',
  /** Whether the authorization check failed because of unmet feature toggles */
  UnmetFeatureToggles = 'unmet_feature_toggles',
  /** Whether the authorization check failed because of unmet permissions */
  UnmetPermissions = 'unmet_permissions'
}

export type RenewSubscriptionIntoAnnual = {
  __typename?: 'RenewSubscriptionIntoAnnual';
  id?: Maybe<Scalars['BigInt']>;
};

export type RenewSubscriptionIntoAnnualInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type RenewSubscriptionIntoMonthly = {
  __typename?: 'RenewSubscriptionIntoMonthly';
  id?: Maybe<Scalars['BigInt']>;
};

export type RenewSubscriptionIntoMonthlyInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type ReopenLastmileOrderRequestInput = {
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  order?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ReopenLastmileOrderParamsInput>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type ReopenLastmileOrderResponse = {
  __typename?: 'ReopenLastmileOrderResponse';
  order?: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

/** A report filter definition */
export type ReportFilterDefinitionsInterface = {
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Whether this filter is required */
  required: Scalars['Boolean'];
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export enum ReportFilterTypeEnum {
  /** A filter that allows selection of a date range. */
  DateRange = 'DATE_RANGE',
  /** A filter that allows selection of a list of retailer locations. */
  RetailerLocations = 'RETAILER_LOCATIONS'
}

/** Report filter values */
export type ReportFilterValuesInterface = {
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Identifier for this filter */
  slug: Scalars['String'];
};

/** Report Filters */
export type ReportFilters = {
  dateRange?: InputMaybe<DateRange>;
  retailerLocations?: InputMaybe<RetailerLocations>;
};

export enum ReportRequestAssociatedObjectTypeEnum {
  /** Retailer type of associated object */
  Retailer = 'Retailer'
}

/** Autogenerated return type of ReportRequestCreate */
export type ReportRequestCreatePayload = {
  __typename?: 'ReportRequestCreatePayload';
  /** The newly created report request */
  reportRequest?: Maybe<ReportsReportRequest>;
};

export enum ReportRequestStateEnum {
  /** A report request that has been successfully processed */
  Complete = 'COMPLETE',
  /** A report request that has failed processing */
  Failed = 'FAILED',
  /** A report request that is currently being processed */
  InProgress = 'IN_PROGRESS',
  /** A report request that is new and not yet processed */
  New = 'NEW'
}

export enum ReportTypeEnum {
  /** Ads report daily */
  AdsDaily = 'ADS_DAILY',
  /** Ads report monthly */
  AdsMonthly = 'ADS_MONTHLY',
  /** Ads report monthly advertiser level */
  AdsMonthlyAdvertiserLevel = 'ADS_MONTHLY_ADVERTISER_LEVEL',
  /** Ads report weekly */
  AdsWeekly = 'ADS_WEEKLY',
  /** Aldi ACI Daily Report */
  AldiAciDailyReport = 'ALDI_ACI_DAILY_REPORT',
  /** Aldi ACI Daily Store Level Report */
  AldiAciDailyStoreLevelReport = 'ALDI_ACI_DAILY_STORE_LEVEL_REPORT',
  /** Associate level performance */
  AssociateLevelPartnerPerformanceReport = 'ASSOCIATE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  /** Bypass Order Feedback Outstanding Files Report */
  BypassOrdersFeedbackOutstandingFilesReport = 'BYPASS_ORDERS_FEEDBACK_OUTSTANDING_FILES_REPORT',
  /** Caper Daily Transactions Details */
  CaperDailyTransactionsDetails = 'CAPER_DAILY_TRANSACTIONS_DETAILS',
  /** Caper Daily Transactions Summary */
  CaperDailyTransactionsSummary = 'CAPER_DAILY_TRANSACTIONS_SUMMARY',
  /** Caper Incentives Report */
  CaperIncentivesReport = 'CAPER_INCENTIVES_REPORT',
  /** Daily Ultrafast Dashboard */
  DailyUltrafastDashboard = 'DAILY_ULTRAFAST_DASHBOARD',
  /** Daily Ultrafast Order Reporting */
  DailyUltrafastOrderReporting = 'DAILY_ULTRAFAST_ORDER_REPORTING',
  /** Delivery Now By Marianos - Daily Adjustment Report */
  DeliveryNowByMarianosDailyAdjustmentReport = 'DELIVERY_NOW_BY_MARIANOS_DAILY_ADJUSTMENT_REPORT',
  /** Ads display preview report daily */
  DisplayPreviewDaily = 'DISPLAY_PREVIEW_DAILY',
  /** Distribution System Report */
  DistributionSystemReport = 'DISTRIBUTION_SYSTEM_REPORT',
  /** Monthly DLOG report */
  DlogMonthly = 'DLOG_MONTHLY',
  /** Giant Eagle Loyalty Perks Attribution Ad-Hoc Report */
  GiantEagleLoyaltyPerksAttributionAdHocReport = 'GIANT_EAGLE_LOYALTY_PERKS_ATTRIBUTION_AD_HOC_REPORT',
  /** Live Issue */
  LiveIssue = 'LIVE_ISSUE',
  /** Merged: Retailer Tax Logs */
  MergedRetailerTaxLogs = 'MERGED_RETAILER_TAX_LOGS',
  /** Merged: Shopper Reimbursements Monthly */
  MergedShopperReimbursementsMonthly = 'MERGED_SHOPPER_REIMBURSEMENTS_MONTHLY',
  /** Merged: Taxes Paid at POS */
  MergedTaxesPaidAtPos = 'MERGED_TAXES_PAID_AT_POS',
  /** Monthly MLOG report */
  MlogMonthly = 'MLOG_MONTHLY',
  /** MPF Taxes Semi Monthly */
  MpfTaxesSemiMonthly = 'MPF_TAXES_SEMI_MONTHLY',
  /** New Verticals - Weekly Retailer Update */
  NewVerticalsWeeklyRetailerUpdate = 'NEW_VERTICALS_WEEKLY_RETAILER_UPDATE',
  /** Order ratings */
  OrderRatings = 'ORDER_RATINGS',
  /** Out of stock items */
  OutOfStock = 'OUT_OF_STOCK',
  /** Replacements and refunds */
  ReplacementsAndRefunds = 'REPLACEMENTS_AND_REFUNDS',
  /** Retailer Pickup */
  RetailerPickup = 'RETAILER_PICKUP',
  /** Retailer Tax Logs */
  RetailerTaxLogs = 'RETAILER_TAX_LOGS',
  /** Live Issue */
  RunnerHours = 'RUNNER_HOURS',
  /** Scan & Pay */
  ScanAndPay = 'SCAN_AND_PAY',
  /** Shopper Breaks */
  ShopperBreaks = 'SHOPPER_BREAKS',
  /** Shopper Reimbursements Monthly */
  ShopperReimbursementsMonthly = 'SHOPPER_REIMBURSEMENTS_MONTHLY',
  /** Store in Store Report Aggregate */
  StoreInStorePerformanceAgg = 'STORE_IN_STORE_PERFORMANCE_AGG',
  /** Store in Store Report Detail */
  StoreInStorePerformanceDtl = 'STORE_IN_STORE_PERFORMANCE_DTL',
  /** Store level performance */
  StoreLevelPartnerPerformanceReport = 'STORE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  /** Taxes Paid at POS */
  TaxesPaidAtPos = 'TAXES_PAID_AT_POS',
  /** Top items */
  TopItems = 'TOP_ITEMS',
  /** Live Issue */
  UnataExpressWeekly = 'UNATA_EXPRESS_WEEKLY',
  /** UUID Compliance */
  UuidCompliance = 'UUID_COMPLIANCE',
  /** General weekly updates */
  WeeklyRetailerUpdate = 'WEEKLY_RETAILER_UPDATE'
}

/** A generated view of the report data */
export type ReportViewsInterface = {
  /** A unique identifier for the report view */
  id: Scalars['ID'];
};

export type ReportsReport = {
  __typename?: 'ReportsReport';
  /** The time at which the report was created */
  createdAt: Scalars['Time'];
  /** A unique identifier for the each report */
  id: Scalars['ID'];
  /** A url-friendly ID for this report */
  slug: Scalars['String'];
  supportedFilters: Array<ReportFilterDefinitionsInterface>;
  /** The time at which the report was updated */
  updatedAt: Scalars['Time'];
};

export type ReportsReportFilterDefinitionsDateRange = ReportFilterDefinitionsInterface & {
  __typename?: 'ReportsReportFilterDefinitionsDateRange';
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Maximum date allowed */
  maxDate: Scalars['ISO8601Date'];
  /** Minimum date */
  minDate: Scalars['ISO8601Date'];
  /** Whether this filter is required */
  required: Scalars['Boolean'];
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export type ReportsReportFilterDefinitionsRetailerLocations = ReportFilterDefinitionsInterface & {
  __typename?: 'ReportsReportFilterDefinitionsRetailerLocations';
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Whether this filter is required */
  required: Scalars['Boolean'];
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export type ReportsReportFilterValuesDateRange = ReportFilterValuesInterface & {
  __typename?: 'ReportsReportFilterValuesDateRange';
  /** End date of date range */
  endDate: Scalars['ISO8601Date'];
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Identifier for this filter */
  slug: Scalars['String'];
  /** Start date of date range */
  startDate: Scalars['ISO8601Date'];
};

export type ReportsReportFilterValuesRetailerLocations = ReportFilterValuesInterface & {
  __typename?: 'ReportsReportFilterValuesRetailerLocations';
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** A retailer location group ID */
  retailerLocationGroupId?: Maybe<Scalars['ID']>;
  /** A list of retailer location IDs */
  retailerLocationIds?: Maybe<Array<Scalars['ID']>>;
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export type ReportsReportRequest = {
  __typename?: 'ReportsReportRequest';
  /** The time at which the report request was created */
  createdAt: Scalars['Time'];
  enterpriseAccount?: Maybe<EnterpriseAccount>;
  /** The state the report request is currently in */
  generationState: ReportRequestStateEnum;
  /** A unique identifier for the report request */
  id: Scalars['ID'];
  /** The name of the report request */
  name: Scalars['String'];
  report: ReportsReport;
  reportFilters: Array<ReportFilterValuesInterface>;
  reportViews: Array<ReportViewsInterface>;
  /** The time at which the report request was updated */
  updatedAt: Scalars['Time'];
};

/** The connection type for ReportsReportRequest. */
export type ReportsReportRequestConnection = {
  __typename?: 'ReportsReportRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReportsReportRequestEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReportsReportRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReportsReportRequestEdge = {
  __typename?: 'ReportsReportRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ReportsReportRequest>;
};

export type ReportsReportViewsCsv = ReportViewsInterface & {
  __typename?: 'ReportsReportViewsCsv';
  /** download URL for the report view */
  downloadUrl?: Maybe<Scalars['String']>;
  /** A unique identifier for the report view */
  id: Scalars['ID'];
};

export enum RequestStateEnum {
  /** Account Request accepted and completed */
  Completed = 'COMPLETED',
  /** Waiting for admin to confirm request */
  PendingAdminAction = 'PENDING_ADMIN_ACTION',
  /** Waiting for requestee to verify email */
  PendingEmailVerification = 'PENDING_EMAIL_VERIFICATION',
  /** Account Request rejected */
  Rejected = 'REJECTED'
}

export enum RequestType {
  RequestTypeAccess = 'REQUEST_TYPE_ACCESS',
  RequestTypeDeletion = 'REQUEST_TYPE_DELETION',
  RequestTypeUnspecified = 'REQUEST_TYPE_UNSPECIFIED'
}

export type RescheduleOrderRequestInput = {
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  order?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1RescheduleOrderParamsInput>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type RescheduleOrderResponse = {
  __typename?: 'RescheduleOrderResponse';
  order?: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

export type ResourceDataQuery = {
  __typename?: 'ResourceDataQuery';
  id?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
};

export type ResourceDataQueryInput = {
  id?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
};

export enum ResponseStatus {
  Failed = 'Failed',
  Success = 'Success',
  Unknown = 'Unknown'
}

export type Retailer = {
  __typename?: 'Retailer';
  /** Country associated with this retailer */
  country: Country;
  /** An array of the retailer's departments */
  departments: Array<CatalogDepartment>;
  /** The entitlements granted to the retailer */
  featureEntitlements: Array<FeatureEntitlement>;
  /** The feature toggles associated with the retailer */
  featureToggles: Array<FeatureToggle>;
  /** A unique identifier for the retailer */
  id: Scalars['ID'];
  /** The features across all locations for this retailer */
  locationFeatures: Array<LocationFeatureEnum>;
  /** A url for the logo */
  logo?: Maybe<Logo>;
  logoUrl?: Maybe<Scalars['String']>;
  /** The name of the retailer */
  name: Scalars['String'];
  /** The partner that this retailer belongs to */
  partner: Partner;
  partnerId?: Maybe<Scalars['BigInt']>;
  /** The store configuration for the pbi retailer */
  pbiConfiguration?: Maybe<ContentManagementPbiConfiguration>;
  /** The warehouse slug for a retailer */
  slug: Scalars['String'];
};

export type RetailerAvailabilityInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** The ID of the retailer */
  retailerId: Scalars['Int'];
};

/** Autogenerated return type of RetailerAvailabilityVisibilityConditionCreate */
export type RetailerAvailabilityVisibilityConditionCreatePayload = {
  __typename?: 'RetailerAvailabilityVisibilityConditionCreatePayload';
  /** The newly created RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityCondition: ContentManagementVisibilityConditionRetailerAvailability;
};

/** Autogenerated return type of RetailerAvailabilityVisibilityConditionUpdate */
export type RetailerAvailabilityVisibilityConditionUpdatePayload = {
  __typename?: 'RetailerAvailabilityVisibilityConditionUpdatePayload';
  /** The newly updated RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityCondition: ContentManagementVisibilityConditionRetailerAvailability;
};

export type RetailerCampaign = {
  __typename?: 'RetailerCampaign';
  budget?: Maybe<GoogleTypeMoney>;
  campaignObjective?: Maybe<CampaignObjective>;
  campaignReference?: Maybe<CampaignReference>;
  countryIdValue?: Maybe<Scalars['BigInt']>;
  couponCount?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorIppUserId?: Maybe<Scalars['BigInt']>;
  discountPolicyIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  discoverability?: Maybe<Discoverability>;
  endsAt?: Maybe<Scalars['DateTime']>;
  filters?: Maybe<Array<Maybe<CampaignFilter>>>;
  fundingDetails?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  ippUserCanonicalUuid?: Maybe<Scalars['String']>;
  lookbackWindowDays?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  narrowSearchTerms?: Maybe<Array<Maybe<Scalars['String']>>>;
  offerDurationInDays?: Maybe<Scalars['BigInt']>;
  offerRequestKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  offerSummary?: Maybe<Scalars['String']>;
  regionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerData?: Maybe<Retailer>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  rouletteFeature?: Maybe<CampaignRouletteFeature>;
  schedule?: Maybe<Scalars['String']>;
  segmentId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  storeConfigurationId?: Maybe<Scalars['BigInt']>;
  targeting?: Maybe<Array<Maybe<Targeting>>>;
  targetingRules?: Maybe<Array<Maybe<TargetingRules>>>;
  templateId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  treatmentTask?: Maybe<CampaignTreatmentTask>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validForDays?: Maybe<Scalars['BigInt']>;
};

export type RetailerCampaignCreativeTreatment = {
  __typename?: 'RetailerCampaignCreativeTreatment';
  placementId?: Maybe<Scalars['BigInt']>;
  slotId?: Maybe<Scalars['String']>;
};

export type RetailerCampaignCreativeTreatmentInput = {
  placementId?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['String']>;
};

export type RetailerCampaignOfferTreatment = {
  __typename?: 'RetailerCampaignOfferTreatment';
  offerDurationInDays?: Maybe<Scalars['BigInt']>;
  offerId?: Maybe<Scalars['BigInt']>;
  offerRequestKey?: Maybe<Scalars['String']>;
  slotId?: Maybe<Scalars['String']>;
  validForDays?: Maybe<Scalars['BigInt']>;
};

export type RetailerCampaignOfferTreatmentInput = {
  offerDurationInDays?: InputMaybe<Scalars['BigInt']>;
  offerId?: InputMaybe<Scalars['BigInt']>;
  offerRequestKey?: InputMaybe<Scalars['String']>;
  slotId?: InputMaybe<Scalars['String']>;
  validForDays?: InputMaybe<Scalars['BigInt']>;
};

export type RetailerCampaignResponse = {
  __typename?: 'RetailerCampaignResponse';
  errors?: Maybe<Scalars['JSONObject']>;
  offers?: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOffer>>>;
  retailerCampaign?: Maybe<RetailerCampaign>;
};

export type RetailerCampaignTreatment = {
  __typename?: 'RetailerCampaignTreatment';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  retailerCampaignId?: Maybe<Scalars['BigInt']>;
  slotIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  treatmentDetails?: Maybe<Array<Maybe<RetailerCampaignTreatmentDetails>>>;
  treatmentType?: Maybe<RetailerCampaignTreatmentType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RetailerCampaignTreatmentDetails = {
  __typename?: 'RetailerCampaignTreatmentDetails';
  creativeTreatment?: Maybe<RetailerCampaignCreativeTreatment>;
  offerTreatment?: Maybe<RetailerCampaignOfferTreatment>;
};

export type RetailerCampaignTreatmentDetailsInput = {
  creativeTreatment?: InputMaybe<RetailerCampaignCreativeTreatmentInput>;
  offerTreatment?: InputMaybe<RetailerCampaignOfferTreatmentInput>;
};

export enum RetailerCampaignTreatmentType {
  Creative = 'CREATIVE',
  InvalidTreatment = 'INVALID_TREATMENT',
  Offer = 'OFFER'
}

export type RetailerCollection = {
  __typename?: 'RetailerCollection';
  retailerId?: Maybe<Scalars['BigInt']>;
  slug?: Maybe<Scalars['String']>;
};

export type RetailerCollectionInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** The connection type for Retailer. */
export type RetailerConnection = {
  __typename?: 'RetailerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetailerEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Retailer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RetailerContainerPath = {
  __typename?: 'RetailerContainerPath';
  containerPath?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
};

export type RetailerContainerPathInput = {
  containerPath?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

/** An edge in a connection. */
export type RetailerEdge = {
  __typename?: 'RetailerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Retailer>;
};

export type RetailerLocale = {
  __typename?: 'RetailerLocale';
  localeCode?: Maybe<Scalars['String']>;
  localeId?: Maybe<Scalars['BigInt']>;
  retailerId?: Maybe<Scalars['BigInt']>;
};

export type RetailerLocation = {
  __typename?: 'RetailerLocation';
  /** The active status for the location */
  active: Scalars['Boolean'];
  /** The location's city */
  city?: Maybe<Scalars['String']>;
  /** Represents the set of 'Fullfillment Models' (how fulfillment is facilitated for the location) */
  fulfillmentModels?: Maybe<Array<FulfillmentModelEnum>>;
  /** Hybrid Picking setting for the location indicates combined ISS and Partner Pick */
  hybridPickingEnabled?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the retailer location */
  id: Scalars['ID'];
  /** The retailer-assigned location code */
  locationCode?: Maybe<Scalars['String']>;
  /** The name of the retailer location */
  name?: Maybe<Scalars['String']>;
  /** Identifies if the location does partner run alcohol delivery */
  partnerAlcoholDelivery: Scalars['Boolean'];
  /** Represents the 'Picking Model' (how picking is facilitated for the location) */
  pickingModel?: Maybe<PickingModelEnum>;
  /** A flag representing whether pickup is enabled */
  pickup: Scalars['Boolean'];
  /** Identifies if the location does platform only deliveries */
  platformOnlyEnabled?: Maybe<Scalars['Boolean']>;
  /** The retailer that this location belongs to */
  retailer: Retailer;
  /** The location's state */
  state?: Maybe<Scalars['String']>;
  /** The location's street (including street #) */
  street?: Maybe<Scalars['String']>;
  /** The location's zip code or postal code */
  zipCode?: Maybe<Scalars['String']>;
  /** The location's ZIP+4 (US-specific) */
  zipPlusFour?: Maybe<Scalars['String']>;
};

export type RetailerLocationAisle = {
  __typename?: 'RetailerLocationAisle';
  aisleCount?: Maybe<Scalars['BigInt']>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  retailerLocationName?: Maybe<Scalars['String']>;
};

/** The connection type for RetailerLocation. */
export type RetailerLocationConnection = {
  __typename?: 'RetailerLocationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetailerLocationEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RetailerLocation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RetailerLocationDetails = {
  __typename?: 'RetailerLocationDetails';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<GoogleTypeLatLng>;
  countryId?: Maybe<Scalars['BigInt']>;
  flags?: Maybe<RetailerLocationFlags>;
  id?: Maybe<Scalars['Int']>;
  inventoryAreaId?: Maybe<Scalars['Int']>;
  locationCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type RetailerLocationEdge = {
  __typename?: 'RetailerLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RetailerLocation>;
};

export type RetailerLocationFlags = {
  __typename?: 'RetailerLocationFlags';
  active?: Maybe<Scalars['Boolean']>;
  alcohol?: Maybe<Scalars['Boolean']>;
  available?: Maybe<Scalars['Boolean']>;
  closed?: Maybe<Scalars['Boolean']>;
  curbsidePickup?: Maybe<Scalars['Boolean']>;
  pickup?: Maybe<Scalars['Boolean']>;
  pickupOnly?: Maybe<Scalars['Boolean']>;
  rx?: Maybe<Scalars['Boolean']>;
  serviceable?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RetailerLocationGroupCreate */
export type RetailerLocationGroupCreatePayload = {
  __typename?: 'RetailerLocationGroupCreatePayload';
  /** The newly created group */
  retailerLocationGroup: RetailersRetailerLocationGroup;
};

/** Autogenerated return type of RetailerLocationGroupDelete */
export type RetailerLocationGroupDeletePayload = {
  __typename?: 'RetailerLocationGroupDeletePayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of RetailerLocationGroupUpdate */
export type RetailerLocationGroupUpdatePayload = {
  __typename?: 'RetailerLocationGroupUpdatePayload';
  /** The updated group */
  retailerLocationGroup: RetailersRetailerLocationGroup;
};

export type RetailerLocationInfo = {
  __typename?: 'RetailerLocationInfo';
  name?: Maybe<Scalars['String']>;
  retailerName?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** Attributes for a retailer location filter */
export type RetailerLocations = {
  /** A retailer location group ID */
  retailerLocationGroupId?: InputMaybe<Scalars['ID']>;
  /** A list of retailer location IDs */
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RetailerMfaConfig = {
  __typename?: 'RetailerMfaConfig';
  /** Whether or not MFA is enabled for the retailer */
  mfaEnabled: Scalars['Boolean'];
  /** A unique identifier for the retailer */
  retailerId: Scalars['ID'];
};

/** Autogenerated return type of RetailerMfaSetEnabled */
export type RetailerMfaSetEnabledPayload = {
  __typename?: 'RetailerMfaSetEnabledPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

export type RetailerMomentCampaign = {
  __typename?: 'RetailerMomentCampaign';
  collectionData?: Maybe<Collection>;
  offers?: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOffer>>>;
  retailerCampaign?: Maybe<RetailerCampaign>;
  retailerData?: Maybe<Retailer>;
};

export type RetailerOverride = {
  __typename?: 'RetailerOverride';
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerName?: Maybe<Scalars['String']>;
};

export type RetailerProduct = {
  __typename?: 'RetailerProduct';
  aisleName?: Maybe<Scalars['String']>;
  aisles?: Maybe<Array<Maybe<RetailerProductAisle>>>;
  alcoholic?: Maybe<Scalars['Boolean']>;
  atkinsDiet?: Maybe<Scalars['Boolean']>;
  bottle?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['BigInt']>;
  brandName?: Maybe<Scalars['String']>;
  calories?: Maybe<Scalars['Float']>;
  carbFree?: Maybe<Scalars['Boolean']>;
  carbohydrate?: Maybe<Scalars['Float']>;
  cchGroupCode?: Maybe<Scalars['String']>;
  cchItemCode?: Maybe<Scalars['String']>;
  coldPrepared?: Maybe<Scalars['Boolean']>;
  containsFlour?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dairyFree?: Maybe<Scalars['Boolean']>;
  departments?: Maybe<Array<Maybe<RetailerProductDepartment>>>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  fatFree?: Maybe<Scalars['Boolean']>;
  glutenFree?: Maybe<Scalars['Boolean']>;
  hasImage?: Maybe<Scalars['Boolean']>;
  highCarb?: Maybe<Scalars['Boolean']>;
  highFat?: Maybe<Scalars['Boolean']>;
  highProtein?: Maybe<Scalars['Boolean']>;
  imageList?: Maybe<Array<Maybe<RetailerProductImageInfo>>>;
  ingredients?: Maybe<Scalars['String']>;
  isOrganic?: Maybe<Scalars['Boolean']>;
  ketoDiet?: Maybe<Scalars['Boolean']>;
  kidFriendly?: Maybe<Scalars['Boolean']>;
  kosher?: Maybe<Scalars['Boolean']>;
  lactoseFree?: Maybe<Scalars['Boolean']>;
  localeCode?: Maybe<Scalars['String']>;
  lowCarb?: Maybe<Scalars['Boolean']>;
  lowFat?: Maybe<Scalars['Boolean']>;
  lowSodium?: Maybe<Scalars['Boolean']>;
  lowSugar?: Maybe<Scalars['Boolean']>;
  managedAttributeValues?: Maybe<Array<Maybe<RetailerProductManagedAttributeValue>>>;
  name?: Maybe<Scalars['String']>;
  organic?: Maybe<Scalars['Boolean']>;
  originalName?: Maybe<Scalars['String']>;
  paleoDiet?: Maybe<Scalars['Boolean']>;
  prepSlicedRepackagedPasteurizedByRetailer?: Maybe<Scalars['Boolean']>;
  primaryAisleId?: Maybe<Scalars['BigInt']>;
  primaryDepartmentId?: Maybe<Scalars['BigInt']>;
  primaryImageUrl?: Maybe<Scalars['String']>;
  productCategory?: Maybe<Scalars['String']>;
  productCategoryTaxonomy?: Maybe<RetailerProductProductCategoryTaxonomy>;
  productCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  productDisplayName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['BigInt']>;
  productTags?: Maybe<Scalars['String']>;
  prop65Text?: Maybe<Scalars['String']>;
  requireReheating?: Maybe<Scalars['Boolean']>;
  requiresKeepFrozen?: Maybe<Scalars['Boolean']>;
  requiresRefrigeration?: Maybe<Scalars['Boolean']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerReferenceCode?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  seasonality?: Maybe<Scalars['JSONObject']>;
  servingSize?: Maybe<Scalars['String']>;
  servingsPerContainer?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  sizeUom?: Maybe<Scalars['String']>;
  storeBrand?: Maybe<Scalars['Boolean']>;
  sugarFree?: Maybe<Scalars['Boolean']>;
  suggestedIncrementalWeight?: Maybe<Scalars['Float']>;
  unitCount?: Maybe<Scalars['BigInt']>;
  vegan?: Maybe<Scalars['Boolean']>;
  vegetarian?: Maybe<Scalars['Boolean']>;
  vertexTaxOverrideAttribute?: Maybe<Scalars['String']>;
  weightWatchers?: Maybe<Scalars['Boolean']>;
  wholeGrain?: Maybe<Scalars['Boolean']>;
};

export type RetailerProductAisle = {
  __typename?: 'RetailerProductAisle';
  departmentId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailerProductAssortment = {
  __typename?: 'RetailerProductAssortment';
  entityId?: Maybe<EntityId>;
  inAssortment?: Maybe<Scalars['Boolean']>;
  outOfAssortmentEndAt?: Maybe<Scalars['DateTime']>;
  outOfAssortmentStartAt?: Maybe<Scalars['DateTime']>;
};

export type RetailerProductAssortmentFilterInput = {
  inAssortment?: InputMaybe<Scalars['Boolean']>;
};

export type RetailerProductData = {
  __typename?: 'RetailerProductData';
  availableItemsCount?: Maybe<Scalars['BigInt']>;
  generatedAt?: Maybe<Scalars['DateTime']>;
  itemsCount?: Maybe<Scalars['BigInt']>;
  maxPriceCents?: Maybe<Scalars['BigInt']>;
  minPriceCents?: Maybe<Scalars['BigInt']>;
  productId?: Maybe<Scalars['BigInt']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  visibleItemsCount?: Maybe<Scalars['BigInt']>;
};

export type RetailerProductDepartment = {
  __typename?: 'RetailerProductDepartment';
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};

export type RetailerProductId = {
  __typename?: 'RetailerProductId';
  locale?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['BigInt']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerProductId?: Maybe<Scalars['BigInt']>;
};

export type RetailerProductIdInput = {
  locale?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerProductId?: InputMaybe<Scalars['BigInt']>;
};

export type RetailerProductImageInfo = {
  __typename?: 'RetailerProductImageInfo';
  imageId?: Maybe<Scalars['String']>;
  imageType?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
};

export type RetailerProductManagedAttributeValue = {
  __typename?: 'RetailerProductManagedAttributeValue';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueDisplayName?: Maybe<Scalars['String']>;
};

export type RetailerProductProductCategoryTaxonomy = {
  __typename?: 'RetailerProductProductCategoryTaxonomy';
  l1?: Maybe<Scalars['String']>;
  l2?: Maybe<Scalars['String']>;
  l3?: Maybe<Scalars['String']>;
  l4?: Maybe<Scalars['String']>;
  l5?: Maybe<Scalars['String']>;
  l6?: Maybe<Scalars['String']>;
};

export type RetailerSatisfactionSurveyResponse = {
  /** Text that was displayed to user */
  surveyQuestion: Scalars['String'];
  /** Can be either numeric or text based on survey type */
  surveyResponse: Scalars['String'];
};

export enum RetailerServiceType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
  ServiceTypeUndefined = 'SERVICE_TYPE_UNDEFINED'
}

export type RetailerSettingDetailRecord = {
  __typename?: 'RetailerSettingDetailRecord';
  detailName?: Maybe<Scalars['String']>;
  detailType?: Maybe<Scalars['String']>;
  detailValue?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  settingName?: Maybe<Scalars['String']>;
  validValues?: Maybe<Scalars['String']>;
};

export type RetailerSettingRecord = {
  __typename?: 'RetailerSettingRecord';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Array<Maybe<RetailerSettingDetailRecord>>>;
  retailerId?: Maybe<Scalars['BigInt']>;
  settingName?: Maybe<Scalars['String']>;
  settingType?: Maybe<RetailerSettingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['BigInt']>;
};

export enum RetailerSettingType {
  RetailerSettingTypeBoolean = 'RETAILER_SETTING_TYPE_BOOLEAN',
  RetailerSettingTypeInteger = 'RETAILER_SETTING_TYPE_INTEGER',
  RetailerSettingTypeString = 'RETAILER_SETTING_TYPE_STRING',
  RetailerSettingTypeUnknown = 'RETAILER_SETTING_TYPE_UNKNOWN'
}

export type RetailersPickupRetailerLocationGroup = {
  __typename?: 'RetailersPickupRetailerLocationGroup';
  /** A unique identifier for the location group */
  id: Scalars['ID'];
  /** A name for this location group */
  name: Scalars['String'];
  /** The identifier for the retailer which all locations in the group belong to */
  retailerId: Scalars['ID'];
  /** The retailer locations associated with the group */
  retailerLocations: Array<RetailerLocation>;
};

export type RetailersRetailerLocationGroup = {
  __typename?: 'RetailersRetailerLocationGroup';
  /** A unique identifier for the location group */
  id: Scalars['ID'];
  /** A name for this location group */
  name: Scalars['String'];
  /** The identifier for the partner which all locations in the group belong to */
  partnerId: Scalars['ID'];
  /** The identifier for the retailer which all locations in the group belong to */
  retailerId?: Maybe<Scalars['ID']>;
  /** The ids for the retailer locations associated with the group */
  retailerLocationIds: Array<Scalars['ID']>;
  /** The retailer locations associated with the group */
  retailerLocations: RetailerLocationConnection;
  /** The time at which the group was last updated */
  updatedAt: Scalars['Time'];
};


export type RetailersRetailerLocationGroupRetailerLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The connection type for RetailersRetailerLocationGroup. */
export type RetailersRetailerLocationGroupConnection = {
  __typename?: 'RetailersRetailerLocationGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetailersRetailerLocationGroupEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RetailersRetailerLocationGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RetailersRetailerLocationGroupEdge = {
  __typename?: 'RetailersRetailerLocationGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RetailersRetailerLocationGroup>;
};

export type RetailersRetailerPickupMetadata = {
  __typename?: 'RetailersRetailerPickupMetadata';
  /** Warehouse early picking hour [if one is set] */
  earlyPickingHour?: Maybe<Scalars['Int']>;
};

export type ReturnItemInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
  returnedQuantity?: InputMaybe<InstacartTypesV1MeasuredQuantityInput>;
};

/** The possible actions performed during a review on an Approval Request. */
export enum ReviewAction {
  /** A approval for the update to the content. */
  Approval = 'APPROVAL',
  /** An approval and publication of the content in one atomic step. */
  ApprovalAndPublish = 'APPROVAL_AND_PUBLISH',
  /** A publication of the content without the required approvals. */
  BreakGlassPublish = 'BREAK_GLASS_PUBLISH',
  /** A cancellation of the content update. */
  Cancel = 'CANCEL',
  /** A comment on the Approval Request. No state updates. */
  Comment = 'COMMENT',
  /** A publication for the content. */
  Publish = 'PUBLISH',
  /** A request for changes to the content update. */
  RequestChanges = 'REQUEST_CHANGES',
  /** A revision of the content (invalidates approvals). */
  ReviseContent = 'REVISE_CONTENT'
}

/** Input parameters for adding a review to an Approval Request */
export type ReviewAddInput = {
  /** The action to take on the approval request. */
  action: ReviewAction;
  /** The unique ID of the Approval Request. */
  arId: Scalars['ID'];
  /** A comment to add to the approval request. */
  userComment?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ReviewAdd */
export type ReviewAddPayload = {
  __typename?: 'ReviewAddPayload';
  approvalRequest: ApprovalsManagementTypesApprovalRequest;
};

/** Input parameters for deleting a review on an Approval Request */
export type ReviewDeleteInput = {
  /** The unique ID of the Review. */
  reviewId: Scalars['ID'];
};

/** Autogenerated return type of ReviewDelete */
export type ReviewDeletePayload = {
  __typename?: 'ReviewDeletePayload';
  approvalRequest: ApprovalsManagementTypesApprovalRequest;
};

/** Input parameters for editing a review to an Approval Request */
export type ReviewEditInput = {
  /** The unique ID of the Review. */
  reviewId: Scalars['ID'];
  /** Update the comment on the approval request. */
  userComment?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ReviewEdit */
export type ReviewEditPayload = {
  __typename?: 'ReviewEditPayload';
  approvalRequest: ApprovalsManagementTypesApprovalRequest;
};

export type RevokePartnerApiKeyRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type RevokePartnerApiKeyResponse = {
  __typename?: 'RevokePartnerApiKeyResponse';
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RoleCreate */
export type RoleCreatePayload = {
  __typename?: 'RoleCreatePayload';
  /** The created role object */
  role: EnterpriseRole;
};

/** Autogenerated return type of RoleDelete */
export type RoleDeletePayload = {
  __typename?: 'RoleDeletePayload';
  /** The delete role object */
  role: EnterpriseRole;
};

export type RoleInput = {
  /** A unique identifier for the role */
  id: Scalars['ID'];
};

/** Autogenerated return type of RoleUpdate */
export type RoleUpdatePayload = {
  __typename?: 'RoleUpdatePayload';
  /** The update role object */
  role: EnterpriseRole;
};

export type RollbackStoreConfigurationRequestInput = {
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type RollbackStoreConfigurationResponse = {
  __typename?: 'RollbackStoreConfigurationResponse';
  errorMessage?: Maybe<Scalars['String']>;
  errorReason?: Maybe<ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type SearchBrandsRequestInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  storeBrandsOnly?: InputMaybe<Scalars['Boolean']>;
};

export type SearchBrandsResponse = {
  __typename?: 'SearchBrandsResponse';
  brands?: Maybe<Array<Maybe<Brand>>>;
};

export type SecondaryBanner = {
  __typename?: 'SecondaryBanner';
  backgroundColorHex?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaBackgroundColorHex?: Maybe<Scalars['String']>;
  ctaColorHex?: Maybe<Scalars['String']>;
  desktopImageUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  locale?: Maybe<Scalars['String']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  secondaryText?: Maybe<Scalars['String']>;
  secondaryTextColorHex?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type SecondaryBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  locale?: InputMaybe<Scalars['String']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  secondaryText?: InputMaybe<Scalars['String']>;
  secondaryTextColorHex?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

/** Autogenerated return type of SecondaryBannerPlacementCreate */
export type SecondaryBannerPlacementCreatePayload = {
  __typename?: 'SecondaryBannerPlacementCreatePayload';
  /** The newly created secondary_banner placement */
  secondaryBannerPlacement: ContentManagementSecondaryBannerPlacement;
};

export type SecondaryBannerPlacementInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  destination?: InputMaybe<PlacementDestinationInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Information of Experiment associated with the placement */
  experiment?: InputMaybe<PlacementExperimentInput>;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** The images on the banner */
  images: GenericImagesInput;
  /** The retailers that this placement should be displayed on */
  includedRetailerIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The layout the placement should appear on */
  layout?: InputMaybe<Scalars['String']>;
  /** Locale */
  locale?: InputMaybe<BannerLocaleEnum>;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank?: InputMaybe<Scalars['Int']>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The style and text of the secondary text */
  secondaryText?: InputMaybe<ConfigurableTextInput>;
  /** The layout the placement should appear on */
  stringLayout?: InputMaybe<Scalars['String']>;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** The variant of the banner */
  variant?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SecondaryBannerPlacementUpdate */
export type SecondaryBannerPlacementUpdatePayload = {
  __typename?: 'SecondaryBannerPlacementUpdatePayload';
  /** The updated secondary_banner placement */
  secondaryBannerPlacement: ContentManagementSecondaryBannerPlacement;
};

export type SellSheetAnalyticsSummary = {
  __typename?: 'SellSheetAnalyticsSummary';
  sellSheetId?: Maybe<Scalars['String']>;
  totalOrders?: Maybe<Scalars['BigInt']>;
  totalSalesUsd?: Maybe<Scalars['Float']>;
};

export type SellSheetAnalyticsSummaryGrowthMetrics = {
  __typename?: 'SellSheetAnalyticsSummaryGrowthMetrics';
  sellSheetId?: Maybe<Scalars['String']>;
  totalOrdersGrowth?: Maybe<Scalars['Float']>;
  totalSalesUsdGrowth?: Maybe<Scalars['Float']>;
};

export type SellSheetAnalyticsSummaryRequestInput = {
  dateRange?: InputMaybe<InstacartAdsSharedV1DateRangeInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetAnalyticsSummaryResponse = {
  __typename?: 'SellSheetAnalyticsSummaryResponse';
  analyticsSummary?: Maybe<SellSheetAnalyticsSummary>;
  growthMetrics?: Maybe<SellSheetAnalyticsSummaryGrowthMetrics>;
  previousPeriodMetrics?: Maybe<SellSheetAnalyticsSummary>;
};

export type SellSheetAttributes = {
  __typename?: 'SellSheetAttributes';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
};

export type SellSheetAttributesRequestInput = {
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetAttributesResponse = {
  __typename?: 'SellSheetAttributesResponse';
  legalFilterType?: Maybe<LegalFilterType>;
  sellSheetAttributes?: Maybe<SellSheetAttributes>;
};

export type SellSheetBrandProfileAttributesRequestInput = {
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetBrandProfileAttributesResponse = {
  __typename?: 'SellSheetBrandProfileAttributesResponse';
  brandProfileAttributes?: Maybe<BrandProfileAttributes>;
};

export type SellSheetCard = {
  __typename?: 'SellSheetCard';
  brandImages?: Maybe<Array<Maybe<ImageAsset>>>;
  brandName?: Maybe<Scalars['String']>;
  brandProfileId?: Maybe<Scalars['String']>;
  productDisplays?: Maybe<Array<Maybe<ProductDisplay>>>;
  productImages?: Maybe<Array<Maybe<ImageAsset>>>;
  sellSheetAnalyticsGrowthMetrics?: Maybe<SellSheetAnalyticsSummaryGrowthMetrics>;
  sellSheetAnalyticsSummary?: Maybe<SellSheetAnalyticsSummary>;
  sellSheetAttributes?: Maybe<SellSheetAttributes>;
};

export type SellSheetCardsRequestInput = {
  brandProfileIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDebug?: InputMaybe<Scalars['Boolean']>;
  paginationRequest?: InputMaybe<InstacartAdsSharedV1PaginationRequestInput>;
  regionalAreas?: InputMaybe<Array<InputMaybe<InstacartAdsNexusV1RegionalArea>>>;
  retailerCarriedState?: InputMaybe<SellSheetRetailerCarriedState>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sortKeys?: InputMaybe<Array<InputMaybe<SellSheetSortKey>>>;
  superCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  superCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type SellSheetCardsResponse = {
  __typename?: 'SellSheetCardsResponse';
  paginationResponse?: Maybe<InstacartAdsSharedV1PaginationResponse>;
  sellSheetCards?: Maybe<Array<Maybe<SellSheetCard>>>;
};

export type SellSheetOverviewAttributes = {
  __typename?: 'SellSheetOverviewAttributes';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productCount?: Maybe<Scalars['BigInt']>;
};

export type SellSheetProductsAttributesRequestInput = {
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetProductsAttributesResponse = {
  __typename?: 'SellSheetProductsAttributesResponse';
  productsAttributes?: Maybe<Array<Maybe<ProductAttributes>>>;
};

export type SellSheetProductsSummaryTraitsRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetProductsSummaryTraitsResponse = {
  __typename?: 'SellSheetProductsSummaryTraitsResponse';
  productsSummaryTraits?: Maybe<Array<Maybe<ProductSummaryTraits>>>;
};

export enum SellSheetRetailerCarriedState {
  PartiallyCarried = 'PARTIALLY_CARRIED',
  RetailerCarriedUnkown = 'RETAILER_CARRIED_UNKOWN'
}

export enum SellSheetSortKey {
  BrandName = 'BRAND_NAME',
  CreatedAt = 'CREATED_AT',
  ProductOrders = 'PRODUCT_ORDERS',
  ProductOrdersGrowth = 'PRODUCT_ORDERS_GROWTH',
  SalesAmount = 'SALES_AMOUNT',
  SalesGrowth = 'SALES_GROWTH',
  SortKeyUnkwown = 'SORT_KEY_UNKWOWN',
  UnitsSold = 'UNITS_SOLD',
  UnitsSoldGrowth = 'UNITS_SOLD_GROWTH'
}

export enum Send {
  /** The campaign has an email template */
  Email = 'email',
  /** The campaign has a push notification template */
  Push = 'push'
}

/** Autogenerated return type of SetMobileProperties */
export type SetMobilePropertiesPayload = {
  __typename?: 'SetMobilePropertiesPayload';
  /** The full set of properties */
  mobileAppProperties: Array<OnboardingFeatureTypesProperty>;
};

export type SetUserSettingRequestInput = {
  client?: InputMaybe<ClientInput>;
  setting?: InputMaybe<UserSettingInput>;
};

export type SetUserSettingResponse = {
  __typename?: 'SetUserSettingResponse';
  _?: Maybe<Scalars['Boolean']>;
};

export enum ShiftTypeEnum {
  FullService = 'FULL_SERVICE',
  PickingOnly = 'PICKING_ONLY',
  Runner = 'RUNNER'
}

export type ShopSidebar = {
  __typename?: 'ShopSidebar';
  collapsed?: Maybe<Scalars['Boolean']>;
};

export type ShoppedAtRetailerInput = {
  /** Id of the country where the check is to be performed */
  countryId?: InputMaybe<Scalars['Int']>;
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** Number of days in the past to check */
  numberOfDays?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the Retailer */
  retailerId: Scalars['Int'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of ShoppedAtRetailerVisibilityConditionCreate */
export type ShoppedAtRetailerVisibilityConditionCreatePayload = {
  __typename?: 'ShoppedAtRetailerVisibilityConditionCreatePayload';
  /** The newly created shopped_at_retailer visibility condition */
  shoppedAtRetailerVisibilityCondition: ContentManagementVisibilityConditionShoppedAtRetailer;
};

/** Autogenerated return type of ShoppedAtRetailerVisibilityConditionUpdate */
export type ShoppedAtRetailerVisibilityConditionUpdatePayload = {
  __typename?: 'ShoppedAtRetailerVisibilityConditionUpdatePayload';
  /** The newly created shopped_at_retailer visibility condition */
  shoppedAtRetailerVisibilityCondition: ContentManagementVisibilityConditionShoppedAtRetailer;
};

export type ShoppedInput = {
  /** Id of the country where the check is to be performed */
  countryId?: InputMaybe<Scalars['Int']>;
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** Number of days in the past to check */
  numberOfDays?: InputMaybe<Scalars['Int']>;
  /** Number of times in the past to check */
  numberOfTimes?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of ShoppedVisibilityConditionCreate */
export type ShoppedVisibilityConditionCreatePayload = {
  __typename?: 'ShoppedVisibilityConditionCreatePayload';
  /** The newly created shopped visibility condition */
  shoppedVisibilityCondition: ContentManagementVisibilityConditionShopped;
};

/** Autogenerated return type of ShoppedVisibilityConditionUpdate */
export type ShoppedVisibilityConditionUpdatePayload = {
  __typename?: 'ShoppedVisibilityConditionUpdatePayload';
  /** The newly created shopped visibility condition */
  shoppedVisibilityCondition: ContentManagementVisibilityConditionShopped;
};

export type ShopperOrderItemScanInput = {
  scannedString?: InputMaybe<Scalars['String']>;
  scannedStringType?: InputMaybe<Scalars['String']>;
};

export type ShopperOrderProfile = {
  __typename?: 'ShopperOrderProfile';
  avatarUrl?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['BigInt']>;
  canChat?: Maybe<Scalars['Boolean']>;
  canReceiveTips?: Maybe<Scalars['Boolean']>;
  canTakeAlcoholOrders?: Maybe<Scalars['Boolean']>;
  deliverer?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  lastName?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['BigInt']>;
  partnerType?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  picker?: Maybe<Scalars['Boolean']>;
  primaryZoneId?: Maybe<Scalars['BigInt']>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  rolesMask?: Maybe<Scalars['Int']>;
  runner?: Maybe<Scalars['Boolean']>;
  shareCode?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export enum ShopperType {
  ShopperTypeFss = 'SHOPPER_TYPE_FSS',
  ShopperTypePartnerPick = 'SHOPPER_TYPE_PARTNER_PICK',
  ShopperTypeUnknown = 'SHOPPER_TYPE_UNKNOWN'
}

export type SignupPartner = {
  __typename?: 'SignupPartner';
  description?: Maybe<Scalars['String']>;
  hasExclusiveOption?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  skipCreditCheck?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
};

export type Slot = {
  __typename?: 'Slot';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  isStatic?: Maybe<Scalars['Boolean']>;
  legacySlotId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SlotCreative = {
  __typename?: 'SlotCreative';
  createdAt?: Maybe<Scalars['DateTime']>;
  creativeId?: Maybe<Scalars['BigInt']>;
  creativeRank?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  slotId?: Maybe<Scalars['BigInt']>;
  treatmentPolicyId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SlotCreativeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creativeId?: InputMaybe<Scalars['BigInt']>;
  creativeRank?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['BigInt']>;
  treatmentPolicyId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SlotInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  legacySlotId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SortingDetailsInput = {
  direction?: InputMaybe<SortingDirection>;
  fieldName?: InputMaybe<Scalars['String']>;
};

export enum SortingDirection {
  SortingDirectionAsc = 'SORTING_DIRECTION_ASC',
  SortingDirectionDesc = 'SORTING_DIRECTION_DESC',
  SortingDirectionUnknown = 'SORTING_DIRECTION_UNKNOWN'
}

export enum SourceLevel {
  SourceLevelMulti = 'SOURCE_LEVEL_MULTI',
  SourceLevelProduct = 'SOURCE_LEVEL_PRODUCT',
  SourceLevelRetailerProduct = 'SOURCE_LEVEL_RETAILER_PRODUCT',
  SourceLevelUnknown = 'SOURCE_LEVEL_UNKNOWN'
}

export type StageLastmileOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerProvidedAmounts?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmountsInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type StageLastmileOrderResponse = {
  __typename?: 'StageLastmileOrderResponse';
  order?: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

export type StandardInfoModalSheet = {
  __typename?: 'StandardInfoModalSheet';
  description?: Maybe<Scalars['String']>;
  desktopImageUrl?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  imageAltText?: Maybe<Scalars['String']>;
  linkCta?: Maybe<Scalars['String']>;
  linkCtaAction?: Maybe<BannerAction>;
  linkCtaButtonStyle?: Maybe<ButtonStyle>;
  locale?: Maybe<Scalars['String']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  primaryCta?: Maybe<Scalars['String']>;
  primaryCtaAction?: Maybe<BannerAction>;
  primaryCtaButtonStyle?: Maybe<ButtonStyle>;
  secondaryCta?: Maybe<Scalars['String']>;
  secondaryCtaAction?: Maybe<BannerAction>;
  secondaryCtaButtonStyle?: Maybe<ButtonStyle>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type StandardInfoModalSheetInput = {
  description?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  disclaimer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  linkCta?: InputMaybe<Scalars['String']>;
  linkCtaAction?: InputMaybe<BannerActionInput>;
  linkCtaButtonStyle?: InputMaybe<ButtonStyle>;
  locale?: InputMaybe<Scalars['String']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  primaryCta?: InputMaybe<Scalars['String']>;
  primaryCtaAction?: InputMaybe<BannerActionInput>;
  primaryCtaButtonStyle?: InputMaybe<ButtonStyle>;
  secondaryCta?: InputMaybe<Scalars['String']>;
  secondaryCtaAction?: InputMaybe<BannerActionInput>;
  secondaryCtaButtonStyle?: InputMaybe<ButtonStyle>;
  title?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type StartCampaignInput = {
  /** The id of the campaign returned from the campaign domain */
  campaignId: Scalars['ID'];
  /** The user friendly name of the campaign returned from the campaign domain */
  campaignName?: InputMaybe<Scalars['String']>;
  /** Whether or not to create a split experiment */
  includeExperiment?: InputMaybe<Scalars['Boolean']>;
  /** The external id of the push notification template returned */
  pushNotificationId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the segment. Currently for Braze's API */
  segmentId: Scalars['ID'];
  /** The start time for the email to send. A null value indicates immediately */
  startTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** The external id of the template returned */
  templateId?: InputMaybe<Scalars['ID']>;
  /** Variables to append to the Braze template */
  templateVariables: TemplateVariablesInput;
  /** The experiment name to use for the CRM Campaign */
  userProvidedExperimentName?: InputMaybe<Scalars['String']>;
  /** The warehouse id to run the campaign for */
  warehouseId: Scalars['ID'];
};

/** Autogenerated return type of StartCampaign */
export type StartCampaignPayload = {
  __typename?: 'StartCampaignPayload';
  /** The campaign configuration object */
  campaignConfiguration: MarketingFeatureTypesCampaignConfiguration;
};

export enum Status {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Live = 'LIVE'
}

export type StatusMapInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
  momentStatus?: InputMaybe<FilterType>;
  status?: InputMaybe<FilterType>;
};

export type StoreConfiguration = {
  __typename?: 'StoreConfiguration';
  enterpriseRetailerSettings?: Maybe<Scalars['JSONObject']>;
  id?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['JSONObject']>;
  userIsolationContextId?: Maybe<Scalars['Int']>;
};

export type StoreConfigurationDraft = {
  __typename?: 'StoreConfigurationDraft';
  createdAt?: Maybe<Scalars['DateTime']>;
  domainConfigurations?: Maybe<DomainConfiguration>;
  draftId?: Maybe<Scalars['String']>;
  draftName?: Maybe<Scalars['String']>;
  parentDraftId?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreConfigurationInput = {
  enterpriseRetailerSettings?: InputMaybe<Scalars['JSONObject']>;
  id?: InputMaybe<Scalars['Int']>;
  settings?: InputMaybe<Scalars['JSONObject']>;
  userIsolationContextId?: InputMaybe<Scalars['Int']>;
};

export enum StoreConfigurationRelationshipEnum {
  /** StoreConfigurations that are associated */
  Associated = 'ASSOCIATED',
  /** StoreConfigurations that are owned */
  Owner = 'OWNER'
}

export type StoreHours = {
  __typename?: 'StoreHours';
  overrideHours?: Maybe<Array<Maybe<OverrideHours>>>;
  regularHours?: Maybe<Array<Maybe<RegularHours>>>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreHoursByDate = {
  __typename?: 'StoreHoursByDate';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<GoogleTypeDate>;
  endsAt?: Maybe<GoogleTypeTimeOfDay>;
  operationalHours?: Maybe<OperationalHours>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerLocationFlags?: Maybe<RetailerLocationFlags>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  startsAt?: Maybe<GoogleTypeTimeOfDay>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoneId?: Maybe<Scalars['BigInt']>;
};

export type StoreHoursByDayOfWeek = {
  __typename?: 'StoreHoursByDayOfWeek';
  createdAt?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<GoogleTypeDayOfWeek>;
  endsAt?: Maybe<GoogleTypeTimeOfDay>;
  operationalHours?: Maybe<OperationalHours>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerLocationFlags?: Maybe<RetailerLocationFlags>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  startsAt?: Maybe<GoogleTypeTimeOfDay>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoneId?: Maybe<Scalars['BigInt']>;
};

export type StoreHoursByZoneRetailer = {
  __typename?: 'StoreHoursByZoneRetailer';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<GoogleTypeDate>;
  endsAt?: Maybe<GoogleTypeTimeOfDay>;
  retailerId?: Maybe<Scalars['BigInt']>;
  startsAt?: Maybe<GoogleTypeTimeOfDay>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoneId?: Maybe<Scalars['BigInt']>;
};

export type StoreHoursUpdateTask = {
  __typename?: 'StoreHoursUpdateTask';
  author?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  failedUpdates?: Maybe<Array<Maybe<FailedUpdate>>>;
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<TaskStatus>;
  updateSource?: Maybe<Scalars['String']>;
  updatedRetailerLocationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type StorePerformanceRecord = {
  __typename?: 'StorePerformanceRecord';
  name?: Maybe<Scalars['String']>;
  overstaffedHours?: Maybe<InstacartTypesV1BigDecimal>;
  recommendedHours?: Maybe<InstacartTypesV1BigDecimal>;
  scheduledDevices?: Maybe<Scalars['Int']>;
  scheduledGaps?: Maybe<Scalars['Int']>;
  scheduledHours?: Maybe<InstacartTypesV1BigDecimal>;
  understaffedHours?: Maybe<InstacartTypesV1BigDecimal>;
  warehouseLocationId?: Maybe<Scalars['BigInt']>;
};

export type StorePerformanceRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type StorePerformanceResponse = {
  __typename?: 'StorePerformanceResponse';
  metric?: Maybe<Array<Maybe<StorePerformanceRecord>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  warehouseId?: Maybe<Scalars['BigInt']>;
};

export enum Storefront {
  /** Marketplace storefront */
  Instacart = 'instacart',
  /** Storefront / Storefront PRO storefronts */
  Pbi = 'pbi'
}

export type StorefrontHiddenBadges = {
  __typename?: 'StorefrontHiddenBadges';
  highlyInStock?: Maybe<Scalars['Boolean']>;
};

export type StorefrontLocationSelector = {
  __typename?: 'StorefrontLocationSelector';
  showCrossRetailerInStoreLocations?: Maybe<Scalars['Boolean']>;
  showCrossRetailerPickupLocations?: Maybe<Scalars['Boolean']>;
  showRetailerNameForHeaderStoreLocation?: Maybe<Scalars['Boolean']>;
};

export type StorefrontPlacementBannerInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** The style and text of the description */
  descriptionText: ConfigurableTextInput;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** A unique identifier of the banner associated with the placement */
  id?: InputMaybe<Scalars['ID']>;
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: PlacementImagesInput;
  /** The banner's locale (not used right now) */
  locale: BannerLocaleEnum;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
};

/** Autogenerated return type of StorefrontPlacementCreate */
export type StorefrontPlacementCreatePayload = {
  __typename?: 'StorefrontPlacementCreatePayload';
  /** The newly created storefront storefront placement */
  storefrontPlacement: ContentManagementStorefrontPlacement;
};

/** Autogenerated return type of StorefrontPlacementDelete */
export type StorefrontPlacementDeletePayload = {
  __typename?: 'StorefrontPlacementDeletePayload';
  /** Whether deleting the storefront placement succeeded or not */
  success: Scalars['Boolean'];
};

export type StorefrontPlacementInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The banners configured to the placement */
  banners?: InputMaybe<Array<StorefrontPlacementBannerInput>>;
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** The style and text of the description */
  descriptionText: ConfigurableTextInput;
  /** Where interacting with the placement leads */
  destination?: InputMaybe<PlacementDestinationInput>;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: PlacementImagesInput;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
  /** The type of this placement */
  type: PlacementTypeEnum;
};

/** Autogenerated return type of StorefrontPlacementUpdate */
export type StorefrontPlacementUpdatePayload = {
  __typename?: 'StorefrontPlacementUpdatePayload';
  /** The updated storefront placement */
  storefrontPlacement: ContentManagementStorefrontPlacement;
};

export type StorefrontSchema = {
  __typename?: 'StorefrontSchema';
  defaultServiceType?: Maybe<InstacartCustomersSharedV1ServiceType>;
  displayCustomAttributes?: Maybe<Scalars['Boolean']>;
  hiddenBadges?: Maybe<StorefrontHiddenBadges>;
  locationSelector?: Maybe<StorefrontLocationSelector>;
  onlyShowInStockItems?: Maybe<Scalars['Boolean']>;
  salePromotionBadgeInDollars?: Maybe<Scalars['Boolean']>;
  showRetailerReferenceCodeOnItemDetails?: Maybe<Scalars['Boolean']>;
};

export type StringsInput = {
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Autogenerated return type of SubmissionCreate */
export type SubmissionCreatePayload = {
  __typename?: 'SubmissionCreatePayload';
  /** Survey submit successful */
  success: Scalars['Boolean'];
};

export type SummaryStatus = {
  __typename?: 'SummaryStatus';
  count?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['String']>;
};

export enum Targeting {
  ActivatedCustomer = 'ACTIVATED_CUSTOMER',
  ChurnedCustomer = 'CHURNED_CUSTOMER',
  LoyaltyCustomer = 'LOYALTY_CUSTOMER',
  NewCustomer = 'NEW_CUSTOMER',
  NonLoyaltyCustomer = 'NON_LOYALTY_CUSTOMER',
  Unknown = 'UNKNOWN'
}

export type TargetingRules = {
  __typename?: 'TargetingRules';
  lookbackWindowDays?: Maybe<Scalars['BigInt']>;
  targetingType?: Maybe<Targeting>;
};

export type TargetingRulesInput = {
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  targetingType?: InputMaybe<Targeting>;
};

export enum TaskStatus {
  CompletedFailure = 'COMPLETED_FAILURE',
  CompletedSuccess = 'COMPLETED_SUCCESS',
  Queued = 'QUEUED',
  Running = 'RUNNING',
  TaskStatusUndefined = 'TASK_STATUS_UNDEFINED'
}

export type TemplateVariablesInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  daysValid?: InputMaybe<Scalars['Int']>;
  offerDetailsDollarOff?: InputMaybe<Scalars['String']>;
  offerDetailsMinimumOrder?: InputMaybe<Scalars['String']>;
  retailerLogoUrl?: InputMaybe<Scalars['String']>;
  retailerName?: InputMaybe<Scalars['String']>;
  retailerSlug?: InputMaybe<Scalars['String']>;
  storefrontType?: InputMaybe<Storefront>;
};

export type TextWithThumbnail = {
  __typename?: 'TextWithThumbnail';
  backgroundColorHex?: Maybe<Scalars['String']>;
  backgroundImageUrl?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaBackgroundColorHex?: Maybe<Scalars['String']>;
  ctaColorHex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type TextWithThumbnailFlex = {
  __typename?: 'TextWithThumbnailFlex';
  backgroundColorHex?: Maybe<Scalars['String']>;
  backgroundImageUrl?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<BannerAction>;
  ctaBackgroundColorHex?: Maybe<Scalars['String']>;
  ctaColorHex?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['BigInt']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
  variant?: Maybe<BannerVariant>;
};

export type TextWithThumbnailFlexInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type TextWithThumbnailInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ThemePage = {
  __typename?: 'ThemePage';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  headerSection?: Maybe<ThemePageHeader>;
  id?: Maybe<Scalars['BigInt']>;
  retailerPicker?: Maybe<ThemePageRetailerPicker>;
  searchBar?: Maybe<ThemePageSearchBar>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ThemePageHeader = {
  __typename?: 'ThemePageHeader';
  backgroundColorHex?: Maybe<Scalars['String']>;
  disclaimerUrl?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  imageAltText?: Maybe<Scalars['String']>;
  imageMobileUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  subTitleColorHex?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleColorHex?: Maybe<Scalars['String']>;
};

export type ThemePageHeaderInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  disclaimerUrl?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  imageMobileUrl?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
};

export type ThemePageInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  headerSection?: InputMaybe<ThemePageHeaderInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  retailerPicker?: InputMaybe<ThemePageRetailerPickerInput>;
  searchBar?: InputMaybe<ThemePageSearchBarInput>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ThemePageRetailerPicker = {
  __typename?: 'ThemePageRetailerPicker';
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ThemePageRetailerPickerInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type ThemePageSearchBar = {
  __typename?: 'ThemePageSearchBar';
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ThemePageSearchBarInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type ThemeSchema = {
  __typename?: 'ThemeSchema';
  footer?: Maybe<Footer>;
  header?: Maybe<Header>;
  idsTheme?: Maybe<IdsThemeSchema>;
  items?: Maybe<ItemsSchema>;
  shopSidebar?: Maybe<ShopSidebar>;
};

export enum TicketStatus {
  TicketStatusCompleted = 'TICKET_STATUS_COMPLETED',
  TicketStatusInProgress = 'TICKET_STATUS_IN_PROGRESS',
  TicketStatusUnknown = 'TICKET_STATUS_UNKNOWN',
  TicketStatusWontDo = 'TICKET_STATUS_WONT_DO'
}

export type TimePeriod = {
  __typename?: 'TimePeriod';
  closesAt?: Maybe<Scalars['DateTime']>;
  opensAt?: Maybe<Scalars['DateTime']>;
};

export enum TokenTypeEnum {
  /** Account Setup Token Type */
  AccountSetup = 'account_setup',
  /** Email Verification Token Type */
  EmailVerification = 'email_verification',
  /** Password Reset Token Type */
  PasswordReset = 'password_reset'
}

export type TokensValidation = {
  __typename?: 'TokensValidation';
  /** Whether account is already set up */
  accountStateValid: Scalars['Boolean'];
  /** Email associated with the token */
  email?: Maybe<Scalars['String']>;
  /** Whether the token is valid */
  valid: Scalars['Boolean'];
};

export type TombstoneDetectionVersionRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  tombstonedBy?: InputMaybe<Scalars['String']>;
  tombstonedWhy?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type TombstoneDetectionVersionResponse = {
  __typename?: 'TombstoneDetectionVersionResponse';
  _?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of TriggerCampaign */
export type TriggerCampaignPayload = {
  __typename?: 'TriggerCampaignPayload';
  /** Whether it succesfully triggered */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of TriggerWorkflow */
export type TriggerWorkflowPayload = {
  __typename?: 'TriggerWorkflowPayload';
  /** The triggered workflow */
  workflow: OnboardingFeatureTypesWorkflow;
};

export type TypographyFontSchema = {
  __typename?: 'TypographyFontSchema';
  fontFamily?: Maybe<Scalars['String']>;
  fontWeight?: Maybe<Scalars['String']>;
};

export type TypographySchema = {
  __typename?: 'TypographySchema';
  rootElement?: Maybe<TypographyFontSchema>;
  subtitleLarge?: Maybe<TypographyFontSchema>;
  subtitleMedium?: Maybe<TypographyFontSchema>;
  subtitleSmall?: Maybe<TypographyFontSchema>;
  titleLarge?: Maybe<TypographyFontSchema>;
  titleMedium?: Maybe<TypographyFontSchema>;
};

export type UnionType = {
  __typename?: 'UnionType';
  content?: Maybe<Scalars['JSONObject']>;
  type?: Maybe<Scalars['String']>;
};

export type UnknownBanner = {
  __typename?: 'UnknownBanner';
  id?: Maybe<Scalars['BigInt']>;
};

export type UnknownBannerInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Upc = {
  __typename?: 'Upc';
  upc?: Maybe<Scalars['String']>;
};

export type UpcInput = {
  upc?: InputMaybe<Scalars['String']>;
};

export type UpdateApiSettingRequestInput = {
  apiType?: InputMaybe<Scalars['String']>;
  cron?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeLocationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateApiSettingResponse = {
  __typename?: 'UpdateApiSettingResponse';
  apiType?: Maybe<Scalars['String']>;
  cron?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  integrationType?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['BigInt']>;
};

export type UpdateAuditBatchRequestInput = {
  action?: InputMaybe<Scalars['String']>;
  batchUuid?: InputMaybe<Scalars['String']>;
  changeSampleSizeValue?: InputMaybe<Scalars['BigInt']>;
  client?: InputMaybe<Scalars['String']>;
  newSecondaryStateId?: InputMaybe<Scalars['BigInt']>;
  overrideDueDate?: InputMaybe<Scalars['DateTime']>;
  userUuid?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateAuditBatchResponse = {
  __typename?: 'UpdateAuditBatchResponse';
  completedAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['BigInt']>;
};

export type UpdateAuditTaskRequestInput = {
  client?: InputMaybe<Scalars['String']>;
  extComment?: InputMaybe<Scalars['String']>;
  intComment?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  taskComponents?: InputMaybe<Array<InputMaybe<UpdateAuditTaskRequestUpdateTaskComponentInput>>>;
  taskUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateAuditTaskRequestUpdateTaskComponentInput = {
  explicitAuditFailed?: InputMaybe<Scalars['Boolean']>;
  extComment?: InputMaybe<Scalars['String']>;
  intComment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  suggestedValue?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateAuditTaskResponse = {
  __typename?: 'UpdateAuditTaskResponse';
  batchVersion?: Maybe<Scalars['BigInt']>;
  message?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['BigInt']>;
};

export type UpdateAvailabilityScoreOverrideRequestInput = {
  enable?: InputMaybe<Scalars['Boolean']>;
  entityId?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  overrideEndAt?: InputMaybe<Scalars['DateTime']>;
  overrideStartAt?: InputMaybe<Scalars['DateTime']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateAvailabilityScoreOverrideResponse = {
  __typename?: 'UpdateAvailabilityScoreOverrideResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateConfirmedStoreHoursRequestInput = {
  confirmations?: InputMaybe<Array<InputMaybe<ConfirmedStoreHoursInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateConfirmedStoreHoursResponse = {
  __typename?: 'UpdateConfirmedStoreHoursResponse';
  confirmations?: Maybe<Array<Maybe<ConfirmedStoreHours>>>;
};

export type UpdateConnectRetailerCallbackConfigurationRequestInput = {
  callbackConfiguration?: InputMaybe<ConnectRetailerCallbackConfigurationInput>;
  callbackConfigurationId?: InputMaybe<Scalars['BigInt']>;
  ignoreV1Settings?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateConnectRetailerCallbackConfigurationResponse = {
  __typename?: 'UpdateConnectRetailerCallbackConfigurationResponse';
  callbackConfiguration?: Maybe<ConnectRetailerCallbackConfiguration>;
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateContentPageRequestV2Input = {
  activeVersionId?: InputMaybe<Scalars['BigInt']>;
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  internalName?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateContentPageResponseV2 = {
  __typename?: 'UpdateContentPageResponseV2';
  contentPage?: Maybe<ContentPageV2>;
};

export type UpdateContentPageVersionMetaRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateContentPageVersionMetaResponse = {
  __typename?: 'UpdateContentPageVersionMetaResponse';
  contentPageVersionMeta?: Maybe<ContentPageVersionMeta>;
};

export type UpdateContentPageVersionRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  routingStatus?: InputMaybe<ContentPageMetaInfoStatusOneOfInput>;
  seoCanonicalUrl?: InputMaybe<Scalars['String']>;
  seoNoIndex?: InputMaybe<Scalars['Boolean']>;
  trackingProperties?: InputMaybe<Scalars['JSONObject']>;
};

export type UpdateContentPageVersionResponse = {
  __typename?: 'UpdateContentPageVersionResponse';
  contentPageVersion?: Maybe<ContentPageVersion>;
};

export type UpdateLaunchOnboardingWarehouseInfoRequestInput = {
  allowAlcohol?: InputMaybe<Scalars['Boolean']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  certifiedDeliveryThreshold?: InputMaybe<Scalars['Float']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  logoBackgroundColor?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  pricingPolicy?: InputMaybe<Scalars['String']>;
  pricingPolicyText?: InputMaybe<Scalars['String']>;
  retailerType?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateLaunchOnboardingWarehouseInfoResponse = {
  __typename?: 'UpdateLaunchOnboardingWarehouseInfoResponse';
  launchOnboarding?: Maybe<LaunchOnboarding>;
};

export type UpdateLaunchOnboardingWarehouseLocationInfoRequestInput = {
  address?: InputMaybe<LaunchOnboardingAddressInput>;
  locationCode?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateLaunchOnboardingWarehouseLocationInfoResponse = {
  __typename?: 'UpdateLaunchOnboardingWarehouseLocationInfoResponse';
  launchOnboarding?: Maybe<LaunchOnboarding>;
  warehouseLocation?: Maybe<LaunchOnboardingWarehouseLocation>;
};

export type UpdateLaunchOnboardingWorkflowStateRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
  stateAction?: InputMaybe<LaunchOnboardingWorkflowStateAction>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateLaunchOnboardingWorkflowStateResponse = {
  __typename?: 'UpdateLaunchOnboardingWorkflowStateResponse';
  launchOnboarding?: Maybe<LaunchOnboarding>;
};

export type UpdateLayoutVariantRequestInput = {
  layoutVariant?: InputMaybe<LayoutVariantInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateLayoutVariantResponse = {
  __typename?: 'UpdateLayoutVariantResponse';
  layoutVariant?: Maybe<LayoutVariant>;
};

export type UpdateMomentCampaignStatusRequestInput = {
  momentCampaignStatus?: InputMaybe<Array<InputMaybe<MomentCampaignStatusInput>>>;
  momentCampaigns?: InputMaybe<StatusMapInput>;
};

export type UpdateMomentCampaignStatusResponse = {
  __typename?: 'UpdateMomentCampaignStatusResponse';
  campaigns?: Maybe<Array<Maybe<RetailerCampaign>>>;
};

export type UpdateMomentRequestInput = {
  campaignSubmissionDeadline?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  momentId?: InputMaybe<Scalars['BigInt']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateMomentResponse = {
  __typename?: 'UpdateMomentResponse';
  moment?: Maybe<Moment>;
};

export type UpdateNavigationRequestInput = {
  navigation?: InputMaybe<NavigationInput>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateNavigationResponse = {
  __typename?: 'UpdateNavigationResponse';
  navigation?: Maybe<Navigation>;
};

export type UpdateOnboardingBillingAddressRequestInput = {
  billingAddress?: InputMaybe<AddressInput>;
  token?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingBillingAddressResponse = {
  __typename?: 'UpdateOnboardingBillingAddressResponse';
  onboarding?: Maybe<Onboarding>;
};

export type UpdateOnboardingRequestInput = {
  contactInfo?: InputMaybe<ContactInput>;
  corporationType?: InputMaybe<Scalars['String']>;
  duns?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  exclusivity?: InputMaybe<Scalars['String']>;
  onboardingType?: InputMaybe<Scalars['String']>;
  posSystemName?: InputMaybe<Scalars['String']>;
  stateOfIncorporation?: InputMaybe<Scalars['String']>;
  storeCount?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  w9Form?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingResponse = {
  __typename?: 'UpdateOnboardingResponse';
  onboarding?: Maybe<Onboarding>;
};

export type UpdateOnboardingWorkflowStateRequestInput = {
  ipAddress?: InputMaybe<Scalars['String']>;
  stateAction?: InputMaybe<OnboardingWorkflowStateAction>;
  token?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingWorkflowStateResponse = {
  __typename?: 'UpdateOnboardingWorkflowStateResponse';
  onboarding?: Maybe<Onboarding>;
};

export type UpdateParkingSettingsRequestInput = {
  imageConfigs?: InputMaybe<Array<InputMaybe<ImageConfigInput>>>;
  instruction?: InputMaybe<Scalars['String']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  setDefault?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<PickupType>;
};

export type UpdateParkingSettingsResponse = {
  __typename?: 'UpdateParkingSettingsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageConfigs?: Maybe<Array<Maybe<ImageConfig>>>;
  instruction?: Maybe<Scalars['String']>;
  retailerLocationConfig?: Maybe<RetailerLocationInfo>;
};

export type UpdatePlacementContentRequestInput = {
  adminContext?: InputMaybe<PlacementAdminContextInput>;
  content?: InputMaybe<PlacementContentInput>;
};

export type UpdatePlacementContentResponse = {
  __typename?: 'UpdatePlacementContentResponse';
  content?: Maybe<PlacementContent>;
};

export type UpdateRetailerAisleTemplateRequestInput = {
  aisleOrders?: InputMaybe<Array<InputMaybe<AisleOrderInput>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  shopperType?: InputMaybe<ShopperType>;
};

export type UpdateRetailerAisleTemplateResponse = {
  __typename?: 'UpdateRetailerAisleTemplateResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  updated?: Maybe<Scalars['Boolean']>;
};

export type UpdateRetailerCampaignRequestInput = {
  budget?: InputMaybe<GoogleTypeMoneyInput>;
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignReference?: InputMaybe<CampaignReferenceInput>;
  couponCount?: InputMaybe<Scalars['BigInt']>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<CampaignFilterInput>>>;
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  narrowSearchTerms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offers?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferInput>>>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  rouletteFeature?: InputMaybe<CampaignRouletteFeatureInput>;
  schedule?: InputMaybe<Scalars['String']>;
  segmentId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  targeting?: InputMaybe<Array<InputMaybe<Targeting>>>;
  targetingRules?: InputMaybe<Array<InputMaybe<TargetingRulesInput>>>;
};

export type UpdateRetailerCampaignTreatmentParamsInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  treatmentDetails?: InputMaybe<Array<InputMaybe<RetailerCampaignTreatmentDetailsInput>>>;
  treatmentType?: InputMaybe<RetailerCampaignTreatmentType>;
};

export type UpdateRetailerCampaignTreatmentsRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  treatments?: InputMaybe<Array<InputMaybe<UpdateRetailerCampaignTreatmentParamsInput>>>;
};

export type UpdateRetailerCampaignTreatmentsResponse = {
  __typename?: 'UpdateRetailerCampaignTreatmentsResponse';
  treatments?: Maybe<Array<Maybe<RetailerCampaignTreatment>>>;
};

export type UpdateRetailerProductVisibilitiesRequestInput = {
  entityId?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  inAssortment?: InputMaybe<Scalars['Boolean']>;
  outOfAssortmentEndAt?: InputMaybe<Scalars['DateTime']>;
  outOfAssortmentStartAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateRetailerProductVisibilitiesResponse = {
  __typename?: 'UpdateRetailerProductVisibilitiesResponse';
  entityId?: Maybe<Array<Maybe<EntityId>>>;
};

export type UpdateRoleInput = {
  /** A description of the role */
  description?: InputMaybe<Scalars['String']>;
  /** The role ID */
  id: Scalars['ID'];
  /** The name of the role */
  name?: InputMaybe<Scalars['String']>;
  /** The permissions associated with this role */
  permissions?: InputMaybe<Array<PermissionInput>>;
};

export type UpdateShopperOrderItemRequestInput = {
  adminRequestContext?: InputMaybe<AdminRequestContextInput>;
  deliveredCount?: InputMaybe<Scalars['BigInt']>;
  deliveredWeight?: InputMaybe<Scalars['Float']>;
  lineNum?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  rrc?: InputMaybe<Scalars['String']>;
  scans?: InputMaybe<Array<InputMaybe<ShopperOrderItemScanInput>>>;
  shopperId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['String']>;
  substituteId?: InputMaybe<Scalars['BigInt']>;
  upc?: InputMaybe<Scalars['String']>;
};

export type UpdateShopperOrderItemResponse = {
  __typename?: 'UpdateShopperOrderItemResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateStagingSettingsRequestInput = {
  freezer?: InputMaybe<StringsInput>;
  fridge?: InputMaybe<StringsInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instruction?: InputMaybe<Scalars['String']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  shelf?: InputMaybe<StringsInput>;
};

export type UpdateStagingSettingsResponse = {
  __typename?: 'UpdateStagingSettingsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  freezer?: Maybe<Array<Maybe<Scalars['String']>>>;
  fridge?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageUrl?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  retailerLocationConfig?: Maybe<RetailerLocationInfo>;
  shelf?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateStoreConfigurationDraftRequestInput = {
  domainConfigurations?: InputMaybe<Scalars['JSONObject']>;
  draftId?: InputMaybe<Scalars['String']>;
  draftName?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateStoreConfigurationDraftResponse = {
  __typename?: 'UpdateStoreConfigurationDraftResponse';
  domainConfigurations?: Maybe<DomainConfiguration>;
  draftId?: Maybe<Scalars['String']>;
  draftName?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorReason?: Maybe<ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type UpdateStoreHoursRequestInput = {
  author?: InputMaybe<Scalars['String']>;
  clearOverrideHoursByType?: InputMaybe<Array<InputMaybe<RetailerServiceType>>>;
  clearOverrideHoursCriteria?: InputMaybe<Array<InputMaybe<ClearOverrideHoursCriteriaInput>>>;
  confirmations?: InputMaybe<Array<InputMaybe<ConfirmedStoreHoursInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  updateRegularHours?: InputMaybe<Array<InputMaybe<RegularHoursInput>>>;
  updateSource?: InputMaybe<Scalars['String']>;
  upsertOverrideHours?: InputMaybe<Array<InputMaybe<OverrideHoursInput>>>;
};

export type UpdateStoreHoursResponse = {
  __typename?: 'UpdateStoreHoursResponse';
  taskId?: Maybe<Scalars['BigInt']>;
};

export type UpdateThemePageRequestInput = {
  themePage?: InputMaybe<ThemePageInput>;
};

export type UpdateThemePageResponse = {
  __typename?: 'UpdateThemePageResponse';
  themePage?: Maybe<ThemePage>;
};

export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  guest?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  userId?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};

export type UserInteractionLimitInput = {
  /** number of seconds to wait before resetting the counter */
  countCooldownSec?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** maximum number of dismiss events */
  maxDismissedCount?: InputMaybe<Scalars['Int']>;
  /** maximum number of click events */
  maxEngagementsCount?: InputMaybe<Scalars['Int']>;
  /** maximum number of view events */
  maxImpressionsCount?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of UserInteractionLimitVisibilityConditionCreate */
export type UserInteractionLimitVisibilityConditionCreatePayload = {
  __typename?: 'UserInteractionLimitVisibilityConditionCreatePayload';
  /** The newly created UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityCondition: ContentManagementVisibilityConditionUserInteractionLimit;
};

/** Autogenerated return type of UserInteractionLimitVisibilityConditionUpdate */
export type UserInteractionLimitVisibilityConditionUpdatePayload = {
  __typename?: 'UserInteractionLimitVisibilityConditionUpdatePayload';
  /** The newly updated UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityCondition: ContentManagementVisibilityConditionUserInteractionLimit;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  guest?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ordersCount?: Maybe<Scalars['BigInt']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  vip?: Maybe<Scalars['Boolean']>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  key?: Maybe<UserSettingKey>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['BigInt']>;
};

export type UserSettingInput = {
  key?: InputMaybe<UserSettingKeyInput>;
  value?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type UserSettingKey = {
  __typename?: 'UserSettingKey';
  app?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserSettingKeyInput = {
  app?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UsersSchema = {
  __typename?: 'UsersSchema';
  afterSignOutPathOrUrl?: Maybe<Scalars['String']>;
  manageAccountUrl?: Maybe<Scalars['String']>;
  notificationSettingsCallBeforeCheckoutOptionsEnabled?: Maybe<Scalars['Boolean']>;
  notificationSettingsEmailOptionsEnabled?: Maybe<Scalars['Boolean']>;
  notificationSettingsPushNotificationOptionsEnabled?: Maybe<Scalars['Boolean']>;
  notificationSettingsTextMessageOptionsEnabled?: Maybe<Scalars['Boolean']>;
  signupPhoneNumberLoyaltyLinkage?: Maybe<Scalars['Boolean']>;
};

export type UtmParametersInput = {
  /** coupon_code */
  couponCode?: InputMaybe<Scalars['String']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** utm_campaign */
  utmCampaign?: InputMaybe<Scalars['String']>;
  /** utm_content */
  utmContent?: InputMaybe<Scalars['String']>;
  /** utm_medium */
  utmMedium?: InputMaybe<Scalars['String']>;
  /** utm_source */
  utmSource?: InputMaybe<Scalars['String']>;
  /** utm_term */
  utmTerm?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UtmParametersVisibilityConditionCreate */
export type UtmParametersVisibilityConditionCreatePayload = {
  __typename?: 'UtmParametersVisibilityConditionCreatePayload';
  /** The newly created UtmParameters visibility condition */
  utmParametersVisibilityCondition: ContentManagementVisibilityConditionUtmParameters;
};

/** Autogenerated return type of UtmParametersVisibilityConditionUpdate */
export type UtmParametersVisibilityConditionUpdatePayload = {
  __typename?: 'UtmParametersVisibilityConditionUpdatePayload';
  /** The newly updated UtmParameters visibility condition */
  utmParametersVisibilityCondition: ContentManagementVisibilityConditionUtmParameters;
};

export type ValueBasedOverrideRequestInput = {
  attributeName?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<ClientInput>;
  createdWhy?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<EntityIdInput>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  overrideType?: InputMaybe<OverrideType>;
  overrideValue?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
  sourceValue?: InputMaybe<Scalars['String']>;
  ticketUrl?: InputMaybe<Scalars['String']>;
};

export type ValueBasedOverrideResponse = {
  __typename?: 'ValueBasedOverrideResponse';
  attributeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByJson?: Maybe<Scalars['String']>;
  createdWhy?: Maybe<Scalars['String']>;
  entityId?: Maybe<EntityId>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  overrideType?: Maybe<OverrideType>;
  overrideValue?: Maybe<Scalars['String']>;
  rowId?: Maybe<Scalars['BigInt']>;
  sourceType?: Maybe<Scalars['String']>;
  sourceValue?: Maybe<Scalars['String']>;
  ticketUrl?: Maybe<Scalars['String']>;
};

export enum VerticalType {
  AlcoholHours = 'ALCOHOL_HOURS',
  DeliveryHours = 'DELIVERY_HOURS',
  IssHours = 'ISS_HOURS',
  PharmacyHours = 'PHARMACY_HOURS',
  PickupHours = 'PICKUP_HOURS',
  PickupOverrideHours = 'PICKUP_OVERRIDE_HOURS'
}

export type VirtualRetailer = {
  __typename?: 'VirtualRetailer';
  coreRetailerId?: Maybe<Scalars['BigInt']>;
  virtualRetailerId?: Maybe<Scalars['BigInt']>;
};

/** Union of the Types of Visibility Conditions which may be returned */
export type VisibilityCondition = ContentManagementVisibilityConditionCountryIds | ContentManagementVisibilityConditionDayOfWeek | ContentManagementVisibilityConditionExperimentVariant | ContentManagementVisibilityConditionExpressMembership | ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout | ContentManagementVisibilityConditionMatchesAnyRetailerId | ContentManagementVisibilityConditionMinimumPlatformVersion | ContentManagementVisibilityConditionNewVerticalUserTargeting | ContentManagementVisibilityConditionRetailerAvailability | ContentManagementVisibilityConditionShopped | ContentManagementVisibilityConditionShoppedAtRetailer | ContentManagementVisibilityConditionUnknownVisibilityCondition | ContentManagementVisibilityConditionUserInteractionLimit | ContentManagementVisibilityConditionUtmParameters | ContentManagementVisibilityConditionWhitelistedPostalCodes | ContentManagementVisibilityConditionWhitelistedZones;

/** Autogenerated return type of VisibilityConditionDelete */
export type VisibilityConditionDeletePayload = {
  __typename?: 'VisibilityConditionDeletePayload';
  /** Whether deleting the visibility condition succeeded or not */
  success: Scalars['Boolean'];
};

export type VisibilityConditionStruct = {
  __typename?: 'VisibilityConditionStruct';
  data?: Maybe<Scalars['JSONObject']>;
  typeName?: Maybe<Scalars['String']>;
};

export type VisibilityConditionStructInput = {
  data?: InputMaybe<Scalars['JSONObject']>;
  typeName?: InputMaybe<Scalars['String']>;
};

export type WhitelistedPostalCodesInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of postal codes */
  postalCodes: Array<Scalars['String']>;
};

/** Autogenerated return type of WhitelistedPostalCodesVisibilityConditionCreate */
export type WhitelistedPostalCodesVisibilityConditionCreatePayload = {
  __typename?: 'WhitelistedPostalCodesVisibilityConditionCreatePayload';
  /** The newly created whitelisted_postal_codes visibility condition */
  whitelistedPostalCodesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedPostalCodes;
};

/** Autogenerated return type of WhitelistedPostalCodesVisibilityConditionUpdate */
export type WhitelistedPostalCodesVisibilityConditionUpdatePayload = {
  __typename?: 'WhitelistedPostalCodesVisibilityConditionUpdatePayload';
  /** The newly created whitelisted_postal_codes visibility condition */
  whitelistedPostalCodesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedPostalCodes;
};

export type WhitelistedZonesInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of zones ids */
  zones: Array<Scalars['String']>;
};

/** Autogenerated return type of WhitelistedZonesVisibilityConditionCreate */
export type WhitelistedZonesVisibilityConditionCreatePayload = {
  __typename?: 'WhitelistedZonesVisibilityConditionCreatePayload';
  /** The newly created whitelisted_zones visibility condition */
  whitelistedZonesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedZones;
};

/** Autogenerated return type of WhitelistedZonesVisibilityConditionUpdate */
export type WhitelistedZonesVisibilityConditionUpdatePayload = {
  __typename?: 'WhitelistedZonesVisibilityConditionUpdatePayload';
  /** The updated WhitelistedZones visibility condition */
  whitelistedZonesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedZones;
};

export type Widget = {
  __typename?: 'Widget';
  dataQuery?: Maybe<DataQuery>;
  id?: Maybe<Scalars['BigInt']>;
  variant?: Maybe<WidgetVariant>;
};

export type WidgetInput = {
  dataQuery?: InputMaybe<DataQueryInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  variant?: InputMaybe<WidgetVariant>;
};

export enum WidgetVariant {
  NavigationList = 'NAVIGATION_LIST',
  OrderHistory = 'ORDER_HISTORY',
  QuickLinks = 'QUICK_LINKS',
  ServiceOptionSelector = 'SERVICE_OPTION_SELECTOR',
  UnspecifiedWidget = 'UNSPECIFIED_WIDGET',
  WaysToShop = 'WAYS_TO_SHOP'
}

/** Autogenerated return type of WizardProgressUpdate */
export type WizardProgressUpdatePayload = {
  __typename?: 'WizardProgressUpdatePayload';
  /** A status which is true if no errors were encountered while performing the update */
  success: Scalars['True'];
};

export enum WizardStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS'
}

export enum WizardTypeEnum {
  PickupSettingsOnboarding = 'PICKUP_SETTINGS_ONBOARDING'
}

export type WizardsWizardProgress = {
  __typename?: 'WizardsWizardProgress';
  id: Scalars['ID'];
  status: WizardStatusEnum;
  wizardType: WizardTypeEnum;
};

export type WorkflowParameter = {
  /** The parameter name */
  name: Scalars['String'];
  /** The value of the parameter */
  value: Scalars['JSON'];
};

export type WorkflowQueryFilter = {
  /** Query by specific statuses */
  status?: InputMaybe<Scalars['String']>;
  /** Query by specific workflow id */
  workflowId?: InputMaybe<Scalars['Int']>;
  /** Query by specific workflow definition */
  workflowName?: InputMaybe<Scalars['String']>;
};

export enum WorkflowStatus {
  WorkflowStatusCompleted = 'WORKFLOW_STATUS_COMPLETED',
  WorkflowStatusDeactivate = 'WORKFLOW_STATUS_DEACTIVATE',
  WorkflowStatusExpired = 'WORKFLOW_STATUS_EXPIRED',
  WorkflowStatusFailed = 'WORKFLOW_STATUS_FAILED',
  WorkflowStatusGetData = 'WORKFLOW_STATUS_GET_DATA',
  WorkflowStatusInProgress = 'WORKFLOW_STATUS_IN_PROGRESS',
  WorkflowStatusReadyToDelete = 'WORKFLOW_STATUS_READY_TO_DELETE',
  WorkflowStatusUnspecified = 'WORKFLOW_STATUS_UNSPECIFIED'
}

/**
 * An object representing a latitude/longitude pair. This is expressed as a pair
 * of doubles representing degrees latitude and degrees longitude. Unless
 * specified otherwise, this must conform to the
 * <a href="http://www.unoosa.org/pdf/icg/2012/template/WGS_84.pdf">WGS84
 * standard</a>. Values must be within normalized ranges.
 */
export type Google_Type_LatLng = {
  __typename?: 'google_type_LatLng';
  /** The latitude in degrees. It must be in the range [-90.0, +90.0]. */
  latitude?: Maybe<Scalars['Float']>;
  /** The longitude in degrees. It must be in the range [-180.0, +180.0]. */
  longitude?: Maybe<Scalars['Float']>;
};

/** Represents an amount of money with its currency type. */
export type Google_Type_Money = {
  __typename?: 'google_type_Money';
  /** The 3-letter currency code defined in ISO 4217. */
  currencyCode?: Maybe<Scalars['String']>;
  /**
   * Number of nano (10^-9) units of the amount.
   * The value must be between -999,999,999 and +999,999,999 inclusive.
   * If `units` is positive, `nanos` must be positive or zero.
   * If `units` is zero, `nanos` can be positive, zero, or negative.
   * If `units` is negative, `nanos` must be negative or zero.
   * For example $-1.75 is represented as `units`=-1 and `nanos`=-750,000,000.
   */
  nanos?: Maybe<Scalars['Int']>;
  /**
   * The whole units of the amount.
   * For example if `currencyCode` is `"USD"`, then 1 unit is one US dollar.
   */
  units?: Maybe<Scalars['BigInt']>;
};

/** Represents an amount of money with its currency type. */
export type Google_Type_Money_Input = {
  /** The 3-letter currency code defined in ISO 4217. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /**
   * Number of nano (10^-9) units of the amount.
   * The value must be between -999,999,999 and +999,999,999 inclusive.
   * If `units` is positive, `nanos` must be positive or zero.
   * If `units` is zero, `nanos` can be positive, zero, or negative.
   * If `units` is negative, `nanos` must be negative or zero.
   * For example $-1.75 is represented as `units`=-1 and `nanos`=-750,000,000.
   */
  nanos?: InputMaybe<Scalars['Int']>;
  /**
   * The whole units of the amount.
   * For example if `currencyCode` is `"USD"`, then 1 unit is one US dollar.
   */
  units?: InputMaybe<Scalars['BigInt']>;
};

/**
 * Represents a postal address, e.g. for postal delivery or payments addresses.
 * Given a postal address, a postal service can deliver items to a premise, P.O.
 * Box or similar.
 * It is not intended to model geographical locations (roads, towns,
 * mountains).
 *
 * In typical usage an address would be created via user input or from importing
 * existing data, depending on the type of process.
 *
 * Advice on address input / editing:
 * - Use an i18n-ready address widget such as
 * https://github.com/google/libaddressinput)
 * - Users should not be presented with UI elements for input or editing of
 * fields outside countries where that field is used.
 *
 * For more guidance on how to use this schema, please see:
 * https://support.google.com/business/answer/6397478
 */
export type Google_Type_PostalAddress = {
  __typename?: 'google_type_PostalAddress';
  /**
   * Unstructured address lines describing the lower levels of an address.
   *
   * Because values in address_lines do not have type information and may
   * sometimes contain multiple values in a single field (e.g.
   * "Austin, TX"), it is important that the line order is clear. The order of
   * address lines should be "envelope order" for the country/region of the
   * address. In places where this can vary (e.g. Japan), address_language is
   * used to make it explicit (e.g. "ja" for large-to-small ordering and
   * "ja-Latn" or "en" for small-to-large). This way, the most specific line of
   * an address can be selected based on the language.
   *
   * The minimum permitted structural representation of an address consists
   * of a region_code with all remaining information placed in the
   * address_lines. It would be possible to format such an address very
   * approximately without geocoding, but no semantic reasoning could be
   * made about any of the address components until it was at least
   * partially resolved.
   *
   * Creating an address only containing a region_code and address_lines, and
   * then geocoding is the recommended way to handle completely unstructured
   * addresses (as opposed to guessing which parts of the address should be
   * localities or administrative areas).
   */
  addressLines?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Optional. Highest administrative subdivision which is used for postal
   * addresses of a country or region.
   * For example, this can be a state, a province, an oblast, or a prefecture.
   * Specifically, for Spain this is the province and not the autonomous
   * community (e.g. "Barcelona" and not "Catalonia").
   * Many countries don't use an administrative area in postal addresses. E.g.
   * in Switzerland this should be left unpopulated.
   */
  administrativeArea?: Maybe<Scalars['String']>;
  /**
   * Optional. BCP-47 language code of the contents of this address (if
   * known). This is often the UI language of the input form or is expected
   * to match one of the languages used in the address' country/region, or their
   * transliterated equivalents.
   * This can affect formatting in certain countries, but is not critical
   * to the correctness of the data and will never affect any validation or
   * other non-formatting related operations.
   *
   * If this value is not known, it should be omitted (rather than specifying a
   * possibly incorrect default).
   *
   * Examples: "zh-Hant", "ja", "ja-Latn", "en".
   */
  languageCode?: Maybe<Scalars['String']>;
  /**
   * Optional. Generally refers to the city/town portion of the address.
   * Examples: US city, IT comune, UK post town.
   * In regions of the world where localities are not well defined or do not fit
   * into this structure well, leave locality empty and use address_lines.
   */
  locality?: Maybe<Scalars['String']>;
  /** Optional. The name of the organization at the address. */
  organization?: Maybe<Scalars['String']>;
  /**
   * Optional. Postal code of the address. Not all countries use or require
   * postal codes to be present, but where they are used, they may trigger
   * additional validation with other parts of the address (e.g. state/zip
   * validation in the U.S.A.).
   */
  postalCode?: Maybe<Scalars['String']>;
  /**
   * Optional. The recipient at the address.
   * This field may, under certain circumstances, contain multiline information.
   * For example, it might contain "care of" information.
   */
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Required. CLDR region code of the country/region of the address. This
   * is never inferred and it is up to the user to ensure the value is
   * correct. See http://cldr.unicode.org/ and
   * http://www.unicode.org/cldr/charts/30/supplemental/territory_information.html
   * for details. Example: "CH" for Switzerland.
   */
  regionCode?: Maybe<Scalars['String']>;
  /**
   * The schema revision of the `PostalAddress`.
   * All new revisions **must** be backward compatible with old revisions.
   */
  revision?: Maybe<Scalars['Int']>;
  /**
   * Optional. Additional, country-specific, sorting code. This is not used
   * in most regions. Where it is used, the value is either a string like
   * "CEDEX", optionally followed by a number (e.g. "CEDEX 7"), or just a number
   * alone, representing the "sector code" (Jamaica), "delivery area indicator"
   * (Malawi) or "post office indicator" (e.g. Côte d'Ivoire).
   */
  sortingCode?: Maybe<Scalars['String']>;
  /**
   * Optional. Sublocality of the address.
   * For example, this can be neighborhoods, boroughs, districts.
   */
  sublocality?: Maybe<Scalars['String']>;
};

/**
 * Contextual scope restrictions that are handled first-class.
 *
 * MOST scoping should come from the targeting rule itself. The ones here are
 * special and use-case specific.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction = {
  __typename?: 'instacart_ads_platform_taas_v1_ContextRestriction';
  global?: Maybe<Scalars['instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext']>;
  retailer?: Maybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext>;
};

/**
 * Contextual scope restrictions that are handled first-class.
 *
 * MOST scoping should come from the targeting rule itself. The ones here are
 * special and use-case specific.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input = {
  global?: InputMaybe<Scalars['instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext_Input']>;
  retailer?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext_Input>;
};

/**
 * Controls a particular segment to only be valid at a particular
 * retailer/whitelabel combination. For Marketplace, use whitelabel_id = 1.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext = {
  __typename?: 'instacart_ads_platform_taas_v1_ContextRestriction_RetailerContext';
  retailerId?: Maybe<Scalars['BigInt']>;
  whitelabelId?: Maybe<Scalars['BigInt']>;
};

/**
 * Controls a particular segment to only be valid at a particular
 * retailer/whitelabel combination. For Marketplace, use whitelabel_id = 1.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  whitelabelId?: InputMaybe<Scalars['BigInt']>;
};

/** Creates a new segment with the given definition. */
export type Instacart_Ads_Platform_Taas_V1_CreateSegmentRequest_Input = {
  /**
   * Specific context in which this segment is valid.
   *
   * NOT editable after the segment is created!
   */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /** Definition of the new segment. */
  definition?: InputMaybe<Instacart_Ads_Targeting_V1_TargetingRule_Input>;
  /** Optional description of the segment. */
  description?: InputMaybe<Scalars['String']>;
  /** Optional name of the segment. */
  name?: InputMaybe<Scalars['String']>;
  /** Data to be returned from TaaS when this segment matches. */
  payload?: InputMaybe<Scalars['GoogleProtobufAnyScalar']>;
  /** Scope in which to create the new segment. */
  universe?: InputMaybe<Instacart_Ads_Platform_V1_Universe>;
};

export type Instacart_Ads_Platform_Taas_V1_DeleteSegmentRequest_Input = {
  /** This field is REQUIRED. */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /**
   * The ID of the segment to be deleted. If the segment does not exist, or if
   * the version number in this field does not match the actual most-recent
   * version number for the segment, then the request will fail.
   */
  id?: InputMaybe<Instacart_Ads_Platform_Taas_V1_SegmentId_Input>;
};

/** Supported feature */
export type Instacart_Ads_Platform_Taas_V1_EntityId = {
  __typename?: 'instacart_ads_platform_taas_v1_EntityId';
  /** Name of the entity id */
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Instacart_Ads_Platform_Taas_V1_EntityIdType>;
};

/** Type of entity id */
export enum Instacart_Ads_Platform_Taas_V1_EntityIdType {
  /** The value of entity id is passed from API payload */
  Context = 'CONTEXT',
  /** The value of entity id is defined as the key of the map type feature such as order count in the taas segment */
  MapKey = 'MAP_KEY',
  /** The value of entity id is defined in the taas segment */
  Segment = 'SEGMENT'
}

/** Type of the data source of the feature */
export enum Instacart_Ads_Platform_Taas_V1_FeatureDataSourceType {
  BrazeSnowflake = 'BRAZE_SNOWFLAKE',
  Contextual = 'CONTEXTUAL',
  Csv = 'CSV',
  FeatureStore = 'FEATURE_STORE',
  HubEvent = 'HUB_EVENT',
  KafkaEvent = 'KAFKA_EVENT',
  Snowflake = 'SNOWFLAKE'
}

export type Instacart_Ads_Platform_Taas_V1_GetAllSegmentDefinitionsRequest_Input = {
  /**
   * Only segments matching this context will be returned.
   *
   * This field is REQUIRED. It is not valid to request all segments for an
   * entire universe via this interface.
   */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  universe?: InputMaybe<Instacart_Ads_Platform_V1_Universe>;
};

/** Request to get all supported features */
export type Instacart_Ads_Platform_Taas_V1_GetAllSupportedFeaturesRequest_Input = {
  /**
   * Only features matching this context via restricted_whitelabel_ids and restricted_retailer_ids will be returned.
   * This field is REQUIRED.
   */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
};

/** Response to get all supported features */
export type Instacart_Ads_Platform_Taas_V1_GetAllSupportedFeaturesResponse = {
  __typename?: 'instacart_ads_platform_taas_v1_GetAllSupportedFeaturesResponse';
  /** List of supported features */
  supportedFeatures?: Maybe<Array<Maybe<Instacart_Ads_Platform_Taas_V1_SupportedFeature>>>;
};

export type Instacart_Ads_Platform_Taas_V1_GetSegmentDefinitionRequest_Input = {
  /** This field is REQUIRED. */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /**
   * The segment to look up.
   *
   * Must contain an ID, but the version_number is optional. If provided, the
   * response will be the definition for that exact version, which may or may
   * not be the latest (currently live) version. If not provided, then the
   * response will be for the latest (currently live) version of that segment.
   */
  id?: InputMaybe<Instacart_Ads_Platform_Taas_V1_SegmentId_Input>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentId = {
  __typename?: 'instacart_ads_platform_taas_v1_SegmentId';
  /** Universe to which the segment belongs. */
  universe?: Maybe<Instacart_Ads_Platform_V1_Universe>;
  /**
   * Unique identifier for a segment. This is guaranteed to be unique among all
   * segments ever created.
   */
  uuid?: Maybe<Scalars['String']>;
  /** Counts the number of revisions that have been made (via UpdateSegment). */
  versionNumber?: Maybe<Scalars['Int']>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentId_Input = {
  /** Universe to which the segment belongs. */
  universe?: InputMaybe<Instacart_Ads_Platform_V1_Universe>;
  /**
   * Unique identifier for a segment. This is guaranteed to be unique among all
   * segments ever created.
   */
  uuid?: InputMaybe<Scalars['String']>;
  /** Counts the number of revisions that have been made (via UpdateSegment). */
  versionNumber?: InputMaybe<Scalars['Int']>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentState = {
  __typename?: 'instacart_ads_platform_taas_v1_SegmentState';
  /**
   * Specific context in which this segment is valid.
   *
   * NOT editable after the segment is created!
   */
  context?: Maybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction>;
  /**
   * The definition of the segment, after the operation.
   *
   * If this field is unset (null), then the segment has been deleted; note
   * that this is semantically very different from an extant definition with no
   * fields filled.
   */
  definition?: Maybe<Instacart_Ads_Targeting_V1_TargetingRule>;
  /** Optional description of the segment. */
  description?: Maybe<Scalars['String']>;
  /** The ID of the segment, with the version number after the operation. */
  id?: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentId>;
  /** Optional name of the segment. */
  name?: Maybe<Scalars['String']>;
  /** Data to be returned from TaaS when this segment matches. */
  payload?: Maybe<Scalars['GoogleProtobufAnyScalar']>;
  /** Date-Time Segment was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentStates = {
  __typename?: 'instacart_ads_platform_taas_v1_SegmentStates';
  segments?: Maybe<Array<Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>>>;
};

/** Supported feature */
export type Instacart_Ads_Platform_Taas_V1_SupportedFeature = {
  __typename?: 'instacart_ads_platform_taas_v1_SupportedFeature';
  /** Whether the feature is contextual or regular feature */
  contextual?: Maybe<Scalars['Boolean']>;
  /** data granularity of the feature */
  dataGranularity?: Maybe<Scalars['String']>;
  /** data refreshness of the feature such as daily, every 2 hours, near real-time */
  dataRefreshness?: Maybe<Scalars['String']>;
  /** Description of the feature */
  description?: Maybe<Scalars['String']>;
  /** the source of the eligible values of the feature */
  eligibleValueSource?: Maybe<Scalars['String']>;
  /** the eligible values of the feature */
  eligibleValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** the logical primary key in TaaS storage */
  entityIds?: Maybe<Array<Maybe<Instacart_Ads_Platform_Taas_V1_EntityId>>>;
  /** ipp specific meta info such as required_permissions, roulette */
  ippMetadata?: Maybe<Scalars['JSON']>;
  /** Name of the feature */
  name?: Maybe<Scalars['String']>;
  /** offline support for the feature */
  offlineEvalSupport?: Maybe<Scalars['Boolean']>;
  /** Online eval Support for the feature */
  onlineEvalSupport?: Maybe<Scalars['Boolean']>;
  /** the supported retailers. If this is not specified, it means the feature is available for all the retailers */
  restrictedRetailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** the supported whitelabel site. If this is not specified, it means the feature is available for all the whitelabel sites */
  restrictedWhitelabelIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** data source type of the feature */
  sourceType?: Maybe<Instacart_Ads_Platform_Taas_V1_FeatureDataSourceType>;
  /** Type of the feature */
  type?: Maybe<Instacart_Ads_Platform_Taas_V1_SupportedFeatureType>;
};

/**
 * Type of the feature this is mapped to taas_config.go
 * https://github.com/instacart/carrot/blob/master/ads/targeting/rule-engine/taas_config.go#L12
 */
export enum Instacart_Ads_Platform_Taas_V1_SupportedFeatureType {
  Boolean = 'BOOLEAN',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  String = 'STRING',
  StringList = 'STRING_LIST',
  Time = 'TIME',
  /** Unknown type placeholder */
  Unknown = 'UNKNOWN'
}

/**
 * This request has OVERWRITE semantics - any existing definition for the segment
 * or its payload will be dropped entirely. Merge semantics, if necessary, are
 * left to the client - the client can get the current definition with
 * GetSegmentDefinition, then assemble what they want the final state to be.
 */
export type Instacart_Ads_Platform_Taas_V1_UpdateSegmentRequest_Input = {
  /** This field is REQUIRED. */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /** The new definition of the segment. */
  definition?: InputMaybe<Instacart_Ads_Targeting_V1_TargetingRule_Input>;
  /** Optional description of the segment. */
  description?: InputMaybe<Scalars['String']>;
  /**
   * The ID of the segment to be modified. If the segment does not exist, or if
   * the version number in this field does not match the actual most-recent
   * version number for the segment, then the request will fail.
   */
  id?: InputMaybe<Instacart_Ads_Platform_Taas_V1_SegmentId_Input>;
  /** Optional name of the segment. */
  name?: InputMaybe<Scalars['String']>;
  /** New data to be returned from TaaS when this segment matches. */
  payload?: InputMaybe<Scalars['GoogleProtobufAnyScalar']>;
};

/**
 * A set of distinct tenants of Ads Platform services. All requests should be
 * scoped to a particular universe, which may imply additional restrictions on
 * available functionality.
 */
export enum Instacart_Ads_Platform_V1_Universe {
  ScopeAds = 'SCOPE_ADS',
  ScopeAdsDiscounts = 'SCOPE_ADS_DISCOUNTS',
  ScopeCmdMarketplace = 'SCOPE_CMD_MARKETPLACE',
  ScopeCmdSfx = 'SCOPE_CMD_SFX',
  ScopeInternalTesting = 'SCOPE_INTERNAL_TESTING',
  ScopeRetailerPromotion = 'SCOPE_RETAILER_PROMOTION',
  /** Default/invalid */
  ScopeUnknown = 'SCOPE_UNKNOWN'
}

export enum Instacart_Ads_Targeting_V1_AlcoholBuyerLicense {
  /** off_premise implies that they sell alcohol for consumption off premise (eg. Bevmo) */
  AlcoholOffPremises = 'ALCOHOL_OFF_PREMISES',
  /**
   * on_and_off_premise implies that they sell alcohol for consumption both on and off premises (eg. Brewery)
   * This is a distinct license and NOT equivalent to having both an on_premise and an off_premise license.
   */
  AlcoholOnAndOffPremises = 'ALCOHOL_ON_AND_OFF_PREMISES',
  /** on_premise implies that they only sell alcohol on premise (eg. restaurant) */
  AlcoholOnPremises = 'ALCOHOL_ON_PREMISES',
  BuyerLicenseUnknown = 'BUYER_LICENSE_UNKNOWN'
}

export type Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition = {
  __typename?: 'instacart_ads_targeting_v1_AlcoholBuyerLicenseCondition';
  /**
   * This represents the Alcohol license for a user.
   * The license is relevent only for retailers at the moment.
   * This is an OR, the licenses list is a form of A || B || C
   * For example, ALCOHOL_ON_PREMISES OR ALCOHOL_OFF_PREMISES can be combined for both cases
   * Like (eg. brewery)
   */
  licenses?: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicense>>>;
};

export type Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition_Input = {
  /**
   * This represents the Alcohol license for a user.
   * The license is relevent only for retailers at the moment.
   * This is an OR, the licenses list is a form of A || B || C
   * For example, ALCOHOL_ON_PREMISES OR ALCOHOL_OFF_PREMISES can be combined for both cases
   * Like (eg. brewery)
   */
  licenses?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicense>>>;
};

export type Instacart_Ads_Targeting_V1_Audience = {
  __typename?: 'instacart_ads_targeting_v1_Audience';
  audienceId?: Maybe<Scalars['String']>;
};

/** Condition for predefined audiences generated from ML models. */
export type Instacart_Ads_Targeting_V1_AudienceCondition = {
  __typename?: 'instacart_ads_targeting_v1_AudienceCondition';
  /** Only target users that are assigned any of the audiences. */
  audiences?: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_Audience>>>;
};

/** Condition for predefined audiences generated from ML models. */
export type Instacart_Ads_Targeting_V1_AudienceCondition_Input = {
  /** Only target users that are assigned any of the audiences. */
  audiences?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_Audience_Input>>>;
};

export type Instacart_Ads_Targeting_V1_Audience_Input = {
  audienceId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Ads_Targeting_V1_BoolCondition = {
  __typename?: 'instacart_ads_targeting_v1_BoolCondition';
  retailerDetails?: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
};

export type Instacart_Ads_Targeting_V1_BoolCondition_Input = {
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
};

/**
 * Condition that requires a user to have bought a product from one of the
 * given brands.
 */
export type Instacart_Ads_Targeting_V1_BrandIdCondition = {
  __typename?: 'instacart_ads_targeting_v1_BrandIdCondition';
  /** Brands in which purchases are considered. */
  brandIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /**
   * A single BrandIdCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two BrandIdCondition, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: Maybe<Scalars['String']>;
};

/**
 * Condition that requires a user to have bought a product from one of the
 * given brands.
 */
export type Instacart_Ads_Targeting_V1_BrandIdCondition_Input = {
  /** Brands in which purchases are considered. */
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /**
   * A single BrandIdCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two BrandIdCondition, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: InputMaybe<Scalars['String']>;
};

/**
 * Condition that requires a user to have bought a product from
 * one of the given categories.
 */
export type Instacart_Ads_Targeting_V1_CategoryCondition = {
  __typename?: 'instacart_ads_targeting_v1_CategoryCondition';
  /** Product categories in which purchases are considered. */
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single CategoryCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two CategoryConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: Maybe<Scalars['String']>;
};

/**
 * Condition that requires a user to have bought a product from
 * one of the given categories.
 */
export type Instacart_Ads_Targeting_V1_CategoryCondition_Input = {
  /** Product categories in which purchases are considered. */
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single CategoryCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two CategoryConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Ads_Targeting_V1_ComparisonOperator {
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  Unknown = 'UNKNOWN'
}

export enum Instacart_Ads_Targeting_V1_CrossRetailerReducer {
  Max = 'MAX',
  Min = 'MIN',
  None = 'NONE',
  Sum = 'SUM'
}

export type Instacart_Ads_Targeting_V1_EmailCsvCondition = {
  __typename?: 'instacart_ads_targeting_v1_EmailCsvCondition';
  /** This represents the name of the uploaded csv file. */
  emailCsvName?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  whitelabelId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_EmailCsvCondition_Input = {
  /** This represents the name of the uploaded csv file. */
  emailCsvName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  whitelabelId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_FloatCondition = {
  __typename?: 'instacart_ads_targeting_v1_FloatCondition';
  crossRetailerReducer?: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator?: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  threshold?: Maybe<Scalars['Float']>;
};

export type Instacart_Ads_Targeting_V1_FloatCondition_Input = {
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  threshold?: InputMaybe<Scalars['Float']>;
};

/** For geo targeting. */
export type Instacart_Ads_Targeting_V1_Geo = {
  __typename?: 'instacart_ads_targeting_v1_Geo';
  /** Currently ignored. All Geo matching elements must specify a state. */
  countryCode?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
};

/** Condition for geo check. */
export type Instacart_Ads_Targeting_V1_GeoCondition = {
  __typename?: 'instacart_ads_targeting_v1_GeoCondition';
  /** Only target users in any of the regions. */
  geo?: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_Geo>>>;
};

/** Condition for geo check. */
export type Instacart_Ads_Targeting_V1_GeoCondition_Input = {
  /** Only target users in any of the regions. */
  geo?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_Geo_Input>>>;
};

/** For geo targeting. */
export type Instacart_Ads_Targeting_V1_Geo_Input = {
  /** Currently ignored. All Geo matching elements must specify a state. */
  countryCode?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type Instacart_Ads_Targeting_V1_IntCondition = {
  __typename?: 'instacart_ads_targeting_v1_IntCondition';
  crossRetailerReducer?: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  mapKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  operator?: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  threshold?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_IntCondition_Input = {
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  mapKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  threshold?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_Region = {
  __typename?: 'instacart_ads_targeting_v1_Region';
  regionCode?: Maybe<Instacart_Ads_Targeting_V1_RegionCode>;
};

export enum Instacart_Ads_Targeting_V1_RegionCode {
  AlcoholEast = 'ALCOHOL_EAST',
  AlcoholEastNorthCentral = 'ALCOHOL_EAST_NORTH_CENTRAL',
  /** alcohol advertiser region codes */
  AlcoholWest = 'ALCOHOL_WEST',
  AlcoholWestNorthCentral = 'ALCOHOL_WEST_NORTH_CENTRAL',
  CcConsolidated = 'CC_CONSOLIDATED',
  CcHeartland = 'CC_HEARTLAND',
  CcLiberty = 'CC_LIBERTY',
  CcReyes = 'CC_REYES',
  CcSouthwest = 'CC_SOUTHWEST',
  /** Coca Cola region codes */
  CcSwire = 'CC_SWIRE',
  DefaultEastNorthCentral = 'DEFAULT_EAST_NORTH_CENTRAL',
  DefaultMiddleAtlantic = 'DEFAULT_MIDDLE_ATLANTIC',
  DefaultMidwest = 'DEFAULT_MIDWEST',
  DefaultMountain = 'DEFAULT_MOUNTAIN',
  DefaultNewEngland = 'DEFAULT_NEW_ENGLAND',
  /** default region codes */
  DefaultPacific = 'DEFAULT_PACIFIC',
  DefaultSouthAtlantic = 'DEFAULT_SOUTH_ATLANTIC',
  DefaultSouthCentral = 'DEFAULT_SOUTH_CENTRAL',
  DefaultWestNorthCentral = 'DEFAULT_WEST_NORTH_CENTRAL',
  UnknownRegion = 'UNKNOWN_REGION'
}

export type Instacart_Ads_Targeting_V1_RegionCondition = {
  __typename?: 'instacart_ads_targeting_v1_RegionCondition';
  /** Create by mistake. Use regions instead. */
  region?: Maybe<Instacart_Ads_Targeting_V1_Region>;
  regions?: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_Region>>>;
};

export type Instacart_Ads_Targeting_V1_RegionCondition_Input = {
  /** Create by mistake. Use regions instead. */
  region?: InputMaybe<Instacart_Ads_Targeting_V1_Region_Input>;
  regions?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_Region_Input>>>;
};

export type Instacart_Ads_Targeting_V1_Region_Input = {
  regionCode?: InputMaybe<Instacart_Ads_Targeting_V1_RegionCode>;
};

export type Instacart_Ads_Targeting_V1_RetailerDetails = {
  __typename?: 'instacart_ads_targeting_v1_RetailerDetails';
  orderSource?: Maybe<Scalars['String']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context. This allows one rule to be reused across contexts, but
   * be evaluated with context-specific data.
   */
  retailerFromContext?: Maybe<Scalars['google_protobuf_Empty']>;
  retailerId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_RetailerDetails_Input = {
  orderSource?: InputMaybe<Scalars['String']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context. This allows one rule to be reused across contexts, but
   * be evaluated with context-specific data.
   */
  retailerFromContext?: InputMaybe<Scalars['google_protobuf_Empty_Input']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export enum Instacart_Ads_Targeting_V1_SetComparisonOperator {
  /** Elements must exactly match between target and feature sets. */
  EqualElements = 'EQUAL_ELEMENTS',
  /** At least one element is common between target and feature sets. */
  NonEmptyIntersection = 'NON_EMPTY_INTERSECTION',
  UnknownSetOperator = 'UNKNOWN_SET_OPERATOR'
}

export type Instacart_Ads_Targeting_V1_StringCondition = {
  __typename?: 'instacart_ads_targeting_v1_StringCondition';
  isRegex?: Maybe<Scalars['Boolean']>;
  retailerDetails?: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  /** For matching against a single target string. */
  targetValue?: Maybe<Scalars['String']>;
  /** For matching against any of multiple target strings. */
  targetValues?: Maybe<Instacart_Ads_Targeting_V1_StringTargetValues>;
};

export type Instacart_Ads_Targeting_V1_StringCondition_Input = {
  isRegex?: InputMaybe<Scalars['Boolean']>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  /** For matching against a single target string. */
  targetValue?: InputMaybe<Scalars['String']>;
  /** For matching against any of multiple target strings. */
  targetValues?: InputMaybe<Instacart_Ads_Targeting_V1_StringTargetValues_Input>;
};

/** This condition compares two lists of strings with one of the available operators */
export type Instacart_Ads_Targeting_V1_StringListCondition = {
  __typename?: 'instacart_ads_targeting_v1_StringListCondition';
  isCaseInsensitive?: Maybe<Scalars['Boolean']>;
  /** Operator defaults to NON_EMPTY_INTERSECTION for backward compatibility */
  operator?: Maybe<Instacart_Ads_Targeting_V1_SetComparisonOperator>;
  retailerDetails?: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  targetValues?: Maybe<Scalars['JSON']>;
};

/** This condition compares two lists of strings with one of the available operators */
export type Instacart_Ads_Targeting_V1_StringListCondition_Input = {
  isCaseInsensitive?: InputMaybe<Scalars['Boolean']>;
  /** Operator defaults to NON_EMPTY_INTERSECTION for backward compatibility */
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_SetComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  targetValues?: InputMaybe<Scalars['JSON']>;
};

export type Instacart_Ads_Targeting_V1_StringTargetValues = {
  __typename?: 'instacart_ads_targeting_v1_StringTargetValues';
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Ads_Targeting_V1_StringTargetValues_Input = {
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * A TargetingRule is a Boolean-logic rule over features of a user or serving
 * context. Rules are stored with creatives/adgroups. At serving time, each
 * creative is eligible to participate in an auction only if its TargetingRule
 * is satisfied by that auction's context.
 *
 * A rule is defined in disjunctive-normal form (an OR of ANDs). (Note,
 * however, that individual rule elements may themselves implicitly be
 * disjunctions - for instance, by specifying a list of product categories on
 * which to match behavior.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule = {
  __typename?: 'instacart_ads_targeting_v1_TargetingRule';
  /**
   * If any of the conjunctions in this list is satisfied, then the rule is
   * satisfied.
   *
   * As a special case, if this list is empty, then the rule is also satisfied.
   * That means that a rule with no conjunctions is semantically equivalent to
   * a rule with a single, empty conjunction.
   */
  conjunctions?: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_TargetingRule_Conjunction>>>;
};

export type Instacart_Ads_Targeting_V1_TargetingRuleElement = {
  __typename?: 'instacart_ads_targeting_v1_TargetingRuleElement';
  alcoholBuyerLicenseCondition?: Maybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition>;
  /**
   * Special value for conditions that are always true. For system-internal
   * use only (e.g., as a simplification of A OR !A); should not be set
   * normally. If 'not' is set, then this element should always evaulate to
   * false.
   */
  alwaysTrue?: Maybe<Scalars['google_protobuf_Empty']>;
  audience?: Maybe<Instacart_Ads_Targeting_V1_AudienceCondition>;
  boolCondition?: Maybe<Instacart_Ads_Targeting_V1_BoolCondition>;
  brandId?: Maybe<Instacart_Ads_Targeting_V1_BrandIdCondition>;
  brandIdInterest?: Maybe<Instacart_Ads_Targeting_V1_BrandIdCondition>;
  category?: Maybe<Instacart_Ads_Targeting_V1_CategoryCondition>;
  categoryInterest?: Maybe<Instacart_Ads_Targeting_V1_CategoryCondition>;
  emailCsvCondition?: Maybe<Instacart_Ads_Targeting_V1_EmailCsvCondition>;
  /** The feature dependency for a given targeting rule. */
  feature?: Maybe<Scalars['String']>;
  floatCondition?: Maybe<Instacart_Ads_Targeting_V1_FloatCondition>;
  geo?: Maybe<Instacart_Ads_Targeting_V1_GeoCondition>;
  /** These conditions are used for implementing generic use cases and should be used by retailers to set up all taas segments */
  intCondition?: Maybe<Instacart_Ads_Targeting_V1_IntCondition>;
  newToInstacart?: Maybe<Scalars['instacart_ads_targeting_v1_NewToInstacartCondition']>;
  /**
   * Logical NOT: inverts the condition to match users/contexts where the
   * condition is otherwise not true.
   */
  not?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Instacart_Ads_Targeting_V1_RegionCondition>;
  stringCondition?: Maybe<Instacart_Ads_Targeting_V1_StringCondition>;
  stringListCondition?: Maybe<Instacart_Ads_Targeting_V1_StringListCondition>;
  timeCondition?: Maybe<Instacart_Ads_Targeting_V1_TimeCondition>;
  timeIntervalCondition?: Maybe<Instacart_Ads_Targeting_V1_TimeIntervalCondition>;
  upc?: Maybe<Instacart_Ads_Targeting_V1_UpcCondition>;
  upcInterest?: Maybe<Instacart_Ads_Targeting_V1_UpcCondition>;
  userOrdersCount?: Maybe<Instacart_Ads_Targeting_V1_UserOrdersCountCondition>;
};

export type Instacart_Ads_Targeting_V1_TargetingRuleElement_Input = {
  alcoholBuyerLicenseCondition?: InputMaybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition_Input>;
  /**
   * Special value for conditions that are always true. For system-internal
   * use only (e.g., as a simplification of A OR !A); should not be set
   * normally. If 'not' is set, then this element should always evaulate to
   * false.
   */
  alwaysTrue?: InputMaybe<Scalars['google_protobuf_Empty_Input']>;
  audience?: InputMaybe<Instacart_Ads_Targeting_V1_AudienceCondition_Input>;
  boolCondition?: InputMaybe<Instacart_Ads_Targeting_V1_BoolCondition_Input>;
  brandId?: InputMaybe<Instacart_Ads_Targeting_V1_BrandIdCondition_Input>;
  brandIdInterest?: InputMaybe<Instacart_Ads_Targeting_V1_BrandIdCondition_Input>;
  category?: InputMaybe<Instacart_Ads_Targeting_V1_CategoryCondition_Input>;
  categoryInterest?: InputMaybe<Instacart_Ads_Targeting_V1_CategoryCondition_Input>;
  emailCsvCondition?: InputMaybe<Instacart_Ads_Targeting_V1_EmailCsvCondition_Input>;
  /** The feature dependency for a given targeting rule. */
  feature?: InputMaybe<Scalars['String']>;
  floatCondition?: InputMaybe<Instacart_Ads_Targeting_V1_FloatCondition_Input>;
  geo?: InputMaybe<Instacart_Ads_Targeting_V1_GeoCondition_Input>;
  /** These conditions are used for implementing generic use cases and should be used by retailers to set up all taas segments */
  intCondition?: InputMaybe<Instacart_Ads_Targeting_V1_IntCondition_Input>;
  newToInstacart?: InputMaybe<Scalars['instacart_ads_targeting_v1_NewToInstacartCondition_Input']>;
  /**
   * Logical NOT: inverts the condition to match users/contexts where the
   * condition is otherwise not true.
   */
  not?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Instacart_Ads_Targeting_V1_RegionCondition_Input>;
  stringCondition?: InputMaybe<Instacart_Ads_Targeting_V1_StringCondition_Input>;
  stringListCondition?: InputMaybe<Instacart_Ads_Targeting_V1_StringListCondition_Input>;
  timeCondition?: InputMaybe<Instacart_Ads_Targeting_V1_TimeCondition_Input>;
  timeIntervalCondition?: InputMaybe<Instacart_Ads_Targeting_V1_TimeIntervalCondition_Input>;
  upc?: InputMaybe<Instacart_Ads_Targeting_V1_UpcCondition_Input>;
  upcInterest?: InputMaybe<Instacart_Ads_Targeting_V1_UpcCondition_Input>;
  userOrdersCount?: InputMaybe<Instacart_Ads_Targeting_V1_UserOrdersCountCondition_Input>;
};

/**
 * Within a single conjunction, /all/ elements must be satisfied in order for
 * the conjunction to be satisfied.
 *
 * As a special case, if the list of elements is empty, then the conjunction
 * is also satisfied. (Another way of looking at this: every conjunction
 * implicitly includes literal TRUE.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule_Conjunction = {
  __typename?: 'instacart_ads_targeting_v1_TargetingRule_Conjunction';
  elements?: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_TargetingRuleElement>>>;
};

/**
 * Within a single conjunction, /all/ elements must be satisfied in order for
 * the conjunction to be satisfied.
 *
 * As a special case, if the list of elements is empty, then the conjunction
 * is also satisfied. (Another way of looking at this: every conjunction
 * implicitly includes literal TRUE.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule_Conjunction_Input = {
  elements?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_TargetingRuleElement_Input>>>;
};

/**
 * A TargetingRule is a Boolean-logic rule over features of a user or serving
 * context. Rules are stored with creatives/adgroups. At serving time, each
 * creative is eligible to participate in an auction only if its TargetingRule
 * is satisfied by that auction's context.
 *
 * A rule is defined in disjunctive-normal form (an OR of ANDs). (Note,
 * however, that individual rule elements may themselves implicitly be
 * disjunctions - for instance, by specifying a list of product categories on
 * which to match behavior.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule_Input = {
  /**
   * If any of the conjunctions in this list is satisfied, then the rule is
   * satisfied.
   *
   * As a special case, if this list is empty, then the rule is also satisfied.
   * That means that a rule with no conjunctions is semantically equivalent to
   * a rule with a single, empty conjunction.
   */
  conjunctions?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_TargetingRule_Conjunction_Input>>>;
};

export type Instacart_Ads_Targeting_V1_TimeCondition = {
  __typename?: 'instacart_ads_targeting_v1_TimeCondition';
  crossRetailerReducer?: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator?: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  threshold?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Ads_Targeting_V1_TimeCondition_Input = {
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  threshold?: InputMaybe<Scalars['DateTime']>;
};

export type Instacart_Ads_Targeting_V1_TimeIntervalCondition = {
  __typename?: 'instacart_ads_targeting_v1_TimeIntervalCondition';
  /**
   * MAX/MIN here means we will take the max/min of the times and then compare with the time interval specified
   * We will not be taking max/min of the intervals
   */
  crossRetailerReducer?: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  mapIndices?: Maybe<Array<Maybe<Scalars['String']>>>;
  mapKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  operator?: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  timeWindow?: Maybe<Scalars['String']>;
};

export type Instacart_Ads_Targeting_V1_TimeIntervalCondition_Input = {
  /**
   * MAX/MIN here means we will take the max/min of the times and then compare with the time interval specified
   * We will not be taking max/min of the intervals
   */
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  mapIndices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mapKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  timeWindow?: InputMaybe<Scalars['String']>;
};

/** Condition for availability check. */
export type Instacart_Ads_Targeting_V1_UpcCondition = {
  __typename?: 'instacart_ads_targeting_v1_UPCCondition';
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single UPCCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * If the time window is empty, this field is for availability check.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two UPCConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: Maybe<Scalars['String']>;
  /**
   * At least 1 of the UPCs must be available at the stores
   * in which the user has shopped (or is shopping).
   */
  upcs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Condition for availability check. */
export type Instacart_Ads_Targeting_V1_UpcCondition_Input = {
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single UPCCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * If the time window is empty, this field is for availability check.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two UPCConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: InputMaybe<Scalars['String']>;
  /**
   * At least 1 of the UPCs must be available at the stores
   * in which the user has shopped (or is shopping).
   */
  upcs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Ads_Targeting_V1_UserOrdersCountCondition = {
  __typename?: 'instacart_ads_targeting_v1_UserOrdersCountCondition';
  /**
   * If set, the condition considers all completed orders.
   *
   * Valid only if order_source == "marketplace"
   */
  includeAllOrders?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  orderSource?: Maybe<Scalars['String']>;
  orderThreshold?: Maybe<Scalars['BigInt']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context.
   */
  retailerFromContext?: Maybe<Scalars['google_protobuf_Empty']>;
  /** If set, the condition applies to only the orders from the given retailer. */
  retailerId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_UserOrdersCountCondition_Input = {
  /**
   * If set, the condition considers all completed orders.
   *
   * Valid only if order_source == "marketplace"
   */
  includeAllOrders?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  orderSource?: InputMaybe<Scalars['String']>;
  orderThreshold?: InputMaybe<Scalars['BigInt']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context.
   */
  retailerFromContext?: InputMaybe<Scalars['google_protobuf_Empty_Input']>;
  /** If set, the condition applies to only the orders from the given retailer. */
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Ads_V3_BrandNameMapping = {
  __typename?: 'instacart_ads_v3_BrandNameMapping';
  brandName?: Maybe<Scalars['String']>;
  brandProfileId?: Maybe<Scalars['String']>;
};

export type Instacart_Ads_V3_DisplayPreviewData = {
  __typename?: 'instacart_ads_v3_DisplayPreviewData';
  accountName?: Maybe<Scalars['String']>;
  adGroupUuid?: Maybe<Scalars['String']>;
  bannerPreview?: Maybe<Scalars['String']>;
  brandPageSlug?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['String']>;
  productIds?: Maybe<Scalars['JSON']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  upcs?: Maybe<Scalars['JSON']>;
};

/** Request message to get display preview data */
export type Instacart_Ads_V3_GetDisplayPreviewDataRequest_Input = {
  pageCriteria?: InputMaybe<Instacart_Ads_V3_PageCriteria_Input>;
  query?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sortCriteria?: InputMaybe<Instacart_Ads_V3_SortCriteria_Input>;
};

/** Response message for display preview data */
export type Instacart_Ads_V3_GetDisplayPreviewDataResponse = {
  __typename?: 'instacart_ads_v3_GetDisplayPreviewDataResponse';
  displayPreviewData?: Maybe<Array<Maybe<Instacart_Ads_V3_DisplayPreviewData>>>;
  pagination?: Maybe<Instacart_Ads_V3_Pagination>;
};

/** Request message to get the ID of Ads blocked by the retailer */
export type Instacart_Ads_V3_GetRetailerBlockedAdIdsRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

/** Response message to get the ID of Ads blocked by the retailer */
export type Instacart_Ads_V3_GetRetailerBlockedAdIdsResponse = {
  __typename?: 'instacart_ads_v3_GetRetailerBlockedAdIdsResponse';
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Ads_V3_IndexBrandNamesRequest_Input = {
  isDebug?: InputMaybe<Scalars['Boolean']>;
  superCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Ads_V3_IndexBrandNamesResponse = {
  __typename?: 'instacart_ads_v3_IndexBrandNamesResponse';
  brandNameMappings?: Maybe<Array<Maybe<Instacart_Ads_V3_BrandNameMapping>>>;
  /** This message is in path to deprecation, since maps do not support ordering */
  brandProfileIdToName?: Maybe<Scalars['JSON']>;
};

export type Instacart_Ads_V3_PageCriteria_Input = {
  itemsPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type Instacart_Ads_V3_Pagination = {
  __typename?: 'instacart_ads_v3_Pagination';
  currentPage?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Instacart_Ads_V3_ProductAdditionalInfoRequest_Input = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Ads_V3_ProductAdditionalInfoResponse = {
  __typename?: 'instacart_ads_v3_ProductAdditionalInfoResponse';
  details?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Scalars['String']>;
};

/** Request message to block/unblock an Ad */
export type Instacart_Ads_V3_RetailerBlockAdRequest_Input = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

/** Response message to block/unblock an Ad */
export type Instacart_Ads_V3_RetailerBlockAdResponse = {
  __typename?: 'instacart_ads_v3_RetailerBlockAdResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Ads_V3_SortCriteria_Input = {
  direction?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<Scalars['String']>;
};

/** A config key and metadata about the config option */
export type Instacart_Caper_Admin_V1_ConfigKey = {
  __typename?: 'instacart_caper_admin_v1_ConfigKey';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Instacart_Caper_Admin_V1_ConfigKey_Type>;
};

export enum Instacart_Caper_Admin_V1_ConfigKey_Type {
  TypeInt = 'TYPE_INT',
  TypeString = 'TYPE_STRING',
  TypeUnknown = 'TYPE_UNKNOWN'
}

/** Configs to remove or update/add for a specified entity */
export type Instacart_Caper_Admin_V1_ConfigUpdate_Input = {
  /** Currently unsupported */
  cartId?: InputMaybe<Scalars['BigInt']>;
  /**
   * To make a config use the default value hardcoded on the Android side
   * you must unset the config in the database by providing the key name in
   * the following field
   */
  configsKeysToUnset?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  configsToUpdate?: InputMaybe<Array<InputMaybe<Instacart_Caper_Admin_V1_ConfigValue_Input>>>;
  /** Currently unsupported */
  partnerId?: InputMaybe<Scalars['BigInt']>;
  /** Currently unsupported */
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeId?: InputMaybe<Scalars['BigInt']>;
};

/** Name and value pair for a configuration */
export type Instacart_Caper_Admin_V1_ConfigValue = {
  __typename?: 'instacart_caper_admin_v1_ConfigValue';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Name and value pair for a configuration */
export type Instacart_Caper_Admin_V1_ConfigValue_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** A Caper device, aka a cart. This is the lowest level of the hierarchy */
export type Instacart_Caper_Admin_V1_Device = {
  __typename?: 'instacart_caper_admin_v1_Device';
  configs?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_ConfigValue>>>;
  /** Attributes of a store, separate from configs */
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};

/** All Caper entities, starting with partner, and global level configs */
export type Instacart_Caper_Admin_V1_GetAllEntitiesResponse = {
  __typename?: 'instacart_caper_admin_v1_GetAllEntitiesResponse';
  /**
   * These are global configs that should apply to all devices that do not have
   * lower level overrides
   */
  configs?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_ConfigValue>>>;
  partners?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_Partner>>>;
};

/** The list of available config keys that the frontend should allow to be set */
export type Instacart_Caper_Admin_V1_GetConfigKeysResponse = {
  __typename?: 'instacart_caper_admin_v1_GetConfigKeysResponse';
  configKeys?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_ConfigKey>>>;
};

/**
 * A Caper partner, the highest level of the Caper hierarchy.
 * Example is Wakefern, Kroger, Schnucks
 */
export type Instacart_Caper_Admin_V1_Partner = {
  __typename?: 'instacart_caper_admin_v1_Partner';
  configs?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_ConfigValue>>>;
  /** Attributes of a partner, separate from configs */
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  retailers?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_Retailer>>>;
};

/** A Caper retailer, such as Shoprite, Kroger, QFC. Same thing as a Banner */
export type Instacart_Caper_Admin_V1_Retailer = {
  __typename?: 'instacart_caper_admin_v1_Retailer';
  configs?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_ConfigValue>>>;
  /** Attributes of a retailer, separate from configs */
  id?: Maybe<Scalars['BigInt']>;
  instacartWarehouseId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_Store>>>;
};

/** A Caper store, a specific physical location of a store under a retailer */
export type Instacart_Caper_Admin_V1_Store = {
  __typename?: 'instacart_caper_admin_v1_Store';
  city?: Maybe<Scalars['String']>;
  configs?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_ConfigValue>>>;
  devices?: Maybe<Array<Maybe<Instacart_Caper_Admin_V1_Device>>>;
  /** Attributes of a store, separate from configs */
  id?: Maybe<Scalars['BigInt']>;
  instacartWarehouseLocationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Requested changes to configs */
export type Instacart_Caper_Admin_V1_UpdateConfigRequest_Input = {
  updates?: InputMaybe<Array<InputMaybe<Instacart_Caper_Admin_V1_ConfigUpdate_Input>>>;
};

/** AuditFinding object */
export type Instacart_Cashflow_Nutella_V1_AuditFinding = {
  __typename?: 'instacart_cashflow_nutella_v1_AuditFinding';
  finding?: Maybe<Scalars['JSON']>;
  uuid?: Maybe<Scalars['String']>;
};

/** AuditItem object */
export type Instacart_Cashflow_Nutella_V1_AuditItem = {
  __typename?: 'instacart_cashflow_nutella_v1_AuditItem';
  auditFindings?: Maybe<Array<Maybe<Instacart_Cashflow_Nutella_V1_AuditFinding>>>;
  data?: Maybe<Scalars['JSON']>;
  uuid?: Maybe<Scalars['String']>;
};

/** AuditItemFindings object */
export type Instacart_Cashflow_Nutella_V1_AuditItemFindings_Input = {
  auditItemUuid?: InputMaybe<Scalars['String']>;
  findings?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
};

/** AuditReview object */
export type Instacart_Cashflow_Nutella_V1_AuditReview = {
  __typename?: 'instacart_cashflow_nutella_v1_AuditReview';
  auditItems?: Maybe<Array<Maybe<Instacart_Cashflow_Nutella_V1_AuditItem>>>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isEscalation?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  workflowName?: Maybe<Scalars['String']>;
};

/** Request object for GetAuditReview API */
export type Instacart_Cashflow_Nutella_V1_GetAuditReviewRequest_Input = {
  auditReviewUuid?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
};

/** Response object for GetAuditReview API */
export type Instacart_Cashflow_Nutella_V1_GetAuditReviewResponse = {
  __typename?: 'instacart_cashflow_nutella_v1_GetAuditReviewResponse';
  auditReview?: Maybe<Instacart_Cashflow_Nutella_V1_AuditReview>;
};

/** Request object for GetNextAuditReview API */
export type Instacart_Cashflow_Nutella_V1_GetNextAuditReviewRequest_Input = {
  client?: InputMaybe<Scalars['String']>;
  isEscalation?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
  workflowName?: InputMaybe<Scalars['String']>;
};

/** Response object for GetNextAuditReview API */
export type Instacart_Cashflow_Nutella_V1_GetNextAuditReviewResponse = {
  __typename?: 'instacart_cashflow_nutella_v1_GetNextAuditReviewResponse';
  auditReview?: Maybe<Instacart_Cashflow_Nutella_V1_AuditReview>;
};

/** Request object for GetWorkflowMetadata API */
export type Instacart_Cashflow_Nutella_V1_GetWorkflowMetadataRequest_Input = {
  client?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  workflowName?: InputMaybe<Scalars['String']>;
};

/** Response object for GetWorkflowMetadata API */
export type Instacart_Cashflow_Nutella_V1_GetWorkflowMetadataResponse = {
  __typename?: 'instacart_cashflow_nutella_v1_GetWorkflowMetadataResponse';
  metadata?: Maybe<Scalars['JSON']>;
};

/** Request object for SetAuditFindings API */
export type Instacart_Cashflow_Nutella_V1_SetAuditFindingsRequest_Input = {
  auditItemFindings?: InputMaybe<Array<InputMaybe<Instacart_Cashflow_Nutella_V1_AuditItemFindings_Input>>>;
  auditReviewUuid?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
  isFinal?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** Response object for SetAuditFindings API */
export type Instacart_Cashflow_Nutella_V1_SetAuditFindingsResponse = {
  __typename?: 'instacart_cashflow_nutella_v1_SetAuditFindingsResponse';
  auditReviewUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

/** This is for auditing purposes, to record who made a change to the database. */
export type Instacart_Catalog_Admin_V1_Client_Input = {
  /**
   * Which service is requesting this change?
   * Possible values: "IPP", "CatalogAdmin", or "Storefront"
   */
  appName?: InputMaybe<Scalars['String']>;
  /** Any additional context needed for your particular API */
  contextJson?: InputMaybe<Scalars['String']>;
  /**
   * Deprecated
   * The remote user email of the user who is making the request
   * Deprecated because this potentially exposes PII and thus will not be used or stored in the future
   */
  userEmail?: InputMaybe<Scalars['String']>;
  /** The remote ID of the user who is making the request */
  userId?: InputMaybe<Scalars['String']>;
  /**
   * Deprecated
   * The remote user name of the user who is making the request
   * Deprecated because this potentially exposes PII and thus will not be used or stored in the future
   */
  userName?: InputMaybe<Scalars['String']>;
  /** The "type" of user ID being supplied. We currently support IPP and Instacart user types */
  userType?: InputMaybe<Scalars['String']>;
};

/** Make a request to bring back one or more items via a CSV file */
export type Instacart_Catalog_Admin_V1_ItemBringBackBatchCsvRequest_Input = {
  /** Required: Client making the request, already existing message. But we will add validations */
  client?: InputMaybe<Instacart_Catalog_Admin_V1_Client_Input>;
  /** optional. Reason why item needs to be taken down */
  createdWhy?: InputMaybe<Scalars['String']>;
  /** Required: Filestack key used to retrieve the CSV */
  fileZipKey?: InputMaybe<Scalars['String']>;
  /** Optional. stringified json. Can be used to pass any metadata specific to a client. */
  metadata?: InputMaybe<Scalars['String']>;
};

/** A uuid to keep track is sent in the response */
export type Instacart_Catalog_Admin_V1_ItemBringBackBatchCsvResponse = {
  __typename?: 'instacart_catalog_admin_v1_ItemBringBackBatchCsvResponse';
  bringBackBatchRequestUuid?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Basket_Products_Orchestrators_V1_GetBasketProductsReponse = {
  __typename?: 'instacart_customers_basket_products_orchestrators_v1_GetBasketProductsReponse';
  /** a list of BasketProduct */
  basketProducts?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct>>>;
};

export type Instacart_Customers_Basket_Products_Orchestrators_V1_GetBasketProductsRequest_Input = {
  includeComboPromotions?: InputMaybe<Scalars['Boolean']>;
  loadUnattachedCoupons?: InputMaybe<Scalars['Boolean']>;
  noUser?: InputMaybe<Scalars['instacart_customers_basket_products_orchestrators_v1_GetBasketProductsRequest_NoUser_Input']>;
  references?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_Input>>>;
  requestContext?: InputMaybe<Instacart_Customers_Shared_V1_RequestContext_Input>;
  restrictUnpurchasable?: InputMaybe<Scalars['Boolean']>;
  serviceType?: InputMaybe<Instacart_Customers_Shared_V1_ServiceType>;
  snapshottable?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_CreateBusinessProgramContentRequest_Input = {
  programContent?: InputMaybe<Instacart_Customers_Business_Types_V1_BusinessProgramContent_Input>;
};

export type Instacart_Customers_Business_Admin_V1_CreateBusinessProgramContentResponse = {
  __typename?: 'instacart_customers_business_admin_v1_CreateBusinessProgramContentResponse';
  programContent?: Maybe<Instacart_Customers_Business_Types_V1_BusinessProgramContent>;
};

export type Instacart_Customers_Business_Admin_V1_CreateBusinessProgramOfferRequest_Input = {
  businessProgramId?: InputMaybe<Scalars['BigInt']>;
  programOffer?: InputMaybe<Instacart_Customers_Business_Types_V1_ProgramOffer_Input>;
};

export type Instacart_Customers_Business_Admin_V1_CreateBusinessProgramOfferResponse = {
  __typename?: 'instacart_customers_business_admin_v1_CreateBusinessProgramOfferResponse';
  programOffer?: Maybe<Instacart_Customers_Business_Types_V1_ProgramOffer>;
};

/** Requests & Responses */
export type Instacart_Customers_Business_Admin_V1_CreateBusinessProgramRequest_Input = {
  businessProgramWithoutOffers?: InputMaybe<Instacart_Customers_Business_Types_V1_BusinessProgram_Input>;
};

export type Instacart_Customers_Business_Admin_V1_CreateBusinessProgramResponse = {
  __typename?: 'instacart_customers_business_admin_v1_CreateBusinessProgramResponse';
  businessProgramWithoutOffers?: Maybe<Instacart_Customers_Business_Types_V1_BusinessProgram>;
};

export type Instacart_Customers_Business_Admin_V1_DeleteBusinessProgramContentRequest_Input = {
  businessProgramContentId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_DeleteBusinessProgramOfferRequest_Input = {
  businessProgramOfferId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_DeleteBusinessProgramRequest_Input = {
  businessProgramId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentByProgramIdRequest_Input = {
  businessProgramId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentByProgramIdResponse = {
  __typename?: 'instacart_customers_business_admin_v1_GetBusinessProgramContentByProgramIdResponse';
  programContent?: Maybe<Array<Maybe<Instacart_Customers_Business_Types_V1_BusinessProgramContent>>>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentRequest_Input = {
  programContentId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramContentResponse = {
  __typename?: 'instacart_customers_business_admin_v1_GetBusinessProgramContentResponse';
  programContent?: Maybe<Instacart_Customers_Business_Types_V1_BusinessProgramContent>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramOfferRequest_Input = {
  businessProgramOfferId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramOfferResponse = {
  __typename?: 'instacart_customers_business_admin_v1_GetBusinessProgramOfferResponse';
  programOffer?: Maybe<Instacart_Customers_Business_Types_V1_ProgramOffer>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramOffersByProgramIdRequest_Input = {
  businessProgramId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramOffersByProgramIdResponse = {
  __typename?: 'instacart_customers_business_admin_v1_GetBusinessProgramOffersByProgramIdResponse';
  programOffers?: Maybe<Array<Maybe<Instacart_Customers_Business_Types_V1_ProgramOffer>>>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramRequest_Input = {
  businessProgramId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Business_Admin_V1_GetBusinessProgramResponse = {
  __typename?: 'instacart_customers_business_admin_v1_GetBusinessProgramResponse';
  businessProgram?: Maybe<Instacart_Customers_Business_Types_V1_BusinessProgram>;
};

export type Instacart_Customers_Business_Admin_V1_ListBusinessProgramRequest_Input = {
  orderBy?: InputMaybe<Instacart_Customers_Business_Admin_V1_ListBusinessProgramRequest_ListBusinessProgramRequestOrderBy>;
  pagination?: InputMaybe<Instacart_Customers_Shared_V1_Pagination_Input>;
};

export enum Instacart_Customers_Business_Admin_V1_ListBusinessProgramRequest_ListBusinessProgramRequestOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  OrderByUnspecified = 'ORDER_BY_UNSPECIFIED'
}

export type Instacart_Customers_Business_Admin_V1_ListBusinessProgramResponse = {
  __typename?: 'instacart_customers_business_admin_v1_ListBusinessProgramResponse';
  businessPrograms?: Maybe<Array<Maybe<Instacart_Customers_Business_Types_V1_BusinessProgram>>>;
  pageInfo?: Maybe<Instacart_Customers_Shared_V1_PageInfo>;
};

export type Instacart_Customers_Business_Admin_V1_ListBusinessesRequest_BusinessFilterSet_Input = {
  businessMemberEmail?: InputMaybe<Scalars['String']>;
  businessMemberId?: InputMaybe<Scalars['BigInt']>;
  businessMemberUserId?: InputMaybe<Scalars['BigInt']>;
  businessType?: InputMaybe<Instacart_Customers_Business_Types_V1_BusinessType>;
};

export enum Instacart_Customers_Business_Admin_V1_ListBusinessesRequest_BusinessOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  OrderByUnspecified = 'ORDER_BY_UNSPECIFIED'
}

export type Instacart_Customers_Business_Admin_V1_ListBusinessesRequest_Input = {
  filters?: InputMaybe<Instacart_Customers_Business_Admin_V1_ListBusinessesRequest_BusinessFilterSet_Input>;
  orderBy?: InputMaybe<Instacart_Customers_Business_Admin_V1_ListBusinessesRequest_BusinessOrderBy>;
  pagination?: InputMaybe<Instacart_Customers_Shared_V1_Pagination_Input>;
};

export type Instacart_Customers_Business_Admin_V1_ListBusinessesResponse = {
  __typename?: 'instacart_customers_business_admin_v1_ListBusinessesResponse';
  businesses?: Maybe<Array<Maybe<Instacart_Customers_Business_Types_V1_Business>>>;
  pageInfo?: Maybe<Instacart_Customers_Shared_V1_PageInfo>;
};

export type Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramContentRequest_Input = {
  programContent?: InputMaybe<Instacart_Customers_Business_Types_V1_BusinessProgramContent_Input>;
};

export type Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramContentResponse = {
  __typename?: 'instacart_customers_business_admin_v1_UpdateBusinessProgramContentResponse';
  programContent?: Maybe<Instacart_Customers_Business_Types_V1_BusinessProgramContent>;
};

export type Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramOfferRequest_Input = {
  programOffer?: InputMaybe<Instacart_Customers_Business_Types_V1_ProgramOffer_Input>;
};

export type Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramOfferResponse = {
  __typename?: 'instacart_customers_business_admin_v1_UpdateBusinessProgramOfferResponse';
  programOffer?: Maybe<Instacart_Customers_Business_Types_V1_ProgramOffer>;
};

export type Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramRequest_Input = {
  businessProgramWithoutOffers?: InputMaybe<Instacart_Customers_Business_Types_V1_BusinessProgram_Input>;
};

export type Instacart_Customers_Business_Admin_V1_UpdateBusinessProgramResponse = {
  __typename?: 'instacart_customers_business_admin_v1_UpdateBusinessProgramResponse';
  businessProgramWithoutOffers?: Maybe<Instacart_Customers_Business_Types_V1_BusinessProgram>;
};

export type Instacart_Customers_Business_Types_V1_Business = {
  __typename?: 'instacart_customers_business_types_v1_Business';
  businessCategory?: Maybe<Scalars['String']>;
  businessType?: Maybe<Instacart_Customers_Business_Types_V1_BusinessType>;
  countryId?: Maybe<Scalars['BigInt']>;
  createdAtUtc?: Maybe<Scalars['DateTime']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  updatedAtUtc?: Maybe<Scalars['DateTime']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Business_Types_V1_BusinessProgram = {
  __typename?: 'instacart_customers_business_types_v1_BusinessProgram';
  active?: Maybe<Scalars['Boolean']>;
  businessId?: Maybe<Scalars['BigInt']>;
  createdAtUtc?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  offers?: Maybe<Array<Maybe<Instacart_Customers_Business_Types_V1_ProgramOffer>>>;
  programCode?: Maybe<Scalars['String']>;
  programType?: Maybe<Instacart_Customers_Business_Types_V1_ProgramType>;
  updatedAtUtc?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Business_Types_V1_BusinessProgramContent = {
  __typename?: 'instacart_customers_business_types_v1_BusinessProgramContent';
  availabilityCondition?: Maybe<Scalars['String']>;
  businessProgramId?: Maybe<Scalars['BigInt']>;
  cobrandedLogoAlt?: Maybe<Scalars['String']>;
  cobrandedLogoUrl?: Maybe<Scalars['String']>;
  createdAtUtc?: Maybe<Scalars['DateTime']>;
  ctaText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  locale?: Maybe<Instacart_Customers_Shared_V1_Locale>;
  offerImageUrl?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  updatedAtUtc?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Business_Types_V1_BusinessProgramContent_Input = {
  availabilityCondition?: InputMaybe<Scalars['String']>;
  businessProgramId?: InputMaybe<Scalars['BigInt']>;
  cobrandedLogoAlt?: InputMaybe<Scalars['String']>;
  cobrandedLogoUrl?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['DateTime']>;
  ctaText?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  locale?: InputMaybe<Instacart_Customers_Shared_V1_Locale>;
  offerImageUrl?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
  updatedAtUtc?: InputMaybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Business_Types_V1_BusinessProgram_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  businessId?: InputMaybe<Scalars['BigInt']>;
  createdAtUtc?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  offers?: InputMaybe<Array<InputMaybe<Instacart_Customers_Business_Types_V1_ProgramOffer_Input>>>;
  programCode?: InputMaybe<Scalars['String']>;
  programType?: InputMaybe<Instacart_Customers_Business_Types_V1_ProgramType>;
  updatedAtUtc?: InputMaybe<Scalars['DateTime']>;
};

export enum Instacart_Customers_Business_Types_V1_BusinessType {
  /** Unspecified business type; accounts for protobuf enum defaults on the wire. */
  BusinessTypeUnspecified = 'BUSINESS_TYPE_UNSPECIFIED',
  /** Legacy business type; do not use. This stores old businesses we launched via a form. */
  Legacy = 'LEGACY',
  /** Employees of the same business organization */
  OrganizationBasic = 'ORGANIZATION_BASIC',
  /** Business Program that groups a set of Business users together, but not yet tied to the same business organization */
  Program = 'PROGRAM'
}

export type Instacart_Customers_Business_Types_V1_ProgramOffer = {
  __typename?: 'instacart_customers_business_types_v1_ProgramOffer';
  active?: Maybe<Scalars['Boolean']>;
  countryId?: Maybe<Scalars['BigInt']>;
  couponCode?: Maybe<Scalars['String']>;
  createdAtUtc?: Maybe<Scalars['DateTime']>;
  existingUsersEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  updatedAtUtc?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Business_Types_V1_ProgramOffer_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  countryId?: InputMaybe<Scalars['BigInt']>;
  couponCode?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['DateTime']>;
  existingUsersEnabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAtUtc?: InputMaybe<Scalars['DateTime']>;
};

export enum Instacart_Customers_Business_Types_V1_ProgramType {
  /** Business Program that is applicable to a business organization */
  Business = 'BUSINESS',
  /** BUsiness program that is applicable to a group of business users */
  Partnership = 'PARTNERSHIP',
  /** Unspecified program type; accounts for protobuf enum defaults on the wire. */
  ProgramTypeUnspecified = 'PROGRAM_TYPE_UNSPECIFIED'
}

export type Instacart_Customers_Campaigns_V1_BulkUpdateCampaignSlotRankingRequest_Input = {
  list?: InputMaybe<Array<InputMaybe<Instacart_Customers_Campaigns_V1_UpdateCampaignSlotRankingParameters_Input>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_BulkUpdateCampaignSlotRankingResponse = {
  __typename?: 'instacart_customers_campaigns_v1_BulkUpdateCampaignSlotRankingResponse';
  failedSlots?: Maybe<Array<Maybe<Instacart_Customers_Campaigns_V1_UpdateCampaignSlotRankingParameters>>>;
  updatedSlots?: Maybe<Array<Maybe<Instacart_Customers_Campaigns_V1_Slot>>>;
};

export type Instacart_Customers_Campaigns_V1_CsvProcessingCount = {
  __typename?: 'instacart_customers_campaigns_v1_CsvProcessingCount';
  errorCount?: Maybe<Scalars['BigInt']>;
  fileName?: Maybe<Scalars['String']>;
  isProcessed?: Maybe<Scalars['Boolean']>;
  successCount?: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Customers_Campaigns_V1_CsvReportType {
  Errors = 'ERRORS',
  Processed = 'PROCESSED',
  Results = 'RESULTS',
  Unknown = 'UNKNOWN'
}

export type Instacart_Customers_Campaigns_V1_GetBucketDownloadUrlRequest_Input = {
  fileName?: InputMaybe<Scalars['String']>;
  reportType?: InputMaybe<Instacart_Customers_Campaigns_V1_CsvReportType>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_GetBucketDownloadUrlResponse = {
  __typename?: 'instacart_customers_campaigns_v1_GetBucketDownloadUrlResponse';
  downloadUrl?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Campaigns_V1_GetBucketUploadUrlRequest_Input = {
  fileName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  /** if dynamic csv feature is being used */
  useDynamicCsvUploadBucket?: InputMaybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Campaigns_V1_GetBucketUploadUrlResponse = {
  __typename?: 'instacart_customers_campaigns_v1_GetBucketUploadUrlResponse';
  expiresAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Campaigns_V1_GetCsvFoundEntriesCountRequest_Input = {
  fileNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_GetCsvFoundEntriesCountResponse = {
  __typename?: 'instacart_customers_campaigns_v1_GetCsvFoundEntriesCountResponse';
  fileCounts?: Maybe<Array<Maybe<Instacart_Customers_Campaigns_V1_CsvProcessingCount>>>;
};

export type Instacart_Customers_Campaigns_V1_GetCsvProcessingSummaryRequest_Input = {
  fileName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_GetCsvProcessingSummaryResponse = {
  __typename?: 'instacart_customers_campaigns_v1_GetCsvProcessingSummaryResponse';
  duplicateCount?: Maybe<Scalars['BigInt']>;
  incorrectFormatCount?: Maybe<Scalars['BigInt']>;
  isProcessed?: Maybe<Scalars['Boolean']>;
  matchedCount?: Maybe<Scalars['BigInt']>;
  unmatchedCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_GetGroupedSlotsRequest_Filter_Input = {
  campaignActiveDate?: InputMaybe<Scalars['DateTime']>;
  campaignName?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Campaigns_V1_GetGroupedSlotsRequest_Input = {
  filter?: InputMaybe<Instacart_Customers_Campaigns_V1_GetGroupedSlotsRequest_Filter_Input>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_GetGroupedSlotsResponse = {
  __typename?: 'instacart_customers_campaigns_v1_GetGroupedSlotsResponse';
  groupedSlots?: Maybe<Array<Maybe<Instacart_Customers_Campaigns_V1_GroupedSlot>>>;
};

export type Instacart_Customers_Campaigns_V1_GroupedSlot = {
  __typename?: 'instacart_customers_campaigns_v1_GroupedSlot';
  slotId?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Maybe<Instacart_Customers_Campaigns_V1_Slot>>>;
};

export type Instacart_Customers_Campaigns_V1_ProcessEmailCsvRequest_Input = {
  /** full path for the CSV file stored in S3 */
  csvPathKey?: InputMaybe<Scalars['String']>;
  /** email of the IPP user that uploaded the file, used to send an email once the processing is complete. */
  notifyUserEmail?: InputMaybe<Scalars['String']>;
  /** id of the IPP user that uploaded the file, used to send an email once the processing is complete. */
  notifyUserId?: InputMaybe<Scalars['BigInt']>;
  /** identifies the retailer that uploaded the file */
  retailerId?: InputMaybe<Scalars['BigInt']>;
  /** description of the taas segment being created. */
  segmentDescription?: InputMaybe<Scalars['String']>;
  /** name of the taas segment being created. */
  segmentName?: InputMaybe<Scalars['String']>;
  /** if dynamic csv feature is being used */
  useDynamicCsvUploadBucket?: InputMaybe<Scalars['Boolean']>;
  /** whitelabel_id, use 1 for marketplace */
  whitelabelId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_ProcessEmailCsvResponse = {
  __typename?: 'instacart_customers_campaigns_v1_ProcessEmailCsvResponse';
  jobId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Campaigns_V1_Slot = {
  __typename?: 'instacart_customers_campaigns_v1_Slot';
  campaignId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  rank?: Maybe<Scalars['BigInt']>;
  slotId?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Campaigns_V1_UpdateCampaignSlotRankingParameters = {
  __typename?: 'instacart_customers_campaigns_v1_UpdateCampaignSlotRankingParameters';
  rank?: Maybe<Scalars['BigInt']>;
  retailerCampaignId?: Maybe<Scalars['BigInt']>;
  slotId?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Campaigns_V1_UpdateCampaignSlotRankingParameters_Input = {
  rank?: InputMaybe<Scalars['BigInt']>;
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_AdditionalMetadata = {
  __typename?: 'instacart_customers_commerce_discount_v1_AdditionalMetadata';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_AdditionalMetadata_Input = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInCoupon = {
  __typename?: 'instacart_customers_commerce_discount_v1_AutoAppliedCreditBackInCoupon';
  autoAppliedCreditBackExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  creditCouponDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails>;
  creditCouponExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  creditCouponIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
};

export type Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInCoupon_Input = {
  autoAppliedCreditBackExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  creditCouponDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails_Input>;
  creditCouponExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  creditCouponIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
};

export type Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInDigitalWallet = {
  __typename?: 'instacart_customers_commerce_discount_v1_AutoAppliedCreditBackInDigitalWallet';
  autoAppliedCreditBackExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  digitalWalletDepositDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails>;
  digitalWalletDepositIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
};

export type Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInDigitalWallet_Input = {
  autoAppliedCreditBackExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  digitalWalletDepositDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails_Input>;
  digitalWalletDepositIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
};

export type Instacart_Customers_Commerce_Discount_V1_AutoAppliedDiscount = {
  __typename?: 'instacart_customers_commerce_discount_v1_AutoAppliedDiscount';
  autoAppliedDiscountExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
};

export type Instacart_Customers_Commerce_Discount_V1_AutoAppliedDiscount_Input = {
  autoAppliedDiscountExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_CheckoutBenefitCodeDiscount = {
  __typename?: 'instacart_customers_commerce_discount_v1_CheckoutBenefitCodeDiscount';
  checkoutBenefitCodeDiscountExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
};

export type Instacart_Customers_Commerce_Discount_V1_CheckoutBenefitCodeDiscount_Input = {
  checkoutBenefitCodeDiscountExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
};

/** Discount using Clippable Coupon */
export type Instacart_Customers_Commerce_Discount_V1_ClippableCouponBasedDiscount = {
  __typename?: 'instacart_customers_commerce_discount_v1_ClippableCouponBasedDiscount';
  couponExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  couponIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  couponIssuingFlow?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

/** Discount using Clippable Coupon */
export type Instacart_Customers_Commerce_Discount_V1_ClippableCouponBasedDiscount_Input = {
  couponExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  couponIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  couponIssuingFlow?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

/**
 * This is a generic condition which can support different types of keys.
 * For the supported keys in ConditionKey, this will be used to check if the condition is met.
 * For example, quantity greater than 2 would be defined as:
 * {
 * key = ConditionKey.QUANTITY,
 * operator : ConditionOperator.GREATER_THAN
 * value: 2
 * }
 */
export type Instacart_Customers_Commerce_Discount_V1_Condition = {
  __typename?: 'instacart_customers_commerce_discount_v1_Condition';
  key?: Maybe<Instacart_Customers_Commerce_Discount_V1_ConditionKey>;
  operator?: Maybe<Instacart_Customers_Commerce_Discount_V1_ConditionOperator>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum Instacart_Customers_Commerce_Discount_V1_ConditionKey {
  /** Condition that checks if a user is an Instacart administrator. */
  AdminUser = 'ADMIN_USER',
  ConditionKeyUnspecified = 'CONDITION_KEY_UNSPECIFIED',
  DevicePlatform = 'DEVICE_PLATFORM',
  /** Condition that checks the directed spend program code in order benefit qualifier */
  DirectedSpendProgramCode = 'DIRECTED_SPEND_PROGRAM_CODE',
  DiscountTags = 'DISCOUNT_TAGS',
  FirstNOrders = 'FIRST_N_ORDERS',
  FulfillmentType = 'FULFILLMENT_TYPE',
  GiftOrder = 'GIFT_ORDER',
  IcPlusMember = 'IC_PLUS_MEMBER',
  ItemsTotalPrice = 'ITEMS_TOTAL_PRICE',
  LoyaltyMember = 'LOYALTY_MEMBER',
  LoyaltyTier = 'LOYALTY_TIER',
  /** Condition that checks the day of the week of order creation. Valid values are Sun, Mon, Tue, Wed, Thu, Fri, Sat (case-insensitive) */
  OrderCreationDayOfWeek = 'ORDER_CREATION_DAY_OF_WEEK',
  OrderDeliveryTags = 'ORDER_DELIVERY_TAGS',
  PaymentInstrumentType = 'PAYMENT_INSTRUMENT_TYPE',
  Quantity = 'QUANTITY',
  RegularItemsPrice = 'REGULAR_ITEMS_PRICE',
  ServiceOptionType = 'SERVICE_OPTION_TYPE',
  UniqueProductsCount = 'UNIQUE_PRODUCTS_COUNT'
}

export enum Instacart_Customers_Commerce_Discount_V1_ConditionOperator {
  ConditionOperatorUnspecified = 'CONDITION_OPERATOR_UNSPECIFIED',
  ContainsAll = 'CONTAINS_ALL',
  ContainsAny = 'CONTAINS_ANY',
  Equal = 'EQUAL',
  Exclude = 'EXCLUDE',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL'
}

/**
 * This is a generic condition which can support different types of keys.
 * For the supported keys in ConditionKey, this will be used to check if the condition is met.
 * For example, quantity greater than 2 would be defined as:
 * {
 * key = ConditionKey.QUANTITY,
 * operator : ConditionOperator.GREATER_THAN
 * value: 2
 * }
 */
export type Instacart_Customers_Commerce_Discount_V1_Condition_Input = {
  key?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ConditionKey>;
  operator?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ConditionOperator>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInCoupon = {
  __typename?: 'instacart_customers_commerce_discount_v1_CouponBasedCreditBackInCoupon';
  creditBackCouponDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponDetails>;
  creditBackCouponExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  creditBackCouponIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  creditBackCouponIssuingFlow?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
  creditCouponDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails>;
  creditCouponExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
};

export type Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInCoupon_Input = {
  creditBackCouponDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponDetails_Input>;
  creditBackCouponExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  creditBackCouponIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  creditBackCouponIssuingFlow?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
  creditCouponDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails_Input>;
  creditCouponExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInDigitalWallet = {
  __typename?: 'instacart_customers_commerce_discount_v1_CouponBasedCreditBackInDigitalWallet';
  creditBackCouponDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponDetails>;
  creditBackCouponExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  creditBackCouponIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  creditBackCouponIssuingFlow?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
  digitalWalletDepositDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails>;
};

export type Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInDigitalWallet_Input = {
  creditBackCouponDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponDetails_Input>;
  creditBackCouponExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  creditBackCouponIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  creditBackCouponIssuingFlow?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
  digitalWalletDepositDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_CouponBasedDiscount = {
  __typename?: 'instacart_customers_commerce_discount_v1_CouponBasedDiscount';
  couponDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponDetails>;
  couponExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  couponIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  couponIssuingFlow?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

export type Instacart_Customers_Commerce_Discount_V1_CouponBasedDiscount_Input = {
  couponDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponDetails_Input>;
  couponExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  couponIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  couponIssuingFlow?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

/** Details of Coupon which will be granted. */
export type Instacart_Customers_Commerce_Discount_V1_CouponDetails = {
  __typename?: 'instacart_customers_commerce_discount_v1_CouponDetails';
  redeemableCouponGrant?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponGrantInfo>;
  redemptionCondition?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_Condition>>>;
};

/** Details of Coupon which will be granted. */
export type Instacart_Customers_Commerce_Discount_V1_CouponDetails_Input = {
  redeemableCouponGrant?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponGrantInfo_Input>;
  redemptionCondition?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_Condition_Input>>>;
};

/** Coupon's Grant which will be awarded/redeemed if condition satisfied */
export type Instacart_Customers_Commerce_Discount_V1_CouponGrantInfo = {
  __typename?: 'instacart_customers_commerce_discount_v1_CouponGrantInfo';
  expiresIn?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
  instanceCount?: Maybe<Scalars['BigInt']>;
};

/** Coupon's Grant which will be awarded/redeemed if condition satisfied */
export type Instacart_Customers_Commerce_Discount_V1_CouponGrantInfo_Input = {
  expiresIn?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration_Input>;
  instanceCount?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreateValueGrantPolicyRequest_Input = {
  additionalMetadata?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_AdditionalMetadata_Input>>>;
  autoAppliedDiscount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedDiscount_Input>;
  autoCreditBackInCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInCoupon_Input>;
  autoCreditBackInDigitalWallet?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInDigitalWallet_Input>;
  checkoutBenefitCodeDiscount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CheckoutBenefitCodeDiscount_Input>;
  countryId?: InputMaybe<Scalars['BigInt']>;
  creator?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_Creator_Input>;
  creditBackInCouponUsingCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInCoupon_Input>;
  creditBackInDigitalWalletUsingCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInDigitalWallet_Input>;
  creditInCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditInCoupon_Input>;
  creditInDigitalWallet?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditInDigitalWallet_Input>;
  /** any description for the policy */
  description?: InputMaybe<Scalars['String']>;
  discountCategoryId?: InputMaybe<Scalars['BigInt']>;
  discountUsingClippableCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ClippableCouponBasedDiscount_Input>;
  discountUsingCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedDiscount_Input>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** reference key of the policy in the external system */
  externalReference?: InputMaybe<Scalars['String']>;
  fundingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_FundingDetail_Input>;
  imageUrl?: InputMaybe<Scalars['String']>;
  /** name of the policy */
  name?: InputMaybe<Scalars['String']>;
  /** Any description of the policy which can be exposed to the end user */
  publicDescription?: InputMaybe<Scalars['String']>;
  /** Request id. */
  requestId?: InputMaybe<Scalars['String']>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  source?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  /** status of the policy */
  status?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyStatus>;
  storeConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  userSegments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  valueGrantCode?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreateValueGrantPolicyResponse = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreateValueGrantPolicyResponse';
  valueGrantPolicy?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy>;
};

export type Instacart_Customers_Commerce_Discount_V1_Creator = {
  __typename?: 'instacart_customers_commerce_discount_v1_Creator';
  creatorId?: Maybe<Scalars['BigInt']>;
  creatorType?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreatorType>;
};

export enum Instacart_Customers_Commerce_Discount_V1_CreatorType {
  CreatorTypeIc = 'CREATOR_TYPE_IC',
  CreatorTypeIpp = 'CREATOR_TYPE_IPP',
  CreatorTypeUnspecified = 'CREATOR_TYPE_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_Creator_Input = {
  creatorId?: InputMaybe<Scalars['BigInt']>;
  creatorType?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreatorType>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreditBackGrantCalculator = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditBackGrantCalculator';
  fixedValueCreditBackAmount?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail>;
  percentCreditBackInfo?: Maybe<Instacart_Customers_Commerce_Discount_V1_PercentGrantInfo>;
};

/** Credit in Coupon */
export type Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditCouponDetails';
  redeemableCreditCouponGrant?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponGrant>;
  redemptionCondition?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_Condition>>>;
};

/** Credit in Coupon */
export type Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails_Input = {
  redeemableCreditCouponGrant?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponGrant_Input>;
  redemptionCondition?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_Condition_Input>>>;
};

/** Credit grant in form of Coupon */
export type Instacart_Customers_Commerce_Discount_V1_CreditCouponGrant = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditCouponGrant';
  credit?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditGrantInfo>;
  grant?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponGrantInfo>;
};

/** Credit grant in form of Coupon */
export type Instacart_Customers_Commerce_Discount_V1_CreditCouponGrant_Input = {
  credit?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditGrantInfo_Input>;
  grant?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponGrantInfo_Input>;
};

/** Credit in Digital wallet */
export type Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditDigitalWalletDepositDetails';
  redeemableCreditDwDepositGrant?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositGrant>;
  redemptionCondition?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_Condition>>>;
};

/** Credit in Digital wallet */
export type Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails_Input = {
  redeemableCreditDwDepositGrant?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositGrant_Input>;
  redemptionCondition?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_Condition_Input>>>;
};

/** Credit grant in form of Digital Wallet */
export type Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositGrant = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditDigitalWalletDepositGrant';
  creditDwDepositTargetDetail?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantTargetDetail>;
  expiresIn?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
  grant?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditGrantInfo>;
};

/** Credit grant in form of Digital Wallet */
export type Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositGrant_Input = {
  creditDwDepositTargetDetail?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantTargetDetail_Input>;
  expiresIn?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration_Input>;
  grant?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditGrantInfo_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreditGrantCalculator = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditGrantCalculator';
  fixedValueCreditAmount?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail>;
  percentCreditInfo?: Maybe<Instacart_Customers_Commerce_Discount_V1_PercentGrantInfo>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreditGrantCalculator_Input = {
  fixedValueCreditAmount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_Input>;
  percentCreditInfo?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_PercentGrantInfo_Input>;
};

/** Credit Grant (represent by coupon OR will store in Digital Wallet) */
export type Instacart_Customers_Commerce_Discount_V1_CreditGrantInfo = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditGrantInfo';
  grantQuantityDetail?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail>;
  grantQuantityType?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityType>;
  refundExpiresIn?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
};

/** Credit Grant (represent by coupon OR will store in Digital Wallet) */
export type Instacart_Customers_Commerce_Discount_V1_CreditGrantInfo_Input = {
  grantQuantityDetail?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_Input>;
  grantQuantityType?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityType>;
  refundExpiresIn?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreditInCoupon = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditInCoupon';
  creditCouponDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails>;
  creditCouponExercisingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails>;
  creditCouponIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  creditCouponIssuingFlow?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreditInCoupon_Input = {
  creditCouponDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditCouponDetails_Input>;
  creditCouponExercisingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input>;
  creditCouponIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  creditCouponIssuingFlow?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreditInDigitalWallet = {
  __typename?: 'instacart_customers_commerce_discount_v1_CreditInDigitalWallet';
  digitalWalletDepositDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails>;
  digitalWalletDepositIssuingAuthority?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  digitalWalletDepositIssuingFlow?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

export type Instacart_Customers_Commerce_Discount_V1_CreditInDigitalWallet_Input = {
  digitalWalletDepositDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditDigitalWalletDepositDetails_Input>;
  digitalWalletDepositIssuingAuthority?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority>;
  digitalWalletDepositIssuingFlow?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow>;
};

export type Instacart_Customers_Commerce_Discount_V1_DiscountGrantCalculator = {
  __typename?: 'instacart_customers_commerce_discount_v1_DiscountGrantCalculator';
  fixedPriceDiscount?: Maybe<Google_Type_Money>;
  fixedValueDiscountAmount?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail>;
  percentDiscountInfo?: Maybe<Instacart_Customers_Commerce_Discount_V1_PercentGrantInfo>;
};

export type Instacart_Customers_Commerce_Discount_V1_DiscountGrantCalculator_Input = {
  fixedPriceDiscount?: InputMaybe<Google_Type_Money_Input>;
  fixedValueDiscountAmount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_Input>;
  percentDiscountInfo?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_PercentGrantInfo_Input>;
};

export enum Instacart_Customers_Commerce_Discount_V1_DiscountTag {
  DiscountTagUnspecified = 'DISCOUNT_TAG_UNSPECIFIED',
  ServiceOptionDiscountGroupTagFivePercent = 'SERVICE_OPTION_DISCOUNT_GROUP_TAG_FIVE_PERCENT',
  /** tags to indicate service option discount groups */
  ServiceOptionDiscountGroupTagTwoDollar = 'SERVICE_OPTION_DISCOUNT_GROUP_TAG_TWO_DOLLAR',
  ValueMealSuperSaverFivePercentCreditBack = 'VALUE_MEAL_SUPER_SAVER_FIVE_PERCENT_CREDIT_BACK',
  ValueMealSuperSaverTenPercentCreditBack = 'VALUE_MEAL_SUPER_SAVER_TEN_PERCENT_CREDIT_BACK',
  ValueMealSuperSaverTwoPercentCreditBack = 'VALUE_MEAL_SUPER_SAVER_TWO_PERCENT_CREDIT_BACK'
}

export enum Instacart_Customers_Commerce_Discount_V1_FulfillmentType {
  Delivery = 'DELIVERY',
  FulfillmentTypeUnspecified = 'FULFILLMENT_TYPE_UNSPECIFIED',
  Pickup = 'PICKUP'
}

export type Instacart_Customers_Commerce_Discount_V1_FundingDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_FundingDetail';
  /** the amount of budget available before the offer is exhausted */
  budgetLimit?: Maybe<Google_Type_Money>;
  fundingProviderInfo?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_FundingProviderInformation>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_FundingDetail_Input = {
  /** the amount of budget available before the offer is exhausted */
  budgetLimit?: InputMaybe<Google_Type_Money_Input>;
  fundingProviderInfo?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_FundingProviderInformation_Input>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_FundingProviderInformation = {
  __typename?: 'instacart_customers_commerce_discount_v1_FundingProviderInformation';
  /** percentage of funding for discount by the source. Can range from 0-100 */
  percent?: Maybe<Scalars['BigInt']>;
  /** who is funding this part */
  source?: Maybe<Instacart_Customers_Commerce_Discount_V1_FundingSource>;
  /** name of the funding source */
  sourceName?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_FundingProviderInformation_Input = {
  /** percentage of funding for discount by the source. Can range from 0-100 */
  percent?: InputMaybe<Scalars['BigInt']>;
  /** who is funding this part */
  source?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_FundingSource>;
  /** name of the funding source */
  sourceName?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Customers_Commerce_Discount_V1_FundingSource {
  Cpg = 'CPG',
  FundingSourceUnspecified = 'FUNDING_SOURCE_UNSPECIFIED',
  Instacart = 'INSTACART',
  Others = 'OTHERS',
  Retailer = 'RETAILER'
}

export type Instacart_Customers_Commerce_Discount_V1_GetValueGrantPolicyFacadeRequest_Input = {
  valueGrantPolicyId?: InputMaybe<Scalars['BigInt']>;
  valueGrantPolicyKey?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_GetValueGrantPolicyFacadeResponse = {
  __typename?: 'instacart_customers_commerce_discount_v1_GetValueGrantPolicyFacadeResponse';
  facade?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyFacade>;
};

/** Grant/Value (i.e. coupon/credit) Exercising */
export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantExercisingDetails';
  attachmentLevel?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantAttachmentLevel>;
  conditions?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingCondition>>>;
  exercisingLimits?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingLimits>;
  reward?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward>>>;
  stackability?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingStackability>;
  storeAvailability?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingStoreAvailability>;
};

export enum Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantAttachmentLevel {
  GrantAttachmentBasketLevel = 'GRANT_ATTACHMENT_BASKET_LEVEL',
  GrantAttachmentFeeLevel = 'GRANT_ATTACHMENT_FEE_LEVEL',
  GrantAttachmentItemLevel = 'GRANT_ATTACHMENT_ITEM_LEVEL',
  GrantAttachmentLevelUnspecified = 'GRANT_ATTACHMENT_LEVEL_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingCondition = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantExercisingDetails_GrantExercisingCondition';
  allowPartialApplication?: Maybe<Scalars['Boolean']>;
  conditions?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_Condition>>>;
  groupCriteria?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_GroupCriteria>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingCondition_Input = {
  allowPartialApplication?: InputMaybe<Scalars['Boolean']>;
  conditions?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_Condition_Input>>>;
  groupCriteria?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_GroupCriteria_Input>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingLimits = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantExercisingDetails_GrantExercisingLimits';
  exercisingLimit?: Maybe<Scalars['BigInt']>;
  exercisingLimitPerOrder?: Maybe<Scalars['BigInt']>;
  exercisingLimitPerUser?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingLimits_Input = {
  exercisingLimit?: InputMaybe<Scalars['BigInt']>;
  exercisingLimitPerOrder?: InputMaybe<Scalars['BigInt']>;
  exercisingLimitPerUser?: InputMaybe<Scalars['BigInt']>;
};

export enum Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingStackability {
  /** Indicates the grant exercising is not stackable with other stackable and non-stackable grant exercising */
  GrantExercisingNonStackable = 'GRANT_EXERCISING_NON_STACKABLE',
  /** Indicates the grant exercising bypasses all stackability rules and always allowed to apply irrespective of other competing grant exercising */
  GrantExercisingPolicyAlwaysStackable = 'GRANT_EXERCISING_POLICY_ALWAYS_STACKABLE',
  GrantExercisingStackabilityUnspecified = 'GRANT_EXERCISING_STACKABILITY_UNSPECIFIED',
  /** Indicates the grant exercising is stackable with grant exercising */
  GrantExercisingStackable = 'GRANT_EXERCISING_STACKABLE'
}

export enum Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingStoreAvailability {
  GrantExercisingStoreAvailabilityInStoreOnly = 'GRANT_EXERCISING_STORE_AVAILABILITY_IN_STORE_ONLY',
  GrantExercisingStoreAvailabilityOnlineOnly = 'GRANT_EXERCISING_STORE_AVAILABILITY_ONLINE_ONLY',
  GrantExercisingStoreAvailabilityUnspecified = 'GRANT_EXERCISING_STORE_AVAILABILITY_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantExercisingDetails_GrantReward';
  credit?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_CreditReward>;
  discount?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_DiscountReward>;
  rewardGroup?: Maybe<Instacart_Customers_Commerce_Discount_V1_RewardGroup>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_CreditReward = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantExercisingDetails_GrantReward_CreditReward';
  creditGrantCalculator?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditGrantCalculator>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_CreditReward_Input = {
  creditGrantCalculator?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditGrantCalculator_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_DiscountReward = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantExercisingDetails_GrantReward_DiscountReward';
  discountCalculator?: Maybe<Instacart_Customers_Commerce_Discount_V1_DiscountGrantCalculator>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_DiscountReward_Input = {
  discountCalculator?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_DiscountGrantCalculator_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_Input = {
  credit?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_CreditReward_Input>;
  discount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_DiscountReward_Input>;
  rewardGroup?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_RewardGroup_Input>;
};

/** Grant/Value (i.e. coupon/credit) Exercising */
export type Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_Input = {
  attachmentLevel?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantAttachmentLevel>;
  conditions?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingCondition_Input>>>;
  exercisingLimits?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingLimits_Input>;
  reward?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantReward_Input>>>;
  stackability?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingStackability>;
  storeAvailability?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantExercisingDetails_GrantExercisingStoreAvailability>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantQuantityDetail';
  monetaryAmount?: Maybe<Google_Type_Money>;
  nonMonetaryAmount?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_NonMonetaryAmount>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_Input = {
  monetaryAmount?: InputMaybe<Google_Type_Money_Input>;
  nonMonetaryAmount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_NonMonetaryAmount_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_NonMonetaryAmount = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantQuantityDetail_NonMonetaryAmount';
  quantity?: Maybe<Scalars['BigInt']>;
  unit?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_NonMonetaryAmount_NonMonetaryUnit>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_NonMonetaryAmount_Input = {
  quantity?: InputMaybe<Scalars['BigInt']>;
  unit?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_NonMonetaryAmount_NonMonetaryUnit>;
};

export enum Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_NonMonetaryAmount_NonMonetaryUnit {
  NonMonetaryChasePoints = 'NON_MONETARY_CHASE_POINTS',
  NonMonetaryUnitUnspecified = 'NON_MONETARY_UNIT_UNSPECIFIED'
}

export enum Instacart_Customers_Commerce_Discount_V1_GrantQuantityType {
  Fixed = 'FIXED',
  GrantValueTypeUnspecified = 'GRANT_VALUE_TYPE_UNSPECIFIED',
  NotApplicable = 'NOT_APPLICABLE',
  Variable = 'VARIABLE'
}

export type Instacart_Customers_Commerce_Discount_V1_GrantTargetDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_GrantTargetDetail';
  walletProduct?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantTargetDetail_WalletProduct>;
};

export type Instacart_Customers_Commerce_Discount_V1_GrantTargetDetail_Input = {
  walletProduct?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantTargetDetail_WalletProduct>;
};

/** This is currently defined as non shared type in payment service */
export enum Instacart_Customers_Commerce_Discount_V1_GrantTargetDetail_WalletProduct {
  BusinessContractualBenefit = 'BUSINESS_CONTRACTUAL_BENEFIT',
  CaperCredit = 'CAPER_CREDIT',
  ChaseCardCredit = 'CHASE_CARD_CREDIT',
  ChasePwp = 'CHASE_PWP',
  DirectedSpend = 'DIRECTED_SPEND',
  ExpressCredit = 'EXPRESS_CREDIT',
  GamificationCredit = 'GAMIFICATION_CREDIT',
  GiftCards = 'GIFT_CARDS',
  HappinessAppeasements = 'HAPPINESS_APPEASEMENTS',
  IcPromotionalCreditBack = 'IC_PROMOTIONAL_CREDIT_BACK',
  RetailerGiftCard = 'RETAILER_GIFT_CARD',
  WalletProductUnspecified = 'WALLET_PRODUCT_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_GroupCriteria = {
  __typename?: 'instacart_customers_commerce_discount_v1_GroupCriteria';
  key?: Maybe<Instacart_Customers_Commerce_Discount_V1_GroupCriteriaKey>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum Instacart_Customers_Commerce_Discount_V1_GroupCriteriaKey {
  /** Matches items with any listed brand name */
  BrandName = 'BRAND_NAME',
  /** Matches all items in cart */
  Cart = 'CART',
  /** Matches items with any listed commerce classifications */
  CommerceClassification = 'COMMERCE_CLASSIFICATION',
  GroupCriteriaKeyUnspecified = 'GROUP_CRITERIA_KEY_UNSPECIFIED',
  /** Matches items in any listed collection */
  ItemCollection = 'ITEM_COLLECTION',
  /** Matches items with any listed discount tag */
  ItemDiscountTag = 'ITEM_DISCOUNT_TAG',
  /** Matches items with any listed product id */
  ProductId = 'PRODUCT_ID',
  /** Matches items with any listed region */
  Region = 'REGION',
  /** Matches retailer products in any listed collection */
  RetailerProductCollectionId = 'RETAILER_PRODUCT_COLLECTION_ID',
  /** Matches retailer products in any excluded listed collection */
  RetailerProductCollectionIdExcluded = 'RETAILER_PRODUCT_COLLECTION_ID_EXCLUDED',
  /** Matches retailer products in any included listed collection */
  RetailerProductCollectionIdIncluded = 'RETAILER_PRODUCT_COLLECTION_ID_INCLUDED',
  /** Matches items with any listed taxonomy node id */
  TaxonomyNodeId = 'TAXONOMY_NODE_ID'
}

export type Instacart_Customers_Commerce_Discount_V1_GroupCriteria_Input = {
  key?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GroupCriteriaKey>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_PercentGrantInfo = {
  __typename?: 'instacart_customers_commerce_discount_v1_PercentGrantInfo';
  maximumGrantQuantity?: Maybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail>;
  percentValue?: Maybe<Instacart_Types_V1_BigDecimal>;
};

export type Instacart_Customers_Commerce_Discount_V1_PercentGrantInfo_Input = {
  maximumGrantQuantity?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_GrantQuantityDetail_Input>;
  percentValue?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy';
  endsAt?: Maybe<Scalars['DateTime']>;
  presentableConditions?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition>>>;
  presentableGrantDetail?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyStatus>;
  valueGrantPolicyId?: Maybe<Scalars['BigInt']>;
  valueGrantPolicyKey?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition';
  allValueGrantTags?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_AllValueGrantTags>;
  country?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_Country>;
  firstNOrders?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_FirstNOrders>;
  fulfillmentType?: Maybe<Instacart_Customers_Commerce_Discount_V1_FulfillmentType>;
  icMembershipExcluded?: Maybe<Scalars['instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_ICMembershipExcluded']>;
  icMembershipRequired?: Maybe<Scalars['instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_ICMembershipRequired']>;
  itemCollection?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_ItemCollection>;
  minimumSpend?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_MinimumSpend>;
  oneOfPaymentInstruments?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfPaymentInstruments>;
  oneOfRegions?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfRegions>;
  oneOfRetailers?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfRetailers>;
  oneOfStoreConfigurations?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfStoreConfigurations>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_AllValueGrantTags = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_AllValueGrantTags';
  valueGrantTags?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_DiscountTag>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_Country = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_Country';
  countryId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_FirstNOrders = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_FirstNOrders';
  orderCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_ItemCollection = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_ItemCollection';
  collectionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  minimumSpend?: Maybe<Google_Type_Money>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_MinimumSpend = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_MinimumSpend';
  commerceClassifications?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_CommerceClassification>>>;
  minimumSpend?: Maybe<Google_Type_Money>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfPaymentInstruments = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_OneOfPaymentInstruments';
  paymentInstructions?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_PaymentInstruction>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfRegions = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_OneOfRegions';
  regionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfRetailers = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_OneOfRetailers';
  retailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableCondition_OneOfStoreConfigurations = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableCondition_OneOfStoreConfigurations';
  storeConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableGrantDetail';
  couponDetail?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_CouponDetail>;
  couponGrantCount?: Maybe<Scalars['BigInt']>;
  creditBackGrantDetail?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_CreditBackGrantDetail>;
  creditGrantDetail?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_CreditGrantDetail>;
  discountGrantDetail?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail>;
  presentableGrantType?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_PresentableGrantType>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_CouponDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableGrantDetail_CouponDetail';
  couponGrantCount?: Maybe<Scalars['BigInt']>;
  couponGrantMaximumDuration?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_CreditBackGrantDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableGrantDetail_CreditBackGrantDetail';
  creditBackGrantCalculator?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditBackGrantCalculator>;
  grantExpiresIn?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
  refundExpiresIn?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_CreditGrantDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableGrantDetail_CreditGrantDetail';
  creditGrantCalculator?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditGrantCalculator>;
  grantExpiresIn?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
  refundExpiresIn?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail';
  discountGrantCalculator?: Maybe<Instacart_Customers_Commerce_Discount_V1_DiscountGrantCalculator>;
  discountedCommerceClassifications?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail_DiscountedCommerceClassifications>;
  discountedServiceItems?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail_DiscountedServiceItems>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail_DiscountedCommerceClassifications = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail_DiscountedCommerceClassifications';
  commerceClassifications?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_CommerceClassification>>>;
};

export type Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail_DiscountedServiceItems = {
  __typename?: 'instacart_customers_commerce_discount_v1_PresentableValueGrantPolicy_PresentableGrantDetail_DiscountGrantDetail_DiscountedServiceItems';
  serviceItemTypes?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_ServiceItemType>>>;
};

export enum Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy_PresentableGrantDetail_PresentableGrantType {
  /** On order credit that is evaluated for all orders of a customer */
  GrantTypeAutoAppliedCreditBack = 'GRANT_TYPE_AUTO_APPLIED_CREDIT_BACK',
  /** Basket level discount that is evaluated for all orders of a customer. */
  GrantTypeAutoAppliedDiscount = 'GRANT_TYPE_AUTO_APPLIED_DISCOUNT',
  /** Checkout code based discount */
  GrantTypeCheckoutCodeBasedDiscount = 'GRANT_TYPE_CHECKOUT_CODE_BASED_DISCOUNT',
  /** On order credit backed evaluated for only customers having credit back coupon */
  GrantTypeCouponCreditBack = 'GRANT_TYPE_COUPON_CREDIT_BACK',
  /**
   * Basket level discount evaluated for only customers having discount coupon
   * Ex: Discount of $10 on order placed from Costco for customers having the coupon
   */
  GrantTypeCouponDiscount = 'GRANT_TYPE_COUPON_DISCOUNT',
  /**
   * Off order credit that is granted independent of an order, Ex: $10 credit
   * when a customer places 3 orders in a week
   */
  GrantTypeCredit = 'GRANT_TYPE_CREDIT',
  PresentableGrantTypeUnspecified = 'PRESENTABLE_GRANT_TYPE_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_RewardGroup = {
  __typename?: 'instacart_customers_commerce_discount_v1_RewardGroup';
  includeConditionGroup?: Maybe<Scalars['Boolean']>;
  quantityLimit?: Maybe<Scalars['BigInt']>;
  rewardGroupKey?: Maybe<Instacart_Customers_Commerce_Discount_V1_RewardGroupKey>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum Instacart_Customers_Commerce_Discount_V1_RewardGroupKey {
  /** Includes items whose brand name appears in the values list. */
  RewardBrandName = 'REWARD_BRAND_NAME',
  /** Includes all items in cart */
  RewardCart = 'REWARD_CART',
  /** Includes all items in the given commerce_classifications. */
  RewardCommerceClassification = 'REWARD_COMMERCE_CLASSIFICATION',
  RewardGroupKeyUnspecified = 'REWARD_GROUP_KEY_UNSPECIFIED',
  /** Includes all items in the specified collections. */
  RewardItemCollection = 'REWARD_ITEM_COLLECTION',
  /** Includes items whose product ID appears in the values list. */
  RewardProductId = 'REWARD_PRODUCT_ID',
  /** Excludes all items with specified retailer product collection ids. */
  RewardRetailerProductCollectionIdExcluded = 'REWARD_RETAILER_PRODUCT_COLLECTION_ID_EXCLUDED',
  /** Includes all items with specified retailer product collection ids. */
  RewardRetailerProductCollectionIdIncluded = 'REWARD_RETAILER_PRODUCT_COLLECTION_ID_INCLUDED',
  /** Includes all service items */
  RewardServiceItem = 'REWARD_SERVICE_ITEM',
  /** Includes items whose (any) taxonomy node ID appears in the values list. */
  RewardTaxonomyNodeId = 'REWARD_TAXONOMY_NODE_ID'
}

export type Instacart_Customers_Commerce_Discount_V1_RewardGroup_Input = {
  includeConditionGroup?: InputMaybe<Scalars['Boolean']>;
  quantityLimit?: InputMaybe<Scalars['BigInt']>;
  rewardGroupKey?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_RewardGroupKey>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum Instacart_Customers_Commerce_Discount_V1_ServiceItemType {
  /** alcohol service fee */
  AlcoholServiceFee = 'ALCOHOL_SERVICE_FEE',
  CancellationFee = 'CANCELLATION_FEE',
  DeliveryFee = 'DELIVERY_FEE',
  /** other service fee except alcohol and rx */
  GeneralServiceFee = 'GENERAL_SERVICE_FEE',
  HeavyDeliveryFee = 'HEAVY_DELIVERY_FEE',
  LongDistanceFee = 'LONG_DISTANCE_FEE',
  PriorityFee = 'PRIORITY_FEE',
  /** total service fee (general + alcohol + rx) */
  RegularServiceFee = 'REGULAR_SERVICE_FEE',
  /** rx service fee */
  RxServiceFee = 'RX_SERVICE_FEE',
  ServiceFee = 'SERVICE_FEE',
  ServiceItemTypeUnspecified = 'SERVICE_ITEM_TYPE_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_TimeDuration = {
  __typename?: 'instacart_customers_commerce_discount_v1_TimeDuration';
  timePeriod?: Maybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration_TimePeriod>;
  timePeriodCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Discount_V1_TimeDuration_Input = {
  timePeriod?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_TimeDuration_TimePeriod>;
  timePeriodCount?: InputMaybe<Scalars['BigInt']>;
};

export enum Instacart_Customers_Commerce_Discount_V1_TimeDuration_TimePeriod {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  TimePeriodUnspecified = 'TIME_PERIOD_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_UpdateValueGrantPolicyRequest_Input = {
  additionalMetadata?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Discount_V1_AdditionalMetadata_Input>>>;
  autoAppliedDiscount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedDiscount_Input>;
  autoCreditBackInCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInCoupon_Input>;
  autoCreditBackInDigitalWallet?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInDigitalWallet_Input>;
  checkoutBenefitCodeDiscount?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CheckoutBenefitCodeDiscount_Input>;
  countryId?: InputMaybe<Scalars['BigInt']>;
  creator?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_Creator_Input>;
  creditBackInCouponUsingCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInCoupon_Input>;
  creditBackInDigitalWalletUsingCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInDigitalWallet_Input>;
  creditInCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditInCoupon_Input>;
  creditInDigitalWallet?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CreditInDigitalWallet_Input>;
  /** any description for the policy */
  description?: InputMaybe<Scalars['String']>;
  discountCategoryId?: InputMaybe<Scalars['BigInt']>;
  discountUsingClippableCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ClippableCouponBasedDiscount_Input>;
  discountUsingCoupon?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedDiscount_Input>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** reference key of the policy in the external system */
  externalReference?: InputMaybe<Scalars['String']>;
  fundingDetails?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_FundingDetail_Input>;
  imageUrl?: InputMaybe<Scalars['String']>;
  /** name of the policy */
  name?: InputMaybe<Scalars['String']>;
  /** Any description of the policy which can be exposed to the end user */
  publicDescription?: InputMaybe<Scalars['String']>;
  /** Request id. */
  requestId?: InputMaybe<Scalars['String']>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  source?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  /** status of the policy */
  status?: InputMaybe<Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyStatus>;
  storeConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  userSegments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  valueGrantPolicyId?: InputMaybe<Scalars['BigInt']>;
  valueGrantPolicyKey?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Discount_V1_UpdateValueGrantPolicyResponse = {
  __typename?: 'instacart_customers_commerce_discount_v1_UpdateValueGrantPolicyResponse';
  errors?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyUpdateError>>>;
  valueGrantPolicy?: Maybe<Instacart_Customers_Commerce_Discount_V1_PresentableValueGrantPolicy>;
};

export type Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyFacade = {
  __typename?: 'instacart_customers_commerce_discount_v1_ValueGrantPolicyFacade';
  additionalMetadata?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Discount_V1_AdditionalMetadata>>>;
  autoAppliedDiscount?: Maybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedDiscount>;
  autoCreditBackInCoupon?: Maybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInCoupon>;
  autoCreditBackInDigitalWallet?: Maybe<Instacart_Customers_Commerce_Discount_V1_AutoAppliedCreditBackInDigitalWallet>;
  checkoutBenefitCodeDiscount?: Maybe<Instacart_Customers_Commerce_Discount_V1_CheckoutBenefitCodeDiscount>;
  countryId?: Maybe<Scalars['BigInt']>;
  creator?: Maybe<Instacart_Customers_Commerce_Discount_V1_Creator>;
  creditBackInCouponUsingCoupon?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInCoupon>;
  creditBackInDigitalWalletUsingCoupon?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedCreditBackInDigitalWallet>;
  creditInCoupon?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditInCoupon>;
  creditInDigitalWallet?: Maybe<Instacart_Customers_Commerce_Discount_V1_CreditInDigitalWallet>;
  /** any description for the policy */
  description?: Maybe<Scalars['String']>;
  discountCategoryId?: Maybe<Scalars['BigInt']>;
  discountUsingClippableCoupon?: Maybe<Instacart_Customers_Commerce_Discount_V1_ClippableCouponBasedDiscount>;
  discountUsingCoupon?: Maybe<Instacart_Customers_Commerce_Discount_V1_CouponBasedDiscount>;
  endsAt?: Maybe<Scalars['DateTime']>;
  /** reference key of the policy in the external system */
  externalReference?: Maybe<Scalars['String']>;
  fundingDetails?: Maybe<Instacart_Customers_Commerce_Discount_V1_FundingDetail>;
  imageUrl?: Maybe<Scalars['String']>;
  /** name of the policy */
  name?: Maybe<Scalars['String']>;
  /** Any description of the policy which can be exposed to the end user */
  publicDescription?: Maybe<Scalars['String']>;
  /** Request id. */
  requestId?: Maybe<Scalars['String']>;
  retailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerLocationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  source?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  /** status of the policy */
  status?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyStatus>;
  storeConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  userSegments?: Maybe<Array<Maybe<Scalars['String']>>>;
  valueGrantCode?: Maybe<Scalars['String']>;
  valueGrantPolicyId?: Maybe<Scalars['BigInt']>;
  valueGrantPolicyKey?: Maybe<Scalars['String']>;
};

export enum Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyStatus {
  ValueGrantPolicyStatusActive = 'VALUE_GRANT_POLICY_STATUS_ACTIVE',
  ValueGrantPolicyStatusDiscarded = 'VALUE_GRANT_POLICY_STATUS_DISCARDED',
  ValueGrantPolicyStatusDraft = 'VALUE_GRANT_POLICY_STATUS_DRAFT',
  ValueGrantPolicyStatusInactive = 'VALUE_GRANT_POLICY_STATUS_INACTIVE',
  ValueGrantPolicyStatusUnspecified = 'VALUE_GRANT_POLICY_STATUS_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyUpdateError = {
  __typename?: 'instacart_customers_commerce_discount_v1_ValueGrantPolicyUpdateError';
  requestId?: Maybe<Scalars['String']>;
  retriable?: Maybe<Scalars['Boolean']>;
  updateError?: Maybe<Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyUpdateError_UpdateError>;
  valueGrantPolicyId?: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Customers_Commerce_Discount_V1_ValueGrantPolicyUpdateError_UpdateError {
  /** end_at is invalid */
  UpdateErrorInvalidEndDate = 'UPDATE_ERROR_INVALID_END_DATE',
  /** update payload invalid */
  UpdateErrorInvalidPayload = 'UPDATE_ERROR_INVALID_PAYLOAD',
  /** indicates that moving to the new status is not allowed */
  UpdateErrorInvalidStatusTransition = 'UPDATE_ERROR_INVALID_STATUS_TRANSITION',
  UpdateErrorUnspecified = 'UPDATE_ERROR_UNSPECIFIED',
  /** indicates the current status does not allow edits */
  UpdateErrorUnsupportedStatusForUpdates = 'UPDATE_ERROR_UNSUPPORTED_STATUS_FOR_UPDATES',
  /** vgp needs to be published first */
  UpdateErrorVgpNotPublished = 'UPDATE_ERROR_VGP_NOT_PUBLISHED'
}

export enum Instacart_Customers_Commerce_Discount_V1_ValueIssuingAuthority {
  ValueIssuingAuthorityBirdzi = 'VALUE_ISSUING_AUTHORITY_BIRDZI',
  ValueIssuingAuthorityCaper = 'VALUE_ISSUING_AUTHORITY_CAPER',
  ValueIssuingAuthorityChaseCobrandPwp = 'VALUE_ISSUING_AUTHORITY_CHASE_COBRAND_PWP',
  ValueIssuingAuthorityChaseCobrandSignupService = 'VALUE_ISSUING_AUTHORITY_CHASE_COBRAND_SIGNUP_SERVICE',
  ValueIssuingAuthorityCx = 'VALUE_ISSUING_AUTHORITY_CX',
  ValueIssuingAuthorityDirectedSpend = 'VALUE_ISSUING_AUTHORITY_DIRECTED_SPEND',
  ValueIssuingAuthorityIcPlus = 'VALUE_ISSUING_AUTHORITY_IC_PLUS',
  ValueIssuingAuthorityInmarYoutech = 'VALUE_ISSUING_AUTHORITY_INMAR_YOUTECH',
  ValueIssuingAuthorityLmsNcr = 'VALUE_ISSUING_AUTHORITY_LMS_NCR',
  /** Authority will not be applicable for Policies that don't have any value part. */
  ValueIssuingAuthorityNotApplicable = 'VALUE_ISSUING_AUTHORITY_NOT_APPLICABLE',
  ValueIssuingAuthoritySuperSaver = 'VALUE_ISSUING_AUTHORITY_SUPER_SAVER',
  ValueIssuingAuthorityTreatmentService = 'VALUE_ISSUING_AUTHORITY_TREATMENT_SERVICE',
  ValueIssuingAuthorityUnspecified = 'VALUE_ISSUING_AUTHORITY_UNSPECIFIED'
}

export enum Instacart_Customers_Commerce_Discount_V1_ValueIssuingFlow {
  ValueIssuingFlowAppeasements = 'VALUE_ISSUING_FLOW_APPEASEMENTS',
  ValueIssuingFlowCaperCredits = 'VALUE_ISSUING_FLOW_CAPER_CREDITS',
  ValueIssuingFlowCharm = 'VALUE_ISSUING_FLOW_CHARM',
  ValueIssuingFlowChaseCobrandPwp = 'VALUE_ISSUING_FLOW_CHASE_COBRAND_PWP',
  ValueIssuingFlowChaseCobrandSignup = 'VALUE_ISSUING_FLOW_CHASE_COBRAND_SIGNUP',
  ValueIssuingFlowCiao = 'VALUE_ISSUING_FLOW_CIAO',
  ValueIssuingFlowDirectedSpendOnboarding = 'VALUE_ISSUING_FLOW_DIRECTED_SPEND_ONBOARDING',
  ValueIssuingFlowDirectedSpendTopUp = 'VALUE_ISSUING_FLOW_DIRECTED_SPEND_TOP_UP',
  ValueIssuingFlowIcPlusPickup = 'VALUE_ISSUING_FLOW_IC_PLUS_PICKUP',
  /** Flow name will not be applicable for Policies that don't have off order part. */
  ValueIssuingFlowNotApplicable = 'VALUE_ISSUING_FLOW_NOT_APPLICABLE',
  ValueIssuingFlowOrderCompletion = 'VALUE_ISSUING_FLOW_ORDER_COMPLETION',
  ValueIssuingFlowRti = 'VALUE_ISSUING_FLOW_RTI',
  ValueIssuingFlowThirdPartyOffersIngestion = 'VALUE_ISSUING_FLOW_THIRD_PARTY_OFFERS_INGESTION',
  ValueIssuingFlowUnspecified = 'VALUE_ISSUING_FLOW_UNSPECIFIED'
}

/** Represent basket item restrictions */
export type Instacart_Customers_Commerce_Types_V1_BasketItemRestrictions = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketItemRestrictions';
  attributes?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketItemRestrictions_ItemRestrictionType>>>;
};

export enum Instacart_Customers_Commerce_Types_V1_BasketItemRestrictions_ItemRestrictionType {
  Alcohol = 'ALCOHOL',
  ItemRestrictionTypeUnspecified = 'ITEM_RESTRICTION_TYPE_UNSPECIFIED',
  OverTheCounter = 'OVER_THE_COUNTER',
  SprayPaint = 'SPRAY_PAINT'
}

/**
 * BasketProduct representation.
 * NOTE: Currently basket_product.rb is not auto generated by this proto.
 * Fields defined in basket_product.rb that are not included here: [legacy_attributes, _legacy_basket_product]
 * Next ID: 83
 */
export type Instacart_Customers_Commerce_Types_V1_BasketProduct = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct';
  alcoholic?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['BigInt']>;
  brandName?: Maybe<Scalars['String']>;
  catalog?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_Catalog>;
  catalogEntityId?: Maybe<Scalars['BigInt']>;
  comboPromotions?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ComboPromotion>>>;
  commerceClassification?: Maybe<Instacart_Customers_Commerce_Types_V1_CommerceClassification>;
  configurableProducts?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts>;
  configured?: Maybe<Scalars['Boolean']>;
  costPricePerUnit?: Maybe<Google_Type_Money>;
  costUnit?: Maybe<Instacart_Types_V1_MeasurementUnit>;
  countryId?: Maybe<Scalars['BigInt']>;
  custom?: Maybe<Scalars['Boolean']>;
  customerSpecialRequest?: Maybe<Scalars['Boolean']>;
  departmentName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disallowedServiceTypes?: Maybe<Array<Maybe<Instacart_Customers_Shared_V1_ServiceType>>>;
  discountData?: Maybe<Scalars['String']>;
  displaySize?: Maybe<Scalars['String']>;
  excludeMarkup?: Maybe<Scalars['Boolean']>;
  externalRetailerSpecificMetadata?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  inventoryAreaId?: Maybe<Scalars['BigInt']>;
  isCatering?: Maybe<Scalars['Boolean']>;
  isHot?: Maybe<Scalars['Boolean']>;
  itemRestrictions?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketItemRestrictions>;
  itemsPricingData?: Maybe<Scalars['String']>;
  l1CategoryName?: Maybe<Scalars['String']>;
  l1TaxonomyNodeId?: Maybe<Scalars['BigInt']>;
  l2CategoryName?: Maybe<Scalars['String']>;
  l2TaxonomyNodeId?: Maybe<Scalars['BigInt']>;
  l3CategoryName?: Maybe<Scalars['String']>;
  l3TaxonomyNodeId?: Maybe<Scalars['BigInt']>;
  l4CategoryName?: Maybe<Scalars['String']>;
  l4TaxonomyNodeId?: Maybe<Scalars['BigInt']>;
  l5CategoryName?: Maybe<Scalars['String']>;
  l5TaxonomyNodeId?: Maybe<Scalars['BigInt']>;
  l6CategoryName?: Maybe<Scalars['String']>;
  l6TaxonomyNodeId?: Maybe<Scalars['BigInt']>;
  legacyFrozenItem?: Maybe<Scalars['Boolean']>;
  legacyItemId?: Maybe<Scalars['BigInt']>;
  legacyV3Id?: Maybe<Scalars['String']>;
  legacyV3IdInt?: Maybe<Scalars['BigInt']>;
  loyaltyAttributes?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_LoyaltyAttributes>;
  managedAttributeIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  name?: Maybe<Scalars['String']>;
  nonRegulatoryItemDeposit?: Maybe<Google_Type_Money>;
  normalizedDimensions?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_NormalizedDimensions>;
  normalizedWeightG?: Maybe<Instacart_Types_V1_BigDecimal>;
  orderLeadTime?: Maybe<Scalars['BigInt']>;
  parWeight?: Maybe<Instacart_Types_V1_MeasuredQuantity>;
  priceMultiple?: Maybe<Instacart_Types_V1_BigDecimal>;
  productCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  productId?: Maybe<Scalars['BigInt']>;
  productLocale?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  quantityAttributes?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_QuantityAttributes>;
  reference?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference>;
  regionId?: Maybe<Scalars['BigInt']>;
  requiresKeepFrozen?: Maybe<Scalars['Boolean']>;
  restrictedOtc?: Maybe<Scalars['Boolean']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  retailerOmsEligible?: Maybe<Scalars['Boolean']>;
  retailerProductCollectionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerProductSizeLabel?: Maybe<Scalars['String']>;
  retailerPromotionDetails?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_RetailerPromotionDetails>;
  rx?: Maybe<Scalars['Boolean']>;
  saleAttributes?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_SaleAttributes>;
  selectedConfigurations?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_SelectedConfiguration>>>;
  sheetCount?: Maybe<Instacart_Types_V1_BigDecimal>;
  sizeMeasurement?: Maybe<Instacart_Types_V1_MeasuredQuantity>;
  sizeUomLabel?: Maybe<Scalars['String']>;
  storeBrand?: Maybe<Scalars['Boolean']>;
  tobacco?: Maybe<Scalars['Boolean']>;
  unattachedCoupon?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_UnattachedCoupon>;
  unitCount?: Maybe<Scalars['BigInt']>;
  usaSnapEligible?: Maybe<Scalars['Boolean']>;
  v4ItemId?: Maybe<Scalars['String']>;
  variantGroupDisplaySizeLabel?: Maybe<Scalars['String']>;
  zoneIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

/** BasketProduceReference representation. */
export type Instacart_Customers_Commerce_Types_V1_BasketProductReference = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProductReference';
  configuredItem?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_ConfiguredItem>;
  item?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_Item>;
  pricedItemSnapshot?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_PricedItemSnapshot>;
  rxItem?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_RxItem>;
  specialRequest?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_SpecialRequest>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_ConfiguredItem = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProductReference_ConfiguredItem';
  id?: Maybe<Scalars['BigInt']>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_ConfiguredItem_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

/** BasketProduceReference representation. */
export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_Input = {
  configuredItem?: InputMaybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_ConfiguredItem_Input>;
  item?: InputMaybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_Item_Input>;
  pricedItemSnapshot?: InputMaybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_PricedItemSnapshot_Input>;
  rxItem?: InputMaybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_RxItem_Input>;
  specialRequest?: InputMaybe<Instacart_Customers_Commerce_Types_V1_BasketProductReference_SpecialRequest_Input>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_Item = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProductReference_Item';
  productId?: Maybe<Scalars['BigInt']>;
  productLocale?: Maybe<Scalars['String']>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_Item_Input = {
  productId?: InputMaybe<Scalars['BigInt']>;
  productLocale?: InputMaybe<Scalars['String']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_PricedItemSnapshot = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProductReference_PricedItemSnapshot';
  id?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_PricedItemSnapshot_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_RxItem = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProductReference_RxItem';
  id?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_RxItem_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_SpecialRequest = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProductReference_SpecialRequest';
  id?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProductReference_SpecialRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export enum Instacart_Customers_Commerce_Types_V1_BasketProduct_Catalog {
  CatalogUnspecified = 'CATALOG_UNSPECIFIED',
  Configured = 'CONFIGURED',
  Custom = 'CUSTOM',
  Product = 'PRODUCT',
  Rx = 'RX'
}

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_ComboPromotion = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_ComboPromotion';
  comboGroupId?: Maybe<Scalars['String']>;
  discountBeginsAt?: Maybe<Scalars['DateTime']>;
  discountEndsAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ComboPromotion_Metadata>;
  name?: Maybe<Scalars['String']>;
  promotionGroupId?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_ComboPromotion_Metadata = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_ComboPromotion_Metadata';
  comboRequirements?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ComboPromotion_Requirement>>>;
  discountValue?: Maybe<Google_Type_Money>;
  fixedPriceValue?: Maybe<Google_Type_Money>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_ComboPromotion_Requirement = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_ComboPromotion_Requirement';
  comboGroupId?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_ConfigurableProducts';
  configId?: Maybe<Scalars['String']>;
  configType?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_ConfigType>;
  optionGroups?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_OptionGroup>>>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_ConfigOptionItem = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_ConfigurableProducts_ConfigOptionItem';
  configOptionItemId?: Maybe<Scalars['String']>;
  costPricePerUnit?: Maybe<Google_Type_Money>;
  enabled?: Maybe<Scalars['Boolean']>;
  overrides?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_OptionOverride>>>;
  saleAttributes?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_SaleAttributes>;
};

export enum Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_ConfigType {
  Builder = 'BUILDER',
  ConfigTypeUnspecified = 'CONFIG_TYPE_UNSPECIFIED',
  Custom = 'CUSTOM',
  PrepStyle = 'PREP_STYLE',
  UnknownType = 'UNKNOWN_TYPE'
}

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_OptionGroup = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_ConfigurableProducts_OptionGroup';
  configOptionItems?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_ConfigOptionItem>>>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_ConfigurableProducts_OptionOverride = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_ConfigurableProducts_OptionOverride';
  configOptionItemId?: Maybe<Scalars['String']>;
  costPricePerUnit?: Maybe<Google_Type_Money>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_LoyaltyAttributes = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_LoyaltyAttributes';
  loyaltyCostPricePerUnit?: Maybe<Google_Type_Money>;
  loyaltyPriceEndAt?: Maybe<Scalars['DateTime']>;
  loyaltyPriceStartAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_NormalizedDimensions = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_NormalizedDimensions';
  normalizedHeightCm?: Maybe<Instacart_Types_V1_BigDecimal>;
  normalizedLengthCm?: Maybe<Instacart_Types_V1_BigDecimal>;
  normalizedWidthCm?: Maybe<Instacart_Types_V1_BigDecimal>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_QuantityAttributes = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_QuantityAttributes';
  increment?: Maybe<Instacart_Types_V1_BigDecimal>;
  max?: Maybe<Instacart_Types_V1_BigDecimal>;
  min?: Maybe<Instacart_Types_V1_BigDecimal>;
  quantityType?: Maybe<Instacart_Customers_Shared_V1_QuantityType>;
  requestUnit?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_QuantityAttributes_RequestUnit>;
  variableWeight?: Maybe<Scalars['Boolean']>;
};

export enum Instacart_Customers_Commerce_Types_V1_BasketProduct_QuantityAttributes_RequestUnit {
  Each = 'EACH',
  Kg = 'KG',
  Lb = 'LB',
  Package = 'PACKAGE',
  RequestUnitUnspecified = 'REQUEST_UNIT_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_RetailerPromotionDetails = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_RetailerPromotionDetails';
  discountBeginsAt?: Maybe<Scalars['DateTime']>;
  discountCategoryId?: Maybe<Scalars['BigInt']>;
  discountEndsAt?: Maybe<Scalars['DateTime']>;
  loyaltyMembersOnly?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Instacart_Customers_Commerce_Types_V1_BasketProduct_RetailerPromotionDetails_Metadata>;
  name?: Maybe<Scalars['String']>;
  promotionGroupId?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_RetailerPromotionDetails_Metadata = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_RetailerPromotionDetails_Metadata';
  bundlePrice?: Maybe<Google_Type_Money>;
  buy?: Maybe<Scalars['BigInt']>;
  discountPercentages?: Maybe<Array<Maybe<Instacart_Types_V1_BigDecimal>>>;
  discountValue?: Maybe<Google_Type_Money>;
  get?: Maybe<Scalars['BigInt']>;
  limitPerOrder?: Maybe<Scalars['BigInt']>;
  maxQty?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  minQty?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** If true, all items need to be purchased and fulfilled for pomo discount. */
  qtyEnforced?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_SaleAttributes = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_SaleAttributes';
  saleCostPricePerUnit?: Maybe<Google_Type_Money>;
  saleEndAt?: Maybe<Scalars['DateTime']>;
  saleStartAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_SelectedConfiguration = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_SelectedConfiguration';
  configOptionId?: Maybe<Scalars['String']>;
  configOptionItemId?: Maybe<Scalars['String']>;
  configOptionItemName?: Maybe<Scalars['String']>;
  configOptionItemPrice?: Maybe<Google_Type_Money>;
  configOptionName?: Maybe<Scalars['String']>;
  textValue?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Types_V1_BasketProduct_UnattachedCoupon = {
  __typename?: 'instacart_customers_commerce_types_v1_BasketProduct_UnattachedCoupon';
  adminOnly?: Maybe<Scalars['Boolean']>;
  countryId?: Maybe<Scalars['BigInt']>;
  description?: Maybe<Scalars['String']>;
  discountBeginsAt?: Maybe<Scalars['DateTime']>;
  discountEndsAt?: Maybe<Scalars['DateTime']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  itemsPerDeal?: Maybe<Scalars['BigInt']>;
  maxRedemptionsPerOrder?: Maybe<Scalars['BigInt']>;
  maxRedemptionsTotal?: Maybe<Scalars['BigInt']>;
  promotionGroupId?: Maybe<Scalars['String']>;
  publicDescription?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<Maybe<Scalars['String']>>>;
  sponsorName?: Maybe<Scalars['String']>;
  subtractValueCents?: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Customers_Commerce_Types_V1_CommerceClassification {
  Alcoholic = 'ALCOHOLIC',
  CommerceClassificationUnknown = 'COMMERCE_CLASSIFICATION_UNKNOWN',
  GeneralMerchandise = 'GENERAL_MERCHANDISE',
  Rx = 'RX',
  VirtualRetailerMembership = 'VIRTUAL_RETAILER_MEMBERSHIP'
}

export enum Instacart_Customers_Commerce_Types_V1_CustomerCancelReason_CustomerReason {
  CancelAndReorder = 'CANCEL_AND_REORDER',
  ChangeDeliveryTime = 'CHANGE_DELIVERY_TIME',
  ChangePickupTime = 'CHANGE_PICKUP_TIME',
  CustomerReasonUnspecified = 'CUSTOMER_REASON_UNSPECIFIED',
  DoNotNeedThisOrderAnymore = 'DO_NOT_NEED_THIS_ORDER_ANYMORE',
  ItemsWereOutOfStock = 'ITEMS_WERE_OUT_OF_STOCK',
  ICreatedADuplicateOrder = 'I_CREATED_A_DUPLICATE_ORDER',
  IDoNotNeedThisOrderAnymore = 'I_DO_NOT_NEED_THIS_ORDER_ANYMORE',
  IWantToChangeMyOrder = 'I_WANT_TO_CHANGE_MY_ORDER',
  LateOrder = 'LATE_ORDER',
  NeedToAddOrRemoveItems = 'NEED_TO_ADD_OR_REMOVE_ITEMS',
  NeedToReschedule = 'NEED_TO_RESCHEDULE',
  NewTimeDidNotWorkDelivery = 'NEW_TIME_DID_NOT_WORK_DELIVERY',
  NewTimeDidNotWorkPickup = 'NEW_TIME_DID_NOT_WORK_PICKUP',
  OrderTakingTooLong = 'ORDER_TAKING_TOO_LONG',
  Other = 'OTHER',
  OtherRedirect = 'OTHER_REDIRECT',
  SwitchToDelivery = 'SWITCH_TO_DELIVERY',
  SwitchToPickup = 'SWITCH_TO_PICKUP',
  ThereIsAProblemWithMyOrder = 'THERE_IS_A_PROBLEM_WITH_MY_ORDER',
  TooExpensve = 'TOO_EXPENSVE',
  WrongAddressDifferentCity = 'WRONG_ADDRESS_DIFFERENT_CITY',
  WrongAddressSameCity = 'WRONG_ADDRESS_SAME_CITY',
  WrongDeliveryLocation = 'WRONG_DELIVERY_LOCATION',
  WrongPaymentMethod = 'WRONG_PAYMENT_METHOD',
  WrongPaymentOrChangeTip = 'WRONG_PAYMENT_OR_CHANGE_TIP',
  WrongPickupLocation = 'WRONG_PICKUP_LOCATION',
  WrongRetailerLocation = 'WRONG_RETAILER_LOCATION'
}

export type Instacart_Customers_Commerce_Types_V1_CustomerCancelReason_Input = {
  customerReason?: InputMaybe<Instacart_Customers_Commerce_Types_V1_CustomerCancelReason_CustomerReason>;
  /** this is only used when customer_reason is other */
  otherComment?: InputMaybe<Scalars['String']>;
};

/** Funding source */
export enum Instacart_Customers_Commerce_Types_V1_FundingSource {
  Cpg = 'CPG',
  FundingSourceUnspecified = 'FUNDING_SOURCE_UNSPECIFIED',
  Instacart = 'INSTACART',
  Others = 'OTHERS',
  Retailer = 'RETAILER'
}

/** Order Cancellation Category */
export enum Instacart_Customers_Commerce_Types_V1_OrderCancellationCategory {
  CustomerResponsible = 'CUSTOMER_RESPONSIBLE',
  InstacartPolicyEnforcement = 'INSTACART_POLICY_ENFORCEMENT',
  IntacartUnbatchable = 'INTACART_UNBATCHABLE',
  OrderCancellationCategoryUnspecified = 'ORDER_CANCELLATION_CATEGORY_UNSPECIFIED',
  OtherCancellationCategory = 'OTHER_CANCELLATION_CATEGORY',
  RetailerStoreIssue = 'RETAILER_STORE_ISSUE',
  ShopperRequested = 'SHOPPER_REQUESTED'
}

/** The specific reason why the order is cancelled */
export enum Instacart_Customers_Commerce_Types_V1_OrderCancellationType {
  AdminRefund = 'ADMIN_REFUND',
  BjsBypassUnrecoverableError = 'BJS_BYPASS_UNRECOVERABLE_ERROR',
  BlockedCompletionCancellation = 'BLOCKED_COMPLETION_CANCELLATION',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  CancelledByRetailer = 'CANCELLED_BY_RETAILER',
  CancelledViaScript = 'CANCELLED_VIA_SCRIPT',
  CardDeclineOnReauth = 'CARD_DECLINE_ON_REAUTH',
  CarAccident = 'CAR_ACCIDENT',
  CarIssue = 'CAR_ISSUE',
  ChangePaymentMethod = 'CHANGE_PAYMENT_METHOD',
  CustomerAppNavigationIssue = 'CUSTOMER_APP_NAVIGATION_ISSUE',
  CustomerMia = 'CUSTOMER_MIA',
  CustomerMiaOnDropOffOrder = 'CUSTOMER_MIA_ON_DROP_OFF_ORDER',
  CustomerRequestedSinceOrderIsEarly = 'CUSTOMER_REQUESTED_SINCE_ORDER_IS_EARLY',
  CustomerRequestedSinceOrderIsLate = 'CUSTOMER_REQUESTED_SINCE_ORDER_IS_LATE',
  CustomerRequestToCancel = 'CUSTOMER_REQUEST_TO_CANCEL',
  DeliveredOutsideOfWindow = 'DELIVERED_OUTSIDE_OF_WINDOW',
  DuplicateOrder = 'DUPLICATE_ORDER',
  Emergency = 'EMERGENCY',
  EmptyDelivery = 'EMPTY_DELIVERY',
  FailedCompletionCancellation = 'FAILED_COMPLETION_CANCELLATION',
  FraudulentCustomer = 'FRAUDULENT_CUSTOMER',
  FulfillmentInitiatedDueToOutOfStock = 'FULFILLMENT_INITIATED_DUE_TO_OUT_OF_STOCK',
  IncorrectAccessibilityInformation = 'INCORRECT_ACCESSIBILITY_INFORMATION',
  IncorrectCustomerInformation = 'INCORRECT_CUSTOMER_INFORMATION',
  InstacartConnectOrderWithAllItemsRefunded = 'INSTACART_CONNECT_ORDER_WITH_ALL_ITEMS_REFUNDED',
  KrogerConnectFailedToGetSuspendedTransaction = 'KROGER_CONNECT_FAILED_TO_GET_SUSPENDED_TRANSACTION',
  ManualFraud = 'MANUAL_FRAUD',
  MassCancellation = 'MASS_CANCELLATION',
  MissingChargeLogForDelivery = 'MISSING_CHARGE_LOG_FOR_DELIVERY',
  None = 'NONE',
  OnlinePayFailure = 'ONLINE_PAY_FAILURE',
  OrderCancellationTypeUnspecified = 'ORDER_CANCELLATION_TYPE_UNSPECIFIED',
  OtherCancellationType = 'OTHER_CANCELLATION_TYPE',
  PrePicking = 'PRE_PICKING',
  RetailerInitiatedCustomerMia = 'RETAILER_INITIATED_CUSTOMER_MIA',
  RetailerInitiatedOutOfStock = 'RETAILER_INITIATED_OUT_OF_STOCK',
  SafetyConcerns = 'SAFETY_CONCERNS',
  SevereWeatherEvent = 'SEVERE_WEATHER_EVENT',
  ShopperCouldNotFindAddress = 'SHOPPER_COULD_NOT_FIND_ADDRESS',
  ShopperInitiatedOutOfStock = 'SHOPPER_INITIATED_OUT_OF_STOCK',
  ShopperUnableToCompleteOrder = 'SHOPPER_UNABLE_TO_COMPLETE_ORDER',
  SingleItemOrderOutOfStock = 'SINGLE_ITEM_ORDER_OUT_OF_STOCK',
  StoreEarlyClosure = 'STORE_EARLY_CLOSURE',
  StoreOutage = 'STORE_OUTAGE',
  TooManyOutOfStockItem = 'TOO_MANY_OUT_OF_STOCK_ITEM',
  TooManyReplacements = 'TOO_MANY_REPLACEMENTS',
  TrafficConditions = 'TRAFFIC_CONDITIONS',
  UnableToRescheduleAsNoOptionFound = 'UNABLE_TO_RESCHEDULE_AS_NO_OPTION_FOUND',
  UnableToRescheduleUnbatchable = 'UNABLE_TO_RESCHEDULE_UNBATCHABLE',
  Unbatchable = 'UNBATCHABLE',
  WrongStoreHours = 'WRONG_STORE_HOURS'
}

export type Instacart_Customers_Commerce_Types_V1_PaymentInstruction = {
  __typename?: 'instacart_customers_commerce_types_v1_PaymentInstruction';
  default?: Maybe<Scalars['Boolean']>;
  instrumentReference?: Maybe<Scalars['String']>;
  /** instrument type used to match the existing v3 payment instruction field. */
  legacyPaymentInstrumentType?: Maybe<Instacart_Customers_Commerce_Types_V1_PaymentInstruction_LegacyPaymentInstrumentType>;
  /** payment method token id */
  paymentMethodTokenReference?: Maybe<Scalars['String']>;
  userSpecifiedMaximumAmount?: Maybe<Google_Type_Money>;
};

export enum Instacart_Customers_Commerce_Types_V1_PaymentInstruction_LegacyPaymentInstrumentType {
  ApplePay = 'APPLE_PAY',
  ChasePwp = 'CHASE_PWP',
  CreditCard = 'CREDIT_CARD',
  DigitalWallet = 'DIGITAL_WALLET',
  DirectedSpend = 'DIRECTED_SPEND',
  Ebt = 'EBT',
  GooglePay = 'GOOGLE_PAY',
  /** deprecated use 14 */
  Hsa = 'HSA',
  HsaFsaCard = 'HSA_FSA_CARD',
  KlarnaMultiUse = 'KLARNA_MULTI_USE',
  KlarnaSingleUse = 'KLARNA_SINGLE_USE',
  LegacyPaymentInstrumentTypeUnspecified = 'LEGACY_PAYMENT_INSTRUMENT_TYPE_UNSPECIFIED',
  LoyaltyWallet = 'LOYALTY_WALLET',
  Medicare = 'MEDICARE',
  Paypal = 'PAYPAL',
  RetailerGiftCard = 'RETAILER_GIFT_CARD',
  Venmo = 'VENMO'
}

export type Instacart_Customers_Commerce_Types_V1_SlimOffer = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer';
  buyMAmountGetNQtyWithXPercentOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetNQtyWithXPercentOffScenario>;
  buyMAmountGetXAmountOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXAmountOffScenario>;
  buyMAmountGetXPercentOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXPercentOffScenario>;
  buyMAmountOfAGetBForXAmountScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetBForXAmountScenario>;
  buyMAmountOfAGetXAmountOffOnBScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXAmountOffOnBScenario>;
  buyMAmountOfAGetXPercentOffOnBScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXPercentOffOnBScenario>;
  buyMQtyForXAmountScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyForXAmountScenario>;
  buyMQtyGetNForFreeScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForFreeScenario>;
  buyMQtyGetNQtyForXAmountScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForXAmountScenario>;
  buyMQtyGetNQtyWithXAmountOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXAmountOffScenario>;
  buyMQtyGetNQtyWithXPercentOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXPercentOffScenario>;
  buyMQtyGetXAmountOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXAmountOffScenario>;
  buyMQtyGetXPercentOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXPercentOffScenario>;
  buyMQtyOfAGetBForXAmountScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetBForXAmountScenario>;
  buyMQtyOfAGetXAmountOffOnBScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXAmountOffOnBScenario>;
  buyMQtyOfAGetXPercentOffOnBScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXPercentOffOnBScenario>;
  buyMQtyOrMoreGetXPercentOffScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOrMoreGetXPercentOffScenario>;
  buyMRangeQtyGetXPercentOffTieredScenario?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffTieredScenario>;
  conditionRewardString?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountCategoryId?: Maybe<Scalars['BigInt']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  fundingDetail?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingDetail>;
  imageUrl?: Maybe<Scalars['String']>;
  metadata?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_Metadata>;
  name?: Maybe<Scalars['String']>;
  offerConditions?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferCondition>>>;
  offerExternalReference?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
  offerRequestKey?: Maybe<Scalars['String']>;
  offerRewards?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferReward>>>;
  offerSource?: Maybe<Scalars['String']>;
  offerStackability?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferStackability>;
  offerType?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferType>;
  promoCode?: Maybe<Scalars['String']>;
  redemptionLimits?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferRedemptionLimits>;
  startsAt?: Maybe<Scalars['DateTime']>;
  terms?: Maybe<Scalars['String']>;
  uncategorizedScenario?: Maybe<Scalars['instacart_customers_commerce_types_v1_SlimOffer_UncategorizedScenario']>;
};

/** Buy M amount of eligible products and get additional N qty with X percent off. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetNQtyWithXPercentOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMAmountGetNQtyWithXPercentOffScenario';
  mAmount?: Maybe<Google_Type_Money>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/** Buy M amount of eligible products and get additional N qty with X percent off. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetNQtyWithXPercentOffScenario_Input = {
  mAmount?: InputMaybe<Google_Type_Money_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/** Buy M amount of eligible products and get X amount off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXAmountOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMAmountGetXAmountOffScenario';
  mAmount?: Maybe<Google_Type_Money>;
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M amount of eligible products and get X amount off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXAmountOffScenario_Input = {
  mAmount?: InputMaybe<Google_Type_Money_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/** Buy M amount of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXPercentOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMAmountGetXPercentOffScenario';
  mAmount?: Maybe<Google_Type_Money>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/** Buy M amount of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXPercentOffScenario_Input = {
  mAmount?: InputMaybe<Google_Type_Money_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/**
 * Buy M amount of A and get B for amount X (set price).
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetBForXAmountScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMAmountOfAGetBForXAmountScenario';
  mAmount?: Maybe<Google_Type_Money>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/**
 * Buy M amount of A and get B for amount X (set price).
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetBForXAmountScenario_Input = {
  mAmount?: InputMaybe<Google_Type_Money_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/**
 * Buy M amount of A and get X amount off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXAmountOffOnBScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMAmountOfAGetXAmountOffOnBScenario';
  mAmount?: Maybe<Google_Type_Money>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/**
 * Buy M amount of A and get X amount off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXAmountOffOnBScenario_Input = {
  mAmount?: InputMaybe<Google_Type_Money_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/**
 * Buy M amount of A and get X% off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXPercentOffOnBScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMAmountOfAGetXPercentOffOnBScenario';
  mAmount?: Maybe<Google_Type_Money>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/**
 * Buy M amount of A and get X% off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXPercentOffOnBScenario_Input = {
  mAmount?: InputMaybe<Google_Type_Money_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/** Buy M qty of eligible products and for a total price of X. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyForXAmountScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyForXAmountScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and for a total price of X. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyForXAmountScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/** Buy M qty of eligible products and get additional N qty for free. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForFreeScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyGetNQtyForFreeScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/** Buy M qty of eligible products and get additional N qty for free. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForFreeScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/** Buy M qty of eligible products and be eligible to buy N eligible products for a total price of X. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForXAmountScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyGetNQtyForXAmountScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and be eligible to buy N eligible products for a total price of X. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForXAmountScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/** Buy M qty of eligible products and get additional N qty with X amount off. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXAmountOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyGetNQtyWithXAmountOffScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get additional N qty with X amount off. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXAmountOffScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/** Buy M qty of eligible products and get additional N qty with X percent off. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXPercentOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyGetNQtyWithXPercentOffScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/** Buy M qty of eligible products and get additional N qty with X percent off. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXPercentOffScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/** Buy M qty of eligible products and get X (monetary value) off the original price. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXAmountOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyGetXAmountOffScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get X (monetary value) off the original price. */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXAmountOffScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/** Buy M qty of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXPercentOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyGetXPercentOffScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/** Buy M qty of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXPercentOffScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/**
 * Buy M qty of A and get B for amount X (set price).
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetBForXAmountScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyOfAGetBForXAmountScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/**
 * Buy M qty of A and get B for amount X (set price).
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetBForXAmountScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/**
 * Buy M qty of A and get X amount off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXAmountOffOnBScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyOfAGetXAmountOffOnBScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xAmount?: Maybe<Google_Type_Money>;
};

/**
 * Buy M qty of A and get X amount off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXAmountOffOnBScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xAmount?: InputMaybe<Google_Type_Money_Input>;
};

/**
 * Buy M qty of A and get X% off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXPercentOffOnBScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyOfAGetXPercentOffOnBScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  nQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/**
 * Buy M qty of A and get X% off the price on B.
 * A and B could be different groups of product, collection or something else.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXPercentOffOnBScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  nQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/** Buy M qty or more qty of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOrMoreGetXPercentOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMQtyOrMoreGetXPercentOffScenario';
  mQty?: Maybe<Instacart_Types_V1_BigDecimal>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/** Buy M qty or more qty of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOrMoreGetXPercentOffScenario_Input = {
  mQty?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/** Buy M range qty of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMRangeQtyGetXPercentOffScenario';
  mQtyMax?: Maybe<Instacart_Types_V1_BigDecimal>;
  mQtyMin?: Maybe<Instacart_Types_V1_BigDecimal>;
  xPercent?: Maybe<Instacart_Types_V1_BigDecimal>;
};

/** Buy M range qty of eligible products and get X% off the price (of M). */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffScenario_Input = {
  mQtyMax?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  mQtyMin?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  xPercent?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
};

/**
 * An offer in a group of BuyMRangeQtyGetXPercentOffScenario offers that forms a tiered reward, where a higher quantity M
 * matches a higher ranked tier with a different reward percentage.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffTieredScenario = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_BuyMRangeQtyGetXPercentOffTieredScenario';
  currentTier?: Maybe<Scalars['BigInt']>;
  tiers?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffScenario>>>;
};

/**
 * An offer in a group of BuyMRangeQtyGetXPercentOffScenario offers that forms a tiered reward, where a higher quantity M
 * matches a higher ranked tier with a different reward percentage.
 */
export type Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffTieredScenario_Input = {
  currentTier?: InputMaybe<Scalars['BigInt']>;
  tiers?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffScenario_Input>>>;
};

export enum Instacart_Customers_Commerce_Types_V1_SlimOffer_DiscountRewardType {
  AmountOff = 'AMOUNT_OFF',
  DiscountRewardTypeUnspecified = 'DISCOUNT_REWARD_TYPE_UNSPECIFIED',
  /** Parts per 100,000. Used to represent fractional percent values (up to 3 decimal places) as an integer. i.e, 1.234 percent is 1234 per cent mille */
  PercentMille = 'PERCENT_MILLE',
  PercentOff = 'PERCENT_OFF',
  SetPrice = 'SET_PRICE',
  /** Used to set the specific price per unit e.g. buy 3 bananas or more for $2 each */
  SetPricePerUnit = 'SET_PRICE_PER_UNIT'
}

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingDetail = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_FundingDetail';
  /** the amount of budget available before the offer is exhausted */
  budgetLimit?: Maybe<Google_Type_Money>;
  fundingProviderInfo?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingProviderInformation>>>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingDetail_Input = {
  /** the amount of budget available before the offer is exhausted */
  budgetLimit?: InputMaybe<Google_Type_Money_Input>;
  fundingProviderInfo?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingProviderInformation_Input>>>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingProviderInformation = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_FundingProviderInformation';
  /** percentage of funding for discount by the source. Can range from 0-100 */
  percent?: Maybe<Scalars['BigInt']>;
  /** who is funding this part */
  source?: Maybe<Instacart_Customers_Commerce_Types_V1_FundingSource>;
  /** name of the funding source */
  sourceName?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingProviderInformation_Input = {
  /** percentage of funding for discount by the source. Can range from 0-100 */
  percent?: InputMaybe<Scalars['BigInt']>;
  /** who is funding this part */
  source?: InputMaybe<Instacart_Customers_Commerce_Types_V1_FundingSource>;
  /** name of the funding source */
  sourceName?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_Input = {
  buyMAmountGetNQtyWithXPercentOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetNQtyWithXPercentOffScenario_Input>;
  buyMAmountGetXAmountOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXAmountOffScenario_Input>;
  buyMAmountGetXPercentOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountGetXPercentOffScenario_Input>;
  buyMAmountOfAGetBForXAmountScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetBForXAmountScenario_Input>;
  buyMAmountOfAGetXAmountOffOnBScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXAmountOffOnBScenario_Input>;
  buyMAmountOfAGetXPercentOffOnBScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMAmountOfAGetXPercentOffOnBScenario_Input>;
  buyMQtyForXAmountScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyForXAmountScenario_Input>;
  buyMQtyGetNForFreeScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForFreeScenario_Input>;
  buyMQtyGetNQtyForXAmountScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyForXAmountScenario_Input>;
  buyMQtyGetNQtyWithXAmountOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXAmountOffScenario_Input>;
  buyMQtyGetNQtyWithXPercentOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetNQtyWithXPercentOffScenario_Input>;
  buyMQtyGetXAmountOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXAmountOffScenario_Input>;
  buyMQtyGetXPercentOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyGetXPercentOffScenario_Input>;
  buyMQtyOfAGetBForXAmountScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetBForXAmountScenario_Input>;
  buyMQtyOfAGetXAmountOffOnBScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXAmountOffOnBScenario_Input>;
  buyMQtyOfAGetXPercentOffOnBScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOfAGetXPercentOffOnBScenario_Input>;
  buyMQtyOrMoreGetXPercentOffScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMQtyOrMoreGetXPercentOffScenario_Input>;
  buyMRangeQtyGetXPercentOffTieredScenario?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_BuyMRangeQtyGetXPercentOffTieredScenario_Input>;
  conditionRewardString?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCategoryId?: InputMaybe<Scalars['BigInt']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  fundingDetail?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_FundingDetail_Input>;
  imageUrl?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_Metadata_Input>;
  name?: InputMaybe<Scalars['String']>;
  offerConditions?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferCondition_Input>>>;
  offerExternalReference?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['String']>;
  offerRequestKey?: InputMaybe<Scalars['String']>;
  offerRewards?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferReward_Input>>>;
  offerSource?: InputMaybe<Scalars['String']>;
  offerStackability?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferStackability>;
  offerType?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferType>;
  promoCode?: InputMaybe<Scalars['String']>;
  redemptionLimits?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferRedemptionLimits_Input>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  terms?: InputMaybe<Scalars['String']>;
  uncategorizedScenario?: InputMaybe<Scalars['instacart_customers_commerce_types_v1_SlimOffer_UncategorizedScenario_Input']>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_Metadata = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_Metadata';
  termsAndConditions?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_TermsAndConditions>>>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_Metadata_Input = {
  termsAndConditions?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_TermsAndConditions_Input>>>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferCondition = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_OfferCondition';
  allowPartialApplication?: Maybe<Scalars['Boolean']>;
  collectionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  conditionProductIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  excludedCollectionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  maximumQuantity?: Maybe<Scalars['BigInt']>;
  minimumQuantity?: Maybe<Scalars['BigInt']>;
  minimumSpend?: Maybe<Google_Type_Money>;
  offerConditionType?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferConditionType>;
  serviceType?: Maybe<Instacart_Customers_Shared_V1_ServiceType>;
  taxonomyNodeIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export enum Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferConditionType {
  OfferConditionTypeBasket = 'OFFER_CONDITION_TYPE_BASKET',
  OfferConditionTypeBrands = 'OFFER_CONDITION_TYPE_BRANDS',
  OfferConditionTypeCategories = 'OFFER_CONDITION_TYPE_CATEGORIES',
  OfferConditionTypeCollections = 'OFFER_CONDITION_TYPE_COLLECTIONS',
  OfferConditionTypeCommerceClassifications = 'OFFER_CONDITION_TYPE_COMMERCE_CLASSIFICATIONS',
  OfferConditionTypeFees = 'OFFER_CONDITION_TYPE_FEES',
  OfferConditionTypeProducts = 'OFFER_CONDITION_TYPE_PRODUCTS',
  OfferConditionTypeRegions = 'OFFER_CONDITION_TYPE_REGIONS',
  OfferConditionTypeUnspecified = 'OFFER_CONDITION_TYPE_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferCondition_Input = {
  allowPartialApplication?: InputMaybe<Scalars['Boolean']>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  conditionProductIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedCollectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  maximumQuantity?: InputMaybe<Scalars['BigInt']>;
  minimumQuantity?: InputMaybe<Scalars['BigInt']>;
  minimumSpend?: InputMaybe<Google_Type_Money_Input>;
  offerConditionType?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferConditionType>;
  serviceType?: InputMaybe<Instacart_Customers_Shared_V1_ServiceType>;
  taxonomyNodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferRedemptionLimits = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_OfferRedemptionLimits';
  redemptionLimit?: Maybe<Scalars['BigInt']>;
  redemptionLimitPerOrder?: Maybe<Scalars['BigInt']>;
  redemptionLimitPerUser?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferRedemptionLimits_Input = {
  redemptionLimit?: InputMaybe<Scalars['BigInt']>;
  redemptionLimitPerOrder?: InputMaybe<Scalars['BigInt']>;
  redemptionLimitPerUser?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferReward = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_OfferReward';
  amountDiscount?: Maybe<Google_Type_Money>;
  discountRewardType?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_DiscountRewardType>;
  includeConditionGroup?: Maybe<Scalars['Boolean']>;
  maximumDiscount?: Maybe<Google_Type_Money>;
  offerPrice?: Maybe<Google_Type_Money>;
  offerRewardType?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferRewardType>;
  percentDiscount?: Maybe<Instacart_Types_V1_BigDecimal>;
  quantityLimit?: Maybe<Scalars['BigInt']>;
  rewardProductIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  serviceItemType?: Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_ServiceItemType>;
};

export enum Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferRewardType {
  OfferRewardTypeBasket = 'OFFER_REWARD_TYPE_BASKET',
  OfferRewardTypeBrands = 'OFFER_REWARD_TYPE_BRANDS',
  OfferRewardTypeCategories = 'OFFER_REWARD_TYPE_CATEGORIES',
  OfferRewardTypeCollections = 'OFFER_REWARD_TYPE_COLLECTIONS',
  OfferRewardTypeProducts = 'OFFER_REWARD_TYPE_PRODUCTS',
  OfferRewardTypeServiceItem = 'OFFER_REWARD_TYPE_SERVICE_ITEM',
  OfferRewardTypeUnspecified = 'OFFER_REWARD_TYPE_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferReward_Input = {
  amountDiscount?: InputMaybe<Google_Type_Money_Input>;
  discountRewardType?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_DiscountRewardType>;
  includeConditionGroup?: InputMaybe<Scalars['Boolean']>;
  maximumDiscount?: InputMaybe<Google_Type_Money_Input>;
  offerPrice?: InputMaybe<Google_Type_Money_Input>;
  offerRewardType?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferRewardType>;
  percentDiscount?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  quantityLimit?: InputMaybe<Scalars['BigInt']>;
  rewardProductIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  serviceItemType?: InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_ServiceItemType>;
};

export enum Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferStackability {
  /** Indicates the offer bypasses all stackability rules and always allowed to apply irrespective of other competing offers */
  AlwaysStackable = 'ALWAYS_STACKABLE',
  /** Indicates the offer is not stackable with other stackable and non-stackable offers */
  NonStackable = 'NON_STACKABLE',
  OfferStackabilityUnspecified = 'OFFER_STACKABILITY_UNSPECIFIED',
  /** Indicates the offer is stackable with any offer */
  Stackable = 'STACKABLE'
}

export enum Instacart_Customers_Commerce_Types_V1_SlimOffer_OfferType {
  AutoApply = 'AUTO_APPLY',
  CheckoutCodeBasedDiscount = 'CHECKOUT_CODE_BASED_DISCOUNT',
  Coupon = 'COUPON',
  OfferTypeUnspecified = 'OFFER_TYPE_UNSPECIFIED'
}

export enum Instacart_Customers_Commerce_Types_V1_SlimOffer_ServiceItemType {
  DeliveryFee = 'DELIVERY_FEE',
  ServiceItemTypeUnspecified = 'SERVICE_ITEM_TYPE_UNSPECIFIED'
}

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_TermsAndConditions = {
  __typename?: 'instacart_customers_commerce_types_v1_SlimOffer_TermsAndConditions';
  locale?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Commerce_Types_V1_SlimOffer_TermsAndConditions_Input = {
  locale?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_AddRetailerCampaignTreatmentsRequest_Input = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  treatments?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_CreateRetailerCampaignTreatmentParams_Input>>>;
};

export type Instacart_Customers_Coupons_V1_AddRetailerCampaignTreatmentsResponse = {
  __typename?: 'instacart_customers_coupons_v1_AddRetailerCampaignTreatmentsResponse';
  treatments?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatment>>>;
};

export enum Instacart_Customers_Coupons_V1_CampaignField {
  OfferData = 'OFFER_DATA',
  RetailerData = 'RETAILER_DATA'
}

export type Instacart_Customers_Coupons_V1_CampaignFilter = {
  __typename?: 'instacart_customers_coupons_v1_CampaignFilter';
  filterType?: Maybe<Instacart_Customers_Coupons_V1_FilterType>;
  filterValue?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CampaignFilter_Input = {
  filterType?: InputMaybe<Instacart_Customers_Coupons_V1_FilterType>;
  filterValue?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Customers_Coupons_V1_CampaignObjective {
  Acquisition = 'ACQUISITION',
  Activation = 'ACTIVATION',
  Awareness = 'AWARENESS',
  Custom = 'CUSTOM',
  Invalid = 'INVALID',
  Loyalty = 'LOYALTY',
  Merchandising = 'MERCHANDISING',
  Moment = 'MOMENT',
  Occasion = 'OCCASION',
  Reactivation = 'REACTIVATION'
}

export type Instacart_Customers_Coupons_V1_CampaignReference = {
  __typename?: 'instacart_customers_coupons_v1_CampaignReference';
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CampaignReference_Input = {
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CampaignRouletteFeature = {
  __typename?: 'instacart_customers_coupons_v1_CampaignRouletteFeature';
  featureName?: Maybe<Scalars['String']>;
  featureVariant?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CampaignRouletteFeature_Input = {
  featureName?: InputMaybe<Scalars['String']>;
  featureVariant?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CampaignTreatmentTask = {
  __typename?: 'instacart_customers_coupons_v1_CampaignTreatmentTask';
  /** [key: value] pairs matching the fields from TreatmentTaskSchema */
  definitionParams?: Maybe<Scalars['JSON']>;
  /** string value from TreatmentTaskTemplate message */
  definitionType?: Maybe<Scalars['String']>;
  treatmentPolicyId?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CampaignTreatmentTaskRequestParams_Input = {
  /** [key: value] pairs matching the fields from TreatmentTaskSchema */
  definitionParams?: InputMaybe<Scalars['JSON']>;
  /** string value from TreatmentTaskTemplate message */
  definitionType?: InputMaybe<Scalars['String']>;
  taskDescription?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CancelMomentRequest_Input = {
  momentId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_CancelMomentResponse = {
  __typename?: 'instacart_customers_coupons_v1_CancelMomentResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Coupons_V1_CancelRetailerCampaignRequest_Input = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_CancelRetailerCampaignResponse = {
  __typename?: 'instacart_customers_coupons_v1_CancelRetailerCampaignResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Coupons_V1_Collection = {
  __typename?: 'instacart_customers_coupons_v1_Collection';
  id?: Maybe<Scalars['BigInt']>;
  products?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_Product>>>;
};

export type Instacart_Customers_Coupons_V1_CreateMomentRequest_Input = {
  campaignObjective?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignObjective>;
  campaignSubmissionDeadline?: InputMaybe<Scalars['DateTime']>;
  discoverability?: InputMaybe<Instacart_Customers_Coupons_V1_Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  templateId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_CreateMomentResponse = {
  __typename?: 'instacart_customers_coupons_v1_CreateMomentResponse';
  moment?: Maybe<Instacart_Customers_Coupons_V1_Moment>;
};

export type Instacart_Customers_Coupons_V1_CreateRetailerCampaignRequest_Input = {
  campaignObjective?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignObjective>;
  campaignReference?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignReference_Input>;
  /** This is a country_id that is a number, not to be confused with country code. */
  countryId?: InputMaybe<Scalars['String']>;
  countryIdValue?: InputMaybe<Scalars['BigInt']>;
  couponCount?: InputMaybe<Scalars['BigInt']>;
  creatorIppUserId?: InputMaybe<Scalars['BigInt']>;
  discountPolicyIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  discoverability?: InputMaybe<Instacart_Customers_Coupons_V1_Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_CampaignFilter_Input>>>;
  ippUserCanonicalUuid?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use targeting_rules.lookback_window_days instead. */
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  marketingMessagingDetails?: InputMaybe<Instacart_Customers_Coupons_V1_MarketingMessagingDetails_Input>;
  name?: InputMaybe<Scalars['String']>;
  narrowSearchTerms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offerDurationInDays?: InputMaybe<Scalars['BigInt']>;
  offers?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_Input>>>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerAgnostic?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  rouletteFeature?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignRouletteFeature_Input>;
  schedule?: InputMaybe<Scalars['String']>;
  segmentId?: InputMaybe<Scalars['String']>;
  /** indicate whether the treatment policy should be created separately for each offer */
  separateTreatmentPolicies?: InputMaybe<Scalars['Boolean']>;
  skipCreateCouponCode?: InputMaybe<Scalars['Boolean']>;
  slotIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  /** Deprecated: Use targeting_rules instead. */
  targeting?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_Targeting>>>;
  targetingRules?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_TargetingRules_Input>>>;
  templateId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  treatmentTask?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignTreatmentTaskRequestParams_Input>;
  validForDays?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_CreateRetailerCampaignTreatmentParams_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  treatmentDetails?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentDetails_Input>>>;
  treatmentType?: InputMaybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentType>;
};

export enum Instacart_Customers_Coupons_V1_Discoverability {
  /** ADMIN_ONLY means the campaign should not be discoverable on IC. It's only for IPP usage by internal users */
  AdminOnly = 'ADMIN_ONLY',
  /** BROWSE means the campaign will be discoverable when user browse the app or web, it's the current behavior of RFM campaigns. */
  Browse = 'BROWSE',
  /** COUPON_CODE means the campaign will be discoverable only by a coupon code */
  CouponCode = 'COUPON_CODE',
  /** CRM means the campaign will be discoverable only by a CRM (eg. Braze) email send. */
  Crm = 'CRM',
  /** DIRECT_LINK means the campaign will be discoverable only when user click on a link, or scan a QR code. */
  DirectLink = 'DIRECT_LINK',
  DiscoverabilityUnknown = 'DISCOVERABILITY_UNKNOWN'
}

export enum Instacart_Customers_Coupons_V1_FilterType {
  FilterTypeAdminOnly = 'FILTER_TYPE_ADMIN_ONLY',
  FilterTypeCollectionId = 'FILTER_TYPE_COLLECTION_ID',
  FilterTypeCollectionSlug = 'FILTER_TYPE_COLLECTION_SLUG',
  FilterTypeCreatedBy = 'FILTER_TYPE_CREATED_BY',
  FilterTypeMoment = 'FILTER_TYPE_MOMENT',
  FilterTypeMomentApproved = 'FILTER_TYPE_MOMENT_APPROVED',
  FilterTypeMomentRejected = 'FILTER_TYPE_MOMENT_REJECTED',
  FilterTypeMomentSubmitted = 'FILTER_TYPE_MOMENT_SUBMITTED',
  FilterTypeOccasion = 'FILTER_TYPE_OCCASION',
  FilterTypeOccasionApproved = 'FILTER_TYPE_OCCASION_APPROVED',
  FilterTypeOccasionRejected = 'FILTER_TYPE_OCCASION_REJECTED',
  FilterTypeOccasionSubmitted = 'FILTER_TYPE_OCCASION_SUBMITTED',
  FilterTypePartnershipOfferId = 'FILTER_TYPE_PARTNERSHIP_OFFER_ID',
  FilterTypeTag = 'FILTER_TYPE_TAG',
  FilterTypeUnknown = 'FILTER_TYPE_UNKNOWN'
}

export type Instacart_Customers_Coupons_V1_GetCouponCodeExistsRequest_Input = {
  code?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_GetCouponCodeExistsResponse = {
  __typename?: 'instacart_customers_coupons_v1_GetCouponCodeExistsResponse';
  couponCodeExists?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Coupons_V1_GetMomentParticipatingRetailersRequest_Input = {
  momentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_GetMomentParticipatingRetailersResponse = {
  __typename?: 'instacart_customers_coupons_v1_GetMomentParticipatingRetailersResponse';
  participatingRetailerCampaigns?: Maybe<Scalars['JSON']>;
  retailerMomentCampaigns?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerMomentCampaign>>>;
};

export type Instacart_Customers_Coupons_V1_GetMomentRequest_Input = {
  momentId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_GetMomentResponse = {
  __typename?: 'instacart_customers_coupons_v1_GetMomentResponse';
  moment?: Maybe<Instacart_Customers_Coupons_V1_Moment>;
};

export type Instacart_Customers_Coupons_V1_GetMomentsRequest_Input = {
  momentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Coupons_V1_GetMomentsResponse = {
  __typename?: 'instacart_customers_coupons_v1_GetMomentsResponse';
  moments?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_Moment>>>;
};

export type Instacart_Customers_Coupons_V1_GetRetailerCampaignRequest_Input = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_ListMomentsRequest_FilterSet_Input = {
  uiStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Customers_Coupons_V1_ListMomentsRequest_Input = {
  filters?: InputMaybe<Instacart_Customers_Coupons_V1_ListMomentsRequest_FilterSet_Input>;
  pagination?: InputMaybe<Instacart_Customers_Shared_V1_Pagination_Input>;
};

export type Instacart_Customers_Coupons_V1_ListMomentsResponse = {
  __typename?: 'instacart_customers_coupons_v1_ListMomentsResponse';
  moments?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_Moment>>>;
  pageInfo?: Maybe<Instacart_Customers_Shared_V1_PageInfo>;
};

export type Instacart_Customers_Coupons_V1_ListRetailerCampaignTreatmentsRequest_Input = {
  retailerCampaignIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_ListRetailerCampaignTreatmentsResponse = {
  __typename?: 'instacart_customers_coupons_v1_ListRetailerCampaignTreatmentsResponse';
  treatments?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatment>>>;
};

export type Instacart_Customers_Coupons_V1_ListRetailerCampaignsRequest_FilterSet_Input = {
  campaignObjective?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignObjective>;
  campaignReference?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignReference_Input>;
  discoverability?: InputMaybe<Instacart_Customers_Coupons_V1_Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  excludeRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_CampaignFilter_Input>>>;
  regions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  uiStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Customers_Coupons_V1_ListRetailerCampaignsRequest_Input = {
  /** Optional filters. */
  filters?: InputMaybe<Instacart_Customers_Coupons_V1_ListRetailerCampaignsRequest_FilterSet_Input>;
  /** Optional includeFields. */
  includeFields?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_CampaignField>>>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Instacart_Customers_Shared_V1_Pagination_Input>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_ListRetailerCampaignsResponse = {
  __typename?: 'instacart_customers_coupons_v1_ListRetailerCampaignsResponse';
  pageInfo?: Maybe<Instacart_Customers_Shared_V1_PageInfo>;
  retailerCampaigns?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerCampaign>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_MarketingMessagingDetails_Input = {
  experimentName?: InputMaybe<Scalars['String']>;
  includeExperiment?: InputMaybe<Scalars['Boolean']>;
  pushNotificationId?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  templateVariables?: InputMaybe<Scalars['JSON']>;
  userEmailOverrides?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Customers_Coupons_V1_Moment = {
  __typename?: 'instacart_customers_coupons_v1_Moment';
  campaigns?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerCampaign>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  momentCampaignId?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  submissionDeadline?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Coupons_V1_MomentCampaignStatus_Input = {
  campaignId?: InputMaybe<Scalars['BigInt']>;
  momentStatus?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_StatusMap_Input>>>;
};

export type Instacart_Customers_Coupons_V1_Product = {
  __typename?: 'instacart_customers_coupons_v1_Product';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryImageUrl?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_Retailer = {
  __typename?: 'instacart_customers_coupons_v1_Retailer';
  id?: Maybe<Scalars['BigInt']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaign = {
  __typename?: 'instacart_customers_coupons_v1_RetailerCampaign';
  budget?: Maybe<Google_Type_Money>;
  campaignObjective?: Maybe<Instacart_Customers_Coupons_V1_CampaignObjective>;
  campaignReference?: Maybe<Instacart_Customers_Coupons_V1_CampaignReference>;
  countryIdValue?: Maybe<Scalars['BigInt']>;
  couponCount?: Maybe<Scalars['BigInt']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creatorIppUserId?: Maybe<Scalars['BigInt']>;
  discountPolicyIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  discoverability?: Maybe<Instacart_Customers_Coupons_V1_Discoverability>;
  endsAt?: Maybe<Scalars['DateTime']>;
  filters?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_CampaignFilter>>>;
  fundingDetails?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  ippUserCanonicalUuid?: Maybe<Scalars['String']>;
  /** Deprecated: Use targeting_rules.lookback_window_days instead. */
  lookbackWindowDays?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  narrowSearchTerms?: Maybe<Array<Maybe<Scalars['String']>>>;
  offerDurationInDays?: Maybe<Scalars['BigInt']>;
  offerRequestKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  offerSummary?: Maybe<Scalars['String']>;
  regionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerData?: Maybe<Instacart_Customers_Coupons_V1_Retailer>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  rouletteFeature?: Maybe<Instacart_Customers_Coupons_V1_CampaignRouletteFeature>;
  /** Schedule is a cron expression string. ex "0 0 * * 2" */
  schedule?: Maybe<Scalars['String']>;
  segmentId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  storeConfigurationId?: Maybe<Scalars['BigInt']>;
  /** Deprecated: Use targeting_rules instead. */
  targeting?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_Targeting>>>;
  targetingRules?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_TargetingRules>>>;
  templateId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  treatmentTask?: Maybe<Instacart_Customers_Coupons_V1_CampaignTreatmentTask>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Days the personal coupon will expire once assigned to the user */
  validForDays?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignCreativeTreatment = {
  __typename?: 'instacart_customers_coupons_v1_RetailerCampaignCreativeTreatment';
  placementId?: Maybe<Scalars['BigInt']>;
  slotId?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignCreativeTreatment_Input = {
  placementId?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignOfferTreatment = {
  __typename?: 'instacart_customers_coupons_v1_RetailerCampaignOfferTreatment';
  offerDurationInDays?: Maybe<Scalars['BigInt']>;
  offerId?: Maybe<Scalars['BigInt']>;
  offerRequestKey?: Maybe<Scalars['String']>;
  slotId?: Maybe<Scalars['String']>;
  validForDays?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignOfferTreatment_Input = {
  offerDurationInDays?: InputMaybe<Scalars['BigInt']>;
  offerId?: InputMaybe<Scalars['BigInt']>;
  offerRequestKey?: InputMaybe<Scalars['String']>;
  slotId?: InputMaybe<Scalars['String']>;
  validForDays?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignResponse = {
  __typename?: 'instacart_customers_coupons_v1_RetailerCampaignResponse';
  errors?: Maybe<Scalars['JSON']>;
  offers?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer>>>;
  retailerCampaign?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaign>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignTreatment = {
  __typename?: 'instacart_customers_coupons_v1_RetailerCampaignTreatment';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  retailerCampaignId?: Maybe<Scalars['BigInt']>;
  slotIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  treatmentDetails?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentDetails>>>;
  treatmentType?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentDetails = {
  __typename?: 'instacart_customers_coupons_v1_RetailerCampaignTreatmentDetails';
  creativeTreatment?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignCreativeTreatment>;
  offerTreatment?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignOfferTreatment>;
};

export type Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentDetails_Input = {
  creativeTreatment?: InputMaybe<Instacart_Customers_Coupons_V1_RetailerCampaignCreativeTreatment_Input>;
  offerTreatment?: InputMaybe<Instacart_Customers_Coupons_V1_RetailerCampaignOfferTreatment_Input>;
};

export enum Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentType {
  Creative = 'CREATIVE',
  InvalidTreatment = 'INVALID_TREATMENT',
  Offer = 'OFFER'
}

export type Instacart_Customers_Coupons_V1_RetailerMomentCampaign = {
  __typename?: 'instacart_customers_coupons_v1_RetailerMomentCampaign';
  collectionData?: Maybe<Instacart_Customers_Coupons_V1_Collection>;
  offers?: Maybe<Array<Maybe<Instacart_Customers_Commerce_Types_V1_SlimOffer>>>;
  retailerCampaign?: Maybe<Instacart_Customers_Coupons_V1_RetailerCampaign>;
  retailerData?: Maybe<Instacart_Customers_Coupons_V1_Retailer>;
};

export type Instacart_Customers_Coupons_V1_StatusMap_Input = {
  momentId?: InputMaybe<Scalars['BigInt']>;
  momentStatus?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<Instacart_Customers_Coupons_V1_FilterType>;
};

export enum Instacart_Customers_Coupons_V1_Targeting {
  ActivatedCustomer = 'ACTIVATED_CUSTOMER',
  ChurnedCustomer = 'CHURNED_CUSTOMER',
  LoyaltyCustomer = 'LOYALTY_CUSTOMER',
  NewCustomer = 'NEW_CUSTOMER',
  NonLoyaltyCustomer = 'NON_LOYALTY_CUSTOMER',
  Unknown = 'UNKNOWN'
}

export type Instacart_Customers_Coupons_V1_TargetingRules = {
  __typename?: 'instacart_customers_coupons_v1_TargetingRules';
  lookbackWindowDays?: Maybe<Scalars['BigInt']>;
  targetingType?: Maybe<Instacart_Customers_Coupons_V1_Targeting>;
};

export type Instacart_Customers_Coupons_V1_TargetingRules_Input = {
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  targetingType?: InputMaybe<Instacart_Customers_Coupons_V1_Targeting>;
};

export type Instacart_Customers_Coupons_V1_UpdateMomentCampaignStatusRequest_Input = {
  momentCampaignStatus?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_MomentCampaignStatus_Input>>>;
  momentCampaigns?: InputMaybe<Scalars['JSON']>;
};

export type Instacart_Customers_Coupons_V1_UpdateMomentCampaignStatusResponse = {
  __typename?: 'instacart_customers_coupons_v1_UpdateMomentCampaignStatusResponse';
  campaigns?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerCampaign>>>;
};

export type Instacart_Customers_Coupons_V1_UpdateMomentRequest_Input = {
  campaignSubmissionDeadline?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  momentId?: InputMaybe<Scalars['BigInt']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Coupons_V1_UpdateMomentResponse = {
  __typename?: 'instacart_customers_coupons_v1_UpdateMomentResponse';
  moment?: Maybe<Instacart_Customers_Coupons_V1_Moment>;
};

export type Instacart_Customers_Coupons_V1_UpdateRetailerCampaignRequest_Input = {
  budget?: InputMaybe<Google_Type_Money_Input>;
  campaignObjective?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignObjective>;
  campaignReference?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignReference_Input>;
  couponCount?: InputMaybe<Scalars['BigInt']>;
  discoverability?: InputMaybe<Instacart_Customers_Coupons_V1_Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_CampaignFilter_Input>>>;
  /** Deprecated: Use targeting_rules.lookback_window instead. */
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  narrowSearchTerms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offers?: InputMaybe<Array<InputMaybe<Instacart_Customers_Commerce_Types_V1_SlimOffer_Input>>>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  rouletteFeature?: InputMaybe<Instacart_Customers_Coupons_V1_CampaignRouletteFeature_Input>;
  schedule?: InputMaybe<Scalars['String']>;
  segmentId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  /** Deprecated: Use targeting_rules instead. */
  targeting?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_Targeting>>>;
  targetingRules?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_TargetingRules_Input>>>;
};

export type Instacart_Customers_Coupons_V1_UpdateRetailerCampaignTreatmentParams_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  treatmentDetails?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentDetails_Input>>>;
  treatmentType?: InputMaybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatmentType>;
};

export type Instacart_Customers_Coupons_V1_UpdateRetailerCampaignTreatmentsRequest_Input = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  treatments?: InputMaybe<Array<InputMaybe<Instacart_Customers_Coupons_V1_UpdateRetailerCampaignTreatmentParams_Input>>>;
};

export type Instacart_Customers_Coupons_V1_UpdateRetailerCampaignTreatmentsResponse = {
  __typename?: 'instacart_customers_coupons_v1_UpdateRetailerCampaignTreatmentsResponse';
  treatments?: Maybe<Array<Maybe<Instacart_Customers_Coupons_V1_RetailerCampaignTreatment>>>;
};

export type Instacart_Customers_Express_V1_GetCurrentExpressMemberStatusForUserParameters_Input = {
  requestContext?: InputMaybe<Instacart_Customers_Shared_V1_RequestContext_Input>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Express_V1_GetCurrentExpressMemberStatusForUserRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Express_V1_GetCurrentExpressMemberStatusForUserParameters_Input>;
};

export type Instacart_Customers_Express_V1_GetCurrentExpressMemberStatusForUserResponse = {
  __typename?: 'instacart_customers_express_v1_GetCurrentExpressMemberStatusForUserResponse';
  isExpressMember?: Maybe<Scalars['Boolean']>;
};

/** Get loyalty cards by card number */
export type Instacart_Customers_Loyalty_V1_GetLoyaltyCardsByCardNumberRequest_Input = {
  /** Card number. */
  cardNumber?: InputMaybe<Scalars['String']>;
  /** Retailer IDs. */
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Loyalty_V1_GetLoyaltyCardsByCardNumberResponse = {
  __typename?: 'instacart_customers_loyalty_v1_GetLoyaltyCardsByCardNumberResponse';
  /** Loyalty cards. */
  loyaltyCards?: Maybe<Array<Maybe<Instacart_Customers_Loyalty_V1_LoyaltyCard>>>;
};

/** Optional filters for the operation. */
export type Instacart_Customers_Loyalty_V1_GetLoyaltyCardsForUserRequest_Filter_Input = {
  /** Filter to Limit the loyalty card retrieval to the specified retailers. */
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Loyalty_V1_GetLoyaltyCardsForUserRequest_Input = {
  /** Optional filters, if applicable. */
  filter?: InputMaybe<Instacart_Customers_Loyalty_V1_GetLoyaltyCardsForUserRequest_Filter_Input>;
  /** The pagination controls for the request. */
  pagination?: InputMaybe<Instacart_Customers_Shared_V1_Pagination_Input>;
  /** The Request Context. */
  requestContext?: InputMaybe<Instacart_Customers_Shared_V1_RequestContext_Input>;
  /** The user ID of the user to query loyalty cards for. */
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Loyalty_V1_GetLoyaltyCardsForUserResponse = {
  __typename?: 'instacart_customers_loyalty_v1_GetLoyaltyCardsForUserResponse';
  /** Loyalty cards that were found. */
  loyaltyCards?: Maybe<Array<Maybe<Instacart_Customers_Loyalty_V1_LoyaltyCard>>>;
  /** Pagination page info. */
  pageInfo?: Maybe<Instacart_Customers_Shared_V1_PageInfo>;
};

export type Instacart_Customers_Loyalty_V1_LoyaltyCard = {
  __typename?: 'instacart_customers_loyalty_v1_LoyaltyCard';
  /** Authentication type. */
  authenticatedType?: Maybe<Scalars['String']>;
  /** Loyalty card number. */
  cardNumber?: Maybe<Scalars['String']>;
  /** Loyalty card ID. */
  id?: Maybe<Scalars['BigInt']>;
  /** Retailer ID. */
  retailerId?: Maybe<Scalars['BigInt']>;
  /** Loyalty card status. */
  status?: Maybe<Instacart_Customers_Loyalty_V1_LoyaltyCard_Status>;
  /** User ID. */
  userId?: Maybe<Scalars['BigInt']>;
};

/** Loyalty card status enum. */
export enum Instacart_Customers_Loyalty_V1_LoyaltyCard_Status {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  PendingOtp = 'PENDING_OTP',
  StatusUnspecified = 'STATUS_UNSPECIFIED',
  Temp = 'TEMP',
  Valid = 'VALID'
}

export type Instacart_Customers_Orders_V1_GetOrderLinesRequest_Input = {
  /** Single order reference. */
  orderReference?: InputMaybe<Scalars['String']>;
  /**
   * Batched order reference call.
   * Limit: 20.
   */
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** RequestContext. */
  requestContext?: InputMaybe<Instacart_Customers_Shared_V1_RequestContext_Input>;
};

export type Instacart_Customers_Orders_V1_GetOrderLinesResponse = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse';
  /** If a single order_reference is passed, the single order item summary. */
  orderLines?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLines>;
  /** Mapped by input order_reference values. */
  orderLinesByOrderReference?: Maybe<Scalars['JSON']>;
};

/** See OrdersDomain::Api::Responses::ItemResponse. */
export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderItem';
  /** Item is alcoholic */
  alcoholic?: Maybe<Scalars['Boolean']>;
  /** Additional item external data provided by the retailer. */
  externalData?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItemExternalData>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Product category */
  productCategory?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem_ProductCategory>;
  /** Product codes */
  productCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  productId?: Maybe<Scalars['BigInt']>;
  /** Quantity including units displayed to customer. */
  quantity?: Maybe<Instacart_Types_V1_MeasuredQuantity>;
  /** Retailer lookup code. */
  retailerLookupCode?: Maybe<Scalars['String']>;
  /** Display size of item. */
  sizeDetails?: Maybe<Scalars['String']>;
  /** Price per unit displayed to customer. */
  unitPrice?: Maybe<Google_Type_Money>;
};

export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItemExternalData = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderItemExternalData';
  lineNum?: Maybe<Scalars['String']>;
  retailerRrc?: Maybe<Scalars['String']>;
  retailerUpc?: Maybe<Scalars['String']>;
  scanCode?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem_ProductCategory = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderItem_ProductCategory';
  l1Category?: Maybe<Scalars['String']>;
  l1CategoryId?: Maybe<Scalars['BigInt']>;
  l2Category?: Maybe<Scalars['String']>;
  l2CategoryId?: Maybe<Scalars['BigInt']>;
  l3Category?: Maybe<Scalars['String']>;
  l3CategoryId?: Maybe<Scalars['BigInt']>;
  l4Category?: Maybe<Scalars['String']>;
  l4CategoryId?: Maybe<Scalars['BigInt']>;
  l5Category?: Maybe<Scalars['String']>;
  l5CategoryId?: Maybe<Scalars['BigInt']>;
  l6Category?: Maybe<Scalars['String']>;
  l6CategoryId?: Maybe<Scalars['BigInt']>;
  productCategory?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['BigInt']>;
};

/** See OrdersDomain::Api::Responses::OrderItemResponse. */
export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderLine';
  /** Id of the shopper that added the order item. */
  addedByDriverId?: Maybe<Scalars['BigInt']>;
  /**
   * Specifies if the customer explicitly allowed a substitution.
   * Possible values:
   * nil - customer did not decide.
   * false - customer asked for no substitution.
   * true - customer asked for a substitute.
   */
  allowedSubstitution?: Maybe<Scalars['Boolean']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Price shown to customer at order time. */
  customerOrderedPrice?: Maybe<Google_Type_Money>;
  /** Price displayed at storefront. */
  customerPrice?: Maybe<Google_Type_Money>;
  /**
   * Customer specificed replacement at checkout/post-checkout.
   * Available only when allowed_substitution is True.
   */
  customerSelectedReplacement?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem>;
  /** Full price for this item. */
  fullPrice?: Maybe<Google_Type_Money>;
  /** This id maps the sequence in which items were ordered. */
  id?: Maybe<Scalars['BigInt']>;
  /** Original item requested at checkout. */
  item?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem>;
  /** A unique identifier for this OrderLine that can be exposed externally. */
  obfuscatedId?: Maybe<Scalars['String']>;
  /** Quantity including units ordered by customer. */
  orderedQuantity?: Maybe<Instacart_Types_V1_MeasuredQuantity>;
  replacementPolicy?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_ReplacementPolicy>;
  /** Special instructions about the order item. */
  specialInstructions?: Maybe<Scalars['String']>;
  /** Original requested item status. */
  status?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_Status>;
  /**
   * Substitute if replaced by shopper. If present, this is the current item
   * on the order.
   */
  substitute?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem>;
  /** The current status of the substitution for this item, if it exists. */
  substitutionStatus?: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_SubstitutionStatus>;
};

/** Chosen replacement policy for the order item. */
export enum Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_ReplacementPolicy {
  NoReplacements = 'NO_REPLACEMENTS',
  ReplacementPolicyUnspecified = 'REPLACEMENT_POLICY_UNSPECIFIED',
  ShoppersChoice = 'SHOPPERS_CHOICE',
  UsersChoice = 'USERS_CHOICE'
}

export enum Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_Status {
  /**
   * DEPRECATED
   * Replacement approved by customer.
   */
  DeprecatedApproved = 'DEPRECATED_APPROVED',
  /**
   * DEPRECATED
   * Replacement rejected by customer.
   */
  DeprecatedRejected = 'DEPRECATED_REJECTED',
  /** Original item requested found by shopper. */
  Found = 'FOUND',
  /** Not yet picked. */
  NotPicked = 'NOT_PICKED',
  /** Item replaced. */
  Replaced = 'REPLACED',
  StatusUnspecified = 'STATUS_UNSPECIFIED',
  /** Item to be refunded. */
  ToRefund = 'TO_REFUND'
}

export enum Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_SubstitutionStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  SubstitutionStatusUnspecified = 'SUBSTITUTION_STATUS_UNSPECIFIED'
}

/** Represents result set for an order. */
export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLines = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderLines';
  lineItems?: Maybe<Array<Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine>>>;
};

/**
 * Workflow States.
 * TODO(stuppy): Adds tests to ensure 1:1 with Domain API.
 */
export enum Instacart_Customers_Orders_V1_WorkflowState {
  Acknowledged = 'ACKNOWLEDGED',
  AcknowledgedForDelivery = 'ACKNOWLEDGED_FOR_DELIVERY',
  AcknowledgedForRunning = 'ACKNOWLEDGED_FOR_RUNNING',
  AtStore = 'AT_STORE',
  BagsCheck = 'BAGS_CHECK',
  BagsVerified = 'BAGS_VERIFIED',
  BrandNew = 'BRAND_NEW',
  Canceled = 'CANCELED',
  Checkout = 'CHECKOUT',
  CustomerSamples = 'CUSTOMER_SAMPLES',
  Delivered = 'DELIVERED',
  Delivering = 'DELIVERING',
  Notified = 'NOTIFIED',
  PendingIdentityVerification = 'PENDING_IDENTITY_VERIFICATION',
  PendingReschedule = 'PENDING_RESCHEDULE',
  Picking = 'PICKING',
  ReceiptSanityCheck = 'RECEIPT_SANITY_CHECK',
  Returning = 'RETURNING',
  RunnerAwaiting = 'RUNNER_AWAITING',
  RunnerNotFound = 'RUNNER_NOT_FOUND',
  RxCheckout = 'RX_CHECKOUT',
  RxPicking = 'RX_PICKING',
  Staged = 'STAGED',
  StagedForCbt = 'STAGED_FOR_CBT',
  StagedForDelivery = 'STAGED_FOR_DELIVERY',
  StagingForCbt = 'STAGING_FOR_CBT',
  VerifyingReplacements = 'VERIFYING_REPLACEMENTS',
  WaitingForDelivery = 'WAITING_FOR_DELIVERY',
  WorkflowStateUnspecified = 'WORKFLOW_STATE_UNSPECIFIED'
}

/** Country API Objects // */
export type Instacart_Customers_Partners_V1_Country = {
  __typename?: 'instacart_customers_partners_v1_Country';
  /** two-letter country abbreviation */
  alpha_2?: Maybe<Scalars['String']>;
  /** three-letter country abbreviation */
  alpha_3?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Partners_V1_GetCountriesRequestParameters_Input = {
  /**
   * For searching by ID
   * If a search string is provided and a number or list of numbers can be extracted from it, search for those numbers as ids as well.
   */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** For searching by name or abbreviation */
  name?: InputMaybe<Scalars['String']>;
};

/** Country API Request // */
export type Instacart_Customers_Partners_V1_GetCountriesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetCountriesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** Countries API Response // */
export type Instacart_Customers_Partners_V1_GetCountriesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetCountriesResponse';
  countries?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Country>>>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequestParameters_Input = {
  /** For searching by code */
  code?: InputMaybe<Scalars['String']>;
  /** For searching by ID */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Required, we will only show a subset of postal codes by zone */
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesByZonesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetPostalCodesByZonesResponse';
  postalCodes?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_PostalCode>>>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesRequestParameters_Input = {
  /** For searching by code, optional */
  code?: InputMaybe<Scalars['String']>;
  /** For searching by multiple codes, optional. Suffixed with _array to avoid confusion with other param `code` */
  codesArray?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Codes that are unserviceable won't be included if set to true */
  excludeUnserviceable?: InputMaybe<Scalars['Boolean']>;
  /**
   * For searching by ID, optional
   * If a search string is provided and a number or list of numbers can be extracted from it, search for those numbers as ids as well.
   */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** PostalCodes API Request // */
export type Instacart_Customers_Partners_V1_GetPostalCodesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** PostalCodes API Response // */
export type Instacart_Customers_Partners_V1_GetPostalCodesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetPostalCodesResponse';
  postalCodes?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_PostalCode>>>;
};

export type Instacart_Customers_Partners_V1_GetRegionsRequestParameters_Input = {
  /** For searching by ID */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** For searching by name */
  name?: InputMaybe<Scalars['String']>;
};

/** Regions API Request // */
export type Instacart_Customers_Partners_V1_GetRegionsRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRegionsRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** Regions API Response // */
export type Instacart_Customers_Partners_V1_GetRegionsResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRegionsResponse';
  regions?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Region>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequestParameters_Input = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  includePhysicalZone?: InputMaybe<Scalars['Boolean']>;
  includeTimeZone?: InputMaybe<Scalars['Boolean']>;
  /** Optionally pass in the location_codes to filter by */
  locationCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Optionally pass in the name to filter by */
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRetailerLocationsByRetailerResponse';
  retailerLocations?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_RetailerLocation>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsRequestParameters_Input = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includePhysicalZone?: InputMaybe<Scalars['Boolean']>;
  includeTimeZone?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** RetailerLocations API Request // */
export type Instacart_Customers_Partners_V1_GetRetailerLocationsRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** RetailerLocations API Response // */
export type Instacart_Customers_Partners_V1_GetRetailerLocationsResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRetailerLocationsResponse';
  retailerLocations?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_RetailerLocation>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailerZonesRequestParameters_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  /** Optional for searching multiple retailers */
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  visibleOnly?: InputMaybe<Scalars['Boolean']>;
  /** Optional for getting all retailers within particular zones */
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailerZonesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerZonesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetRetailerZonesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRetailerZonesResponse';
  retailerZones?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_RetailerZone>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailersRequestParameters_Input = {
  /** For searching by IDs */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /**
   * For searching by name
   * Optional to only include retailers with visible=true AND at least one WHL with active=true
   */
  name?: InputMaybe<Scalars['String']>;
  onlyVisibleAndActive?: InputMaybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Partners_V1_GetRetailersRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailersRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetRetailersResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRetailersResponse';
  retailers?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Retailer>>>;
};

export type Instacart_Customers_Partners_V1_GetZonesByRegionRequestParameters_Input = {
  /** For searching by ID */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** For searching by name */
  name?: InputMaybe<Scalars['String']>;
  /** Required, we will only show a subset of zones by region */
  regionId?: InputMaybe<Scalars['BigInt']>;
};

/** Zones API Request // */
export type Instacart_Customers_Partners_V1_GetZonesByRegionRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesByRegionRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetZonesByRegionResponse = {
  __typename?: 'instacart_customers_partners_v1_GetZonesByRegionResponse';
  zones?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Zone>>>;
};

export type Instacart_Customers_Partners_V1_GetZonesRequestParameters_Input = {
  /** For searching by IDs, optional */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /**
   * For searching by name, optional
   * If a search string is provided and a number or list of numbers can be extracted from it, search for those numbers as ids as well.
   */
  name?: InputMaybe<Scalars['String']>;
};

/** Zones API Request // */
export type Instacart_Customers_Partners_V1_GetZonesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** Zones API Response // */
export type Instacart_Customers_Partners_V1_GetZonesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetZonesResponse';
  zones?: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Zone>>>;
};

/** PostalCode API Objects // */
export type Instacart_Customers_Partners_V1_PostalCode = {
  __typename?: 'instacart_customers_partners_v1_PostalCode';
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  state?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['BigInt']>;
};

/** Region API Objects // */
export type Instacart_Customers_Partners_V1_Region = {
  __typename?: 'instacart_customers_partners_v1_Region';
  countryId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
};

/** Retailers API Objects // */
export type Instacart_Customers_Partners_V1_Retailer = {
  __typename?: 'instacart_customers_partners_v1_Retailer';
  adminOnly?: Maybe<Scalars['Boolean']>;
  aliasName?: Maybe<Scalars['String']>;
  allowAlcohol?: Maybe<Scalars['Boolean']>;
  allowSpecialRequests?: Maybe<Scalars['Boolean']>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  backgroundImagePath?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  countrySlug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  franchiseeId?: Maybe<Scalars['BigInt']>;
  franchisorId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  logoBackgroundColor?: Maybe<Scalars['String']>;
  logoImagePath?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priceTransparencyDescription?: Maybe<Scalars['String']>;
  priceTransparencyFlag?: Maybe<Scalars['String']>;
  retailerType?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** RetailerLocations API Objects // */
export type Instacart_Customers_Partners_V1_RetailerLocation = {
  __typename?: 'instacart_customers_partners_v1_RetailerLocation';
  active?: Maybe<Scalars['Boolean']>;
  addressCounty?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['BigInt']>;
  bagFeeRateCents?: Maybe<Scalars['BigInt']>;
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  inventoryAreaId?: Maybe<Scalars['BigInt']>;
  issEnabled?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  locationCode?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  partnerPickup?: Maybe<Scalars['Boolean']>;
  physicalZone?: Maybe<Instacart_Customers_Partners_V1_Zone>;
  pickup?: Maybe<Scalars['Boolean']>;
  pickupOnly?: Maybe<Scalars['Boolean']>;
  postalCode?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  zipPlusFour?: Maybe<Scalars['String']>;
};

/** RetailerZone API Objects // */
export type Instacart_Customers_Partners_V1_RetailerZone = {
  __typename?: 'instacart_customers_partners_v1_RetailerZone';
  allowLocationInventory?: Maybe<Scalars['Boolean']>;
  delivery?: Maybe<Scalars['Boolean']>;
  handoffBatchingOnly?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  inventoryAreaId?: Maybe<Scalars['BigInt']>;
  pickup?: Maybe<Scalars['Boolean']>;
  promote?: Maybe<Scalars['Boolean']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  salePriceAllowed?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
  zoneId?: Maybe<Scalars['BigInt']>;
};

/** Zone API Objects // */
export type Instacart_Customers_Partners_V1_Zone = {
  __typename?: 'instacart_customers_partners_v1_Zone';
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partnership_Offer_V1_CreateRedemptionUrlsRequest_Input = {
  churnedDays?: InputMaybe<Scalars['BigInt']>;
  count?: InputMaybe<Scalars['BigInt']>;
  createdBy?: InputMaybe<Scalars['String']>;
  discountPolicyId?: InputMaybe<Scalars['BigInt']>;
  jobName?: InputMaybe<Scalars['String']>;
  omitUuid?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['String']>;
  userSegment?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Customers_Partnership_Offer_V1_CreateRedemptionUrlsResponse = {
  __typename?: 'instacart_customers_partnership_offer_v1_CreateRedemptionUrlsResponse';
  urlJob?: Maybe<Instacart_Customers_Partnership_Offer_V1_PartnershipManagementUrl>;
};

export type Instacart_Customers_Partnership_Offer_V1_GetPartnershipManagementUrlDownloadLinkRequest_Input = {
  partnershipManagementUrlId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partnership_Offer_V1_GetPartnershipManagementUrlDownloadLinkResponse = {
  __typename?: 'instacart_customers_partnership_offer_v1_GetPartnershipManagementUrlDownloadLinkResponse';
  filePath?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Partnership_Offer_V1_ListPartnershipManagementUrlsRequest_Input = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partnership_Offer_V1_ListPartnershipManagementUrlsResponse = {
  __typename?: 'instacart_customers_partnership_offer_v1_ListPartnershipManagementUrlsResponse';
  urlJobs?: Maybe<Array<Maybe<Instacart_Customers_Partnership_Offer_V1_PartnershipManagementUrl>>>;
};

export type Instacart_Customers_Partnership_Offer_V1_PartnershipManagementUrl = {
  __typename?: 'instacart_customers_partnership_offer_v1_PartnershipManagementUrl';
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueUrl?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Recipes_V1_Category = {
  __typename?: 'instacart_customers_recipes_v1_Category';
  contentCreatorId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Recipes_V1_CoreIngredient = {
  __typename?: 'instacart_customers_recipes_v1_CoreIngredient';
  id?: Maybe<Scalars['BigInt']>;
  term?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Recipes_V1_CoreIngredientUpdate_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Recipes_V1_GetCategoriesByNameRequest_Input = {
  /** content creator ID */
  contentCreatorId?: InputMaybe<Scalars['BigInt']>;
  /** limit the number of results */
  limit?: InputMaybe<Scalars['BigInt']>;
  /** name of the recipe category */
  name?: InputMaybe<Scalars['String']>;
  /** offset the results */
  offset?: InputMaybe<Scalars['BigInt']>;
  /** retailer ID */
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Recipes_V1_GetCategoriesByNameResponse = {
  __typename?: 'instacart_customers_recipes_v1_GetCategoriesByNameResponse';
  /** list of recipe categories */
  categories?: Maybe<Array<Maybe<Instacart_Customers_Recipes_V1_Category>>>;
};

export type Instacart_Customers_Recipes_V1_GetCoreIngredientsResponse = {
  __typename?: 'instacart_customers_recipes_v1_GetCoreIngredientsResponse';
  coreIngredients?: Maybe<Array<Maybe<Instacart_Customers_Recipes_V1_CoreIngredient>>>;
};

export type Instacart_Customers_Recipes_V1_GetRecipeByIdRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Recipes_V1_GetRecipeByIdResponse = {
  __typename?: 'instacart_customers_recipes_v1_GetRecipeByIdResponse';
  recipe?: Maybe<Instacart_Customers_Recipes_V1_Recipe>;
};

export type Instacart_Customers_Recipes_V1_IngestRecipesCsvRequest_Input = {
  /** CSV file lines */
  csv?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Customers_Recipes_V1_IngestRecipesCsvResponse = {
  __typename?: 'instacart_customers_recipes_v1_IngestRecipesCsvResponse';
  /** parsings script's response message */
  parserResponse?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Recipes_V1_Ingredient = {
  __typename?: 'instacart_customers_recipes_v1_Ingredient';
  coreIngredient?: Maybe<Instacart_Customers_Recipes_V1_CoreIngredient>;
  id?: Maybe<Scalars['BigInt']>;
  isKeyIngredient?: Maybe<Scalars['Boolean']>;
  quantityHigh?: Maybe<Scalars['Float']>;
  quantityLow?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Recipes_V1_IngredientGroup = {
  __typename?: 'instacart_customers_recipes_v1_IngredientGroup';
  id?: Maybe<Scalars['BigInt']>;
  ingredients?: Maybe<Array<Maybe<Instacart_Customers_Recipes_V1_Ingredient>>>;
  name?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Recipes_V1_IngredientGroupUpdate_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  ingredients?: InputMaybe<Array<InputMaybe<Instacart_Customers_Recipes_V1_IngredientUpdate_Input>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Recipes_V1_IngredientUpdate_Input = {
  coreIngredient?: InputMaybe<Instacart_Customers_Recipes_V1_CoreIngredientUpdate_Input>;
  id?: InputMaybe<Scalars['BigInt']>;
  isKeyIngredient?: InputMaybe<Scalars['Boolean']>;
  quantityHigh?: InputMaybe<Scalars['Float']>;
  quantityLow?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Recipes_V1_Recipe = {
  __typename?: 'instacart_customers_recipes_v1_Recipe';
  adminVisible?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  ingredientGroups?: Maybe<Array<Maybe<Instacart_Customers_Recipes_V1_IngredientGroup>>>;
  name?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
};

/**
 * Please keep the RecipeUpdate message a subset of the Recipe message
 * The idea is you should be able to mutate the Recipe message and send it back
 * as a RecipeUpdate message
 */
export type Instacart_Customers_Recipes_V1_RecipeUpdate_Input = {
  adminVisible?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  ingredientGroups?: InputMaybe<Array<InputMaybe<Instacart_Customers_Recipes_V1_IngredientGroupUpdate_Input>>>;
  name?: InputMaybe<Scalars['String']>;
  private?: InputMaybe<Scalars['Boolean']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Recipes_V1_UpdateRecipesRequest_Input = {
  recipes?: InputMaybe<Array<InputMaybe<Instacart_Customers_Recipes_V1_RecipeUpdate_Input>>>;
};

export type Instacart_Customers_Recipes_V1_UpdateRecipesResponse = {
  __typename?: 'instacart_customers_recipes_v1_UpdateRecipesResponse';
  recipes?: Maybe<Array<Maybe<Instacart_Customers_Recipes_V1_Recipe>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Collection = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Collection';
  collectionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultSortOrder?: Maybe<Scalars['String']>;
  dynamicallyFedStatic?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  filters?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Filter>>>;
  id?: Maybe<Scalars['BigInt']>;
  inventoryFileMappings?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_InventoryFileMapping>>>;
  name?: Maybe<Scalars['String']>;
  productCount?: Maybe<Scalars['BigInt']>;
  productIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerId?: Maybe<Scalars['BigInt']>;
  slug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  translations?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum Instacart_Customers_Retailer_Collections_Admin_V1_CollectionStatus {
  Active = 'ACTIVE',
  ActiveAdminOnly = 'ACTIVE_ADMIN_ONLY',
  EnumTypeStatusUnspecified = 'ENUM_TYPE_STATUS_UNSPECIFIED',
  Inactive = 'INACTIVE'
}

export type Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CollectionTranslation';
  localeCode?: Maybe<Instacart_Customers_Shared_V1_Locale>;
  name?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation_Input = {
  localeCode?: InputMaybe<Instacart_Customers_Shared_V1_Locale>;
  name?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionRequest_Input = {
  collectionType?: InputMaybe<Scalars['String']>;
  copyProductsExcludedProductIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  copyProductsFromCollection?: InputMaybe<Scalars['BigInt']>;
  defaultSortOrder?: InputMaybe<Scalars['String']>;
  dynamicallyFedStatic?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Filter_Input>>>;
  name?: InputMaybe<Scalars['String']>;
  productEvaluation?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  translations?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation_Input>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CreateCollectionResponse';
  collection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateFlyerClickableAreaRequest_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  dimensions?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerAreaDimension_Input>;
  flyerId?: InputMaybe<Scalars['BigInt']>;
  flyerPageId?: InputMaybe<Scalars['BigInt']>;
  navigateToCollection?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToCollection_Input>;
  navigateToFlyerPage?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToFlyerPage_Input>;
  navigateToRecipe?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToRecipe_Input>;
  navigateToUrl?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToUrl_Input>;
  openFlyerActivity?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_OpenFlyerActivity_Input>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateFlyerClickableAreaResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CreateFlyerClickableAreaResponse';
  flyerClickableArea?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerClickableArea>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CreateInvfMappingResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CreateRootNodeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DeleteCollectionResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteFlyerClickableAreasRequest_Input = {
  flyerId?: InputMaybe<Scalars['BigInt']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteFlyerClickableAreasResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DeleteFlyerClickableAreasResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DeleteInvfMappingResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DeleteNavigationNodeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DuplicateDepartmentRequest_Input = {
  destinationStoreConfigurations?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sourceDepartmentId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DuplicateDepartmentResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DuplicateDepartmentResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Filter = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Filter';
  collectionId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  intersectionGroup?: Maybe<Scalars['BigInt']>;
  ruleId?: Maybe<Scalars['BigInt']>;
  value?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Filter_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  intersectionGroup?: InputMaybe<Scalars['BigInt']>;
  ruleId?: InputMaybe<Scalars['BigInt']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Flyer = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Flyer';
  flyerPages?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerPage>>>;
  flyerRetailerLocation?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerRetailerLocation>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  storeConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  tag?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_FlyerActivity = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_FlyerActivity';
  collectionId?: Maybe<Scalars['BigInt']>;
  description?: Maybe<Scalars['String']>;
  flyerPageId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  title?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_FlyerAreaDimension = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_FlyerAreaDimension';
  height?: Maybe<Scalars['Float']>;
  heightPercent?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  widthPercent?: Maybe<Scalars['Float']>;
  xCoord?: Maybe<Scalars['Float']>;
  xPercent?: Maybe<Scalars['Float']>;
  yCoord?: Maybe<Scalars['Float']>;
  yPercent?: Maybe<Scalars['Float']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_FlyerAreaDimension_Input = {
  height?: InputMaybe<Scalars['Float']>;
  heightPercent?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
  widthPercent?: InputMaybe<Scalars['Float']>;
  xCoord?: InputMaybe<Scalars['Float']>;
  xPercent?: InputMaybe<Scalars['Float']>;
  yCoord?: InputMaybe<Scalars['Float']>;
  yPercent?: InputMaybe<Scalars['Float']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_FlyerClickableArea = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_FlyerClickableArea';
  active?: Maybe<Scalars['Boolean']>;
  dimensions?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerAreaDimension>;
  flyerPageId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  navigateToCollection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToCollection>;
  navigateToFlyerPage?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToFlyerPage>;
  navigateToRecipe?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToRecipe>;
  navigateToUrl?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToUrl>;
  openFlyerActivity?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_OpenFlyerActivity>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_FlyerPage = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_FlyerPage';
  flyerId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  image?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_FlyerRetailerLocation = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_FlyerRetailerLocation';
  endsAt?: Maybe<Scalars['DateTime']>;
  flyerId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  retailerLocationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  versionCode?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetCollectionResponse';
  collection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesRequest_Input = {
  dynamicFeedEligible?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetCollectionRulesResponse';
  rules?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Rule>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerActivitiesRequest_Input = {
  flyerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerActivitiesResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetFlyerActivitiesResponse';
  flyerActivities?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerActivity>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerClickableAreasRequest_Input = {
  flyerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerClickableAreasResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetFlyerClickableAreasResponse';
  flyerClickableAreas?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerClickableArea>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerRequest_Input = {
  flyerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetFlyerResponse';
  flyer?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Flyer>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerTagsRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetFlyerTagsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetFlyerTagsResponse';
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetNavigationTreeResponse';
  navigationConfiguration?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration>;
  nodes?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetRetailerCsvResponse';
  filePath?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_InventoryFileMapping = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_InventoryFileMapping';
  collectionSlug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  retailerCollectionId?: Maybe<Scalars['BigInt']>;
  retailerId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_ListCollectionProductsResponse';
  products?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsRequest_Input = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  withoutAdminCollections?: InputMaybe<Scalars['Boolean']>;
  withoutNavigationCollections?: InputMaybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_ListCollectionsResponse';
  collections?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListFlyersRequest_Input = {
  filter?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListFlyersRequest_OptionalFilter_Input>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListFlyersRequest_OptionalFilter_Input = {
  endsAt?: InputMaybe<Scalars['DateTime']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListFlyersResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_ListFlyersResponse';
  flyers?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Flyer>>>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListRetailerDepartmentsRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListRetailerDepartmentsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_ListRetailerDepartmentsResponse';
  departments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToCollection = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigateToCollection';
  collectionSlug?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToCollection_Input = {
  collectionSlug?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToFlyerPage = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigateToFlyerPage';
  flyerPageId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToFlyerPage_Input = {
  flyerPageId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToRecipe = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigateToRecipe';
  recipeId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToRecipe_Input = {
  recipeId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToUrl = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigateToUrl';
  url?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToUrl_Input = {
  url?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigationConfiguration';
  additionalStoreConfigurationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  alwaysPinned?: Maybe<Scalars['Boolean']>;
  inventoryCountDisabled?: Maybe<Scalars['Boolean']>;
  isSyncedFromSource?: Maybe<Scalars['Boolean']>;
  maxTopLevelNodes?: Maybe<Scalars['BigInt']>;
  sourceDomain?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration_Input = {
  additionalStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  alwaysPinned?: InputMaybe<Scalars['Boolean']>;
  inventoryCountDisabled?: InputMaybe<Scalars['Boolean']>;
  isSyncedFromSource?: InputMaybe<Scalars['Boolean']>;
  maxTopLevelNodes?: InputMaybe<Scalars['BigInt']>;
  sourceDomain?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigationNode';
  alcoholic?: Maybe<Scalars['Boolean']>;
  alwaysShow?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode>>>;
  contentType?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode_ContentType>;
  endDate?: Maybe<Scalars['DateTime']>;
  externallyManaged?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  isDynamic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['BigInt']>;
  productCount?: Maybe<Scalars['BigInt']>;
  retailerCollectionId?: Maybe<Scalars['BigInt']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionStatus>;
  storeConfigurationId?: Maybe<Scalars['BigInt']>;
  tileImageUrl?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Translation>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode_ContentType {
  Collections = 'COLLECTIONS',
  Products = 'PRODUCTS',
  Root = 'ROOT',
  Unspecified = 'UNSPECIFIED'
}

export type Instacart_Customers_Retailer_Collections_Admin_V1_OpenFlyerActivity = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_OpenFlyerActivity';
  flyerActivityId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_OpenFlyerActivity_Input = {
  flyerActivityId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesRequest_Input = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_OrderNavigationNodesResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Product = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Product';
  cachedProductId?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['BigInt']>;
  lookupCode?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['BigInt']>;
  retailerReferenceCode?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input = {
  cachedProductId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  lookupCode?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['BigInt']>;
  retailerReferenceCode?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_QueueNavigationMigrationResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Rule = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Rule';
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  valueSource?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSource>;
  valueType?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSource = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_RuleValueSource';
  options?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSourceOption>>>;
  query?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSourceOption = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_RuleValueSourceOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_SearchCollectionProductsResponse';
  products?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Translation = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Translation';
  localeCode?: Maybe<Instacart_Customers_Shared_V1_Locale>;
  name?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Translation_Input = {
  localeCode?: InputMaybe<Instacart_Customers_Shared_V1_Locale>;
  name?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  productsToAdd?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  productsToDelete?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateCollectionProductsResponse';
  collection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionRequest_Input = {
  collectionType?: InputMaybe<Scalars['String']>;
  defaultSortOrder?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Filter_Input>>>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  productsToAdd?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  productsToDelete?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  resetAllProducts?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  translations?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation_Input>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateCollectionResponse';
  collection?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyerClickableAreaRequest_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  dimensions?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerAreaDimension_Input>;
  flyerId?: InputMaybe<Scalars['BigInt']>;
  flyerPageId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  navigateToCollection?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToCollection_Input>;
  navigateToFlyerPage?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToFlyerPage_Input>;
  navigateToRecipe?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToRecipe_Input>;
  navigateToUrl?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigateToUrl_Input>;
  openFlyerActivity?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_OpenFlyerActivity_Input>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyerClickableAreaResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateFlyerClickableAreaResponse';
  flyerClickableArea?: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_FlyerClickableArea>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyersRequest_Input = {
  endsAt?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateFlyersResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateFlyersResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationRequest_Input = {
  navigationConfiguration?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration_Input>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateNavigationConfigurationResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeRequest_Input = {
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  alwaysShow?: InputMaybe<Scalars['Boolean']>;
  contentType?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode_ContentType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['BigInt']>;
  retailerCollectionId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionStatus>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  tileImageUrl?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Translation_Input>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpsertNavigationNodeResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** namespace entity id of the draft */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** status of the draft */
  status?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_IppDraftStatus>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_orchestrator_v1_DeleteIppDraftResponse';
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

/** Represent reasons for failure */
export enum Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason {
  /** Request context or payload incomplete or invalid */
  ErrorReasonBadRequest = 'ERROR_REASON_BAD_REQUEST',
  /** Unknown error */
  ErrorUnknown = 'ERROR_UNKNOWN',
  /** No error */
  NoError = 'NO_ERROR'
}

export enum Instacart_Customers_Retailer_Management_Orchestrator_V1_IppDraftStatus {
  Deleted = 'DELETED',
  DeleteError = 'DELETE_ERROR',
  Published = 'PUBLISHED',
  PublishError = 'PUBLISH_ERROR',
  Working = 'WORKING'
}

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** namespace entity id of the draft */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** status of the draft */
  status?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_IppDraftStatus>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_orchestrator_v1_PublishIppDraftResponse';
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_orchestrator_v1_RevertIppDraftResponse';
  /** the draft id of the reverted draft that was created */
  draftId?: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewRequest_Input = {
  /** draft id to create the preview from */
  draftId?: InputMaybe<Scalars['String']>;
  /** redirect url */
  redirectUrl?: InputMaybe<Scalars['String']>;
  /** retailer id */
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_CreateIppDraftPreviewResponse';
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
  /** auth token for the preview */
  previewAuthToken?: Maybe<Scalars['String']>;
  /** password for the preview */
  previewPassword?: Maybe<Scalars['String']>;
  /** url of the preview */
  previewUrl?: Maybe<Scalars['String']>;
  /** redirect url */
  redirectUrl?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftRequest_Input = {
  /** optional a list of draft content that represents the draft */
  contents?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Management_V1_IppDraftContent_Input>>>;
  /** list of draft components associated with the draft. */
  draftIds?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Management_V1_IppDraftComponent_Input>>>;
  /** the name of the draft */
  name?: InputMaybe<Scalars['String']>;
  /** a short description of a draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** the ID of the entity being drafted */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** partion of the draft (i.e. store_configuration, warehouse, contentpage, etc.) */
  partition?: InputMaybe<Scalars['String']>;
  /** associated partition id */
  partitionId?: InputMaybe<Scalars['BigInt']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_CreateIppDraftResponse';
  /** the draft id that was created */
  draftId?: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_DeleteIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** the ID of the entity being drafted */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_DeleteIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_DeleteIppDraftResponse';
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

/** Represent reasons for failure */
export enum Instacart_Customers_Retailer_Management_V1_ErrorReason {
  /** Request context or payload incomplete or invalid */
  ErrorReasonBadRequest = 'ERROR_REASON_BAD_REQUEST',
  /** Request is unauthorized */
  ErrorReasonUnauthorized = 'ERROR_REASON_UNAUTHORIZED',
  /** Unknown error */
  ErrorUnknown = 'ERROR_UNKNOWN',
  /** No error */
  NoError = 'NO_ERROR'
}

export type Instacart_Customers_Retailer_Management_V1_GetIppDraftByIdRequest_Input = {
  /** the draft id to retrieve */
  draftId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetIppDraftByIdResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_GetIppDraftByIdResponse';
  /** the draft based on the id */
  draft?: Maybe<Instacart_Customers_Retailer_Management_V1_IppDraft>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenRequest_Input = {
  /** encoded preview auth token to get preview */
  authToken?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_GetIppPreviewByAuthTokenResponse';
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  /** draft associated with preview */
  ippDraft?: Maybe<Instacart_Customers_Retailer_Management_V1_IppDraft>;
  /** preview object */
  ippPreview?: Maybe<Instacart_Customers_Retailer_Management_V1_IppPreview>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenRequest_Input = {
  /** passcode for preview */
  passcode?: InputMaybe<Scalars['String']>;
  /** preview_token for request */
  previewToken?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_GetPreviewAuthTokenResponse';
  /** auth_token */
  authToken?: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraft = {
  __typename?: 'instacart_customers_retailer_management_v1_IppDraft';
  /** date of creation */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** IPP user id of the user who created the draft */
  createdBy?: Maybe<Scalars['String']>;
  /** draft id that this draft was created from (for revert purposes) */
  createdFrom?: Maybe<Scalars['String']>;
  /** date of deletion */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** IPP user id that deleted the draft */
  deletedBy?: Maybe<Scalars['String']>;
  /** draft id */
  draftId?: Maybe<Scalars['String']>;
  /** list of draft components associated with the draft. */
  ippDraftComponents?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Management_V1_IppDraftComponent>>>;
  /** name of the draft */
  name?: Maybe<Scalars['String']>;
  /** namespace of the draft */
  namespace?: Maybe<Scalars['String']>;
  /** partion of the draft (i.e. store_configuration, warehouse, contentpage, etc.) */
  partition?: Maybe<Scalars['String']>;
  /** associated partition id */
  partitionId?: Maybe<Scalars['BigInt']>;
  /** date of publish */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** IPP user id that published the draft */
  publishedBy?: Maybe<Scalars['String']>;
  /** date last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraftComponent = {
  __typename?: 'instacart_customers_retailer_management_v1_IppDraftComponent';
  /** the domain's configuration draft id */
  configurationDraftId?: Maybe<Scalars['String']>;
  /** the draft blob */
  content?: Maybe<Instacart_Customers_Retailer_Management_V1_IppDraftContent>;
  /** the domain associated with the draft */
  domain?: Maybe<Scalars['String']>;
  /** the associated draft id */
  draftId?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraftComponent_Input = {
  /** the domain's configuration draft id */
  configurationDraftId?: InputMaybe<Scalars['String']>;
  /** the draft blob */
  content?: InputMaybe<Instacart_Customers_Retailer_Management_V1_IppDraftContent_Input>;
  /** the domain associated with the draft */
  domain?: InputMaybe<Scalars['String']>;
  /** the associated draft id */
  draftId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraftContent = {
  __typename?: 'instacart_customers_retailer_management_v1_IppDraftContent';
  /** the json blob representation of the draft */
  content?: Maybe<Scalars['String']>;
  /** content id */
  contentId?: Maybe<Scalars['BigInt']>;
  /** the type of draft content */
  contentType?: Maybe<Scalars['String']>;
  /** the version of the draft content */
  version?: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraftContent_Input = {
  /** the json blob representation of the draft */
  content?: InputMaybe<Scalars['String']>;
  /** content id */
  contentId?: InputMaybe<Scalars['BigInt']>;
  /** the type of draft content */
  contentType?: InputMaybe<Scalars['String']>;
  /** the version of the draft content */
  version?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Customers_Retailer_Management_V1_IppDraftStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Published = 'PUBLISHED',
  Unknown = 'UNKNOWN',
  Working = 'WORKING'
}

export type Instacart_Customers_Retailer_Management_V1_IppPreview = {
  __typename?: 'instacart_customers_retailer_management_v1_IppPreview';
  /** draft id associated with preview */
  draftId?: Maybe<Scalars['String']>;
  /** passcode for the preview */
  passcode?: Maybe<Scalars['String']>;
  /** token associated with preview */
  previewToken?: Maybe<Scalars['String']>;
  /** url of the preview */
  previewUrl?: Maybe<Scalars['String']>;
};

export enum Instacart_Customers_Retailer_Management_V1_ListIppDraftsColumns {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  PublishedAt = 'PUBLISHED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsFilterBy_Input = {
  /** author id of the draft */
  authorId?: InputMaybe<Scalars['String']>;
  /** created end date of the draft */
  createdEndDate?: InputMaybe<Scalars['DateTime']>;
  /** created start date of the draft */
  createdStartDate?: InputMaybe<Scalars['DateTime']>;
  /** deleted end date of the draft */
  deletedEndDate?: InputMaybe<Scalars['DateTime']>;
  /** deleted start date of the draft */
  deletedStartDate?: InputMaybe<Scalars['DateTime']>;
  /** id of the draft */
  draftId?: InputMaybe<Scalars['String']>;
  /** namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** the ID of the entity being drafted */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** partition of the draft */
  partition?: InputMaybe<Scalars['String']>;
  /** id of the partition */
  partitionId?: InputMaybe<Scalars['BigInt']>;
  /** published end date of the draft */
  publishedEndDate?: InputMaybe<Scalars['DateTime']>;
  /** published start date of the draft */
  publishedStartDate?: InputMaybe<Scalars['DateTime']>;
  /** query for searching drafts by name */
  query?: InputMaybe<Scalars['String']>;
  /** status of the draft */
  status?: InputMaybe<Instacart_Customers_Retailer_Management_V1_IppDraftStatus>;
};

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsOrderBy_Input = {
  column?: InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsColumns>;
  direction?: InputMaybe<Instacart_Customers_Retailer_Management_V1_OrderByDirection>;
};

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsRequest_Input = {
  /** filters for the draft list */
  filterBy?: InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsFilterBy_Input>;
  /** order drafts list by certain criteria */
  orderBy?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsOrderBy_Input>>>;
  /** pagination info */
  pagination?: InputMaybe<Instacart_Customers_Shared_V1_Pagination_Input>;
};

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_ListIppDraftsResponse';
  /** list of drafts */
  drafts?: Maybe<Array<Maybe<Instacart_Customers_Retailer_Management_V1_IppDraft>>>;
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
  /** page info for pagination */
  pageInfo?: Maybe<Instacart_Customers_Shared_V1_PageInfo>;
};

export enum Instacart_Customers_Retailer_Management_V1_OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Instacart_Customers_Retailer_Management_V1_PublishIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_PublishIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_PublishIppDraftResponse';
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_RevertIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_RevertIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_RevertIppDraftResponse';
  /** the draft id of the reverted draft that was created */
  draftId?: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage?: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason?: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_UpdateIppDraftContentRequest_Input = {
  /** a list of draft content that represents the draft */
  contents?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Management_V1_IppDraftContent_Input>>>;
  /** the draft id to update */
  draftId?: InputMaybe<Scalars['String']>;
  /** the name of the draft */
  draftName?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_UpdateIppDraftContentResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_UpdateIppDraftContentResponse';
  isSuccessful?: Maybe<Scalars['Boolean']>;
};

export enum Instacart_Customers_Shared_V1_Locale {
  EnAu = 'EN_AU',
  EnCa = 'EN_CA',
  EnUs = 'EN_US',
  EsUs = 'ES_US',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  LocaleUnspecified = 'LOCALE_UNSPECIFIED'
}

/** Represents pagination info extracted from Domain::PageInfo objects */
export type Instacart_Customers_Shared_V1_PageInfo = {
  __typename?: 'instacart_customers_shared_v1_PageInfo';
  /** The last cursor of the current page */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether or not there is a next page */
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

/** Pagination request parameters for Domain::Shared::Pagination */
export type Instacart_Customers_Shared_V1_Pagination_Input = {
  /** Cursor to start from when fetching. */
  after?: InputMaybe<Scalars['String']>;
  /** Count to return */
  limit?: InputMaybe<Scalars['Int']>;
};

export enum Instacart_Customers_Shared_V1_QuantityType {
  Each = 'EACH',
  Unspecified = 'UNSPECIFIED',
  Weight = 'WEIGHT'
}

/**
 * Contextual information used for identifying the
 * marketplace/pbi/connect configurations associated
 * with a request
 *
 * see Domain::Shared::RequestContext
 */
export type Instacart_Customers_Shared_V1_RequestContext_Input = {
  /**
   * Known as `oauth_application_id` in the corresponding domain object
   * But refers to the primary key on the `client_configurations` table.
   */
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  /** ISO numeric code */
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export enum Instacart_Customers_Shared_V1_ServiceType {
  Delivery = 'DELIVERY',
  Instore = 'INSTORE',
  Pickup = 'PICKUP',
  ScanAndPay = 'SCAN_AND_PAY',
  ServiceTypeUnspecified = 'SERVICE_TYPE_UNSPECIFIED',
  /** Used for non-physical services */
  Virtual = 'VIRTUAL'
}

export type Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoRequest_Input = {
  orderId?: InputMaybe<Scalars['BigInt']>;
  /** used for graphql authorization */
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoResponse = {
  __typename?: 'instacart_enterprise_boba_core_offers_v1_GetOrderDiscountInfoResponse';
  orderDiscountInfo?: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo>>>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails';
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyForXAmountScenario?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyForXAmountScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetNForFreeScenario?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForFreeScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetNQtyForXAmountScenario?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForXAmountScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetXAmountOffScenario?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXAmountOffScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetXPercentOffScenario?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXPercentOffScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyOrMoreGetXPercentOffScenario?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyOrMoreGetXPercentOffScenario>;
  /** Human readable condition and reward string for the discount */
  conditionRewardString?: Maybe<Scalars['String']>;
  /** Internal description of the discount */
  description?: Maybe<Scalars['String']>;
  /** Legacy discount_category_id */
  discountCategoryId?: Maybe<Scalars['BigInt']>;
  /** Discount end time */
  endsAt?: Maybe<Scalars['DateTime']>;
  /** Details about the funding information for this offer */
  fundingDetail?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingDetail>;
  /** Internal name of the discount */
  name?: Maybe<Scalars['String']>;
  /** List of offer conditions */
  offerConditions?: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferCondition>>>;
  /** Reference to the offer in any external system */
  offerExternalReference?: Maybe<Scalars['String']>;
  /** Unique offer identifier, will map to discount_policy_id post Unified Discounting Platform */
  offerId?: Maybe<Scalars['String']>;
  /** The offer request key */
  offerRequestKey?: Maybe<Scalars['String']>;
  /** List of offer rewards */
  offerRewards?: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferReward>>>;
  /** The source system which is ingesting this offer */
  offerSource?: Maybe<Scalars['String']>;
  /** Type of offer: coupon, auto apply etc */
  offerType?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferType>;
  /** Optional promo code for the discount */
  promoCode?: Maybe<Scalars['String']>;
  /** Indicates the maximum allowed redemptions of this offer */
  redemptionLimits?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRedemptionLimits>;
  /** Discount start time */
  startsAt?: Maybe<Scalars['DateTime']>;
  /** Legal terms and condition for the discount */
  terms?: Maybe<Scalars['String']>;
  /** When present, it indicates this offer cannot be categorized into any known scenario. */
  uncategorizedScenario?: Maybe<Scalars['instacart_enterprise_boba_core_types_v1_OfferDetails_UncategorizedScenario']>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BigDecimal';
  /**
   * Nano units of the amount (10^-9)
   * Must be same sign as units
   * Example: The value -1.25 is represented as units=-1 and nanos=-250000000
   */
  nanos?: Maybe<Scalars['Int']>;
  /** Whole units part of the amount */
  units?: Maybe<Scalars['BigInt']>;
};

/** Buy M qty of eligible products and for a total price of X. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyForXAmountScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyForXAmountScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_amount referred in the scenario name */
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get additional N qty for free. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForFreeScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetNQtyForFreeScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter n_qty referred in the scenario name */
  nQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
};

/** Buy M qty of eligible products and be eligible to buy N eligible products for a total price of X. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForXAmountScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetNQtyForXAmountScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter n_qty referred in the scenario name */
  nQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_amount referred in the scenario name */
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get X (monetary value) off the original price. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXAmountOffScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetXAmountOffScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_amount referred in the scenario name */
  xAmount?: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get X% off the price (of M). */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXPercentOffScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetXPercentOffScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_percent referred in the scenario name */
  xPercent?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
};

/** Buy M or more qty of eligible products and get X% off the price (of M). */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyOrMoreGetXPercentOffScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyOrMoreGetXPercentOffScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_percent referred in the scenario name */
  xPercent?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_DiscountRewardType {
  AmountOff = 'AMOUNT_OFF',
  DiscountRewardTypeUnspecified = 'DISCOUNT_REWARD_TYPE_UNSPECIFIED',
  PercentOff = 'PERCENT_OFF',
  SetPrice = 'SET_PRICE'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingDetail = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_FundingDetail';
  /** the amount of budget available before the offer is exhausted */
  budgetLimit?: Maybe<Google_Type_Money>;
  fundingProviderInfo?: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingProviderInformation>>>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingProviderInformation = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_FundingProviderInformation';
  /** percentage of funding for discount by the source. Can range from 0-100 */
  percent?: Maybe<Scalars['BigInt']>;
  /** who is funding this part */
  source?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingSource>;
  /** name of the funding source */
  sourceName?: Maybe<Scalars['String']>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingSource {
  Cpg = 'CPG',
  FundingSourceUnspecified = 'FUNDING_SOURCE_UNSPECIFIED',
  Instacart = 'INSTACART',
  Others = 'OTHERS',
  Retailer = 'RETAILER'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferCondition = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_OfferCondition';
  /** Indicates if offer can be applied partially when quantity conditions are not met */
  allowPartialApplication?: Maybe<Scalars['Boolean']>;
  /** List of collection ids for the condition */
  collectionIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** List of product ids for the condition */
  conditionProductIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /**
   * Maximum quantity allowed in a single application of this offer.
   * Same value as minimum_quantity is used to indicate exact quantity matching, whereas nil value is used when no upper limit is set.
   */
  maximumQuantity?: Maybe<Scalars['BigInt']>;
  /** Minimum quantity to have in cart (with any mix of the products) to qualify for discount */
  minimumQuantity?: Maybe<Scalars['BigInt']>;
  /** Minimum amount to spend to qualify for discount */
  minimumSpend?: Maybe<Google_Type_Money>;
  offerConditionType?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferConditionType>;
  /** List of taxonomy node ids for the condition */
  taxonomyNodeIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferConditionType {
  OfferConditionTypeBasket = 'OFFER_CONDITION_TYPE_BASKET',
  OfferConditionTypeBrands = 'OFFER_CONDITION_TYPE_BRANDS',
  OfferConditionTypeCategories = 'OFFER_CONDITION_TYPE_CATEGORIES',
  OfferConditionTypeCollections = 'OFFER_CONDITION_TYPE_COLLECTIONS',
  OfferConditionTypeCommerceClassifications = 'OFFER_CONDITION_TYPE_COMMERCE_CLASSIFICATIONS',
  OfferConditionTypeFees = 'OFFER_CONDITION_TYPE_FEES',
  OfferConditionTypeProducts = 'OFFER_CONDITION_TYPE_PRODUCTS',
  OfferConditionTypeRegions = 'OFFER_CONDITION_TYPE_REGIONS',
  OfferConditionTypeUnspecified = 'OFFER_CONDITION_TYPE_UNSPECIFIED'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRedemptionLimits = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_OfferRedemptionLimits';
  /** Total number of redemptions allowed on offers in the same group. */
  redemptionLimit?: Maybe<Scalars['BigInt']>;
  /** Number of redemptions allowed per order on offers in the same group */
  redemptionLimitPerOrder?: Maybe<Scalars['BigInt']>;
  /** Number of redemptions allowed per user on offers in the same group. */
  redemptionLimitPerUser?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferReward = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_OfferReward';
  /** Discount amount */
  amountDiscount?: Maybe<Google_Type_Money>;
  discountRewardType?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_DiscountRewardType>;
  /** Indicates if the reward group can include items in the condition group */
  includeConditionGroup?: Maybe<Scalars['Boolean']>;
  /** Discount sets price for the item */
  offerPrice?: Maybe<Google_Type_Money>;
  offerRewardType?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRewardType>;
  /** Discount percent value between 1-100. */
  percentDiscount?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Maximum quantity allowed to receive the reward in a single offer application */
  quantityLimit?: Maybe<Scalars['BigInt']>;
  /** List of reward product ids" */
  rewardProductIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRewardType {
  OfferRewardTypeBasket = 'OFFER_REWARD_TYPE_BASKET',
  OfferRewardTypeBrands = 'OFFER_REWARD_TYPE_BRANDS',
  OfferRewardTypeCategories = 'OFFER_REWARD_TYPE_CATEGORIES',
  OfferRewardTypeCollections = 'OFFER_REWARD_TYPE_COLLECTIONS',
  OfferRewardTypeProducts = 'OFFER_REWARD_TYPE_PRODUCTS',
  OfferRewardTypeUnspecified = 'OFFER_REWARD_TYPE_UNSPECIFIED'
}

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferType {
  AutoApply = 'AUTO_APPLY',
  Coupon = 'COUPON',
  OfferTypeUnspecified = 'OFFER_TYPE_UNSPECIFIED'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OrderDiscountInfo';
  /** Discount amount resutling from the evaluation of the offer */
  appliedAmount?: Maybe<Google_Type_Money>;
  /** ID of the discount_discountables record */
  discountDiscountableId?: Maybe<Scalars['BigInt']>;
  /** ID of the target the offer applies to */
  discountableId?: Maybe<Scalars['BigInt']>;
  /** Discountable entity: basket, item, customer fee etc. */
  discountableType?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo_DiscountableType>;
  /** Discount policy data */
  offer?: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails>;
  /** ID of the target order */
  orderId?: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo_DiscountableType {
  BasketLevelOffer = 'BASKET_LEVEL_OFFER',
  CustomerFeeOffer = 'CUSTOMER_FEE_OFFER',
  DiscountableTypeUnspecified = 'DISCOUNTABLE_TYPE_UNSPECIFIED',
  ItemLevelOffer = 'ITEM_LEVEL_OFFER'
}

export type Instacart_Enterprise_Frigate_Fulfillment_V1_AddressInformation = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_AddressInformation';
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Google_Type_LatLng>;
  streetName?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_CancelDashboardOrderRequest_Input = {
  /** Customer cancel reason, when required. */
  customerCancelReason?: InputMaybe<Instacart_Customers_Commerce_Types_V1_CustomerCancelReason_Input>;
  description?: InputMaybe<Scalars['String']>;
  /** OrderCancellationPolicyInput from GetOrderCancellationPolicyListResponse */
  orderCancellationPolicyInput?: InputMaybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicyInput_Input>;
  /** OrderChange token from GetOrderCancellationPolicyListResponse. Optional for certain policy inputs. */
  orderChangeToken?: InputMaybe<Scalars['String']>;
  /** Order Delivery ID. */
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<Instacart_Types_V1_RequestContext_Input>;
  /** Retailer App ID. */
  retailerAppId?: InputMaybe<Scalars['BigInt']>;
  /** User ID. */
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_CancelDashboardOrderResponse = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_CancelDashboardOrderResponse';
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_CustomerContext = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_CustomerContext';
  clientConfigurationId?: Maybe<Scalars['BigInt']>;
  countryId?: Maybe<Scalars['BigInt']>;
  storeConfigurationId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_CustomerInformation = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_CustomerInformation';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_DashboardOrder = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_DashboardOrder';
  /** Information about the address. */
  addressInformation?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_AddressInformation>;
  /** Customer context. Do we need this? */
  customerContext?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_CustomerContext>;
  /** Information about the customer. */
  customerInformation?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_CustomerInformation>;
  /** The external ID. */
  externalId?: Maybe<Scalars['String']>;
  /** Fulfillment metadata. */
  fulfillmentDetails?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_FulfillmentDetails>;
  /** The tip amount from the latest invoice. */
  latestTip?: Maybe<Google_Type_Money>;
  /** The tip amount from the latest invoice, string-formatted. */
  latestTipFormatted?: Maybe<Scalars['String']>;
  /** Details about the cancellation. */
  orderCancellationDetails?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationDetails>;
  /** Photos of the order delivery's dropoff. */
  orderDeliveryPhotoList?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderDeliveryPhotoList>;
  /** Events about the order lifecycle */
  orderHistoryEvents?: Maybe<Array<Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHistoryEvent>>>;
  orderId?: Maybe<Scalars['BigInt']>;
  orderLineList?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderLineList>;
  /** Information about the shopper. */
  shopperInformation?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_ShopperInformation>;
  /** The timezone of the sender. */
  timezone?: Maybe<Scalars['String']>;
  /** The user ID. */
  userId?: Maybe<Scalars['BigInt']>;
  /** The status of the order delivery. */
  workflowState?: Maybe<Instacart_Customers_Orders_V1_WorkflowState>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_FulfillmentDetails = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_FulfillmentDetails';
  addressId?: Maybe<Scalars['BigInt']>;
  estimatedDeliveryTime?: Maybe<Scalars['DateTime']>;
  estimatedPickupTime?: Maybe<Scalars['DateTime']>;
  isAlcoholic?: Maybe<Scalars['Boolean']>;
  numOfBags?: Maybe<Scalars['BigInt']>;
  orderHandlingDetails?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHandlingDetails>;
  previouslyRescheduled?: Maybe<Scalars['Boolean']>;
  retailerId?: Maybe<Scalars['BigInt']>;
  retailerLocationId?: Maybe<Scalars['BigInt']>;
  retailerLocationName?: Maybe<Scalars['String']>;
  retailerProvidedAmounts?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_RetailerProvidedAmounts>;
  serviceOptionId?: Maybe<Scalars['BigInt']>;
  windowEndsAt?: Maybe<Scalars['DateTime']>;
  windowStartsAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_GetDashboardOrderRequest_Input = {
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<Instacart_Types_V1_RequestContext_Input>;
  /** also known as `warehouse_id` internally */
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_GetDashboardOrderResponse = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_GetDashboardOrderResponse';
  dashboardOrder?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_DashboardOrder>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_GetOrderCancellationPolicyListRequest_Input = {
  /** Order Delivery ID. */
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<Instacart_Types_V1_RequestContext_Input>;
  /** Retailer App ID. */
  retailerAppId?: InputMaybe<Scalars['BigInt']>;
  /** Optional user ID to speed up Orders sharded query. */
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_GetOrderCancellationPolicyListResponse = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_GetOrderCancellationPolicyListResponse';
  /** OrderChange policy token. */
  orderChangeToken?: Maybe<Scalars['String']>;
  /** The list of OrderChangePolicies. */
  results?: Maybe<Array<Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy>>>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationDetails = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderCancellationDetails';
  cancellationCategory?: Maybe<Instacart_Customers_Commerce_Types_V1_OrderCancellationCategory>;
  cancellationOrigin?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationDetails_CancellationOrigin>;
  cancellationType?: Maybe<Instacart_Customers_Commerce_Types_V1_OrderCancellationType>;
};

export enum Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationDetails_CancellationOrigin {
  Customer = 'CUSTOMER',
  Instacart = 'INSTACART',
  Other = 'OTHER',
  Retailer = 'RETAILER',
  Shopper = 'SHOPPER'
}

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderCancellationPolicy';
  /** Selection details. */
  inputs?: Maybe<Array<Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicyInput>>>;
  notPermitted?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy_NotPermitted>;
  permitted?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy_Permitted>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicyInput = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderCancellationPolicyInput';
  label?: Maybe<Scalars['String']>;
  reasonType?: Maybe<Instacart_Customers_Commerce_Types_V1_OrderCancellationType>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicyInput_Input = {
  label?: InputMaybe<Scalars['String']>;
  reasonType?: InputMaybe<Instacart_Customers_Commerce_Types_V1_OrderCancellationType>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy_NotPermitted = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderCancellationPolicy_NotPermitted';
  /** Reason not permitted. Defaults to UNKNOWN. */
  reason?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy_NotPermitted_Reason>;
};

export enum Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy_NotPermitted_Reason {
  ReasonUnspecified = 'REASON_UNSPECIFIED',
  /** if an unrecognized reason is added upstream, it will be converted to UNKNOWN */
  Unknown = 'UNKNOWN'
}

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderCancellationPolicy_Permitted = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderCancellationPolicy_Permitted';
  /** Fees associated with the action. */
  cancelFee?: Maybe<Google_Type_Money>;
  /** When true, requires a customer_cancel_reason with input. */
  isCustomerCancelReasonRequired?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderDeliveryPhoto = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderDeliveryPhoto';
  id?: Maybe<Scalars['BigInt']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderDeliveryPhotoList = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderDeliveryPhotoList';
  areOrderDeliveryPhotosExpired?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Array<Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderDeliveryPhoto>>>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHandlingDetails = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderHandlingDetails';
  acceptableRetailerLocationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** Delivery to customer. */
  delivery?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHandlingDetails_DeliveryDetails>;
  /** Pickup from location. */
  pickup?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHandlingDetails_PickupDetails>;
  specialRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHandlingDetails_DeliveryDetails = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderHandlingDetails_DeliveryDetails';
  address?: Maybe<Google_Type_PostalAddress>;
  addressId?: Maybe<Scalars['BigInt']>;
  addressType?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Google_Type_LatLng>;
  customerManualLatLng?: Maybe<Google_Type_LatLng>;
  doorCode?: Maybe<Scalars['String']>;
  dropoffLocation?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHandlingDetails_PickupDetails = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderHandlingDetails_PickupDetails';
  address?: Maybe<Google_Type_PostalAddress>;
  addressId?: Maybe<Scalars['BigInt']>;
  coordinates?: Maybe<Google_Type_LatLng>;
  instructions?: Maybe<Scalars['String']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHistoryEvent = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderHistoryEvent';
  action?: Maybe<Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHistoryEvent_OrderHistoryEventAction>;
  actionedAt?: Maybe<Scalars['DateTime']>;
  windowEndsAt?: Maybe<Scalars['DateTime']>;
  windowStartsAt?: Maybe<Scalars['DateTime']>;
};

export enum Instacart_Enterprise_Frigate_Fulfillment_V1_OrderHistoryEvent_OrderHistoryEventAction {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Delivered = 'DELIVERED',
  Rescheduled = 'RESCHEDULED'
}

/** Represents result set for an order. */
export type Instacart_Enterprise_Frigate_Fulfillment_V1_OrderLineList = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_OrderLineList';
  results?: Maybe<Array<Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine>>>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_RetailerProvidedAmounts = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_RetailerProvidedAmounts';
  bagLabel?: Maybe<Scalars['String']>;
};

export type Instacart_Enterprise_Frigate_Fulfillment_V1_ShopperInformation = {
  __typename?: 'instacart_enterprise_frigate_fulfillment_v1_ShopperInformation';
  avatarUrl?: Maybe<Scalars['String']>;
  carModelAndColor?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
};

export type Instacart_Enterprise_Frigate_Post_Checkout_V1_EnterpriseChatMessage = {
  __typename?: 'instacart_enterprise_frigate_post_checkout_v1_EnterpriseChatMessage';
  /** The body of the message. */
  body?: Maybe<Scalars['String']>;
  /** The time of creation. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the message. */
  id?: Maybe<Scalars['BigInt']>;
  /**
   * The URL of the image in the message.
   * e.g. https://d2syimmrmqkckt.cloudfront.net/chatmessage/image/254f4bf8-2d13-11ec-8d3d-0242ac130003.jpg
   */
  image?: Maybe<Scalars['String']>;
  /** The order delivery ID. */
  orderDeliveryId?: Maybe<Scalars['BigInt']>;
  /** Whether or not the message was read. */
  read?: Maybe<Scalars['Boolean']>;
  /** Time the message was read at. */
  readAt?: Maybe<Scalars['DateTime']>;
  /** The recipient ID. */
  recipientId?: Maybe<Scalars['BigInt']>;
  /** The type of recipient. */
  recipientType?: Maybe<Scalars['String']>;
  /** The avatar URL of the sender. */
  senderAvatarUrl?: Maybe<Scalars['String']>;
  /** The display name of the sender. */
  senderDisplayName?: Maybe<Scalars['String']>;
  /** The sender ID. */
  senderId?: Maybe<Scalars['BigInt']>;
  /** The type of sender. */
  senderType?: Maybe<Scalars['String']>;
};

export type Instacart_Enterprise_Frigate_Post_Checkout_V1_GetEnterpriseChatMessageListRequest_Input = {
  /** The order delivery ID associated with the conversation. */
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<Instacart_Types_V1_RequestContext_Input>;
};

export type Instacart_Enterprise_Frigate_Post_Checkout_V1_GetEnterpriseChatMessageListResponse = {
  __typename?: 'instacart_enterprise_frigate_post_checkout_v1_GetEnterpriseChatMessageListResponse';
  /** The messages of the conversation. */
  results?: Maybe<Array<Maybe<Instacart_Enterprise_Frigate_Post_Checkout_V1_EnterpriseChatMessage>>>;
};

/** GetTenderTransactionsByWhlIdsRequest */
export type Instacart_Fulfillment_Shared_V1_GetTenderTransactionsByWhlIdsRequest_Input = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<Instacart_Fulfillment_Shared_V1_TenderTransaction_TenderTransactionState>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** GetTenderTransactionsByStoreIdsResponse */
export type Instacart_Fulfillment_Shared_V1_GetTenderTransactionsByWhlIdsResponse = {
  __typename?: 'instacart_fulfillment_shared_v1_GetTenderTransactionsByWhlIdsResponse';
  tenderTransactions?: Maybe<Array<Maybe<Instacart_Fulfillment_Shared_V1_TenderTransaction>>>;
};

/** Tender Merchant */
export type Instacart_Fulfillment_Shared_V1_TenderMerchant = {
  __typename?: 'instacart_fulfillment_shared_v1_TenderMerchant';
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['BigInt']>;
  warehouseLocationId?: Maybe<Scalars['BigInt']>;
  zip?: Maybe<Scalars['String']>;
};

/** Tender Transaction */
export type Instacart_Fulfillment_Shared_V1_TenderTransaction = {
  __typename?: 'instacart_fulfillment_shared_v1_TenderTransaction';
  amount?: Maybe<Google_Type_Money>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  state?: Maybe<Instacart_Fulfillment_Shared_V1_TenderTransaction_TenderTransactionState>;
  tenderMerchant?: Maybe<Instacart_Fulfillment_Shared_V1_TenderMerchant>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  warehouseId?: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Fulfillment_Shared_V1_TenderTransaction_TenderTransactionState {
  TenderTransactionStateCleared = 'TENDER_TRANSACTION_STATE_CLEARED',
  TenderTransactionStateCompletion = 'TENDER_TRANSACTION_STATE_COMPLETION',
  TenderTransactionStateDeclined = 'TENDER_TRANSACTION_STATE_DECLINED',
  TenderTransactionStateError = 'TENDER_TRANSACTION_STATE_ERROR',
  TenderTransactionStatePending = 'TENDER_TRANSACTION_STATE_PENDING',
  TenderTransactionStateUnknown = 'TENDER_TRANSACTION_STATE_UNKNOWN'
}

export enum Instacart_Growth_Crm_Messaging_Service_V1_CampaignStatus {
  CampaignStatusCancelled = 'CAMPAIGN_STATUS_CANCELLED',
  CampaignStatusCompleted = 'CAMPAIGN_STATUS_COMPLETED',
  CampaignStatusExportingSegment = 'CAMPAIGN_STATUS_EXPORTING_SEGMENT',
  CampaignStatusFailed = 'CAMPAIGN_STATUS_FAILED',
  CampaignStatusInProgress = 'CAMPAIGN_STATUS_IN_PROGRESS',
  CampaignStatusLoadingSegment = 'CAMPAIGN_STATUS_LOADING_SEGMENT',
  CampaignStatusPartiallyCompleted = 'CAMPAIGN_STATUS_PARTIALLY_COMPLETED',
  CampaignStatusPending = 'CAMPAIGN_STATUS_PENDING',
  CampaignStatusUnknown = 'CAMPAIGN_STATUS_UNKNOWN'
}

/** Attributes representing an Email Template */
export type Instacart_Growth_Crm_Messaging_Service_V1_EmailTemplate = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_EmailTemplate';
  body?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  preheader?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** Request object to fetch the status of a campaign */
export type Instacart_Growth_Crm_Messaging_Service_V1_GetCampaignStatusRequest_Input = {
  /** Associated ICMS campaign ID */
  extId?: InputMaybe<Scalars['BigInt']>;
  /** Internal ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** The warehouse id context for the request */
  warehouseId?: InputMaybe<Scalars['String']>;
};

/** Response object to fetch the status of a campaign */
export type Instacart_Growth_Crm_Messaging_Service_V1_GetCampaignStatusResponse = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_GetCampaignStatusResponse';
  experimentName?: Maybe<Scalars['String']>;
  failedUserCount?: Maybe<Scalars['BigInt']>;
  pendingUserCount?: Maybe<Scalars['BigInt']>;
  sendTypeTemplateMap?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_SendTypeTemplateMap>;
  sentUserCount?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_CampaignStatus>;
  totalUserCount?: Maybe<Scalars['BigInt']>;
};

/** Request object to fetch the size of a segment */
export type Instacart_Growth_Crm_Messaging_Service_V1_GetSegmentSizeRequest_Input = {
  /** The segment id to get the size for */
  segmentId?: InputMaybe<Scalars['String']>;
  /** The warehouse id context for the request */
  warehouseId?: InputMaybe<Scalars['String']>;
};

/** Response object to fetch the size of a segment */
export type Instacart_Growth_Crm_Messaging_Service_V1_GetSegmentSizeResponse = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_GetSegmentSizeResponse';
  /** The size of the segment */
  size?: Maybe<Scalars['BigInt']>;
};

/** Request object to fetch a template */
export type Instacart_Growth_Crm_Messaging_Service_V1_GetTemplateRequest_Input = {
  /** Which types of templates to include */
  includedSendTypes?: InputMaybe<Array<InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_SendType>>>;
  /** The template id to get */
  templateId?: InputMaybe<Scalars['String']>;
  /** Template Variables to inject in preview */
  templateVariables?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_TemplateVariables_Input>;
  /** The warehouse id context for the request */
  warehouseId?: InputMaybe<Scalars['String']>;
};

/** Response object to fetch a template */
export type Instacart_Growth_Crm_Messaging_Service_V1_GetTemplateResponse = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_GetTemplateResponse';
  template?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_Template>;
};

/** Request object to fetch list of segments */
export type Instacart_Growth_Crm_Messaging_Service_V1_ListSegmentsRequest_Input = {
  /** The warehouse id to list segments for */
  warehouseId?: InputMaybe<Scalars['String']>;
};

/** Response object to fetch list of segments */
export type Instacart_Growth_Crm_Messaging_Service_V1_ListSegmentsResponse = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_ListSegmentsResponse';
  /** The list of segments for the warehouse */
  segments?: Maybe<Array<Maybe<Instacart_Growth_Crm_Messaging_Service_V1_Segment>>>;
};

/** Request object to fetch list of templates */
export type Instacart_Growth_Crm_Messaging_Service_V1_ListTemplatesRequest_Input = {
  /** Which types of templates to include */
  includedSendTypes?: InputMaybe<Array<InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_SendType>>>;
  /** Template Variables to inject in previews */
  templateVariables?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_TemplateVariables_Input>;
  /** The warehouse id context for the request */
  warehouseId?: InputMaybe<Scalars['String']>;
};

/** Response object to fetch list of templates */
export type Instacart_Growth_Crm_Messaging_Service_V1_ListTemplatesResponse = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_ListTemplatesResponse';
  templates?: Maybe<Array<Maybe<Instacart_Growth_Crm_Messaging_Service_V1_Template>>>;
};

/** Attributes representing a Push Notification Template */
export type Instacart_Growth_Crm_Messaging_Service_V1_PushNotifcationTemplate = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_PushNotifcationTemplate';
  body?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  title?: Maybe<Scalars['String']>;
};

/** Attributes representing a user Segment */
export type Instacart_Growth_Crm_Messaging_Service_V1_Segment = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_Segment';
  accessibleWarehouseIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  externalSegmentId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  segmentName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum Instacart_Growth_Crm_Messaging_Service_V1_SendType {
  SendTypeEmail = 'SEND_TYPE_EMAIL',
  SendTypePush = 'SEND_TYPE_PUSH',
  SendTypeUnknown = 'SEND_TYPE_UNKNOWN'
}

/** Object mapping send type to template id */
export type Instacart_Growth_Crm_Messaging_Service_V1_SendTypeTemplateMap = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_SendTypeTemplateMap';
  email?: Maybe<Scalars['BigInt']>;
  push?: Maybe<Scalars['BigInt']>;
};

/** Attributes representing our internal template object */
export type Instacart_Growth_Crm_Messaging_Service_V1_Template = {
  __typename?: 'instacart_growth_crm_messaging_service_v1_Template';
  emailTemplate?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_EmailTemplate>;
  id?: Maybe<Scalars['BigInt']>;
  pushNotificationTemplate?: Maybe<Instacart_Growth_Crm_Messaging_Service_V1_PushNotifcationTemplate>;
  templateName?: Maybe<Scalars['String']>;
};

export enum Instacart_Growth_Crm_Messaging_Service_V1_TemplateVariablesStorefrontType {
  TemplateVariablesStorefrontTypeInstacart = 'TEMPLATE_VARIABLES_STOREFRONT_TYPE_INSTACART',
  TemplateVariablesStorefrontTypePbi = 'TEMPLATE_VARIABLES_STOREFRONT_TYPE_PBI',
  TemplateVariablesStorefrontTypeUnknown = 'TEMPLATE_VARIABLES_STOREFRONT_TYPE_UNKNOWN'
}

/** Set of variables we allow injecting into templates */
export type Instacart_Growth_Crm_Messaging_Service_V1_TemplateVariables_Input = {
  couponCode?: InputMaybe<Scalars['String']>;
  daysValid?: InputMaybe<Scalars['String']>;
  offerDetailsDollarOff?: InputMaybe<Scalars['String']>;
  offerDetailsMinimumOrder?: InputMaybe<Scalars['String']>;
  retailerLogoUrl?: InputMaybe<Scalars['String']>;
  retailerName?: InputMaybe<Scalars['String']>;
  retailerSlug?: InputMaybe<Scalars['String']>;
  storefrontType?: InputMaybe<Instacart_Growth_Crm_Messaging_Service_V1_TemplateVariablesStorefrontType>;
};

/** Contact details for a team/user */
export type Instacart_Infra_Totem_V1_ContactDetails = {
  __typename?: 'instacart_infra_totem_v1_ContactDetails';
  alert_slack_channels?: Maybe<Instacart_Infra_Totem_V1_SlackChannelList>;
  email?: Maybe<Scalars['String']>;
  general_slack_channels?: Maybe<Instacart_Infra_Totem_V1_SlackChannelList>;
  jira_issue_fields?: Maybe<Instacart_Infra_Totem_V1_JiraIssueFields>;
  name?: Maybe<Scalars['String']>;
  oncall_slack_group?: Maybe<Instacart_Infra_Totem_V1_SlackChannel>;
  opsgenie_responder?: Maybe<Instacart_Infra_Totem_V1_OpsgenieResponder>;
};

/** Entity details */
export type Instacart_Infra_Totem_V1_Entity = {
  __typename?: 'instacart_infra_totem_v1_Entity';
  created_at?: Maybe<Scalars['DateTime']>;
  external_id?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  system_name?: Maybe<Scalars['String']>;
  type?: Maybe<Instacart_Infra_Totem_V1_EntityType>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Information describing the type of entity */
export type Instacart_Infra_Totem_V1_EntityType = {
  __typename?: 'instacart_infra_totem_v1_EntityType';
  display_name?: Maybe<Scalars['String']>;
  is_totem_managed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownership_policies?: Maybe<Array<Maybe<Instacart_Infra_Totem_V1_OwnershipPolicy>>>;
};

/** Request the Entity with a given name */
export type Instacart_Infra_Totem_V1_GetEntityRequest_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  type_name?: InputMaybe<Scalars['String']>;
};

/** The returned Entity */
export type Instacart_Infra_Totem_V1_GetEntityResponse = {
  __typename?: 'instacart_infra_totem_v1_GetEntityResponse';
  entity?: Maybe<Instacart_Infra_Totem_V1_Entity>;
};

/** Request the System with a given name */
export type Instacart_Infra_Totem_V1_GetSystemRequest_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** The returned System */
export type Instacart_Infra_Totem_V1_GetSystemResponse = {
  __typename?: 'instacart_infra_totem_v1_GetSystemResponse';
  system?: Maybe<Instacart_Infra_Totem_V1_System>;
};

/** Information related to a JIRA issue */
export type Instacart_Infra_Totem_V1_JiraIssueFields = {
  __typename?: 'instacart_infra_totem_v1_JiraIssueFields';
  assignee?: Maybe<Scalars['String']>;
  components?: Maybe<Array<Maybe<Scalars['String']>>>;
  project?: Maybe<Instacart_Infra_Totem_V1_JiraProject>;
};

/** JIRA project information */
export type Instacart_Infra_Totem_V1_JiraProject = {
  __typename?: 'instacart_infra_totem_v1_JiraProject';
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  valid_components?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum Instacart_Infra_Totem_V1_ManagementLevel {
  ManagementLevelDirector = 'MANAGEMENT_LEVEL_DIRECTOR',
  ManagementLevelExecutive = 'MANAGEMENT_LEVEL_EXECUTIVE',
  ManagementLevelIndividualContributor = 'MANAGEMENT_LEVEL_INDIVIDUAL_CONTRIBUTOR',
  ManagementLevelManager = 'MANAGEMENT_LEVEL_MANAGER',
  ManagementLevelSeniorDirector = 'MANAGEMENT_LEVEL_SENIOR_DIRECTOR',
  ManagementLevelSeniorManager = 'MANAGEMENT_LEVEL_SENIOR_MANAGER',
  ManagementLevelUnknown = 'MANAGEMENT_LEVEL_UNKNOWN',
  ManagementLevelVicePresident = 'MANAGEMENT_LEVEL_VICE_PRESIDENT'
}

export enum Instacart_Infra_Totem_V1_OpsgeniePriority {
  OpsgeniePriorityP1 = 'OPSGENIE_PRIORITY_P1',
  OpsgeniePriorityP2 = 'OPSGENIE_PRIORITY_P2',
  OpsgeniePriorityP3 = 'OPSGENIE_PRIORITY_P3',
  OpsgeniePriorityP4 = 'OPSGENIE_PRIORITY_P4',
  OpsgeniePriorityP5 = 'OPSGENIE_PRIORITY_P5',
  OpsgeniePriorityUnknown = 'OPSGENIE_PRIORITY_UNKNOWN'
}

/** Information to find the responder in Opsgenie */
export type Instacart_Infra_Totem_V1_OpsgenieResponder = {
  __typename?: 'instacart_infra_totem_v1_OpsgenieResponder';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Instacart_Infra_Totem_V1_OpsgeniePriority>;
  type?: Maybe<Instacart_Infra_Totem_V1_OpsgenieResponderType>;
};

export enum Instacart_Infra_Totem_V1_OpsgenieResponderType {
  OpsgenieResponderTypeEscalation = 'OPSGENIE_RESPONDER_TYPE_ESCALATION',
  OpsgenieResponderTypeSchedule = 'OPSGENIE_RESPONDER_TYPE_SCHEDULE',
  OpsgenieResponderTypeTeam = 'OPSGENIE_RESPONDER_TYPE_TEAM',
  OpsgenieResponderTypeUnknown = 'OPSGENIE_RESPONDER_TYPE_UNKNOWN',
  OpsgenieResponderTypeUser = 'OPSGENIE_RESPONDER_TYPE_USER'
}

export enum Instacart_Infra_Totem_V1_OwnerType {
  OwnerTypeTeam = 'OWNER_TYPE_TEAM',
  OwnerTypeUnknown = 'OWNER_TYPE_UNKNOWN',
  OwnerTypeUser = 'OWNER_TYPE_USER'
}

/** An entity's ownership policy */
export type Instacart_Infra_Totem_V1_OwnershipPolicy = {
  __typename?: 'instacart_infra_totem_v1_OwnershipPolicy';
  name?: Maybe<Scalars['String']>;
  owner_type?: Maybe<Instacart_Infra_Totem_V1_OwnerType>;
  required_contact_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Details to lookup a channel in Slack */
export type Instacart_Infra_Totem_V1_SlackChannel = {
  __typename?: 'instacart_infra_totem_v1_SlackChannel';
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Instacart_Infra_Totem_V1_SlackChannelType>;
};

/** A list of SlackChannels */
export type Instacart_Infra_Totem_V1_SlackChannelList = {
  __typename?: 'instacart_infra_totem_v1_SlackChannelList';
  channels?: Maybe<Array<Maybe<Instacart_Infra_Totem_V1_SlackChannel>>>;
};

export enum Instacart_Infra_Totem_V1_SlackChannelType {
  SlackChannelTypePrivate = 'SLACK_CHANNEL_TYPE_PRIVATE',
  SlackChannelTypePublic = 'SLACK_CHANNEL_TYPE_PUBLIC',
  SlackChannelTypeUnknown = 'SLACK_CHANNEL_TYPE_UNKNOWN',
  SlackChannelTypeUser = 'SLACK_CHANNEL_TYPE_USER',
  SlackChannelTypeUserGroup = 'SLACK_CHANNEL_TYPE_USER_GROUP'
}

/** System details */
export type Instacart_Infra_Totem_V1_System = {
  __typename?: 'instacart_infra_totem_v1_System';
  contact_details?: Maybe<Instacart_Infra_Totem_V1_ContactDetails>;
  created_at?: Maybe<Scalars['DateTime']>;
  display_name?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  owner_team?: Maybe<Instacart_Infra_Totem_V1_Team>;
  owner_user?: Maybe<Instacart_Infra_Totem_V1_User>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Details related to a Team at instacart */
export type Instacart_Infra_Totem_V1_Team = {
  __typename?: 'instacart_infra_totem_v1_Team';
  cost_center?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  default_contact_card_name?: Maybe<Scalars['String']>;
  default_system_name?: Maybe<Scalars['String']>;
  lead_username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parent_team_name?: Maybe<Scalars['String']>;
  status?: Maybe<Instacart_Infra_Totem_V1_TeamStatus>;
  updated_at?: Maybe<Scalars['DateTime']>;
  workday_id?: Maybe<Scalars['String']>;
  workday_org_name?: Maybe<Scalars['String']>;
};

export enum Instacart_Infra_Totem_V1_TeamStatus {
  TeamStatusActive = 'TEAM_STATUS_ACTIVE',
  TeamStatusInactive = 'TEAM_STATUS_INACTIVE',
  TeamStatusUnknown = 'TEAM_STATUS_UNKNOWN'
}

/** Details of a user */
export type Instacart_Infra_Totem_V1_User = {
  __typename?: 'instacart_infra_totem_v1_User';
  created_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  hire_date?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  management_level?: Maybe<Instacart_Infra_Totem_V1_ManagementLevel>;
  manager_username?: Maybe<Scalars['String']>;
  opsgenie_responder?: Maybe<Instacart_Infra_Totem_V1_OpsgenieResponder>;
  slack_channel?: Maybe<Instacart_Infra_Totem_V1_SlackChannel>;
  status?: Maybe<Instacart_Infra_Totem_V1_UserStatus>;
  team_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export enum Instacart_Infra_Totem_V1_UserStatus {
  UserStatusActive = 'USER_STATUS_ACTIVE',
  UserStatusInactive = 'USER_STATUS_INACTIVE',
  UserStatusUnknown = 'USER_STATUS_UNKNOWN'
}

export type Instacart_Logistics_Marketplace_Ops_V1_AddRequestNoteToOpsEscalationRequestRequest_Input = {
  /** ID of the associated OpsEscalationRequest */
  opsEscalationRequestId?: InputMaybe<Scalars['BigInt']>;
  /** contains all the required information to update a request note record */
  requestNote?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInput_Input>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_AddRequestNoteToOpsEscalationRequestResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_AddRequestNoteToOpsEscalationRequestResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestNote?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNote>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_BulkUpdateOpsEscalationRequestStatusRequest_Input = {
  updateOpsEscalationRequestStatusRequests?: InputMaybe<Array<InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestStatusRequest_Input>>>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_BulkUpdateOpsEscalationRequestStatusResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_BulkUpdateOpsEscalationRequestStatusResponse';
  /** Any error messages that occurred during the update */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Whether all operations succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
};

/** attributes needed to create an OpsEscalationRequest record and its associated RequestNotes */
export type Instacart_Logistics_Marketplace_Ops_V1_CreateOpsEscalationRequestInput_Input = {
  opsEscalationRequestInput?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestInput_Input>;
  requestNoteInputs?: InputMaybe<Array<InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInput_Input>>>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_CreateOpsEscalationRequestsRequest_Input = {
  /** Array of objects with data for creating/updating an OpsEscalationRequest record and its associated request notes */
  opsEscalationRequests?: InputMaybe<Array<InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_CreateOpsEscalationRequestInput_Input>>>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_CreateOpsEscalationRequestsResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_CreateOpsEscalationRequestsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_CreateRequestNoteRequest_Input = {
  /** Content of the request note */
  requestNoteInput?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInputData_Input>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_CreateRequestNoteResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_CreateRequestNoteResponse';
  /** Any erors from the operation */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The new request note */
  requestNote?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNote>;
  /** Whether the create succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestRequestNotesRequest_Input = {
  /** IDs of the request notes to be deleted */
  requestNoteIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestRequestNotesResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_DeleteOpsEscalationRequestRequestNotesResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestsRequest_Input = {
  /** List of OpsEscalationRequest IDs to be deleted */
  opsEscalationRequestIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response for the DeleteOpsEscalationRequests request */
export type Instacart_Logistics_Marketplace_Ops_V1_DeleteOpsEscalationRequestsResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_DeleteOpsEscalationRequestsResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Did the operation succeed? */
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_DeleteRequestNoteRequest_Input = {
  /** IPP canonical ID of the person who created the request note. Must match to be ablet to delete the note. */
  createdByIppAccountCanonicalId?: InputMaybe<Scalars['String']>;
  /** ID of the note to delete */
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_DeleteRequestNoteResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_DeleteRequestNoteResponse';
  /** Any erors from the operation */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Whether the delete succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_GetOpsEscalationRequestRequest_Input = {
  /** ID of the OpsEscalationRequest to fetch. */
  opsEscalationRequestId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_GetOpsEscalationRequestResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_GetOpsEscalationRequestResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The OpsEscalationRequest data, returned if found. */
  opsEscalationRequest?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestData>;
};

/** Request for listing duplicate OpsEscalationRequests based on matching by WHL IDs. */
export type Instacart_Logistics_Marketplace_Ops_V1_ListDuplicateOpsEscalationRequestsRequest_Input = {
  /** ID of the OpsEscalationRequest for which we are looking for duplicates */
  opsEscalationRequestId?: InputMaybe<Scalars['BigInt']>;
};

/** Response for the ListDuplicateOpsEscalationRequests request. */
export type Instacart_Logistics_Marketplace_Ops_V1_ListDuplicateOpsEscalationRequestsResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_ListDuplicateOpsEscalationRequestsResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  opsEscalationRequests?: Maybe<Array<Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestData>>>;
};

/** Request for listing OpsEscalationRequests with filters and pagination. */
export type Instacart_Logistics_Marketplace_Ops_V1_ListOpsEscalationRequestsRequest_Input = {
  /** Select ops_escalation_requests assigned to users with provided IPP canonical ids */
  assignedToIppAccountCanonicalIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Select ops_escalation_requests created by users with provided IPP canonical ids */
  createdByIppAccountCanonicalIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Find ops_escalation_requests that end after this date. */
  endsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Information on how to paginate the results of a list/filter operation. This feeds into the will_paginate gem */
  paginationInstruction?: InputMaybe<Instacart_Logistics_V1_PaginationInstruction_Input>;
  /** Select ops_escalation_requests with provided priorities */
  priorities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Select ops_escalation_requests with provided request_reasons */
  requestReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Information on how to sort the results (list of attribute and direction) */
  sortingInstruction?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_SortingInstruction_Input>>>;
  /** Find ops_escalation_requests that start before this date. */
  startsBefore?: InputMaybe<Scalars['DateTime']>;
  /** Select ops_escalation_requests with provided statuses */
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Select ops_escalation_requests with provided warehouse_ids */
  warehouseIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Select ops_escalation_requests with provided warehouse_location_ids */
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Select ops_escalation_requests with provided zone_ids */
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response for the ListOpsEscalationRequests request. */
export type Instacart_Logistics_Marketplace_Ops_V1_ListOpsEscalationRequestsResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_ListOpsEscalationRequestsResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  opsEscalationRequests?: Maybe<Array<Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestData>>>;
  /** How many total events match the criteria */
  opsEscalationRequestsCount?: Maybe<Scalars['Int']>;
  /** How the results are paginated */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Valid noteable types */
export enum Instacart_Logistics_Marketplace_Ops_V1_NoteableType {
  NoteableTypeUnspecified = 'NOTEABLE_TYPE_UNSPECIFIED',
  OpsEscalationRequest = 'OPS_ESCALATION_REQUEST'
}

/**
 * OpsEscalationRequestData captures the details necessary for handling an operational escalation request,
 * such as a store closure or a system outage. It includes identification, timing, and categorization of the request.
 */
export type Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestData = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_OpsEscalationRequestData';
  /** IPP canonical ID of the person assigned to handle the request. */
  assignedToIppAccountCanonicalId?: Maybe<Scalars['String']>;
  /** Standard Rails timestamps */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** IPP canonical ID of the person who created the request. */
  createdByIppAccountCanonicalId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  /** DB row id */
  id?: Maybe<Scalars['BigInt']>;
  /** List of location IDs that are part of this request. */
  locationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** Type of location associated with the escalation request. */
  locationType?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsLocationType>;
  /** Metadata related to the request in JSON format. */
  metadata?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestMetadata>;
  /** If this request has been “intervened”, it will have a parent fulfillment event id */
  parentFulfillmentEventId?: Maybe<Scalars['BigInt']>;
  /** Priority level of the escalation request. */
  priority?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestPriority>;
  /** Additional configurations related to the request in JSON format. */
  requestConfigurations?: Maybe<Scalars['JSON']>;
  /** Array of request notes associated with the OpsEscalationRequest. */
  requestNotes?: Maybe<Array<Maybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNote>>>;
  /** Specific reason for the escalation request. */
  requestReason?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestReason>;
  /** Type of escalation request. */
  requestType?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestType>;
  /** Time in UTC for start_at and end_at of the ops escalation request. */
  startsAt?: Maybe<Scalars['DateTime']>;
  /** Current status of the escalation request. */
  status?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** OpsEscalationRequestInput represents the input data for creating or updating an OpsEscalationRequest. */
export type Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestInput_Input = {
  /** IPP canonical ID of the person assigned to handle the request. */
  assignedToIppAccountCanonicalId?: InputMaybe<Scalars['String']>;
  /** IPP canonical ID of the person who created the request. */
  createdByIppAccountCanonicalId?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** List of location IDs that are part of this request. */
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Type of location associated with the escalation request. */
  locationType?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsLocationType>;
  /** Metadata related to the request. Currently includes only warehouse_id */
  metadata?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestMetadata_Input>;
  /** Priority level of the escalation request. */
  priority?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestPriority>;
  /** Additional configurations related to the request in JSON format. */
  requestConfigurations?: InputMaybe<Scalars['JSON']>;
  /** Specific reason for the escalation request. */
  requestReason?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestReason>;
  /** Type of escalation request. */
  requestType?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestType>;
  /** Time in UTC for start_at and end_at of the ops escalation request. */
  startsAt?: InputMaybe<Scalars['DateTime']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestMetadata = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_OpsEscalationRequestMetadata';
  warehouseId?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestMetadata_Input = {
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

/** Definitions of enums for location types, statuses, request types, reasons, and priorities. */
export enum Instacart_Logistics_Marketplace_Ops_V1_OpsLocationType {
  LocationTypeUnspecified = 'LOCATION_TYPE_UNSPECIFIED',
  WarehouseLocation = 'WAREHOUSE_LOCATION'
}

export enum Instacart_Logistics_Marketplace_Ops_V1_OpsRequestPriority {
  CrtRequest = 'CRT_REQUEST',
  PriorityUnspecified = 'PRIORITY_UNSPECIFIED',
  TempStoreClosure = 'TEMP_STORE_CLOSURE'
}

export enum Instacart_Logistics_Marketplace_Ops_V1_OpsRequestReason {
  AdminConfigurationIssue = 'ADMIN_CONFIGURATION_ISSUE',
  BdWhlRequest = 'BD_WHL_REQUEST',
  CityEvent = 'CITY_EVENT',
  CrtIncident = 'CRT_INCIDENT',
  EmergencyClosure = 'EMERGENCY_CLOSURE',
  MaintenanceClosure = 'MAINTENANCE_CLOSURE',
  PosOutageHours = 'POS_OUTAGE_HOURS',
  PowerOutage = 'POWER_OUTAGE',
  RequestReasonUnspecified = 'REQUEST_REASON_UNSPECIFIED',
  TemporaryStoreClosure = 'TEMPORARY_STORE_CLOSURE',
  WhlIssuesExtremeWeather = 'WHL_ISSUES_EXTREME_WEATHER'
}

export enum Instacart_Logistics_Marketplace_Ops_V1_OpsRequestStatus {
  Duplicate = 'DUPLICATE',
  Expired = 'EXPIRED',
  InterventionCompleted = 'INTERVENTION_COMPLETED',
  InterventionCreated = 'INTERVENTION_CREATED',
  InterventionInProgress = 'INTERVENTION_IN_PROGRESS',
  NewRequest = 'NEW_REQUEST',
  StatusUnspecified = 'STATUS_UNSPECIFIED',
  WontDo = 'WONT_DO'
}

export enum Instacart_Logistics_Marketplace_Ops_V1_OpsRequestType {
  RequestTypeUnspecified = 'REQUEST_TYPE_UNSPECIFIED',
  StoreClosure = 'STORE_CLOSURE'
}

/** A request note object */
export type Instacart_Logistics_Marketplace_Ops_V1_RequestNote = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_RequestNote';
  /** Content of the request note */
  content?: Maybe<Scalars['String']>;
  /** Standard Rails timestamps */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** IPP canonical ID of the person who created the request note */
  createdByIppAccountCanonicalId?: Maybe<Scalars['String']>;
  /** DB row id */
  id?: Maybe<Scalars['BigInt']>;
  /** IDs of IPP accounts tagged in the request note. */
  ippAccountCanonicalTaggedIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The ID of the ops escalation request note is assigned to */
  opsEscalationRequestId?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** input data to create a new RequestNote record */
export type Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInputData_Input = {
  /** Content of the request note */
  content?: InputMaybe<Scalars['String']>;
  /** IPP canonical ID of the person who created the request */
  createdByIppAccountCanonicalId?: InputMaybe<Scalars['String']>;
  /** IPP canonical IDs of users tagged in the request note */
  ippAccountCanonicalTaggedIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** ID of the record associated with the note */
  noteableId?: InputMaybe<Scalars['BigInt']>;
  /** IPP canonical IDs of users tagged in the request note */
  noteableType?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_NoteableType>;
};

/** input data to create a RequestNote record when creating an OpsEscalationRequest */
export type Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInput_Input = {
  /** Content of the request note */
  content?: InputMaybe<Scalars['String']>;
  /** IPP canonical ID of the person who created the request */
  createdByIppAccountCanonicalId?: InputMaybe<Scalars['String']>;
  /** IPP canonical IDs of users tagged in the request note */
  ippAccountCanonicalTaggedIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestRequestNoteRequest_Input = {
  /** contains all the required information to update a request note record */
  requestNote?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInput_Input>;
  /** ID of the request note to be updated */
  requestNoteId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestRequestNoteResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_UpdateOpsEscalationRequestRequestNoteResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestStatusRequest_Input = {
  /** The new status to set */
  newStatus?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsRequestStatus>;
  /** The ID of the OpsEscalationRequest to update */
  opsEscalationRequestId?: InputMaybe<Scalars['BigInt']>;
  /** Optional note to add to the updated OpsEscalationRequest */
  requestNote?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInput_Input>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestStatusResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_UpdateOpsEscalationRequestStatusResponse';
  /** Any error messages that occurred during the update */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestsRequest_Input = {
  /** IDs of OpsEscalationRequests to be updated */
  opsEscalationRequestIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** basic data for creating/updating an OpsEscalationRequest record */
  opsEscalationRequestInput?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_OpsEscalationRequestInput_Input>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateOpsEscalationRequestsResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_UpdateOpsEscalationRequestsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateRequestNoteRequest_Input = {
  /** ID of the note to update */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Content of the request note. Created by must match the existing user */
  requestNoteInput?: InputMaybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNoteInputData_Input>;
};

export type Instacart_Logistics_Marketplace_Ops_V1_UpdateRequestNoteResponse = {
  __typename?: 'instacart_logistics_marketplace_ops_v1_UpdateRequestNoteResponse';
  /** Any erors from the operation */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The update request note */
  requestNote?: Maybe<Instacart_Logistics_Marketplace_Ops_V1_RequestNote>;
  /** Whether the update succeeded or not */
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Logistics_V1_AddLocationsToParentEventRequest_Input = {
  /** Email of the creator */
  creator?: InputMaybe<Scalars['String']>;
  /** Array of IDs to be added (they will be of the same type as the parent event) */
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** The ID of the parent event that locations will be added to */
  parentFulfillmentEventId?: InputMaybe<Scalars['BigInt']>;
  /** For restricted availability events, we might have new warehouses that are impacted */
  warehouseIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Logistics_V1_AddLocationsToParentEventResponse = {
  __typename?: 'instacart_logistics_v1_AddLocationsToParentEventResponse';
  /** Error message that occured during operation */
  error?: Maybe<Scalars['String']>;
  /** Did the operation succeed? */
  success?: Maybe<Scalars['Boolean']>;
};

/** Data shared by all types of fulfillment events */
export type Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input = {
  /** The email of the creator of the event, this will also be the first owner */
  creator?: InputMaybe<Scalars['String']>;
  /** The end time of the event (deprecated, use ends_at_string instead) */
  endsAt?: InputMaybe<Scalars['DateTime']>;
  endsAtString?: InputMaybe<Scalars['String']>;
  /** The type of the event */
  eventType?: InputMaybe<Instacart_Logistics_V1_FulfillmentEventType>;
  /** A link, typically a Jira link, to the reason for the event */
  infoUrl?: InputMaybe<Scalars['String']>;
  /** Location ids, a child event will be created for each location id */
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Location type, which will apply to all location ids */
  locationType?: InputMaybe<Instacart_Logistics_V1_FulfillmentEventLocationType>;
  /** Name/description of the event */
  name?: InputMaybe<Scalars['String']>;
  /** The severity level of the event */
  severityLevel?: InputMaybe<Instacart_Logistics_V1_FulfillmentEventSeverityLevel>;
  /** The start time of the event (deprecated, use starts_at_string instead) */
  startsAt?: InputMaybe<Scalars['DateTime']>;
  /** to avoid timezone issues we are going to set starts_at and ends_at as strings */
  startsAtString?: InputMaybe<Scalars['String']>;
  /** Any tags associated with the event, optional */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Request to cancel a list of fulfillment events */
export type Instacart_Logistics_V1_CancelFulfillmentEventsRequest_Input = {
  /** Email of the creator (user cancelling the events) */
  creator?: InputMaybe<Scalars['String']>;
  /** List of fulfillment event ids to cancel */
  fulfillmentEventIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response to cancel a list of fulfillment events */
export type Instacart_Logistics_V1_CancelFulfillmentEventsResponse = {
  __typename?: 'instacart_logistics_v1_CancelFulfillmentEventsResponse';
  /** List of canceled fulfillment event ids */
  canceledFulfillmentEventIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

/** Values specific to a fulfillment capacity-impacting event */
export type Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input = {
  /** Expected rate of increasing of delivery time */
  deliveryMultiple?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  /** The percentage by which to adjust capacity for the location, a negative number */
  suggestedCapacityLevel?: InputMaybe<Scalars['Int']>;
};

/**
 * Values specific to a store closure event
 * More documentation here https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Closure-event%3A-Level-4
 */
export type Instacart_Logistics_V1_ClosureFulfillmentEventData = {
  __typename?: 'instacart_logistics_v1_ClosureFulfillmentEventData';
  addDeliveryBlock?: Maybe<Scalars['Boolean']>;
  /** Allow staged delivery orders to be batched instead of reassigning to alternative open stores */
  batchStagedBatches?: Maybe<Scalars['Boolean']>;
  /** Cancel shifts */
  cancelAllPartnerShifts?: Maybe<Scalars['Boolean']>;
  /** Disable delivery options */
  disableDeliveryOptions?: Maybe<Scalars['Boolean']>;
  disableIssOptions?: Maybe<Scalars['Boolean']>;
  disablePickupOptions?: Maybe<Scalars['Boolean']>;
  /** Keep shifts */
  keepAllIssShifts?: Maybe<Scalars['Boolean']>;
  keepIssShiftsForPickup?: Maybe<Scalars['Boolean']>;
  keepWarehouseLocationEnabled?: Maybe<Scalars['Boolean']>;
  setWarehouseLocationsToPickupOnly?: Maybe<Scalars['Boolean']>;
  /**
   * Reschedule orders
   * Skip rescheduling impacted orders except staged orders
   */
  skipDeliveriesRescheduling?: Maybe<Scalars['Boolean']>;
  /** Zero out staffing levels */
  zeroOutStaffing?: Maybe<Scalars['Boolean']>;
};

/**
 * Values specific to a store closure event
 * More documentation here https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Closure-event%3A-Level-4
 */
export type Instacart_Logistics_V1_ClosureFulfillmentEventData_Input = {
  addDeliveryBlock?: InputMaybe<Scalars['Boolean']>;
  /** Allow staged delivery orders to be batched instead of reassigning to alternative open stores */
  batchStagedBatches?: InputMaybe<Scalars['Boolean']>;
  /** Cancel shifts */
  cancelAllPartnerShifts?: InputMaybe<Scalars['Boolean']>;
  /** Disable delivery options */
  disableDeliveryOptions?: InputMaybe<Scalars['Boolean']>;
  disableIssOptions?: InputMaybe<Scalars['Boolean']>;
  disablePickupOptions?: InputMaybe<Scalars['Boolean']>;
  /** Keep shifts */
  keepAllIssShifts?: InputMaybe<Scalars['Boolean']>;
  keepIssShiftsForPickup?: InputMaybe<Scalars['Boolean']>;
  keepWarehouseLocationEnabled?: InputMaybe<Scalars['Boolean']>;
  setWarehouseLocationsToPickupOnly?: InputMaybe<Scalars['Boolean']>;
  /**
   * Reschedule orders
   * Skip rescheduling impacted orders except staged orders
   */
  skipDeliveriesRescheduling?: InputMaybe<Scalars['Boolean']>;
  /** Zero out staffing levels */
  zeroOutStaffing?: InputMaybe<Scalars['Boolean']>;
};

/** Request to create a new closure event */
export type Instacart_Logistics_V1_CreateClosureEventRequest_Input = {
  /** The event data that is specific to closure events */
  closureData?: InputMaybe<Instacart_Logistics_V1_ClosureFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Response to create a new closure event */
export type Instacart_Logistics_V1_CreateClosureEventResponse = {
  __typename?: 'instacart_logistics_v1_CreateClosureEventResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The new parent event, includes paginated child events */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /**
   * Impact data for the created child events
   * Key is one of: shifts staffing delivery_blocks delivery_options pickup_options order_deliveries
   */
  impactData?: Maybe<Scalars['JSON']>;
  /** The pagination information for the child events */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Parameters needed for creating new fulfillment capacity impacting events */
export type Instacart_Logistics_V1_CreateFulfillmentEventRequest_Input = {
  /** The event data that is specific to capacity-impacting events */
  capacityImpactData?: InputMaybe<Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Returns the created event and any errors */
export type Instacart_Logistics_V1_CreateFulfillmentEventResponse = {
  __typename?: 'instacart_logistics_v1_CreateFulfillmentEventResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The new parent event, includes paginated child events */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Request to create a new restricted availability event */
export type Instacart_Logistics_V1_CreateRestrictedAvailabilityEventRequest_Input = {
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** The ID of the associated OpsEscalationRequest (optional) */
  opsEscalationRequestId?: InputMaybe<Scalars['BigInt']>;
  /** The event data that is specific to restricted availability events */
  restrictedAvailabilityData?: InputMaybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input>;
  /** The warehouse_ids that will be impacted (optional) */
  warehouseIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response to create a new restricted availability event */
export type Instacart_Logistics_V1_CreateRestrictedAvailabilityEventResponse = {
  __typename?: 'instacart_logistics_v1_CreateRestrictedAvailabilityEventResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The new parent event, includes paginated child events */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** A fulfillment event is a temporary event that affects the capacity of a location. */
export type Instacart_Logistics_V1_FulfillmentEventData = {
  __typename?: 'instacart_logistics_v1_FulfillmentEventData';
  /**
   * The percentage by which to adjust staffing for the location.
   * When there are overlapping events affecting the same location, the lowest value is used.
   */
  adjustStaffingPercentage?: Maybe<Scalars['Int']>;
  /** The number of children for this event */
  childCount?: Maybe<Scalars['Int']>;
  /** Child events, if this is a parent event */
  childEvents?: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventData>>>;
  /** A list of the location ids associated with the event's child events, if this is a parent event */
  childLocationIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** Additional data for closure events */
  closureData?: Maybe<Instacart_Logistics_V1_ClosureFulfillmentEventData>;
  closureEventDataMetadata?: Maybe<Instacart_Logistics_V1_ClosureFulfillmentEventData>;
  /** Standard Rails timestamps */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Original creator of the event - email address if it was a person, system name if it's a system, e.g. "apollo" */
  creator?: Maybe<Scalars['String']>;
  /** Message shown to customers about the event */
  customerMessage?: Maybe<Scalars['String']>;
  /** Deleted time, if the event was cancelled before it started */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Expected rate of increasing of delivery time */
  deliveryMultiple?: Maybe<Instacart_Types_V1_BigDecimal>;
  endsAt?: Maybe<Scalars['DateTime']>;
  /** General type of event (see enum above) */
  eventType?: Maybe<Instacart_Logistics_V1_FulfillmentEventType>;
  /** Whether we should forgive shoppers for cancelling shifts */
  forgiveReliabilityIncident?: Maybe<Scalars['Boolean']>;
  /** DB row id */
  id?: Maybe<Scalars['BigInt']>;
  /** Link to a Jira ticket or other documentation for the event */
  infoUrl?: Maybe<Scalars['String']>;
  /** Location data, if this is a child event */
  location?: Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>;
  /**
   * When a list of parent events is retrieved, we only need to know the location type and count of children
   * When a single parent event is retrieved, we need to know the full details of the children
   */
  locationType?: Maybe<Instacart_Logistics_V1_FulfillmentEventLocationType>;
  /** Name of the event */
  name?: Maybe<Scalars['String']>;
  /** Most recent updater of the event - email address if it was a person, system name if it's a system, e.g. "apollo" */
  owner?: Maybe<Scalars['String']>;
  /**
   * Parent event id, if this is a child event
   * Note: some historical events are child events but do not have a parent event id
   */
  parentFulfillmentEventId?: Maybe<Scalars['BigInt']>;
  /** Additional data for restricted availability events */
  restrictedAvailabilityData?: Maybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData>;
  restrictedAvailabilityEventDataMetadata?: Maybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData>;
  /** A comma-separated list of shift types for looking up staffing_levels */
  roleTypes?: Maybe<Scalars['String']>;
  /** Severity (see enum above) */
  severityLevel?: Maybe<Instacart_Logistics_V1_FulfillmentEventSeverityLevel>;
  /** Start and end time */
  startsAt?: Maybe<Scalars['DateTime']>;
  /**
   * Event status as computed from the start and end times of the event relative to the current time.
   * Status is DELETED if the event has been soft-deleted.
   */
  status?: Maybe<Instacart_Logistics_V1_FulfillmentEventStatus>;
  /**
   * The percentage by which to adjust capacity for the location.
   * When there are overlapping events affecting the same location, the highest value is used.
   */
  suggestedCapacityLevel?: Maybe<Scalars['Int']>;
  /** Tags associated by the events */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Timezone as it turns out google.protobuf.Timestamp is only in UTC, so we need this to do conversions */
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not this event was automatically created by weather data */
  weatherEvent?: Maybe<Scalars['Boolean']>;
  /** Zone for the event */
  zoneId?: Maybe<Scalars['BigInt']>;
};

/** Rich(er) data about the location associated with the fulfillment event */
export type Instacart_Logistics_V1_FulfillmentEventLocationData = {
  __typename?: 'instacart_logistics_v1_FulfillmentEventLocationData';
  /** DB row id */
  id?: Maybe<Scalars['BigInt']>;
  locationType?: Maybe<Instacart_Logistics_V1_FulfillmentEventLocationType>;
  /** Name from association table */
  name?: Maybe<Scalars['String']>;
};

/** Possible types of locations associated with a fulfillment event */
export enum Instacart_Logistics_V1_FulfillmentEventLocationType {
  Country = 'COUNTRY',
  LocationTypeUnspecified = 'LOCATION_TYPE_UNSPECIFIED',
  PostalCode = 'POSTAL_CODE',
  Region = 'REGION',
  WarehouseLocation = 'WAREHOUSE_LOCATION',
  WarehouseZone = 'WAREHOUSE_ZONE',
  Zone = 'ZONE'
}

/**
 * Event severities, see more at
 * https://docs.google.com/document/d/1lSGwGQU-0_ED55yH9P-TDFaCxVdXqaLV/edit#heading=h.994m78oh6kur
 *
 * Fulfillment capacity-impacting events (severities 1-3, formerly "Weather events")
 * These types of events cause a reduction in the number of orders we can fulfill. The capacity level (reduction)
 * for these events can be set to anything from -5 to -100. We can also customize the delivery multiple
 * (the factor indicating expected delivery time increase) and customer facing messages for these events.
 *
 * Closure event/extreme weather event (severity 4)
 * This severity causes closure of specific stores (WarehouseLocation).
 *
 * Restricted availability (severity 5, previous known as "safe mode")
 * This severity causes the system to restrict available service options, e.g. disallow all delivery orders.
 *
 * Note that while levels 1-3 and 6 are related, the severity levels are not necessarily contiguous or strictly
 * ordered.
 */
export enum Instacart_Logistics_V1_FulfillmentEventSeverityLevel {
  EventSeverityUnspecified = 'EVENT_SEVERITY_UNSPECIFIED',
  HighCapacityImpact = 'HIGH_CAPACITY_IMPACT',
  LowCapacityImpact = 'LOW_CAPACITY_IMPACT',
  MediumCapacityImpact = 'MEDIUM_CAPACITY_IMPACT',
  RestrictedServiceOptions = 'RESTRICTED_SERVICE_OPTIONS',
  StoreClosure = 'STORE_CLOSURE',
  /**
   * this is a new severity level that is used to indicate that the event has no capacity impact;
   * since 0 is reserved for "unspecified", and other values were already defined, we use 6 here.
   */
  ZeroCapacityImpact = 'ZERO_CAPACITY_IMPACT'
}

/**
 * Event status, computed from the start and end times of the event relative to the current time. Status is
 * DELETED if the event has been soft-deleted.
 */
export enum Instacart_Logistics_V1_FulfillmentEventStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  EventStatusUnspecified = 'EVENT_STATUS_UNSPECIFIED',
  InProgress = 'IN_PROGRESS',
  Upcoming = 'UPCOMING'
}

/**
 * Event types, see more at
 * https://docs.google.com/document/d/1lSGwGQU-0_ED55yH9P-TDFaCxVdXqaLV/edit#heading=h.rs2ipnbn0urn
 * Note: We are expecting a new and more comprehensive list of types in the future.
 */
export enum Instacart_Logistics_V1_FulfillmentEventType {
  AirQuality = 'AIR_QUALITY',
  CityEvent = 'CITY_EVENT',
  Cold = 'COLD',
  Cultural = 'CULTURAL',
  EventTypeUnspecified = 'EVENT_TYPE_UNSPECIFIED',
  Experiment = 'EXPERIMENT',
  Flood = 'FLOOD',
  Heatwave = 'HEATWAVE',
  Holiday = 'HOLIDAY',
  Hurricane = 'HURRICANE',
  Other = 'OTHER',
  Outage = 'OUTAGE',
  Rain = 'RAIN',
  Snow = 'SNOW',
  Tornado = 'TORNADO',
  TropicalStorm = 'TROPICAL_STORM',
  Wildfire = 'WILDFIRE',
  Wind = 'WIND',
  WinterStorm = 'WINTER_STORM'
}

export type Instacart_Logistics_V1_GetFulfillmentEventRequest_Input = {
  fulfillmentEventId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Logistics_V1_GetFulfillmentEventResponse = {
  __typename?: 'instacart_logistics_v1_GetFulfillmentEventResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerMessage?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Expected rate of increasing of delivery time */
  deliveryMultiple?: Maybe<Scalars['Float']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  eventType?: Maybe<Instacart_Logistics_V1_GetFulfillmentEventResponse_EventType>;
  /** Whether we should forgive shoppers for cancelling shifts */
  forgiveReliabilityIncident?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  locationId?: Maybe<Scalars['BigInt']>;
  locationType?: Maybe<Instacart_Logistics_V1_GetFulfillmentEventResponse_LocationType>;
  locked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** Author of the event */
  owner?: Maybe<Scalars['String']>;
  roleTypes?: Maybe<Scalars['String']>;
  /** Level of the fulfillment event */
  severityLevel?: Maybe<Scalars['Int']>;
  shopperMessage?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  /** Adjustment of the capacity level */
  suggestedCapacityLevel?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weatherEvent?: Maybe<Scalars['Boolean']>;
  zoneId?: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Logistics_V1_GetFulfillmentEventResponse_EventType {
  Cultural = 'CULTURAL',
  Experiment = 'EXPERIMENT',
  Other = 'OTHER',
  Rain = 'RAIN',
  Snow = 'SNOW'
}

export enum Instacart_Logistics_V1_GetFulfillmentEventResponse_LocationType {
  Country = 'COUNTRY',
  PostalCode = 'POSTAL_CODE',
  Region = 'REGION',
  WarehouseLocation = 'WAREHOUSE_LOCATION',
  WarehouseZone = 'WAREHOUSE_ZONE',
  Zone = 'ZONE'
}

export type Instacart_Logistics_V1_ListChildFulfillmentEventsRequest_Input = {
  /** Find events created by these users */
  creators?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Find events which end after this date. */
  endsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Select events with these statuses */
  eventStatuses?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventStatus>>>;
  /** Select events with these event types */
  eventTypes?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventType>>>;
  /** Find an event with one of these owners (most recent updater) */
  owners?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Information on how to paginate the results of a list/filter operation. This feeds into the will_paginate gem. */
  paginationInstruction?: InputMaybe<Instacart_Logistics_V1_PaginationInstruction_Input>;
  /**
   * The parent event id from which a set of location-specific events were generated; this will return
   * a list of only the child events for that parent.
   */
  parentEventId?: InputMaybe<Scalars['BigInt']>;
  /** A search string that matches the event name or link content (e.g. Jira ticket id). */
  searchString?: InputMaybe<Scalars['String']>;
  /** Select events with these severity levels */
  severityLevels?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventSeverityLevel>>>;
  /**
   * Information on how to sort the results (list of attribute and direction); currently the front-end only
   * supports a single sort attribute but this is a list in case users ask for more nuance in future.
   */
  sortingInstruction?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_SortingInstruction_Input>>>;
  /** Find events which start before this date. */
  startsBefore?: InputMaybe<Scalars['DateTime']>;
  /** Select events with these tags */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Logistics_V1_ListChildFulfillmentEventsResponse = {
  __typename?: 'instacart_logistics_v1_ListChildFulfillmentEventsResponse';
  /** How many total events match the criteria */
  eventCount?: Maybe<Scalars['Int']>;
  /** Paginated and sorted array of child fulfillment events for a specified parent event that match the given criteria. */
  fulfillmentEvents?: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventData>>>;
  /** How the results are paginated */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Return all creators, sorted alphabetically */
export type Instacart_Logistics_V1_ListCreatorsResponse = {
  __typename?: 'instacart_logistics_v1_ListCreatorsResponse';
  /** List of creators */
  creators?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Request fulfillment events according to specified criteria. All parameters are optional. */
export type Instacart_Logistics_V1_ListFulfillmentEventsRequest_Input = {
  /** Find events with these countries */
  countryIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Find events created by these users */
  creators?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Find events which end after this date. */
  endsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Select events with these statuses */
  eventStatuses?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventStatus>>>;
  /** Select events with these event types */
  eventTypes?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventType>>>;
  /** Select only events where the location_type matches this value (typically WarehouseLocation is selected here) */
  locationTypes?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventLocationType>>>;
  /** Find an event with one of these owners (most recent updater) */
  owners?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Information on how to paginate the results of a list/filter operation. This feeds into the will_paginate gem. */
  paginationInstruction?: InputMaybe<Instacart_Logistics_V1_PaginationInstruction_Input>;
  /**
   * The parent event id from which a set of location-specific events were generated; this will return
   * a list of only the child events for that parent.
   */
  parentEventId?: InputMaybe<Scalars['BigInt']>;
  /** Find events with these postal codes */
  postalCodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Find events in selected region (not used, see region_ids) */
  regionId?: InputMaybe<Scalars['BigInt']>;
  /** Find events with these regions */
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** A search string that matches the event name or link content (e.g. Jira ticket id). */
  searchString?: InputMaybe<Scalars['String']>;
  /** Select events with these severity levels */
  severityLevels?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventSeverityLevel>>>;
  /**
   * Information on how to sort the results (list of attribute and direction); currently the front-end only
   * supports a single sort attribute but this is a list in case users ask for more nuance in future.
   */
  sortingInstruction?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_SortingInstruction_Input>>>;
  /** Find events which start before this date. */
  startsBefore?: InputMaybe<Scalars['DateTime']>;
  /** Select events with these tags */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Find events with these warehouse locations */
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Find events with these zones */
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Logistics_V1_ListFulfillmentEventsResponse = {
  __typename?: 'instacart_logistics_v1_ListFulfillmentEventsResponse';
  /** How many total events match the criteria */
  eventCount?: Maybe<Scalars['Int']>;
  /**
   * Paginated and sorted array of fulfillment events that match the given criteria.
   *
   * If a parent event id was not specified in the search criteria, this ia a list of parent events
   * where each parent event contains a list of location-specific child events.
   *
   * If the search criteria includes a parent_event_id, then this will return just that parent, with
   * child events nested as usual.
   */
  fulfillmentEvents?: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventData>>>;
  /** How the results are paginated */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Return all tags, sorted alphabetically */
export type Instacart_Logistics_V1_ListTagsResponse = {
  __typename?: 'instacart_logistics_v1_ListTagsResponse';
  /** List of creators */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Commonly used data structures for pagination */
export type Instacart_Logistics_V1_PaginationInstruction = {
  __typename?: 'instacart_logistics_v1_PaginationInstruction';
  /** The page of results to show, will default if nil */
  page?: Maybe<Scalars['Int']>;
  /** The number of results to show per page, will default if nil */
  perPage?: Maybe<Scalars['Int']>;
};

/** Commonly used data structures for pagination */
export type Instacart_Logistics_V1_PaginationInstruction_Input = {
  /** The page of results to show, will default if nil */
  page?: InputMaybe<Scalars['Int']>;
  /** The number of results to show per page, will default if nil */
  perPage?: InputMaybe<Scalars['Int']>;
};

/**
 * Restricted availability event data
 * More documentation here:https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Safe-Mode
 */
export type Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData = {
  __typename?: 'instacart_logistics_v1_RestrictedAvailabilityFulfillmentEventData';
  restrictServiceOptionTypes?: Maybe<Array<Maybe<Instacart_Logistics_V1_RestrictedServiceOptionType>>>;
  storeCloseBuffer?: Maybe<Scalars['Int']>;
  warehouseIds?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

/**
 * Restricted availability event data
 * More documentation here:https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Safe-Mode
 */
export type Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input = {
  restrictServiceOptionTypes?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_RestrictedServiceOptionType>>>;
  storeCloseBuffer?: InputMaybe<Scalars['Int']>;
  warehouseIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Types of service options that can be restricted */
export enum Instacart_Logistics_V1_RestrictedServiceOptionType {
  Asap = 'ASAP',
  /** WHL specific restrictions */
  BatchingForDelivery = 'BATCHING_FOR_DELIVERY',
  BatchingForInflightOrders = 'BATCHING_FOR_INFLIGHT_ORDERS',
  BatchingForPickup = 'BATCHING_FOR_PICKUP',
  FiveHour = 'FIVE_HOUR',
  LimitedAvailability = 'LIMITED_AVAILABILITY',
  LimitedAvailabilityFiveHour = 'LIMITED_AVAILABILITY_FIVE_HOUR',
  LimitedAvailabilityFourHour = 'LIMITED_AVAILABILITY_FOUR_HOUR',
  LimitedAvailabilityThreeHour = 'LIMITED_AVAILABILITY_THREE_HOUR',
  LimitedAvailabilityTwoHour = 'LIMITED_AVAILABILITY_TWO_HOUR',
  Pickup = 'PICKUP',
  PickupEta = 'PICKUP_ETA',
  PriorityEta = 'PRIORITY_ETA',
  PriorityEtaByEndAt = 'PRIORITY_ETA_BY_END_AT',
  PriorityEtaPickup = 'PRIORITY_ETA_PICKUP',
  RetailerLocationAvailabilityForDeliveryAndPickup = 'RETAILER_LOCATION_AVAILABILITY_FOR_DELIVERY_AND_PICKUP',
  ScheduledAvailabilityOneHour = 'SCHEDULED_AVAILABILITY_ONE_HOUR',
  StandardEta = 'STANDARD_ETA',
  StandardEtaByEndAt = 'STANDARD_ETA_BY_END_AT',
  ThreeHourScheduledDelivery = 'THREE_HOUR_SCHEDULED_DELIVERY',
  TwoHour = 'TWO_HOUR',
  UnspecifiedRestrictedServiceOptionType = 'UNSPECIFIED_RESTRICTED_SERVICE_OPTION_TYPE'
}

/** Information on how to sort the results of a list/filter operation */
export enum Instacart_Logistics_V1_SortingDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
  SortingDirectionUnspecified = 'SORTING_DIRECTION_UNSPECIFIED'
}

export type Instacart_Logistics_V1_SortingInstruction_Input = {
  /** The direction to sort, defaults to ascending */
  direction?: InputMaybe<Instacart_Logistics_V1_SortingDirection>;
  /** The name of the field to sort on, e.g. starts_at */
  fieldName?: InputMaybe<Scalars['String']>;
};

/**
 * Request to update one or more closure events
 * All events must be children of the same parent
 */
export type Instacart_Logistics_V1_UpdateClosureEventsRequest_Input = {
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** IDs of the events to update */
  fulfillmentEventIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response to update one or more closure events */
export type Instacart_Logistics_V1_UpdateClosureEventsResponse = {
  __typename?: 'instacart_logistics_v1_UpdateClosureEventsResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The updated parent event, includes paginated child events */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/**
 * Request to update one or more fulfillment events
 * All events must be children of the same parent
 */
export type Instacart_Logistics_V1_UpdateFulfillmentEventsRequest_Input = {
  /** The event data that is specific to capacity-impacting events */
  capacityImpactData?: InputMaybe<Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** IDs of the events to update */
  fulfillmentEventIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response to update one or more fulfillment events */
export type Instacart_Logistics_V1_UpdateFulfillmentEventsResponse = {
  __typename?: 'instacart_logistics_v1_UpdateFulfillmentEventsResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The updated parent event, includes paginated child events */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/**
 * Request to update one or more restricted availability events
 * All events must be children of the same parent
 */
export type Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsRequest_Input = {
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** IDs of the events to update */
  fulfillmentEventIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** The event data that is specific to restricted availability events */
  restrictedAvailabilityData?: InputMaybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input>;
};

/** Response to update a restricted availability event */
export type Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsResponse = {
  __typename?: 'instacart_logistics_v1_UpdateRestrictedAvailabilityEventsResponse';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The updated parent event, includes paginated child events */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction?: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Request to validate a closure event */
export type Instacart_Logistics_V1_ValidateNewClosureEventRequest_Input = {
  /** The event data that is specific to closure events */
  closureData?: InputMaybe<Instacart_Logistics_V1_ClosureFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Response to validate a closure event */
export type Instacart_Logistics_V1_ValidateNewClosureEventResponse = {
  __typename?: 'instacart_logistics_v1_ValidateNewClosureEventResponse';
  /** Any errors that occurred during validation */
  errors?: Maybe<Array<Maybe<Instacart_Logistics_V1_ValidationError>>>;
  /** The validated or created event */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** A list of locations, with names and ids */
  locations?: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>>>;
  /** Is the event valid? */
  valid?: Maybe<Scalars['Boolean']>;
};

/** Parameters needed for validating new fulfillment capacity impacting events */
export type Instacart_Logistics_V1_ValidateNewFulfillmentEventRequest_Input = {
  /** The event data that is specific to capacity-impacting events */
  capacityImpactData?: InputMaybe<Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Returns the validated or created event and any errors */
export type Instacart_Logistics_V1_ValidateNewFulfillmentEventResponse = {
  __typename?: 'instacart_logistics_v1_ValidateNewFulfillmentEventResponse';
  /** Any errors that occurred during validation */
  errors?: Maybe<Array<Maybe<Instacart_Logistics_V1_ValidationError>>>;
  /** Data for the new parent event */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** A list of locations, with names and ids */
  locations?: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>>>;
  /** Is the event valid? */
  valid?: Maybe<Scalars['Boolean']>;
};

/** Request to validate a restricted availability event */
export type Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventRequest_Input = {
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** The event data that is specific to restricted availability events */
  restrictedAvailabilityData?: InputMaybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input>;
};

/** Response to validate a restricted availability event */
export type Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventResponse = {
  __typename?: 'instacart_logistics_v1_ValidateNewRestrictedAvailabilityEventResponse';
  /** Any errors that occurred during validation */
  errors?: Maybe<Array<Maybe<Instacart_Logistics_V1_ValidationError>>>;
  /** The validated or created event */
  event?: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** A list of locations, with names and ids */
  locations?: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>>>;
  /** Is the event valid? */
  valid?: Maybe<Scalars['Boolean']>;
};

/** Validation error messages for a field, one field might have more than one error */
export type Instacart_Logistics_V1_ValidationError = {
  __typename?: 'instacart_logistics_v1_ValidationError';
  /** The error messages */
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The name of the field with the error, corresponds to the field name in FulfillmentEventData */
  fieldName?: Maybe<Scalars['String']>;
};

/**
 * Represents an approvable record, which can be of different types
 * Currently only ColumnMapRecord is used, but this structure allows for future extension, i.e. ValidationMaps
 */
export type Instacart_Partners_Data_Ingestion_V1_ApprovableRecord = {
  __typename?: 'instacart_partners_data_ingestion_v1_ApprovableRecord';
  columnMap?: Maybe<Instacart_Partners_Data_Ingestion_V1_ColumnMapRecord>;
};

/** Represents a change approval request */
export type Instacart_Partners_Data_Ingestion_V1_ChangeApprovalRequest = {
  __typename?: 'instacart_partners_data_ingestion_v1_ChangeApprovalRequest';
  changeType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  latestRevision?: Maybe<Instacart_Partners_Data_Ingestion_V1_ApprovableRecord>;
  recordType?: Maybe<Scalars['String']>;
  sourceRecord?: Maybe<Instacart_Partners_Data_Ingestion_V1_ApprovableRecord>;
  sourceRecordId?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents a column map record */
export type Instacart_Partners_Data_Ingestion_V1_ColumnMapRecord = {
  __typename?: 'instacart_partners_data_ingestion_v1_ColumnMapRecord';
  fileNameMatcher?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  ingestionType?: Maybe<Scalars['String']>;
  map?: Maybe<Scalars['JSON']>;
  mapType?: Maybe<Scalars['String']>;
  normalizedSchema?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['BigInt']>;
  rawSchema?: Maybe<Scalars['String']>;
  tombstone?: Maybe<Scalars['Boolean']>;
};

/** Request message for getting a change approval request by its ID */
export type Instacart_Partners_Data_Ingestion_V1_GetChangeApprovalRequestRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
};

/** Response message containing the requested change approval request and includes relevant approvable records */
export type Instacart_Partners_Data_Ingestion_V1_GetChangeApprovalRequestResponse = {
  __typename?: 'instacart_partners_data_ingestion_v1_GetChangeApprovalRequestResponse';
  changeApprovalRequest?: Maybe<Instacart_Partners_Data_Ingestion_V1_ChangeApprovalRequest>;
};

/** Enum to represent the configurable type of partner configuration model. */
export enum Instacart_Partners_Partners_V1_ConfigurableType {
  ConfigurableTypeRetailer = 'CONFIGURABLE_TYPE_RETAILER',
  ConfigurableTypeRetailerLocation = 'CONFIGURABLE_TYPE_RETAILER_LOCATION',
  ConfigurableTypeUnknown = 'CONFIGURABLE_TYPE_UNKNOWN',
  ConfigurableTypeZone = 'CONFIGURABLE_TYPE_ZONE'
}

/**
 * Object to represent a configuration setting.
 * The configuration setting could be either a string or a json which is stored as jsonb in the DB
 */
export type Instacart_Partners_Partners_V1_ConfigurationSetting = {
  __typename?: 'instacart_partners_partners_v1_ConfigurationSetting';
  jsonSetting?: Maybe<Scalars['JSON']>;
  /** This will be deprecated once we have updated the implementation to use google.protobuf.Struct */
  keyValueSettings?: Maybe<Instacart_Partners_Partners_V1_ConfigurationSetting_SettingsMap>;
  stringSetting?: Maybe<Scalars['String']>;
};

/**
 * Object to represent key-value pairs.
 * This will be deprecated once we have updated the implementation to use google.protobuf.Struct
 */
export type Instacart_Partners_Partners_V1_ConfigurationSetting_SettingsMap = {
  __typename?: 'instacart_partners_partners_v1_ConfigurationSetting_SettingsMap';
  settings?: Maybe<Scalars['JSON']>;
};

/** Request to get configurations for a given configurable type with a list of configurable ids. */
export type Instacart_Partners_Partners_V1_GetPartnerConfigurationsRequest_Input = {
  configurableIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  configurableType?: InputMaybe<Instacart_Partners_Partners_V1_ConfigurableType>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  partnerConfigurationType?: InputMaybe<Instacart_Partners_Partners_V1_PartnerConfigurationType>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

/** Response to get partner configurations for given configurable information. */
export type Instacart_Partners_Partners_V1_GetPartnerConfigurationsResponse = {
  __typename?: 'instacart_partners_partners_v1_GetPartnerConfigurationsResponse';
  partnerConfigurations?: Maybe<Array<Maybe<Instacart_Partners_Partners_V1_PartnerConfiguration>>>;
};

/** Object to represent a partner configuration. */
export type Instacart_Partners_Partners_V1_PartnerConfiguration = {
  __typename?: 'instacart_partners_partners_v1_PartnerConfiguration';
  configurableId?: Maybe<Scalars['BigInt']>;
  configurableType?: Maybe<Instacart_Partners_Partners_V1_ConfigurableType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  partnerConfigurationType?: Maybe<Instacart_Partners_Partners_V1_PartnerConfigurationType>;
  setting?: Maybe<Instacart_Partners_Partners_V1_ConfigurationSetting>;
  startTime?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Enum to represent the type of partner configuration. */
export enum Instacart_Partners_Partners_V1_PartnerConfigurationType {
  PartnerConfigurationTypeBatching = 'PARTNER_CONFIGURATION_TYPE_BATCHING',
  PartnerConfigurationTypeServiceOptionAvailability = 'PARTNER_CONFIGURATION_TYPE_SERVICE_OPTION_AVAILABILITY',
  PartnerConfigurationTypeUnknown = 'PARTNER_CONFIGURATION_TYPE_UNKNOWN'
}

export type Instacart_Retailer_Tools_Growth_Targeting_V1_GetTargetingFeaturesRequest_Input = {
  parameters?: InputMaybe<Instacart_Ads_Platform_Taas_V1_GetAllSupportedFeaturesRequest_Input>;
  sfxVersion?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** PRIMITIVES */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Entitlement = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_Entitlement';
  createdAt?: Maybe<Scalars['DateTime']>;
  /** which environment was this entitlement from */
  fromEnv?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment>;
  /** true if the entitlement is integrated into IPP and that entitlement changes are expected to near immediately change the Retailer UX on IPP, false otherwise. */
  hasIppIntegration?: Maybe<Scalars['Boolean']>;
  /** the main identifier for this entitlement */
  id?: Maybe<Scalars['String']>;
  /** more human readable name / display string */
  name?: Maybe<Scalars['String']>;
  /** the slack handle to contact for the team/individual that owns the entitlement */
  ownerSlackHandle?: Maybe<Scalars['String']>;
  /** true if the entitlement requires some form of manual effort in order to unlock the feature gated, false otherise. */
  requiresManualMigration?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_EntitlementShallowDetails';
  entitlement?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Entitlement>;
  /** how many packages use this entitlement */
  numReferencingPackages?: Maybe<Scalars['Int']>;
  /** how many retailers reference this entitlement */
  numReferencingRetailerAdditionalEntitlements?: Maybe<Scalars['Int']>;
};

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementsSearchByType {
  EntitlementIdOrName = 'ENTITLEMENT_ID_OR_NAME',
  EnumEntitlementUsagesSearchTypeUnspecified = 'ENUM_ENTITLEMENT_USAGES_SEARCH_TYPE_UNSPECIFIED'
}

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment {
  DevelopmentEnvironment = 'DEVELOPMENT_ENVIRONMENT',
  ProductionEnvironment = 'PRODUCTION_ENVIRONMENT',
  StagingEnvironment = 'STAGING_ENVIRONMENT',
  UnspecifiedEnvironment = 'UNSPECIFIED_ENVIRONMENT'
}

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsRequest_Input = {
  byEntitlementId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetEntitlementDetailsResponse';
  entitlement?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Entitlement>;
  packages?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails>>>;
  retailerEntitlementProfiles?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails>>>;
};

/** ENTITLEMENTS API */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsRequest_Input = {
  searchBy?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementsSearchByType>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetEntitlementsResponse';
  entitlements?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails>>>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsRequest_Input = {
  byPackageId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetPackageDetailsResponse';
  entitlements?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails>>>;
  package?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Package>;
  retailerEntitlementProfiles?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails>>>;
};

/** PACKAGES API */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesRequest_Input = {
  searchBy?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackagesSearchByType>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetPackagesResponse';
  packages?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails>>>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsRequest_Input = {
  byRetailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetRetailerEntitlementProfileDetailsResponse';
  additionalEntitlements?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails>>>;
  packages?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails>>>;
  retailerEntitlementProfile?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfile>;
};

/** RETAILER ENTITLEMENT PROFILES API */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesRequest_Input = {
  searchBy?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfilesSearchByType>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetRetailerEntitlementProfilesResponse';
  retailerEntitlementProfiles?: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails>>>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Package = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_Package';
  createdAt?: Maybe<Scalars['DateTime']>;
  /** a list of the entitlement IDs that are included in this package */
  entitlementIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** which environment was this package from */
  fromEnv?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment>;
  /** the main identifier for this package */
  id?: Maybe<Scalars['String']>;
  /** more human readable name / display string */
  name?: Maybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_PackageShallowDetails';
  /** how many entitlements are in this package */
  numEntitlements?: Maybe<Scalars['Int']>;
  /** how many retailers reference this package */
  numReferencingRetailerEntitlementProfiles?: Maybe<Scalars['Int']>;
  package?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Package>;
};

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackagesSearchByType {
  EnumPackagesSearchTypeUnspecified = 'ENUM_PACKAGES_SEARCH_TYPE_UNSPECIFIED',
  PackageIdOrName = 'PACKAGE_ID_OR_NAME'
}

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfile = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_RetailerEntitlementProfile';
  /** the list of a la carte entitlements given to this retailer profile */
  additionalEntitlementIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** the denormalized list of IDs this retailer is entitled to. this destructures the packages referenced entitlements. */
  denormalizedEntitlementIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** which environment was this retailer profile from */
  fromEnv?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment>;
  /** the list of packages applied to this retailer profile */
  packageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** the canonical warehouse model */
  warehouseId?: Maybe<Scalars['BigInt']>;
  /** the canonical warehouse model */
  warehouseName?: Maybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_RetailerEntitlementProfileShallowDetails';
  /** how many custom additional entitlements are used by this retailer (not included via packages) */
  numAdditionalEntitlements?: Maybe<Scalars['Int']>;
  /** how many packages are used by this retailer */
  numPackages?: Maybe<Scalars['Int']>;
  retailerEntitlementProfile?: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfile>;
};

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfilesSearchByType {
  EnumRetailerEntitlementProfilesSearchTypeUnspecified = 'ENUM_RETAILER_ENTITLEMENT_PROFILES_SEARCH_TYPE_UNSPECIFIED',
  RetalerId = 'RETALER_ID'
}

/**
 * Defines partners, retailers, and retailer locations that the current user is
 * authorized to access
 */
export type Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input = {
  accountId?: InputMaybe<Scalars['BigInt']>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomExportRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
  dashboardSlug?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_Input>>>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  orderBys?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalOrderBy_Input>>>;
  schema?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalSchema>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomExportResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalCreateCustomExportResponse';
  customExport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomExport>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomReportRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  dashboardSlug?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterSelectionOptionsList?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOptions_Input>>>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_Input>>>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  orderBys?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalOrderBy_Input>>>;
  schema?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalSchema>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalCreateCustomReportResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalCreateCustomReportResponse';
  customReport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomReport>;
  success?: Maybe<Scalars['Boolean']>;
};

/** CSV Exports */
export type Instacart_Retailer_Tools_V2_InsightsPortalCustomExport = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalCustomExport';
  createdAt?: Maybe<Scalars['DateTime']>;
  dashboardSlug?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  downloadUrl?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection>>>;
  generationState?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomExport_GenerationState>;
  id?: Maybe<Scalars['BigInt']>;
  metrics?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  orderBys?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalOrderBy>>>;
  schema?: Maybe<Scalars['String']>;
};

export enum Instacart_Retailer_Tools_V2_InsightsPortalCustomExport_GenerationState {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

/** Custom Reports */
export type Instacart_Retailer_Tools_V2_InsightsPortalCustomReport = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalCustomReport';
  createdAt?: Maybe<Scalars['DateTime']>;
  dashboardSlug?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterSelectionOptionsList?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOptions>>>;
  filters?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection>>>;
  id?: Maybe<Scalars['BigInt']>;
  metrics?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  orderBys?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalOrderBy>>>;
  schema?: Maybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalDeleteCustomExportRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  customExportId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalDeleteCustomExportResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalDeleteCustomExportResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalDownloadCustomExportRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  customExportId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalDownloadCustomExportResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalDownloadCustomExportResponse';
  customExport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomExport>;
  downloadUrl?: Maybe<Scalars['String']>;
};

/** Defines selected values for a filter to be applied to schema metrics. */
export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalFilterSelection';
  dateFilter?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter>;
  dimFilter?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DimensionFilter>;
  name?: Maybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOption = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalFilterSelectionOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOption_Input = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOptions = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalFilterSelectionOptions';
  dimension?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOption>>>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOptions_Input = {
  dimension?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOption_Input>>>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalFilterSelection_DateFilter';
  comparisonEnd?: Maybe<Scalars['DateTime']>;
  comparisonPeriod?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_ComparisonPeriod>;
  comparisonStart?: Maybe<Scalars['DateTime']>;
  dateRange?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_DateRange>;
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
  weekStartDay?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_Weekday>;
};

export enum Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_ComparisonPeriod {
  LastPeriod = 'LAST_PERIOD',
  LastPeriodDayOfWeekMatch = 'LAST_PERIOD_DAY_OF_WEEK_MATCH',
  LastYear = 'LAST_YEAR',
  LastYearDayOfWeekMatch = 'LAST_YEAR_DAY_OF_WEEK_MATCH',
  None = 'NONE'
}

export enum Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_DateRange {
  FiveWeeksAgo = 'FIVE_WEEKS_AGO',
  FourWeeksAgo = 'FOUR_WEEKS_AGO',
  Last_1Year = 'LAST_1_YEAR',
  Last_2Years = 'LAST_2_YEARS',
  Last_7Days = 'LAST_7_DAYS',
  Last_28Days = 'LAST_28_DAYS',
  Last_30Days = 'LAST_30_DAYS',
  Last_84Days = 'LAST_84_DAYS',
  Last_90Days = 'LAST_90_DAYS',
  Last_120Days = 'LAST_120_DAYS',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  QuarterToDate = 'QUARTER_TO_DATE',
  ThreeWeeksAgo = 'THREE_WEEKS_AGO',
  TwoWeeksAgo = 'TWO_WEEKS_AGO',
  Undefined = 'UNDEFINED',
  YearToDate = 'YEAR_TO_DATE',
  Yesterday = 'YESTERDAY'
}

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_Input = {
  comparisonEnd?: InputMaybe<Scalars['DateTime']>;
  comparisonPeriod?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_ComparisonPeriod>;
  comparisonStart?: InputMaybe<Scalars['DateTime']>;
  dateRange?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_DateRange>;
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
  weekStartDay?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_Weekday>;
};

export enum Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DimensionFilter = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalFilterSelection_DimensionFilter';
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DimensionFilter_Input = {
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Defines selected values for a filter to be applied to schema metrics. */
export type Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_Input = {
  dateFilter?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DateFilter_Input>;
  dimFilter?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_DimensionFilter_Input>;
  name?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalGetCustomReportRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalGetCustomReportResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalGetCustomReportResponse';
  customReport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomReport>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalListCustomExportsRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
  dashboardSlug?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalListCustomExportsResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalListCustomExportsResponse';
  customExports?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomExport>>>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalListCustomReportsRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  dashboardSlug?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalListCustomReportsResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalListCustomReportsResponse';
  customReports?: Maybe<Array<Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomReport>>>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalOrderBy = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalOrderBy';
  columnName?: Maybe<Scalars['String']>;
  orderDirection?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalOrderDirection>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalOrderBy_Input = {
  columnName?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalOrderDirection>;
};

export enum Instacart_Retailer_Tools_V2_InsightsPortalOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE'
}

export type Instacart_Retailer_Tools_V2_InsightsPortalRegenerateCustomExportRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  customExportId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalRegenerateCustomExportResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalRegenerateCustomExportResponse';
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * The name of a pre-defined schema.
 * Each schema defines certain metrics that can be queried and
 * filters that can be applied to those metrics.
 */
export enum Instacart_Retailer_Tools_V2_InsightsPortalSchema {
  AdsDisplayBanner = 'ADS_DISPLAY_BANNER',
  AdsSponsoredProduct = 'ADS_SPONSORED_PRODUCT',
  AggMerchCollections = 'AGG_MERCH_COLLECTIONS',
  AggMerchCollectionsRollup = 'AGG_MERCH_COLLECTIONS_ROLLUP',
  AggMerchPlacements = 'AGG_MERCH_PLACEMENTS',
  AggWarehouseLocationPerformance = 'AGG_WAREHOUSE_LOCATION_PERFORMANCE',
  AggWarehousePerformance = 'AGG_WAREHOUSE_PERFORMANCE',
  FactCampaignImpressionsPerformance = 'FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE',
  FactCampaignPerformance = 'FACT_CAMPAIGN_PERFORMANCE',
  FactCampaignPerformanceStitchFactCampaignImpressionsPerformance = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE',
  FactCampaignPerformanceStitchOrderDelivery = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY',
  FutureScheduledOrders = 'FUTURE_SCHEDULED_ORDERS',
  ImageCoverage = 'IMAGE_COVERAGE',
  InventoryIntelligenceOosSignalsAgg = 'INVENTORY_INTELLIGENCE_OOS_SIGNALS_AGG',
  MissingImage_90DayRetailerInsights = 'MISSING_IMAGE_90DAY_RETAILER_INSIGHTS',
  OrderDelivery = 'ORDER_DELIVERY',
  OrderItem = 'ORDER_ITEM',
  OrderItemStitchProductByCategoryBenchmark = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK',
  ProductByCategoryBenchmark = 'PRODUCT_BY_CATEGORY_BENCHMARK',
  ProductByPartnerBenchmark = 'PRODUCT_BY_PARTNER_BENCHMARK',
  SearchTerm = 'SEARCH_TERM',
  SearchTermByPartnerBenchmark = 'SEARCH_TERM_BY_PARTNER_BENCHMARK',
  SearchTermByTermBenchmark = 'SEARCH_TERM_BY_TERM_BENCHMARK',
  SearchTermStitchSearchTermByTermBenchmark = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK',
  Unknown = 'UNKNOWN'
}

export type Instacart_Retailer_Tools_V2_InsightsPortalSetCustomReportDisabledRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalSetCustomReportDisabledResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalSetCustomReportDisabledResponse';
  customReport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomReport>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalUpdateCustomReportRequest_Input = {
  authorizationContext?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalAuthorizationContext_Input>;
  dimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterSelectionOptionsList?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelectionOptions_Input>>>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalFilterSelection_Input>>>;
  id?: InputMaybe<Scalars['String']>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  orderBys?: InputMaybe<Array<InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalOrderBy_Input>>>;
  schema?: InputMaybe<Instacart_Retailer_Tools_V2_InsightsPortalSchema>;
};

export type Instacart_Retailer_Tools_V2_InsightsPortalUpdateCustomReportResponse = {
  __typename?: 'instacart_retailer_tools_v2_InsightsPortalUpdateCustomReportResponse';
  customReport?: Maybe<Instacart_Retailer_Tools_V2_InsightsPortalCustomReport>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Retailers_V1_GetWidgetsConfigRequest_Input = {
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailers_V1_GetWidgetsConfigResponse = {
  __typename?: 'instacart_retailers_v1_GetWidgetsConfigResponse';
  config?: Maybe<Scalars['String']>;
};

export type Instacart_Retailers_V1_UpsertWidgetsConfigRequest_Input = {
  config?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailers_V1_UpsertWidgetsConfigResponse = {
  __typename?: 'instacart_retailers_v1_UpsertWidgetsConfigResponse';
  config?: Maybe<Scalars['String']>;
};

export type Instacart_Retailers_V1_ValidateWidgetsConfigRequest_Input = {
  config?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailers_V1_ValidateWidgetsConfigResponse = {
  __typename?: 'instacart_retailers_v1_ValidateWidgetsConfigResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * A |Criterion| is a specification for a boolean function of one value.
 * Values can be evaluated by criteria, yielding either "true" or "false".
 * There several kinds of criteria - exact matches (e.g. "value == 42"),
 * ranges ("0 < value < 50"), even regex matches.  More kinds can and will
 * be added in the future.
 */
export type Instacart_Roulette_V1_Criterion = {
  __typename?: 'instacart_roulette_v1_Criterion';
  boolMatch?: Maybe<Instacart_Roulette_V1_Criterion_BoolCriterion>;
  dayOfWeek?: Maybe<Instacart_Roulette_V1_Criterion_DayOfWeekCriterion>;
  group?: Maybe<Instacart_Roulette_V1_Criterion_GroupCriterion>;
  numberRange?: Maybe<Instacart_Roulette_V1_Criterion_NumberRangeCriterion>;
  numericMatch?: Maybe<Instacart_Roulette_V1_Criterion_NumberCriterion>;
  semver?: Maybe<Instacart_Roulette_V1_Criterion_SemverCriterion>;
  stringMatch?: Maybe<Instacart_Roulette_V1_Criterion_StringCriterion>;
};

/** A criterion expecting a boolean value. */
export type Instacart_Roulette_V1_Criterion_BoolCriterion = {
  __typename?: 'instacart_roulette_v1_Criterion_BoolCriterion';
  expected?: Maybe<Scalars['Boolean']>;
};

/**
 * A criteron which is satisfied on the specified days of the week.
 *
 * TODO: I'm not happy with the placement of this check as a Criterion;
 * it's not really a function of request input.  Not sure what would
 * be better.
 */
export type Instacart_Roulette_V1_Criterion_DayOfWeekCriterion = {
  __typename?: 'instacart_roulette_v1_Criterion_DayOfWeekCriterion';
  days?: Maybe<Array<Maybe<Instacart_Roulette_V1_DayOfWeek>>>;
};

/**
 * A group criterion makes a claim that a given value is present
 * within the group.
 */
export type Instacart_Roulette_V1_Criterion_GroupCriterion = {
  __typename?: 'instacart_roulette_v1_Criterion_GroupCriterion';
  groupId?: Maybe<Scalars['String']>;
};

/** A criterion expecting a specific integer value. */
export type Instacart_Roulette_V1_Criterion_NumberCriterion = {
  __typename?: 'instacart_roulette_v1_Criterion_NumberCriterion';
  expected?: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

/**
 * A numeric-range criterion, containing an inclusive minimum value
 * and an exclusive maximum value.  It evaluates to true if the value
 * given is numeric and falls within the range defined.
 */
export type Instacart_Roulette_V1_Criterion_NumberRangeCriterion = {
  __typename?: 'instacart_roulette_v1_Criterion_NumberRangeCriterion';
  maxExclusive?: Maybe<Scalars['BigInt']>;
  minInclusive?: Maybe<Scalars['BigInt']>;
};

/**
 * A semantic version criterion makes a claim about a version string;
 * for details of semantic versions see https://semver.org/.
 *
 * A SemverCriterion consists of an expression.  An expression is a
 * combination of a comparison operator and a semantic version, for
 * example ">= 0.1.0".  In this example, the criterion is satisfied
 * by any semantic version comparing greater-than or equal-to version
 * "0.1.0".
 *
 * Valid operators are ">", "<", ">=", "<=", "=", and "!=".
 */
export type Instacart_Roulette_V1_Criterion_SemverCriterion = {
  __typename?: 'instacart_roulette_v1_Criterion_SemverCriterion';
  expression?: Maybe<Scalars['String']>;
};

/**
 * A criterion for a string value.
 * It may be an exact match (case sensitive or insensitive), or
 * it may be a regular expression.
 */
export type Instacart_Roulette_V1_Criterion_StringCriterion = {
  __typename?: 'instacart_roulette_v1_Criterion_StringCriterion';
  caseSensitive?: Maybe<Scalars['Boolean']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * HACK: copied verbatim from instacart.types.v1 to avoid
 * go's ridiculous habit of panicking when > 1 package
 * relies on the same enum.
 */
export enum Instacart_Roulette_V1_DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  ReservedDay = 'RESERVED_DAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Instacart_Roulette_V1_Feature = {
  __typename?: 'instacart_roulette_v1_Feature';
  /**
   * When a feature is archived, it should be hidden from user interfaces but
   * may still be enabled.
   */
  archived?: Maybe<Scalars['Boolean']>;
  /** Zero or more request IDs may be manually assigned to a named variant. */
  assignments?: Maybe<Array<Maybe<Instacart_Roulette_V1_Feature_StaticAssignment>>>;
  /** Zero or more request IDs may be explicitly opted in to a feature. */
  deprecatedStaticAssignments?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * A domain is a logical grouping of features, e.g. "customers" or "ads".
   * It is useful in that requests to list features may be scoped to one or
   * more domains, potentially making feature assignment faster on those
   * clients by avoiding
   */
  domain?: Maybe<Scalars['String']>;
  /** When false, the feature is disabled and no assignments to it will be made. */
  enabled?: Maybe<Scalars['Boolean']>;
  /**
   * Defines the particular method used to compute hashes when evaluating
   * this feature.
   */
  hashSpec?: Maybe<Instacart_Roulette_V1_HashSpec>;
  /** Is this feature a rollback of another feature? */
  isRollback?: Maybe<Scalars['Boolean']>;
  /**
   * The metadata field can be used to store data for extensions that are not
   * related to core Roulette functionality, e.g. canary configuration.
   */
  metadata?: Maybe<Instacart_Roulette_V1_Feature_Metadata>;
  /** A human-readable name for the feature. */
  name?: Maybe<Scalars['String']>;
  /**
   * Text describing the feature.  It might be a description of the related
   * experiment, a JIRA link, an Expy link, or nothing.
   */
  notes?: Maybe<Scalars['String']>;
  /**
   * The date after which the owner of this Feature should be nudged in order
   * to perform some cleanup, perhaps removing this Feature from Roulette
   */
  notifyDate?: Maybe<Scalars['DateTime']>;
  /**
   * A PartitionKind identifies on which kind of input partitioning will be
   * performed.
   */
  partitionKind?: Maybe<Instacart_Roulette_V1_Feature_PartitionKind>;
  /**
   * Whether the feature is marked as "permanent".
   * Permanent features won't get notified if the feature/toggle has been fully enabled for a predefined period of time
   */
  permanent?: Maybe<Instacart_Roulette_V1_Feature_Permanent>;
  /**
   * The Precondition associated with this feature, if any.  This field is
   * optional.
   *
   * This field is deprecated; use [preconditions] instead.
   */
  precondition?: Maybe<Instacart_Roulette_V1_Feature_Precondition>;
  /**
   * The Preconditions associated with this feature, if any.  This field is
   * optional.
   */
  preconditions?: Maybe<Array<Maybe<Instacart_Roulette_V1_Feature_Precondition>>>;
  rollback?: Maybe<Instacart_Roulette_V1_Feature_Rollback>;
  /**
   * The rules by which the feature will be assigned.
   * A feature will always have at least one ruleset, and at most one ruleset
   * at a time will be enabled.
   */
  rulesets?: Maybe<Array<Maybe<Instacart_Roulette_V1_Ruleset>>>;
  /**
   * A randomly-generated value that will be incorporated into the request-ID
   * hash.  This value must be strongly random, as it is what ensures the even
   * distribution of feature assignments.
   */
  salt?: Maybe<Scalars['BigInt']>;
  /** Specifies the totem system name under which we will create an entity */
  systemName?: Maybe<Scalars['String']>;
  /**
   * When true, every single exposure of this feature will be recorded
   * and available for analysis.  When false (the default), only aggregated
   * exposure statistics will be reported.
   */
  trackAllExposures?: Maybe<Scalars['Boolean']>;
  /** The user who created this feature version */
  user?: Maybe<Instacart_Roulette_V1_User>;
  /** A string uniquely identifying this feature.  This is an immutable identifier. */
  uuid?: Maybe<Scalars['String']>;
  variantValueType?: Maybe<Instacart_Roulette_V1_VariantValueType>;
  /**
   * A feature has one or more variants into which users will be assigned;
   * a variant represents an observable difference in the experience of the
   * feature (e.g. "teal button" vs "turquoise button" in the "blue button" feature).
   * Of the variants defined, different percentages of assigned users can be
   * slotted.  By default, users will be evenly distributed across variants,
   * but this is configurable for each ruleset.
   *
   * Exactly one variant in each feature must be the "default" variant - that is,
   * the control group.  This variant serves as the "fall-through" - when a user
   * is not assigned to a variant by any ruleset in the feature, they will "fall through"
   * to this default variant.
   */
  variants?: Maybe<Array<Maybe<Instacart_Roulette_V1_Feature_Variant>>>;
  /**
   * Any modification to the feature or to its rulesets will result in its
   * version being incremented.
   */
  version?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Roulette_V1_Feature_Metadata = {
  __typename?: 'instacart_roulette_v1_Feature_Metadata';
  /**
   * Each extension should have its own top-level key, with its schema
   * defined outside Roulette.
   */
  extensions?: Maybe<Scalars['JSON']>;
};

export type Instacart_Roulette_V1_Feature_PartitionKind = {
  __typename?: 'instacart_roulette_v1_Feature_PartitionKind';
  idDayKind?: Maybe<Instacart_Roulette_V1_Feature_PartitionKind_IdDayMod>;
  idKind?: Maybe<Instacart_Roulette_V1_Feature_PartitionKind_IdMod>;
};

/**
 * An ::IdDayMod feature will ensure that, within a single day, a
 * consistent set of requestors will be assigned to the feature, but that
 * set will be different on each weekday.
 */
export type Instacart_Roulette_V1_Feature_PartitionKind_IdDayMod = {
  __typename?: 'instacart_roulette_v1_Feature_PartitionKind_IdDayMod';
  /** A JSON Path, as in PartitionKind::IdMod#selector. */
  selector?: Maybe<Scalars['String']>;
};

/**
 * A PartitionKind::IdMod represents ID-based partitioning.  It identifies
 * a single attribute from the input object to serve as the request ID,
 * which will subsequently be hashed and used for bucketing.
 */
export type Instacart_Roulette_V1_Feature_PartitionKind_IdMod = {
  __typename?: 'instacart_roulette_v1_Feature_PartitionKind_IdMod';
  /**
   * A JSON Path identifying an input value to serve as the "request ID".
   * This may in practice be a shopper ID (e.g. "$.shopper_id"), customer
   * ID, Kochava identifier, Ahoy visitor token, or any other input.
   *
   * If a given request does not include the expected attribute, it will
   * not be assigned the feature.
   */
  selector?: Maybe<Scalars['String']>;
};

export type Instacart_Roulette_V1_Feature_Permanent = {
  __typename?: 'instacart_roulette_v1_Feature_Permanent';
  /** Is the feature permanent */
  isPermanent?: Maybe<Scalars['Boolean']>;
  /** The reason for marking the feature as permanent */
  reason?: Maybe<Scalars['String']>;
};

/**
 * A Precondition in a Feature means that evaluations of the feature are
 * predicated on the evaluation of another feature, identified by the
 * given feature_uuid.  If that feature evaluates to a positive assignment,
 * or to a specific variant, then evaluation of _this_ feature proceeds.
 *
 * If the Precondition is not satisfied, then this feature is deemed not
 * to match - both rulesets and static assignments are ignored.
 */
export type Instacart_Roulette_V1_Feature_Precondition = {
  __typename?: 'instacart_roulette_v1_Feature_Precondition';
  /**
   * The variant(s) expected to have been assigned.
   *
   * If present, may not be empty.
   */
  allowedVariants?: Maybe<Instacart_Roulette_V1_VariantList>;
  /** Set if the precondition depends on a percentage of the target feature's traffic */
  exclusiveGroup?: Maybe<Instacart_Roulette_V1_Feature_Precondition_ExclusiveGroupPrecondition>;
  /** The UUID identifying the feature targeted by this precondition. */
  featureUuid?: Maybe<Scalars['String']>;
  /** Whether or not the target feature should have been assigned. */
  shouldBeAssigned?: Maybe<Scalars['Boolean']>;
};

export type Instacart_Roulette_V1_Feature_Precondition_ExclusiveGroupPrecondition = {
  __typename?: 'instacart_roulette_v1_Feature_Precondition_ExclusiveGroupPrecondition';
  /**
   * The variant(s) that have been assigned.
   * This should be treated as read-only by the clients; the server will ignore this
   * and assign allowed_variants strictly based on `percentage`.
   */
  allowedVariants?: Maybe<Instacart_Roulette_V1_VariantList>;
  /**
   * For what percentage of the Exclusive Group's traffic should this
   * precondition pass?
   */
  percentage?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Roulette_V1_Feature_Rollback = {
  __typename?: 'instacart_roulette_v1_Feature_Rollback';
  /** The note specified when the feature is rolled back */
  note?: Maybe<Scalars['String']>;
  /** The version of the feature this feature is a rollback of */
  version?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Roulette_V1_Feature_StaticAssignment = {
  __typename?: 'instacart_roulette_v1_Feature_StaticAssignment';
  requestId?: Maybe<Scalars['String']>;
  variantName?: Maybe<Scalars['String']>;
};

export type Instacart_Roulette_V1_Feature_Variant = {
  __typename?: 'instacart_roulette_v1_Feature_Variant';
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Instacart_Roulette_V1_Feature_VariantValue>;
};

/** The Value of the variant */
export type Instacart_Roulette_V1_Feature_VariantValue = {
  __typename?: 'instacart_roulette_v1_Feature_VariantValue';
  encoding?: Maybe<Instacart_Roulette_V1_Feature_VariantValue_Encoding>;
  raw?: Maybe<Scalars['String']>;
};

/** The encoding method of the variant value */
export enum Instacart_Roulette_V1_Feature_VariantValue_Encoding {
  Json = 'JSON',
  Unspecified = 'UNSPECIFIED'
}

export type Instacart_Roulette_V1_GetFeatureByNameAndVersionRequest_Input = {
  /** Include the associated fields, e.g., Preconditions, Rulesets, etc */
  includeAssociations?: InputMaybe<Scalars['Boolean']>;
  /** The name of the feature */
  name?: InputMaybe<Scalars['String']>;
  /**
   * The version of the feature to fetch
   * If 0 is passed in, the latest version of the feature will be returned.
   */
  version?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Roulette_V1_GetFeatureByNameAndVersionResponse = {
  __typename?: 'instacart_roulette_v1_GetFeatureByNameAndVersionResponse';
  feature?: Maybe<Instacart_Roulette_V1_Feature>;
};

export type Instacart_Roulette_V1_GetFeaturesRequest_Input = {
  /**
   * A pagination token which, when set, will cause the next batch of features to be returned.
   * This value is expected to be empty for initial fetches, and for subsequent fetches will be
   * the value most recently provided by the server in a |GetFeaturesResponse|.
   */
  cursor?: InputMaybe<Scalars['String']>;
  /**
   * A list of domains for which features are requested.  If no domains are
   * given, features from all domains will be returned.
   */
  domain?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**
   * The maximum number of features to return for this request.  If zero or unset,
   * all features will be returned.
   */
  limit?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Roulette_V1_GetFeaturesResponse = {
  __typename?: 'instacart_roulette_v1_GetFeaturesResponse';
  /**
   * A pagination token.  Should be provided verbatim in the next |GetFeaturesRequest|.
   *
   * Notes on pagination:
   *
   * Pagination is of |Feature| only.  Each response will contain all groups depended on by
   * the features contained within that response.  Consequently, the same group may appear
   * many times in a series of responses; clients *MUST* dedupliate accordingly.
   *
   * In this design, pagination takes advantage of Roulette's append-only dataset.
   * Cursors identify the most-recently-modified feature that has yet been returned to a client.
   * Because all changes to existing features result in a new version, it's guaranteed that a
   * given cursor will always incorporate updates, without the need to maintain any kind of
   * additional state on the back end.
   */
  cursor?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Instacart_Roulette_V1_Feature>>>;
  groups?: Maybe<Array<Maybe<Instacart_Roulette_V1_Group>>>;
  status?: Maybe<Instacart_Roulette_V1_ResultStatus>;
};

/**
 * A |Group| is a named collection of values, used in criteria to
 * assert a user's membership in that group.  For example we might
 * have a group called 'west_coast_zones', containing every zone
 * that we deem to be on the West Coast; this can be used in multiple
 * features to filter requests to only those pertaining to those zones.
 * Another obvious group would be 'admins'.
 */
export type Instacart_Roulette_V1_Group = {
  __typename?: 'instacart_roulette_v1_Group';
  /** The unique human-readable name of the group. */
  id?: Maybe<Scalars['String']>;
  /** The group's members. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any modification to a Group will cause its version to be incremented. */
  version?: Maybe<Scalars['BigInt']>;
};

/** Specifies a hash function for use when evaluating a feature. */
export type Instacart_Roulette_V1_HashSpec = {
  __typename?: 'instacart_roulette_v1_HashSpec';
  simple?: Maybe<Instacart_Roulette_V1_HashSpec_Simple>;
  weightedRendezvous?: Maybe<Instacart_Roulette_V1_HashSpec_WeightedRendezvous>;
};

/**
 * Spells out particular hashing algorithms.  All algorithms here
 * are, unless otherwise specified, the 64-bit variant.
 */
export enum Instacart_Roulette_V1_HashSpec_HashPrimitive {
  Unspecified = 'UNSPECIFIED',
  /**
   * xxhash is an extremely fast non-cryptographic hash algorithm.
   * see https://cyan4973.github.io/xxHash/
   *
   * This is currently the only supported hash primitive.
   */
  Xxhash = 'XXHASH'
}

/**
 * The Simple specification is just the application of the bare primitive
 * to an identifier, and the 64-bit output is used as-is.
 *
 * Generally should never be used for new features.
 */
export type Instacart_Roulette_V1_HashSpec_Simple = {
  __typename?: 'instacart_roulette_v1_HashSpec_Simple';
  hashPrimitive?: Maybe<Instacart_Roulette_V1_HashSpec_HashPrimitive>;
};

/**
 * Specifies that the "Weighted Rendezvous Hash" approach should be used.
 * This is a variant of consistent hashing that allows for changing variant
 * weights without unduly disrupting existing assignments.
 *
 * Note for implementers:
 * There are numerous variants of WRH; we've settled on the following particulars:
 * - Logarithmic approach to applying weights; the precise equation for scoring is
 * `score = -nodeWeight / ln(combineHashes(hash(key), hash(node)) / MAX_UINT64)`
 * - xorshift* to combine key and node hashcodes
 * - nodes traversed in ascending lexicographic order
 *
 * For example: https://github.com/benjamin-bader/rendezvous/blob/v0.2.0/rendezvous.go
 */
export type Instacart_Roulette_V1_HashSpec_WeightedRendezvous = {
  __typename?: 'instacart_roulette_v1_HashSpec_WeightedRendezvous';
  hashPrimitive?: Maybe<Instacart_Roulette_V1_HashSpec_HashPrimitive>;
};

export type Instacart_Roulette_V1_Pagination_Input = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
};

/** A ResultStatus struct describes the outcome of a RouletteService RPC operation. */
export type Instacart_Roulette_V1_ResultStatus = {
  __typename?: 'instacart_roulette_v1_ResultStatus';
  /**
   * If the request was unsuccessful, may contain useful diagnostic information.
   * It's not safe to assume this will be available or useful in the general case.
   */
  data?: Maybe<Scalars['JSON']>;
  /** Indicates the disposition of the request */
  status?: Maybe<Instacart_Roulette_V1_ResultStatus_Status>;
};

export enum Instacart_Roulette_V1_ResultStatus_Status {
  /**
   * Indicates that some mutating operation failed due to a versioning conflict -
   * someone else touched the feature before you did, and your version was consequently
   * out-of-date.
   */
  Conflict = 'CONFLICT',
  /** The server got itself into trouble. */
  InternalError = 'INTERNAL_ERROR',
  /** Indicates that all went as requested. */
  Ok = 'OK',
  /** Indicates that the developers forgot to set a status code :( */
  Unknown = 'UNKNOWN'
}

/**
 * A Ruleset defines the rules governing how a feature is assigned.
 *
 * A ruleset may be enabled or disabled; if the latter, it will not be used
 * for assignment at all.
 *
 * The process of ruleset evaluation is as follows:
 * As input, a ruleset is applied to an associative array (hash, object, map, etc)
 * and a hashed identifier (see [Feature.request_id_selector]).  Based on the hash,
 * an exposure percentage is applied - whatever the exposure percentage is, that
 * proportion of requests are considered for assignment.  (Another way to say that
 * is that 1.0 - [exposure_percentage] percent of requests are excluded.)
 *
 * Of the requests included in the exposure, the population is further winnowed by
 * any defined rules
 */
export type Instacart_Roulette_V1_Ruleset = {
  __typename?: 'instacart_roulette_v1_Ruleset';
  enabled?: Maybe<Scalars['Boolean']>;
  /**
   * A value between zero and one.  This is the "top of the funnel",
   * and represents the proportion of requests considered for assignment.
   * For example, given an |exposure_percentage| of 0.3, 30% of requests
   * would proceed to be evaluated against the ruleset, and 70% would
   * be screened out.
   */
  exposurePercentage?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  /**
   * The rules to be evaulated for each request.
   * The input to a rule is a structured document (e.g. a JSON object);
   * consequently, a rule consists of a |path| and some criteria.
   * Paths are JSON Path (https://github.com/json-path/JsonPath) strings
   * denoting a specific value in the input, and criteria are one or more
   * well-defined conditions.
   *
   * Successive rules have a logical "AND" relationship.
   */
  rules?: Maybe<Array<Maybe<Instacart_Roulette_V1_Ruleset_Rule>>>;
  /**
   * A ruleset can define proportional weights for each of the feature's
   * variants.  By default, all variants are equally-weighted; if no variant
   * weights are specified, it is assumed that all have an equal default
   * weight.
   *
   * If weights are provided, then there must be one weight for every variant
   * defined for the featue; it is an error to provide any fewer or any more
   * weights.
   *
   * Weights are positive integers.  The sum of all weights must equal
   * 10,000.  This is an arbitrary constraint intended to make it easy to
   * reason about weights in terms of percentages.
   *
   * For example, given three variants "a", "b", and "c", if we wanted
   * 50% of assignments in "a", 30% in "b", and 20% in "c", the corresponding
   * weights would be:
   * {
   * "a": 5000,
   * "b": 3000,
   * "c": 2000,
   * }
   */
  variantWeights?: Maybe<Array<Maybe<Instacart_Roulette_V1_Ruleset_VariantWeight>>>;
};

export type Instacart_Roulette_V1_Ruleset_Rule = {
  __typename?: 'instacart_roulette_v1_Ruleset_Rule';
  /**
   * Multiple criteria have a logical "OR" relationship.
   * If any of the criteria in the list are satisfied by
   * the rule input, then the rule evaluates successfully.
   *
   * For example, given a rule of the form
   * {
   * "path": "$.email", criteria: [
   * {"type": "string", "expected": "foo"},
   * {"type": "string", "expected": "bar"},
   * ]
   * },
   *
   * the rule would match input like {"email": "foo"}.
   */
  criteria?: Maybe<Array<Maybe<Instacart_Roulette_V1_Criterion>>>;
  /** A |path| is a JSON Path notation descr */
  path?: Maybe<Scalars['String']>;
};

export type Instacart_Roulette_V1_Ruleset_VariantWeight = {
  __typename?: 'instacart_roulette_v1_Ruleset_VariantWeight';
  variant?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
};

export type Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_DomainNameFilter_Input = {
  operator?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_DomainNameFilter_Operator>;
  value?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_DomainNameFilter_Operator {
  Equals = 'EQUALS'
}

export type Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureNameFilter_Input = {
  operator?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureNameFilter_Operator>;
  value?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureNameFilter_Operator {
  Contains = 'CONTAINS',
  Equals = 'EQUALS'
}

export type Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureUuidFilter_Input = {
  operator?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureUuidFilter_Operator>;
  value?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureUuidFilter_Operator {
  Equals = 'EQUALS'
}

/** `Criterion` represents a single filter criterion on a feature attribute */
export type Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_Input = {
  domainName?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_DomainNameFilter_Input>;
  featureName?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureNameFilter_Input>;
  featureUuid?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_FeatureUuidFilter_Input>;
  preconditionName?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionNameFilter_Input>;
  preconditionUuid?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionUuidFilter_Input>;
};

export type Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionNameFilter_Input = {
  operator?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionNameFilter_Operator>;
  value?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionNameFilter_Operator {
  Contains = 'CONTAINS',
  Equals = 'EQUALS'
}

export type Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionUuidFilter_Input = {
  operator?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionUuidFilter_Operator>;
  value?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_PreconditionUuidFilter_Operator {
  Equals = 'EQUALS'
}

/**
 * `Filters` is a collection of criteria to be applied to the feature search
 * The criteria are AND'ed
 */
export type Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Input = {
  criteria?: InputMaybe<Array<InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Criterion_Input>>>;
};

export type Instacart_Roulette_V1_SearchFeaturesRequest_Input = {
  filters?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Filters_Input>;
  pagination?: InputMaybe<Instacart_Roulette_V1_Pagination_Input>;
  sort?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Sort_Input>;
};

export enum Instacart_Roulette_V1_SearchFeaturesRequest_Sort_Attribute {
  ByName = 'BY_NAME'
}

export type Instacart_Roulette_V1_SearchFeaturesRequest_Sort_By_Input = {
  attribute?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Sort_Attribute>;
  direction?: InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Sort_Direction>;
};

export enum Instacart_Roulette_V1_SearchFeaturesRequest_Sort_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Instacart_Roulette_V1_SearchFeaturesRequest_Sort_Input = {
  attributes?: InputMaybe<Array<InputMaybe<Instacart_Roulette_V1_SearchFeaturesRequest_Sort_By_Input>>>;
};

export type Instacart_Roulette_V1_SearchFeaturesResponse = {
  __typename?: 'instacart_roulette_v1_SearchFeaturesResponse';
  features?: Maybe<Array<Maybe<Instacart_Roulette_V1_Feature>>>;
  status?: Maybe<Instacart_Roulette_V1_ResultStatus>;
  totalCount?: Maybe<Scalars['BigInt']>;
};

export type Instacart_Roulette_V1_User = {
  __typename?: 'instacart_roulette_v1_User';
  /** User's email */
  email?: Maybe<Scalars['String']>;
};

/**
 * A list of zero or more variants.
 *
 * NOTE:
 * Useful only in that it allows embedding a repeated value
 * within a oneof.  If that's not your use-case, just use
 * a repeated field.
 */
export type Instacart_Roulette_V1_VariantList = {
  __typename?: 'instacart_roulette_v1_VariantList';
  variantName?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The configuration associated with a variant */
export type Instacart_Roulette_V1_VariantValueType = {
  __typename?: 'instacart_roulette_v1_VariantValueType';
  /** Is the type user defined or predefined */
  isUserDefined?: Maybe<Scalars['Boolean']>;
  /** The name of the variant value type */
  typeName?: Maybe<Instacart_Roulette_V1_VariantValueType_TypeName>;
};

/** The variant value type names */
export enum Instacart_Roulette_V1_VariantValueType_TypeName {
  Boolean = 'BOOLEAN',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  ListOfBooleans = 'LIST_OF_BOOLEANS',
  ListOfFloats = 'LIST_OF_FLOATS',
  ListOfIntegers = 'LIST_OF_INTEGERS',
  ListOfStrings = 'LIST_OF_STRINGS',
  String = 'STRING',
  Unspecified = 'UNSPECIFIED'
}

/**
 * Represents an accurate fractional value
 * Similar to google.type.Money
 */
export type Instacart_Types_V1_BigDecimal = {
  __typename?: 'instacart_types_v1_BigDecimal';
  /**
   * Nano units of the amount (10^-9)
   * Must be same sign as units
   * Example: The value -1.25 is represented as units=-1 and nanos=-250000000
   */
  nanos?: Maybe<Scalars['Int']>;
  /** Whole units part of the amount */
  units?: Maybe<Scalars['BigInt']>;
};

/**
 * Represents an accurate fractional value
 * Similar to google.type.Money
 */
export type Instacart_Types_V1_BigDecimal_Input = {
  /**
   * Nano units of the amount (10^-9)
   * Must be same sign as units
   * Example: The value -1.25 is represented as units=-1 and nanos=-250000000
   */
  nanos?: InputMaybe<Scalars['Int']>;
  /** Whole units part of the amount */
  units?: InputMaybe<Scalars['BigInt']>;
};

/**
 * Expresses a measurement unit based on the UCUM standard
 * which builds on ANSI_X3_50 and ISO_2955_1983 with explicit
 * emphasis on machine-to-machine interoperability.
 * See: http://unitsofmeasure.org/ucum.html.
 *
 * Services should document the unit_codes they support.
 * They should raise clear errors if unsupported unit_codes are passed.
 */
export type Instacart_Types_V1_MeasuredQuantity = {
  __typename?: 'instacart_types_v1_MeasuredQuantity';
  /**
   * The quantity in relevant units. Quantity may be positive, negative or zero in a message.
   * The semantics of negative quantities are application-specific.
   */
  quantity?: Maybe<Scalars['Float']>;
  /**
   * The measurement unit for this quantity. If this is the unknown MeasurementUnit
   * then the quantity field cannot be deterministically interpreted without
   * outside knowledge or heuristics. Valid MeasurementUnits should be used for all
   * new data.
   */
  unit?: Maybe<Instacart_Types_V1_MeasurementUnit>;
};

/**
 * Expresses a measurement unit based on the UCUM standard
 * which builds on ANSI_X3_50 and ISO_2955_1983 with explicit
 * emphasis on machine-to-machine interoperability.
 * See: http://unitsofmeasure.org/ucum.html.
 *
 * Services should document the unit_codes they support.
 * They should raise clear errors if unsupported unit_codes are passed.
 */
export type Instacart_Types_V1_MeasurementUnit = {
  __typename?: 'instacart_types_v1_MeasurementUnit';
  /** Cost unit of the item. */
  costUnit?: Maybe<Instacart_Types_V1_MeasurementUnit_CostUnit>;
  /**
   * Represents the case-sensitive symbols (c/s) defined by the
   * UCUM specification. Non-matching MeasurementUnits *might* be
   * convertible within the UCUM system by an application that
   * understands the semantics of UCUM symbols, base units, and
   * derived units.
   *
   * Simple equality comparison of a MeasurementUnit requires only
   * a case-sensitive comparison of `unit_code`.
   * Confirming that a `unit_code` is valid in the UCUM system requires
   * a full understanding the specification.
   * The protobuf default string value is explicitly *not* a valid symbol
   * in the UCUM system and represents an unknown MeasurementUnit
   * rather than a literal code. An unknown unit code does not match any
   * other unit code (including unknown).
   *
   * Note that the Arbitrary Unit code "[arb'U]" is used to represent "per each" quantities
   * where the concept of "each" is relative to an identified thing.
   * See: https://en.wikipedia.org/wiki/Arbitrary_unit
   *
   * Common unit_codes used in Instacart flows include:
   * Arbitrary Unit (per each):               "[arb'U]"
   * US Fluid Ounce (fluid volume):           "[foz_us]"
   * Avoirdupois Pound (mass aka weight):     "[lb_av]"
   */
  unitCode?: Maybe<Scalars['String']>;
};

export enum Instacart_Types_V1_MeasurementUnit_CostUnit {
  CostUnitUnspecified = 'COST_UNIT_UNSPECIFIED',
  /** Unit is measured as each */
  Each = 'EACH',
  /** Fluid Ounce */
  FlOz = 'FL_OZ',
  /** Gallons */
  Gallon = 'GALLON',
  /** grams, 1/1000 of a KG */
  Gram = 'GRAM',
  /** Kilogram */
  Kg = 'KG',
  /** Pounds */
  Lb = 'LB',
  /** 1 L = 1 Liter = 1_000 MilliLiter */
  Liter = 'LITER',
  /** MilliLiter, and not MegaLiter */
  Ml = 'ML',
  /** Ounce */
  Oz = 'OZ',
  /** Pint; 1/2 of a Gallon */
  Pint = 'PINT',
  /** Quart; 1/4 of a Gallon */
  Quart = 'QUART'
}

/**
 * Contextual information used for identifying the
 * marketplace/pbi/connect configurations associated
 * with a request
 */
export type Instacart_Types_V1_RequestContext_Input = {
  /**
   * NOTE: this ID is still a proposal/wip
   * for replacing the multiple identifiers long term
   */
  applicationId?: InputMaybe<Scalars['Int']>;
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  /** ISO numeric code */
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export type GetConfirmedStoreHoursQueryVariables = Exact<{
  retailerLocationIds: Array<Scalars['BigInt']> | Scalars['BigInt'];
  startDate?: InputMaybe<GoogleTypeDateInput>;
  endDate?: InputMaybe<GoogleTypeDateInput>;
}>;


export type GetConfirmedStoreHoursQuery = { __typename?: 'Query', retailerStoreHoursGetConfirmedStoreHours?: { __typename?: 'GetConfirmedStoreHoursResponse', confirmations?: Array<{ __typename?: 'ConfirmedStoreHours', retailerLocationId?: string, confirmed?: boolean, date?: { __typename?: 'GoogleTypeDate', year?: number, month?: number, day?: number } }> } };

export type GetRetailerHolidaysStatusQueryVariables = Exact<{
  retailerId: Scalars['BigInt'];
}>;


export type GetRetailerHolidaysStatusQuery = { __typename?: 'Query', retailerStoreHoursGetRetailerHolidaysStatus?: { __typename?: 'GetHolidaysStatusResponse', holidays?: Array<{ __typename?: 'HolidayStatus', confirmedRetailerLocationCount?: number, unconfirmedRetailerLocationCount?: number, holiday?: { __typename?: 'Holiday', holidaySlug?: string, regions?: Array<string>, date?: { __typename?: 'GoogleTypeDate', day?: number, month?: number, year?: number } } }> } };

export type GetStoreGroupsByRetailerQueryVariables = Exact<{
  partnerId: Scalars['ID'];
  retailerId?: InputMaybe<Scalars['ID']>;
}>;


export type GetStoreGroupsByRetailerQuery = { __typename?: 'Query', retailerLocationGroupsByRetailer: { __typename?: 'RetailersRetailerLocationGroupConnection', nodes?: Array<{ __typename?: 'RetailersRetailerLocationGroup', id: string, name: string, retailerLocationIds: Array<string> }> } };

export type GetStoreHoursBulkQueryVariables = Exact<{
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>> | InputMaybe<Scalars['BigInt']>>;
  serviceTypes?: InputMaybe<Array<InputMaybe<RetailerServiceType>> | InputMaybe<RetailerServiceType>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  endDate?: InputMaybe<GoogleTypeDateInput>;
}>;


export type GetStoreHoursBulkQuery = { __typename?: 'Query', retailerStoreHoursGetStoreHours?: { __typename?: 'GetStoreHoursResponse', storeHours?: Array<{ __typename?: 'StoreHours', retailerLocationId?: string, updatedAt?: any, regularHours?: Array<{ __typename?: 'RegularHours', dayOfWeek?: GoogleTypeDayOfWeek, serviceType?: RetailerServiceType, startTime?: { __typename?: 'GoogleTypeTimeOfDay', hours?: number, minutes?: number }, endTime?: { __typename?: 'GoogleTypeTimeOfDay', hours?: number, minutes?: number } }>, overrideHours?: Array<{ __typename?: 'OverrideHours', serviceType?: RetailerServiceType, description?: string, date?: { __typename?: 'GoogleTypeDate', year?: number, month?: number, day?: number }, startTime?: { __typename?: 'GoogleTypeTimeOfDay', hours?: number, minutes?: number }, endTime?: { __typename?: 'GoogleTypeTimeOfDay', hours?: number, minutes?: number } }> }> } };

export type GetUpdateTasksQueryVariables = Exact<{
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>> | InputMaybe<Scalars['BigInt']>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
}>;


export type GetUpdateTasksQuery = { __typename?: 'Query', retailerStoreHoursGetStoreHoursUpdateTasks?: { __typename?: 'GetStoreHoursUpdateTasksResponse', tasks?: Array<{ __typename?: 'StoreHoursUpdateTask', id?: string, updatedRetailerLocationIds?: Array<string>, status?: TaskStatus, createdAt?: any, completedAt?: any, updateSource?: string, author?: string, failedUpdates?: Array<{ __typename?: 'FailedUpdate', retailerLocationIds?: Array<string> }> }> } };

export type GetVirtualRetailersQueryVariables = Exact<{
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>> | InputMaybe<Scalars['BigInt']>>;
}>;


export type GetVirtualRetailersQuery = { __typename?: 'Query', retailerToolsServiceGetVirtualRetailers?: { __typename?: 'GetVirtualRetailersResponse', virtualRetailers?: Array<{ __typename?: 'VirtualRetailer', coreRetailerId?: string, virtualRetailerId?: string }> } };

export type SetConfirmedStoreHoursMutationVariables = Exact<{
  retailerId: Scalars['BigInt'];
  confirmations: Array<ConfirmedStoreHoursInput> | ConfirmedStoreHoursInput;
}>;


export type SetConfirmedStoreHoursMutation = { __typename?: 'Mutation', retailerStoreHoursUpdateConfirmStoreHours?: { __typename?: 'UpdateConfirmedStoreHoursResponse', confirmations?: Array<{ __typename?: 'ConfirmedStoreHours', retailerLocationId?: string, confirmed?: boolean, date?: { __typename?: 'GoogleTypeDate', year?: number, month?: number, day?: number } }> } };

export type SetStoreHoursMutationVariables = Exact<{
  retailerId: Scalars['BigInt'];
  retailerLocationIds?: InputMaybe<Array<Scalars['BigInt']> | Scalars['BigInt']>;
  regularHours?: InputMaybe<Array<RegularHoursInput> | RegularHoursInput>;
  overrideHours?: InputMaybe<Array<OverrideHoursInput> | OverrideHoursInput>;
  clearOverrideHoursByType?: InputMaybe<Array<RetailerServiceType> | RetailerServiceType>;
  clearOverrideHoursCriteria?: InputMaybe<Array<ClearOverrideHoursCriteriaInput> | ClearOverrideHoursCriteriaInput>;
  confirmations?: InputMaybe<Array<ConfirmedStoreHoursInput> | ConfirmedStoreHoursInput>;
}>;


export type SetStoreHoursMutation = { __typename?: 'Mutation', retailerStoreHoursUpdateStoreHours?: { __typename?: 'UpdateStoreHoursResponse', taskId?: string } };


export const GetConfirmedStoreHoursDocument = gql`
    query getConfirmedStoreHours($retailerLocationIds: [BigInt!]!, $startDate: GoogleTypeDateInput, $endDate: GoogleTypeDateInput) {
  retailerStoreHoursGetConfirmedStoreHours(
    input: {retailerLocationIds: $retailerLocationIds, startDate: $startDate, endDate: $endDate}
  ) {
    confirmations {
      retailerLocationId
      date {
        year
        month
        day
      }
      confirmed
    }
  }
}
    `;

/**
 * __useGetConfirmedStoreHoursQuery__
 *
 * To run a query within a React component, call `useGetConfirmedStoreHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfirmedStoreHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfirmedStoreHoursQuery({
 *   variables: {
 *      retailerLocationIds: // value for 'retailerLocationIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetConfirmedStoreHoursQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetConfirmedStoreHoursQuery, GetConfirmedStoreHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetConfirmedStoreHoursQuery, GetConfirmedStoreHoursQueryVariables>(GetConfirmedStoreHoursDocument, options);
      }
export function useGetConfirmedStoreHoursLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConfirmedStoreHoursQuery, GetConfirmedStoreHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetConfirmedStoreHoursQuery, GetConfirmedStoreHoursQueryVariables>(GetConfirmedStoreHoursDocument, options);
        }
export type GetConfirmedStoreHoursQueryHookResult = ReturnType<typeof useGetConfirmedStoreHoursQuery>;
export type GetConfirmedStoreHoursLazyQueryHookResult = ReturnType<typeof useGetConfirmedStoreHoursLazyQuery>;
export type GetConfirmedStoreHoursQueryResult = ApolloReactCommon.QueryResult<GetConfirmedStoreHoursQuery, GetConfirmedStoreHoursQueryVariables>;
export const GetRetailerHolidaysStatusDocument = gql`
    query getRetailerHolidaysStatus($retailerId: BigInt!) {
  retailerStoreHoursGetRetailerHolidaysStatus(input: {retailerId: $retailerId}) {
    holidays {
      holiday {
        holidaySlug
        date {
          day
          month
          year
        }
        regions
      }
      confirmedRetailerLocationCount
      unconfirmedRetailerLocationCount
    }
  }
}
    `;

/**
 * __useGetRetailerHolidaysStatusQuery__
 *
 * To run a query within a React component, call `useGetRetailerHolidaysStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetailerHolidaysStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetailerHolidaysStatusQuery({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *   },
 * });
 */
export function useGetRetailerHolidaysStatusQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRetailerHolidaysStatusQuery, GetRetailerHolidaysStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRetailerHolidaysStatusQuery, GetRetailerHolidaysStatusQueryVariables>(GetRetailerHolidaysStatusDocument, options);
      }
export function useGetRetailerHolidaysStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRetailerHolidaysStatusQuery, GetRetailerHolidaysStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRetailerHolidaysStatusQuery, GetRetailerHolidaysStatusQueryVariables>(GetRetailerHolidaysStatusDocument, options);
        }
export type GetRetailerHolidaysStatusQueryHookResult = ReturnType<typeof useGetRetailerHolidaysStatusQuery>;
export type GetRetailerHolidaysStatusLazyQueryHookResult = ReturnType<typeof useGetRetailerHolidaysStatusLazyQuery>;
export type GetRetailerHolidaysStatusQueryResult = ApolloReactCommon.QueryResult<GetRetailerHolidaysStatusQuery, GetRetailerHolidaysStatusQueryVariables>;
export const GetStoreGroupsByRetailerDocument = gql`
    query getStoreGroupsByRetailer($partnerId: ID!, $retailerId: ID) {
  retailerLocationGroupsByRetailer(partnerId: $partnerId, retailerId: $retailerId) {
    nodes {
      id
      name
      retailerLocationIds
    }
  }
}
    `;

/**
 * __useGetStoreGroupsByRetailerQuery__
 *
 * To run a query within a React component, call `useGetStoreGroupsByRetailerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreGroupsByRetailerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreGroupsByRetailerQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      retailerId: // value for 'retailerId'
 *   },
 * });
 */
export function useGetStoreGroupsByRetailerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetStoreGroupsByRetailerQuery, GetStoreGroupsByRetailerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStoreGroupsByRetailerQuery, GetStoreGroupsByRetailerQueryVariables>(GetStoreGroupsByRetailerDocument, options);
      }
export function useGetStoreGroupsByRetailerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStoreGroupsByRetailerQuery, GetStoreGroupsByRetailerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStoreGroupsByRetailerQuery, GetStoreGroupsByRetailerQueryVariables>(GetStoreGroupsByRetailerDocument, options);
        }
export type GetStoreGroupsByRetailerQueryHookResult = ReturnType<typeof useGetStoreGroupsByRetailerQuery>;
export type GetStoreGroupsByRetailerLazyQueryHookResult = ReturnType<typeof useGetStoreGroupsByRetailerLazyQuery>;
export type GetStoreGroupsByRetailerQueryResult = ApolloReactCommon.QueryResult<GetStoreGroupsByRetailerQuery, GetStoreGroupsByRetailerQueryVariables>;
export const GetStoreHoursBulkDocument = gql`
    query getStoreHoursBulk($retailerLocationIds: [BigInt], $serviceTypes: [RetailerServiceType], $startDate: GoogleTypeDateInput, $endDate: GoogleTypeDateInput) {
  retailerStoreHoursGetStoreHours(
    input: {retailerLocationIds: $retailerLocationIds, serviceTypes: $serviceTypes, startDate: $startDate, endDate: $endDate}
  ) {
    storeHours {
      retailerLocationId
      regularHours {
        dayOfWeek
        serviceType
        startTime {
          hours
          minutes
        }
        endTime {
          hours
          minutes
        }
      }
      overrideHours {
        date {
          year
          month
          day
        }
        serviceType
        startTime {
          hours
          minutes
        }
        endTime {
          hours
          minutes
        }
        description
      }
      updatedAt
    }
  }
}
    `;

/**
 * __useGetStoreHoursBulkQuery__
 *
 * To run a query within a React component, call `useGetStoreHoursBulkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreHoursBulkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreHoursBulkQuery({
 *   variables: {
 *      retailerLocationIds: // value for 'retailerLocationIds'
 *      serviceTypes: // value for 'serviceTypes'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetStoreHoursBulkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStoreHoursBulkQuery, GetStoreHoursBulkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStoreHoursBulkQuery, GetStoreHoursBulkQueryVariables>(GetStoreHoursBulkDocument, options);
      }
export function useGetStoreHoursBulkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStoreHoursBulkQuery, GetStoreHoursBulkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStoreHoursBulkQuery, GetStoreHoursBulkQueryVariables>(GetStoreHoursBulkDocument, options);
        }
export type GetStoreHoursBulkQueryHookResult = ReturnType<typeof useGetStoreHoursBulkQuery>;
export type GetStoreHoursBulkLazyQueryHookResult = ReturnType<typeof useGetStoreHoursBulkLazyQuery>;
export type GetStoreHoursBulkQueryResult = ApolloReactCommon.QueryResult<GetStoreHoursBulkQuery, GetStoreHoursBulkQueryVariables>;
export const GetUpdateTasksDocument = gql`
    query getUpdateTasks($retailerLocationIds: [BigInt], $startDate: GoogleTypeDateInput) {
  retailerStoreHoursGetStoreHoursUpdateTasks(
    input: {retailerLocationIds: $retailerLocationIds, startDate: $startDate}
  ) {
    tasks {
      id
      updatedRetailerLocationIds
      status
      createdAt
      completedAt
      updateSource
      author
      failedUpdates {
        retailerLocationIds
      }
    }
  }
}
    `;

/**
 * __useGetUpdateTasksQuery__
 *
 * To run a query within a React component, call `useGetUpdateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateTasksQuery({
 *   variables: {
 *      retailerLocationIds: // value for 'retailerLocationIds'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetUpdateTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUpdateTasksQuery, GetUpdateTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUpdateTasksQuery, GetUpdateTasksQueryVariables>(GetUpdateTasksDocument, options);
      }
export function useGetUpdateTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUpdateTasksQuery, GetUpdateTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUpdateTasksQuery, GetUpdateTasksQueryVariables>(GetUpdateTasksDocument, options);
        }
export type GetUpdateTasksQueryHookResult = ReturnType<typeof useGetUpdateTasksQuery>;
export type GetUpdateTasksLazyQueryHookResult = ReturnType<typeof useGetUpdateTasksLazyQuery>;
export type GetUpdateTasksQueryResult = ApolloReactCommon.QueryResult<GetUpdateTasksQuery, GetUpdateTasksQueryVariables>;
export const GetVirtualRetailersDocument = gql`
    query getVirtualRetailers($retailerIds: [BigInt]) {
  retailerToolsServiceGetVirtualRetailers(
    input: {parameters: {retailerIds: $retailerIds}}
  ) {
    virtualRetailers {
      coreRetailerId
      virtualRetailerId
    }
  }
}
    `;

/**
 * __useGetVirtualRetailersQuery__
 *
 * To run a query within a React component, call `useGetVirtualRetailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVirtualRetailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVirtualRetailersQuery({
 *   variables: {
 *      retailerIds: // value for 'retailerIds'
 *   },
 * });
 */
export function useGetVirtualRetailersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVirtualRetailersQuery, GetVirtualRetailersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetVirtualRetailersQuery, GetVirtualRetailersQueryVariables>(GetVirtualRetailersDocument, options);
      }
export function useGetVirtualRetailersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVirtualRetailersQuery, GetVirtualRetailersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetVirtualRetailersQuery, GetVirtualRetailersQueryVariables>(GetVirtualRetailersDocument, options);
        }
export type GetVirtualRetailersQueryHookResult = ReturnType<typeof useGetVirtualRetailersQuery>;
export type GetVirtualRetailersLazyQueryHookResult = ReturnType<typeof useGetVirtualRetailersLazyQuery>;
export type GetVirtualRetailersQueryResult = ApolloReactCommon.QueryResult<GetVirtualRetailersQuery, GetVirtualRetailersQueryVariables>;
export const SetConfirmedStoreHoursDocument = gql`
    mutation setConfirmedStoreHours($retailerId: BigInt!, $confirmations: [ConfirmedStoreHoursInput!]!) {
  retailerStoreHoursUpdateConfirmStoreHours(
    input: {retailerId: $retailerId, confirmations: $confirmations}
  ) {
    confirmations {
      retailerLocationId
      date {
        year
        month
        day
      }
      confirmed
    }
  }
}
    `;

/**
 * __useSetConfirmedStoreHoursMutation__
 *
 * To run a mutation, you first call `useSetConfirmedStoreHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConfirmedStoreHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConfirmedStoreHoursMutation, { data, loading, error }] = useSetConfirmedStoreHoursMutation({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *      confirmations: // value for 'confirmations'
 *   },
 * });
 */
export function useSetConfirmedStoreHoursMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetConfirmedStoreHoursMutation, SetConfirmedStoreHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetConfirmedStoreHoursMutation, SetConfirmedStoreHoursMutationVariables>(SetConfirmedStoreHoursDocument, options);
      }
export type SetConfirmedStoreHoursMutationHookResult = ReturnType<typeof useSetConfirmedStoreHoursMutation>;
export type SetConfirmedStoreHoursMutationResult = ApolloReactCommon.MutationResult<SetConfirmedStoreHoursMutation>;
export type SetConfirmedStoreHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<SetConfirmedStoreHoursMutation, SetConfirmedStoreHoursMutationVariables>;
export const SetStoreHoursDocument = gql`
    mutation setStoreHours($retailerId: BigInt!, $retailerLocationIds: [BigInt!], $regularHours: [RegularHoursInput!], $overrideHours: [OverrideHoursInput!], $clearOverrideHoursByType: [RetailerServiceType!], $clearOverrideHoursCriteria: [ClearOverrideHoursCriteriaInput!], $confirmations: [ConfirmedStoreHoursInput!]) {
  retailerStoreHoursUpdateStoreHours(
    input: {retailerId: $retailerId, retailerLocationIds: $retailerLocationIds, updateRegularHours: $regularHours, upsertOverrideHours: $overrideHours, clearOverrideHoursByType: $clearOverrideHoursByType, clearOverrideHoursCriteria: $clearOverrideHoursCriteria, confirmations: $confirmations, updateSource: "IPP"}
  ) {
    taskId
  }
}
    `;

/**
 * __useSetStoreHoursMutation__
 *
 * To run a mutation, you first call `useSetStoreHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStoreHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStoreHoursMutation, { data, loading, error }] = useSetStoreHoursMutation({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *      retailerLocationIds: // value for 'retailerLocationIds'
 *      regularHours: // value for 'regularHours'
 *      overrideHours: // value for 'overrideHours'
 *      clearOverrideHoursByType: // value for 'clearOverrideHoursByType'
 *      clearOverrideHoursCriteria: // value for 'clearOverrideHoursCriteria'
 *      confirmations: // value for 'confirmations'
 *   },
 * });
 */
export function useSetStoreHoursMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetStoreHoursMutation, SetStoreHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetStoreHoursMutation, SetStoreHoursMutationVariables>(SetStoreHoursDocument, options);
      }
export type SetStoreHoursMutationHookResult = ReturnType<typeof useSetStoreHoursMutation>;
export type SetStoreHoursMutationResult = ApolloReactCommon.MutationResult<SetStoreHoursMutation>;
export type SetStoreHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<SetStoreHoursMutation, SetStoreHoursMutationVariables>;
export const usePaginatedGetStoreGroupsByRetailerQuery = withPaginationDetails(useGetStoreGroupsByRetailerQuery, "retailerLocationGroupsByRetailer")