import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { orderItemsSchemaFilterParamsConfig } from '../order-item/insightsPortalOrderItemsSchema.helpers'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
} from '../helpers/schema.helpers'
import { InsightsFormatterType } from '../types/schema.types'
import { GroupBys, Metrics, Dimensions } from './orderItemStitchProductByCategoryBenchmark.types'

export { GroupBys, Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.SUM_GMV]: {
      label: 'insightsPortalDomain.basketDashboard.topCategoriesByGmvShareBreakdown.header.gmv',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.DIFF_GMV_SHARE_CATEGORY_BENCHMARK]: {
      label: 'insightsPortalDomain.basketDashboard.gmvShareTable.header.gmvShareVsBenchmark',
      formatter: InsightsFormatterType.Percentage,
      private: true,
    },
    [Metrics.TOTAL_GMV]: {
      label: 'insightsPortalDomain.basketDashboard.topCategoriesByGmvShareBreakdown.header.gmv',
      formatter: InsightsFormatterType.Currency,
      private: true,
    },
    [Metrics.BENCHMARK_TOTAL_GMV]: {
      label: 'insightsPortalDomain.basketDashboard.topCategoriesByGmvShareBreakdown.header.gmv',
      formatter: InsightsFormatterType.Currency,
      private: true,
    },
    [Metrics.BENCHMARK_SUM_GMV]: {
      label: 'insightsPortalDomain.basketDashboard.topCategoriesByGmvShareBreakdown.header.gmv',
      formatter: InsightsFormatterType.Currency,
      private: true,
    },
    [Metrics.BENCHMARK_AVG_ITEM_FOUND]: {
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.AVG_ITEM_FOUND',
      formatter: InsightsFormatterType.Percentage,
      private: true,
    },
    [Metrics.PRODUCT_NAME]: {
      private: true,
    },
    [Metrics.GMV_SHARE]: {
      label: 'insightsPortalDomain.basketDashboard.gmvShareTable.header.gmvShare',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_FILLED]: {
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.AVG_ITEM_FILLED',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_FOUND]: {
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.AVG_ITEM_FOUND',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_REPLACED]: {
      label: 'insightsPortalDomain.outOfStockTable.replacementRate',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.LOST_GMV]: {
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.LOST_GMV',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.DIFF_AVG_ITEM_FOUND_CATEGORY_BENCHMARK]: {
      label: 'insightsPortalDomain.outOfStockDashboard.byLostGmv.header.foundVsBenchmark',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_FOUND_CATEGORY_BENCHMARK_GMV_GAP]: {
      label: 'insightsPortalDomain.outOfStockTable.benchmarkGmvGap',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_BASKET]: {
      label: 'insightsPortalDomain.basketDashboard.metrics.AVG_BASKET',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.BENCHMARK_GMV_SHARE]: {
      label: 'insightsPortalDomain.basketDashboard.gmvShareTable.header.gmvShare',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_FILLED_PRICE]: {
      label: 'insightsPortalDomain.basketDashboard.metrics.AVG_ITEM_FILLED_PRICE',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_LOST_GMV_PER_STORE]: {
      label: 'insightsPortalDomain.basketDashboard.metrics.AVG_LOST_GMV_PER_STORE',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.AVG_ORDERED_ITEM]: {
      label: 'insightsPortalDomain.basketDashboard.metrics.AVG_ORDERED_ITEM',
      formatter: InsightsFormatterType.Currency,
    },
    [Metrics.BENCHMARK_AVG_ITEM_FILLED]: {
      label: 'insightsPortalDomain.outOfStockDashboard.metrics.AVG_ITEM_FILLED',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.COUNT_ORDER]: {
      label: 'insightsPortalDomain.orderItems.metrics.COUNT_ORDER',
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.LAST_ETL_UPDATE]: {
      private: true,
      formatter: InsightsFormatterType.Date,
    },
    [Metrics.GMV_SHARE_PCT_DIFF]: {
      label: 'insightsPortalDomain.categoryDetails.gmvSharePercentChange',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_FOUND_PCT_DIFF]: {
      label: 'insightsPortalDomain.outOfStockTable.percentFoundChange',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_FOUND_CATEGORY_BENCHMARK_GMV_GAP_PCT_DIFF]: {
      label: 'insightsPortalDomain.outOfStockTable.benchGmvGapPercentChange',
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_REPLACED_PCT_DIFF]: {
      formatter: InsightsFormatterType.Percentage,
    },
  },
  dimensionsConfig: {
    [Dimensions.ORDER_SOURCE]: {
      private: true,
    },
    [Dimensions.ALCOHOL]: {
      private: true,
    },
    [Dimensions.EXPRESS]: {
      private: true,
    },
    [Dimensions.LOYALTY]: {
      private: true,
    },
    [Dimensions.FULFILLMENT_TYPE]: {
      private: true,
    },
    [Dimensions.FIRST_ORDER]: {
      private: true,
    },
    [Dimensions.VIRTUAL_WAREHOUSE]: {
      private: true,
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      private: true,
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      private: true,
    },
    [Dimensions.WAREHOUSE_LOCATION_CODE]: {
      private: true,
    },
    [Dimensions.WAREHOUSE_LOCATION_GROUP]: {
      private: true,
    },
    [Dimensions.PARTNER_GEO_DIVISION]: {
      private: true,
    },
    [Dimensions.ORDER_CURRENCY]: {
      private: true,
    },
    [Dimensions.REGION]: {
      private: true,
    },
    [Dimensions.L1_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L1Category',
    },
    [Dimensions.L2_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L2Category',
    },
    [Dimensions.L3_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L3Category',
    },
    [Dimensions.L4_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L4Category',
    },
    [Dimensions.L5_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L5Category',
    },
    [Dimensions.L6_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L6Category',
    },
    [Dimensions.PRODUCT_CATEGORY_ID]: {
      private: true,
    },
    [Dimensions.RETAILER_PRODUCT]: {
      private: true,
      label: 'insightsPortalDomain.collectionProducts.groupBys.productName',
    },
    [Dimensions.RETAILER_PRODUCT_ID]: {
      private: true,
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [Dimensions.CATALOG_PRODUCT_ID]: {
      private: true,
    },
    [Dimensions.PRODUCT_UPC_CODE]: {
      private: true,
    },
    [Dimensions.RX_ORDER]: {
      private: true,
    },
    [Dimensions.EBT_ITEM]: {
      private: true,
    },
    [Dimensions.INSIGHTS_PORTAL_REPORTING_PLATFORM]: {
      private: true,
    },
    [Dimensions.EBT_ORDER]: {
      private: true,
    },
    ...buildTimeIntervalDimensionsConfig,
  },

  // TODO: deprecate
  groupBys: GroupBys,
  groupBysConfig: {
    ...buildTimeIntervalGroupBysConfig,
    [GroupBys.ORDER_SOURCE]: {
      private: true,
    },
    [GroupBys.PICKUP]: {
      private: true,
    },
    [GroupBys.ALCOHOL]: {
      private: true,
    },
    [GroupBys.EXPRESS]: {
      private: true,
    },
    [GroupBys.LOYALTY]: {
      private: true,
    },
    [GroupBys.EBT]: {
      private: true,
    },
    [GroupBys.FIRST_ORDER]: {
      private: true,
    },
    [GroupBys.VIRTUAL_WAREHOUSE]: {
      private: true,
    },
    [GroupBys.WAREHOUSE_LOCATION]: {
      private: true,
    },
    [GroupBys.WAREHOUSE_LOCATION_STATE]: {
      private: true,
    },
    [GroupBys.PARTNER_GEO_DIVISION]: {
      private: true,
    },
    [GroupBys.ORDER_CURRENCY]: {
      private: true,
    },
    [GroupBys.REGION]: {
      private: true,
    },
    [GroupBys.L1_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L1Category',
    },
    [GroupBys.L2_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L2Category',
    },
    [GroupBys.L3_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L3Category',
    },
    [GroupBys.L4_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L4Category',
    },
    [GroupBys.L5_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L5Category',
    },
    [GroupBys.L6_CATEGORY]: {
      private: true,
      label: 'insightsPortalDomain.filters.productCategories.L6Category',
    },
    [GroupBys.PRODUCT_CATEGORY_ID]: {
      private: true,
    },
    [GroupBys.RETAILER_PRODUCT]: {
      private: true,
      label: 'insightsPortalDomain.collectionProducts.groupBys.productName',
    },
    [GroupBys.RETAILER_PRODUCT_ID]: {
      private: true,
    },
    [GroupBys.CATALOG_PRODUCT_ID]: {
      private: true,
    },
    [GroupBys.PRODUCT_UPC_CODE]: {
      private: true,
      label: 'insightsPortalDomain.searchDashboard.missingImage.header.productUpcCode',
    },
    [GroupBys.RX_ORDER]: {
      private: true,
    },
  },

  filterConfigs: orderItemsSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    orderItemsSchemaFilterParamsConfig
  ),
})
