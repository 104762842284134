import { dashColors } from '../../../dash-blocks/foundation/colors'

// Note that this has only been tested with Buttons
// See: https://instacart.github.io/Snacks/#themes

export const dangerTheme = {
  colors: {
    action: dashColors.DANGER,
    actionHover: dashColors.DARK_DANGER,
    primaryBackground: dashColors.WHITE,
    primaryForeground: dashColors.DANGER,
    secondaryBackground: dashColors.DANGER,
    secondaryForeground: dashColors.WHITE,
    secondaryForegroundFocus: dashColors.DARK_DANGER,
  },
}
