const EN_US = {
  'instacartDeveloperPlatformDomain.title': 'Instacart Developer Platform',
  'instacartDeveloperPlatformDomain.getStartedPage.title': 'Get started',
  'instacartDeveloperPlatformDomain.getStartedPage.welcome.title':
    'Welcome to Instacart Developer Platform, {givenName}!',
  'instacartDeveloperPlatformDomain.getStartedPage.welcome.subtitle':
    'What are you building today?',
  'instacartDeveloperPlatformDomain.getStartedPage.apiKeys.title': 'API keys',
  'instacartDeveloperPlatformDomain.getStartedPage.apiKeys.description':
    'Generate API keys for development and production environments.',
  'instacartDeveloperPlatformDomain.getStartedPage.docs.title': 'Developer docs',
  'instacartDeveloperPlatformDomain.getStartedPage.docs.description':
    'Access our developer documentation for tutorials, API references, best practices, and more.',
  'instacartDeveloperPlatformDomain.getStartedPage.affiliate.title': 'Apply for affiliate payments',
  'instacartDeveloperPlatformDomain.getStartedPage.affiliate.description':
    'Apply for an account to track conversions and, if eligible, to receive affiliate payments for each order you generate.',
  'instacartDeveloperPlatformDomain.getStartedPage.checklist.title': 'Pre-launch checklist',
  'instacartDeveloperPlatformDomain.getStartedPage.checklist.description':
    'Use our pre-launch checklist to ensure your app complies with the guidelines before submitting it for production approval.',
  'instacartDeveloperPlatformDomain.getStartedPage.team.title': 'Your team',
  'instacartDeveloperPlatformDomain.getStartedPage.team.description':
    'Invite other members of your team to collaborate on building the integration.',
  'instacartDeveloperPlatformDomain.getStartedPage.support.title': 'Technical support',
  'instacartDeveloperPlatformDomain.getStartedPage.support.description':
    'Reach out for assistance during development, to report a bug, or to submit questions or feedback.',
} as const

export default EN_US
