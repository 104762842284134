import {
  type RetailerScopedRouteParams,
  type PartnerScopedRouteParams,
} from '../../legacy/common/types'
import { LEGACY_ROUTE_URLS } from '../../legacy/common/routes'

export type DashRouteType<TIdentifier extends string, TParams extends {} = {}> = {
  identifier: TIdentifier
  params: TParams
}

/* --------------------------------------------- */
/* --------------------------------------------- */
/*        FREE-FORM ROUTING URLS                 */
/* --------------------------------------------- */
/* --------------------------------------------- */

export const URL_BASE = '/'

/* ------------------------- */
/*      PUBLIC ROUTES        */
/* ------------------------- */

// PUBLIC ROUTES
export const URL_LOGOUT = '/logout'
export const URL_STRIPE_CONNECT_CALLBACK = '/stripe'
export const URL_SCOPE_SELECTOR = '/scope'

/* ------------------------- */
/*   PARTNER SCOPED ROUTES   */
/* ------------------------- */

export const URL_PARTNER_BASE = '/partners'
export const URL_PARTNER_SCOPED = `${URL_PARTNER_BASE}/:partner_id`
export const URL_PARTNER_HALT_TERMS = `${URL_PARTNER_SCOPED}/terms-conditions`

/* ------------------------- */
/*  RETAILER SCOPED ROUTES   */
/* ------------------------- */

export const URL_RETAILER_SCOPED = `${URL_PARTNER_SCOPED}/warehouses/:warehouse_id`
export const URL_DASHBOARD = `${URL_RETAILER_SCOPED}/dashboard`
export const URL_SETTINGS = `${URL_RETAILER_SCOPED}/settings`
export const URL_GENERATED_REPORTS = `${URL_RETAILER_SCOPED}/generated-reports`
export const URL_STORE_GROUPS = `${URL_RETAILER_SCOPED}/store-groups`
const URL_ORDERS = `${URL_RETAILER_SCOPED}/orders`
const URL_RETAILER_FILES = `${URL_RETAILER_SCOPED}/retailer-files`

/* --------------------- */
/*  SITE SCOPED ROUTES   */
/* --------------------- */

export const URL_SITE_SCOPED = `/sites/:storeConfigId`

// Notifications Routers
const URL_BANNER_NOTIFICATIONS_LIST = `${URL_RETAILER_SCOPED}/notifications/list`
const URL_PLATFORM_NOTIFICATIONS_LIST = `${URL_RETAILER_SCOPED}/notifications/platform/list`

// (New) Reports Routes
const URL_REPORTS_NORMALIZED_REPORTS_LIST = `${URL_RETAILER_SCOPED}/reports/normalized-reports`
const URL_INSTACART_MANAGED_REPORTS_LIST = `${URL_RETAILER_SCOPED}/reports/instacart-managed-reports`
const URL_TRANSACTION_LOGS = `${URL_RETAILER_SCOPED}/reports/transaction-logs`

/* ------------------------------- */
/*       PUBLIC / APP ROUTES       */
/* ------------------------------- */

// Public Routes
const URL_LOG_OUT = `/log-out`
export const URL_PUBLIC = `/public`

// App Routes (Protected Routes, Non-Public)
export const URL_APP = `/app`

const URL_APP_HALT = `${URL_APP}/halt`
const URL_APP_HALT_REDIRECT = `${URL_APP_HALT}/redirect`
const URL_APP_HALT_ACCOUNT_ACTION_REQUIRED = `${URL_APP_HALT}/account-action-required`
const URL_APP_HALT_ACCOUNT_LIMITED_ACCESS = `${URL_APP_HALT}/account-limited-access`
const URL_APP_HALT_AUTH_SERVICE_ACCOUNT_LIMITED_ACCESS = `${URL_APP_HALT}/auth-service-account-limited-access`

export const URL_APP_ADMIN = `${URL_APP}/admin`

// Landing Page System Routes
export const URL_APP_ADMIN_LANDING_PAGE_SYSTEM = `${URL_APP_ADMIN}/lps`

/* --------------------------------------------- */
/* --------------------------------------------- */
/*            REGISTER ROUTES                    */
/* --------------------------------------------- */
/* --------------------------------------------- */

export type DashRouteSet =
  | DashRouteType<'base'>
  | DashRouteType<'logout'>
  | DashRouteType<'stripe-connect-callback'>
  | DashRouteType<'scope'>
  | DashRouteType<'partner-scoped', PartnerScopedRouteParams>
  | DashRouteType<'retailer-scoped', RetailerScopedRouteParams>
  | DashRouteType<'orders', RetailerScopedRouteParams>
  | DashRouteType<'settings', RetailerScopedRouteParams>
  | DashRouteType<'generated-reports', RetailerScopedRouteParams>
  | DashRouteType<'store-groups', RetailerScopedRouteParams>
  | DashRouteType<'retailer-files', RetailerScopedRouteParams>
  | DashRouteType<'log-out'>
  | DashRouteType<'public'>
  | DashRouteType<'app'>
  | DashRouteType<'app-halt'>
  | DashRouteType<'app-halt-redirect'>
  | DashRouteType<'app-halt-account-limited-access'>
  | DashRouteType<'app-halt-auth-service-account-limited-access'>
  | DashRouteType<'app-halt-account-action-required'>
  | DashRouteType<'app-admin'>
  | DashRouteType<'banner-notifications-list'>
  | DashRouteType<'platform-notifications-list'>
  | DashRouteType<'reports-normalized-reports-list'>
  | DashRouteType<'instacart-managed-reports-list'>
  | DashRouteType<'transaction-logs-list'>
  | DashRouteType<'partner-halt-terms', PartnerScopedRouteParams>
  | DashRouteType<'dashboard', RetailerScopedRouteParams>

export type DashRouteName = DashRouteSet['identifier']

/* --------------------------------------------- */
/* --------------------------------------------- */
/*         WIRE UP ROUTES TO URLS                */
/* --------------------------------------------- */
/* --------------------------------------------- */

export const DASH_ROUTE_URLS: { [Key in DashRouteSet['identifier']]: string } = {
  base: URL_BASE,
  logout: URL_LOGOUT,
  'stripe-connect-callback': URL_STRIPE_CONNECT_CALLBACK,
  scope: URL_SCOPE_SELECTOR,
  'partner-scoped': URL_PARTNER_SCOPED,
  'retailer-scoped': URL_RETAILER_SCOPED,
  orders: URL_ORDERS,
  settings: URL_SETTINGS,
  'generated-reports': URL_GENERATED_REPORTS,
  'store-groups': URL_STORE_GROUPS,
  'retailer-files': URL_RETAILER_FILES,
  'log-out': URL_LOG_OUT,
  public: URL_PUBLIC,
  app: URL_APP,
  'app-halt': URL_APP_HALT,
  'app-halt-redirect': URL_APP_HALT_REDIRECT,
  'partner-halt-terms': URL_PARTNER_HALT_TERMS,
  'app-halt-account-limited-access': URL_APP_HALT_ACCOUNT_LIMITED_ACCESS,
  'app-halt-auth-service-account-limited-access': URL_APP_HALT_AUTH_SERVICE_ACCOUNT_LIMITED_ACCESS,
  'app-halt-account-action-required': URL_APP_HALT_ACCOUNT_ACTION_REQUIRED,
  'app-admin': URL_APP_ADMIN,
  'banner-notifications-list': URL_BANNER_NOTIFICATIONS_LIST,
  'platform-notifications-list': URL_PLATFORM_NOTIFICATIONS_LIST,
  'reports-normalized-reports-list': URL_REPORTS_NORMALIZED_REPORTS_LIST,
  'instacart-managed-reports-list': URL_INSTACART_MANAGED_REPORTS_LIST,
  'transaction-logs-list': URL_TRANSACTION_LOGS,
  dashboard: URL_DASHBOARD,
}

export const ROUTES_BY_NAME = { ...DASH_ROUTE_URLS, ...LEGACY_ROUTE_URLS }
export type RouteName = keyof typeof ROUTES_BY_NAME

const ROUTE_NAMES = Object.keys(ROUTES_BY_NAME) as RouteName[]
export const ROUTES_BY_PATH = ROUTE_NAMES.reduce<{ [key: string]: RouteName }>((acc, key) => {
  acc[ROUTES_BY_NAME[key]] = key
  return acc
}, {})
