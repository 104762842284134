// @ts-nocheck legacy file transformed to TS
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const FilterInput = styled.input`
  width: 100%;
  outline: none;
  background: none;
  border: none;
  padding: 10px 12px;
  font-size: 14px;
`

const DropdownFilter = React.forwardRef((props, ref) => (
  <FilterInput
    placeholder={props.placeholder}
    type="text"
    ref={ref}
    value={props.value}
    onChange={props.onChange}
  />
))

DropdownFilter.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default DropdownFilter
