export const EN_US = {
  'customersDomain.search.pageTitle': 'Search customers',
  'customersDomain.search.fullName': 'Full name',
  'customersDomain.search.emailAddress': 'Email Address',
  'customersDomain.search.phoneNumber': 'Phone number',
  'customersDomain.search.membershipNumber': 'Membership number',
  'customersDomain.search.placeholder': 'Search email...',
  'customersDomain.search.noResultsMessage': 'No customers found',
  'customersDomain.search.newSearchMessage': 'Enter an email address above to find a customer',
  'customersDomain.search.searchingMessage': 'Searching...',
  'customersDomain.search.requestError': 'Hmm, something went wrong',
  'customersDomain.search.bannerSubtitle': 'Find a customer',
  'customersDomain.search.bannerDescription': 'Search by email or phone number',
  'customersDomain.search.email': 'Email',
  'customersDomain.search.emailPlaceholder': 'Search by Email',
  'customersDomain.search.emailNewSearch': 'Enter an email address above to find a customer',
  'customersDomain.search.phone': 'Phone',
  'customersDomain.search.phonePlaceholder': 'Search by Phone',
  'customersDomain.search.phoneNewSearch': 'Enter a phone number above to find a customer',
  'customersDomain.search.order': 'Order ID',
  'customersDomain.search.orderPlaceholder': 'Search by Order Number',
  'customersDomain.search.orderNewSearch': 'Enter an order number above to find a customer',
  'customersDomain.search.loyaltyNumber': 'Loyalty #',
  'customersDomain.search.loyaltyNumberPlaceholder': 'Search by Loyalty Number',
  'customersDomain.search.loyaltyNumberNewSearch':
    'Enter a loyalty number above to find a customer',

  'customersDomain.customer.tabs.details': 'Details',
  'customersDomain.customer.tabs.privacyRequests': 'Privacy Requests',

  'customersDomain.customer.notFound': 'Customer not found',
  'customersDomain.customer.actions.createPrivacyRequest': 'Create Privacy Request',
  'customersDomain.customer.actions.goToOrders': 'Go to orders',
  'customersDomain.customer.actions.impersonateUser': 'Log in as customer',
  'customersDomain.customer.actions.impersonateUserError':
    'An error occurred trying to impersonate',

  'customersDomain.privacyRequests.pageTitle': 'Customer privacy requests',
  'customersDomain.privacyRequests.requestDate': 'Request Date',
  'customersDomain.privacyRequests.customerEmail': 'Customer Email',
  'customersDomain.privacyRequests.customerId': 'Customer ID',
  'customersDomain.privacyRequests.requestType': 'Request Type',
  'customersDomain.privacyRequests.status': 'Status',
  'customersDomain.privacyRequests.download': 'Download',
  'customersDomain.privacyRequests.retailerNotes': 'Additional Notes',
  'customersDomain.privacyRequests.allStatuses': 'All Statuses',
  'customersDomain.privacyRequests.allRequestTypes': 'All Request Types',
  'customersDomain.privacyRequests.noResultsMessage': 'No results',
  'customersDomain.privacyRequests.requestError': 'Hmm, something went wrong',

  'customersDomain.requestType.access': 'Data access',
  'customersDomain.requestType.deletion': 'Data deletion',
  'customersDomain.requestType.unspecified': 'Unspecified',

  'customersDomain.workflowStatus.completed': 'Completed',
  'customersDomain.workflowStatus.deactivate': 'Deactivate',
  'customersDomain.workflowStatus.expired': 'Expired',
  'customersDomain.workflowStatus.failed': 'Failed',
  'customersDomain.workflowStatus.getData': 'Get Data',
  'customersDomain.workflowStatus.inProgress': 'In Progress',
  'customersDomain.workflowStatus.readyToDelete': 'Ready To Delete',
  'customersDomain.workflowStatus.unspecified': 'Unspecified',

  'customersDomain.createPrivacyRequest.header': 'Create privacy request',
  'customersDomain.createPrivacyRequest.requestType': 'Request Type',
  'customersDomain.createPrivacyRequest.dataAccess': 'Data access',
  'customersDomain.createPrivacyRequest.dataDeletion': 'Data deletion',
  'customersDomain.createPrivacyRequest.cancel': 'Cancel',
  'customersDomain.createPrivacyRequest.createRequestButton': 'Create Request',
  'customersDomain.createPrivacyRequest.additionalComment': 'Additional comment',
  'customersDomain.createPrivacyRequest.optional': '(optional)',
  'customersDomain.createPrivacyRequest.acknowledge': 'Acknowledge',
  'customersDomain.createPrivacyRequest.acknowledgeLabel':
    'I acknowledge that this is a verified request from the customer.',
  'customersDomain.createPrivacyRequest.customer': 'Customer',
  'customersDomain.createPrivacyRequest.userId': 'User ID',
  'customersDomain.createPrivacyRequest.privacyRequestCreated':
    'Privacy request created successfully',
  'customersDomain.createPrivacyRequest.privacyRequestError':
    'Privacy request creation failed with status: {status}',
  'customersDomain.createPrivacyRequest.privacyRequestGenericError':
    'Privacy request creation failed with network or graph error',
  'customersDomain.createPrivacyRequest.retailerNotes': 'Additional Notes',

  'customersDomain.customerDetails.firstName': 'First name',
  'customersDomain.customerDetails.lastName': 'Last name',
  'customersDomain.customerDetails.emailAddress': 'Email address',
  'customersDomain.customerDetails.phoneNumber': 'Phone number',
  'customersDomain.customerDetails.deliveryAddress': 'Delivery address',
  'customersDomain.customerDetails.lastStoreShopped': 'Last store shopped',
  'customersDomain.customerDetails.loyaltyCard': 'Loyalty card',
  'customersDomain.customerDetails.icPlus': 'IC+ member',
  'customersDomain.customerDetails.icPlus.true': 'Yes',
  'customersDomain.customerDetails.icPlus.false': 'No',
  'customersDomain.customerDetails.notApplicable': 'N/A',
  'customersDomain.customerDetails.editDetails': 'Edit Details',
  'customersDomain.customerPrivacyRequests.empty':
    'No privacy requests associated with this customer',

  'customersDomain.retailerNotes.header': 'Privacy Request Notes',
  'customersDomain.retailerNotes.date': 'Request Date',
  'customersDomain.retailerNotes.notes': 'Additional Notes',
  'customersDomain.retailerNotes.close': 'Close',
} as const

export default EN_US
