// Should match the list of error codes that the enterprise-dashboard-api returns
export const errorCodes = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  STANDARD_ERROR: 'STANDARD_ERROR',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  INVALID_CAPTCHA: 'INVALID_CAPTCHA',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  NOT_FOUND: 'NOT_FOUND',
  SECURITY: 'SECURITY',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  DISABLED_ACCOUNT: 'DISABLED_ACCOUNT',
  UNDETERMINED_ACCOUNT: 'UNDETERMINED_ACCOUNT',
  INVALID_ACTION: 'INVALID_ACTION',
  ACCOUNT_ALREADY_SETUP: 'ACCOUNT_ALREADY_SETUP',
} as const

export type ErrorCodes = ValueOf<typeof errorCodes>
