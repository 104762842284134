import { type FunctionComponent, useEffect } from 'react'
import { logout } from './utils/logout'

export const LogOut: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  // logout will trigger an apollo `resetClient` and page refresh,
  // so we don't need to return anything from this component
  useEffect(() => {
    logout()
  }, [])

  return null
}
