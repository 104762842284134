import { CustomError } from 'ts-custom-error'

class ApiResponseError extends CustomError {
  status: number

  errors?: JSONArray | undefined

  constructor(message: string, status: number, errors?: JSONArray | undefined) {
    super(message)

    this.status = status
    this.errors = errors
  }
}

export default ApiResponseError
