import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { EligibilityOptions, SubscriptionOptions } from '../notifications-list/NotificationsList'
import { useDomainMessage } from '../../utils/domain/intl'
import { NotificationsFilter } from './NotificationFilter'

interface NotificationFilterHeaderType {
  subscribedFilter?: SubscriptionOptions
  eligibleFilter?: EligibilityOptions
  setSubscribedFilter: (value?: SubscriptionOptions) => void
  setEligibleFilter: (value?: EligibilityOptions) => void
}

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
})

const NotificationFilterHeader: FunctionComponent<
  React.PropsWithChildren<NotificationFilterHeaderType>
> = ({ eligibleFilter, setEligibleFilter, subscribedFilter, setSubscribedFilter }) => {
  const eligibilityOptions = [
    {
      value: EligibilityOptions.All,
      label: useDomainMessage('notificationsDomain.list.filter.allEligible'),
    },
    {
      value: EligibilityOptions.Eligible,
      label: useDomainMessage('notificationsDomain.list.filter.eligible'),
    },
    {
      value: EligibilityOptions.Ineligible,
      label: useDomainMessage('notificationsDomain.list.filter.ineligible'),
    },
  ]
  const subscribeOptions = [
    {
      value: SubscriptionOptions.All,
      label: useDomainMessage('notificationsDomain.list.filter.allSubscriptions'),
    },
    {
      value: SubscriptionOptions.Subscribed,
      label: useDomainMessage('notificationsDomain.list.filter.subscribed'),
    },
    {
      value: SubscriptionOptions.Unsubscribed,
      label: useDomainMessage('notificationsDomain.list.filter.unsubscribed'),
    },
  ]

  return (
    <HeaderContainer data-testid="notifications-filter-header">
      <NotificationsFilter
        data-testid="notifications-filter-eligibility"
        placeholder={useDomainMessage('notificationsDomain.list.filter.eligiblePlaceholder')}
        options={eligibilityOptions}
        selectedValue={eligibleFilter}
        onChange={setEligibleFilter}
      />
      <NotificationsFilter
        data-testid="notifications-filter-subscription"
        placeholder={useDomainMessage('notificationsDomain.list.filter.subscribedPlaceholder')}
        options={subscribeOptions}
        selectedValue={subscribedFilter}
        onChange={setSubscribedFilter}
      />
    </HeaderContainer>
  )
}

export default NotificationFilterHeader
