import React, { useMemo, type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { type useGetAllPartnersForUserPaginatedQuery } from '../../../__codegen__/api'
import { EXCLUDED_PARTNER_ID_LIST } from '../../../legacy/components/SelectPartnerWarehouse/PartnerList'
import { useDashMessages } from '../../../intl/intl.hooks'
import { PartnerOption } from './PartnerOption'

interface Props {
  setPartnerId: (id: string) => void
  partnerData: ReturnType<typeof useGetAllPartnersForUserPaginatedQuery>['data']
  partnerDataError: ReturnType<typeof useGetAllPartnersForUserPaginatedQuery>['error']
  debouncedSearchFilter: string
}

const Header = styled.div({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
})

export const PartnerList: FunctionComponent<React.PropsWithChildren<Props>> = ({
  setPartnerId,
  partnerData,
  partnerDataError,
  debouncedSearchFilter,
}) => {
  const messages = useDashMessages({
    all: 'selectPartner.partnersList.all',
    resultsFor: 'selectPartner.partnersList.resultsFor',
  })

  const partnerListDescription = useMemo(
    () =>
      debouncedSearchFilter
        ? `${partnerData?.partnerRetailerSearch?.nodes?.length} ${messages.resultsFor} "${debouncedSearchFilter}"`
        : messages.all,
    [
      debouncedSearchFilter,
      messages.all,
      messages.resultsFor,
      partnerData?.partnerRetailerSearch?.nodes?.length,
    ]
  )

  const partners = partnerData?.partnerRetailerSearch?.nodes

  const partnersChunk = useMemo(() => {
    if (partnerDataError) {
      return [
        <div key="0" css={{ textAlign: 'center' }}>
          Error Loading Partners
        </div>,
      ]
    }

    if (!partners) {
      return []
    }

    return partners
      .filter(
        partner =>
          !EXCLUDED_PARTNER_ID_LIST.some(excludedId => excludedId === +partner.id) &&
          !partner.name.toLocaleLowerCase().includes('do not use')
      )
      .map(partner => (
        <div
          key={partner.id}
          onClick={evt => {
            setPartnerId(partner.id.toString())
          }}
        >
          <PartnerOption partner={partner} debouncedSearchFilter={debouncedSearchFilter} />
        </div>
      ))
  }, [partnerDataError, partners, debouncedSearchFilter, setPartnerId])

  return (
    <div>
      <Header>{partnerListDescription}</Header>
      {partnersChunk}
    </div>
  )
}
