import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from "@retailer-platform/dashboard/apollo";
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: string;
  CustomersBigInt: any;
  CustomersDateTime: any;
  DateTime: any;
  GoogleProtobufAnyScalar: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  JSONObject: any;
  LegacyDate: any;
  Time: string;
  True: true;
  Url: any;
  google_protobuf_Empty: any;
  google_protobuf_Empty_Input: any;
  instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext: any;
  instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext_Input: any;
  instacart_ads_targeting_v1_NewToInstacartCondition: any;
  instacart_ads_targeting_v1_NewToInstacartCondition_Input: any;
  instacart_enterprise_boba_core_types_v1_OfferDetails_UncategorizedScenario: any;
  instacart_logistics_v1_ListCreatorsRequest_Input: any;
  instacart_logistics_v1_ListTagsRequest_Input: any;
};

export type AccessControlAuthorizationAuthorizationCheck = {
  __typename?: 'AccessControlAuthorizationAuthorizationCheck';
  /** Determines whether the current account has access to the given rules */
  authorized: Scalars['Boolean'];
  /** An array of reasons why validation failed, if any */
  reasons: Array<RejectionReasonEnum>;
};

export type AccessControlConfiguration = {
  __typename?: 'AccessControlConfiguration';
  /** The flippers associated with the account */
  flippers: AccessControlFlippers;
  /** The policies associated with the account */
  policies: AccessControlPolicies;
  /** The access control details for the retailer */
  retailerDetails: AccessControlRetailerDetails;
};


export type AccessControlConfigurationRetailerDetailsArgs = {
  retailerId: Scalars['ID'];
};

export type AccessControlFlippers = {
  __typename?: 'AccessControlFlippers';
  /** The modernized version of the enterprise dashboard nav */
  modernNavigation: Scalars['Boolean'];
};

export type AccessControlPolicies = {
  __typename?: 'AccessControlPolicies';
  /** Permission to view the admin tools section */
  viewAdminToolsSection: Scalars['Boolean'];
  /** Permission to view the retailer dashboard section */
  viewRetailerDashboardSection: Scalars['Boolean'];
};

export type AccessControlRetailerDetails = {
  __typename?: 'AccessControlRetailerDetails';
  /** The policies associated with the retailer */
  policies: AccessControlRetailerPolicies;
};

export type AccessControlRetailerPolicies = {
  __typename?: 'AccessControlRetailerPolicies';
  /** Permission to modify retailer location groups */
  modifyRetailerLocationGroups: Scalars['Boolean'];
  /** Permission to modify retailer reports */
  modifyRetailerReports: Scalars['Boolean'];
  /** Permission to view retailer location groups */
  viewRetailerLocationGroups: Scalars['Boolean'];
  /** Permission to view retailer reports */
  viewRetailerReports: Scalars['Boolean'];
};

export type AccessToken = {
  __typename?: 'AccessToken';
  /** The account associated with the token */
  enterpriseAccount: Maybe<EnterpriseAccount>;
  /** A unique token for authentication purposes */
  token: Scalars['ID'];
};

export type AccountOrdersEmptyState = {
  __typename?: 'AccountOrdersEmptyState';
  enabled: Maybe<Scalars['Boolean']>;
  imageUrl: Maybe<Scalars['String']>;
};

export type AccountRequestAccountRequest = {
  __typename?: 'AccountRequestAccountRequest';
  /** Canonical account ID */
  accountCanonicalId: Maybe<Scalars['String']>;
  /** Time this request was created */
  createdAt: Scalars['Time'];
  /** Country associated with this retailer */
  email: Scalars['String'];
  /** Country associated with this retailer */
  fullName: Scalars['String'];
  /** A unique identifier for the retailer */
  id: Scalars['ID'];
  /** justification from retailer employee for account creation */
  requestReason: Scalars['String'];
  /** Current state of the request */
  requestState: RequestStateEnum;
  /** The name of the retailer */
  retailerId: Scalars['ID'];
  /** Requested role IDs */
  roles: Array<EnterpriseRole>;
};

/** Autogenerated return type of AccountRequestCreate */
export type AccountRequestCreatePayload = {
  __typename?: 'AccountRequestCreatePayload';
  /** Account request received successfully */
  success: Scalars['Boolean'];
};

export type AccountRequestPreviousRequestExists = {
  __typename?: 'AccountRequestPreviousRequestExists';
  /** Does a previous account request exist */
  requestExists: Scalars['Boolean'];
};

export enum AccountStatusEnum {
  /** Active status */
  Active = 'ACTIVE',
  /** Disabled status */
  Disabled = 'DISABLED',
  /** Pending status */
  Pending = 'PENDING'
}

export type AddProductsToRetailerCollectionRequestInput = {
  inputCodeType?: InputMaybe<RetailerCollectionProductCodeType>;
  products?: InputMaybe<Array<InputMaybe<AddProductsToRetailerCollectionRequestProductToAddInput>>>;
  retailerCollectionId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type AddProductsToRetailerCollectionRequestProductToAddInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  lookupCode?: InputMaybe<Scalars['String']>;
  rrc?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type AddProductsToRetailerCollectionResponse = {
  __typename?: 'AddProductsToRetailerCollectionResponse';
  productIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type AddRetailerCampaignTreatmentsRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  treatments?: InputMaybe<Array<InputMaybe<CreateRetailerCampaignTreatmentParamsInput>>>;
};

export type AddRetailerCampaignTreatmentsResponse = {
  __typename?: 'AddRetailerCampaignTreatmentsResponse';
  treatments: Maybe<Array<Maybe<RetailerCampaignTreatment>>>;
};

export type Address = {
  __typename?: 'Address';
  apartmentNumber: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
};

export type AddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

export type AdminRequestContextInput = {
  retailerAppId?: InputMaybe<Scalars['BigInt']>;
};

export type AdsSchema = {
  __typename?: 'AdsSchema';
  allowAlcoholSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowBiaPageSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowBrowseSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowCheckoutSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowItemDetailsSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowSearchGridSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowSearchOtherSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowSponsoredProducts: Maybe<Scalars['Boolean']>;
  allowStorefrontSponsoredProducts: Maybe<Scalars['Boolean']>;
};

export type AdvanceBatchStateRequestInput = {
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  batchId?: InputMaybe<Scalars['BigInt']>;
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  workflowState?: InputMaybe<Scalars['String']>;
};

export type AdvanceBatchStateResponse = {
  __typename?: 'AdvanceBatchStateResponse';
  id: Maybe<Scalars['BigInt']>;
  workflowState: Maybe<Scalars['String']>;
};

export type AisleOrder = {
  __typename?: 'AisleOrder';
  inventoryAreaAisleId: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  rank: Maybe<Scalars['BigInt']>;
};

export type AisleOrderInput = {
  inventoryAreaAisleId?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['BigInt']>;
};

export type Analytics = {
  __typename?: 'Analytics';
  cadence: Maybe<Cadence>;
  dateRange: Maybe<InstacartAdsSharedV1DateRange>;
  entity: Maybe<Entity>;
  metric: Maybe<Metric>;
  valueToDate: Maybe<Array<Maybe<AnalyticsUnit>>>;
  valueToDateV2: Maybe<Array<Maybe<AnalyticsUnitV2>>>;
};

export type AnalyticsUnit = {
  __typename?: 'AnalyticsUnit';
  date: Maybe<Scalars['DateTime']>;
  value: Maybe<Scalars['Float']>;
};

export type AnalyticsUnitV2 = {
  __typename?: 'AnalyticsUnitV2';
  date: Maybe<GoogleTypeDate>;
  value: Maybe<Scalars['Float']>;
};

export type AnomalousItemDateCount = {
  __typename?: 'AnomalousItemDateCount';
  count: Maybe<Scalars['BigInt']>;
  date: Maybe<Scalars['DateTime']>;
};

export type ApiIntegrationStoreLocation = {
  __typename?: 'ApiIntegrationStoreLocation';
  active: Maybe<Scalars['Boolean']>;
  currentlySynced: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export enum AreaOfInterest {
  AreaOfInterestUnknown = 'AREA_OF_INTEREST_UNKNOWN',
  Claims = 'CLAIMS',
  DistributionVolume = 'DISTRIBUTION_VOLUME',
  ItemImage = 'ITEM_IMAGE',
  ProductSales = 'PRODUCT_SALES',
  UnitLevelPricing = 'UNIT_LEVEL_PRICING'
}

export enum AssetType {
  Favicon = 'FAVICON',
  Font = 'FONT',
  LandingPageBackgroundImage = 'LANDING_PAGE_BACKGROUND_IMAGE',
  Logo = 'LOGO',
  MobileBackgroundImage = 'MOBILE_BACKGROUND_IMAGE',
  MobileLogo = 'MOBILE_LOGO',
  Unknown = 'UNKNOWN'
}

/** Autogenerated return type of AssignPermissionToRole */
export type AssignPermissionToRolePayload = {
  __typename?: 'AssignPermissionToRolePayload';
  /** The role with the new permissions */
  role: EnterpriseRole;
};

export type AssignVirtualBatchRequestInput = {
  batchId?: InputMaybe<Scalars['BigInt']>;
  batchType?: InputMaybe<BatchType>;
  driverId?: InputMaybe<Scalars['BigInt']>;
  order?: InputMaybe<OrderInput>;
  shopperId?: InputMaybe<Scalars['BigInt']>;
};

export type AssignVirtualBatchResponse = {
  __typename?: 'AssignVirtualBatchResponse';
  id: Maybe<Scalars['BigInt']>;
  workflowState: Maybe<Scalars['String']>;
};

export enum AtomsColor {
  AtomsColorUnknown = 'ATOMS_COLOR_UNKNOWN',
  BrandHighlightDark = 'BRAND_HIGHLIGHT_DARK',
  BrandHighlightLight = 'BRAND_HIGHLIGHT_LIGHT',
  BrandHighlightRegular = 'BRAND_HIGHLIGHT_REGULAR',
  BrandLoyaltyDark = 'BRAND_LOYALTY_DARK',
  BrandLoyaltyLight = 'BRAND_LOYALTY_LIGHT',
  BrandLoyaltyRegular = 'BRAND_LOYALTY_REGULAR',
  BrandPrimaryDark = 'BRAND_PRIMARY_DARK',
  BrandPrimaryExtraDark = 'BRAND_PRIMARY_EXTRA_DARK',
  BrandPrimaryRegular = 'BRAND_PRIMARY_REGULAR',
  BrandPromotionalDark = 'BRAND_PROMOTIONAL_DARK',
  BrandPromotionalLight = 'BRAND_PROMOTIONAL_LIGHT',
  BrandPromotionalRegular = 'BRAND_PROMOTIONAL_REGULAR',
  BrandSecondaryDark = 'BRAND_SECONDARY_DARK',
  BrandSecondaryLight = 'BRAND_SECONDARY_LIGHT',
  BrandSecondaryRegular = 'BRAND_SECONDARY_REGULAR',
  BrandTertiaryRegular = 'BRAND_TERTIARY_REGULAR',
  MaxYellow = 'MAX_YELLOW',
  PlusDark = 'PLUS_DARK',
  PlusExtraDark = 'PLUS_EXTRA_DARK',
  PlusExtraLight = 'PLUS_EXTRA_LIGHT',
  PlusLight = 'PLUS_LIGHT',
  PlusRegular = 'PLUS_REGULAR',
  SystemDetrimentalDark = 'SYSTEM_DETRIMENTAL_DARK',
  SystemDetrimentalExtraDark = 'SYSTEM_DETRIMENTAL_EXTRA_DARK',
  SystemDetrimentalLight = 'SYSTEM_DETRIMENTAL_LIGHT',
  SystemDetrimentalRegular = 'SYSTEM_DETRIMENTAL_REGULAR',
  SystemGrayscale00 = 'SYSTEM_GRAYSCALE00',
  SystemGrayscale10 = 'SYSTEM_GRAYSCALE10',
  SystemGrayscale20 = 'SYSTEM_GRAYSCALE20',
  SystemGrayscale30 = 'SYSTEM_GRAYSCALE30',
  SystemGrayscale50 = 'SYSTEM_GRAYSCALE50',
  SystemGrayscale70 = 'SYSTEM_GRAYSCALE70',
  SystemSuccessDark = 'SYSTEM_SUCCESS_DARK',
  SystemSuccessLight = 'SYSTEM_SUCCESS_LIGHT',
  SystemSuccessRegular = 'SYSTEM_SUCCESS_REGULAR'
}

export type AttributeData = {
  __typename?: 'AttributeData';
  attributeName: Maybe<Scalars['String']>;
  attributeValue: Maybe<Scalars['String']>;
  productSourceId: Maybe<Scalars['BigInt']>;
  sourceId: Maybe<Scalars['BigInt']>;
  sourceType: Maybe<Scalars['String']>;
};

export type AuditBatch = {
  __typename?: 'AuditBatch';
  allowSuggestedValues: Maybe<Scalars['Boolean']>;
  auditableTaskCount: Maybe<Scalars['BigInt']>;
  auditedTaskCount: Maybe<Scalars['BigInt']>;
  batchId: Maybe<Scalars['BigInt']>;
  batchMetadata: Maybe<Scalars['String']>;
  batchState: Maybe<Scalars['String']>;
  batchUuid: Maybe<Scalars['String']>;
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  isExplicitFailedRedrive: Maybe<Scalars['Boolean']>;
  recommendedSampleSize: Maybe<Scalars['BigInt']>;
  sampleTaskCount: Maybe<Scalars['BigInt']>;
  sampleTasks: Maybe<Array<Maybe<AuditTask>>>;
  slaDueDate: Maybe<Scalars['DateTime']>;
  version: Maybe<Scalars['BigInt']>;
  workflow: Maybe<Scalars['String']>;
};

export enum AuditLogEntityTypeEnum {
  /** Account entity */
  Account = 'ACCOUNT',
  /** Aisle entity */
  Aisle = 'AISLE',
  /** Collection entity */
  Collection = 'COLLECTION',
  /** ContentPage entity */
  ContentPage = 'CONTENT_PAGE',
  /** ContentPageVersion entity */
  ContentPageVersion = 'CONTENT_PAGE_VERSION',
  /** ContentPageVersionMeta entity */
  ContentPageVersionMeta = 'CONTENT_PAGE_VERSION_META',
  /** Department entity */
  Department = 'DEPARTMENT',
  /** DepartmentNavigation entity */
  DepartmentNavigation = 'DEPARTMENT_NAVIGATION',
  /** Item entity */
  Item = 'ITEM',
  /** Placement entity */
  Placement = 'PLACEMENT',
  /** Product entity */
  Product = 'PRODUCT',
  /** RetailerCampaign entity */
  RetailerCampaign = 'RETAILER_CAMPAIGN',
  /** Role entity */
  Role = 'ROLE'
}

export type AuditLogsAuditLog = {
  __typename?: 'AuditLogsAuditLog';
  /** The email of the account that made the change */
  accountEmail: Scalars['String'];
  /** The UUID of the account that made the change */
  accountUuid: Scalars['ID'];
  /** The entities related to this change */
  entities: Maybe<Array<AuditLogsAuditLogEntity>>;
  /** Event name */
  eventName: Maybe<Scalars['String']>;
  /** Time that the audit log event took place */
  eventTime: Scalars['ISO8601DateTime'];
  /** The internal unique identifier for the audit log */
  id: Scalars['ID'];
  /** Whether or not the change was successful */
  success: Maybe<Scalars['Boolean']>;
};

/** The connection type for AuditLogsAuditLog. */
export type AuditLogsAuditLogConnection = {
  __typename?: 'AuditLogsAuditLogConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AuditLogsAuditLogEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AuditLogsAuditLog>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AuditLogsAuditLogEdge = {
  __typename?: 'AuditLogsAuditLogEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<AuditLogsAuditLog>;
};

export type AuditLogsAuditLogEntity = {
  __typename?: 'AuditLogsAuditLogEntity';
  /** The entity display name */
  entityDisplayName: Maybe<Scalars['String']>;
  /** The identifier of the entity */
  entityIds: Array<Scalars['ID']>;
  /** The entity type */
  entityType: AuditLogEntityTypeEnum;
  /** The internal unique identifier for the audit log entity */
  id: Scalars['ID'];
};

export type AuditTask = {
  __typename?: 'AuditTask';
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  entityId: Maybe<EntityId>;
  extComment: Maybe<Scalars['String']>;
  inputData: Maybe<Scalars['String']>;
  intComment: Maybe<Scalars['String']>;
  taskComponents: Maybe<Array<Maybe<AuditTaskTaskComponent>>>;
  taskState: Maybe<Scalars['String']>;
  taskUuid: Maybe<Scalars['String']>;
  version: Maybe<Scalars['BigInt']>;
  workflow: Maybe<Scalars['String']>;
};

export type AuditTaskTaskComponent = {
  __typename?: 'AuditTaskTaskComponent';
  explicitAuditFailed: Maybe<Scalars['Boolean']>;
  extComment: Maybe<Scalars['String']>;
  intComment: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  suggestedValue: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
  valueType: Maybe<Scalars['String']>;
};

export type AuditWorkflow = {
  __typename?: 'AuditWorkflow';
  approvedTaskCount: Maybe<Scalars['BigInt']>;
  auditableTaskCount: Maybe<Scalars['BigInt']>;
  defaultSamplePct: Maybe<Scalars['Float']>;
  defaultSlaDays: Maybe<Scalars['BigInt']>;
  failOverPct: Maybe<Scalars['Float']>;
  firstPendingSlaDueDate: Maybe<Scalars['DateTime']>;
  name: Maybe<Scalars['String']>;
  rejectedTaskCount: Maybe<Scalars['BigInt']>;
};

export type Authentication = {
  __typename?: 'Authentication';
  postAuthPaths: Maybe<PostAuthPaths>;
};

export type AuthenticationInput = {
  postAuthPaths?: InputMaybe<PostAuthPathsInput>;
};

/** The method of authentication associated with an account */
export type AuthenticationInterface = {
  /** Unique authentication identifier */
  authenticationIdentifier: Scalars['ID'];
};

export type AuthorizationContextInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Banner = {
  __typename?: 'Banner';
  action: Maybe<Scalars['String']>;
  actionData: Maybe<Scalars['String']>;
  backgroundColorHex: Maybe<Scalars['String']>;
  buttonStyle: Maybe<ButtonStyle>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaColorHex: Maybe<Scalars['String']>;
  dataQuery: Maybe<DataQuery>;
  id: Maybe<Scalars['BigInt']>;
  imageAltText: Maybe<Scalars['String']>;
  imageMobileUrl: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  locale: Maybe<InstacartCustomersSharedV1Locale>;
  status: Maybe<Status>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  tagline: Maybe<Scalars['String']>;
  taglineColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type BannerAction = {
  __typename?: 'BannerAction';
  dismissBusinessIdentication: Maybe<DismissBusinessIdentication>;
  doNotNavigate: Maybe<DoNotNavigate>;
  navigateToAskInstacart: Maybe<NavigateToAskInstacart>;
  navigateToAuthentication: Maybe<NavigateToAuthentication>;
  navigateToBenefitsModal: Maybe<NavigateToBenefitsModal>;
  navigateToCategorySurface: Maybe<NavigateToCategorySurface>;
  navigateToCollaborativeShopInfoModal: Maybe<NavigateToCollaborativeShopInfoModal>;
  navigateToCollection: Maybe<NavigateToCollection>;
  navigateToCollectionHub: Maybe<NavigateToCollectionHub>;
  navigateToContainerPathViaStoreselector: Maybe<NavigateToContainerPathViaStoreSelector>;
  navigateToContentPage: Maybe<NavigateToContentPage>;
  navigateToCouponRedemption: Maybe<NavigateToCouponRedemption>;
  navigateToDepartment: Maybe<NavigateToDepartment>;
  navigateToExpressAccountPage: Maybe<NavigateToExpressAccountPage>;
  navigateToHouseholdAccountSharingFlow: Maybe<NavigateToHouseholdAccountSharingFlow>;
  navigateToPickupStoreselector: Maybe<NavigateToPickupStoreSelector>;
  navigateToProduct: Maybe<NavigateToProduct>;
  navigateToPromoModal: Maybe<NavigateToPromoModal>;
  navigateToPromotionDetail: Maybe<NavigateToPromotionDetail>;
  navigateToRetailerCollection: Maybe<NavigateToRetailerCollection>;
  navigateToRetailerCollectionViaStoreselector: Maybe<NavigateToRetailerCollectionViaStoreSelector>;
  navigateToSsaStoreselector: Maybe<NavigateToSsaStoreSelector>;
  navigateToStorefront: Maybe<NavigateToStorefront>;
  navigateToStoreselector: Maybe<NavigateToStoreSelector>;
  navigateToUrl: Maybe<NavigateToUrl>;
  openExpressPaidModal: Maybe<OpenExpressPaidModal>;
  openExpressTrialModal: Maybe<OpenExpressTrialModal>;
  optInUser: Maybe<OptInUser>;
  renewSubscriptionIntoAnnual: Maybe<RenewSubscriptionIntoAnnual>;
  renewSubscriptionIntoMonthly: Maybe<RenewSubscriptionIntoMonthly>;
};

export type BannerActionInput = {
  dismissBusinessIdentication?: InputMaybe<DismissBusinessIdenticationInput>;
  doNotNavigate?: InputMaybe<DoNotNavigateInput>;
  navigateToAskInstacart?: InputMaybe<NavigateToAskInstacartInput>;
  navigateToAuthentication?: InputMaybe<NavigateToAuthenticationInput>;
  navigateToBenefitsModal?: InputMaybe<NavigateToBenefitsModalInput>;
  navigateToCategorySurface?: InputMaybe<NavigateToCategorySurfaceInput>;
  navigateToCollaborativeShopInfoModal?: InputMaybe<NavigateToCollaborativeShopInfoModalInput>;
  navigateToCollection?: InputMaybe<NavigateToCollectionInput>;
  navigateToCollectionHub?: InputMaybe<NavigateToCollectionHubInput>;
  navigateToContainerPathViaStoreselector?: InputMaybe<NavigateToContainerPathViaStoreSelectorInput>;
  navigateToContentPage?: InputMaybe<NavigateToContentPageInput>;
  navigateToCouponRedemption?: InputMaybe<NavigateToCouponRedemptionInput>;
  navigateToDepartment?: InputMaybe<NavigateToDepartmentInput>;
  navigateToExpressAccountPage?: InputMaybe<NavigateToExpressAccountPageInput>;
  navigateToHouseholdAccountSharingFlow?: InputMaybe<NavigateToHouseholdAccountSharingFlowInput>;
  navigateToPickupStoreselector?: InputMaybe<NavigateToPickupStoreSelectorInput>;
  navigateToProduct?: InputMaybe<NavigateToProductInput>;
  navigateToPromoModal?: InputMaybe<NavigateToPromoModalInput>;
  navigateToPromotionDetail?: InputMaybe<NavigateToPromotionDetailInput>;
  navigateToRetailerCollection?: InputMaybe<NavigateToRetailerCollectionInput>;
  navigateToRetailerCollectionViaStoreselector?: InputMaybe<NavigateToRetailerCollectionViaStoreSelectorInput>;
  navigateToSsaStoreselector?: InputMaybe<NavigateToSsaStoreSelectorInput>;
  navigateToStorefront?: InputMaybe<NavigateToStorefrontInput>;
  navigateToStoreselector?: InputMaybe<NavigateToStoreSelectorInput>;
  navigateToUrl?: InputMaybe<NavigateToUrlInput>;
  openExpressPaidModal?: InputMaybe<OpenExpressPaidModalInput>;
  openExpressTrialModal?: InputMaybe<OpenExpressTrialModalInput>;
  optInUser?: InputMaybe<OptInUserInput>;
  renewSubscriptionIntoAnnual?: InputMaybe<RenewSubscriptionIntoAnnualInput>;
  renewSubscriptionIntoMonthly?: InputMaybe<RenewSubscriptionIntoMonthlyInput>;
};

export type BannerInput = {
  action?: InputMaybe<Scalars['String']>;
  actionData?: InputMaybe<Scalars['String']>;
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  buttonStyle?: InputMaybe<ButtonStyle>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  dataQuery?: InputMaybe<DataQueryInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  imageMobileUrl?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  status?: InputMaybe<Status>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<Scalars['String']>;
  taglineColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export enum BannerLocaleEnum {
  /** Canadian English */
  EnCa = 'EN_CA',
  /** American English */
  EnUs = 'EN_US',
  /** Canadian French */
  FrCa = 'FR_CA'
}

export type BannerType = {
  __typename?: 'BannerType';
  compactBanner: Maybe<CompactBanner>;
  defaultBanner: Maybe<Banner>;
  heroBanner: Maybe<HeroBanner>;
  imageForward: Maybe<ImageForward>;
  imageForwardFlex: Maybe<ImageForwardFlex>;
  infoSheet: Maybe<StandardInfoModalSheet>;
  logoWithTextBanner: Maybe<LogoWithTextBanner>;
  secondaryBanner: Maybe<SecondaryBanner>;
  textWithThumbnail: Maybe<TextWithThumbnail>;
  textWithThumbnailFlex: Maybe<TextWithThumbnailFlex>;
  unknownBanner: Maybe<UnknownBanner>;
};

export type BannerTypeInput = {
  compactBanner?: InputMaybe<CompactBannerInput>;
  defaultBanner?: InputMaybe<BannerInput>;
  heroBanner?: InputMaybe<HeroBannerInput>;
  imageForward?: InputMaybe<ImageForwardInput>;
  imageForwardFlex?: InputMaybe<ImageForwardFlexInput>;
  infoSheet?: InputMaybe<StandardInfoModalSheetInput>;
  logoWithTextBanner?: InputMaybe<LogoWithTextBannerInput>;
  secondaryBanner?: InputMaybe<SecondaryBannerInput>;
  textWithThumbnail?: InputMaybe<TextWithThumbnailInput>;
  textWithThumbnailFlex?: InputMaybe<TextWithThumbnailFlexInput>;
  unknownBanner?: InputMaybe<UnknownBannerInput>;
};

export enum BannerVariant {
  AsyncImage = 'ASYNC_IMAGE',
  AsyncModal = 'ASYNC_MODAL',
  BannerRow = 'BANNER_ROW',
  BottomTracker = 'BOTTOM_TRACKER',
  BusinessIdentificationShopBanner = 'BUSINESS_IDENTIFICATION_SHOP_BANNER',
  CarouselCard = 'CAROUSEL_CARD',
  CartTracker = 'CART_TRACKER',
  CategoryHub = 'CATEGORY_HUB',
  ChurnedUserSurvey = 'CHURNED_USER_SURVEY',
  Coachmark = 'COACHMARK',
  CompactBanner = 'COMPACT_BANNER',
  FeedUnit = 'FEED_UNIT',
  Gamification = 'GAMIFICATION',
  Header = 'HEADER',
  HeroBanner = 'HERO_BANNER',
  Image = 'IMAGE',
  ImageForward = 'IMAGE_FORWARD',
  ImageForwardFlex = 'IMAGE_FORWARD_FLEX',
  InformationModal = 'INFORMATION_MODAL',
  LandingFaq = 'LANDING_FAQ',
  LandingHero = 'LANDING_HERO',
  LandingModule = 'LANDING_MODULE',
  LandingValueProp = 'LANDING_VALUE_PROP',
  Large = 'LARGE',
  LogoWithText = 'LOGO_WITH_TEXT',
  Mega = 'MEGA',
  MultiOfferSheet = 'MULTI_OFFER_SHEET',
  NavigationBanner = 'NAVIGATION_BANNER',
  OccasionHub = 'OCCASION_HUB',
  OffersForYouCard = 'OFFERS_FOR_YOU_CARD',
  OfferCard = 'OFFER_CARD',
  OverHeader = 'OVER_HEADER',
  RetailerStory = 'RETAILER_STORY',
  RichInformationModal = 'RICH_INFORMATION_MODAL',
  RichText = 'RICH_TEXT',
  SecondaryBanner = 'SECONDARY_BANNER',
  StandardInformationModal = 'STANDARD_INFORMATION_MODAL',
  Survey = 'SURVEY',
  Text = 'TEXT',
  TextIconHeader = 'TEXT_ICON_HEADER',
  TextSlim = 'TEXT_SLIM',
  TextWithThumbnail = 'TEXT_WITH_THUMBNAIL',
  TextWithThumbnailFlex = 'TEXT_WITH_THUMBNAIL_FLEX',
  TitledMedia = 'TITLED_MEDIA'
}

export type BatchGetUsersByIdsStrictRequestInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
};

export type BatchGetUsersByIdsStrictResponse = {
  __typename?: 'BatchGetUsersByIdsStrictResponse';
  users: Maybe<Array<Maybe<User>>>;
};

export enum BatchType {
  BatchTypeUnspecified = 'BATCH_TYPE_UNSPECIFIED',
  Delivery = 'DELIVERY',
  DeliveryOnly = 'DELIVERY_ONLY',
  DriveAndPick = 'DRIVE_AND_PICK'
}

export type BatchUpdatePlacementsRequestInput = {
  layout?: InputMaybe<Layout>;
  placements?: InputMaybe<Array<InputMaybe<PlacementInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type BatchUpdateSlotCreativesRequestInput = {
  slotCreatives?: InputMaybe<Array<InputMaybe<SlotCreativeInput>>>;
};

export type BatchUpdateSlotCreativesResponse = {
  __typename?: 'BatchUpdateSlotCreativesResponse';
  slotCreatives: Maybe<Array<Maybe<SlotCreative>>>;
};

export type BorderRadiusSchema = {
  __typename?: 'BorderRadiusSchema';
  isCircle: Maybe<Scalars['Boolean']>;
  radius: Maybe<Scalars['BigInt']>;
};

export enum BotAccountAssignableRoleEnum {
  /** Engineer role */
  Engineer = 'ENGINEER',
  /** Admin role */
  NonEngineer = 'NON_ENGINEER'
}

export enum BotAccountOperationTypeEnum {
  /** Account was created */
  Created = 'CREATED',
  /** Account was updated */
  Updated = 'UPDATED'
}

/** Autogenerated return type of BotEnterpriseAccountUpsert */
export type BotEnterpriseAccountUpsertPayload = {
  __typename?: 'BotEnterpriseAccountUpsertPayload';
  /** The newly created or updated account object */
  enterpriseAccount: EnterpriseAccount;
  /** What kind of operation was executed */
  operationType: BotAccountOperationTypeEnum;
};

export type Brand = {
  __typename?: 'Brand';
  brandId: Maybe<Scalars['BigInt']>;
  brandName: Maybe<Scalars['String']>;
};

export type BrandContact = {
  __typename?: 'BrandContact';
  email: Maybe<Scalars['String']>;
  jobTitle: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
};

export type BrandContactRequestInput = {
  accountInformation?: InputMaybe<IppAccountInformationInput>;
  areasOfInterest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  areasOfInterestEnums?: InputMaybe<Array<InputMaybe<AreaOfInterest>>>;
  brandProfileId?: InputMaybe<Scalars['String']>;
  interestedSellSheetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDebug?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
};

export type BrandContactResponse = {
  __typename?: 'BrandContactResponse';
  brandContactId: Maybe<Scalars['String']>;
};

export type BrandNameMapping = {
  __typename?: 'BrandNameMapping';
  brandName: Maybe<Scalars['String']>;
  brandProfileId: Maybe<Scalars['String']>;
};

export type BrandProfileAttributes = {
  __typename?: 'BrandProfileAttributes';
  aboutBrand: Maybe<Scalars['String']>;
  brandContacts: Maybe<Array<Maybe<BrandContact>>>;
  brandName: Maybe<Scalars['String']>;
  certifications: Maybe<Array<Maybe<Scalars['String']>>>;
  distributors: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Maybe<Scalars['String']>;
  imageAssets: Maybe<Array<Maybe<ImageAsset>>>;
  regionalAreas: Maybe<Array<Maybe<InstacartAdsNexusV1RegionalArea>>>;
  storeTypes: Maybe<Array<Maybe<InstacartAdsNexusV1StoreType>>>;
  website: Maybe<Scalars['String']>;
};

export type BrandProfileAttributesRequestInput = {
  brandProfileId?: InputMaybe<Scalars['String']>;
};

export type BrandProfileAttributesResponse = {
  __typename?: 'BrandProfileAttributesResponse';
  brandProfileAttributes: Maybe<BrandProfileAttributes>;
};

export type BrandProfileSellSheetCardsRequestInput = {
  brandProfileId?: InputMaybe<Scalars['String']>;
  paginationRequest?: InputMaybe<InstacartAdsSharedV1PaginationRequestInput>;
};

export type BrandProfileSellSheetCardsResponse = {
  __typename?: 'BrandProfileSellSheetCardsResponse';
  paginationResponse: Maybe<InstacartAdsSharedV1PaginationResponse>;
  sellSheetCards: Maybe<Array<Maybe<SellSheetCard>>>;
};

export type BrandSellSheetsAttributesRequestInput = {
  brandProfileId?: InputMaybe<Scalars['String']>;
};

export type BrandSellSheetsAttributesResponse = {
  __typename?: 'BrandSellSheetsAttributesResponse';
  sellSheetsOverviewAttributes: Maybe<Array<Maybe<SellSheetOverviewAttributes>>>;
};

export type BrandingSchema = {
  __typename?: 'BrandingSchema';
  customImages: Maybe<CustomImages>;
  favicon: Maybe<Scalars['String']>;
  loadingIcons: Maybe<Array<Maybe<Scalars['String']>>>;
  logos: Maybe<Logos>;
  retailerOverrides: Maybe<Array<Maybe<RetailerOverride>>>;
};

export enum BusinessDerivedAttributesSourceType {
  LangchainAi = 'LANGCHAIN_AI'
}

export enum ButtonStyle {
  Detrimental = 'DETRIMENTAL',
  Plus = 'PLUS',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export enum ButtonVariantEnum {
  /** Use the dismiss button style */
  Dismiss = 'DISMISS',
  /** Use the link button style */
  Link = 'LINK',
  /** Use the plus button style */
  Plus = 'PLUS',
  /** Use the primary button style */
  Primary = 'PRIMARY',
  /** Use the secondary button style */
  Secondary = 'SECONDARY'
}

export enum Cadence {
  Weekly = 'WEEKLY'
}

export type CallbackConfigurationInput = {
  /** The access token TTL */
  accessTokenTtl?: InputMaybe<Scalars['Int']>;
  /** The authentication URI */
  authUri?: InputMaybe<Scalars['String']>;
  /** The possible statuses to enable */
  availableStatuses?: InputMaybe<Array<Scalars['String']>>;
  /** The callback URI */
  callbackUri?: InputMaybe<Scalars['String']>;
  /** The client id */
  credentialsClientId?: InputMaybe<Scalars['String']>;
  /** The client id */
  credentialsClientSecret?: InputMaybe<Scalars['String']>;
  /** The id */
  id: Scalars['ID'];
  /** The enabled statusers */
  statusesOfInterest?: InputMaybe<Array<Scalars['String']>>;
};

export type CampaignFilter = {
  __typename?: 'CampaignFilter';
  filterType: Maybe<FilterType>;
  filterValue: Maybe<Scalars['String']>;
};

export type CampaignFilterInput = {
  filterType?: InputMaybe<FilterType>;
  filterValue?: InputMaybe<Scalars['String']>;
};

export enum CampaignObjective {
  Acquisition = 'ACQUISITION',
  Activation = 'ACTIVATION',
  Awareness = 'AWARENESS',
  Custom = 'CUSTOM',
  Invalid = 'INVALID',
  Loyalty = 'LOYALTY',
  Merchandising = 'MERCHANDISING',
  Moment = 'MOMENT',
  Occasion = 'OCCASION',
  Reactivation = 'REACTIVATION'
}

export enum CampaignProgress {
  /** The campaign has been cancelled */
  Cancelled = 'cancelled',
  /** The campaign has completed successfully */
  Completed = 'completed',
  /** The campaign has failed for some users */
  Failed = 'failed',
  /** The campaign is in progress */
  InProgress = 'in_progress',
  /** The campaign has completed for some users */
  PartiallyCompleted = 'partially_completed',
  /** The campaign is not currently running */
  Pending = 'pending'
}

export type CampaignReference = {
  __typename?: 'CampaignReference';
  utmCampaign: Maybe<Scalars['String']>;
  utmContent: Maybe<Scalars['String']>;
  utmMedium: Maybe<Scalars['String']>;
  utmSource: Maybe<Scalars['String']>;
  utmTerm: Maybe<Scalars['String']>;
};

export type CampaignReferenceInput = {
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CancelCampaign */
export type CancelCampaignPayload = {
  __typename?: 'CancelCampaignPayload';
  /** Whether it succesfully cancelled */
  success: Scalars['Boolean'];
};

export type CancelMomentRequestInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
};

export type CancelMomentResponse = {
  __typename?: 'CancelMomentResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type CancelOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type CancelOrderResponse = {
  __typename?: 'CancelOrderResponse';
  instrumentationData: Maybe<Scalars['String']>;
  order: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
  orderItems: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItem>>>;
};

export type CancelRetailerCampaignRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CancelRetailerCampaignResponse = {
  __typename?: 'CancelRetailerCampaignResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type CardList = {
  __typename?: 'CardList';
  cta: Maybe<Scalars['String']>;
  dataQuery: Maybe<DataQuery>;
  id: Maybe<Scalars['BigInt']>;
  title: Maybe<Scalars['String']>;
  variant: Maybe<CardListVariant>;
};

export type CardListInput = {
  cta?: InputMaybe<Scalars['String']>;
  dataQuery?: InputMaybe<DataQueryInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  title?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<CardListVariant>;
};

export enum CardListVariant {
  AsyncPlacements = 'ASYNC_PLACEMENTS',
  CardList = 'CARD_LIST',
  GridList = 'GRID_LIST',
  ItemBundles = 'ITEM_BUNDLES',
  LandingFaqsList = 'LANDING_FAQS_LIST',
  LandingRetailersList = 'LANDING_RETAILERS_LIST',
  LandingValuePropsList = 'LANDING_VALUE_PROPS_LIST',
  MarketplaceList = 'MARKETPLACE_LIST',
  PillList = 'PILL_LIST',
  RetailerForwardList = 'RETAILER_FORWARD_LIST',
  RetailerStoriesList = 'RETAILER_STORIES_LIST',
  Slot = 'SLOT',
  StoreDirectoryStoreForwardList = 'STORE_DIRECTORY_STORE_FORWARD_LIST',
  StoreForwardList = 'STORE_FORWARD_LIST',
  StoreSpotlight = 'STORE_SPOTLIGHT',
  TaskCenter = 'TASK_CENTER',
  TileGridList = 'TILE_GRID_LIST',
  TileList = 'TILE_LIST',
  WindowShoppingList = 'WINDOW_SHOPPING_LIST'
}

export type CartEmptyState = {
  __typename?: 'CartEmptyState';
  enabled: Maybe<Scalars['Boolean']>;
  imageUrl: Maybe<Scalars['String']>;
};

export type CatalogAisle = {
  __typename?: 'CatalogAisle';
  /** The department associated with this aisle */
  department: CatalogDepartment;
  /** A unique identifier for the aisle */
  id: Scalars['ID'];
  /** The aisle's name - ('Candy & Chocolates', 'Chips & Pretzels', ...) */
  name: Scalars['String'];
};

export type CatalogClassificationInfo = {
  __typename?: 'CatalogClassificationInfo';
  /** The associated code (Could be a UPC - '034000170388', PLU - '1147', or In-House 'AB008') */
  code: Scalars['String'];
  /** The associated retailer (for in-house products) */
  retailer: Maybe<Retailer>;
  /** The type of classified code (universal, commodity, in-house, ...) */
  type: ClassifiedTypeEnum;
};

export type CatalogDepartment = {
  __typename?: 'CatalogDepartment';
  /** An array of the department's aisles */
  aisles: Array<CatalogAisle>;
  /** A unique identifier for the department */
  id: Scalars['ID'];
  /** The department's name - ('Snacks', 'Meat & Seafood', ...) */
  name: Scalars['String'];
};

export type CatalogProduct = {
  __typename?: 'CatalogProduct';
  /** The classified code/type associated with the product (contains UPC details) */
  classificationInfo: CatalogClassificationInfo;
  /**
   * The classified code associated with the product (Also referred to as UPC - '034000170388')
   * @deprecated Use `classification info` instead, as it has more details than `classified_code`
   */
  classifiedCode: Maybe<Scalars['String']>;
  /** Details about the product, e.g. 'Brown, overripe bananas, suitable for baking */
  description: Maybe<Scalars['String']>;
  /** The product's name - 'Hershey's Extra Large Chocolate Bar' */
  displayName: Scalars['String'];
  /** A unique identifier for the product */
  id: Scalars['ID'];
  /**
   * The product's name - 'Hershey's Extra Large Chocolate Bar'
   * @deprecated Use `display name` instead, as it has more details than `name`
   */
  name: Scalars['String'];
  /** An absolute url of the primary image associated with the product - 'https://www.example.com/etc' */
  primaryImageUrl: Maybe<Scalars['String']>;
};

export type CatalogRetailerProduct = {
  __typename?: 'CatalogRetailerProduct';
  /** The available item count of the retailer product */
  availableItemsCount: Maybe<Scalars['Int']>;
  /** The details of the retailer product */
  details: Maybe<Scalars['String']>;
  /** The display_name of the retailer product */
  displayName: Maybe<Scalars['String']>;
  /** A unique identifier for the retailer product */
  id: Scalars['ID'];
  /** The Url of the retailer product's primary image */
  primaryImageUrl: Maybe<Scalars['Url']>;
  /** The unique identifier for the associated product */
  productId: Maybe<Scalars['ID']>;
  /** The size of the retailer product (e.g., 4 oz) */
  size: Maybe<Scalars['String']>;
  /** The unit count of the retailer product */
  unitCount: Maybe<Scalars['Int']>;
};

/** The connection type for CatalogRetailerProduct. */
export type CatalogRetailerProductConnection = {
  __typename?: 'CatalogRetailerProductConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CatalogRetailerProductEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CatalogRetailerProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CatalogRetailerProductEdge = {
  __typename?: 'CatalogRetailerProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<CatalogRetailerProduct>;
};

export type CatalogSchema = {
  __typename?: 'CatalogSchema';
  _: Maybe<Scalars['Boolean']>;
};

export type CatalogSearchDownloadsCatalogSearchDownloadSignedUrl = {
  __typename?: 'CatalogSearchDownloadsCatalogSearchDownloadSignedUrl';
  /** Signed url for the object */
  signedUrl: Scalars['String'];
};

export type CatalogTaxonomyNode = {
  __typename?: 'CatalogTaxonomyNode';
  /** A unique identifier for the taxonomy node */
  id: Scalars['ID'];
  /** The taxonomy nodes's name - ('Food', 'Meat', ...) */
  name: Scalars['String'];
  /** The parent taxonomy node */
  parentId: Scalars['ID'];
};

export enum CategorySurfaceType {
  AlcoholSurface = 'ALCOHOL_SURFACE',
  RetailerRecommendation = 'RETAILER_RECOMMENDATION'
}

export type CheckIfLaunchOnboardingInProgressByPartnerRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type CheckIfLaunchOnboardingInProgressByPartnerResponse = {
  __typename?: 'CheckIfLaunchOnboardingInProgressByPartnerResponse';
  hasInProgressLaunchOnboarding: Maybe<Scalars['Boolean']>;
  workflowState: Maybe<Scalars['String']>;
};

export type CheckLaunchOnboardingProgressByPartnerRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type CheckLaunchOnboardingProgressByPartnerResponse = {
  __typename?: 'CheckLaunchOnboardingProgressByPartnerResponse';
  workflowState: Maybe<Scalars['String']>;
};

export type CheckoutSchema = {
  __typename?: 'CheckoutSchema';
  smsMarketingOptIn: Maybe<Scalars['Boolean']>;
  trackExtUserId: Maybe<Scalars['Boolean']>;
};

export enum ClassifiedTypeEnum {
  /** A commodity (bananas, apples, ...) */
  Commodity = 'COMMODITY',
  /** A product that is specific to a retailer (not universal) */
  InHouse = 'IN_HOUSE',
  /** A universal product (standard product with a UPC) */
  Universal = 'UNIVERSAL',
  /** The classification type is not known */
  Unknown = 'UNKNOWN'
}

export type ClearOverrideHoursCriteriaInput = {
  date?: InputMaybe<GoogleTypeDateInput>;
  serviceType?: InputMaybe<RetailerServiceType>;
};

export type ClientConfiguration = {
  __typename?: 'ClientConfiguration';
  applicationType: Maybe<Scalars['String']>;
  clientConfigurationId: Maybe<Scalars['Int']>;
  retailerName: Maybe<Scalars['String']>;
  retailerSlug: Maybe<Scalars['String']>;
  settings: Maybe<Scalars['JSONObject']>;
  storeConfigurationIds: Maybe<Scalars['String']>;
  userIsolationContextId: Maybe<Scalars['Int']>;
};

export type ClientConfigurationInput = {
  applicationType?: InputMaybe<Scalars['String']>;
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  retailerName?: InputMaybe<Scalars['String']>;
  retailerSlug?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSONObject']>;
  storeConfigurationIds?: InputMaybe<Scalars['String']>;
  userIsolationContextId?: InputMaybe<Scalars['Int']>;
};

export enum ClientContextClient {
  Ipp = 'IPP',
  Unknown = 'UNKNOWN'
}

export type ClientContextInput = {
  canonicalId?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<ClientContextClient>;
  email?: InputMaybe<Scalars['String']>;
};

export type ClientInput = {
  appName?: InputMaybe<Scalars['String']>;
  contextJson?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};

export type Collection = {
  __typename?: 'Collection';
  active: Maybe<Scalars['Boolean']>;
  activeAdminOnly: Maybe<Scalars['Boolean']>;
  collectionStoreConfigurations: Maybe<Array<Maybe<CollectionStoreConfiguration>>>;
  collectionType: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  defaultSortOrder: Maybe<Scalars['String']>;
  dynamicallyFedStatic: Maybe<Scalars['Boolean']>;
  endDate: Maybe<Scalars['DateTime']>;
  excludedRetailerIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  filters: Maybe<Array<Maybe<Filter>>>;
  headerImageUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  imagePath: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  inventoryFileMappings: Maybe<Array<Maybe<InventoryFileMapping>>>;
  legacyId: Maybe<Scalars['BigInt']>;
  mobileHeaderImageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  nestedCollections: Maybe<Array<Maybe<Collection>>>;
  parentId: Maybe<Scalars['BigInt']>;
  position: Maybe<Scalars['BigInt']>;
  productCount: Maybe<Scalars['BigInt']>;
  productIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  products: Maybe<Array<Maybe<Product>>>;
  replaceDepartmentIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  requiredAlcoholContext: Maybe<Scalars['String']>;
  requiredRetailerIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerId: Maybe<Scalars['BigInt']>;
  searchThumbWideImageUrl: Maybe<Scalars['String']>;
  showDepartmentTile: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  translations: Maybe<Array<Maybe<CollectionTranslation>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum CollectionStatus {
  Active = 'ACTIVE',
  ActiveAdminOnly = 'ACTIVE_ADMIN_ONLY',
  EnumTypeStatusUnspecified = 'ENUM_TYPE_STATUS_UNSPECIFIED',
  Inactive = 'INACTIVE'
}

export type CollectionStoreConfiguration = {
  __typename?: 'CollectionStoreConfiguration';
  id: Maybe<Scalars['BigInt']>;
  pinned: Maybe<Scalars['Boolean']>;
};

export type CollectionTranslation = {
  __typename?: 'CollectionTranslation';
  localeCode: Maybe<InstacartCustomersSharedV1Locale>;
  name: Maybe<Scalars['String']>;
};

export type CollectionTranslationInput = {
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  name?: InputMaybe<Scalars['String']>;
};

export type ColorsSchema = {
  __typename?: 'ColorsSchema';
  brandExpressDark: Maybe<Scalars['String']>;
  brandExpressExtraDark: Maybe<Scalars['String']>;
  brandExpressLight: Maybe<Scalars['String']>;
  brandExpressRegular: Maybe<Scalars['String']>;
  brandHighlightDark: Maybe<Scalars['String']>;
  brandHighlightLight: Maybe<Scalars['String']>;
  brandHighlightRegular: Maybe<Scalars['String']>;
  brandLoyaltyDark: Maybe<Scalars['String']>;
  brandLoyaltyLight: Maybe<Scalars['String']>;
  brandLoyaltyRegular: Maybe<Scalars['String']>;
  brandMaxDark: Maybe<Scalars['String']>;
  brandMaxLight: Maybe<Scalars['String']>;
  brandPrimaryDark: Maybe<Scalars['String']>;
  brandPrimaryExtraDark: Maybe<Scalars['String']>;
  brandPrimaryRegular: Maybe<Scalars['String']>;
  brandPromotionalDark: Maybe<Scalars['String']>;
  brandPromotionalLight: Maybe<Scalars['String']>;
  brandPromotionalRegular: Maybe<Scalars['String']>;
  brandSecondaryDark: Maybe<Scalars['String']>;
  brandSecondaryLight: Maybe<Scalars['String']>;
  brandSecondaryRegular: Maybe<Scalars['String']>;
  highContrastColors: Maybe<HighContrastColorsSchema>;
  plusDark: Maybe<Scalars['String']>;
  plusExtraDark: Maybe<Scalars['String']>;
  plusExtraLight: Maybe<Scalars['String']>;
  plusLight: Maybe<Scalars['String']>;
  plusRegular: Maybe<Scalars['String']>;
  systemDetrimentalDark: Maybe<Scalars['String']>;
  systemDetrimentalExtraDark: Maybe<Scalars['String']>;
  systemDetrimentalLight: Maybe<Scalars['String']>;
  systemDetrimentalRegular: Maybe<Scalars['String']>;
  systemGrayscale_00: Maybe<Scalars['String']>;
  systemGrayscale_10: Maybe<Scalars['String']>;
  systemGrayscale_20: Maybe<Scalars['String']>;
  systemGrayscale_30: Maybe<Scalars['String']>;
  systemGrayscale_50: Maybe<Scalars['String']>;
  systemGrayscale_70: Maybe<Scalars['String']>;
  systemSuccessDark: Maybe<Scalars['String']>;
  systemSuccessLight: Maybe<Scalars['String']>;
  systemSuccessRegular: Maybe<Scalars['String']>;
  tertiaryRegular: Maybe<Scalars['String']>;
};

export type CombineAuditBatchesRequestInput = {
  batchUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<Scalars['String']>;
  workflow?: InputMaybe<Scalars['String']>;
};

export type CombineAuditBatchesResponse = {
  __typename?: 'CombineAuditBatchesResponse';
  batchUuid: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
};

export type CompactBanner = {
  __typename?: 'CompactBanner';
  backgroundColorHex: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaBackgroundColorHex: Maybe<Scalars['String']>;
  ctaColorHex: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  imageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type CompactBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ConfigurableButtonInput = {
  /** Background color of the button */
  backgroundColor?: InputMaybe<Scalars['String']>;
  /** Color of the button text */
  color?: InputMaybe<Scalars['String']>;
  /** The button text */
  label: Scalars['String'];
  /** The button style to use */
  styleVariant?: InputMaybe<ButtonVariantEnum>;
};

export type ConfigurableTextInput = {
  /** The color of the text (Eg. #FFFFFF) */
  color: Scalars['String'];
  /** The text to display */
  text: Scalars['String'];
};

export type ConfirmedStoreHours = {
  __typename?: 'ConfirmedStoreHours';
  confirmed: Maybe<Scalars['Boolean']>;
  date: Maybe<GoogleTypeDate>;
  retailerLocationId: Maybe<Scalars['BigInt']>;
};

export type ConfirmedStoreHoursInput = {
  confirmed?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type ConnectRetailerCallbackConfiguration = {
  __typename?: 'ConnectRetailerCallbackConfiguration';
  accessTokenTtl: Maybe<Scalars['Int']>;
  authUri: Maybe<Scalars['String']>;
  clientConfigurationId: Maybe<Scalars['BigInt']>;
  credentials: Maybe<ConnectRetailerCallbackCredentials>;
  enabled: Maybe<Scalars['Boolean']>;
  environment: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  isDeleted: Maybe<Scalars['Boolean']>;
  lastUpdatedAt: Maybe<Scalars['DateTime']>;
  name: Maybe<Scalars['String']>;
  returnsPaymentAck: Maybe<Scalars['Boolean']>;
  v1Settings: Maybe<ConnectRetailerCallbackV1Settings>;
  v2Settings: Maybe<ConnectRetailerCallbackV2Settings>;
};

export type ConnectRetailerCallbackConfigurationInput = {
  accessTokenTtl?: InputMaybe<Scalars['Int']>;
  authUri?: InputMaybe<Scalars['String']>;
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
  credentials?: InputMaybe<ConnectRetailerCallbackCredentialsInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  environment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  returnsPaymentAck?: InputMaybe<Scalars['Boolean']>;
  v1Settings?: InputMaybe<ConnectRetailerCallbackV1SettingsInput>;
  v2Settings?: InputMaybe<ConnectRetailerCallbackV2SettingsInput>;
};

export type ConnectRetailerCallbackCredentials = {
  __typename?: 'ConnectRetailerCallbackCredentials';
  clientId: Maybe<Scalars['String']>;
  clientSecret: Maybe<Scalars['String']>;
  clientSecretEnvKey: Maybe<Scalars['String']>;
  customKey: Maybe<Scalars['String']>;
  customTokenEnvKey: Maybe<Scalars['String']>;
  grantType: Maybe<Scalars['String']>;
  resource: Maybe<Scalars['String']>;
  scope: Maybe<Scalars['String']>;
};

export type ConnectRetailerCallbackCredentialsInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clientSecretEnvKey?: InputMaybe<Scalars['String']>;
  customKey?: InputMaybe<Scalars['String']>;
  customTokenEnvKey?: InputMaybe<Scalars['String']>;
  grantType?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
};

export type ConnectRetailerCallbackV1Settings = {
  __typename?: 'ConnectRetailerCallbackV1Settings';
  callbackUri: Maybe<Scalars['String']>;
  statusesOfInterest: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConnectRetailerCallbackV1SettingsInput = {
  callbackUri?: InputMaybe<Scalars['String']>;
  statusesOfInterest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ConnectRetailerCallbackV2Settings = {
  __typename?: 'ConnectRetailerCallbackV2Settings';
  callbackUri: Maybe<Scalars['String']>;
  orderLocationCallbackFrequency: Maybe<Scalars['BigInt']>;
  statusesOfInterest: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConnectRetailerCallbackV2SettingsInput = {
  callbackUri?: InputMaybe<Scalars['String']>;
  orderLocationCallbackFrequency?: InputMaybe<Scalars['BigInt']>;
  statusesOfInterest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ConnectionMeta = {
  __typename?: 'ConnectionMeta';
  /** The total number of connection elements */
  totalCount: Scalars['Int'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContentManagementAnnouncementBannerGenericImages = {
  __typename?: 'ContentManagementAnnouncementBannerGenericImages';
  /** The foreground image url in the placement to be used on desktop clients */
  desktop: Maybe<Scalars['Url']>;
  /** The foreground image url in the placement to be used on mobile clients */
  mobile: Scalars['Url'];
};

export type ContentManagementConfigurableButton = {
  __typename?: 'ContentManagementConfigurableButton';
  /** The background color of the button (Eg. #FFFFFF) */
  backgroundColor: Maybe<Scalars['String']>;
  /** Color of the button text (Eg. #000000) */
  color: Maybe<Scalars['String']>;
  /** The button text */
  label: Scalars['String'];
  /** The button style to use */
  styleVariant: ButtonVariantEnum;
};

export type ContentManagementConfigurableText = {
  __typename?: 'ContentManagementConfigurableText';
  /** The color of the text (Eg. #FFFFFF) */
  color: Scalars['String'];
  /** The text to display */
  text: Maybe<Scalars['String']>;
};

export type ContentManagementFlyoutPlacement = {
  __typename?: 'ContentManagementFlyoutPlacement';
  /** A unique identifier of the banner associated with the placement */
  bannerId: Maybe<Scalars['ID']>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** The style and text of the subheading */
  descriptionText: ContentManagementConfigurableText;
  /** The style and text of the secondary text */
  disclaimerText: Maybe<ContentManagementConfigurableText>;
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment: Maybe<ContentManagementPlacementExperiment>;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The possible images to display */
  images: ContentManagementAnnouncementBannerGenericImages;
  /** The style and text of the button within the placement */
  linkButton: Maybe<ContentManagementConfigurableButton>;
  /** Where interacting with the placement leads */
  linkDestination: Maybe<ContentManagementPlacementDestination>;
  /** Locale */
  locale: BannerLocaleEnum;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The style and text of the button within the placement */
  primaryButton: Maybe<ContentManagementConfigurableButton>;
  /** Where interacting with the placement leads */
  primaryDestination: Maybe<ContentManagementPlacementDestination>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The style and text of the button within the placement */
  secondaryButton: Maybe<ContentManagementConfigurableButton>;
  /** Where interacting with the placement leads */
  secondaryDestination: Maybe<ContentManagementPlacementDestination>;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The style and text of the heading */
  titleText: ContentManagementConfigurableText;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
};

export type ContentManagementHeroBannerPlacement = {
  __typename?: 'ContentManagementHeroBannerPlacement';
  /** The color of the background for text images (Eg. #FFFFFF) */
  backgroundColor: Maybe<Scalars['String']>;
  /** A unique identifier of the banner associated with the placement */
  bannerId: Maybe<Scalars['ID']>;
  /** The style and text of the button within the placement */
  button: Maybe<ContentManagementConfigurableButton>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** Where interacting with the placement leads */
  destination: Maybe<ContentManagementPlacementDestination>;
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment: Maybe<ContentManagementPlacementExperiment>;
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The possible images to display */
  images: ContentManagementAnnouncementBannerGenericImages;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
  /** The color of the text area (Eg. #FFFFFF) */
  textareaColor: Maybe<Scalars['String']>;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
};

export type ContentManagementPbiConfiguration = {
  __typename?: 'ContentManagementPbiConfiguration';
  /** The url for the retailer's pbi instance */
  domainName: Scalars['String'];
  /** The unique slug for the retailer */
  slug: Scalars['String'];
};

export type ContentManagementPlacementDestination = {
  __typename?: 'ContentManagementPlacementDestination';
  /** The human readable value of the target */
  displayValue: Maybe<Scalars['String']>;
  /** The target destination of interacting with the placement. Can be an ID of a product/collection/department, or a URL */
  target: Scalars['String'];
  /** What the corresponding target refers to */
  type: PlacementDestinationTypeEnum;
};

export type ContentManagementPlacementExperiment = {
  __typename?: 'ContentManagementPlacementExperiment';
  /** Experiment Assignment or Lookup */
  accessMethod: Maybe<PlacementExperimentMethodEnum>;
  /** Name of the Experiment */
  name: Maybe<Scalars['String']>;
  /** Variant of the Experiment for which placement should be visible */
  variant: Maybe<Scalars['String']>;
};

export type ContentManagementPlacementImages = {
  __typename?: 'ContentManagementPlacementImages';
  /** The image url of the placement to display for large viewports */
  large: Maybe<Scalars['Url']>;
  /** The image url of the placement to display for small viewports */
  small: Maybe<Scalars['Url']>;
};

export type ContentManagementPlacementSchedule = {
  __typename?: 'ContentManagementPlacementSchedule';
  /** When the placement should stop being active */
  endDate: Maybe<Scalars['Time']>;
  /** When the placement should become active */
  startDate: Scalars['Time'];
  /** The timezone in which the banner will stop being active at midnight */
  timezone: Maybe<Scalars['String']>;
};

export type ContentManagementSecondaryBannerPlacement = {
  __typename?: 'ContentManagementSecondaryBannerPlacement';
  /** The color of the background for text images (Eg. #FFFFFF) */
  backgroundColor: Maybe<Scalars['String']>;
  /** A unique identifier of the banner associated with the placement */
  bannerId: Maybe<Scalars['ID']>;
  /** The style and text of the button within the placement */
  button: Maybe<ContentManagementConfigurableButton>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** Where interacting with the placement leads */
  destination: Maybe<ContentManagementPlacementDestination>;
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment: Maybe<ContentManagementPlacementExperiment>;
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The possible images to display */
  images: ContentManagementAnnouncementBannerGenericImages;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The style and text of the secondary text */
  secondaryText: Maybe<ContentManagementConfigurableText>;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
};

export type ContentManagementStorefrontPlacement = {
  __typename?: 'ContentManagementStorefrontPlacement';
  /** The color of the background for text images (Eg. #FFFFFF) */
  backgroundColor: Maybe<Scalars['String']>;
  /** A unique identifier of the banner associated with the placement */
  bannerId: Maybe<Scalars['ID']>;
  /** The banners configured to the placement */
  banners: Array<ContentManagementStorefrontPlacementBanner>;
  /** The style and text of the button within the placement */
  button: Maybe<ContentManagementConfigurableButton>;
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** The style and text of the description */
  descriptionText: ContentManagementConfigurableText;
  /** Where interacting with the placement leads */
  destination: Maybe<ContentManagementPlacementDestination>;
  /** Whether or not the placement can be dismissed */
  dismissable: Scalars['Boolean'];
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: ContentManagementPlacementImages;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds: Maybe<Array<Scalars['Int']>>;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** A unique identifier for the retailer */
  retailerId: Scalars['ID'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
};

export type ContentManagementStorefrontPlacementBanner = {
  __typename?: 'ContentManagementStorefrontPlacementBanner';
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Maybe<Scalars['String']>;
  /** The style and text of the button within the placement */
  button: Maybe<ContentManagementConfigurableButton>;
  /** The style and text of the description */
  descriptionText: ContentManagementConfigurableText;
  /** The style and text of the heading */
  headingText: ContentManagementConfigurableText;
  /** A unique identifier of the banner associated with the placement */
  id: Maybe<Scalars['ID']>;
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: ContentManagementPlacementImages;
  /** The banner's locale */
  locale: Maybe<BannerLocaleEnum>;
  /** The style and text of the subheading */
  subheadingText: ContentManagementConfigurableText;
};

/** The connection type for ContentManagementStorefrontPlacement. */
export type ContentManagementStorefrontPlacementConnection = {
  __typename?: 'ContentManagementStorefrontPlacementConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ContentManagementStorefrontPlacementEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ContentManagementStorefrontPlacement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContentManagementStorefrontPlacementEdge = {
  __typename?: 'ContentManagementStorefrontPlacementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<ContentManagementStorefrontPlacement>;
};

export type ContentManagementUnknownBannerPlacement = {
  __typename?: 'ContentManagementUnknownBannerPlacement';
  /** When the placement was created */
  createdAt: Scalars['Time'];
  /** If the placement is enabled */
  enabled: Scalars['Boolean'];
  /** Experiment associated with the placement */
  experiment: Maybe<ContentManagementPlacementExperiment>;
  /** A unique identifier for the placement */
  id: Scalars['ID'];
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank: Scalars['Int'];
  /** When the placement begins and ends */
  scheduling: ContentManagementPlacementSchedule;
  /** The current status of the placement */
  status: PlacementStatusEnum;
  /** The type of this placement */
  type: PlacementTypeEnum;
  /** When the placement was last edited */
  updatedAt: Scalars['Time'];
};

export type ContentManagementVisibilityConditionCountryIds = {
  __typename?: 'ContentManagementVisibilityConditionCountryIds';
  /** list of country ids */
  countryIds: Array<Scalars['Int']>;
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionDayOfWeek = {
  __typename?: 'ContentManagementVisibilityConditionDayOfWeek';
  /** list of days within the week */
  days: Array<Scalars['String']>;
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionExperimentVariant = {
  __typename?: 'ContentManagementVisibilityConditionExperimentVariant';
  /** The method of the experiment */
  experimentMethod: PlacementExperimentMethodEnum;
  /** The name of the experiment */
  experimentName: Scalars['String'];
  /** The name of the experiment variant */
  experimentVariant: Scalars['String'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionExpressMembership = {
  __typename?: 'ContentManagementVisibilityConditionExpressMembership';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Whether the user should be an express member */
  membershipStatus: Scalars['Boolean'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Maybe<Scalars['ID']>;
};

export type ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout = {
  __typename?: 'ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Maybe<Scalars['ID']>;
};

export type ContentManagementVisibilityConditionMatchesAnyRetailerId = {
  __typename?: 'ContentManagementVisibilityConditionMatchesAnyRetailerId';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of retailer ids */
  retailerIds: Array<Scalars['Int']>;
};

export type ContentManagementVisibilityConditionMinimumPlatformVersion = {
  __typename?: 'ContentManagementVisibilityConditionMinimumPlatformVersion';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** The platform the version is being checked for */
  platform: PlacementPlatformEnum;
  /** Version used for the check */
  version: Scalars['String'];
};

export type ContentManagementVisibilityConditionNewVerticalUserTargeting = {
  __typename?: 'ContentManagementVisibilityConditionNewVerticalUserTargeting';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** The module type */
  moduleType: Scalars['String'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionRetailerAvailability = {
  __typename?: 'ContentManagementVisibilityConditionRetailerAvailability';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** A unique identifier of the retailer associated with the visibility condition */
  retailerId: Scalars['Int'];
};

export type ContentManagementVisibilityConditionShopped = {
  __typename?: 'ContentManagementVisibilityConditionShopped';
  /** Id of the country where the check is to be performed */
  countryId: Scalars['Int'];
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Number of days in the past to check */
  numberOfDays: Scalars['Int'];
  /** Number of times in the past to check */
  numberOfTimes: Scalars['Int'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId: Scalars['Int'];
};

export type ContentManagementVisibilityConditionShoppedAtRetailer = {
  __typename?: 'ContentManagementVisibilityConditionShoppedAtRetailer';
  /** Id of the country where the check is to be performed */
  countryId: Scalars['Int'];
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Number of days in the past to check */
  numberOfDays: Scalars['Int'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the Retailer */
  retailerId: Scalars['Int'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId: Scalars['Int'];
};

export type ContentManagementVisibilityConditionUnknownVisibilityCondition = {
  __typename?: 'ContentManagementVisibilityConditionUnknownVisibilityCondition';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionUserInteractionLimit = {
  __typename?: 'ContentManagementVisibilityConditionUserInteractionLimit';
  /** Number of seconds to wait before resetting the counter */
  countCooldownSec: Maybe<Scalars['Int']>;
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** Maximum number of dismiss events */
  maxDismissedCount: Maybe<Scalars['Int']>;
  /** Maximum number of click events */
  maxEngagementsCount: Maybe<Scalars['Int']>;
  /** Maximum number of view events */
  maxImpressionsCount: Maybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

export type ContentManagementVisibilityConditionUtmParameters = {
  __typename?: 'ContentManagementVisibilityConditionUtmParameters';
  /** coupon_code */
  couponCode: Scalars['String'];
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** utm_campaign */
  utmCampaign: Scalars['String'];
  /** utm_content */
  utmContent: Scalars['String'];
  /** utm_medium */
  utmMedium: Scalars['String'];
  /** utm_source */
  utmSource: Scalars['String'];
  /** utm_term */
  utmTerm: Scalars['String'];
};

export type ContentManagementVisibilityConditionWhitelistedPostalCodes = {
  __typename?: 'ContentManagementVisibilityConditionWhitelistedPostalCodes';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of postal codes */
  postalCodes: Array<Scalars['String']>;
};

export type ContentManagementVisibilityConditionWhitelistedZones = {
  __typename?: 'ContentManagementVisibilityConditionWhitelistedZones';
  /** A unique identifier for the visibility condition */
  id: Scalars['ID'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of zone ids */
  zones: Array<Scalars['String']>;
};

export type ContentPage = {
  __typename?: 'ContentPage';
  createdAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  internalName: Maybe<Scalars['String']>;
  layout: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['BigInt']>;
  slug: Maybe<Scalars['String']>;
  storeConfigurationId: Maybe<Scalars['BigInt']>;
};

export type ContentPageMetaInfoStatusFound302 = {
  __typename?: 'ContentPageMetaInfoStatusFound302';
  redirectUrl: Maybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusFound302Input = {
  redirectUrl?: InputMaybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusMovedPermanently301 = {
  __typename?: 'ContentPageMetaInfoStatusMovedPermanently301';
  redirectUrl: Maybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusMovedPermanently301Input = {
  redirectUrl?: InputMaybe<Scalars['String']>;
};

export type ContentPageMetaInfoStatusNotFound404 = {
  __typename?: 'ContentPageMetaInfoStatusNotFound404';
  _: Maybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusNotFound404Input = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusOk200 = {
  __typename?: 'ContentPageMetaInfoStatusOk200';
  _: Maybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusOk200Input = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export type ContentPageMetaInfoStatusOneOf = {
  __typename?: 'ContentPageMetaInfoStatusOneOf';
  found302: Maybe<ContentPageMetaInfoStatusFound302>;
  movedPermanently301: Maybe<ContentPageMetaInfoStatusMovedPermanently301>;
  notFound404: Maybe<ContentPageMetaInfoStatusNotFound404>;
  ok200: Maybe<ContentPageMetaInfoStatusOk200>;
};

export type ContentPageMetaInfoStatusOneOfInput = {
  found302?: InputMaybe<ContentPageMetaInfoStatusFound302Input>;
  movedPermanently301?: InputMaybe<ContentPageMetaInfoStatusMovedPermanently301Input>;
  notFound404?: InputMaybe<ContentPageMetaInfoStatusNotFound404Input>;
  ok200?: InputMaybe<ContentPageMetaInfoStatusOk200Input>;
};

export type ContentPageV2 = {
  __typename?: 'ContentPageV2';
  activeVersionId: Maybe<Scalars['BigInt']>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  internalName: Maybe<Scalars['String']>;
  pageType: Maybe<Scalars['String']>;
  retailerIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  slug: Maybe<Scalars['String']>;
  storeConfigurationIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type ContentPageVersion = {
  __typename?: 'ContentPageVersion';
  contentPageId: Maybe<Scalars['BigInt']>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  previewToken: Maybe<Scalars['String']>;
  routingStatus: Maybe<ContentPageMetaInfoStatusOneOf>;
  seoCanonicalUrl: Maybe<Scalars['String']>;
  seoNoIndex: Maybe<Scalars['Boolean']>;
  trackingProperties: Maybe<Scalars['JSONObject']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type ContentPageVersionMeta = {
  __typename?: 'ContentPageVersionMeta';
  contentPageVersionId: Maybe<Scalars['BigInt']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  locale: Maybe<InstacartCustomersSharedV1Locale>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type ContractRenderData = {
  __typename?: 'ContractRenderData';
  companyAddress: Maybe<Scalars['String']>;
  companyContactName: Maybe<Scalars['String']>;
  companyContactTitle: Maybe<Scalars['String']>;
  companyName: Maybe<Scalars['String']>;
  currentDate: Maybe<Scalars['String']>;
  stateOfIncorporation: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  /** country code */
  alpha2: Maybe<Scalars['String']>;
  /** Unique identifier */
  id: Scalars['ID'];
  /** country name */
  name: Scalars['String'];
};

export type CountryIdsInput = {
  /** list of country ids */
  countryIds: Array<Scalars['Int']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of CountryIdsVisibilityConditionCreate */
export type CountryIdsVisibilityConditionCreatePayload = {
  __typename?: 'CountryIdsVisibilityConditionCreatePayload';
  /** The newly created CountryIds visibility condition */
  countryIdsVisibilityCondition: ContentManagementVisibilityConditionCountryIds;
};

/** Autogenerated return type of CountryIdsVisibilityConditionUpdate */
export type CountryIdsVisibilityConditionUpdatePayload = {
  __typename?: 'CountryIdsVisibilityConditionUpdatePayload';
  /** The newly updated CountryIds visibility condition */
  countryIdsVisibilityCondition: ContentManagementVisibilityConditionCountryIds;
};

export type CreateClientConfigurationRequestInput = {
  allowExistingUserIsolationContextId?: InputMaybe<Scalars['Boolean']>;
  clientConfiguration?: InputMaybe<ClientConfigurationInput>;
};

export type CreateClientConfigurationResponse = {
  __typename?: 'CreateClientConfigurationResponse';
  clientConfiguration: Maybe<ClientConfiguration>;
  errorMessage: Maybe<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

export type CreateCollectionRequestInput = {
  defaultSortOrder?: InputMaybe<Scalars['String']>;
  dynamicallyFedStatic?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  name?: InputMaybe<Scalars['String']>;
  productEvaluation?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<CollectionTranslationInput>>>;
};

export type CreateCollectionResponse = {
  __typename?: 'CreateCollectionResponse';
  collection: Maybe<Collection>;
};

export type CreateConnectRetailerCallbackConfigurationRequestInput = {
  callbackConfiguration?: InputMaybe<ConnectRetailerCallbackConfigurationInput>;
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateConnectRetailerCallbackConfigurationResponse = {
  __typename?: 'CreateConnectRetailerCallbackConfigurationResponse';
  callbackConfiguration: Maybe<ConnectRetailerCallbackConfiguration>;
  errorMessage: Maybe<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

export type CreateContentPageRequestInput = {
  internalName?: InputMaybe<Scalars['String']>;
  layout?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateContentPageRequestV2Input = {
  internalName?: InputMaybe<Scalars['String']>;
  pageType?: InputMaybe<Scalars['String']>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  slug?: InputMaybe<Scalars['String']>;
  storeConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  teamOwner?: InputMaybe<Scalars['String']>;
};

export type CreateContentPageResponse = {
  __typename?: 'CreateContentPageResponse';
  contentPage: Maybe<ContentPage>;
};

export type CreateContentPageResponseV2 = {
  __typename?: 'CreateContentPageResponseV2';
  contentPage: Maybe<ContentPageV2>;
};

export type CreateContentPageVersionMetaRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  description?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateContentPageVersionMetaResponse = {
  __typename?: 'CreateContentPageVersionMetaResponse';
  contentPageVersionMeta: Maybe<ContentPageVersionMeta>;
};

export type CreateContentPageVersionRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  baseContentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  routingStatus?: InputMaybe<ContentPageMetaInfoStatusOneOfInput>;
  seoCanonicalUrl?: InputMaybe<Scalars['String']>;
  seoNoIndex?: InputMaybe<Scalars['Boolean']>;
  trackingProperties?: InputMaybe<Scalars['JSONObject']>;
};

export type CreateContentPageVersionResponse = {
  __typename?: 'CreateContentPageVersionResponse';
  contentPageVersion: Maybe<ContentPageVersion>;
};

export type CreateDriverRequestInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateDriverResponse = {
  __typename?: 'CreateDriverResponse';
  id: Maybe<Scalars['BigInt']>;
};

export type CreateImageUploadBatchRequestInput = {
  imagesZipKey?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateImageUploadBatchResponse = {
  __typename?: 'CreateImageUploadBatchResponse';
  imageUploadBatchId: Maybe<Scalars['BigInt']>;
  status: Maybe<Scalars['String']>;
};

export type CreateInventoryFileMappingRequestInput = {
  collectionSlug?: InputMaybe<Scalars['String']>;
  retailerCollectionId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateInventoryFileMappingResponse = {
  __typename?: 'CreateInventoryFileMappingResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type CreateInvfMappingRequestInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateInvfMappingResponse = {
  __typename?: 'CreateInvfMappingResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type CreateLastmileOrderFromDashboardRequestInput = {
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  order?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1LastMileOrderDashboardCreationParamsInput>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateLastmileOrderFromDashboardResponse = {
  __typename?: 'CreateLastmileOrderFromDashboardResponse';
  order: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

export type CreateLayoutVariantRequestInput = {
  layoutVariant?: InputMaybe<LayoutVariantInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateLayoutVariantResponse = {
  __typename?: 'CreateLayoutVariantResponse';
  layoutVariant: Maybe<LayoutVariant>;
};

export type CreateMomentRequestInput = {
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignSubmissionDeadline?: InputMaybe<Scalars['DateTime']>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  templateId?: InputMaybe<Scalars['String']>;
};

export type CreateMomentResponse = {
  __typename?: 'CreateMomentResponse';
  moment: Maybe<Moment>;
};

export type CreateNavigationPreviewRequestInput = {
  navigation?: InputMaybe<NavigationInput>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateNavigationPreviewResponse = {
  __typename?: 'CreateNavigationPreviewResponse';
  previewToken: Maybe<Scalars['String']>;
};

export type CreateNavigationRequestInput = {
  layout?: InputMaybe<Scalars['String']>;
  navigation?: InputMaybe<NavigationInput>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateNavigationResponse = {
  __typename?: 'CreateNavigationResponse';
  navigation: Maybe<Navigation>;
};

export type CreateOnboardingStoreLocationRequestInput = {
  address?: InputMaybe<AddressInput>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOnboardingStoreLocationResponse = {
  __typename?: 'CreateOnboardingStoreLocationResponse';
  onboarding: Maybe<Onboarding>;
};

export type CreateOosAcknowledgmentRequestInput = {
  oosId?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateOosAcknowledgmentResponse = {
  __typename?: 'CreateOosAcknowledgmentResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type CreateOrUpdateLinkAutosuggestionAdminParametersInput = {
  linkAutosuggestion?: InputMaybe<LinkAutosuggestionAdminInput>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateOrUpdateLinkAutosuggestionAdminRequestInput = {
  parameters?: InputMaybe<CreateOrUpdateLinkAutosuggestionAdminParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type CreateOrUpdateLinkAutosuggestionAdminResponse = {
  __typename?: 'CreateOrUpdateLinkAutosuggestionAdminResponse';
  linkAutosuggestion: Maybe<LinkAutosuggestionAdmin>;
};

export type CreateOrUpdateReturnRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  returnData?: InputMaybe<ProposedReturnInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateOrUpdateReturnResponse = {
  __typename?: 'CreateOrUpdateReturnResponse';
  message: Maybe<Scalars['String']>;
  status: Maybe<ResponseStatus>;
};

export type CreatePiiRequestForRetailerRequestInput = {
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  requestType?: InputMaybe<RequestType>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerNotes?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type CreatePiiRequestForRetailerResponse = {
  __typename?: 'CreatePiiRequestForRetailerResponse';
  piiRequestCreationStatus: Maybe<PiiRequestCreationStatus>;
  piiRequestId: Maybe<Scalars['BigInt']>;
};

export type CreatePlacementContentRequestInput = {
  adminContext?: InputMaybe<PlacementAdminContextInput>;
  content?: InputMaybe<PlacementContentInput>;
};

export type CreatePlacementContentResponse = {
  __typename?: 'CreatePlacementContentResponse';
  content: Maybe<PlacementContent>;
};

export type CreatePlacementRequestInput = {
  placement?: InputMaybe<PlacementInput>;
};

export type CreatePlacementResponse = {
  __typename?: 'CreatePlacementResponse';
  placement: Maybe<Placement>;
};

export type CreateRetailerCampaignRequestInput = {
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignReference?: InputMaybe<CampaignReferenceInput>;
  couponCount?: InputMaybe<Scalars['BigInt']>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<CampaignFilterInput>>>;
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  narrowSearchTerms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offers?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferInput>>>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  segmentId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  targeting?: InputMaybe<Array<InputMaybe<Targeting>>>;
  targetingRules?: InputMaybe<Array<InputMaybe<TargetingRulesInput>>>;
  templateId?: InputMaybe<Scalars['String']>;
  validForDays?: InputMaybe<Scalars['BigInt']>;
};

export type CreateRetailerCampaignTreatmentParamsInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  treatmentDetails?: InputMaybe<Array<InputMaybe<RetailerCampaignTreatmentDetailsInput>>>;
  treatmentType?: InputMaybe<RetailerCampaignTreatmentType>;
};

export type CreateRetailerCollectionRequestInput = {
  contentType?: InputMaybe<RetailerCollectionContentType>;
  defaultSortOrder?: InputMaybe<RetailerCollectionSortOrder>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<RetailerCollectionFilterInput>>>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  position?: InputMaybe<Scalars['Int']>;
  products?: InputMaybe<Array<InputMaybe<RetailerCollectionProductPayloadInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<RetailerCollectionStatus>;
  storeConfigurations?: InputMaybe<Array<InputMaybe<RetailerCollectionStoreConfigurationInput>>>;
  surfaces?: InputMaybe<Array<InputMaybe<RetailerCollectionSurface>>>;
  tileImageUrl?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<RetailerCollectionTranslationInput>>>;
};

export type CreateRetailerCollectionResponse = {
  __typename?: 'CreateRetailerCollectionResponse';
  retailerCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type CreateRoleInput = {
  /** A description of the role */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the role */
  name: Scalars['String'];
  partnerId?: InputMaybe<Scalars['ID']>;
  /** The permissions associated with this role */
  permissions?: InputMaybe<Array<PermissionInput>>;
  retailerId?: InputMaybe<Scalars['ID']>;
};

export type CreateRootNodeRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateRootNodeResponse = {
  __typename?: 'CreateRootNodeResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type CreateSignupRequestInput = {
  email?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  signupPartnerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateSignupResponse = {
  __typename?: 'CreateSignupResponse';
  token: Maybe<Scalars['String']>;
};

export type CreateSlotCreativeRequestInput = {
  creativeId?: InputMaybe<Scalars['BigInt']>;
  creativeRank?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['BigInt']>;
  treatmentPolicyId?: InputMaybe<Scalars['String']>;
};

export type CreateSlotCreativeResponse = {
  __typename?: 'CreateSlotCreativeResponse';
  slotCreative: Maybe<SlotCreative>;
};

export type CreateSlotRequestInput = {
  description?: InputMaybe<Scalars['String']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  legacySlotId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateSlotResponse = {
  __typename?: 'CreateSlotResponse';
  slot: Maybe<Slot>;
};

export type CreateStoreConfigurationDraftRequestInput = {
  draftName?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateStoreConfigurationDraftResponse = {
  __typename?: 'CreateStoreConfigurationDraftResponse';
  domainConfigurations: Maybe<DomainConfiguration>;
  draftId: Maybe<Scalars['String']>;
  draftName: Maybe<Scalars['String']>;
  errorMessage: Maybe<Scalars['String']>;
  errorReason: Maybe<ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
  storeConfigurationId: Maybe<Scalars['BigInt']>;
};

export type CreateStoreConfigurationRequestInput = {
  allowExistingUserIsolationContextId?: InputMaybe<Scalars['Boolean']>;
  storeConfiguration?: InputMaybe<StoreConfigurationInput>;
};

export type CreateStoreConfigurationResponse = {
  __typename?: 'CreateStoreConfigurationResponse';
  errorMessage: Maybe<Scalars['String']>;
  storeConfiguration: Maybe<StoreConfiguration>;
  success: Maybe<Scalars['Boolean']>;
};

export type CreateThemePageRequestInput = {
  themePage?: InputMaybe<ThemePageInput>;
};

export type CreateThemePageResponse = {
  __typename?: 'CreateThemePageResponse';
  themePage: Maybe<ThemePage>;
};

export type CreateTicketRequestInput = {
  assignee?: InputMaybe<UserInput>;
  client?: InputMaybe<ClientInput>;
  description?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  entityType?: InputMaybe<EntityType>;
  localeCode?: InputMaybe<Scalars['String']>;
  problemType?: InputMaybe<ProblemType>;
  reportedOnBehalfOf?: InputMaybe<UserInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type CreateTicketResponse = {
  __typename?: 'CreateTicketResponse';
  cptTicketId: Maybe<Scalars['BigInt']>;
};

export type CreateValueBasedOverrideRequestInput = {
  valueBasedOverride?: InputMaybe<ValueBasedOverrideRequestInput>;
};

export type CreateValueBasedOverrideResponse = {
  __typename?: 'CreateValueBasedOverrideResponse';
  valueBasedOverride: Maybe<ValueBasedOverrideResponse>;
};

export type CsvSummary = {
  __typename?: 'CsvSummary';
  addedProducts: Maybe<Array<Maybe<Scalars['String']>>>;
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  removedProducts: Maybe<Array<Maybe<Scalars['String']>>>;
  unchangedProducts: Maybe<Array<Maybe<Scalars['String']>>>;
  unmatchedProducts: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CustomFontFamilySchema = {
  __typename?: 'CustomFontFamilySchema';
  customFontFamily: Maybe<FontFamily>;
};

export type CustomImages = {
  __typename?: 'CustomImages';
  accountOrdersEmptyState: Maybe<AccountOrdersEmptyState>;
  cartEmptyState: Maybe<CartEmptyState>;
};

export enum CustomersAddressType {
  AddressTypeUnspecified = 'ADDRESS_TYPE_UNSPECIFIED',
  Business = 'BUSINESS',
  Residential = 'RESIDENTIAL'
}

export type CustomersAdminRequestContextInput = {
  retailerAppId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersAmounts = {
  __typename?: 'CustomersAmounts';
  amount: Maybe<CustomersGoogleTypeMoney>;
  labelInEnglish: Maybe<Scalars['String']>;
};

export type CustomersContext = {
  __typename?: 'CustomersContext';
  clientConfigurationId: Maybe<Scalars['Int']>;
  clientName: Maybe<Scalars['String']>;
  countryId: Maybe<Scalars['Int']>;
  storeConfigurationId: Maybe<Scalars['Int']>;
};

export type CustomersGetHandlingDetailsRequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetHandlingDetailsResponse = {
  __typename?: 'CustomersGetHandlingDetailsResponse';
  handlingDetails: Maybe<CustomersGetHandlingDetailsResponseHandlingDetails>;
  handlingDetailsByOrderReference: Maybe<CustomersGetHandlingDetailsResponseHandlingDetails>;
};

export type CustomersGetHandlingDetailsResponseHandlingDetails = {
  __typename?: 'CustomersGetHandlingDetailsResponseHandlingDetails';
  acceptableRetailerLocationIds: Maybe<Array<Maybe<Scalars['CustomersBigInt']>>>;
  delivery: Maybe<CustomersGetHandlingDetailsResponseHandlingDetailsDelivery>;
  pickup: Maybe<CustomersGetHandlingDetailsResponseHandlingDetailsPickup>;
  specialRequirements: Maybe<Array<Maybe<CustomersGetHandlingDetailsResponseHandlingDetailsSpecialRequirement>>>;
};

export type CustomersGetHandlingDetailsResponseHandlingDetailsDelivery = {
  __typename?: 'CustomersGetHandlingDetailsResponseHandlingDetailsDelivery';
  address: Maybe<CustomersGoogleTypePostalAddress>;
  addressId: Maybe<Scalars['CustomersBigInt']>;
  addressType: Maybe<CustomersAddressType>;
  customerManualLatLng: Maybe<CustomersGoogleTypeLatLng>;
  doorCode: Maybe<Scalars['String']>;
  dropoffLocation: Maybe<Scalars['String']>;
  instructions: Maybe<Scalars['String']>;
  latLng: Maybe<CustomersGoogleTypeLatLng>;
  notes: Maybe<Scalars['String']>;
  zoneId: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersGetHandlingDetailsResponseHandlingDetailsPickup = {
  __typename?: 'CustomersGetHandlingDetailsResponseHandlingDetailsPickup';
  address: Maybe<CustomersGoogleTypePostalAddress>;
  addressId: Maybe<Scalars['CustomersBigInt']>;
  instructions: Maybe<Scalars['String']>;
  latLng: Maybe<CustomersGoogleTypeLatLng>;
};

export enum CustomersGetHandlingDetailsResponseHandlingDetailsSpecialRequirement {
  AlcoholCompliant = 'ALCOHOL_COMPLIANT',
  CertifiedDelivery = 'CERTIFIED_DELIVERY',
  DisableShopperAdds = 'DISABLE_SHOPPER_ADDS',
  EbtRetailerLocationConstrained = 'EBT_RETAILER_LOCATION_CONSTRAINED',
  IncludesEbtPayment = 'INCLUDES_EBT_PAYMENT',
  OrderAhead = 'ORDER_AHEAD',
  OrderCreatedWithBigBulky = 'ORDER_CREATED_WITH_BIG_BULKY',
  RequiresShopperBgcProcess_1 = 'REQUIRES_SHOPPER_BGC_PROCESS_1',
  RestrictedOtcCompliant = 'RESTRICTED_OTC_COMPLIANT',
  RxCompliant = 'RX_COMPLIANT',
  RxOnlyCompliant = 'RX_ONLY_COMPLIANT',
  SpecialRequirementUnspecified = 'SPECIAL_REQUIREMENT_UNSPECIFIED',
  UnattendedDelivery = 'UNATTENDED_DELIVERY'
}

export type CustomersGetOrderAmountsSummaryV0RequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetOrderAmountsSummaryV0Response = {
  __typename?: 'CustomersGetOrderAmountsSummaryV0Response';
  orderAmountsSummary: Maybe<CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary>;
  orderAmountsSummaryByOrderReference: Maybe<CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary>;
};

export type CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary = {
  __typename?: 'CustomersGetOrderAmountsSummaryV0ResponseAmountsSummary';
  orderAmounts: Maybe<Array<Maybe<CustomersAmounts>>>;
};

export type CustomersGetOrderLinesRequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetOrderLinesResponse = {
  __typename?: 'CustomersGetOrderLinesResponse';
  orderLines: Maybe<CustomersGetOrderLinesResponseOrderLines>;
  orderLinesByOrderReference: Maybe<CustomersGetOrderLinesResponseOrderLines>;
};

export type CustomersGetOrderLinesResponseOrderLines = {
  __typename?: 'CustomersGetOrderLinesResponseOrderLines';
  lineItems: Maybe<Array<Maybe<CustomersOrderLine>>>;
};

export type CustomersGetOrderRequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetOrderResponse = {
  __typename?: 'CustomersGetOrderResponse';
  order: Maybe<CustomersOrder>;
  ordersByOrderReference: Maybe<CustomersOrder>;
};

export type CustomersGetPaymentMethodSummaryRequestInput = {
  orderReference?: InputMaybe<Scalars['String']>;
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersGetPaymentMethodSummaryResponse = {
  __typename?: 'CustomersGetPaymentMethodSummaryResponse';
  paymentMethodSummariesByOrderReference: Maybe<CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary>;
  paymentMethodSummary: Maybe<CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary>;
};

export type CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary = {
  __typename?: 'CustomersGetPaymentMethodSummaryResponsePaymentMethodSummary';
  paymentMethod: Maybe<Array<Maybe<CustomersGetPaymentMethodSummaryResponsePaymentMethodSummaryPaymentMethod>>>;
};

export type CustomersGetPaymentMethodSummaryResponsePaymentMethodSummaryPaymentMethod = {
  __typename?: 'CustomersGetPaymentMethodSummaryResponsePaymentMethodSummaryPaymentMethod';
  label: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type CustomersGoogleTypeDate = {
  __typename?: 'CustomersGoogleTypeDate';
  day: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export type CustomersGoogleTypeLatLng = {
  __typename?: 'CustomersGoogleTypeLatLng';
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
};

export type CustomersGoogleTypeMoney = {
  __typename?: 'CustomersGoogleTypeMoney';
  currencyCode: Maybe<Scalars['String']>;
  nanos: Maybe<Scalars['Int']>;
  units: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersGoogleTypePostalAddress = {
  __typename?: 'CustomersGoogleTypePostalAddress';
  addressLines: Maybe<Array<Maybe<Scalars['String']>>>;
  administrativeArea: Maybe<Scalars['String']>;
  languageCode: Maybe<Scalars['String']>;
  locality: Maybe<Scalars['String']>;
  organization: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  recipients: Maybe<Array<Maybe<Scalars['String']>>>;
  regionCode: Maybe<Scalars['String']>;
  revision: Maybe<Scalars['Int']>;
  sortingCode: Maybe<Scalars['String']>;
  sublocality: Maybe<Scalars['String']>;
};

export enum CustomersInstacartCustomersSharedV1FulfillmentTypeWrapperFulfillmentType {
  AffordableDelivery = 'AFFORDABLE_DELIVERY',
  Asap = 'ASAP',
  Delivery = 'DELIVERY',
  DeliveryOnly = 'DELIVERY_ONLY',
  DirectToConsumer = 'DIRECT_TO_CONSUMER',
  Eta = 'ETA',
  FiveHour = 'FIVE_HOUR',
  FulfillmentTypeUnspecified = 'FULFILLMENT_TYPE_UNSPECIFIED',
  HyperFast = 'HYPER_FAST',
  LimitedAvailability = 'LIMITED_AVAILABILITY',
  OneHour = 'ONE_HOUR',
  Pickup = 'PICKUP',
  PickupEta = 'PICKUP_ETA',
  PriorityEta = 'PRIORITY_ETA',
  RetailerInstore = 'RETAILER_INSTORE',
  RetailerManaged = 'RETAILER_MANAGED',
  ScanAndPay = 'SCAN_AND_PAY',
  Scheduled = 'SCHEDULED',
  ThreeHour = 'THREE_HOUR',
  TwoHour = 'TWO_HOUR',
  Ultrafast = 'ULTRAFAST',
  VirtualExpress = 'VIRTUAL_EXPRESS',
  VirtualRetailerMembership = 'VIRTUAL_RETAILER_MEMBERSHIP'
}

export type CustomersInstacartCustomersSharedV1PageInfo = {
  __typename?: 'CustomersInstacartCustomersSharedV1PageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Maybe<Scalars['Boolean']>;
};

export type CustomersInstacartCustomersSharedV1PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type CustomersInstacartCustomersSharedV1RequestContextInput = {
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export enum CustomersInstacartCustomersSharedV1ServiceType {
  Delivery = 'DELIVERY',
  Instore = 'INSTORE',
  Pickup = 'PICKUP',
  ScanAndPay = 'SCAN_AND_PAY',
  ServiceTypeUnspecified = 'SERVICE_TYPE_UNSPECIFIED',
  Virtual = 'VIRTUAL'
}

export type CustomersInstacartTypesV1MeasuredQuantity = {
  __typename?: 'CustomersInstacartTypesV1MeasuredQuantity';
  quantity: Maybe<Scalars['Float']>;
  unit: Maybe<CustomersInstacartTypesV1MeasurementUnit>;
};

export type CustomersInstacartTypesV1MeasurementUnit = {
  __typename?: 'CustomersInstacartTypesV1MeasurementUnit';
  costUnit: Maybe<CustomersInstacartTypesV1MeasurementUnitCostUnit>;
  unitCode: Maybe<Scalars['String']>;
};

export enum CustomersInstacartTypesV1MeasurementUnitCostUnit {
  CostUnitUnspecified = 'COST_UNIT_UNSPECIFIED',
  Each = 'EACH',
  FlOz = 'FL_OZ',
  Gallon = 'GALLON',
  Gram = 'GRAM',
  Kg = 'KG',
  Lb = 'LB',
  Liter = 'LITER',
  Ml = 'ML',
  Oz = 'OZ',
  Pint = 'PINT',
  Quart = 'QUART'
}

export type CustomersListOrdersRequestFilterSetDateRangeInput = {
  from?: InputMaybe<Scalars['CustomersDateTime']>;
  to?: InputMaybe<Scalars['CustomersDateTime']>;
};

export type CustomersListOrdersRequestFilterSetInput = {
  addressId?: InputMaybe<Scalars['CustomersBigInt']>;
  creationDateRange?: InputMaybe<CustomersListOrdersRequestFilterSetDateRangeInput>;
  fulfillmentDateRange?: InputMaybe<CustomersListOrdersRequestFilterSetDateRangeInput>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<CustomersListOrdersRequestFilterSetOrderStatusCriteria>>>;
  query?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  serviceType?: InputMaybe<CustomersInstacartCustomersSharedV1ServiceType>;
};

export enum CustomersListOrdersRequestFilterSetOrderStatusCriteria {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  OrderStatusCriteriaUnspecified = 'ORDER_STATUS_CRITERIA_UNSPECIFIED'
}

export type CustomersListOrdersRequestInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  filters?: InputMaybe<CustomersListOrdersRequestFilterSetInput>;
  pagination?: InputMaybe<CustomersInstacartCustomersSharedV1PaginationInput>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
  userId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersListOrdersResponse = {
  __typename?: 'CustomersListOrdersResponse';
  orderSummaries: Maybe<Array<Maybe<CustomersListOrdersResponseOrderSummary>>>;
  orders: Maybe<Array<Maybe<CustomersOrder>>>;
  pageInfo: Maybe<CustomersInstacartCustomersSharedV1PageInfo>;
};

export type CustomersListOrdersResponseOrderSummary = {
  __typename?: 'CustomersListOrdersResponseOrderSummary';
  creationDate: Maybe<Scalars['CustomersDateTime']>;
  externalId: Maybe<Scalars['String']>;
  fulfillmentDate: Maybe<Scalars['CustomersDateTime']>;
  fulfillmentType: Maybe<CustomersInstacartCustomersSharedV1FulfillmentTypeWrapperFulfillmentType>;
  id: Maybe<Scalars['CustomersBigInt']>;
  legacyOrderId: Maybe<Scalars['CustomersBigInt']>;
  orderReference: Maybe<Scalars['String']>;
  retailerLocationId: Maybe<Scalars['CustomersBigInt']>;
  totalAmount: Maybe<CustomersGoogleTypeMoney>;
  userId: Maybe<Scalars['CustomersBigInt']>;
  workflowState: Maybe<CustomersWorkflowState>;
};

export type CustomersOrder = {
  __typename?: 'CustomersOrder';
  businessId: Maybe<Scalars['CustomersBigInt']>;
  checkoutRetailerLocationId: Maybe<Scalars['CustomersBigInt']>;
  clientConfigurationId: Maybe<Scalars['CustomersBigInt']>;
  closestRetailerLocationId: Maybe<Scalars['CustomersBigInt']>;
  communicationLocale: Maybe<Scalars['String']>;
  confirmedAt: Maybe<Scalars['CustomersDateTime']>;
  countryId: Maybe<Scalars['CustomersBigInt']>;
  creationDate: Maybe<Scalars['CustomersDateTime']>;
  customerOrderNumber: Maybe<Scalars['CustomersBigInt']>;
  deliveredAt: Maybe<Scalars['CustomersDateTime']>;
  deliveryStartedAt: Maybe<Scalars['CustomersDateTime']>;
  expressSubscriptionId: Maybe<Scalars['CustomersBigInt']>;
  fulfillmentType: Maybe<CustomersInstacartCustomersSharedV1FulfillmentTypeWrapperFulfillmentType>;
  fulfillmentWindow: Maybe<CustomersOrderOrderFulfillmentWindow>;
  id: Maybe<Scalars['CustomersBigInt']>;
  legacyOrderId: Maybe<Scalars['CustomersBigInt']>;
  loyaltyNumber: Maybe<Scalars['String']>;
  orderReference: Maybe<Scalars['String']>;
  plannedDeliveredAt: Maybe<Scalars['CustomersDateTime']>;
  plannedDeliveryStartedAt: Maybe<Scalars['CustomersDateTime']>;
  retailerExternalOrderId: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['CustomersBigInt']>;
  retailerOriginatedData: Maybe<CustomersOrderRetailerOriginatedData>;
  retailerReceiptBarcodeValue: Maybe<Scalars['String']>;
  retailerReferenceId: Maybe<Scalars['String']>;
  serviceOptionId: Maybe<Scalars['CustomersBigInt']>;
  shoppedRetailerLocationId: Maybe<Scalars['CustomersBigInt']>;
  shopperOriginatedData: Maybe<CustomersOrderShopperOriginatedData>;
  storeConfigurationId: Maybe<Scalars['CustomersBigInt']>;
  userBirthday: Maybe<CustomersGoogleTypeDate>;
  userId: Maybe<Scalars['CustomersBigInt']>;
  workflowState: Maybe<CustomersWorkflowState>;
  zoneId: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersOrderItem = {
  __typename?: 'CustomersOrderItem';
  alcoholic: Maybe<Scalars['Boolean']>;
  externalData: Maybe<CustomersOrderItemExternalData>;
  imageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  productCategory: Maybe<CustomersProductCategory>;
  productCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  productId: Maybe<Scalars['CustomersBigInt']>;
  quantity: Maybe<CustomersInstacartTypesV1MeasuredQuantity>;
  retailerLookupCode: Maybe<Scalars['String']>;
  sizeDetails: Maybe<Scalars['String']>;
  unitPrice: Maybe<CustomersGoogleTypeMoney>;
};

export type CustomersOrderItemExternalData = {
  __typename?: 'CustomersOrderItemExternalData';
  lineNum: Maybe<Scalars['String']>;
  retailerRrc: Maybe<Scalars['String']>;
  retailerUpc: Maybe<Scalars['String']>;
  scanCode: Maybe<Scalars['String']>;
};

export type CustomersOrderLine = {
  __typename?: 'CustomersOrderLine';
  addedByDriverId: Maybe<Scalars['CustomersBigInt']>;
  allowedSubstitution: Maybe<Scalars['Boolean']>;
  creationDate: Maybe<Scalars['CustomersDateTime']>;
  customerOrderedPrice: Maybe<CustomersGoogleTypeMoney>;
  customerPrice: Maybe<CustomersGoogleTypeMoney>;
  customerSelectedReplacement: Maybe<CustomersOrderItem>;
  fullPrice: Maybe<CustomersGoogleTypeMoney>;
  id: Maybe<Scalars['CustomersBigInt']>;
  item: Maybe<CustomersOrderItem>;
  obfuscatedId: Maybe<Scalars['String']>;
  orderedQuantity: Maybe<CustomersInstacartTypesV1MeasuredQuantity>;
  replacementPolicy: Maybe<CustomersReplacementPolicy>;
  specialInstructions: Maybe<Scalars['String']>;
  status: Maybe<CustomersStatus>;
  substitute: Maybe<CustomersOrderItem>;
  substitutionStatus: Maybe<CustomersSubstitutionStatus>;
};

export type CustomersOrderOrderFulfillmentWindow = {
  __typename?: 'CustomersOrderOrderFulfillmentWindow';
  windowEnd: Maybe<Scalars['CustomersDateTime']>;
  windowStart: Maybe<Scalars['CustomersDateTime']>;
};

export type CustomersOrderRetailerOriginatedData = {
  __typename?: 'CustomersOrderRetailerOriginatedData';
  bagLabel: Maybe<Scalars['String']>;
};

export type CustomersOrderShopperOriginatedData = {
  __typename?: 'CustomersOrderShopperOriginatedData';
  customerBagCount: Maybe<Scalars['Int']>;
};

export enum CustomersOrdersSortByDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  DirectionUnspecified = 'DIRECTION_UNSPECIFIED'
}

export type CustomersOrdersSortByInput = {
  direction?: InputMaybe<CustomersOrdersSortByDirection>;
  name?: InputMaybe<CustomersOrdersSortByOrdersAttributes>;
};

export enum CustomersOrdersSortByOrdersAttributes {
  OrdersAttributesUnspecified = 'ORDERS_ATTRIBUTES_UNSPECIFIED',
  OrderDeliveryId = 'ORDER_DELIVERY_ID',
  WindowEndsAt = 'WINDOW_ENDS_AT'
}

export type CustomersProductCategory = {
  __typename?: 'CustomersProductCategory';
  l1Category: Maybe<Scalars['String']>;
  l1CategoryId: Maybe<Scalars['CustomersBigInt']>;
  l2Category: Maybe<Scalars['String']>;
  l2CategoryId: Maybe<Scalars['CustomersBigInt']>;
  l3Category: Maybe<Scalars['String']>;
  l3CategoryId: Maybe<Scalars['CustomersBigInt']>;
  l4Category: Maybe<Scalars['String']>;
  l4CategoryId: Maybe<Scalars['CustomersBigInt']>;
  l5Category: Maybe<Scalars['String']>;
  l5CategoryId: Maybe<Scalars['CustomersBigInt']>;
  l6Category: Maybe<Scalars['String']>;
  l6CategoryId: Maybe<Scalars['CustomersBigInt']>;
  productCategory: Maybe<Scalars['String']>;
  productCategoryId: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersQueryOrderReferenceRequestInput = {
  orderDeliveryId?: InputMaybe<Scalars['CustomersBigInt']>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersQueryOrderReferenceResponse = {
  __typename?: 'CustomersQueryOrderReferenceResponse';
  orderReference: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['CustomersBigInt']>;
};

export type CustomersQueryOrdersRequestInput = {
  ordersSortBy?: InputMaybe<CustomersOrdersSortByInput>;
  pagination?: InputMaybe<CustomersInstacartCustomersSharedV1PaginationInput>;
  scenarioActivePickupOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActivePickupOrdersAtRetailerLocationInput>;
  scenarioActiveScanAndPayOrders?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveScanAndPayOrdersInput>;
  scenarioActiveShopperFulfillDeliveryOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveShopperFulfillDeliveryOrdersAtRetailerLocationInput>;
  scenarioActiveStoreManagerOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveStoreManagerOrdersAtRetailerLocationInput>;
  scenarioActiveUltrafastOrdersAtRetailerLocation?: InputMaybe<CustomersQueryOrdersRequestScenarioActiveUltrafastOrdersAtRetailerLocationInput>;
  scenarioCompletedScanAndPayOrders?: InputMaybe<CustomersQueryOrdersRequestScenarioCompletedScanAndPayOrdersInput>;
  scenarioEnterpriseRppOrdersByCreationDate?: InputMaybe<CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByCreationDateInput>;
  scenarioEnterpriseRppOrdersByFulfillmentDate?: InputMaybe<CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByFulfillmentDateInput>;
  scenarioEnterpriseRppOrdersByOrderIdentifier?: InputMaybe<CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByOrderIdentifierInput>;
};

export type CustomersQueryOrdersRequestScenarioActivePickupOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
};

export type CustomersQueryOrdersRequestScenarioActiveScanAndPayOrdersInput = {
  createdAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersQueryOrdersRequestScenarioActiveShopperFulfillDeliveryOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationZoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
};

export type CustomersQueryOrdersRequestScenarioActiveStoreManagerOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationZoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
};

export type CustomersQueryOrdersRequestScenarioActiveUltrafastOrdersAtRetailerLocationInput = {
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationZoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
};

export type CustomersQueryOrdersRequestScenarioCompletedScanAndPayOrdersInput = {
  createdAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationId?: InputMaybe<Scalars['CustomersBigInt']>;
};

export type CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByCreationDateInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  creationDateRange?: InputMaybe<CustomersTimestampRangeInput>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<CustomersRppOrderStatusCriteria>>>;
  orderType?: InputMaybe<CustomersRppOrderType>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
};

export type CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByFulfillmentDateInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<CustomersRppOrderStatusCriteria>>>;
  orderType?: InputMaybe<CustomersRppOrderType>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['CustomersBigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
  windowEndsAtRange?: InputMaybe<CustomersTimestampRangeInput>;
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['CustomersBigInt']>>>;
};

export type CustomersQueryOrdersRequestScenarioEnterpriseRppOrdersByOrderIdentifierInput = {
  adminRequestContext?: InputMaybe<CustomersAdminRequestContextInput>;
  orderIdentifier?: InputMaybe<Scalars['String']>;
  requestContext?: InputMaybe<CustomersInstacartCustomersSharedV1RequestContextInput>;
};

export type CustomersQueryOrdersResponse = {
  __typename?: 'CustomersQueryOrdersResponse';
  orders: Maybe<Array<Maybe<CustomersOrder>>>;
  pageInfo: Maybe<CustomersInstacartCustomersSharedV1PageInfo>;
};

export enum CustomersReplacementPolicy {
  NoReplacements = 'NO_REPLACEMENTS',
  ReplacementPolicyUnspecified = 'REPLACEMENT_POLICY_UNSPECIFIED',
  ShoppersChoice = 'SHOPPERS_CHOICE',
  UsersChoice = 'USERS_CHOICE'
}

export enum CustomersRppOrderStatusCriteria {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Delivering = 'DELIVERING',
  New = 'NEW',
  Processing = 'PROCESSING',
  ReadyForPickup = 'READY_FOR_PICKUP',
  RppOrderStatusCriteriaUnspecified = 'RPP_ORDER_STATUS_CRITERIA_UNSPECIFIED',
  Shopping = 'SHOPPING'
}

export enum CustomersRppOrderType {
  Delivery = 'DELIVERY',
  LastMileDelivery = 'LAST_MILE_DELIVERY',
  Pickup = 'PICKUP',
  RppOrderTypeUnspecified = 'RPP_ORDER_TYPE_UNSPECIFIED'
}

export enum CustomersStatus {
  Found = 'FOUND',
  Pending = 'PENDING',
  Replaced = 'REPLACED',
  StatusUnspecified = 'STATUS_UNSPECIFIED',
  ToRefund = 'TO_REFUND'
}

export enum CustomersSubstitutionStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  SubstitutionStatusUnspecified = 'SUBSTITUTION_STATUS_UNSPECIFIED'
}

export type CustomersTimestampRangeInput = {
  from?: InputMaybe<Scalars['CustomersDateTime']>;
  to?: InputMaybe<Scalars['CustomersDateTime']>;
};

export enum CustomersWorkflowState {
  Acknowledged = 'ACKNOWLEDGED',
  AcknowledgedForDelivery = 'ACKNOWLEDGED_FOR_DELIVERY',
  AcknowledgedForRunning = 'ACKNOWLEDGED_FOR_RUNNING',
  AtStore = 'AT_STORE',
  BagsCheck = 'BAGS_CHECK',
  BagsVerified = 'BAGS_VERIFIED',
  BrandNew = 'BRAND_NEW',
  Canceled = 'CANCELED',
  Checkout = 'CHECKOUT',
  CustomerSamples = 'CUSTOMER_SAMPLES',
  Delivered = 'DELIVERED',
  Delivering = 'DELIVERING',
  Notified = 'NOTIFIED',
  PendingIdentityVerification = 'PENDING_IDENTITY_VERIFICATION',
  PendingReschedule = 'PENDING_RESCHEDULE',
  Picking = 'PICKING',
  ReceiptSanityCheck = 'RECEIPT_SANITY_CHECK',
  Returning = 'RETURNING',
  RunnerAwaiting = 'RUNNER_AWAITING',
  RunnerNotFound = 'RUNNER_NOT_FOUND',
  RxCheckout = 'RX_CHECKOUT',
  RxPicking = 'RX_PICKING',
  Staged = 'STAGED',
  StagedForCbt = 'STAGED_FOR_CBT',
  StagedForDelivery = 'STAGED_FOR_DELIVERY',
  StagingForCbt = 'STAGING_FOR_CBT',
  VerifyingReplacements = 'VERIFYING_REPLACEMENTS',
  WaitingForDelivery = 'WAITING_FOR_DELIVERY',
  WorkflowStateUnspecified = 'WORKFLOW_STATE_UNSPECIFIED'
}

export type DataEntryItem = {
  __typename?: 'DataEntryItem';
  deiId: Maybe<Scalars['String']>;
};

export type DataItem = {
  __typename?: 'DataItem';
  groupByName: Maybe<Scalars['String']>;
  metricName: Maybe<Scalars['String']>;
  value: Maybe<InsightsPortalValue>;
};

export type DataQuery = {
  __typename?: 'DataQuery';
  resourceDataQuery: Maybe<ResourceDataQuery>;
};

export type DataQueryInput = {
  resourceDataQuery?: InputMaybe<ResourceDataQueryInput>;
};

/** Attributes for a date range filter */
export type DateRange = {
  /** End date of date range */
  endDate: Scalars['ISO8601Date'];
  /** Start date of date range */
  startDate: Scalars['ISO8601Date'];
};

export type DateRangeInput = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type DayOfWeekInput = {
  /** list of days within the week */
  days: Array<Scalars['String']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of DayOfWeekVisibilityConditionCreate */
export type DayOfWeekVisibilityConditionCreatePayload = {
  __typename?: 'DayOfWeekVisibilityConditionCreatePayload';
  /** The newly created express_membership visibility condition */
  dayOfWeekVisibilityCondition: ContentManagementVisibilityConditionDayOfWeek;
};

/** Autogenerated return type of DayOfWeekVisibilityConditionUpdate */
export type DayOfWeekVisibilityConditionUpdatePayload = {
  __typename?: 'DayOfWeekVisibilityConditionUpdatePayload';
  /** The newly created shopped_at_retailer visibility condition */
  dayOfWeekVisibilityCondition: ContentManagementVisibilityConditionDayOfWeek;
};

export type DeleteApiSettingRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteApiSettingResponse = {
  __typename?: 'DeleteApiSettingResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteCollectionRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteCollectionResponse = {
  __typename?: 'DeleteCollectionResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteConnectRetailerCallbackConfigurationRequestInput = {
  callbackConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteConnectRetailerCallbackConfigurationResponse = {
  __typename?: 'DeleteConnectRetailerCallbackConfigurationResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteContentPageRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteContentPageResponse = {
  __typename?: 'DeleteContentPageResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type DeleteContentPageVersionRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteContentPageVersionResponse = {
  __typename?: 'DeleteContentPageVersionResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type DeleteImageRequestInput = {
  imageId?: InputMaybe<Scalars['Int']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteImageResponse = {
  __typename?: 'DeleteImageResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type DeleteInventoryFileMappingRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteInventoryFileMappingResponse = {
  __typename?: 'DeleteInventoryFileMappingResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteInvfMappingRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteInvfMappingResponse = {
  __typename?: 'DeleteInvfMappingResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteLaunchOnboardingWarehouseLocationRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLaunchOnboardingWarehouseLocationResponse = {
  __typename?: 'DeleteLaunchOnboardingWarehouseLocationResponse';
  launchOnboarding: Maybe<LaunchOnboarding>;
};

export type DeleteLayoutVariantRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLayoutVariantResponse = {
  __typename?: 'DeleteLayoutVariantResponse';
  response: Maybe<Scalars['Boolean']>;
};

export type DeleteLinkAutosuggestionAdminParametersInput = {
  linkId?: InputMaybe<Scalars['BigInt']>;
  linkName?: InputMaybe<Scalars['String']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLinkAutosuggestionAdminRequestInput = {
  parameters?: InputMaybe<DeleteLinkAutosuggestionAdminParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteLinkAutosuggestionAdminResponse = {
  __typename?: 'DeleteLinkAutosuggestionAdminResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteNavigationNodeRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteNavigationNodeResponse = {
  __typename?: 'DeleteNavigationNodeResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteNavigationRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteNavigationResponse = {
  __typename?: 'DeleteNavigationResponse';
  response: Maybe<Scalars['Boolean']>;
};

export type DeletePlacementRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeletePlacementResponse = {
  __typename?: 'DeletePlacementResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type DeleteRetailerCollectionRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteRetailerCollectionResponse = {
  __typename?: 'DeleteRetailerCollectionResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type DeleteSlotCreativeRequestInput = {
  creativeId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteSlotCreativeResponse = {
  __typename?: 'DeleteSlotCreativeResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type DeleteSlotRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DeleteSlotResponse = {
  __typename?: 'DeleteSlotResponse';
  _: Maybe<Scalars['Boolean']>;
};

export enum DeviceTypeEnum {
  /** Phone plan */
  Phone = 'phone',
  /** Wi-Fi */
  Wifi = 'wifi'
}

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DisablePlacementContentRequestInput = {
  adminContext?: InputMaybe<PlacementAdminContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DisablePlacementContentResponse = {
  __typename?: 'DisablePlacementContentResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type DisableThemePageRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type DisableThemePageResponse = {
  __typename?: 'DisableThemePageResponse';
  _: Maybe<Scalars['Boolean']>;
};

export type DiscountDiscountablesData = {
  __typename?: 'DiscountDiscountablesData';
  appliedAmount: Maybe<GoogleTypeMoney>;
  discountableId: Maybe<Scalars['BigInt']>;
  discountableType: Maybe<DiscountDiscountablesDataDiscountableType>;
  id: Maybe<Scalars['BigInt']>;
  offerId: Maybe<Scalars['BigInt']>;
  orderId: Maybe<Scalars['BigInt']>;
};

export enum DiscountDiscountablesDataDiscountableType {
  BasketLevelOffer = 'BASKET_LEVEL_OFFER',
  CustomerFeeOffer = 'CUSTOMER_FEE_OFFER',
  DiscountableTypeUnspecified = 'DISCOUNTABLE_TYPE_UNSPECIFIED',
  ItemLevelOffer = 'ITEM_LEVEL_OFFER'
}

export enum Discoverability {
  AdminOnly = 'ADMIN_ONLY',
  Browse = 'BROWSE',
  CouponCode = 'COUPON_CODE',
  Crm = 'CRM',
  DirectLink = 'DIRECT_LINK',
  DiscoverabilityUnknown = 'DISCOVERABILITY_UNKNOWN'
}

export type DismissBusinessIdentication = {
  __typename?: 'DismissBusinessIdentication';
  id: Maybe<Scalars['BigInt']>;
  source: Maybe<BusinessDerivedAttributesSourceType>;
};

export type DismissBusinessIdenticationInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<BusinessDerivedAttributesSourceType>;
};

export type DoNotNavigate = {
  __typename?: 'DoNotNavigate';
  recordClickAttempt: Maybe<Scalars['Boolean']>;
};

export type DoNotNavigateInput = {
  recordClickAttempt?: InputMaybe<Scalars['Boolean']>;
};

export type DomainConfiguration = {
  __typename?: 'DomainConfiguration';
  ads: Maybe<AdsSchema>;
  branding: Maybe<BrandingSchema>;
  catalog: Maybe<CatalogSchema>;
  checkout: Maybe<CheckoutSchema>;
  icplus: Maybe<IcPlusSchema>;
  idsTheme: Maybe<IdsThemeSchema>;
  landing: Maybe<LandingSchema>;
  loyalty: Maybe<LoyaltySchema>;
  offers: Maybe<OffersSchema>;
  orders: Maybe<OrdersSchema>;
  payments: Maybe<PaymentsSchema>;
  pricing: Maybe<PricingSchema>;
  theme: Maybe<ThemeSchema>;
};

export type DownloadCatalogSearchResultsRequestInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  costUnitType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  creationDate?: InputMaybe<DateRangeInput>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  isParWeighted?: InputMaybe<Scalars['Boolean']>;
  itemFilename?: InputMaybe<Scalars['String']>;
  itemsOnPromoDateRange?: InputMaybe<DateRangeInput>;
  itemsOnSaleDateRange?: InputMaybe<DateRangeInput>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  loyaltyPricing?: InputMaybe<Scalars['Boolean']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  primaryImageUrl?: InputMaybe<Scalars['Boolean']>;
  productAvailability?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productFilename?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  query?: InputMaybe<Scalars['String']>;
  requestItemData?: InputMaybe<Scalars['Boolean']>;
  requestProductData?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerReferenceCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  snapEbtEligible?: InputMaybe<Scalars['Boolean']>;
  storeBrandProduct?: InputMaybe<Scalars['Boolean']>;
};

export type DownloadCatalogSearchResultsResponse = {
  __typename?: 'DownloadCatalogSearchResultsResponse';
  filename: Maybe<Scalars['String']>;
};

export type DuplicateInstacartCollectionRequestInput = {
  contentType?: InputMaybe<RetailerCollectionContentType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  position?: InputMaybe<Scalars['Int']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  sourceCollectionId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<RetailerCollectionStatus>;
  storeConfigurations?: InputMaybe<Array<InputMaybe<RetailerCollectionStoreConfigurationInput>>>;
  surfaces?: InputMaybe<Array<InputMaybe<RetailerCollectionSurface>>>;
  tileImageUrl?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<RetailerCollectionTranslationInput>>>;
};

export type DuplicateInstacartCollectionResponse = {
  __typename?: 'DuplicateInstacartCollectionResponse';
  instacartCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type DuplicateRetailerCollectionRequestInput = {
  contentType?: InputMaybe<RetailerCollectionContentType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  position?: InputMaybe<Scalars['Int']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  sourceCollectionId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<RetailerCollectionStatus>;
  storeConfigurations?: InputMaybe<Array<InputMaybe<RetailerCollectionStoreConfigurationInput>>>;
  surfaces?: InputMaybe<Array<InputMaybe<RetailerCollectionSurface>>>;
  tileImageUrl?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<RetailerCollectionTranslationInput>>>;
};

export type DuplicateRetailerCollectionResponse = {
  __typename?: 'DuplicateRetailerCollectionResponse';
  retailerCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type EbpAnalyticsRequestInput = {
  dateRange?: InputMaybe<InstacartAdsSharedV1DateRangeInput>;
  entity?: InputMaybe<Entity>;
  entityId?: InputMaybe<EntityIdInput>;
  metric?: InputMaybe<Metric>;
};

export type EbpAnalyticsResponse = {
  __typename?: 'EbpAnalyticsResponse';
  analytics: Maybe<Analytics>;
};

/** Autogenerated return type of EmailVerify */
export type EmailVerifyPayload = {
  __typename?: 'EmailVerifyPayload';
  /** Account request received successfully */
  success: Scalars['Boolean'];
};

export type EnterpriseAccount = {
  __typename?: 'EnterpriseAccount';
  /** Whether or not the user is fully set up */
  accountStateValid: Maybe<Scalars['Boolean']>;
  /** Whether account must use SSO/auth service for authentication */
  authServiceRequired: Scalars['Boolean'];
  /** The associated authentication */
  authentication: Maybe<AuthenticationInterface>;
  /** The external unique identifier for the enterprise account */
  canonicalId: Scalars['ID'];
  /** When the account was created */
  createdAt: Scalars['Time'];
  /** Whether account is active */
  disabled: Scalars['Boolean'];
  /** The email associated with the account */
  email: Maybe<Scalars['String']>;
  /** The feature experiments associated with the account */
  featureExperiments: Array<FeatureExperiment>;
  /** The feature toggles associated with the account */
  featureToggles: Array<FeatureToggle>;
  /**
   * The flippers associated with the account
   * @deprecated Use feature_toggles instead
   */
  flippers: AccessControlFlippers;
  /** Given name of user */
  givenName: Maybe<Scalars['String']>;
  /** The internal unique identifier for the enterprise account */
  id: Scalars['ID'];
  /** The associated internal access configuration for the account */
  internalAccessConfiguration: EnterpriseInternalAccessConfiguration;
  /** Whether account is an internal account */
  isInternal: Scalars['Boolean'];
  /** When MFA was disabled for the account */
  mfaDeactivatedAt: Maybe<Scalars['Time']>;
  /** Whether account has MFA enabled */
  mfaEnabled: Scalars['Boolean'];
  /** When MFA was enabled for the account */
  mfaEnabledAt: Maybe<Scalars['Time']>;
  /** The permissions associated with this account */
  permissions: Array<EnterprisePermission>;
  /** Phone number for the user */
  phone: Maybe<Scalars['String']>;
  /** The policies associated with the account */
  policies: AccessControlPolicies;
  /** The roles associated with this account */
  roles: Array<EnterpriseRole>;
  /** Surname of user */
  surname: Maybe<Scalars['String']>;
  /** The title of the user */
  title: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EnterpriseAccountAccountSetup */
export type EnterpriseAccountAccountSetupPayload = {
  __typename?: 'EnterpriseAccountAccountSetupPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

/** Autogenerated return type of EnterpriseAccountAuthenticateEmailPassword */
export type EnterpriseAccountAuthenticateEmailPasswordPayload = {
  __typename?: 'EnterpriseAccountAuthenticateEmailPasswordPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

/** Autogenerated return type of EnterpriseAccountAuthenticatePreflight */
export type EnterpriseAccountAuthenticatePreflightPayload = {
  __typename?: 'EnterpriseAccountAuthenticatePreflightPayload';
  canRequestAccount: Scalars['Boolean'];
  useAuthService: Scalars['Boolean'];
};

/** The connection type for EnterpriseAccount. */
export type EnterpriseAccountConnection = {
  __typename?: 'EnterpriseAccountConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<EnterpriseAccountEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<EnterpriseAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of EnterpriseAccountCreate */
export type EnterpriseAccountCreatePayload = {
  __typename?: 'EnterpriseAccountCreatePayload';
  /** The newly created account object */
  enterpriseAccount: EnterpriseAccount;
};

/** An edge in a connection. */
export type EnterpriseAccountEdge = {
  __typename?: 'EnterpriseAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<EnterpriseAccount>;
};

/** Autogenerated return type of EnterpriseAccountMfaDeactivate */
export type EnterpriseAccountMfaDeactivatePayload = {
  __typename?: 'EnterpriseAccountMfaDeactivatePayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountMfaEnable */
export type EnterpriseAccountMfaEnablePayload = {
  __typename?: 'EnterpriseAccountMfaEnablePayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountMfaReset */
export type EnterpriseAccountMfaResetPayload = {
  __typename?: 'EnterpriseAccountMfaResetPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountPartnerOnboardingRequestAccountSetupEmail */
export type EnterpriseAccountPartnerOnboardingRequestAccountSetupEmailPayload = {
  __typename?: 'EnterpriseAccountPartnerOnboardingRequestAccountSetupEmailPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountRequestAccountSetupEmail */
export type EnterpriseAccountRequestAccountSetupEmailPayload = {
  __typename?: 'EnterpriseAccountRequestAccountSetupEmailPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountRequestEmailVerification */
export type EnterpriseAccountRequestEmailVerificationPayload = {
  __typename?: 'EnterpriseAccountRequestEmailVerificationPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountRequestPasswordReset */
export type EnterpriseAccountRequestPasswordResetPayload = {
  __typename?: 'EnterpriseAccountRequestPasswordResetPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of EnterpriseAccountResetPassword */
export type EnterpriseAccountResetPasswordPayload = {
  __typename?: 'EnterpriseAccountResetPasswordPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

/** Autogenerated return type of EnterpriseAccountSetDisabled */
export type EnterpriseAccountSetDisabledPayload = {
  __typename?: 'EnterpriseAccountSetDisabledPayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountSignUpEmailPassword */
export type EnterpriseAccountSignUpEmailPasswordPayload = {
  __typename?: 'EnterpriseAccountSignUpEmailPasswordPayload';
  /** The newly created Account */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountUpdate */
export type EnterpriseAccountUpdatePayload = {
  __typename?: 'EnterpriseAccountUpdatePayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountUpdateRoles */
export type EnterpriseAccountUpdateRolesPayload = {
  __typename?: 'EnterpriseAccountUpdateRolesPayload';
  /** The updated account object */
  enterpriseAccount: EnterpriseAccount;
};

/** Autogenerated return type of EnterpriseAccountVerifyAccountSetupToken */
export type EnterpriseAccountVerifyAccountSetupTokenPayload = {
  __typename?: 'EnterpriseAccountVerifyAccountSetupTokenPayload';
  /** Email of the verified account */
  email: Scalars['String'];
  /** List of partners with partner id and name */
  partners: Array<Partner>;
};

/** Autogenerated return type of EnterpriseAccountVerifyEmail */
export type EnterpriseAccountVerifyEmailPayload = {
  __typename?: 'EnterpriseAccountVerifyEmailPayload';
  /** The AuthenticationToken token to be used for future requests */
  accessToken: AccessToken;
};

export type EnterpriseAuthenticationEmailPassword = AuthenticationInterface & {
  __typename?: 'EnterpriseAuthenticationEmailPassword';
  /** Unique authentication identifier */
  authenticationIdentifier: Scalars['ID'];
  /** Whether or not the email has been verified */
  emailVerified: Scalars['Boolean'];
  /** Whether or not the password has been set */
  passwordSet: Scalars['Boolean'];
};

export type EnterpriseFeatureTour = {
  __typename?: 'EnterpriseFeatureTour';
  lastShownAt: Maybe<Scalars['String']>;
  /** The name of the feature tour */
  name: Scalars['String'];
  /** The current completion status of the feature tour */
  status: Scalars['String'];
};

export type EnterpriseInternalAccessConfiguration = {
  __typename?: 'EnterpriseInternalAccessConfiguration';
  /** The associated assigned partners */
  assignedPartners: Array<Partner>;
  /** The associated assigned retailer locations */
  assignedRetailerLocations: Array<RetailerLocation>;
  /** The associated assigned retailers */
  assignedRetailers: Array<Retailer>;
  /** Whether access is granted for every partner, retailer and location */
  hasAllPartnersFullAccess: Scalars['Boolean'];
  /** Whether access is granted for every retailer and location for partners assigned */
  hasFullPartnerAccess: Scalars['Boolean'];
  /** Whether access is granted for every location for retailers assigned */
  hasFullRetailerAccess: Scalars['Boolean'];
  /** Whether access is not granted to any partner, retailer or location */
  hasNothingAccessible: Scalars['Boolean'];
};

export type EnterprisePermission = {
  __typename?: 'EnterprisePermission';
  /** A description of the permission */
  description: Maybe<Scalars['String']>;
  /** Indicates if the Permission can be used to assign to a Retailer role */
  isRetailerRoleAssignable: Scalars['Boolean'];
  /** A unique identifier for the permission */
  slug: Scalars['ID'];
};

export type EnterpriseRole = {
  __typename?: 'EnterpriseRole';
  /** The number of accounts currently attached to the role */
  attachedAccounts: Maybe<Scalars['Int']>;
  /** A description of the role */
  description: Maybe<Scalars['String']>;
  /** The role ID */
  id: Scalars['ID'];
  /** Whether this role can only be assigned to internal IC employees */
  internalOnly: Scalars['Boolean'];
  /** The name of the role */
  name: Scalars['String'];
  partnerId: Maybe<Scalars['ID']>;
  /** The permissions associated with this role */
  permissions: Array<EnterprisePermission>;
  retailerId: Maybe<Scalars['ID']>;
  /** A unique identifier for the role */
  slug: Scalars['ID'];
  updatedAt: Scalars['Time'];
};

export type EnterpriseWelcomeModal = {
  __typename?: 'EnterpriseWelcomeModal';
  /** slug identifying type of welcome modal to be shown. */
  slug: Scalars['String'];
};

export enum Entity {
  Product = 'PRODUCT',
  SellSheet = 'SELL_SHEET'
}

export type EntityId = {
  __typename?: 'EntityId';
  dataEntryItem: Maybe<DataEntryItem>;
  itemId: Maybe<ItemId>;
  localeProduct: Maybe<LocaleProduct>;
  outsourceDataEntryItem: Maybe<OutsourceDataEntryItem>;
  productId: Maybe<ProductId>;
  recipeEntryItem: Maybe<RecipeEntryItem>;
  retailerProductId: Maybe<RetailerProductId>;
  upc: Maybe<Upc>;
};

export type EntityIdInput = {
  int64Id?: InputMaybe<Scalars['BigInt']>;
  itemId?: InputMaybe<ItemIdInput>;
  productId?: InputMaybe<ProductIdInput>;
  retailerProductId?: InputMaybe<RetailerProductIdInput>;
  stringId?: InputMaybe<Scalars['String']>;
  upc?: InputMaybe<UpcInput>;
};

export enum EntityStatus {
  EntityStatusCancelled = 'ENTITY_STATUS_CANCELLED',
  EntityStatusCompleted = 'ENTITY_STATUS_COMPLETED',
  EntityStatusDeduplicated = 'ENTITY_STATUS_DEDUPLICATED',
  EntityStatusEntityNotFound = 'ENTITY_STATUS_ENTITY_NOT_FOUND',
  EntityStatusFailed = 'ENTITY_STATUS_FAILED',
  EntityStatusFailedUncaught = 'ENTITY_STATUS_FAILED_UNCAUGHT',
  EntityStatusInProgress = 'ENTITY_STATUS_IN_PROGRESS',
  EntityStatusNonNormalizedUpc = 'ENTITY_STATUS_NON_NORMALIZED_UPC',
  EntityStatusStarted = 'ENTITY_STATUS_STARTED',
  EntityStatusSubmittedToFixService = 'ENTITY_STATUS_SUBMITTED_TO_FIX_SERVICE',
  EntityStatusUnknown = 'ENTITY_STATUS_UNKNOWN',
  EntityStatusUnknownError = 'ENTITY_STATUS_UNKNOWN_ERROR',
  EntityStatusUpcCollision = 'ENTITY_STATUS_UPC_COLLISION',
  EntityStatusWarning = 'ENTITY_STATUS_WARNING',
  EntityStatusWontDo = 'ENTITY_STATUS_WONT_DO'
}

export enum EntityType {
  EntityTypeItemIds = 'ENTITY_TYPE_ITEM_IDS',
  EntityTypeProductIds = 'ENTITY_TYPE_PRODUCT_IDS',
  EntityTypeRetailerProductIds = 'ENTITY_TYPE_RETAILER_PRODUCT_IDS',
  EntityTypeUnknown = 'ENTITY_TYPE_UNKNOWN',
  EntityTypeUpcs = 'ENTITY_TYPE_UPCS'
}

export enum ErrorReason {
  DraftErrorConflict = 'DRAFT_ERROR_CONFLICT',
  DraftErrorNotFound = 'DRAFT_ERROR_NOT_FOUND',
  DraftErrorUnexpectedState = 'DRAFT_ERROR_UNEXPECTED_STATE',
  ErrorEnumUnknown = 'ERROR_ENUM_UNKNOWN',
  ErrorReasonBadRequest = 'ERROR_REASON_BAD_REQUEST',
  ErrorReasonModificationNotPermitted = 'ERROR_REASON_MODIFICATION_NOT_PERMITTED',
  ErrorReasonUnknown = 'ERROR_REASON_UNKNOWN'
}

export enum ExperimentMethod {
  Assign = 'ASSIGN',
  ExperimentMethodUnspecified = 'EXPERIMENT_METHOD_UNSPECIFIED',
  Lookup = 'LOOKUP',
  Preview = 'PREVIEW'
}

export type ExperimentVariantInput = {
  /** The method of the experiment */
  experimentMethod: PlacementExperimentMethodEnum;
  /** The name of the experiment */
  experimentName: Scalars['String'];
  /** The name of the experiment variant */
  experimentVariant: Scalars['String'];
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of ExperimentVariantVisibilityConditionCreate */
export type ExperimentVariantVisibilityConditionCreatePayload = {
  __typename?: 'ExperimentVariantVisibilityConditionCreatePayload';
  /** The newly created ExperimentVariant visibility condition */
  experimentVariantVisibilityCondition: ContentManagementVisibilityConditionExperimentVariant;
};

/** Autogenerated return type of ExperimentVariantVisibilityConditionUpdate */
export type ExperimentVariantVisibilityConditionUpdatePayload = {
  __typename?: 'ExperimentVariantVisibilityConditionUpdatePayload';
  /** The newly updated ExperimentVariant visibility condition */
  experimentVariantVisibilityCondition: ContentManagementVisibilityConditionExperimentVariant;
};

export type ExpressMembershipInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** Whether the user should be an express member */
  membershipStatus: Scalars['Boolean'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ExpressMembershipVisibilityConditionCreate */
export type ExpressMembershipVisibilityConditionCreatePayload = {
  __typename?: 'ExpressMembershipVisibilityConditionCreatePayload';
  /** The newly created express_membership visibility condition */
  expressMembershipVisibilityCondition: ContentManagementVisibilityConditionExpressMembership;
};

/** Autogenerated return type of ExpressMembershipVisibilityConditionUpdate */
export type ExpressMembershipVisibilityConditionUpdatePayload = {
  __typename?: 'ExpressMembershipVisibilityConditionUpdatePayload';
  /** The newly created express_membership visibility condition */
  expressMembershipVisibilityCondition: ContentManagementVisibilityConditionExpressMembership;
};

export type ExpressNonmemberLongTermHoldoutInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of ExpressNonmemberLongTermHoldoutVisibilityConditionCreate */
export type ExpressNonmemberLongTermHoldoutVisibilityConditionCreatePayload = {
  __typename?: 'ExpressNonmemberLongTermHoldoutVisibilityConditionCreatePayload';
  /** The newly created ExpressNonmemberLongTermHoldout visibility condition */
  expressNonmemberLongTermHoldoutVisibilityCondition: ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout;
};

export type FailedUpdate = {
  __typename?: 'FailedUpdate';
  reason: Maybe<Scalars['String']>;
  retailerLocationIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type FeatureEntitlement = {
  __typename?: 'FeatureEntitlement';
  /** The unique referable identifier of this entitlement */
  id: Scalars['String'];
  /** The display name of this entitlement */
  name: Scalars['String'];
};

export type FeatureExperiment = {
  __typename?: 'FeatureExperiment';
  /** The name of this feature */
  name: Scalars['String'];
  /** The variant picked */
  variant: Scalars['String'];
};

export type FeatureToggle = {
  __typename?: 'FeatureToggle';
  /** The name of this feature toggle */
  name: Scalars['String'];
};

export type FeatureTogglesEnabledCheck = {
  __typename?: 'FeatureTogglesEnabledCheck';
  /** Whether the feature toggle is enabled */
  enabled: Scalars['Boolean'];
};

/** Autogenerated return type of FeatureTourUpdate */
export type FeatureTourUpdatePayload = {
  __typename?: 'FeatureTourUpdatePayload';
  /** A status which is true if no errors were encountered while performing the update */
  success: Scalars['True'];
};

export type Filter = {
  __typename?: 'Filter';
  collectionId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  intersectionGroup: Maybe<Scalars['BigInt']>;
  ruleId: Maybe<Scalars['BigInt']>;
  value: Maybe<Scalars['String']>;
};

export type FilterInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  intersectionGroup?: InputMaybe<Scalars['BigInt']>;
  ruleId?: InputMaybe<Scalars['BigInt']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum FilterType {
  FilterTypeCollectionId = 'FILTER_TYPE_COLLECTION_ID',
  FilterTypeCollectionSlug = 'FILTER_TYPE_COLLECTION_SLUG',
  FilterTypeMoment = 'FILTER_TYPE_MOMENT',
  FilterTypeMomentApproved = 'FILTER_TYPE_MOMENT_APPROVED',
  FilterTypeMomentRejected = 'FILTER_TYPE_MOMENT_REJECTED',
  FilterTypeMomentSubmitted = 'FILTER_TYPE_MOMENT_SUBMITTED',
  FilterTypeOccasion = 'FILTER_TYPE_OCCASION',
  FilterTypeOccasionApproved = 'FILTER_TYPE_OCCASION_APPROVED',
  FilterTypeOccasionRejected = 'FILTER_TYPE_OCCASION_REJECTED',
  FilterTypeOccasionSubmitted = 'FILTER_TYPE_OCCASION_SUBMITTED',
  FilterTypeTag = 'FILTER_TYPE_TAG',
  FilterTypeUnknown = 'FILTER_TYPE_UNKNOWN'
}

export type FlattenedTaxonomy = {
  __typename?: 'FlattenedTaxonomy';
  createdAt: Maybe<Scalars['DateTime']>;
  productCategoryId: Maybe<Scalars['BigInt']>;
  productCategoryPath: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type FlattenedTaxonomyFilterInput = {
  updatedSince?: InputMaybe<Scalars['DateTime']>;
};

/** Autogenerated return type of FlyoutPlacementCreate */
export type FlyoutPlacementCreatePayload = {
  __typename?: 'FlyoutPlacementCreatePayload';
  /** The newly created flyout placement */
  flyoutPlacement: ContentManagementFlyoutPlacement;
};

export type FlyoutPlacementInput = {
  /** The style and text of the subheading */
  descriptionText: ConfigurableTextInput;
  /** The style and text of the secondary text */
  disclaimerText?: InputMaybe<ConfigurableTextInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Information of Experiment associated with the placement */
  experiment?: InputMaybe<PlacementExperimentInput>;
  /** The images on the banner */
  images: GenericImagesInput;
  /** The style and text of the button within the placement */
  linkButton: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  linkDestination?: InputMaybe<PlacementDestinationInput>;
  /** Locale */
  locale: BannerLocaleEnum;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The style and text of the button within the placement */
  primaryButton: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  primaryDestination?: InputMaybe<PlacementDestinationInput>;
  /** The priority rank of the placement */
  rank?: InputMaybe<Scalars['Int']>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The style and text of the button within the placement */
  secondaryButton: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  secondaryDestination?: InputMaybe<PlacementDestinationInput>;
  /** The style and text of the heading */
  titleText: ConfigurableTextInput;
  /** The type of this placement */
  type: PlacementTypeEnum;
};

/** Autogenerated return type of FlyoutPlacementUpdate */
export type FlyoutPlacementUpdatePayload = {
  __typename?: 'FlyoutPlacementUpdatePayload';
  /** The updated flyout placement */
  flyoutPlacement: ContentManagementFlyoutPlacement;
};

export type FontFamily = {
  __typename?: 'FontFamily';
  files: Maybe<FontFiles>;
  name: Maybe<Scalars['String']>;
};

export type FontFile = {
  __typename?: 'FontFile';
  otf: Maybe<Scalars['String']>;
  ttf: Maybe<Scalars['String']>;
  woff: Maybe<Scalars['String']>;
  woff2: Maybe<Scalars['String']>;
};

export type FontFiles = {
  __typename?: 'FontFiles';
  bold: Maybe<FontFile>;
  normal: Maybe<FontFile>;
  semiBold: Maybe<FontFile>;
};

export type Footer = {
  __typename?: 'Footer';
  variant: Maybe<FooterVariant>;
};

export enum FooterVariant {
  MultiColumn = 'MULTI_COLUMN',
  NoFooter = 'NO_FOOTER',
  VariantUnknown = 'VARIANT_UNKNOWN'
}

export enum FulfillmentModelEnum {
  /** Carrotwork */
  Carrotwork = 'CARROTWORK',
  /** In store shoppers */
  Iss = 'ISS',
  /** Locker */
  Locker = 'LOCKER',
  /** Partner managed */
  Retailer = 'RETAILER'
}

export type GenerateBatchRequestInput = {
  adminRequestContext?: InputMaybe<AdminRequestContextInput>;
  batchType?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Scalars['BigInt']>;
  orders?: InputMaybe<Array<InputMaybe<OrderInput>>>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  shopperId?: InputMaybe<Scalars['BigInt']>;
  workflowState?: InputMaybe<Scalars['String']>;
};

export type GenerateBatchResponse = {
  __typename?: 'GenerateBatchResponse';
  id: Maybe<Scalars['BigInt']>;
  workflowState: Maybe<Scalars['String']>;
};

export type GenericImagesInput = {
  /** The image url of the placement to display for large viewports */
  desktop?: InputMaybe<Scalars['Url']>;
  /** The image url of the placement to display for small viewports */
  mobile: Scalars['Url'];
};

export type GetAcknowledgedOosIdsRequestInput = {
  retailerId?: InputMaybe<Scalars['String']>;
};

export type GetAcknowledgedOosIdsResponse = {
  __typename?: 'GetAcknowledgedOosIdsResponse';
  oosIds: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetAnomalousItemsListRequestInput = {
  filterEndAtDate?: InputMaybe<Scalars['DateTime']>;
  filterStartAtDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetAnomalousItemsListResponse = {
  __typename?: 'GetAnomalousItemsListResponse';
  dateCounts: Maybe<Array<Maybe<AnomalousItemDateCount>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type GetAnomalousItemsReportRequestInput = {
  filterEndAtDate?: InputMaybe<Scalars['DateTime']>;
  filterStartAtDate?: InputMaybe<Scalars['DateTime']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  selectedDates?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type GetAnomalousItemsReportResponse = {
  __typename?: 'GetAnomalousItemsReportResponse';
  filename: Maybe<Scalars['String']>;
};

export type GetApiSettingsRequestInput = {
  apiType?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetApiSettingsResponse = {
  __typename?: 'GetApiSettingsResponse';
  apiSettings: Maybe<Array<Maybe<GetApiSettingsResponseApiSetting>>>;
};

export type GetApiSettingsResponseApiSetting = {
  __typename?: 'GetApiSettingsResponseApiSetting';
  apiType: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  cron: Maybe<Scalars['String']>;
  enabled: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['BigInt']>;
  integrationType: Maybe<Scalars['String']>;
  partnerId: Maybe<Scalars['BigInt']>;
  storeLocationIds: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetAuditBatchRequestInput = {
  batchUuid?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
  orderByComponent?: InputMaybe<OrderByComponentInput>;
  page?: InputMaybe<PaginationInput>;
  returnSampleTasks?: InputMaybe<Scalars['Boolean']>;
};

export type GetAuditBatchResponse = {
  __typename?: 'GetAuditBatchResponse';
  auditBatch: Maybe<AuditBatch>;
};

export type GetAuditTaskRequestInput = {
  client?: InputMaybe<Scalars['String']>;
  taskUuid?: InputMaybe<Scalars['String']>;
};

export type GetAuditTaskResponse = {
  __typename?: 'GetAuditTaskResponse';
  auditTask: Maybe<AuditTask>;
};

export type GetAuthenticationUrlRequestInput = {
  errorRedirectUrl?: InputMaybe<Scalars['String']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  successRedirectUrl?: InputMaybe<Scalars['String']>;
};

export type GetAuthenticationUrlResponse = {
  __typename?: 'GetAuthenticationUrlResponse';
  authenticationUrl: Maybe<Scalars['String']>;
};

export type GetAvailableStoreLocationsRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetAvailableStoreLocationsResponse = {
  __typename?: 'GetAvailableStoreLocationsResponse';
  storeLocations: Maybe<Array<Maybe<ApiIntegrationStoreLocation>>>;
};

export type GetCatalogFaqFileRequestInput = {
  fileName?: InputMaybe<Scalars['String']>;
};

export type GetCatalogFaqFileResponse = {
  __typename?: 'GetCatalogFaqFileResponse';
  file: Maybe<Scalars['String']>;
};

export type GetCollectionFiltersRequestInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetCollectionFiltersResponse = {
  __typename?: 'GetCollectionFiltersResponse';
  filters: Maybe<Array<Maybe<RetailerCollectionFilter>>>;
};

export type GetCollectionRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GetCollectionResponse = {
  __typename?: 'GetCollectionResponse';
  collection: Maybe<Collection>;
};

export type GetCollectionRulesRequestInput = {
  dynamicFeedEligible?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetCollectionRulesResponse = {
  __typename?: 'GetCollectionRulesResponse';
  rules: Maybe<Array<Maybe<Rule>>>;
};

export type GetCollectionsRequestInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  admin?: InputMaybe<Scalars['Boolean']>;
  collectionTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludeDynamicRetailerCollections?: InputMaybe<Scalars['Boolean']>;
  excludedSourceTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includeAlcohol?: InputMaybe<Scalars['Boolean']>;
  includedSourceTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderBy?: InputMaybe<InstacartCustomersSharedV1OrderInput>;
  parentId?: InputMaybe<Scalars['String']>;
  replaceDepartmentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  showDepartmentTile?: InputMaybe<Scalars['Boolean']>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  useRetailerTypeMapping?: InputMaybe<Scalars['Boolean']>;
};

export type GetCollectionsResponse = {
  __typename?: 'GetCollectionsResponse';
  collections: Maybe<Array<Maybe<Collection>>>;
};

export type GetConfirmedStoreHoursRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type GetConfirmedStoreHoursResponse = {
  __typename?: 'GetConfirmedStoreHoursResponse';
  confirmations: Maybe<Array<Maybe<ConfirmedStoreHours>>>;
};

export type GetConnectRetailerCallbackConfigurationByIdRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type GetConnectRetailerCallbackConfigurationByIdResponse = {
  __typename?: 'GetConnectRetailerCallbackConfigurationByIdResponse';
  callbackConfiguration: Maybe<ConnectRetailerCallbackConfiguration>;
};

export type GetConnectRetailerCallbackConfigurationsRequestInput = {
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
  environment?: InputMaybe<Scalars['String']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GetConnectRetailerCallbackConfigurationsResponse = {
  __typename?: 'GetConnectRetailerCallbackConfigurationsResponse';
  callbackConfigurations: Maybe<Array<Maybe<ConnectRetailerCallbackConfiguration>>>;
};

export type GetConnectSettingsRequestInput = {
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
};

export type GetConnectSettingsResponse = {
  __typename?: 'GetConnectSettingsResponse';
  enableEtaServiceOptions: Maybe<Scalars['Boolean']>;
  postCheckoutAppDomain: Maybe<Scalars['String']>;
};

export type GetCsvImportRequestInput = {
  key?: InputMaybe<Scalars['String']>;
};

export type GetCsvImportResponse = {
  __typename?: 'GetCsvImportResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type GetCsvSummaryRequestInput = {
  key?: InputMaybe<Scalars['String']>;
};

export type GetCsvSummaryResponse = {
  __typename?: 'GetCsvSummaryResponse';
  summary: Maybe<CsvSummary>;
};

export type GetCustomersContextRequestInput = {
  parameters?: InputMaybe<GetCustomersContextRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetCustomersContextRequestParametersInput = {
  partnerId?: InputMaybe<Scalars['Int']>;
};

export type GetCustomersContextResponse = {
  __typename?: 'GetCustomersContextResponse';
  contexts: Maybe<Array<Maybe<CustomersContext>>>;
};

export type GetDashboardOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetDashboardOrderResponse = {
  __typename?: 'GetDashboardOrderResponse';
  dashboardOrder: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrder>;
};

export type GetDashboardOrdersRequestFilterSetInput = {
  orderIdentifier?: InputMaybe<Scalars['String']>;
  orderStatusCriteria?: InputMaybe<Array<InputMaybe<GetDashboardOrdersRequestFilterSetOrderStatusCriteria>>>;
  orderType?: InputMaybe<GetDashboardOrdersRequestFilterSetOrderType>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  windowEndsAtRange?: InputMaybe<GetDashboardOrdersRequestFilterSetTimestampRangeInput>;
};

export enum GetDashboardOrdersRequestFilterSetOrderStatusCriteria {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Delivering = 'DELIVERING',
  New = 'NEW',
  OrderStatusCriteriaUnspecified = 'ORDER_STATUS_CRITERIA_UNSPECIFIED',
  Processing = 'PROCESSING',
  ReadyForPickup = 'READY_FOR_PICKUP',
  Shopping = 'SHOPPING'
}

export enum GetDashboardOrdersRequestFilterSetOrderType {
  Delivery = 'DELIVERY',
  LastMileDelivery = 'LAST_MILE_DELIVERY',
  OrderTypeUnspecified = 'ORDER_TYPE_UNSPECIFIED',
  Pickup = 'PICKUP'
}

export type GetDashboardOrdersRequestFilterSetTimestampRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetDashboardOrdersRequestInput = {
  filterSet?: InputMaybe<GetDashboardOrdersRequestFilterSetInput>;
  ordersSortBy?: InputMaybe<GetDashboardOrdersRequestOrdersSortByInput>;
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
};

export enum GetDashboardOrdersRequestOrdersSortByDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  DirectionUnspecified = 'DIRECTION_UNSPECIFIED'
}

export type GetDashboardOrdersRequestOrdersSortByInput = {
  direction?: InputMaybe<GetDashboardOrdersRequestOrdersSortByDirection>;
  name?: InputMaybe<GetDashboardOrdersRequestOrdersSortByOrdersAttributes>;
};

export enum GetDashboardOrdersRequestOrdersSortByOrdersAttributes {
  OrdersAttributesUnspecified = 'ORDERS_ATTRIBUTES_UNSPECIFIED',
  OrderDeliveryId = 'ORDER_DELIVERY_ID',
  WindowEndsAt = 'WINDOW_ENDS_AT'
}

export type GetDashboardOrdersResponse = {
  __typename?: 'GetDashboardOrdersResponse';
  dashboardOrderSummary: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummary>>>;
  pageInfo: Maybe<InstacartCustomersSharedV1PageInfo>;
};

export type GetFlattenedTaxonomiesParametersInput = {
  filter?: InputMaybe<FlattenedTaxonomyFilterInput>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetFlattenedTaxonomiesRequestInput = {
  parameters?: InputMaybe<GetFlattenedTaxonomiesParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetFlattenedTaxonomiesResponse = {
  __typename?: 'GetFlattenedTaxonomiesResponse';
  taxonomies: Maybe<Array<Maybe<FlattenedTaxonomy>>>;
};

export type GetHolidaysStatusRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetHolidaysStatusResponse = {
  __typename?: 'GetHolidaysStatusResponse';
  holidays: Maybe<Array<Maybe<HolidayStatus>>>;
};

export type GetImageUploadBatchDetailsRequestInput = {
  batchId?: InputMaybe<Scalars['BigInt']>;
  page?: InputMaybe<Scalars['BigInt']>;
  pageSize?: InputMaybe<Scalars['BigInt']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type GetImageUploadBatchDetailsResponse = {
  __typename?: 'GetImageUploadBatchDetailsResponse';
  imageUploadBatchDetails: Maybe<Array<Maybe<ImageUploadBatchDetails>>>;
  imageUploadBatchMetadata: Maybe<ImageUploadBatchMetadata>;
};

export type GetImageUploadBatchesRequestInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['BigInt']>;
  pageSize?: InputMaybe<Scalars['BigInt']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  timeRange?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type GetImageUploadBatchesResponse = {
  __typename?: 'GetImageUploadBatchesResponse';
  imageUploadBatch: Maybe<Array<Maybe<ImageUploadBatch>>>;
  imageUploadBatchMetadata: Maybe<ImageUploadBatchMetadata>;
};

export type GetInstacartCollectionRequestInput = {
  contentType?: InputMaybe<RetailerCollectionContentType>;
  id?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetInstacartCollectionResponse = {
  __typename?: 'GetInstacartCollectionResponse';
  hasChildCollections: Maybe<Scalars['Boolean']>;
  hierarchy: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  instacartCollection: Maybe<RetailerCollection>;
  localeCode: Maybe<InstacartCustomersSharedV1Locale>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type GetInstacartCollectionsTreeRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetInstacartCollectionsTreeResponse = {
  __typename?: 'GetInstacartCollectionsTreeResponse';
  instacartCollections: Maybe<Array<Maybe<RetailerCollectionTreeNode>>>;
};

export type GetInventoryFileMappingsRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetInventoryFileMappingsResponse = {
  __typename?: 'GetInventoryFileMappingsResponse';
  inventoryFileMappings: Maybe<Array<Maybe<InventoryFileMapping>>>;
};

export type GetInventoryFileMismatchesRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetInventoryFileMismatchesResponse = {
  __typename?: 'GetInventoryFileMismatchesResponse';
  inventoryFileMismatches: Maybe<Array<Maybe<InventoryFileMismatch>>>;
};

export type GetIsGrocerRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetIsGrocerResponse = {
  __typename?: 'GetIsGrocerResponse';
  isGrocer: Maybe<Scalars['Boolean']>;
};

export type GetItemsRequestInput = {
  parameters?: InputMaybe<GetItemsRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetItemsRequestParametersInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  offset?: InputMaybe<Scalars['BigInt']>;
  primaryAisleId?: InputMaybe<Scalars['BigInt']>;
  primaryDepartmentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetItemsResponse = {
  __typename?: 'GetItemsResponse';
  results: Maybe<Array<Maybe<Item>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type GetLaunchOnboardingRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
  token?: InputMaybe<Scalars['String']>;
};

export type GetLaunchOnboardingResponse = {
  __typename?: 'GetLaunchOnboardingResponse';
  launchOnboarding: Maybe<LaunchOnboarding>;
};

export type GetLayoutVariantsRequestInput = {
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Status>;
};

export type GetLayoutVariantsResponse = {
  __typename?: 'GetLayoutVariantsResponse';
  layoutVariants: Maybe<Array<Maybe<LayoutVariant>>>;
};

export type GetLegacyElasticsearchRequestInput = {
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  itemIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetLegacyItemsShoppersRequestBoostWhereBoostObjectInput = {
  factor?: InputMaybe<Scalars['BigInt']>;
  value?: InputMaybe<Scalars['BigInt']>;
};

export type GetLegacyItemsShoppersRequestBoostWhereInput = {
  primaryDepartmentId?: InputMaybe<GetLegacyItemsShoppersRequestBoostWhereBoostObjectInput>;
};

export type GetLegacyItemsShoppersRequestInput = {
  aisleId?: InputMaybe<Scalars['BigInt']>;
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  anyMatchingProductCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  available?: InputMaybe<Scalars['Boolean']>;
  boostWhere?: InputMaybe<GetLegacyItemsShoppersRequestBoostWhereInput>;
  departmentId?: InputMaybe<Scalars['BigInt']>;
  filters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasImage?: InputMaybe<Scalars['Boolean']>;
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  itemIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  limit?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<InstacartCustomersSharedV1OrderInput>;
  productCodes?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  query?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerReferenceCode?: InputMaybe<Scalars['String']>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
  tsqueryQuery?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type GetLegacyProductsRequestInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  availableInventoryAreaIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  inventoryAreaIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  query?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source?: InputMaybe<Scalars['String']>;
};

export type GetLocalesByRetailerRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetLocalesByRetailerResponse = {
  __typename?: 'GetLocalesByRetailerResponse';
  retailerLocales: Maybe<Array<Maybe<RetailerLocale>>>;
};

export type GetManagedAttributeValuesForRetailerRequestInput = {
  attributeName?: InputMaybe<Scalars['String']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetManagedAttributeValuesForRetailerResponse = {
  __typename?: 'GetManagedAttributeValuesForRetailerResponse';
  managedAttributeValues: Maybe<Array<Maybe<ManagedAttributeValue>>>;
};

export type GetMomentParticipatingRetailersRequestInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetMomentParticipatingRetailersResponse = {
  __typename?: 'GetMomentParticipatingRetailersResponse';
  participatingRetailerCampaigns: Maybe<RetailerCampaign>;
  retailerMomentCampaigns: Maybe<Array<Maybe<RetailerMomentCampaign>>>;
};

export type GetMomentRequestInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
};

export type GetMomentResponse = {
  __typename?: 'GetMomentResponse';
  moment: Maybe<Moment>;
};

export type GetMomentsRequestInput = {
  momentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type GetMomentsResponse = {
  __typename?: 'GetMomentsResponse';
  moments: Maybe<Array<Maybe<Moment>>>;
};

export type GetNavigationRequestInput = {
  layout?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetNavigationResponse = {
  __typename?: 'GetNavigationResponse';
  navigation: Maybe<Navigation>;
};

export type GetNavigationTreeRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetNavigationTreeResponse = {
  __typename?: 'GetNavigationTreeResponse';
  navigationConfiguration: Maybe<NavigationConfiguration>;
  nodes: Maybe<Array<Maybe<NavigationNode>>>;
};

export type GetOnboardingRequestInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type GetOnboardingResponse = {
  __typename?: 'GetOnboardingResponse';
  onboarding: Maybe<Onboarding>;
};

export type GetOrderDiscountDetailsRequestInput = {
  orderId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderDiscountDetailsResponse = {
  __typename?: 'GetOrderDiscountDetailsResponse';
  discountDiscountables: Maybe<Array<Maybe<DiscountDiscountablesData>>>;
};

export type GetOrderFulfillmentOverviewRequestInput = {
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderFulfillmentOverviewResponse = {
  __typename?: 'GetOrderFulfillmentOverviewResponse';
  activeShopper: Maybe<Scalars['Int']>;
  isFullService: Maybe<Scalars['Boolean']>;
  shoppers: Maybe<Array<Maybe<ShopperOrderProfile>>>;
  warehouseLocationId: Maybe<Scalars['BigInt']>;
};

export type GetOrderLocationRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderLocationResponse = {
  __typename?: 'GetOrderLocationResponse';
  shopperCoordinates: Maybe<GoogleTypeLatLng>;
};

export type GetOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetOrderResponse = {
  __typename?: 'GetOrderResponse';
  order: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
  orderItems: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItem>>>;
};

export type GetOrderStateChangesRequestInput = {
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
};

export type GetOrderStateChangesResponse = {
  __typename?: 'GetOrderStateChangesResponse';
  currentState: Maybe<GetOrderStateChangesResponseState>;
  stateChanges: Maybe<Array<Maybe<GetOrderStateChangesResponseState>>>;
};

export type GetOrderStateChangesResponseState = {
  __typename?: 'GetOrderStateChangesResponseState';
  issues: Maybe<Array<Maybe<GetOrderStateChangesResponseStateIssue>>>;
  stateDisplayName: Maybe<Scalars['String']>;
  stateType: Maybe<GetOrderStateChangesResponseStateStateType>;
  transitionedAt: Maybe<Scalars['DateTime']>;
  transitionedEta: Maybe<Scalars['DateTime']>;
};

export type GetOrderStateChangesResponseStateIssue = {
  __typename?: 'GetOrderStateChangesResponseStateIssue';
  issueDescription: Maybe<Scalars['String']>;
  issueType: Maybe<GetOrderStateChangesResponseStateIssueIssueType>;
};

export enum GetOrderStateChangesResponseStateIssueIssueType {
  IssueTypeCanceled = 'ISSUE_TYPE_CANCELED',
  IssueTypeDelayed = 'ISSUE_TYPE_DELAYED',
  IssueTypeFailedDelivery = 'ISSUE_TYPE_FAILED_DELIVERY',
  IssueTypePartiallyDelivery = 'ISSUE_TYPE_PARTIALLY_DELIVERY',
  IssueTypeUnknown = 'ISSUE_TYPE_UNKNOWN'
}

export enum GetOrderStateChangesResponseStateStateType {
  StateTypeArrived = 'STATE_TYPE_ARRIVED',
  StateTypeAssigned = 'STATE_TYPE_ASSIGNED',
  StateTypeAtStore = 'STATE_TYPE_AT_STORE',
  StateTypeAwaitingReturn = 'STATE_TYPE_AWAITING_RETURN',
  StateTypeBagsPickedUp = 'STATE_TYPE_BAGS_PICKED_UP',
  StateTypeCheckingOut = 'STATE_TYPE_CHECKING_OUT',
  StateTypeDelivered = 'STATE_TYPE_DELIVERED',
  StateTypeInTransit = 'STATE_TYPE_IN_TRANSIT',
  StateTypeOrderPlaced = 'STATE_TYPE_ORDER_PLACED',
  StateTypePicking = 'STATE_TYPE_PICKING',
  StateTypeReadyForPickup = 'STATE_TYPE_READY_FOR_PICKUP',
  StateTypeReturnCompleted = 'STATE_TYPE_RETURN_COMPLETED',
  StateTypeReturnInProgress = 'STATE_TYPE_RETURN_IN_PROGRESS',
  StateTypeRunningOrder = 'STATE_TYPE_RUNNING_ORDER',
  StateTypeStaging = 'STATE_TYPE_STAGING',
  StateTypeUnknown = 'STATE_TYPE_UNKNOWN'
}

export type GetParkingSettingsRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<PickupType>;
};

export type GetParkingSettingsResponse = {
  __typename?: 'GetParkingSettingsResponse';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  imageConfigs: Maybe<Array<Maybe<ImageConfig>>>;
  instruction: Maybe<Scalars['String']>;
  retailerLocationConfig: Maybe<RetailerLocationInfo>;
};

export type GetPartnerIdRequestInput = {
  clientConfigurationId?: InputMaybe<Scalars['BigInt']>;
  retailerAppId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetPartnerIdResponse = {
  __typename?: 'GetPartnerIdResponse';
  partnerId: Maybe<Scalars['BigInt']>;
};

export type GetPickupSettingsMetadataRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetPickupSettingsMetadataResponse = {
  __typename?: 'GetPickupSettingsMetadataResponse';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  isCurbsidePickupEnabled: Maybe<Scalars['Boolean']>;
  isReadyForPickup: Maybe<Scalars['Boolean']>;
  isSettingsOnboardingComplete: Maybe<Scalars['Boolean']>;
  pickupEnableDate: Maybe<GoogleTypeDate>;
};

export type GetPiiRequestsForRetailerRequestInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  requestCreatedAfter?: InputMaybe<Scalars['DateTime']>;
  requestCreatedBefore?: InputMaybe<Scalars['DateTime']>;
  requestTypes?: InputMaybe<Array<InputMaybe<RequestType>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
  workflowStatus?: InputMaybe<Array<InputMaybe<WorkflowStatus>>>;
};

export type GetPiiRequestsForRetailerResponse = {
  __typename?: 'GetPiiRequestsForRetailerResponse';
  piiRequests: Maybe<Array<Maybe<PiiRequest>>>;
};

export type GetPlacementContentByIdRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type GetPlacementContentByIdResponse = {
  __typename?: 'GetPlacementContentByIdResponse';
  content: Maybe<PlacementContent>;
};

export type GetPlacementsRequestInput = {
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  enabledOnly?: InputMaybe<Scalars['Boolean']>;
  layout?: InputMaybe<Layout>;
  layoutVariantId?: InputMaybe<Scalars['BigInt']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  platform?: InputMaybe<Array<InputMaybe<PlatformType>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Direction>;
  status?: InputMaybe<Array<InputMaybe<Status>>>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
  variant?: InputMaybe<Array<InputMaybe<BannerVariant>>>;
};

export type GetPlatformVersionsRequestInput = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export type GetPlatformVersionsResponse = {
  __typename?: 'GetPlatformVersionsResponse';
  nextPlatformVersion: Maybe<Scalars['String']>;
  nextPlatformVersionRouletteName: Maybe<Scalars['String']>;
  platformVersion: Maybe<Scalars['String']>;
  platformVersionRouletteName: Maybe<Scalars['String']>;
};

export type GetProductDataRequestInput = {
  productId?: InputMaybe<ProductIdInput>;
};

export type GetProductDataResponse = {
  __typename?: 'GetProductDataResponse';
  productData: Maybe<Array<Maybe<ProductData>>>;
  productId: Maybe<ProductId>;
};

export type GetProductSourcesRequestInput = {
  sourceLevel?: InputMaybe<SourceLevel>;
};

export type GetProductSourcesResponse = {
  __typename?: 'GetProductSourcesResponse';
  sourceCount: Maybe<Scalars['BigInt']>;
  sources: Maybe<Array<Maybe<ProductSource>>>;
};

export type GetRequestContextsRequestInput = {
  partnerId?: InputMaybe<Scalars['Int']>;
  retailerId?: InputMaybe<Scalars['Int']>;
};

export type GetRequestContextsResponse = {
  __typename?: 'GetRequestContextsResponse';
  requestContexts: Maybe<Array<Maybe<InstacartCustomersSharedV1RequestContext>>>;
};

export type GetRetailerAisleTemplateRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  shopperType?: InputMaybe<ShopperType>;
};

export type GetRetailerAisleTemplateResponse = {
  __typename?: 'GetRetailerAisleTemplateResponse';
  activeAt: Maybe<Scalars['DateTime']>;
  aisleOrders: Maybe<Array<Maybe<AisleOrder>>>;
  aisleTemplateId: Maybe<Scalars['BigInt']>;
  layoutId: Maybe<Scalars['String']>;
};

export type GetRetailerCampaignRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerCollectionRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerCollectionResponse = {
  __typename?: 'GetRetailerCollectionResponse';
  hasChildCollections: Maybe<Scalars['Boolean']>;
  hierarchy: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  localeCode: Maybe<InstacartCustomersSharedV1Locale>;
  retailerCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type GetRetailerCollectionsCsvRequestInput = {
  bulkUploadType?: InputMaybe<RetailerCollectionBulkUploadType>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerCollectionsCsvResponse = {
  __typename?: 'GetRetailerCollectionsCsvResponse';
  filePath: Maybe<Scalars['String']>;
};

export type GetRetailerCollectionsTreeRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  surface?: InputMaybe<RetailerCollectionSurface>;
};

export type GetRetailerCollectionsTreeResponse = {
  __typename?: 'GetRetailerCollectionsTreeResponse';
  retailerCollections: Maybe<Array<Maybe<RetailerCollectionTreeNode>>>;
};

export type GetRetailerConfigRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerConfigResponse = {
  __typename?: 'GetRetailerConfigResponse';
  maxActiveTopLevelCollections: Maybe<Scalars['BigInt']>;
  maxActiveTopLevelStorefrontCollections: Maybe<Scalars['Int']>;
  maxActiveTopLevelStorefrontProCollections: Maybe<Scalars['Int']>;
  numActiveTopLevelCollections: Maybe<Scalars['BigInt']>;
  storeConfigurationConfigs: Maybe<Array<Maybe<RetailerCollectionStoreConfigurationConfig>>>;
  supportedSurfaces: Maybe<Array<Maybe<RetailerCollectionSurface>>>;
};

export type GetRetailerCsvRequestInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerCsvResponse = {
  __typename?: 'GetRetailerCsvResponse';
  filePath: Maybe<Scalars['String']>;
};

export type GetRetailerLocationByLayoutRequestInput = {
  layoutId?: InputMaybe<Scalars['String']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerLocationByLayoutResponse = {
  __typename?: 'GetRetailerLocationByLayoutResponse';
  retailerLocationAisles: Maybe<Array<Maybe<RetailerLocationAisle>>>;
};

export type GetRetailerLocationsByLocationCodesRequestInput = {
  locationCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  skipCache?: InputMaybe<Scalars['Boolean']>;
  skipStatusCheck?: InputMaybe<Scalars['Boolean']>;
};

export type GetRetailerLocationsByLocationCodesResponse = {
  __typename?: 'GetRetailerLocationsByLocationCodesResponse';
  retailerLocations: Maybe<Array<Maybe<RetailerLocationDetails>>>;
};

export type GetRetailerLocationsDetailsRequestInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type GetRetailerLocationsDetailsResponse = {
  __typename?: 'GetRetailerLocationsDetailsResponse';
  retailerLocations: Maybe<Array<Maybe<RetailerLocationDetails>>>;
};

export type GetRetailerProductAssortmentRequestInput = {
  entityId?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  filter?: InputMaybe<RetailerProductAssortmentFilterInput>;
};

export type GetRetailerProductAssortmentResponse = {
  __typename?: 'GetRetailerProductAssortmentResponse';
  retailerProductAssortment: Maybe<Array<Maybe<RetailerProductAssortment>>>;
};

export type GetRetailerProductDataRequestInput = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerProductDataResponse = {
  __typename?: 'GetRetailerProductDataResponse';
  retailerProductData: Maybe<Array<Maybe<RetailerProductData>>>;
};

export type GetRetailerProductsRequestInput = {
  parameters?: InputMaybe<GetRetailerProductsRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerProductsRequestParametersDateTimeRangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type GetRetailerProductsRequestParametersInput = {
  aisleIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  hasPrimaryImageUrl?: InputMaybe<Scalars['Boolean']>;
  isStoreBrandProduct?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  offset?: InputMaybe<Scalars['BigInt']>;
  omniSearchTerm?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderBy?: InputMaybe<InstacartCustomersSharedV1OrderInput>;
  primaryAisleId?: InputMaybe<Scalars['BigInt']>;
  primaryDepartmentId?: InputMaybe<Scalars['BigInt']>;
  productCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productCreationDateRange?: InputMaybe<GetRetailerProductsRequestParametersDateTimeRangeInput>;
  query?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerProductIds?: InputMaybe<Array<InputMaybe<GetRetailerProductsRequestParametersRetailerProductIdInput>>>;
  retailerReferenceCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  select?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subjectIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  withUnclassifiedProducts?: InputMaybe<Scalars['Boolean']>;
};

export type GetRetailerProductsRequestParametersRetailerProductIdInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRetailerProductsResponse = {
  __typename?: 'GetRetailerProductsResponse';
  results: Maybe<Array<Maybe<RetailerProduct>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type GetRetailerRegionsRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  skipCache?: InputMaybe<Scalars['Boolean']>;
};

export type GetRetailerRegionsResponse = {
  __typename?: 'GetRetailerRegionsResponse';
  regions: Maybe<Array<Maybe<Region>>>;
};

export type GetRppConfigurableItemsRequestInput = {
  inventoryAreaId?: InputMaybe<Scalars['BigInt']>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetRppConfigurableItemsResponse = {
  __typename?: 'GetRppConfigurableItemsResponse';
  configurableProducts: Maybe<Scalars['String']>;
};

export type GetServiceOptionsFiltersInput = {
  addressId?: InputMaybe<Scalars['BigInt']>;
  cartTotal?: InputMaybe<Scalars['Float']>;
  earliestOptionStartAt?: InputMaybe<Scalars['DateTime']>;
  itemsCount?: InputMaybe<Scalars['Int']>;
  postalCode?: InputMaybe<Scalars['String']>;
  serviceType?: InputMaybe<Scalars['String']>;
  unitsCount?: InputMaybe<Scalars['Float']>;
  warehouseId?: InputMaybe<Scalars['Int']>;
  warehouseLocationId?: InputMaybe<Scalars['BigInt']>;
  withEtaOptions?: InputMaybe<Scalars['Boolean']>;
  withPriorityEtaOptions?: InputMaybe<Scalars['Boolean']>;
};

export type GetServiceOptionsRequestInput = {
  filters?: InputMaybe<GetServiceOptionsFiltersInput>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type GetServiceOptionsResponse = {
  __typename?: 'GetServiceOptionsResponse';
  serviceOptions: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOption>>>;
};

export type GetSettingRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  settingName?: InputMaybe<Scalars['String']>;
};

export type GetSettingResponse = {
  __typename?: 'GetSettingResponse';
  settingRecord: Maybe<RetailerSettingRecord>;
};

export type GetSignupPartnerRequestInput = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GetSignupPartnerResponse = {
  __typename?: 'GetSignupPartnerResponse';
  signupPartner: Maybe<SignupPartner>;
};

export type GetStagingSettingsRequestInput = {
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetStagingSettingsResponse = {
  __typename?: 'GetStagingSettingsResponse';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  freezer: Maybe<Array<Maybe<Scalars['String']>>>;
  fridge: Maybe<Array<Maybe<Scalars['String']>>>;
  imageUrl: Maybe<Scalars['String']>;
  instruction: Maybe<Scalars['String']>;
  retailerLocationConfig: Maybe<RetailerLocationInfo>;
  shelf: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetStoreConfigurationDraftsRequestInput = {
  includeOutdatedDrafts?: InputMaybe<Scalars['Boolean']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type GetStoreConfigurationDraftsResponse = {
  __typename?: 'GetStoreConfigurationDraftsResponse';
  drafts: Maybe<Array<Maybe<StoreConfigurationDraft>>>;
  errorMessage: Maybe<Scalars['String']>;
  errorReason: Maybe<ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type GetStoreHoursByDateRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  verticalType?: InputMaybe<VerticalType>;
};

export type GetStoreHoursByDateResponse = {
  __typename?: 'GetStoreHoursByDateResponse';
  storeHours: Maybe<Array<Maybe<StoreHoursByDate>>>;
};

export type GetStoreHoursByDayOfWeekRequestInput = {
  daysOfWeek?: InputMaybe<Array<InputMaybe<GoogleTypeDayOfWeek>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  verticalType?: InputMaybe<VerticalType>;
};

export type GetStoreHoursByDayOfWeekResponse = {
  __typename?: 'GetStoreHoursByDayOfWeekResponse';
  storeHours: Maybe<Array<Maybe<StoreHoursByDayOfWeek>>>;
};

export type GetStoreHoursByZoneRetailerRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  includeClosedRetailer?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  verticalType?: InputMaybe<VerticalType>;
  zoneId?: InputMaybe<Scalars['BigInt']>;
};

export type GetStoreHoursByZoneRetailerResponse = {
  __typename?: 'GetStoreHoursByZoneRetailerResponse';
  storeHours: Maybe<Array<Maybe<StoreHoursByZoneRetailer>>>;
};

export type GetStoreHoursRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  serviceTypes?: InputMaybe<Array<InputMaybe<RetailerServiceType>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type GetStoreHoursResponse = {
  __typename?: 'GetStoreHoursResponse';
  storeHours: Maybe<Array<Maybe<StoreHours>>>;
};

export type GetStoreHoursUpdateTasksRequestInput = {
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type GetStoreHoursUpdateTasksResponse = {
  __typename?: 'GetStoreHoursUpdateTasksResponse';
  tasks: Maybe<Array<Maybe<StoreHoursUpdateTask>>>;
};

export type GetThemePageRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GetThemePageResponse = {
  __typename?: 'GetThemePageResponse';
  themePage: Maybe<ThemePage>;
};

export type GetTicketRequestInput = {
  client?: InputMaybe<ClientInput>;
  cptTicketId?: InputMaybe<Scalars['BigInt']>;
};

export type GetTicketResponse = {
  __typename?: 'GetTicketResponse';
  comments: Maybe<Array<Maybe<ProblemTrackerTicketComment>>>;
  entities: Maybe<Array<Maybe<ProblemTrackerEntityStatus>>>;
  ticket: Maybe<ProblemTrackerTicket>;
  watchers: Maybe<Array<Maybe<User>>>;
};

export type GetUserByIdFilteredByIsolationContextParametersInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
};

export type GetUserByIdFilteredByIsolationContextRequestInput = {
  parameters?: InputMaybe<GetUserByIdFilteredByIsolationContextParametersInput>;
};

export type GetValueBasedOverridesRequestInput = {
  entityId?: InputMaybe<EntityIdInput>;
};

export type GetValueBasedOverridesResponse = {
  __typename?: 'GetValueBasedOverridesResponse';
  entityId: Maybe<EntityId>;
  valueBasedOverride: Maybe<Array<Maybe<ValueBasedOverrideResponse>>>;
};

export type GetVirtualRetailersRequestInput = {
  parameters?: InputMaybe<GetVirtualRetailersRequestParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type GetVirtualRetailersRequestParametersInput = {
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type GetVirtualRetailersResponse = {
  __typename?: 'GetVirtualRetailersResponse';
  virtualRetailers: Maybe<Array<Maybe<VirtualRetailer>>>;
};

export type GoogleTypeDate = {
  __typename?: 'GoogleTypeDate';
  day: Maybe<Scalars['Int']>;
  month: Maybe<Scalars['Int']>;
  year: Maybe<Scalars['Int']>;
};

export type GoogleTypeDateInput = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export enum GoogleTypeDayOfWeek {
  DayOfWeekUnspecified = 'DAY_OF_WEEK_UNSPECIFIED',
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type GoogleTypeLatLng = {
  __typename?: 'GoogleTypeLatLng';
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
};

export type GoogleTypeMoney = {
  __typename?: 'GoogleTypeMoney';
  currencyCode: Maybe<Scalars['String']>;
  nanos: Maybe<Scalars['Int']>;
  units: Maybe<Scalars['BigInt']>;
};

export type GoogleTypeMoneyInput = {
  currencyCode?: InputMaybe<Scalars['String']>;
  nanos?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['BigInt']>;
};

export type GoogleTypeTimeOfDay = {
  __typename?: 'GoogleTypeTimeOfDay';
  hours: Maybe<Scalars['Int']>;
  minutes: Maybe<Scalars['Int']>;
  nanos: Maybe<Scalars['Int']>;
  seconds: Maybe<Scalars['Int']>;
};

export type GoogleTypeTimeOfDayInput = {
  hours?: InputMaybe<Scalars['Int']>;
  minutes?: InputMaybe<Scalars['Int']>;
  nanos?: InputMaybe<Scalars['Int']>;
  seconds?: InputMaybe<Scalars['Int']>;
};

export type GrantImpersonationRequestInput = {
  clientContext?: InputMaybe<ClientContextInput>;
  impersonatedUserId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type GrantImpersonationResponse = {
  __typename?: 'GrantImpersonationResponse';
  authorizationCode: Maybe<Scalars['String']>;
};

export type Header = {
  __typename?: 'Header';
  desktopHeader: Maybe<UnionType>;
  mobileHeader: Maybe<UnionType>;
};

export type HealthCheck = {
  __typename?: 'HealthCheck';
  /** Server health status */
  alive: Scalars['True'];
  /** Postgresql health status */
  postgresql: Maybe<Scalars['Boolean']>;
  /** Redis health status */
  redis: Maybe<Scalars['Boolean']>;
  /** Snowflake health status */
  snowflake: Maybe<Scalars['Boolean']>;
};

export type HeroBanner = {
  __typename?: 'HeroBanner';
  backgroundColorHex: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaBackgroundColorHex: Maybe<Scalars['String']>;
  ctaColorHex: Maybe<Scalars['String']>;
  desktopImageUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  mobileImageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  textAreaColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type HeroBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  textAreaColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

/** Autogenerated return type of HeroBannerPlacementCreate */
export type HeroBannerPlacementCreatePayload = {
  __typename?: 'HeroBannerPlacementCreatePayload';
  /** The newly created hero_banner placement */
  heroBannerPlacement: ContentManagementHeroBannerPlacement;
};

export type HeroBannerPlacementInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  destination?: InputMaybe<PlacementDestinationInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Information of Experiment associated with the placement */
  experiment?: InputMaybe<PlacementExperimentInput>;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** The images on the banner */
  images: GenericImagesInput;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank?: InputMaybe<Scalars['Int']>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
  /** The color of the text area for placements (Eg. #FFFFFF) */
  textareaColor?: InputMaybe<Scalars['String']>;
  /** The type of this placement */
  type: PlacementTypeEnum;
};

/** Autogenerated return type of HeroBannerPlacementUpdate */
export type HeroBannerPlacementUpdatePayload = {
  __typename?: 'HeroBannerPlacementUpdatePayload';
  /** The updated hero_banner placement */
  heroBannerPlacement: ContentManagementHeroBannerPlacement;
};

export type HighContrastColorsSchema = {
  __typename?: 'HighContrastColorsSchema';
  brandExpressDark: Maybe<Scalars['String']>;
  brandExpressExtraDark: Maybe<Scalars['String']>;
  brandExpressLight: Maybe<Scalars['String']>;
  brandExpressRegular: Maybe<Scalars['String']>;
  brandHighlightDark: Maybe<Scalars['String']>;
  brandHighlightLight: Maybe<Scalars['String']>;
  brandHighlightRegular: Maybe<Scalars['String']>;
  brandLoyaltyDark: Maybe<Scalars['String']>;
  brandLoyaltyLight: Maybe<Scalars['String']>;
  brandLoyaltyRegular: Maybe<Scalars['String']>;
  brandMaxDark: Maybe<Scalars['String']>;
  brandMaxLight: Maybe<Scalars['String']>;
  brandPrimaryDark: Maybe<Scalars['String']>;
  brandPrimaryExtraDark: Maybe<Scalars['String']>;
  brandPrimaryRegular: Maybe<Scalars['String']>;
  brandPromotionalDark: Maybe<Scalars['String']>;
  brandPromotionalLight: Maybe<Scalars['String']>;
  brandPromotionalRegular: Maybe<Scalars['String']>;
  brandSecondaryDark: Maybe<Scalars['String']>;
  brandSecondaryLight: Maybe<Scalars['String']>;
  brandSecondaryRegular: Maybe<Scalars['String']>;
};

export type HistoricalPerformanceOverviewRecord = {
  __typename?: 'HistoricalPerformanceOverviewRecord';
  currentValue: Maybe<InstacartTypesV1BigDecimal>;
  metricType: Maybe<MetricType>;
  pastValue: Maybe<InstacartTypesV1BigDecimal>;
};

export type HistoricalStorePerformanceRecord = {
  __typename?: 'HistoricalStorePerformanceRecord';
  dedicatedMatchRate: Maybe<InstacartTypesV1BigDecimal>;
  dedicatedUtilizationRate: Maybe<InstacartTypesV1BigDecimal>;
  fiveHourAvailabilityRate: Maybe<InstacartTypesV1BigDecimal>;
  fssOrdersCount: Maybe<Scalars['Int']>;
  matchRate: Maybe<InstacartTypesV1BigDecimal>;
  name: Maybe<Scalars['String']>;
  partnerPickOrdersCount: Maybe<Scalars['Int']>;
  recommendedHours: Maybe<InstacartTypesV1BigDecimal>;
  scheduledGaps: Maybe<Scalars['Int']>;
  scheduledHours: Maybe<InstacartTypesV1BigDecimal>;
  utilizationRate: Maybe<InstacartTypesV1BigDecimal>;
  warehouseLocationId: Maybe<Scalars['BigInt']>;
};

export type HistoricalStorePerformanceRequestInput = {
  devicePrioritizationEnabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<GoogleTypeDateInput>;
  metricThresholds?: InputMaybe<Array<InputMaybe<MetricThresholdInput>>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type HistoricalStorePerformanceResponse = {
  __typename?: 'HistoricalStorePerformanceResponse';
  metrics: Maybe<Array<Maybe<HistoricalStorePerformanceRecord>>>;
  overviewMetrics: Maybe<Array<Maybe<HistoricalPerformanceOverviewRecord>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
  warehouseId: Maybe<Scalars['BigInt']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  date: Maybe<GoogleTypeDate>;
  holidaySlug: Maybe<Scalars['String']>;
  regions: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HolidayStatus = {
  __typename?: 'HolidayStatus';
  confirmedRetailerLocationCount: Maybe<Scalars['Int']>;
  holiday: Maybe<Holiday>;
  unconfirmedRetailerLocationCount: Maybe<Scalars['Int']>;
};

/** Types of Home Placements which may be returned */
export type HomePlacement = ContentManagementFlyoutPlacement | ContentManagementHeroBannerPlacement | ContentManagementSecondaryBannerPlacement | ContentManagementUnknownBannerPlacement;

export type HubPaginationMeta = {
  __typename?: 'HubPaginationMeta';
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type IcPlusSchema = {
  __typename?: 'IcPlusSchema';
  enabledFeatures: Maybe<Array<Maybe<IcPlusSchemaIcPlusFeature>>>;
};

export enum IcPlusSchemaIcPlusFeature {
  CreditBack = 'CREDIT_BACK',
  EnterpriseUpsell = 'ENTERPRISE_UPSELL',
  Unknown = 'UNKNOWN',
  Upsell = 'UPSELL'
}

export type IdsThemeSchema = {
  __typename?: 'IdsThemeSchema';
  buttonBorderRadius: Maybe<BorderRadiusSchema>;
  colors: Maybe<ColorsSchema>;
  fonts: Maybe<Array<Maybe<CustomFontFamilySchema>>>;
  inputBorderRadius: Maybe<BorderRadiusSchema>;
  radius: Maybe<RadiusSchema>;
  typography: Maybe<TypographySchema>;
};

export type ImageAsset = {
  __typename?: 'ImageAsset';
  altText: Maybe<Scalars['String']>;
  identifier: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ImageConfig = {
  __typename?: 'ImageConfig';
  caption: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  url: Maybe<Scalars['String']>;
};

export type ImageConfigInput = {
  caption?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ImageForward = {
  __typename?: 'ImageForward';
  backgroundColorHex: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaBackgroundColorHex: Maybe<Scalars['String']>;
  ctaColorHex: Maybe<Scalars['String']>;
  desktopImageUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  mobileImageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type ImageForwardFlex = {
  __typename?: 'ImageForwardFlex';
  backgroundColorHex: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaBackgroundColorHex: Maybe<Scalars['String']>;
  ctaColorHex: Maybe<Scalars['String']>;
  desktopImageUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  mobileImageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type ImageForwardFlexInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ImageForwardInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ImageUploadBatch = {
  __typename?: 'ImageUploadBatch';
  batchId: Maybe<Scalars['BigInt']>;
  createdAt: Maybe<Scalars['DateTime']>;
  processedImageUploads: Maybe<Scalars['BigInt']>;
  state: Maybe<Scalars['String']>;
  successfulImageUploads: Maybe<Scalars['BigInt']>;
  totalImageUploads: Maybe<Scalars['BigInt']>;
};

export type ImageUploadBatchDetails = {
  __typename?: 'ImageUploadBatchDetails';
  filename: Maybe<Scalars['String']>;
  imageUploadId: Maybe<Scalars['BigInt']>;
  imageUrl: Maybe<Scalars['String']>;
  isSuccessful: Maybe<Scalars['Boolean']>;
  productName: Maybe<Scalars['String']>;
};

export type ImageUploadBatchMetadata = {
  __typename?: 'ImageUploadBatchMetadata';
  perPage: Maybe<Scalars['BigInt']>;
  totalCount: Maybe<Scalars['BigInt']>;
  totalPages: Maybe<Scalars['BigInt']>;
};

export type IndexBrandNamesRequestInput = {
  isDebug?: InputMaybe<Scalars['Boolean']>;
  superCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IndexBrandNamesResponse = {
  __typename?: 'IndexBrandNamesResponse';
  brandNameMappings: Maybe<Array<Maybe<BrandNameMapping>>>;
  brandProfileIdToName: Maybe<Scalars['String']>;
};

export type IndexEbpCategoriesRequestInput = {
  brandProfileIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDebug?: InputMaybe<Scalars['Boolean']>;
};

export type IndexEbpCategoriesResponse = {
  __typename?: 'IndexEbpCategoriesResponse';
  superCategories: Maybe<Array<Maybe<Scalars['String']>>>;
  superCategoryIdToString: Maybe<Scalars['String']>;
};

export type InsightsPortalAuthorizationContextInput = {
  accountId?: InputMaybe<Scalars['BigInt']>;
  partnerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type InsightsPortalCreateCustomReportRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  groupBys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  orderBys?: InputMaybe<Array<InputMaybe<InsightsPortalOrderByInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalCreateCustomReportResponse = {
  __typename?: 'InsightsPortalCreateCustomReportResponse';
  customReport: Maybe<InsightsPortalCustomReport>;
  success: Maybe<Scalars['Boolean']>;
};

export type InsightsPortalCustomReport = {
  __typename?: 'InsightsPortalCustomReport';
  createdAt: Maybe<Scalars['DateTime']>;
  downloadUrl: Maybe<Scalars['String']>;
  fileGenerationStatus: Maybe<InsightsPortalCustomReportFileGenerationStatus>;
  filterSelections: Maybe<Scalars['String']>;
  groupBys: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Maybe<Scalars['BigInt']>;
  metrics: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Maybe<Scalars['String']>;
  orderBys: Maybe<Array<Maybe<InsightsPortalOrderBy>>>;
  schema: Maybe<Scalars['String']>;
};

export enum InsightsPortalCustomReportFileGenerationStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Ready = 'READY'
}

export enum InsightsPortalDataSource {
  Clickhouse = 'CLICKHOUSE',
  Default = 'DEFAULT',
  Snowflake = 'SNOWFLAKE'
}

export type InsightsPortalFilterOption = {
  __typename?: 'InsightsPortalFilterOption';
  displayValue: Maybe<Scalars['String']>;
  rawValue: Maybe<InsightsPortalValue>;
};

export type InsightsPortalFilterOptionsRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  owner?: InputMaybe<InsightsPortalOwner>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
  whitelistFilterOptions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InsightsPortalFilterOptionsResponse = {
  __typename?: 'InsightsPortalFilterOptionsResponse';
  filterOptions: Maybe<Array<Maybe<InsightsPortalFilterOptionsResponseFilterOptions>>>;
};

export type InsightsPortalFilterOptionsResponseFilterOptions = {
  __typename?: 'InsightsPortalFilterOptionsResponseFilterOptions';
  name: Maybe<Scalars['String']>;
  options: Maybe<Array<Maybe<InsightsPortalFilterOption>>>;
};

export type InsightsPortalFilterSelectionBetweenFilterInput = {
  end?: InputMaybe<InsightsPortalValueInput>;
  start?: InputMaybe<InsightsPortalValueInput>;
};

export type InsightsPortalFilterSelectionEqualFilterInput = {
  value?: InputMaybe<InsightsPortalValueInput>;
};

export type InsightsPortalFilterSelectionInFilterInput = {
  values?: InputMaybe<Array<InputMaybe<InsightsPortalValueInput>>>;
};

export type InsightsPortalFilterSelectionInput = {
  betweenFilter?: InputMaybe<InsightsPortalFilterSelectionBetweenFilterInput>;
  equalFilter?: InputMaybe<InsightsPortalFilterSelectionEqualFilterInput>;
  inFilter?: InputMaybe<InsightsPortalFilterSelectionInFilterInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type InsightsPortalGetCustomReportRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
};

export type InsightsPortalGetCustomReportResponse = {
  __typename?: 'InsightsPortalGetCustomReportResponse';
  customReport: Maybe<InsightsPortalCustomReport>;
};

export type InsightsPortalGroupComparisonRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  comparisonDataset?: InputMaybe<InsightsPortalGroupComparisonRequestInsightsPortalGroupComparisonDatasetInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  joinBy?: InputMaybe<InsightsPortalGroupComparisonRequestJoinBy>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<InsightsPortalGroupComparisonRequestOrderBy>;
  orderDirection?: InputMaybe<InsightsPortalGroupComparisonRequestOrderDirection>;
  owner?: InputMaybe<InsightsPortalOwner>;
  primaryDataset?: InputMaybe<InsightsPortalGroupComparisonRequestInsightsPortalGroupComparisonDatasetInput>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
};

export type InsightsPortalGroupComparisonRequestInsightsPortalGroupComparisonDatasetInput = {
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  groupBy?: InputMaybe<Scalars['String']>;
  metric?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<InsightsPortalSchema>;
  secondaryMetrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum InsightsPortalGroupComparisonRequestJoinBy {
  CustomJoinProductToCategory = 'CUSTOM_JOIN_PRODUCT_TO_CATEGORY',
  GroupValue = 'GROUP_VALUE',
  RowNumber = 'ROW_NUMBER',
  Unknown = 'UNKNOWN'
}

export enum InsightsPortalGroupComparisonRequestOrderBy {
  Default = 'DEFAULT',
  Growth = 'GROWTH',
  GrowthPercentage = 'GROWTH_PERCENTAGE',
  Total = 'TOTAL'
}

export enum InsightsPortalGroupComparisonRequestOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE'
}

export type InsightsPortalGroupComparisonResponse = {
  __typename?: 'InsightsPortalGroupComparisonResponse';
  values: Maybe<Array<Maybe<InsightsPortalGroupComparisonResponseInsightsPortalGroupComparisonValues>>>;
};

export type InsightsPortalGroupComparisonResponseInsightsPortalGroupComparisonValues = {
  __typename?: 'InsightsPortalGroupComparisonResponseInsightsPortalGroupComparisonValues';
  comparisonGroupValue: Maybe<InsightsPortalValue>;
  comparisonMetricValue: Maybe<InsightsPortalValue>;
  comparisonSecondaryMetricValues: Maybe<Array<Maybe<InsightsPortalMetricValue>>>;
  growth: Maybe<Scalars['Float']>;
  growthPercentage: Maybe<Scalars['Float']>;
  metricName: Maybe<Scalars['String']>;
  primaryGroupValue: Maybe<InsightsPortalValue>;
  primaryMetricValue: Maybe<InsightsPortalValue>;
  primarySecondaryMetricValues: Maybe<Array<Maybe<InsightsPortalMetricValue>>>;
};

export type InsightsPortalGroupMetricValueRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  groupBy?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderDirection?: InputMaybe<InsightsPortalGroupMetricValueRequestOrderDirection>;
  owner?: InputMaybe<InsightsPortalOwner>;
  primaryMetric?: InputMaybe<Scalars['String']>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
  secondaryMetrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum InsightsPortalGroupMetricValueRequestOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE'
}

export type InsightsPortalGroupMetricValueResponse = {
  __typename?: 'InsightsPortalGroupMetricValueResponse';
  values: Maybe<Array<Maybe<InsightsPortalGroupMetricValueResponseGroupMetricValue>>>;
};

export type InsightsPortalGroupMetricValueResponseGroupMetricValue = {
  __typename?: 'InsightsPortalGroupMetricValueResponseGroupMetricValue';
  groupValue: Maybe<InsightsPortalValue>;
  metricValue: Maybe<InsightsPortalValue>;
  secondaryMetricValues: Maybe<Array<Maybe<InsightsPortalMetricValue>>>;
};

export type InsightsPortalListCustomReportsRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type InsightsPortalListCustomReportsResponse = {
  __typename?: 'InsightsPortalListCustomReportsResponse';
  customReports: Maybe<Array<Maybe<InsightsPortalCustomReport>>>;
};

export type InsightsPortalMetricValue = {
  __typename?: 'InsightsPortalMetricValue';
  metric: Maybe<Scalars['String']>;
  value: Maybe<InsightsPortalValue>;
};

export type InsightsPortalMetricValuesRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  owner?: InputMaybe<InsightsPortalOwner>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalMetricValuesResponse = {
  __typename?: 'InsightsPortalMetricValuesResponse';
  metricValues: Maybe<Array<Maybe<InsightsPortalMetricValue>>>;
};

export type InsightsPortalMultiGroupRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  dataSource?: InputMaybe<InsightsPortalDataSource>;
  filters?: InputMaybe<Array<InputMaybe<InsightsPortalFilterSelectionInput>>>;
  groupBys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['BigInt']>;
  metrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBys?: InputMaybe<Array<InputMaybe<InsightsPortalOrderByInput>>>;
  owner?: InputMaybe<InsightsPortalOwner>;
  queryTags?: InputMaybe<Array<InputMaybe<InsightsPortalQueryTagInput>>>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalMultiGroupResponse = {
  __typename?: 'InsightsPortalMultiGroupResponse';
  dataHeaders: Maybe<Array<Maybe<InsightsPortalMultiGroupResponseDataHeaders>>>;
  dataRows: Maybe<Array<Maybe<InsightsPortalMultiGroupResponseDataRow>>>;
  totalRows: Maybe<Scalars['BigInt']>;
};

export type InsightsPortalMultiGroupResponseDataHeaders = {
  __typename?: 'InsightsPortalMultiGroupResponseDataHeaders';
  groupByName: Maybe<Scalars['String']>;
  metricName: Maybe<Scalars['String']>;
};

export type InsightsPortalMultiGroupResponseDataRow = {
  __typename?: 'InsightsPortalMultiGroupResponseDataRow';
  dataItems: Maybe<Array<Maybe<DataItem>>>;
};

export type InsightsPortalOrderBy = {
  __typename?: 'InsightsPortalOrderBy';
  columnName: Maybe<Scalars['String']>;
  orderDirection: Maybe<InsightsPortalOrderDirection>;
};

export type InsightsPortalOrderByInput = {
  columnName?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<InsightsPortalOrderDirection>;
};

export enum InsightsPortalOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  None = 'NONE'
}

export enum InsightsPortalOwner {
  RetailerTools = 'RETAILER_TOOLS',
  Unspecified = 'UNSPECIFIED'
}

export type InsightsPortalQueryTagInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum InsightsPortalSchema {
  AdsDisplayBanner = 'ADS_DISPLAY_BANNER',
  AdsSponsoredProduct = 'ADS_SPONSORED_PRODUCT',
  AdsSponsoredProductHistoricalGrowth = 'ADS_SPONSORED_PRODUCT_HISTORICAL_GROWTH',
  AggMerchCollections = 'AGG_MERCH_COLLECTIONS',
  AggMerchCollectionsHistoricalGrowth = 'AGG_MERCH_COLLECTIONS_HISTORICAL_GROWTH',
  AggMerchCollectionsRollup = 'AGG_MERCH_COLLECTIONS_ROLLUP',
  AggMerchCollectionsRollupHistoricalGrowth = 'AGG_MERCH_COLLECTIONS_ROLLUP_HISTORICAL_GROWTH',
  AggMerchPlacements = 'AGG_MERCH_PLACEMENTS',
  AggMerchPlacementsHistoricalGrowth = 'AGG_MERCH_PLACEMENTS_HISTORICAL_GROWTH',
  AggWarehouseLocationCampaignPerformance = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE',
  AggWarehouseLocationCampaignPerformanceHistoricalGrowth = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE_HISTORICAL_GROWTH',
  AggWarehouseLocationCampaignPerformanceStitchOrderDelivery = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY',
  AggWarehouseLocationCampaignPerformanceStitchOrderDeliveryHistoricalGrowth = 'AGG_WAREHOUSE_LOCATION_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY_HISTORICAL_GROWTH',
  AggWarehouseLocationPerformance = 'AGG_WAREHOUSE_LOCATION_PERFORMANCE',
  AggWarehouseLocationPerformanceHistoricalGrowth = 'AGG_WAREHOUSE_LOCATION_PERFORMANCE_HISTORICAL_GROWTH',
  AggWarehousePerformance = 'AGG_WAREHOUSE_PERFORMANCE',
  FactCampaignImpressionsPerformance = 'FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE',
  FactCampaignImpressionsPerformanceHistoricalGrowth = 'FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE_HISTORICAL_GROWTH',
  FactCampaignPerformance = 'FACT_CAMPAIGN_PERFORMANCE',
  FactCampaignPerformanceStitchFactCampaignImpressionsPerformance = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE',
  FactCampaignPerformanceStitchFactCampaignImpressionsPerformanceHistoricalGrowth = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_FACT_CAMPAIGN_IMPRESSIONS_PERFORMANCE_HISTORICAL_GROWTH',
  FactCampaignPerformanceStitchOrderDelivery = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY',
  FactCampaignPerformanceStitchOrderDeliveryHistoricalGrowth = 'FACT_CAMPAIGN_PERFORMANCE_STITCH_ORDER_DELIVERY_HISTORICAL_GROWTH',
  ImageCoverage = 'IMAGE_COVERAGE',
  ImageCoverageClickhouse = 'IMAGE_COVERAGE_CLICKHOUSE',
  ImageCoverageDemo = 'IMAGE_COVERAGE_DEMO',
  ImageCoverageHistoricalGrowth = 'IMAGE_COVERAGE_HISTORICAL_GROWTH',
  ImageCoverageHistoricalGrowthClickhouse = 'IMAGE_COVERAGE_HISTORICAL_GROWTH_CLICKHOUSE',
  InventoryIntelligenceOosSignalsAgg = 'INVENTORY_INTELLIGENCE_OOS_SIGNALS_AGG',
  MissingImage_90DayRetailerInsights = 'MISSING_IMAGE_90DAY_RETAILER_INSIGHTS',
  MissingImage_90DayRetailerInsightsClickhouse = 'MISSING_IMAGE_90DAY_RETAILER_INSIGHTS_CLICKHOUSE',
  OrderDelivery = 'ORDER_DELIVERY',
  OrderDeliveryClickhouse = 'ORDER_DELIVERY_CLICKHOUSE',
  OrderDeliveryDemo = 'ORDER_DELIVERY_DEMO',
  OrderDeliveryHistoricalGrowth = 'ORDER_DELIVERY_HISTORICAL_GROWTH',
  OrderDeliveryHistoricalGrowthClickhouse = 'ORDER_DELIVERY_HISTORICAL_GROWTH_CLICKHOUSE',
  OrderItem = 'ORDER_ITEM',
  OrderItemClickhouse = 'ORDER_ITEM_CLICKHOUSE',
  OrderItemDemo = 'ORDER_ITEM_DEMO',
  OrderItemHistoricalGrowth = 'ORDER_ITEM_HISTORICAL_GROWTH',
  OrderItemHistoricalGrowthClickhouse = 'ORDER_ITEM_HISTORICAL_GROWTH_CLICKHOUSE',
  OrderItemStitchProductByCategoryBenchmark = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK',
  OrderItemStitchProductByCategoryBenchmarkClickhouse = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK_CLICKHOUSE',
  OrderItemStitchProductByCategoryBenchmarkHistoricalGrowth = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK_HISTORICAL_GROWTH',
  OrderItemStitchProductByCategoryBenchmarkHistoricalGrowthClickhouse = 'ORDER_ITEM_STITCH_PRODUCT_BY_CATEGORY_BENCHMARK_HISTORICAL_GROWTH_CLICKHOUSE',
  ProductByCategoryBenchmark = 'PRODUCT_BY_CATEGORY_BENCHMARK',
  ProductByCategoryBenchmarkClickhouse = 'PRODUCT_BY_CATEGORY_BENCHMARK_CLICKHOUSE',
  ProductByPartnerBenchmark = 'PRODUCT_BY_PARTNER_BENCHMARK',
  ProductByPartnerBenchmarkClickhouse = 'PRODUCT_BY_PARTNER_BENCHMARK_CLICKHOUSE',
  SearchTerm = 'SEARCH_TERM',
  SearchTermByPartnerBenchmark = 'SEARCH_TERM_BY_PARTNER_BENCHMARK',
  SearchTermByPartnerBenchmarkClickhouse = 'SEARCH_TERM_BY_PARTNER_BENCHMARK_CLICKHOUSE',
  SearchTermByTermBenchmark = 'SEARCH_TERM_BY_TERM_BENCHMARK',
  SearchTermByTermBenchmarkClickhouse = 'SEARCH_TERM_BY_TERM_BENCHMARK_CLICKHOUSE',
  SearchTermClickhouse = 'SEARCH_TERM_CLICKHOUSE',
  SearchTermDemo = 'SEARCH_TERM_DEMO',
  SearchTermHistoricalGrowth = 'SEARCH_TERM_HISTORICAL_GROWTH',
  SearchTermHistoricalGrowthClickhouse = 'SEARCH_TERM_HISTORICAL_GROWTH_CLICKHOUSE',
  SearchTermStitchSearchTermByTermBenchmark = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK',
  SearchTermStitchSearchTermByTermBenchmarkClickhouse = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK_CLICKHOUSE',
  SearchTermStitchSearchTermByTermBenchmarkHistoricalGrowth = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK_HISTORICAL_GROWTH',
  SearchTermStitchSearchTermByTermBenchmarkHistoricalGrowthClickhouse = 'SEARCH_TERM_STITCH_SEARCH_TERM_BY_TERM_BENCHMARK_HISTORICAL_GROWTH_CLICKHOUSE',
  Unknown = 'UNKNOWN'
}

export type InsightsPortalSchemaMetadataRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  schema?: InputMaybe<InsightsPortalSchema>;
};

export type InsightsPortalSchemaMetadataResponse = {
  __typename?: 'InsightsPortalSchemaMetadataResponse';
  filters: Maybe<Array<Maybe<Scalars['String']>>>;
  groupBys: Maybe<Array<Maybe<Scalars['String']>>>;
  metrics: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Maybe<Scalars['String']>;
};

export type InsightsPortalSetCustomReportDisabledRequestInput = {
  authorizationContext?: InputMaybe<InsightsPortalAuthorizationContextInput>;
  customReportId?: InputMaybe<Scalars['BigInt']>;
};

export type InsightsPortalSetCustomReportDisabledResponse = {
  __typename?: 'InsightsPortalSetCustomReportDisabledResponse';
  customReport: Maybe<InsightsPortalCustomReport>;
  success: Maybe<Scalars['Boolean']>;
};

export type InsightsPortalSnowflakeHeartbeatRequestInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type InsightsPortalSnowflakeHeartbeatResponse = {
  __typename?: 'InsightsPortalSnowflakeHeartbeatResponse';
  authenticated: Maybe<Scalars['Boolean']>;
};

export type InsightsPortalValue = {
  __typename?: 'InsightsPortalValue';
  doubleValue: Maybe<Scalars['Float']>;
  intValue: Maybe<Scalars['BigInt']>;
  stringValue: Maybe<Scalars['String']>;
  timestampValue: Maybe<Scalars['DateTime']>;
};

export type InsightsPortalValueInput = {
  doubleValue?: InputMaybe<Scalars['Float']>;
  intValue?: InputMaybe<Scalars['BigInt']>;
  stringValue?: InputMaybe<Scalars['String']>;
  timestampValue?: InputMaybe<Scalars['DateTime']>;
};

export enum InstacartAdsNexusV1RegionalArea {
  Midwest = 'MIDWEST',
  Mountain = 'MOUNTAIN',
  Northeast = 'NORTHEAST',
  Pacific = 'PACIFIC',
  RegionalAreaUnknown = 'REGIONAL_AREA_UNKNOWN',
  Southeast = 'SOUTHEAST',
  Southwest = 'SOUTHWEST'
}

export enum InstacartAdsNexusV1StoreType {
  Alcohol = 'ALCOHOL',
  Asian = 'ASIAN',
  Baby = 'BABY',
  ClubWarehouse = 'CLUB_WAREHOUSE',
  Convenience = 'CONVENIENCE',
  ConventionalTraditional = 'CONVENTIONAL_TRADITIONAL',
  Coop = 'COOP',
  DepartmentStore = 'DEPARTMENT_STORE',
  Drugstore = 'DRUGSTORE',
  HomeKitchenBath = 'HOME_KITCHEN_BATH',
  LatinAmerican = 'LATIN_AMERICAN',
  LocalSpecialty = 'LOCAL_SPECIALTY',
  NaturalOrganic = 'NATURAL_ORGANIC',
  Other = 'OTHER',
  Pet = 'PET',
  StoreTypeUnknown = 'STORE_TYPE_UNKNOWN',
  Supplement = 'SUPPLEMENT',
  Value = 'VALUE'
}

export type InstacartAdsSharedV1DateRange = {
  __typename?: 'InstacartAdsSharedV1DateRange';
  endDate: Maybe<GoogleTypeDate>;
  startDate: Maybe<GoogleTypeDate>;
};

export type InstacartAdsSharedV1DateRangeInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
};

export type InstacartAdsSharedV1PaginationRequestInput = {
  page?: InputMaybe<Scalars['BigInt']>;
  resultsPerPage?: InputMaybe<Scalars['BigInt']>;
};

export type InstacartAdsSharedV1PaginationResponse = {
  __typename?: 'InstacartAdsSharedV1PaginationResponse';
  page: Maybe<Scalars['BigInt']>;
  resultsPerPage: Maybe<Scalars['BigInt']>;
  totalPages: Maybe<Scalars['BigInt']>;
};

export enum InstacartCustomersCommerceTypesV1FundingSource {
  Cpg = 'CPG',
  FundingSourceUnspecified = 'FUNDING_SOURCE_UNSPECIFIED',
  Instacart = 'INSTACART',
  Others = 'OTHERS',
  Retailer = 'RETAILER'
}

export type InstacartCustomersCommerceTypesV1SlimOffer = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOffer';
  buyMAmountGetXAmountOffScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenario>;
  buyMAmountGetXPercentOffScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenario>;
  buyMQtyForXAmountScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenario>;
  buyMQtyGetNForFreeScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenario>;
  buyMQtyGetNQtyForXAmountScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenario>;
  buyMQtyGetXAmountOffScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenario>;
  buyMQtyGetXPercentOffScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenario>;
  buyMQtyOrMoreGetXPercentOffScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenario>;
  buyMRangeQtyGetXPercentOffTieredScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenario>;
  conditionRewardString: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  discountCategoryId: Maybe<Scalars['BigInt']>;
  endsAt: Maybe<Scalars['DateTime']>;
  fundingDetail: Maybe<InstacartCustomersCommerceTypesV1SlimOfferFundingDetail>;
  imageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  offerConditions: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferCondition>>>;
  offerExternalReference: Maybe<Scalars['String']>;
  offerId: Maybe<Scalars['String']>;
  offerRequestKey: Maybe<Scalars['String']>;
  offerRewards: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferReward>>>;
  offerSource: Maybe<Scalars['String']>;
  offerType: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferType>;
  promoCode: Maybe<Scalars['String']>;
  redemptionLimits: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimits>;
  startsAt: Maybe<Scalars['DateTime']>;
  terms: Maybe<Scalars['String']>;
  uncategorizedScenario: Maybe<InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenario>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenario';
  mAmount: Maybe<GoogleTypeMoney>;
  xAmount: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenario';
  mAmount: Maybe<GoogleTypeMoney>;
  xPercent: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenarioInput = {
  mAmount?: InputMaybe<GoogleTypeMoneyInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenario';
  mQty: Maybe<InstacartTypesV1BigDecimal>;
  xAmount: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenario';
  mQty: Maybe<InstacartTypesV1BigDecimal>;
  nQty: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenario';
  mQty: Maybe<InstacartTypesV1BigDecimal>;
  nQty: Maybe<InstacartTypesV1BigDecimal>;
  xAmount: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  nQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenario';
  mQty: Maybe<InstacartTypesV1BigDecimal>;
  xAmount: Maybe<GoogleTypeMoney>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xAmount?: InputMaybe<GoogleTypeMoneyInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenario';
  mQty: Maybe<InstacartTypesV1BigDecimal>;
  xPercent: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenario';
  mQty: Maybe<InstacartTypesV1BigDecimal>;
  xPercent: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenarioInput = {
  mQty?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenario';
  mQtyMax: Maybe<InstacartTypesV1BigDecimal>;
  mQtyMin: Maybe<InstacartTypesV1BigDecimal>;
  xPercent: Maybe<InstacartTypesV1BigDecimal>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenarioInput = {
  mQtyMax?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  mQtyMin?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  xPercent?: InputMaybe<InstacartTypesV1BigDecimalInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenario';
  currentTier: Maybe<Scalars['BigInt']>;
  tiers: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenario>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenarioInput = {
  currentTier?: InputMaybe<Scalars['BigInt']>;
  tiers?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffScenarioInput>>>;
};

export enum InstacartCustomersCommerceTypesV1SlimOfferDiscountRewardType {
  AmountOff = 'AMOUNT_OFF',
  DiscountRewardTypeUnspecified = 'DISCOUNT_REWARD_TYPE_UNSPECIFIED',
  PercentOff = 'PERCENT_OFF',
  SetPrice = 'SET_PRICE'
}

export type InstacartCustomersCommerceTypesV1SlimOfferFundingDetail = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferFundingDetail';
  budgetLimit: Maybe<GoogleTypeMoney>;
  fundingProviderInfo: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformation>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferFundingDetailInput = {
  budgetLimit?: InputMaybe<GoogleTypeMoneyInput>;
  fundingProviderInfo?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformationInput>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformation = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformation';
  percent: Maybe<Scalars['BigInt']>;
  source: Maybe<InstacartCustomersCommerceTypesV1FundingSource>;
  sourceName: Maybe<Scalars['String']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferFundingProviderInformationInput = {
  percent?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<InstacartCustomersCommerceTypesV1FundingSource>;
  sourceName?: InputMaybe<Scalars['String']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferInput = {
  buyMAmountGetXAmountOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXAmountOffScenarioInput>;
  buyMAmountGetXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMAmountGetXPercentOffScenarioInput>;
  buyMQtyForXAmountScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyForXAmountScenarioInput>;
  buyMQtyGetNForFreeScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForFreeScenarioInput>;
  buyMQtyGetNQtyForXAmountScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetNQtyForXAmountScenarioInput>;
  buyMQtyGetXAmountOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXAmountOffScenarioInput>;
  buyMQtyGetXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyGetXPercentOffScenarioInput>;
  buyMQtyOrMoreGetXPercentOffScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMQtyOrMoreGetXPercentOffScenarioInput>;
  buyMRangeQtyGetXPercentOffTieredScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferBuyMRangeQtyGetXPercentOffTieredScenarioInput>;
  conditionRewardString?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountCategoryId?: InputMaybe<Scalars['BigInt']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  fundingDetail?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferFundingDetailInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  offerConditions?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferConditionInput>>>;
  offerExternalReference?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['String']>;
  offerRequestKey?: InputMaybe<Scalars['String']>;
  offerRewards?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRewardInput>>>;
  offerSource?: InputMaybe<Scalars['String']>;
  offerType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferType>;
  promoCode?: InputMaybe<Scalars['String']>;
  redemptionLimits?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimitsInput>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  terms?: InputMaybe<Scalars['String']>;
  uncategorizedScenario?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenarioInput>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferCondition = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferOfferCondition';
  allowPartialApplication: Maybe<Scalars['Boolean']>;
  collectionIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  conditionProductIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  maximumQuantity: Maybe<Scalars['BigInt']>;
  minimumQuantity: Maybe<Scalars['BigInt']>;
  minimumSpend: Maybe<GoogleTypeMoney>;
  offerConditionType: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferConditionType>;
  taxonomyNodeIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferConditionInput = {
  allowPartialApplication?: InputMaybe<Scalars['Boolean']>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  conditionProductIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  maximumQuantity?: InputMaybe<Scalars['BigInt']>;
  minimumQuantity?: InputMaybe<Scalars['BigInt']>;
  minimumSpend?: InputMaybe<GoogleTypeMoneyInput>;
  offerConditionType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferConditionType>;
  taxonomyNodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export enum InstacartCustomersCommerceTypesV1SlimOfferOfferConditionType {
  OfferConditionTypeBasket = 'OFFER_CONDITION_TYPE_BASKET',
  OfferConditionTypeBrands = 'OFFER_CONDITION_TYPE_BRANDS',
  OfferConditionTypeCategories = 'OFFER_CONDITION_TYPE_CATEGORIES',
  OfferConditionTypeCollections = 'OFFER_CONDITION_TYPE_COLLECTIONS',
  OfferConditionTypeCommerceClassifications = 'OFFER_CONDITION_TYPE_COMMERCE_CLASSIFICATIONS',
  OfferConditionTypeFees = 'OFFER_CONDITION_TYPE_FEES',
  OfferConditionTypeProducts = 'OFFER_CONDITION_TYPE_PRODUCTS',
  OfferConditionTypeRegions = 'OFFER_CONDITION_TYPE_REGIONS',
  OfferConditionTypeUnspecified = 'OFFER_CONDITION_TYPE_UNSPECIFIED'
}

export type InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimits = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimits';
  redemptionLimit: Maybe<Scalars['BigInt']>;
  redemptionLimitPerOrder: Maybe<Scalars['BigInt']>;
  redemptionLimitPerUser: Maybe<Scalars['BigInt']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferRedemptionLimitsInput = {
  redemptionLimit?: InputMaybe<Scalars['BigInt']>;
  redemptionLimitPerOrder?: InputMaybe<Scalars['BigInt']>;
  redemptionLimitPerUser?: InputMaybe<Scalars['BigInt']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferReward = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferOfferReward';
  amountDiscount: Maybe<GoogleTypeMoney>;
  discountRewardType: Maybe<InstacartCustomersCommerceTypesV1SlimOfferDiscountRewardType>;
  includeConditionGroup: Maybe<Scalars['Boolean']>;
  maximumDiscount: Maybe<GoogleTypeMoney>;
  offerPrice: Maybe<GoogleTypeMoney>;
  offerRewardType: Maybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRewardType>;
  percentDiscount: Maybe<InstacartTypesV1BigDecimal>;
  quantityLimit: Maybe<Scalars['BigInt']>;
  rewardProductIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferOfferRewardInput = {
  amountDiscount?: InputMaybe<GoogleTypeMoneyInput>;
  discountRewardType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferDiscountRewardType>;
  includeConditionGroup?: InputMaybe<Scalars['Boolean']>;
  maximumDiscount?: InputMaybe<GoogleTypeMoneyInput>;
  offerPrice?: InputMaybe<GoogleTypeMoneyInput>;
  offerRewardType?: InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferOfferRewardType>;
  percentDiscount?: InputMaybe<InstacartTypesV1BigDecimalInput>;
  quantityLimit?: InputMaybe<Scalars['BigInt']>;
  rewardProductIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export enum InstacartCustomersCommerceTypesV1SlimOfferOfferRewardType {
  OfferRewardTypeBasket = 'OFFER_REWARD_TYPE_BASKET',
  OfferRewardTypeBrands = 'OFFER_REWARD_TYPE_BRANDS',
  OfferRewardTypeCategories = 'OFFER_REWARD_TYPE_CATEGORIES',
  OfferRewardTypeCollections = 'OFFER_REWARD_TYPE_COLLECTIONS',
  OfferRewardTypeProducts = 'OFFER_REWARD_TYPE_PRODUCTS',
  OfferRewardTypeUnspecified = 'OFFER_REWARD_TYPE_UNSPECIFIED'
}

export enum InstacartCustomersCommerceTypesV1SlimOfferOfferType {
  AutoApply = 'AUTO_APPLY',
  Coupon = 'COUPON',
  OfferTypeUnspecified = 'OFFER_TYPE_UNSPECIFIED'
}

export type InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenario = {
  __typename?: 'InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenario';
  _: Maybe<Scalars['Boolean']>;
};

export type InstacartCustomersCommerceTypesV1SlimOfferUncategorizedScenarioInput = {
  _?: InputMaybe<Scalars['Boolean']>;
};

export enum InstacartCustomersSharedV1Locale {
  EnAu = 'EN_AU',
  EnCa = 'EN_CA',
  EnUs = 'EN_US',
  EsUs = 'ES_US',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  LocaleUnspecified = 'LOCALE_UNSPECIFIED'
}

export enum InstacartCustomersSharedV1OrderAttributeDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type InstacartCustomersSharedV1OrderAttributeInput = {
  direction?: InputMaybe<InstacartCustomersSharedV1OrderAttributeDirection>;
  name?: InputMaybe<Scalars['String']>;
};

export type InstacartCustomersSharedV1OrderInput = {
  attributes?: InputMaybe<Array<InputMaybe<InstacartCustomersSharedV1OrderAttributeInput>>>;
};

export type InstacartCustomersSharedV1PageInfo = {
  __typename?: 'InstacartCustomersSharedV1PageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Maybe<Scalars['Boolean']>;
};

export type InstacartCustomersSharedV1PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type InstacartCustomersSharedV1RequestContext = {
  __typename?: 'InstacartCustomersSharedV1RequestContext';
  clientConfigurationId: Maybe<Scalars['Int']>;
  countryId: Maybe<Scalars['Int']>;
  storeConfigurationId: Maybe<Scalars['Int']>;
};

export type InstacartCustomersSharedV1RequestContextInput = {
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export type InstacartEnterpriseBobaCoreTypesV1AddressDashboardCreationParamsInput = {
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1AddressInformation = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1AddressInformation';
  city: Maybe<Scalars['String']>;
  streetName: Maybe<Scalars['String']>;
  unitNumber: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1CustomerInformation = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1CustomerInformation';
  customerFirstName: Maybe<Scalars['String']>;
  customerFullName: Maybe<Scalars['String']>;
  customerLastName: Maybe<Scalars['String']>;
  customerPhoneNumber: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrder = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrder';
  addressInformation: Maybe<InstacartEnterpriseBobaCoreTypesV1AddressInformation>;
  addressLatitude: Maybe<Scalars['Float']>;
  addressLongitude: Maybe<Scalars['Float']>;
  alcoholic: Maybe<Scalars['Boolean']>;
  cancellationOrigin: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderCancellationOrigin>;
  cancellationReason: Maybe<Scalars['String']>;
  carModelAndColor: Maybe<Scalars['String']>;
  customerContext: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderCustomerContext>;
  customerInformation: Maybe<InstacartEnterpriseBobaCoreTypesV1CustomerInformation>;
  dashboardOptions: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderOptions>;
  displayId: Maybe<Scalars['BigInt']>;
  estimatedDeliveryTime: Maybe<Scalars['DateTime']>;
  estimatedPickupTime: Maybe<Scalars['DateTime']>;
  externalId: Maybe<Scalars['String']>;
  formattedTipAmount: Maybe<Scalars['String']>;
  fulfillmentDetails: Maybe<InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails>;
  id: Maybe<Scalars['BigInt']>;
  initialTip: Maybe<Scalars['Float']>;
  orderDeliveryId: Maybe<Scalars['BigInt']>;
  orderHistoryEvents: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderHistoryEvent>>>;
  previouslyRescheduled: Maybe<Scalars['Boolean']>;
  retailerProvidedAmounts: Maybe<InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmounts>;
  shopperAvatarUrl: Maybe<Scalars['String']>;
  shopperFirstName: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['BigInt']>;
  warehouseId: Maybe<Scalars['Int']>;
  warehouseLocationId: Maybe<Scalars['BigInt']>;
  warehouseLocationName: Maybe<Scalars['String']>;
  workflowState: Maybe<Scalars['String']>;
};

export enum InstacartEnterpriseBobaCoreTypesV1DashboardOrderCancellationOrigin {
  CancellationOriginUnspecified = 'CANCELLATION_ORIGIN_UNSPECIFIED',
  Customer = 'CUSTOMER',
  Instacart = 'INSTACART',
  Other = 'OTHER',
  Retailer = 'RETAILER',
  Shopper = 'SHOPPER'
}

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderCustomerContext = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderCustomerContext';
  clientConfigurationId: Maybe<Scalars['Int']>;
  countryId: Maybe<Scalars['Int']>;
  storeConfigurationId: Maybe<Scalars['Int']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderOptions = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderOptions';
  leaveUnattended: Maybe<Scalars['Boolean']>;
  specialInstructions: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummary = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummary';
  bagCount: Maybe<Scalars['Int']>;
  bagLabel: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deliveryWindow: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryDeliveryWindow>;
  fulfillmentTimes: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryFulfillmentTimes>;
  id: Maybe<Scalars['BigInt']>;
  legacyOrderId: Maybe<Scalars['BigInt']>;
  orderReference: Maybe<Scalars['String']>;
  orderStatus: Maybe<InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryOrderStatus>;
  retailerLocationId: Maybe<Scalars['BigInt']>;
  retailerReferenceId: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['BigInt']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryDeliveryWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryDeliveryWindow';
  windowEndsAt: Maybe<Scalars['DateTime']>;
  windowStartsAt: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryFulfillmentTimes = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryFulfillmentTimes';
  shopperDeliveredAt: Maybe<Scalars['DateTime']>;
  shopperDispatchedAt: Maybe<Scalars['DateTime']>;
  shopperEstimatedDeliveredAt: Maybe<Scalars['DateTime']>;
  shopperEstimatedPickedAt: Maybe<Scalars['DateTime']>;
  shopperPickedAt: Maybe<Scalars['DateTime']>;
  shopperStartedDeliveryAt: Maybe<Scalars['DateTime']>;
};

export enum InstacartEnterpriseBobaCoreTypesV1DashboardOrderSummaryOrderStatus {
  OrderCancelled = 'ORDER_CANCELLED',
  OrderComplete = 'ORDER_COMPLETE',
  OrderDelayed = 'ORDER_DELAYED',
  OrderEnRoute = 'ORDER_EN_ROUTE',
  OrderInProgress = 'ORDER_IN_PROGRESS',
  OrderPlaced = 'ORDER_PLACED',
  WaitingForDelivery = 'WAITING_FOR_DELIVERY'
}

export type InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails';
  addressId: Maybe<Scalars['BigInt']>;
  fulfillmentType: Maybe<Scalars['String']>;
  numOfBags: Maybe<Scalars['BigInt']>;
  serviceOptionId: Maybe<Scalars['BigInt']>;
  warehouseLocationId: Maybe<Scalars['BigInt']>;
  windowEndsAt: Maybe<Scalars['DateTime']>;
  windowStartsAt: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ItemReplacement = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ItemReplacement';
  itemRrc: Maybe<Scalars['String']>;
  itemUpc: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1LastMileOrderDashboardCreationParamsInput = {
  addressCreationParams?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1AddressDashboardCreationParamsInput>;
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  endAt?: InputMaybe<Scalars['String']>;
  externalData?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput>;
  initialTip?: InputMaybe<Scalars['Float']>;
  locale?: InputMaybe<Scalars['String']>;
  locationCode?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderOptionsInput>;
  retailerProvidedAmounts?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmountsInput>;
  serviceOptionId?: InputMaybe<Scalars['BigInt']>;
  serviceOptionReference?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
  userBirthday?: InputMaybe<Scalars['String']>;
  userCreationParams?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1UserDashboardCreationParamsInput>;
  userPhone?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ModificationEventInput = {
  reason?: InputMaybe<Scalars['String']>;
  reasonDetailed?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventUserType>;
};

export enum InstacartEnterpriseBobaCoreTypesV1ModificationEventUserType {
  Rpp = 'RPP',
  UserTypeUnspecified = 'USER_TYPE_UNSPECIFIED'
}

export type InstacartEnterpriseBobaCoreTypesV1Order = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1Order';
  canceledAt: Maybe<Scalars['DateTime']>;
  cancellationReason: Maybe<Scalars['String']>;
  cancellationType: Maybe<Scalars['String']>;
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deliveredAt: Maybe<Scalars['DateTime']>;
  deliveryEta: Maybe<Scalars['DateTime']>;
  displayId: Maybe<Scalars['BigInt']>;
  externalData: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalData>;
  fulfillmentDetails: Maybe<InstacartEnterpriseBobaCoreTypesV1FulfillmentDetails>;
  id: Maybe<Scalars['BigInt']>;
  isExpress: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  loyaltyCardId: Maybe<Scalars['BigInt']>;
  orderDeliveryId: Maybe<Scalars['BigInt']>;
  postCheckoutLink: Maybe<Scalars['String']>;
  statusUrl: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  userId: Maybe<Scalars['BigInt']>;
  warehouseId: Maybe<Scalars['BigInt']>;
  warnings: Maybe<Array<Maybe<InstacartEnterpriseBobaCoreTypesV1OrderWarning>>>;
  workflowState: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderExternalData = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderExternalData';
  externalId: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput = {
  externalId?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderHistoryEvent = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderHistoryEvent';
  action: Maybe<Scalars['String']>;
  actionedAt: Maybe<Scalars['DateTime']>;
  windowEndsAt: Maybe<Scalars['DateTime']>;
  windowStartsAt: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItem = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItem';
  createdAt: Maybe<Scalars['DateTime']>;
  externalData: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemExternalData>;
  id: Maybe<Scalars['BigInt']>;
  isAddOnItem: Maybe<Scalars['Boolean']>;
  itemCodes: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemCodes>;
  itemId: Maybe<Scalars['BigInt']>;
  itemProperties: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemProperties>;
  orderId: Maybe<Scalars['BigInt']>;
  pickedAt: Maybe<Scalars['DateTime']>;
  quantity: Maybe<InstacartEnterpriseBobaCoreTypesV1OrderItemQuantity>;
  replacementItem: Maybe<InstacartEnterpriseBobaCoreTypesV1ItemReplacement>;
  replacementPolicy: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  substituteId: Maybe<Scalars['BigInt']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemCodes = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemCodes';
  deliveredRrc: Maybe<Scalars['String']>;
  deliveredUpc: Maybe<Scalars['String']>;
  requestedRrc: Maybe<Scalars['String']>;
  requestedUpc: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemExternalData = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemExternalData';
  itemRrc: Maybe<Scalars['String']>;
  itemUpc: Maybe<Scalars['String']>;
  lineNum: Maybe<Scalars['String']>;
  scanCode: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemProperties = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemProperties';
  name: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['BigInt']>;
  productType: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderItemQuantity = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderItemQuantity';
  countDelivered: Maybe<Scalars['Int']>;
  countOrdered: Maybe<Scalars['Int']>;
  unit: Maybe<Scalars['String']>;
  weightDelivered: Maybe<Scalars['Float']>;
  weightOrdered: Maybe<Scalars['Float']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderOptionsInput = {
  leaveUnattended?: InputMaybe<Scalars['Boolean']>;
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
  specialInstructions?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1OrderWarning = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1OrderWarning';
  errorCode: Maybe<Scalars['Int']>;
  itemCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  message: Maybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ReopenLastmileOrderParamsInput = {
  canceledExternalOrderId?: InputMaybe<Scalars['String']>;
  canceledOrderId?: InputMaybe<Scalars['BigInt']>;
  endAt?: InputMaybe<Scalars['String']>;
  externalData?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput>;
  serviceOptionId?: InputMaybe<Scalars['BigInt']>;
  serviceOptionReference?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1RescheduleOrderParamsInput = {
  endAt?: InputMaybe<Scalars['String']>;
  externalData?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1OrderExternalDataInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  serviceOptionId?: InputMaybe<Scalars['BigInt']>;
  serviceOptionReference?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['String']>;
};

export type InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmounts = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmounts';
  bagLabel: Maybe<Scalars['String']>;
  bagScanCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  bagsCount: Maybe<Scalars['Int']>;
  cartTotal: Maybe<Scalars['Float']>;
  itemsCount: Maybe<Scalars['Int']>;
  itemsWeight: Maybe<Scalars['Float']>;
};

export type InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmountsInput = {
  bagLabel?: InputMaybe<Scalars['String']>;
  bagScanCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  cartTotal?: InputMaybe<Scalars['Float']>;
  itemsCount?: InputMaybe<Scalars['Int']>;
  itemsWeight?: InputMaybe<Scalars['Float']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOption = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOption';
  asapWindow: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionAsapWindow>;
  availability: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionAvailability>;
  date: Maybe<GoogleTypeDate>;
  id: Maybe<Scalars['BigInt']>;
  immediateWindow: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionImmediateWindow>;
  scheduledWindow: Maybe<InstacartEnterpriseBobaCoreTypesV1ServiceOptionScheduledWindow>;
  serviceOptionReference: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  warehouseId: Maybe<Scalars['Int']>;
  warehouseLocationId: Maybe<Scalars['Int']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionAsapWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionAsapWindow';
  asap: Maybe<Scalars['Boolean']>;
  descriptor: Maybe<Scalars['String']>;
  endAt: Maybe<Scalars['DateTime']>;
  startAt: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionAvailability = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionAvailability';
  available: Maybe<Scalars['Boolean']>;
  itemCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  reasons: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionImmediateWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionImmediateWindow';
  descriptor: Maybe<Scalars['String']>;
  endAt: Maybe<Scalars['DateTime']>;
  immediateHour: Maybe<Scalars['Int']>;
  startAt: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1ServiceOptionScheduledWindow = {
  __typename?: 'InstacartEnterpriseBobaCoreTypesV1ServiceOptionScheduledWindow';
  descriptor: Maybe<Scalars['String']>;
  endAt: Maybe<Scalars['DateTime']>;
  startAt: Maybe<Scalars['DateTime']>;
};

export type InstacartEnterpriseBobaCoreTypesV1UserDashboardCreationParamsInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  userPhone?: InputMaybe<Scalars['String']>;
};

export type InstacartStoreConfiguration = {
  __typename?: 'InstacartStoreConfiguration';
  /** Indicates if the Store Configuration is active or not */
  active: Scalars['Boolean'];
  /** Flag to indicate if this StoreConfiguration is associated to all warehouses */
  allowAllWarehouses: Scalars['Boolean'];
  /** The brand name as customers would see on Instacart, PBI, etc */
  brandedName: Scalars['String'];
  /** When the Store Configuration was created */
  createdAt: Scalars['Time'];
  /** Unata, etc... */
  deApplicationPlatform: Maybe<Scalars['String']>;
  /** The domain name of the Storefront (eg. costco.ca) */
  domainName: Maybe<Scalars['String']>;
  /** A unique identifier for the Store Configuration */
  id: Scalars['ID'];
  /** Legal data */
  legal: Maybe<InstacartStoreConfigurationLegal>;
  /** The primary logo URL */
  logoUrl: Scalars['String'];
  /** The rules on how Partners can access this Store Configuration */
  partnerAccessSettings: InstacartStoreConfigurationPartnerAccessSettings;
  /** The unique slug for this Store Configuration */
  slug: Scalars['String'];
  /** When the Store Configuration was last updated */
  updatedAt: Scalars['Time'];
  /** Indicates if the user is permitted to modify data related to this Store Configuration */
  userAccessToModify: Scalars['Boolean'];
  /** The unique identifier for the User Isolation Context this Store Configuration uses */
  userIsolationContextId: Scalars['ID'];
  /** The list of associated warehouses */
  whitelistedWarehouseIds: Maybe<Array<Scalars['ID']>>;
};

export type InstacartStoreConfigurationLegal = {
  __typename?: 'InstacartStoreConfigurationLegal';
  /** The shorter name for business entity */
  shortName: Scalars['String'];
};

export type InstacartStoreConfigurationModificationRules = {
  __typename?: 'InstacartStoreConfigurationModificationRules';
  /** The list of Partner IDs who are permitted to make modificaftions to this Store Configuration */
  allowlistPartnerIds: Maybe<Array<Scalars['ID']>>;
};

export type InstacartStoreConfigurationPartnerAccessSettings = {
  __typename?: 'InstacartStoreConfigurationPartnerAccessSettings';
  /** The ruleset for allowing modifications for this Store Configuration */
  modificationRules: Maybe<InstacartStoreConfigurationModificationRules>;
  /** True if Partners are allow to view this Store Configuration on IPP */
  visibleToPartnersOnIpp: Scalars['Boolean'];
};

export type InstacartTypesV1BigDecimal = {
  __typename?: 'InstacartTypesV1BigDecimal';
  nanos: Maybe<Scalars['Int']>;
  units: Maybe<Scalars['BigInt']>;
};

export type InstacartTypesV1BigDecimalInput = {
  nanos?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['BigInt']>;
};

export type InstacartTypesV1MeasuredQuantityInput = {
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<InstacartTypesV1MeasurementUnitInput>;
};

export enum InstacartTypesV1MeasurementUnitCostUnit {
  CostUnitUnspecified = 'COST_UNIT_UNSPECIFIED',
  Each = 'EACH',
  FlOz = 'FL_OZ',
  Gallon = 'GALLON',
  Gram = 'GRAM',
  Kg = 'KG',
  Lb = 'LB',
  Liter = 'LITER',
  Ml = 'ML',
  Oz = 'OZ',
  Pint = 'PINT',
  Quart = 'QUART'
}

export type InstacartTypesV1MeasurementUnitInput = {
  costUnit?: InputMaybe<InstacartTypesV1MeasurementUnitCostUnit>;
  unitCode?: InputMaybe<Scalars['String']>;
};

export type InstacartTypesV1RequestContextInput = {
  applicationId?: InputMaybe<Scalars['Int']>;
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export type InventoryFileMapping = {
  __typename?: 'InventoryFileMapping';
  collectionSlug: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  retailerCollection: Maybe<RetailerCollection>;
  retailerCollectionId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type InventoryFileMismatch = {
  __typename?: 'InventoryFileMismatch';
  collectionSlug: Maybe<Scalars['String']>;
  count: Maybe<Scalars['BigInt']>;
  lastSeen: Maybe<Scalars['DateTime']>;
  leadCode: Maybe<Scalars['String']>;
};

export type IppAccountInformationInput = {
  canonicalId?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type IroncladData = {
  __typename?: 'IroncladData';
  isaGroupKey: Maybe<Scalars['String']>;
  ndaGroupKey: Maybe<Scalars['String']>;
  siteId: Maybe<Scalars['String']>;
  useIronclad: Maybe<Scalars['Boolean']>;
};

export type Item = {
  __typename?: 'Item';
  localeCode: Maybe<Scalars['String']>;
  primaryAisleId: Maybe<Scalars['BigInt']>;
  primaryDepartmentId: Maybe<Scalars['BigInt']>;
  primaryImageUrl: Maybe<Scalars['String']>;
  productCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  productDisplayName: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
  retailerReferenceCode: Maybe<Scalars['String']>;
};

export type ItemId = {
  __typename?: 'ItemId';
  itemId: Maybe<Scalars['BigInt']>;
};

export type ItemIdInput = {
  itemId?: InputMaybe<Scalars['BigInt']>;
};

export type ItemList = {
  __typename?: 'ItemList';
  cta: Maybe<Scalars['String']>;
  ctaVisibilityMin: Maybe<Scalars['BigInt']>;
  dataQuery: Maybe<DataQuery>;
  disclaimer: Maybe<Scalars['String']>;
  iconUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  placementId: Maybe<Scalars['BigInt']>;
  selectable: Maybe<Scalars['Boolean']>;
  subTitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  variant: Maybe<ItemListVariant>;
};

export type ItemListInput = {
  cta?: InputMaybe<Scalars['String']>;
  ctaVisibilityMin?: InputMaybe<Scalars['BigInt']>;
  dataQuery?: InputMaybe<DataQueryInput>;
  disclaimer?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  selectable?: InputMaybe<Scalars['Boolean']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<ItemListVariant>;
};

export enum ItemListVariant {
  CarouselItemList = 'CAROUSEL_ITEM_LIST',
  CouponList = 'COUPON_LIST',
  DoubleDeckerItemList = 'DOUBLE_DECKER_ITEM_LIST',
  FilterableItemList = 'FILTERABLE_ITEM_LIST',
  GridCouponList = 'GRID_COUPON_LIST',
  GridItemList = 'GRID_ITEM_LIST',
  ItemList = 'ITEM_LIST',
  UnspecifiedItemList = 'UNSPECIFIED_ITEM_LIST'
}

export type ItemsSchema = {
  __typename?: 'ItemsSchema';
  promoPricingBackgroundColor: Maybe<AtomsColor>;
  promoPricingTextColor: Maybe<AtomsColor>;
};

export type KnowledgeOwlConfiguration = {
  __typename?: 'KnowledgeOwlConfiguration';
  knowledgeOwl: KnowledgeOwlType;
};

export type KnowledgeOwlType = {
  __typename?: 'KnowledgeOwlType';
  /** Knowledge Base Domain */
  domain: Scalars['String'];
  /** OAuth Token */
  oauthToken: Scalars['String'];
  /** Knowledge Base Project ID */
  projectId: Scalars['String'];
};

export type LandingSchema = {
  __typename?: 'LandingSchema';
  landingSkipPostalCode: Maybe<Scalars['Boolean']>;
};

export type LaunchOnboarding = {
  __typename?: 'LaunchOnboarding';
  createdAt: Maybe<Scalars['DateTime']>;
  createdByUserId: Maybe<Scalars['BigInt']>;
  entityName: Maybe<Scalars['String']>;
  expansionsOwnerUserId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  ippAccountUuid: Maybe<Scalars['String']>;
  partner: Maybe<LaunchOnboardingPartner>;
  tamOnboardingOwnerUserId: Maybe<Scalars['BigInt']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  warehouse: Maybe<LaunchOnboardingWarehouse>;
  warehouseLocations: Maybe<Array<Maybe<LaunchOnboardingWarehouseLocation>>>;
  workflowState: Maybe<Scalars['String']>;
};

export type LaunchOnboardingAddress = {
  __typename?: 'LaunchOnboardingAddress';
  apartmentNumber: Maybe<Scalars['String']>;
  approvalRequests: Maybe<Array<Maybe<LaunchOnboardingApprovalRequest>>>;
  approved: Maybe<Scalars['Boolean']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
};

export type LaunchOnboardingAddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']>;
  approvalRequests?: InputMaybe<Array<InputMaybe<LaunchOnboardingApprovalRequestInput>>>;
  approved?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

export type LaunchOnboardingApprovalRequest = {
  __typename?: 'LaunchOnboardingApprovalRequest';
  approveableId: Maybe<Scalars['BigInt']>;
  approveableType: Maybe<Scalars['String']>;
  attributeName: Maybe<Scalars['String']>;
  attributeValue: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  status: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  userId: Maybe<Scalars['BigInt']>;
};

export type LaunchOnboardingApprovalRequestInput = {
  approveableId?: InputMaybe<Scalars['BigInt']>;
  approveableType?: InputMaybe<Scalars['String']>;
  attributeName?: InputMaybe<Scalars['String']>;
  attributeValue?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type LaunchOnboardingPartner = {
  __typename?: 'LaunchOnboardingPartner';
  createdAt: Maybe<Scalars['DateTime']>;
  externalId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  partnerType: Maybe<Scalars['String']>;
  salesforceId: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type LaunchOnboardingWarehouse = {
  __typename?: 'LaunchOnboardingWarehouse';
  allowAlcohol: Maybe<Scalars['Boolean']>;
  approvalRequests: Maybe<Array<Maybe<LaunchOnboardingApprovalRequest>>>;
  approved: Maybe<Scalars['Boolean']>;
  backgroundColor: Maybe<Scalars['String']>;
  categories: Maybe<Array<Maybe<Scalars['String']>>>;
  certifiedDeliveryThreshold: Maybe<Scalars['Float']>;
  country: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  externalId: Maybe<Scalars['BigInt']>;
  franchiseeId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  logo: Maybe<Scalars['String']>;
  merchantId: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  pricingPolicy: Maybe<Scalars['String']>;
  pricingPolicyText: Maybe<Scalars['String']>;
  retailerType: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  visible: Maybe<Scalars['Boolean']>;
  warehouseLocations: Maybe<Array<Maybe<LaunchOnboardingWarehouseLocation>>>;
};

export type LaunchOnboardingWarehouseLocation = {
  __typename?: 'LaunchOnboardingWarehouseLocation';
  address: Maybe<LaunchOnboardingAddress>;
  approvalRequests: Maybe<Array<Maybe<LaunchOnboardingApprovalRequest>>>;
  approved: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['DateTime']>;
  externalId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  inventoryAreaId: Maybe<Scalars['BigInt']>;
  isochroneData: Maybe<Scalars['String']>;
  locationCode: Maybe<Scalars['String']>;
  merchantId: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum LaunchOnboardingWorkflowStateAction {
  LaunchOnboardingWorkflowStateActionCompleteInitialOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_COMPLETE_INITIAL_ONBOARDING',
  LaunchOnboardingWorkflowStateActionCompleteLaunchOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_COMPLETE_LAUNCH_ONBOARDING',
  LaunchOnboardingWorkflowStateActionCreateIppAccount = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_CREATE_IPP_ACCOUNT',
  LaunchOnboardingWorkflowStateActionLaunch = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_LAUNCH',
  LaunchOnboardingWorkflowStateActionProvideInitialWarehouseInfo = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_INITIAL_WAREHOUSE_INFO',
  LaunchOnboardingWorkflowStateActionProvideInitialWarehouseLocationsInfo = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_INITIAL_WAREHOUSE_LOCATIONS_INFO',
  LaunchOnboardingWorkflowStateActionSendEmailInvite = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_SEND_EMAIL_INVITE',
  LaunchOnboardingWorkflowStateActionStartInitialOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_START_INITIAL_ONBOARDING',
  LaunchOnboardingWorkflowStateActionStartLaunchOnboarding = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_START_LAUNCH_ONBOARDING',
  LaunchOnboardingWorkflowStateActionUnknown = 'LAUNCH_ONBOARDING_WORKFLOW_STATE_ACTION_UNKNOWN'
}

export enum Layout {
  ActionSheet = 'ACTION_SHEET',
  BloomTargetStorefront = 'BLOOM_TARGET_STOREFRONT',
  BloomTargetStorefrontDealsLists = 'BLOOM_TARGET_STOREFRONT_DEALS_LISTS',
  BrowseDepartments = 'BROWSE_DEPARTMENTS',
  BrowseFeed = 'BROWSE_FEED',
  CampaignPlacement = 'CAMPAIGN_PLACEMENT',
  CartMessages = 'CART_MESSAGES',
  CheckoutAisle = 'CHECKOUT_AISLE',
  CheckoutAisleNewUser = 'CHECKOUT_AISLE_NEW_USER',
  CollectionDepartment = 'COLLECTION_DEPARTMENT',
  CollectionHub = 'COLLECTION_HUB',
  CollectionHubOnLoad = 'COLLECTION_HUB_ON_LOAD',
  CrossRetailerSearchEyebrow = 'CROSS_RETAILER_SEARCH_EYEBROW',
  CustomStorefront = 'CUSTOM_STOREFRONT',
  DealsTabHero = 'DEALS_TAB_HERO',
  DealsTabSecondary = 'DEALS_TAB_SECONDARY',
  EntStorefront = 'ENT_STOREFRONT',
  FlyoutModal = 'FLYOUT_MODAL',
  Home = 'HOME',
  HomeCategoryForward = 'HOME_CATEGORY_FORWARD',
  HomeEyebrow = 'HOME_EYEBROW',
  HomeFeed = 'HOME_FEED',
  HomeFeedAlcohol = 'HOME_FEED_ALCOHOL',
  HomeFeedBeauty = 'HOME_FEED_BEAUTY',
  HomeFeedConvenience = 'HOME_FEED_CONVENIENCE',
  HomeFeedDefault = 'HOME_FEED_DEFAULT',
  HomeFeedGifts = 'HOME_FEED_GIFTS',
  HomeFeedGrocery = 'HOME_FEED_GROCERY',
  HomeFeedHeader = 'HOME_FEED_HEADER',
  HomeFeedNewUser = 'HOME_FEED_NEW_USER',
  HomeFeedOpenLate = 'HOME_FEED_OPEN_LATE',
  HomeFeedPets = 'HOME_FEED_PETS',
  HomeFeedPharmacy = 'HOME_FEED_PHARMACY',
  HomeFeedUseCaseTiles = 'HOME_FEED_USE_CASE_TILES',
  HomeFeedWholesale = 'HOME_FEED_WHOLESALE',
  HomeHero = 'HOME_HERO',
  HomeItemForward = 'HOME_ITEM_FORWARD',
  HomeMarketplace = 'HOME_MARKETPLACE',
  HomeOnLoad = 'HOME_ON_LOAD',
  HomeSecondary = 'HOME_SECONDARY',
  HomeSuperSaver = 'HOME_SUPER_SAVER',
  Hub = 'HUB',
  HubHeader = 'HUB_HEADER',
  InStoreDeals = 'IN_STORE_DEALS',
  IsolatedStorefront = 'ISOLATED_STOREFRONT',
  ItemDetailsRecommendations = 'ITEM_DETAILS_RECOMMENDATIONS',
  MealsTabOnStorefront = 'MEALS_TAB_ON_STOREFRONT',
  NewVerticalsStorefront = 'NEW_VERTICALS_STOREFRONT',
  OrderStatusRecommendations = 'ORDER_STATUS_RECOMMENDATIONS',
  OrderStatusSecondary = 'ORDER_STATUS_SECONDARY',
  PostCheckoutRecommendations = 'POST_CHECKOUT_RECOMMENDATIONS',
  PostCheckoutSheet = 'POST_CHECKOUT_SHEET',
  PostCheckoutSlot_1 = 'POST_CHECKOUT_SLOT_1',
  RecommendationsTray = 'RECOMMENDATIONS_TRAY',
  RetailerStories = 'RETAILER_STORIES',
  Search = 'SEARCH',
  ShopContentPage = 'SHOP_CONTENT_PAGE',
  Storefront = 'STOREFRONT',
  StorefrontCoachmark = 'STOREFRONT_COACHMARK',
  StorefrontEyebrow = 'STOREFRONT_EYEBROW',
  StorefrontHero = 'STOREFRONT_HERO',
  StorefrontNavigationEmbeddedApp = 'STOREFRONT_NAVIGATION_EMBEDDED_APP',
  StorefrontNavigationFooter = 'STOREFRONT_NAVIGATION_FOOTER',
  StorefrontNavigationHeader = 'STOREFRONT_NAVIGATION_HEADER',
  StorefrontNavigationItem = 'STOREFRONT_NAVIGATION_ITEM',
  StorefrontNewUser = 'STOREFRONT_NEW_USER',
  StorefrontOnLoad = 'STOREFRONT_ON_LOAD',
  StorefrontOverHeader = 'STOREFRONT_OVER_HEADER',
  StorefrontSuperSaver = 'STOREFRONT_SUPER_SAVER',
  TreatmentOffersForYou = 'TREATMENT_OFFERS_FOR_YOU',
  TreatmentSheet = 'TREATMENT_SHEET',
  TreatmentTracker = 'TREATMENT_TRACKER',
  YourItems = 'YOUR_ITEMS'
}

export type LayoutVariant = {
  __typename?: 'LayoutVariant';
  contentPageId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  placements: Maybe<Array<Maybe<Placement>>>;
  platform: Maybe<Array<Maybe<PlatformType>>>;
  previewToken: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['BigInt']>;
  status: Maybe<Status>;
};

export type LayoutVariantInput = {
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  placements?: InputMaybe<Array<InputMaybe<PlacementInput>>>;
  platform?: InputMaybe<Array<InputMaybe<PlatformType>>>;
  previewToken?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Status>;
};

export type LegacyBaseImage = {
  __typename?: 'LegacyBaseImage';
  /** The image url */
  url: Maybe<Scalars['String']>;
};

export type LegacyCountry = {
  __typename?: 'LegacyCountry';
  /** Unique identifier */
  id: Scalars['ID'];
  /** country name */
  name: Scalars['String'];
};

export type LegacyDeliveryHours = {
  __typename?: 'LegacyDeliveryHours';
  /** Date of Delivery Hours */
  date: Scalars['LegacyDate'];
  /** Ending hour of Delivery Hours on specified date */
  endsAtHour: Scalars['Int'];
  /** Starting hour of Delivery Hours on specified date */
  startsAtHour: Scalars['Int'];
  /** The associated warehouse of the delivery hours */
  warehouse: LegacyWarehouse;
};

export type LegacyElasticsearchResponse = {
  __typename?: 'LegacyElasticsearchResponse';
  docs: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
};

export type LegacyHealthCheck = {
  __typename?: 'LegacyHealthCheck';
  /** Server health status */
  ok: Scalars['Boolean'];
};

export type LegacyItemTyped = {
  __typename?: 'LegacyItemTyped';
  aisleId: Maybe<Scalars['BigInt']>;
  aisleName: Maybe<Scalars['String']>;
  alcoholic: Maybe<Scalars['Boolean']>;
  allergensKeywords: Maybe<Array<Maybe<Scalars['String']>>>;
  available: Maybe<Scalars['Boolean']>;
  brandName: Maybe<Scalars['String']>;
  configurableProducts: Maybe<Scalars['JSONObject']>;
  costPricePerUnit: Maybe<InstacartTypesV1BigDecimal>;
  costUnit: Maybe<Scalars['String']>;
  dairyFree: Maybe<Scalars['Boolean']>;
  departmentId: Maybe<Scalars['BigInt']>;
  departmentName: Maybe<Scalars['String']>;
  displayName: Maybe<Scalars['String']>;
  fullPrice: Maybe<InstacartTypesV1BigDecimal>;
  halal: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['BigInt']>;
  imageUrl: Maybe<Scalars['String']>;
  inStoreLocation: Maybe<Scalars['String']>;
  inventoryAreaId: Maybe<Scalars['BigInt']>;
  isCatering: Maybe<Scalars['Boolean']>;
  isHot: Maybe<Scalars['Boolean']>;
  isPackagedByWeight: Maybe<Scalars['Boolean']>;
  ketoDiet: Maybe<Scalars['Boolean']>;
  largeImageUrl: Maybe<Scalars['String']>;
  localeCode: Maybe<Scalars['String']>;
  lowSodium: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  nonenumeratedManagedAttributes: Maybe<NonenumeratedManagedAttributes>;
  normalizedHeightCm: Maybe<InstacartTypesV1BigDecimal>;
  normalizedLengthCm: Maybe<InstacartTypesV1BigDecimal>;
  normalizedWeightG: Maybe<InstacartTypesV1BigDecimal>;
  normalizedWidthCm: Maybe<InstacartTypesV1BigDecimal>;
  parWeight: Maybe<InstacartTypesV1BigDecimal>;
  price: Maybe<InstacartTypesV1BigDecimal>;
  pricePerUnit: Maybe<InstacartTypesV1BigDecimal>;
  primaryImageUrl: Maybe<Scalars['String']>;
  productCategory: Maybe<ProductCategory>;
  productCategoryTaxonomy: Maybe<ProductCategoryTaxonomy>;
  productCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  productEnrichmentKeywords: Maybe<Array<Maybe<Scalars['String']>>>;
  productId: Maybe<Scalars['BigInt']>;
  productType: Maybe<Scalars['String']>;
  requestUnit: Maybe<LegacyItemTypedRequestUnit>;
  restrictedOtc: Maybe<Scalars['Boolean']>;
  restrictedReasons: Maybe<Array<Maybe<Scalars['String']>>>;
  retailerMetadata: Maybe<LegacyItemTypedLegacyRetailerMetadataTyped>;
  retailerOmsEligible: Maybe<Scalars['Boolean']>;
  retailerProductSize: Maybe<Scalars['String']>;
  retailerReferenceCode: Maybe<Scalars['String']>;
  retailerSpecificMetadata: Maybe<LegacyItemTypedLegacyRetailerSpecificMetadataTyped>;
  shopperHeroImageUrl: Maybe<Scalars['String']>;
  shopperHeroLargeImageUrl: Maybe<Scalars['String']>;
  shouldScan: Maybe<Scalars['Boolean']>;
  size: Maybe<Scalars['String']>;
  sizeUomVariable: Maybe<Scalars['String']>;
  storageTemp: Maybe<Scalars['String']>;
  sugarFree: Maybe<Scalars['Boolean']>;
  suggestedReplacementIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  syntheticCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  tobacco: Maybe<Scalars['Boolean']>;
  unitCount: Maybe<Scalars['BigInt']>;
  unitPrice: Maybe<InstacartTypesV1BigDecimal>;
  unlisted: Maybe<Scalars['Boolean']>;
  usaSnapEligible: Maybe<Scalars['Boolean']>;
  variantDimensionValues: Maybe<Array<Maybe<LegacyItemTypedLegacyVariantDimensionValueTyped>>>;
  warehouseId: Maybe<Scalars['BigInt']>;
};

export type LegacyItemTypedLegacyRetailerMetadataTyped = {
  __typename?: 'LegacyItemTypedLegacyRetailerMetadataTyped';
  aldiProductCode: Maybe<Scalars['String']>;
};

export type LegacyItemTypedLegacyRetailerSpecificMetadataTyped = {
  __typename?: 'LegacyItemTypedLegacyRetailerSpecificMetadataTyped';
  aldiProductCode: Maybe<Scalars['String']>;
};

export type LegacyItemTypedLegacyVariantDimensionValueTyped = {
  __typename?: 'LegacyItemTypedLegacyVariantDimensionValueTyped';
  heading: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export enum LegacyItemTypedRequestUnit {
  Each = 'EACH',
  Kg = 'KG',
  Lb = 'LB',
  Package = 'PACKAGE',
  RequestUnitUnspecified = 'REQUEST_UNIT_UNSPECIFIED'
}

export type LegacyItemsTyped = {
  __typename?: 'LegacyItemsTyped';
  items: Maybe<Array<Maybe<LegacyItemTyped>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type LegacyPartner = {
  __typename?: 'LegacyPartner';
  /** The Partner's unique identifier  */
  id: Scalars['ID'];
  /** The name of the Partner */
  name: Scalars['String'];
  /** The associated Warehouses */
  warehouses: Array<LegacyWarehouse>;
};

export type LegacyPartnerUser = {
  __typename?: 'LegacyPartnerUser';
  /** The user's given name */
  firstName: Scalars['String'];
};

export type LegacyProducts = {
  __typename?: 'LegacyProducts';
  items: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  totalCount: Maybe<Scalars['BigInt']>;
  totalPages: Maybe<Scalars['BigInt']>;
};

export type LegacyWarehouse = {
  __typename?: 'LegacyWarehouse';
  /** Country of Warehouse */
  country: Maybe<LegacyCountry>;
  /** Warehouse early picking hour [if one is set] */
  earlyPickingHour: Maybe<Scalars['Int']>;
  /** The Warehouse's unique identifier */
  id: Scalars['ID'];
  /** The Warehouses' logo */
  logo: Maybe<LegacyWarehouseLogo>;
  /** The name of the Warehouse */
  name: Maybe<Scalars['String']>;
  /** The associated Partner */
  partner: Maybe<LegacyPartner>;
};

export type LegacyWarehouseLocation = {
  __typename?: 'LegacyWarehouseLocation';
  /** The delivery hours of the Warehouse Location */
  deliveryHours: Array<LegacyDeliveryHours>;
  /** The unique ID */
  id: Scalars['ID'];
};


export type LegacyWarehouseLocationDeliveryHoursArgs = {
  dates: Array<Scalars['LegacyDate']>;
};

export type LegacyWarehouseLogo = {
  __typename?: 'LegacyWarehouseLogo';
  /** A header-specific version of the logo */
  header: Maybe<LegacyBaseImage>;
  /** A tiny version of the logo */
  tiny: Maybe<LegacyBaseImage>;
  /** The image url */
  url: Maybe<Scalars['String']>;
  /** The whitelabeled logo */
  whiteLabelLandingPage: Maybe<LegacyBaseImage>;
};

export enum LegalFilterType {
  FullyFiltered = 'FULLY_FILTERED',
  LegalFilterTypeUnknown = 'LEGAL_FILTER_TYPE_UNKNOWN',
  NotFiltered = 'NOT_FILTERED',
  PartiallyFiltered = 'PARTIALLY_FILTERED'
}

export type LinkAutosuggestion = {
  __typename?: 'LinkAutosuggestion';
  endDate: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  linkDescription: Maybe<Scalars['String']>;
  linkId: Maybe<Scalars['BigInt']>;
  linkImageUrl: Maybe<Scalars['String']>;
  linkName: Maybe<Scalars['String']>;
  linkTitle: Maybe<Scalars['String']>;
  linkType: Maybe<LinkAutosuggestionLinkType>;
  linkUrl: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['BigInt']>;
  startDate: Maybe<Scalars['DateTime']>;
};

export type LinkAutosuggestionAdmin = {
  __typename?: 'LinkAutosuggestionAdmin';
  keywords: Maybe<Array<Maybe<Scalars['String']>>>;
  linkAutosuggestion: Maybe<LinkAutosuggestion>;
};

export type LinkAutosuggestionAdminInput = {
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkAutosuggestion?: InputMaybe<LinkAutosuggestionInput>;
};

export type LinkAutosuggestionInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  linkDescription?: InputMaybe<Scalars['String']>;
  linkId?: InputMaybe<Scalars['BigInt']>;
  linkImageUrl?: InputMaybe<Scalars['String']>;
  linkName?: InputMaybe<Scalars['String']>;
  linkTitle?: InputMaybe<Scalars['String']>;
  linkType?: InputMaybe<LinkAutosuggestionLinkType>;
  linkUrl?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export enum LinkAutosuggestionLinkType {
  LinkTypeHard = 'LINK_TYPE_HARD',
  LinkTypeSoft = 'LINK_TYPE_SOFT',
  LinkTypeUnknown = 'LINK_TYPE_UNKNOWN'
}

export type ListAuditBatchesRequestInput = {
  batchStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<PaginationInput>;
  showReassignmentMappings?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  workflows?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListAuditBatchesResponse = {
  __typename?: 'ListAuditBatchesResponse';
  auditBatches: Maybe<Array<Maybe<AuditBatch>>>;
  totalResultCount: Maybe<Scalars['BigInt']>;
  workflowReassignmentMappings: Maybe<Array<Maybe<ListAuditBatchesResponseWorkflowReassignmentMapping>>>;
};

export type ListAuditBatchesResponseWorkflowReassignmentMapping = {
  __typename?: 'ListAuditBatchesResponseWorkflowReassignmentMapping';
  fromWorkflow: Maybe<Scalars['String']>;
  toWorkflows: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ListAuditWorkflowsRequestInput = {
  batchStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  returnTaskInformation?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  workflows?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListAuditWorkflowsResponse = {
  __typename?: 'ListAuditWorkflowsResponse';
  auditWorkflows: Maybe<Array<Maybe<AuditWorkflow>>>;
};

export type ListCollectionProductsRequestInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListCollectionProductsResponse = {
  __typename?: 'ListCollectionProductsResponse';
  products: Maybe<Array<Maybe<Product>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type ListCollectionsRequestInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  withoutNavigationCollections?: InputMaybe<Scalars['Boolean']>;
};

export type ListCollectionsResponse = {
  __typename?: 'ListCollectionsResponse';
  collections: Maybe<Array<Maybe<Collection>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type ListContentPageVersionMetasRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
};

export type ListContentPageVersionMetasResponse = {
  __typename?: 'ListContentPageVersionMetasResponse';
  contentPageVersionMetas: Maybe<Array<Maybe<ContentPageVersionMeta>>>;
};

export type ListContentPageVersionsRequestInput = {
  authContext?: InputMaybe<AuthorizationContextInput>;
  contentPageId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type ListContentPageVersionsResponse = {
  __typename?: 'ListContentPageVersionsResponse';
  contentPageVersions: Maybe<Array<Maybe<ContentPageVersion>>>;
};

export type ListContentPagesRequestV2Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  pageType?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type ListContentPagesResponseV2 = {
  __typename?: 'ListContentPagesResponseV2';
  contentPages: Maybe<Array<Maybe<ContentPageV2>>>;
};

export type ListInstacartCollectionsRequestInput = {
  parentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type ListInstacartCollectionsResponse = {
  __typename?: 'ListInstacartCollectionsResponse';
  instacartCollections: Maybe<Array<Maybe<RetailerCollection>>>;
  parentId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type ListLinkAutosuggestionAdminParametersInput = {
  keyword?: InputMaybe<Scalars['String']>;
  linkIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  linkNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListLinkAutosuggestionAdminRequestInput = {
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
  parameters?: InputMaybe<ListLinkAutosuggestionAdminParametersInput>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type ListLinkAutosuggestionAdminResponse = {
  __typename?: 'ListLinkAutosuggestionAdminResponse';
  linkAutosuggestions: Maybe<Array<Maybe<LinkAutosuggestionAdmin>>>;
  pageInfo: Maybe<InstacartCustomersSharedV1PageInfo>;
};

export type ListMomentsRequestFilterSetInput = {
  uiStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListMomentsRequestInput = {
  filters?: InputMaybe<ListMomentsRequestFilterSetInput>;
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
};

export type ListMomentsResponse = {
  __typename?: 'ListMomentsResponse';
  moments: Maybe<Array<Maybe<Moment>>>;
  pageInfo: Maybe<InstacartCustomersSharedV1PageInfo>;
};

export type ListPlacementContentParamsInput = {
  campaignEndsAfter?: InputMaybe<Scalars['DateTime']>;
  campaignEndsBefore?: InputMaybe<Scalars['DateTime']>;
  campaignStartsAfter?: InputMaybe<Scalars['DateTime']>;
  campaignStartsBefore?: InputMaybe<Scalars['DateTime']>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  createdAfter?: InputMaybe<Scalars['DateTime']>;
  createdBefore?: InputMaybe<Scalars['DateTime']>;
  enabledOnly?: InputMaybe<Scalars['Boolean']>;
  excludedLayoutVariants?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedLayouts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludedNamesRegex?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludedPlatforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludedRetailers?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  experimentNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includedLayoutVariants?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includedLayouts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includedPlatforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includedRetailers?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  internalNameRegex?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pagination?: InputMaybe<PlacementContentRequestPaginationInput>;
  parentPlacementId?: InputMaybe<Scalars['BigInt']>;
  placementSurface?: InputMaybe<PlacementContentSurfaceInput>;
  placementTypeVariants?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  placementTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListPlacementContentRequestInput = {
  excludingLocales?: InputMaybe<InstacartCustomersSharedV1Locale>;
  params?: InputMaybe<ListPlacementContentParamsInput>;
};

export type ListPlacementContentResponse = {
  __typename?: 'ListPlacementContentResponse';
  contents: Maybe<Array<Maybe<PlacementContent>>>;
  pagination: Maybe<PlacementContentResponsePagination>;
};

export type ListRetailerCampaignTreatmentsRequestInput = {
  retailerCampaignIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListRetailerCampaignTreatmentsResponse = {
  __typename?: 'ListRetailerCampaignTreatmentsResponse';
  treatments: Maybe<Array<Maybe<RetailerCampaignTreatment>>>;
};

export type ListRetailerCampaignsRequestFilterSetInput = {
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignReference?: InputMaybe<CampaignReferenceInput>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  excludeRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  filters?: InputMaybe<Array<InputMaybe<CampaignFilterInput>>>;
  regions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  uiStatus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListRetailerCampaignsRequestInput = {
  filters?: InputMaybe<ListRetailerCampaignsRequestFilterSetInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListRetailerCampaignsResponse = {
  __typename?: 'ListRetailerCampaignsResponse';
  retailerCampaigns: Maybe<Array<Maybe<RetailerCampaign>>>;
};

export type ListRetailerCollectionProductsRequestInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  lookupCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offset?: InputMaybe<Scalars['BigInt']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  rrcs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListRetailerCollectionProductsResponse = {
  __typename?: 'ListRetailerCollectionProductsResponse';
  parentId: Maybe<Scalars['BigInt']>;
  retailerCollectionProducts: Maybe<Array<Maybe<RetailerCollectionProduct>>>;
  retailerId: Maybe<Scalars['BigInt']>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type ListRetailerCollectionsRequestInput = {
  contentType?: InputMaybe<RetailerCollectionContentType>;
  ignoreParentId?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<RetailerCollectionOrderBy>;
  orderDirection?: InputMaybe<RetailerCollectionOrderDirection>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  storeConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  surfaces?: InputMaybe<Array<InputMaybe<RetailerCollectionSurface>>>;
  version?: InputMaybe<RetailerCollectionVersion>;
  withoutNavigationCollections?: InputMaybe<Scalars['Boolean']>;
};

export type ListRetailerCollectionsResponse = {
  __typename?: 'ListRetailerCollectionsResponse';
  localeCode: Maybe<InstacartCustomersSharedV1Locale>;
  parentId: Maybe<Scalars['BigInt']>;
  retailerCollections: Maybe<Array<Maybe<RetailerCollection>>>;
  retailerId: Maybe<Scalars['BigInt']>;
  surfaces: Maybe<Array<Maybe<RetailerCollectionSurface>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type ListStoreConfigurationsRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ListStoreConfigurationsResponse = {
  __typename?: 'ListStoreConfigurationsResponse';
  retailerId: Maybe<Scalars['BigInt']>;
  storeConfigurations: Maybe<Array<Maybe<RetailerCollectionStoreConfiguration>>>;
};

export type ListThemePagesRequestInput = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  includeSections?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type ListThemePagesResponse = {
  __typename?: 'ListThemePagesResponse';
  themePages: Maybe<Array<Maybe<ThemePage>>>;
};

export type ListTicketsRequestInput = {
  associatedUser?: InputMaybe<UserInput>;
  client?: InputMaybe<ClientInput>;
  createdBy?: InputMaybe<UserInput>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  entityType?: InputMaybe<EntityType>;
  getSummaryStatus?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<PaginationInput>;
  problemType?: InputMaybe<ProblemType>;
  reportedOnBehalfOf?: InputMaybe<UserInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  severity?: InputMaybe<Scalars['BigInt']>;
  sourceAppNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  ticketIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type ListTicketsResponse = {
  __typename?: 'ListTicketsResponse';
  includesOnBehalfOf: Maybe<Scalars['Boolean']>;
  page: Maybe<Pagination>;
  tickets: Maybe<Array<Maybe<ProblemTrackerTicket>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type ListUserSettingsRequestInput = {
  appNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  client?: InputMaybe<ClientInput>;
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ListUserSettingsResponse = {
  __typename?: 'ListUserSettingsResponse';
  settings: Maybe<Array<Maybe<UserSetting>>>;
};

export type ListUsersByPersonalIdentifiersStrictRequestInput = {
  identifier?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<InstacartCustomersSharedV1PaginationInput>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
};

export type ListUsersByPersonalIdentifiersStrictResponse = {
  __typename?: 'ListUsersByPersonalIdentifiersStrictResponse';
  pageInfo: Maybe<InstacartCustomersSharedV1PageInfo>;
  users: Maybe<Array<Maybe<User>>>;
};

export type LocaleProduct = {
  __typename?: 'LocaleProduct';
  localeId: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['String']>;
};

export type Logo = {
  __typename?: 'Logo';
  /** full url of the image */
  url: Maybe<Scalars['String']>;
};

export type LogoWithTextBanner = {
  __typename?: 'LogoWithTextBanner';
  backgroundColorHex: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  id: Maybe<Scalars['BigInt']>;
  imageAltText: Maybe<Scalars['String']>;
  imageMobileUrl: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type LogoWithTextBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  imageMobileUrl?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type Logos = {
  __typename?: 'Logos';
  defaultSiteLogoImageUrl: Maybe<Scalars['String']>;
  mobileBackgroundUrl: Maybe<Scalars['String']>;
  mobileLogoUrl: Maybe<Scalars['String']>;
  showPoweredByBranding: Maybe<Scalars['Boolean']>;
};

export type LoyaltySchema = {
  __typename?: 'LoyaltySchema';
  showLoyaltyActionsInList: Maybe<Scalars['Boolean']>;
};

export type ManagedAttributeValue = {
  __typename?: 'ManagedAttributeValue';
  valueDisplayName: Maybe<Scalars['String']>;
  valueId: Maybe<Scalars['BigInt']>;
};

export type MarketingFeatureTypesBrazeSegment = {
  __typename?: 'MarketingFeatureTypesBrazeSegment';
  /** Warehouse ids the segment is visible by */
  accessibleWarehouseIds: Array<Scalars['Int']>;
  /** A unique identifier for the braze segment. For Braze's API */
  brazeSegmentId: Scalars['String'];
  /** The role ID */
  id: Scalars['ID'];
  /** The visible name of the segment */
  segmentName: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type MarketingFeatureTypesBrazeSegmentSize = {
  __typename?: 'MarketingFeatureTypesBrazeSegmentSize';
  /** The size of the segment */
  size: Scalars['Int'];
};

export type MarketingFeatureTypesCampaignConfiguration = {
  __typename?: 'MarketingFeatureTypesCampaignConfiguration';
  /** The id of the campaign returned from the campaign domain */
  campaignId: Scalars['ID'];
  /** The name of the experiment to run the campaign for */
  experimentName: Maybe<Scalars['String']>;
  /** The push_notification id of the push notification template returned */
  pushNotificationId: Maybe<Scalars['String']>;
  /** A unique identifier for the segment. Currently for Braze's API */
  segmentId: Scalars['String'];
  /** The external id of the template returned */
  templateId: Maybe<Scalars['String']>;
  /** The warehouse id to run the campaign for */
  warehouseId: Scalars['ID'];
};

export type MarketingFeatureTypesCampaignStatus = {
  __typename?: 'MarketingFeatureTypesCampaignStatus';
  /** The name of the associated experiment */
  experimentName: Maybe<Scalars['String']>;
  /** The id of the braze template */
  failedUserCount: Maybe<Scalars['Int']>;
  /** The id of the braze template */
  pendingUserCount: Maybe<Scalars['Int']>;
  /** The id of the associated push notification */
  pushNotificationId: Maybe<Scalars['ID']>;
  /** The send types of the campaign */
  sendTypes: Maybe<Array<Send>>;
  /** The id of the push notification */
  sentUserCount: Maybe<Scalars['Int']>;
  /** The internal id of the template */
  status: CampaignProgress;
  /** The id of the associated template */
  templateId: Maybe<Scalars['ID']>;
  /** The name of the template */
  totalUserCount: Scalars['Int'];
};

export type MarketingFeatureTypesEmailTemplatePreview = {
  __typename?: 'MarketingFeatureTypesEmailTemplatePreview';
  /** The full html preview of the email body */
  body: Scalars['String'];
  /** the preheader of the email */
  preheader: Maybe<Scalars['String']>;
  /** The subject of the email */
  subject: Maybe<Scalars['String']>;
  /** The external id of the template returned */
  templateId: Maybe<Scalars['String']>;
  /** The name of the template */
  templateName: Maybe<Scalars['String']>;
  /** Variables used in the template */
  variables: Maybe<Array<Scalars['String']>>;
};

export type MarketingFeatureTypesPushTemplatePreview = {
  __typename?: 'MarketingFeatureTypesPushTemplatePreview';
  /** The body of the push notification returned */
  body: Maybe<Scalars['String']>;
  /** The id of the push notification returned */
  id: Scalars['String'];
  /** The title of the push notification returned */
  title: Maybe<Scalars['String']>;
};

export type MarketingFeatureTypesTemplate = {
  __typename?: 'MarketingFeatureTypesTemplate';
  /** The id of the braze template */
  brazeTemplateId: Maybe<Scalars['String']>;
  /** The name of the template */
  emailTemplateName: Scalars['String'];
  /** The internal id of the template */
  id: Scalars['ID'];
  /** Preview of the template */
  preview: Maybe<MarketingFeatureTypesTemplatePreview>;
  /** The id of the push notification */
  pushNotificationId: Maybe<Scalars['String']>;
};


export type MarketingFeatureTypesTemplatePreviewArgs = {
  useCache?: InputMaybe<Scalars['Boolean']>;
  variables?: InputMaybe<TemplateVariablesInput>;
};

export type MarketingFeatureTypesTemplatePreview = {
  __typename?: 'MarketingFeatureTypesTemplatePreview';
  email: Maybe<MarketingFeatureTypesEmailTemplatePreview>;
  push: Maybe<MarketingFeatureTypesPushTemplatePreview>;
};

export type MatchesAnyRetailerIdInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of retailer ids */
  retailerIds: Array<Scalars['Int']>;
};

/** Autogenerated return type of MatchesAnyRetailerIdVisibilityConditionCreate */
export type MatchesAnyRetailerIdVisibilityConditionCreatePayload = {
  __typename?: 'MatchesAnyRetailerIdVisibilityConditionCreatePayload';
  /** The newly created MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityCondition: ContentManagementVisibilityConditionMatchesAnyRetailerId;
};

/** Autogenerated return type of MatchesAnyRetailerIdVisibilityConditionUpdate */
export type MatchesAnyRetailerIdVisibilityConditionUpdatePayload = {
  __typename?: 'MatchesAnyRetailerIdVisibilityConditionUpdatePayload';
  /** The newly updated MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityCondition: ContentManagementVisibilityConditionMatchesAnyRetailerId;
};

export enum MergeableReportTypeEnum {
  /** Retailer Tax Logs */
  RetailerTaxLogs = 'RETAILER_TAX_LOGS',
  /** Taxes Paid at POS */
  TaxesPaidAtPos = 'TAXES_PAID_AT_POS'
}

export enum Metric {
  Orders = 'ORDERS',
  OrderPerStore = 'ORDER_PER_STORE',
  Sales = 'SALES',
  SalesPerStore = 'SALES_PER_STORE',
  Stores = 'STORES'
}

export type MetricThresholdInput = {
  metricType?: InputMaybe<MetricType>;
  threshold?: InputMaybe<Scalars['BigInt']>;
};

export enum MetricType {
  DedicatedMatchRate = 'DEDICATED_MATCH_RATE',
  DedicatedUtilizationRate = 'DEDICATED_UTILIZATION_RATE',
  Default = 'DEFAULT',
  FiveHourAvailabilityRate = 'FIVE_HOUR_AVAILABILITY_RATE',
  FulfilledOrders = 'FULFILLED_ORDERS',
  MatchRate = 'MATCH_RATE',
  RecommendedHours = 'RECOMMENDED_HOURS',
  ScheduledGaps = 'SCHEDULED_GAPS',
  ScheduledHours = 'SCHEDULED_HOURS',
  StaffingRate = 'STAFFING_RATE',
  UtilizationRate = 'UTILIZATION_RATE'
}

export type MinimumPlatformVersionInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId?: InputMaybe<Scalars['ID']>;
  /** The platform the version is being checked for */
  platform: PlacementPlatformEnum;
  /** Version used for the check */
  version: Scalars['String'];
};

/** Autogenerated return type of MinimumPlatformVersionVisibilityConditionCreate */
export type MinimumPlatformVersionVisibilityConditionCreatePayload = {
  __typename?: 'MinimumPlatformVersionVisibilityConditionCreatePayload';
  /** The newly created minimum_platform_version visibility condition */
  minimumPlatformVersionVisibilityCondition: ContentManagementVisibilityConditionMinimumPlatformVersion;
};

/** Autogenerated return type of MinimumPlatformVersionVisibilityConditionUpdate */
export type MinimumPlatformVersionVisibilityConditionUpdatePayload = {
  __typename?: 'MinimumPlatformVersionVisibilityConditionUpdatePayload';
  /** The newly created minimum_platform_version visibility condition */
  minimumPlatformVersionVisibilityCondition: ContentManagementVisibilityConditionMinimumPlatformVersion;
};

export type Moment = {
  __typename?: 'Moment';
  campaigns: Maybe<Array<Maybe<RetailerCampaign>>>;
  createdAt: Maybe<Scalars['DateTime']>;
  endsAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  momentCampaignId: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  startsAt: Maybe<Scalars['DateTime']>;
  status: Maybe<Scalars['String']>;
  submissionDeadline: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type MomentCampaignStatusInput = {
  campaignId?: InputMaybe<Scalars['BigInt']>;
  momentStatus?: InputMaybe<Array<InputMaybe<StatusMapInput>>>;
};

export type MoveRetailerAssetsFromFilestackBucketRequestInput = {
  assetType?: InputMaybe<AssetType>;
  isPublicAccessible?: InputMaybe<Scalars['Boolean']>;
  objectKeyMappings?: InputMaybe<Array<InputMaybe<ObjectKeyMappingInput>>>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type MoveRetailerAssetsFromFilestackBucketResponse = {
  __typename?: 'MoveRetailerAssetsFromFilestackBucketResponse';
  assetUrls: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutableS3Asset = {
  __typename?: 'MutableS3Asset';
  /** The s3 file exists */
  exists: Scalars['Boolean'];
  /** The idenfitier for the asset */
  id: Scalars['String'];
  /** The s3 read url for getting the asset */
  s3ReadUrl: Maybe<Scalars['String']>;
  /** The s3 upload url for putting the asset */
  s3WriteUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Allow a retailer employee to request account creation to their retailer admin */
  accountRequestAccountRequestCreate: Maybe<AccountRequestCreatePayload>;
  /** Allow a user requesting an account to verify email ownership */
  accountRequestEmailVerify: Maybe<EmailVerifyPayload>;
  apiIntegrationsServiceDeleteApiSetting: Maybe<DeleteApiSettingResponse>;
  apiIntegrationsServiceUpdateApiSetting: Maybe<UpdateApiSettingResponse>;
  /** Assign a permission to a role */
  assignPermissionToRole: Maybe<AssignPermissionToRolePayload>;
  /** Create/update a new enterprise account by its email */
  botEnterpriseAccountUpsert: Maybe<BotEnterpriseAccountUpsertPayload>;
  /** Update an existing callback configuration */
  callbackConfigurationUpdate: Maybe<PartnerCallbackConfigurationUpdatePayload>;
  catalogProblemTrackerServiceCreateTicket: Maybe<CreateTicketResponse>;
  configurableItemsServicePublishRppConfigurableItems: Maybe<PublishRppConfigurableItemsResponse>;
  connectPlatformServiceAdvanceBatchState: Maybe<AdvanceBatchStateResponse>;
  connectPlatformServiceAssignVirtualBatch: Maybe<AssignVirtualBatchResponse>;
  connectPlatformServiceCreateDriver: Maybe<CreateDriverResponse>;
  connectPlatformServiceGenerateBatch: Maybe<GenerateBatchResponse>;
  connectPlatformServiceUpdateShopperOrderItem: Maybe<UpdateShopperOrderItemResponse>;
  contentManagementAdminServiceBatchUpdateSlotCreatives: Maybe<BatchUpdateSlotCreativesResponse>;
  contentManagementAdminServiceCreateContentPage: Maybe<CreateContentPageResponse>;
  contentManagementAdminServiceCreateContentPageV2: Maybe<CreateContentPageResponseV2>;
  contentManagementAdminServiceCreateContentPageVersion: Maybe<CreateContentPageVersionResponse>;
  contentManagementAdminServiceCreateContentPageVersionMeta: Maybe<CreateContentPageVersionMetaResponse>;
  contentManagementAdminServiceCreateNavigation: Maybe<CreateNavigationResponse>;
  contentManagementAdminServiceCreateNavigationPreview: Maybe<CreateNavigationPreviewResponse>;
  contentManagementAdminServiceCreatePlacementContent: Maybe<CreatePlacementContentResponse>;
  contentManagementAdminServiceCreateSlot: Maybe<CreateSlotResponse>;
  contentManagementAdminServiceCreateSlotCreative: Maybe<CreateSlotCreativeResponse>;
  contentManagementAdminServiceDeleteContentPage: Maybe<DeleteContentPageResponse>;
  contentManagementAdminServiceDeleteContentPageVersion: Maybe<DeleteContentPageVersionResponse>;
  contentManagementAdminServiceDeleteNavigation: Maybe<DeleteNavigationResponse>;
  contentManagementAdminServiceDeleteSlot: Maybe<DeleteSlotResponse>;
  contentManagementAdminServiceDeleteSlotCreative: Maybe<DeleteSlotCreativeResponse>;
  contentManagementAdminServiceDisablePlacementContent: Maybe<DisablePlacementContentResponse>;
  contentManagementAdminServiceUpdateContentPageV2: Maybe<UpdateContentPageResponseV2>;
  contentManagementAdminServiceUpdateNavigation: Maybe<UpdateNavigationResponse>;
  contentManagementAdminServiceUpdatePlacementContent: Maybe<UpdatePlacementContentResponse>;
  contentManagementServiceBatchUpdatePlacements: Maybe<PlacementsResponse>;
  contentManagementServiceCreateLayoutVariant: Maybe<CreateLayoutVariantResponse>;
  contentManagementServiceCreatePlacement: Maybe<CreatePlacementResponse>;
  contentManagementServiceCreateThemePage: Maybe<CreateThemePageResponse>;
  contentManagementServiceDeleteLayoutVariant: Maybe<DeleteLayoutVariantResponse>;
  contentManagementServiceDeletePlacement: Maybe<DeletePlacementResponse>;
  contentManagementServiceDisableThemePage: Maybe<DisableThemePageResponse>;
  contentManagementServiceUpdateLayoutVariant: Maybe<UpdateLayoutVariantResponse>;
  contentManagementServiceUpdateThemePage: Maybe<UpdateThemePageResponse>;
  /** Create a CountryIds visibility condition */
  countryIdsVisibilityConditionCreate: Maybe<CountryIdsVisibilityConditionCreatePayload>;
  /** Update a CountryIds visibility condition */
  countryIdsVisibilityConditionUpdate: Maybe<CountryIdsVisibilityConditionUpdatePayload>;
  /** Create a ExpressMembership visibility condition */
  dayOfWeekVisibilityConditionCreate: Maybe<DayOfWeekVisibilityConditionCreatePayload>;
  /** Update an existing ShoppedAtRetailer visibility condition */
  dayOfWeekVisibilityConditionUpdate: Maybe<DayOfWeekVisibilityConditionUpdatePayload>;
  demeterServiceCombineAuditBatches: Maybe<CombineAuditBatchesResponse>;
  demeterServiceReassignAuditBatchWorkflow: Maybe<ReassignAuditBatchWorkflowResponse>;
  demeterServiceUpdateAuditBatch: Maybe<UpdateAuditBatchResponse>;
  demeterServiceUpdateAuditTask: Maybe<UpdateAuditTaskResponse>;
  emergingBrandPortalServiceContactBrand: Maybe<BrandContactResponse>;
  /** Set up a new account using an account setup token */
  enterpriseAccountAccountSetup: Maybe<EnterpriseAccountAccountSetupPayload>;
  /** Authenticate an account using an email and password */
  enterpriseAccountAuthenticateEmailPassword: Maybe<EnterpriseAccountAuthenticateEmailPasswordPayload>;
  /** Preflight authentication info per email address */
  enterpriseAccountAuthenticatePreflight: Maybe<EnterpriseAccountAuthenticatePreflightPayload>;
  /** Create a new enterprise account */
  enterpriseAccountCreate: Maybe<EnterpriseAccountCreatePayload>;
  /** Deactivate MFA for an account */
  enterpriseAccountMfaDeactivate: Maybe<EnterpriseAccountMfaDeactivatePayload>;
  /** Enable MFA for an account */
  enterpriseAccountMfaEnable: Maybe<EnterpriseAccountMfaEnablePayload>;
  /** Reset MFA for an account */
  enterpriseAccountMfaReset: Maybe<EnterpriseAccountMfaResetPayload>;
  /** Partner Onboarding new account token request, generate new email that will be sent to an Account's email address */
  enterpriseAccountPartnerOnboardingRequestAccountSetupEmail: Maybe<EnterpriseAccountPartnerOnboardingRequestAccountSetupEmailPayload>;
  /** Request that an account setup email be sent to an Account's email address */
  enterpriseAccountRequestAccountSetupEmail: Maybe<EnterpriseAccountRequestAccountSetupEmailPayload>;
  /** Request that a verification email be sent to an Account's email address */
  enterpriseAccountRequestEmailVerification: Maybe<EnterpriseAccountRequestEmailVerificationPayload>;
  /** Request that a password reset email be sent to an Account's email address */
  enterpriseAccountRequestPasswordReset: Maybe<EnterpriseAccountRequestPasswordResetPayload>;
  /** Set/Reset an account's password using a password reset token */
  enterpriseAccountResetPassword: Maybe<EnterpriseAccountResetPasswordPayload>;
  /** Sets the disabled flag on an account to true/false */
  enterpriseAccountSetDisabled: Maybe<EnterpriseAccountSetDisabledPayload>;
  /** Sign up for an account using an email */
  enterpriseAccountSignUpEmailPassword: Maybe<EnterpriseAccountSignUpEmailPasswordPayload>;
  /** Update an enterprise account */
  enterpriseAccountUpdate: Maybe<EnterpriseAccountUpdatePayload>;
  /** Update an enterprise account's roles */
  enterpriseAccountUpdateRoles: Maybe<EnterpriseAccountUpdateRolesPayload>;
  /** Verify an account via account setup token */
  enterpriseAccountVerifyAccountSetupToken: Maybe<EnterpriseAccountVerifyAccountSetupTokenPayload>;
  /** Verify an account's email using a verification token */
  enterpriseAccountVerifyEmail: Maybe<EnterpriseAccountVerifyEmailPayload>;
  /** Create a ExperimentVariant visibility condition */
  experimentVariantVisibilityConditionCreate: Maybe<ExperimentVariantVisibilityConditionCreatePayload>;
  /** Update a ExperimentVariant visibility condition */
  experimentVariantVisibilityConditionUpdate: Maybe<ExperimentVariantVisibilityConditionUpdatePayload>;
  /** Create a ExpressMembership visibility condition */
  expressMembershipVisibilityConditionCreate: Maybe<ExpressMembershipVisibilityConditionCreatePayload>;
  /** Update an existing ExpressMembership visibility condition */
  expressMembershipVisibilityConditionUpdate: Maybe<ExpressMembershipVisibilityConditionUpdatePayload>;
  /** Create an ExpressNonmemberLongTermHoldout visibility condition */
  expressNonmemberLongTermHoldoutVisibilityConditionCreate: Maybe<ExpressNonmemberLongTermHoldoutVisibilityConditionCreatePayload>;
  /** Update the feature tour status for the currently logged in account */
  featureTourUpdate: Maybe<FeatureTourUpdatePayload>;
  /** Create a new flyout placement */
  flyoutPlacementCreate: Maybe<FlyoutPlacementCreatePayload>;
  /** Update an existing flyout placement */
  flyoutPlacementUpdate: Maybe<FlyoutPlacementUpdatePayload>;
  /** Create a new home placement with HeroBanner banner */
  heroBannerPlacementCreate: Maybe<HeroBannerPlacementCreatePayload>;
  /** Update an existing hero_banner placement placement */
  heroBannerPlacementUpdate: Maybe<HeroBannerPlacementUpdatePayload>;
  insightsPortalServiceCreateInsightsPortalCustomReport: Maybe<InsightsPortalCreateCustomReportResponse>;
  insightsPortalServiceSetInsightsPortalCustomReportDisabled: Maybe<InsightsPortalSetCustomReportDisabledResponse>;
  /**
   * CREATE
   *
   * The response will have a newly-minted ID, which always have version_number
   * 1, and the definition will be the same as what was passed in the request.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_CreateSegment: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  /**
   * DELETE
   *
   * The response will have the version number one greater than the request,
   * and the definition will be empty.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_DeleteSegment: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  /**
   * UDPATE
   *
   * The response will have the version number one greater than the request,
   * and the definition will be the same as what was passed in the request.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_UpdateSegment: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_CreateCollection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_CreateInvfMapping: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_CreateRootNode: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_DeleteCollection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_DeleteInvfMapping: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_DeleteNavigationNode: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_OrderNavigationNodes: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_QueueNavigationMigration: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpdateCollection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpdateCollectionProducts: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpdateNavigationConfiguration: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_UpsertNavigationNode: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeResponse>;
  instacart_customers_retailer_management_orchestrator_v1_RetailerManagementService_DeleteIppDraft: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftResponse>;
  instacart_customers_retailer_management_orchestrator_v1_RetailerManagementService_PublishIppDraft: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftResponse>;
  instacart_customers_retailer_management_orchestrator_v1_RetailerManagementService_RevertIppDraft: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_CreateIppDraft: Maybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_DeleteIppDraft: Maybe<Instacart_Customers_Retailer_Management_V1_DeleteIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_PublishIppDraft: Maybe<Instacart_Customers_Retailer_Management_V1_PublishIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_RevertIppDraft: Maybe<Instacart_Customers_Retailer_Management_V1_RevertIppDraftResponse>;
  instacart_customers_retailer_management_v1_IppDraftPreviewService_CreateIppDraftPreview: Maybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewResponse>;
  /** Create a new closure event */
  instacart_logistics_v1_FulfillmentEvents_CreateClosureEvent: Maybe<Instacart_Logistics_V1_CreateClosureEventResponse>;
  /** Create a new fulfillment capacity-impacting event */
  instacart_logistics_v1_FulfillmentEvents_CreateFulfillmentEvent: Maybe<Instacart_Logistics_V1_CreateFulfillmentEventResponse>;
  /** Create a new restricted availability event */
  instacart_logistics_v1_FulfillmentEvents_CreateRestrictedAvailabilityEvent: Maybe<Instacart_Logistics_V1_CreateRestrictedAvailabilityEventResponse>;
  /** Update a closure event */
  instacart_logistics_v1_FulfillmentEvents_UpdateClosureEvents: Maybe<Instacart_Logistics_V1_UpdateClosureEventsResponse>;
  /** Update a fulfillment capacity-impacting event */
  instacart_logistics_v1_FulfillmentEvents_UpdateFulfillmentEvents: Maybe<Instacart_Logistics_V1_UpdateFulfillmentEventsResponse>;
  /** Update a restricted availability event */
  instacart_logistics_v1_FulfillmentEvents_UpdateRestrictedAvailabilityEvents: Maybe<Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsResponse>;
  /** Validate a new closure event */
  instacart_logistics_v1_FulfillmentEvents_ValidateNewClosureEvent: Maybe<Instacart_Logistics_V1_ValidateNewClosureEventResponse>;
  /** Validate a new fulfillment capacity-impacting event */
  instacart_logistics_v1_FulfillmentEvents_ValidateNewFulfillmentEvent: Maybe<Instacart_Logistics_V1_ValidateNewFulfillmentEventResponse>;
  /** Validate a new restricted availability event */
  instacart_logistics_v1_FulfillmentEvents_ValidateNewRestrictedAvailabilityEvent: Maybe<Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventResponse>;
  inventoryIntelligenceServiceCreateOosAcknowledgment: Maybe<CreateOosAcknowledgmentResponse>;
  linkAutosuggestServiceCreateOrUpdateLinkAutosuggestionAdmin: Maybe<CreateOrUpdateLinkAutosuggestionAdminResponse>;
  linkAutosuggestServiceDeleteLinkAutosuggestionAdmin: Maybe<DeleteLinkAutosuggestionAdminResponse>;
  /** Cancels a marketing email campaign in progress */
  marketingFeatureCancelCampaign: Maybe<CancelCampaignPayload>;
  /** Starts a marketing email campaign */
  marketingFeatureStartCampaign: Maybe<StartCampaignPayload>;
  /** Triggers the send for an existing marketing campaign */
  marketingFeatureTriggerCampaign: Maybe<TriggerCampaignPayload>;
  /** Create a MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityConditionCreate: Maybe<MatchesAnyRetailerIdVisibilityConditionCreatePayload>;
  /** Update a MatchesAnyRetailerId visibility condition */
  matchesAnyRetailerIdVisibilityConditionUpdate: Maybe<MatchesAnyRetailerIdVisibilityConditionUpdatePayload>;
  /** Create a MinimumPlatformVersion visibility condition */
  minimumPlatformVersionVisibilityConditionCreate: Maybe<MinimumPlatformVersionVisibilityConditionCreatePayload>;
  /** Update an existing MinimumPlatformVersion visibility condition */
  minimumPlatformVersionVisibilityConditionUpdate: Maybe<MinimumPlatformVersionVisibilityConditionUpdatePayload>;
  /** Create a NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityConditionCreate: Maybe<NewVerticalUserTargetingVisibilityConditionCreatePayload>;
  /** Update a NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityConditionUpdate: Maybe<NewVerticalUserTargetingVisibilityConditionUpdatePayload>;
  /** Merges two or more normalized-reports by combining their contents into one file */
  normalizedReportsMerge: Maybe<NormalizedReportsMergePayload>;
  /** Subscribe an account to a notification type for a retailer */
  notificationSubscriptionCreate: Maybe<NotificationSubscriptionCreatePayload>;
  /** Unsubscribe an account to a notification type for a retailer */
  notificationSubscriptionDelete: Maybe<NotificationSubscriptionDeletePayload>;
  /** Sets properties for mobile apps */
  onboardingMobileAppPropertiesUpdate: Maybe<SetMobilePropertiesPayload>;
  onboardingServiceCreateOnboardingStoreLocation: Maybe<CreateOnboardingStoreLocationResponse>;
  onboardingServiceCreateSignup: Maybe<CreateSignupResponse>;
  onboardingServiceDeleteLaunchOnboardingWarehouseLocation: Maybe<DeleteLaunchOnboardingWarehouseLocationResponse>;
  onboardingServiceUpdateLaunchOnboardingWarehouseInfo: Maybe<UpdateLaunchOnboardingWarehouseInfoResponse>;
  onboardingServiceUpdateLaunchOnboardingWarehouseLocationInfo: Maybe<UpdateLaunchOnboardingWarehouseLocationInfoResponse>;
  onboardingServiceUpdateLaunchOnboardingWorkflowState: Maybe<UpdateLaunchOnboardingWorkflowStateResponse>;
  onboardingServiceUpdateOnboarding: Maybe<UpdateOnboardingResponse>;
  onboardingServiceUpdateOnboardingBillingAddress: Maybe<UpdateOnboardingBillingAddressResponse>;
  onboardingServiceUpdateOnboardingWorkflowState: Maybe<UpdateOnboardingWorkflowStateResponse>;
  orderServiceCancelOrder: Maybe<CancelOrderResponse>;
  orderServiceCreateLastmileOrderFromDashboard: Maybe<CreateLastmileOrderFromDashboardResponse>;
  orderServiceReopenLastmileOrder: Maybe<ReopenLastmileOrderResponse>;
  orderServiceRescheduleOrder: Maybe<RescheduleOrderResponse>;
  orderServiceStageLastmileOrder: Maybe<StageLastmileOrderResponse>;
  /** Sends a test callback for a partner */
  partnerCallbackCreate: Maybe<PartnerCallbackCreatePayload>;
  /** Create a new partner pick device */
  partnerPickDeviceCreate: Maybe<PartnerPickDeviceCreatePayload>;
  /** Update an existing partner pick device's status */
  partnerPickDeviceSetStatus: Maybe<PartnerPickDeviceSetStatusPayload>;
  /** Update an existing partner pick device */
  partnerPickDeviceUpdate: Maybe<PartnerPickDeviceUpdatePayload>;
  /** Create a new partner pick recurring shift */
  partnerPickRecurringShiftCreate: Maybe<PartnerPickRecurringShiftCreatePayload>;
  /** Delete a partner pick recurring shift by id, from some date onward */
  partnerPickRecurringShiftDelete: Maybe<PartnerPickRecurringShiftDeletePayload>;
  /** Update an existing partner pick recurring shift */
  partnerPickRecurringShiftUpdate: Maybe<PartnerPickRecurringShiftUpdatePayload>;
  privacyServiceCreatePiiRequestForRetailer: Maybe<CreatePiiRequestForRetailerResponse>;
  /** Create a new report request */
  reportRequestCreate: Maybe<ReportRequestCreatePayload>;
  retailerAislesUpdateRetailerAisleTemplate: Maybe<UpdateRetailerAisleTemplateResponse>;
  /** Create a RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityConditionCreate: Maybe<RetailerAvailabilityVisibilityConditionCreatePayload>;
  /** Update a RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityConditionUpdate: Maybe<RetailerAvailabilityVisibilityConditionUpdatePayload>;
  retailerCampaignServiceAddRetailerCampaignTreatments: Maybe<AddRetailerCampaignTreatmentsResponse>;
  retailerCampaignServiceCancelMoment: Maybe<CancelMomentResponse>;
  retailerCampaignServiceCancelRetailerCampaign: Maybe<CancelRetailerCampaignResponse>;
  retailerCampaignServiceCreateMoment: Maybe<CreateMomentResponse>;
  retailerCampaignServiceCreateRetailerCampaign: Maybe<RetailerCampaignResponse>;
  retailerCampaignServiceUpdateMoment: Maybe<UpdateMomentResponse>;
  retailerCampaignServiceUpdateMomentCampaignStatus: Maybe<UpdateMomentCampaignStatusResponse>;
  retailerCampaignServiceUpdateRetailerCampaign: Maybe<RetailerCampaignResponse>;
  retailerCampaignServiceUpdateRetailerCampaignTreatments: Maybe<UpdateRetailerCampaignTreatmentsResponse>;
  retailerCollectionsAdminServiceCreateCollection: Maybe<CreateCollectionResponse>;
  retailerCollectionsAdminServiceCreateInvfMapping: Maybe<CreateInvfMappingResponse>;
  retailerCollectionsAdminServiceCreateRootNode: Maybe<CreateRootNodeResponse>;
  retailerCollectionsAdminServiceDeleteCollection: Maybe<DeleteCollectionResponse>;
  retailerCollectionsAdminServiceDeleteInvfMapping: Maybe<DeleteInvfMappingResponse>;
  retailerCollectionsAdminServiceDeleteNavigationNode: Maybe<DeleteNavigationNodeResponse>;
  retailerCollectionsAdminServiceOrderNavigationNodes: Maybe<OrderNavigationNodesResponse>;
  retailerCollectionsAdminServiceQueueNavigationMigration: Maybe<QueueNavigationMigrationResponse>;
  retailerCollectionsAdminServiceStartCsvImport: Maybe<StartCsvImportResponse>;
  retailerCollectionsAdminServiceStartCsvSummary: Maybe<StartCsvSummaryResponse>;
  retailerCollectionsAdminServiceUpdateCollection: Maybe<UpdateCollectionResponse>;
  retailerCollectionsAdminServiceUpdateCollectionProducts: Maybe<UpdateCollectionProductsResponse>;
  retailerCollectionsAdminServiceUpdateNavigationConfiguration: Maybe<UpdateNavigationConfigurationResponse>;
  retailerCollectionsAdminServiceUpsertNavigationNode: Maybe<UpsertNavigationNodeResponse>;
  retailerCollectionsServiceAddProductsToRetailerCollection: Maybe<AddProductsToRetailerCollectionResponse>;
  retailerCollectionsServiceCreateInventoryFileMapping: Maybe<CreateInventoryFileMappingResponse>;
  retailerCollectionsServiceCreateRetailerCollection: Maybe<CreateRetailerCollectionResponse>;
  retailerCollectionsServiceDeleteInventoryFileMapping: Maybe<DeleteInventoryFileMappingResponse>;
  retailerCollectionsServiceDeleteRetailerCollection: Maybe<DeleteRetailerCollectionResponse>;
  retailerCollectionsServiceDuplicateInstacartCollection: Maybe<DuplicateInstacartCollectionResponse>;
  retailerCollectionsServiceDuplicateRetailerCollection: Maybe<DuplicateRetailerCollectionResponse>;
  retailerCollectionsServiceProcessRetailerCollectionsCSV: Maybe<ProcessRetailerCollectionsCsvResponse>;
  retailerCollectionsServiceRemoveProductsFromRetailerCollection: Maybe<RemoveProductsFromRetailerCollectionResponse>;
  retailerCollectionsServiceSetCollectionFilters: Maybe<SetCollectionFiltersResponse>;
  retailerCollectionsServiceUpdateInstacartCollection: Maybe<UpdateInstacartCollectionResponse>;
  retailerCollectionsServiceUpdateRetailerCollection: Maybe<UpdateRetailerCollectionResponse>;
  retailerCollectionsServiceUpdateRetailerCollectionInvfEnrollment: Maybe<UpdateRetailerCollectionInvfEnrollmentResponse>;
  retailerCollectionsServiceUpdateRetailerCollectionPinnedStatus: Maybe<UpdateRetailerCollectionPinnedStatusResponse>;
  retailerCollectionsServiceUpdateRetailerCollectionStatus: Maybe<UpdateRetailerCollectionStatusResponse>;
  retailerCollectionsServiceUpsertRetailerConfig: Maybe<UpsertRetailerConfigResponse>;
  retailerCollectionsServiceValidateRetailerCollectionsCSV: Maybe<ValidateRetailerCollectionsCsvResponse>;
  /** Create a new retailer location group */
  retailerLocationGroupCreate: Maybe<RetailerLocationGroupCreatePayload>;
  /** Delete an existing retailer location group */
  retailerLocationGroupDelete: Maybe<RetailerLocationGroupDeletePayload>;
  /** Update an existing retailer location group */
  retailerLocationGroupUpdate: Maybe<RetailerLocationGroupUpdatePayload>;
  retailerManagementServiceCreateClientConfiguration: Maybe<CreateClientConfigurationResponse>;
  retailerManagementServiceCreateConnectRetailerCallbackConfiguration: Maybe<CreateConnectRetailerCallbackConfigurationResponse>;
  retailerManagementServiceCreateStoreConfiguration: Maybe<CreateStoreConfigurationResponse>;
  retailerManagementServiceDeleteConnectRetailerCallbackConfiguration: Maybe<DeleteConnectRetailerCallbackConfigurationResponse>;
  retailerManagementServiceUpdateConnectRetailerCallbackConfiguration: Maybe<UpdateConnectRetailerCallbackConfigurationResponse>;
  /** Set MFA enabled status for a retailer */
  retailerMfaSetEnabled: Maybe<RetailerMfaSetEnabledPayload>;
  retailerProductAttributeEditServiceUpdateRetailerProductVisibilities: Maybe<UpdateRetailerProductVisibilitiesResponse>;
  /** Submit the results of the Retailer Satisfication Survey form */
  retailerSatisfactionSurveySubmissionCreate: Maybe<SubmissionCreatePayload>;
  retailerSettingsServiceDeleteImage: Maybe<DeleteImageResponse>;
  retailerSettingsServiceUpdateParkingSettings: Maybe<UpdateParkingSettingsResponse>;
  retailerSettingsServiceUpdateStagingSettings: Maybe<UpdateStagingSettingsResponse>;
  retailerStoreHoursUpdateConfirmStoreHours: Maybe<UpdateConfirmedStoreHoursResponse>;
  retailerStoreHoursUpdateStoreHours: Maybe<UpdateStoreHoursResponse>;
  retailersServiceMoveRetailerAssetsFromFilestackBucket: Maybe<MoveRetailerAssetsFromFilestackBucketResponse>;
  returnsServiceCreateOrUpdateReturn: Maybe<CreateOrUpdateReturnResponse>;
  /** Create a role */
  roleCreate: Maybe<RoleCreatePayload>;
  /** Delete a role */
  roleDelete: Maybe<RoleDeletePayload>;
  /** Update a role */
  roleUpdate: Maybe<RoleUpdatePayload>;
  rppBulkImageUploadsServiceCreateImageUploadBatch: Maybe<CreateImageUploadBatchResponse>;
  /** Simulate an event for an existing sandbox order */
  sandboxOrderSimulateEvent: Maybe<PartnerSandboxOrderSimulateEventPayload>;
  /** Create a new home placement with SecondaryBanner banner */
  secondaryBannerPlacementCreate: Maybe<SecondaryBannerPlacementCreatePayload>;
  /** Update an existing secondary_banner placement placement */
  secondaryBannerPlacementUpdate: Maybe<SecondaryBannerPlacementUpdatePayload>;
  /** Create a ShoppedAtRetailer visibility condition */
  shoppedAtRetailerVisibilityConditionCreate: Maybe<ShoppedAtRetailerVisibilityConditionCreatePayload>;
  /** Update an existing ShoppedAtRetailer visibility condition */
  shoppedAtRetailerVisibilityConditionUpdate: Maybe<ShoppedAtRetailerVisibilityConditionUpdatePayload>;
  /** Create a shopped visibility condition */
  shoppedVisibilityConditionCreate: Maybe<ShoppedVisibilityConditionCreatePayload>;
  /** Update an existing Shopped visibility condition */
  shoppedVisibilityConditionUpdate: Maybe<ShoppedVisibilityConditionUpdatePayload>;
  storeConfigurationsDraftServiceCreateStoreConfigurationDraft: Maybe<CreateStoreConfigurationDraftResponse>;
  storeConfigurationsDraftServicePublishStoreConfigurationDraft: Maybe<PublishStoreConfigurationDraftResponse>;
  storeConfigurationsDraftServiceRollbackStoreConfiguration: Maybe<RollbackStoreConfigurationResponse>;
  storeConfigurationsDraftServiceUpdateStoreConfigurationDraft: Maybe<UpdateStoreConfigurationDraftResponse>;
  /** Create a new storefront placement */
  storefrontPlacementCreate: Maybe<StorefrontPlacementCreatePayload>;
  /** Delete an existing storefront placement */
  storefrontPlacementDelete: Maybe<StorefrontPlacementDeletePayload>;
  /** Update an existing storefront placement placement */
  storefrontPlacementUpdate: Maybe<StorefrontPlacementUpdatePayload>;
  /** Create a UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityConditionCreate: Maybe<UserInteractionLimitVisibilityConditionCreatePayload>;
  /** Update a UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityConditionUpdate: Maybe<UserInteractionLimitVisibilityConditionUpdatePayload>;
  userSettingServiceSetUserSetting: Maybe<SetUserSettingResponse>;
  usersServiceGrantImpersonation: Maybe<GrantImpersonationResponse>;
  /** Create a UtmParameters visibility condition */
  utmParametersVisibilityConditionCreate: Maybe<UtmParametersVisibilityConditionCreatePayload>;
  /** Update a UtmParameters visibility condition */
  utmParametersVisibilityConditionUpdate: Maybe<UtmParametersVisibilityConditionUpdatePayload>;
  valueBasedOverrideServiceCreateValueBasedOverride: Maybe<CreateValueBasedOverrideResponse>;
  /** Delete an existing visibility condition */
  visibilityConditionDelete: Maybe<VisibilityConditionDeletePayload>;
  /** Create a WhitelistedPostalCodes visibility condition */
  whitelistedPostalCodesVisibilityConditionCreate: Maybe<WhitelistedPostalCodesVisibilityConditionCreatePayload>;
  /** Update an existing WhitelistedPostalCodes visibility condition */
  whitelistedPostalCodesVisibilityConditionUpdate: Maybe<WhitelistedPostalCodesVisibilityConditionUpdatePayload>;
  /** Create a WhitelistedZones visibility condition */
  whitelistedZonesVisibilityConditionCreate: Maybe<WhitelistedZonesVisibilityConditionCreatePayload>;
  /** Update a WhitelistedZones visibility condition */
  whitelistedZonesVisibilityConditionUpdate: Maybe<WhitelistedZonesVisibilityConditionUpdatePayload>;
  /** Update the wizard progress for the currently logged in account */
  wizardProgressUpdate: Maybe<WizardProgressUpdatePayload>;
};


export type MutationAccountRequestAccountRequestCreateArgs = {
  email: Scalars['String'];
  features: Array<Scalars['String']>;
  fullName: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  requestReason: Scalars['String'];
  retailerId: Scalars['ID'];
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationAccountRequestEmailVerifyArgs = {
  rawToken: Scalars['String'];
};


export type MutationApiIntegrationsServiceDeleteApiSettingArgs = {
  input?: InputMaybe<DeleteApiSettingRequestInput>;
};


export type MutationApiIntegrationsServiceUpdateApiSettingArgs = {
  input?: InputMaybe<UpdateApiSettingRequestInput>;
};


export type MutationAssignPermissionToRoleArgs = {
  permission: PermissionInput;
  role: RoleInput;
};


export type MutationBotEnterpriseAccountUpsertArgs = {
  email: Scalars['String'];
  extraRoleSlugs?: InputMaybe<Array<Scalars['String']>>;
  givenName: Scalars['String'];
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  readOnly?: InputMaybe<Scalars['Boolean']>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  role: BotAccountAssignableRoleEnum;
  slackParentMessageTs?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};


export type MutationCallbackConfigurationUpdateArgs = {
  id: Scalars['ID'];
  updatedCallbackConfiguration: CallbackConfigurationInput;
};


export type MutationCatalogProblemTrackerServiceCreateTicketArgs = {
  input?: InputMaybe<CreateTicketRequestInput>;
};


export type MutationConfigurableItemsServicePublishRppConfigurableItemsArgs = {
  input?: InputMaybe<PublishRppConfigurableItemsRequestInput>;
};


export type MutationConnectPlatformServiceAdvanceBatchStateArgs = {
  input?: InputMaybe<AdvanceBatchStateRequestInput>;
};


export type MutationConnectPlatformServiceAssignVirtualBatchArgs = {
  input?: InputMaybe<AssignVirtualBatchRequestInput>;
};


export type MutationConnectPlatformServiceCreateDriverArgs = {
  input?: InputMaybe<CreateDriverRequestInput>;
};


export type MutationConnectPlatformServiceGenerateBatchArgs = {
  input?: InputMaybe<GenerateBatchRequestInput>;
};


export type MutationConnectPlatformServiceUpdateShopperOrderItemArgs = {
  input?: InputMaybe<UpdateShopperOrderItemRequestInput>;
};


export type MutationContentManagementAdminServiceBatchUpdateSlotCreativesArgs = {
  input?: InputMaybe<BatchUpdateSlotCreativesRequestInput>;
};


export type MutationContentManagementAdminServiceCreateContentPageArgs = {
  input?: InputMaybe<CreateContentPageRequestInput>;
};


export type MutationContentManagementAdminServiceCreateContentPageV2Args = {
  input?: InputMaybe<CreateContentPageRequestV2Input>;
};


export type MutationContentManagementAdminServiceCreateContentPageVersionArgs = {
  input?: InputMaybe<CreateContentPageVersionRequestInput>;
};


export type MutationContentManagementAdminServiceCreateContentPageVersionMetaArgs = {
  input?: InputMaybe<CreateContentPageVersionMetaRequestInput>;
};


export type MutationContentManagementAdminServiceCreateNavigationArgs = {
  input?: InputMaybe<CreateNavigationRequestInput>;
};


export type MutationContentManagementAdminServiceCreateNavigationPreviewArgs = {
  input?: InputMaybe<CreateNavigationPreviewRequestInput>;
};


export type MutationContentManagementAdminServiceCreatePlacementContentArgs = {
  input?: InputMaybe<CreatePlacementContentRequestInput>;
};


export type MutationContentManagementAdminServiceCreateSlotArgs = {
  input?: InputMaybe<CreateSlotRequestInput>;
};


export type MutationContentManagementAdminServiceCreateSlotCreativeArgs = {
  input?: InputMaybe<CreateSlotCreativeRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteContentPageArgs = {
  input?: InputMaybe<DeleteContentPageRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteContentPageVersionArgs = {
  input?: InputMaybe<DeleteContentPageVersionRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteNavigationArgs = {
  input?: InputMaybe<DeleteNavigationRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteSlotArgs = {
  input?: InputMaybe<DeleteSlotRequestInput>;
};


export type MutationContentManagementAdminServiceDeleteSlotCreativeArgs = {
  input?: InputMaybe<DeleteSlotCreativeRequestInput>;
};


export type MutationContentManagementAdminServiceDisablePlacementContentArgs = {
  input?: InputMaybe<DisablePlacementContentRequestInput>;
};


export type MutationContentManagementAdminServiceUpdateContentPageV2Args = {
  input?: InputMaybe<UpdateContentPageRequestV2Input>;
};


export type MutationContentManagementAdminServiceUpdateNavigationArgs = {
  input?: InputMaybe<UpdateNavigationRequestInput>;
};


export type MutationContentManagementAdminServiceUpdatePlacementContentArgs = {
  input?: InputMaybe<UpdatePlacementContentRequestInput>;
};


export type MutationContentManagementServiceBatchUpdatePlacementsArgs = {
  input?: InputMaybe<BatchUpdatePlacementsRequestInput>;
};


export type MutationContentManagementServiceCreateLayoutVariantArgs = {
  input?: InputMaybe<CreateLayoutVariantRequestInput>;
};


export type MutationContentManagementServiceCreatePlacementArgs = {
  input?: InputMaybe<CreatePlacementRequestInput>;
};


export type MutationContentManagementServiceCreateThemePageArgs = {
  input?: InputMaybe<CreateThemePageRequestInput>;
};


export type MutationContentManagementServiceDeleteLayoutVariantArgs = {
  input?: InputMaybe<DeleteLayoutVariantRequestInput>;
};


export type MutationContentManagementServiceDeletePlacementArgs = {
  input?: InputMaybe<DeletePlacementRequestInput>;
};


export type MutationContentManagementServiceDisableThemePageArgs = {
  input?: InputMaybe<DisableThemePageRequestInput>;
};


export type MutationContentManagementServiceUpdateLayoutVariantArgs = {
  input?: InputMaybe<UpdateLayoutVariantRequestInput>;
};


export type MutationContentManagementServiceUpdateThemePageArgs = {
  input?: InputMaybe<UpdateThemePageRequestInput>;
};


export type MutationCountryIdsVisibilityConditionCreateArgs = {
  countryIdsVisibilityConditionData: CountryIdsInput;
};


export type MutationCountryIdsVisibilityConditionUpdateArgs = {
  countryIdsVisibilityConditionData: CountryIdsInput;
};


export type MutationDayOfWeekVisibilityConditionCreateArgs = {
  dayOfWeekVisibilityConditionData: DayOfWeekInput;
};


export type MutationDayOfWeekVisibilityConditionUpdateArgs = {
  dayOfWeekVisibilityConditionData: DayOfWeekInput;
};


export type MutationDemeterServiceCombineAuditBatchesArgs = {
  input?: InputMaybe<CombineAuditBatchesRequestInput>;
};


export type MutationDemeterServiceReassignAuditBatchWorkflowArgs = {
  input?: InputMaybe<ReassignAuditBatchWorkflowRequestInput>;
};


export type MutationDemeterServiceUpdateAuditBatchArgs = {
  input?: InputMaybe<UpdateAuditBatchRequestInput>;
};


export type MutationDemeterServiceUpdateAuditTaskArgs = {
  input?: InputMaybe<UpdateAuditTaskRequestInput>;
};


export type MutationEmergingBrandPortalServiceContactBrandArgs = {
  input?: InputMaybe<BrandContactRequestInput>;
};


export type MutationEnterpriseAccountAccountSetupArgs = {
  givenName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  rawToken: Scalars['String'];
  surname?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountAuthenticateEmailPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountAuthenticatePreflightArgs = {
  email: Scalars['String'];
};


export type MutationEnterpriseAccountCreateArgs = {
  accountRequestId?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  givenName: Scalars['String'];
  hasAllPartnersFullAccess: Scalars['Boolean'];
  hasFullPartnerAccess: Scalars['Boolean'];
  hasFullRetailerAccess: Scalars['Boolean'];
  partnerIds: Array<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  retailerIds: Array<Scalars['ID']>;
  retailerLocationIds: Array<Scalars['ID']>;
  roleIds: Array<Scalars['ID']>;
  surname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountMfaDeactivateArgs = {
  canonicalId: Scalars['ID'];
};


export type MutationEnterpriseAccountMfaEnableArgs = {
  canonicalId: Scalars['ID'];
};


export type MutationEnterpriseAccountMfaResetArgs = {
  canonicalId: Scalars['ID'];
};


export type MutationEnterpriseAccountPartnerOnboardingRequestAccountSetupEmailArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountRequestAccountSetupEmailArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountRequestEmailVerificationArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountRequestPasswordResetArgs = {
  email: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountResetPasswordArgs = {
  password: Scalars['String'];
  rawToken: Scalars['String'];
};


export type MutationEnterpriseAccountSetDisabledArgs = {
  accountCanonicalId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationEnterpriseAccountSignUpEmailPasswordArgs = {
  email: Scalars['String'];
  givenName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  turnstileToken?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountUpdateArgs = {
  canonicalId: Scalars['ID'];
  givenName: Scalars['String'];
  hasAllPartnersFullAccess: Scalars['Boolean'];
  hasFullPartnerAccess: Scalars['Boolean'];
  hasFullRetailerAccess: Scalars['Boolean'];
  partnerIds: Array<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  retailerIds: Array<Scalars['ID']>;
  retailerLocationIds: Array<Scalars['ID']>;
  roleIds: Array<Scalars['ID']>;
  surname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationEnterpriseAccountUpdateRolesArgs = {
  addRoleIds?: InputMaybe<Array<Scalars['ID']>>;
  canonicalId: Scalars['ID'];
  removeRoleIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationEnterpriseAccountVerifyAccountSetupTokenArgs = {
  rawToken: Scalars['String'];
};


export type MutationEnterpriseAccountVerifyEmailArgs = {
  rawToken: Scalars['String'];
};


export type MutationExperimentVariantVisibilityConditionCreateArgs = {
  experimentVariantVisibilityConditionData: ExperimentVariantInput;
};


export type MutationExperimentVariantVisibilityConditionUpdateArgs = {
  experimentVariantVisibilityConditionData: ExperimentVariantInput;
};


export type MutationExpressMembershipVisibilityConditionCreateArgs = {
  expressMembershipVisibilityConditionData: ExpressMembershipInput;
};


export type MutationExpressMembershipVisibilityConditionUpdateArgs = {
  expressMembershipVisibilityConditionData: ExpressMembershipInput;
};


export type MutationExpressNonmemberLongTermHoldoutVisibilityConditionCreateArgs = {
  expressNonmemberLongTermHoldoutVisibilityConditionData: ExpressNonmemberLongTermHoldoutInput;
};


export type MutationFeatureTourUpdateArgs = {
  name: Scalars['String'];
  status: Status;
};


export type MutationFlyoutPlacementCreateArgs = {
  flyoutPlacementData: FlyoutPlacementInput;
};


export type MutationFlyoutPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  flyoutPlacementData: FlyoutPlacementInput;
  id: Scalars['ID'];
};


export type MutationHeroBannerPlacementCreateArgs = {
  heroBannerPlacementData: HeroBannerPlacementInput;
};


export type MutationHeroBannerPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  heroBannerPlacementData: HeroBannerPlacementInput;
  id: Scalars['ID'];
};


export type MutationInsightsPortalServiceCreateInsightsPortalCustomReportArgs = {
  input?: InputMaybe<InsightsPortalCreateCustomReportRequestInput>;
};


export type MutationInsightsPortalServiceSetInsightsPortalCustomReportDisabledArgs = {
  input?: InputMaybe<InsightsPortalSetCustomReportDisabledRequestInput>;
};


export type MutationInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_CreateSegmentArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_CreateSegmentRequest_Input>;
};


export type MutationInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_DeleteSegmentArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_DeleteSegmentRequest_Input>;
};


export type MutationInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_UpdateSegmentArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_UpdateSegmentRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_CreateCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_CreateInvfMappingArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_CreateRootNodeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_DeleteCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_DeleteInvfMappingArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_DeleteNavigationNodeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_OrderNavigationNodesArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_QueueNavigationMigrationArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpdateCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpdateCollectionProductsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpdateNavigationConfigurationArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_UpsertNavigationNodeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_Orchestrator_V1_RetailerManagementService_DeleteIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_Orchestrator_V1_RetailerManagementService_PublishIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_Orchestrator_V1_RetailerManagementService_RevertIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_CreateIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_DeleteIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_DeleteIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_PublishIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_PublishIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_RevertIppDraftArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_RevertIppDraftRequest_Input>;
};


export type MutationInstacart_Customers_Retailer_Management_V1_IppDraftPreviewService_CreateIppDraftPreviewArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_CreateClosureEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_CreateClosureEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_CreateFulfillmentEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_CreateFulfillmentEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_CreateRestrictedAvailabilityEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_CreateRestrictedAvailabilityEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_UpdateClosureEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_UpdateClosureEventsRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_UpdateFulfillmentEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_UpdateFulfillmentEventsRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_UpdateRestrictedAvailabilityEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_ValidateNewClosureEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ValidateNewClosureEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_ValidateNewFulfillmentEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ValidateNewFulfillmentEventRequest_Input>;
};


export type MutationInstacart_Logistics_V1_FulfillmentEvents_ValidateNewRestrictedAvailabilityEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventRequest_Input>;
};


export type MutationInventoryIntelligenceServiceCreateOosAcknowledgmentArgs = {
  input?: InputMaybe<CreateOosAcknowledgmentRequestInput>;
};


export type MutationLinkAutosuggestServiceCreateOrUpdateLinkAutosuggestionAdminArgs = {
  input?: InputMaybe<CreateOrUpdateLinkAutosuggestionAdminRequestInput>;
};


export type MutationLinkAutosuggestServiceDeleteLinkAutosuggestionAdminArgs = {
  input?: InputMaybe<DeleteLinkAutosuggestionAdminRequestInput>;
};


export type MutationMarketingFeatureCancelCampaignArgs = {
  campaignId: Scalars['ID'];
};


export type MutationMarketingFeatureStartCampaignArgs = {
  campaign: StartCampaignInput;
};


export type MutationMarketingFeatureTriggerCampaignArgs = {
  campaignId: Scalars['ID'];
};


export type MutationMatchesAnyRetailerIdVisibilityConditionCreateArgs = {
  matchesAnyRetailerIdVisibilityConditionData: MatchesAnyRetailerIdInput;
};


export type MutationMatchesAnyRetailerIdVisibilityConditionUpdateArgs = {
  matchesAnyRetailerIdVisibilityConditionData: MatchesAnyRetailerIdInput;
};


export type MutationMinimumPlatformVersionVisibilityConditionCreateArgs = {
  minimumPlatformVersionVisibilityConditionData: MinimumPlatformVersionInput;
};


export type MutationMinimumPlatformVersionVisibilityConditionUpdateArgs = {
  minimumPlatformVersionVisibilityConditionData: MinimumPlatformVersionInput;
};


export type MutationNewVerticalUserTargetingVisibilityConditionCreateArgs = {
  newVerticalUserTargetingVisibilityConditionData: NewVerticalUserTargetingInput;
};


export type MutationNewVerticalUserTargetingVisibilityConditionUpdateArgs = {
  newVerticalUserTargetingVisibilityConditionData: NewVerticalUserTargetingInput;
};


export type MutationNormalizedReportsMergeArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  partnerId: Scalars['ID'];
  reportType: MergeableReportTypeEnum;
  retailerId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type MutationNotificationSubscriptionCreateArgs = {
  accountId: Scalars['ID'];
  notificationTypeId: Scalars['ID'];
  retailerId: Scalars['ID'];
};


export type MutationNotificationSubscriptionDeleteArgs = {
  accountId: Scalars['ID'];
  notificationTypeId: Scalars['ID'];
  retailerId: Scalars['ID'];
};


export type MutationOnboardingMobileAppPropertiesUpdateArgs = {
  mobileAppProperties: Array<PropertyInput>;
  os: Os;
  storeConfigurationId: Scalars['Int'];
};


export type MutationOnboardingServiceCreateOnboardingStoreLocationArgs = {
  input?: InputMaybe<CreateOnboardingStoreLocationRequestInput>;
};


export type MutationOnboardingServiceCreateSignupArgs = {
  input?: InputMaybe<CreateSignupRequestInput>;
};


export type MutationOnboardingServiceDeleteLaunchOnboardingWarehouseLocationArgs = {
  input?: InputMaybe<DeleteLaunchOnboardingWarehouseLocationRequestInput>;
};


export type MutationOnboardingServiceUpdateLaunchOnboardingWarehouseInfoArgs = {
  input?: InputMaybe<UpdateLaunchOnboardingWarehouseInfoRequestInput>;
};


export type MutationOnboardingServiceUpdateLaunchOnboardingWarehouseLocationInfoArgs = {
  input?: InputMaybe<UpdateLaunchOnboardingWarehouseLocationInfoRequestInput>;
};


export type MutationOnboardingServiceUpdateLaunchOnboardingWorkflowStateArgs = {
  input?: InputMaybe<UpdateLaunchOnboardingWorkflowStateRequestInput>;
};


export type MutationOnboardingServiceUpdateOnboardingArgs = {
  input?: InputMaybe<UpdateOnboardingRequestInput>;
};


export type MutationOnboardingServiceUpdateOnboardingBillingAddressArgs = {
  input?: InputMaybe<UpdateOnboardingBillingAddressRequestInput>;
};


export type MutationOnboardingServiceUpdateOnboardingWorkflowStateArgs = {
  input?: InputMaybe<UpdateOnboardingWorkflowStateRequestInput>;
};


export type MutationOrderServiceCancelOrderArgs = {
  input?: InputMaybe<CancelOrderRequestInput>;
};


export type MutationOrderServiceCreateLastmileOrderFromDashboardArgs = {
  input?: InputMaybe<CreateLastmileOrderFromDashboardRequestInput>;
};


export type MutationOrderServiceReopenLastmileOrderArgs = {
  input?: InputMaybe<ReopenLastmileOrderRequestInput>;
};


export type MutationOrderServiceRescheduleOrderArgs = {
  input?: InputMaybe<RescheduleOrderRequestInput>;
};


export type MutationOrderServiceStageLastmileOrderArgs = {
  input?: InputMaybe<StageLastmileOrderRequestInput>;
};


export type MutationPartnerCallbackCreateArgs = {
  event: Scalars['String'];
  orderId: Scalars['String'];
  partnerId: Scalars['ID'];
};


export type MutationPartnerPickDeviceCreateArgs = {
  deviceType: DeviceTypeEnum;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isPartnerPickStoreManager: Scalars['Boolean'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  warehouseLocationId: Scalars['ID'];
};


export type MutationPartnerPickDeviceSetStatusArgs = {
  active: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationPartnerPickDeviceUpdateArgs = {
  deviceType?: InputMaybe<DeviceTypeEnum>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isPartnerPickStoreManager?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  warehouseLocationId?: InputMaybe<Scalars['ID']>;
};


export type MutationPartnerPickRecurringShiftCreateArgs = {
  daysOfWeek: Array<Scalars['Int']>;
  driverId: Scalars['ID'];
  onCall?: InputMaybe<Scalars['Boolean']>;
  recurEndDate?: InputMaybe<Scalars['String']>;
  recurStartDate: Scalars['String'];
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  shiftType: ShiftTypeEnum;
  warehouseLocationId: Scalars['ID'];
};


export type MutationPartnerPickRecurringShiftDeleteArgs = {
  deleteShiftsFromDate: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationPartnerPickRecurringShiftUpdateArgs = {
  daysOfWeek?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['ID'];
  onCall?: InputMaybe<Scalars['Boolean']>;
  recurEndDate?: InputMaybe<Scalars['String']>;
  recurStartDate?: InputMaybe<Scalars['String']>;
  shiftLengthSeconds?: InputMaybe<Scalars['Int']>;
  shiftStartSecondsSinceMidnight?: InputMaybe<Scalars['Int']>;
};


export type MutationPrivacyServiceCreatePiiRequestForRetailerArgs = {
  input?: InputMaybe<CreatePiiRequestForRetailerRequestInput>;
};


export type MutationReportRequestCreateArgs = {
  associatedObjectId: Scalars['ID'];
  associatedObjectType: ReportRequestAssociatedObjectTypeEnum;
  filters?: InputMaybe<ReportFilters>;
  name: Scalars['String'];
  reportId: Scalars['ID'];
};


export type MutationRetailerAislesUpdateRetailerAisleTemplateArgs = {
  input?: InputMaybe<UpdateRetailerAisleTemplateRequestInput>;
};


export type MutationRetailerAvailabilityVisibilityConditionCreateArgs = {
  retailerAvailabilityVisibilityConditionData: RetailerAvailabilityInput;
};


export type MutationRetailerAvailabilityVisibilityConditionUpdateArgs = {
  retailerAvailabilityVisibilityConditionData: RetailerAvailabilityInput;
};


export type MutationRetailerCampaignServiceAddRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<AddRetailerCampaignTreatmentsRequestInput>;
};


export type MutationRetailerCampaignServiceCancelMomentArgs = {
  input?: InputMaybe<CancelMomentRequestInput>;
};


export type MutationRetailerCampaignServiceCancelRetailerCampaignArgs = {
  input?: InputMaybe<CancelRetailerCampaignRequestInput>;
};


export type MutationRetailerCampaignServiceCreateMomentArgs = {
  input?: InputMaybe<CreateMomentRequestInput>;
};


export type MutationRetailerCampaignServiceCreateRetailerCampaignArgs = {
  input?: InputMaybe<CreateRetailerCampaignRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateMomentArgs = {
  input?: InputMaybe<UpdateMomentRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateMomentCampaignStatusArgs = {
  input?: InputMaybe<UpdateMomentCampaignStatusRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateRetailerCampaignArgs = {
  input?: InputMaybe<UpdateRetailerCampaignRequestInput>;
};


export type MutationRetailerCampaignServiceUpdateRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<UpdateRetailerCampaignTreatmentsRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceCreateCollectionArgs = {
  input?: InputMaybe<CreateCollectionRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceCreateInvfMappingArgs = {
  input?: InputMaybe<CreateInvfMappingRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceCreateRootNodeArgs = {
  input?: InputMaybe<CreateRootNodeRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceDeleteCollectionArgs = {
  input?: InputMaybe<DeleteCollectionRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceDeleteInvfMappingArgs = {
  input?: InputMaybe<DeleteInvfMappingRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceDeleteNavigationNodeArgs = {
  input?: InputMaybe<DeleteNavigationNodeRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceOrderNavigationNodesArgs = {
  input?: InputMaybe<OrderNavigationNodesRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceQueueNavigationMigrationArgs = {
  input?: InputMaybe<QueueNavigationMigrationRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceStartCsvImportArgs = {
  input?: InputMaybe<StartCsvImportRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceStartCsvSummaryArgs = {
  input?: InputMaybe<StartCsvSummaryRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceUpdateCollectionArgs = {
  input?: InputMaybe<UpdateCollectionRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceUpdateCollectionProductsArgs = {
  input?: InputMaybe<UpdateCollectionProductsRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceUpdateNavigationConfigurationArgs = {
  input?: InputMaybe<UpdateNavigationConfigurationRequestInput>;
};


export type MutationRetailerCollectionsAdminServiceUpsertNavigationNodeArgs = {
  input?: InputMaybe<UpsertNavigationNodeRequestInput>;
};


export type MutationRetailerCollectionsServiceAddProductsToRetailerCollectionArgs = {
  input?: InputMaybe<AddProductsToRetailerCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceCreateInventoryFileMappingArgs = {
  input?: InputMaybe<CreateInventoryFileMappingRequestInput>;
};


export type MutationRetailerCollectionsServiceCreateRetailerCollectionArgs = {
  input?: InputMaybe<CreateRetailerCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceDeleteInventoryFileMappingArgs = {
  input?: InputMaybe<DeleteInventoryFileMappingRequestInput>;
};


export type MutationRetailerCollectionsServiceDeleteRetailerCollectionArgs = {
  input?: InputMaybe<DeleteRetailerCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceDuplicateInstacartCollectionArgs = {
  input?: InputMaybe<DuplicateInstacartCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceDuplicateRetailerCollectionArgs = {
  input?: InputMaybe<DuplicateRetailerCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceProcessRetailerCollectionsCsvArgs = {
  input?: InputMaybe<ProcessRetailerCollectionsCsvRequestInput>;
};


export type MutationRetailerCollectionsServiceRemoveProductsFromRetailerCollectionArgs = {
  input?: InputMaybe<RemoveProductsFromRetailerCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceSetCollectionFiltersArgs = {
  input?: InputMaybe<SetCollectionFiltersRequestInput>;
};


export type MutationRetailerCollectionsServiceUpdateInstacartCollectionArgs = {
  input?: InputMaybe<UpdateInstacartCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceUpdateRetailerCollectionArgs = {
  input?: InputMaybe<UpdateRetailerCollectionRequestInput>;
};


export type MutationRetailerCollectionsServiceUpdateRetailerCollectionInvfEnrollmentArgs = {
  input?: InputMaybe<UpdateRetailerCollectionInvfEnrollmentRequestInput>;
};


export type MutationRetailerCollectionsServiceUpdateRetailerCollectionPinnedStatusArgs = {
  input?: InputMaybe<UpdateRetailerCollectionPinnedStatusRequestInput>;
};


export type MutationRetailerCollectionsServiceUpdateRetailerCollectionStatusArgs = {
  input?: InputMaybe<UpdateRetailerCollectionStatusRequestInput>;
};


export type MutationRetailerCollectionsServiceUpsertRetailerConfigArgs = {
  input?: InputMaybe<UpsertRetailerConfigRequestInput>;
};


export type MutationRetailerCollectionsServiceValidateRetailerCollectionsCsvArgs = {
  input?: InputMaybe<ValidateRetailerCollectionsCsvRequestInput>;
};


export type MutationRetailerLocationGroupCreateArgs = {
  name: Scalars['String'];
  retailerId: Scalars['ID'];
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRetailerLocationGroupDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationRetailerLocationGroupUpdateArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRetailerManagementServiceCreateClientConfigurationArgs = {
  input?: InputMaybe<CreateClientConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceCreateConnectRetailerCallbackConfigurationArgs = {
  input?: InputMaybe<CreateConnectRetailerCallbackConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceCreateStoreConfigurationArgs = {
  input?: InputMaybe<CreateStoreConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceDeleteConnectRetailerCallbackConfigurationArgs = {
  input?: InputMaybe<DeleteConnectRetailerCallbackConfigurationRequestInput>;
};


export type MutationRetailerManagementServiceUpdateConnectRetailerCallbackConfigurationArgs = {
  input?: InputMaybe<UpdateConnectRetailerCallbackConfigurationRequestInput>;
};


export type MutationRetailerMfaSetEnabledArgs = {
  retailerId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationRetailerProductAttributeEditServiceUpdateRetailerProductVisibilitiesArgs = {
  input?: InputMaybe<UpdateRetailerProductVisibilitiesRequestInput>;
};


export type MutationRetailerSatisfactionSurveySubmissionCreateArgs = {
  accountId: Scalars['ID'];
  milestoneSlug?: InputMaybe<Scalars['String']>;
  retailerId: Scalars['ID'];
  shownAt: Scalars['String'];
  submittedAt: Scalars['String'];
  surveyResponses: Array<RetailerSatisfactionSurveyResponse>;
  urlPath: Scalars['String'];
};


export type MutationRetailerSettingsServiceDeleteImageArgs = {
  input?: InputMaybe<DeleteImageRequestInput>;
};


export type MutationRetailerSettingsServiceUpdateParkingSettingsArgs = {
  input?: InputMaybe<UpdateParkingSettingsRequestInput>;
};


export type MutationRetailerSettingsServiceUpdateStagingSettingsArgs = {
  input?: InputMaybe<UpdateStagingSettingsRequestInput>;
};


export type MutationRetailerStoreHoursUpdateConfirmStoreHoursArgs = {
  input?: InputMaybe<UpdateConfirmedStoreHoursRequestInput>;
};


export type MutationRetailerStoreHoursUpdateStoreHoursArgs = {
  input?: InputMaybe<UpdateStoreHoursRequestInput>;
};


export type MutationRetailersServiceMoveRetailerAssetsFromFilestackBucketArgs = {
  input?: InputMaybe<MoveRetailerAssetsFromFilestackBucketRequestInput>;
};


export type MutationReturnsServiceCreateOrUpdateReturnArgs = {
  input?: InputMaybe<CreateOrUpdateReturnRequestInput>;
};


export type MutationRoleCreateArgs = {
  role: CreateRoleInput;
};


export type MutationRoleDeleteArgs = {
  roleId: Scalars['ID'];
};


export type MutationRoleUpdateArgs = {
  role: UpdateRoleInput;
};


export type MutationRppBulkImageUploadsServiceCreateImageUploadBatchArgs = {
  input?: InputMaybe<CreateImageUploadBatchRequestInput>;
};


export type MutationSandboxOrderSimulateEventArgs = {
  callbackEvent?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  partnerId: Scalars['String'];
  simulationEvent: Scalars['String'];
  simulationEventArgs?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSecondaryBannerPlacementCreateArgs = {
  secondaryBannerPlacementData: SecondaryBannerPlacementInput;
};


export type MutationSecondaryBannerPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  id: Scalars['ID'];
  secondaryBannerPlacementData: SecondaryBannerPlacementInput;
};


export type MutationShoppedAtRetailerVisibilityConditionCreateArgs = {
  shoppedAtRetailerVisibilityConditionData: ShoppedAtRetailerInput;
};


export type MutationShoppedAtRetailerVisibilityConditionUpdateArgs = {
  shoppedAtRetailerVisibilityConditionData: ShoppedAtRetailerInput;
};


export type MutationShoppedVisibilityConditionCreateArgs = {
  shoppedVisibilityConditionData: ShoppedInput;
};


export type MutationShoppedVisibilityConditionUpdateArgs = {
  shoppedVisibilityConditionData: ShoppedInput;
};


export type MutationStoreConfigurationsDraftServiceCreateStoreConfigurationDraftArgs = {
  input?: InputMaybe<CreateStoreConfigurationDraftRequestInput>;
};


export type MutationStoreConfigurationsDraftServicePublishStoreConfigurationDraftArgs = {
  input?: InputMaybe<PublishStoreConfigurationDraftRequestInput>;
};


export type MutationStoreConfigurationsDraftServiceRollbackStoreConfigurationArgs = {
  input?: InputMaybe<RollbackStoreConfigurationRequestInput>;
};


export type MutationStoreConfigurationsDraftServiceUpdateStoreConfigurationDraftArgs = {
  input?: InputMaybe<UpdateStoreConfigurationDraftRequestInput>;
};


export type MutationStorefrontPlacementCreateArgs = {
  retailerId: Scalars['ID'];
  storefrontPlacementData: StorefrontPlacementInput;
};


export type MutationStorefrontPlacementDeleteArgs = {
  id: Scalars['ID'];
  retailerId: Scalars['ID'];
};


export type MutationStorefrontPlacementUpdateArgs = {
  bannerId: Scalars['ID'];
  id: Scalars['ID'];
  retailerId: Scalars['ID'];
  storefrontPlacementData: StorefrontPlacementInput;
};


export type MutationUserInteractionLimitVisibilityConditionCreateArgs = {
  userInteractionLimitVisibilityConditionData: UserInteractionLimitInput;
};


export type MutationUserInteractionLimitVisibilityConditionUpdateArgs = {
  userInteractionLimitVisibilityConditionData: UserInteractionLimitInput;
};


export type MutationUserSettingServiceSetUserSettingArgs = {
  input?: InputMaybe<SetUserSettingRequestInput>;
};


export type MutationUsersServiceGrantImpersonationArgs = {
  input?: InputMaybe<GrantImpersonationRequestInput>;
};


export type MutationUtmParametersVisibilityConditionCreateArgs = {
  utmParametersVisibilityConditionData: UtmParametersInput;
};


export type MutationUtmParametersVisibilityConditionUpdateArgs = {
  utmParametersVisibilityConditionData: UtmParametersInput;
};


export type MutationValueBasedOverrideServiceCreateValueBasedOverrideArgs = {
  input?: InputMaybe<CreateValueBasedOverrideRequestInput>;
};


export type MutationVisibilityConditionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationWhitelistedPostalCodesVisibilityConditionCreateArgs = {
  whitelistedPostalCodesVisibilityConditionData: WhitelistedPostalCodesInput;
};


export type MutationWhitelistedPostalCodesVisibilityConditionUpdateArgs = {
  whitelistedPostalCodesVisibilityConditionData: WhitelistedPostalCodesInput;
};


export type MutationWhitelistedZonesVisibilityConditionCreateArgs = {
  whitelistedZonesVisibilityConditionData: WhitelistedZonesInput;
};


export type MutationWhitelistedZonesVisibilityConditionUpdateArgs = {
  whitelistedZonesVisibilityConditionData: WhitelistedZonesInput;
};


export type MutationWizardProgressUpdateArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type NavigateToAskInstacart = {
  __typename?: 'NavigateToAskInstacart';
  prompt: Maybe<Scalars['String']>;
  useBusinessDerivedAttributes: Maybe<Scalars['Boolean']>;
};

export type NavigateToAskInstacartInput = {
  prompt?: InputMaybe<Scalars['String']>;
  useBusinessDerivedAttributes?: InputMaybe<Scalars['Boolean']>;
};

export type NavigateToAuthentication = {
  __typename?: 'NavigateToAuthentication';
  authentication: Maybe<Authentication>;
};

export type NavigateToAuthenticationInput = {
  authentication?: InputMaybe<AuthenticationInput>;
};

export type NavigateToBenefitsModal = {
  __typename?: 'NavigateToBenefitsModal';
  id: Maybe<Scalars['BigInt']>;
};

export type NavigateToBenefitsModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToCategorySurface = {
  __typename?: 'NavigateToCategorySurface';
  categorySurfaceType: Maybe<CategorySurfaceType>;
};

export type NavigateToCategorySurfaceInput = {
  categorySurfaceType?: InputMaybe<CategorySurfaceType>;
};

export type NavigateToCollaborativeShopInfoModal = {
  __typename?: 'NavigateToCollaborativeShopInfoModal';
  id: Maybe<Scalars['BigInt']>;
};

export type NavigateToCollaborativeShopInfoModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToCollection = {
  __typename?: 'NavigateToCollection';
  legacyPath: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
};

export type NavigateToCollectionHub = {
  __typename?: 'NavigateToCollectionHub';
  category: Maybe<Scalars['String']>;
  collection: Maybe<Scalars['String']>;
};

export type NavigateToCollectionHubInput = {
  category?: InputMaybe<Scalars['String']>;
  collection?: InputMaybe<Scalars['String']>;
};

export type NavigateToCollectionInput = {
  legacyPath?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type NavigateToContainerPathViaStoreSelector = {
  __typename?: 'NavigateToContainerPathViaStoreSelector';
  retailers: Maybe<Array<Maybe<RetailerContainerPath>>>;
};

export type NavigateToContainerPathViaStoreSelectorInput = {
  retailers?: InputMaybe<Array<InputMaybe<RetailerContainerPathInput>>>;
};

export type NavigateToContentPage = {
  __typename?: 'NavigateToContentPage';
  slug: Maybe<Scalars['String']>;
};

export type NavigateToContentPageInput = {
  slug?: InputMaybe<Scalars['String']>;
};

export type NavigateToCouponRedemption = {
  __typename?: 'NavigateToCouponRedemption';
  couponCode: Maybe<Scalars['String']>;
  landingUrl: Maybe<Scalars['String']>;
  validateDays: Maybe<Scalars['BigInt']>;
  visibleAfterRedeem: Maybe<Scalars['Boolean']>;
};

export type NavigateToCouponRedemptionInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  validateDays?: InputMaybe<Scalars['BigInt']>;
  visibleAfterRedeem?: InputMaybe<Scalars['Boolean']>;
};

export type NavigateToDepartment = {
  __typename?: 'NavigateToDepartment';
  departmentId: Maybe<Scalars['BigInt']>;
};

export type NavigateToDepartmentInput = {
  departmentId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToExpressAccountPage = {
  __typename?: 'NavigateToExpressAccountPage';
  id: Maybe<Scalars['BigInt']>;
};

export type NavigateToExpressAccountPageInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToHouseholdAccountSharingFlow = {
  __typename?: 'NavigateToHouseholdAccountSharingFlow';
  id: Maybe<Scalars['BigInt']>;
};

export type NavigateToHouseholdAccountSharingFlowInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToPickupStoreSelector = {
  __typename?: 'NavigateToPickupStoreSelector';
  showDistance: Maybe<Scalars['Boolean']>;
};

export type NavigateToPickupStoreSelectorInput = {
  showDistance?: InputMaybe<Scalars['Boolean']>;
};

export type NavigateToProduct = {
  __typename?: 'NavigateToProduct';
  productId: Maybe<Scalars['BigInt']>;
};

export type NavigateToProductInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToPromoModal = {
  __typename?: 'NavigateToPromoModal';
  discountPolicyId: Maybe<Scalars['BigInt']>;
};

export type NavigateToPromoModalInput = {
  discountPolicyId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToPromotionDetail = {
  __typename?: 'NavigateToPromotionDetail';
  id: Maybe<Scalars['BigInt']>;
};

export type NavigateToPromotionDetailInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToRetailerCollection = {
  __typename?: 'NavigateToRetailerCollection';
  retailerId: Maybe<Scalars['BigInt']>;
  slug: Maybe<Scalars['String']>;
};

export type NavigateToRetailerCollectionInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type NavigateToRetailerCollectionViaStoreSelector = {
  __typename?: 'NavigateToRetailerCollectionViaStoreSelector';
  retailerCollections: Maybe<Array<Maybe<RetailerCollection>>>;
};

export type NavigateToRetailerCollectionViaStoreSelectorInput = {
  retailerCollections?: InputMaybe<Array<InputMaybe<RetailerCollectionInput>>>;
};

export type NavigateToSsaStoreSelector = {
  __typename?: 'NavigateToSsaStoreSelector';
  serviceAreaType: Maybe<Scalars['String']>;
};

export type NavigateToSsaStoreSelectorInput = {
  serviceAreaType?: InputMaybe<Scalars['String']>;
};

export type NavigateToStoreSelector = {
  __typename?: 'NavigateToStoreSelector';
  retailerIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  storeForwardModuleType: Maybe<Scalars['String']>;
};

export type NavigateToStoreSelectorInput = {
  retailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  storeForwardModuleType?: InputMaybe<Scalars['String']>;
};

export type NavigateToStorefront = {
  __typename?: 'NavigateToStorefront';
  retailerId: Maybe<Scalars['BigInt']>;
};

export type NavigateToStorefrontInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigateToUrl = {
  __typename?: 'NavigateToUrl';
  openInNewTab: Maybe<Scalars['Boolean']>;
  openInNewWindow: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
};

export type NavigateToUrlInput = {
  openInNewTab?: InputMaybe<Scalars['Boolean']>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Navigation = {
  __typename?: 'Navigation';
  id: Maybe<Scalars['BigInt']>;
  items: Maybe<Array<Maybe<NavigationItem>>>;
  locale: Maybe<InstacartCustomersSharedV1Locale>;
  logoUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
};

export type NavigationAction = {
  __typename?: 'NavigationAction';
  data: Maybe<Scalars['JSONObject']>;
  typeName: Maybe<Scalars['String']>;
};

export type NavigationActionInput = {
  data?: InputMaybe<Scalars['JSONObject']>;
  typeName?: InputMaybe<Scalars['String']>;
};

export type NavigationConfiguration = {
  __typename?: 'NavigationConfiguration';
  alwaysPinned: Maybe<Scalars['Boolean']>;
  inventoryCountDisabled: Maybe<Scalars['Boolean']>;
  maxTopLevelNodes: Maybe<Scalars['BigInt']>;
  sourceDomain: Maybe<Scalars['String']>;
};

export type NavigationConfigurationInput = {
  alwaysPinned?: InputMaybe<Scalars['Boolean']>;
  inventoryCountDisabled?: InputMaybe<Scalars['Boolean']>;
  maxTopLevelNodes?: InputMaybe<Scalars['BigInt']>;
  sourceDomain?: InputMaybe<Scalars['String']>;
};

export type NavigationInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  items?: InputMaybe<Array<InputMaybe<NavigationItemInput>>>;
  locale?: InputMaybe<InstacartCustomersSharedV1Locale>;
  logoUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  actions: Maybe<Array<Maybe<NavigationAction>>>;
  groupPosition: Maybe<Scalars['String']>;
  grouping: Maybe<Scalars['String']>;
  icon: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  imageUrl: Maybe<Scalars['String']>;
  isNested: Maybe<Scalars['Boolean']>;
  itemType: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  position: Maybe<Scalars['BigInt']>;
  text: Maybe<Scalars['String']>;
  variant: Maybe<Scalars['String']>;
};

export type NavigationItemInput = {
  actions?: InputMaybe<Array<InputMaybe<NavigationActionInput>>>;
  groupPosition?: InputMaybe<Scalars['String']>;
  grouping?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isNested?: InputMaybe<Scalars['Boolean']>;
  itemType?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  position?: InputMaybe<Scalars['BigInt']>;
  text?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<Scalars['String']>;
};

export type NavigationNode = {
  __typename?: 'NavigationNode';
  alcoholic: Maybe<Scalars['Boolean']>;
  alwaysShow: Maybe<Scalars['Boolean']>;
  children: Maybe<Array<Maybe<NavigationNode>>>;
  contentType: Maybe<NavigationNodeContentType>;
  endDate: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  isDynamic: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  pinned: Maybe<Scalars['Boolean']>;
  position: Maybe<Scalars['BigInt']>;
  productCount: Maybe<Scalars['BigInt']>;
  retailerCollectionId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
  slug: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  status: Maybe<CollectionStatus>;
  storeConfigurationId: Maybe<Scalars['BigInt']>;
  tileImageUrl: Maybe<Scalars['String']>;
  translations: Maybe<Array<Maybe<Translation>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum NavigationNodeContentType {
  Collections = 'COLLECTIONS',
  Products = 'PRODUCTS',
  Root = 'ROOT',
  Unspecified = 'UNSPECIFIED'
}

export type NewVerticalUserTargetingInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** The module type */
  moduleType: Scalars['String'];
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of NewVerticalUserTargetingVisibilityConditionCreate */
export type NewVerticalUserTargetingVisibilityConditionCreatePayload = {
  __typename?: 'NewVerticalUserTargetingVisibilityConditionCreatePayload';
  /** The newly created NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityCondition: ContentManagementVisibilityConditionNewVerticalUserTargeting;
};

/** Autogenerated return type of NewVerticalUserTargetingVisibilityConditionUpdate */
export type NewVerticalUserTargetingVisibilityConditionUpdatePayload = {
  __typename?: 'NewVerticalUserTargetingVisibilityConditionUpdatePayload';
  /** The newly updated NewVerticalUserTargeting visibility condition */
  newVerticalUserTargetingVisibilityCondition: ContentManagementVisibilityConditionNewVerticalUserTargeting;
};

export type NonenumeratedManagedAttributes = {
  __typename?: 'NonenumeratedManagedAttributes';
  lookupCodePluOnly: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Autogenerated return type of NormalizedReportsMerge */
export type NormalizedReportsMergePayload = {
  __typename?: 'NormalizedReportsMergePayload';
  /** Merge successful */
  success: Scalars['Boolean'];
};

export type NormalizedReportsNormalizedReport = {
  __typename?: 'NormalizedReportsNormalizedReport';
  /** Name of the bucket where the report comes from */
  bucketName: Scalars['String'];
  /** Date at which the report was created in YYYY-MM-DD format */
  createdAt: Scalars['Time'];
  /** Type of the file */
  fileType: Scalars['String'];
  /** Name of the file */
  name: Scalars['String'];
  /** Type of the report */
  reportType: ReportTypeEnum;
  /** File path to the object in the bucket */
  s3Key: Scalars['String'];
  /** Size in bytes of the object */
  size: Scalars['Int'];
};

/** The connection type for NormalizedReportsNormalizedReport. */
export type NormalizedReportsNormalizedReportConnection = {
  __typename?: 'NormalizedReportsNormalizedReportConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<NormalizedReportsNormalizedReportEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<NormalizedReportsNormalizedReport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NormalizedReportsNormalizedReportEdge = {
  __typename?: 'NormalizedReportsNormalizedReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<NormalizedReportsNormalizedReport>;
};

export type NormalizedReportsNormalizedReportSignedUrl = {
  __typename?: 'NormalizedReportsNormalizedReportSignedUrl';
  /** Signed url for the object */
  signedUrl: Scalars['String'];
};

export type NormalizedReportsReportType = {
  __typename?: 'NormalizedReportsReportType';
  /** Report type label */
  label: Scalars['String'];
  /** The report type value */
  value: ReportTypeEnum;
};

/** Autogenerated return type of NotificationSubscriptionCreate */
export type NotificationSubscriptionCreatePayload = {
  __typename?: 'NotificationSubscriptionCreatePayload';
  /** Subscription successful */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of NotificationSubscriptionDelete */
export type NotificationSubscriptionDeletePayload = {
  __typename?: 'NotificationSubscriptionDeletePayload';
  /** Unsubscribe successful */
  success: Scalars['Boolean'];
};

export type NotificationsNotificationType = {
  __typename?: 'NotificationsNotificationType';
  /** Description of notification type, ie: Inventory File Normalization */
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If the user making the request is eligible to subscribe to this notification */
  isEligible: Scalars['Boolean'];
  /** If the user making the request is subscribed to the notification type for the specified retailer */
  isSubscribed: Scalars['Boolean'];
  /** Name of notification type, ie: Inventory File Normalization */
  name: Scalars['String'];
};

export type NotificationsNotificationTypeAccount = {
  __typename?: 'NotificationsNotificationTypeAccount';
  /** Account ID of user */
  accountId: Scalars['ID'];
  /** Email of user */
  email: Scalars['ID'];
  /** Given name of user */
  givenName: Scalars['String'];
  /** If the user is eligible to subscribe to this notification */
  isEligible: Scalars['Boolean'];
  /** If the user is subscribed to the notification type for the specified retailer */
  isSubscribed: Scalars['Boolean'];
  /** Surname of user */
  surname: Maybe<Scalars['String']>;
};

export type ObjectKeyMappingInput = {
  destinationObjectKey?: InputMaybe<Scalars['String']>;
  sourceObjectKey?: InputMaybe<Scalars['String']>;
};

export type OffersSchema = {
  __typename?: 'OffersSchema';
  disableFlierPlacement: Maybe<Scalars['Boolean']>;
};

export type Onboarding = {
  __typename?: 'Onboarding';
  billingAddress: Maybe<Address>;
  contactInfo: Maybe<Contact>;
  contractRenderData: Maybe<ContractRenderData>;
  corporationType: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  duns: Maybe<Scalars['String']>;
  entityName: Maybe<Scalars['String']>;
  exclusivity: Maybe<Scalars['String']>;
  ironcladData: Maybe<IroncladData>;
  isaImageSignedUrls: Maybe<Array<Maybe<Scalars['String']>>>;
  isaPdfSignedUrl: Maybe<Scalars['String']>;
  ndaImageSignedUrls: Maybe<Array<Maybe<Scalars['String']>>>;
  ndaPdfSignedUrl: Maybe<Scalars['String']>;
  onboardingType: Maybe<Scalars['String']>;
  posSystemName: Maybe<Scalars['String']>;
  signupPartner: Maybe<SignupPartner>;
  stateOfIncorporation: Maybe<Scalars['String']>;
  storeCount: Maybe<Scalars['String']>;
  storeLocation: Maybe<Address>;
  updatedAt: Maybe<Scalars['DateTime']>;
  w9Form: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
  workflowState: Maybe<Scalars['String']>;
};

export type OnboardingFeatureTypesMobileAppConfig = {
  __typename?: 'OnboardingFeatureTypesMobileAppConfig';
  /** The asset information for uploaded items */
  assets: Array<MutableS3Asset>;
  /** The properties for the app */
  properties: Array<OnboardingFeatureTypesProperty>;
};

export type OnboardingFeatureTypesProperty = {
  __typename?: 'OnboardingFeatureTypesProperty';
  /** The property name */
  name: Scalars['String'];
  /** The property value */
  value: Maybe<Scalars['JSON']>;
};

export enum OnboardingWorkflowStateAction {
  OnboardingWorkflowStateActionComplete = 'ONBOARDING_WORKFLOW_STATE_ACTION_COMPLETE',
  OnboardingWorkflowStateActionProvideBillingAddress = 'ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_BILLING_ADDRESS',
  OnboardingWorkflowStateActionProvideOnboardingInfo = 'ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_ONBOARDING_INFO',
  OnboardingWorkflowStateActionProvideStoreLocations = 'ONBOARDING_WORKFLOW_STATE_ACTION_PROVIDE_STORE_LOCATIONS',
  OnboardingWorkflowStateActionSignIsa = 'ONBOARDING_WORKFLOW_STATE_ACTION_SIGN_ISA',
  OnboardingWorkflowStateActionSignNda = 'ONBOARDING_WORKFLOW_STATE_ACTION_SIGN_NDA',
  OnboardingWorkflowStateActionStart = 'ONBOARDING_WORKFLOW_STATE_ACTION_START',
  OnboardingWorkflowStateActionUnknown = 'ONBOARDING_WORKFLOW_STATE_ACTION_UNKNOWN',
  OnboardingWorkflowStateActionViewIsa = 'ONBOARDING_WORKFLOW_STATE_ACTION_VIEW_ISA',
  OnboardingWorkflowStateActionViewNda = 'ONBOARDING_WORKFLOW_STATE_ACTION_VIEW_NDA'
}

export type OpenExpressPaidModal = {
  __typename?: 'OpenExpressPaidModal';
  id: Maybe<Scalars['BigInt']>;
};

export type OpenExpressPaidModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type OpenExpressTrialModal = {
  __typename?: 'OpenExpressTrialModal';
  id: Maybe<Scalars['BigInt']>;
};

export type OpenExpressTrialModalInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type OperationalHours = {
  __typename?: 'OperationalHours';
  displayableTimePeriods: Maybe<Array<Maybe<TimePeriod>>>;
  isCrossDay: Maybe<Scalars['Boolean']>;
  isOpenAllDay: Maybe<Scalars['Boolean']>;
  timePeriods: Maybe<Array<Maybe<TimePeriod>>>;
};

export type OptInUser = {
  __typename?: 'OptInUser';
  id: Maybe<Scalars['BigInt']>;
  optOut: Maybe<Scalars['Boolean']>;
};

export type OptInUserInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  optOut?: InputMaybe<Scalars['Boolean']>;
};

export type OrderByComponentInput = {
  asc?: InputMaybe<Scalars['Boolean']>;
  component?: InputMaybe<Scalars['String']>;
};

export type OrderInput = {
  checkoutRetailerLocationId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  shoppedRetailerLocationId?: InputMaybe<Scalars['BigInt']>;
  zoneId?: InputMaybe<Scalars['BigInt']>;
};

export type OrderNavigationNodesRequestInput = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type OrderNavigationNodesResponse = {
  __typename?: 'OrderNavigationNodesResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type OrderSources = {
  __typename?: 'OrderSources';
  /** Order Sources */
  orderSources: Maybe<Array<Scalars['String']>>;
};

export type OrdersSchema = {
  __typename?: 'OrdersSchema';
  disablePostCheckoutDeliveryFallbackImage: Maybe<Scalars['Boolean']>;
  disableSpecialRequests: Maybe<Scalars['Boolean']>;
};

export enum Os {
  /** Android app */
  Android = 'android',
  /** iOS app */
  IOs = 'iOS'
}

export type OutsourceDataEntryItem = {
  __typename?: 'OutsourceDataEntryItem';
  outsourceItemId: Maybe<Scalars['String']>;
};

export type OverrideHours = {
  __typename?: 'OverrideHours';
  date: Maybe<GoogleTypeDate>;
  description: Maybe<Scalars['String']>;
  endTime: Maybe<GoogleTypeTimeOfDay>;
  serviceType: Maybe<RetailerServiceType>;
  startTime: Maybe<GoogleTypeTimeOfDay>;
};

export type OverrideHoursInput = {
  date?: InputMaybe<GoogleTypeDateInput>;
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
  serviceType?: InputMaybe<RetailerServiceType>;
  startTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
};

export enum OverrideType {
  OverrideTypeExclude = 'OVERRIDE_TYPE_EXCLUDE',
  OverrideTypeReplace = 'OVERRIDE_TYPE_REPLACE',
  OverrideTypeUnknown = 'OVERRIDE_TYPE_UNKNOWN'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  limit: Maybe<Scalars['BigInt']>;
  offset: Maybe<Scalars['BigInt']>;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
};

export type Partner = {
  __typename?: 'Partner';
  /** A unique identifier for the partner */
  id: Scalars['ID'];
  /** The name of the partner */
  name: Scalars['String'];
  /** The retailers belonging to this partner */
  retailers: Array<Retailer>;
  /** A sandbox order belonging to the partner */
  sandboxOrder: Maybe<PartnerSandboxOrder>;
};


export type PartnerSandboxOrderArgs = {
  id: Scalars['String'];
};

export type PartnerCallbackConfiguration = {
  __typename?: 'PartnerCallbackConfiguration';
  /** The access token TTL */
  accessTokenTtl: Scalars['Int'];
  /** The authentication URI */
  authUri: Scalars['String'];
  /** The possible statuses to enable */
  availableStatuses: Array<Scalars['String']>;
  /** The callback URI */
  callbackUri: Scalars['String'];
  /** The client id */
  credentialsClientId: Scalars['String'];
  /** The client credentials_client_secret */
  credentialsClientSecret: Scalars['String'];
  /** The environment that the configuration belongs to */
  environment: Scalars['String'];
  /** A unique identifier for the callback configuration */
  id: Scalars['ID'];
  /** The enabled statusers */
  statusesOfInterest: Array<Scalars['String']>;
};

/** Autogenerated return type of PartnerCallbackConfigurationUpdate */
export type PartnerCallbackConfigurationUpdatePayload = {
  __typename?: 'PartnerCallbackConfigurationUpdatePayload';
  /** The updated callback configuration */
  callbackConfiguration: PartnerCallbackConfiguration;
};

/** Autogenerated return type of PartnerCallbackCreate */
export type PartnerCallbackCreatePayload = {
  __typename?: 'PartnerCallbackCreatePayload';
  /** The response(s) from the callback */
  callbackResponse: Array<PartnerCallbackResponse>;
};

export type PartnerCallbackResponse = {
  __typename?: 'PartnerCallbackResponse';
  /** The body that was sent */
  body: Scalars['String'];
  /** The headers that were sent */
  headers: Scalars['String'];
  /** The type of the notification log */
  logType: Scalars['String'];
  /** The response body */
  responseBody: Scalars['String'];
  /** The response code */
  responseCode: Scalars['String'];
  /** The time when the callback was sent */
  timestamp: Scalars['String'];
  /** The url the callback was sent to */
  url: Scalars['String'];
};

/** The connection type for Partner. */
export type PartnerConnection = {
  __typename?: 'PartnerConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PartnerEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Partner>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PartnerEdge = {
  __typename?: 'PartnerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Partner>;
};

export type PartnerPickCapacityGetCapacityRecommendationsResponse = {
  __typename?: 'PartnerPickCapacityGetCapacityRecommendationsResponse';
  allowedRecommendationTypes: Array<RecommendationTypeEnum>;
  recommendations: Array<PartnerPickCapacityRecommendation>;
};

export type PartnerPickCapacityRecommendation = {
  __typename?: 'PartnerPickCapacityRecommendation';
  date: Scalars['ISO8601Date'];
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  recommendationType: RecommendationTypeEnum;
  retailerLocationId: Scalars['ID'];
  shiftType: ShiftTypeEnum;
  startsAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of PartnerPickDeviceCreate */
export type PartnerPickDeviceCreatePayload = {
  __typename?: 'PartnerPickDeviceCreatePayload';
  /** The newly created device */
  partnerPickDevice: PartnerPickDevicesDevice;
};

/** Autogenerated return type of PartnerPickDeviceSetStatus */
export type PartnerPickDeviceSetStatusPayload = {
  __typename?: 'PartnerPickDeviceSetStatusPayload';
  /** The updated device */
  partnerPickDevice: PartnerPickDevicesDevice;
};

/** Autogenerated return type of PartnerPickDeviceUpdate */
export type PartnerPickDeviceUpdatePayload = {
  __typename?: 'PartnerPickDeviceUpdatePayload';
  /** The updated device */
  partnerPickDevice: PartnerPickDevicesDevice;
};

export type PartnerPickDevicesDevice = {
  __typename?: 'PartnerPickDevicesDevice';
  /** Status for the device */
  active: Scalars['Boolean'];
  /** Type of device */
  deviceType: DeviceTypeEnum;
  /** Email address for the device */
  email: Scalars['String'];
  /** First name for the device */
  firstName: Scalars['String'];
  /** A unique identifier for the device */
  id: Scalars['ID'];
  /** Whether the device belongs to a store manager */
  isPartnerPickStoreManager: Scalars['Boolean'];
  /** Last name for the device */
  lastName: Scalars['String'];
  /** marqeta cards metatdata for the device */
  marqetaCardsMetadata: Maybe<PartnerPickDevicesMarqetaCardsMetadata>;
  /** Phone number for the device */
  phone: Scalars['String'];
};

export type PartnerPickDevicesDeviceSearch = {
  __typename?: 'PartnerPickDevicesDeviceSearch';
  devices: Array<PartnerPickDevicesDevice>;
  meta: HubPaginationMeta;
};

export type PartnerPickDevicesMarqetaCardsMetadata = {
  __typename?: 'PartnerPickDevicesMarqetaCardsMetadata';
  digital: Maybe<Scalars['Int']>;
  inactive: Maybe<Scalars['Int']>;
  physical: Maybe<Scalars['Int']>;
  suspended: Maybe<Scalars['Int']>;
  terminated: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of PartnerPickRecurringShiftCreate */
export type PartnerPickRecurringShiftCreatePayload = {
  __typename?: 'PartnerPickRecurringShiftCreatePayload';
  /** The newly created recurring shift */
  partnerPickRecurringShift: PartnerPickRecurringShiftsRecurringShift;
};

/** Autogenerated return type of PartnerPickRecurringShiftDelete */
export type PartnerPickRecurringShiftDeletePayload = {
  __typename?: 'PartnerPickRecurringShiftDeletePayload';
  errorMessage: Maybe<Scalars['String']>;
  /** Whether the deletion succeeded or not */
  success: Scalars['True'];
};

/** Autogenerated return type of PartnerPickRecurringShiftUpdate */
export type PartnerPickRecurringShiftUpdatePayload = {
  __typename?: 'PartnerPickRecurringShiftUpdatePayload';
  /** The updated recurring shift */
  partnerPickRecurringShift: PartnerPickRecurringShiftsRecurringShift;
};

export type PartnerPickRecurringShiftsConflict = {
  __typename?: 'PartnerPickRecurringShiftsConflict';
  recurringShiftConflicts: Array<PartnerPickRecurringShiftsRecurringShiftConflict>;
  singleShiftConflicts: Array<PartnerPickRecurringShiftsSingleShiftConflict>;
};

export type PartnerPickRecurringShiftsRecurringShift = {
  __typename?: 'PartnerPickRecurringShiftsRecurringShift';
  /** an array of Ruby Date wday that a shift recurs on (0-6, Sunday is zero) */
  daysOfWeek: Array<Scalars['Int']>;
  driverId: Scalars['ID'];
  driverShiftId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  onCall: Maybe<Scalars['Boolean']>;
  recurEndDate: Maybe<Scalars['ISO8601Date']>;
  recurStartDate: Maybe<Scalars['ISO8601Date']>;
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  shiftType: ShiftTypeEnum;
};

export type PartnerPickRecurringShiftsRecurringShiftConflict = {
  __typename?: 'PartnerPickRecurringShiftsRecurringShiftConflict';
  /** Days of the week when a shift occurs, represented as an Int (0-6). Sunday is 0 */
  daysOfWeek: Array<Scalars['Int']>;
  id: Scalars['ID'];
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  shiftType: ShiftTypeEnum;
};

export type PartnerPickRecurringShiftsSingleShiftConflict = {
  __typename?: 'PartnerPickRecurringShiftsSingleShiftConflict';
  date: Scalars['ISO8601Date'];
  endsAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  shiftType: ShiftTypeEnum;
  startsAt: Scalars['ISO8601DateTime'];
};

export type PartnerSandboxOrder = {
  __typename?: 'PartnerSandboxOrder';
  /** The logs from callback attempts for the sandbox order */
  callbackLogs: Maybe<Array<PartnerSandboxOrderCallbackLog>>;
  /** Timestamp when the sandbox order was created */
  createdAt: Scalars['String'];
  /** Timestamp when the sandbox order was delivered */
  deliveredAt: Maybe<Scalars['String']>;
  /** A unique identifier for the sandbox order */
  id: Scalars['ID'];
  /** Items within the sandbox order */
  items: Array<PartnerSandboxOrderItem>;
  /** Events that can be simulated on this order */
  simulationEvents: Array<PartnerSandboxOrderSimulationEvent>;
  /** The fulfillment status of the sandbox order */
  status: Scalars['String'];
  /** Timestamp when the sandbox order delivery window ends */
  windowEndsAt: Scalars['String'];
  /** Timestamp when the sandbox order delivery window starts */
  windowStartsAt: Scalars['String'];
};

export type PartnerSandboxOrderCallbackLog = {
  __typename?: 'PartnerSandboxOrderCallbackLog';
  /** The event that the callback is for */
  event: Scalars['String'];
  /** The response for the callback */
  logs: Maybe<Array<PartnerCallbackResponse>>;
};

export type PartnerSandboxOrderItem = {
  __typename?: 'PartnerSandboxOrderItem';
  /** Retailer reference code to identify the item */
  itemRrc: Maybe<Scalars['String']>;
  /** Universal product code to identify the item */
  itemUpc: Maybe<Scalars['String']>;
  /** The unique line number decided by the partner */
  lineNum: Scalars['String'];
  /** The quantity of items for the order */
  qty: Scalars['Float'];
  /** The quantity unit the quantity value is in */
  qtyUnit: Scalars['String'];
  /** Whether or not this item was replaced during fulfillment */
  replaced: Scalars['Boolean'];
};

/** Autogenerated return type of PartnerSandboxOrderSimulateEvent */
export type PartnerSandboxOrderSimulateEventPayload = {
  __typename?: 'PartnerSandboxOrderSimulateEventPayload';
  /** The order that went through simulation */
  sandboxOrder: PartnerSandboxOrder;
};

export type PartnerSandboxOrderSimulationEvent = {
  __typename?: 'PartnerSandboxOrderSimulationEvent';
  /** Arguments required when simulating this event */
  arguments: Array<Scalars['String']>;
  /** The name of this simulation event */
  event: Scalars['String'];
  /** The ID of the sandbox order these simulation events belong to */
  orderId: Scalars['String'];
  /** The result when this event is simulated */
  outcome: PartnerSandboxOrderSimulationEventOutcome;
};

export type PartnerSandboxOrderSimulationEventOutcome = {
  __typename?: 'PartnerSandboxOrderSimulationEventOutcome';
  /** A callback that will be triggered when the event is simulated */
  callback: Maybe<Scalars['String']>;
  /** The status the order will be when the event is simulated */
  orderStatus: Scalars['String'];
};

export type PaymentsSchema = {
  __typename?: 'PaymentsSchema';
  useSiteNameForIcWalletBranding: Maybe<Scalars['Boolean']>;
};

export type Permission = {
  __typename?: 'Permission';
  /** Indicates if the permission can be used for Retailer custom roles */
  isRetailerRoleAssignable: Scalars['Boolean'];
  /** A unique identifier for the permission */
  slug: Scalars['ID'];
};

export type PermissionInput = {
  /** A unique identifier for the permission */
  slug: Scalars['ID'];
};

export enum PickingModelEnum {
  /** Carrotwork */
  Carrotwork = 'CARROTWORK',
  /** Full service shoppers */
  FullService = 'FULL_SERVICE',
  /** In store shoppers */
  Iss = 'ISS',
  /** Partner managed */
  Retailer = 'RETAILER'
}

export enum PickupType {
  PickupTypeCurbside = 'PICKUP_TYPE_CURBSIDE',
  PickupTypeInStore = 'PICKUP_TYPE_IN_STORE',
  PickupTypeUnknown = 'PICKUP_TYPE_UNKNOWN'
}

export type PiiRequest = {
  __typename?: 'PiiRequest';
  createdAt: Maybe<Scalars['DateTime']>;
  downloadLink: Maybe<Scalars['String']>;
  requestId: Maybe<Scalars['BigInt']>;
  requestType: Maybe<RequestType>;
  retailerNotes: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  userEmail: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['BigInt']>;
  workflowStatus: Maybe<WorkflowStatus>;
};

export enum PiiRequestCreationStatus {
  PiiRequestCreationStatusDuplicateError = 'PII_REQUEST_CREATION_STATUS_DUPLICATE_ERROR',
  PiiRequestCreationStatusInternalError = 'PII_REQUEST_CREATION_STATUS_INTERNAL_ERROR',
  PiiRequestCreationStatusNotFound = 'PII_REQUEST_CREATION_STATUS_NOT_FOUND',
  PiiRequestCreationStatusSuccess = 'PII_REQUEST_CREATION_STATUS_SUCCESS',
  PiiRequestCreationStatusUnspecified = 'PII_REQUEST_CREATION_STATUS_UNSPECIFIED',
  PiiRequestCreationStatusUserNotFound = 'PII_REQUEST_CREATION_STATUS_USER_NOT_FOUND',
  PiiRequestCreationStatusUserStoreConfigurationNotFound = 'PII_REQUEST_CREATION_STATUS_USER_STORE_CONFIGURATION_NOT_FOUND'
}

export type Placement = {
  __typename?: 'Placement';
  banner: Maybe<BannerType>;
  banners: Maybe<Array<Maybe<BannerType>>>;
  cardList: Maybe<CardList>;
  contentPageVersionId: Maybe<Scalars['BigInt']>;
  createdAt: Maybe<Scalars['DateTime']>;
  enabled: Maybe<Scalars['Boolean']>;
  endDate: Maybe<Scalars['DateTime']>;
  experimentMethod: Maybe<ExperimentMethod>;
  experimentName: Maybe<Scalars['String']>;
  experimentVariant: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  includedStoreConfigurationIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  internalName: Maybe<Scalars['String']>;
  itemList: Maybe<ItemList>;
  layout: Maybe<Layout>;
  layoutVariantId: Maybe<Scalars['BigInt']>;
  platform: Maybe<Array<Maybe<PlatformType>>>;
  rank: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
  startDate: Maybe<Scalars['DateTime']>;
  status: Maybe<Status>;
  teamOwner: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  userOwner: Maybe<Scalars['BigInt']>;
  widget: Maybe<Widget>;
};

export enum PlacementAdminContextAudience {
  AdminContextAudienceUnspecified = 'ADMIN_CONTEXT_AUDIENCE_UNSPECIFIED',
  InstacartAdmins = 'INSTACART_ADMINS',
  ThirdPartyAdmins = 'THIRD_PARTY_ADMINS'
}

export type PlacementAdminContextInput = {
  audience?: InputMaybe<PlacementAdminContextAudience>;
};

export type PlacementContent = {
  __typename?: 'PlacementContent';
  contentPageVersionId: Maybe<Scalars['BigInt']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  enabled: Maybe<Scalars['Boolean']>;
  endsAt: Maybe<Scalars['DateTime']>;
  excludedRetailerIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  experimentMethod: Maybe<Scalars['String']>;
  experimentName: Maybe<Scalars['String']>;
  experimentVariant: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  includedRetailerIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  internalName: Maybe<Scalars['String']>;
  layout: Maybe<Scalars['String']>;
  layoutVariantId: Maybe<Scalars['BigInt']>;
  parentPlacementId: Maybe<Scalars['BigInt']>;
  placementBucketId: Maybe<Scalars['BigInt']>;
  placementType: Maybe<Scalars['String']>;
  placementTypeData: Maybe<Array<Maybe<PlacementContentDataStruct>>>;
  platform: Maybe<Array<Maybe<Scalars['String']>>>;
  rank: Maybe<Scalars['BigInt']>;
  slotCreatives: Maybe<Array<Maybe<SlotCreative>>>;
  slots: Maybe<Array<Maybe<Slot>>>;
  startsAt: Maybe<Scalars['DateTime']>;
  teamOwner: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  userOwner: Maybe<Scalars['String']>;
  visibilityConditions: Maybe<Array<Maybe<VisibilityConditionStruct>>>;
};

export type PlacementContentDataStruct = {
  __typename?: 'PlacementContentDataStruct';
  contentData: Maybe<Scalars['JSONObject']>;
  contentDataId: Maybe<Scalars['BigInt']>;
  contentType: Maybe<Scalars['String']>;
  contentVariant: Maybe<Scalars['String']>;
};

export type PlacementContentDataStructInput = {
  contentData?: InputMaybe<Scalars['JSONObject']>;
  contentDataId?: InputMaybe<Scalars['BigInt']>;
  contentType?: InputMaybe<Scalars['String']>;
  contentVariant?: InputMaybe<Scalars['String']>;
};

export type PlacementContentInput = {
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  excludedRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  excludedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  experimentMethod?: InputMaybe<Scalars['String']>;
  experimentName?: InputMaybe<Scalars['String']>;
  experimentVariant?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  includedRetailerIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  includedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  internalName?: InputMaybe<Scalars['String']>;
  layout?: InputMaybe<Scalars['String']>;
  layoutVariantId?: InputMaybe<Scalars['BigInt']>;
  parentPlacementId?: InputMaybe<Scalars['BigInt']>;
  placementBucketId?: InputMaybe<Scalars['BigInt']>;
  placementType?: InputMaybe<Scalars['String']>;
  placementTypeData?: InputMaybe<Array<InputMaybe<PlacementContentDataStructInput>>>;
  platform?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  rank?: InputMaybe<Scalars['BigInt']>;
  slotCreatives?: InputMaybe<Array<InputMaybe<SlotCreativeInput>>>;
  slots?: InputMaybe<Array<InputMaybe<SlotInput>>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  teamOwner?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userOwner?: InputMaybe<Scalars['String']>;
  visibilityConditions?: InputMaybe<Array<InputMaybe<VisibilityConditionStructInput>>>;
};

export type PlacementContentRequestPagination = {
  __typename?: 'PlacementContentRequestPagination';
  orderBy: Maybe<Array<Maybe<PlacementContentRequestPaginationOrder>>>;
  pageNumber: Maybe<Scalars['BigInt']>;
  pageSize: Maybe<Scalars['BigInt']>;
};

export type PlacementContentRequestPaginationInput = {
  orderBy?: InputMaybe<Array<InputMaybe<PlacementContentRequestPaginationOrderInput>>>;
  pageNumber?: InputMaybe<Scalars['BigInt']>;
  pageSize?: InputMaybe<Scalars['BigInt']>;
};

export type PlacementContentRequestPaginationOrder = {
  __typename?: 'PlacementContentRequestPaginationOrder';
  descending: Maybe<Scalars['Boolean']>;
  placementId: Maybe<Scalars['BigInt']>;
  placementRank: Maybe<Scalars['BigInt']>;
};

export type PlacementContentRequestPaginationOrderInput = {
  descending?: InputMaybe<Scalars['Boolean']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  placementRank?: InputMaybe<Scalars['BigInt']>;
};

export type PlacementContentResponsePagination = {
  __typename?: 'PlacementContentResponsePagination';
  hasNextPage: Maybe<Scalars['Boolean']>;
  requestPagination: Maybe<PlacementContentRequestPagination>;
};

export type PlacementContentSurfaceInput = {
  surfaceIdentifier?: InputMaybe<Scalars['String']>;
  surfaceType?: InputMaybe<Scalars['String']>;
};

export type PlacementDestinationInput = {
  /** The target destination of interacting with the placement. Can be an ID of a product/collection/department, or a URL */
  target: Scalars['String'];
  /** What the corresponding target refers to */
  type: PlacementDestinationTypeEnum;
};

export enum PlacementDestinationTypeEnum {
  /** The placement links to a collection */
  Collection = 'COLLECTION',
  /** The placement links to a collection hub */
  CollectionHub = 'COLLECTION_HUB',
  /** The placement links to containers via a store selector */
  ContainerPathViaStoreSelector = 'CONTAINER_PATH_VIA_STORE_SELECTOR',
  /** The placement links to a department */
  Department = 'DEPARTMENT',
  /** The placement does not have an action */
  DoNotNavigate = 'DO_NOT_NAVIGATE',
  /** The placement links to an external url */
  External = 'EXTERNAL',
  /** The placement links to the pickup store selector */
  PickupStoreselector = 'PICKUP_STORESELECTOR',
  /** The placement links to a product */
  Product = 'PRODUCT',
  /** The placement links to the SSA store selector */
  SsaStoreselector = 'SSA_STORESELECTOR',
  /** The placement links to a storefront */
  Storefront = 'STOREFRONT',
  /** The placement links to a store selector */
  StoreSelector = 'STORE_SELECTOR',
  /** Unfamiliar Banner Action */
  Unknown = 'UNKNOWN'
}

export type PlacementExperimentInput = {
  /** Experiment Assignment or Lookup */
  accessMethod?: InputMaybe<PlacementExperimentMethodEnum>;
  /** Name of the Experiment */
  name: Scalars['String'];
  /** Variant of the Experiment for which placement should be visible */
  variant: Scalars['String'];
};

export enum PlacementExperimentMethodEnum {
  /** Whether variant assignment should happen */
  Assign = 'ASSIGN',
  /** Not specified */
  ExperimentMethodUnspecified = 'EXPERIMENT_METHOD_UNSPECIFIED',
  /** Whether a variant lookup should occur */
  Lookup = 'LOOKUP',
  /** Whether a variant preview should occur */
  Preview = 'PREVIEW'
}

export type PlacementFilterInput = {
  /** The platform(s) the placement is configured for (Eg. [MOBILE, WEB]) */
  platform?: InputMaybe<Array<PlacementPlatformEnum>>;
  /** A term to use to search for a placement */
  searchTerm?: InputMaybe<Scalars['String']>;
  /** The current status of the placement (Eg. ACTIVE) */
  status?: InputMaybe<PlacementStatusEnum>;
  /** The type of the placement (Eg. TEXT) */
  type?: InputMaybe<PlacementTypeEnum>;
};

export type PlacementImagesInput = {
  /** The image url of the placement to display for large viewports */
  large: Scalars['Url'];
  /** The image url of the placement to display for small viewports */
  small: Scalars['Url'];
};

export type PlacementInput = {
  banner?: InputMaybe<BannerTypeInput>;
  banners?: InputMaybe<Array<InputMaybe<BannerTypeInput>>>;
  cardList?: InputMaybe<CardListInput>;
  contentPageVersionId?: InputMaybe<Scalars['BigInt']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  experimentMethod?: InputMaybe<ExperimentMethod>;
  experimentName?: InputMaybe<Scalars['String']>;
  experimentVariant?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  includedStoreConfigurationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  internalName?: InputMaybe<Scalars['String']>;
  itemList?: InputMaybe<ItemListInput>;
  layout?: InputMaybe<Layout>;
  layoutVariantId?: InputMaybe<Scalars['BigInt']>;
  platform?: InputMaybe<Array<InputMaybe<PlatformType>>>;
  rank?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Status>;
  teamOwner?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userOwner?: InputMaybe<Scalars['BigInt']>;
  widget?: InputMaybe<WidgetInput>;
};

export enum PlacementPlatformEnum {
  /** This placement should display on android */
  Android = 'ANDROID',
  /** This placement should display on ios */
  Ios = 'IOS',
  /** This placement should display on mobile */
  Mobile = 'MOBILE',
  /** This placement should display on web */
  Web = 'WEB'
}

export type PlacementScheduleInput = {
  /** When the placement should stop being active */
  endDate?: InputMaybe<Scalars['Time']>;
  /** When the placement should become active */
  startDate: Scalars['Time'];
  /** The timezone in which the banner will stop being active at midnight */
  timezone: Scalars['String'];
};

export type PlacementSortInput = {
  /** The direction to sort */
  direction?: InputMaybe<PlacementSortInputDirectionEnum>;
  /** The field to sort on */
  field: PlacementSortInputFieldEnum;
};

export enum PlacementSortInputDirectionEnum {
  /** Sort the results in ascending order */
  Asc = 'ASC',
  /** Sort the results in descending order */
  Desc = 'DESC'
}

export enum PlacementSortInputFieldEnum {
  /** Sort the results based on the status of the placement */
  CreatedAt = 'CREATED_AT',
  /** Sort the results based on the status of the placement */
  Status = 'STATUS'
}

export enum PlacementStatusEnum {
  /** The placement is currently active */
  Active = 'ACTIVE',
  /** The placement is not currently active */
  Inactive = 'INACTIVE',
  /** The placement is currently the live banner */
  Live = 'LIVE'
}

export enum PlacementTypeEnum {
  /** This is ASYNC_IMAGE placement */
  AsyncImage = 'ASYNC_IMAGE',
  /** This is ASYNC_MODAL placement */
  AsyncModal = 'ASYNC_MODAL',
  /** This is CAROUSEL_CARD placement */
  CarouselCard = 'CAROUSEL_CARD',
  /** This is CATEGORY_HUB placement */
  CategoryHub = 'CATEGORY_HUB',
  /** This is HEADER placement */
  Header = 'HEADER',
  /** This placement displays a HeroBanner Home Announcement Banner */
  HeroBanner = 'HERO_BANNER',
  /** This placement displays an image */
  Image = 'IMAGE',
  /** This placement displays an ImageForward Home Announcement Banner */
  ImageForward = 'IMAGE_FORWARD',
  /** This placement displays a ImageForwardFlex Home Announcement Banner */
  ImageForwardFlex = 'IMAGE_FORWARD_FLEX',
  /** This is INFORMATION_MODAL placement */
  InformationModal = 'INFORMATION_MODAL',
  /** This is LARGE placement */
  Large = 'LARGE',
  /** This is LOGO_WITH_TEXT placement */
  LogoWithText = 'LOGO_WITH_TEXT',
  /** This is MEGA placement */
  Mega = 'MEGA',
  /** This is OCCASION_HUB placement */
  OccasionHub = 'OCCASION_HUB',
  /** This is RICH_TEXT placement */
  RichText = 'RICH_TEXT',
  /** This placement displays a SecondaryBanner Home Announcement Banner */
  SecondaryBanner = 'SECONDARY_BANNER',
  /** This is flyout placement */
  StandardInformationModal = 'STANDARD_INFORMATION_MODAL',
  /** This is SURVEY placement */
  Survey = 'SURVEY',
  /** This placement displays text */
  Text = 'TEXT',
  /** This is TEXT_SLIM placement */
  TextSlim = 'TEXT_SLIM',
  /** This placement displays a TextWithThumbnail Home Announcement Banner */
  TextWithThumbnail = 'TEXT_WITH_THUMBNAIL',
  /** This placement displays a TextWithThumbnailFlex Home Announcement Banner */
  TextWithThumbnailFlex = 'TEXT_WITH_THUMBNAIL_FLEX',
  /** This is TITLED_MEDIA placement */
  TitledMedia = 'TITLED_MEDIA',
  /** This placement displays an ImageForward Home Announcement Banner */
  Unknown = 'UNKNOWN'
}

export type PlacementsResponse = {
  __typename?: 'PlacementsResponse';
  placements: Maybe<Array<Maybe<Placement>>>;
};

export enum PlatformType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type PostAuthPaths = {
  __typename?: 'PostAuthPaths';
  loginPath: Maybe<Scalars['String']>;
  signupPath: Maybe<Scalars['String']>;
};

export type PostAuthPathsInput = {
  loginPath?: InputMaybe<Scalars['String']>;
  signupPath?: InputMaybe<Scalars['String']>;
};

export type PricingSchema = {
  __typename?: 'PricingSchema';
  _: Maybe<Scalars['Boolean']>;
};

export type ProblemTrackerEntityStatus = {
  __typename?: 'ProblemTrackerEntityStatus';
  calculatedEntityId: Maybe<EntityId>;
  createdAt: Maybe<Scalars['DateTime']>;
  explanation: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  providedEntityId: Maybe<EntityId>;
  providedEntityType: Maybe<EntityType>;
  status: Maybe<EntityStatus>;
  ticketId: Maybe<Scalars['BigInt']>;
};

export type ProblemTrackerTicket = {
  __typename?: 'ProblemTrackerTicket';
  assignee: Maybe<User>;
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  entities: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  entityType: Maybe<EntityType>;
  fixTaskGroupUuid: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  localeCode: Maybe<Scalars['String']>;
  problemType: Maybe<ProblemType>;
  reportedOnBehalfOf: Maybe<User>;
  retailerId: Maybe<Scalars['BigInt']>;
  severity: Maybe<Scalars['BigInt']>;
  sourceAppName: Maybe<Scalars['String']>;
  status: Maybe<TicketStatus>;
  summaryStatus: Maybe<Array<Maybe<SummaryStatus>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type ProblemTrackerTicketComment = {
  __typename?: 'ProblemTrackerTicketComment';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<User>;
  id: Maybe<Scalars['BigInt']>;
};

export enum ProblemType {
  ProblemTypeOther = 'PROBLEM_TYPE_OTHER',
  ProblemTypeProductAlcohol = 'PROBLEM_TYPE_PRODUCT_ALCOHOL',
  ProblemTypeProductAvailability = 'PROBLEM_TYPE_PRODUCT_AVAILABILITY',
  ProblemTypeProductBrand = 'PROBLEM_TYPE_PRODUCT_BRAND',
  ProblemTypeProductCharacteristics = 'PROBLEM_TYPE_PRODUCT_CHARACTERISTICS',
  ProblemTypeProductClassification = 'PROBLEM_TYPE_PRODUCT_CLASSIFICATION',
  ProblemTypeProductDetails = 'PROBLEM_TYPE_PRODUCT_DETAILS',
  ProblemTypeProductIdentity = 'PROBLEM_TYPE_PRODUCT_IDENTITY',
  ProblemTypeProductImage = 'PROBLEM_TYPE_PRODUCT_IMAGE',
  ProblemTypeProductSize = 'PROBLEM_TYPE_PRODUCT_SIZE',
  ProblemTypeProductTaxonomy = 'PROBLEM_TYPE_PRODUCT_TAXONOMY',
  ProblemTypeUnknown = 'PROBLEM_TYPE_UNKNOWN'
}

export type ProcessRetailerCollectionsCsvRequestInput = {
  bulkUploadType?: InputMaybe<RetailerCollectionBulkUploadType>;
  fileName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type ProcessRetailerCollectionsCsvResponse = {
  __typename?: 'ProcessRetailerCollectionsCsvResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Product = {
  __typename?: 'Product';
  cachedProductId: Maybe<Scalars['BigInt']>;
  displayName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  lookupCode: Maybe<Scalars['String']>;
  pinned: Maybe<Scalars['Boolean']>;
  position: Maybe<Scalars['BigInt']>;
  primaryImageUrl: Maybe<Scalars['String']>;
  retailerReferenceCode: Maybe<Scalars['String']>;
};

export type ProductAnalyticSummaryRequestInput = {
  dateRange?: InputMaybe<InstacartAdsSharedV1DateRangeInput>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductAnalyticsSummary = {
  __typename?: 'ProductAnalyticsSummary';
  avgOrdersPerStore: Maybe<Scalars['Float']>;
  avgSalesPerStoreUsd: Maybe<Scalars['Float']>;
  avgStoreCount: Maybe<Scalars['Float']>;
  productId: Maybe<Scalars['BigInt']>;
  totalOrders: Maybe<Scalars['BigInt']>;
  totalSalesUsd: Maybe<Scalars['Float']>;
  totalStores: Maybe<Scalars['BigInt']>;
};

export type ProductAnalyticsSummaryGrowthMetrics = {
  __typename?: 'ProductAnalyticsSummaryGrowthMetrics';
  avgOrdersPerStoreGrowth: Maybe<Scalars['Float']>;
  avgSalesPerStoreUsdGrowth: Maybe<Scalars['Float']>;
  productId: Maybe<Scalars['BigInt']>;
  totalOrdersGrowth: Maybe<Scalars['Float']>;
  totalSalesUsdGrowth: Maybe<Scalars['Float']>;
  totalStoresGrowth: Maybe<Scalars['Float']>;
};

export type ProductAnalyticsSummaryResponse = {
  __typename?: 'ProductAnalyticsSummaryResponse';
  analyticsSummary: Maybe<ProductAnalyticsSummary>;
  growthMetrics: Maybe<ProductAnalyticsSummaryGrowthMetrics>;
  previousPeriodMetrics: Maybe<ProductAnalyticsSummary>;
};

export type ProductAttributes = {
  __typename?: 'ProductAttributes';
  avgUnitPrice: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['BigInt']>;
  imageUrl: Maybe<Scalars['String']>;
  legalFilterType: Maybe<LegalFilterType>;
  name: Maybe<Scalars['String']>;
  size: Maybe<Scalars['String']>;
  unitCount: Maybe<Scalars['String']>;
  upc: Maybe<Scalars['String']>;
};

export type ProductAttributesRequestInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductAttributesResponse = {
  __typename?: 'ProductAttributesResponse';
  productAttributes: Maybe<ProductAttributes>;
};

export type ProductBrandProfileAttributesRequestInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductBrandProfileAttributesResponse = {
  __typename?: 'ProductBrandProfileAttributesResponse';
  brandProfileAttributes: Maybe<BrandProfileAttributes>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  l1Category: Maybe<Scalars['String']>;
  l1CategoryId: Maybe<Scalars['BigInt']>;
  l2Category: Maybe<Scalars['String']>;
  l2CategoryId: Maybe<Scalars['BigInt']>;
  l3Category: Maybe<Scalars['String']>;
  l3CategoryId: Maybe<Scalars['BigInt']>;
  l4Category: Maybe<Scalars['String']>;
  l4CategoryId: Maybe<Scalars['BigInt']>;
  l5Category: Maybe<Scalars['String']>;
  l5CategoryId: Maybe<Scalars['BigInt']>;
  l6Category: Maybe<Scalars['String']>;
  l6CategoryId: Maybe<Scalars['BigInt']>;
  productCategory: Maybe<Scalars['String']>;
  productCategoryId: Maybe<Scalars['BigInt']>;
};

export type ProductCategoryTaxonomy = {
  __typename?: 'ProductCategoryTaxonomy';
  l1: Maybe<Scalars['String']>;
  l2: Maybe<Scalars['String']>;
  l3: Maybe<Scalars['String']>;
  l4: Maybe<Scalars['String']>;
  l5: Maybe<Scalars['String']>;
  l6: Maybe<Scalars['String']>;
};

export type ProductData = {
  __typename?: 'ProductData';
  attributeData: Maybe<Array<Maybe<AttributeData>>>;
  localeId: Maybe<Scalars['String']>;
};

export type ProductDisplay = {
  __typename?: 'ProductDisplay';
  imageAsset: Maybe<ImageAsset>;
  productId: Maybe<Scalars['BigInt']>;
};

export type ProductId = {
  __typename?: 'ProductId';
  locale: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['BigInt']>;
};

export type ProductIdInput = {
  locale?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductInput = {
  cachedProductId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  lookupCode?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['BigInt']>;
  retailerReferenceCode?: InputMaybe<Scalars['String']>;
};

export type ProductSellSheetCardsRequestInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type ProductSellSheetCardsResponse = {
  __typename?: 'ProductSellSheetCardsResponse';
  sellSheetCards: Maybe<Array<Maybe<SellSheetCard>>>;
};

export type ProductSource = {
  __typename?: 'ProductSource';
  defaultPriority: Maybe<Scalars['BigInt']>;
  description: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  sourceLevel: Maybe<SourceLevel>;
};

export type ProductSummaryMetrics = {
  __typename?: 'ProductSummaryMetrics';
  avgSalesPerStoreUsd: Maybe<Scalars['Float']>;
  dateRange: Maybe<InstacartAdsSharedV1DateRange>;
  storeCount: Maybe<Scalars['BigInt']>;
  totalSalesUsd: Maybe<Scalars['Float']>;
};

export type ProductSummaryTraits = {
  __typename?: 'ProductSummaryTraits';
  isCarriedByRetailer: Maybe<Scalars['Boolean']>;
  productAttributes: Maybe<ProductAttributes>;
  productSummaryMetrics: Maybe<ProductSummaryMetrics>;
};

export type PropertyInput = {
  /** The property name */
  name: Scalars['String'];
  /** The property value */
  value?: InputMaybe<Scalars['JSON']>;
};

export type ProposedReturnInput = {
  orderDeliveryId?: InputMaybe<Scalars['BigInt']>;
  returnItems?: InputMaybe<Array<InputMaybe<ReturnItemInput>>>;
  returnStatus?: InputMaybe<ProposedReturnStatus>;
  returnType?: InputMaybe<ProposedReturnType>;
};

export enum ProposedReturnStatus {
  Accepted = 'Accepted',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Pending = 'Pending',
  ProposedReturnStatusUnspecified = 'ProposedReturnStatusUnspecified',
  Refunded = 'Refunded'
}

export enum ProposedReturnType {
  Manual = 'Manual',
  ProposedReturnTypeUnspecified = 'ProposedReturnTypeUnspecified',
  RetailerIntegration = 'RetailerIntegration'
}

export type PublicRetailer = {
  __typename?: 'PublicRetailer';
  /** Country associated with this retailer */
  country: Country;
  /** A unique identifier for the retailer */
  id: Scalars['ID'];
  /** The name of the retailer */
  name: Scalars['String'];
};

export type PublishRppConfigurableItemsRequestInput = {
  configurableProducts?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type PublishRppConfigurableItemsResponse = {
  __typename?: 'PublishRppConfigurableItemsResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type PublishStoreConfigurationDraftRequestInput = {
  draftId?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type PublishStoreConfigurationDraftResponse = {
  __typename?: 'PublishStoreConfigurationDraftResponse';
  errorMessage: Maybe<Scalars['String']>;
  errorReason: Maybe<ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  _noop: Maybe<Scalars['String']>;
  /** The access control details for the account */
  accessControlConfiguration: AccessControlConfiguration;
  /** Check if account request for given email already exists */
  accountRequestPreviousRequestExistByEmail: AccountRequestPreviousRequestExists;
  /** Search for retailers matching a set of criteria - without an account */
  accountRequestRetailerSearch: Array<PublicRetailer>;
  /** Search for an previously created Account Request by ID */
  accountRequestSearchById: AccountRequestAccountRequest;
  /** Check if an account token (reset password or verify email) is valid */
  accountTokenValidate: TokensValidation;
  /** Fetch list of users eligible to subscribe to an event. Also indicates if they are currently subscribed. */
  accountsByNotificationType: Array<NotificationsNotificationTypeAccount>;
  apiIntegrationsServiceGetApiSettings: Maybe<GetApiSettingsResponse>;
  apiIntegrationsServiceGetAuthenticationUrl: Maybe<GetAuthenticationUrlResponse>;
  apiIntegrationsServiceGetAvailableStoreLocations: Maybe<GetAvailableStoreLocationsResponse>;
  assignmentsGetOrderFulfillmentOverview: Maybe<GetOrderFulfillmentOverviewResponse>;
  /** Search for Audit Logs */
  auditLogSearch: AuditLogsAuditLogConnection;
  availabilityGetStoreHoursByDate: Maybe<GetStoreHoursByDateResponse>;
  availabilityGetStoreHoursByDayOfWeek: Maybe<GetStoreHoursByDayOfWeekResponse>;
  availabilityGetStoreHoursByZoneRetailer: Maybe<GetStoreHoursByZoneRetailerResponse>;
  /** Fetch a specific aisle by id */
  catalogAisle: CatalogAisle;
  /** Fetch a specific department by id */
  catalogDepartment: CatalogDepartment;
  catalogProblemTrackerServiceGetTicket: Maybe<GetTicketResponse>;
  catalogProblemTrackerServiceListTickets: Maybe<ListTicketsResponse>;
  /** Retrieve products by IDs */
  catalogProducts: Array<CatalogProduct>;
  /** Retrieve a retailer-product by its ID */
  catalogRetailerProduct: CatalogRetailerProduct;
  /** Search for retailer-products matching a set of criteria */
  catalogRetailerProductSearch: CatalogRetailerProductConnection;
  /** Fetch a signed url for an S3 report */
  catalogSearchDownloadSignedUrl: CatalogSearchDownloadsCatalogSearchDownloadSignedUrl;
  catalogToolsApiServiceDownloadCatalogSearchResults: Maybe<DownloadCatalogSearchResultsResponse>;
  catalogToolsApiServiceGetAnomalousItemsList: Maybe<GetAnomalousItemsListResponse>;
  catalogToolsApiServiceGetAnomalousItemsReport: Maybe<GetAnomalousItemsReportResponse>;
  catalogToolsApiServiceGetLocalesByRetailer: Maybe<GetLocalesByRetailerResponse>;
  catalogToolsApiServiceGetRetailerProductData: Maybe<GetRetailerProductDataResponse>;
  collectionsServiceGetCollections: Maybe<GetCollectionsResponse>;
  commerceInvoicingServiceGetOrderDiscountDetails: Maybe<GetOrderDiscountDetailsResponse>;
  configurableItemsServiceGetRppConfigurableItems: Maybe<GetRppConfigurableItemsResponse>;
  connectPlatformServiceGetConnectSettings: Maybe<GetConnectSettingsResponse>;
  connectPlatformServiceGetPartnerId: Maybe<GetPartnerIdResponse>;
  connectPlatformServiceGetRequestContexts: Maybe<GetRequestContextsResponse>;
  contentManagementAdminServiceGetNavigation: Maybe<GetNavigationResponse>;
  contentManagementAdminServiceGetPlacementContentById: Maybe<GetPlacementContentByIdResponse>;
  contentManagementAdminServiceListContentPageVersionMetas: Maybe<ListContentPageVersionMetasResponse>;
  contentManagementAdminServiceListContentPageVersions: Maybe<ListContentPageVersionsResponse>;
  contentManagementAdminServiceListContentPagesV2: Maybe<ListContentPagesResponseV2>;
  contentManagementAdminServiceListPlacementContent: Maybe<ListPlacementContentResponse>;
  contentManagementServiceGetLayoutVariants: Maybe<GetLayoutVariantsResponse>;
  contentManagementServiceGetPlacements: Maybe<PlacementsResponse>;
  contentManagementServiceGetThemePage: Maybe<GetThemePageResponse>;
  contentManagementServiceListThemePages: Maybe<ListThemePagesResponse>;
  /** The currently authenticated account */
  currentEnterpriseAccount: EnterpriseAccount;
  /** Check if user has access to the given arguments */
  currentEnterpriseAccountAuthorizationCheck: AccessControlAuthorizationAuthorizationCheck;
  customersOrdersServiceGetHandlingDetails: Maybe<CustomersGetHandlingDetailsResponse>;
  customersOrdersServiceGetOrder: Maybe<CustomersGetOrderResponse>;
  customersOrdersServiceGetOrderAmountsSummaryV0: Maybe<CustomersGetOrderAmountsSummaryV0Response>;
  customersOrdersServiceGetOrderLines: Maybe<CustomersGetOrderLinesResponse>;
  customersOrdersServiceGetPaymentMethodSummary: Maybe<CustomersGetPaymentMethodSummaryResponse>;
  customersOrdersServiceListOrders: Maybe<CustomersListOrdersResponse>;
  customersOrdersServiceQueryOrderReference: Maybe<CustomersQueryOrderReferenceResponse>;
  customersOrdersServiceQueryOrders: Maybe<CustomersQueryOrdersResponse>;
  demeterServiceGetAuditBatch: Maybe<GetAuditBatchResponse>;
  demeterServiceGetAuditTask: Maybe<GetAuditTaskResponse>;
  demeterServiceListAuditBatches: Maybe<ListAuditBatchesResponse>;
  demeterServiceListAuditWorkflows: Maybe<ListAuditWorkflowsResponse>;
  emergingBrandPortalServiceGetBrandProfileAttributes: Maybe<BrandProfileAttributesResponse>;
  emergingBrandPortalServiceGetBrandProfileSellSheetCards: Maybe<BrandProfileSellSheetCardsResponse>;
  emergingBrandPortalServiceGetBrandSellSheetsAttributes: Maybe<BrandSellSheetsAttributesResponse>;
  emergingBrandPortalServiceGetEBPAnalytics: Maybe<EbpAnalyticsResponse>;
  emergingBrandPortalServiceGetEBPProductAnalyticsSummary: Maybe<ProductAnalyticsSummaryResponse>;
  emergingBrandPortalServiceGetEBPProductAttributes: Maybe<ProductAttributesResponse>;
  emergingBrandPortalServiceGetIndexBrandNames: Maybe<IndexBrandNamesResponse>;
  emergingBrandPortalServiceGetIndexEBPCategories: Maybe<IndexEbpCategoriesResponse>;
  emergingBrandPortalServiceGetIndexSellSheetCards: Maybe<SellSheetCardsResponse>;
  emergingBrandPortalServiceGetProductBrandProfileAttributes: Maybe<ProductBrandProfileAttributesResponse>;
  emergingBrandPortalServiceGetProductSellSheetCards: Maybe<ProductSellSheetCardsResponse>;
  emergingBrandPortalServiceGetSellSheetAnalyticsSummary: Maybe<SellSheetAnalyticsSummaryResponse>;
  emergingBrandPortalServiceGetSellSheetAttributes: Maybe<SellSheetAttributesResponse>;
  emergingBrandPortalServiceGetSellSheetBrandProfileAttributes: Maybe<SellSheetBrandProfileAttributesResponse>;
  emergingBrandPortalServiceGetSellSheetProductsAttributes: Maybe<SellSheetProductsAttributesResponse>;
  emergingBrandPortalServiceGetSellSheetProductsSummaryTraits: Maybe<SellSheetProductsSummaryTraitsResponse>;
  /** Fetch an EnterpriseAccount by its canonical id */
  enterpriseAccount: EnterpriseAccount;
  /** Search for EnterpriseAccounts matching a set of criteria */
  enterpriseAccountSearch: EnterpriseAccountConnection;
  /** Check whether a given feature flag is fully/publicly enabled. */
  featureToggleFullyEnabled: FeatureTogglesEnabledCheck;
  /** Fetch a list of feature tours and their statuses for the current user */
  featureTours: Array<EnterpriseFeatureTour>;
  /** Check the health of the server */
  healthCheck: HealthCheck;
  historicalStorePerformanceServiceGetAggregatedHistoricalStorePerformance: Maybe<HistoricalStorePerformanceResponse>;
  /** Fetch a list of Marketplace Home placements */
  homePlacementSearch: Array<HomePlacement>;
  /** Check if user has access to the given arguments */
  insightsPortalAuthorizationCheck: AccessControlAuthorizationAuthorizationCheck;
  insightsPortalServiceGetInsightsPortalCustomReport: Maybe<InsightsPortalGetCustomReportResponse>;
  insightsPortalServiceGetInsightsPortalFilterOptions: Maybe<InsightsPortalFilterOptionsResponse>;
  insightsPortalServiceGetInsightsPortalGroupComparison: Maybe<InsightsPortalGroupComparisonResponse>;
  insightsPortalServiceGetInsightsPortalGroupMetricValue: Maybe<InsightsPortalGroupMetricValueResponse>;
  insightsPortalServiceGetInsightsPortalMetricValues: Maybe<InsightsPortalMetricValuesResponse>;
  insightsPortalServiceGetInsightsPortalMultiGroupData: Maybe<InsightsPortalMultiGroupResponse>;
  insightsPortalServiceGetInsightsPortalSchemaMetadata: Maybe<InsightsPortalSchemaMetadataResponse>;
  insightsPortalServiceGetInsightsPortalSnowflakeHeartbeat: Maybe<InsightsPortalSnowflakeHeartbeatResponse>;
  insightsPortalServiceListInsightsPortalCustomReports: Maybe<InsightsPortalListCustomReportsResponse>;
  /**
   * READ (Batch)
   *
   * Returns the set of segments matching the given context. Useful for
   * finding, e.g., all targeting segments in use by a particular retailer.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_GetAllSegmentDefinitions: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentStates>;
  /**
   * READ
   *
   * The response will have the same version number as the request (or the
   * greatest available if none was specified; see below) and the definition
   * associated with that version.
   */
  instacart_ads_platform_taas_v1_SegmentRegistryService_GetSegmentDefinition: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>;
  instacart_ads_v3_EmergingBrandPortalService_GetIndexBrandNames: Maybe<Instacart_Ads_V3_IndexBrandNamesResponse>;
  instacart_ads_v3_EmergingBrandPortalService_GetProductAdditionalInfo: Maybe<Instacart_Ads_V3_ProductAdditionalInfoResponse>;
  instacart_customers_coupons_v1_CouponsDomainService_GetCouponCodeExists: Maybe<Instacart_Customers_Coupons_V1_GetCouponCodeExistsResponse>;
  /** API to return order items details. */
  instacart_customers_orders_v1_OrdersService_GetOrderLines: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse>;
  /** Countries API */
  instacart_customers_partners_v1_PartnersService_GetCountries: Maybe<Instacart_Customers_Partners_V1_GetCountriesResponse>;
  /**
   * PostalCodes API
   * Search postal codes by name or id. Not recommended unless you can't use GetPostalCodesByZones, as
   * the list can be very long.
   */
  instacart_customers_partners_v1_PartnersService_GetPostalCodes: Maybe<Instacart_Customers_Partners_V1_GetPostalCodesResponse>;
  /** Search postal codes within a zone. This is the preferred method for searching postal codes. */
  instacart_customers_partners_v1_PartnersService_GetPostalCodesByZones: Maybe<Instacart_Customers_Partners_V1_GetPostalCodesByZonesResponse>;
  /** Regions API */
  instacart_customers_partners_v1_PartnersService_GetRegions: Maybe<Instacart_Customers_Partners_V1_GetRegionsResponse>;
  /** RetailerLocations aka WarehouseLocations API */
  instacart_customers_partners_v1_PartnersService_GetRetailerLocations: Maybe<Instacart_Customers_Partners_V1_GetRetailerLocationsResponse>;
  instacart_customers_partners_v1_PartnersService_GetRetailerLocationsByRetailer: Maybe<Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerResponse>;
  /** Retailers aka Warehouses API */
  instacart_customers_partners_v1_PartnersService_GetRetailers: Maybe<Instacart_Customers_Partners_V1_GetRetailersResponse>;
  /**
   * Zones API
   * Gets all zones, optionally filtered by name. Using GetZonesByRegion is preferred where possible as
   * the list of zones is very long when not filtered by region.
   */
  instacart_customers_partners_v1_PartnersService_GetZones: Maybe<Instacart_Customers_Partners_V1_GetZonesResponse>;
  /** Gets all zones in a region, optionally filtered by name. Preferred where possible. */
  instacart_customers_partners_v1_PartnersService_GetZonesByRegion: Maybe<Instacart_Customers_Partners_V1_GetZonesByRegionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetCollection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetCollectionRules: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetNavigationTree: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_GetRetailerCsv: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_ListCollectionProducts: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_ListCollections: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsResponse>;
  instacart_customers_retailer_collections_admin_v1_RetailerCollectionsAdminService_SearchCollectionProducts: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsResponse>;
  instacart_customers_retailer_management_v1_IppDraftManagementService_ListIppDrafts: Maybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsResponse>;
  instacart_customers_retailer_management_v1_IppDraftPreviewService_GetIppPreviewByToken: Maybe<Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenResponse>;
  instacart_customers_retailer_management_v1_IppDraftPreviewService_GetPreviewAuthToken: Maybe<Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenResponse>;
  instacart_enterprise_boba_core_offers_v1_OfferService_GetOrderDiscountInfo: Maybe<Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoResponse>;
  instacart_logistics_v1_FulfillmentEvents_GetFulfillmentEvent: Maybe<Instacart_Logistics_V1_GetFulfillmentEventResponse>;
  /** List all creators */
  instacart_logistics_v1_FulfillmentEvents_ListCreators: Maybe<Instacart_Logistics_V1_ListCreatorsResponse>;
  /** List all fulfillment events that match the given criteria */
  instacart_logistics_v1_FulfillmentEvents_ListFulfillmentEvents: Maybe<Instacart_Logistics_V1_ListFulfillmentEventsResponse>;
  /** List all tags */
  instacart_logistics_v1_FulfillmentEvents_ListTags: Maybe<Instacart_Logistics_V1_ListTagsResponse>;
  /** fetches detailed entitlement data for a single entitlement */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetEntitlementDetails: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsResponse>;
  /** fetches shallow entitlement data for many entitlements */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetEntitlements: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsResponse>;
  /** fetches detailed package data for a single package */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetPackageDetails: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsResponse>;
  /** fetches shallow package data for many packages */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetPackages: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesResponse>;
  /** fetches detailed entitlement profile data for a single retailer */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetRetailerEntitlementProfileDetails: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsResponse>;
  /** fetches shallow entitlement profile data for many retailers */
  instacart_retailer_tools_retailer_platform_api_v1_FeatureEntitlementsService_GetRetailerEntitlementProfiles: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesResponse>;
  inventoryIntelligenceServiceGetAcknowledgedOosIds: Maybe<GetAcknowledgedOosIdsResponse>;
  /** Fetch KnowledgeOwl Configuration */
  knowledgeOwlConfiguration: KnowledgeOwlConfiguration;
  /** Retrieve the current partner user */
  legacyCurrentPartnerUser: LegacyPartnerUser;
  /** Check the health of the server */
  legacyHealthCheck: LegacyHealthCheck;
  /** Query a Partner by id */
  legacyPartner: LegacyPartner;
  /** Query a Warehouse by id */
  legacyWarehouse: LegacyWarehouse;
  /** Query a WarehouseLocation by id */
  legacyWarehouseLocation: LegacyWarehouseLocation;
  linkAutosuggestServiceListLinkAutosuggestionAdmin: Maybe<ListLinkAutosuggestionAdminResponse>;
  /** Retrieve the size of the Braze Segment */
  marketingFeatureBrazeSegmentSize: MarketingFeatureTypesBrazeSegmentSize;
  /** Retrieve a list of available braze segments for the retailer */
  marketingFeatureBrazeSegments: Array<MarketingFeatureTypesBrazeSegment>;
  /** Fetches the status of a marketing campaign */
  marketingFeatureCampaignStatus: MarketingFeatureTypesCampaignStatus;
  /** Fetches an email template */
  marketingFeatureTemplate: MarketingFeatureTypesTemplate;
  /** Retrieves a list of email templates */
  marketingFeatureTemplateSearch: Array<MarketingFeatureTypesTemplate>;
  /** Fetch a signed url for a single S3 report */
  normalizedReportSignedUrl: NormalizedReportsNormalizedReportSignedUrl;
  /** Fetch a list of S3 report types */
  normalizedReportTypes: Array<NormalizedReportsReportType>;
  /** Fetch a list of S3 reports */
  normalizedReportsSearch: NormalizedReportsNormalizedReportConnection;
  /** Fetch list of notification types for a retailer */
  notificationTypesForCurrentAccountOnRetailer: Array<NotificationsNotificationType>;
  /** Get mobile app config */
  onboardingMobileAppConfig: OnboardingFeatureTypesMobileAppConfig;
  onboardingServiceCheckIfLaunchOnboardingInProgressByPartner: Maybe<CheckIfLaunchOnboardingInProgressByPartnerResponse>;
  onboardingServiceCheckLaunchOnboardingProgressByPartner: Maybe<CheckLaunchOnboardingProgressByPartnerResponse>;
  onboardingServiceGetCatalogFaqFile: Maybe<GetCatalogFaqFileResponse>;
  onboardingServiceGetLaunchOnboarding: Maybe<GetLaunchOnboardingResponse>;
  onboardingServiceGetOnboarding: Maybe<GetOnboardingResponse>;
  onboardingServiceGetSignupPartner: Maybe<GetSignupPartnerResponse>;
  orderServiceGetDashboardOrder: Maybe<GetDashboardOrderResponse>;
  orderServiceGetDashboardOrders: Maybe<GetDashboardOrdersResponse>;
  orderServiceGetOrder: Maybe<GetOrderResponse>;
  orderServiceGetOrderLocation: Maybe<GetOrderLocationResponse>;
  orderServiceGetOrderStateChanges: Maybe<GetOrderStateChangesResponse>;
  /** Fetch Types of Order Sources for a given retailer */
  orderSources: OrderSources;
  /** Fetch a specific partner by id */
  partner: Partner;
  /** Fetch a specific partner pick device by id */
  partnerPickDevice: PartnerPickDevicesDevice;
  /** Fetch list of partner pick devices */
  partnerPickDeviceSearch: PartnerPickDevicesDeviceSearch;
  /** Fetch capacity recommendations for a given retailer location and date */
  partnerPickGetCapacityRecommendations: PartnerPickCapacityGetCapacityRecommendationsResponse;
  /** Check if recurring_shift being created will conflict with existing shifts */
  partnerPickRecurringShiftConflictCheck: PartnerPickRecurringShiftsConflict;
  /** Search for partners matching a set of criteria */
  partnerSearch: PartnerConnection;
  /** Search for partners matching a set of criteria - returns an array instead of a connection type */
  partnerSearchArray: Array<Partner>;
  /** Fetches multiple partners by their ids */
  partners: Array<Partner>;
  /** Fetches the list of permissions available for the current user */
  permissions: Array<Permission>;
  /** Retrieve pickup retailer location groups that belong to a specific retailer */
  pickupRetailerLocationGroup: Array<RetailersPickupRetailerLocationGroup>;
  privacyServiceGetPiiRequestsForRetailer: Maybe<GetPiiRequestsForRetailerResponse>;
  productDataServiceGetProductData: Maybe<GetProductDataResponse>;
  productSourceServiceGetProductSources: Maybe<GetProductSourcesResponse>;
  productsServiceGetFlattenedTaxonomies: Maybe<GetFlattenedTaxonomiesResponse>;
  productsServiceGetLegacyElasticsearch: Maybe<LegacyElasticsearchResponse>;
  productsServiceGetLegacyItemsShoppers: Maybe<LegacyItemsTyped>;
  productsServiceGetLegacyProducts: Maybe<LegacyProducts>;
  productsServiceGetManagedAttributeValuesForRetailer: Maybe<GetManagedAttributeValuesForRetailerResponse>;
  productsServiceSearchBrands: Maybe<SearchBrandsResponse>;
  /** Retrieve a list of reports requested by the retailer */
  reportRequests: ReportsReportRequestConnection;
  /** Retrieve a report view by report view id */
  reportView: ReportViewsInterface;
  /** Retrieve a list of available reports to request */
  reports: Array<ReportsReport>;
  /** Fetch a specific retailer by id */
  retailer: Retailer;
  retailerAislesGetRetailerAisleTemplate: Maybe<GetRetailerAisleTemplateResponse>;
  retailerAislesGetRetailerLocationByLayout: Maybe<GetRetailerLocationByLayoutResponse>;
  /** Check if a Retailer has access to the given arguments. If multiple retailers are provided, they must ALL each pass the access checks. */
  retailerAuthorizationCheck: AccessControlAuthorizationAuthorizationCheck;
  retailerCampaignServiceGetMoment: Maybe<GetMomentResponse>;
  retailerCampaignServiceGetMomentParticipatingRetailers: Maybe<GetMomentParticipatingRetailersResponse>;
  retailerCampaignServiceGetMoments: Maybe<GetMomentsResponse>;
  retailerCampaignServiceGetRetailerCampaign: Maybe<RetailerCampaignResponse>;
  retailerCampaignServiceListMoments: Maybe<ListMomentsResponse>;
  retailerCampaignServiceListRetailerCampaignTreatments: Maybe<ListRetailerCampaignTreatmentsResponse>;
  retailerCampaignServiceListRetailerCampaigns: Maybe<ListRetailerCampaignsResponse>;
  retailerCollectionsAdminServiceGetCollection: Maybe<GetCollectionResponse>;
  retailerCollectionsAdminServiceGetCollectionRules: Maybe<GetCollectionRulesResponse>;
  retailerCollectionsAdminServiceGetCsvImport: Maybe<GetCsvImportResponse>;
  retailerCollectionsAdminServiceGetCsvSummary: Maybe<GetCsvSummaryResponse>;
  retailerCollectionsAdminServiceGetNavigationTree: Maybe<GetNavigationTreeResponse>;
  retailerCollectionsAdminServiceGetRetailerCsv: Maybe<GetRetailerCsvResponse>;
  retailerCollectionsAdminServiceListCollectionProducts: Maybe<ListCollectionProductsResponse>;
  retailerCollectionsAdminServiceListCollections: Maybe<ListCollectionsResponse>;
  retailerCollectionsAdminServiceSearchCollectionProducts: Maybe<SearchCollectionProductsResponse>;
  retailerCollectionsServiceGetCollectionFilters: Maybe<GetCollectionFiltersResponse>;
  retailerCollectionsServiceGetCollectionRules: Maybe<GetCollectionRulesResponse>;
  retailerCollectionsServiceGetInstacartCollection: Maybe<GetInstacartCollectionResponse>;
  retailerCollectionsServiceGetInstacartCollectionsTree: Maybe<GetInstacartCollectionsTreeResponse>;
  retailerCollectionsServiceGetInventoryFileMappings: Maybe<GetInventoryFileMappingsResponse>;
  retailerCollectionsServiceGetInventoryFileMismatches: Maybe<GetInventoryFileMismatchesResponse>;
  retailerCollectionsServiceGetRetailerCollection: Maybe<GetRetailerCollectionResponse>;
  retailerCollectionsServiceGetRetailerCollectionsCSV: Maybe<GetRetailerCollectionsCsvResponse>;
  retailerCollectionsServiceGetRetailerCollectionsTree: Maybe<GetRetailerCollectionsTreeResponse>;
  retailerCollectionsServiceGetRetailerConfig: Maybe<GetRetailerConfigResponse>;
  retailerCollectionsServiceListInstacartCollections: Maybe<ListInstacartCollectionsResponse>;
  retailerCollectionsServiceListRetailerCollectionProducts: Maybe<ListRetailerCollectionProductsResponse>;
  retailerCollectionsServiceListRetailerCollections: Maybe<ListRetailerCollectionsResponse>;
  retailerCollectionsServiceListStoreConfigurations: Maybe<ListStoreConfigurationsResponse>;
  /** Fetch a specific retailer location by id */
  retailerLocation: RetailerLocation;
  /** Retrieve a retailer location group by id */
  retailerLocationGroup: RetailersRetailerLocationGroup;
  /** Retrieve retailer location groups that belong to a specific retailer */
  retailerLocationGroupsByRetailer: RetailersRetailerLocationGroupConnection;
  /** Search for retailer locations matching a set of criteria */
  retailerLocationSearch: RetailerLocationConnection;
  /** Search for retailer locations matching a set of criteria - returns an array instead of a connection type */
  retailerLocationSearchArray: Array<RetailerLocation>;
  retailerManagementServiceGetConnectRetailerCallbackConfigurationById: Maybe<GetConnectRetailerCallbackConfigurationByIdResponse>;
  retailerManagementServiceGetConnectRetailerCallbackConfigurations: Maybe<GetConnectRetailerCallbackConfigurationsResponse>;
  /** Fetch pickup metadata for a retailer by its id */
  retailerPickupMetadata: RetailersRetailerPickupMetadata;
  retailerProductAttributeEditServiceGetRetailerProductAssortment: Maybe<GetRetailerProductAssortmentResponse>;
  /** Search for retailers matching a set of criteria */
  retailerSearch: RetailerConnection;
  /** Search for retailers matching a set of criteria - returns an array instead of a connection type */
  retailerSearchArray: Array<Retailer>;
  retailerSettingServiceGetSetting: Maybe<GetSettingResponse>;
  retailerSettingsServiceGetIsGrocer: Maybe<GetIsGrocerResponse>;
  retailerSettingsServiceGetParkingSettings: Maybe<GetParkingSettingsResponse>;
  retailerSettingsServiceGetPickupSettingsMetadata: Maybe<GetPickupSettingsMetadataResponse>;
  retailerSettingsServiceGetStagingSettings: Maybe<GetStagingSettingsResponse>;
  retailerStoreHoursGetConfirmedStoreHours: Maybe<GetConfirmedStoreHoursResponse>;
  retailerStoreHoursGetRetailerHolidaysStatus: Maybe<GetHolidaysStatusResponse>;
  retailerStoreHoursGetStoreHours: Maybe<GetStoreHoursResponse>;
  retailerStoreHoursGetStoreHoursUpdateTasks: Maybe<GetStoreHoursUpdateTasksResponse>;
  retailerToolsServiceGetCustomersContext: Maybe<GetCustomersContextResponse>;
  retailerToolsServiceGetItems: Maybe<GetItemsResponse>;
  retailerToolsServiceGetRetailerProducts: Maybe<GetRetailerProductsResponse>;
  retailerToolsServiceGetVirtualRetailers: Maybe<GetVirtualRetailersResponse>;
  retailersServiceGetPlatformVersions: Maybe<GetPlatformVersionsResponse>;
  retailersServiceGetRetailerLocationsByLocationCodes: Maybe<GetRetailerLocationsByLocationCodesResponse>;
  retailersServiceGetRetailerLocationsDetails: Maybe<GetRetailerLocationsDetailsResponse>;
  retailersServiceGetRetailerRegions: Maybe<GetRetailerRegionsResponse>;
  /** Search for roles */
  roleSearch: Array<EnterpriseRole>;
  rppBulkImageUploadsServiceGetImageUploadBatchDetails: Maybe<GetImageUploadBatchDetailsResponse>;
  rppBulkImageUploadsServiceGetImageUploadBatches: Maybe<GetImageUploadBatchesResponse>;
  serviceOptionServiceGetServiceOptions: Maybe<GetServiceOptionsResponse>;
  /** Fetch Store Configuration by Id */
  storeConfigurationById: InstacartStoreConfiguration;
  /** Fetch list of Store Configurations by Partner/Retailer IDs */
  storeConfigurationsByRetailerIds: Array<InstacartStoreConfiguration>;
  storeConfigurationsDraftServiceGetStoreConfigurationDrafts: Maybe<GetStoreConfigurationDraftsResponse>;
  storeConfigurationsDraftServiceReadLatestStoreConfigurationDraft: Maybe<ReadLatestStoreConfigurationDraftResponse>;
  storeConfigurationsDraftServiceReadStoreConfiguration: Maybe<ReadStoreConfigurationResponse>;
  storePerformanceServiceGetAggregatedStorePerformance: Maybe<StorePerformanceResponse>;
  /** Fetch a storefront placement by id */
  storefrontPlacement: ContentManagementStorefrontPlacement;
  /** Fetch a list of storefront placements */
  storefrontPlacementSearch: ContentManagementStorefrontPlacementConnection;
  /** Fetch taxonomy nodes */
  taxonomyNodes: Array<CatalogTaxonomyNode>;
  userSettingServiceListUserSettings: Maybe<ListUserSettingsResponse>;
  usersServiceBatchGetUsersByIdsStrict: Maybe<BatchGetUsersByIdsStrictResponse>;
  usersServiceGetUserByIdFilteredByIsolationContext: Maybe<UserResponse>;
  usersServiceListUsersByPersonalIdentifiersStrict: Maybe<ListUsersByPersonalIdentifiersStrictResponse>;
  valueBasedOverrideServiceGetValueBasedOverrides: Maybe<GetValueBasedOverridesResponse>;
  /** Fetch visibility conditions */
  visibilityConditionSearch: Array<VisibilityCondition>;
  /** Returns a WelcomeModal that should be shown to current user */
  welcomeModal: EnterpriseWelcomeModal;
  /** Find progress of wizard for given partner/retailer/location and type */
  wizardProgress: Maybe<WizardsWizardProgress>;
};


export type QueryAccountRequestPreviousRequestExistByEmailArgs = {
  email: Scalars['String'];
};


export type QueryAccountRequestRetailerSearchArgs = {
  namePrefix: Scalars['String'];
};


export type QueryAccountRequestSearchByIdArgs = {
  accountRequestId: Scalars['ID'];
};


export type QueryAccountTokenValidateArgs = {
  rawToken: Scalars['String'];
  tokenType: TokenTypeEnum;
};


export type QueryAccountsByNotificationTypeArgs = {
  excludeInternalUsers?: InputMaybe<Scalars['Boolean']>;
  notificationTypeId: Scalars['ID'];
  retailerId: Scalars['ID'];
};


export type QueryApiIntegrationsServiceGetApiSettingsArgs = {
  input?: InputMaybe<GetApiSettingsRequestInput>;
};


export type QueryApiIntegrationsServiceGetAuthenticationUrlArgs = {
  input?: InputMaybe<GetAuthenticationUrlRequestInput>;
};


export type QueryApiIntegrationsServiceGetAvailableStoreLocationsArgs = {
  input?: InputMaybe<GetAvailableStoreLocationsRequestInput>;
};


export type QueryAssignmentsGetOrderFulfillmentOverviewArgs = {
  input?: InputMaybe<GetOrderFulfillmentOverviewRequestInput>;
};


export type QueryAuditLogSearchArgs = {
  accountEmails?: InputMaybe<Array<Scalars['String']>>;
  accountUuids?: InputMaybe<Array<Scalars['ID']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entityIds?: InputMaybe<Array<Scalars['ID']>>;
  entityType?: InputMaybe<AuditLogEntityTypeEnum>;
  eventName?: InputMaybe<Scalars['String']>;
  eventTimeEndAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  eventTimeStartAt?: InputMaybe<Scalars['ISO8601DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryAvailabilityGetStoreHoursByDateArgs = {
  input?: InputMaybe<GetStoreHoursByDateRequestInput>;
};


export type QueryAvailabilityGetStoreHoursByDayOfWeekArgs = {
  input?: InputMaybe<GetStoreHoursByDayOfWeekRequestInput>;
};


export type QueryAvailabilityGetStoreHoursByZoneRetailerArgs = {
  input?: InputMaybe<GetStoreHoursByZoneRetailerRequestInput>;
};


export type QueryCatalogAisleArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogDepartmentArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogProblemTrackerServiceGetTicketArgs = {
  input?: InputMaybe<GetTicketRequestInput>;
};


export type QueryCatalogProblemTrackerServiceListTicketsArgs = {
  input?: InputMaybe<ListTicketsRequestInput>;
};


export type QueryCatalogProductsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryCatalogRetailerProductArgs = {
  id: Scalars['ID'];
};


export type QueryCatalogRetailerProductSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  aisleIds?: InputMaybe<Array<Scalars['ID']>>;
  available?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  localeCode?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
  searchLimit?: InputMaybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
  searchType: Scalars['String'];
};


export type QueryCatalogSearchDownloadSignedUrlArgs = {
  s3Key: Scalars['String'];
};


export type QueryCatalogToolsApiServiceDownloadCatalogSearchResultsArgs = {
  input?: InputMaybe<DownloadCatalogSearchResultsRequestInput>;
};


export type QueryCatalogToolsApiServiceGetAnomalousItemsListArgs = {
  input?: InputMaybe<GetAnomalousItemsListRequestInput>;
};


export type QueryCatalogToolsApiServiceGetAnomalousItemsReportArgs = {
  input?: InputMaybe<GetAnomalousItemsReportRequestInput>;
};


export type QueryCatalogToolsApiServiceGetLocalesByRetailerArgs = {
  input?: InputMaybe<GetLocalesByRetailerRequestInput>;
};


export type QueryCatalogToolsApiServiceGetRetailerProductDataArgs = {
  input?: InputMaybe<GetRetailerProductDataRequestInput>;
};


export type QueryCollectionsServiceGetCollectionsArgs = {
  input?: InputMaybe<GetCollectionsRequestInput>;
};


export type QueryCommerceInvoicingServiceGetOrderDiscountDetailsArgs = {
  input?: InputMaybe<GetOrderDiscountDetailsRequestInput>;
};


export type QueryConfigurableItemsServiceGetRppConfigurableItemsArgs = {
  input?: InputMaybe<GetRppConfigurableItemsRequestInput>;
};


export type QueryConnectPlatformServiceGetConnectSettingsArgs = {
  input?: InputMaybe<GetConnectSettingsRequestInput>;
};


export type QueryConnectPlatformServiceGetPartnerIdArgs = {
  input?: InputMaybe<GetPartnerIdRequestInput>;
};


export type QueryConnectPlatformServiceGetRequestContextsArgs = {
  input?: InputMaybe<GetRequestContextsRequestInput>;
};


export type QueryContentManagementAdminServiceGetNavigationArgs = {
  input?: InputMaybe<GetNavigationRequestInput>;
};


export type QueryContentManagementAdminServiceGetPlacementContentByIdArgs = {
  input?: InputMaybe<GetPlacementContentByIdRequestInput>;
};


export type QueryContentManagementAdminServiceListContentPageVersionMetasArgs = {
  input?: InputMaybe<ListContentPageVersionMetasRequestInput>;
};


export type QueryContentManagementAdminServiceListContentPageVersionsArgs = {
  input?: InputMaybe<ListContentPageVersionsRequestInput>;
};


export type QueryContentManagementAdminServiceListContentPagesV2Args = {
  input?: InputMaybe<ListContentPagesRequestV2Input>;
};


export type QueryContentManagementAdminServiceListPlacementContentArgs = {
  input?: InputMaybe<ListPlacementContentRequestInput>;
};


export type QueryContentManagementServiceGetLayoutVariantsArgs = {
  input?: InputMaybe<GetLayoutVariantsRequestInput>;
};


export type QueryContentManagementServiceGetPlacementsArgs = {
  input?: InputMaybe<GetPlacementsRequestInput>;
};


export type QueryContentManagementServiceGetThemePageArgs = {
  input?: InputMaybe<GetThemePageRequestInput>;
};


export type QueryContentManagementServiceListThemePagesArgs = {
  input?: InputMaybe<ListThemePagesRequestInput>;
};


export type QueryCurrentEnterpriseAccountAuthorizationCheckArgs = {
  accountFlippers?: InputMaybe<Array<Scalars['String']>>;
  featureEntitlementIds?: InputMaybe<Array<Scalars['ID']>>;
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
  requireWriteAccess?: InputMaybe<Scalars['Boolean']>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
  storeConfigIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryCustomersOrdersServiceGetHandlingDetailsArgs = {
  input?: InputMaybe<CustomersGetHandlingDetailsRequestInput>;
};


export type QueryCustomersOrdersServiceGetOrderArgs = {
  input?: InputMaybe<CustomersGetOrderRequestInput>;
};


export type QueryCustomersOrdersServiceGetOrderAmountsSummaryV0Args = {
  input?: InputMaybe<CustomersGetOrderAmountsSummaryV0RequestInput>;
};


export type QueryCustomersOrdersServiceGetOrderLinesArgs = {
  input?: InputMaybe<CustomersGetOrderLinesRequestInput>;
};


export type QueryCustomersOrdersServiceGetPaymentMethodSummaryArgs = {
  input?: InputMaybe<CustomersGetPaymentMethodSummaryRequestInput>;
};


export type QueryCustomersOrdersServiceListOrdersArgs = {
  input?: InputMaybe<CustomersListOrdersRequestInput>;
};


export type QueryCustomersOrdersServiceQueryOrderReferenceArgs = {
  input?: InputMaybe<CustomersQueryOrderReferenceRequestInput>;
};


export type QueryCustomersOrdersServiceQueryOrdersArgs = {
  input?: InputMaybe<CustomersQueryOrdersRequestInput>;
};


export type QueryDemeterServiceGetAuditBatchArgs = {
  input?: InputMaybe<GetAuditBatchRequestInput>;
};


export type QueryDemeterServiceGetAuditTaskArgs = {
  input?: InputMaybe<GetAuditTaskRequestInput>;
};


export type QueryDemeterServiceListAuditBatchesArgs = {
  input?: InputMaybe<ListAuditBatchesRequestInput>;
};


export type QueryDemeterServiceListAuditWorkflowsArgs = {
  input?: InputMaybe<ListAuditWorkflowsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetBrandProfileAttributesArgs = {
  input?: InputMaybe<BrandProfileAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetBrandProfileSellSheetCardsArgs = {
  input?: InputMaybe<BrandProfileSellSheetCardsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetBrandSellSheetsAttributesArgs = {
  input?: InputMaybe<BrandSellSheetsAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetEbpAnalyticsArgs = {
  input?: InputMaybe<EbpAnalyticsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetEbpProductAnalyticsSummaryArgs = {
  input?: InputMaybe<ProductAnalyticSummaryRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetEbpProductAttributesArgs = {
  input?: InputMaybe<ProductAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetIndexBrandNamesArgs = {
  input?: InputMaybe<IndexBrandNamesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetIndexEbpCategoriesArgs = {
  input?: InputMaybe<IndexEbpCategoriesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetIndexSellSheetCardsArgs = {
  input?: InputMaybe<SellSheetCardsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetProductBrandProfileAttributesArgs = {
  input?: InputMaybe<ProductBrandProfileAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetProductSellSheetCardsArgs = {
  input?: InputMaybe<ProductSellSheetCardsRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetAnalyticsSummaryArgs = {
  input?: InputMaybe<SellSheetAnalyticsSummaryRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetAttributesArgs = {
  input?: InputMaybe<SellSheetAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetBrandProfileAttributesArgs = {
  input?: InputMaybe<SellSheetBrandProfileAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetProductsAttributesArgs = {
  input?: InputMaybe<SellSheetProductsAttributesRequestInput>;
};


export type QueryEmergingBrandPortalServiceGetSellSheetProductsSummaryTraitsArgs = {
  input?: InputMaybe<SellSheetProductsSummaryTraitsRequestInput>;
};


export type QueryEnterpriseAccountArgs = {
  canonicalId?: InputMaybe<Scalars['ID']>;
};


export type QueryEnterpriseAccountSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  canonicalIds?: InputMaybe<Array<Scalars['ID']>>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<AccountStatusEnum>;
};


export type QueryFeatureToggleFullyEnabledArgs = {
  featureName: Scalars['String'];
};


export type QueryHealthCheckArgs = {
  metricsToken?: InputMaybe<Scalars['String']>;
};


export type QueryHistoricalStorePerformanceServiceGetAggregatedHistoricalStorePerformanceArgs = {
  input?: InputMaybe<HistoricalStorePerformanceRequestInput>;
};


export type QueryHomePlacementSearchArgs = {
  filter?: InputMaybe<PlacementFilterInput>;
  placementId?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<PlacementSortInput>;
};


export type QueryInsightsPortalAuthorizationCheckArgs = {
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryInsightsPortalServiceGetInsightsPortalCustomReportArgs = {
  input?: InputMaybe<InsightsPortalGetCustomReportRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalFilterOptionsArgs = {
  input?: InputMaybe<InsightsPortalFilterOptionsRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalGroupComparisonArgs = {
  input?: InputMaybe<InsightsPortalGroupComparisonRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalGroupMetricValueArgs = {
  input?: InputMaybe<InsightsPortalGroupMetricValueRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalMetricValuesArgs = {
  input?: InputMaybe<InsightsPortalMetricValuesRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalMultiGroupDataArgs = {
  input?: InputMaybe<InsightsPortalMultiGroupRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalSchemaMetadataArgs = {
  input?: InputMaybe<InsightsPortalSchemaMetadataRequestInput>;
};


export type QueryInsightsPortalServiceGetInsightsPortalSnowflakeHeartbeatArgs = {
  input?: InputMaybe<InsightsPortalSnowflakeHeartbeatRequestInput>;
};


export type QueryInsightsPortalServiceListInsightsPortalCustomReportsArgs = {
  input?: InputMaybe<InsightsPortalListCustomReportsRequestInput>;
};


export type QueryInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_GetAllSegmentDefinitionsArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_GetAllSegmentDefinitionsRequest_Input>;
};


export type QueryInstacart_Ads_Platform_Taas_V1_SegmentRegistryService_GetSegmentDefinitionArgs = {
  input?: InputMaybe<Instacart_Ads_Platform_Taas_V1_GetSegmentDefinitionRequest_Input>;
};


export type QueryInstacart_Ads_V3_EmergingBrandPortalService_GetIndexBrandNamesArgs = {
  input?: InputMaybe<Instacart_Ads_V3_IndexBrandNamesRequest_Input>;
};


export type QueryInstacart_Ads_V3_EmergingBrandPortalService_GetProductAdditionalInfoArgs = {
  input?: InputMaybe<Instacart_Ads_V3_ProductAdditionalInfoRequest_Input>;
};


export type QueryInstacart_Customers_Coupons_V1_CouponsDomainService_GetCouponCodeExistsArgs = {
  input?: InputMaybe<Instacart_Customers_Coupons_V1_GetCouponCodeExistsRequest_Input>;
};


export type QueryInstacart_Customers_Orders_V1_OrdersService_GetOrderLinesArgs = {
  input?: InputMaybe<Instacart_Customers_Orders_V1_GetOrderLinesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetCountriesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetCountriesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetPostalCodesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetPostalCodesByZonesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRegionsArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRegionsRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRetailerLocationsArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRetailerLocationsByRetailerArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetRetailersArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailersRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetZonesArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesRequest_Input>;
};


export type QueryInstacart_Customers_Partners_V1_PartnersService_GetZonesByRegionArgs = {
  input?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesByRegionRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetCollectionArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetCollectionRulesArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetNavigationTreeArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_GetRetailerCsvArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_ListCollectionProductsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_ListCollectionsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Collections_Admin_V1_RetailerCollectionsAdminService_SearchCollectionProductsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Management_V1_IppDraftManagementService_ListIppDraftsArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Management_V1_IppDraftPreviewService_GetIppPreviewByTokenArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenRequest_Input>;
};


export type QueryInstacart_Customers_Retailer_Management_V1_IppDraftPreviewService_GetPreviewAuthTokenArgs = {
  input?: InputMaybe<Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenRequest_Input>;
};


export type QueryInstacart_Enterprise_Boba_Core_Offers_V1_OfferService_GetOrderDiscountInfoArgs = {
  input?: InputMaybe<Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_GetFulfillmentEventArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_GetFulfillmentEventRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ListCreatorsArgs = {
  input?: InputMaybe<Scalars['instacart_logistics_v1_ListCreatorsRequest_Input']>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ListFulfillmentEventsArgs = {
  input?: InputMaybe<Instacart_Logistics_V1_ListFulfillmentEventsRequest_Input>;
};


export type QueryInstacart_Logistics_V1_FulfillmentEvents_ListTagsArgs = {
  input?: InputMaybe<Scalars['instacart_logistics_v1_ListTagsRequest_Input']>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetEntitlementDetailsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetEntitlementsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetPackageDetailsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetPackagesArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetRetailerEntitlementProfileDetailsArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsRequest_Input>;
};


export type QueryInstacart_Retailer_Tools_Retailer_Platform_Api_V1_FeatureEntitlementsService_GetRetailerEntitlementProfilesArgs = {
  input?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesRequest_Input>;
};


export type QueryInventoryIntelligenceServiceGetAcknowledgedOosIdsArgs = {
  input?: InputMaybe<GetAcknowledgedOosIdsRequestInput>;
};


export type QueryLegacyPartnerArgs = {
  id: Scalars['ID'];
};


export type QueryLegacyWarehouseArgs = {
  id: Scalars['ID'];
};


export type QueryLegacyWarehouseLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLinkAutosuggestServiceListLinkAutosuggestionAdminArgs = {
  input?: InputMaybe<ListLinkAutosuggestionAdminRequestInput>;
};


export type QueryMarketingFeatureBrazeSegmentSizeArgs = {
  segmentId: Scalars['ID'];
};


export type QueryMarketingFeatureCampaignStatusArgs = {
  campaignId: Scalars['ID'];
};


export type QueryMarketingFeatureTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
  pushNotificationId?: InputMaybe<Scalars['ID']>;
};


export type QueryNormalizedReportSignedUrlArgs = {
  bucketName: Scalars['String'];
  s3Key: Scalars['String'];
};


export type QueryNormalizedReportTypesArgs = {
  partnerId: Scalars['ID'];
};


export type QueryNormalizedReportsSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId: Scalars['ID'];
  reportType?: InputMaybe<ReportTypeEnum>;
  retailerId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type QueryNotificationTypesForCurrentAccountOnRetailerArgs = {
  retailerId: Scalars['ID'];
};


export type QueryOnboardingMobileAppConfigArgs = {
  os: Os;
  storeConfigurationId: Scalars['Int'];
};


export type QueryOnboardingServiceCheckIfLaunchOnboardingInProgressByPartnerArgs = {
  input?: InputMaybe<CheckIfLaunchOnboardingInProgressByPartnerRequestInput>;
};


export type QueryOnboardingServiceCheckLaunchOnboardingProgressByPartnerArgs = {
  input?: InputMaybe<CheckLaunchOnboardingProgressByPartnerRequestInput>;
};


export type QueryOnboardingServiceGetCatalogFaqFileArgs = {
  input?: InputMaybe<GetCatalogFaqFileRequestInput>;
};


export type QueryOnboardingServiceGetLaunchOnboardingArgs = {
  input?: InputMaybe<GetLaunchOnboardingRequestInput>;
};


export type QueryOnboardingServiceGetOnboardingArgs = {
  input?: InputMaybe<GetOnboardingRequestInput>;
};


export type QueryOnboardingServiceGetSignupPartnerArgs = {
  input?: InputMaybe<GetSignupPartnerRequestInput>;
};


export type QueryOrderServiceGetDashboardOrderArgs = {
  input?: InputMaybe<GetDashboardOrderRequestInput>;
};


export type QueryOrderServiceGetDashboardOrdersArgs = {
  input?: InputMaybe<GetDashboardOrdersRequestInput>;
};


export type QueryOrderServiceGetOrderArgs = {
  input?: InputMaybe<GetOrderRequestInput>;
};


export type QueryOrderServiceGetOrderLocationArgs = {
  input?: InputMaybe<GetOrderLocationRequestInput>;
};


export type QueryOrderServiceGetOrderStateChangesArgs = {
  input?: InputMaybe<GetOrderStateChangesRequestInput>;
};


export type QueryOrderSourcesArgs = {
  retailerId: Scalars['ID'];
};


export type QueryPartnerArgs = {
  id: Scalars['ID'];
};


export type QueryPartnerPickDeviceArgs = {
  id: Scalars['ID'];
};


export type QueryPartnerPickDeviceSearchArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  warehouseLocationId: Scalars['ID'];
};


export type QueryPartnerPickGetCapacityRecommendationsArgs = {
  date: Scalars['ISO8601Date'];
  retailerLocationId: Scalars['ID'];
};


export type QueryPartnerPickRecurringShiftConflictCheckArgs = {
  daysOfWeek: Array<Scalars['Int']>;
  driverId: Scalars['ID'];
  existingDriverShiftId?: InputMaybe<Scalars['ID']>;
  existingRecurringShiftId?: InputMaybe<Scalars['ID']>;
  recurEndDate?: InputMaybe<Scalars['String']>;
  recurStartDate?: InputMaybe<Scalars['String']>;
  shiftLengthSeconds: Scalars['Int'];
  shiftStartSecondsSinceMidnight: Scalars['Int'];
  warehouseLocationId: Scalars['ID'];
};


export type QueryPartnerSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryPartnersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPermissionsArgs = {
  roleId?: InputMaybe<Scalars['ID']>;
};


export type QueryPickupRetailerLocationGroupArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  retailerId: Scalars['ID'];
};


export type QueryPrivacyServiceGetPiiRequestsForRetailerArgs = {
  input?: InputMaybe<GetPiiRequestsForRetailerRequestInput>;
};


export type QueryProductDataServiceGetProductDataArgs = {
  input?: InputMaybe<GetProductDataRequestInput>;
};


export type QueryProductSourceServiceGetProductSourcesArgs = {
  input?: InputMaybe<GetProductSourcesRequestInput>;
};


export type QueryProductsServiceGetFlattenedTaxonomiesArgs = {
  input?: InputMaybe<GetFlattenedTaxonomiesRequestInput>;
};


export type QueryProductsServiceGetLegacyElasticsearchArgs = {
  input?: InputMaybe<GetLegacyElasticsearchRequestInput>;
};


export type QueryProductsServiceGetLegacyItemsShoppersArgs = {
  input?: InputMaybe<GetLegacyItemsShoppersRequestInput>;
};


export type QueryProductsServiceGetLegacyProductsArgs = {
  input?: InputMaybe<GetLegacyProductsRequestInput>;
};


export type QueryProductsServiceGetManagedAttributeValuesForRetailerArgs = {
  input?: InputMaybe<GetManagedAttributeValuesForRetailerRequestInput>;
};


export type QueryProductsServiceSearchBrandsArgs = {
  input?: InputMaybe<SearchBrandsRequestInput>;
};


export type QueryReportRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
};


export type QueryReportViewArgs = {
  reportViewId: Scalars['ID'];
};


export type QueryRetailerArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerAislesGetRetailerAisleTemplateArgs = {
  input?: InputMaybe<GetRetailerAisleTemplateRequestInput>;
};


export type QueryRetailerAislesGetRetailerLocationByLayoutArgs = {
  input?: InputMaybe<GetRetailerLocationByLayoutRequestInput>;
};


export type QueryRetailerAuthorizationCheckArgs = {
  featureEntitlements?: InputMaybe<Array<Scalars['ID']>>;
  retailerIds: Array<Scalars['ID']>;
};


export type QueryRetailerCampaignServiceGetMomentArgs = {
  input?: InputMaybe<GetMomentRequestInput>;
};


export type QueryRetailerCampaignServiceGetMomentParticipatingRetailersArgs = {
  input?: InputMaybe<GetMomentParticipatingRetailersRequestInput>;
};


export type QueryRetailerCampaignServiceGetMomentsArgs = {
  input?: InputMaybe<GetMomentsRequestInput>;
};


export type QueryRetailerCampaignServiceGetRetailerCampaignArgs = {
  input?: InputMaybe<GetRetailerCampaignRequestInput>;
};


export type QueryRetailerCampaignServiceListMomentsArgs = {
  input?: InputMaybe<ListMomentsRequestInput>;
};


export type QueryRetailerCampaignServiceListRetailerCampaignTreatmentsArgs = {
  input?: InputMaybe<ListRetailerCampaignTreatmentsRequestInput>;
};


export type QueryRetailerCampaignServiceListRetailerCampaignsArgs = {
  input?: InputMaybe<ListRetailerCampaignsRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceGetCollectionArgs = {
  input?: InputMaybe<GetCollectionRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceGetCollectionRulesArgs = {
  input?: InputMaybe<GetCollectionRulesRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceGetCsvImportArgs = {
  input?: InputMaybe<GetCsvImportRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceGetCsvSummaryArgs = {
  input?: InputMaybe<GetCsvSummaryRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceGetNavigationTreeArgs = {
  input?: InputMaybe<GetNavigationTreeRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceGetRetailerCsvArgs = {
  input?: InputMaybe<GetRetailerCsvRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceListCollectionProductsArgs = {
  input?: InputMaybe<ListCollectionProductsRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceListCollectionsArgs = {
  input?: InputMaybe<ListCollectionsRequestInput>;
};


export type QueryRetailerCollectionsAdminServiceSearchCollectionProductsArgs = {
  input?: InputMaybe<SearchCollectionProductsRequestInput>;
};


export type QueryRetailerCollectionsServiceGetCollectionFiltersArgs = {
  input?: InputMaybe<GetCollectionFiltersRequestInput>;
};


export type QueryRetailerCollectionsServiceGetCollectionRulesArgs = {
  input?: InputMaybe<GetCollectionRulesRequestInput>;
};


export type QueryRetailerCollectionsServiceGetInstacartCollectionArgs = {
  input?: InputMaybe<GetInstacartCollectionRequestInput>;
};


export type QueryRetailerCollectionsServiceGetInstacartCollectionsTreeArgs = {
  input?: InputMaybe<GetInstacartCollectionsTreeRequestInput>;
};


export type QueryRetailerCollectionsServiceGetInventoryFileMappingsArgs = {
  input?: InputMaybe<GetInventoryFileMappingsRequestInput>;
};


export type QueryRetailerCollectionsServiceGetInventoryFileMismatchesArgs = {
  input?: InputMaybe<GetInventoryFileMismatchesRequestInput>;
};


export type QueryRetailerCollectionsServiceGetRetailerCollectionArgs = {
  input?: InputMaybe<GetRetailerCollectionRequestInput>;
};


export type QueryRetailerCollectionsServiceGetRetailerCollectionsCsvArgs = {
  input?: InputMaybe<GetRetailerCollectionsCsvRequestInput>;
};


export type QueryRetailerCollectionsServiceGetRetailerCollectionsTreeArgs = {
  input?: InputMaybe<GetRetailerCollectionsTreeRequestInput>;
};


export type QueryRetailerCollectionsServiceGetRetailerConfigArgs = {
  input?: InputMaybe<GetRetailerConfigRequestInput>;
};


export type QueryRetailerCollectionsServiceListInstacartCollectionsArgs = {
  input?: InputMaybe<ListInstacartCollectionsRequestInput>;
};


export type QueryRetailerCollectionsServiceListRetailerCollectionProductsArgs = {
  input?: InputMaybe<ListRetailerCollectionProductsRequestInput>;
};


export type QueryRetailerCollectionsServiceListRetailerCollectionsArgs = {
  input?: InputMaybe<ListRetailerCollectionsRequestInput>;
};


export type QueryRetailerCollectionsServiceListStoreConfigurationsArgs = {
  input?: InputMaybe<ListStoreConfigurationsRequestInput>;
};


export type QueryRetailerLocationArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerLocationGroupArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerLocationGroupsByRetailerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
};


export type QueryRetailerLocationSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeInactiveLocations?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pickup?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerLocationSearchArrayArgs = {
  includeInactiveLocations?: InputMaybe<Scalars['Boolean']>;
  pickup?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerManagementServiceGetConnectRetailerCallbackConfigurationByIdArgs = {
  input?: InputMaybe<GetConnectRetailerCallbackConfigurationByIdRequestInput>;
};


export type QueryRetailerManagementServiceGetConnectRetailerCallbackConfigurationsArgs = {
  input?: InputMaybe<GetConnectRetailerCallbackConfigurationsRequestInput>;
};


export type QueryRetailerPickupMetadataArgs = {
  id: Scalars['ID'];
};


export type QueryRetailerProductAttributeEditServiceGetRetailerProductAssortmentArgs = {
  input?: InputMaybe<GetRetailerProductAssortmentRequestInput>;
};


export type QueryRetailerSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerSearchArrayArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
};


export type QueryRetailerSettingServiceGetSettingArgs = {
  input?: InputMaybe<GetSettingRequestInput>;
};


export type QueryRetailerSettingsServiceGetIsGrocerArgs = {
  input?: InputMaybe<GetIsGrocerRequestInput>;
};


export type QueryRetailerSettingsServiceGetParkingSettingsArgs = {
  input?: InputMaybe<GetParkingSettingsRequestInput>;
};


export type QueryRetailerSettingsServiceGetPickupSettingsMetadataArgs = {
  input?: InputMaybe<GetPickupSettingsMetadataRequestInput>;
};


export type QueryRetailerSettingsServiceGetStagingSettingsArgs = {
  input?: InputMaybe<GetStagingSettingsRequestInput>;
};


export type QueryRetailerStoreHoursGetConfirmedStoreHoursArgs = {
  input?: InputMaybe<GetConfirmedStoreHoursRequestInput>;
};


export type QueryRetailerStoreHoursGetRetailerHolidaysStatusArgs = {
  input?: InputMaybe<GetHolidaysStatusRequestInput>;
};


export type QueryRetailerStoreHoursGetStoreHoursArgs = {
  input?: InputMaybe<GetStoreHoursRequestInput>;
};


export type QueryRetailerStoreHoursGetStoreHoursUpdateTasksArgs = {
  input?: InputMaybe<GetStoreHoursUpdateTasksRequestInput>;
};


export type QueryRetailerToolsServiceGetCustomersContextArgs = {
  input?: InputMaybe<GetCustomersContextRequestInput>;
};


export type QueryRetailerToolsServiceGetItemsArgs = {
  input?: InputMaybe<GetItemsRequestInput>;
};


export type QueryRetailerToolsServiceGetRetailerProductsArgs = {
  input?: InputMaybe<GetRetailerProductsRequestInput>;
};


export type QueryRetailerToolsServiceGetVirtualRetailersArgs = {
  input?: InputMaybe<GetVirtualRetailersRequestInput>;
};


export type QueryRetailersServiceGetPlatformVersionsArgs = {
  input?: InputMaybe<GetPlatformVersionsRequestInput>;
};


export type QueryRetailersServiceGetRetailerLocationsByLocationCodesArgs = {
  input?: InputMaybe<GetRetailerLocationsByLocationCodesRequestInput>;
};


export type QueryRetailersServiceGetRetailerLocationsDetailsArgs = {
  input?: InputMaybe<GetRetailerLocationsDetailsRequestInput>;
};


export type QueryRetailersServiceGetRetailerRegionsArgs = {
  input?: InputMaybe<GetRetailerRegionsRequestInput>;
};


export type QueryRoleSearchArgs = {
  assignableByAccountUuid?: InputMaybe<Scalars['ID']>;
  attainableByAccountUuid?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  includeInstacartManaged?: InputMaybe<Scalars['Boolean']>;
  instacartManagedOnly?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['ID']>;
  partnerIds?: InputMaybe<Array<Scalars['ID']>>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerIds?: InputMaybe<Array<Scalars['ID']>>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryRppBulkImageUploadsServiceGetImageUploadBatchDetailsArgs = {
  input?: InputMaybe<GetImageUploadBatchDetailsRequestInput>;
};


export type QueryRppBulkImageUploadsServiceGetImageUploadBatchesArgs = {
  input?: InputMaybe<GetImageUploadBatchesRequestInput>;
};


export type QueryServiceOptionServiceGetServiceOptionsArgs = {
  input?: InputMaybe<GetServiceOptionsRequestInput>;
};


export type QueryStoreConfigurationByIdArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type QueryStoreConfigurationsByRetailerIdsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  retailerIds: Array<Scalars['ID']>;
  storeConfigRelationship: StoreConfigurationRelationshipEnum;
};


export type QueryStoreConfigurationsDraftServiceGetStoreConfigurationDraftsArgs = {
  input?: InputMaybe<GetStoreConfigurationDraftsRequestInput>;
};


export type QueryStoreConfigurationsDraftServiceReadLatestStoreConfigurationDraftArgs = {
  input?: InputMaybe<ReadLatestStoreConfigurationDraftRequestInput>;
};


export type QueryStoreConfigurationsDraftServiceReadStoreConfigurationArgs = {
  input?: InputMaybe<ReadStoreConfigurationRequestInput>;
};


export type QueryStorePerformanceServiceGetAggregatedStorePerformanceArgs = {
  input?: InputMaybe<StorePerformanceRequestInput>;
};


export type QueryStorefrontPlacementArgs = {
  id: Scalars['ID'];
  retailerId: Scalars['ID'];
};


export type QueryStorefrontPlacementSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PlacementFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  retailerId: Scalars['ID'];
  sort?: InputMaybe<PlacementSortInput>;
};


export type QueryUserSettingServiceListUserSettingsArgs = {
  input?: InputMaybe<ListUserSettingsRequestInput>;
};


export type QueryUsersServiceBatchGetUsersByIdsStrictArgs = {
  input?: InputMaybe<BatchGetUsersByIdsStrictRequestInput>;
};


export type QueryUsersServiceGetUserByIdFilteredByIsolationContextArgs = {
  input?: InputMaybe<GetUserByIdFilteredByIsolationContextRequestInput>;
};


export type QueryUsersServiceListUsersByPersonalIdentifiersStrictArgs = {
  input?: InputMaybe<ListUsersByPersonalIdentifiersStrictRequestInput>;
};


export type QueryValueBasedOverrideServiceGetValueBasedOverridesArgs = {
  input?: InputMaybe<GetValueBasedOverridesRequestInput>;
};


export type QueryVisibilityConditionSearchArgs = {
  placementId?: InputMaybe<Scalars['ID']>;
  visibilityConditionId?: InputMaybe<Scalars['ID']>;
};


export type QueryWelcomeModalArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerLocationId?: InputMaybe<Scalars['ID']>;
};


export type QueryWizardProgressArgs = {
  partnerId?: InputMaybe<Scalars['ID']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  retailerLocationId: Scalars['ID'];
  wizardType: WizardTypeEnum;
};

export type QueueNavigationMigrationRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type QueueNavigationMigrationResponse = {
  __typename?: 'QueueNavigationMigrationResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type RadiusSchema = {
  __typename?: 'RadiusSchema';
  r0: Maybe<Scalars['BigInt']>;
  r4: Maybe<Scalars['BigInt']>;
  r8: Maybe<Scalars['BigInt']>;
  r12: Maybe<Scalars['BigInt']>;
  r24: Maybe<Scalars['BigInt']>;
};

export type ReadLatestStoreConfigurationDraftRequestInput = {
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type ReadLatestStoreConfigurationDraftResponse = {
  __typename?: 'ReadLatestStoreConfigurationDraftResponse';
  domainConfigurations: Maybe<DomainConfiguration>;
  draftId: Maybe<Scalars['String']>;
  errorMessage: Maybe<Scalars['String']>;
  errorReason: Maybe<ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type ReadStoreConfigurationRequestInput = {
  draftId?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type ReadStoreConfigurationResponse = {
  __typename?: 'ReadStoreConfigurationResponse';
  domainConfigurations: Maybe<DomainConfiguration>;
  draftId: Maybe<Scalars['String']>;
  draftName: Maybe<Scalars['String']>;
  errorMessage: Maybe<Scalars['String']>;
  errorReason: Maybe<ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
  parentDraftId: Maybe<Scalars['String']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  storeConfigurationId: Maybe<Scalars['BigInt']>;
};

export type ReassignAuditBatchWorkflowRequestInput = {
  batchUuid?: InputMaybe<Scalars['String']>;
  client?: InputMaybe<Scalars['String']>;
  newWorkflow?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type ReassignAuditBatchWorkflowResponse = {
  __typename?: 'ReassignAuditBatchWorkflowResponse';
  completedAt: Maybe<Scalars['DateTime']>;
  message: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  version: Maybe<Scalars['BigInt']>;
};

export type RecipeEntryItem = {
  __typename?: 'RecipeEntryItem';
  recipeId: Maybe<Scalars['String']>;
};

export enum RecommendationTypeEnum {
  DemandSurge = 'DEMAND_SURGE',
  NoRunner = 'NO_RUNNER'
}

export type Region = {
  __typename?: 'Region';
  id: Maybe<Scalars['BigInt']>;
  locationCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type RegularHours = {
  __typename?: 'RegularHours';
  dayOfWeek: Maybe<GoogleTypeDayOfWeek>;
  endTime: Maybe<GoogleTypeTimeOfDay>;
  serviceType: Maybe<RetailerServiceType>;
  startTime: Maybe<GoogleTypeTimeOfDay>;
};

export type RegularHoursInput = {
  dayOfWeek?: InputMaybe<GoogleTypeDayOfWeek>;
  endTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
  serviceType?: InputMaybe<RetailerServiceType>;
  startTime?: InputMaybe<GoogleTypeTimeOfDayInput>;
};

export enum RejectionReasonEnum {
  /** Whether the authorization check failed because of lack of write access */
  NoWriteAccess = 'no_write_access',
  /** Whether the authorization check failed because of unauthorized partners */
  UnauthorizedPartners = 'unauthorized_partners',
  /** Whether the authorization check failed because of unauthorized retailer locations */
  UnauthorizedRetailerLocations = 'unauthorized_retailer_locations',
  /** Whether the authorization check failed because of unauthorized retailers */
  UnauthorizedRetailers = 'unauthorized_retailers',
  /** Whether the authorization check failed because of unauthorized access to store configuration */
  UnauthorizedStoreConfigs = 'unauthorized_store_configs',
  /** Whether the authorization check failed because missing entitlements */
  UnmetFeatureEntitlements = 'unmet_feature_entitlements',
  /** Whether the authorization check failed because of unmet flippers */
  UnmetFlippers = 'unmet_flippers',
  /** Whether the authorization check failed because of unmet permissions */
  UnmetPermissions = 'unmet_permissions'
}

export type RemoveProductsFromRetailerCollectionRequestInput = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type RemoveProductsFromRetailerCollectionResponse = {
  __typename?: 'RemoveProductsFromRetailerCollectionResponse';
  productIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type RenewSubscriptionIntoAnnual = {
  __typename?: 'RenewSubscriptionIntoAnnual';
  id: Maybe<Scalars['BigInt']>;
};

export type RenewSubscriptionIntoAnnualInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type RenewSubscriptionIntoMonthly = {
  __typename?: 'RenewSubscriptionIntoMonthly';
  id: Maybe<Scalars['BigInt']>;
};

export type RenewSubscriptionIntoMonthlyInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type ReopenLastmileOrderRequestInput = {
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  order?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ReopenLastmileOrderParamsInput>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type ReopenLastmileOrderResponse = {
  __typename?: 'ReopenLastmileOrderResponse';
  order: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

/** A report filter definition */
export type ReportFilterDefinitionsInterface = {
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Whether this filter is required */
  required: Scalars['Boolean'];
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export enum ReportFilterTypeEnum {
  /** A filter that allows selection of a date range. */
  DateRange = 'DATE_RANGE',
  /** A filter that allows selection of a list of retailer locations. */
  RetailerLocations = 'RETAILER_LOCATIONS'
}

/** Report filter values */
export type ReportFilterValuesInterface = {
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Identifier for this filter */
  slug: Scalars['String'];
};

/** Report Filters */
export type ReportFilters = {
  dateRange?: InputMaybe<DateRange>;
  retailerLocations?: InputMaybe<RetailerLocations>;
};

export enum ReportRequestAssociatedObjectTypeEnum {
  /** Retailer type of associated object */
  Retailer = 'Retailer'
}

/** Autogenerated return type of ReportRequestCreate */
export type ReportRequestCreatePayload = {
  __typename?: 'ReportRequestCreatePayload';
  /** The newly created report request */
  reportRequest: Maybe<ReportsReportRequest>;
};

export enum ReportRequestStateEnum {
  /** A report request that has been successfully processed */
  Complete = 'COMPLETE',
  /** A report request that has failed processing */
  Failed = 'FAILED',
  /** A report request that is currently being processed */
  InProgress = 'IN_PROGRESS',
  /** A report request that is new and not yet processed */
  New = 'NEW'
}

export enum ReportTypeEnum {
  /** Ads report daily */
  AdsDaily = 'ADS_DAILY',
  /** Ads report monthly */
  AdsMonthly = 'ADS_MONTHLY',
  /** Ads report weekly */
  AdsWeekly = 'ADS_WEEKLY',
  /** Aldi ACI Daily Report */
  AldiAciDailyReport = 'ALDI_ACI_DAILY_REPORT',
  /** Aldi ACI Daily Store Level Report */
  AldiAciDailyStoreLevelReport = 'ALDI_ACI_DAILY_STORE_LEVEL_REPORT',
  /** Associate level performance */
  AssociateLevelPartnerPerformanceReport = 'ASSOCIATE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  /** Caper Daily Transactions Details */
  CaperDailyTransactionsDetails = 'CAPER_DAILY_TRANSACTIONS_DETAILS',
  /** Caper Daily Transactions Summary */
  CaperDailyTransactionsSummary = 'CAPER_DAILY_TRANSACTIONS_SUMMARY',
  /** Daily Ultrafast Dashboard */
  DailyUltrafastDashboard = 'DAILY_ULTRAFAST_DASHBOARD',
  /** Daily Ultrafast Order Reporting */
  DailyUltrafastOrderReporting = 'DAILY_ULTRAFAST_ORDER_REPORTING',
  /** Delivery Now By Marianos - Daily Adjustment Report */
  DeliveryNowByMarianosDailyAdjustmentReport = 'DELIVERY_NOW_BY_MARIANOS_DAILY_ADJUSTMENT_REPORT',
  /** Ads display preview report daily */
  DisplayPreviewDaily = 'DISPLAY_PREVIEW_DAILY',
  /** Distribution System Report */
  DistributionSystemReport = 'DISTRIBUTION_SYSTEM_REPORT',
  /** Monthly DLOG report */
  DlogMonthly = 'DLOG_MONTHLY',
  /** Giant Eagle Loyalty Perks Attribution Ad-Hoc Report */
  GiantEagleLoyaltyPerksAttributionAdHocReport = 'GIANT_EAGLE_LOYALTY_PERKS_ATTRIBUTION_AD_HOC_REPORT',
  /** Live Issue */
  LiveIssue = 'LIVE_ISSUE',
  /** Merged: Retailer Tax Logs */
  MergedRetailerTaxLogs = 'MERGED_RETAILER_TAX_LOGS',
  /** Merged: Taxes Paid at POS */
  MergedTaxesPaidAtPos = 'MERGED_TAXES_PAID_AT_POS',
  /** Monthly MLOG report */
  MlogMonthly = 'MLOG_MONTHLY',
  /** MPF Taxes Semi Monthly */
  MpfTaxesSemiMonthly = 'MPF_TAXES_SEMI_MONTHLY',
  /** New Verticals - Weekly Retailer Update */
  NewVerticalsWeeklyRetailerUpdate = 'NEW_VERTICALS_WEEKLY_RETAILER_UPDATE',
  /** Order ratings */
  OrderRatings = 'ORDER_RATINGS',
  /** Out of stock items */
  OutOfStock = 'OUT_OF_STOCK',
  /** Replacements and refunds */
  ReplacementsAndRefunds = 'REPLACEMENTS_AND_REFUNDS',
  /** Retailer Pickup */
  RetailerPickup = 'RETAILER_PICKUP',
  /** Retailer Tax Logs */
  RetailerTaxLogs = 'RETAILER_TAX_LOGS',
  /** Live Issue */
  RunnerHours = 'RUNNER_HOURS',
  /** Scan & Pay */
  ScanAndPay = 'SCAN_AND_PAY',
  /** Shopper Breaks */
  ShopperBreaks = 'SHOPPER_BREAKS',
  /** Store level performance */
  StoreLevelPartnerPerformanceReport = 'STORE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  /** Taxes Paid at POS */
  TaxesPaidAtPos = 'TAXES_PAID_AT_POS',
  /** Top items */
  TopItems = 'TOP_ITEMS',
  /** Live Issue */
  UnataExpressWeekly = 'UNATA_EXPRESS_WEEKLY',
  /** UUID Compliance */
  UuidCompliance = 'UUID_COMPLIANCE',
  /** General weekly updates */
  WeeklyRetailerUpdate = 'WEEKLY_RETAILER_UPDATE'
}

/** A generated view of the report data */
export type ReportViewsInterface = {
  /** A unique identifier for the report view */
  id: Scalars['ID'];
};

export type ReportsReport = {
  __typename?: 'ReportsReport';
  /** The time at which the report was created */
  createdAt: Scalars['Time'];
  /** A unique identifier for the each report */
  id: Scalars['ID'];
  /** A url-friendly ID for this report */
  slug: Scalars['String'];
  supportedFilters: Array<ReportFilterDefinitionsInterface>;
  /** The time at which the report was updated */
  updatedAt: Scalars['Time'];
};

export type ReportsReportFilterDefinitionsDateRange = ReportFilterDefinitionsInterface & {
  __typename?: 'ReportsReportFilterDefinitionsDateRange';
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Maximum date allowed */
  maxDate: Scalars['ISO8601Date'];
  /** Minimum date */
  minDate: Scalars['ISO8601Date'];
  /** Whether this filter is required */
  required: Scalars['Boolean'];
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export type ReportsReportFilterDefinitionsRetailerLocations = ReportFilterDefinitionsInterface & {
  __typename?: 'ReportsReportFilterDefinitionsRetailerLocations';
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Whether this filter is required */
  required: Scalars['Boolean'];
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export type ReportsReportFilterValuesDateRange = ReportFilterValuesInterface & {
  __typename?: 'ReportsReportFilterValuesDateRange';
  /** End date of date range */
  endDate: Scalars['ISO8601Date'];
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** Identifier for this filter */
  slug: Scalars['String'];
  /** Start date of date range */
  startDate: Scalars['ISO8601Date'];
};

export type ReportsReportFilterValuesRetailerLocations = ReportFilterValuesInterface & {
  __typename?: 'ReportsReportFilterValuesRetailerLocations';
  /** Type of filter */
  filterType: ReportFilterTypeEnum;
  /** A retailer location group ID */
  retailerLocationGroupId: Maybe<Scalars['ID']>;
  /** A list of retailer location IDs */
  retailerLocationIds: Maybe<Array<Scalars['ID']>>;
  /** Identifier for this filter */
  slug: Scalars['String'];
};

export type ReportsReportRequest = {
  __typename?: 'ReportsReportRequest';
  /** The time at which the report request was created */
  createdAt: Scalars['Time'];
  enterpriseAccount: Maybe<EnterpriseAccount>;
  /** The state the report request is currently in */
  generationState: ReportRequestStateEnum;
  /** A unique identifier for the report request */
  id: Scalars['ID'];
  /** The name of the report request */
  name: Scalars['String'];
  report: ReportsReport;
  reportFilters: Array<ReportFilterValuesInterface>;
  reportViews: Array<ReportViewsInterface>;
  /** The time at which the report request was updated */
  updatedAt: Scalars['Time'];
};

/** The connection type for ReportsReportRequest. */
export type ReportsReportRequestConnection = {
  __typename?: 'ReportsReportRequestConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportsReportRequestEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ReportsReportRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReportsReportRequestEdge = {
  __typename?: 'ReportsReportRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<ReportsReportRequest>;
};

export type ReportsReportViewsCsv = ReportViewsInterface & {
  __typename?: 'ReportsReportViewsCsv';
  /** download URL for the report view */
  downloadUrl: Maybe<Scalars['String']>;
  /** A unique identifier for the report view */
  id: Scalars['ID'];
};

export enum RequestStateEnum {
  /** Account Request accepted and completed */
  Completed = 'COMPLETED',
  /** Waiting for admin to confirm request */
  PendingAdminAction = 'PENDING_ADMIN_ACTION',
  /** Waiting for requestee to verify email */
  PendingEmailVerification = 'PENDING_EMAIL_VERIFICATION',
  /** Account Request rejected */
  Rejected = 'REJECTED'
}

export enum RequestType {
  RequestTypeAccess = 'REQUEST_TYPE_ACCESS',
  RequestTypeDeletion = 'REQUEST_TYPE_DELETION',
  RequestTypeUnspecified = 'REQUEST_TYPE_UNSPECIFIED'
}

export type RescheduleOrderRequestInput = {
  modificationEvent?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1ModificationEventInput>;
  order?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1RescheduleOrderParamsInput>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type RescheduleOrderResponse = {
  __typename?: 'RescheduleOrderResponse';
  order: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

export type ResourceDataQuery = {
  __typename?: 'ResourceDataQuery';
  id: Maybe<Scalars['String']>;
  resource: Maybe<Scalars['String']>;
};

export type ResourceDataQueryInput = {
  id?: InputMaybe<Scalars['String']>;
  resource?: InputMaybe<Scalars['String']>;
};

export enum ResponseStatus {
  Failed = 'Failed',
  Success = 'Success',
  Unknown = 'Unknown'
}

export type Retailer = {
  __typename?: 'Retailer';
  /** Country associated with this retailer */
  country: Country;
  /** An array of the retailer's departments */
  departments: Array<CatalogDepartment>;
  /** Warehouse early picking hour [if one is set] */
  earlyPickingHour: Maybe<Scalars['Int']>;
  /** The entitlements granted to the retailer */
  featureEntitlements: Array<FeatureEntitlement>;
  /** The feature toggles associated with the retailer */
  featureToggles: Array<FeatureToggle>;
  /** A url for header-specific version of the logo */
  headerLogoUrl: Maybe<Scalars['String']>;
  /** A unique identifier for the retailer */
  id: Scalars['ID'];
  /** Whether or not the retailer is on pbi */
  isPbi: Scalars['Boolean'];
  /** A url for the logo */
  logo: Maybe<Logo>;
  logoUrl: Maybe<Scalars['String']>;
  /** The name of the retailer */
  name: Scalars['String'];
  /** The partner that this retailer belongs to */
  partner: Partner;
  /** The store configuration for the pbi retailer */
  pbiConfiguration: Maybe<ContentManagementPbiConfiguration>;
  /** The warehouse slug for a retailer */
  slug: Scalars['String'];
  /** A url for tiny version of the logo */
  tinyLogoUrl: Maybe<Scalars['String']>;
  /** A url for the white label landing page */
  whiteLabelLandingPageUrl: Maybe<Scalars['String']>;
};

export type RetailerAvailabilityInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** The ID of the retailer */
  retailerId: Scalars['Int'];
};

/** Autogenerated return type of RetailerAvailabilityVisibilityConditionCreate */
export type RetailerAvailabilityVisibilityConditionCreatePayload = {
  __typename?: 'RetailerAvailabilityVisibilityConditionCreatePayload';
  /** The newly created RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityCondition: ContentManagementVisibilityConditionRetailerAvailability;
};

/** Autogenerated return type of RetailerAvailabilityVisibilityConditionUpdate */
export type RetailerAvailabilityVisibilityConditionUpdatePayload = {
  __typename?: 'RetailerAvailabilityVisibilityConditionUpdatePayload';
  /** The newly updated RetailerAvailability visibility condition */
  retailerAvailabilityVisibilityCondition: ContentManagementVisibilityConditionRetailerAvailability;
};

export type RetailerCampaign = {
  __typename?: 'RetailerCampaign';
  budget: Maybe<GoogleTypeMoney>;
  campaignObjective: Maybe<CampaignObjective>;
  campaignReference: Maybe<CampaignReference>;
  couponCount: Maybe<Scalars['BigInt']>;
  createdAt: Maybe<Scalars['DateTime']>;
  discountPolicyIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  discoverability: Maybe<Discoverability>;
  endsAt: Maybe<Scalars['DateTime']>;
  filters: Maybe<Array<Maybe<CampaignFilter>>>;
  id: Maybe<Scalars['BigInt']>;
  lookbackWindowDays: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  narrowSearchTerms: Maybe<Array<Maybe<Scalars['String']>>>;
  offerRequestKeys: Maybe<Array<Maybe<Scalars['String']>>>;
  regionIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerId: Maybe<Scalars['BigInt']>;
  segmentId: Maybe<Scalars['String']>;
  startsAt: Maybe<Scalars['DateTime']>;
  status: Maybe<Scalars['String']>;
  storeConfigurationId: Maybe<Scalars['BigInt']>;
  targeting: Maybe<Array<Maybe<Targeting>>>;
  targetingRules: Maybe<Array<Maybe<TargetingRules>>>;
  templateId: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  validForDays: Maybe<Scalars['BigInt']>;
};

export type RetailerCampaignCreativeTreatment = {
  __typename?: 'RetailerCampaignCreativeTreatment';
  placementId: Maybe<Scalars['BigInt']>;
  slotId: Maybe<Scalars['String']>;
};

export type RetailerCampaignCreativeTreatmentInput = {
  placementId?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['String']>;
};

export type RetailerCampaignOfferTreatment = {
  __typename?: 'RetailerCampaignOfferTreatment';
  offerId: Maybe<Scalars['BigInt']>;
  offerRequestKey: Maybe<Scalars['String']>;
  slotId: Maybe<Scalars['String']>;
  validForDays: Maybe<Scalars['BigInt']>;
};

export type RetailerCampaignOfferTreatmentInput = {
  offerId?: InputMaybe<Scalars['BigInt']>;
  offerRequestKey?: InputMaybe<Scalars['String']>;
  slotId?: InputMaybe<Scalars['String']>;
  validForDays?: InputMaybe<Scalars['BigInt']>;
};

export type RetailerCampaignResponse = {
  __typename?: 'RetailerCampaignResponse';
  offers: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOffer>>>;
  retailerCampaign: Maybe<RetailerCampaign>;
};

export type RetailerCampaignTreatment = {
  __typename?: 'RetailerCampaignTreatment';
  active: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  retailerCampaignId: Maybe<Scalars['BigInt']>;
  slotIds: Maybe<Array<Maybe<Scalars['String']>>>;
  treatmentDetails: Maybe<Array<Maybe<RetailerCampaignTreatmentDetails>>>;
  treatmentType: Maybe<RetailerCampaignTreatmentType>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type RetailerCampaignTreatmentDetails = {
  __typename?: 'RetailerCampaignTreatmentDetails';
  creativeTreatment: Maybe<RetailerCampaignCreativeTreatment>;
  offerTreatment: Maybe<RetailerCampaignOfferTreatment>;
};

export type RetailerCampaignTreatmentDetailsInput = {
  creativeTreatment?: InputMaybe<RetailerCampaignCreativeTreatmentInput>;
  offerTreatment?: InputMaybe<RetailerCampaignOfferTreatmentInput>;
};

export enum RetailerCampaignTreatmentType {
  Creative = 'CREATIVE',
  InvalidTreatment = 'INVALID_TREATMENT',
  Offer = 'OFFER'
}

export type RetailerCollection = {
  __typename?: 'RetailerCollection';
  contentType: Maybe<RetailerCollectionContentType>;
  createdAt: Maybe<Scalars['DateTime']>;
  defaultSortOrder: Maybe<RetailerCollectionSortOrder>;
  endDate: Maybe<Scalars['DateTime']>;
  extCollectionId: Maybe<Scalars['BigInt']>;
  filters: Maybe<Array<Maybe<RetailerCollectionFilter>>>;
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  parentId: Maybe<Scalars['BigInt']>;
  position: Maybe<Scalars['Int']>;
  productCount: Maybe<Scalars['Int']>;
  retailerId: Maybe<Scalars['BigInt']>;
  slug: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  status: Maybe<RetailerCollectionStatus>;
  storeConfigurations: Maybe<Array<Maybe<RetailerCollectionStoreConfiguration>>>;
  surfaces: Maybe<Array<Maybe<RetailerCollectionSurface>>>;
  tileImageUrl: Maybe<Scalars['String']>;
  translations: Maybe<Array<Maybe<RetailerCollectionTranslation>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum RetailerCollectionBulkUploadType {
  CollectionHeirarchy = 'COLLECTION_HEIRARCHY',
  EnumTypeBulkUploadUnspecified = 'ENUM_TYPE_BULK_UPLOAD_UNSPECIFIED',
  ProductAssignment = 'PRODUCT_ASSIGNMENT'
}

export enum RetailerCollectionContentType {
  Collections = 'COLLECTIONS',
  EnumTypeContentTypeUnspecified = 'ENUM_TYPE_CONTENT_TYPE_UNSPECIFIED',
  Products = 'PRODUCTS'
}

export type RetailerCollectionFilter = {
  __typename?: 'RetailerCollectionFilter';
  collectionId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  intersectionGroup: Maybe<Scalars['BigInt']>;
  ruleId: Maybe<Scalars['BigInt']>;
  value: Maybe<Scalars['String']>;
};

export type RetailerCollectionFilterInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  intersectionGroup?: InputMaybe<Scalars['BigInt']>;
  ruleId?: InputMaybe<Scalars['BigInt']>;
  value?: InputMaybe<Scalars['String']>;
};

export type RetailerCollectionInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum RetailerCollectionOrderBy {
  EnumTypeOrderByUnspecified = 'ENUM_TYPE_ORDER_BY_UNSPECIFIED',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

export enum RetailerCollectionOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
  EnumTypeOrderDirectionUnspecified = 'ENUM_TYPE_ORDER_DIRECTION_UNSPECIFIED'
}

export type RetailerCollectionProduct = {
  __typename?: 'RetailerCollectionProduct';
  cachedProductId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  lookupCode: Maybe<Scalars['String']>;
};

export enum RetailerCollectionProductCodeType {
  EnumTypeProductCodeTypeUnspecified = 'ENUM_TYPE_PRODUCT_CODE_TYPE_UNSPECIFIED',
  Rrc = 'RRC',
  Upc = 'UPC'
}

export type RetailerCollectionProductPayloadInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  lookupCode?: InputMaybe<Scalars['String']>;
  rrc?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type RetailerCollectionRule = {
  __typename?: 'RetailerCollectionRule';
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  valueSource: Maybe<RetailerCollectionRuleValueSource>;
  valueType: Maybe<RetailerCollectionRuleValueType>;
};

export type RetailerCollectionRuleStaticOption = {
  __typename?: 'RetailerCollectionRuleStaticOption';
  label: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export enum RetailerCollectionRuleSupportedQuery {
  BrandNames = 'BRAND_NAMES',
  EnumTypeRuleValueSourceQueryUnspecified = 'ENUM_TYPE_RULE_VALUE_SOURCE_QUERY_UNSPECIFIED',
  TaxonomyNodes = 'TAXONOMY_NODES'
}

export type RetailerCollectionRuleValueSource = {
  __typename?: 'RetailerCollectionRuleValueSource';
  options: Maybe<Array<Maybe<RetailerCollectionRuleStaticOption>>>;
  query: Maybe<RetailerCollectionRuleSupportedQuery>;
  type: Maybe<RetailerCollectionRuleValueSourceType>;
};

export enum RetailerCollectionRuleValueSourceType {
  EnumTypeRuleValueSourceTypeUnspecified = 'ENUM_TYPE_RULE_VALUE_SOURCE_TYPE_UNSPECIFIED',
  Query = 'QUERY',
  Static = 'STATIC'
}

export enum RetailerCollectionRuleValueType {
  Boolean = 'BOOLEAN',
  EnumTypeRuleValueTypeUnspecified = 'ENUM_TYPE_RULE_VALUE_TYPE_UNSPECIFIED',
  IntegerMultiselect = 'INTEGER_MULTISELECT',
  IntegerSelect = 'INTEGER_SELECT',
  RangeFloat = 'RANGE_FLOAT',
  StringMultiselect = 'STRING_MULTISELECT',
  StringSelect = 'STRING_SELECT',
  Text = 'TEXT'
}

export enum RetailerCollectionSortOrder {
  BestMatch = 'BEST_MATCH',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnumTypeSortOrderUnspecified = 'ENUM_TYPE_SORT_ORDER_UNSPECIFIED',
  Popularity = 'POPULARITY'
}

export enum RetailerCollectionStatus {
  Active = 'ACTIVE',
  ActiveAdminOnly = 'ACTIVE_ADMIN_ONLY',
  EnumTypeStatusUnspecified = 'ENUM_TYPE_STATUS_UNSPECIFIED',
  Inactive = 'INACTIVE'
}

export type RetailerCollectionStoreConfiguration = {
  __typename?: 'RetailerCollectionStoreConfiguration';
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  pinned: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  surface: Maybe<RetailerCollectionSurface>;
  url: Maybe<Scalars['String']>;
};

export type RetailerCollectionStoreConfigurationConfig = {
  __typename?: 'RetailerCollectionStoreConfigurationConfig';
  maxActiveTopLevelCollections: Maybe<Scalars['BigInt']>;
  numActiveTopLevelCollections: Maybe<Scalars['BigInt']>;
  storeConfigurationId: Maybe<Scalars['BigInt']>;
};

export type RetailerCollectionStoreConfigurationInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  surface?: InputMaybe<RetailerCollectionSurface>;
  url?: InputMaybe<Scalars['String']>;
};

export enum RetailerCollectionSurface {
  EnumTypeSurfaceUnspecified = 'ENUM_TYPE_SURFACE_UNSPECIFIED',
  Marketplace = 'MARKETPLACE',
  Storefront = 'STOREFRONT',
  StorefrontPro = 'STOREFRONT_PRO'
}

export type RetailerCollectionTranslation = {
  __typename?: 'RetailerCollectionTranslation';
  localeCode: Maybe<InstacartCustomersSharedV1Locale>;
  name: Maybe<Scalars['String']>;
};

export type RetailerCollectionTranslationInput = {
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  name?: InputMaybe<Scalars['String']>;
};

export type RetailerCollectionTreeNode = {
  __typename?: 'RetailerCollectionTreeNode';
  children: Maybe<Array<Maybe<RetailerCollectionTreeNode>>>;
  contentType: Maybe<RetailerCollectionContentType>;
  endDate: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  isInventoryFileEnrolled: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  position: Maybe<Scalars['Int']>;
  startDate: Maybe<Scalars['DateTime']>;
  status: Maybe<RetailerCollectionStatus>;
};

export enum RetailerCollectionVersion {
  EnumTypeVersionUnspecified = 'ENUM_TYPE_VERSION_UNSPECIFIED',
  V1 = 'V1',
  V2 = 'V2'
}

export type RetailerCollectionsCsvError = {
  __typename?: 'RetailerCollectionsCsvError';
  errorMessage: Maybe<Scalars['String']>;
  identifiers: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RetailerCollectionsCsvSummary = {
  __typename?: 'RetailerCollectionsCsvSummary';
  collectionCount: Maybe<Scalars['Int']>;
  identifiers: Maybe<Array<Maybe<Scalars['String']>>>;
  productCount: Maybe<Scalars['Int']>;
  summaryType: Maybe<RetailerCollectionsCsvSummarySectionType>;
};

export enum RetailerCollectionsCsvSummarySectionType {
  EnumTypeCsvSummaryUnspecified = 'ENUM_TYPE_CSV_SUMMARY_UNSPECIFIED',
  New = 'NEW',
  NewL1 = 'NEW_L1',
  NewL2 = 'NEW_L2',
  NewL3 = 'NEW_L3',
  NewL4 = 'NEW_L4',
  NewL5 = 'NEW_L5',
  NoChange = 'NO_CHANGE',
  Remove = 'REMOVE',
  Unmatched = 'UNMATCHED'
}

export type RetailerConfig = {
  __typename?: 'RetailerConfig';
  maxActiveTopLevelCollections: Maybe<Scalars['Int']>;
  maxActiveTopLevelStorefrontCollections: Maybe<Scalars['Int']>;
  maxActiveTopLevelStorefrontProCollections: Maybe<Scalars['Int']>;
};

export type RetailerConfigInput = {
  maxActiveTopLevelCollections?: InputMaybe<Scalars['Int']>;
  maxActiveTopLevelStorefrontCollections?: InputMaybe<Scalars['Int']>;
  maxActiveTopLevelStorefrontProCollections?: InputMaybe<Scalars['Int']>;
};

/** The connection type for Retailer. */
export type RetailerConnection = {
  __typename?: 'RetailerConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RetailerEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Retailer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RetailerContainerPath = {
  __typename?: 'RetailerContainerPath';
  containerPath: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type RetailerContainerPathInput = {
  containerPath?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

/** An edge in a connection. */
export type RetailerEdge = {
  __typename?: 'RetailerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Retailer>;
};

export type RetailerLocale = {
  __typename?: 'RetailerLocale';
  localeCode: Maybe<Scalars['String']>;
  localeId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type RetailerLocation = {
  __typename?: 'RetailerLocation';
  /** The active status for the location */
  active: Scalars['Boolean'];
  /** The location's city */
  city: Maybe<Scalars['String']>;
  /** Represents the set of 'Fullfillment Models' (how fulfillment is facilitated for the location) */
  fulfillmentModels: Maybe<Array<FulfillmentModelEnum>>;
  /** Hybrid Picking setting for the location indicates combined ISS and Partner Pick */
  hybridPickingEnabled: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the retailer location */
  id: Scalars['ID'];
  /** The retailer-assigned location code */
  locationCode: Maybe<Scalars['String']>;
  /** The name of the retailer location */
  name: Maybe<Scalars['String']>;
  /** Identifies if the location does partner run alcohol delivery */
  partnerAlcoholDelivery: Scalars['Boolean'];
  /** Represents the 'Picking Model' (how picking is facilitated for the location) */
  pickingModel: Maybe<PickingModelEnum>;
  /** A flag representing whether pickup is enabled */
  pickup: Scalars['Boolean'];
  /** Identifies if the location does platform only deliveries */
  platformOnlyEnabled: Maybe<Scalars['Boolean']>;
  /** The retailer that this location belongs to */
  retailer: Retailer;
  /** The location's state */
  state: Maybe<Scalars['String']>;
  /** The location's street (including street #) */
  street: Maybe<Scalars['String']>;
  /** The location's zip code or postal code */
  zipCode: Maybe<Scalars['String']>;
  /** The location's ZIP+4 (US-specific) */
  zipPlusFour: Maybe<Scalars['String']>;
};

export type RetailerLocationAisle = {
  __typename?: 'RetailerLocationAisle';
  aisleCount: Maybe<Scalars['BigInt']>;
  retailerLocationId: Maybe<Scalars['BigInt']>;
  retailerLocationName: Maybe<Scalars['String']>;
};

/** The connection type for RetailerLocation. */
export type RetailerLocationConnection = {
  __typename?: 'RetailerLocationConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RetailerLocationEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<RetailerLocation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RetailerLocationDetails = {
  __typename?: 'RetailerLocationDetails';
  address: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  coordinates: Maybe<GoogleTypeLatLng>;
  countryId: Maybe<Scalars['BigInt']>;
  flags: Maybe<RetailerLocationFlags>;
  id: Maybe<Scalars['Int']>;
  inventoryAreaId: Maybe<Scalars['Int']>;
  locationCode: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['Int']>;
  state: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type RetailerLocationEdge = {
  __typename?: 'RetailerLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<RetailerLocation>;
};

export type RetailerLocationFlags = {
  __typename?: 'RetailerLocationFlags';
  active: Maybe<Scalars['Boolean']>;
  alcohol: Maybe<Scalars['Boolean']>;
  available: Maybe<Scalars['Boolean']>;
  closed: Maybe<Scalars['Boolean']>;
  curbsidePickup: Maybe<Scalars['Boolean']>;
  pickup: Maybe<Scalars['Boolean']>;
  pickupOnly: Maybe<Scalars['Boolean']>;
  rx: Maybe<Scalars['Boolean']>;
  serviceable: Maybe<Scalars['Boolean']>;
  unavailable: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RetailerLocationGroupCreate */
export type RetailerLocationGroupCreatePayload = {
  __typename?: 'RetailerLocationGroupCreatePayload';
  /** The newly created group */
  retailerLocationGroup: RetailersRetailerLocationGroup;
};

/** Autogenerated return type of RetailerLocationGroupDelete */
export type RetailerLocationGroupDeletePayload = {
  __typename?: 'RetailerLocationGroupDeletePayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

/** Autogenerated return type of RetailerLocationGroupUpdate */
export type RetailerLocationGroupUpdatePayload = {
  __typename?: 'RetailerLocationGroupUpdatePayload';
  /** The updated group */
  retailerLocationGroup: RetailersRetailerLocationGroup;
};

export type RetailerLocationInfo = {
  __typename?: 'RetailerLocationInfo';
  name: Maybe<Scalars['String']>;
  retailerName: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
};

/** Attributes for a retailer location filter */
export type RetailerLocations = {
  /** A retailer location group ID */
  retailerLocationGroupId?: InputMaybe<Scalars['ID']>;
  /** A list of retailer location IDs */
  retailerLocationIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of RetailerMfaSetEnabled */
export type RetailerMfaSetEnabledPayload = {
  __typename?: 'RetailerMfaSetEnabledPayload';
  /** A status which is true if no errors were encountered */
  success: Scalars['True'];
};

export type RetailerMomentCampaign = {
  __typename?: 'RetailerMomentCampaign';
  collectionData: Maybe<Collection>;
  offers: Maybe<Array<Maybe<InstacartCustomersCommerceTypesV1SlimOffer>>>;
  retailerCampaign: Maybe<RetailerCampaign>;
  retailerData: Maybe<Retailer>;
};

export type RetailerOverride = {
  __typename?: 'RetailerOverride';
  retailerId: Maybe<Scalars['BigInt']>;
  retailerName: Maybe<Scalars['String']>;
};

export type RetailerProduct = {
  __typename?: 'RetailerProduct';
  aisleName: Maybe<Scalars['String']>;
  aisles: Maybe<Array<Maybe<RetailerProductAisle>>>;
  alcoholic: Maybe<Scalars['Boolean']>;
  atkinsDiet: Maybe<Scalars['Boolean']>;
  bottle: Maybe<Scalars['Boolean']>;
  brandId: Maybe<Scalars['BigInt']>;
  brandName: Maybe<Scalars['String']>;
  calories: Maybe<Scalars['Float']>;
  carbFree: Maybe<Scalars['Boolean']>;
  carbohydrate: Maybe<Scalars['Float']>;
  cchGroupCode: Maybe<Scalars['String']>;
  cchItemCode: Maybe<Scalars['String']>;
  coldPrepared: Maybe<Scalars['Boolean']>;
  containsFlour: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['DateTime']>;
  dairyFree: Maybe<Scalars['Boolean']>;
  departments: Maybe<Array<Maybe<RetailerProductDepartment>>>;
  description: Maybe<Scalars['String']>;
  details: Maybe<Scalars['String']>;
  directions: Maybe<Scalars['String']>;
  fatFree: Maybe<Scalars['Boolean']>;
  glutenFree: Maybe<Scalars['Boolean']>;
  hasImage: Maybe<Scalars['Boolean']>;
  highCarb: Maybe<Scalars['Boolean']>;
  highFat: Maybe<Scalars['Boolean']>;
  highProtein: Maybe<Scalars['Boolean']>;
  imageList: Maybe<Array<Maybe<RetailerProductImageInfo>>>;
  ingredients: Maybe<Scalars['String']>;
  isOrganic: Maybe<Scalars['Boolean']>;
  ketoDiet: Maybe<Scalars['Boolean']>;
  kidFriendly: Maybe<Scalars['Boolean']>;
  kosher: Maybe<Scalars['Boolean']>;
  lactoseFree: Maybe<Scalars['Boolean']>;
  localeCode: Maybe<Scalars['String']>;
  lowCarb: Maybe<Scalars['Boolean']>;
  lowFat: Maybe<Scalars['Boolean']>;
  lowSodium: Maybe<Scalars['Boolean']>;
  lowSugar: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  organic: Maybe<Scalars['Boolean']>;
  originalName: Maybe<Scalars['String']>;
  paleoDiet: Maybe<Scalars['Boolean']>;
  prepSlicedRepackagedPasteurizedByRetailer: Maybe<Scalars['Boolean']>;
  primaryAisleId: Maybe<Scalars['BigInt']>;
  primaryDepartmentId: Maybe<Scalars['BigInt']>;
  primaryImageUrl: Maybe<Scalars['String']>;
  productCategory: Maybe<Scalars['String']>;
  productCategoryTaxonomy: Maybe<RetailerProductProductCategoryTaxonomy>;
  productCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  productDisplayName: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['BigInt']>;
  productTags: Maybe<Scalars['String']>;
  prop65Text: Maybe<Scalars['String']>;
  requireReheating: Maybe<Scalars['Boolean']>;
  requiresKeepFrozen: Maybe<Scalars['Boolean']>;
  requiresRefrigeration: Maybe<Scalars['Boolean']>;
  retailerId: Maybe<Scalars['BigInt']>;
  retailerReferenceCode: Maybe<Scalars['String']>;
  score: Maybe<Scalars['Float']>;
  seasonality: Maybe<Scalars['JSONObject']>;
  size: Maybe<Scalars['String']>;
  sizeUom: Maybe<Scalars['String']>;
  storeBrand: Maybe<Scalars['Boolean']>;
  sugarFree: Maybe<Scalars['Boolean']>;
  suggestedIncrementalWeight: Maybe<Scalars['Float']>;
  unitCount: Maybe<Scalars['BigInt']>;
  vegan: Maybe<Scalars['Boolean']>;
  vegetarian: Maybe<Scalars['Boolean']>;
  vertexTaxOverrideAttribute: Maybe<Scalars['String']>;
  weightWatchers: Maybe<Scalars['Boolean']>;
  wholeGrain: Maybe<Scalars['Boolean']>;
};

export type RetailerProductAisle = {
  __typename?: 'RetailerProductAisle';
  departmentId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
};

export type RetailerProductAssortment = {
  __typename?: 'RetailerProductAssortment';
  entityId: Maybe<EntityId>;
  inAssortment: Maybe<Scalars['Boolean']>;
  outOfAssortmentEndAt: Maybe<Scalars['DateTime']>;
  outOfAssortmentStartAt: Maybe<Scalars['DateTime']>;
};

export type RetailerProductAssortmentFilterInput = {
  inAssortment?: InputMaybe<Scalars['Boolean']>;
};

export type RetailerProductData = {
  __typename?: 'RetailerProductData';
  availableItemsCount: Maybe<Scalars['BigInt']>;
  generatedAt: Maybe<Scalars['DateTime']>;
  itemsCount: Maybe<Scalars['BigInt']>;
  maxPriceCents: Maybe<Scalars['BigInt']>;
  minPriceCents: Maybe<Scalars['BigInt']>;
  productId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
  visibleItemsCount: Maybe<Scalars['BigInt']>;
};

export type RetailerProductDepartment = {
  __typename?: 'RetailerProductDepartment';
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
};

export type RetailerProductId = {
  __typename?: 'RetailerProductId';
  locale: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
  retailerProductId: Maybe<Scalars['BigInt']>;
};

export type RetailerProductIdInput = {
  locale?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerProductId?: InputMaybe<Scalars['BigInt']>;
};

export type RetailerProductImageInfo = {
  __typename?: 'RetailerProductImageInfo';
  imageId: Maybe<Scalars['String']>;
  imageType: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  sourceType: Maybe<Scalars['String']>;
};

export type RetailerProductProductCategoryTaxonomy = {
  __typename?: 'RetailerProductProductCategoryTaxonomy';
  l1: Maybe<Scalars['String']>;
  l2: Maybe<Scalars['String']>;
  l3: Maybe<Scalars['String']>;
  l4: Maybe<Scalars['String']>;
  l5: Maybe<Scalars['String']>;
  l6: Maybe<Scalars['String']>;
};

export type RetailerSatisfactionSurveyResponse = {
  /** Text that was displayed to user */
  surveyQuestion: Scalars['String'];
  /** Can be either numeric or text based on survey type */
  surveyResponse: Scalars['String'];
};

export enum RetailerServiceType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
  ServiceTypeUndefined = 'SERVICE_TYPE_UNDEFINED'
}

export type RetailerSettingDetailRecord = {
  __typename?: 'RetailerSettingDetailRecord';
  detailName: Maybe<Scalars['String']>;
  detailType: Maybe<Scalars['String']>;
  detailValue: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['BigInt']>;
  settingName: Maybe<Scalars['String']>;
  validValues: Maybe<Scalars['String']>;
};

export type RetailerSettingRecord = {
  __typename?: 'RetailerSettingRecord';
  comment: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  details: Maybe<Array<Maybe<RetailerSettingDetailRecord>>>;
  retailerId: Maybe<Scalars['BigInt']>;
  settingName: Maybe<Scalars['String']>;
  settingType: Maybe<RetailerSettingType>;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Maybe<Scalars['String']>;
  version: Maybe<Scalars['BigInt']>;
};

export enum RetailerSettingType {
  RetailerSettingTypeBoolean = 'RETAILER_SETTING_TYPE_BOOLEAN',
  RetailerSettingTypeInteger = 'RETAILER_SETTING_TYPE_INTEGER',
  RetailerSettingTypeString = 'RETAILER_SETTING_TYPE_STRING',
  RetailerSettingTypeUnknown = 'RETAILER_SETTING_TYPE_UNKNOWN'
}

export type RetailersPickupRetailerLocationGroup = {
  __typename?: 'RetailersPickupRetailerLocationGroup';
  /** A unique identifier for the location group */
  id: Scalars['ID'];
  /** A name for this location group */
  name: Scalars['String'];
  /** The identifier for the retailer which all locations in the group belong to */
  retailerId: Scalars['ID'];
  /** The retailer locations associated with the group */
  retailerLocations: Array<RetailerLocation>;
};

export type RetailersRetailerLocationGroup = {
  __typename?: 'RetailersRetailerLocationGroup';
  /** A unique identifier for the location group */
  id: Scalars['ID'];
  /** A name for this location group */
  name: Scalars['String'];
  /** The identifier for the retailer which all locations in the group belong to */
  retailerId: Scalars['ID'];
  /** The ids for the retailer locations associated with the group */
  retailerLocationIds: Array<Scalars['ID']>;
  /** The retailer locations associated with the group */
  retailerLocations: RetailerLocationConnection;
  /** The time at which the group was last updated */
  updatedAt: Scalars['Time'];
};


export type RetailersRetailerLocationGroupRetailerLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The connection type for RetailersRetailerLocationGroup. */
export type RetailersRetailerLocationGroupConnection = {
  __typename?: 'RetailersRetailerLocationGroupConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RetailersRetailerLocationGroupEdge>>>;
  /** Metadata regarding the connection */
  meta: ConnectionMeta;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<RetailersRetailerLocationGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RetailersRetailerLocationGroupEdge = {
  __typename?: 'RetailersRetailerLocationGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<RetailersRetailerLocationGroup>;
};

export type RetailersRetailerPickupMetadata = {
  __typename?: 'RetailersRetailerPickupMetadata';
  /** Warehouse early picking hour [if one is set] */
  earlyPickingHour: Maybe<Scalars['Int']>;
};

export type ReturnItemInput = {
  productId?: InputMaybe<Scalars['BigInt']>;
  returnedQuantity?: InputMaybe<InstacartTypesV1MeasuredQuantityInput>;
};

/** Autogenerated return type of RoleCreate */
export type RoleCreatePayload = {
  __typename?: 'RoleCreatePayload';
  /** The created role object */
  role: EnterpriseRole;
};

/** Autogenerated return type of RoleDelete */
export type RoleDeletePayload = {
  __typename?: 'RoleDeletePayload';
  /** The delete role object */
  role: EnterpriseRole;
};

export type RoleInput = {
  /** A unique identifier for the role */
  id: Scalars['ID'];
};

/** Autogenerated return type of RoleUpdate */
export type RoleUpdatePayload = {
  __typename?: 'RoleUpdatePayload';
  /** The update role object */
  role: EnterpriseRole;
};

export type RollbackStoreConfigurationRequestInput = {
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type RollbackStoreConfigurationResponse = {
  __typename?: 'RollbackStoreConfigurationResponse';
  errorMessage: Maybe<Scalars['String']>;
  errorReason: Maybe<ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Rule = {
  __typename?: 'Rule';
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  valueSource: Maybe<RuleValueSource>;
  valueType: Maybe<Scalars['String']>;
};

export type RuleValueSource = {
  __typename?: 'RuleValueSource';
  options: Maybe<Array<Maybe<RuleValueSourceOption>>>;
  query: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type RuleValueSourceOption = {
  __typename?: 'RuleValueSourceOption';
  label: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type SearchBrandsRequestInput = {
  limit?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  storeBrandsOnly?: InputMaybe<Scalars['Boolean']>;
};

export type SearchBrandsResponse = {
  __typename?: 'SearchBrandsResponse';
  brands: Maybe<Array<Maybe<Brand>>>;
};

export type SearchCollectionProductsRequestInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type SearchCollectionProductsResponse = {
  __typename?: 'SearchCollectionProductsResponse';
  products: Maybe<Array<Maybe<Product>>>;
};

export type SecondaryBanner = {
  __typename?: 'SecondaryBanner';
  backgroundColorHex: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaBackgroundColorHex: Maybe<Scalars['String']>;
  ctaColorHex: Maybe<Scalars['String']>;
  desktopImageUrl: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  mobileImageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  secondaryText: Maybe<Scalars['String']>;
  secondaryTextColorHex: Maybe<Scalars['String']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type SecondaryBannerInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  secondaryText?: InputMaybe<Scalars['String']>;
  secondaryTextColorHex?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

/** Autogenerated return type of SecondaryBannerPlacementCreate */
export type SecondaryBannerPlacementCreatePayload = {
  __typename?: 'SecondaryBannerPlacementCreatePayload';
  /** The newly created secondary_banner placement */
  secondaryBannerPlacement: ContentManagementSecondaryBannerPlacement;
};

export type SecondaryBannerPlacementInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** Where interacting with the placement leads */
  destination?: InputMaybe<PlacementDestinationInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Information of Experiment associated with the placement */
  experiment?: InputMaybe<PlacementExperimentInput>;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** The images on the banner */
  images: GenericImagesInput;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** The priority rank of the placement */
  rank?: InputMaybe<Scalars['Int']>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The style and text of the secondary text */
  secondaryText?: InputMaybe<ConfigurableTextInput>;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
  /** The type of this placement */
  type: PlacementTypeEnum;
};

/** Autogenerated return type of SecondaryBannerPlacementUpdate */
export type SecondaryBannerPlacementUpdatePayload = {
  __typename?: 'SecondaryBannerPlacementUpdatePayload';
  /** The updated secondary_banner placement */
  secondaryBannerPlacement: ContentManagementSecondaryBannerPlacement;
};

export type SellSheetAnalyticsSummary = {
  __typename?: 'SellSheetAnalyticsSummary';
  sellSheetId: Maybe<Scalars['String']>;
  totalOrders: Maybe<Scalars['BigInt']>;
  totalSalesUsd: Maybe<Scalars['Float']>;
};

export type SellSheetAnalyticsSummaryGrowthMetrics = {
  __typename?: 'SellSheetAnalyticsSummaryGrowthMetrics';
  sellSheetId: Maybe<Scalars['String']>;
  totalOrdersGrowth: Maybe<Scalars['Float']>;
  totalSalesUsdGrowth: Maybe<Scalars['Float']>;
};

export type SellSheetAnalyticsSummaryRequestInput = {
  dateRange?: InputMaybe<InstacartAdsSharedV1DateRangeInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetAnalyticsSummaryResponse = {
  __typename?: 'SellSheetAnalyticsSummaryResponse';
  analyticsSummary: Maybe<SellSheetAnalyticsSummary>;
  growthMetrics: Maybe<SellSheetAnalyticsSummaryGrowthMetrics>;
  previousPeriodMetrics: Maybe<SellSheetAnalyticsSummary>;
};

export type SellSheetAttributes = {
  __typename?: 'SellSheetAttributes';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  overview: Maybe<Scalars['String']>;
};

export type SellSheetAttributesRequestInput = {
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetAttributesResponse = {
  __typename?: 'SellSheetAttributesResponse';
  legalFilterType: Maybe<LegalFilterType>;
  sellSheetAttributes: Maybe<SellSheetAttributes>;
};

export type SellSheetBrandProfileAttributesRequestInput = {
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetBrandProfileAttributesResponse = {
  __typename?: 'SellSheetBrandProfileAttributesResponse';
  brandProfileAttributes: Maybe<BrandProfileAttributes>;
};

export type SellSheetCard = {
  __typename?: 'SellSheetCard';
  brandImages: Maybe<Array<Maybe<ImageAsset>>>;
  brandName: Maybe<Scalars['String']>;
  brandProfileId: Maybe<Scalars['String']>;
  productDisplays: Maybe<Array<Maybe<ProductDisplay>>>;
  productImages: Maybe<Array<Maybe<ImageAsset>>>;
  sellSheetAnalyticsGrowthMetrics: Maybe<SellSheetAnalyticsSummaryGrowthMetrics>;
  sellSheetAnalyticsSummary: Maybe<SellSheetAnalyticsSummary>;
  sellSheetAttributes: Maybe<SellSheetAttributes>;
};

export type SellSheetCardsRequestInput = {
  brandProfileIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isDebug?: InputMaybe<Scalars['Boolean']>;
  paginationRequest?: InputMaybe<InstacartAdsSharedV1PaginationRequestInput>;
  regionalAreas?: InputMaybe<Array<InputMaybe<InstacartAdsNexusV1RegionalArea>>>;
  retailerCarriedState?: InputMaybe<SellSheetRetailerCarriedState>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sortKeys?: InputMaybe<Array<InputMaybe<SellSheetSortKey>>>;
  superCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  superCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type SellSheetCardsResponse = {
  __typename?: 'SellSheetCardsResponse';
  paginationResponse: Maybe<InstacartAdsSharedV1PaginationResponse>;
  sellSheetCards: Maybe<Array<Maybe<SellSheetCard>>>;
};

export type SellSheetOverviewAttributes = {
  __typename?: 'SellSheetOverviewAttributes';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  productCount: Maybe<Scalars['BigInt']>;
};

export type SellSheetProductsAttributesRequestInput = {
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetProductsAttributesResponse = {
  __typename?: 'SellSheetProductsAttributesResponse';
  productsAttributes: Maybe<Array<Maybe<ProductAttributes>>>;
};

export type SellSheetProductsSummaryTraitsRequestInput = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  sellSheetId?: InputMaybe<Scalars['String']>;
};

export type SellSheetProductsSummaryTraitsResponse = {
  __typename?: 'SellSheetProductsSummaryTraitsResponse';
  productsSummaryTraits: Maybe<Array<Maybe<ProductSummaryTraits>>>;
};

export enum SellSheetRetailerCarriedState {
  PartiallyCarried = 'PARTIALLY_CARRIED',
  RetailerCarriedUnkown = 'RETAILER_CARRIED_UNKOWN'
}

export enum SellSheetSortKey {
  BrandName = 'BRAND_NAME',
  CreatedAt = 'CREATED_AT',
  ProductOrders = 'PRODUCT_ORDERS',
  ProductOrdersGrowth = 'PRODUCT_ORDERS_GROWTH',
  SalesAmount = 'SALES_AMOUNT',
  SalesGrowth = 'SALES_GROWTH',
  SortKeyUnkwown = 'SORT_KEY_UNKWOWN',
  UnitsSold = 'UNITS_SOLD',
  UnitsSoldGrowth = 'UNITS_SOLD_GROWTH'
}

export enum Send {
  /** The campaign has an email template */
  Email = 'email',
  /** The campaign has a push notification template */
  Push = 'push'
}

export type SetCollectionFiltersRequestInput = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  filters?: InputMaybe<Array<InputMaybe<RetailerCollectionFilterInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type SetCollectionFiltersResponse = {
  __typename?: 'SetCollectionFiltersResponse';
  filters: Maybe<Array<Maybe<RetailerCollectionFilter>>>;
};

/** Autogenerated return type of SetMobileProperties */
export type SetMobilePropertiesPayload = {
  __typename?: 'SetMobilePropertiesPayload';
  /** The full set of properties */
  mobileAppProperties: Array<OnboardingFeatureTypesProperty>;
};

export type SetUserSettingRequestInput = {
  client?: InputMaybe<ClientInput>;
  setting?: InputMaybe<UserSettingInput>;
};

export type SetUserSettingResponse = {
  __typename?: 'SetUserSettingResponse';
  _: Maybe<Scalars['Boolean']>;
};

export enum ShiftTypeEnum {
  FullService = 'FULL_SERVICE',
  PickingOnly = 'PICKING_ONLY',
  Runner = 'RUNNER'
}

export type ShopSidebar = {
  __typename?: 'ShopSidebar';
  collapsed: Maybe<Scalars['Boolean']>;
};

export type ShoppedAtRetailerInput = {
  /** Id of the country where the check is to be performed */
  countryId?: InputMaybe<Scalars['Int']>;
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** Number of days in the past to check */
  numberOfDays?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the Retailer */
  retailerId: Scalars['Int'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of ShoppedAtRetailerVisibilityConditionCreate */
export type ShoppedAtRetailerVisibilityConditionCreatePayload = {
  __typename?: 'ShoppedAtRetailerVisibilityConditionCreatePayload';
  /** The newly created shopped_at_retailer visibility condition */
  shoppedAtRetailerVisibilityCondition: ContentManagementVisibilityConditionShoppedAtRetailer;
};

/** Autogenerated return type of ShoppedAtRetailerVisibilityConditionUpdate */
export type ShoppedAtRetailerVisibilityConditionUpdatePayload = {
  __typename?: 'ShoppedAtRetailerVisibilityConditionUpdatePayload';
  /** The newly created shopped_at_retailer visibility condition */
  shoppedAtRetailerVisibilityCondition: ContentManagementVisibilityConditionShoppedAtRetailer;
};

export type ShoppedInput = {
  /** Id of the country where the check is to be performed */
  countryId?: InputMaybe<Scalars['Int']>;
  /** Whether the user should have shopped at the retailer */
  hasShopped: Scalars['Boolean'];
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** Number of days in the past to check */
  numberOfDays?: InputMaybe<Scalars['Int']>;
  /** Number of times in the past to check */
  numberOfTimes?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** Id of the store configuraiton for which the check is to be performed */
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of ShoppedVisibilityConditionCreate */
export type ShoppedVisibilityConditionCreatePayload = {
  __typename?: 'ShoppedVisibilityConditionCreatePayload';
  /** The newly created shopped visibility condition */
  shoppedVisibilityCondition: ContentManagementVisibilityConditionShopped;
};

/** Autogenerated return type of ShoppedVisibilityConditionUpdate */
export type ShoppedVisibilityConditionUpdatePayload = {
  __typename?: 'ShoppedVisibilityConditionUpdatePayload';
  /** The newly created shopped visibility condition */
  shoppedVisibilityCondition: ContentManagementVisibilityConditionShopped;
};

export type ShopperOrderItemScanInput = {
  scannedString?: InputMaybe<Scalars['String']>;
  scannedStringType?: InputMaybe<Scalars['String']>;
};

export type ShopperOrderProfile = {
  __typename?: 'ShopperOrderProfile';
  avatarUrl: Maybe<Scalars['String']>;
  batchId: Maybe<Scalars['BigInt']>;
  canChat: Maybe<Scalars['Boolean']>;
  canReceiveTips: Maybe<Scalars['Boolean']>;
  canTakeAlcoholOrders: Maybe<Scalars['Boolean']>;
  deliverer: Maybe<Scalars['Boolean']>;
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  lastName: Maybe<Scalars['String']>;
  partnerId: Maybe<Scalars['BigInt']>;
  partnerType: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  picker: Maybe<Scalars['Boolean']>;
  primaryZoneId: Maybe<Scalars['BigInt']>;
  retailerLocationId: Maybe<Scalars['BigInt']>;
  rolesMask: Maybe<Scalars['Int']>;
  runner: Maybe<Scalars['Boolean']>;
  shareCode: Maybe<Scalars['String']>;
  signatureUrl: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['BigInt']>;
};

export enum ShopperType {
  ShopperTypeFss = 'SHOPPER_TYPE_FSS',
  ShopperTypePartnerPick = 'SHOPPER_TYPE_PARTNER_PICK',
  ShopperTypeUnknown = 'SHOPPER_TYPE_UNKNOWN'
}

export type SignupPartner = {
  __typename?: 'SignupPartner';
  description: Maybe<Scalars['String']>;
  hasExclusiveOption: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['BigInt']>;
  logo: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  skipCreditCheck: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
};

export type Slot = {
  __typename?: 'Slot';
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  isStatic: Maybe<Scalars['Boolean']>;
  legacySlotId: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['BigInt']>;
  type: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type SlotCreative = {
  __typename?: 'SlotCreative';
  createdAt: Maybe<Scalars['DateTime']>;
  creativeId: Maybe<Scalars['BigInt']>;
  creativeRank: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  slotId: Maybe<Scalars['BigInt']>;
  treatmentPolicyId: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type SlotCreativeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creativeId?: InputMaybe<Scalars['BigInt']>;
  creativeRank?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  slotId?: InputMaybe<Scalars['BigInt']>;
  treatmentPolicyId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SlotInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isStatic?: InputMaybe<Scalars['Boolean']>;
  legacySlotId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum SourceLevel {
  SourceLevelMulti = 'SOURCE_LEVEL_MULTI',
  SourceLevelProduct = 'SOURCE_LEVEL_PRODUCT',
  SourceLevelRetailerProduct = 'SOURCE_LEVEL_RETAILER_PRODUCT',
  SourceLevelUnknown = 'SOURCE_LEVEL_UNKNOWN'
}

export type StageLastmileOrderRequestInput = {
  externalId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartTypesV1RequestContextInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerProvidedAmounts?: InputMaybe<InstacartEnterpriseBobaCoreTypesV1RetailerProvidedAmountsInput>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type StageLastmileOrderResponse = {
  __typename?: 'StageLastmileOrderResponse';
  order: Maybe<InstacartEnterpriseBobaCoreTypesV1Order>;
};

export type StandardInfoModalSheet = {
  __typename?: 'StandardInfoModalSheet';
  description: Maybe<Scalars['String']>;
  desktopImageUrl: Maybe<Scalars['String']>;
  disclaimer: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  imageAltText: Maybe<Scalars['String']>;
  linkCta: Maybe<Scalars['String']>;
  linkCtaAction: Maybe<BannerAction>;
  linkCtaButtonStyle: Maybe<ButtonStyle>;
  locale: Maybe<Scalars['String']>;
  mobileImageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  primaryCta: Maybe<Scalars['String']>;
  primaryCtaAction: Maybe<BannerAction>;
  primaryCtaButtonStyle: Maybe<ButtonStyle>;
  secondaryCta: Maybe<Scalars['String']>;
  secondaryCtaAction: Maybe<BannerAction>;
  secondaryCtaButtonStyle: Maybe<ButtonStyle>;
  title: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type StandardInfoModalSheetInput = {
  description?: InputMaybe<Scalars['String']>;
  desktopImageUrl?: InputMaybe<Scalars['String']>;
  disclaimer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  linkCta?: InputMaybe<Scalars['String']>;
  linkCtaAction?: InputMaybe<BannerActionInput>;
  linkCtaButtonStyle?: InputMaybe<ButtonStyle>;
  locale?: InputMaybe<Scalars['String']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  primaryCta?: InputMaybe<Scalars['String']>;
  primaryCtaAction?: InputMaybe<BannerActionInput>;
  primaryCtaButtonStyle?: InputMaybe<ButtonStyle>;
  secondaryCta?: InputMaybe<Scalars['String']>;
  secondaryCtaAction?: InputMaybe<BannerActionInput>;
  secondaryCtaButtonStyle?: InputMaybe<ButtonStyle>;
  title?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type StartCampaignInput = {
  /** The id of the campaign returned from the campaign domain */
  campaignId: Scalars['ID'];
  /** The user friendly name of the campaign returned from the campaign domain */
  campaignName?: InputMaybe<Scalars['String']>;
  /** Whether or not to create a split experiment */
  includeExperiment?: InputMaybe<Scalars['Boolean']>;
  /** The external id of the push notification template returned */
  pushNotificationId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the segment. Currently for Braze's API */
  segmentId: Scalars['ID'];
  /** The start time for the email to send. A null value indicates immediately */
  startTime?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** The external id of the template returned */
  templateId?: InputMaybe<Scalars['ID']>;
  /** Variables to append to the Braze template */
  templateVariables: TemplateVariablesInput;
  /** The experiment name to use for the CRM Campaign */
  userProvidedExperimentName?: InputMaybe<Scalars['String']>;
  /** The warehouse id to run the campaign for */
  warehouseId: Scalars['ID'];
};

/** Autogenerated return type of StartCampaign */
export type StartCampaignPayload = {
  __typename?: 'StartCampaignPayload';
  /** The campaign configuration object */
  campaignConfiguration: MarketingFeatureTypesCampaignConfiguration;
};

export type StartCsvImportRequestInput = {
  fileName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type StartCsvImportResponse = {
  __typename?: 'StartCsvImportResponse';
  key: Maybe<Scalars['String']>;
};

export type StartCsvSummaryRequestInput = {
  fileName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type StartCsvSummaryResponse = {
  __typename?: 'StartCsvSummaryResponse';
  key: Maybe<Scalars['String']>;
};

export enum Status {
  Active = 'ACTIVE',
  /** The tour has been finished by the user */
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Live = 'LIVE',
  /** The tour has not been seen by the user */
  Pending = 'PENDING',
  /** The tour has been displayed to the user, but not finished */
  Shown = 'SHOWN'
}

export type StatusMapInput = {
  momentId?: InputMaybe<Scalars['BigInt']>;
  momentStatus?: InputMaybe<FilterType>;
  status?: InputMaybe<FilterType>;
};

export type StoreConfiguration = {
  __typename?: 'StoreConfiguration';
  enterpriseRetailerSettings: Maybe<Scalars['JSONObject']>;
  id: Maybe<Scalars['Int']>;
  settings: Maybe<Scalars['JSONObject']>;
  userIsolationContextId: Maybe<Scalars['Int']>;
};

export type StoreConfigurationDraft = {
  __typename?: 'StoreConfigurationDraft';
  createdAt: Maybe<Scalars['DateTime']>;
  domainConfigurations: Maybe<DomainConfiguration>;
  draftId: Maybe<Scalars['String']>;
  draftName: Maybe<Scalars['String']>;
  parentDraftId: Maybe<Scalars['String']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type StoreConfigurationInput = {
  enterpriseRetailerSettings?: InputMaybe<Scalars['JSONObject']>;
  id?: InputMaybe<Scalars['Int']>;
  settings?: InputMaybe<Scalars['JSONObject']>;
  userIsolationContextId?: InputMaybe<Scalars['Int']>;
};

export enum StoreConfigurationRelationshipEnum {
  /** StoreConfigurations that are associated */
  Associated = 'ASSOCIATED',
  /** StoreConfigurations that are owned */
  Owner = 'OWNER'
}

export type StoreHours = {
  __typename?: 'StoreHours';
  overrideHours: Maybe<Array<Maybe<OverrideHours>>>;
  regularHours: Maybe<Array<Maybe<RegularHours>>>;
  retailerLocationId: Maybe<Scalars['BigInt']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type StoreHoursByDate = {
  __typename?: 'StoreHoursByDate';
  createdAt: Maybe<Scalars['DateTime']>;
  date: Maybe<GoogleTypeDate>;
  endsAt: Maybe<GoogleTypeTimeOfDay>;
  operationalHours: Maybe<OperationalHours>;
  retailerId: Maybe<Scalars['BigInt']>;
  retailerLocationFlags: Maybe<RetailerLocationFlags>;
  retailerLocationId: Maybe<Scalars['BigInt']>;
  startsAt: Maybe<GoogleTypeTimeOfDay>;
  updatedAt: Maybe<Scalars['DateTime']>;
  zoneId: Maybe<Scalars['BigInt']>;
};

export type StoreHoursByDayOfWeek = {
  __typename?: 'StoreHoursByDayOfWeek';
  createdAt: Maybe<Scalars['DateTime']>;
  dayOfWeek: Maybe<GoogleTypeDayOfWeek>;
  endsAt: Maybe<GoogleTypeTimeOfDay>;
  operationalHours: Maybe<OperationalHours>;
  retailerId: Maybe<Scalars['BigInt']>;
  retailerLocationFlags: Maybe<RetailerLocationFlags>;
  retailerLocationId: Maybe<Scalars['BigInt']>;
  startsAt: Maybe<GoogleTypeTimeOfDay>;
  updatedAt: Maybe<Scalars['DateTime']>;
  zoneId: Maybe<Scalars['BigInt']>;
};

export type StoreHoursByZoneRetailer = {
  __typename?: 'StoreHoursByZoneRetailer';
  createdAt: Maybe<Scalars['DateTime']>;
  date: Maybe<GoogleTypeDate>;
  endsAt: Maybe<GoogleTypeTimeOfDay>;
  retailerId: Maybe<Scalars['BigInt']>;
  startsAt: Maybe<GoogleTypeTimeOfDay>;
  updatedAt: Maybe<Scalars['DateTime']>;
  zoneId: Maybe<Scalars['BigInt']>;
};

export type StoreHoursUpdateTask = {
  __typename?: 'StoreHoursUpdateTask';
  author: Maybe<Scalars['String']>;
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  failedUpdates: Maybe<Array<Maybe<FailedUpdate>>>;
  id: Maybe<Scalars['BigInt']>;
  status: Maybe<TaskStatus>;
  updateSource: Maybe<Scalars['String']>;
  updatedRetailerLocationIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export type StorePerformanceRecord = {
  __typename?: 'StorePerformanceRecord';
  name: Maybe<Scalars['String']>;
  overstaffedHours: Maybe<InstacartTypesV1BigDecimal>;
  recommendedHours: Maybe<InstacartTypesV1BigDecimal>;
  scheduledDevices: Maybe<Scalars['Int']>;
  scheduledGaps: Maybe<Scalars['Int']>;
  scheduledHours: Maybe<InstacartTypesV1BigDecimal>;
  understaffedHours: Maybe<InstacartTypesV1BigDecimal>;
  warehouseLocationId: Maybe<Scalars['BigInt']>;
};

export type StorePerformanceRequestInput = {
  endDate?: InputMaybe<GoogleTypeDateInput>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type StorePerformanceResponse = {
  __typename?: 'StorePerformanceResponse';
  metric: Maybe<Array<Maybe<StorePerformanceRecord>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
  warehouseId: Maybe<Scalars['BigInt']>;
};

export enum Storefront {
  /** Marketplace storefront */
  Instacart = 'instacart',
  /** Storefront / Storefront PRO storefronts */
  Pbi = 'pbi'
}

export type StorefrontPlacementBannerInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** The style and text of the description */
  descriptionText: ConfigurableTextInput;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** A unique identifier of the banner associated with the placement */
  id?: InputMaybe<Scalars['ID']>;
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: PlacementImagesInput;
  /** The banner's locale (not used right now) */
  locale: BannerLocaleEnum;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
};

/** Autogenerated return type of StorefrontPlacementCreate */
export type StorefrontPlacementCreatePayload = {
  __typename?: 'StorefrontPlacementCreatePayload';
  /** The newly created storefront storefront placement */
  storefrontPlacement: ContentManagementStorefrontPlacement;
};

/** Autogenerated return type of StorefrontPlacementDelete */
export type StorefrontPlacementDeletePayload = {
  __typename?: 'StorefrontPlacementDeletePayload';
  /** Whether deleting the storefront placement succeeded or not */
  success: Scalars['Boolean'];
};

export type StorefrontPlacementInput = {
  /** The color of the background for text placements (Eg. #FFFFFF) */
  backgroundColor: Scalars['String'];
  /** The banners configured to the placement */
  banners?: InputMaybe<Array<StorefrontPlacementBannerInput>>;
  /** The style and text of the button within the placement */
  button: ConfigurableButtonInput;
  /** The style and text of the description */
  descriptionText: ConfigurableTextInput;
  /** Where interacting with the placement leads */
  destination?: InputMaybe<PlacementDestinationInput>;
  /** The style and text of the heading */
  headingText: ConfigurableTextInput;
  /** the alt text for the placement images */
  imageAltText: Scalars['String'];
  /** The possible placement images to display */
  images: PlacementImagesInput;
  /** The store configurations that this placement should be displayed on */
  includedStoreConfigurationIds?: InputMaybe<Array<Scalars['Int']>>;
  /** The name of the placement */
  name: Scalars['String'];
  /** The platform which this placement should be displayed */
  platform: Array<PlacementPlatformEnum>;
  /** When the placement begins and ends */
  scheduling: PlacementScheduleInput;
  /** The style and text of the subheading */
  subheadingText: ConfigurableTextInput;
  /** The type of this placement */
  type: PlacementTypeEnum;
};

/** Autogenerated return type of StorefrontPlacementUpdate */
export type StorefrontPlacementUpdatePayload = {
  __typename?: 'StorefrontPlacementUpdatePayload';
  /** The updated storefront placement */
  storefrontPlacement: ContentManagementStorefrontPlacement;
};

export type StringsInput = {
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Autogenerated return type of SubmissionCreate */
export type SubmissionCreatePayload = {
  __typename?: 'SubmissionCreatePayload';
  /** Survey submit successful */
  success: Scalars['Boolean'];
};

export type SummaryStatus = {
  __typename?: 'SummaryStatus';
  count: Maybe<Scalars['BigInt']>;
  status: Maybe<Scalars['String']>;
};

export enum Targeting {
  ActivatedCustomer = 'ACTIVATED_CUSTOMER',
  ChurnedCustomer = 'CHURNED_CUSTOMER',
  NewCustomer = 'NEW_CUSTOMER',
  Unknown = 'UNKNOWN'
}

export type TargetingRules = {
  __typename?: 'TargetingRules';
  lookbackWindowDays: Maybe<Scalars['BigInt']>;
  targetingType: Maybe<Targeting>;
};

export type TargetingRulesInput = {
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  targetingType?: InputMaybe<Targeting>;
};

export enum TaskStatus {
  CompletedFailure = 'COMPLETED_FAILURE',
  CompletedSuccess = 'COMPLETED_SUCCESS',
  Queued = 'QUEUED',
  Running = 'RUNNING',
  TaskStatusUndefined = 'TASK_STATUS_UNDEFINED'
}

export type TemplateVariablesInput = {
  couponCode?: InputMaybe<Scalars['String']>;
  daysValid?: InputMaybe<Scalars['Int']>;
  offerDetailsDollarOff?: InputMaybe<Scalars['String']>;
  offerDetailsMinimumOrder?: InputMaybe<Scalars['String']>;
  retailerLogoUrl?: InputMaybe<Scalars['String']>;
  retailerName?: InputMaybe<Scalars['String']>;
  retailerSlug?: InputMaybe<Scalars['String']>;
  storefrontType?: InputMaybe<Storefront>;
};

export type TextWithThumbnail = {
  __typename?: 'TextWithThumbnail';
  backgroundColorHex: Maybe<Scalars['String']>;
  backgroundImageUrl: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaBackgroundColorHex: Maybe<Scalars['String']>;
  ctaColorHex: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  imageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type TextWithThumbnailFlex = {
  __typename?: 'TextWithThumbnailFlex';
  backgroundColorHex: Maybe<Scalars['String']>;
  backgroundImageUrl: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  ctaAction: Maybe<BannerAction>;
  ctaBackgroundColorHex: Maybe<Scalars['String']>;
  ctaColorHex: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  imageUrl: Maybe<Scalars['String']>;
  placementId: Maybe<Scalars['BigInt']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
  variant: Maybe<BannerVariant>;
};

export type TextWithThumbnailFlexInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type TextWithThumbnailInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  cta?: InputMaybe<Scalars['String']>;
  ctaAction?: InputMaybe<BannerActionInput>;
  ctaBackgroundColorHex?: InputMaybe<Scalars['String']>;
  ctaColorHex?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  placementId?: InputMaybe<Scalars['BigInt']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
  variant?: InputMaybe<BannerVariant>;
};

export type ThemePage = {
  __typename?: 'ThemePage';
  active: Maybe<Scalars['Boolean']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  headerSection: Maybe<ThemePageHeader>;
  id: Maybe<Scalars['BigInt']>;
  retailerPicker: Maybe<ThemePageRetailerPicker>;
  searchBar: Maybe<ThemePageSearchBar>;
  slug: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type ThemePageHeader = {
  __typename?: 'ThemePageHeader';
  backgroundColorHex: Maybe<Scalars['String']>;
  disclaimerUrl: Maybe<Scalars['String']>;
  enabled: Maybe<Scalars['Boolean']>;
  imageAltText: Maybe<Scalars['String']>;
  imageMobileUrl: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  subTitle: Maybe<Scalars['String']>;
  subTitleColorHex: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColorHex: Maybe<Scalars['String']>;
};

export type ThemePageHeaderInput = {
  backgroundColorHex?: InputMaybe<Scalars['String']>;
  disclaimerUrl?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  imageAltText?: InputMaybe<Scalars['String']>;
  imageMobileUrl?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  subTitleColorHex?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleColorHex?: InputMaybe<Scalars['String']>;
};

export type ThemePageInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  headerSection?: InputMaybe<ThemePageHeaderInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  retailerPicker?: InputMaybe<ThemePageRetailerPickerInput>;
  searchBar?: InputMaybe<ThemePageSearchBarInput>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ThemePageRetailerPicker = {
  __typename?: 'ThemePageRetailerPicker';
  enabled: Maybe<Scalars['Boolean']>;
};

export type ThemePageRetailerPickerInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type ThemePageSearchBar = {
  __typename?: 'ThemePageSearchBar';
  enabled: Maybe<Scalars['Boolean']>;
};

export type ThemePageSearchBarInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
};

export type ThemeSchema = {
  __typename?: 'ThemeSchema';
  footer: Maybe<Footer>;
  header: Maybe<Header>;
  idsTheme: Maybe<IdsThemeSchema>;
  items: Maybe<ItemsSchema>;
  shopSidebar: Maybe<ShopSidebar>;
};

export enum TicketStatus {
  TicketStatusCompleted = 'TICKET_STATUS_COMPLETED',
  TicketStatusInProgress = 'TICKET_STATUS_IN_PROGRESS',
  TicketStatusUnknown = 'TICKET_STATUS_UNKNOWN',
  TicketStatusWontDo = 'TICKET_STATUS_WONT_DO'
}

export type TimePeriod = {
  __typename?: 'TimePeriod';
  closesAt: Maybe<Scalars['DateTime']>;
  opensAt: Maybe<Scalars['DateTime']>;
};

export enum TokenTypeEnum {
  /** Email Verification Token Type */
  EmailVerification = 'email_verification',
  /** Password Reset Token Type */
  PasswordReset = 'password_reset'
}

export type TokensValidation = {
  __typename?: 'TokensValidation';
  /** Whether the token is valid */
  valid: Scalars['Boolean'];
};

export type Translation = {
  __typename?: 'Translation';
  localeCode: Maybe<InstacartCustomersSharedV1Locale>;
  name: Maybe<Scalars['String']>;
};

export type TranslationInput = {
  localeCode?: InputMaybe<InstacartCustomersSharedV1Locale>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of TriggerCampaign */
export type TriggerCampaignPayload = {
  __typename?: 'TriggerCampaignPayload';
  /** Whether it succesfully triggered */
  success: Scalars['Boolean'];
};

export type TypographyFontSchema = {
  __typename?: 'TypographyFontSchema';
  fontFamily: Maybe<Scalars['String']>;
  fontWeight: Maybe<Scalars['String']>;
};

export type TypographySchema = {
  __typename?: 'TypographySchema';
  rootElement: Maybe<TypographyFontSchema>;
  subtitleLarge: Maybe<TypographyFontSchema>;
  subtitleMedium: Maybe<TypographyFontSchema>;
  subtitleSmall: Maybe<TypographyFontSchema>;
  titleLarge: Maybe<TypographyFontSchema>;
  titleMedium: Maybe<TypographyFontSchema>;
};

export type UnionType = {
  __typename?: 'UnionType';
  content: Maybe<Scalars['JSONObject']>;
  type: Maybe<Scalars['String']>;
};

export type UnknownBanner = {
  __typename?: 'UnknownBanner';
  id: Maybe<Scalars['BigInt']>;
};

export type UnknownBannerInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Upc = {
  __typename?: 'Upc';
  upc: Maybe<Scalars['String']>;
};

export type UpcInput = {
  upc?: InputMaybe<Scalars['String']>;
};

export type UpdateApiSettingRequestInput = {
  apiType?: InputMaybe<Scalars['String']>;
  cron?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  integrationType?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeLocationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateApiSettingResponse = {
  __typename?: 'UpdateApiSettingResponse';
  apiType: Maybe<Scalars['String']>;
  cron: Maybe<Scalars['String']>;
  enabled: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['BigInt']>;
  integrationType: Maybe<Scalars['String']>;
  partnerId: Maybe<Scalars['BigInt']>;
};

export type UpdateAuditBatchRequestInput = {
  action?: InputMaybe<Scalars['String']>;
  batchUuid?: InputMaybe<Scalars['String']>;
  changeSampleSizeValue?: InputMaybe<Scalars['BigInt']>;
  client?: InputMaybe<Scalars['String']>;
  overrideDueDate?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateAuditBatchResponse = {
  __typename?: 'UpdateAuditBatchResponse';
  completedAt: Maybe<Scalars['DateTime']>;
  message: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  version: Maybe<Scalars['BigInt']>;
};

export type UpdateAuditTaskRequestInput = {
  client?: InputMaybe<Scalars['String']>;
  extComment?: InputMaybe<Scalars['String']>;
  intComment?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  taskComponents?: InputMaybe<Array<InputMaybe<UpdateAuditTaskRequestUpdateTaskComponentInput>>>;
  taskUuid?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateAuditTaskRequestUpdateTaskComponentInput = {
  explicitAuditFailed?: InputMaybe<Scalars['Boolean']>;
  extComment?: InputMaybe<Scalars['String']>;
  intComment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  suggestedValue?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateAuditTaskResponse = {
  __typename?: 'UpdateAuditTaskResponse';
  batchVersion: Maybe<Scalars['BigInt']>;
  message: Maybe<Scalars['String']>;
  version: Maybe<Scalars['BigInt']>;
};

export type UpdateCollectionProductsRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  productsToAdd?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  productsToDelete?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<Scalars['String']>;
};

export type UpdateCollectionProductsResponse = {
  __typename?: 'UpdateCollectionProductsResponse';
  collection: Maybe<Collection>;
};

export type UpdateCollectionRequestInput = {
  collectionType?: InputMaybe<Scalars['String']>;
  defaultSortOrder?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  productsToAdd?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  productsToDelete?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  resetAllProducts?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<CollectionTranslationInput>>>;
};

export type UpdateCollectionResponse = {
  __typename?: 'UpdateCollectionResponse';
  collection: Maybe<Collection>;
};

export type UpdateConfirmedStoreHoursRequestInput = {
  confirmations?: InputMaybe<Array<InputMaybe<ConfirmedStoreHoursInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateConfirmedStoreHoursResponse = {
  __typename?: 'UpdateConfirmedStoreHoursResponse';
  confirmations: Maybe<Array<Maybe<ConfirmedStoreHours>>>;
};

export type UpdateConnectRetailerCallbackConfigurationRequestInput = {
  callbackConfiguration?: InputMaybe<ConnectRetailerCallbackConfigurationInput>;
  callbackConfigurationId?: InputMaybe<Scalars['BigInt']>;
  ignoreV1Settings?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateConnectRetailerCallbackConfigurationResponse = {
  __typename?: 'UpdateConnectRetailerCallbackConfigurationResponse';
  callbackConfiguration: Maybe<ConnectRetailerCallbackConfiguration>;
  errorMessage: Maybe<Scalars['String']>;
  success: Maybe<Scalars['Boolean']>;
};

export type UpdateContentPageRequestV2Input = {
  activeVersionId?: InputMaybe<Scalars['BigInt']>;
  authContext?: InputMaybe<AuthorizationContextInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  internalName?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateContentPageResponseV2 = {
  __typename?: 'UpdateContentPageResponseV2';
  contentPage: Maybe<ContentPageV2>;
};

export type UpdateInstacartCollectionRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurations?: InputMaybe<Array<InputMaybe<RetailerCollectionStoreConfigurationInput>>>;
};

export type UpdateInstacartCollectionResponse = {
  __typename?: 'UpdateInstacartCollectionResponse';
  instacartCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type UpdateLaunchOnboardingWarehouseInfoRequestInput = {
  allowAlcohol?: InputMaybe<Scalars['Boolean']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  certifiedDeliveryThreshold?: InputMaybe<Scalars['Float']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  pricingPolicy?: InputMaybe<Scalars['String']>;
  pricingPolicyText?: InputMaybe<Scalars['String']>;
  retailerType?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateLaunchOnboardingWarehouseInfoResponse = {
  __typename?: 'UpdateLaunchOnboardingWarehouseInfoResponse';
  launchOnboarding: Maybe<LaunchOnboarding>;
};

export type UpdateLaunchOnboardingWarehouseLocationInfoRequestInput = {
  address?: InputMaybe<LaunchOnboardingAddressInput>;
  locationCode?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId?: InputMaybe<Scalars['BigInt']>;
  token?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['BigInt']>;
  warehouseLocationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateLaunchOnboardingWarehouseLocationInfoResponse = {
  __typename?: 'UpdateLaunchOnboardingWarehouseLocationInfoResponse';
  launchOnboarding: Maybe<LaunchOnboarding>;
};

export type UpdateLaunchOnboardingWorkflowStateRequestInput = {
  partnerId?: InputMaybe<Scalars['BigInt']>;
  stateAction?: InputMaybe<LaunchOnboardingWorkflowStateAction>;
  token?: InputMaybe<Scalars['String']>;
};

export type UpdateLaunchOnboardingWorkflowStateResponse = {
  __typename?: 'UpdateLaunchOnboardingWorkflowStateResponse';
  launchOnboarding: Maybe<LaunchOnboarding>;
};

export type UpdateLayoutVariantRequestInput = {
  layoutVariant?: InputMaybe<LayoutVariantInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateLayoutVariantResponse = {
  __typename?: 'UpdateLayoutVariantResponse';
  layoutVariant: Maybe<LayoutVariant>;
};

export type UpdateMomentCampaignStatusRequestInput = {
  momentCampaignStatus?: InputMaybe<Array<InputMaybe<MomentCampaignStatusInput>>>;
  momentCampaigns?: InputMaybe<StatusMapInput>;
};

export type UpdateMomentCampaignStatusResponse = {
  __typename?: 'UpdateMomentCampaignStatusResponse';
  campaigns: Maybe<Array<Maybe<RetailerCampaign>>>;
};

export type UpdateMomentRequestInput = {
  campaignSubmissionDeadline?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  momentId?: InputMaybe<Scalars['BigInt']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateMomentResponse = {
  __typename?: 'UpdateMomentResponse';
  moment: Maybe<Moment>;
};

export type UpdateNavigationConfigurationRequestInput = {
  navigationConfiguration?: InputMaybe<NavigationConfigurationInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateNavigationConfigurationResponse = {
  __typename?: 'UpdateNavigationConfigurationResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type UpdateNavigationRequestInput = {
  navigation?: InputMaybe<NavigationInput>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateNavigationResponse = {
  __typename?: 'UpdateNavigationResponse';
  navigation: Maybe<Navigation>;
};

export type UpdateOnboardingBillingAddressRequestInput = {
  billingAddress?: InputMaybe<AddressInput>;
  token?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingBillingAddressResponse = {
  __typename?: 'UpdateOnboardingBillingAddressResponse';
  onboarding: Maybe<Onboarding>;
};

export type UpdateOnboardingRequestInput = {
  contactInfo?: InputMaybe<ContactInput>;
  corporationType?: InputMaybe<Scalars['String']>;
  duns?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Scalars['String']>;
  exclusivity?: InputMaybe<Scalars['String']>;
  onboardingType?: InputMaybe<Scalars['String']>;
  posSystemName?: InputMaybe<Scalars['String']>;
  stateOfIncorporation?: InputMaybe<Scalars['String']>;
  storeCount?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  w9Form?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingResponse = {
  __typename?: 'UpdateOnboardingResponse';
  onboarding: Maybe<Onboarding>;
};

export type UpdateOnboardingWorkflowStateRequestInput = {
  ipAddress?: InputMaybe<Scalars['String']>;
  stateAction?: InputMaybe<OnboardingWorkflowStateAction>;
  token?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingWorkflowStateResponse = {
  __typename?: 'UpdateOnboardingWorkflowStateResponse';
  onboarding: Maybe<Onboarding>;
};

export type UpdateParkingSettingsRequestInput = {
  imageConfigs?: InputMaybe<Array<InputMaybe<ImageConfigInput>>>;
  instruction?: InputMaybe<Scalars['String']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  setDefault?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<PickupType>;
};

export type UpdateParkingSettingsResponse = {
  __typename?: 'UpdateParkingSettingsResponse';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  imageConfigs: Maybe<Array<Maybe<ImageConfig>>>;
  instruction: Maybe<Scalars['String']>;
  retailerLocationConfig: Maybe<RetailerLocationInfo>;
};

export type UpdatePlacementContentRequestInput = {
  adminContext?: InputMaybe<PlacementAdminContextInput>;
  content?: InputMaybe<PlacementContentInput>;
};

export type UpdatePlacementContentResponse = {
  __typename?: 'UpdatePlacementContentResponse';
  content: Maybe<PlacementContent>;
};

export type UpdateRetailerAisleTemplateRequestInput = {
  aisleOrders?: InputMaybe<Array<InputMaybe<AisleOrderInput>>>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  shopperType?: InputMaybe<ShopperType>;
};

export type UpdateRetailerAisleTemplateResponse = {
  __typename?: 'UpdateRetailerAisleTemplateResponse';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  updated: Maybe<Scalars['Boolean']>;
};

export type UpdateRetailerCampaignRequestInput = {
  campaignObjective?: InputMaybe<CampaignObjective>;
  campaignReference?: InputMaybe<CampaignReferenceInput>;
  couponCount?: InputMaybe<Scalars['BigInt']>;
  discoverability?: InputMaybe<Discoverability>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<CampaignFilterInput>>>;
  lookbackWindowDays?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  narrowSearchTerms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  offers?: InputMaybe<Array<InputMaybe<InstacartCustomersCommerceTypesV1SlimOfferInput>>>;
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  segmentId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  targeting?: InputMaybe<Array<InputMaybe<Targeting>>>;
  targetingRules?: InputMaybe<Array<InputMaybe<TargetingRulesInput>>>;
};

export type UpdateRetailerCampaignTreatmentParamsInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  treatmentDetails?: InputMaybe<Array<InputMaybe<RetailerCampaignTreatmentDetailsInput>>>;
  treatmentType?: InputMaybe<RetailerCampaignTreatmentType>;
};

export type UpdateRetailerCampaignTreatmentsRequestInput = {
  retailerCampaignId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  treatments?: InputMaybe<Array<InputMaybe<UpdateRetailerCampaignTreatmentParamsInput>>>;
};

export type UpdateRetailerCampaignTreatmentsResponse = {
  __typename?: 'UpdateRetailerCampaignTreatmentsResponse';
  treatments: Maybe<Array<Maybe<RetailerCampaignTreatment>>>;
};

export type UpdateRetailerCollectionInvfEnrollmentRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  isInventoryFileEnrolled?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateRetailerCollectionInvfEnrollmentResponse = {
  __typename?: 'UpdateRetailerCollectionInvfEnrollmentResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type UpdateRetailerCollectionPinnedStatusRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateRetailerCollectionPinnedStatusResponse = {
  __typename?: 'UpdateRetailerCollectionPinnedStatusResponse';
  retailerCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type UpdateRetailerCollectionRequestInput = {
  contentType?: InputMaybe<RetailerCollectionContentType>;
  defaultSortOrder?: InputMaybe<RetailerCollectionSortOrder>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filters?: InputMaybe<Array<InputMaybe<RetailerCollectionFilterInput>>>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  position?: InputMaybe<Scalars['Int']>;
  productsToAdd?: InputMaybe<Array<InputMaybe<RetailerCollectionProductPayloadInput>>>;
  productsToDelete?: InputMaybe<Array<InputMaybe<RetailerCollectionProductPayloadInput>>>;
  resetAllProducts?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<RetailerCollectionStatus>;
  storeConfigurations?: InputMaybe<Array<InputMaybe<RetailerCollectionStoreConfigurationInput>>>;
  surfaces?: InputMaybe<Array<InputMaybe<RetailerCollectionSurface>>>;
  tileImageUrl?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<RetailerCollectionTranslationInput>>>;
};

export type UpdateRetailerCollectionResponse = {
  __typename?: 'UpdateRetailerCollectionResponse';
  retailerCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type UpdateRetailerCollectionStatusRequestInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<RetailerCollectionStatus>;
};

export type UpdateRetailerCollectionStatusResponse = {
  __typename?: 'UpdateRetailerCollectionStatusResponse';
  retailerCollection: Maybe<RetailerCollection>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type UpdateRetailerProductVisibilitiesRequestInput = {
  entityId?: InputMaybe<Array<InputMaybe<EntityIdInput>>>;
  inAssortment?: InputMaybe<Scalars['Boolean']>;
  outOfAssortmentEndAt?: InputMaybe<Scalars['DateTime']>;
  outOfAssortmentStartAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateRetailerProductVisibilitiesResponse = {
  __typename?: 'UpdateRetailerProductVisibilitiesResponse';
  entityId: Maybe<Array<Maybe<EntityId>>>;
};

export type UpdateRoleInput = {
  /** A description of the role */
  description?: InputMaybe<Scalars['String']>;
  /** The role ID */
  id: Scalars['ID'];
  /** The name of the role */
  name?: InputMaybe<Scalars['String']>;
  /** The permissions associated with this role */
  permissions?: InputMaybe<Array<PermissionInput>>;
};

export type UpdateShopperOrderItemRequestInput = {
  adminRequestContext?: InputMaybe<AdminRequestContextInput>;
  deliveredCount?: InputMaybe<Scalars['BigInt']>;
  deliveredWeight?: InputMaybe<Scalars['Float']>;
  lineNum?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderItemId?: InputMaybe<Scalars['BigInt']>;
  requestContext?: InputMaybe<InstacartCustomersSharedV1RequestContextInput>;
  rrc?: InputMaybe<Scalars['String']>;
  scans?: InputMaybe<Array<InputMaybe<ShopperOrderItemScanInput>>>;
  shopperId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['String']>;
  substituteId?: InputMaybe<Scalars['BigInt']>;
  upc?: InputMaybe<Scalars['String']>;
};

export type UpdateShopperOrderItemResponse = {
  __typename?: 'UpdateShopperOrderItemResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type UpdateStagingSettingsRequestInput = {
  freezer?: InputMaybe<StringsInput>;
  fridge?: InputMaybe<StringsInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  instruction?: InputMaybe<Scalars['String']>;
  retailerLocationId?: InputMaybe<Scalars['BigInt']>;
  shelf?: InputMaybe<StringsInput>;
};

export type UpdateStagingSettingsResponse = {
  __typename?: 'UpdateStagingSettingsResponse';
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  freezer: Maybe<Array<Maybe<Scalars['String']>>>;
  fridge: Maybe<Array<Maybe<Scalars['String']>>>;
  imageUrl: Maybe<Scalars['String']>;
  instruction: Maybe<Scalars['String']>;
  retailerLocationConfig: Maybe<RetailerLocationInfo>;
  shelf: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateStoreConfigurationDraftRequestInput = {
  domainConfigurations?: InputMaybe<Scalars['JSONObject']>;
  draftId?: InputMaybe<Scalars['String']>;
  draftName?: InputMaybe<Scalars['String']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateStoreConfigurationDraftResponse = {
  __typename?: 'UpdateStoreConfigurationDraftResponse';
  domainConfigurations: Maybe<DomainConfiguration>;
  draftId: Maybe<Scalars['String']>;
  draftName: Maybe<Scalars['String']>;
  errorMessage: Maybe<Scalars['String']>;
  errorReason: Maybe<ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type UpdateStoreHoursRequestInput = {
  author?: InputMaybe<Scalars['String']>;
  clearOverrideHoursByType?: InputMaybe<Array<InputMaybe<RetailerServiceType>>>;
  clearOverrideHoursCriteria?: InputMaybe<Array<InputMaybe<ClearOverrideHoursCriteriaInput>>>;
  confirmations?: InputMaybe<Array<InputMaybe<ConfirmedStoreHoursInput>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  updateRegularHours?: InputMaybe<Array<InputMaybe<RegularHoursInput>>>;
  updateSource?: InputMaybe<Scalars['String']>;
  upsertOverrideHours?: InputMaybe<Array<InputMaybe<OverrideHoursInput>>>;
};

export type UpdateStoreHoursResponse = {
  __typename?: 'UpdateStoreHoursResponse';
  taskId: Maybe<Scalars['BigInt']>;
};

export type UpdateThemePageRequestInput = {
  themePage?: InputMaybe<ThemePageInput>;
};

export type UpdateThemePageResponse = {
  __typename?: 'UpdateThemePageResponse';
  themePage: Maybe<ThemePage>;
};

export type UpsertNavigationNodeRequestInput = {
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  alwaysShow?: InputMaybe<Scalars['Boolean']>;
  contentType?: InputMaybe<NavigationNodeContentType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['BigInt']>;
  retailerCollectionId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<CollectionStatus>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  tileImageUrl?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<TranslationInput>>>;
};

export type UpsertNavigationNodeResponse = {
  __typename?: 'UpsertNavigationNodeResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type UpsertRetailerConfigRequestInput = {
  config?: InputMaybe<RetailerConfigInput>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type UpsertRetailerConfigResponse = {
  __typename?: 'UpsertRetailerConfigResponse';
  config: Maybe<RetailerConfig>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type User = {
  __typename?: 'User';
  active: Maybe<Scalars['Boolean']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  guest: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['BigInt']>;
  lastName: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  roles: Maybe<Array<Maybe<Scalars['String']>>>;
  userId: Maybe<Scalars['String']>;
  userType: Maybe<Scalars['String']>;
  vip: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  userId?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['String']>;
};

export type UserInteractionLimitInput = {
  /** number of seconds to wait before resetting the counter */
  countCooldownSec?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** maximum number of dismiss events */
  maxDismissedCount?: InputMaybe<Scalars['Int']>;
  /** maximum number of click events */
  maxEngagementsCount?: InputMaybe<Scalars['Int']>;
  /** maximum number of view events */
  maxImpressionsCount?: InputMaybe<Scalars['Int']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
};

/** Autogenerated return type of UserInteractionLimitVisibilityConditionCreate */
export type UserInteractionLimitVisibilityConditionCreatePayload = {
  __typename?: 'UserInteractionLimitVisibilityConditionCreatePayload';
  /** The newly created UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityCondition: ContentManagementVisibilityConditionUserInteractionLimit;
};

/** Autogenerated return type of UserInteractionLimitVisibilityConditionUpdate */
export type UserInteractionLimitVisibilityConditionUpdatePayload = {
  __typename?: 'UserInteractionLimitVisibilityConditionUpdatePayload';
  /** The newly updated UserInteractionLimit visibility condition */
  userInteractionLimitVisibilityCondition: ContentManagementVisibilityConditionUserInteractionLimit;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  active: Maybe<Scalars['Boolean']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  guest: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['BigInt']>;
  lastName: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  ordersCount: Maybe<Scalars['BigInt']>;
  phone: Maybe<Scalars['String']>;
  roles: Maybe<Array<Maybe<Scalars['String']>>>;
  vip: Maybe<Scalars['Boolean']>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  key: Maybe<UserSettingKey>;
  value: Maybe<Scalars['String']>;
  version: Maybe<Scalars['BigInt']>;
};

export type UserSettingInput = {
  key?: InputMaybe<UserSettingKeyInput>;
  value?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['BigInt']>;
};

export type UserSettingKey = {
  __typename?: 'UserSettingKey';
  app: Maybe<Scalars['String']>;
  domain: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type UserSettingKeyInput = {
  app?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UtmParametersInput = {
  /** coupon_code */
  couponCode?: InputMaybe<Scalars['String']>;
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** utm_campaign */
  utmCampaign?: InputMaybe<Scalars['String']>;
  /** utm_content */
  utmContent?: InputMaybe<Scalars['String']>;
  /** utm_medium */
  utmMedium?: InputMaybe<Scalars['String']>;
  /** utm_source */
  utmSource?: InputMaybe<Scalars['String']>;
  /** utm_term */
  utmTerm?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UtmParametersVisibilityConditionCreate */
export type UtmParametersVisibilityConditionCreatePayload = {
  __typename?: 'UtmParametersVisibilityConditionCreatePayload';
  /** The newly created UtmParameters visibility condition */
  utmParametersVisibilityCondition: ContentManagementVisibilityConditionUtmParameters;
};

/** Autogenerated return type of UtmParametersVisibilityConditionUpdate */
export type UtmParametersVisibilityConditionUpdatePayload = {
  __typename?: 'UtmParametersVisibilityConditionUpdatePayload';
  /** The newly updated UtmParameters visibility condition */
  utmParametersVisibilityCondition: ContentManagementVisibilityConditionUtmParameters;
};

export type ValidateRetailerCollectionsCsvRequestInput = {
  bulkUploadType?: InputMaybe<RetailerCollectionBulkUploadType>;
  fileName?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type ValidateRetailerCollectionsCsvResponse = {
  __typename?: 'ValidateRetailerCollectionsCsvResponse';
  errors: Maybe<Array<Maybe<RetailerCollectionsCsvError>>>;
  reviewSummaries: Maybe<Array<Maybe<RetailerCollectionsCsvSummary>>>;
  valid: Maybe<Scalars['Boolean']>;
};

export type ValueBasedOverrideRequestInput = {
  attributeName?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<ClientInput>;
  createdWhy?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<EntityIdInput>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  overrideType?: InputMaybe<OverrideType>;
  overrideValue?: InputMaybe<Scalars['String']>;
  sourceType?: InputMaybe<Scalars['String']>;
  sourceValue?: InputMaybe<Scalars['String']>;
  ticketUrl?: InputMaybe<Scalars['String']>;
};

export type ValueBasedOverrideResponse = {
  __typename?: 'ValueBasedOverrideResponse';
  attributeName: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdByJson: Maybe<Scalars['String']>;
  createdWhy: Maybe<Scalars['String']>;
  entityId: Maybe<EntityId>;
  expiryDate: Maybe<Scalars['DateTime']>;
  overrideType: Maybe<OverrideType>;
  overrideValue: Maybe<Scalars['String']>;
  rowId: Maybe<Scalars['BigInt']>;
  sourceType: Maybe<Scalars['String']>;
  sourceValue: Maybe<Scalars['String']>;
  ticketUrl: Maybe<Scalars['String']>;
};

export enum VerticalType {
  AlcoholHours = 'ALCOHOL_HOURS',
  DeliveryHours = 'DELIVERY_HOURS',
  IssHours = 'ISS_HOURS',
  PickupHours = 'PICKUP_HOURS',
  PickupOverrideHours = 'PICKUP_OVERRIDE_HOURS'
}

export type VirtualRetailer = {
  __typename?: 'VirtualRetailer';
  coreRetailerId: Maybe<Scalars['BigInt']>;
  virtualRetailerId: Maybe<Scalars['BigInt']>;
};

/** Union of the Types of Visibility Conditions which may be returned */
export type VisibilityCondition = ContentManagementVisibilityConditionCountryIds | ContentManagementVisibilityConditionDayOfWeek | ContentManagementVisibilityConditionExperimentVariant | ContentManagementVisibilityConditionExpressMembership | ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout | ContentManagementVisibilityConditionMatchesAnyRetailerId | ContentManagementVisibilityConditionMinimumPlatformVersion | ContentManagementVisibilityConditionNewVerticalUserTargeting | ContentManagementVisibilityConditionRetailerAvailability | ContentManagementVisibilityConditionShopped | ContentManagementVisibilityConditionShoppedAtRetailer | ContentManagementVisibilityConditionUnknownVisibilityCondition | ContentManagementVisibilityConditionUserInteractionLimit | ContentManagementVisibilityConditionUtmParameters | ContentManagementVisibilityConditionWhitelistedPostalCodes | ContentManagementVisibilityConditionWhitelistedZones;

/** Autogenerated return type of VisibilityConditionDelete */
export type VisibilityConditionDeletePayload = {
  __typename?: 'VisibilityConditionDeletePayload';
  /** Whether deleting the visibility condition succeeded or not */
  success: Scalars['Boolean'];
};

export type VisibilityConditionStruct = {
  __typename?: 'VisibilityConditionStruct';
  data: Maybe<Scalars['JSONObject']>;
  typeName: Maybe<Scalars['String']>;
};

export type VisibilityConditionStructInput = {
  data?: InputMaybe<Scalars['JSONObject']>;
  typeName?: InputMaybe<Scalars['String']>;
};

export type WhitelistedPostalCodesInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of postal codes */
  postalCodes: Array<Scalars['String']>;
};

/** Autogenerated return type of WhitelistedPostalCodesVisibilityConditionCreate */
export type WhitelistedPostalCodesVisibilityConditionCreatePayload = {
  __typename?: 'WhitelistedPostalCodesVisibilityConditionCreatePayload';
  /** The newly created whitelisted_postal_codes visibility condition */
  whitelistedPostalCodesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedPostalCodes;
};

/** Autogenerated return type of WhitelistedPostalCodesVisibilityConditionUpdate */
export type WhitelistedPostalCodesVisibilityConditionUpdatePayload = {
  __typename?: 'WhitelistedPostalCodesVisibilityConditionUpdatePayload';
  /** The newly created whitelisted_postal_codes visibility condition */
  whitelistedPostalCodesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedPostalCodes;
};

export type WhitelistedZonesInput = {
  /** A unique identifier of the visibility condition */
  id?: InputMaybe<Scalars['ID']>;
  /** A unique identifier of the placement associated with the visibility condition */
  placementId: Scalars['ID'];
  /** List of zones ids */
  zones: Array<Scalars['String']>;
};

/** Autogenerated return type of WhitelistedZonesVisibilityConditionCreate */
export type WhitelistedZonesVisibilityConditionCreatePayload = {
  __typename?: 'WhitelistedZonesVisibilityConditionCreatePayload';
  /** The newly created whitelisted_zones visibility condition */
  whitelistedZonesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedZones;
};

/** Autogenerated return type of WhitelistedZonesVisibilityConditionUpdate */
export type WhitelistedZonesVisibilityConditionUpdatePayload = {
  __typename?: 'WhitelistedZonesVisibilityConditionUpdatePayload';
  /** The updated WhitelistedZones visibility condition */
  whitelistedZonesVisibilityCondition: ContentManagementVisibilityConditionWhitelistedZones;
};

export type Widget = {
  __typename?: 'Widget';
  dataQuery: Maybe<DataQuery>;
  id: Maybe<Scalars['BigInt']>;
  variant: Maybe<WidgetVariant>;
};

export type WidgetInput = {
  dataQuery?: InputMaybe<DataQueryInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  variant?: InputMaybe<WidgetVariant>;
};

export enum WidgetVariant {
  NavigationList = 'NAVIGATION_LIST',
  OrderHistory = 'ORDER_HISTORY',
  QuickLinks = 'QUICK_LINKS',
  ServiceOptionSelector = 'SERVICE_OPTION_SELECTOR',
  UnspecifiedWidget = 'UNSPECIFIED_WIDGET',
  WaysToShop = 'WAYS_TO_SHOP'
}

/** Autogenerated return type of WizardProgressUpdate */
export type WizardProgressUpdatePayload = {
  __typename?: 'WizardProgressUpdatePayload';
  /** A status which is true if no errors were encountered while performing the update */
  success: Scalars['True'];
};

export enum WizardStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS'
}

export enum WizardTypeEnum {
  PickupSettingsOnboarding = 'PICKUP_SETTINGS_ONBOARDING'
}

export type WizardsWizardProgress = {
  __typename?: 'WizardsWizardProgress';
  id: Scalars['ID'];
  status: WizardStatusEnum;
  wizardType: WizardTypeEnum;
};

export enum WorkflowStatus {
  WorkflowStatusCompleted = 'WORKFLOW_STATUS_COMPLETED',
  WorkflowStatusDeactivate = 'WORKFLOW_STATUS_DEACTIVATE',
  WorkflowStatusExpired = 'WORKFLOW_STATUS_EXPIRED',
  WorkflowStatusFailed = 'WORKFLOW_STATUS_FAILED',
  WorkflowStatusGetData = 'WORKFLOW_STATUS_GET_DATA',
  WorkflowStatusInProgress = 'WORKFLOW_STATUS_IN_PROGRESS',
  WorkflowStatusReadyToDelete = 'WORKFLOW_STATUS_READY_TO_DELETE',
  WorkflowStatusUnspecified = 'WORKFLOW_STATUS_UNSPECIFIED'
}

export type Google_Protobuf_Duration = {
  __typename?: 'google_protobuf_Duration';
  nanos: Maybe<Scalars['Int']>;
  seconds: Maybe<Scalars['BigInt']>;
};

export type Google_Protobuf_Duration_Input = {
  nanos?: InputMaybe<Scalars['Int']>;
  seconds?: InputMaybe<Scalars['BigInt']>;
};

/** Represents an amount of money with its currency type. */
export type Google_Type_Money = {
  __typename?: 'google_type_Money';
  /** The 3-letter currency code defined in ISO 4217. */
  currencyCode: Maybe<Scalars['String']>;
  /**
   * Number of nano (10^-9) units of the amount.
   * The value must be between -999,999,999 and +999,999,999 inclusive.
   * If `units` is positive, `nanos` must be positive or zero.
   * If `units` is zero, `nanos` can be positive, zero, or negative.
   * If `units` is negative, `nanos` must be negative or zero.
   * For example $-1.75 is represented as `units`=-1 and `nanos`=-750,000,000.
   */
  nanos: Maybe<Scalars['Int']>;
  /**
   * The whole units of the amount.
   * For example if `currencyCode` is `"USD"`, then 1 unit is one US dollar.
   */
  units: Maybe<Scalars['BigInt']>;
};

/**
 * Contextual scope restrictions that are handled first-class.
 *
 * MOST scoping should come from the targeting rule itself. The ones here are
 * special and use-case specific.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction = {
  __typename?: 'instacart_ads_platform_taas_v1_ContextRestriction';
  global: Maybe<Scalars['instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext']>;
  retailer: Maybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext>;
};

/**
 * Contextual scope restrictions that are handled first-class.
 *
 * MOST scoping should come from the targeting rule itself. The ones here are
 * special and use-case specific.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input = {
  global?: InputMaybe<Scalars['instacart_ads_platform_taas_v1_ContextRestriction_GlobalContext_Input']>;
  retailer?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext_Input>;
};

/**
 * Controls a particular segment to only be valid at a particular
 * retailer/whitelabel combination. For Marketplace, use whitelabel_id = 1.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext = {
  __typename?: 'instacart_ads_platform_taas_v1_ContextRestriction_RetailerContext';
  retailerId: Maybe<Scalars['BigInt']>;
  whitelabelId: Maybe<Scalars['BigInt']>;
};

/**
 * Controls a particular segment to only be valid at a particular
 * retailer/whitelabel combination. For Marketplace, use whitelabel_id = 1.
 */
export type Instacart_Ads_Platform_Taas_V1_ContextRestriction_RetailerContext_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  whitelabelId?: InputMaybe<Scalars['BigInt']>;
};

/** Creates a new segment with the given definition. */
export type Instacart_Ads_Platform_Taas_V1_CreateSegmentRequest_Input = {
  /**
   * Specific context in which this segment is valid.
   *
   * NOT editable after the segment is created!
   */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /** Definition of the new segment. */
  definition?: InputMaybe<Instacart_Ads_Targeting_V1_TargetingRule_Input>;
  /** Optional description of the segment. */
  description?: InputMaybe<Scalars['String']>;
  /** Optional name of the segment. */
  name?: InputMaybe<Scalars['String']>;
  /** Data to be returned from TaaS when this segment matches. */
  payload?: InputMaybe<Scalars['GoogleProtobufAnyScalar']>;
  /** Scope in which to create the new segment. */
  universe?: InputMaybe<Instacart_Ads_Platform_V1_Universe>;
};

export type Instacart_Ads_Platform_Taas_V1_DeleteSegmentRequest_Input = {
  /** This field is REQUIRED. */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /**
   * The ID of the segment to be deleted. If the segment does not exist, or if
   * the version number in this field does not match the actual most-recent
   * version number for the segment, then the request will fail.
   */
  id?: InputMaybe<Instacart_Ads_Platform_Taas_V1_SegmentId_Input>;
};

export type Instacart_Ads_Platform_Taas_V1_GetAllSegmentDefinitionsRequest_Input = {
  /**
   * Only segments matching this context will be returned.
   *
   * This field is REQUIRED. It is not valid to request all segments for an
   * entire universe via this interface.
   */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  universe?: InputMaybe<Instacart_Ads_Platform_V1_Universe>;
};

export type Instacart_Ads_Platform_Taas_V1_GetSegmentDefinitionRequest_Input = {
  /** This field is REQUIRED. */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /**
   * The segment to look up.
   *
   * Must contain an ID, but the version_number is optional. If provided, the
   * response will be the definition for that exact version, which may or may
   * not be the latest (currently live) version. If not provided, then the
   * response will be for the latest (currently live) version of that segment.
   */
  id?: InputMaybe<Instacart_Ads_Platform_Taas_V1_SegmentId_Input>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentId = {
  __typename?: 'instacart_ads_platform_taas_v1_SegmentId';
  /** Universe to which the segment belongs. */
  universe: Maybe<Instacart_Ads_Platform_V1_Universe>;
  /**
   * Unique identifier for a segment. This is guaranteed to be unique among all
   * segments ever created.
   */
  uuid: Maybe<Scalars['String']>;
  /** Counts the number of revisions that have been made (via UpdateSegment). */
  versionNumber: Maybe<Scalars['Int']>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentId_Input = {
  /** Universe to which the segment belongs. */
  universe?: InputMaybe<Instacart_Ads_Platform_V1_Universe>;
  /**
   * Unique identifier for a segment. This is guaranteed to be unique among all
   * segments ever created.
   */
  uuid?: InputMaybe<Scalars['String']>;
  /** Counts the number of revisions that have been made (via UpdateSegment). */
  versionNumber?: InputMaybe<Scalars['Int']>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentState = {
  __typename?: 'instacart_ads_platform_taas_v1_SegmentState';
  /**
   * Specific context in which this segment is valid.
   *
   * NOT editable after the segment is created!
   */
  context: Maybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction>;
  /**
   * The definition of the segment, after the operation.
   *
   * If this field is unset (null), then the segment has been deleted; note
   * that this is semantically very different from an extant definition with no
   * fields filled.
   */
  definition: Maybe<Instacart_Ads_Targeting_V1_TargetingRule>;
  /** Optional description of the segment. */
  description: Maybe<Scalars['String']>;
  /** The ID of the segment, with the version number after the operation. */
  id: Maybe<Instacart_Ads_Platform_Taas_V1_SegmentId>;
  /** Optional name of the segment. */
  name: Maybe<Scalars['String']>;
  /** Data to be returned from TaaS when this segment matches. */
  payload: Maybe<Scalars['GoogleProtobufAnyScalar']>;
  /** Date-Time Segment was last updated */
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type Instacart_Ads_Platform_Taas_V1_SegmentStates = {
  __typename?: 'instacart_ads_platform_taas_v1_SegmentStates';
  segments: Maybe<Array<Maybe<Instacart_Ads_Platform_Taas_V1_SegmentState>>>;
};

/**
 * This request has OVERWRITE semantics - any existing definition for the segment
 * or its payload will be dropped entirely. Merge semantics, if necessary, are
 * left to the client - the client can get the current definition with
 * GetSegmentDefinition, then assemble what they want the final state to be.
 */
export type Instacart_Ads_Platform_Taas_V1_UpdateSegmentRequest_Input = {
  /** This field is REQUIRED. */
  context?: InputMaybe<Instacart_Ads_Platform_Taas_V1_ContextRestriction_Input>;
  /** The new definition of the segment. */
  definition?: InputMaybe<Instacart_Ads_Targeting_V1_TargetingRule_Input>;
  /** Optional description of the segment. */
  description?: InputMaybe<Scalars['String']>;
  /**
   * The ID of the segment to be modified. If the segment does not exist, or if
   * the version number in this field does not match the actual most-recent
   * version number for the segment, then the request will fail.
   */
  id?: InputMaybe<Instacart_Ads_Platform_Taas_V1_SegmentId_Input>;
  /** Optional name of the segment. */
  name?: InputMaybe<Scalars['String']>;
  /** New data to be returned from TaaS when this segment matches. */
  payload?: InputMaybe<Scalars['GoogleProtobufAnyScalar']>;
};

/**
 * A set of distinct tenants of Ads Platform services. All requests should be
 * scoped to a particular universe, which may imply additional restrictions on
 * available functionality.
 */
export enum Instacart_Ads_Platform_V1_Universe {
  ScopeAds = 'SCOPE_ADS',
  ScopeCmdMarketplace = 'SCOPE_CMD_MARKETPLACE',
  ScopeCmdSfx = 'SCOPE_CMD_SFX',
  ScopeInternalTesting = 'SCOPE_INTERNAL_TESTING',
  ScopeRetailerPromotion = 'SCOPE_RETAILER_PROMOTION',
  /** Default/invalid */
  ScopeUnknown = 'SCOPE_UNKNOWN'
}

export enum Instacart_Ads_Targeting_V1_AlcoholBuyerLicense {
  /** off_premise implies that they sell alcohol for consumption off premise (eg. Bevmo) */
  AlcoholOffPremises = 'ALCOHOL_OFF_PREMISES',
  /**
   * on_and_off_premise implies that they sell alcohol for consumption both on and off premises (eg. Brewery)
   * This is a distinct license and NOT equivalent to having both an on_premise and an off_premise license.
   */
  AlcoholOnAndOffPremises = 'ALCOHOL_ON_AND_OFF_PREMISES',
  /** on_premise implies that they only sell alcohol on premise (eg. restaurant) */
  AlcoholOnPremises = 'ALCOHOL_ON_PREMISES',
  BuyerLicenseUnknown = 'BUYER_LICENSE_UNKNOWN'
}

export type Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition = {
  __typename?: 'instacart_ads_targeting_v1_AlcoholBuyerLicenseCondition';
  /**
   * This represents the Alcohol license for a user.
   * The license is relevent only for retailers at the moment.
   * This is an OR, the licenses list is a form of A || B || C
   * For example, ALCOHOL_ON_PREMISES OR ALCOHOL_OFF_PREMISES can be combined for both cases
   * Like (eg. brewery)
   */
  licenses: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicense>>>;
};

export type Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition_Input = {
  /**
   * This represents the Alcohol license for a user.
   * The license is relevent only for retailers at the moment.
   * This is an OR, the licenses list is a form of A || B || C
   * For example, ALCOHOL_ON_PREMISES OR ALCOHOL_OFF_PREMISES can be combined for both cases
   * Like (eg. brewery)
   */
  licenses?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicense>>>;
};

export type Instacart_Ads_Targeting_V1_Audience = {
  __typename?: 'instacart_ads_targeting_v1_Audience';
  audienceId: Maybe<Scalars['String']>;
};

/** Condition for predefined audiences generated from ML models. */
export type Instacart_Ads_Targeting_V1_AudienceCondition = {
  __typename?: 'instacart_ads_targeting_v1_AudienceCondition';
  /** Only target users that are assigned any of the audiences. */
  audiences: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_Audience>>>;
};

/** Condition for predefined audiences generated from ML models. */
export type Instacart_Ads_Targeting_V1_AudienceCondition_Input = {
  /** Only target users that are assigned any of the audiences. */
  audiences?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_Audience_Input>>>;
};

export type Instacart_Ads_Targeting_V1_Audience_Input = {
  audienceId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Ads_Targeting_V1_BoolCondition = {
  __typename?: 'instacart_ads_targeting_v1_BoolCondition';
  retailerDetails: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
};

export type Instacart_Ads_Targeting_V1_BoolCondition_Input = {
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
};

/**
 * Condition that requires a user to have bought a product from one of the
 * given brands.
 */
export type Instacart_Ads_Targeting_V1_BrandIdCondition = {
  __typename?: 'instacart_ads_targeting_v1_BrandIdCondition';
  /** Brands in which purchases are considered. */
  brandIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /**
   * A single BrandIdCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two BrandIdCondition, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow: Maybe<Google_Protobuf_Duration>;
};

/**
 * Condition that requires a user to have bought a product from one of the
 * given brands.
 */
export type Instacart_Ads_Targeting_V1_BrandIdCondition_Input = {
  /** Brands in which purchases are considered. */
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /**
   * A single BrandIdCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two BrandIdCondition, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: InputMaybe<Google_Protobuf_Duration_Input>;
};

/**
 * Condition that requires a user to have bought a product from
 * one of the given categories.
 */
export type Instacart_Ads_Targeting_V1_CategoryCondition = {
  __typename?: 'instacart_ads_targeting_v1_CategoryCondition';
  /** Product categories in which purchases are considered. */
  categories: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single CategoryCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two CategoryConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow: Maybe<Google_Protobuf_Duration>;
};

/**
 * Condition that requires a user to have bought a product from
 * one of the given categories.
 */
export type Instacart_Ads_Targeting_V1_CategoryCondition_Input = {
  /** Product categories in which purchases are considered. */
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single CategoryCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * It is invalid not to specify a time window. Not all time window values
   * will be valid; writers should use the targeting dictionary API (coming
   * soon) to find valid values.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two CategoryConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: InputMaybe<Google_Protobuf_Duration_Input>;
};

export enum Instacart_Ads_Targeting_V1_ComparisonOperator {
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  Unknown = 'UNKNOWN'
}

export enum Instacart_Ads_Targeting_V1_CrossRetailerReducer {
  Max = 'MAX',
  Min = 'MIN',
  None = 'NONE',
  Sum = 'SUM'
}

export type Instacart_Ads_Targeting_V1_FloatCondition = {
  __typename?: 'instacart_ads_targeting_v1_FloatCondition';
  crossRetailerReducer: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  threshold: Maybe<Scalars['Float']>;
};

export type Instacart_Ads_Targeting_V1_FloatCondition_Input = {
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  threshold?: InputMaybe<Scalars['Float']>;
};

/** For geo targeting. */
export type Instacart_Ads_Targeting_V1_Geo = {
  __typename?: 'instacart_ads_targeting_v1_Geo';
  /** Currently ignored. All Geo matching elements must specify a state. */
  countryCode: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  stateCode: Maybe<Scalars['String']>;
};

/** Condition for geo check. */
export type Instacart_Ads_Targeting_V1_GeoCondition = {
  __typename?: 'instacart_ads_targeting_v1_GeoCondition';
  /** Only target users in any of the regions. */
  geo: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_Geo>>>;
};

/** Condition for geo check. */
export type Instacart_Ads_Targeting_V1_GeoCondition_Input = {
  /** Only target users in any of the regions. */
  geo?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_Geo_Input>>>;
};

/** For geo targeting. */
export type Instacart_Ads_Targeting_V1_Geo_Input = {
  /** Currently ignored. All Geo matching elements must specify a state. */
  countryCode?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
};

export type Instacart_Ads_Targeting_V1_IntCondition = {
  __typename?: 'instacart_ads_targeting_v1_IntCondition';
  crossRetailerReducer: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  threshold: Maybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_IntCondition_Input = {
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  threshold?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_Region = {
  __typename?: 'instacart_ads_targeting_v1_Region';
  regionCode: Maybe<Instacart_Ads_Targeting_V1_RegionCode>;
};

export enum Instacart_Ads_Targeting_V1_RegionCode {
  AlcoholEast = 'ALCOHOL_EAST',
  AlcoholEastNorthCentral = 'ALCOHOL_EAST_NORTH_CENTRAL',
  /** alcohol advertiser region codes */
  AlcoholWest = 'ALCOHOL_WEST',
  AlcoholWestNorthCentral = 'ALCOHOL_WEST_NORTH_CENTRAL',
  CcConsolidated = 'CC_CONSOLIDATED',
  CcHeartland = 'CC_HEARTLAND',
  CcLiberty = 'CC_LIBERTY',
  CcReyes = 'CC_REYES',
  CcSouthwest = 'CC_SOUTHWEST',
  /** Coca Cola region codes */
  CcSwire = 'CC_SWIRE',
  DefaultEastNorthCentral = 'DEFAULT_EAST_NORTH_CENTRAL',
  DefaultMiddleAtlantic = 'DEFAULT_MIDDLE_ATLANTIC',
  DefaultMidwest = 'DEFAULT_MIDWEST',
  DefaultMountain = 'DEFAULT_MOUNTAIN',
  DefaultNewEngland = 'DEFAULT_NEW_ENGLAND',
  /** default region codes */
  DefaultPacific = 'DEFAULT_PACIFIC',
  DefaultSouthAtlantic = 'DEFAULT_SOUTH_ATLANTIC',
  DefaultSouthCentral = 'DEFAULT_SOUTH_CENTRAL',
  DefaultWestNorthCentral = 'DEFAULT_WEST_NORTH_CENTRAL',
  UnknownRegion = 'UNKNOWN_REGION'
}

export type Instacart_Ads_Targeting_V1_RegionCondition = {
  __typename?: 'instacart_ads_targeting_v1_RegionCondition';
  /** Create by mistake. Use regions instead. */
  region: Maybe<Instacart_Ads_Targeting_V1_Region>;
  regions: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_Region>>>;
};

export type Instacart_Ads_Targeting_V1_RegionCondition_Input = {
  /** Create by mistake. Use regions instead. */
  region?: InputMaybe<Instacart_Ads_Targeting_V1_Region_Input>;
  regions?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_Region_Input>>>;
};

export type Instacart_Ads_Targeting_V1_Region_Input = {
  regionCode?: InputMaybe<Instacart_Ads_Targeting_V1_RegionCode>;
};

export type Instacart_Ads_Targeting_V1_RetailerDetails = {
  __typename?: 'instacart_ads_targeting_v1_RetailerDetails';
  orderSource: Maybe<Scalars['String']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context. This allows one rule to be reused across contexts, but
   * be evaluated with context-specific data.
   */
  retailerFromContext: Maybe<Scalars['google_protobuf_Empty']>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_RetailerDetails_Input = {
  orderSource?: InputMaybe<Scalars['String']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context. This allows one rule to be reused across contexts, but
   * be evaluated with context-specific data.
   */
  retailerFromContext?: InputMaybe<Scalars['google_protobuf_Empty_Input']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export enum Instacart_Ads_Targeting_V1_SetComparisonOperator {
  /** Elements must exactly match between target and feature sets. */
  EqualElements = 'EQUAL_ELEMENTS',
  /** At least one element is common between target and feature sets. */
  NonEmptyIntersection = 'NON_EMPTY_INTERSECTION',
  UnknownSetOperator = 'UNKNOWN_SET_OPERATOR'
}

export type Instacart_Ads_Targeting_V1_StringCondition = {
  __typename?: 'instacart_ads_targeting_v1_StringCondition';
  isRegex: Maybe<Scalars['Boolean']>;
  retailerDetails: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  /** For matching against a single target string. */
  targetValue: Maybe<Scalars['String']>;
  /** For matching against any of multiple target strings. */
  targetValues: Maybe<Instacart_Ads_Targeting_V1_StringTargetValues>;
};

export type Instacart_Ads_Targeting_V1_StringCondition_Input = {
  isRegex?: InputMaybe<Scalars['Boolean']>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  /** For matching against a single target string. */
  targetValue?: InputMaybe<Scalars['String']>;
  /** For matching against any of multiple target strings. */
  targetValues?: InputMaybe<Instacart_Ads_Targeting_V1_StringTargetValues_Input>;
};

/** This condition compares two lists of strings with one of the available operators */
export type Instacart_Ads_Targeting_V1_StringListCondition = {
  __typename?: 'instacart_ads_targeting_v1_StringListCondition';
  /** Operator defaults to NON_EMPTY_INTERSECTION for backward compatibility */
  operator: Maybe<Instacart_Ads_Targeting_V1_SetComparisonOperator>;
  retailerDetails: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  targetValues: Maybe<Scalars['JSON']>;
};

/** This condition compares two lists of strings with one of the available operators */
export type Instacart_Ads_Targeting_V1_StringListCondition_Input = {
  /** Operator defaults to NON_EMPTY_INTERSECTION for backward compatibility */
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_SetComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  targetValues?: InputMaybe<Scalars['JSON']>;
};

export type Instacart_Ads_Targeting_V1_StringTargetValues = {
  __typename?: 'instacart_ads_targeting_v1_StringTargetValues';
  values: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Instacart_Ads_Targeting_V1_StringTargetValues_Input = {
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * A TargetingRule is a Boolean-logic rule over features of a user or serving
 * context. Rules are stored with creatives/adgroups. At serving time, each
 * creative is eligible to participate in an auction only if its TargetingRule
 * is satisfied by that auction's context.
 *
 * A rule is defined in disjunctive-normal form (an OR of ANDs). (Note,
 * however, that individual rule elements may themselves implicitly be
 * disjunctions - for instance, by specifying a list of product categories on
 * which to match behavior.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule = {
  __typename?: 'instacart_ads_targeting_v1_TargetingRule';
  /**
   * If any of the conjunctions in this list is satisfied, then the rule is
   * satisfied.
   *
   * As a special case, if this list is empty, then the rule is also satisfied.
   * That means that a rule with no conjunctions is semantically equivalent to
   * a rule with a single, empty conjunction.
   */
  conjunctions: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_TargetingRule_Conjunction>>>;
};

export type Instacart_Ads_Targeting_V1_TargetingRuleElement = {
  __typename?: 'instacart_ads_targeting_v1_TargetingRuleElement';
  alcoholBuyerLicenseCondition: Maybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition>;
  /**
   * Special value for conditions that are always true. For system-internal
   * use only (e.g., as a simplification of A OR !A); should not be set
   * normally. If 'not' is set, then this element should always evaulate to
   * false.
   */
  alwaysTrue: Maybe<Scalars['google_protobuf_Empty']>;
  audience: Maybe<Instacart_Ads_Targeting_V1_AudienceCondition>;
  boolCondition: Maybe<Instacart_Ads_Targeting_V1_BoolCondition>;
  brandId: Maybe<Instacart_Ads_Targeting_V1_BrandIdCondition>;
  brandIdInterest: Maybe<Instacart_Ads_Targeting_V1_BrandIdCondition>;
  category: Maybe<Instacart_Ads_Targeting_V1_CategoryCondition>;
  categoryInterest: Maybe<Instacart_Ads_Targeting_V1_CategoryCondition>;
  /** The feature dependency for a given targeting rule. */
  feature: Maybe<Scalars['String']>;
  floatCondition: Maybe<Instacart_Ads_Targeting_V1_FloatCondition>;
  geo: Maybe<Instacart_Ads_Targeting_V1_GeoCondition>;
  /** These conditions are used for implementing generic use cases and should be used by retailers to set up all taas segments */
  intCondition: Maybe<Instacart_Ads_Targeting_V1_IntCondition>;
  newToInstacart: Maybe<Scalars['instacart_ads_targeting_v1_NewToInstacartCondition']>;
  /**
   * Logical NOT: inverts the condition to match users/contexts where the
   * condition is otherwise not true.
   */
  not: Maybe<Scalars['Boolean']>;
  region: Maybe<Instacart_Ads_Targeting_V1_RegionCondition>;
  stringCondition: Maybe<Instacart_Ads_Targeting_V1_StringCondition>;
  stringListCondition: Maybe<Instacart_Ads_Targeting_V1_StringListCondition>;
  timeCondition: Maybe<Instacart_Ads_Targeting_V1_TimeCondition>;
  timeIntervalCondition: Maybe<Instacart_Ads_Targeting_V1_TimeIntervalCondition>;
  upc: Maybe<Instacart_Ads_Targeting_V1_UpcCondition>;
  upcInterest: Maybe<Instacart_Ads_Targeting_V1_UpcCondition>;
  userOrdersCount: Maybe<Instacart_Ads_Targeting_V1_UserOrdersCountCondition>;
};

export type Instacart_Ads_Targeting_V1_TargetingRuleElement_Input = {
  alcoholBuyerLicenseCondition?: InputMaybe<Instacart_Ads_Targeting_V1_AlcoholBuyerLicenseCondition_Input>;
  /**
   * Special value for conditions that are always true. For system-internal
   * use only (e.g., as a simplification of A OR !A); should not be set
   * normally. If 'not' is set, then this element should always evaulate to
   * false.
   */
  alwaysTrue?: InputMaybe<Scalars['google_protobuf_Empty_Input']>;
  audience?: InputMaybe<Instacart_Ads_Targeting_V1_AudienceCondition_Input>;
  boolCondition?: InputMaybe<Instacart_Ads_Targeting_V1_BoolCondition_Input>;
  brandId?: InputMaybe<Instacart_Ads_Targeting_V1_BrandIdCondition_Input>;
  brandIdInterest?: InputMaybe<Instacart_Ads_Targeting_V1_BrandIdCondition_Input>;
  category?: InputMaybe<Instacart_Ads_Targeting_V1_CategoryCondition_Input>;
  categoryInterest?: InputMaybe<Instacart_Ads_Targeting_V1_CategoryCondition_Input>;
  /** The feature dependency for a given targeting rule. */
  feature?: InputMaybe<Scalars['String']>;
  floatCondition?: InputMaybe<Instacart_Ads_Targeting_V1_FloatCondition_Input>;
  geo?: InputMaybe<Instacart_Ads_Targeting_V1_GeoCondition_Input>;
  /** These conditions are used for implementing generic use cases and should be used by retailers to set up all taas segments */
  intCondition?: InputMaybe<Instacart_Ads_Targeting_V1_IntCondition_Input>;
  newToInstacart?: InputMaybe<Scalars['instacart_ads_targeting_v1_NewToInstacartCondition_Input']>;
  /**
   * Logical NOT: inverts the condition to match users/contexts where the
   * condition is otherwise not true.
   */
  not?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Instacart_Ads_Targeting_V1_RegionCondition_Input>;
  stringCondition?: InputMaybe<Instacart_Ads_Targeting_V1_StringCondition_Input>;
  stringListCondition?: InputMaybe<Instacart_Ads_Targeting_V1_StringListCondition_Input>;
  timeCondition?: InputMaybe<Instacart_Ads_Targeting_V1_TimeCondition_Input>;
  timeIntervalCondition?: InputMaybe<Instacart_Ads_Targeting_V1_TimeIntervalCondition_Input>;
  upc?: InputMaybe<Instacart_Ads_Targeting_V1_UpcCondition_Input>;
  upcInterest?: InputMaybe<Instacart_Ads_Targeting_V1_UpcCondition_Input>;
  userOrdersCount?: InputMaybe<Instacart_Ads_Targeting_V1_UserOrdersCountCondition_Input>;
};

/**
 * Within a single conjunction, /all/ elements must be satisfied in order for
 * the conjunction to be satisfied.
 *
 * As a special case, if the list of elements is empty, then the conjunction
 * is also satisfied. (Another way of looking at this: every conjunction
 * implicitly includes literal TRUE.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule_Conjunction = {
  __typename?: 'instacart_ads_targeting_v1_TargetingRule_Conjunction';
  elements: Maybe<Array<Maybe<Instacart_Ads_Targeting_V1_TargetingRuleElement>>>;
};

/**
 * Within a single conjunction, /all/ elements must be satisfied in order for
 * the conjunction to be satisfied.
 *
 * As a special case, if the list of elements is empty, then the conjunction
 * is also satisfied. (Another way of looking at this: every conjunction
 * implicitly includes literal TRUE.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule_Conjunction_Input = {
  elements?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_TargetingRuleElement_Input>>>;
};

/**
 * A TargetingRule is a Boolean-logic rule over features of a user or serving
 * context. Rules are stored with creatives/adgroups. At serving time, each
 * creative is eligible to participate in an auction only if its TargetingRule
 * is satisfied by that auction's context.
 *
 * A rule is defined in disjunctive-normal form (an OR of ANDs). (Note,
 * however, that individual rule elements may themselves implicitly be
 * disjunctions - for instance, by specifying a list of product categories on
 * which to match behavior.)
 */
export type Instacart_Ads_Targeting_V1_TargetingRule_Input = {
  /**
   * If any of the conjunctions in this list is satisfied, then the rule is
   * satisfied.
   *
   * As a special case, if this list is empty, then the rule is also satisfied.
   * That means that a rule with no conjunctions is semantically equivalent to
   * a rule with a single, empty conjunction.
   */
  conjunctions?: InputMaybe<Array<InputMaybe<Instacart_Ads_Targeting_V1_TargetingRule_Conjunction_Input>>>;
};

export type Instacart_Ads_Targeting_V1_TimeCondition = {
  __typename?: 'instacart_ads_targeting_v1_TimeCondition';
  crossRetailerReducer: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  threshold: Maybe<Scalars['DateTime']>;
};

export type Instacart_Ads_Targeting_V1_TimeCondition_Input = {
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  threshold?: InputMaybe<Scalars['DateTime']>;
};

export type Instacart_Ads_Targeting_V1_TimeIntervalCondition = {
  __typename?: 'instacart_ads_targeting_v1_TimeIntervalCondition';
  /**
   * MAX/MIN here means we will take the max/min of the times and then compare with the time interval specified
   * We will not be taking max/min of the intervals
   */
  crossRetailerReducer: Maybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  mapIndices: Maybe<Array<Maybe<Scalars['String']>>>;
  operator: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails: Maybe<Instacart_Ads_Targeting_V1_RetailerDetails>;
  timeWindow: Maybe<Google_Protobuf_Duration>;
};

export type Instacart_Ads_Targeting_V1_TimeIntervalCondition_Input = {
  /**
   * MAX/MIN here means we will take the max/min of the times and then compare with the time interval specified
   * We will not be taking max/min of the intervals
   */
  crossRetailerReducer?: InputMaybe<Instacart_Ads_Targeting_V1_CrossRetailerReducer>;
  mapIndices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  retailerDetails?: InputMaybe<Instacart_Ads_Targeting_V1_RetailerDetails_Input>;
  timeWindow?: InputMaybe<Google_Protobuf_Duration_Input>;
};

/** Condition for availability check. */
export type Instacart_Ads_Targeting_V1_UpcCondition = {
  __typename?: 'instacart_ads_targeting_v1_UPCCondition';
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single UPCCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * If the time window is empty, this field is for availability check.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two UPCConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow: Maybe<Google_Protobuf_Duration>;
  /**
   * At least 1 of the UPCs must be available at the stores
   * in which the user has shopped (or is shopping).
   */
  upcs: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Condition for availability check. */
export type Instacart_Ads_Targeting_V1_UpcCondition_Input = {
  /**
   * Time range in which to consider purchase, relative to the auction time.
   *
   * A single UPCCondition's time window establishes a time range:
   * [auction_time - time_window, auction_time]
   *
   * If the time window is empty, this field is for availability check.
   *
   * In order to construct a time window with its right bound in the past
   * (e.g., "users who have bought this product in the past 90 days, but not in
   * the past 30), create two UPCConditions, with the one containing the
   * shorter time_window inverted.
   */
  timeWindow?: InputMaybe<Google_Protobuf_Duration_Input>;
  /**
   * At least 1 of the UPCs must be available at the stores
   * in which the user has shopped (or is shopping).
   */
  upcs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Ads_Targeting_V1_UserOrdersCountCondition = {
  __typename?: 'instacart_ads_targeting_v1_UserOrdersCountCondition';
  /**
   * If set, the condition considers all completed orders.
   *
   * Valid only if order_source == "marketplace"
   */
  includeAllOrders: Maybe<Scalars['Boolean']>;
  operator: Maybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  orderSource: Maybe<Scalars['String']>;
  orderThreshold: Maybe<Scalars['BigInt']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context.
   */
  retailerFromContext: Maybe<Scalars['google_protobuf_Empty']>;
  /** If set, the condition applies to only the orders from the given retailer. */
  retailerId: Maybe<Scalars['BigInt']>;
};

export type Instacart_Ads_Targeting_V1_UserOrdersCountCondition_Input = {
  /**
   * If set, the condition considers all completed orders.
   *
   * Valid only if order_source == "marketplace"
   */
  includeAllOrders?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<Instacart_Ads_Targeting_V1_ComparisonOperator>;
  orderSource?: InputMaybe<Scalars['String']>;
  orderThreshold?: InputMaybe<Scalars['BigInt']>;
  /**
   * If set, the condition considers orders from the retailer set in the
   * request context.
   */
  retailerFromContext?: InputMaybe<Scalars['google_protobuf_Empty_Input']>;
  /** If set, the condition applies to only the orders from the given retailer. */
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Ads_V3_BrandNameMapping = {
  __typename?: 'instacart_ads_v3_BrandNameMapping';
  brandName: Maybe<Scalars['String']>;
  brandProfileId: Maybe<Scalars['String']>;
};

export type Instacart_Ads_V3_IndexBrandNamesRequest_Input = {
  isDebug?: InputMaybe<Scalars['Boolean']>;
  superCategories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Instacart_Ads_V3_IndexBrandNamesResponse = {
  __typename?: 'instacart_ads_v3_IndexBrandNamesResponse';
  brandNameMappings: Maybe<Array<Maybe<Instacart_Ads_V3_BrandNameMapping>>>;
  /** This message is in path to deprecation, since maps do not support ordering */
  brandProfileIdToName: Maybe<Scalars['JSON']>;
};

export type Instacart_Ads_V3_ProductAdditionalInfoRequest_Input = {
  productId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Ads_V3_ProductAdditionalInfoResponse = {
  __typename?: 'instacart_ads_v3_ProductAdditionalInfoResponse';
  details: Maybe<Scalars['String']>;
  ingredients: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_GetCouponCodeExistsRequest_Input = {
  code?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Coupons_V1_GetCouponCodeExistsResponse = {
  __typename?: 'instacart_customers_coupons_v1_GetCouponCodeExistsResponse';
  couponCodeExists: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Orders_V1_GetOrderLinesRequest_Input = {
  /** Single order reference. */
  orderReference?: InputMaybe<Scalars['String']>;
  /**
   * Batched order reference call.
   * Limit: 20.
   */
  orderReferences?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** RequestContext. */
  requestContext?: InputMaybe<Instacart_Customers_Shared_V1_RequestContext_Input>;
};

export type Instacart_Customers_Orders_V1_GetOrderLinesResponse = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse';
  /** If a single order_reference is passed, the single order item summary. */
  orderLines: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLines>;
  /** Mapped by input order_reference values. */
  orderLinesByOrderReference: Maybe<Scalars['JSON']>;
};

/** See OrdersDomain::Api::Responses::ItemResponse. */
export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderItem';
  /** Item is alcoholic */
  alcoholic: Maybe<Scalars['Boolean']>;
  /** Additional item external data provided by the retailer. */
  externalData: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItemExternalData>;
  imageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  /** Product category */
  productCategory: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem_ProductCategory>;
  /** Product codes */
  productCodes: Maybe<Array<Maybe<Scalars['String']>>>;
  productId: Maybe<Scalars['BigInt']>;
  /** Quantity including units displayed to customer. */
  quantity: Maybe<Instacart_Types_V1_MeasuredQuantity>;
  /** Retailer lookup code. */
  retailerLookupCode: Maybe<Scalars['String']>;
  /** Display size of item. */
  sizeDetails: Maybe<Scalars['String']>;
  /** Price per unit displayed to customer. */
  unitPrice: Maybe<Google_Type_Money>;
};

export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItemExternalData = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderItemExternalData';
  lineNum: Maybe<Scalars['String']>;
  retailerRrc: Maybe<Scalars['String']>;
  retailerUpc: Maybe<Scalars['String']>;
  scanCode: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem_ProductCategory = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderItem_ProductCategory';
  l1Category: Maybe<Scalars['String']>;
  l1CategoryId: Maybe<Scalars['BigInt']>;
  l2Category: Maybe<Scalars['String']>;
  l2CategoryId: Maybe<Scalars['BigInt']>;
  l3Category: Maybe<Scalars['String']>;
  l3CategoryId: Maybe<Scalars['BigInt']>;
  l4Category: Maybe<Scalars['String']>;
  l4CategoryId: Maybe<Scalars['BigInt']>;
  l5Category: Maybe<Scalars['String']>;
  l5CategoryId: Maybe<Scalars['BigInt']>;
  l6Category: Maybe<Scalars['String']>;
  l6CategoryId: Maybe<Scalars['BigInt']>;
  productCategory: Maybe<Scalars['String']>;
  productCategoryId: Maybe<Scalars['BigInt']>;
};

/** See OrdersDomain::Api::Responses::OrderItemResponse. */
export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderLine';
  /** Id of the shopper that added the order item. */
  addedByDriverId: Maybe<Scalars['BigInt']>;
  /**
   * Specifies if the customer explicitly allowed a substitution.
   * Possible values:
   * nil - customer did not decide.
   * false - customer asked for no substitution.
   * true - customer asked for a substitute.
   */
  allowedSubstitution: Maybe<Scalars['Boolean']>;
  creationDate: Maybe<Scalars['DateTime']>;
  /** Price shown to customer at order time. */
  customerOrderedPrice: Maybe<Google_Type_Money>;
  /** Price displayed at storefront. */
  customerPrice: Maybe<Google_Type_Money>;
  /**
   * Customer specificed replacement at checkout/post-checkout.
   * Available only when allowed_substitution is True.
   */
  customerSelectedReplacement: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem>;
  /** Full price for this item. */
  fullPrice: Maybe<Google_Type_Money>;
  /** This id maps the sequence in which items were ordered. */
  id: Maybe<Scalars['BigInt']>;
  /** Original item requested at checkout. */
  item: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem>;
  /** A unique identifier for this OrderLine that can be exposed externally. */
  obfuscatedId: Maybe<Scalars['String']>;
  /** Quantity including units ordered by customer. */
  orderedQuantity: Maybe<Instacart_Types_V1_MeasuredQuantity>;
  replacementPolicy: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_ReplacementPolicy>;
  /** Special instructions about the order item. */
  specialInstructions: Maybe<Scalars['String']>;
  /** Original requested item status. */
  status: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_Status>;
  /**
   * Substitute if replaced by shopper. If present, this is the current item
   * on the order.
   */
  substitute: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderItem>;
  /** The current status of the substitution for this item, if it exists. */
  substitutionStatus: Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_SubstitutionStatus>;
};

/** Chosen replacement policy for the order item. */
export enum Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_ReplacementPolicy {
  NoReplacements = 'NO_REPLACEMENTS',
  ReplacementPolicyUnspecified = 'REPLACEMENT_POLICY_UNSPECIFIED',
  ShoppersChoice = 'SHOPPERS_CHOICE',
  UsersChoice = 'USERS_CHOICE'
}

export enum Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_Status {
  /**
   * DEPRECATED
   * Replacement approved by customer.
   */
  DeprecatedApproved = 'DEPRECATED_APPROVED',
  /**
   * DEPRECATED
   * Replacement rejected by customer.
   */
  DeprecatedRejected = 'DEPRECATED_REJECTED',
  /** Original item requested found by shopper. */
  Found = 'FOUND',
  /** Not yet picked. */
  NotPicked = 'NOT_PICKED',
  /** Item replaced. */
  Replaced = 'REPLACED',
  StatusUnspecified = 'STATUS_UNSPECIFIED',
  /** Item to be refunded. */
  ToRefund = 'TO_REFUND'
}

export enum Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine_SubstitutionStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  SubstitutionStatusUnspecified = 'SUBSTITUTION_STATUS_UNSPECIFIED'
}

/** Represents result set for an order. */
export type Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLines = {
  __typename?: 'instacart_customers_orders_v1_GetOrderLinesResponse_OrderLines';
  lineItems: Maybe<Array<Maybe<Instacart_Customers_Orders_V1_GetOrderLinesResponse_OrderLine>>>;
};

/** Country API Objects // */
export type Instacart_Customers_Partners_V1_Country = {
  __typename?: 'instacart_customers_partners_v1_Country';
  /** two-letter country abbreviation */
  alpha_2: Maybe<Scalars['String']>;
  /** three-letter country abbreviation */
  alpha_3: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Partners_V1_GetCountriesRequestParameters_Input = {
  /**
   * For searching by ID
   * If a search string is provided and a number or list of numbers can be extracted from it, search for those numbers as ids as well.
   */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** For searching by name or abbreviation */
  name?: InputMaybe<Scalars['String']>;
};

/** Country API Request // */
export type Instacart_Customers_Partners_V1_GetCountriesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetCountriesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** Countries API Request // */
export type Instacart_Customers_Partners_V1_GetCountriesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetCountriesResponse';
  countries: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Country>>>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequestParameters_Input = {
  /** For searching by code */
  code?: InputMaybe<Scalars['String']>;
  /** For searching by ID */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Required, we will only show a subset of postal codes by zone */
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesByZonesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesByZonesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetPostalCodesByZonesResponse';
  postalCodes: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_PostalCode>>>;
};

export type Instacart_Customers_Partners_V1_GetPostalCodesRequestParameters_Input = {
  /** For searching by code, optional */
  code?: InputMaybe<Scalars['String']>;
  /**
   * For searching by ID, optional
   * If a search string is provided and a number or list of numbers can be extracted from it, search for those numbers as ids as well.
   */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** PostalCodes API Request // */
export type Instacart_Customers_Partners_V1_GetPostalCodesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetPostalCodesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** PostalCodes API Response // */
export type Instacart_Customers_Partners_V1_GetPostalCodesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetPostalCodesResponse';
  postalCodes: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_PostalCode>>>;
};

export type Instacart_Customers_Partners_V1_GetRegionsRequestParameters_Input = {
  /** For searching by ID */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** For searching by name */
  name?: InputMaybe<Scalars['String']>;
};

/** Regions API Request // */
export type Instacart_Customers_Partners_V1_GetRegionsRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRegionsRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** Regions API Response // */
export type Instacart_Customers_Partners_V1_GetRegionsResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRegionsResponse';
  regions: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Region>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequestParameters_Input = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  /** Optionally pass in the name to filter by */
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsByRetailerResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRetailerLocationsByRetailerResponse';
  retailerLocations: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_RetailerLocation>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailerLocationsRequestParameters_Input = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  name?: InputMaybe<Scalars['String']>;
};

/** RetailerLocations API Request // */
export type Instacart_Customers_Partners_V1_GetRetailerLocationsRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailerLocationsRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** RetailerLocations API Response // */
export type Instacart_Customers_Partners_V1_GetRetailerLocationsResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRetailerLocationsResponse';
  retailerLocations: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_RetailerLocation>>>;
};

export type Instacart_Customers_Partners_V1_GetRetailersRequestParameters_Input = {
  /** For searching by IDs */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** For searching by name */
  name?: InputMaybe<Scalars['String']>;
};

/** Retailers API Request // */
export type Instacart_Customers_Partners_V1_GetRetailersRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetRetailersRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** Retailers API Response // */
export type Instacart_Customers_Partners_V1_GetRetailersResponse = {
  __typename?: 'instacart_customers_partners_v1_GetRetailersResponse';
  retailers: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Retailer>>>;
};

export type Instacart_Customers_Partners_V1_GetZonesByRegionRequestParameters_Input = {
  /** For searching by ID */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** For searching by name */
  name?: InputMaybe<Scalars['String']>;
  /** Required, we will only show a subset of zones by region */
  regionId?: InputMaybe<Scalars['BigInt']>;
};

/** Zones API Request // */
export type Instacart_Customers_Partners_V1_GetZonesByRegionRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesByRegionRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Partners_V1_GetZonesByRegionResponse = {
  __typename?: 'instacart_customers_partners_v1_GetZonesByRegionResponse';
  zones: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Zone>>>;
};

export type Instacart_Customers_Partners_V1_GetZonesRequestParameters_Input = {
  /** For searching by IDs, optional */
  ids?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /**
   * For searching by name, optional
   * If a search string is provided and a number or list of numbers can be extracted from it, search for those numbers as ids as well.
   */
  name?: InputMaybe<Scalars['String']>;
};

/** Zones API Request // */
export type Instacart_Customers_Partners_V1_GetZonesRequest_Input = {
  parameters?: InputMaybe<Instacart_Customers_Partners_V1_GetZonesRequestParameters_Input>;
  timeoutMs?: InputMaybe<Scalars['BigInt']>;
};

/** Zones API Request // */
export type Instacart_Customers_Partners_V1_GetZonesResponse = {
  __typename?: 'instacart_customers_partners_v1_GetZonesResponse';
  zones: Maybe<Array<Maybe<Instacart_Customers_Partners_V1_Zone>>>;
};

/** PostalCode API Objects // */
export type Instacart_Customers_Partners_V1_PostalCode = {
  __typename?: 'instacart_customers_partners_v1_PostalCode';
  city: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  countryId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  state: Maybe<Scalars['String']>;
  zoneId: Maybe<Scalars['BigInt']>;
};

/** Region API Objects // */
export type Instacart_Customers_Partners_V1_Region = {
  __typename?: 'instacart_customers_partners_v1_Region';
  countryId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
};

/** Retailers API Objects // */
export type Instacart_Customers_Partners_V1_Retailer = {
  __typename?: 'instacart_customers_partners_v1_Retailer';
  adminOnly: Maybe<Scalars['Boolean']>;
  allowAlcohol: Maybe<Scalars['Boolean']>;
  allowSpecialRequests: Maybe<Scalars['Boolean']>;
  backgroundColorHex: Maybe<Scalars['String']>;
  backgroundImagePath: Maybe<Scalars['String']>;
  categories: Maybe<Array<Maybe<Scalars['String']>>>;
  countrySlug: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  franchiseeId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  logoImagePath: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  priceTransparencyDescription: Maybe<Scalars['String']>;
  priceTransparencyFlag: Maybe<Scalars['String']>;
  retailerType: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
};

/** RetailerLocations API Objects // */
export type Instacart_Customers_Partners_V1_RetailerLocation = {
  __typename?: 'instacart_customers_partners_v1_RetailerLocation';
  active: Maybe<Scalars['Boolean']>;
  addressCounty: Maybe<Scalars['String']>;
  addressId: Maybe<Scalars['BigInt']>;
  bagFeeRateCents: Maybe<Scalars['BigInt']>;
  city: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  inventoryAreaId: Maybe<Scalars['BigInt']>;
  latitude: Maybe<Scalars['Float']>;
  locationCode: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
  retailerId: Maybe<Scalars['BigInt']>;
  state: Maybe<Scalars['String']>;
  streetAddress: Maybe<Scalars['String']>;
  zipPlusFour: Maybe<Scalars['String']>;
};

/** Zone API Objects // */
export type Instacart_Customers_Partners_V1_Zone = {
  __typename?: 'instacart_customers_partners_v1_Zone';
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  regionId: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Collection = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Collection';
  createdAt: Maybe<Scalars['DateTime']>;
  defaultSortOrder: Maybe<Scalars['String']>;
  dynamicallyFedStatic: Maybe<Scalars['Boolean']>;
  endDate: Maybe<Scalars['DateTime']>;
  filters: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Filter>>>;
  id: Maybe<Scalars['BigInt']>;
  inventoryFileMappings: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_InventoryFileMapping>>>;
  name: Maybe<Scalars['String']>;
  productCount: Maybe<Scalars['BigInt']>;
  productIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  retailerId: Maybe<Scalars['BigInt']>;
  slug: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  translations: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum Instacart_Customers_Retailer_Collections_Admin_V1_CollectionStatus {
  Active = 'ACTIVE',
  ActiveAdminOnly = 'ACTIVE_ADMIN_ONLY',
  EnumTypeStatusUnspecified = 'ENUM_TYPE_STATUS_UNSPECIFIED',
  Inactive = 'INACTIVE'
}

export type Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CollectionTranslation';
  localeCode: Maybe<Instacart_Customers_Shared_V1_Locale>;
  name: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation_Input = {
  localeCode?: InputMaybe<Instacart_Customers_Shared_V1_Locale>;
  name?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionRequest_Input = {
  defaultSortOrder?: InputMaybe<Scalars['String']>;
  dynamicallyFedStatic?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Filter_Input>>>;
  name?: InputMaybe<Scalars['String']>;
  productEvaluation?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation_Input>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CreateCollectionResponse';
  collection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateInvfMappingResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CreateInvfMappingResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_CreateRootNodeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_CreateRootNodeResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DeleteCollectionResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteInvfMappingResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DeleteInvfMappingResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_DeleteNavigationNodeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_DeleteNavigationNodeResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Filter = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Filter';
  collectionId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  intersectionGroup: Maybe<Scalars['BigInt']>;
  ruleId: Maybe<Scalars['BigInt']>;
  value: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Filter_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  intersectionGroup?: InputMaybe<Scalars['BigInt']>;
  ruleId?: InputMaybe<Scalars['BigInt']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetCollectionResponse';
  collection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesRequest_Input = {
  dynamicFeedEligible?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetCollectionRulesResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetCollectionRulesResponse';
  rules: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Rule>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetNavigationTreeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetNavigationTreeResponse';
  navigationConfiguration: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration>;
  nodes: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_GetRetailerCsvResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_GetRetailerCsvResponse';
  filePath: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_InventoryFileMapping = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_InventoryFileMapping';
  collectionSlug: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  retailerCollectionId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionProductsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_ListCollectionProductsResponse';
  products: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsRequest_Input = {
  limit?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['BigInt']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  slugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  withoutNavigationCollections?: InputMaybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_ListCollectionsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_ListCollectionsResponse';
  collections: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>>>;
  totalCount: Maybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigationConfiguration';
  alwaysPinned: Maybe<Scalars['Boolean']>;
  inventoryCountDisabled: Maybe<Scalars['Boolean']>;
  maxTopLevelNodes: Maybe<Scalars['BigInt']>;
  sourceDomain: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration_Input = {
  alwaysPinned?: InputMaybe<Scalars['Boolean']>;
  inventoryCountDisabled?: InputMaybe<Scalars['Boolean']>;
  maxTopLevelNodes?: InputMaybe<Scalars['BigInt']>;
  sourceDomain?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_NavigationNode';
  alcoholic: Maybe<Scalars['Boolean']>;
  alwaysShow: Maybe<Scalars['Boolean']>;
  children: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode>>>;
  contentType: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode_ContentType>;
  endDate: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['BigInt']>;
  isDynamic: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  pinned: Maybe<Scalars['Boolean']>;
  position: Maybe<Scalars['BigInt']>;
  productCount: Maybe<Scalars['BigInt']>;
  retailerCollectionId: Maybe<Scalars['BigInt']>;
  retailerId: Maybe<Scalars['BigInt']>;
  slug: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  status: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionStatus>;
  storeConfigurationId: Maybe<Scalars['BigInt']>;
  tileImageUrl: Maybe<Scalars['String']>;
  translations: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Translation>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode_ContentType {
  Collections = 'COLLECTIONS',
  Products = 'PRODUCTS',
  Root = 'ROOT',
  Unspecified = 'UNSPECIFIED'
}

export type Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesRequest_Input = {
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_OrderNavigationNodesResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_OrderNavigationNodesResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Product = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Product';
  cachedProductId: Maybe<Scalars['BigInt']>;
  id: Maybe<Scalars['BigInt']>;
  lookupCode: Maybe<Scalars['String']>;
  pinned: Maybe<Scalars['Boolean']>;
  position: Maybe<Scalars['BigInt']>;
  retailerReferenceCode: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input = {
  cachedProductId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  lookupCode?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['BigInt']>;
  retailerReferenceCode?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationRequest_Input = {
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_QueueNavigationMigrationResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_QueueNavigationMigrationResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Rule = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Rule';
  id: Maybe<Scalars['BigInt']>;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  valueSource: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSource>;
  valueType: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSource = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_RuleValueSource';
  options: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSourceOption>>>;
  query: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_RuleValueSourceOption = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_RuleValueSourceOption';
  label: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsRequest_Input = {
  collectionId?: InputMaybe<Scalars['BigInt']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_SearchCollectionProductsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_SearchCollectionProductsResponse';
  products: Maybe<Array<Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Translation = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_Translation';
  localeCode: Maybe<Instacart_Customers_Shared_V1_Locale>;
  name: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_Translation_Input = {
  localeCode?: InputMaybe<Instacart_Customers_Shared_V1_Locale>;
  name?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsRequest_Input = {
  id?: InputMaybe<Scalars['BigInt']>;
  productsToAdd?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  productsToDelete?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  source?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionProductsResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateCollectionProductsResponse';
  collection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionRequest_Input = {
  collectionType?: InputMaybe<Scalars['String']>;
  defaultSortOrder?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Filter_Input>>>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  productsToAdd?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  productsToDelete?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Product_Input>>>;
  resetAllProducts?: InputMaybe<Scalars['Boolean']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionTranslation_Input>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateCollectionResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateCollectionResponse';
  collection: Maybe<Instacart_Customers_Retailer_Collections_Admin_V1_Collection>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationRequest_Input = {
  navigationConfiguration?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationConfiguration_Input>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpdateNavigationConfigurationResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpdateNavigationConfigurationResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeRequest_Input = {
  alcoholic?: InputMaybe<Scalars['Boolean']>;
  alwaysShow?: InputMaybe<Scalars['Boolean']>;
  contentType?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_NavigationNode_ContentType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['BigInt']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['BigInt']>;
  retailerCollectionId?: InputMaybe<Scalars['BigInt']>;
  retailerId?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_CollectionStatus>;
  storeConfigurationId?: InputMaybe<Scalars['BigInt']>;
  tileImageUrl?: InputMaybe<Scalars['String']>;
  translations?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Collections_Admin_V1_Translation_Input>>>;
};

export type Instacart_Customers_Retailer_Collections_Admin_V1_UpsertNavigationNodeResponse = {
  __typename?: 'instacart_customers_retailer_collections_admin_v1_UpsertNavigationNodeResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** namespace entity id of the draft */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** status of the draft */
  status?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_IppDraftStatus>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_DeleteIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_orchestrator_v1_DeleteIppDraftResponse';
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

/** Represent reasons for failure */
export enum Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason {
  /** Request context or payload incomplete or invalid */
  ErrorReasonBadRequest = 'ERROR_REASON_BAD_REQUEST',
  /** Unknown error */
  ErrorUnknown = 'ERROR_UNKNOWN',
  /** No error */
  NoError = 'NO_ERROR'
}

export enum Instacart_Customers_Retailer_Management_Orchestrator_V1_IppDraftStatus {
  Deleted = 'DELETED',
  DeleteError = 'DELETE_ERROR',
  Published = 'PUBLISHED',
  PublishError = 'PUBLISH_ERROR',
  Working = 'WORKING'
}

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** namespace entity id of the draft */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** status of the draft */
  status?: InputMaybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_IppDraftStatus>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_PublishIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_orchestrator_v1_PublishIppDraftResponse';
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_Orchestrator_V1_RevertIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_orchestrator_v1_RevertIppDraftResponse';
  /** the draft id of the reverted draft that was created */
  draftId: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_Orchestrator_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewRequest_Input = {
  /** draft id to create the preview from */
  draftId?: InputMaybe<Scalars['String']>;
  /** retailer id */
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftPreviewResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_CreateIppDraftPreviewResponse';
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
  /** auth token for the preview */
  previewAuthToken: Maybe<Scalars['String']>;
  /** password for the preview */
  previewPassword: Maybe<Scalars['String']>;
  /** url of the preview */
  previewUrl: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftRequest_Input = {
  /** list of draft components associated with the draft. */
  draftIds?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Management_V1_IppDraftComponent_Input>>>;
  /** the name of the draft */
  name?: InputMaybe<Scalars['String']>;
  /** a short description of a draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** the ID of the entity being drafted */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** partion of the draft (i.e. store_configuration, warehouse, contentpage, etc.) */
  partition?: InputMaybe<Scalars['String']>;
  /** associated partition id */
  partitionId?: InputMaybe<Scalars['BigInt']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_CreateIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_CreateIppDraftResponse';
  /** the draft id that was created */
  draftId: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_DeleteIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** the ID of the entity being drafted */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_DeleteIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_DeleteIppDraftResponse';
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

/** Represent reasons for failure */
export enum Instacart_Customers_Retailer_Management_V1_ErrorReason {
  /** Request context or payload incomplete or invalid */
  ErrorReasonBadRequest = 'ERROR_REASON_BAD_REQUEST',
  /** Request is unauthorized */
  ErrorReasonUnauthorized = 'ERROR_REASON_UNAUTHORIZED',
  /** Unknown error */
  ErrorUnknown = 'ERROR_UNKNOWN',
  /** No error */
  NoError = 'NO_ERROR'
}

export type Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenRequest_Input = {
  /** encoded preview auth token to get preview */
  authToken?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetIppPreviewByAuthTokenResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_GetIppPreviewByAuthTokenResponse';
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  /** draft associated with preview */
  ippDraft: Maybe<Instacart_Customers_Retailer_Management_V1_IppDraft>;
  /** preview object */
  ippPreview: Maybe<Instacart_Customers_Retailer_Management_V1_IppPreview>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenRequest_Input = {
  /** passcode for preview */
  passcode?: InputMaybe<Scalars['String']>;
  /** preview_token for request */
  previewToken?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_GetPreviewAuthTokenResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_GetPreviewAuthTokenResponse';
  /** auth_token */
  authToken: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraft = {
  __typename?: 'instacart_customers_retailer_management_v1_IppDraft';
  /** date of creation */
  createdAt: Maybe<Scalars['DateTime']>;
  /** IPP user id of the user who created the draft */
  createdBy: Maybe<Scalars['String']>;
  /** draft id that this draft was created from (for revert purposes) */
  createdFrom: Maybe<Scalars['String']>;
  /** date of deletion */
  deletedAt: Maybe<Scalars['DateTime']>;
  /** IPP user id that deleted the draft */
  deletedBy: Maybe<Scalars['String']>;
  /** draft id */
  draftId: Maybe<Scalars['String']>;
  /** list of draft components associated with the draft. */
  ippDraftComponents: Maybe<Array<Maybe<Instacart_Customers_Retailer_Management_V1_IppDraftComponent>>>;
  /** name of the draft */
  name: Maybe<Scalars['String']>;
  /** namespace of the draft */
  namespace: Maybe<Scalars['String']>;
  /** partion of the draft (i.e. store_configuration, warehouse, contentpage, etc.) */
  partition: Maybe<Scalars['String']>;
  /** associated partition id */
  partitionId: Maybe<Scalars['BigInt']>;
  /** date of publish */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** IPP user id that published the draft */
  publishedBy: Maybe<Scalars['String']>;
  /** date last updated */
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraftComponent = {
  __typename?: 'instacart_customers_retailer_management_v1_IppDraftComponent';
  /** the domain's configuration draft id */
  configurationDraftId: Maybe<Scalars['String']>;
  /** the domain associated with the draft */
  domain: Maybe<Scalars['String']>;
  /** the associated draft id */
  draftId: Maybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_IppDraftComponent_Input = {
  /** the domain's configuration draft id */
  configurationDraftId?: InputMaybe<Scalars['String']>;
  /** the domain associated with the draft */
  domain?: InputMaybe<Scalars['String']>;
  /** the associated draft id */
  draftId?: InputMaybe<Scalars['String']>;
};

export enum Instacart_Customers_Retailer_Management_V1_IppDraftStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Published = 'PUBLISHED',
  Unknown = 'UNKNOWN'
}

export type Instacart_Customers_Retailer_Management_V1_IppPreview = {
  __typename?: 'instacart_customers_retailer_management_v1_IppPreview';
  /** draft id associated with preview */
  draftId: Maybe<Scalars['String']>;
  /** passcode for the preview */
  passcode: Maybe<Scalars['String']>;
  /** token associated with preview */
  previewToken: Maybe<Scalars['String']>;
  /** url of the preview */
  previewUrl: Maybe<Scalars['String']>;
};

export enum Instacart_Customers_Retailer_Management_V1_ListIppDraftsColumns {
  CreatedAt = 'CREATED_AT',
  DeletedAt = 'DELETED_AT',
  PublishedAt = 'PUBLISHED_AT'
}

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsFilterBy_Input = {
  /** author id of the draft */
  authorId?: InputMaybe<Scalars['String']>;
  /** created end date of the draft */
  createdEndDate?: InputMaybe<Scalars['DateTime']>;
  /** created start date of the draft */
  createdStartDate?: InputMaybe<Scalars['DateTime']>;
  /** deleted end date of the draft */
  deletedEndDate?: InputMaybe<Scalars['DateTime']>;
  /** deleted start date of the draft */
  deletedStartDate?: InputMaybe<Scalars['DateTime']>;
  /** id of the draft */
  draftId?: InputMaybe<Scalars['String']>;
  /** namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** the ID of the entity being drafted */
  namespaceEntityId?: InputMaybe<Scalars['String']>;
  /** partition of the draft */
  partition?: InputMaybe<Scalars['String']>;
  /** id of the partition */
  partitionId?: InputMaybe<Scalars['BigInt']>;
  /** published end date of the draft */
  publishedEndDate?: InputMaybe<Scalars['DateTime']>;
  /** published start date of the draft */
  publishedStartDate?: InputMaybe<Scalars['DateTime']>;
  /** query for searching drafts by name */
  query?: InputMaybe<Scalars['String']>;
  /** status of the draft */
  status?: InputMaybe<Instacart_Customers_Retailer_Management_V1_IppDraftStatus>;
};

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsOrderBy_Input = {
  column?: InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsColumns>;
  direction?: InputMaybe<Instacart_Customers_Retailer_Management_V1_OrderByDirection>;
};

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsRequest_Input = {
  /** filters for the draft list */
  filterBy?: InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsFilterBy_Input>;
  /** order drafts list by certain criteria */
  orderBy?: InputMaybe<Array<InputMaybe<Instacart_Customers_Retailer_Management_V1_ListIppDraftsOrderBy_Input>>>;
  /** pagination info */
  pagination?: InputMaybe<Instacart_Customers_Shared_V1_Pagination_Input>;
};

export type Instacart_Customers_Retailer_Management_V1_ListIppDraftsResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_ListIppDraftsResponse';
  /** list of drafts */
  drafts: Maybe<Array<Maybe<Instacart_Customers_Retailer_Management_V1_IppDraft>>>;
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
  /** page info for pagination */
  pageInfo: Maybe<Instacart_Customers_Shared_V1_PageInfo>;
};

export enum Instacart_Customers_Retailer_Management_V1_OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Instacart_Customers_Retailer_Management_V1_PublishIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_PublishIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_PublishIppDraftResponse';
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export type Instacart_Customers_Retailer_Management_V1_RevertIppDraftRequest_Input = {
  /** the draft id that was created */
  draftId?: InputMaybe<Scalars['String']>;
  /** the namespace of the draft */
  namespace?: InputMaybe<Scalars['String']>;
  /** User ID used in audit */
  userId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Customers_Retailer_Management_V1_RevertIppDraftResponse = {
  __typename?: 'instacart_customers_retailer_management_v1_RevertIppDraftResponse';
  /** the draft id of the reverted draft that was created */
  draftId: Maybe<Scalars['String']>;
  /** Error message, if applicable */
  errorMessage: Maybe<Scalars['String']>;
  /** Structured error code, if applicable */
  errorReason: Maybe<Instacart_Customers_Retailer_Management_V1_ErrorReason>;
  isSuccessful: Maybe<Scalars['Boolean']>;
};

export enum Instacart_Customers_Shared_V1_Locale {
  EnAu = 'EN_AU',
  EnCa = 'EN_CA',
  EnUs = 'EN_US',
  EsUs = 'ES_US',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  LocaleUnspecified = 'LOCALE_UNSPECIFIED'
}

/** Represents pagination info extracted from Domain::PageInfo objects */
export type Instacart_Customers_Shared_V1_PageInfo = {
  __typename?: 'instacart_customers_shared_v1_PageInfo';
  /** The last cursor of the current page */
  endCursor: Maybe<Scalars['String']>;
  /** Indicates whether or not there is a next page */
  hasNextPage: Maybe<Scalars['Boolean']>;
};

/** Pagination request parameters for Domain::Shared::Pagination */
export type Instacart_Customers_Shared_V1_Pagination_Input = {
  /** Cursor to start from when fetching. */
  after?: InputMaybe<Scalars['String']>;
  /** Count to return */
  limit?: InputMaybe<Scalars['Int']>;
};

/**
 * Contextual information used for identifying the
 * marketplace/pbi/connect configurations associated
 * with a request
 *
 * see Domain::Shared::RequestContext
 */
export type Instacart_Customers_Shared_V1_RequestContext_Input = {
  /**
   * Known as `oauth_application_id` in the corresponding domain object
   * But refers to the primary key on the `client_configurations` table.
   */
  clientConfigurationId?: InputMaybe<Scalars['Int']>;
  /** ISO numeric code */
  countryId?: InputMaybe<Scalars['Int']>;
  storeConfigurationId?: InputMaybe<Scalars['Int']>;
};

export type Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoRequest_Input = {
  orderId?: InputMaybe<Scalars['BigInt']>;
  /** used for graphql authorization */
  retailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Boba_Core_Offers_V1_GetOrderDiscountInfoResponse = {
  __typename?: 'instacart_enterprise_boba_core_offers_v1_GetOrderDiscountInfoResponse';
  orderDiscountInfo: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo>>>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails';
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyForXAmountScenario: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyForXAmountScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetNForFreeScenario: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForFreeScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetNQtyForXAmountScenario: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForXAmountScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetXAmountOffScenario: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXAmountOffScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyGetXPercentOffScenario: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXPercentOffScenario>;
  /** When present, it indicates this offer is categorized into this scenario. */
  buyMQtyOrMoreGetXPercentOffScenario: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyOrMoreGetXPercentOffScenario>;
  /** Human readable condition and reward string for the discount */
  conditionRewardString: Maybe<Scalars['String']>;
  /** Internal description of the discount */
  description: Maybe<Scalars['String']>;
  /** Legacy discount_category_id */
  discountCategoryId: Maybe<Scalars['BigInt']>;
  /** Discount end time */
  endsAt: Maybe<Scalars['DateTime']>;
  /** Details about the funding information for this offer */
  fundingDetail: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingDetail>;
  /** Internal name of the discount */
  name: Maybe<Scalars['String']>;
  /** List of offer conditions */
  offerConditions: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferCondition>>>;
  /** Reference to the offer in any external system */
  offerExternalReference: Maybe<Scalars['String']>;
  /** Unique offer identifier, will map to discount_policy_id post Unified Discounting Platform */
  offerId: Maybe<Scalars['String']>;
  /** The offer request key */
  offerRequestKey: Maybe<Scalars['String']>;
  /** List of offer rewards */
  offerRewards: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferReward>>>;
  /** The source system which is ingesting this offer */
  offerSource: Maybe<Scalars['String']>;
  /** Type of offer: coupon, auto apply etc */
  offerType: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferType>;
  /** Optional promo code for the discount */
  promoCode: Maybe<Scalars['String']>;
  /** Indicates the maximum allowed redemptions of this offer */
  redemptionLimits: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRedemptionLimits>;
  /** Discount start time */
  startsAt: Maybe<Scalars['DateTime']>;
  /** Legal terms and condition for the discount */
  terms: Maybe<Scalars['String']>;
  /** When present, it indicates this offer cannot be categorized into any known scenario. */
  uncategorizedScenario: Maybe<Scalars['instacart_enterprise_boba_core_types_v1_OfferDetails_UncategorizedScenario']>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BigDecimal';
  /**
   * Nano units of the amount (10^-9)
   * Must be same sign as units
   * Example: The value -1.25 is represented as units=-1 and nanos=-250000000
   */
  nanos: Maybe<Scalars['Int']>;
  /** Whole units part of the amount */
  units: Maybe<Scalars['BigInt']>;
};

/** Buy M qty of eligible products and for a total price of X. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyForXAmountScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyForXAmountScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_amount referred in the scenario name */
  xAmount: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get additional N qty for free. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForFreeScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetNQtyForFreeScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter n_qty referred in the scenario name */
  nQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
};

/** Buy M qty of eligible products and be eligible to buy N eligible products for a total price of X. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetNQtyForXAmountScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetNQtyForXAmountScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter n_qty referred in the scenario name */
  nQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_amount referred in the scenario name */
  xAmount: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get X (monetary value) off the original price. */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXAmountOffScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetXAmountOffScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_amount referred in the scenario name */
  xAmount: Maybe<Google_Type_Money>;
};

/** Buy M qty of eligible products and get X% off the price (of M). */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyGetXPercentOffScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyGetXPercentOffScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_percent referred in the scenario name */
  xPercent: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
};

/** Buy M or more qty of eligible products and get X% off the price (of M). */
export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BuyMQtyOrMoreGetXPercentOffScenario = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_BuyMQtyOrMoreGetXPercentOffScenario';
  /** Value of parameter m_qty referred in the scenario name */
  mQty: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Value of parameter x_percent referred in the scenario name */
  xPercent: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_DiscountRewardType {
  AmountOff = 'AMOUNT_OFF',
  DiscountRewardTypeUnspecified = 'DISCOUNT_REWARD_TYPE_UNSPECIFIED',
  PercentOff = 'PERCENT_OFF',
  SetPrice = 'SET_PRICE'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingDetail = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_FundingDetail';
  /** the amount of budget available before the offer is exhausted */
  budgetLimit: Maybe<Google_Type_Money>;
  fundingProviderInfo: Maybe<Array<Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingProviderInformation>>>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingProviderInformation = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_FundingProviderInformation';
  /** percentage of funding for discount by the source. Can range from 0-100 */
  percent: Maybe<Scalars['BigInt']>;
  /** who is funding this part */
  source: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingSource>;
  /** name of the funding source */
  sourceName: Maybe<Scalars['String']>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_FundingSource {
  Cpg = 'CPG',
  FundingSourceUnspecified = 'FUNDING_SOURCE_UNSPECIFIED',
  Instacart = 'INSTACART',
  Others = 'OTHERS',
  Retailer = 'RETAILER'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferCondition = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_OfferCondition';
  /** Indicates if offer can be applied partially when quantity conditions are not met */
  allowPartialApplication: Maybe<Scalars['Boolean']>;
  /** List of collection ids for the condition */
  collectionIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /** List of product ids for the condition */
  conditionProductIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
  /**
   * Maximum quantity allowed in a single application of this offer.
   * Same value as minimum_quantity is used to indicate exact quantity matching, whereas nil value is used when no upper limit is set.
   */
  maximumQuantity: Maybe<Scalars['BigInt']>;
  /** Minimum quantity to have in cart (with any mix of the products) to qualify for discount */
  minimumQuantity: Maybe<Scalars['BigInt']>;
  /** Minimum amount to spend to qualify for discount */
  minimumSpend: Maybe<Google_Type_Money>;
  offerConditionType: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferConditionType>;
  /** List of taxonomy node ids for the condition */
  taxonomyNodeIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferConditionType {
  OfferConditionTypeBasket = 'OFFER_CONDITION_TYPE_BASKET',
  OfferConditionTypeBrands = 'OFFER_CONDITION_TYPE_BRANDS',
  OfferConditionTypeCategories = 'OFFER_CONDITION_TYPE_CATEGORIES',
  OfferConditionTypeCollections = 'OFFER_CONDITION_TYPE_COLLECTIONS',
  OfferConditionTypeCommerceClassifications = 'OFFER_CONDITION_TYPE_COMMERCE_CLASSIFICATIONS',
  OfferConditionTypeFees = 'OFFER_CONDITION_TYPE_FEES',
  OfferConditionTypeProducts = 'OFFER_CONDITION_TYPE_PRODUCTS',
  OfferConditionTypeRegions = 'OFFER_CONDITION_TYPE_REGIONS',
  OfferConditionTypeUnspecified = 'OFFER_CONDITION_TYPE_UNSPECIFIED'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRedemptionLimits = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_OfferRedemptionLimits';
  /** Total number of redemptions allowed on offers in the same group. */
  redemptionLimit: Maybe<Scalars['BigInt']>;
  /** Number of redemptions allowed per order on offers in the same group */
  redemptionLimitPerOrder: Maybe<Scalars['BigInt']>;
  /** Number of redemptions allowed per user on offers in the same group. */
  redemptionLimitPerUser: Maybe<Scalars['BigInt']>;
};

export type Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferReward = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OfferDetails_OfferReward';
  /** Discount amount */
  amountDiscount: Maybe<Google_Type_Money>;
  discountRewardType: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_DiscountRewardType>;
  /** Indicates if the reward group can include items in the condition group */
  includeConditionGroup: Maybe<Scalars['Boolean']>;
  /** Discount sets price for the item */
  offerPrice: Maybe<Google_Type_Money>;
  offerRewardType: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRewardType>;
  /** Discount percent value between 1-100. */
  percentDiscount: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_BigDecimal>;
  /** Maximum quantity allowed to receive the reward in a single offer application */
  quantityLimit: Maybe<Scalars['BigInt']>;
  /** List of reward product ids" */
  rewardProductIds: Maybe<Array<Maybe<Scalars['BigInt']>>>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferRewardType {
  OfferRewardTypeBasket = 'OFFER_REWARD_TYPE_BASKET',
  OfferRewardTypeBrands = 'OFFER_REWARD_TYPE_BRANDS',
  OfferRewardTypeCategories = 'OFFER_REWARD_TYPE_CATEGORIES',
  OfferRewardTypeCollections = 'OFFER_REWARD_TYPE_COLLECTIONS',
  OfferRewardTypeProducts = 'OFFER_REWARD_TYPE_PRODUCTS',
  OfferRewardTypeUnspecified = 'OFFER_REWARD_TYPE_UNSPECIFIED'
}

export enum Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails_OfferType {
  AutoApply = 'AUTO_APPLY',
  Coupon = 'COUPON',
  OfferTypeUnspecified = 'OFFER_TYPE_UNSPECIFIED'
}

export type Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo = {
  __typename?: 'instacart_enterprise_boba_core_types_v1_OrderDiscountInfo';
  /** Discount amount resutling from the evaluation of the offer */
  appliedAmount: Maybe<Google_Type_Money>;
  /** ID of the discount_discountables record */
  discountDiscountableId: Maybe<Scalars['BigInt']>;
  /** ID of the target the offer applies to */
  discountableId: Maybe<Scalars['BigInt']>;
  /** Discountable entity: basket, item, customer fee etc. */
  discountableType: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo_DiscountableType>;
  /** Discount policy data */
  offer: Maybe<Instacart_Enterprise_Boba_Core_Types_V1_OfferDetails>;
  /** ID of the target order */
  orderId: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Enterprise_Boba_Core_Types_V1_OrderDiscountInfo_DiscountableType {
  BasketLevelOffer = 'BASKET_LEVEL_OFFER',
  CustomerFeeOffer = 'CUSTOMER_FEE_OFFER',
  DiscountableTypeUnspecified = 'DISCOUNTABLE_TYPE_UNSPECIFIED',
  ItemLevelOffer = 'ITEM_LEVEL_OFFER'
}

/** Data shared by all types of fulfillment events */
export type Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input = {
  /** The email of the creator of the event, this will also be the first owner */
  creator?: InputMaybe<Scalars['String']>;
  /** The end time of the event */
  endsAt?: InputMaybe<Scalars['DateTime']>;
  /** The type of the event */
  eventType?: InputMaybe<Instacart_Logistics_V1_FulfillmentEventType>;
  /** A link, typically a Jira link, to the reason for the event */
  infoUrl?: InputMaybe<Scalars['String']>;
  /** Location ids, a child event will be created for each location id */
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Location type, which will apply to all location ids */
  locationType?: InputMaybe<Instacart_Logistics_V1_FulfillmentEventLocationType>;
  /** Name/description of the event */
  name?: InputMaybe<Scalars['String']>;
  /** The severity level of the event */
  severityLevel?: InputMaybe<Instacart_Logistics_V1_FulfillmentEventSeverityLevel>;
  /** The start time of the event */
  startsAt?: InputMaybe<Scalars['DateTime']>;
  /** Any tags associated with the event, optional */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Values specific to a fulfillment capacity-impacting event */
export type Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input = {
  /** Expected rate of increasing of delivery time */
  deliveryMultiple?: InputMaybe<Instacart_Types_V1_BigDecimal_Input>;
  /** The percentage by which to adjust capacity for the location, a negative number */
  suggestedCapacityLevel?: InputMaybe<Scalars['Int']>;
};

/**
 * Values specific to a store closure event
 * More documentation here https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Closure-event%3A-Level-4
 */
export type Instacart_Logistics_V1_ClosureFulfillmentEventData = {
  __typename?: 'instacart_logistics_v1_ClosureFulfillmentEventData';
  addDeliveryBlock: Maybe<Scalars['Boolean']>;
  /** Allow staged delivery orders to be batched instead of reassigning to alternative open stores */
  batchStagedBatches: Maybe<Scalars['Boolean']>;
  /** Cancel shifts */
  cancelAllPartnerShifts: Maybe<Scalars['Boolean']>;
  cancelIssPickupShifts: Maybe<Scalars['Boolean']>;
  cancelIssShifts: Maybe<Scalars['Boolean']>;
  /** Disable delivery options */
  disableDeliveryOptions: Maybe<Scalars['Boolean']>;
  disableIssOptions: Maybe<Scalars['Boolean']>;
  disablePickupOptions: Maybe<Scalars['Boolean']>;
  disableWarehouseLocations: Maybe<Scalars['Boolean']>;
  /**
   * Reschedule orders
   * Reschedule all impacted orders except staged orders
   */
  rescheduleDeliveryOrders: Maybe<Scalars['Boolean']>;
  setWarehouseLocationsToPickupOnly: Maybe<Scalars['Boolean']>;
  /** Zero out staffing levels */
  zeroOutStaffing: Maybe<Scalars['Boolean']>;
};

/**
 * Values specific to a store closure event
 * More documentation here https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Closure-event%3A-Level-4
 */
export type Instacart_Logistics_V1_ClosureFulfillmentEventData_Input = {
  addDeliveryBlock?: InputMaybe<Scalars['Boolean']>;
  /** Allow staged delivery orders to be batched instead of reassigning to alternative open stores */
  batchStagedBatches?: InputMaybe<Scalars['Boolean']>;
  /** Cancel shifts */
  cancelAllPartnerShifts?: InputMaybe<Scalars['Boolean']>;
  cancelIssPickupShifts?: InputMaybe<Scalars['Boolean']>;
  cancelIssShifts?: InputMaybe<Scalars['Boolean']>;
  /** Disable delivery options */
  disableDeliveryOptions?: InputMaybe<Scalars['Boolean']>;
  disableIssOptions?: InputMaybe<Scalars['Boolean']>;
  disablePickupOptions?: InputMaybe<Scalars['Boolean']>;
  disableWarehouseLocations?: InputMaybe<Scalars['Boolean']>;
  /**
   * Reschedule orders
   * Reschedule all impacted orders except staged orders
   */
  rescheduleDeliveryOrders?: InputMaybe<Scalars['Boolean']>;
  setWarehouseLocationsToPickupOnly?: InputMaybe<Scalars['Boolean']>;
  /** Zero out staffing levels */
  zeroOutStaffing?: InputMaybe<Scalars['Boolean']>;
};

/** Request to create a new closure event */
export type Instacart_Logistics_V1_CreateClosureEventRequest_Input = {
  /** The event data that is specific to closure events */
  closureData?: InputMaybe<Instacart_Logistics_V1_ClosureFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Response to create a new closure event */
export type Instacart_Logistics_V1_CreateClosureEventResponse = {
  __typename?: 'instacart_logistics_v1_CreateClosureEventResponse';
  /** The new parent event, includes paginated child events */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /**
   * Impact data for the created child events
   * Key is one of: shifts staffing delivery_blocks delivery_options pickup_options order_deliveries
   */
  impactData: Maybe<Scalars['JSON']>;
  /** The pagination information for the child events */
  paginationInstruction: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Parameters needed for creating new fulfillment capacity impacting events */
export type Instacart_Logistics_V1_CreateFulfillmentEventRequest_Input = {
  /** The event data that is specific to capacity-impacting events */
  capacityImpactData?: InputMaybe<Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Returns the created event and any errors */
export type Instacart_Logistics_V1_CreateFulfillmentEventResponse = {
  __typename?: 'instacart_logistics_v1_CreateFulfillmentEventResponse';
  /** The new parent event, includes paginated child events */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Request to create a new restricted availability event */
export type Instacart_Logistics_V1_CreateRestrictedAvailabilityEventRequest_Input = {
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /**
   * Impact data for the created child events
   * Key is one of: shifts staffing delivery_blocks delivery_options pickup_options order_deliveries
   */
  impactData?: InputMaybe<Scalars['JSON']>;
  /** The event data that is specific to restricted availability events */
  restrictedAvailabilityData?: InputMaybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input>;
};

/** Response to create a new restricted availability event */
export type Instacart_Logistics_V1_CreateRestrictedAvailabilityEventResponse = {
  __typename?: 'instacart_logistics_v1_CreateRestrictedAvailabilityEventResponse';
  /** The new parent event, includes paginated child events */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** A fulfillment event is a temporary event that affects the capacity of a location. */
export type Instacart_Logistics_V1_FulfillmentEventData = {
  __typename?: 'instacart_logistics_v1_FulfillmentEventData';
  /**
   * The percentage by which to adjust staffing for the location.
   * When there are overlapping events affecting the same location, the lowest value is used.
   */
  adjustStaffingPercentage: Maybe<Scalars['Int']>;
  /** The number of children for this event */
  childCount: Maybe<Scalars['Int']>;
  /** Child events, if this is a parent event */
  childEvents: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventData>>>;
  /** Additional data for closure events */
  closureData: Maybe<Instacart_Logistics_V1_ClosureFulfillmentEventData>;
  /** Standard Rails timestamps */
  createdAt: Maybe<Scalars['DateTime']>;
  /** Original creator of the event - email address if it was a person, system name if it's a system, e.g. "apollo" */
  creator: Maybe<Scalars['String']>;
  /** Message shown to customers about the event */
  customerMessage: Maybe<Scalars['String']>;
  /** Deleted time, if the event was cancelled before it started */
  deletedAt: Maybe<Scalars['DateTime']>;
  /** Expected rate of increasing of delivery time */
  deliveryMultiple: Maybe<Instacart_Types_V1_BigDecimal>;
  endsAt: Maybe<Scalars['DateTime']>;
  /** General type of event (see enum above) */
  eventType: Maybe<Instacart_Logistics_V1_FulfillmentEventType>;
  /** Whether we should forgive shoppers for cancelling shifts */
  forgiveReliabilityIncident: Maybe<Scalars['Boolean']>;
  /** DB row id */
  id: Maybe<Scalars['BigInt']>;
  /** Link to a Jira ticket or other documentation for the event */
  infoUrl: Maybe<Scalars['String']>;
  /** Location data, if this is a child event */
  location: Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>;
  /**
   * When a list of parent events is retrieved, we only need to know the location type and count of children
   * When a single parent event is retrieved, we need to know the full details of the children
   */
  locationType: Maybe<Instacart_Logistics_V1_FulfillmentEventLocationType>;
  /** Name of the event */
  name: Maybe<Scalars['String']>;
  /** Most recent updater of the event - email address if it was a person, system name if it's a system, e.g. "apollo" */
  owner: Maybe<Scalars['String']>;
  /**
   * Parent event id, if this is a child event
   * Note: some historical events are child events but do not have a parent event id
   */
  parentFulfillmentEventId: Maybe<Scalars['BigInt']>;
  /** Additional data for restricted availability events */
  restrictedAvailabilityData: Maybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData>;
  /** A comma-separated list of shift types for looking up staffing_levels */
  roleTypes: Maybe<Scalars['String']>;
  /** Severity (see enum above) */
  severityLevel: Maybe<Instacart_Logistics_V1_FulfillmentEventSeverityLevel>;
  /** Start and end time */
  startsAt: Maybe<Scalars['DateTime']>;
  /**
   * Event status as computed from the start and end times of the event relative to the current time.
   * Status is DELETED if the event has been soft-deleted.
   */
  status: Maybe<Instacart_Logistics_V1_FulfillmentEventStatus>;
  /**
   * The percentage by which to adjust capacity for the location.
   * When there are overlapping events affecting the same location, the highest value is used.
   */
  suggestedCapacityLevel: Maybe<Scalars['Int']>;
  /** Tags associated by the events */
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** Whether or not this event was automatically created by weather data */
  weatherEvent: Maybe<Scalars['Boolean']>;
  /** Zone for the event */
  zoneId: Maybe<Scalars['BigInt']>;
};

/** Rich(er) data about the location associated with the fulfillment event */
export type Instacart_Logistics_V1_FulfillmentEventLocationData = {
  __typename?: 'instacart_logistics_v1_FulfillmentEventLocationData';
  /** DB row id */
  id: Maybe<Scalars['BigInt']>;
  locationType: Maybe<Instacart_Logistics_V1_FulfillmentEventLocationType>;
  /** Name from association table */
  name: Maybe<Scalars['String']>;
};

/** Possible types of locations associated with a fulfillment event */
export enum Instacart_Logistics_V1_FulfillmentEventLocationType {
  Country = 'COUNTRY',
  LocationTypeUnspecified = 'LOCATION_TYPE_UNSPECIFIED',
  PostalCode = 'POSTAL_CODE',
  Region = 'REGION',
  WarehouseLocation = 'WAREHOUSE_LOCATION',
  WarehouseZone = 'WAREHOUSE_ZONE',
  Zone = 'ZONE'
}

/**
 * Event severities, see more at
 * https://docs.google.com/document/d/1lSGwGQU-0_ED55yH9P-TDFaCxVdXqaLV/edit#heading=h.994m78oh6kur
 *
 * Fulfillment capacity-impacting events (severities 1-3, formerly "Weather events")
 * These types of events cause a reduction in the number of orders we can fulfill. The capacity level (reduction)
 * for these events can be set to anything from -5 to -100. We can also customize the delivery multiple
 * (the factor indicating expected delivery time increase) and customer facing messages for these events.
 *
 * Closure event/extreme weather event (severity 4)
 * This severity causes closure of specific stores (WarehouseLocation).
 *
 * Restricted availability (severity 5, previous known as "safe mode")
 * This severity causes the system to restrict available service options, e.g. disallow all delivery orders.
 *
 * Note that while levels 1-3 and 6 are related, the severity levels are not necessarily contiguous or strictly
 * ordered.
 */
export enum Instacart_Logistics_V1_FulfillmentEventSeverityLevel {
  EventSeverityUnspecified = 'EVENT_SEVERITY_UNSPECIFIED',
  HighCapacityImpact = 'HIGH_CAPACITY_IMPACT',
  LowCapacityImpact = 'LOW_CAPACITY_IMPACT',
  MediumCapacityImpact = 'MEDIUM_CAPACITY_IMPACT',
  RestrictedServiceOptions = 'RESTRICTED_SERVICE_OPTIONS',
  StoreClosure = 'STORE_CLOSURE',
  /**
   * this is a new severity level that is used to indicate that the event has no capacity impact;
   * since 0 is reserved for "unspecified", and other values were already defined, we use 6 here.
   */
  ZeroCapacityImpact = 'ZERO_CAPACITY_IMPACT'
}

/**
 * Event status, computed from the start and end times of the event relative to the current time. Status is
 * DELETED if the event has been soft-deleted.
 */
export enum Instacart_Logistics_V1_FulfillmentEventStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  EventStatusUnspecified = 'EVENT_STATUS_UNSPECIFIED',
  InProgress = 'IN_PROGRESS',
  Upcoming = 'UPCOMING'
}

/**
 * Event types, see more at
 * https://docs.google.com/document/d/1lSGwGQU-0_ED55yH9P-TDFaCxVdXqaLV/edit#heading=h.rs2ipnbn0urn
 * Note: We are expecting a new and more comprehensive list of types in the future.
 */
export enum Instacart_Logistics_V1_FulfillmentEventType {
  AirQuality = 'AIR_QUALITY',
  CityEvent = 'CITY_EVENT',
  Cold = 'COLD',
  Cultural = 'CULTURAL',
  EventTypeUnspecified = 'EVENT_TYPE_UNSPECIFIED',
  Experiment = 'EXPERIMENT',
  Flood = 'FLOOD',
  Heatwave = 'HEATWAVE',
  Holiday = 'HOLIDAY',
  Hurricane = 'HURRICANE',
  Other = 'OTHER',
  Outage = 'OUTAGE',
  Rain = 'RAIN',
  Snow = 'SNOW',
  Tornado = 'TORNADO',
  TropicalStorm = 'TROPICAL_STORM',
  Wildfire = 'WILDFIRE',
  Wind = 'WIND',
  WinterStorm = 'WINTER_STORM'
}

export type Instacart_Logistics_V1_GetFulfillmentEventRequest_Input = {
  fulfillmentEventId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Logistics_V1_GetFulfillmentEventResponse = {
  __typename?: 'instacart_logistics_v1_GetFulfillmentEventResponse';
  createdAt: Maybe<Scalars['DateTime']>;
  customerMessage: Maybe<Scalars['String']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  /** Expected rate of increasing of delivery time */
  deliveryMultiple: Maybe<Scalars['Float']>;
  endsAt: Maybe<Scalars['DateTime']>;
  eventType: Maybe<Instacart_Logistics_V1_GetFulfillmentEventResponse_EventType>;
  /** Whether we should forgive shoppers for cancelling shifts */
  forgiveReliabilityIncident: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['BigInt']>;
  locationId: Maybe<Scalars['BigInt']>;
  locationType: Maybe<Instacart_Logistics_V1_GetFulfillmentEventResponse_LocationType>;
  locked: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  /** Author of the event */
  owner: Maybe<Scalars['String']>;
  roleTypes: Maybe<Scalars['String']>;
  /** Level of the fulfillment event */
  severityLevel: Maybe<Scalars['Int']>;
  shopperMessage: Maybe<Scalars['String']>;
  startsAt: Maybe<Scalars['DateTime']>;
  /** Adjustment of the capacity level */
  suggestedCapacityLevel: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  weatherEvent: Maybe<Scalars['Boolean']>;
  zoneId: Maybe<Scalars['BigInt']>;
};

export enum Instacart_Logistics_V1_GetFulfillmentEventResponse_EventType {
  Cultural = 'CULTURAL',
  Experiment = 'EXPERIMENT',
  Other = 'OTHER',
  Rain = 'RAIN',
  Snow = 'SNOW'
}

export enum Instacart_Logistics_V1_GetFulfillmentEventResponse_LocationType {
  Country = 'COUNTRY',
  PostalCode = 'POSTAL_CODE',
  Region = 'REGION',
  WarehouseLocation = 'WAREHOUSE_LOCATION',
  WarehouseZone = 'WAREHOUSE_ZONE',
  Zone = 'ZONE'
}

/** Return all creators, sorted alphabetically */
export type Instacart_Logistics_V1_ListCreatorsResponse = {
  __typename?: 'instacart_logistics_v1_ListCreatorsResponse';
  /** List of creators */
  creators: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Request fulfillment events according to specified criteria. All parameters are optional. */
export type Instacart_Logistics_V1_ListFulfillmentEventsRequest_Input = {
  /** Find events with these countries */
  countryIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Find events created by these users */
  creators?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Find events which end after this date. */
  endsAfter?: InputMaybe<Scalars['DateTime']>;
  /** Select events with these statuses */
  eventStatuses?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventStatus>>>;
  /** Select events with these event types */
  eventTypes?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventType>>>;
  /** Select only events where the location_type matches this value (typically WarehouseLocation is selected here) */
  locationTypes?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventLocationType>>>;
  /** Find an event with one of these owners (most recent updater) */
  owners?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Information on how to paginate the results of a list/filter operation. This feeds into the will_paginate gem. */
  paginationInstruction?: InputMaybe<Instacart_Logistics_V1_PaginationInstruction_Input>;
  /**
   * The parent event id from which a set of location-specific events were generated; this will return
   * a list of only the child events for that parent.
   */
  parentEventId?: InputMaybe<Scalars['BigInt']>;
  /** Find events with these postal codes */
  postalCodeIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Find events in selected region (not used, see region_ids) */
  regionId?: InputMaybe<Scalars['BigInt']>;
  /** Find events with these regions */
  regionIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** A search string that matches the event name or link content (e.g. Jira ticket id). */
  searchString?: InputMaybe<Scalars['String']>;
  /** Select events with these severity levels */
  severityLevels?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_FulfillmentEventSeverityLevel>>>;
  /**
   * Information on how to sort the results (list of attribute and direction); currently the front-end only
   * supports a single sort attribute but this is a list in case users ask for more nuance in future.
   */
  sortingInstruction?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_SortingInstruction_Input>>>;
  /** Find events which start before this date. */
  startsBefore?: InputMaybe<Scalars['DateTime']>;
  /** Select events with these tags */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Find events with these warehouse locations */
  warehouseLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** Find events with these zones */
  zoneIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

export type Instacart_Logistics_V1_ListFulfillmentEventsResponse = {
  __typename?: 'instacart_logistics_v1_ListFulfillmentEventsResponse';
  /** How many total events match the criteria */
  eventCount: Maybe<Scalars['Int']>;
  /**
   * Paginated and sorted array of fulfillment events that match the given criteria.
   *
   * If a parent event id was not specified in the search criteria, this ia a list of parent events
   * where each parent event contains a list of location-specific child events.
   *
   * If the search criteria includes a parent_event_id, then this will return just that parent, with
   * child events nested as usual.
   */
  fulfillmentEvents: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventData>>>;
  /** How the results are paginated */
  paginationInstruction: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Return all tags, sorted alphabetically */
export type Instacart_Logistics_V1_ListTagsResponse = {
  __typename?: 'instacart_logistics_v1_ListTagsResponse';
  /** List of creators */
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Information on how to paginate the results of a list/filter operation. This feeds into the will_paginate gem. */
export type Instacart_Logistics_V1_PaginationInstruction = {
  __typename?: 'instacart_logistics_v1_PaginationInstruction';
  /** The page of results to show, will default if nil */
  page: Maybe<Scalars['Int']>;
  /** The number of results to show per page, will default if nil */
  perPage: Maybe<Scalars['Int']>;
};

/** Information on how to paginate the results of a list/filter operation. This feeds into the will_paginate gem. */
export type Instacart_Logistics_V1_PaginationInstruction_Input = {
  /** The page of results to show, will default if nil */
  page?: InputMaybe<Scalars['Int']>;
  /** The number of results to show per page, will default if nil */
  perPage?: InputMaybe<Scalars['Int']>;
};

/**
 * Restricted availability event data
 * More documentation here:https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Safe-Mode
 */
export type Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData = {
  __typename?: 'instacart_logistics_v1_RestrictedAvailabilityFulfillmentEventData';
  restrictServiceOptionTypes: Maybe<Array<Maybe<Instacart_Logistics_V1_RestrictedServiceOptionType>>>;
  storeCloseBuffer: Maybe<Scalars['Int']>;
};

/**
 * Restricted availability event data
 * More documentation here:https://instacart.atlassian.net/wiki/spaces/OPS/pages/3781492737/Fulfillment+Event+Tool+SOP+Updated#Safe-Mode
 */
export type Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input = {
  restrictServiceOptionTypes?: InputMaybe<Array<InputMaybe<Instacart_Logistics_V1_RestrictedServiceOptionType>>>;
  storeCloseBuffer?: InputMaybe<Scalars['Int']>;
};

/** Types of service options that can be restricted */
export enum Instacart_Logistics_V1_RestrictedServiceOptionType {
  Asap = 'ASAP',
  FiveHour = 'FIVE_HOUR',
  LimitedAvailability = 'LIMITED_AVAILABILITY',
  LimitedAvailabilityFiveHour = 'LIMITED_AVAILABILITY_FIVE_HOUR',
  LimitedAvailabilityFourHour = 'LIMITED_AVAILABILITY_FOUR_HOUR',
  LimitedAvailabilityOneHour = 'LIMITED_AVAILABILITY_ONE_HOUR',
  LimitedAvailabilityThreeHour = 'LIMITED_AVAILABILITY_THREE_HOUR',
  LimitedAvailabilityTwoHour = 'LIMITED_AVAILABILITY_TWO_HOUR',
  Pickup = 'PICKUP',
  PickupEta = 'PICKUP_ETA',
  PriorityEta = 'PRIORITY_ETA',
  PriorityEtaByEndAt = 'PRIORITY_ETA_BY_END_AT',
  StandardEta = 'STANDARD_ETA',
  StandardEtaByEndAt = 'STANDARD_ETA_BY_END_AT',
  TwoHour = 'TWO_HOUR',
  UnspecifiedRestrictedServiceOptionType = 'UNSPECIFIED_RESTRICTED_SERVICE_OPTION_TYPE'
}

/** Information on how to sort the results of a list/filter operation */
export enum Instacart_Logistics_V1_SortingDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
  SortingDirectionUnspecified = 'SORTING_DIRECTION_UNSPECIFIED'
}

export type Instacart_Logistics_V1_SortingInstruction_Input = {
  /** The direction to sort, defaults to ascending */
  direction?: InputMaybe<Instacart_Logistics_V1_SortingDirection>;
  /** The name of the field to sort on, e.g. starts_at */
  fieldName?: InputMaybe<Scalars['String']>;
};

/**
 * Request to update one or more closure events
 * All events must be children of the same parent
 */
export type Instacart_Logistics_V1_UpdateClosureEventsRequest_Input = {
  /** The event data that is specific to closure events */
  closureData?: InputMaybe<Instacart_Logistics_V1_ClosureFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** IDs of the events to update */
  fulfillmentEventIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response to update one or more closure events */
export type Instacart_Logistics_V1_UpdateClosureEventsResponse = {
  __typename?: 'instacart_logistics_v1_UpdateClosureEventsResponse';
  /** The updated parent event, includes paginated child events */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/**
 * Request to update one or more fulfillment events
 * All events must be children of the same parent
 */
export type Instacart_Logistics_V1_UpdateFulfillmentEventsRequest_Input = {
  /** The event data that is specific to capacity-impacting events */
  capacityImpactData?: InputMaybe<Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** IDs of the events to update */
  fulfillmentEventIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
};

/** Response to update one or more fulfillment events */
export type Instacart_Logistics_V1_UpdateFulfillmentEventsResponse = {
  __typename?: 'instacart_logistics_v1_UpdateFulfillmentEventsResponse';
  /** The updated parent event, includes paginated child events */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/**
 * Request to update one or more restricted availability events
 * All events must be children of the same parent
 */
export type Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsRequest_Input = {
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** IDs of the events to update */
  fulfillmentEventIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>>>;
  /** The event data that is specific to restricted availability events */
  restrictedAvailabilityData?: InputMaybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input>;
};

/** Response to update a restricted availability event */
export type Instacart_Logistics_V1_UpdateRestrictedAvailabilityEventsResponse = {
  __typename?: 'instacart_logistics_v1_UpdateRestrictedAvailabilityEventsResponse';
  /** The updated parent event, includes paginated child events */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** The pagination information for the child events */
  paginationInstruction: Maybe<Instacart_Logistics_V1_PaginationInstruction>;
};

/** Request to validate a closure event */
export type Instacart_Logistics_V1_ValidateNewClosureEventRequest_Input = {
  /** The event data that is specific to closure events */
  closureData?: InputMaybe<Instacart_Logistics_V1_ClosureFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Response to validate a closure event */
export type Instacart_Logistics_V1_ValidateNewClosureEventResponse = {
  __typename?: 'instacart_logistics_v1_ValidateNewClosureEventResponse';
  /** Any errors that occurred during validation */
  errors: Maybe<Array<Maybe<Instacart_Logistics_V1_ValidationError>>>;
  /** The validated or created event */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** A list of locations, with names and ids */
  locations: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>>>;
  /** Is the event valid? */
  valid: Maybe<Scalars['Boolean']>;
};

/** Parameters needed for validating new fulfillment capacity impacting events */
export type Instacart_Logistics_V1_ValidateNewFulfillmentEventRequest_Input = {
  /** The event data that is specific to capacity-impacting events */
  capacityImpactData?: InputMaybe<Instacart_Logistics_V1_CapacityImpactFulfillmentEventData_Input>;
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
};

/** Returns the validated or created event and any errors */
export type Instacart_Logistics_V1_ValidateNewFulfillmentEventResponse = {
  __typename?: 'instacart_logistics_v1_ValidateNewFulfillmentEventResponse';
  /** Any errors that occurred during validation */
  errors: Maybe<Array<Maybe<Instacart_Logistics_V1_ValidationError>>>;
  /** Data for the new parent event */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** A list of locations, with names and ids */
  locations: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>>>;
  /** Is the event valid? */
  valid: Maybe<Scalars['Boolean']>;
};

/** Request to validate a restricted availability event */
export type Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventRequest_Input = {
  /** The event data that is shared among all events */
  eventData?: InputMaybe<Instacart_Logistics_V1_BasicNewFulfillmentEventData_Input>;
  /** The event data that is specific to restricted availability events */
  restrictedAvailabilityData?: InputMaybe<Instacart_Logistics_V1_RestrictedAvailabilityFulfillmentEventData_Input>;
};

/** Response to validate a restricted availability event */
export type Instacart_Logistics_V1_ValidateNewRestrictedAvailabilityEventResponse = {
  __typename?: 'instacart_logistics_v1_ValidateNewRestrictedAvailabilityEventResponse';
  /** Any errors that occurred during validation */
  errors: Maybe<Array<Maybe<Instacart_Logistics_V1_ValidationError>>>;
  /** The validated or created event */
  event: Maybe<Instacart_Logistics_V1_FulfillmentEventData>;
  /** A list of locations, with names and ids */
  locations: Maybe<Array<Maybe<Instacart_Logistics_V1_FulfillmentEventLocationData>>>;
  /** Is the event valid? */
  valid: Maybe<Scalars['Boolean']>;
};

/** Validation error messages for a field, one field might have more than one error */
export type Instacart_Logistics_V1_ValidationError = {
  __typename?: 'instacart_logistics_v1_ValidationError';
  /** The error messages */
  errors: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The name of the field with the error, corresponds to the field name in FulfillmentEventData */
  fieldName: Maybe<Scalars['String']>;
};

/** PRIMITIVES */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Entitlement = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_Entitlement';
  createdAt: Maybe<Scalars['DateTime']>;
  /** which environment was this entitlement from */
  fromEnv: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment>;
  /** true if the entitlement is integrated into IPP and that entitlement changes are expected to near immediately change the Retailer UX on IPP, false otherwise. */
  hasIppIntegration: Maybe<Scalars['Boolean']>;
  /** the main identifier for this entitlement */
  id: Maybe<Scalars['String']>;
  /** more human readable name / display string */
  name: Maybe<Scalars['String']>;
  /** the slack handle to contact for the team/individual that owns the entitlement */
  ownerSlackHandle: Maybe<Scalars['String']>;
  /** true if the entitlement requires some form of manual effort in order to unlock the feature gated, false otherise. */
  requiresManualMigration: Maybe<Scalars['Boolean']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_EntitlementShallowDetails';
  entitlement: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Entitlement>;
  /** how many packages use this entitlement */
  numReferencingPackages: Maybe<Scalars['Int']>;
  /** how many retailers reference this entitlement */
  numReferencingRetailerAdditionalEntitlements: Maybe<Scalars['Int']>;
};

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementsSearchByType {
  EntitlementIdOrName = 'ENTITLEMENT_ID_OR_NAME',
  EnumEntitlementUsagesSearchTypeUnspecified = 'ENUM_ENTITLEMENT_USAGES_SEARCH_TYPE_UNSPECIFIED'
}

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment {
  DevelopmentEnvironment = 'DEVELOPMENT_ENVIRONMENT',
  ProductionEnvironment = 'PRODUCTION_ENVIRONMENT',
  StagingEnvironment = 'STAGING_ENVIRONMENT',
  UnspecifiedEnvironment = 'UNSPECIFIED_ENVIRONMENT'
}

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsRequest_Input = {
  byEntitlementId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementDetailsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetEntitlementDetailsResponse';
  entitlement: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Entitlement>;
  packages: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails>>>;
  retailerEntitlementProfiles: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails>>>;
};

/** ENTITLEMENTS API */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsRequest_Input = {
  searchBy?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementsSearchByType>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetEntitlementsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetEntitlementsResponse';
  entitlements: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails>>>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsRequest_Input = {
  byPackageId?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackageDetailsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetPackageDetailsResponse';
  entitlements: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails>>>;
  package: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Package>;
  retailerEntitlementProfiles: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails>>>;
};

/** PACKAGES API */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesRequest_Input = {
  searchBy?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackagesSearchByType>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetPackagesResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetPackagesResponse';
  packages: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails>>>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsRequest_Input = {
  byRetailerId?: InputMaybe<Scalars['BigInt']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfileDetailsResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetRetailerEntitlementProfileDetailsResponse';
  additionalEntitlements: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_EntitlementShallowDetails>>>;
  packages: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails>>>;
  retailerEntitlementProfile: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfile>;
};

/** RETAILER ENTITLEMENT PROFILES API */
export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesRequest_Input = {
  searchBy?: InputMaybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfilesSearchByType>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_GetRetailerEntitlementProfilesResponse = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_GetRetailerEntitlementProfilesResponse';
  retailerEntitlementProfiles: Maybe<Array<Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails>>>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Package = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_Package';
  createdAt: Maybe<Scalars['DateTime']>;
  /** a list of the entitlement IDs that are included in this package */
  entitlementIds: Maybe<Array<Maybe<Scalars['String']>>>;
  /** which environment was this package from */
  fromEnv: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment>;
  /** the main identifier for this package */
  id: Maybe<Scalars['String']>;
  /** more human readable name / display string */
  name: Maybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackageShallowDetails = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_PackageShallowDetails';
  /** how many entitlements are in this package */
  numEntitlements: Maybe<Scalars['Int']>;
  /** how many retailers reference this package */
  numReferencingRetailerEntitlementProfiles: Maybe<Scalars['Int']>;
  package: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_Package>;
};

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_PackagesSearchByType {
  EnumPackagesSearchTypeUnspecified = 'ENUM_PACKAGES_SEARCH_TYPE_UNSPECIFIED',
  PackageIdOrName = 'PACKAGE_ID_OR_NAME'
}

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfile = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_RetailerEntitlementProfile';
  /** the list of a la carte entitlements given to this retailer profile */
  additionalEntitlementIds: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** the denormalized list of IDs this retailer is entitled to. this destructures the packages referenced entitlements. */
  denormalizedEntitlementIds: Maybe<Array<Maybe<Scalars['String']>>>;
  /** which environment was this retailer profile from */
  fromEnv: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_FromEnvironment>;
  /** the list of packages applied to this retailer profile */
  packageIds: Maybe<Array<Maybe<Scalars['String']>>>;
  /** the canonical warehouse model */
  warehouseId: Maybe<Scalars['BigInt']>;
  /** the canonical warehouse model */
  warehouseName: Maybe<Scalars['String']>;
};

export type Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfileShallowDetails = {
  __typename?: 'instacart_retailer_tools_retailer_platform_api_v1_RetailerEntitlementProfileShallowDetails';
  /** how many custom additional entitlements are used by this retailer (not included via packages) */
  numAdditionalEntitlements: Maybe<Scalars['Int']>;
  /** how many packages are used by this retailer */
  numPackages: Maybe<Scalars['Int']>;
  retailerEntitlementProfile: Maybe<Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfile>;
};

export enum Instacart_Retailer_Tools_Retailer_Platform_Api_V1_RetailerEntitlementProfilesSearchByType {
  EnumRetailerEntitlementProfilesSearchTypeUnspecified = 'ENUM_RETAILER_ENTITLEMENT_PROFILES_SEARCH_TYPE_UNSPECIFIED',
  RetalerId = 'RETALER_ID'
}

/**
 * Represents an accurate fractional value
 * Similar to google.type.Money
 */
export type Instacart_Types_V1_BigDecimal = {
  __typename?: 'instacart_types_v1_BigDecimal';
  /**
   * Nano units of the amount (10^-9)
   * Must be same sign as units
   * Example: The value -1.25 is represented as units=-1 and nanos=-250000000
   */
  nanos: Maybe<Scalars['Int']>;
  /** Whole units part of the amount */
  units: Maybe<Scalars['BigInt']>;
};

/**
 * Represents an accurate fractional value
 * Similar to google.type.Money
 */
export type Instacart_Types_V1_BigDecimal_Input = {
  /**
   * Nano units of the amount (10^-9)
   * Must be same sign as units
   * Example: The value -1.25 is represented as units=-1 and nanos=-250000000
   */
  nanos?: InputMaybe<Scalars['Int']>;
  /** Whole units part of the amount */
  units?: InputMaybe<Scalars['BigInt']>;
};

/**
 * Expresses a measurement unit based on the UCUM standard
 * which builds on ANSI_X3_50 and ISO_2955_1983 with explicit
 * emphasis on machine-to-machine interoperability.
 * See: http://unitsofmeasure.org/ucum.html.
 *
 * Services should document the unit_codes they support.
 * They should raise clear errors if unsupported unit_codes are passed.
 */
export type Instacart_Types_V1_MeasuredQuantity = {
  __typename?: 'instacart_types_v1_MeasuredQuantity';
  /**
   * The quantity in relevant units. Quantity may be positive, negative or zero in a message.
   * The semantics of negative quantities are application-specific.
   */
  quantity: Maybe<Scalars['Float']>;
  /**
   * The measurement unit for this quantity. If this is the unknown MeasurementUnit
   * then the quantity field cannot be deterministically interpreted without
   * outside knowledge or heuristics. Valid MeasurementUnits should be used for all
   * new data.
   */
  unit: Maybe<Instacart_Types_V1_MeasurementUnit>;
};

/**
 * Expresses a measurement unit based on the UCUM standard
 * which builds on ANSI_X3_50 and ISO_2955_1983 with explicit
 * emphasis on machine-to-machine interoperability.
 * See: http://unitsofmeasure.org/ucum.html.
 *
 * Services should document the unit_codes they support.
 * They should raise clear errors if unsupported unit_codes are passed.
 */
export type Instacart_Types_V1_MeasurementUnit = {
  __typename?: 'instacart_types_v1_MeasurementUnit';
  /** Cost unit of the item. */
  costUnit: Maybe<Instacart_Types_V1_MeasurementUnit_CostUnit>;
  /**
   * Represents the case-sensitive symbols (c/s) defined by the
   * UCUM specification. Non-matching MeasurementUnits *might* be
   * convertible within the UCUM system by an application that
   * understands the semantics of UCUM symbols, base units, and
   * derived units.
   *
   * Simple equality comparison of a MeasurementUnit requires only
   * a case-sensitive comparison of `unit_code`.
   * Confirming that a `unit_code` is valid in the UCUM system requires
   * a full understanding the specification.
   * The protobuf default string value is explicitly *not* a valid symbol
   * in the UCUM system and represents an unknown MeasurementUnit
   * rather than a literal code. An unknown unit code does not match any
   * other unit code (including unknown).
   *
   * Note that the Arbitrary Unit code "[arb'U]" is used to represent "per each" quantities
   * where the concept of "each" is relative to an identified thing.
   * See: https://en.wikipedia.org/wiki/Arbitrary_unit
   *
   * Common unit_codes used in Instacart flows include:
   * Arbitrary Unit (per each):               "[arb'U]"
   * US Fluid Ounce (fluid volume):           "[foz_us]"
   * Avoirdupois Pound (mass aka weight):     "[lb_av]"
   */
  unitCode: Maybe<Scalars['String']>;
};

export enum Instacart_Types_V1_MeasurementUnit_CostUnit {
  CostUnitUnspecified = 'COST_UNIT_UNSPECIFIED',
  /** Unit is measured as each */
  Each = 'EACH',
  /** Fluid Ounce */
  FlOz = 'FL_OZ',
  /** Gallons */
  Gallon = 'GALLON',
  /** grams, 1/1000 of a KG */
  Gram = 'GRAM',
  /** Kilogram */
  Kg = 'KG',
  /** Pounds */
  Lb = 'LB',
  /** 1 L = 1 Liter = 1_000 MilliLiter */
  Liter = 'LITER',
  /** MilliLiter, and not MegaLiter */
  Ml = 'ML',
  /** Ounce */
  Oz = 'OZ',
  /** Pint; 1/2 of a Gallon */
  Pint = 'PINT',
  /** Quart; 1/4 of a Gallon */
  Quart = 'QUART'
}

export type PartnerPickGetCapacityRecommendationsQueryVariables = Exact<{
  retailerLocationId: Scalars['ID'];
  date: Scalars['ISO8601Date'];
}>;


export type PartnerPickGetCapacityRecommendationsQuery = { __typename?: 'Query', partnerPickGetCapacityRecommendations: { __typename?: 'PartnerPickCapacityGetCapacityRecommendationsResponse', allowedRecommendationTypes: Array<RecommendationTypeEnum>, recommendations: Array<{ __typename?: 'PartnerPickCapacityRecommendation', id: string, retailerLocationId: string, date: any, recommendationType: RecommendationTypeEnum, shiftType: ShiftTypeEnum, startsAt: any, endsAt: any }> } };

export type GetRetailerPickupMetadataQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRetailerPickupMetadataQuery = { __typename?: 'Query', retailerPickupMetadata: { __typename?: 'RetailersRetailerPickupMetadata', earlyPickingHour: number } };

export type GetStoreHoursByDateQueryVariables = Exact<{
  retailerLocationIds?: InputMaybe<Array<InputMaybe<Scalars['BigInt']>> | InputMaybe<Scalars['BigInt']>>;
  startDate?: InputMaybe<GoogleTypeDateInput>;
  endDate?: InputMaybe<GoogleTypeDateInput>;
  verticalType?: InputMaybe<VerticalType>;
}>;


export type GetStoreHoursByDateQuery = { __typename?: 'Query', availabilityGetStoreHoursByDate: { __typename?: 'GetStoreHoursByDateResponse', storeHours: Array<{ __typename?: 'StoreHoursByDate', retailerLocationId: string, startsAt: { __typename?: 'GoogleTypeTimeOfDay', hours: number, minutes: number }, endsAt: { __typename?: 'GoogleTypeTimeOfDay', hours: number, minutes: number } }> } };


export const PartnerPickGetCapacityRecommendationsDocument = gql`
    query partnerPickGetCapacityRecommendations($retailerLocationId: ID!, $date: ISO8601Date!) {
  partnerPickGetCapacityRecommendations(
    retailerLocationId: $retailerLocationId
    date: $date
  ) {
    recommendations {
      id
      retailerLocationId
      date
      recommendationType
      shiftType
      startsAt
      endsAt
    }
    allowedRecommendationTypes
  }
}
    `;

/**
 * __usePartnerPickGetCapacityRecommendationsQuery__
 *
 * To run a query within a React component, call `usePartnerPickGetCapacityRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerPickGetCapacityRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerPickGetCapacityRecommendationsQuery({
 *   variables: {
 *      retailerLocationId: // value for 'retailerLocationId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePartnerPickGetCapacityRecommendationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PartnerPickGetCapacityRecommendationsQuery, PartnerPickGetCapacityRecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<PartnerPickGetCapacityRecommendationsQuery, PartnerPickGetCapacityRecommendationsQueryVariables>(PartnerPickGetCapacityRecommendationsDocument, options);
      }
export function usePartnerPickGetCapacityRecommendationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerPickGetCapacityRecommendationsQuery, PartnerPickGetCapacityRecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<PartnerPickGetCapacityRecommendationsQuery, PartnerPickGetCapacityRecommendationsQueryVariables>(PartnerPickGetCapacityRecommendationsDocument, options);
        }
export type PartnerPickGetCapacityRecommendationsQueryHookResult = ReturnType<typeof usePartnerPickGetCapacityRecommendationsQuery>;
export type PartnerPickGetCapacityRecommendationsLazyQueryHookResult = ReturnType<typeof usePartnerPickGetCapacityRecommendationsLazyQuery>;
export type PartnerPickGetCapacityRecommendationsQueryResult = ApolloReactCommon.QueryResult<PartnerPickGetCapacityRecommendationsQuery, PartnerPickGetCapacityRecommendationsQueryVariables>;
export const GetRetailerPickupMetadataDocument = gql`
    query getRetailerPickupMetadata($id: ID!) {
  retailerPickupMetadata(id: $id) {
    earlyPickingHour
  }
}
    `;

/**
 * __useGetRetailerPickupMetadataQuery__
 *
 * To run a query within a React component, call `useGetRetailerPickupMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetailerPickupMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetailerPickupMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRetailerPickupMetadataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRetailerPickupMetadataQuery, GetRetailerPickupMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRetailerPickupMetadataQuery, GetRetailerPickupMetadataQueryVariables>(GetRetailerPickupMetadataDocument, options);
      }
export function useGetRetailerPickupMetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRetailerPickupMetadataQuery, GetRetailerPickupMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRetailerPickupMetadataQuery, GetRetailerPickupMetadataQueryVariables>(GetRetailerPickupMetadataDocument, options);
        }
export type GetRetailerPickupMetadataQueryHookResult = ReturnType<typeof useGetRetailerPickupMetadataQuery>;
export type GetRetailerPickupMetadataLazyQueryHookResult = ReturnType<typeof useGetRetailerPickupMetadataLazyQuery>;
export type GetRetailerPickupMetadataQueryResult = ApolloReactCommon.QueryResult<GetRetailerPickupMetadataQuery, GetRetailerPickupMetadataQueryVariables>;
export const GetStoreHoursByDateDocument = gql`
    query getStoreHoursByDate($retailerLocationIds: [BigInt], $startDate: GoogleTypeDateInput, $endDate: GoogleTypeDateInput, $verticalType: VerticalType) {
  availabilityGetStoreHoursByDate(
    input: {retailerLocationIds: $retailerLocationIds, startDate: $startDate, endDate: $endDate, verticalType: $verticalType}
  ) {
    storeHours {
      retailerLocationId
      startsAt {
        hours
        minutes
      }
      endsAt {
        hours
        minutes
      }
    }
  }
}
    `;

/**
 * __useGetStoreHoursByDateQuery__
 *
 * To run a query within a React component, call `useGetStoreHoursByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreHoursByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreHoursByDateQuery({
 *   variables: {
 *      retailerLocationIds: // value for 'retailerLocationIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      verticalType: // value for 'verticalType'
 *   },
 * });
 */
export function useGetStoreHoursByDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStoreHoursByDateQuery, GetStoreHoursByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStoreHoursByDateQuery, GetStoreHoursByDateQueryVariables>(GetStoreHoursByDateDocument, options);
      }
export function useGetStoreHoursByDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStoreHoursByDateQuery, GetStoreHoursByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStoreHoursByDateQuery, GetStoreHoursByDateQueryVariables>(GetStoreHoursByDateDocument, options);
        }
export type GetStoreHoursByDateQueryHookResult = ReturnType<typeof useGetStoreHoursByDateQuery>;
export type GetStoreHoursByDateLazyQueryHookResult = ReturnType<typeof useGetStoreHoursByDateLazyQuery>;
export type GetStoreHoursByDateQueryResult = ApolloReactCommon.QueryResult<GetStoreHoursByDateQuery, GetStoreHoursByDateQueryVariables>;