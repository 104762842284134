import { WarehouseLocationFeature } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const InsightsPortalStoreLaborPerformanceV2AccessControl: DomainAccessControlConfig = [
  {
    warehouseLocationFeatures: WarehouseLocationFeature.RetailerPickup,
    permissions: Permission.Analytics,
    featureToggles: FeatureToggle.InsightsPortalStoreLaborPerformanceV2,
  },
  {
    warehouseLocationFeatures: WarehouseLocationFeature.RetailerPickup,
    permissions: Permission.PickupAnalytics,
    featureToggles: FeatureToggle.InsightsPortalStoreLaborPerformanceV2,
  },
]
