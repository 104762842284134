const EN_US = {
  'storeConfigurationDomain.title': 'Store config admin',
  'storeConfigurationDomain.current': 'Current',

  'storeConfigurationDomain.actions.viewChanges': 'View Changes',
  'storeConfigurationDomain.actions.cancel': 'Cancel',
  'storeConfigurationDomain.actions.confirm': 'Confirm',
  'storeConfigurationDomain.actions.save': 'Save',
  'storeConfigurationDomain.actions.publish': 'Publish',
  'storeConfigurationDomain.actions.saveAndPublish': 'Save & Publish',
  'storeConfigurationDomain.actions.preview': 'Preview',

  'storeConfigurationDomain.messages.confirmDraftChanges':
    'Please double check your changes below before publishing.',
  'storeConfigurationDomain.messages.successfullySavedDraft': 'Your draft has been saved!',
  'storeConfigurationDomain.messages.successfullyPublishedDraft': 'Your draft has been published!',
  'storeConfigurationDomain.messages.saved': 'Saved!',
  'storeConfigurationDomain.messages.published': 'Published!',
  'storeConfigurationDomain.messages.removed': 'Removed',
  'storeConfigurationDomain.messages.previewCannotBeViewed': 'Previews cannot be viewed in staging',

  'storeConfigurationDomain.headers.confirmDraftChanges': 'Confirm Draft Changes',
  'storeConfigurationDomain.headers.draftChanges': 'Draft Changes',
  'storeConfigurationDomain.headers.previousValue': 'Previous value',
  'storeConfigurationDomain.headers.newValue': 'New value',
  'storeConfigurationDomain.headers.currentlyPublished': 'Currently published',
  'storeConfigurationDomain.headers.newChanges': 'New changes',

  'storeConfigurationDomain.table.errorMsg': 'Error loading drafts',
  'storeConfigurationDomain.table.emptyMsg': 'No drafts available',

  'storeConfigurationDomain.errors.error': 'Error',
  'storeConfigurationDomain.errors.invalidFields': 'Your draft has invalid fields',
  'storeConfigurationDomain.errors.failedToSaveDraft': 'Your draft failed to save.',
  'storeConfigurationDomain.errors.failedToPublishDraft': 'Your draft failed to publish.',
  'storeConfigurationDomain.errors.draftIsStale':
    'Your draft is stale, please create a new draft from the latest revision.',

  'storeConfigurationDomain.table.updatedAt': 'Updated at',
} as const

export default EN_US
