import { type DashRouteType, URL_PUBLIC } from '@retailer-platform/dashboard/routing'

const URL_PUBLIC_AUTHENTICATION = URL_PUBLIC
const URL_PUBLIC_AUTHENTICATION_LOG_IN = `${URL_PUBLIC_AUTHENTICATION}/log-in`
// const URL_PUBLIC_AUTHENTICATION_LOG_IN_V_2 = `${URL_PUBLIC_AUTHENTICATION}/log-in-v2`
const URL_PUBLIC_AUTHENTICATION_CREATE_ACCOUNT = `${URL_PUBLIC_AUTHENTICATION}/create-account`
const URL_PUBLIC_AUTHENTICATION_REQUEST_EMAIL_VERIFICATION = `${URL_PUBLIC_AUTHENTICATION}/request-email-verification`
const URL_PUBLIC_AUTHENTICATION_EMAIL_VERIFICATION = `${URL_PUBLIC_AUTHENTICATION}/email-verification/:token`
const URL_PUBLIC_AUTHENTICATION_PENDING_EMAIL_VERIFICATION = `${URL_PUBLIC_AUTHENTICATION}/pending-email-verification`
const URL_PUBLIC_AUTHENTICATION_PASSWORD_RESET = `${URL_PUBLIC_AUTHENTICATION}/password-reset/:token`
const URL_PUBLIC_AUTHENTICATION_REQUEST_PASSWORD_RESET = `${URL_PUBLIC_AUTHENTICATION}/request-password-reset`
const URL_PUBLIC_AUTHENTICATION_PENDING_PASSWORD_RESET = `${URL_PUBLIC_AUTHENTICATION}/pending-password-reset`
const URL_PUBLIC_AUTHENTICATION_COMPLETE_ACCOUNT_SETUP = `${URL_PUBLIC_AUTHENTICATION}/complete-account-setup/:token`
const URL_PUBLIC_AUTHENTICATION_REQUEST_ACCOUNT_SETUP = `${URL_PUBLIC_AUTHENTICATION}/request-account-setup`
const URL_PUBLIC_AUTHENTICATION_SIGNUP = `${URL_PUBLIC_AUTHENTICATION}/signup`
const URL_PUBLIC_AUTHENTICATION_SIGNUP_EMAIL_VERIFICATION = `${URL_PUBLIC_AUTHENTICATION}/signup-email-verification/:token`
const URL_PUBLIC_AUTHENTICATION_AUTH_SERVICE_CALLBACK = `${URL_PUBLIC_AUTHENTICATION}/auth/callback`
// @hygen:inject public-urls

export type DashRouteSet =
  | DashRouteType<'app-public-authentication'>
  | DashRouteType<'app-public-authentication-log-in'>
  // | DashRouteType<'app-public-authentication-log-in-v2'>
  | DashRouteType<'app-public-authentication-create-account'>
  | DashRouteType<'app-public-authentication-request-email-verification'>
  | DashRouteType<'app-public-authentication-email-verification', { token: string }>
  | DashRouteType<'app-public-authentication-pending-email-verification'>
  | DashRouteType<'app-public-authentication-password-reset', { token: string }>
  | DashRouteType<'app-public-authentication-request-password-reset'>
  | DashRouteType<'app-public-authentication-pending-password-reset'>
  | DashRouteType<'app-public-authentication-complete-account-setup', { token: string }>
  | DashRouteType<'app-public-authentication-request-account-setup'>
  | DashRouteType<'app-public-authentication-signup'>
  | DashRouteType<'app-public-authentication-signup-email-verification', { token: string }>
  | DashRouteType<'app-public-authentication-auth-service-callback'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-public-authentication': URL_PUBLIC_AUTHENTICATION,
  'app-public-authentication-log-in': URL_PUBLIC_AUTHENTICATION_LOG_IN,
  //  'app-public-authentication-log-in-v2': URL_PUBLIC_AUTHENTICATION_LOG_IN_V_2,
  'app-public-authentication-create-account': URL_PUBLIC_AUTHENTICATION_CREATE_ACCOUNT,
  'app-public-authentication-request-email-verification':
    URL_PUBLIC_AUTHENTICATION_REQUEST_EMAIL_VERIFICATION,
  'app-public-authentication-email-verification': URL_PUBLIC_AUTHENTICATION_EMAIL_VERIFICATION,
  'app-public-authentication-pending-email-verification':
    URL_PUBLIC_AUTHENTICATION_PENDING_EMAIL_VERIFICATION,
  'app-public-authentication-password-reset': URL_PUBLIC_AUTHENTICATION_PASSWORD_RESET,
  'app-public-authentication-request-password-reset':
    URL_PUBLIC_AUTHENTICATION_REQUEST_PASSWORD_RESET,
  'app-public-authentication-pending-password-reset':
    URL_PUBLIC_AUTHENTICATION_PENDING_PASSWORD_RESET,
  'app-public-authentication-complete-account-setup':
    URL_PUBLIC_AUTHENTICATION_COMPLETE_ACCOUNT_SETUP,
  'app-public-authentication-request-account-setup':
    URL_PUBLIC_AUTHENTICATION_REQUEST_ACCOUNT_SETUP,
  'app-public-authentication-signup': URL_PUBLIC_AUTHENTICATION_SIGNUP,
  'app-public-authentication-signup-email-verification':
    URL_PUBLIC_AUTHENTICATION_SIGNUP_EMAIL_VERIFICATION,
  'app-public-authentication-auth-service-callback':
    URL_PUBLIC_AUTHENTICATION_AUTH_SERVICE_CALLBACK,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
