const EN_US = {
  'emergingBrandsPortalDomain.title': 'Brand explorer',
  'emergingBrandsPortalDomain.about.brand': 'About {brand}',
  'emergingBrandsPortalDomain.other.sell.sheet.products': 'Other products in {sellSheet}',
  'emergingBrandsPortalDomain.other.sell.sheets': 'Other sell sheets that includes this item',
  'emergingBrandsPortalDomain.last.week': 'Last week ({startDate} - {endDate})',
  'emergingBrandsPortalDomain.last.month': 'Last 4 weeks ({startDate} - {endDate})',
  'emergingBrandsPortalDomain.last.quarter': 'Last 13 weeks ({startDate} - {endDate})',
  'emergingBrandsPortalDomain.last.half.year': 'Last 26 weeks ({startDate} - {endDate})',
  'emergingBrandsPortalDomain.last.year': 'Last 52 weeks ({startDate} - {endDate})',
  'emergingBrandsPortalDomain.legal.legalDisclaimer':
    'Note: The content above is provided by the brand. Instacart does not guarantee its accuracy.',
  'emergingBrandsPortalDomain.total.sales': 'Sales',
  'emergingBrandsPortalDomain.total.orders': 'Orders',
  'emergingBrandsPortalDomain.total.stores': 'Avg # of stores with sales',
  'emergingBrandsPortalDomain.avg.sales': 'Sales per store',
  'emergingBrandsPortalDomain.avg.orders': 'Orders per store',
  'emergingBrandsPortalDomain.header.stores': 'Stores with sales',
  'emergingBrandsPortalDomain.header.avg.price': 'Avg. price',
  'emergingBrandsPortalDomain.header.productAttribute.price': 'Average price',
  'emergingBrandsPortalDomain.header.productAttribute.size': 'Sizes',
  'emergingBrandsPortalDomain.header.productAttribute.unitCount': 'Unit Count',
  'emergingBrandsPortalDomain.header.productAttribute.upc': 'UPC',
  'emergingBrandsPortalDomain.sell.sheet.disclaimer': 'Totals are based on the previous week',
  'emergingBrandsPortalDomain.wow': 'WoW',
  'emergingBrandsPortalDomain.mom': 'MoM',
  'emergingBrandsPortalDomain.qoq': 'QoQ',
  'emergingBrandsPortalDomain.hoh': 'HoH',
  'emergingBrandsPortalDomain.yoy': 'YoY',
  'emergingBrandsPortalDomain.sell.sheet.card.sales': 'Sales (last 4 weeks)',
  'emergingBrandsPortalDomain.sell.sheet.card.orders': 'Orders (last 4 weeks)',
  'emergingBrandsPortalDomain.sell.sheet.card.products.count': '{productCount} products',
  'emergingBrandsPortalDomain.sell.sheet.card.product.count': '{productCount} product',
  'emergingBrandsPortalDomain.sell.sheets': 'Sell Sheets',
  'emergingBrandsPortalDomain.contact': 'Contact',
  'emergingBrandsPortalDomain.get.connected': 'Let’s get you connected',
  'emergingBrandsPortalDomain.provide.details': 'Let the brand know what info you’re looking for.',
  'emergingBrandsPortalDomain.message.header': 'Anything else you want to add?',
  'emergingBrandsPortalDomain.submit': 'Submit',
  'emergingBrandsPortalDomain.cancel': 'Cancel',
  'emergingBrandsPortalDomain.pii.disclaimer':
    'By submitting this form, your inquiry details and contact info will be automatically shared with the brand contact through an automated email. Subsequent emails from that thread won’t include Instacart.',
  'emergingBrandsPortalDomain.product.filtered':
    "Sales data for some products can't be shown due to data sharing restrictions.",
  'emergingBrandsPortalDomain.sellSheet.table.product.filtered':
    "Sales data for some products can't be shown due to data sharing restrictions.",
  'emergingBrandsPortalDomain.contact.error': 'Error submitting your form',
  'emergingBrandsPortalDomain.contact.success': 'Your form was submitted',
  'emergingBrandsPortalDomain.contact.header.name': 'Name',
  'emergingBrandsPortalDomain.contact.header.email': 'Email',
  'emergingBrandsPortalDomain.contact.header.jobTitle': 'Job Title',
  'emergingBrandsPortalDomain.contact.header.phoneNumber': 'Phone Number',
  'emergingBrandsPortalDomain.contact.placeholder.jobTitle': 'Add your job tile',
  'emergingBrandsPortalDomain.contact.placeholder.phoneNumber': 'Add your phone number',
  'emergingBrandsPortalDomain.all.brands': 'All Brands',
  'emergingBrandsPortalDomain.all.categories': 'All Categories',
  'emergingBrandsPortalDomain.distributors': 'Distributors',
  'emergingBrandsPortalDomain.website': 'Website',
  'emergingBrandsPortalDomain.sortBy': 'Sort by',
  'emergingBrandsPortalDomain.sellSheetSort.brandName': 'brand name',
  'emergingBrandsPortalDomain.sellSheetSort.createdAt': 'created date',
  'emergingBrandsPortalDomain.sellSheetSort.productOrders': 'orders',
  'emergingBrandsPortalDomain.sellSheetSort.productOrdersGrowth': 'orders growth',
  'emergingBrandsPortalDomain.sellSheetSort.salesAmount': 'sales',
  'emergingBrandsPortalDomain.sellSheetSort.salesGrowth': 'sales growth',
  'emergingBrandsPortalDomain.storeTypes': 'Store types',
  'emergingBrandsPortalDomain.storeTypes.names.alcohol': 'Alcohol',
  'emergingBrandsPortalDomain.storeTypes.names.asian': 'Asian',
  'emergingBrandsPortalDomain.storeTypes.names.baby': 'Baby',
  'emergingBrandsPortalDomain.storeTypes.names.clubWarehouse': 'Club / Warehouse',
  'emergingBrandsPortalDomain.storeTypes.names.coop': 'Co-op',
  'emergingBrandsPortalDomain.storeTypes.names.convenience': 'Convenience',
  'emergingBrandsPortalDomain.storeTypes.names.conventionalTraditional':
    'Conventional / Traditional',
  'emergingBrandsPortalDomain.storeTypes.names.departmentStore': 'Department Store',
  'emergingBrandsPortalDomain.storeTypes.names.drugstore': 'Drugstore',
  'emergingBrandsPortalDomain.storeTypes.names.homeKitchenBath': 'Home, Kitchen and Bath',
  'emergingBrandsPortalDomain.storeTypes.names.latinAmerican': 'Latin American',
  'emergingBrandsPortalDomain.storeTypes.names.localSpecialty': 'Local / Specialty',
  'emergingBrandsPortalDomain.storeTypes.names.naturalOrganic': 'Natural / Organic',
  'emergingBrandsPortalDomain.storeTypes.names.pet': 'Pet',
  'emergingBrandsPortalDomain.storeTypes.names.supplement': 'Supplement',
  'emergingBrandsPortalDomain.storeTypes.names.value': 'Value',
  'emergingBrandsPortalDomain.storeTypes.names.other': 'Other',
  'emergingBrandsPortalDomain.regionalAreas': 'Regional areas',
  'emergingBrandsPortalDomain.regionalAreas.names.pacific': 'Pacific',
  'emergingBrandsPortalDomain.regionalAreas.names.mountain': 'Mountain',
  'emergingBrandsPortalDomain.regionalAreas.names.southwest': 'Southwest',
  'emergingBrandsPortalDomain.regionalAreas.names.southeast': 'Southeast',
  'emergingBrandsPortalDomain.regionalAreas.names.midwest': 'Midwest',
  'emergingBrandsPortalDomain.regionalAreas.names.northeast': 'Northeast',
  'emergingBrandsPortalDomain.regionalAreas.names.regionalAreaUnknown': 'Other',
  'emergingBrandsPortalDomain.certifications': 'Certifications',
  'emergingBrandsPortalDomain.interests': 'What are you interested in learning more about?',
  'emergingBrandsPortalDomain.pricing': 'Unit level pricing',
  'emergingBrandsPortalDomain.distribution': 'Distribution (current distributors, volume, etc.)',
  'emergingBrandsPortalDomain.image': 'Visual assets (image on shelf, etc.)',
  'emergingBrandsPortalDomain.sales': 'Growth projections (seasonality, promotion periods, etc.)',
  'emergingBrandsPortalDomain.claims': 'Label claims (gluten-free, vegan, etc.)',
  'emergingBrandsPortalDomain.select.items': 'Select the sell sheets you’re interested in',
  'emergingBrandsPortalDomain.noData.header': 'No results',
  'emergingBrandsPortalDomain.noData.instructions':
    'Try clearing your filters to view sell sheets.',
  'emergingBrandsPortalDomain.regionalAreas.placeholder': 'Regions sold in',
  'emergingBrandsPortalDomain.regionalAreas.disclaimer':
    'Note: Regions sold in are provided by the brand. Instacart does not guarantee its accuracy.',
  'emergingBrandsPortalDomain.retailer.checkbox.label': 'Hide sell sheets with products I stock',
  'emergingBrandsPortalDomain.header.retailer.carried': 'Carried in my stores',
  'emergingBrandsPortalDomain.retailer.carried.true': 'Yes',
  'emergingBrandsPortalDomain.retailer.carried.false': 'No',
  'emergingBrandsPortalDomain.topBrandsTile.title':
    'Instacart’s Fastest-Growing Emerging Brands in 2023',
  'emergingBrandsPortalDomain.topBrandsTile.buttonText': 'Discover all',
  'emergingBrandsPortalDomain.breadCrumb.searchPage': 'Brand Explorer',
  'emergingBrandsPortalDomain.breadCrumb.topBrandsBrowsePage':
    'Instacart’s Fastest-Growing Emerging Brands in 2023',
  'emergingBrandsPortalDomain.badge.name': 'FASTEST-GROWING BRAND ‘23',
  'emergingBrandsPortalDomain.highlights': 'Highlights',
  'emergingBrandsPortalDomain.educationalBanner.heading':
    'Discover Instacart’s fastest-growing emerging brands',
  'emergingBrandsPortalDomain.educationalBanner.information':
    'Learn more about these brands, their top-selling products and more by checking out their sell sheets in Brand Explorer!',
  'emergingBrandsPortalDomain.educationalBanner.informationNoAccess':
    'Learn more about these brands, their top-selling products and more by checking out this year’s list!',
  'emergingBrandsPortalDomain.educationalBanner.buttonTextNoAccess': 'Explore',
  'emergingBrandsPortalDomain.educationalBanner.buttonText': 'Explore',
  'emergingBrandsPortalDomain.tabHeader.sales': 'Sales',
  'emergingBrandsPortalDomain.tabHeader.detailsAndIngredients': 'Details And Ingredients',
  'emergingBrandsPortalDomain.ingredientsAndDetails.labels.details': 'Details',
  'emergingBrandsPortalDomain.ingredientsAndDetails.labels.ingredients': 'Ingredients',
  'emergingBrandsPortalDomain.ingredientsAndDetails.title': 'Details & Ingredients',
  'emergingBrandsPortalDomain.topbrands.logo.tooltip': 'Fastest Growing Brand 2023',
} as const

export default EN_US
