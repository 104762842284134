import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { bannersAccessControl } from './access-control/bannersAccess.configuration'
import { bannersAdminRouter } from './routing/bannersAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import { EN_US } from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'banners',
  totem: {
    entity: 'banners-dashboard-tool',
  },
  routes,
  translations: { EN_US: EN_US },
  routers: {
    admin: [bannersAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.Merchandising,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'app-admin-banners-list',
          labelId: 'bannersDomain.title',
          accessControl: bannersAccessControl,
          positionNavV2: 1,
        },
      ],
    },
  ],
}

export default definition
