const EN_US = {
  'businessDomain.title': 'Instacart Business',
  'businessDomain.businessPrograms.title': 'Business programs',
  'businessDomain.businessPrograms.list.createProgramCTA': 'Create Business Program',
  'businessDomain.businessPrograms.list.title': 'Business Programs',
  'businessDomain.businessPrograms.list.documentTitle': 'Business Programs',
  'businessDomain.businessPrograms.list.missingPermissions':
    'You do not have the necessary permissions to create Business Programs',
  'businessDomain.businessPrograms.create.title': 'Create New Program',
  'businessDomain.businessPrograms.create.documentTitle': 'Create program - Business Programs',
} as const

export default EN_US
