// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'

const ButtonBase = styled.button`
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:active: {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`

ButtonBase.defaultProps = {
  type: 'button',
}

export default ButtonBase
