import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const shoppingListAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.ShoppingListView, Permission.ShoppingListModify],
    featureToggles: FeatureToggle.ShoppingList,
  },
  {
    permissions: [Permission.ShoppingListView, Permission.ShoppingListModify],
    warehouseFeatureToggles: FeatureToggle.ShoppingList,
  },
]
