export function url(pattern: string, params: { [key: string]: string } = {}) {
  return pattern
    .split('/')
    .reduce<string[]>((acc, segment) => {
      // If the current segment does not start with :, it means it's a static segment
      // so we push as-is
      if (!segment.startsWith(':')) {
        acc.push(segment)
        return acc
      }

      // Remove token characters
      const key = segment.replace(/:|\?|\(.*\)/g, '')
      const value = params[key]

      // If the current segment is optional and has no value, omit it
      if (!value && segment.endsWith('?')) return acc

      // Push the stringified version of this value.
      acc.push(String(value))
      return acc
    }, [])
    .join('/') // Rejoin everything back into url form
    .replace('//', '/') // Compact any double slashes, added for compatibility with older version
}
