import { type DashRouteType, URL_PARTNER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_CREDENTIALS_MANAGEMENT = `${URL_PARTNER_SCOPED}/credentials-management`
const URL_CREDENTIALS_MANAGEMENT_API_KEYS = `${URL_CREDENTIALS_MANAGEMENT}/api-keys`
const URL_CREDENTIALS_MANAGEMENT_API_KEY_APPROVAL_REQUESTS = `${URL_CREDENTIALS_MANAGEMENT}/api-key-approval-requests`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'credentials-management'>
  | DashRouteType<'credentials-management-api-keys'>
  | DashRouteType<'credentials-management-api-key-approval-requests'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'credentials-management': URL_CREDENTIALS_MANAGEMENT,
  'credentials-management-api-keys': URL_CREDENTIALS_MANAGEMENT_API_KEYS,
  'credentials-management-api-key-approval-requests':
    URL_CREDENTIALS_MANAGEMENT_API_KEY_APPROVAL_REQUESTS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
