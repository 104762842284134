import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const pageBuilderViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView, Permission.PageBuilderView],
}

export const pageBuilderModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify, Permission.PageBuilderModify],
}

export const landingAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.AdminPanel],
}
