import React, { type FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { type PatchedLocationDescriptorObject } from '../common/history'
import { type RouteName } from '../../utils/routing/routes'
import { type OnClick } from '../../utils/react/event.types'
import RetailerRoute from './RetailerRoute'
import { useAbsoluteRouteMatch } from '../../utils/routing/useAbsoluteRouteMatch.hooks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Location = string | PatchedLocationDescriptorObject<any>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Query = { [key: string]: any }

export type Route =
  | RouteName
  | {
      name: RouteName
      params: { [key: string]: string }
    }

function getLocation(to: Location, query?: Query) {
  let location

  if (typeof to === 'string') {
    location = { pathname: to, query }
  } else {
    location = { ...to, query }
  }

  return location
}

interface Props extends Omit<React.ComponentPropsWithoutRef<typeof Link>, 'to' | 'href'> {
  disabled?: boolean
  query?: Query
  elementAttributes?: {
    [key: string]: string | undefined | object
  } & React.AnchorHTMLAttributes<HTMLAnchorElement>
  route?: Route
  to?: Location
  href?: string
  onClick?: OnClick<HTMLElement>
  wrapperStyles?: { [key: string]: string }
}

const LinkBase: FunctionComponent<React.PropsWithChildren<Props>> = ({
  disabled = false,
  elementAttributes,
  href,
  route,
  query,
  to,
  onClick,
  wrapperStyles,
  ...rest
}) => {
  const absoluteCurrentRoute = useAbsoluteRouteMatch()

  // eslint-disable-next-line react/no-multi-comp
  const LinkBaseWrapper: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => (
    <div
      style={{ display: 'inline-block', ...wrapperStyles }}
      role="link"
      onClick={onClick}
      onKeyDown={() => {}}
    >
      {children}
    </div>
  )
  // eslint-enable-next-line react/no-multi-comp

  if (disabled) {
    return (
      <LinkBaseWrapper>
        <a {...elementAttributes} {...rest} />
      </LinkBaseWrapper>
    )
  }

  if (href) {
    return (
      <LinkBaseWrapper>
        <a href={href} {...elementAttributes} {...rest} />
      </LinkBaseWrapper>
    )
  }

  if (!route) {
    return (
      <LinkBaseWrapper>
        <Link to={getLocation(to as Location, query)} {...elementAttributes} {...rest} />
      </LinkBaseWrapper>
    )
  }

  return (
    <RetailerRoute>
      {({ url }) => {
        // // HACK TILL REACT ROUTER V6 - Which supported nested route params
        // // See: https://github.com/remix-run/react-router/pull/7963
        const routeName = typeof route === 'string' ? route : route.name
        const routeParams = typeof route === 'string' ? {} : route.params

        const location = url(routeName, { ...absoluteCurrentRoute?.params, ...routeParams })
        // REMOVE AFTER REACT ROUTER V6 UPGRADE

        return (
          <LinkBaseWrapper>
            <Link to={getLocation(location, query)} {...elementAttributes} {...rest} />
          </LinkBaseWrapper>
        )
      }}
    </RetailerRoute>
  )
}

export default LinkBase
