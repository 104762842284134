import React, { useMemo } from 'react'
import { useGetAllPartnersForUserQuery } from '../../__codegen__/api'

type Partner = {
  id: number
  name: string
}

interface UseAllPartnersForUserResult {
  partners: Partner[]
  loading: boolean
  error: Error
}

// This hook gets all partners for the current user and converts the id strings into integers
// Only use this hook if you need the ids as integers
export const useAllPartnersForUser = (): UseAllPartnersForUserResult => {
  const { data, loading, error } = useGetAllPartnersForUserQuery()

  // Convert the id strings into integers
  const partners = useMemo(
    () =>
      data?.partnerSearchArray.map(({ id, name }) => ({
        id: parseInt(id),
        name,
      })),
    [data?.partnerSearchArray]
  )

  return {
    partners,
    loading,
    error,
  }
}
