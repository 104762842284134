import debounce from 'lodash/debounce'
import { useState, useCallback, useEffect, useMemo } from 'react'

type DebouncedState<T> = [T, T, (newValue: T, immediate?: boolean) => void, boolean]

/**
 * A state function that returns both a regular state plus
 * a debounced state, and a setter for both.
 *
 * Returns an array containing [`state`, `debouncedState`, `setState`, isChangePending]
 *
 * Note that the setState function can receive a second argument that allows
 * for immediate applying (without waiting for the debounce period), for instance:
 *
 * ```js
 * setValue("hello", true)
 * ```
 *
 * will set both `state` and `debouncedState` immediately.
 * isChangePending indicates that debouncedState and state are not yet in sync, which
 * can be useful for 'loading' states
 *
 * @param initialValue The initial provided value, same as useState
 * @param debounceTime The amount of time for the debounce callback to execute after input
 */
export function useDebouncedState<T>(initialValue: T, debounceTime = 0): DebouncedState<T> {
  const [value, setValue] = useState(initialValue)
  const [debouncedValue, setDebouncedValue] = useState(value)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetter = useMemo(() => debounce(setDebouncedValue, debounceTime), [])
  // This takes care of canceling the debouncer once the component is unmounted
  useEffect(() => debouncedSetter.cancel, [debouncedSetter])

  const setter = useCallback(
    (newValue: T, immediate = false) => {
      if (!immediate) {
        debouncedSetter(newValue)
      } else {
        // Ensure any remaining updates are cancelled to not override this value
        debouncedSetter.cancel()
        setDebouncedValue(newValue)
      }

      setValue(newValue)
    },
    [debouncedSetter]
  )

  const isChangePending = value !== debouncedValue

  return [value, debouncedValue, setter, isChangePending]
}
