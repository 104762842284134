import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { storeConfigurationRouter } from './routing/storeConfigurationRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { storeConfigurationAccessControl } from './access-control/storeConfigurationAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'store-configuration',
  totem: {
    entity: 'store-configuration-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [storeConfigurationRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Storefront,
      attachToV2: NavEntryPoint.UserMenuSettings,
      subNavItems: [
        {
          type: 'item',
          labelId: 'storeConfigurationDomain.title',
          route: 'store-configuration-drafts',
          accessControl: storeConfigurationAccessControl,
          id: 'store-configuration-drafts',
          positionNavV2: 3,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
