import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

/**
 * insightsPortalAccessControl is used for permissioning whereas
 * insightsPortalCombinedNonIAMilestone2AccessControl is used for feature toggling the IAMilestone2 feature
 */
export const insightsPortalAccessControl: DomainAccessControlConfig = {
  permissions: Permission.Performance,
}
export const insightsPortalCombinedNonIAMilestone2AccessControl: DomainAccessControlConfig = {
  permissions: Permission.Performance,
  notFeatureToggles: FeatureToggle.InsightsPortalIAMilestone2,
}

export const insightsPortalCombinedIAMilestone2AccessControl: DomainAccessControlConfig = {
  permissions: Permission.Performance,
  featureToggles: FeatureToggle.InsightsPortalIAMilestone2,
}
