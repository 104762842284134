import { useEffect, useRef } from 'react'

/**
 * A debounced useEffect.
 * It will only be called after `debounceTime` milliseconds after `dependencies`
 * change. If any dependencies change before that time, the delay will restart
 * and the called function will be newest one, as you would expect with a `debounce`d function.
 *
 * Note that the cleanup function only gets executed immediately before the new callback gets executed.
 * So it's always a reference to the latest _executed_ callback.
 *
 * @param callback Callback to be debounced
 * @param dependencies Dependencies
 * @param debounceTime Time in milliseconds to debounce `callback`
 */
export function useDebouncedEffect(
  callback: () => void | (() => void),
  dependencies: any[],
  debounceTime = 0
) {
  const callbackRef = useRef(callback)
  // Keep a reference of the cleanup function, if any
  const cleanupRef = useRef<() => void>()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(
    () => {
      const handle = setTimeout(() => {
        // Attempt to do cleanup if there's a reference to the previous cleanup function
        cleanupRef.current?.()

        // Update the cleanup reference
        // The or branch here is just to appease TS.
        cleanupRef.current = callbackRef?.current?.() || undefined
      }, debounceTime)

      return () => clearTimeout(handle)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  )
}
