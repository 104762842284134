import { useCallback } from 'react'
import { type OnKeyboard } from '../../../../utils/react/event.types'
import { KEYS } from './keyboard.constants'

export const useOnEnterHandler = (onEnterCallback?: Function): OnKeyboard =>
  useCallback(
    ({ key }) => {
      if (key === KEYS.ENTER) {
        onEnterCallback && onEnterCallback()
      }
    },
    [onEnterCallback]
  )
