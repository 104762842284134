import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { shoppingListAccessControl } from '../access-control/shoppingListAccess.configuration'

const ShoppingListListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ShoppingListListPage" */
      '../pages/list/ShoppingListListPage'
    )
)
// @hygen:inject page-import

export const shoppingListRouter = (
  <DomainRoute route="shopping-list" accessControl={shoppingListAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="shopping-list-list"
        component={ShoppingListListPage}
        scopePicker={{
          titles: ['shoppingListDomain.title'],
        }}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
