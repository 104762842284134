import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'

type TitleTextProps = {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  padding?: string
}

export const TitleText = styled.div<TitleTextProps>(
  ({ fontWeight = 600, fontSize = '18px', lineHeight = '22px', padding = '' }) => ({
    display: 'flex',
    padding: padding,

    fontStyle: 'normal',
    fontWeight: fontWeight,
    fontSize: fontSize,
    lineHeight: lineHeight,
    alignItems: 'center',
    color: colors.GRAYSCALE.X80,
    cursor: 'default',
  })
)

export const Divider = styled.div({
  display: 'flex',
  flex: 1,
  backgroundColor: colors.GRAYSCALE.X20,
  height: 1,
  margin: '8px 0px',
})
