import React, { useCallback } from 'react'
import ReactSelect, { createFilter, type Styles } from 'react-select'
import { useDeepMemo } from '@retailer-platform/shared-common'
import { colors } from '../../foundation'
import {
  type SelectProps,
  type BaseOption,
  type SimpleOption,
  type SelectStyleConfig,
} from './utils/select.types'
import { useSelectBehaviour } from './utils/select.hooks'
import { makeSelectBaseTheme, selectBaseStyles } from './utils/styles'
import { mergeReactSelectStyles } from './utils/select.utils'

const modernIDSTheme: SelectStyleConfig = {
  placeholder: (base, { isDisabled }) => ({
    color: isDisabled ? colors.GRAYSCALE.X30 : base.color,
  }),

  control: () => ({
    backgroundColor: colors.GRAYSCALE.X10,
    border: 'none',
  }),

  indicatorsContainer: (_, { isDisabled }) => ({
    svg: { fill: isDisabled ? colors.GRAYSCALE.X30 : colors.GRAYSCALE.X50 },
  }),
}

/**
 * @deprecated Prefer using the NewSelect component from @retailer-platform/shared-components
 */
export const Select = <TOption extends BaseOption = SimpleOption<string>>(
  props: SelectProps<TOption>
) => {
  const { reactSelectOverrides, filteredOptions } = useSelectBehaviour<TOption>(props)

  // Don't pass along messages - causes a TS conflict with React Select
  const {
    ignoreAccents = false,
    emptyValue,
    loadingMessage,
    errorMessage,
    onChange,
    filterOption,
    styles: overrideStyles,
    isModernTheme = false,
    ...rest
  } = props

  const selectStyles = useDeepMemo(() => {
    const styles = [
      selectBaseStyles,
      // optional theme
      isModernTheme && modernIDSTheme,
      overrideStyles,
    ].filter(Boolean)

    return mergeReactSelectStyles(...styles)
  }, [overrideStyles, isModernTheme])

  // By default, react-select strips accents from inputs
  // There is a significant performance boost gained by disabling this
  const customFilterOption = filterOption || createFilter({ ignoreAccents })

  /**
   * This is to support the emptyValue prop, which allows for being able to specify
   * what should happen when there's an empty value. This is due to
   * inconsistencies in how react-select handles this:
   * - when in multi mode, removing all nodes at the same time will yield []
   * - when in multi mode, removing nodes 1 by 1 will yield null
   */
  const localOnChange = useCallback(
    (value: any, action: any) => {
      let localValue = value
      /**
       * Since we're dealing with falsey values, it's better to check
       * the prop exists within the object instead of checking the value
       * of the prop itself.
       */
      if ('emptyValue' in props) {
        if (Array.isArray(value)) {
          /**
           * We check for the value's length -- this should give us whether the
           * array is empty, or falsey altogether. If it's not, return original
           */
          localValue = value?.length ? value : emptyValue
        } else {
          localValue = value ? value : emptyValue
        }
      }

      return onChange(localValue, action)
    },
    [emptyValue, onChange, props]
  )

  return (
    <ReactSelect<TOption>
      {...rest}
      // Small override here: Styles types only supports CSSProperties, where it should be CSSObject instead
      styles={selectStyles as Styles}
      theme={makeSelectBaseTheme}
      options={filteredOptions}
      onChange={localOnChange}
      filterOption={customFilterOption}
      {...reactSelectOverrides}
    />
  )
}
