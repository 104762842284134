import React from 'react'

export interface CardContextValue {
  collapsible: boolean
  collapsed: boolean
  toggle: () => void
  id: string
}

export const CardContext = React.createContext<CardContextValue>({
  collapsible: false,
  collapsed: false,
  toggle: () => {
    throw new Error('Trying to toggle card collapse state without context')
  },
  id: '',
})

CardContext.displayName = 'CardContext'
