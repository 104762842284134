import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

const URL_EMERGING_BRANDS_PORTAL = `${URL_RETAILER_SCOPED}/brand-explorer`
const URL_EMERGING_BRANDS_PORTAL_PRODUCT = `${URL_EMERGING_BRANDS_PORTAL}/product/:productId/sell-sheet/:sellSheetId`
const URL_EMERGING_BRANDS_PORTAL_SELL_SHEET = `${URL_EMERGING_BRANDS_PORTAL}/sell-sheet/:sellSheetId`
const URL_EMERGING_BRANDS_PORTAL_BRAND = `${URL_EMERGING_BRANDS_PORTAL}/brand/:brandId`
const URL_EMERGING_BRANDS_PORTAL_SEARCH = `${URL_EMERGING_BRANDS_PORTAL}/search`
const URL_EMERGING_BRANDS_TOP_BRANDS = `${URL_EMERGING_BRANDS_PORTAL}/browse-top-brands`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'emerging-brands-portal'>
  | DashRouteType<'emerging-brands-portal-product'>
  | DashRouteType<'emerging-brands-portal-sell-sheet'>
  | DashRouteType<'emerging-brands-portal-brand'>
  | DashRouteType<'emerging-brands-portal-search'>
  | DashRouteType<'emerging-brands-portal-browse-top-brands'>

// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'emerging-brands-portal': URL_EMERGING_BRANDS_PORTAL,
  'emerging-brands-portal-product': URL_EMERGING_BRANDS_PORTAL_PRODUCT,
  'emerging-brands-portal-sell-sheet': URL_EMERGING_BRANDS_PORTAL_SELL_SHEET,
  'emerging-brands-portal-brand': URL_EMERGING_BRANDS_PORTAL_BRAND,
  'emerging-brands-portal-search': URL_EMERGING_BRANDS_PORTAL_SEARCH,
  'emerging-brands-portal-browse-top-brands': URL_EMERGING_BRANDS_TOP_BRANDS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
