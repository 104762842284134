import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  retailerFundedMarketingWholesalerAccessControl,
  retailerFundedMarketingWholesalerEarlyAccessControl,
} from '../access-control/retailerFundedMarketingWholesalerAccess.configuration'

const MarketplaceCampaignListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "MarketplaceCampaignListPage" */
      '../pages/retailer-campaigns/list/MarketplaceCampaignListPage'
    )
)
const StorefrontProCampaignListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontProCampaignListPage" */
      '../pages/retailer-campaigns/list/StorefrontProCampaignListPage'
    )
)
const PartnerCampaignCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "PartnerCampaignCreatePage" */
      '../pages/retailer-campaigns/create/PartnerCampaignCreatePage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const retailerFundedMarketingPartnerScopedRouter = (
  <DomainRoute
    route="campaigns-partner-scoped"
    // TODO: update to use retailerFundedMarketingWholesalerAccessControl
    accessControl={retailerFundedMarketingWholesalerEarlyAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="campaigns-marketplace-list"
        component={MarketplaceCampaignListPage}
      />
      <DomainRoute
        exact
        route="campaigns-storefront-pro-list"
        component={StorefrontProCampaignListPage}
      />
      <DomainRoute
        exact
        route="partner-campaigns-create-mp"
        component={PartnerCampaignCreatePage}
      />
      <DomainRoute
        exact
        route="partner-campaigns-create-sfx"
        component={PartnerCampaignCreatePage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
