import { type DashRouteType, URL_PARTNER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_OAUTH_APPLICATIONS = `${URL_PARTNER_SCOPED}/oauth-applications`
const URL_OAUTH_APPLICATIONS_LIST = `${URL_OAUTH_APPLICATIONS}/list`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'oauth-applications'>
  | DashRouteType<'oauth-applications-list'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'oauth-applications': URL_OAUTH_APPLICATIONS,
  'oauth-applications-list': URL_OAUTH_APPLICATIONS_LIST,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
