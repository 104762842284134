import { useMemo } from 'react'
import { URL_BASE, URL_STRIPE_CONNECT_CALLBACK } from '../../utils/routing/routes'
import { SETTINGS_STRIPE_URL } from '../../legacy/common/routes'
import { url } from '../../utils/parsing/url'
import {
  type RetailerRouteComponentProps,
  type ParsedQuery,
} from '../../legacy/components/RetailerRoute'

interface StripeConnectCallbackQuery {
  state?: string
}

export interface StripeConnectCallbackProps
  extends RetailerRouteComponentProps<
    {},
    StripeConnectCallbackQuery,
    ParsedQuery<StripeConnectCallbackQuery>
  > {}

type Props = {
  pathname: StripeConnectCallbackProps['location']['pathname']
  search: StripeConnectCallbackProps['location']['search']
  state: StripeConnectCallbackProps['query']['state']
}

const defaultStripeRedirect = { pathname: url(URL_BASE) }

export const useStripeConnectCallbackOptions = ({ pathname, search, state }: Props) =>
  useMemo(() => {
    if (pathname !== URL_STRIPE_CONNECT_CALLBACK) return defaultStripeRedirect
    if (!state) return defaultStripeRedirect

    return {
      pathname: url(SETTINGS_STRIPE_URL, JSON.parse(state)),
      search,
    }
  }, [state, pathname, search])
