// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export default FlexRow
