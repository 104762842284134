import { logDebugEvent } from '../core/factories/events/eventDebugging'
import { segmentAnalytics, SEGMENT_OPTIONS } from '../core/factories/events/segmentAnalytics'
import { type FetchCurrentEnterpriseAccountQuery } from '../../__codegen__/api'

function buildUserEventPayload({
  canonicalId,
  email,
  givenName,
  surname,
  roles,
  internalAccessConfiguration: { assignedPartners, assignedRetailers },
}: FetchCurrentEnterpriseAccountQuery['currentEnterpriseAccount']) {
  return {
    id: canonicalId,
    email,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    first_name: givenName,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_name: surname,
    roles: roles.map(role => role.slug),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    partner_id: assignedPartners.length === 1 ? assignedPartners[0].id : null,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    warehouse_ids: assignedPartners.length === 1 ? assignedRetailers.map(r => r.id) : null,
  }
}

const identifyUser = (account: FetchCurrentEnterpriseAccountQuery['currentEnterpriseAccount']) => {
  const identifier = account.canonicalId

  const payload = {
    ...buildUserEventPayload(account),
    ...account,
  }

  segmentAnalytics().identify(identifier, payload, SEGMENT_OPTIONS)
  logDebugEvent('identify', `Identified the user as: ${identifier}`, payload)
}

// eslint-disable-next-line import/no-default-export
export default identifyUser
