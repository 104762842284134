const EN_US = {
  'dataIngestionDomain.title': 'Data Ingestion',

  'dataIngestionDomain.approvalRequest.showPage.title': 'Approval Request {id}',
  'dataIngestionDomain.approvalRequest.showPage.unsupportedContentType':
    'Content type {contentType} not supported.',

  'dataIngestionDomain.approvalRequest.details.createdAtTitle': 'Created at',
  'dataIngestionDomain.approvalRequest.details.changeTypeTitle': 'Change type',
  'dataIngestionDomain.approvalRequest.details.statusTitle': 'Status',
  'dataIngestionDomain.approvalRequest.details.publishedStatusMessage':
    'The change approval request has been approved and published.',
  'dataIngestionDomain.approvalRequest.details.cancelledStatusMessage':
    'The change approval request has been cancelled.',

  'dataIngestionDomain.approvalRequest.details.columnMaps.partnerIdTitle': 'Partner ID',
  'dataIngestionDomain.approvalRequest.details.columnMaps.mapTypeTitle': 'Map type',
  'dataIngestionDomain.approvalRequest.details.columnMaps.normalizedSchemaTitle':
    'Normalized schema',
  'dataIngestionDomain.approvalRequest.details.columnMaps.ingestionTypeTitle': 'Ingestion type',
  'dataIngestionDomain.approvalRequest.details.columnMaps.rawSchemaTitle': 'Raw schema',
  'dataIngestionDomain.approvalRequest.details.columnMaps.fileNameMatcherTitle':
    'File Name Matcher',
  'dataIngestionDomain.approvalRequest.details.columnMaps.mapTitle': 'Map',
  'dataIngestionDomain.approvalRequest.details.columnMaps.global': 'Global',
  'dataIngestionDomain.approvalRequest.details.columnMaps.deleteInfoMessage':
    'The column map is being deleted.',
} as const

export default EN_US
