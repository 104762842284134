export const EN_US = {
  // Home Placements ex Home Announcement Banners
  'bannersDomain.title': 'Home placements',
  // List
  'bannersDomain.list.title': 'Home Placements',
  'bannersDomain.list.columns.date.header': 'Date',
  'bannersDomain.list.columns.name.header': 'Internal Name',
  'bannersDomain.list.columns.platform.header': 'Platform',
  'bannersDomain.list.columns.locale.header': 'Locale',
  'bannersDomain.list.columns.status.header': 'Status',
  'bannersDomain.list.columns.enabled.header': 'Enabled',
  'bannersDomain.list.columns.name.type': 'Placement Type',
  'bannersDomain.list.columns.activeDates.header': 'Active Dates',
  'bannersDomain.list.columns.lastUpdated.header': 'Last Updated',
  'bannersDomain.list.filters.name.placeholder': 'Search By Placement Name',
  'bannersDomain.list.filters.platform.placeholder': 'Platform',
  'bannersDomain.list.filters.status.placeholder': 'Date Status',
  'bannersDomain.list.filters.enabled.placeholder': 'Enabled Status',
  'bannersDomain.list.filters.type.placeholder': 'Placement Type',
  'bannersDomain.list.filters.locale.placeholder': 'Placement Locale',
  'bannersDomain.list.add': 'Add Placement',
  'bannersDomain.list.status.default': 'Status',
  'bannersDomain.list.status.active': 'Active',
  'bannersDomain.list.status.inactive': 'Inactive',
  'bannersDomain.list.status.live': 'Live',
  'bannersDomain.list.enabled.true': 'Enabled',
  'bannersDomain.list.enabled.false': 'Disabled',
  'bannersDomain.list.activeDates.noStart': 'no start date',
  'bannersDomain.list.activeDates.noEnd': 'no end date',
  'bannersDomain.platform.type.desktop-web': 'Desktop Web',
  'bannersDomain.platform.type.mobile-web': 'Mobile Web',
  'bannersDomain.platform.type.ios': 'iOS',
  'bannersDomain.platform.type.android': 'Android',
  'bannersDomain.experiment.access-method.none': 'None',
  'bannersDomain.experiment.access-method.assign': 'Assign',
  'bannersDomain.experiment.access-method.lookup': 'Lookup',
  'bannersDomain.experiment.access-method.preview': 'Preview',
  'bannersDomain.banners.type.textWithThumbnail': 'text_with_thumbnail',
  'bannersDomain.banners.type.imageForward': 'image_forward',
  'bannersDomain.banners.type.textWithThumbnailFlex': 'text_with_thumbnail_flex',
  'bannersDomain.banners.type.imageForwardFlex': 'image_forward_flex',
  'bannersDomain.banners.type.heroBanner': 'home hero banner',
  'bannersDomain.banners.type.secondaryBanner': 'home secondary banner',
  'bannersDomain.banners.type.flyout': 'home flyout',
  'bannersDomain.field.empty.value': 'n/a',
  // Create
  'bannersDomain.create.title': 'Create New Placement',
  'bannersDomain.create.typeSelect.title': 'Select Placement Type',
  'bannersDomain.create.continueButton': 'Continue',
  'bannersDomain.banners.page.create.selector.flyout.title': 'Flyout / Home Modal',
  'bannersDomain.banners.page.create.selector.storefrontIntroSheet.title': 'Storefront Modal',
  'bannersDomain.banners.page.create.selector.heroBanner.title': 'Hero Banner',
  'bannersDomain.banners.page.create.selector.secondaryBanner.title': 'Secondary Banner',
  'bannersDomain.banners.page.create.selector.heroBanner.description':
    'A hero banner is used for special occasion and theming of the app',
  'bannersDomain.banners.page.create.selector.secondaryBanner.description':
    'A secondary banner has 4x1 image on mobile and 8x1 image on desktop',
  'bannersDomain.banners.page.create.selector.flyout.description':
    'A modal displayed on home on cold load.',

  // Form
  'bannersDomain.bannersForm.form.title.create': 'Create New Placement',
  'bannersDomain.bannersForm.form.submit': 'Submit',
  'bannersDomain.bannersForm.form.field.empty.value': 'n/a',
  'bannersDomain.banners.section.details.field.rank.label0': '0 - Account Alerts',
  'bannersDomain.banners.section.details.field.rank.label1': '1 - MP/PBI News',
  'bannersDomain.banners.section.details.field.rank.label2': '2 - Retailer Launches',
  'bannersDomain.banners.section.details.field.rank.label3': '3 - Promotions',
  'bannersDomain.banners.section.details.field.rank.label4': '4 - Product Launches',
  'bannersDomain.banners.section.details.field.rank.label_80': 'Special Ads Flyout',
  'bannersDomain.banners.section.details.field.rank.label_79': 'Special 1 Flyout',
  'bannersDomain.banners.section.details.field.rank.label_78': 'Special 2 Flyout',
  'bannersDomain.banners.section.details.field.rank.label_77': 'Special 3 Flyout',
  'bannersDomain.banners.section.details.field.rank.label_76': 'Special 4 Flyout',
  'bannersDomain.bannersForm.form.validation.required': 'This field is required',
  'bannersDomain.bannersForm.form.validation.default': 'This field cannot be blank.',
  'bannersDomain.banners.section.detail.title': 'Placement Details',
  'bannersDomain.banners.section.detail.tooltip':
    'The Placement name is an internal descriptor for the banner. The Active dates denotes the date range when the Banner should be active, with an optional end date. Banner Rank is used to determine whether the banner should be picked over another banner and also impacts the ordering of banners in a banner carousel.',
  'bannersDomain.bannersForm.form.section.detail.field.placementName.label': 'Placement name',
  'bannersDomain.banners.section.details.field.enabled.label': 'Enabled',
  'bannersDomain.bannersForm.form.section.detail.field.dates.label': 'Active dates',
  'bannersDomain.bannersForm.form.section.detail.field.dates.checkbox': 'No end date',
  'bannersDomain.bannersForm.form.section.detail.field.status.label': 'Status',
  'bannersDomain.bannersForm.form.section.detail.field.timezone.label': 'Timezone',
  'bannersDomain.banners.section.details.field.placementName.label': 'Placement name',
  'bannersDomain.banners.section.details.field.priority.label': 'Placement Rank',
  'bannersDomain.bannersForm.form.location.field.location.label': 'Device',
  'bannersDomain.bannersForm.form.location.title': 'Device',
  'bannersDomain.bannersForm.form.location.tooltip':
    'Refers to where the banner shows up. Selecting Web allows placements to show up on Desktop web browsers and tablets. Selecting Mobile makes the placement visible on Mobile Web browsers. iOS and Android make the placement visible on the respective Native mobile platforms.',
  'bannersDomain.banners.section.link-destination.field.destinationType.label': 'Destination Type',
  'bannersDomain.banners.section.link-destination.title': 'Placement CTA Destination',
  'bannersDomain.banners.section.link-destination.target': 'Target',
  'bannersDomain.banners.section.link-destination.style': 'Button Style',
  'bannersDomain.flyoutForm.form.locale.en-us.label': 'English (US)',
  'bannersDomain.flyoutForm.form.locale.en-ca.label': 'English (Canada)',
  'bannersDomain.flyoutForm.form.locale.fr-ca.label': 'French (Canada)',
  'bannersDomain.flyoutForm.form.locale.label': 'Flyout Locale',
  'bannersDomain.bannersForm.form.section.advanced.locale': 'Banner Locale',
  'bannersDomain.bannersForm.form.section.advanced.retailerIds': 'Retailer Ids',
  'bannersDomain.bannersForm.form.section.advanced.storeConfigurationIds':
    'Store Configuration Ids',
  'bannersDomain.bannersForm.form.section.advanced.layout': 'Layout',
  'bannersDomain.bannersForm.form.section.advanced.variant': 'Banner Variant',
  'bannersDomain.banners.section.details.field.placementName.advancedRank': 'Show Advanced Rank',
  'bannersDomain.banners.section.details.field.placementName.advancedRankDetails':
    'More info on rank',
  'bannersDomain.flyoutForm.form.primaryDestination.title': 'Placement Primary Button',
  'bannersDomain.flyoutForm.form.missingStorePrefix':
    'Relative URLs must have a "/store/" prefix and absolute URLs must have a "https://" prefix',
  'bannersDomain.flyoutForm.form.secondaryDestination.title': 'Placement Secondary Button',
  'bannersDomain.flyoutForm.form.linkDestination.title': 'Placement Link Button',
  'bannersDomain.flyoutForm.form.destination.cta': 'Button text',
  'bannersDomain.flyoutForm.form.destination.tooltip':
    'Flyout placement has 1 to 3 buttons with various actions, including url or deeplink, Storefront, Store Selector or Container. Button destination can also be set to "do-not-navigate", in which case it just closes the flyout.',

  'bannersDomain.bannersForm.form.link-destination.title': 'Placement CTA Destination',
  'bannersDomain.bannersForm.form.link-destination.tooltip':
    'Where placement main action directs customers. Placement can link out to one of five locations including a URL or a deep link, Storefront, Store Selector, Pickup Stores Selector, or Containers via a Store Selector',

  'bannersDomain.bannersForm.form.link-destination.input.style.option.primary': 'Primary',
  'bannersDomain.bannersForm.form.link-destination.input.style.option.secondary': 'Secondary',
  'bannersDomain.bannersForm.form.link-destination.input.style.option.detrimental': 'Detrimental',
  'bannersDomain.bannersForm.form.link-destination.input.style.option.link': 'Link',
  'bannersDomain.bannersForm.form.link-destination.input.style.option.plus': 'Plus',

  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.empty': 'None',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.doNotNavigate':
    'None',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.unknown':
    'Unsupported destination type',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.external': 'URL',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.storefront':
    'Storefront',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.storeSelector':
    'Store Selector',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.pickupStoreSelector':
    'Pickup Store Selector',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.option.containerPathViaStoreSelector':
    'Container Paths via Store Selector',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.label': 'Destination Type',
  'bannersDomain.bannersForm.form.link-destination.input.destinationType.style': 'Button Style',
  'bannersDomain.bannersForm.form.link-destination.input.destination-target.external.url':
    'Enter URL',
  'bannersDomain.bannersForm.form.link-destination.input.destination-target.external.open-in-new-tab':
    'Open URL in New Tab',
  'bannersDomain.bannersForm.form.link-destination.input.destination-target.external.open-in-new-window':
    'Open URL in New Window',

  'bannersDomain.bannersForm.form.link-destination.input.destination-target.storefront.retailer-id':
    'Enter Retailer Id',
  'bannersDomain.bannersForm.form.link-destination.input.destination-target.storeselector':
    'Enter Comma Separated Retailer Ids',
  'bannersDomain.bannersForm.form.link-destination.input.destination-target.pickup-storeselector.show-pickup-distance':
    'Show Pickup Distance',
  'bannersDomain.bannersForm.form.link-destination.input.destination-target.container-path-via-storeselector':
    'Enter Container Paths',
  'bannersDomain.bannersForm.form.section.content.experiment.title': 'Experiment Details',
  'bannersDomain.bannersForm.form.section.content.experiment.tooltip':
    'If Banner is to be shown as part of an experiment, configure the experiment details here.',
  'bannersDomain.bannersForm.form.section.content.experiment.name.label': 'Experiment Name',
  'bannersDomain.bannersForm.form.section.content.experiment.variant.label': 'Experiment Variant',
  'bannersDomain.bannersForm.form.section.content.experiment.method.label': 'Experiment Method',
  'bannersDomain.bannersForm.form.section.content.experiment.footnote':
    'The experiment name and variant need to be exactly as configured.',
  'bannersDomain.banners.section.content.title': 'Banner Content',
  'bannersDomain.bannersForm.form.section.content.heading.label': 'Heading',
  'bannersDomain.bannersForm.form.section.content.title.label': 'Flyout Title',
  'bannersDomain.bannersForm.form.section.content.description.label': 'Flyout Description',
  'bannersDomain.bannersForm.form.section.content.disclaimer.label': 'Flyout Disclaimer',
  'bannersDomain.bannersForm.form.section.content.heading.color.label': 'Heading color',
  'bannersDomain.bannersForm.form.section.content.subheading.label': 'Subheading',
  'bannersDomain.bannersForm.form.section.content.subheading.color.label': 'Subheading color',
  'bannersDomain.bannersForm.form.section.content.secondaryText.label': 'Secondary text',
  'bannersDomain.bannersForm.form.section.content.secondaryText.color.label':
    'Secondary text color',
  'bannersDomain.flyoutForm.form.section.content.cta.cta.label': 'CTA',
  'bannersDomain.bannersForm.form.section.content.cta.cta.label': 'CTA',
  'bannersDomain.bannersForm.form.section.content.cta.color.label': 'CTA color',
  'bannersDomain.bannersForm.form.section.content.cta.backgroundColor.label':
    'CTA background color',
  'bannersDomain.bannersForm.form.section.content.backgroundColor.label':
    'Placement background color',
  'bannersDomain.bannersForm.form.section.content.textareaColor.label': 'Placement textarea color',
  'bannersDomain.bannersForm.form.link-destination.input.image.flyout.desktop':
    'Image for Desktop Clients',
  'bannersDomain.bannersForm.form.link-destination.input.image.flyout.mobile':
    'Image for Mobile Clients',
  'bannersDomain.bannersForm.form.link-destination.input.image.heroBanner.desktop':
    'Image for Desktop Clients',
  'bannersDomain.bannersForm.form.link-destination.input.image.heroBanner.mobile':
    'Image for Mobile Clients',
  'bannersDomain.bannersForm.form.link-destination.input.image.secondaryBanner.desktop':
    'Image for Desktop Clients',
  'bannersDomain.bannersForm.form.link-destination.input.image.secondaryBanner.mobile':
    'Image for Mobile Clients',
  'bannersDomain.bannersForm.form.section.content.image.acceptedFormat': '.png, .jpg images only',
  'bannersDomain.bannersForm.form.section.content.image.uploaded': 'Uploaded',
  'bannersDomain.bannersForm.form.section.content.image.remove': 'Remove Image',
  'bannersDomain.banners.form.content.text.tooltip':
    'Use this section to upload banner assets. Customize text by adding in a header, subheader, and a call to action.',
  // Details
  'bannersDomain.details.color.label': 'color',
  'bannersDomain.details.backgroundColor.label': 'background color',
  'bannersDomain.details.content.field.image.label': 'Placement Image',
  'bannersDomain.details.content.title': 'Placement Content',
  'bannersDomain.details.details.title': 'Placement Details',
  'bannersDomain.details.details.placementName': 'Placement name',
  'bannersDomain.details.details.placementTypeBanner': 'Placement',
  'bannersDomain.details.details.dates': 'Active dates',
  'bannersDomain.details.details.status': 'Status',
  'bannersDomain.details.details.rank': 'Rank',
  'bannersDomain.details.details.placementId': 'Placement Record ID',
  'bannersDomain.details.details.bannerId': 'Banner Record ID',
  'bannersDomain.details.details.enabled': 'Enabled',
  'bannersDomain.details.platform.platform': 'Platform',
  'bannersDomain.details.platform.title': 'Platforms',
  'bannersDomain.details.link-destination.destinationType': 'Destination Type',
  'bannersDomain.details.link-destination.destinationValue': 'Destination Value',
  'bannersDomain.details.link-destination.title': 'Placement CTA Destination',
  'bannersDomain.details.image.title': 'Placement Images',
  'bannersDomain.details.image.mobile': 'Image for Mobile Clients',
  'bannersDomain.details.image.desktop': 'Image for Desktop Clients',
  'bannersDomain.details.image.logo': 'Logo Image',
  'bannersDomain.details.image.background': 'Background Image',
  'bannersDomain.details.button.visibility-conditions': 'Visibility Conditions',
  'bannersDomain.details.button.edit': 'Edit Placement',
  'bannersDomain.details.button.delete': 'Delete Placement',
  'bannersDomain.details.deleteModal.title': 'Delete Placement?',
  'bannersDomain.details.deleteModal.checkboxLabel': 'Yes, I want to delete this placement',
  'bannersDomain.details.deleteModal.error': 'Could not delete placement, please try again later.',
  'bannersDomain.details.anonymous.title': 'Anonymous Banner',
  'bannersDomain.details.not-found.header': 'Placement Not Found',
  'bannersDomain.details.not-found.content': 'There is no supported placement with the id supplied',
  'bannersDomain.details.not-supported.header': 'Placement type is not supported in dashboard',
  'bannersDomain.details.not-supported.content':
    'The type of the placement is not supported in this Dashboard.',
  'bannersDomain.details.experiment.title': 'Placement Experiment',
  'bannersDomain.details.experiment.name': 'Name',
  'bannersDomain.details.experiment.variant': 'Variant',
  'bannersDomain.details.experiment.access-method': 'Method',
  // Edit
  'bannersDomain.edit.title': 'Edit',
  'bannersDomain.edit.details.title': 'Placement & Banner IDs',
  // Visibility Conditions
  // List
  'bannersDomain.visibility-conditions.list.title': 'Visibility Conditions',
  'bannersDomain.visibility-conditions.list.button.create': 'Add New Visibility Condition',
  'bannersDomain.details.visibility-condition.unsupported.title':
    'Visibility Condition Not Supported By Tool',
  'bannersDomain.details.visibility-condition.country-ids.title': 'Country Ids',
  'bannersDomain.details.visibility-condition.country-ids.country-ids': 'Country Ids',
  'bannersDomain.details.visibility-condition.day-of-week.title': 'Day(s) of Week',
  'bannersDomain.details.visibility-condition.day-of-week.days': 'Day(s) of Week',
  'bannersDomain.details.visibility-condition.express-membership.title': 'Express Membership',
  'bannersDomain.details.visibility-condition.express-membership.membership-status':
    'Membership Status',
  'bannersDomain.details.visibility-condition.express-nonmember-long-term-holdout.title':
    'Express Nonmember Long Term Holdout',
  'bannersDomain.details.visibility-condition.matches-any-retailer-id.title':
    'Matches Any Retailer Id',
  'bannersDomain.details.visibility-condition.matches-any-retailer-id.retailer-ids': 'Retailer Ids',
  'bannersDomain.details.visibility-condition.minimum-platform-version.title':
    'Minimum Platform Version',
  'bannersDomain.details.visibility-condition.minimum-platform-version.platform': 'Platform',
  'bannersDomain.details.visibility-condition.minimum-platform-version.version': 'Version',
  'bannersDomain.details.visibility-condition.retailer-availability.title': 'Retailer Availability',
  'bannersDomain.details.visibility-condition.retailer-availability.retailer-id': 'Retailer Id',
  'bannersDomain.details.visibility-condition.shopped-at-retailer.title': 'Shopped At Retailer',
  'bannersDomain.details.visibility-condition.shopped-at-retailer.retailer-id': 'Retailer Id',
  'bannersDomain.details.visibility-condition.shopped-at-retailer.has-shopped':
    'Should Have Shopped At Retailer',
  'bannersDomain.details.visibility-condition.shopped-at-retailer.number-of-days':
    'Last Number of Days',
  'bannersDomain.details.visibility-condition.shopped-at-retailer.country-id': 'Country Id',
  'bannersDomain.details.visibility-condition.shopped-at-retailer.store-configuration-id':
    'Store Configuration Id',
  'bannersDomain.details.visibility-condition.shopped.title': 'Shopped',
  'bannersDomain.details.visibility-condition.shopped.has-shopped':
    'Should Have Shopped In This Context',
  'bannersDomain.details.visibility-condition.shopped.country-id': 'Country Id',
  'bannersDomain.details.visibility-condition.shopped.store-configuration-id':
    'Store Configuration Id',
  'bannersDomain.details.visibility-condition.shopped.number-of-days': 'Number of Days',
  'bannersDomain.details.visibility-condition.shopped.number-of-times': 'Number of Times',
  'bannersDomain.details.visibility-condition.user-interaction-limit.title':
    'User Interaction Limit',
  'bannersDomain.details.visibility-condition.user-interaction-limit.max-impressions-count':
    'Max Impressions Count',
  'bannersDomain.details.visibility-condition.user-interaction-limit.max-engagements-count':
    'Max Engagements Count',
  'bannersDomain.details.visibility-condition.user-interaction-limit.max-dismissed-count':
    'Max Dismissed Count',
  'bannersDomain.details.visibility-condition.user-interaction-limit.count-cooldown-sec':
    'Count Cooldown Seconds',
  'bannersDomain.details.visibility-condition.whitelisted-postal-codes.title':
    'Whitelisted Postal Codes',
  'bannersDomain.details.visibility-condition.whitelisted-postal-codes.postal-codes':
    'Postal Codes',
  'bannersDomain.details.visibility-condition.whitelisted-zones.title': 'Whitelisted Zones',
  'bannersDomain.details.visibility-condition.whitelisted-zones.zones': 'Zones',
  'bannersDomain.details.visibility-condition.deleteModal.title': 'Delete Visibility Condition?',
  'bannersDomain.details.visibility-condition.deleteModal.body':
    'Are you sure you want to permanently delete the {conditionName} visibility condition on {placementName}?',
  'bannersDomain.details.visibility-condition.deleteButton': 'Delete',
  'bannersDomain.details.visibility-condition.deleteModal.checkboxLabel':
    'Yes, I want to delete this visibility condition',
  // Create
  'bannersDomain.visibility-conditions.create.title': 'Create',
  'bannersDomain.details.visibility-condition.whitelisted-postal-codes.description':
    'An Express Membership Visibility Condition lets you show a placement to users depending on their an express membership status. You can configure the condition by picking either True of False for the membership status option.',
  'bannersDomain.details.visibility-condition.express-membership.description':
    'An Express Membership Visibility Condition lets you show a placement to users depending on their an express membership status. You can configure the condition by picking either True of False for the membership status option.',
  // Form
  'bannersDomain.details.visibility-condition.form.title': 'Add a Visibility Condition',
  'bannersDomain.details.visibility-condition.form.tooltip':
    'Visibility Conditions are filters/conditions that are applied to a banner placement to narrow targeting to a particular set of users',
  'bannersDomain.details.visibility-condition.form.input.visibilityConditionType.label':
    'Type of Visibility Condition',
  'bannersDomain.details.visibility-condition.form.input.country-ids.country-ids':
    'Countries where the banner should be visible',
  'bannersDomain.details.visibility-condition.form.input.day-of-week.day-of-week': 'Days of week',
  'bannersDomain.details.visibility-condition.form.input.express-membership.membership-status':
    'Should The Requesting User Be An Express Member?',
  'bannersDomain.details.visibility-condition.form.input.matches-any-retailer-id.retailer-ids':
    'Retailer Ids',
  'bannersDomain.details.visibility-condition.form.input.minimum-platform-version.platform':
    'Platform Type',
  'bannersDomain.details.visibility-condition.form.input.minimum-platform-version.version':
    'Version',
  'bannersDomain.details.visibility-condition.form.input.retailer-availability.retailer-id':
    'Retailer Id',
  'bannersDomain.details.visibility-condition.form.input.shopped-at-retailer.retailer-id':
    'Enter Retailer Id',
  'bannersDomain.details.visibility-condition.form.input.shopped-at-retailer.has-shopped':
    'Should User Have Shopped At Retailer?',
  'bannersDomain.details.visibility-condition.form.input.shopped.country-id': 'Enter Country Id',
  'bannersDomain.details.visibility-condition.form.input.shopped.store-configuration-id':
    'Enter Store Configuration Id',
  'bannersDomain.details.visibility-condition.form.input.shopped.number-of-times':
    'Number of Times',
  'bannersDomain.details.visibility-condition.form.input.shopped.number-of-days': 'Number of Days',
  'bannersDomain.details.visibility-condition.form.input.shopped.has-shopped':
    'Has User Shopped This Context?',
  'bannersDomain.details.visibility-condition.form.input.user-interaction-limit.max-impressions-count':
    'Max number of impressions for banner',
  'bannersDomain.details.visibility-condition.form.input.user-interaction-limit.max-dismissed-count':
    'Max number of dismissals for banner',
  'bannersDomain.details.visibility-condition.form.input.user-interaction-limit.max-engagements-count':
    'Max number of engagements for banner',
  'bannersDomain.details.visibility-condition.form.input.user-interaction-limit.count-cooldown-sec':
    'Number of seconds until the interaction limits expire',
  'bannersDomain.details.visibility-condition.form.input.whitelisted-postal-codes.postal-codes':
    'Enter Postal Codes Where The Banner Should Be Visible',
  'bannersDomain.details.visibility-condition.form.input.whitelisted-zones.zones':
    'Enter Zones Where The Banner Should Be Visible',
  'bannersDomain.details.visibility-condition.form.submit': 'Add',
  // links to new banner details
  'bannersDomain.linksToNewBannerDetails.title':
    'We now support editing banners in a locale other than en_US. Therefore, the old link you bookmarked is no longer functional.',
  'bannersDomain.linksToNewBannerDetails.enUSTextButton':
    'Click here to go to the new banner details page with the locale set to en_US.',
  'bannersDomain.linksToNewBannerDetails.frCATextButton':
    'Click here to go to the new banner details page with the locale set to fr_CA.',
  'bannersDomain.form.validation.required': "{label} can't be blank.",
  'bannersDomain.hero-banners.section.detail.field.dates.label': 'Active dates',
  'bannersDomain.hero-banners.section.detail.field.dates.checkbox': 'No end date',
  'bannersDomain.hero-banners.field.empty.value': 'n/a',
  'bannersDomain.hero-banners.status.active': 'active',
  'bannersDomain.hero-banners.status.active.definition':
    'Banner start or end date falls within an active date & time range.',
  'bannersDomain.hero-banners.status.inactive': 'inactive',
  'bannersDomain.hero-banners.status.inactive.definition':
    "Banner start and end date doesn't fall within active date & time range.",
  'bannersDomain.common.browse': 'Browse',
  'bannersDomain.common.cancel': 'Cancel',
  'bannersDomain.common.confirm': 'Confirm',
  'bannersDomain.button.clear': 'clear',
} as const
