import React, { type FunctionComponent } from 'react'
import { injectIntl } from 'react-intl'
import { Formik } from 'formik'
import { type InjectedDashIntlProps } from '../../../../../intl/intl.types'
import { useStoreGroupsFormValidator, useStoreGroupsFormInitialValues } from './utils/form.hooks'
import {
  type StoreGroupsFormikValues,
  type StoreGroupsFormInitializerProps,
} from './utils/form.types'
import { StoreGroupsFormContent } from './StoreGroupsFormContent'

export interface StoreGroupsFormProps
  extends StoreGroupsFormInitializerProps,
    InjectedDashIntlProps {
  handleSubmit: (values: StoreGroupsFormikValues) => void
  formId?: string
  showFormConfirmBar?: boolean
}

export const _StoreGroupsForm: FunctionComponent<React.PropsWithChildren<StoreGroupsFormProps>> = ({
  intl,
  existingStoreGroup,
  handleSubmit,
  formId,
  showFormConfirmBar = true,
}) => {
  const validator = useStoreGroupsFormValidator({ intl })
  const initialValues = useStoreGroupsFormInitialValues({ existingStoreGroup })

  return (
    <Formik<StoreGroupsFormikValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validate={validator}
    >
      {formikProps => (
        <StoreGroupsFormContent
          {...formikProps}
          formId={formId}
          showFormConfirmBar={showFormConfirmBar}
        />
      )}
    </Formik>
  )
}

export const StoreGroupsForm = injectIntl(_StoreGroupsForm)
