import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@instacart/ids-core'
import { spacing } from '../../foundation'

const BreadcrumbsWrapper = styled.div(({ linkColor }: { linkColor: string }) => ({
  display: 'flex',

  // adds a '/' before each direct child but removes the '/' from the first child
  // output: breadcrumb1 / breadcrumb2 / breadcrumb3
  '> *': {
    '::before': {
      content: '"/"',
      paddingRight: spacing.X4,
      paddingLeft: spacing.X4,
    },
    ':first-of-type::before': {
      content: '""',
      paddingLeft: spacing.X0,
    },
    a: {
      color: linkColor,
    },
  },
}))

export const Breadcrumbs: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { colors } = useTheme()

  return <BreadcrumbsWrapper linkColor={colors.systemGrayscale50}>{children}</BreadcrumbsWrapper>
}
