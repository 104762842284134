import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const retailerFundedMarketingTargetingAccessControl: DomainAccessControlConfig = [
  {
    warehouseFeatureToggles: [FeatureToggle.retailerFundedMarketingCampaign],
    permissions: [
      Permission.RetailerFundedMarketingViewAccess,
      Permission.RetailerFundedMarketingModifyAccess,
    ],
  },
  {
    permissions: [Permission.RetailerFundedMarketingAdminAccess],
  },
]
