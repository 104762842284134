import React from 'react'
import { SurfaceContext } from '../../../../../contexts/surface/SurfaceContext'

const withSurfaceId = <T,>(
  Component: React.ComponentType<React.PropsWithChildren<T>>
): React.FunctionComponent<React.PropsWithChildren<T>> => {
  return props => {
    const [surfaceId, setSurfaceId] = React.useState(1)
    return (
      <SurfaceContext.Provider value={{ surfaceId, setSurfaceId }}>
        <Component {...props} />
      </SurfaceContext.Provider>
    )
  }
}

export default withSurfaceId
