// React context provider for integration configuration

import React, { createContext, useContext } from 'react'
import {
  type ContentTypes,
  type IntegrationConfig,
  IntegrationConfigurations,
} from '../../integrationConfigurations'

const IntegrationConfigContext = createContext<IntegrationConfig>({} as IntegrationConfig)

export type IntegrationConfigProviderProps = {
  contentType?: ContentTypes
  config?: IntegrationConfig
}

export const IntegrationConfigProvider: React.FC<
  React.PropsWithChildren<IntegrationConfigProviderProps>
> = ({ contentType, config, children }) => {
  if (contentType) {
    config ||= IntegrationConfigurations[contentType]
  }

  if (!config) {
    throw new Error('Integration config must be provided')
  }

  return (
    <IntegrationConfigContext.Provider value={config}>{children}</IntegrationConfigContext.Provider>
  )
}

export const useIntegrationConfig = () => useContext(IntegrationConfigContext)
