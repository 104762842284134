import { type FunctionComponent, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

export const Portal: FunctionComponent<
  React.PropsWithChildren<{ portalContainer?: HTMLElement }>
> = ({ children, portalContainer = document.body }) => {
  const portalRef = useRef<HTMLElement | null>(null)

  // lazily create the portal element only once
  // https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
  function portalElement() {
    if (portalRef.current === null) {
      portalRef.current = document.createElement('div')
      portalRef.current.style.display = 'contents'
    }
    return portalRef.current
  }

  useEffect(() => {
    // append the portal div to the end of the body
    portalContainer.appendChild(portalElement())

    return () => {
      // remove div when portal is unmounting
      portalContainer.removeChild(portalElement())
    }
  })

  return createPortal(children, portalElement())
}
