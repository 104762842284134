import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  storeInterventionsCreateNewRequestAccessControl,
  storeInterventionsModifyAccessControl,
  storeInterventionsViewAccessControl,
} from '../access-control/storeInterventionsAccess.configuration'

const StoreInterventionsCreateRequestPage = lazy(
  () => import('../pages/create-request/StoreInterventionsCreateRequestPage')
)
const StoreInterventionsUpdateRequestPage = lazy(
  () => import('../pages/update-request/StoreInterventionsUpdateRequestPage')
)
const StoreInterventionsDuplicateRequestPage = lazy(
  () => import('../pages/duplicate-request/StoreInterventionsDuplicateRequestPage')
)
const StoreInterventionsRequestListPage = lazy(
  () => import('../pages/request-list/StoreInterventionsRequestListPage')
)
const StoreInterventionsRequestDetailsPage = lazy(
  () => import('../pages/request-details/StoreInterventionsRequestDetailsPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const storeInterventionsAdminRouter = (
  <DomainRoute route="app-admin-store-interventions">
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-store-interventions-create-request"
        component={StoreInterventionsCreateRequestPage}
        accessControl={storeInterventionsCreateNewRequestAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-store-interventions-update-request"
        component={StoreInterventionsUpdateRequestPage}
        accessControl={storeInterventionsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-store-interventions-duplicate-request"
        component={StoreInterventionsDuplicateRequestPage}
        accessControl={storeInterventionsModifyAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-store-interventions-request-list"
        component={StoreInterventionsRequestListPage}
        accessControl={storeInterventionsViewAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-store-interventions-request-details"
        component={StoreInterventionsRequestDetailsPage}
        accessControl={storeInterventionsViewAccessControl}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
