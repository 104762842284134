import React from 'react'
import { useIntl } from 'react-intl'
import { NavLink } from '../NavLink'
import { useAbsoluteRouteMatch } from '../../../../../utils/routing/useAbsoluteRouteMatch.hooks'
import { NavItemStyle, TextContainer } from '../NavItem'
import { type AccessControlConfig } from '../../../../../legacy/components/AccessControl/accessControl.utils'
import useAccessControl from '../../../../../legacy/components/AccessControl/useAccessControl'

type Props = {
  onClick: () => void
  route?: string
  title: string
  accessControl?: AccessControlConfig
  position?: number
}

export const Option: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  onClick,
  route,
  accessControl,
}) => {
  const intl = useIntl()
  const routeParams = useAbsoluteRouteMatch()
  const hasAccess = useAccessControl()

  const selected = route === routeParams?.route

  if (accessControl && !hasAccess(accessControl)) {
    return null
  }

  return (
    <NavLink onClick={onClick} route={route}>
      <NavItemStyle selected={selected} padding="8px 24px">
        <TextContainer>{intl.formatMessage({ id: title })}</TextContainer>
      </NavItemStyle>
    </NavLink>
  )
}
