import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRedirect, DomainRoute } from '../utils/domain/routingComponents'
import { instacartDeveloperPlatformAccessControl } from '../access-control/instacartDeveloperPlatformAccess.configuration'
const InstacartDeveloperPlatformGetStartedPage = lazy(
  () => import('../pages/get-started/InstacartDeveloperPlatformGetStartedPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const instacartDeveloperPlatformRouter = (
  <DomainRoute
    route="instacart-developer-platform"
    accessControl={instacartDeveloperPlatformAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="instacart-developer-platform-get-started"
        component={InstacartDeveloperPlatformGetStartedPage}
      />
      <DomainRedirect toRoute="instacart-developer-platform-get-started" />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
