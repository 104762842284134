import { type DefaultOptions } from 'apollo-client'

export const defaultClientOptions: DefaultOptions = {
  query: {
    errorPolicy: 'all',
  },
  watchQuery: {
    errorPolicy: 'all',
  },
}
