import React from 'react'
import styled from '@emotion/styled'
import { colors, SVGIcon, Text } from 'ic-snacks'
import ButtonBase from '../ButtonBase'
import { CardContext } from './CardContext'

const CardHeaderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.GRAY_88};
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const CardCollapseButton = styled(ButtonBase)`
  margin-left: 15px;

  &:hover {
    color: ${colors.GREEN_500};
  }
`

const CardHeaderActions = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
`

const CardHeaderTitle = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-grow: 1;
  height: 65px;
`

const CardHeaderTitleSibling = styled.div`
  padding-left: 10px;
  padding-top: 2px;
`

interface Props {
  title: React.ReactNode
  titleSibling?: React.ReactNode
  children?: React.ReactNode
}

export default class CardHeader extends React.PureComponent<Props> {
  render() {
    const { title, titleSibling, children } = this.props

    return (
      <CardContext.Consumer>
        {({ collapsed, collapsible, toggle, id }) => (
          <CardHeaderContainer>
            <CardHeaderTitle id={`${id}-heading`}>
              <Text variant="T.18" fontWeight="semiBold">
                {title}
              </Text>
              {titleSibling && <CardHeaderTitleSibling>{titleSibling}</CardHeaderTitleSibling>}
            </CardHeaderTitle>
            <CardHeaderActions>
              {children}
              {collapsible && (
                <CardCollapseButton
                  data-testid="card-collapse-button"
                  onClick={toggle}
                  aria-expanded={collapsed}
                  aria-controls={`${id}-body`}
                >
                  <SVGIcon width="20px" height="20px" name={collapsed ? 'arrowDown' : 'arrowUp'} />
                </CardCollapseButton>
              )}
            </CardHeaderActions>
          </CardHeaderContainer>
        )}
      </CardContext.Consumer>
    )
  }
}
