import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import { createSchemaConfig } from '../helpers/schema.helpers'
import { inventoryIntelligenceOosSignalsSchemaFilterParamsConfig } from './insightsPortalInventoryIntelligenceOosSignalSchema.helpers'
import { Dimensions, GroupBys } from './insightsPortalInventoryIntelligenceOosSignal.types'

export { Dimensions, GroupBys }

export const schemaConfig = createSchemaConfig({
  metrics: {},
  dimensions: Dimensions,
  dimensionsConfig: {
    [Dimensions.ID]: {
      private: true,
    },
    [Dimensions.DISPLAY_NAME]: {
      private: true,
    },
    [Dimensions.OOS_AT_DATETIME]: {
      date: true,
      private: true,
    },
    [Dimensions.LATEST_SIGNAL_AT_DATETIME]: {
      date: true,
      private: true,
    },
    [Dimensions.SHOPPER_PHOTOS]: {
      private: true,
    },
  },

  // TODO: deprecate
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.ID]: {
      private: true,
    },
    [GroupBys.DISPLAY_NAME]: {
      private: true,
    },
    [GroupBys.OOS_AT_DATETIME]: {
      private: true,
    },
    [GroupBys.LATEST_SIGNAL_AT_DATETIME]: {
      private: true,
    },
    [GroupBys.SHOPPER_PHOTOS]: {
      private: true,
    },
  },
  metricsConfig: {},
  filterConfigs: inventoryIntelligenceOosSignalsSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    inventoryIntelligenceOosSignalsSchemaFilterParamsConfig
  ),
})
