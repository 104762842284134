export enum Metrics {
  NUM_BATCHES = 'NUM_BATCHES',
  NUM_BATCHES_ACCEPTED = 'NUM_BATCHES_ACCEPTED',
  BATCH_ACCEPTANCE_PCT = 'BATCH_ACCEPTANCE_PCT',
  NUM_COMPLETED_PARTNER_PICK = 'NUM_COMPLETED_PARTNER_PICK',
  NUM_DELIVERY_BATCHES = 'NUM_DELIVERY_BATCHES',
  NUM_DELIVERY_BATCHES_ACCEPTED = 'NUM_DELIVERY_BATCHES_ACCEPTED',
  DELIVERY_BATCH_ACCEPTANCE_PCT = 'DELIVERY_BATCH_ACCEPTANCE_PCT',
  NUM_COMPLETED_PARTNER_PICK_DELIVERY = 'NUM_COMPLETED_PARTNER_PICK_DELIVERY',
  NUM_PICKUP_BATCHES = 'NUM_PICKUP_BATCHES',
  NUM_PICKUP_BATCHES_ACCEPTED = 'NUM_PICKUP_BATCHES_ACCEPTED',
  PICKUP_BATCH_ACCEPTANCE_PCT = 'PICKUP_BATCH_ACCEPTANCE_PCT',
  NUM_COMPLETED_PARTNER_PICK_PICKUP = 'NUM_COMPLETED_PARTNER_PICK_PICKUP',
  FIVE_HOUR_AVAILABILITY_PCT = 'FIVE_HOUR_AVAILABILITY_PCT',
  SECONDS_PER_ITEM = 'SECONDS_PER_ITEM',
  LATE_STAGE_PCT = 'LATE_STAGE_PCT',
  AVG_ORDER_RATING = 'AVG_ORDER_RATING',
  UTILIZATION_PCT = 'UTILIZATION_PCT',
  WAITING_TIME_P75_WAIT_TIME = 'WAITING_TIME_P75_WAIT_TIME',
}

export enum Dimensions {
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE = 'WAREHOUSE',
  PARTNER = 'PARTNER',
  INSIGHTS_PORTAL_DATE_PT = 'INSIGHTS_PORTAL_DATE_PT',
}
