import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

export const URL_SHIFTS = `${URL_RETAILER_SCOPED}/shifts`
// @hygen:inject scoped-urls

export type DashRouteSet = DashRouteType<'shifts'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  shifts: URL_SHIFTS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
