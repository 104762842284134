import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const pickupSettingsAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.PickupSettingsView, Permission.PickupSettingsModify],
  },
  {
    permissions: [Permission.PickupSettingsView, Permission.PickupSettingsModify],
  },
]
