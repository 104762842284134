import React, { type FunctionComponent } from 'react'
import { Checkbox } from '@retailer-platform/shared-components/src/tds'
import styled from '@emotion/styled'
import { ConfirmIcon } from '@instacart/ids-core'
import { type Option, getKey } from './utils'
import { StyledLogoImage, StyledLogoBox } from './Common'

interface Props {
  option: Option
  selectedState: Record<string, Option>
  setSelectedState: (state: Record<string, Option>) => void
  multiSelect: boolean
  onSingleSelect: (option: Option) => void
}

const getDescendantLeafNodes = (option: Option) => {
  const descendants = []

  if (option.children?.length) {
    option.children.forEach(child => {
      descendants.push(...getDescendantLeafNodes(child))
    })
  } else {
    descendants.push(option)
  }

  return descendants
}

const getAllChildren = (options: Option[]) => {
  const children = []

  options.forEach(option => {
    children.push(option)

    if (option.children) {
      children.push(...getAllChildren(option.children))
    }
  })

  return children
}

const getCheckboxState = (
  option: Option,
  selectedState: Record<string, Option>,
  isSelected: boolean
) => {
  const [allChildrenSelected, someChildrenSelected] = getAllChildren(option.children || []).reduce(
    (acc, child) => {
      const [allSelected, someSelected] = acc

      const childKey = getKey(child)

      return [allSelected && selectedState[childKey], someSelected || selectedState[childKey]]
    },
    [true, false]
  )

  let checkboxState: boolean | 'indeterminate'
  if (getAllChildren(option.children || []).length) {
    if (allChildrenSelected) {
      checkboxState = true
    } else if (someChildrenSelected) {
      checkboxState = 'indeterminate'
    } else {
      checkboxState = false
    }
  } else {
    checkboxState = isSelected
  }

  const indeterminate = checkboxState === 'indeterminate'
  const checked = checkboxState === true

  return [checked, indeterminate]
}

const Container = styled.div<{ selected: boolean; selectable: boolean }>(
  ({ selected, selectable }) => ({
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    padding: '10px 16px',
    maxWidth: '421px',
    gap: '8px',
    cursor: selectable ? 'pointer' : null,
    backgroundColor: selected ? 'rgb(245, 251, 249)' : null,
    '&:hover': {
      backgroundColor: selectable ? 'rgb(236, 246, 243)' : null,
    },
  })
)

const LeafText = styled.div({
  fontFamily: 'Instacart Sans Text v1.1',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#242529',
})

const GroupText = styled.div({
  fontFamily: 'Instacart Sans Text v1.1',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#242529',
  textOverflow: 'ellipsis',
})

const DescendantSelectedText = styled.div(({ visible }: { visible: boolean }) => ({
  fontFamily: 'Instacart Sans Text v1.1',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#56595E',
  marginLeft: 'auto',
  marginRight: '0',
  textWrap: 'nowrap',
  visibility: visible ? 'visible' : 'hidden',
}))

const CheckmarkIconWrapper = styled.div({
  marginLeft: 'auto',
  marginRight: '0',
})

const MultiScopeDropdownOption: FunctionComponent<Props> = ({
  option,
  selectedState,
  setSelectedState,
  multiSelect,
  onSingleSelect,
}) => {
  const key = getKey(option)
  const isSelected = !!selectedState[key]

  const [checked, indeterminate] = getCheckboxState(option, selectedState, isSelected)

  const onClick = () => {
    if (multiSelect) {
      const newState = {
        ...selectedState,
      }

      if (isSelected) {
        delete newState[key]
      } else {
        newState[key] = option
      }

      // this might need to be getAllDescendants
      getAllChildren(option.children || []).forEach(child => {
        const childKey = getKey(child)

        if (isSelected) {
          delete newState[childKey]
        } else {
          newState[childKey] = child
        }
      })

      setSelectedState({
        ...newState,
      })
    } else {
      if (option.leafNode) {
        const key = getKey(option)
        const newState = {
          [key]: option,
        }
        setSelectedState(newState)

        onSingleSelect(option)
      }
    }
  }

  const descendantLeafNodes = getDescendantLeafNodes(option)
  const descendantLeafNodesSelectedNumber = descendantLeafNodes.filter(
    node => selectedState[getKey(node)]
  ).length

  return (
    <Container onClick={onClick} selected={checked} selectable={multiSelect || option.leafNode}>
      {multiSelect && (
        <Checkbox
          label={''}
          checked={checked}
          indeterminate={indeterminate}
          id={`checkbox-${option.id}`}
        />
      )}
      {option.logoUrl && (
        <StyledLogoBox>
          <StyledLogoImage src={option.logoUrl} alt="logo" />
        </StyledLogoBox>
      )}
      {option.leafNode ? <LeafText>{option.name}</LeafText> : <GroupText>{option.name}</GroupText>}

      {multiSelect && !option.leafNode && (
        <DescendantSelectedText visible={descendantLeafNodesSelectedNumber > 0}>
          {descendantLeafNodesSelectedNumber} / {descendantLeafNodes.length} selected
        </DescendantSelectedText>
      )}
      {!multiSelect && isSelected && (
        <CheckmarkIconWrapper>
          <ConfirmIcon size="20px" />
        </CheckmarkIconWrapper>
      )}
    </Container>
  )
}

export default MultiScopeDropdownOption
