import React from 'react'
import { fontWeight, spacing } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { useDashMessages } from '../../../intl/intl.hooks'
import { Button } from '../../common/button/Button'

const StyledContainer = styled.div({
  paddingTop: spacing.X64,
  textAlign: 'center',
  maxWidth: 500,
  margin: '0 auto',

  '.header': {
    fontWeight: fontWeight.SEMIBOLD,
    margin: spacing.X20,
  },

  '.content': {
    marginBottom: spacing.X20,
  },
})

interface Props {
  action?: () => void
  actionLabel?: string
  className?: string
  headerLabel?: string
  'data-testid'?: string
}

export const ErrorPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  action,
  actionLabel,
  children,
  className,
  headerLabel,
  ...props
}) => {
  const messages = useDashMessages({
    header: 'components.errorPage.header',
    content: 'components.errorPage.content',
    defaultAction: 'components.errorPage.defaultAction',
  })

  return (
    <StyledContainer data-testid="error-page" className={className} {...props}>
      {headerLabel !== null && <div className="header">{headerLabel || messages.header}</div>}
      <div className="content"> {children || messages.content}</div>

      {action && (
        <div>
          <Button data-testid="error-page-action" onClick={() => action()}>
            {actionLabel || messages.defaultAction}
          </Button>
        </div>
      )}
    </StyledContainer>
  )
}
