import React, { type FunctionComponent, type PropsWithChildren } from 'react'

type RefetchListProviderProps = {
  refetch: () => void
}

const Context = React.createContext<RefetchListProviderProps | undefined>(undefined)

export const RefetchListProvider: FunctionComponent<
  React.PropsWithChildren<PropsWithChildren<RefetchListProviderProps>>
> = ({ refetch, children }) => <Context.Provider value={{ refetch }}>{children}</Context.Provider>

export const useRefetchList = () => React.useContext(Context)
