import React, { type FunctionComponent, type ComponentProps } from 'react'
import styled from '@emotion/styled'
import { Checkbox } from '../checkbox/Checkbox'
import { Text, type TextProps } from '../text/Text'
import { type TestID } from '../../utils/testing/types'

const Wrapper = styled.div({ display: 'flex', alignItems: 'center' })

export interface Props extends Omit<ComponentProps<typeof Checkbox>, 'label'>, TestID {
  id: string
  label: React.ReactChild
  textSize?: TextProps['size']
  className?: string
}

export const CheckboxWithLabel: FunctionComponent<React.PropsWithChildren<Props>> = ({
  label,
  id,
  'data-testid': testId,
  textSize = 'medium',
  className,
  ...props
}) => (
  <Wrapper className={className}>
    <Checkbox id={id} {...props} css={{ flexShrink: 0 }} />
    <label data-testid={testId} htmlFor={id}>
      <Text size={textSize}>{label}</Text>
    </label>
  </Wrapper>
)
