import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import {
  buildTimeIntervalDimensionsConfig,
  buildTimeIntervalGroupBysConfig,
  createSchemaConfig,
} from '../helpers/schema.helpers'
import { InsightsFormatterType } from '../types/schema.types'
import { TimeIntervalDimensions } from '../types/groupBy.types'
import { Metrics, Dimensions, GroupBys } from './insightsPortalImageCoverage.types'
import { imageCoverageSchemaFilterParamsConfig } from './insightsPortalImageCoverage.hooks'

export { Metrics, Dimensions, GroupBys }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.IMAGE_COVERAGE]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.searchDashboard.metrics.imageCoverage',
    },
  },
  dimensionsConfig: buildTimeIntervalDimensionsConfig,

  // TODO: deprecate
  groupBys: TimeIntervalDimensions,
  groupBysConfig: buildTimeIntervalGroupBysConfig,
  filterConfigs: imageCoverageSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    imageCoverageSchemaFilterParamsConfig
  ),
})
