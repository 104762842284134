import { useSyncExternalStore } from 'react'

export function makeState<T>(initial: T) {
  let current = initial
  const callbacks = new Set<() => void>()
  function subscribe(callback: () => void) {
    callbacks.add(callback)
    return () => callbacks.delete(callback)
  }
  function update(updater: (current: T) => T) {
    current = updater(current)
    for (const callback of callbacks) callback()
  }
  function useState() {
    return useSyncExternalStore(subscribe, () => current)
  }
  return [useState, update] as const
}
