import type React from 'react'
import styled from '@emotion/styled'
import { colors, spacing } from '../../foundation'

export interface CardWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  showDropShadow?: boolean
  children: React.ReactNode
}

export const CardWrapper = styled.div<CardWrapperProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  margin: spacing.X12,
  boxShadow: props.showDropShadow ? '0px 0px 8px rgba(0, 0, 0, 0.16)' : undefined,
  border: `1px solid ${colors.GRAYSCALE.X20}`,
  borderRadius: spacing.X12,
  background: 'white',
  flexGrow: 1,
}))
