const EN_US = {
  'landingDomain.title': 'Landing',
  'landingDomain.edit': 'Edit',
  'landingDomain.set-active': 'Set Active',
  'landingDomain.duplicate': 'Duplicate',
  'landingDomain.delete': 'Delete',
  'landingDomain.landingPageBuilder': 'Landing page builder',
  'landingDomain.contentPages.builder.pageTypeLabel': 'Landing Page Builder',
  'landingDomain.contentPages.dashboard.pageTitle': 'Landing Page Builder',
  'landingDomain.contentPages.dashboard.newPageCTA': 'Create a new landing page',
  'landingDomain.layouts-list.columns.name.header': 'Landing page name',
  'landingDomain.layouts-list.columns.createdOn.header': 'Created on',
  'landingDomain.layouts-list.columns.status.header': 'Status',
  'landingDomain.layouts-list.column.status.active': 'Active',
  'landingDomain.layouts-list.column.status.draft': 'Draft',
} as const

export default EN_US
