import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { notificationsAccessControl } from '../access-control/notificationsAccess.configuration'
import PlatformNotificationsListPage from '../pages/list/PlatformNotificationsListPage'
// @hygen:inject page-import

const NotificationsListPage = lazy(() => import('../pages/list/BannerNotificationsListPage'))

export const notificationsRouter = (
  <DomainRoute route="notifications" accessControl={notificationsAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="banner-notifications-list"
        component={NotificationsListPage}
        scopePicker={{
          titles: ['notificationsDomain.list.bannerNotifications.pageTitle'],
        }}
      />
      <DomainRoute
        exact
        route="platform-notifications-list"
        component={PlatformNotificationsListPage}
        scopePicker={{
          titles: ['notificationsDomain.list.platformNotifications.pageTitle'],
        }}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
