import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { credentialsManagementRouter } from './routing/credentialsManagementRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import {
  apiKeyRequestApprovalsAccessControl,
  apiKeysAccessControl,
} from './access-control/credentialsManagementAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'credentials-management',
  totem: {
    entity: 'credentials-management-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    partnerScoped: [credentialsManagementRouter],
  },
  navigationEntries: [
    {
      attachToV2: NavEntryPoint.Developer,
      subNavItems: [
        {
          type: 'item',
          labelId: 'credentialsManagementDomain.apiKeysPage.title',
          route: 'credentials-management-api-keys',
          accessControl: apiKeysAccessControl,
          position: 2,
          positionNavV2: 2,
        },
        {
          type: 'item',
          labelId: 'credentialsManagementDomain.apiKeyApprovalRequestsPage.title',
          route: 'credentials-management-api-key-approval-requests',
          accessControl: apiKeyRequestApprovalsAccessControl,
          position: 3,
          positionNavV2: 3,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
  // NOTE TO DEV: run `yarn ipp domain register <your-system-name>` to register your domain with Totem here
}

export default definition
