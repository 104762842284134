// NOTE: Do not modify this file.
// This is copied from: https://github.com/instacart/carrot/blob/master/catalog/infinity/catflow/common/udfs/normalize_lookup_code.js
// (This will be replaced with a packaged version at some point.)

/**
 * Check if the provided lookupCode is a valid PLU.
 * - Ignore leading 0's
 * - Must be 5 or 4 digits.
 * - If 5 digits, leading digit must be 8 or 9, followed by a 3 or 4.
 * - If 4 digits, leading digit must be a 3 or 4.
 * - Remaining 3 digits must be numeric.
 *
 * @param lookupCode
 * @returns {boolean}
 */
export const isValidPlu = (lookupCode: string) => /^[0]*[89]?[34]\d{3}$/.test(lookupCode)

/**
 * Check if the provided lookupCode is a valid GTIN.
 * - Must be between 12 digits and 14 digits.
 * - The last digit should have checkdigit
 *
 * @param lookupCode
 * @returns {boolean}
 */
export const isValidGtin = (lookupCode: string) => {
  const codeLength = lookupCode.length
  if (codeLength < 12 || codeLength > 14) {
    return false
  }

  return lookupCode[codeLength - 1] == calcCheckDigit(lookupCode.padStart(14, '0')).toString()
}

/**
 * Check if the provided lookupCode is a possible UPC-E.
 * - Must be 8 digits.
 * - Must start with 0 or 1.
 * - The last digit must be same as check digit of conveted UPC-A.
 *
 * @param lookupCode
 * @returns {boolean}
 */
export const isPossibleUpcE = (lookupCode: string) => {
  if (lookupCode.length !== 8 || !['0', '1'].includes(lookupCode[0])) {
    return false
  }
  const upcA = convertUpcEToUpcA(lookupCode)
  const checkDigit = calcCheckDigit(upcA.padEnd(13, '0')).toString()

  return checkDigit == lookupCode[7]
}

/**
 * Given a UPC-E code, convert the code into UPC-A.
 * @param upcE
 * @returns {string} A possible UPC-A
 */
export const convertUpcEToUpcA = (upcE: string) => {
  let upcA
  switch (upcE[6]) {
    case '0':
    case '1':
    case '2':
      upcA = `${upcE.substring(0, 3)}${upcE[6]}0000${upcE.substring(3, 6)}`
      break
    case '3':
      upcA = `${upcE.substring(0, 4)}00000${upcE.substring(4, 6)}`
      break
    case '4':
      upcA = `${upcE.substring(0, 5)}00000${upcE[5]}`
      break
    default:
      upcA = `${upcE.substring(0, 6)}0000${upcE[6]}`
  }

  return upcA
}

/**
 * Given either a UPC14, or a UPC13 without a check digit. Calculate the check digit.
 * - In the case of a UPC14, we only care about the first 13 characters.
 *
 * @param input
 * @returns {number} The calculated check digit.
 */
export const calcCheckDigit = (upc14: string): number => {
  let oddSum = 0,
    evenSum = 0
  for (let i = 0; i < 13; i++) {
    // The even index is actually the 'odd' digit in this case.
    if (i % 2) {
      evenSum += parseInt(upc14[i])
    } else {
      oddSum += parseInt(upc14[i])
    }
  }
  const checkDigit = (10 - ((oddSum * 3 + evenSum) % 10)) % 10
  return checkDigit
}

/**
 * Given a UPC14, lazily add a check digit.
 * First, verify the current check digit is correct.
 * If the check digit is invalid, we append a valid check digit.
 *   - In this case the first digit is lost. (It was probably 0 padded anyway)
 *
 * @param lookupCode
 * @param forceCheckDigit
 * @returns {string} A checked UPC14
 */
export const fixCheckDigit = (upc14: string, forceCheckDigit: boolean): string => {
  if (forceCheckDigit || upc14[13] != calcCheckDigit(upc14).toString()) {
    const strippedUpc = upc14.substring(1)
    return strippedUpc + calcCheckDigit(strippedUpc)
  } else {
    return upc14
  }
}

/**
 * If the UPC14 starts with 002 or 02, it is a variable price code and we zero out the last 6 digits.
 * Some retailers use the 9th digit, so we can conditionally only zero out the last 5 digits.
 * Some retailers want to keep the middle digits. The keepMiddle argument decides that.
 *
 * - If we zero out digits, we need to re-calculate the check digit.
 *
 * @param lookupCode
 * @returns {string}
 */
export const cleanUpc14 = (upc14: string, keep9th: boolean, keepMiddle = false): string => {
  if (upc14.startsWith('002') || upc14.startsWith('02')) {
    let upc13

    if (keep9th && upc14.startsWith('002') && upc14[8] !== '0') {
      //Only keep the 9th if it starts with 002, not 02.
      upc13 = upc14.substring(0, upc14.length - 5) + '0000'
    } else if (keepMiddle) {
      upc13 = upc14.substring(0, 13)
    } else {
      upc13 = upc14.substring(0, upc14.length - 6) + '00000'
    }

    return upc13 + calcCheckDigit(upc13)
  }
  return upc14
}

/**
 * Normalize the provided lookup code.
 * - Check if it's a valid PLU, if so return a PLU with the leading padded 0's removed.
 * - Else pad the UPC to a 14 digit UPC, fix the check digit and clear digits for variable price UPC's
 *
 * @param lookupCode
 * @returns {string}
 */
export const normalizeLookupCode = (
  lookupCode,
  forceCheckDigit = false,
  keep9th = false,
  normalizeUpcE = false,
  checkGtin14 = false,
  keepMiddle = false
): string => {
  // Echo back lookupCode if the input is empty or contains non-numeric values. (Trim white space edges)
  // Should we return something like 'invalid' here instead?
  if (!lookupCode) return lookupCode
  lookupCode = lookupCode.trim()

  if (!/^[0-9]+$/.test(lookupCode)) return lookupCode

  if (checkGtin14 && isValidGtin(lookupCode)) {
    return lookupCode.padStart(14, '0')
  }

  // If this is a valid PLU, return it, no leading 0's
  if (isValidPlu(lookupCode)) {
    return lookupCode.replace(/^0+/, '') // Strip leading 0's
  }

  //Ensure a valid UPC length
  if (lookupCode.length <= 5 || lookupCode.length > 14) {
    // Should we return something like 'invalid' here instead?
    return lookupCode
  }

  // If lookupCode is a possibleUPC and normalizeUpcE flag is true, apply UPC-E normalization logic
  if (normalizeUpcE && isPossibleUpcE(lookupCode)) {
    return `${convertUpcEToUpcA(lookupCode).padStart(13, '0')}${lookupCode[7]}`
  }

  // At this point we know we have a numeric value, between 6 and 14 digits (inclusively).
  // Pad to 14 digits, verify or add a check digit, then clean variable price UPC's
  let upc14 = lookupCode.padStart(14, '0')

  upc14 = fixCheckDigit(upc14, forceCheckDigit)
  return cleanUpc14(upc14, keep9th, keepMiddle)
}
