import { useEffect, type FunctionComponent, type ReactElement } from 'react'
import { type ApolloError } from 'apollo-client'
import { datadogRum } from '@datadog/browser-rum'
import { errors } from '../../utils/error-handling/errors'
import {
  useFetchCurrentEnterpriseAccountLazyQuery,
  type FetchCurrentEnterpriseAccountQuery,
} from '../../__codegen__/api'
import { isNotAuthorizedError } from '../utils/apollo/errors/isError.hooks'
import { type PartialCurrentEnterpriseAccount } from '../../utils/current-account/test-helpers/currentAccountTestUtils'
import { useLocalForageKey } from '../../utils/local-storage/useLocalForageKey.hooks'

export type CurrentAccountDetailsProps = Parameters<
  typeof useFetchCurrentEnterpriseAccountLazyQuery
>[0]

interface CurrentAccountDetailsValue {
  authorized: boolean
  actionRequired: boolean
  account: FetchCurrentEnterpriseAccountQuery['currentEnterpriseAccount']
  loading: boolean
  error?: ApolloError
}

const notAuthorizedAccountDetails: CurrentAccountDetailsValue = {
  authorized: false,
  actionRequired: false,
  account: undefined,
  loading: false,
  error: undefined,
}

export const useCurrentAccountDetails = (
  options: CurrentAccountDetailsProps = {}
): CurrentAccountDetailsValue => {
  const { data: hasSessionData, setData: storeSessionData } = useLocalForageKey('session')
  const hasSession = Boolean(hasSessionData)

  const [fetchAccount, { data: apiData, loading: apiLoading, error: apiError, called: apiCalled }] =
    useFetchCurrentEnterpriseAccountLazyQuery(options)

  const notAuthorizedError = isNotAuthorizedError(apiError)

  // If we find out that we have a session, then go fetch the account details
  useEffect(() => {
    if (hasSession === true) {
      fetchAccount()
    }
  }, [hasSession, fetchAccount])

  // If we fetched the account, but it failed, and it was a not-authorized error
  // Then our session must be invalid, and we should clear it
  useEffect(() => {
    if (notAuthorizedError) {
      storeSessionData(undefined)
    }
  }, [notAuthorizedError, storeSessionData])

  const account = apiData?.currentEnterpriseAccount

  // If we are authorized and have a account data, then setup datadog rum user details
  useEffect(() => {
    try {
      if (account) {
        // Set user identifying data for datadog rum
        const user = {
          id: account.id,
          // Add any other custom attributes you want to track
          canonicalId: account.canonicalId,
          isInternal: account.isInternal,
        }
        datadogRum.setUser(user)
      }
    } catch (error) {
      console.error('Error setting Datadog RUM user details:', error)
      errors.captureException(error)
    }
  }, [account])

  // If we find out that we do not have a session, then just return
  if (hasSession === false) {
    return notAuthorizedAccountDetails
  }

  // We suppress not-authorized errors when fetching details
  // Instead, just mark authorized as false
  if (notAuthorizedError) {
    return notAuthorizedAccountDetails
  }

  // If we haven't fetched the account details yet, then we are still in a "loading" state
  const loading = hasSession === null || !apiCalled || apiLoading

  // If the account isn't in a valid state, we need to finish setting it up
  const actionRequired = Boolean(account?.accountStateValid)

  return {
    authorized: Boolean(apiData),
    account,
    loading,
    error: apiError,
    actionRequired,
  }
}

type CurrentAccountWithChildren = (account: PartialCurrentEnterpriseAccount) => ReactElement
interface ICurrentAccountProps {
  children: CurrentAccountWithChildren
}

/**
 * This is a helper component that will render the children with the current account details data
 * This is only required for legacy components that are not using hooks (i.e. StripeAssociations.tsx)
 */
export const CurrentAccountDetails: FunctionComponent<
  React.PropsWithChildren<ICurrentAccountProps>
> = ({ children }) => {
  const { account } = useCurrentAccountDetails()

  return children(account)
}
