import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { cashflowAdminRouter } from './routing/cashflowAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { cashflowAdminAccessControl } from './access-control/cashflowAdminAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'cashflow',
  totem: {
    entity: 'cashflow-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [cashflowAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.FinancialHardening,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-cashflow-price-audit-landing',
          labelId: 'cashflowDomain.priceAudit.title',
          accessControl: cashflowAdminAccessControl,
        },
      ],
    },
  ],
}

export default definition
