import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'

const ApprovalRequestsSearchPage = lazy(
  () => import('../pages/approval-requests-search/ApprovalRequestsSearchPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const approvalManagementAdminRouter = (
  <DomainRoute route="app-admin-approvals-management-library">
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-approvals-management-library-approval-requests-search"
        component={ApprovalRequestsSearchPage}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
