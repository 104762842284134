import { useCallback, useMemo } from 'react'

export interface UseGridListDetailsProps {
  dataArrayLength: number
  canLoadMore: boolean
  loadingMore: boolean
  onLoadMore: () => void
  loadingItemCount?: number
}

export const useGridListDetails = ({
  dataArrayLength,
  canLoadMore,
  loadingMore,
  onLoadMore,
  loadingItemCount = 10,
}: UseGridListDetailsProps) => {
  // If there are more items to be loaded then add some extra rows as placeholders.
  // The loading indicators will appear in these rows
  const itemCount = useMemo(() => {
    if (canLoadMore) {
      return dataArrayLength + loadingItemCount
    }

    return dataArrayLength
  }, [canLoadMore, dataArrayLength, loadingItemCount])

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = useCallback(async () => {
    if (loadingMore) {
      return () => undefined
    }

    return onLoadMore()
  }, [loadingMore, onLoadMore])

  // If we can't load anymore, then we have loaded everything, and can return true
  // Otherwise, we check if the index is within the set of data we have already fetched
  const isItemLoaded = useCallback(
    (index: number) => {
      if (!canLoadMore) {
        return true
      }

      return index < dataArrayLength
    },
    [canLoadMore, dataArrayLength]
  )

  return {
    itemCount,
    loadMoreItems,
    isItemLoaded,
  }
}
