import React, { useMemo, type FunctionComponent } from 'react'
import { usePartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'
import {
  type InstacartStoreConfiguration,
  StoreConfigurationRelationshipEnum,
} from '../../../__codegen__/api'
import { useStoreConfigurations } from '../../../api/store-configurations/useStoreConfigurations.hooks'
import {
  type SiteOperationIntent,
  type FilterSitesFn,
} from '../retailer-and-site-picker/RetailerAndSitePicker.types'
import { MARKETPLACE_SITE_ID } from '../../common/selected-site-page/CommonSiteFilters'
import { ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import {
  createSiteOptions,
  groupBannersBySites,
  groupBannersByWarehouseGroup,
  normalizeStoreConfigurations,
} from './utils'
import MultiScopeDropdownComponent from './MultiScopeDropdownComponent'
import { type Option } from './utils'
import { type PopoverConfig } from './Common'

export interface Props {
  selectedOptions: { siteId?: string; retailerId?: string }[]
  selectionType?: ViewType
  multiSelect?: boolean
  scopeSelectionOptional?: boolean
  openByDefault?: boolean
  onSelected: (selectedOptions: { siteId?: string; retailerId?: string }[]) => void
  popoverConfig?: PopoverConfig
  storeConfigFilter?: FilterSitesFn
  storeConfigOperationIntent?: SiteOperationIntent
}

// there is built-in intersection for sets, but only as of 2024
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/intersection
// lets use a custom intersection function for now
const intersection = (a: Set<string>, b: Set<string>): string[] => [...a].filter(e => b.has(e))

export const MultiScopePicker: FunctionComponent<React.PropsWithChildren<Props>> = ({
  selectedOptions,
  selectionType = ViewType.RETAILER_AND_STORE_CONFIG,
  multiSelect = false,
  scopeSelectionOptional,
  onSelected,
  popoverConfig,
  storeConfigFilter,
  storeConfigOperationIntent,
  openByDefault,
}) => {
  const originalSelectedOptions = new Set(
    selectedOptions.map(e => {
      if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
        return e.siteId + ':' + e.retailerId
      } else if (selectionType === ViewType.RETAILER) {
        return e.retailerId
      } else {
        return e.siteId
      }
    })
  )

  const { warehouses: availableWarehouses } = usePartnerContext()

  const { data, loading } = useStoreConfigurations({
    retailerIds: availableWarehouses?.map(e => e.id.toString()),
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
  })

  const storeConfigurations: InstacartStoreConfiguration[] = useMemo(() => {
    // we convert here from InstacartStoreConfiguration to StoreConfigurationForSelection
    // so that we can use the existing logic for filtering - but once we have the filtered
    // options, we can convert them back to InstacartStoreConfiguration

    const storeConfigs: InstacartStoreConfiguration[] = data?.storeConfigurationsByRetailerIds
    if (!storeConfigs) {
      return []
    }

    const warehouseIds = new Set(availableWarehouses?.map(e => e.id.toString()))

    const filteredStoreConfigs = storeConfigs.filter(sc => {
      const whitelistedWarehouseIds = new Set(sc.whitelistedWarehouseIds)
      return (
        intersection(whitelistedWarehouseIds, warehouseIds).length > 0 ||
        sc.id === MARKETPLACE_SITE_ID
      )
    })

    let options = normalizeStoreConfigurations(filteredStoreConfigs, storeConfigOperationIntent)

    if (storeConfigFilter) {
      options = storeConfigFilter(options)
    }

    return options.map(e => e.storeConfig)
  }, [
    availableWarehouses,
    data?.storeConfigurationsByRetailerIds,
    storeConfigFilter,
    storeConfigOperationIntent,
  ])

  // availableWarehouses.forEach(e => {
  //   if (e.name == 'Quick Picks by Publix') {
  //     e.warehouseGroup = {
  //       franchisorId: '1',
  //       franchisorName: 'Quick Picks by Publix',
  //     }
  //   }
  // })

  if (loading) {
    return null
  }

  let options: Option[]
  if (selectionType === ViewType.RETAILER) {
    options = groupBannersByWarehouseGroup(availableWarehouses, originalSelectedOptions)
  } else if (selectionType === ViewType.STORE_CONFIG) {
    options = createSiteOptions(storeConfigurations, originalSelectedOptions)
  } else if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
    options = groupBannersBySites(storeConfigurations, availableWarehouses, originalSelectedOptions)

    // remove sites with no banners
    options = options.filter(e => e.children?.length)
  } else {
    throw new Error('Unexpected selection type')
  }

  const onSingleSelect = (option: Option) => {
    if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
      onSelected([
        {
          siteId: option.breadcrumbs[0].id,
          retailerId: option.id,
        },
      ])
    } else if (selectionType === ViewType.RETAILER) {
      onSelected([{ retailerId: option.id }])
    } else {
      onSelected([{ siteId: option.id }])
    }
  }

  const onMultiSelect = (options: Option[]) => {
    if (selectionType === ViewType.RETAILER_AND_STORE_CONFIG) {
      onSelected(
        options.map(option => ({
          siteId: option.breadcrumbs[0].id,
          retailerId: option.id,
        }))
      )
    } else if (selectionType === ViewType.RETAILER) {
      onSelected(options.map(option => ({ retailerId: option.id })))
    } else {
      onSelected(options.map(option => ({ siteId: option.id })))
    }
  }

  if (multiSelect) {
    options = [
      {
        id: 'all',
        name: 'All',
        children: options,
        selected: false,
        type: 'all',
        breadcrumbs: [],
        leafNode: false,
      },
    ]
  }

  return (
    <MultiScopeDropdownComponent
      options={options}
      multiSelect={multiSelect}
      onSingleSelect={onSingleSelect}
      onMultiSelect={onMultiSelect}
      scopeSelectionOptional={scopeSelectionOptional}
      openByDefault={openByDefault}
      selectionType={selectionType}
      popoverConfig={popoverConfig}
    />
  )
}

export default MultiScopePicker
