import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute, DomainRedirect } from '../utils/domain/routingComponents'
import { aggregateStorePerformanceAccessControl } from '../access-control/aggregateStorePerformanceAccess.configuration'

const AggregateStorePerformanceOverviewPage = lazy(
  () => import('../pages/overview/AggregateStorePerformanceOverviewPage')
)
// @hygen:inject page-import

export const aggregateStorePerformanceRouter = (
  <DomainRoute
    route="aggregate-store-performance"
    accessControl={aggregateStorePerformanceAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="aggregate-store-performance-overview"
        component={AggregateStorePerformanceOverviewPage}
        scopePicker={{
          titles: ['aggregateStorePerformanceDomain.title.new'],
        }}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
      <DomainRedirect
        fromRoute="aggregate-store-performance"
        toRoute="aggregate-store-performance-overview"
      />
    </DashSwitch>
  </DomainRoute>
)
