import instacart from './instacart'
import {
  type ScopedRouteParams,
  type ApiResponse,
  type InventoryArea,
  type Warehouse,
} from './types'

interface ReorderableAttributes {
  rank_offset_position: number
}

interface DepartmentParams extends ScopedRouteParams {
  department_id: string
}

interface DepartmentAttributes extends ReorderableAttributes {}

export interface AisleParams extends DepartmentParams {
  aisle_id: string
  skip_product_count?: string
}

interface AisleAttributes extends ReorderableAttributes {}

interface FetchProductsQuery {
  page: number
  aisle_ids?: string[]
  department_ids?: string[]
  inventory_area_ids?: string[]
  region_ids?: string[]
  available?: boolean
  only_primary_products?: boolean
  with_unclassified_products?: boolean
}

export interface FetchPrimaryProducts {
  primary_product_ids: number[]
}

export interface FetchDepartmentAisleProductsQuery
  extends RequireKeys<FetchProductsQuery, 'page' | 'department_ids'> {}

export const fetchAisles = async (params: DepartmentParams) => {
  const { partner_id, warehouse_id, department_id, skip_product_count } = params
  const query = { skip_product_count }

  return instacart.go.get(
    `/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments/${department_id}/aisles`,
    { query }
  )
}

export const fetchAisle = async (params: AisleParams) => {
  const { partner_id, warehouse_id, department_id, aisle_id } = params

  return instacart.go.get(
    `/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments/${department_id}/aisles/${aisle_id}`
  )
}

export const fetchAislePrimaryProducts = async (params: AisleParams) => {
  const { partner_id, warehouse_id, department_id, aisle_id } = params

  return instacart.go.get<FetchPrimaryProducts>(
    `/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments/${department_id}/aisles/${aisle_id}/primary_products`
  )
}

export const updateAisle = (
  params: DepartmentParams,
  aisle_id: number,
  attributes: AisleAttributes
) => {
  const { partner_id, warehouse_id, department_id } = params

  return instacart.go.put(
    `/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments/${department_id}/aisles/${aisle_id}`,
    { data: attributes }
  )
}

export const fetchDepartments = async (params: ScopedRouteParams) => {
  const { partner_id, warehouse_id } = params

  return instacart.go.get(`/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments`)
}

export const updateDepartment = (
  params: ScopedRouteParams,
  department_id: number,
  attributes: DepartmentAttributes
) => {
  const { partner_id, warehouse_id } = params

  return instacart.go.put(
    `/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments/${department_id}`,
    { data: attributes }
  )
}

export const fetchDepartment = async (params: DepartmentParams) => {
  const { partner_id, warehouse_id, department_id } = params

  return instacart.go.get(
    `/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments/${department_id}`
  )
}

export const fetchProducts = (params: ScopedRouteParams, query: FetchProductsQuery) => {
  const { partner_id: pid, warehouse_id: wid } = params

  return instacart.go.get(`/v1/partners/${pid}/warehouses/${wid}/products`, { query })
}

export const fetchDepartmentAisleProducts = (
  params: ScopedRouteParams,
  query: FetchDepartmentAisleProductsQuery
) => fetchProducts(params, query)

export const fetchInventoryAreas = (
  partner_id: string,
  warehouse_id: string,
  query: { region_ids?: string[] }
) =>
  instacart.go.get<ApiResponse<InventoryArea[]>>(
    `/v1/partners/${partner_id}/warehouses/${warehouse_id}/inventory_areas`,
    { query }
  )
