import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { NavSeparator } from './NavSeparator'

const NavFooterContent = styled.div({
  width: '100%',
  marginTop: 'auto',
  position: 'relative',
  bottom: 0,
})

export const NavFooterItems: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
  ...rest
}) => (
  <NavFooterContent {...rest}>
    <NavSeparator />
    {children}
  </NavFooterContent>
)
