import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute, DomainRedirect } from '../utils/domain/routingComponents'
import {
  webhookConfigurationAccessControl,
  webhookConfigurationModifyAccessControl,
} from '../access-control/webhookConfigurationAccess.configuration'

// @hygen:inject page-import
const WebhookConfigurationsTablePage = lazy(
  () =>
    import(
      /* webpackChunkName: "WebhookConfigurationsTablePage" */
      '../pages/webhook-configuration-table/WebhookConfigurationsTablePage'
    )
)

const WebhookConfigurationInfoPage = lazy(
  () =>
    import(
      /* webpackChunkName: "WebhookConfigurationInfoPage" */
      '../pages/webhook-configuration-info/WebhookConfigurationInfoPage'
    )
)

const WebhookConfigurationCreationFormPage = lazy(
  () =>
    import(
      /* webpackChunkName: "WebhookConfigurationCreationFormPage" */
      '../pages/webhook-configuration-creation-form/WebhookConfigurationCreationFormPage'
    )
)

const WebhookConfigurationEditFormPage = lazy(
  () =>
    import(
      /* webpackChunkName: "WebhookConfigurationEditFormPage" */
      '../pages/webhook-configuration-edit-form/WebhookConfigurationEditFormPage'
    )
)

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const webhookConfigurationRouter = (
  <DomainRoute route="webhook-configuration" accessControl={webhookConfigurationAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="webhook-configurations-table"
        component={WebhookConfigurationsTablePage}
      />
      <DomainRoute
        route="webhook-configuration-create"
        component={WebhookConfigurationCreationFormPage}
        accessControl={webhookConfigurationModifyAccessControl}
      />
      <DomainRoute
        route="webhook-configuration-edit"
        component={WebhookConfigurationEditFormPage}
        accessControl={webhookConfigurationModifyAccessControl}
      />
      <DomainRoute route="webhook-configuration-info" component={WebhookConfigurationInfoPage} />
      <DomainRedirect toRoute="webhook-configurations-table" />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
