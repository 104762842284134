// Please create a new permission per resource
// https://github.com/instacart/carrot/blob/master/retailer-tools/retailer-platform-api/app/support/permission.rb
// Example: https://github.com/instacart/carrot/pull/464461
// More instructions here: https://instacart.atlassian.net/wiki/spaces/Catalog/pages/2174812846
export enum Permission {
  CashflowCatalogAuditView = 'catalog_admin_cashflow_audit.view',
  CashflowCatalogAuditModify = 'catalog_admin_cashflow_audit.modify',
  CashflowCatalogEscalationAuditView = 'catalog_admin_cashflow_escalation_audit.view',
  CashflowCatalogEscalationAuditModify = 'catalog_admin_cashflow_escalation_audit.modify',
}
