import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'
import sortBy from 'lodash/sortBy'
import Spinner from '../Spinner'
import BannerCell from './BannerCell'

import { ColumnBase, ColumnTitle, ColumnRow } from './ListComponents'

import { useFetchWarehouses } from './queries/warehouses'

const BannersContent = styled(ColumnRow)`
  padding-left: 30px;
  padding-right: 20px;
`

const Message = styled.div`
  top: 50%;
  left: 45%;
  position: absolute;
  color: ${colors.GRAY_46};
`

interface Props {
  selectedPartnerId?: number
  selectWarehouse: (id: number) => void
  autoSelect?: boolean
  toggleAutoSelect?: (nextAutoSelectState: boolean) => void
}

const BannerList: FunctionComponent<React.PropsWithChildren<Props>> = ({
  selectedPartnerId,
  selectWarehouse,
  autoSelect,
  toggleAutoSelect,
}) => {
  const { warehouses, loading, error } = useFetchWarehouses({ id: selectedPartnerId })

  if (warehouses && !loading && !error) {
    if (autoSelect && warehouses && warehouses.length === 1) {
      selectWarehouse(warehouses[0].id)
    } else {
      toggleAutoSelect && toggleAutoSelect(false)
    }
  }

  const renderLoading = selectedPartnerId ? (
    <Spinner
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    />
  ) : null

  const renderBanners = () => {
    if (!warehouses) return null
    if (warehouses.length === 0) return <Message>No Banners Available</Message>

    return sortBy(warehouses, warehouse => warehouse.name).map(props => (
      <BannerCell {...props} key={props.id} onClick={selectWarehouse} />
    ))
  }

  return (
    <ColumnBase style={{ flex: 1 }}>
      <ColumnTitle>BANNER</ColumnTitle>

      <BannersContent>{warehouses ? renderBanners() : renderLoading}</BannersContent>
    </ColumnBase>
  )
}

export default BannerList
