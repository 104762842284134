import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const insightsPortalSummaryReportAccessControl: DomainAccessControlConfig = [
  {
    permissions: Permission.ExecutiveSummary,
  },
  {
    permissions: Permission.ExecutiveSummary,
  },
]
