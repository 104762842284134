import React, { useState, useEffect, useCallback, type FunctionComponent } from 'react'
import styled from '@emotion/styled'
// Ideally, we want to remove this ASAP, but we have no way of including modern IDS icons. TODO!
import { SVGIcon } from 'ic-snacks'
import { borderRadius, colors } from '../../foundation'
import { type TestID } from '../../utils/testing/types'
/**
 * Styles here can be a little tricky, as checked would just
 * come from the checked attribute and combining that with + or ~ selectors
 * to style stuff accordingly. Here we need styles on the parent, so we use
 * props instead.
 */
const StyledLabel = styled.label<{
  disabled?: boolean
  checked?: boolean
  size?: number
  indeterminate?: boolean
}>(
  {
    boxSizing: 'border-box',
    display: 'inline-flex',
    borderRadius: borderRadius.X4,
    border: `2px solid`,
    position: 'relative',
    margin: 3,
    verticalAlign: 'text-bottom',

    '& input': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      appearance: 'none',
      margin: 0,
    },

    '& .icon': {
      borderRadius: borderRadius.X4,
      boxSizing: 'border-box',
      padding: 2,
      position: 'absolute',
      color: 'white',
      top: -2,
      left: -2,
    },
  },
  ({ disabled, checked, indeterminate, size = 18 }) => {
    let borderColor: string = disabled ? colors.GRAYSCALE.X30 : colors.GRAYSCALE.X50
    borderColor = checked ? 'transparent' : borderColor
    let backgroundColor: string = indeterminate ? colors.GRAYSCALE.X50 : colors.PRIMARY.REGULAR
    const cursor = disabled ? 'not-allowed' : 'pointer'

    if (disabled) {
      backgroundColor = colors.GRAYSCALE.X30
    }

    return {
      borderColor,
      width: size,
      height: size,

      '& .icon': {
        width: size,
        height: size,
        display: checked || indeterminate ? 'block' : 'none',
        background: backgroundColor,
        cursor,
      },

      '& input': {
        cursor,
      },
    }
  }
)

export interface Props extends Omit<React.HTMLProps<HTMLInputElement>, 'type'>, TestID {
  checked?: boolean
  size?: number
  indeterminate?: boolean
}

export const CheckboxTestIds = {
  input: 'checkbox-input',
  label: 'checkbox-label',
  icon: 'checkbox-icon',
}

export const Checkbox: FunctionComponent<React.PropsWithChildren<Props>> = ({
  size,
  onChange,
  indeterminate,
  className,
  ...props
}) => {
  const outerChecked = props.checked
  const [checked, setChecked] = useState(props.checked ?? props.defaultChecked ?? false)

  /**
   * We keep internal state to manage styles in an easier way
   * but we use the incoming checked property to keep it in-sync
   */
  useEffect(() => {
    setChecked(outerChecked as boolean)
  }, [outerChecked])

  /**
   * This function aims to mimic uncontrolled behavior
   * if checked was passed explicitly, we use that value
   * otherwise, we let it change
   */
  const defaultCallback = useCallback(
    (evt: React.FormEvent<HTMLInputElement>) => {
      if (outerChecked !== undefined) return
      setChecked(evt.currentTarget.checked)
    },
    [setChecked, outerChecked]
  )

  const changeHandler = onChange || defaultCallback

  return (
    <StyledLabel
      data-testid={CheckboxTestIds.label}
      disabled={props.disabled}
      checked={checked}
      size={size}
      indeterminate={indeterminate}
      className={className}
    >
      <input
        data-testid={CheckboxTestIds.input}
        {...props}
        onChange={changeHandler}
        className="input"
        type="checkbox"
      />

      <SVGIcon
        aria-hidden="true"
        focusable="false"
        className="icon"
        name={indeterminate ? 'minus' : 'checkmark'}
        data-testid={CheckboxTestIds.icon}
      />
    </StyledLabel>
  )
}
