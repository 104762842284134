import React from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { campaignManagementAccessControl } from '../access-control/campaignManagementAccess.configuration'

// @hygen:inject page-import

export const campaignManagementRouter = (
  <DomainRoute route="campaign-management" accessControl={campaignManagementAccessControl}>
    <DashSwitch>
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
