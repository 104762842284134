import { type DashRouteType, URL_APP_ADMIN } from '@retailer-platform/dashboard/routing'

export const URL_ADMIN_YODA_RULES = `${URL_APP_ADMIN}/yoda-rules`
export const URL_ADMIN_YODA_RULES_APPROVAL_REQUEST_SHOW = `${URL_ADMIN_YODA_RULES}/approval-request/:approvalRequestId`
// @hygen:inject admin-urls

export type YodaRulesDashRouteSet =
  | DashRouteType<'app-admin-yoda-rules'>
  | DashRouteType<'app-admin-yoda-rules-approval-request/show'>
// @hygen:inject dash-route-set

export const yodaRulesRoutes: { [Key in YodaRulesDashRouteSet['identifier']]: string } = {
  'app-admin-yoda-rules': URL_ADMIN_YODA_RULES,
  'app-admin-yoda-rules-approval-request/show': URL_ADMIN_YODA_RULES_APPROVAL_REQUEST_SHOW,
  // @hygen:inject route-pair
}

export type YodaRulesDomainRouteName = keyof typeof yodaRulesRoutes
