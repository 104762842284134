import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRedirect, DomainRoute } from '../utils/domain/routingComponents'
import { partnerPickDevicesAccessControl } from '../access-control/partnerPickDevicesAccess.configuration'
const PartnerPickDevicesListPage = lazy(() => import('../pages/list/PartnerPickDevicesListPage'))
const PartnerPickDevicesEditPage = lazy(() => import('../pages/edit/PartnerPickDevicesEditPage'))
// @hygen:inject page-import

export const partnerPickDevicesRouter = (
  <DomainRoute route="partner-pick-devices" accessControl={partnerPickDevicesAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="partner-pick-devices-list"
        component={PartnerPickDevicesListPage}
        scopePicker={{
          titles: ['partnerPickDevicesDomain.title.new'],
        }}
      />
      <DomainRoute exact route="partner-pick-devices-edit" component={PartnerPickDevicesEditPage} />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}

      <DomainRedirect fromRoute="partner-pick-devices" toRoute="partner-pick-devices-list" />
    </DashSwitch>
  </DomainRoute>
)
