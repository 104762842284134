import gql from 'graphql-tag'
import { useDashQuery } from '../../../apollo/clientHooks'
import { type StoreGroup } from '../../../common/graphql-types/retailerLocationGroup.types'

export const FETCH_STORE_GROUP_QUERY = gql`
  query fetchStoreGroup($id: ID!) {
    retailerLocationGroup(id: $id) {
      id
      name
      updatedAt
      retailerLocationIds
    }
  }
`

export interface FetchStoreGroupQueryData {
  retailerLocationGroup: StoreGroup
}

export interface FetchStoreGroupQueryVariables {
  id: string
}

interface Props {
  storeGroupId: string
}

// TODO:
// Right now we are using the old table implementation, and API pagination hasn't been implemented
// Once we have updated it and implemented API pagination, we can remove the cap for max_pagination_nodes
// and replace it with proper pagination. This applies to all endpoints fetching retailerLocations
// Create, Update, Fetch, List
export const useFetchStoreGroup = ({ storeGroupId }: Props) => {
  const { data, error, loading } = useDashQuery<
    FetchStoreGroupQueryData,
    FetchStoreGroupQueryVariables
  >(FETCH_STORE_GROUP_QUERY, {
    variables: {
      id: storeGroupId,
    },
    skip: !storeGroupId,
    // Fix bug that displays stale data if you edit, save, and then edit again.
    // The default fetchPolicy, "cache-first", returns data from the cache, while
    // this option makes a new request each time. Ideally, we will update the cache
    // in our mutation queries, but this is a solution in the meantime.
    fetchPolicy: 'network-only',
  })

  const storeGroup = data && data.retailerLocationGroup

  return {
    storeGroup,
    loading,
    error,
  }
}
