import React, { type FunctionComponent, type MouseEventHandler } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import { type EmotionStyles } from '../../utils/styling/types'
import { fontSize, defaultLineHeight } from '../../foundation/fontSize'
import { fontWeight, type FontWeights } from '../../foundation/fontWeight'
import { type TestID } from '../../utils/testing/types'
import { spacing } from '../../foundation/spacing'
import { type TypographySizes } from '../../foundation/foundation.types'

export type TextVariant = 'link'

export type TextProps = EmotionStyles &
  TestID & {
    size?: TypographySizes
    weight?: 'regular' | 'semibold' | 'bold'
    display?: string
    title?: string
    className?: string
    variant?: TextVariant
    as?: React.ElementType
    onClick?: MouseEventHandler<any>
  }

const textStyles = css({
  marginTop: spacing.X0,
  marginBottom: spacing.X0,
  fontSize: fontSize.X12,
  lineHeight: defaultLineHeight,
})

const sizeStyles: { [key in TypographySizes]: SerializedStyles } = {
  small: css({
    fontSize: fontSize.X12,
    lineHeight: '18px',
  }),
  medium: css({
    fontSize: fontSize.X15,
    lineHeight: '22px',
  }),
  large: css({
    fontSize: fontSize.X18,
    lineHeight: '28px',
  }),
}

const linkVariantStyles: SerializedStyles = css({
  cursor: 'pointer',
  textDecoration: 'underline',
})

export const Text: FunctionComponent<React.PropsWithChildren<TextProps>> = ({
  styles,
  children,
  size = 'medium',
  weight = 'regular',
  display = 'block',
  variant,
  as: Component = 'div',
  ...rest
}) => {
  const variantStyles = getVariantStyles(variant)
  const dynamicStyles = css({
    fontWeight: fontWeight[weight.toUpperCase() as FontWeights],
    display: display,
  })

  return (
    <Component
      css={css(textStyles, dynamicStyles, variantStyles, sizeStyles[size], styles)}
      {...rest}
    >
      {children}
    </Component>
  )
}

function getVariantStyles(variant?: TextVariant) {
  if (variant === 'link') {
    return linkVariantStyles
  }

  return {}
}
