import React, { type FunctionComponent, useMemo, lazy } from 'react'
import { usePendingPartnerOnboardingRedirection } from '@retailer-platform/domain-onboarding'
import RetailerRoute from '../../../../legacy/components/RetailerRoute'
import Reports from '../../../../legacy/routes/reports/Reports'
import { DashRoute } from '../../../../legacy/dash-components/dash-route/DashRoute'
import { StoreGroupsSection } from '../../../../legacy/sections/store-groups/StoreGroupsSection'
import { StoreGroupsSectionAccessControl } from '../../../../legacy/sections/store-groups/StoreGroupsSection.configuration'
import { OrdersSection } from '../../../../legacy/sections/orders/OrdersSection'
import CatalogSection from '../../../../legacy/sections/catalog/CatalogSection'
import { CatalogSectionAccessControl } from '../../../../legacy/sections/catalog/CatalogSection.configuration'
import { GeneratedReportsSection } from '../../../../legacy/sections/generated-reports/GeneratedReportsSection'
import { GeneratedReportsSectionAccessControl } from '../../../../legacy/sections/generated-reports/GeneratedReportsSection.configuration'
import RetailerSwitch from '../../../../legacy/components/RetailerSwitch'
import { RouteSuspense } from '../../../../legacy/dash-components/route-suspense/RouteSuspense'
import { useCoreContext } from '../../../../utils/core/RPPCoreContext'
import { SettingsRoutes } from './settings/SettingsRoutes'

const Dashboard = lazy(() => import('../../../../legacy/routes/dashboard/Dashboard'))

export const RetailerScopedRoutes: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const coreContext = useCoreContext()

  const scopedRouters = useMemo(
    () => coreContext.scopedRouters.map(el => React.cloneElement(el, { key: el.props.route })),
    [coreContext]
  )
  const { loading } = usePendingPartnerOnboardingRedirection()

  if (loading) {
    return null
  }

  return (
    <RouteSuspense>
      <RetailerSwitch>
        <RetailerRoute
          route="dashboard"
          component={Dashboard}
          scopePicker={{
            retailerSelectionOptional: true,
          }}
        />
        <RetailerRoute
          route="catalog"
          component={CatalogSection}
          accessControl={CatalogSectionAccessControl}
          scopePicker={{}}
        />
        <RetailerRoute route="reports" component={Reports} />
        <DashRoute route="orders" component={OrdersSection} />
        <DashRoute route="settings" component={SettingsRoutes} />
        <DashRoute
          route="generated-reports"
          component={GeneratedReportsSection}
          accessControl={GeneratedReportsSectionAccessControl}
        />
        <DashRoute
          route="store-groups"
          component={StoreGroupsSection}
          accessControl={StoreGroupsSectionAccessControl}
        />
        {scopedRouters}
      </RetailerSwitch>
    </RouteSuspense>
  )
}
