import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint, NavItem } from '@retailer-platform/dashboard/routing'
import React from 'react'
import { NewBadge } from '@retailer-platform/dashboard/gin-and-tonic'
import { siteManagementRouter } from './routing/siteManagementRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { siteManagementNavigationAccessControl } from './access-control/siteManagementNavigationAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'site-management',
  totem: {
    entity: 'site-management-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [siteManagementRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.RetailerFundedMarketing,
      attachToV2: NavEntryPoint.Merchandising,
      subNavItems: [
        {
          type: 'item',
          labelId: 'siteManagementDomain.navigation.page.title',
          route: 'site-management-navigation-desktop',
          accessControl: siteManagementNavigationAccessControl,
          navBadge: React.createElement(NewBadge, null),
          isNew: true,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
  // NOTE TO DEV: run `yarn ipp domain register <your-system-name>` to register your domain with Totem here
}

export default definition
