import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

const URL_AUDIT_LOGS = `${URL_RETAILER_SCOPED}/audit-logs`
const URL_AUDIT_LOGS_LIST = `${URL_AUDIT_LOGS}/list`
// @hygen:inject scoped-urls

export type DashRouteSet = DashRouteType<'audit-logs'> | DashRouteType<'audit-logs-list'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'audit-logs': URL_AUDIT_LOGS,
  'audit-logs-list': URL_AUDIT_LOGS_LIST,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
