import { useEffect } from 'react'
import { useNavContext } from '../../../gin-and-tonic/containers/nav/utils/NavContext'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'
import { useOptionalPartnerContext } from '../../../utils/contexts/partner/PartnerContext.hooks'

export const useAllowNavV1Access = () => {
  const { allowNavV1RouletteEnabled, setAllowNavV1RouletteEnabled } = useNavContext()

  const partnerContext = useOptionalPartnerContext()
  const { account: currentAccount } = useCurrentAccountDetails()
  const canAccountUseV1 = !!currentAccount?.featureToggles?.find(
    feature => feature.name === 'rt_ia_allow_classic'
  )

  const canPartnerUseV1 = !!partnerContext?.featureToggles?.find(
    feature => feature.name === 'rt_ia_allow_classic'
  )

  const enabled = canAccountUseV1 || canPartnerUseV1

  useEffect(() => {
    if (allowNavV1RouletteEnabled !== enabled) {
      setAllowNavV1RouletteEnabled(enabled)
    }
  })
}
