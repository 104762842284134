import React, { type FunctionComponent, useEffect, Fragment, useState } from 'react'
import { Button, SVGIcon, colors } from 'ic-snacks'
import { injectIntl } from 'react-intl'
import { type InjectedDashIntlProps } from '../../../../../../intl/intl.types'
import { useDeleteStoreGroup } from '../../../graphql/delete.hooks'
import { useNotifications } from '../../../../../common/utils/notifications/notifications.hooks'
import { type StoreGroup } from '../../../../../common/graphql-types/retailerLocationGroup.types'
import { StoreGroupsListConfirmDeleteModal } from './StoreGroupsListConfirmDeleteModal'

const deleteIcon = <SVGIcon name="delete" color={colors.RED_500} />

interface Props extends InjectedDashIntlProps {
  storeGroup: StoreGroup
}

const _StoreGroupsListTableDeleteButton: FunctionComponent<React.PropsWithChildren<Props>> = ({
  storeGroup: { id: storeGroupId, name: storeGroupName, retailerLocationIds },
  intl,
}) => {
  const { deleteStoreGroup, loading, success } = useDeleteStoreGroup()
  const { notify } = useNotifications()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const onClick = () => setShowConfirmationModal(true)
  const onConfirm = () => deleteStoreGroup({ storeGroupId })

  // Hide confirmation modal & notify after successful deletion
  useEffect(() => {
    if (!success) return
    notify(
      intl.formatMessage(
        { id: 'settings.storeGroups.notifications.deleteSuccess' },
        { storeGroupName }
      )
    )
    setShowConfirmationModal(false)
  }, [intl, notify, setShowConfirmationModal, storeGroupName, success])

  return (
    <Fragment>
      {showConfirmationModal && (
        <StoreGroupsListConfirmDeleteModal
          storeGroupName={storeGroupName}
          retailerLocationCount={retailerLocationIds.length}
          onClose={() => {
            setShowConfirmationModal(false)
          }}
          onConfirm={onConfirm}
        />
      )}
      <Button
        disabled={loading}
        icon={deleteIcon}
        snacksStyle="flat"
        style={{ paddingLeft: 0, paddingRight: 0 }}
        onClick={onClick}
        elementAttributes={{ 'data-testid': 'store-group-delete-button' }}
      />
    </Fragment>
  )
}

export const StoreGroupsListTableDeleteButton = injectIntl(_StoreGroupsListTableDeleteButton)
