import { type DocumentNode } from 'graphql'
import {
  type MutationHookOptions,
  type QueryHookOptions,
  useMutation,
  useQuery,
  useLazyQuery,
} from '@apollo/react-hooks'
import {
  fetchDashboardClient,
  fetchRetailerPlatformMeshClient,
  fetchRetailersClient,
} from './dashboardClient'

// no-op onError, to avoid GraphQL errors being raised.
// (Errors will still be returned as part of the result.)
// References:
// https://github.com/apollographql/react-apollo/issues/2737
// https://www.apollographql.com/docs/react/data/mutations/#options
const onError = () => undefined

export const useDashMutation = <TData, TVariables>(
  query: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
) => useMutation<TData, TVariables>(query, { onError, ...options, client: fetchDashboardClient() })

export const useDashQuery = <TData, TVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => useQuery<TData, TVariables>(query, { ...options, client: fetchDashboardClient() })

export const useLazyDashQuery = <TData, TVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => useLazyQuery<TData, TVariables>(query, { ...options, client: fetchDashboardClient() })

export const useRetailersMutation = <TData, TVariables>(
  query: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
) => useMutation<TData, TVariables>(query, { ...options, client: fetchRetailersClient(), onError })

export const useRetailersQuery = <TData, TVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => useQuery<TData, TVariables>(query, { ...options, client: fetchRetailersClient() })

export const useLazyRetailersQuery = <TData, TVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => useLazyQuery<TData, TVariables>(query, { ...options, client: fetchRetailersClient() })

export const useMeshQuery = <TData, TVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
) => useQuery<TData, TVariables>(query, { ...options, client: fetchRetailerPlatformMeshClient() })
