import {
  type DashRouteSet as SharedDataIngestionRouteSet,
  routes as SharedDataIngestionRoutes,
} from '@retailer-platform/domain-approvals-management-library'

// @hygen:inject admin-urls

export type DashRouteSet = SharedDataIngestionRouteSet

// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  ...SharedDataIngestionRoutes,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
