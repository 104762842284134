import React from 'react'
import { isElement } from 'react-is'
// eslint-disable-next-line no-restricted-imports
import { Switch } from 'react-router'
import { type RouteName } from '../../utils/routing/routes'
import { useCoreContext } from '../../utils/core/RPPCoreContext'
import { DashRoute } from '../dash-components/dash-route/DashRoute'
import { NotFoundPage } from '../dash-components/not-found-page/NotFoundPage'

interface Props extends React.ComponentPropsWithoutRef<typeof Switch> {}

/**
 * The react-router `Switch` component requires `path`
 * to be set for it's internal implementation.
 *
 * This wrapper allows us to use our named routes with
 * the `RetailerRouter` and the `Switch` functionality.
 *
 * We also semi-duplicate some access control logic here, because we
 * want `Switch` to be able to fall back to the "Not Found" page
 * if a user does not have access to a route. If `Switch` is able to
 * make a match we end up just displaying a blank screen instead.
 *
 * RetailerRoute still accepts the `route` prop without this.
 */
const RetailerSwitch: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  ...rest
}) => {
  const { routesByName } = useCoreContext()

  function renderChild(child: React.ReactNode) {
    if (isElement(child) && child.props.route) {
      return React.cloneElement(child, {
        path: child.props.route ? routesByName[child.props.route as RouteName] : child.props.path,
        // Don't duplicate access check in RetailerRoute
        accessControl: child.props.accessControl,
        route: undefined,
      })
    }

    return child
  }

  return (
    <Switch {...rest}>
      {React.Children.map(children, renderChild)}
      <DashRoute component={NotFoundPage} />
    </Switch>
  )
}

export default RetailerSwitch
