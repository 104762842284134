import React, { type FunctionComponent } from 'react'
import { Text, colors } from 'ic-snacks'
import { ErrorMessage } from 'formik'

// Radium currently throws out a warning when using short form
// for `padding: 123` with the Text component
const textStyle = {
  paddingTop: 15,
  paddingLeft: 15,
  paddingRight: 15,
  PaddingBottom: 15,
  color: colors.RED_700,
}

export const StoreGroupsFormSelectedListErrors: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => (
  <ErrorMessage name="selectedLocationIds">
    {msg => (
      <Text variant="T.14" elementType="div" style={textStyle}>
        {msg}
      </Text>
    )}
  </ErrorMessage>
)
