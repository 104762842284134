import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { tdsPlaygroundAdminAccessControl } from '../access-control/tdsPlaygroundAdminAccess.configuration'

const TdsPlaygroundComponentsPage = lazy(
  () => import('../pages/components/TdsPlaygroundComponentsPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const tdsPlaygroundAdminRouter = (
  <DomainRoute route="app-admin-tds-playground" accessControl={tdsPlaygroundAdminAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-tds-playground-components"
        component={TdsPlaygroundComponentsPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
