import { JsonParam } from 'use-query-params'
import { compactNullObject } from '../objects/compact'
import { type QueryParamEncodedType } from './queryParams.types'

type BaseType = { [key: string]: string | null | undefined } | undefined

const makeEncoder =
  <TResult extends BaseType>() =>
  (inputValue: TResult | undefined): QueryParamEncodedType => {
    if (!inputValue) return undefined

    const compactedInputValue = compactNullObject(inputValue)

    return JsonParam.encode(compactedInputValue)
  }

const makeDecoder =
  <TResult extends BaseType>() =>
  (queryStr: QueryParamEncodedType): Partial<TResult> | undefined => {
    if (!queryStr) return undefined

    // Returns a partial because if any nulls were compacted out, they won't existin
    // on the object anymore
    return JsonParam.decode(queryStr) as Partial<TResult>
  }

export const makeGenericPartialStringObjectParamEncoder = <TResult extends BaseType>() => ({
  encode: makeEncoder<TResult>(),
  decode: makeDecoder<TResult>(),
})

export const partialStringObjectQueryParamEncoder =
  makeGenericPartialStringObjectParamEncoder<BaseType>()
