// @ts-nocheck legacy file transformed to TS
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

class FormattedTimeWithOffset extends React.Component<any> {
  static propTypes = {
    value: PropTypes.instanceOf(Date),
    offset: PropTypes.number,
  }

  render() {
    const { value, offset, format } = this.props

    if (format) {
      return <React.Fragment>{moment.utc(value).add(offset, 'h').format(format)}</React.Fragment>
    }

    const offsetMoment = moment.utc(value).add(offset, 'h')

    if (offsetMoment.format('mm') === '00') {
      return <React.Fragment>{offsetMoment.format('h A')}</React.Fragment>
    }

    return <React.Fragment>{offsetMoment.format('h:mm A')}</React.Fragment>
  }
}

export default FormattedTimeWithOffset
