import { type Ref } from 'react'

// react-window's WindowList makes some bad assumptions about the refs
// It assumes they will be class components with react methods
// To avoid an error, we mock out the `forceUpdate` method
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeWindowListRefHandler = (ref?: Ref<any>) => (target: any) => {
  if (ref instanceof Function) {
    if (target) {
      target.forceUpdate = () => null
    }

    ref(target)
  }
}
