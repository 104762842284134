import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import {
  DayjsDateParam,
  SimpleOptionsParam,
  compactObject,
  makeGenericStringQueryParamEncoder,
} from '@retailer-platform/dashboard/utils'
import {
  type ComparisonPeriod,
  DateRangePickerPresetOption,
  WeekStartDay,
} from '@retailer-platform/shared-components/src/common/date-range-picker/utils/DateRangePickerPresets.utils'
import { StringParam, useQueryParams } from 'use-query-params'
import { insightsSchemaConfigs } from '../../schemas/configuration/schema.configuration'
import {
  InsightsWeekStartDayLocalStorageKey,
  type FilterParamsFilterNames,
} from './filterParams.types'
import { FilterParamsContext } from './filterParamsContext'

export const defaultFiltersValues = {
  weekStartDay: WeekStartDay.Monday,
  comparisonType: 'lastPeriod',
  retailer: [],
  state: [],
  region: [],
  storeLocation: [],
  storeLocationName: [],
  storeLocationCode: [],
  orderSource: [],
  fulfillmentType: [],
  alcohol: [],
  express: [],
  storeGroup: [],
  convenience: [],
  firstOrder: [],
  ebt: [],
  loyalty: [],
  L1Category: [],
  L2Category: [],
  L3Category: [],
  L4Category: [],
  L5Category: [],
  L6Category: [],
  partnerDivision: [],
  orderCurrency: '',
  popularSearchTerm: [],
  campaignStatus: [],
  campaignName: [],
  campaignEndingSoon: [],
  campaignType: [],
  taasUserSegmentCampaignType: [],
  dimUserSegmentCampaignType: [],
  userSegmentCampaignType: [],
  taasUserSegment: [],
  demandSource: [],
  campaign: [],
  campaignObjective: [],
  dimCampaignObjective: [],
  collectionId: [],
  platform: [],
  placementId: [],
  placementType: [],
  placementStatus: [],
  placementUserSegment: [],
  discoverability: [],
  collectionLevel: [],
  collectionL1: [],
  collectionL2: [],
  collectionL3: [],
  deliveryState: [],
  fsaHsaOrder: [],
  rxOrder: [],
  guestUser: [],
}

export const useFilterParams = () => {
  // API V1 Filter params
  const [selectedFiltersValues, setSelectedFiltersValues] = useQueryParams({
    retailer: SimpleOptionsParam,
    state: SimpleOptionsParam,
    region: SimpleOptionsParam,
    storeLocation: SimpleOptionsParam,
    storeLocationName: SimpleOptionsParam,
    storeLocationCode: SimpleOptionsParam,
    storeGroup: SimpleOptionsParam,
    orderSource: SimpleOptionsParam,
    fulfillmentType: SimpleOptionsParam,
    loyalty: SimpleOptionsParam,
    express: SimpleOptionsParam,
    firstOrder: SimpleOptionsParam,
    ebt: SimpleOptionsParam,
    alcohol: SimpleOptionsParam,
    convenience: SimpleOptionsParam,
    L1Category: SimpleOptionsParam,
    L2Category: SimpleOptionsParam,
    L3Category: SimpleOptionsParam,
    L4Category: SimpleOptionsParam,
    L5Category: SimpleOptionsParam,
    L6Category: SimpleOptionsParam,
    popularSearchTerm: SimpleOptionsParam,
    partnerDivision: SimpleOptionsParam,
    orderCurrency: StringParam,
    campaignStatus: SimpleOptionsParam,
    campaignName: SimpleOptionsParam,
    campaignEndingSoon: SimpleOptionsParam,
    campaignObjective: SimpleOptionsParam,
    dimCampaignObjective: SimpleOptionsParam,
    campaignType: SimpleOptionsParam,
    taasUserSegmentCampaignType: SimpleOptionsParam,
    dimUserSegmentCampaignType: SimpleOptionsParam,
    userSegmentCampaignType: SimpleOptionsParam,
    taasUserSegment: SimpleOptionsParam,
    demandSource: SimpleOptionsParam,
    campaign: SimpleOptionsParam,
    collectionId: SimpleOptionsParam,
    platform: SimpleOptionsParam,
    placementId: SimpleOptionsParam,
    placementType: SimpleOptionsParam,
    placementStatus: SimpleOptionsParam,
    placementUserSegment: SimpleOptionsParam,
    discoverability: SimpleOptionsParam,
    collectionLevel: SimpleOptionsParam,
    collectionL1: SimpleOptionsParam,
    collectionL2: SimpleOptionsParam,
    collectionL3: SimpleOptionsParam,
    deliveryState: SimpleOptionsParam,
    fsaHsaOrder: SimpleOptionsParam,
    rxOrder: SimpleOptionsParam,
    guestUser: SimpleOptionsParam,
  })

  // add params that need a default here
  const selectedFiltersValuesWithDefaults: typeof selectedFiltersValues = {
    ...defaultFiltersValues,
    ...compactObject(selectedFiltersValues),
  }

  type FilterParamsSelections = Parameters<typeof setSelectedFiltersValues>[0]

  function setFilterSelection<TName extends FilterParamsFilterNames>(
    name: TName,
    selection: FilterParamsSelections[TName]
  ) {
    setSelectedFiltersValues({ [name]: selection })
  }

  // API V2 Filter params
  // get list of all Dimension names from all schemas, excluding date dimensions
  const allDimensions: Set<string> = useMemo(
    () =>
      Object.values(insightsSchemaConfigs).reduce((dimensions, { dimensionsConfig }) => {
        if (!dimensionsConfig) return dimensions

        Object.entries(dimensionsConfig)
          .filter(([, config]) => !config.date)
          .forEach(([dim]) => dimensions.add(dim))

        return dimensions
      }, new Set<string>()),
    []
  )

  // create query param config for each dimension
  const dimensionQueryParamConfig = useMemo(
    () => Object.fromEntries(Array.from(allDimensions).map(dim => [dim, SimpleOptionsParam])),
    [allDimensions]
  )

  const [selectedDimensionFilterValues, setSelectedDimensionFilterValues] =
    useQueryParams(dimensionQueryParamConfig)

  // Date filter params
  const [selectedDateFilterValues, setSelectedDateFilterValues] = useQueryParams({
    from: DayjsDateParam,
    to: DayjsDateParam,
    dateRange: makeGenericStringQueryParamEncoder<DateRangePickerPresetOption>(),
    comparisonType: makeGenericStringQueryParamEncoder<ComparisonPeriod>(),
    weekStartDay: makeGenericStringQueryParamEncoder<WeekStartDay>(),
  })

  const setDates: typeof setSelectedDateFilterValues = values => {
    if (values['weekStartDay']) {
      localStorage.setItem(InsightsWeekStartDayLocalStorageKey, values['weekStartDay'] as string)
    }

    setSelectedDateFilterValues(values)
  }

  const selectedDateValuesWithDefaults = Object.assign(
    {
      from: dayjs().subtract(1, 'week').startOf('day'),
      to: dayjs().subtract(1, 'days').endOf('day'),
      weekStartDay:
        (localStorage.getItem(InsightsWeekStartDayLocalStorageKey) as WeekStartDay) ||
        WeekStartDay.Monday,
      dateRange: DateRangePickerPresetOption.Last7Days,
    },
    compactObject(selectedDateFilterValues)
  )

  return {
    dates: selectedDateValuesWithDefaults,
    setDates,

    // API V1
    selectedFiltersValues: selectedFiltersValuesWithDefaults,
    setSelectedFiltersValues,
    setFilterSelection,

    // API V2
    selectedDimensionFilterValues,
    setSelectedDimensionFilterValues,
  }
}

export const FilterParamsProvider: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const filterParamsState = useFilterParams()

  return (
    <FilterParamsContext.Provider value={filterParamsState}>
      {children}
    </FilterParamsContext.Provider>
  )
}
