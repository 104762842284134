import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { colors, fontSize } from '@retailer-platform/shared-components'
import { useIntl } from 'react-intl'
import { useNavContext } from '../../../nav/utils/NavContext'
import { type NavMenuHierarchyL1 } from '../../NavMenuHierarchy'
import { useGatherAllRoutes } from '../navBar.common'
import { useAbsoluteRouteMatch } from '../../../../../utils/routing/useAbsoluteRouteMatch.hooks'

type Props = {
  title: string
  l1Key: number
  l1: NavMenuHierarchyL1
  setCurrentlySelectedItem: any
  currentlySelectedItem: number
  hoverColor: string
  backgroundColor: string
}

export const MenuItemTitleContainer = styled.div<{
  currentlySelected: boolean
  color: string
  backgroundColor: string
  hoverColor: string
  customPadding?: string
}>(
  {
    display: 'flex',
    height: 48,

    fontSize: fontSize.X12,
    userSelect: 'none',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',

    alignItems: 'center',

    cursor: 'pointer',
  },
  ({ color, backgroundColor, currentlySelected, hoverColor, customPadding }) => ({
    color,
    backgroundColor,
    padding: customPadding ? customPadding : '8px 16px',
    '&:hover': !currentlySelected && {
      backgroundColor: hoverColor,
    },
  })
)

const MenuItemTitle = styled.div<{
  underlined: boolean
}>(
  {
    display: 'flex',
    width: '100%',
    height: '40px',
    alignItems: 'center',
  },
  ({ underlined }) => ({
    borderBottom: underlined && '2px solid white',
  })
)

export const NavMenuTopL1Item: FunctionComponent<React.PropsWithChildren<Props>> = ({
  title,
  l1Key,
  l1,
  setCurrentlySelectedItem,
  currentlySelectedItem,
  hoverColor,
  backgroundColor,
}) => {
  const routeParams = useAbsoluteRouteMatch()
  const { mostRecentClickedRoute } = useNavContext()
  const intl = useIntl()

  const currentlyOpen = currentlySelectedItem == l1Key

  const routes = useGatherAllRoutes(l1)

  const underlined =
    routes?.includes(routeParams?.route) || routes?.includes(mostRecentClickedRoute)

  return (
    <>
      <MenuItemTitleContainer
        currentlySelected={currentlyOpen}
        color={currentlyOpen ? backgroundColor : colors.GRAYSCALE.X0}
        backgroundColor={currentlyOpen ? colors.GRAYSCALE.X0 : backgroundColor}
        hoverColor={hoverColor}
        onClick={() => {
          if (currentlyOpen) {
            setCurrentlySelectedItem(null)
          } else {
            setCurrentlySelectedItem(l1Key)
          }
        }}
      >
        {title && (
          <MenuItemTitle underlined={underlined}>{intl.formatMessage({ id: title })}</MenuItemTitle>
        )}
      </MenuItemTitleContainer>
    </>
  )
}
