import React from 'react'
import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'
import { ConfirmIcon, StoreIcon } from '@instacart/ids-core'
import useAccessControl from '../../../legacy/components/AccessControl/useAccessControl'
import { useDashMessage } from '../../../intl/intl.hooks'
import { useOptionalAccessControlsContext } from '../../../utils/contexts/access-controls/AccessControls.hooks'
import { accessControlsHasAccess } from '../../../utils/contexts/access-controls/AccessControlsContext'
import { StyledLogoDiv, StyledLogoImage, Tag } from './RetailerAndSitePicker.styles'

interface RetailerItemProps {
  selected: boolean
  hasAccess?: boolean
}

const RightAlign = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: '0',
})

const RetailerItemRoot = styled.div<RetailerItemProps>(({ selected, hasAccess }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 8px 8px 8px',
  gap: '8px',
  minWidth: '240px',
  minHeight: '48px',
  fontSize: '14px',
  lineHeight: '20px',
  color: !hasAccess ? colors.GRAYSCALE.X70 : null,
  backgroundColor: selected ? colors.GRAYSCALE.X20 : null,
  userSelect: 'none',
  '&:hover': hasAccess
    ? {
        backgroundColor: colors.GRAYSCALE.X20,
        cursor: 'pointer',
      }
    : null,
}))

interface TextProps {
  hasAccess: boolean
}

const Text = styled.div<TextProps>(({ hasAccess }) => ({
  color: !hasAccess ? colors.GRAYSCALE.X70 : null,
}))

export const RetailerItem = ({ retailer, selected, onClick, onMouseEnter, currentlySelected }) => {
  const hasAccessControl = useAccessControl({ ...retailer.context, validId: true, loaded: true })
  const messageNotApplicable = useDashMessage('retailerPicker.notApplicable')
  const accessControlsContext = useOptionalAccessControlsContext()

  const accessControls = accessControlsContext?.accessControls || []
  const hasAccess = accessControlsHasAccess(accessControls, hasAccessControl)

  return (
    <RetailerItemRoot
      id={retailer.value}
      key={retailer.value}
      selected={selected}
      onClick={() => {
        hasAccess && onClick()
      }}
      onMouseEnter={() => {
        hasAccess && onMouseEnter()
      }}
      hasAccess={hasAccess}
    >
      {retailer.logo?.url ? (
        <StyledLogoImage grayout={!hasAccess} src={retailer.logo?.url} alt="logo" />
      ) : (
        <StyledLogoDiv>
          <StoreIcon size={30} color="systemGrayscale30" />
        </StyledLogoDiv>
      )}
      <Text hasAccess={hasAccess}>{retailer.label}</Text>
      <RightAlign>
        {hasAccess ? (
          currentlySelected && <ConfirmIcon size={14} color="brandPrimaryRegular" />
        ) : (
          <Tag color={'#F6F7F8'}>{messageNotApplicable}</Tag>
        )}
      </RightAlign>
    </RetailerItemRoot>
  )
}
