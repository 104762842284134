import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { PARTNER_ID_KEY, RETAILER_ID_KEY, RETAILER_IDS_KEY } from '../../utils/events/analytics'
import { usePartnerId, useRetailerId, useRetailerIds } from '../../utils/routing/params.hooks'

export const AttachPartnerAnalyticsMetadata = ({
  partnerId: partnerIdArg,
}: {
  partnerId?: string
}) => {
  const partnerIdDefault = usePartnerId({ throwOnMissing: false })
  const partnerId = partnerIdArg ?? partnerIdDefault

  useEffect(() => {
    datadogRum.setGlobalContextProperty(PARTNER_ID_KEY, partnerId)

    return () => {
      datadogRum.removeGlobalContextProperty(PARTNER_ID_KEY)
    }
  }, [partnerId])

  return null
}

export const AttachRetailerAnalyticsMetadata = ({
  retailerId: retailerIdArg,
  retailerIds: retailerIdsArg,
}: {
  retailerId?: string
  retailerIds?: string[]
}) => {
  const retailerIdDefault = useRetailerId({ throwOnMissing: false })
  const retailerIdsDefault = useRetailerIds({ throwOnMissing: false })
  const retailerId = retailerIdArg ?? retailerIdDefault
  const retailerIds = retailerIdsArg ?? retailerIdsDefault

  useEffect(() => {
    datadogRum.setGlobalContextProperty(RETAILER_ID_KEY, retailerId)
    datadogRum.setGlobalContextProperty(RETAILER_IDS_KEY, retailerIds)

    return () => {
      datadogRum.removeGlobalContextProperty(RETAILER_ID_KEY)
      datadogRum.removeGlobalContextProperty(RETAILER_IDS_KEY)
    }
  }, [retailerIds, retailerId])

  return null
}
