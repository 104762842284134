import {
  Button,
  Checkbox,
  type GridColumns,
  GridList,
  Modal,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  Text,
  useDebouncedState,
} from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useCallback } from 'react'
import { LoadingGenericBase } from '@instacart/ids-customers'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Input, useNotifications } from '@retailer-platform/dashboard/legacy'
import {
  type NotificationsNotificationTypeAccount,
  useGetAccountByNotificationTypeQuery,
  useSubscribeNotificationMutation,
  useUnsubscribeNotificationMutation,
} from '../../api'
import { useDomainMessage, useDomainMessages } from '../../utils/domain/intl'
interface NotificationsModalProps {
  eventName: string
  retailerId?: string
  notificationTypeId: string
  showModal: boolean
  setShowModal: (show: boolean) => void
}

export const NotificationsModal: FunctionComponent<
  React.PropsWithChildren<NotificationsModalProps>
> = ({ eventName, retailerId, notificationTypeId, showModal, setShowModal }) => {
  const [searchEmail, debouncedEmail, setSearchEmail] = useDebouncedState<string>('', 500)

  const { data, loading, error, refetch } = useGetAccountByNotificationTypeQuery({
    variables: {
      retailerId,
      notificationTypeId,
      ilikeEmail: debouncedEmail,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [subscribeNotificationMutation, { loading: subLoading }] =
    useSubscribeNotificationMutation()
  const [unsubscribeNotificationMutation, { loading: unsubLoading }] =
    useUnsubscribeNotificationMutation()

  const i18n = useDomainMessages({
    failedMessage: 'notificationsDomain.modal.message.update.failed',
    noUsersFound: 'notificationsDomain.noUsersFound',
    list100Max: 'notificationsDomain.modal.list100Max',
  })
  const { notifyError } = useNotifications()

  const handleToastNotifications = useCallback(
    ({ errors }) => {
      if (errors) {
        notifyError(i18n.failedMessage, 'modern')
      }
    },
    [notifyError, i18n]
  )

  const handleSubscribe = useCallback(
    accountId =>
      subscribeNotificationMutation({
        variables: {
          accountId,
          notificationTypeId,
          retailerId,
        },
        update: (_, { data, errors }) => {
          handleToastNotifications({ errors })
          refetch?.()
        },
      }),
    [
      subscribeNotificationMutation,
      notificationTypeId,
      retailerId,
      handleToastNotifications,
      refetch,
    ]
  )

  const handleUnsubscribe = useCallback(
    accountId =>
      unsubscribeNotificationMutation({
        variables: {
          accountId,
          notificationTypeId,
          retailerId,
        },
        optimisticResponse: {
          notificationSubscriptionDelete: {
            success: true,
            __typename: 'NotificationSubscriptionDeletePayload',
          },
        },
        update: (_, { data, errors }) => {
          handleToastNotifications({ errors })
          refetch?.()
        },
      }),
    [
      unsubscribeNotificationMutation,
      notificationTypeId,
      retailerId,
      handleToastNotifications,
      refetch,
    ]
  )

  const columns: GridColumns<NotificationsNotificationTypeAccount> = [
    {
      id: 'name',
      width: 100,
      Header: useDomainMessage('notificationsDomain.modal.columnTitle.name'),
      Cell: ({
        row: {
          original: { givenName, surname },
        },
      }) => <div>{`${givenName} ${surname}`}</div>,
    },
    {
      id: 'email',
      Header: useDomainMessage('notificationsDomain.modal.columnTitle.email'),
      Cell: ({
        row: {
          original: { email },
        },
      }) => <div>{email}</div>,
    },
    {
      id: 'subscribe',
      width: 50,
      Header: useDomainMessage('notificationsDomain.modal.columnTitle.subscribe'),
      Cell: ({
        row: {
          original: { isSubscribed, isEligible, accountId },
        },
      }) => {
        const message = useDomainMessage('notificationsDomain.modal.cell.ineligible')

        return !isEligible ? (
          <div>{message}</div>
        ) : (
          <Checkbox
            id={accountId}
            checked={isSubscribed}
            onChange={() => {
              isSubscribed ? handleUnsubscribe(accountId) : handleSubscribe(accountId)
            }}
          />
        )
      },
    },
  ]

  // This is not placing on <Input placeholder={} /> because it will break the rule of hooks
  // Ref: https://reactjs.org/docs/hooks-rules.html
  const filterInputPlaceholder = useDomainMessage('notificationsDomain.modal.search.placeholder')
  const closeButtonTitle = useDomainMessage('notificationsDomain.modal.button.close')

  const handleModalClose = () => {
    setSearchEmail('')
    setShowModal(false)
  }

  return (
    <Modal onRequestClose={handleModalClose} isOpen={showModal} maxWidth={800} maxHeight={800}>
      <ModalContainer style={{ backgroundColor: 'white' }}>
        <ModalHeader>{eventName}</ModalHeader>
        <ModalBody css={{ height: 525 }}>
          <Input
            value={searchEmail}
            autoFocus
            outerStyles={{
              styles: 'display: flex; width: 97%;',
              name: 'notifications-email-filter-style',
            }}
            name="notifications-email-search"
            placeholder={filterInputPlaceholder}
            onChange={e => setSearchEmail(e.target.value)}
          />
          {(loading || subLoading || unsubLoading || error) && (
            <div
              css={{
                height: 400,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 100,
              }}
            >
              <LoadingGenericBase styles={{ container: { padding: 60 } }} />
            </div>
          )}

          <GridList
            height={400}
            onLoadMore={() => {
              //
            }}
            data={data?.accountsByNotificationType || []}
            columns={columns}
            loadingMore={false}
            canLoadMore={false}
            emptyStateMessage={i18n.noUsersFound}
          />
        </ModalBody>
        <ModalFooter css={{ justifyContent: 'space-between' }}>
          <Text css={{ fontStyle: 'italic' }}>
            {(data?.accountsByNotificationType?.length || 0) >= 100 && i18n.list100Max}
          </Text>
          <Button onClick={handleModalClose}>{closeButtonTitle}</Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}
