/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { instacart, dateToStringFormat } from '@retailer-platform/dashboard/legacy'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import {
  type DateRange,
  type RetailerLocationSelectValueType,
  type DateSelectRangeValueType,
} from '@retailer-platform/dashboard/legacy'
import { compactNullObject } from '@retailer-platform/dashboard/utils'
import { type PickupAnalyticsApiData, type PickupAnalyticsApiMetric } from './pickupAnalytics.types'

export interface PickupAnalyticsFetchDataProps {
  partnerId: string
  retailerId: string
  pageNumber?: number
  dateSelectRangeValue?: DateSelectRangeValueType
  retailerLocationSelectValue?: RetailerLocationSelectValueType
}

interface PickupAnalyticsFetchDataQueryParams {
  time_range?: DateRange
  start_date?: string
  end_date?: string
  store_group_id?: string
  retailer_location_ids?: ReadonlyArray<string>
}

export type PickupAnalyticsApiResponse = {
  data: PickupAnalyticsApiData
}

export const convertFetchDataPropsToApiQueryParams = ({
  dateSelectRangeValue,
  retailerLocationSelectValue,
}: PickupAnalyticsFetchDataProps): PickupAnalyticsFetchDataQueryParams => {
  const dateRange = dateSelectRangeValue?.dateRange || undefined
  const customDates = dateSelectRangeValue?.customDates || undefined
  const customStartDate = customDates?.startDate || undefined
  const customEndDate = customDates?.endDate || undefined
  const storeGroupId = retailerLocationSelectValue?.storeGroupId || undefined
  const retailerLocationId = retailerLocationSelectValue?.retailerLocationId

  const query = compactNullObject({
    time_range: dateRange,
    start_date: customStartDate ? dateToStringFormat(customStartDate) : undefined,
    end_date: customEndDate ? dateToStringFormat(customEndDate) : undefined,
    store_group_id: storeGroupId,
    retailer_location_ids: retailerLocationId ? [retailerLocationId] : undefined,
  })

  return query
}

export const pickupAnalyticsFetchData = async (
  pickupAnalyticsFetchDataProps: PickupAnalyticsFetchDataProps
) => {
  const { partnerId, retailerId } = pickupAnalyticsFetchDataProps

  const query = convertFetchDataPropsToApiQueryParams(pickupAnalyticsFetchDataProps)

  return instacart.go.get<PickupAnalyticsApiResponse, PickupAnalyticsFetchDataQueryParams>(
    `/v1/partners/${partnerId}/warehouses/${retailerId}/pickup_metrics/aggregate_pickup_metrics`,
    {
      query,
    }
  )
}

export const pickupAnalyticsFetchMetric = async (
  pickupAnalyticsFetchDataProps: PickupAnalyticsFetchDataProps,
  metric: string
) => {
  const { partnerId, retailerId } = pickupAnalyticsFetchDataProps

  const query = convertFetchDataPropsToApiQueryParams(pickupAnalyticsFetchDataProps)

  return instacart.go.get<{ data: PickupAnalyticsApiMetric }, PickupAnalyticsFetchDataQueryParams>(
    `/v1/partners/${partnerId}/warehouses/${retailerId}/pickup_metrics/${metric}`,
    {
      query,
    }
  )
}
