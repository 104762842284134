import React, { type FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { type StoreGroupsFormikProps } from '../utils/form.types'
import { hasTouchedFieldsWithErrors } from '../../../../../common/formikUtils'
import FormConfirmationBar from '../../../../../components/Forms/FormConfirmationBar'

interface Props extends StoreGroupsFormikProps {}

export const StoreGroupsFormConfirmationBar: FunctionComponent<React.PropsWithChildren<Props>> = ({
  dirty,
  isSubmitting,
  errors,
  touched,
}) => {
  if (!dirty) return null

  // TODO
  // When a user is creating a new group, and only fills in some fields,
  // the bar is still green, even though there might be errors
  const causedErrors = hasTouchedFieldsWithErrors(errors, touched)

  return (
    <FormConfirmationBar
      error={causedErrors}
      errorMessage={<FormattedMessage id="forms.fieldError" />}
      isSubmitting={isSubmitting}
    />
  )
}
