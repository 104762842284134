import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { ViewType } from '@retailer-platform/dashboard/gin-and-tonic'
import { DomainRoute } from '../utils/domain/routingComponents'
import { storefrontOnboardingAccessControl } from '../access-control/storefrontOnboardingAccess.configuration'

const StorefrontOnboardingIosPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontOnboardingIosPage" */
      '../pages/ios/StorefrontOnboardingIosPage'
    )
)
const StorefrontOnboardingAndroidPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontOnboardingAndroidPage" */
      '../pages/android/StorefrontOnboardingAndroidPage'
    )
)
const StorefrontOnboardingWebPage = lazy(() => import('../pages/web/StorefrontOnboardingWebPage'))
const StorefrontOnboardingWebStatusPage = lazy(
  () => import('../pages/web-status/StorefrontOnboardingWebStatusPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const storefrontOnboardingRouter = (
  <DomainRoute route="storefront-onboarding" accessControl={storefrontOnboardingAccessControl}>
    <DashSwitch>
      <DomainRoute
        route="storefront-onboarding-site-scoped"
        accessControl={storefrontOnboardingAccessControl}
      >
        <DashSwitch>
          <DomainRoute
            exact
            route="storefront-onboarding-ios"
            component={StorefrontOnboardingIosPage}
            scopePicker={{
              titles: ['storefrontOnboardingDomain.mobileAppTitle'],
              selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
              storeConfigOperationIntent: 'modify',
            }}
          />
          <DomainRoute
            exact
            route="storefront-onboarding-android"
            component={StorefrontOnboardingAndroidPage}
            scopePicker={{
              titles: ['storefrontOnboardingDomain.mobileAppTitle'],
              selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
              storeConfigOperationIntent: 'modify',
            }}
          />
          <DomainRoute
            exact
            route="storefront-onboarding-web-status"
            component={StorefrontOnboardingWebStatusPage}
            scopePicker={{
              titles: ['storefrontOnboardingDomain.web.title'],
              selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
              storeConfigOperationIntent: 'modify',
            }}
          />
        </DashSwitch>
      </DomainRoute>
      <DomainRoute
        exact
        route="storefront-onboarding-web"
        component={StorefrontOnboardingWebPage}
        scopePicker={{
          titles: ['storefrontOnboardingDomain.web.title'],
        }}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
