import React, { type FunctionComponent } from 'react'
import { IssueIcon, ApprovedIcon, InformationIcon } from '@instacart/ids-core'
import { css } from '@emotion/react'
import { spacing } from '../../foundation/spacing'
import { colors } from '../../foundation/colors'
import { borderRadius, fontSize } from '../../foundation'

interface Props {
  variant: 'warning' | 'danger' | 'success' | 'info'
  className?: string
  showIcon?: boolean
}

const alertStyles = css({
  display: 'flex',
  alignItems: 'center',
  padding: spacing.X12,
  borderRadius: borderRadius.X8,
  fontSize: fontSize.X15,
})

const childrenWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: spacing.X8,
  flex: 1,
})

export const Alert: FunctionComponent<React.PropsWithChildren<Props>> = ({
  variant,
  children,
  showIcon = true,
  ...props
}) => {
  let dynamicProps: { backgroundColor: string; icon: React.ReactNode }
  const iconSize = 20

  switch (variant) {
    case 'danger':
      dynamicProps = {
        backgroundColor: colors.DETRIMENTAL.LIGHT,
        icon: <IssueIcon size={iconSize} color="systemDetrimentalRegular" />,
      }
      break
    case 'warning':
      dynamicProps = {
        backgroundColor: colors.LOYALTY.LIGHT,
        icon: <IssueIcon size={iconSize} color="brandLoyaltyRegular" />,
      }
      break

    case 'success':
      dynamicProps = {
        backgroundColor: colors.SUCCESS.LIGHT,
        icon: <ApprovedIcon size={iconSize} color="systemSuccessRegular" />,
      }
      break

    case 'info':
      dynamicProps = {
        backgroundColor: colors.HIGHLIGHT.LIGHT,
        icon: <InformationIcon size={iconSize} color="brandHighlightRegular" />,
      }
      break
  }

  return (
    <div css={css(alertStyles, { backgroundColor: dynamicProps.backgroundColor })} {...props}>
      {showIcon && dynamicProps.icon}
      <div css={childrenWrapper}>{children}</div>
    </div>
  )
}
