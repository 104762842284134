import React from 'react'
import { arrayOf, shape, string, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'

const TabsContainer = styled.ul<{ fillHeight }>`
  display: flex;
  justify-content: flex-end;
  align-self: ${({ fillHeight }) => (fillHeight ? 'stretch' : 'auto')};
`
interface TabProps {
  active: boolean
}
const Tab = styled.li`
  border-bottom: 3px solid
    ${(props: TabProps) => (props.active ? colors.GRAYSCALE.X70 : 'transparent')};
  text-align: center;
  min-width: 150px;
  display: flex;
  align-items: stretch;
  transition: 0.2s border;
`
const ButtonContainer = styled.button<{ active: boolean; onClick: Function }>`
  color: ${({ active }) => (active ? colors.GRAYSCALE.X70 : colors.GRAYSCALE.X50)};
  justify-content: center;
  align-items: center;
  display: flex;
  height: auto;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  background: transparent;
  margin-bottom: 1px;
  :hover {
    color: ${colors.GRAYSCALE.X70};
  }
`

interface Props {
  children?: React.ReactNode
  fillHeight?: boolean
  tabs?: Array<{
    label: string
    value: any
  }>
  selected?: string
  onClick?: Function
  size?: string
}

class Tabs extends React.Component<Props> {
  handleClick = value => () => {
    this.props.onClick(value)
  }

  render() {
    const { selected, tabs, fillHeight } = this.props

    return (
      <TabsContainer fillHeight={fillHeight}>
        {tabs.map(({ value, label }) => (
          <Tab key={value} active={selected === value}>
            <ButtonContainer active={selected === value} onClick={this.handleClick(value)}>
              <FormattedMessage id={label} />
            </ButtonContainer>
          </Tab>
        ))}
      </TabsContainer>
    )
  }
}

export default Tabs
