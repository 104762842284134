import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, AdminNavEntryPoint, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { eversightRouter } from './routing/eversightRouter'
import { eversightAdminRouter } from './routing/eversightAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { eversightCompetitiveIntelAccessControl } from './access-control/eversightCompetitiveIntelAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'eversight',
  totem: {
    entity: 'eversight-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [eversightRouter],
    admin: [eversightAdminRouter],
  },
  navigationEntries: [
    {
      attachToV2: NavEntryPoint.AnalyticsMerchandising,
      subNavItems: [
        {
          type: 'item',
          labelId: 'eversightDomain.competitiveIntel',
          href: 'https://price.eversightlabs.com/',
          position: 99, //last
          accessControl: eversightCompetitiveIntelAccessControl,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
