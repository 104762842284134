import React, { type FunctionComponent } from 'react'
import { Route } from 'react-router'

export const RetailerSatisfactionSurveyStep: FunctionComponent<
  React.PropsWithChildren<{
    name: string
    component?: React.ComponentType<React.PropsWithChildren<any>>
  }>
> = ({ name, component, children }) => (
  <Route path={name} exact component={component}>
    {children}
  </Route>
)
