import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { shiftsAccessControl } from '../access-control/shiftsAccess.configuration'

const ShiftsListPage = lazy(() => import('../pages/list/ShiftsListPage'))
// @hygen:inject page-import

export const shiftsRouter = (
  <DomainRoute route="shifts" accessControl={shiftsAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="shifts"
        component={ShiftsListPage}
        scopePicker={{
          titles: ['shiftsDomain.title'],
        }}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
