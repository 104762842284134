import React, { useCallback, useContext, useMemo, useState } from 'react'
import { type SimpleOption } from './types'

export type ConfirmStepContext = {
  stagedOptionsArray: SimpleOption[]
  stagedOptions: Set<SimpleOption>
  replaceStagedOptions: (option: SimpleOption[]) => void
  toggleStageOption: (option: SimpleOption) => void
}

export const ConfirmStepContext = React.createContext<ConfirmStepContext | undefined>(undefined)

export const useConfirmContext = () => useContext(ConfirmStepContext) as ConfirmStepContext

export const ConfirmStepProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = props => {
  const [stagedOptions, setStagedOptions] = useState<Set<SimpleOption>>(new Set())

  const toggleStageOption = useCallback((option: SimpleOption) => {
    setStagedOptions(currentStagedOptions => {
      const nextOptions = new Set(currentStagedOptions)

      if (nextOptions.has(option)) {
        nextOptions.delete(option)

        return nextOptions
      }

      return nextOptions.add(option)
    })
  }, [])

  const replaceStagedOptions = useCallback(
    (newOptions: SimpleOption[]) => {
      setStagedOptions(new Set(newOptions))
    },
    [setStagedOptions]
  )

  const stagedOptionsArray = useMemo(() => Array.from(stagedOptions), [stagedOptions])

  return (
    <ConfirmStepContext.Provider
      value={{
        stagedOptionsArray: stagedOptionsArray,
        stagedOptions,
        replaceStagedOptions,
        toggleStageOption,
      }}
    >
      {props.children}
    </ConfirmStepContext.Provider>
  )
}
