/* eslint-disable @typescript-eslint/no-explicit-any */
// Implementation inspired by:
// - https://github.com/kentcdodds/use-deep-compare-effect/blob/master/src/index.js
// - https://github.com/apollographql/react-apollo/blob/master/packages/hooks/src/utils/useDeepMemo.ts
import { useRef, type EffectCallback, type DependencyList } from 'react'
import isEqual from 'lodash/isEqual'

type PrimitiveTypes = boolean | number | string | Function

// You shouldn't useDeepMemo/useDeepEffect with _only_ primative deps
// It is intended for deps that include complex types such as objects, nested arrays, etc.
type ComplexDependencyList<TDeps = any> = TDeps extends DependencyList
  ? TDeps[number] extends PrimitiveTypes
    ? never
    : TDeps
  : never

export const useDeepMemo = <TValue, TDeps>(
  memoFn: () => TValue,
  deps: ComplexDependencyList<TDeps>
) => {
  const ref = useRef<{ deps: ComplexDependencyList; value: TValue }>()

  if (!ref.current || !isEqual(deps, ref.current.deps)) {
    ref.current = { deps, value: memoFn() }
  }

  return ref.current.value
}

// Same as useMemo, but no return value
export const useDeepEffect = <TDeps>(
  effectFn: EffectCallback,
  deps: ComplexDependencyList<TDeps>
) => {
  useDeepMemo(effectFn, deps)
}

export const useDeepCallback = <TDeps>(
  callbackFn: (...args: any[]) => void,
  deps: ComplexDependencyList<TDeps>
) => useDeepMemo(() => callbackFn, deps)
