import React, { type FunctionComponent } from 'react'
import { css } from '@emotion/react'
import { spacing } from '../../foundation'

const cardBody = css({
  padding: `${spacing.X24} ${spacing.X24}`,
  display: 'flex',
  gap: spacing.X24,
  flexDirection: 'column',
})

export const CardBody: FunctionComponent<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div css={cardBody} className={className}>
    {children}
  </div>
)
