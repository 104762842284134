/* eslint-disable react/prefer-stateless-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import RetailerRoute, { type RetailerRouteComponentProps } from '../components/RetailerRoute'
import { getDisplayName } from './reactUtils'

function withRetailerRouter<P extends RetailerRouteComponentProps<any, any>>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>
): React.ComponentClass<Omit<P, keyof RetailerRouteComponentProps<any, any>>> {
  class WithRetailerRouter extends React.Component<
    Omit<P, keyof RetailerRouteComponentProps<any, any>>
  > {
    static displayName = `withRetailerRouter(${getDisplayName(WrappedComponent)})`

    render() {
      return (
        <RetailerRoute>
          {routeComponentProps => (
            // cast temporarily
            // Workaround for https://github.com/microsoft/TypeScript/issues/28884
            <WrappedComponent {...(this.props as P)} {...routeComponentProps} />
          )}
        </RetailerRoute>
      )
    }
  }

  return WithRetailerRouter
}

export default withRetailerRouter
