import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { RoleManagementAccessControl } from '../access-control/roleManagementAccess.configuration'

const RoleManagementRolesListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RoleManagementRolesListPage" */
      '../pages/roles-list/RoleManagementRolesListPage'
    )
)
const RoleManagementManageRolePage = lazy(
  () =>
    import(
      /* webpackChunkName: "RoleManagementManageRolePage" */
      '../pages/manage-role/RoleManagementManageRolePage'
    )
)
const RoleManagementCreateRolePage = lazy(
  () =>
    import(
      /* webpackChunkName: "RoleManagementCreateRolePage" */
      '../pages/create-role/RoleManagementCreateRolePage'
    )
)
const RoleManagementEditRolePage = lazy(
  () =>
    import(
      /* webpackChunkName: "RoleManagementEditRolePage" */
      '../pages/edit-role/RoleManagementEditRolePage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const roleManagementRouter = (
  <DomainRoute route="role-management" accessControl={RoleManagementAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="role-management-roles-list"
        component={RoleManagementRolesListPage}
        accessControl={RoleManagementAccessControl}
        scopePicker={{
          titles: ['roleManagementDomain.title'],
        }}
      />
      <DomainRoute
        exact
        route="role-management-role-assigned-users"
        component={RoleManagementManageRolePage}
        accessControl={RoleManagementAccessControl}
      />
      <DomainRoute
        exact
        route="role-management-role-permissions"
        component={RoleManagementManageRolePage}
        accessControl={RoleManagementAccessControl}
      />
      <DomainRoute
        exact
        route="role-management-create-role"
        component={RoleManagementCreateRolePage}
        accessControl={RoleManagementAccessControl}
      />
      <DomainRoute
        exact
        route="role-management-duplicate-role"
        component={RoleManagementCreateRolePage}
        accessControl={RoleManagementAccessControl}
      />
      <DomainRoute exact route="role-management-edit-role" component={RoleManagementEditRolePage} />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
