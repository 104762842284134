import React, { createContext, useContext } from 'react'
import { type RPPCoreContext } from './RPPCore.types'

export const CoreContext = createContext<Readonly<RPPCoreContext>>(null)

export const useCoreContext = () => useContext(CoreContext)

export const withCoreContext = <T extends React.ComponentClass>(OriginalComponent: T) => {
  const EnhancedComponent = props => {
    const ctx = useCoreContext()
    return <OriginalComponent {...props} coreContext={ctx} />
  }

  // Ensures the returned component has the same shape as the original,
  return EnhancedComponent as unknown as T
}
