import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

const URL_ROLE_MANAGEMENT = `${URL_RETAILER_SCOPED}/role-management`
const URL_ROLE_MANAGEMENT_ROLES_LIST = `${URL_ROLE_MANAGEMENT}/roles-list`
const URL_ROLE_MANAGEMENT_ROLE_ASSIGNED_USERS = `${URL_ROLE_MANAGEMENT}/manage-role/:roleId/assigned-users`
const URL_ROLE_MANAGEMENT_ROLE_PERMISSIONS = `${URL_ROLE_MANAGEMENT}/manage-role/:roleId/permissions`
const URL_ROLE_MANAGEMENT_CREATE_ROLE = `${URL_ROLE_MANAGEMENT}/create-role`
const URL_ROLE_MANAGEMENT_DUPLICATE_ROLE = `${URL_ROLE_MANAGEMENT}/:roleId/duplicate-role`
const URL_ROLE_MANAGEMENT_EDIT_ROLE = `${URL_ROLE_MANAGEMENT}/manage-role/:roleId/edit-role`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'role-management'>
  | DashRouteType<'role-management-roles-list'>
  | DashRouteType<'role-management-role-assigned-users'>
  | DashRouteType<'role-management-role-permissions'>
  | DashRouteType<'role-management-create-role'>
  | DashRouteType<'role-management-duplicate-role'>
  | DashRouteType<'role-management-edit-role'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'role-management': URL_ROLE_MANAGEMENT,
  'role-management-roles-list': URL_ROLE_MANAGEMENT_ROLES_LIST,
  'role-management-role-assigned-users': URL_ROLE_MANAGEMENT_ROLE_ASSIGNED_USERS,
  'role-management-role-permissions': URL_ROLE_MANAGEMENT_ROLE_PERMISSIONS,
  'role-management-create-role': URL_ROLE_MANAGEMENT_CREATE_ROLE,
  'role-management-duplicate-role': URL_ROLE_MANAGEMENT_DUPLICATE_ROLE,
  'role-management-edit-role': URL_ROLE_MANAGEMENT_EDIT_ROLE,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
