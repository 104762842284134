import React, { forwardRef, type ButtonHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { borderRadius, colors, fontSize, fontWeight, spacing } from '../../foundation'
import { LoadingDots } from '../loading-dots/LoadingDots'
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'detrimental' | 'secondary' | 'tertiary' | 'transparent'
  compact?: boolean
  loading?: boolean
}

const StyledButton = styled.button<ButtonProps>(
  {
    height: 40,
    padding: '8px 16px',
    margin: spacing.X8,
    fontSize: fontSize.X15,
    fontWeight: fontWeight.SEMIBOLD,
    color: colors.GRAYSCALE.X0,
    backgroundColor: colors.PRIMARY.REGULAR,
    borderRadius: borderRadius.X8,
    border: '1px solid',
    width: 'fit-content',
  },
  props => {
    const buttonColors = {
      bgColor: colors.PRIMARY.DARK,
      fgColor: colors.GRAYSCALE.X0,
      hoverBgColor: colors.PRIMARY.REGULAR,
      darkBgColor: colors.PRIMARY.DARK,
      borderColor: colors.PRIMARY.DARK,
      hoverFgColor: undefined,
      disabledBgColor: colors.GRAYSCALE.X10,
      disabledBorderColor: colors.GRAYSCALE.X20,
    }

    switch (props.variant) {
      case 'secondary':
        Object.assign(buttonColors, {
          bgColor: colors.GRAYSCALE.X20,
          fgColor: colors.GRAYSCALE.X70,
          darkBgColor: colors.GRAYSCALE.X30,
          borderColor: colors.GRAYSCALE.X30,
          hoverBgColor: '#D7D9DC',
        })
        break
      case 'tertiary':
        Object.assign(buttonColors, {
          bgColor: colors.GRAYSCALE.X0,
          fgColor: colors.GRAYSCALE.X70,
          darkBgColor: colors.GRAYSCALE.X30,
          borderColor: colors.GRAYSCALE.X30,
          hoverBgColor: colors.GRAYSCALE.X20,
        })
        break
      case 'detrimental':
        Object.assign(buttonColors, {
          bgColor: colors.DETRIMENTAL.REGULAR,
          fgColor: colors.GRAYSCALE.X0,
          darkBgColor: colors.DETRIMENTAL.DARK,
          borderColor: colors.DETRIMENTAL.DARK,
          hoverBgColor: '#C62928',
        })
        break
      case 'transparent':
        Object.assign(buttonColors, {
          bgColor: 'transparent',
          fgColor: colors.PRIMARY.REGULAR,
          darkBgColor: 'transparent',
          borderColor: 'transparent',
          hoverBgColor: 'transparent',
          hoverFgColor: colors.PRIMARY.DARK,
          disabledBorderColor: 'none',
          disabledBgColor: 'transparent',
        })
        break
      case 'primary':
      default:
      // Use styles defined above. NOOP
    }

    const dimensions = props.compact
      ? {
          height: 28,
          padding: '4px 12px',
          fontSize: fontSize.X12,
          loadingHeight: 6,
          loadingWidth: 30,
        }
      : {
          height: 40,
          padding: '8px 16px',
          fontSize: fontSize.X15,
          loadingHeight: 8,
          loadingWidth: 40,
        }

    return {
      backgroundColor: buttonColors.bgColor,
      color: buttonColors.fgColor,
      border: '1px solid',
      borderColor: buttonColors.borderColor,

      height: dimensions.height,
      padding: dimensions.padding,
      fontSize: dimensions.fontSize,

      '&:hover': {
        backgroundColor: buttonColors.hoverBgColor,
        color: buttonColors.hoverFgColor,
      },

      '&:active': {
        backgroundColor: buttonColors.darkBgColor,
      },

      '&:focus-visible': {
        outline: `2px solid ${colors.GRAYSCALE.X70}`,
        outlineOffset: 2,
      },

      '&:disabled': {
        backgroundColor: buttonColors.disabledBgColor,
        color: colors.GRAYSCALE.X30,
        cursor: 'default',
        borderColor: buttonColors.disabledBorderColor,
      },

      '& .loading-dots-svg': {
        height: dimensions.loadingHeight,
        width: dimensions.loadingWidth,
      },

      '& .loading-dots': {
        fill: buttonColors.fgColor,
      },
    }
  }
)

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = 'button', children, loading, compact, ...rest }, ref) => (
    <StyledButton ref={ref} type={type} compact={compact} {...rest}>
      {loading ? <LoadingDots centered /> : children}
    </StyledButton>
  )
)
