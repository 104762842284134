/* eslint-disable no-underscore-dangle */

import { type CaptchaProvider } from '@retailer-platform/shared-components'

export interface ClientEnv {
  PUBLIC_CLIENT_RPP_URL: string
  PUBLIC_CLIENT_CATALOG_ADMIN_WEB_SERVICE_URL: string
  PUBLIC_CLIENT_RPP_DEV_URL: string
  PUBLIC_CLIENT_PRODUCTION_URL: string
  PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL: string
  PUBLIC_CLIENT_ENVIRONMENT: string
  PUBLIC_CLIENT_SEGMENT_API_HOST: string
  PUBLIC_CLIENT_MONGOOSE_WRITE_KEY: string
  PUBLIC_CLIENT_FILEPICKER_API_KEY: string
  PUBLIC_CLIENT_CAPTCHA_PROVIDER: CaptchaProvider
  PUBLIC_CLIENT_CLOUDFLARE_TURNSTILE_SITE_KEY: string
  PUBLIC_CLIENT_GOOGLE_RECAPTCHA_SITE_KEY: string
  PUBLIC_CLIENT_AUTH_SERVICE_BASE_URL: string
  PUBLIC_CLIENT_AUTH_SERVICE_CLIENT_ID: string
  PUBLIC_CLIENT_SENTRY_DSN: string
  PUBLIC_CLIENT_ISC_APP: string
  PUBLIC_CLIENT_ISC_SHA: string
  PUBLIC_CLIENT_ISC_BRANCH: string
  PUBLIC_CLIENT_CYPRESS_TOKEN: string
  PUBLIC_CLIENT_MAPBOX_API_KEY: string
  PUBLIC_CLIENT_RPP_ACCESS_POLICY_URL: string
  PUBLIC_CLIENT_SEGMENT_TOKEN: string
  PUBLIC_CLIENT_PARTNER_WEB_SERVICE_URL: string
  PUBLIC_CLIENT_RETAILER_COLLECTIONS_BUCKET_NAME: string
  PUBLIC_CLIENT_RETAILER_COLLECTIONS_BUCKET_REGION: string
  PUBLIC_CLIENT_BUILDER: string
  PUBLIC_CLIENT_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: number
  PUBLIC_CLIENT_USE_SIGNUP: boolean
  PUBLIC_CLIENT_PARTNER_DOCS_URL: string
  PUBLIC_CLIENT_DATADOG_RUM_APPLICATION_ID: string
  PUBLIC_CLIENT_DATADOG_RUM_CLIENT_TOKEN: string
  PUBLIC_CLIENT_DATADOG_RUM_SAMPLE_RATE: string
  PUBLIC_CLIENT_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE: string
}

declare global {
  interface Window {
    __RPP_ENV__: Record<keyof ClientEnv, string>
  }
}

/**
 * These variables are injected into the window in 2 ways:
 *
 * Development: Passed and injected into the HTML when developing locally via the HTML plugin.
 * Staging/Prod: Included via an env.js file, which is generated when spinning up the service.
 *
 * We use this instead of Vite define so that the variables can
 * be defined independently of the build artifact for different environments/deployments.
 *
 * Be sure to apply any transforms to the data to match the ClientEnv shape.
 */
const clientEnv: ClientEnv = {
  ...window.__RPP_ENV__,
  // Note that it uses process.env as it's replaced by define plugin (or similar)
  PUBLIC_CLIENT_BUILDER: process.env.PUBLIC_CLIENT_BUILDER ?? 'vite',
  PUBLIC_CLIENT_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: parseFloat(
    window.__RPP_ENV__.PUBLIC_CLIENT_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? '0'
  ),
  // Temporary flag to gate the rollout of the new sign up flow
  PUBLIC_CLIENT_USE_SIGNUP: window.__RPP_ENV__.PUBLIC_CLIENT_USE_SIGNUP === 'true',
  PUBLIC_CLIENT_CAPTCHA_PROVIDER:
    window.__RPP_ENV__.PUBLIC_CLIENT_CAPTCHA_PROVIDER === 'turnstile' ? 'turnstile' : 'recaptcha',
  PUBLIC_CLIENT_PARTNER_DOCS_URL:
    window.__RPP_ENV__.PUBLIC_CLIENT_PARTNER_DOCS_URL ??
    'https://partner-docs.instacart.com/instacart_platform_portal',
}

// eslint-disable-next-line import/no-default-export
export default clientEnv
