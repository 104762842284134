export const SHARED_COMPONENTS_EN_US = {
  'sharedComponents.modal.confirm.confirmLabel': 'Confirm',
  'sharedComponents.modal.confirm.cancelLabel': 'Cancel',
  'sharedComponents.pagination.totalLabelIndeterminate': 'of many',
  'sharedComponents.pagination.totalLabel': 'of {total}',
  'sharedComponents.select.withConfirmation.cancel': 'Cancel',
  'sharedComponents.select.withConfirmation.apply': 'Apply',
  'sharedComponents.formField.optional': '(optional)',
  'sharedComponents.dateRangePicker.currentPeriod': 'Current Period',
  'sharedComponents.dateRangePicker.comparisonPeriod': 'Comparison Period',
  'sharedComponents.dateRangePicker.weekStartDay': 'Week Start Day',
  'sharedComponents.dateRangePicker.v2.weekStartDay': 'Week starts',
  'sharedComponents.dateRangePicker.v2.weekStartsOn': 'Week start on {weekStartDay}',
  'sharedComponents.datePicker.noDateSelected': 'No date selected',
  'sharedComponents.paginatedGrid.itemsPerPageLabel': 'Rows per page',
  'sharedComponents.publicLayout.links.termsOfUse': 'Terms of use',
  'sharedComponents.publicLayout.links.privacyPolicy': 'Privacy policy',
  'sharedComponents.publicPages.continue': 'Continue',
  'sharedComponents.publicPages.cancel': 'Cancel',
  'sharedComponents.publicPages.loginForm.signin': 'Sign in',
  'sharedComponents.publicPages.loginForm.continue': 'Continue',
  'sharedComponents.publicPages.loginForm.back': 'Back',
  'sharedComponents.publicPages.loginForm.email.label': 'Work email',
  'sharedComponents.publicPages.loginForm.email.placeholder':
    'Enter the email associated with your organization',
  'sharedComponents.publicPages.loginForm.password.label': 'Password',
  'sharedComponents.publicPages.loginForm.password.placeholder': 'Enter your password',
  'sharedComponents.publicPages.loginForm.password.forgotPassword': 'Forgot password?',
  'sharedComponents.publicPages.logInForm.preflight.headline': 'Welcome',
  'sharedComponents.publicPages.logInForm.preflight.description':
    'Access the Instacart Platform Portal',
  'sharedComponents.publicPages.logInForm.loginPassword.headline': 'Sign in',
  'sharedComponents.publicPages.logInForm.loginPassword.description':
    'Welcome back! Sign in to the Instacart Platform Portal.',
  'sharedComponents.publicPages.logInForm.errors.required': 'This field is required',
  'sharedComponents.publicPages.logInForm.errors.email.default': 'Please enter an email address',
  'sharedComponents.publicPages.logInForm.errors.email.pattern': 'Email must be valid',
  'sharedComponents.publicPages.logInForm.errors.password.minLength': 'Password is too short',
  'sharedComponents.publicPages.logInForm.requestPasswordReset.headline': 'Reset password',
  'sharedComponents.publicPages.logInForm.requestPasswordReset.description':
    'Enter your email address, and we’ll send you a link to reset your password.',

  'sharedComponents.publicPages.formPasswordConfirm.label': 'Confirm password',
  'sharedComponents.publicPages.formPasswordConfirm.placeholder': 'Repeat your password',
  'sharedComponents.publicPages.formPasswordConfirm.errors.default':
    'Please enter your password again',
  'sharedComponents.publicPages.formPasswordConfirm.errors.matches': 'Passwords must match',

  'sharedComponents.publicPages.pendingPasswordReset.headline': 'Check your email',
  'sharedComponents.publicPages.pendingPasswordReset.description':
    'We sent a message to the email you provided. Please use the link in this email to complete the password reset.',
  'sharedComponents.publicPages.pendingPasswordReset.footer':
    'Didn’t get the email? Check your spam folder or',
  'sharedComponents.publicPages.pendingPasswordReset.restart': 'send it again.',

  'sharedComponents.publicPages.passwordReset.createPasswordTitle': 'Create password',
  'sharedComponents.publicPages.passwordReset.passwordUsageInfo':
    'This password will be used to log in to your account.',
  'sharedComponents.publicPages.passwordReset.continue': 'Continue',

  'sharedComponents.publicPages.changePassword.title': 'Update your password',
  'sharedComponents.publicPages.changePassword.subTitleExpiringSoon':
    'As part of our ongoing efforts to keep your account and data safe, we recommend for you to change your password.',
  'sharedComponents.publicPages.changePassword.expiringSoonDescription':
    ' Your password expires {relativeDate}. After this, you will need to update it to continue.',
  'sharedComponents.publicPages.changePassword.subTitleExpired':
    'As part of our ongoing efforts to keep your account and data safe, it’s time to change your password.',
  'sharedComponents.publicPages.changePassword.currentPassword.label': 'Current password',
  'sharedComponents.publicPages.changePassword.currentPassword.placeholder':
    'Your current password',
  'sharedComponents.publicPages.changePassword.password.label': 'New password',
  'sharedComponents.publicPages.changePassword.confirmPassword.label': 'Confirm new password',
  'sharedComponents.publicPages.changePassword.confirmPassword.placeholder': 'Repeat your password',
  'sharedComponents.publicPages.changePassword.errors.default': 'Please enter your password again',
  'sharedComponents.publicPages.changePassword.errors.matches': 'Passwords must match',
  'sharedComponents.publicPages.changePassword.confirm': 'Confirm',
  'sharedComponents.publicPages.changePassword.skip': 'Skip for now',

  'sharedComponents.publicPages.formPassword.label': 'Password',
  'sharedComponents.publicPages.formPassword.placeholder': 'Enter a password',
  'sharedComponents.publicPages.formPassword.rules.length': '8 characters minimum',
  'sharedComponents.publicPages.formPassword.rules.numeric': 'At least 1 number',
  'sharedComponents.publicPages.formPassword.rules.alpha': 'At least 1 letter',

  'sharedComponents.publicPages.pendingMfaSetup.headline': 'Set up Google Authenticator',
  'sharedComponents.publicPages.pendingMfaSetup.setupDeadline':
    'You have {daysLeft} days left to set up 2FA. After that you will be required to set up 2FA to access your account.',
  'sharedComponents.publicPages.pendingMfaSetup.accountPendingMfaSetup':
    "Your account is pending two-factor authentication setup. Please take a moment to set it up to better your account's security.",
  'sharedComponents.publicPages.pendingMfaSetup.2faInfo':
    'Two-factor authentication (2FA) provides an additional layer of security by requiring a verification code when you sign in on a new device. This helps protect your account from unauthorized access. You can set up 2FA with Google Authenticator.',
  'sharedComponents.publicPages.pendingMfaSetup.setUpNow': 'Continue',
  'sharedComponents.publicPages.pendingMfaSetup.remindMeLater': 'Remind me later',

  'sharedComponents.publicPages.mfaSetup.installGoogleAuth':
    '2FA Setup: Install Google Authenticator',
  'sharedComponents.publicPages.mfaSetup.firstInstall':
    "First, let's set up the Google Authenticator app.",
  'sharedComponents.publicPages.mfaSetup.visitAppStore':
    'Install the Google Authenticator app from <a>Google Play</a> or from the <a1>Apple App Store</a>.',
  'sharedComponents.publicPages.mfaSetup.continueAfter':
    'After you install the app, click Continue.',

  'sharedComponents.publicPages.mfaSetup.setupYour2faDevice': '2FA Setup: Scan QR code',
  'sharedComponents.publicPages.mfaSetup.openGoogleAuth':
    'Open the Google Authenticator app, tap the <b>+</b> symbol, then tap <b>Scan a QR code</b>',
  'sharedComponents.publicPages.mfaSetup.enterCode':
    'If you cannot scan the QR code, enter this setup key:',
  'sharedComponents.publicPages.mfaSetup.enterCodeFromGoogleAuth':
    'To confirm setup, enter the 6-digit code:',
  'sharedComponents.publicPages.mfaSetup.enterCodeFromGoogleAuth.placeholder': 'Authenticator code',
  'sharedComponents.publicPages.mfaSetup.enterCodeFromGoogleAuth.checkCode': 'Check code',
  'sharedComponents.publicPages.mfaSetup.enterCodeFromGoogleAuth.invalid': 'Invalid code',

  'sharedComponents.publicPages.mfaSetup.2faActivated': '2FA successfully activated!',
  'sharedComponents.publicPages.mfaSetup.accountMoreSecure':
    'Your account is now more secure with 2FA',
  'sharedComponents.publicPages.mfaSetup.keepDevice':
    'Be sure to have your 2FA device on hand when you log in.',
  'sharedComponents.publicPages.mfaSetup.helpDetails':
    "If you are unable to access your device and find yourself locked out, contact your company's Administrator to disable or reset 2FA for your account. If you administrator is unavailable, visit help@instacart.com to contact the Instacart Retailer Health Team.",

  'sharedComponents.publicPages.mfaInputCode.title': 'Authenticate your account',
  'sharedComponents.publicPages.mfaInputCode.description':
    'Open your two-factor authentication (2FA) app or browser extension and enter the 6-digit code.',
  'sharedComponents.publicPages.mfaInputCode.code.label': 'Authenticator code',
  'sharedComponents.publicPages.mfaInputCode.code.placeholder': 'Enter code',
  'sharedComponents.publicPages.mfaInputCode.code.invalid': 'Invalid code',
  'sharedComponents.publicPages.mfaInputCode.code.serverError':
    "There was a server error. Please contact support via the 'Having trouble' link below.",
  'sharedComponents.publicPages.mfaInputCode.code.havingTrouble?': 'Having trouble?',
  'sharedComponents.publicPages.mfaInputCode.code.rememberDevice30Days':
    'Remember this device for 30 days',
  'sharedComponents.publicPages.mfaInputCode.verify': 'Verify',

  'sharedComponents.publicPages.mfaInputCode.help.title': 'Contact an administrator',
  'sharedComponents.publicPages.mfaInputCode.help.description':
    "Contact your company's administrator to reset 2FA and regain access to your account.{br}{br}" +
    "If you cannot reach an admin, visit <a href='https://docs.instacart.com/support/request_technical_support'>docs.instacart.com/support/request_technical_support</a> to request a reset of your authentication settings.",
  'sharedComponents.publicPages.mfaInputCode.help.back': 'Back',
} as const
