import React, { useState, type FunctionComponent } from 'react'
import { Redirect } from 'react-router'
import { URL_LOGOUT } from '../../../utils/routing/routes'
import { url } from '../../../utils/parsing/url'
import SelectPartner from '../../../gin-and-tonic/common/select-partner/SelectPartner'

const SelectPartnerWarehousePage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const [modalOpen, setModal] = useState(true)
  const closeModal = () => setModal(false)

  if (!modalOpen) {
    return <Redirect to={url(URL_LOGOUT)} />
  }

  return <SelectPartner autoSelect initialLoad handleClose={closeModal} />
}

export default SelectPartnerWarehousePage
