import { type AccessControlConfig } from '@retailer-platform/dashboard/access-control'
import {
  WarehouseLocationFeature,
  AccessControl,
} from '@retailer-platform/dashboard/access-control'
import { Permission } from '@retailer-platform/dashboard/utils'

export const ReportsPickupAnalyticsAccessControl: AccessControlConfig = {
  warehouseLocationFeatures: WarehouseLocationFeature.RetailerPickup,
  permissions: AccessControl.oneOf([Permission.Analytics, Permission.PickupAnalytics]),
}
