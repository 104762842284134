import React, { Component } from 'react'
import { fontSize, NewSelect, TextArea } from '@retailer-platform/shared-components'
import { css } from '@emotion/react'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'

type CellType = 'dropdown' | 'currency'

interface Props {
  type: CellType
  attribute: string
  // eslint-disable-next-line
  options?: any[]
  item: {
    [index: string]: string
  }
  onChange: (attribute: string, value: string) => void
}

interface State {
  value: string
  isBusy: boolean
  isError: boolean
}

class ProductLocationsCell extends Component<Props, State> {
  readonly state: State = {
    value: this.props.item[this.props.attribute],
    isBusy: false,
    isError: false,
  }

  onChange = (value: string) => {
    if (this.props.type === 'currency') {
      if (isNaN(+value)) return
      if (!isNumber(+value)) return
    }

    this.setState(
      {
        isError: false,
        value,
      },
      () => this.props.onChange(this.props.attribute, value)
    )
  }

  render() {
    const { type, options } = this.props

    switch (type) {
      case 'dropdown':
        return (
          <NewSelect
            css={css({
              width: '130px',
            })}
            value={this.state.value}
            isLoading={this.state.isBusy}
            isDisabled={this.state.isError}
            onChange={this.onChange}
            options={options}
          />
        )
      case 'currency':
        return (
          <TextArea
            style={{ height: '40px', fontSize: fontSize.X15 }}
            disabled={this.state.isError || this.state.isBusy}
            value={this.state.value}
            onChange={e => this.onChange(e.target.value)}
          />
        )
      default:
        return null
    }
  }
}

export default ProductLocationsCell
