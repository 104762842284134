// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'

const ModalBody = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 20px 30px;
  font-size: 12px;
  line-height: 20px;
`

export default ModalBody
