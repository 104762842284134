import React, { type FunctionComponent } from 'react'
import { type PartnerScopedRouteParams } from '../../../legacy/common/types'
import { type DashRouteComponentProps } from '../../../legacy/dash-components/dash-route/utils/dashRoute.types'
import PartnerProvider from '../../../utils/contexts/partner/PartnerProvider'
import SelectPartner from '../../../gin-and-tonic/common/select-partner/SelectPartner'
import { PartnerScopedApp } from './PartnerScopedApp'

interface Props extends DashRouteComponentProps<PartnerScopedRouteParams> {}

export const PartnerScopedSection: FunctionComponent<React.PropsWithChildren<Props>> = ({
  match: {
    params: { partner_id: partnerId },
  },
  location: { pathname },
}) => {
  const validPartnerId = Number(partnerId) > 0

  if (!validPartnerId) {
    return <SelectPartner autoSelect restrictToPartnerView />
  }

  return (
    <PartnerProvider id={partnerId}>
      <PartnerScopedApp pathname={pathname} />
    </PartnerProvider>
  )
}
