import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const caperAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ConnectedStoresView],
}

export const uposStatusAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.UposViewStatus],
}
export const uposRetailerAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.UposConfigRetailer],
}
export const uposStoreAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.UposConfigStore],
}

export const shelfLabelsAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ShelfLabelsModify],
}
