import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { RegionLocationProvider } from '@retailer-platform/dashboard/legacy'
import { DomainRoute } from '../utils/domain/routingComponents'
import { configurableProductsAccessControl } from '../access-control/configurableProductsAccess.configuration'

const ConfigurableProductsShowPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfigurableProductsShowPage" */
      '../pages/show/ConfigurableProductsShowPage'
    )
)
// @hygen:inject page-import

export const configurableProductsRouter = (
  <DomainRoute route="configurable-products" accessControl={configurableProductsAccessControl}>
    <DashSwitch>
      <RegionLocationProvider>
        <DomainRoute
          exact
          route="configurable-products-show"
          component={ConfigurableProductsShowPage}
          scopePicker={{
            titles: ['configurableProductsDomain.title'],
          }}
        />
        {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
      </RegionLocationProvider>
    </DashSwitch>
  </DomainRoute>
)
