import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import localforage from 'localforage'

/**
 * A hook to use a key from localforage.
 * It returns its data, loading and a function to set the value
 *
 * @param key
 * @returns
 */
export const useLocalForageKey = <T extends {}>(key: string) => {
  const queryClient = useQueryClient()

  const setKey = useMutation<any, any, T>({
    mutationFn: async value => localforage.setItem(key, value),
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    },
  })

  const getKey = useQuery<T, string>({
    queryKey: [key],
    queryFn: () => localforage.getItem(key),
  })

  return {
    loading: getKey.isLoading,
    data: getKey.data ?? undefined,
    setData: setKey.mutate,
    hasKey: getKey.isFetched ? Boolean(getKey.data) : undefined,
  }
}
