import { type Moment } from 'moment'
import {
  type ConfirmedStoreHours,
  type ConfirmedStoreHoursInput,
  type GoogleTypeDate,
  type GoogleTypeDayOfWeek,
  type GoogleTypeTimeOfDayInput,
  type OverrideHours,
  type OverrideHoursInput,
  type RegularHours,
  type RegularHoursInput,
  RetailerServiceType,
  type ClearOverrideHoursCriteriaInput,
} from '../api'
import {
  type FormData,
  type FormDayHours,
  type FormServiceTypeHours,
  type FormSpecialHours,
  type ServiceType,
} from '../components/edit/components/edit-hours-form/StoreHoursBulkEditHoursFormCommon'

// FUTURE DEV: add newly supported RetailerServiceTypes here
export const SUPPORTED_RETAILER_SERVICE_TYPES = [RetailerServiceType.Delivery]

export const formDataToApiData = (formData: FormData, retailerLocationIds: string[]) => {
  const apiInputData = formDataToApiInputData(formData, retailerLocationIds)

  return {
    regularHours: apiInputData.regularHours as RegularHours[],
    overrideHours: apiInputData.overrideHours as OverrideHours[],
    confirmations: apiInputData.confirmations as ConfirmedStoreHours[],
  }
}

export const formDataToApiInputData = (formData: FormData, retailerLocationIds: string[]) => {
  const regularHours: RegularHoursInput[] = []
  const overrideHours: OverrideHoursInput[] = []
  const clearOverrideHoursByType: RetailerServiceType[] = []
  const confirmations: ConfirmedStoreHoursInput[] = []
  const clearOverrideHoursCriteria: ClearOverrideHoursCriteriaInput[] =
    formData?.delivery?.clearOverrideHoursCriteria ?? []

  if (formData) {
    Object.entries(formData).forEach(([serviceType, formServiceData]) => {
      const apiInputData = formServiceTypeDataToApiInputData(
        serviceType as ServiceType,
        formServiceData,
        retailerLocationIds
      )
      regularHours.push(...apiInputData.regularHours)
      overrideHours.push(...apiInputData.overrideHours)
      clearOverrideHoursByType.push(...apiInputData.clearOverrideHoursByType)
      confirmations.push(...apiInputData.confirmations)
    })
  }

  return {
    regularHours,
    overrideHours,
    clearOverrideHoursByType,
    clearOverrideHoursCriteria,
    confirmations,
  }
}

export const formServiceTypeDataToApiData = (
  serviceType: ServiceType,
  formServiceData: FormServiceTypeHours,
  retailerLocationIds: string[]
) => {
  const apiInputData = formServiceTypeDataToApiInputData(
    serviceType,
    formServiceData,
    retailerLocationIds
  )

  return {
    regularHours: apiInputData.regularHours as RegularHours[],
    overrideHours: apiInputData.overrideHours as OverrideHours[],
    confirmations: apiInputData.confirmations as ConfirmedStoreHours[],
  }
}

export const formServiceTypeDataToApiInputData = (
  serviceType: ServiceType,
  formServiceData: FormServiceTypeHours,
  retailerLocationIds: string[]
) => {
  if (!serviceType) throw Error('ServiceType cannot be null/undefined')

  const regularHours: RegularHoursInput[] = []
  const overrideHours: OverrideHoursInput[] = []
  const clearOverrideHoursByType: RetailerServiceType[] = []
  const confirmations: ConfirmedStoreHoursInput[] = []
  const clearOverrideHoursCriteria: ClearOverrideHoursCriteriaInput[] =
    formServiceData?.clearOverrideHoursCriteria ?? []

  // toUpperCase since the graphql API is in all upper case
  const serviceTypeApi = serviceType.toUpperCase()

  if (formServiceData?.regularReplaceHours) {
    // create new RegularHoursInputs for each day of the week entry
    Object.entries(formServiceData.regular).forEach(([dayOfWeek, dayFormData]) => {
      const newRegularHours: RegularHoursInput = {
        dayOfWeek: dayOfWeek as GoogleTypeDayOfWeek,
        serviceType: serviceTypeApi as RetailerServiceType,
      }
      if (formDayHoursToApiData(dayFormData, newRegularHours as CommonHoursInterface)) {
        regularHours.push(newRegularHours)
      }
    })
  }

  if (formServiceData?.specialReplaceHours) {
    // add this ServiceType to the list of service types to be replaced
    if (formServiceData.specialHoursUpdateMode === 'replace') {
      clearOverrideHoursByType.push(serviceTypeApi as RetailerServiceType)
    }

    // create new OverrideHoursInputs for each special hour entry
    formServiceData.special?.forEach(formSpecialHour => {
      const newOverrideHours = specialHoursFormToApiInputData(
        formSpecialHour,
        serviceTypeApi as RetailerServiceType
      )
      if (newOverrideHours) {
        overrideHours.push(newOverrideHours)
      }
      if (formSpecialHour.openMode === 'regular-hours') {
        // Regular hours here need to be marked confirmed
        retailerLocationIds.forEach(locationId => {
          confirmations.push({
            retailerLocationId: locationId,
            date: momentToGoogleTypeDate(formSpecialHour.date),
            confirmed: true,
          })
        })
      }
    })
  }

  return {
    regularHours,
    overrideHours,
    clearOverrideHoursByType,
    clearOverrideHoursCriteria,
    confirmations,
  }
}

export const specialHoursFormToApiInputData = (
  formSpecialHour: FormSpecialHours,
  serviceTypeApi: RetailerServiceType
) => {
  const newOverrideHours: OverrideHoursInput = {
    date: momentToGoogleTypeDate(formSpecialHour.date),
    description: formSpecialHour.description,
    serviceType: serviceTypeApi as RetailerServiceType,
  }
  if (formDayHoursToApiData(formSpecialHour, newOverrideHours as CommonHoursInterface)) {
    return newOverrideHours
  }
  return null
}

export const momentToGoogleTypeDate = (moment: Moment) =>
  ({
    year: moment.year(),
    month: moment.month() + 1, // moment is 0-based. API is 1-based.
    day: moment.date(),
  } as GoogleTypeDate)

interface CommonHoursInterface {
  serviceType: RetailerServiceType
  startTime: GoogleTypeTimeOfDayInput
  endTime: GoogleTypeTimeOfDayInput
}

const formDayHoursToApiData = (dayFormData: FormDayHours, commonApiObj: CommonHoursInterface) => {
  switch (dayFormData.openMode) {
    case 'closed':
      commonApiObj.startTime = null
      commonApiObj.endTime = null
      return true

    case 'open-24-hours':
      commonApiObj.startTime = {} as GoogleTypeTimeOfDayInput
      commonApiObj.endTime = {} as GoogleTypeTimeOfDayInput
      return true

    case 'open':
      commonApiObj.startTime = {
        hours: formHourToApiHour(dayFormData.openTime.hours),
        minutes: dayFormData.openTime.minutes,
      } as GoogleTypeTimeOfDayInput
      commonApiObj.endTime = {
        hours: formHourToApiHour(dayFormData.closeTime.hours),
        minutes: dayFormData.closeTime.minutes,
      } as GoogleTypeTimeOfDayInput
      return true

    case 'regular-hours':
      // Ignore this case, do not build any override hour
      return false

    default:
      return false
  }
}

// The form uses extended range hours for cross day.
// eg: 25:00, 25:30, 26:00, etc...
// GraphQL stays within the 24 hour range and loops around to the beginning.
// eg: 01:00, 01:30, 02:00, etc...
const formHourToApiHour = (hours: number) => hours % 24
