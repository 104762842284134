import { useParams } from 'react-router'
import { useMemo } from 'react'
import { errors } from '../error-handling/errors'
import {
  type RetailerScopedRouteParams,
  type PartnerScopedRouteParams,
  type StoreConfigurationScopedRouteParams,
} from '../../legacy/common/types'

const getMessage = (key: string) => `Attempted to fetch ${key} from params, but it was not found.`

export const usePartnerId = (props: { throwOnMissing: boolean } = { throwOnMissing: true }) => {
  const { partner_id: partnerId } = useParams<PartnerScopedRouteParams>()

  if (!partnerId && props?.throwOnMissing) {
    const message = getMessage('partner_id')
    errors.captureMessage(message)
    throw new Error(message)
  }

  return partnerId
}

export const useRetailerId = (props: { throwOnMissing: boolean } = { throwOnMissing: true }) => {
  const { warehouse_id: retailerId } = useParams<RetailerScopedRouteParams>()

  if (!retailerId && props?.throwOnMissing) {
    const message = getMessage('warehouse_id')

    errors.captureMessage(message)
    throw new Error(message)
  }

  return useMemo(() => retailerId?.split('-')?.[0], [retailerId])
}

export const useRetailerIds = (props: { throwOnMissing: boolean } = { throwOnMissing: true }) => {
  const { warehouse_id: retailerIds } = useParams<RetailerScopedRouteParams>()

  if (!retailerIds && props?.throwOnMissing) {
    const message = getMessage('warehouse_id')

    errors.captureMessage(message)
    throw new Error(message)
  }

  return useMemo(() => retailerIds?.split('-')?.filter(id => id !== '0'), [retailerIds])
}

export const useStoreConfigId = (props: { throwOnMissing: boolean } = { throwOnMissing: true }) => {
  const { storeConfigId } = useParams<StoreConfigurationScopedRouteParams>()

  if (!storeConfigId && props?.throwOnMissing) {
    const message = getMessage('storeConfigId')

    errors.captureMessage(message)
    throw new Error(message)
  }

  return useMemo(() => storeConfigId?.split('-')?.[0], [storeConfigId])
}

export const useStoreConfigIds = (
  props: { throwOnMissing: boolean } = { throwOnMissing: true }
) => {
  const { storeConfigId: storeConfigIds } = useParams<StoreConfigurationScopedRouteParams>()

  if (!storeConfigIds && props?.throwOnMissing) {
    const message = getMessage('storeConfigId')

    errors.captureMessage(message)
    throw new Error(message)
  }

  return useMemo(() => storeConfigIds?.split('-').filter(id => id !== '0'), [storeConfigIds])
}
