import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { instacartDeveloperPlatformRouter } from './routing/instacartDeveloperPlatformRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { instacartDeveloperPlatformAccessControl } from './access-control/instacartDeveloperPlatformAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'instacart-developer-platform',
  totem: {
    entity: 'instacart-developer-platform-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    partnerScoped: [instacartDeveloperPlatformRouter],
  },
  navigationEntries: [
    {
      attachToV2: NavEntryPoint.Developer,
      subNavItems: [
        {
          type: 'item',
          labelId: 'instacartDeveloperPlatformDomain.getStartedPage.title',
          route: 'instacart-developer-platform-get-started',
          accessControl: instacartDeveloperPlatformAccessControl,
          position: 0,
          positionNavV2: 0,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
