import { type DashRouteType, URL_RETAILER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_STORE_HOURS_BULK = `${URL_RETAILER_SCOPED}/store-hours-bulk`
const URL_STORE_HOURS_BULK_LIST = `${URL_STORE_HOURS_BULK}/list`
/**
 * using #hash (fragment) parameter since we may have 10k+ retailerLocationIds,
 * and we don't want to send all those parameters to the backend
 */
const URL_STORE_HOURS_BULK_EDIT = `${URL_STORE_HOURS_BULK}/edit`
const URL_STORE_HOURS_BULK_UPDATE_HISTORY = `${URL_STORE_HOURS_BULK}/update-history`

/**
 * Holiday scoped routes
 */
const URL_HOLIDAY_LIST = `${URL_STORE_HOURS_BULK}/holiday/list`
const URL_STORE_HOURS_BULK_HOLIDAYS_STORES_CONFIRMED_LIST = `${URL_HOLIDAY_LIST}/:date/stores-confirmed`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'store-hours-bulk'>
  | DashRouteType<'store-hours-bulk-list'>
  | DashRouteType<'store-hours-bulk-edit', { retailerLocationIds: string }>
  | DashRouteType<'store-hours-bulk-update-history'>
  | DashRouteType<'holiday-list'>
  | DashRouteType<'store-hours-bulk-holidays-stores-confirmed-list'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'store-hours-bulk': URL_STORE_HOURS_BULK,
  'store-hours-bulk-list': URL_STORE_HOURS_BULK_LIST,
  'store-hours-bulk-edit': URL_STORE_HOURS_BULK_EDIT,
  'store-hours-bulk-update-history': URL_STORE_HOURS_BULK_UPDATE_HISTORY,
  'holiday-list': URL_HOLIDAY_LIST,
  'store-hours-bulk-holidays-stores-confirmed-list':
    URL_STORE_HOURS_BULK_HOLIDAYS_STORES_CONFIRMED_LIST,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
