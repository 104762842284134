// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

const ModalHeader = styled.div`
  display: flex;
  padding: 10px 30px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-weight: 500;
  min-height: 50px;
  border-bottom: 1px solid ${colors.GRAY_93};
`

export default ModalHeader
