import React from 'react'
import { type GroupBase } from 'react-select-5'
import { BaseSelect } from './BaseSelect'
import { useSimpleSelectBehavior } from './utils/utils'
import { type NewSelectProps, type SimpleOption } from './utils/types'

/**
 * IPP's select component -- built on top of https://react-select.com/
 * Note that this component works(e.g. value, onChange) directly with the option values instead of whole objects.
 *
 * For documentation, refer to the link above, but be sure to check the storybook
 * for our custom functionality
 *
 * If you require to work with objects for some reason, check `<BaseSelect />` instead.
 *
 * @param param0
 * @returns
 */
export const NewSelect = <
  Option extends SimpleOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  onChange: simpleOnChange,
  value: simpleValue,
  defaultValue: simpleDefaultValue,
  options,
  ...props
}: NewSelectProps<Option, IsMulti, Group>): React.ReactElement | null => {
  const { onChange, value, defaultValue } = useSimpleSelectBehavior({
    onChange: simpleOnChange,
    value: simpleValue,
    defaultValue: simpleDefaultValue,
    options,
  })

  return (
    <BaseSelect
      {...props}
      options={options}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
    />
  )
}
