import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { FeatureEntitlementManagementAccessConfig } from '../access-control/featureEntitlementsManagementAdminAccess.configuration'

const FeatureEntitlementsManagementRootPage = lazy(
  () =>
    import(
      /* webpackChunkName: "FeatureEntitlementsManagementRootPage" */
      '../pages/root-page/FeatureEntitlementsManagementRootPage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const featureEntitlementsManagementAdminRouter = (
  <DomainRoute
    route="app-admin-feature-entitlements-management"
    accessControl={FeatureEntitlementManagementAccessConfig}
  >
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-feature-entitlements-management-entitlements"
        component={FeatureEntitlementsManagementRootPage}
      />
      <DomainRoute
        exact
        route="app-admin-feature-entitlements-management-packages"
        component={FeatureEntitlementsManagementRootPage}
      />
      <DomainRoute
        exact
        route="app-admin-feature-entitlements-management-retailers"
        component={FeatureEntitlementsManagementRootPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
