import { useCallback, useMemo } from 'react'
import {
  type MultiValue,
  type SingleValue,
  type OptionsOrGroups,
  type GroupBase,
  type OnChangeValue,
  type ActionMeta,
} from 'react-select-5'
import { type SimpleOption, type SimpleOptionValue, type SimpleSelectProps } from './types'

export const isMulti = <T extends SimpleOption>(
  v: MultiValue<T> | SingleValue<T>
): v is MultiValue<T> => {
  if (Array.isArray(v)) return true

  return false
}

/**
 * Builds a map of options by their values, for fast retrieval
 *
 * @param options
 * @returns
 */
export const useOptionsIndex = <Option extends SimpleOption>(
  options?: OptionsOrGroups<Option, GroupBase<Option>>
) =>
  useMemo(
    () =>
      options?.reduce((acc, item) => {
        if ('options' in item) {
          item.options.forEach(item => acc.set(item.value, item))
          return acc
        }

        return acc.set(item.value, item)
      }, new Map<any, Option>()),
    [options]
  )

/**
 * Returns a value and onChange pair that deals directly with their complex counterparts for us
 *
 * @param param0
 * @returns
 */
export const useSimpleSelectBehavior = <
  Option extends SimpleOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  onChange: simpleOnChange,
  value: simpleValue,
  defaultValue: simpleDefaultValue,
  options,
}: SimpleSelectProps<Option, IsMulti> & {
  options: OptionsOrGroups<Option, Group> | undefined
}) => {
  const indexedOptions = useOptionsIndex(options)

  const onChange = useCallback(
    (option?: OnChangeValue<Option, IsMulti>, meta?: ActionMeta<Option>) => {
      if (!option) return simpleOnChange?.(undefined, undefined, meta)

      if (isMulti(option)) {
        return simpleOnChange?.(
          option?.map(v => v?.value),
          option,
          meta
        )
      }

      simpleOnChange?.(option?.value, option, meta)
    },
    [simpleOnChange]
  )

  const value: Option | null | undefined = useMemo(() => {
    if (simpleValue === null || simpleValue === undefined) return simpleValue
    if (!Array.isArray(simpleValue)) return indexedOptions?.get(simpleValue)

    return simpleValue.map((v: SimpleOptionValue<Option>) => indexedOptions?.get(v))
  }, [indexedOptions, simpleValue])

  const defaultValue: Option | null | undefined = useMemo(() => {
    if (!simpleDefaultValue) return simpleDefaultValue
    if (!Array.isArray(simpleDefaultValue)) return indexedOptions?.get(simpleDefaultValue)

    return simpleDefaultValue.map((v: SimpleOptionValue<Option>) => indexedOptions?.get(v))
  }, [indexedOptions, simpleDefaultValue])

  return { onChange, value, defaultValue }
}
