import { useCallback } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  type InsightsFormatterFunction,
  InsightsFormatterType,
} from '../schemas/types/schema.types'
import { useDomainIntl } from './domain/intl'

dayjs.extend(utc)

const formattedValue = (value: number | null, callback: () => string) => {
  if (value === null || value === undefined || isNaN(value)) {
    return '-'
  }

  return callback()
}

export const useFormatValue = () => {
  const { formatNumber } = useDomainIntl()

  const formatYesNoValue = (value: string) => {
    if (value === 'Y') return 'Yes'
    else if (value === 'N') return 'No'
    else return value
  }

  const formatDecimal = useCallback(
    (value: number | null, compact?: boolean, duplicatedValue?: boolean) =>
      formattedValue(value, () =>
        formatNumber(value, {
          maximumFractionDigits: 2 + (duplicatedValue ? 1 : 0),
          notation: compact ? 'compact' : 'standard',
        })
      ),
    [formatNumber]
  )
  const formatInteger = useCallback(
    (value: number | null, compact?: boolean, duplicatedValue?: boolean) =>
      formattedValue(value, () =>
        formatNumber(value, {
          maximumFractionDigits: (compact && value >= 1e3 ? 2 : 0) + (duplicatedValue ? 2 : 0),
          notation: compact ? 'compact' : 'standard',
        })
      ),
    [formatNumber]
  )

  const formatCurrency = useCallback(
    (value: number | null, compact?: boolean, duplicatedValue?: boolean) =>
      formattedValue(value, () =>
        formatNumber(value, {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: (compact ? 0 : 2) + (duplicatedValue ? 2 : 0),
          notation: compact ? 'compact' : 'standard',
        })
      ),
    [formatNumber]
  )

  const formatPercentage = useCallback(
    (value: number | null) =>
      formattedValue(value, () =>
        formatNumber(value, {
          style: 'percent',
          maximumFractionDigits: 2,
        })
      ),
    [formatNumber]
  )

  const formatDate = useCallback(val => {
    if (val === undefined || val === null || val === '') return '-'

    return dayjs.utc(val).format('MM/DD/YYYY')
  }, [])

  const getFormatterByType = (type: InsightsFormatterType | InsightsFormatterFunction) => {
    if (typeof type === 'function') return type

    const map = {
      [InsightsFormatterType.Currency]: formatCurrency,
      [InsightsFormatterType.Date]: formatDate,
      [InsightsFormatterType.Decimal]: formatDecimal,
      [InsightsFormatterType.Integer]: formatInteger,
      [InsightsFormatterType.Percentage]: formatPercentage,
      [InsightsFormatterType.YesOrNo]: formatYesNoValue,
    }

    return map[type]
  }

  return {
    formatDecimal,
    formatInteger,
    formatCurrency,
    formatPercentage,
    formatYesNoValue,
    formatDate,
    getFormatterByType,
  }
}
