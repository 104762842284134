import gql from 'graphql-tag'
import { type CustomApolloClient } from '../../utils/apollo/client/apolloClient.types'

export interface RetailerLocation {
  id: string
  name?: string
}

interface RetailerLocationsResponse {
  retailerLocationSearchArray: RetailerLocation[]
}

// We're not using codegen for this because we can't use hooks for
// what we want, see useRetailerLocations.hooks.ts for more info
const SEARCH_RETAILER_LOCATION_QUERY = gql`
  query searchRetailerLocations($retailerId: ID!, $includeInactiveLocations: Boolean) {
    retailerLocationSearchArray(
      retailerId: $retailerId
      includeInactiveLocations: $includeInactiveLocations
    ) {
      id
      name
    }
  }
`

export const queryRetailerLocations = (
  client: CustomApolloClient,
  retailerId: string,
  includeInactiveLocations?: boolean
) =>
  client
    .query<RetailerLocationsResponse>({
      query: SEARCH_RETAILER_LOCATION_QUERY,
      variables: { retailerId, includeInactiveLocations },
    })
    .then(response => ({
      ...response,
      data: response.data?.retailerLocationSearchArray,
    }))
