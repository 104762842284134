// prettier-ignore
const EN_US = {
  "connect.title": "Connect",
  "connect.configuration.title": "API Configuration",
  "connect.configuration.header": "API Configuration",
  "connect.configuration.environment": "Environment",
  "connect.configuration.applicationConfiguration": "Application Configuration",
  "connect.configuration.callbackSettings": "Callback Settings",
  "connect.configuration.fetchFailed.title": "We couldn't load your API configuration.",
  "connect.configuration.fetchFailed.message": "Something went wrong on our end. Please try reloading the page.",
  "connect.configuration.form.fields.authUri": "Auth URI",
  "connect.configuration.form.fields.authUri.error.required": "Auth URI required",
  "connect.configuration.form.fields.callbackUri": "Callback URI",
  "connect.configuration.form.fields.callbackUri.error.required": "Callback URI required",
  "connect.configuration.form.fields.clientId": "Client ID",
  "connect.configuration.form.fields.clientId.error.required": "Client ID required",
  "connect.configuration.form.fields.clientSecret": "Client Secret",
  "connect.configuration.form.fields.clientSecret.error.required": "Client Secret required",
  "connect.configuration.form.fields.accessTokenTtl": "Access Token TTL",
  "connect.configuration.form.submit": "Save settings",
  "connect.configuration.form.submit.success": "Configuration saved",
  "connect.configuration.form.submit.error": "There was an error updating your configuration. Please try again.",
  "connect.fulfillmentSimulator.title": "Fulfillment Simulator",
  "connect.fulfillmentSimulator.header": "Sandbox Fulfillment Simulator",
  "connect.fulfillmentSimulator.loadOrder": "Load Order",
  "connect.fulfillmentSimulator.loadOrder.error": "There was an error loading your order. Please try again.",
  "connect.fulfillmentSimulator.orderProps": "Order Properties",
  "connect.fulfillmentSimulator.orderProps.id": "Order ID",
  "connect.fulfillmentSimulator.orderProps.status": "Fulfillment Status",
  "connect.fulfillmentSimulator.orderProps.createdAt": "Created At",
  "connect.fulfillmentSimulator.orderProps.windowStartsAt": "Window Starts At",
  "connect.fulfillmentSimulator.orderProps.windowEndsAt": "Window Ends At",
  "connect.fulfillmentSimulator.orderProps.deliveredAt": "Delivered At",
  "connect.fulfillmentSimulator.orderProps.items": "Order Items",
  "connect.fulfillmentSimulator.orderProps.items.lineNum": "Line Number",
  "connect.fulfillmentSimulator.orderProps.items.itemUpc": "Item UPC",
  "connect.fulfillmentSimulator.orderProps.items.itemRrc": "Item RRC",
  "connect.fulfillmentSimulator.orderProps.items.qty": "Quantity",
  "connect.fulfillmentSimulator.orderProps.items.qtyUnit": "Quantity Unit",
  "connect.fulfillmentSimulator.orderProps.items.replaced": "Replaced",
  "connect.fulfillmentSimulator.orderProps.callbackLogs": "Callback Logs",
  "connect.fulfillmentSimulator.orderProps.callbackLogs.showLogs": "Show Logs",
  "connect.fulfillmentSimulator.orderProps.callbackLogs.missingRequest": "Bad Request",
  "connect.fulfillmentSimulator.orderProps.callbackLogs.hideLogs": "Hide Logs",
  "connect.fulfillmentSimulator.orderProps.callbackLogs.event": "Event",
  "connect.fulfillmentSimulator.orderProps.callbackLogs.status": "Status",
  "connect.fulfillmentSimulator.orderProps.callbackLogs.timestamp": "Timestamp",
  "connect.fulfillmentSimulator.orderProps.simulationEvents": "Simulation Events",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.event": "Event Name",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.callbackEventName": "Callback Name",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.triggerCallbackEvent": "Send Callback",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.outcome.orderStatus": "Resulting Order Status",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.outcome.callback": "Associated Callback",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.arguments": "Simulation Arguments",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.actions": "Actions",
  "connect.fulfillmentSimulator.orderProps.simulationEvents.actions.simulate": "Simulate",
  "connect.fulfillmentSimulator.simulateEvent": "Simulate Event",
  "connect.fulfillmentSimulator.simulateEvent.error": "There was an error simulating event. Please try again.",
  "connect.callback-testing.title": "Callback Testing",
  "connect.callback-testing.header": "Callback Testing",
  "connect.callback-testing.send-callback": "Send Callback for an Order",
  "connect.callback-testing.form.fields.orderId": "Order Id",
  "connect.callback-testing.form.fields.eventName": "Event Name",
  "connect.callback-testing.form.submit": "Send Callback",
  "connect.callback-testing.response": "Response",
  "connect.callback-testing.response.error": "There was an error sending the callback. Please try again",
} as const

export default EN_US
