import { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { routes } from '../routing/routes'
import { FeatureToggle } from '../access-control/featureToggles'
import { useCheckLaunchOnboardingProgress } from './useCheckLaunchOnboardingProgress.hooks'
import { STATE_TO_STEP_MAP, WORKFLOW_STATE } from './constants'
import { useDomainAccessControl } from './domain/accessControl'
import { useDomainGoToPath } from './domain/routing'

export const usePendingPartnerOnboardingRedirection = () => {
  // We redirect the user if the partner is still completing their initial onboarding setup and the feature toggle is on
  const hasAccess = useDomainAccessControl()
  const shouldSkipRedirect = !hasAccess({
    featureToggles: FeatureToggle.PartnerOnboardingSignupInProgressRedirect,
  })

  const { workflowState, loading, error } = useCheckLaunchOnboardingProgress(shouldSkipRedirect)

  // If they are already on the onboarding path, don't redirect as it will cause an infinite loop
  const match = useRouteMatch(routes.onboarding)
  const goToPath = useDomainGoToPath('onboarding-initial-setup')

  useEffect(() => {
    // If our service fails (returns error), we assume there is no redirect necessary so as not to affect end users
    if (shouldSkipRedirect || loading || match || error || !workflowState) {
      return
    }

    // redirect user to initial-setup page only if the initial setup is not completed
    if (
      STATE_TO_STEP_MAP[workflowState] <
      STATE_TO_STEP_MAP[WORKFLOW_STATE.INITIAL_ONBOARDING_COMPLETED]
    ) {
      goToPath()
    }
  }, [match, workflowState, goToPath, error, loading, shouldSkipRedirect])

  return {
    loading,
  }
}
