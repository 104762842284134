// React context provider for integration configuration

import React, { createContext, useContext } from 'react'
import { Center, LoadingDots } from '@retailer-platform/shared-components'
import { type ApiApprovalRequest } from '../../api/common'
import { useGetApprovalRequestQuery } from '../../api'
import { ErrorPageAlert } from '../generic-error-page-alert/ErrorPageAlert'

const ApprovalRequestContext = createContext<ApiApprovalRequest>({} as ApiApprovalRequest)

export type ApprovalRequestProviderProps = {
  approvalRequestId?: string
  approvalRequest?: ApiApprovalRequest
}

export const ApprovalRequestProvider: React.FC<
  React.PropsWithChildren<ApprovalRequestProviderProps>
> = ({ approvalRequest, approvalRequestId, children }) => {
  if (!approvalRequest && !approvalRequestId) {
    throw new Error('Approval request or ID must be provided')
  }

  const apiQueryState = useGetApprovalRequestQuery({
    variables: {
      arId: approvalRequestId!,
    },
    skip: !!approvalRequest,
  })

  const coalescedAr = approvalRequest || apiQueryState.data?.approvalsManagementGetRequest

  if (apiQueryState.loading) {
    return (
      <Center fillParent>
        <LoadingDots />
      </Center>
    )
  }

  if (apiQueryState.error) {
    return <ErrorPageAlert error={apiQueryState.error} refetch={apiQueryState.refetch} />
  }

  if (!coalescedAr) {
    throw new Error('Approval request not found')
  }

  return (
    <ApprovalRequestContext.Provider value={coalescedAr}>
      {children}
    </ApprovalRequestContext.Provider>
  )
}

export const useApprovalRequest = () => useContext(ApprovalRequestContext)
