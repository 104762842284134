import styled from '@emotion/styled'
import { colors } from '@retailer-platform/shared-components'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { type FloatingPosition } from '@mantine/core'

export const Divider = styled.div({
  margin: '0px 8px',
  width: '389px',
  height: '0px',
  border: `1px solid ${colors.GRAYSCALE.X20}`,
})

export const StyledLogoImage = styled.img(({ grayout }: { grayout?: boolean }) => ({
  width: 26,
  height: 26,
  background: 'white',
  filter: grayout ? 'grayscale(100%)' : '',
}))

export const StyledLogoBox = styled.div({
  boxSizing: 'border-box',
  width: 36,
  height: 28,
  background: '#FFFFFF',
  border: '0.5px solid #C7C8CD',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export type PopoverConfig = {
  maxHeight?: number
  position?: FloatingPosition
  offset?: number
}
