import React, { Component, type ComponentType } from 'react'
import { type TrackedEvent, trackEvent } from './trackEvent'

type Args = Pick<TrackedEvent, 'id' | 'description'>

export const withTrackEventOnMount = (trackedEvent: Args) =>
  function <Props>(WrappedComponent: ComponentType<React.PropsWithChildren<Props>>) {
    return class extends Component<Props> {
      componentDidMount() {
        trackEvent(trackedEvent)
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  }
