import styled from '@emotion/styled'
import { DASH_NAV_WIDTH } from '../../../legacy/common/theme'

type AppContentContainerProps = {
  leftPadding?: boolean
}

export const AppContentContainer = styled.div<AppContentContainerProps>(
  ({ leftPadding = false }) => ({
    width: '100%',
    minHeight: '100%',
    height: '100%',
    paddingLeft: leftPadding ? DASH_NAV_WIDTH : '0px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  })
)
