import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const RoleManagementAccessControl: DomainAccessControlConfig = {
  // Right now a user needs to have both ManageUsers and ModifyRoles permissions to be able to manage roles.
  // In the future, we want to remove the Manage Users permission, but since managing users (specifically their roles)
  // is so intertwined with the ability to manage roles, we will require both for simplicity.
  // Likewise, we may want to reduce the requirements `role_management.modify` permission by being more granular
  // with viewing actions versus editing actions. For simplicity for now, we'll require the `modify` permission.
  permissions: [Permission.ManageUsers, Permission.ModifyRoles],
}
