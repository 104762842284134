import styled from '@emotion/styled'
import React from 'react'
import { type Color, IssueIcon } from '@instacart/ids-core'
import { type CSSObject } from '@emotion/react'
import { colors, spacing } from '../../foundation'
import { Text } from '../text/Text'
import { useMessages } from '../../utils/intl/intl.hooks'

export const FormField = styled.div<{ noMargin?: boolean }>(
  {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.X4,
  },
  ({ noMargin }) => ({ paddingBottom: noMargin ? 0 : spacing.X24 })
)

export const FormFieldLabel: React.FunctionComponent<
  React.PropsWithChildren<React.HTMLProps<'label'> & { optional?: boolean }>
> = ({ children, optional, ...props }) => {
  const messages = useMessages({
    optional: 'sharedComponents.formField.optional',
  })

  return (
    <Text {...props} as="label" weight="semibold" size="medium">
      {children}

      {optional && (
        <Text css={{ display: 'inline', marginLeft: spacing.X4, color: colors.GRAYSCALE.X50 }}>
          {messages.optional}
        </Text>
      )}
    </Text>
  )
}

export const FormFieldDescription: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, ...props }) => (
  <Text {...props} size="small">
    {children}
  </Text>
)

export const FormFieldFeedback: React.FunctionComponent<
  React.PropsWithChildren<
    React.HTMLProps<'label'> & {
      icon: React.ComponentType<React.PropsWithChildren<{ size: number; color: Color }>>
      iconColor?: Color
      className?: string
      containerStyles?: CSSObject
    }
  >
> = ({ children, icon: Icon, iconColor, containerStyles = {}, ...props }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: spacing.X4,
      ...containerStyles,
    }}
  >
    <Icon size={13} color={iconColor || 'systemGrayscale70'} />

    <Text {...props} as="label" size="small" css={{ color: colors.GRAYSCALE.X70 }}>
      {children}
    </Text>
  </div>
)

export const FormFieldError: React.FunctionComponent<
  React.PropsWithChildren<
    React.HTMLProps<'label'> & {
      icon?: React.ComponentType<React.PropsWithChildren<{ size: number; color: Color }>>
      containerStyles?: CSSObject
    }
  >
> = ({ icon: Icon = IssueIcon, ...props }) => (
  <FormFieldFeedback
    {...props}
    css={{ color: colors.DETRIMENTAL.REGULAR }}
    icon={Icon}
    iconColor="systemDetrimentalRegular"
  />
)
