import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import { createFilterParamConfig } from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
]

export const missingImage90DayRetailerInsightsSchemaFilterParamsConfig =
  transformFilterParamsForApiConfig

export const mapApiNameToDashboardName = {
  WAREHOUSE: 'retailer',
}

export const transformMissingImage90DayApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
} as const
