import React, { type HTMLAttributes } from 'react'
import styled from '@emotion/styled'

export interface CenterProps extends HTMLAttributes<HTMLDivElement> {
  /** Set to true to use inline-flex instead of flex */
  inline?: boolean
  fillParent?: boolean
  children: React.ReactNode
}

const CenterDiv = styled.div<CenterProps>(({ inline, fillParent }) => ({
  display: inline ? 'inline-flex' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: fillParent ? '100%' : undefined,
  height: fillParent ? '100%' : undefined,
}))

export const Center = ({ children, ...rest }: CenterProps) => (
  <CenterDiv {...rest}>{children}</CenterDiv>
)
