import React, { type FunctionComponent, useEffect, useState } from 'react'
import { Button, Confirm, notify, NotificationLevel } from '@retailer-platform/shared-components'
import {
  type CurrentAccountContextValue,
  useCurrentAccountContext,
} from '@retailer-platform/dashboard/utils'
import { useDomainMessage, useDomainMessages } from '../../../../utils/domain/intl'
import { type ReportTypeEnum } from '../../../../api'
import {
  DateRangePreset,
  useDateRangePresentMessages,
} from '../date-range-preset-select/ReportsNormalizedReportsListDateRangePresetSelect'
import { type MomentRange } from '../../utils/useReportsNormalizedReportsList.hooks'
import { reportTypeCanMerge } from '../../utils/ReportsNormalizedReportsList.utils'
import {
  type Props as MergeHookProps,
  useMergeNormalizedReports,
} from '../../utils/useMergeNormalizedReport.hooks'

interface ReportsNormalizedReportsListMergeButtonAndModalProps {
  reportCount: number //count of reports in the result set
  reportType: ReportTypeEnum
  dateRangePreset: DateRangePreset
  dateRange: MomentRange
}

export const mergeButtonAndModalTestIds = {
  mergeButton: 'merge-button',
  mergeConfirmModal: 'merge-confirm-modal',
}

const shouldShowMerge = ({
  reportType,
  retailerIds,
  account,
}: {
  reportType: ReportTypeEnum
  account: CurrentAccountContextValue['account']
  retailerIds: string[]
}) => {
  if (retailerIds?.length > 1 && !account.internalAccessConfiguration.hasFullPartnerAccess) {
    return false
  }

  return reportTypeCanMerge(reportType)
}

export const ReportsNormalizedReportsListMergeButtonAndModal: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListMergeButtonAndModalProps & MergeHookProps>
> = ({ reportCount, reportType, dateRangePreset, dateRange, ...rest }) => {
  const { account } = useCurrentAccountContext()
  const mergeText = useDomainMessage('reportsDomain.normalizedReports.list.merge')
  const showMerge = shouldShowMerge({ reportType, retailerIds: rest.retailerIds, account })
  const disableMerge = reportCount < 2
  const [showModal, setShowModal] = useState(false)

  const { createMergedReport, apiError } = useMergeNormalizedReports({
    reportType,
    dateRange,
    ...rest,
  })

  const dateRangeMessages = useDateRangePresentMessages()
  const i18n = useDomainMessages({
    mergeSuccess: 'reportsDomain.instacartManagedReports.merge.result.success.message',
    mergeFailure: 'reportsDomain.normalizedReports.merge.result.failure.message',
    beginningOfTime: 'reportsDomain.normalizedReports.merge.beginningOfTime',
    today: 'reportsDomain.normalizedReports.merge.today',
  })

  useEffect(() => {
    if (apiError) {
      notify({ body: i18n.mergeFailure, level: NotificationLevel.Error })
    }
  }, [apiError, i18n])

  // Returns a string that conveys a range of time for the given dateRangePreset, dateRange
  // eg: 'Last 7 days', 'Last 6 months', '2021/10/01 - 2021/11/01'
  let reportRange: string
  if (dateRangePreset === DateRangePreset.Custom) {
    const formattedStartDate = dateRange.startDate?.format('YYYY/MM/DD') || i18n.beginningOfTime
    const formattedEndDate = dateRange.endDate?.format('YYYY/MM/DD') || i18n.today
    reportRange = `${formattedStartDate} - ${formattedEndDate}`
  } else {
    reportRange = dateRangeMessages[dateRangePreset]
  }

  const i18n2 = useDomainMessages(
    {
      mergeSuccess: 'reportsDomain.normalizedReports.merge.result.success.message',
      mergeFailure: 'reportsDomain.normalizedReports.merge.result.failure.message',
      beginningOfTime: 'reportsDomain.normalizedReports.merge.beginningOfTime',
      today: 'reportsDomain.normalizedReports.merge.today',
      confirmButton: 'reportsDomain.normalizedReports.merge.confirmPrompt.confirmMerge',
      cancelButton: 'reportsDomain.normalizedReports.merge.confirmPrompt.cancelMerge',
      promptHeader: 'reportsDomain.normalizedReports.merge.confirmPrompt.header',
      modalMsgQuestion: 'reportsDomain.normalizedReports.merge.confirmPrompt.message.question',
      modalMsgReportCount:
        'reportsDomain.normalizedReports.merge.confirmPrompt.message.reportCount',
      modalMsgDuration: 'reportsDomain.normalizedReports.merge.confirmPrompt.message.duration',
    },
    { reportCount, reportTime: reportRange }
  )

  const bodyContent = (
    <div>
      <p>{i18n2.modalMsgQuestion}</p>
      <p>{i18n2.modalMsgReportCount}</p>
      <p>{i18n2.modalMsgDuration}</p>
    </div>
  )

  const confirmModal = (
    <Confirm
      data-testid={mergeButtonAndModalTestIds.mergeConfirmModal}
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      onConfirm={createMergedReport}
      confirmLabel={i18n2.confirmButton}
      cancelLabel={i18n2.cancelButton}
      headerContent={i18n2.promptHeader}
      bodyContent={bodyContent}
    />
  )

  return (
    <div>
      {showMerge && (
        <Button
          data-testid={mergeButtonAndModalTestIds.mergeButton}
          disabled={disableMerge}
          onClick={() => setShowModal(true)}
        >
          {mergeText}
        </Button>
      )}
      {confirmModal}
    </div>
  )
}
