import { useCallback } from 'react'
import { useCurrentAccountContext } from '../../current-account/CurrentAccountContext'
import { useICPlatformContext } from '../icPlatform.context'
import { type ICPlatformVersion } from './versions.constants'

export enum ICPlatformVersionMatchOperator {
  LessThanEqual,
  Equal,
  GreaterThanEqual,
}

/**
 * This function returns a callback that will return true if the requested
 * version is less than or equal to the resolved SFX version.
 *
 * This is useful for gating access using SFXs versioning system.
 * Note that sfxVersion is also an option when using access control hooks
 *
 * For more info: https://instacart.atlassian.net/wiki/spaces/Enterprise/pages/3905127168/SFX+Release+Gating+on+IPP
 *
 * @param requestedVersion ICPlatformVersion
 * @returns
 */
export const useMatchICPlatformVersion = () => {
  const accountContext = useCurrentAccountContext()
  const { version, isVersionOverridden } = useICPlatformContext()

  return useCallback(
    (
      requestedVersion: ICPlatformVersion,
      operator: ICPlatformVersionMatchOperator = ICPlatformVersionMatchOperator.LessThanEqual
    ) => {
      // If the user is internal, and we're not overriding the version, we always allow them access
      // this is to match how Storefront works.
      if (!isVersionOverridden && accountContext?.account?.isInternal) return true
      if (version === undefined) return false

      switch (operator) {
        case ICPlatformVersionMatchOperator.LessThanEqual:
          return requestedVersion <= version
        case ICPlatformVersionMatchOperator.Equal:
          return requestedVersion === version
        case ICPlatformVersionMatchOperator.GreaterThanEqual:
          return requestedVersion >= version
        default:
          throw new Error('Unknown/missing operator for comparing the SFX version')
      }
    },
    [accountContext?.account?.isInternal, isVersionOverridden, version]
  )
}
