import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const insightsPortalCreativeInsightsAccessControl: DomainAccessControlConfig = [
  {
    featureToggles: [FeatureToggle.InsightsCreativeInsights],
    permissions: [Permission.Placements],
  },
  {
    warehouseFeatureToggles: [FeatureToggle.InsightsCreativeInsights],
    permissions: [Permission.Placements],
  },
]
