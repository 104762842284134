
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "AuthenticationInterface",
        "possibleTypes": [
          {
            "name": "EnterpriseAuthenticationEmailPassword"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "HomePlacement",
        "possibleTypes": [
          {
            "name": "ContentManagementFlyoutPlacement"
          },
          {
            "name": "ContentManagementHeroBannerPlacement"
          },
          {
            "name": "ContentManagementSecondaryBannerPlacement"
          },
          {
            "name": "ContentManagementUnknownBannerPlacement"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ReportFilterDefinitionsInterface",
        "possibleTypes": [
          {
            "name": "ReportsReportFilterDefinitionsDateRange"
          },
          {
            "name": "ReportsReportFilterDefinitionsRetailerLocations"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ReportFilterValuesInterface",
        "possibleTypes": [
          {
            "name": "ReportsReportFilterValuesDateRange"
          },
          {
            "name": "ReportsReportFilterValuesRetailerLocations"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ReportViewsInterface",
        "possibleTypes": [
          {
            "name": "ReportsReportViewsCsv"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "VisibilityCondition",
        "possibleTypes": [
          {
            "name": "ContentManagementVisibilityConditionCountryIds"
          },
          {
            "name": "ContentManagementVisibilityConditionDayOfWeek"
          },
          {
            "name": "ContentManagementVisibilityConditionExperimentVariant"
          },
          {
            "name": "ContentManagementVisibilityConditionExpressMembership"
          },
          {
            "name": "ContentManagementVisibilityConditionExpressNonmemberLongTermHoldout"
          },
          {
            "name": "ContentManagementVisibilityConditionMatchesAnyRetailerId"
          },
          {
            "name": "ContentManagementVisibilityConditionMinimumPlatformVersion"
          },
          {
            "name": "ContentManagementVisibilityConditionNewVerticalUserTargeting"
          },
          {
            "name": "ContentManagementVisibilityConditionRetailerAvailability"
          },
          {
            "name": "ContentManagementVisibilityConditionShopped"
          },
          {
            "name": "ContentManagementVisibilityConditionShoppedAtRetailer"
          },
          {
            "name": "ContentManagementVisibilityConditionUnknownVisibilityCondition"
          },
          {
            "name": "ContentManagementVisibilityConditionUserInteractionLimit"
          },
          {
            "name": "ContentManagementVisibilityConditionUtmParameters"
          },
          {
            "name": "ContentManagementVisibilityConditionWhitelistedPostalCodes"
          },
          {
            "name": "ContentManagementVisibilityConditionWhitelistedZones"
          }
        ]
      }
    ]
  }
};
      export default result;
    