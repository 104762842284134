import { NewSelect } from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useMemo } from 'react'
import { useDomainMessages } from '../../../../utils/domain/intl'

export enum DateRangePreset {
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last_7_days',
  Last30Days = 'last_30_days',
  Last6Months = 'last_6_months',
  Custom = 'custom',
}

interface ReportsNormalizedReportsListDateRangePresetSelectProps {
  selectedDateRange: DateRangePreset
  onChange: (dateRange: DateRangePreset) => void
}

export const useDateRangePresentMessages = () =>
  useDomainMessages({
    [DateRangePreset.Today]: 'reportsDomain.normalizedReports.dateRangePreset.today',
    [DateRangePreset.Yesterday]: 'reportsDomain.normalizedReports.dateRangePreset.yesterday',
    [DateRangePreset.Last7Days]: 'reportsDomain.normalizedReports.dateRangePreset.last7Days',
    [DateRangePreset.Last30Days]: 'reportsDomain.normalizedReports.dateRangePreset.last30Days',
    [DateRangePreset.Last6Months]: 'reportsDomain.normalizedReports.dateRangePreset.last6Months',
    [DateRangePreset.Custom]: 'reportsDomain.normalizedReports.dateRangePreset.custom',
  })

export const ReportsNormalizedReportsListDateRangePresetSelect: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListDateRangePresetSelectProps>
> = ({ selectedDateRange = DateRangePreset.Last7Days, onChange }) => {
  const messages = useDateRangePresentMessages()
  const options = useMemo(
    () => [
      { value: DateRangePreset.Today, label: messages.today },
      { value: DateRangePreset.Yesterday, label: messages.yesterday },
      { value: DateRangePreset.Last7Days, label: messages.last_7_days },
      { value: DateRangePreset.Last30Days, label: messages.last_30_days },
      { value: DateRangePreset.Last6Months, label: messages.last_6_months },
      { value: DateRangePreset.Custom, label: messages.custom },
    ],
    [messages]
  )

  return (
    <NewSelect
      aria-label="ReportsNormalizedReportsListDateRangePresetSelect"
      options={options}
      value={selectedDateRange}
      onChange={val => onChange(val)}
      compact
    />
  )
}
