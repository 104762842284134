import { useMemo } from 'react'
import {
  dataFetchingError,
  dataFetchingLoading,
  type DataFetchingState,
  dataFetchingSuccess,
} from '../data-fetching/dataFetching.types'
import { type WarehouseLocation } from '../../types'
import { useWarehouseContext } from '../../../../utils/contexts/warehouse/WarehouseContext.hooks'

type Result = DataFetchingState<WarehouseLocation[]>
type UseWarehouseLocationsParams = {
  active?: boolean
}

export const useWarehouseLocations = (
  params: UseWarehouseLocationsParams = { active: true }
): Result => {
  const warehouseContext = useWarehouseContext()

  const data = useMemo(() => {
    if (!warehouseContext.loaded) {
      return []
    }

    return params.active
      ? warehouseContext.warehouse.warehouseLocations
      : [
          ...(warehouseContext.warehouse.warehouseLocations || []),
          ...(warehouseContext.warehouse.inactiveWarehouseLocations || []),
        ]
  }, [warehouseContext, params.active])

  if (warehouseContext.error) {
    return dataFetchingError<WarehouseLocation[]>(new Error('Failed to fetch warehouse locations.'))
  }
  if (!warehouseContext.loaded) {
    return dataFetchingLoading<WarehouseLocation[]>()
  }

  return dataFetchingSuccess<WarehouseLocation[]>(data)
}
