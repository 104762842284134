export enum Metrics {
  IMPRESSIONS = 'SUM_VISIT_IMPRESSIONS',
  ADD_TO_CARTS = 'SUM_VISIT_ADD_TO_CARTS',
  CART_CONVERSIONS = 'VISIT_CART_CONVERSION',
  GMV = 'SUM_PRODUCT_GMV',
  UNITS_SOLD = 'SUM_UNITS_SOLD',
  LAST_ETL_UPDATE = 'MAX_UPDATED_AT_DATE_TIME_UTC',
  NUM_COLLECTIONS = 'NUM_COLLECTIONS',
  ERV = 'SUM_PRODUCT_ERV',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  COLLECTION = 'COLLECTION',
  COLLECTION_AND_WAREHOUSE = 'COLLECTION_AND_WAREHOUSE',
  COLLECTION_ID = 'COLLECTION_ID',
  COLLECTION_NAME = 'COLLECTION_NAME',
  COLLECTION_SOURCE = 'COLLECTION_SOURCE',
  DEVICES = 'PLATFORM',
  L1 = 'L1',
  L1_ID = 'L1_ID',
  L1_NAME = 'L1_NAME',
  L2 = 'L2',
  L2_ID = 'L2_ID',
  L2_NAME = 'L2_NAME',
  L3 = 'L3',
  L3_ID = 'L3_ID',
  L3_NAME = 'L3_NAME',
  LAST_UPDATED = 'COLLECTION_LAST_UPDATED_UTC',
  NAV_COLLECTION_LEVEL = 'NAV_COLLECTION_LEVEL',
  ORDER_SOURCE = 'ORDER_SOURCE',
  RETAILER_COLLECTION_ID = 'RETAILER_COLLECTION_ID',
  WAREHOUSE = 'WAREHOUSE',
}

export enum GroupBys {
  COLLECTION_NAME = 'COLLECTION_NAME',
  COLLECTION_ID = 'COLLECTION_ID',
  RETAILER_COLLECTION_ID = 'RETAILER_COLLECTION_ID',
  START_DATE = 'START_DATE',
  LAST_UPDATED = 'COLLECTION_LAST_UPDATED_UTC',
  CREATED_BY = 'CREATED_BY',
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  MONTH_PT = 'MONTH_PT',
  DEVICES = 'PLATFORM',
  CATALOG_PRODUCT_ID = 'CATALOG_PRODUCT_ID',
  RETAILER_PRODUCT = 'RETAILER_PRODUCT',
  PRODUCT_UPC_CODE = 'PRODUCT_UPC_CODE',
  COLLECTION_SOURCE = 'COLLECTION_SOURCE',
  NAV_COLLECTION_LEVEL = 'NAV_COLLECTION_LEVEL',
  L1_ID = 'L1_ID',
  L1_NAME = 'L1_NAME',
  L2_ID = 'L2_ID',
  L2_NAME = 'L2_NAME',
  L3_ID = 'L3_ID',
  L3_NAME = 'L3_NAME',
  COLLECTION_AND_WAREHOUSE = 'COLLECTION_AND_WAREHOUSE',
  WAREHOUSE = 'WAREHOUSE',
}
