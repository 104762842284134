import React, { type FunctionComponent, Fragment } from 'react'
import { useCurrentAccountDetails } from '../../../../api/current-account/currentAccount.hooks'

// Load all globally-required data before the app boots
export const AppProviderPreloader: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { loading } = useCurrentAccountDetails()

  if (loading) {
    return null
  }

  return <Fragment>{children}</Fragment>
}
