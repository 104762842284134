import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { connectOrdersAccessControl } from '../access-control/connectOrdersAccess.configuration'
import { rescheduleAndCancelConnectOrderControl } from '../access-control/rescheduleAndCancelConnectOrder.configuration'
import { createConnectOrderControl } from '../access-control/createConnectOrder.configuration'

const ConnectOrdersOrderDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConnectOrdersOrderDetailsPage" */
      '../pages/order-details/ConnectOrdersOrderDetailsPage'
    )
)

const ConnectOrdersOrdersTablePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConnectOrdersOrdersTablePage" */
      '../pages/orders-table/ConnectOrdersOrdersTablePage'
    )
)

const ConnectOrderRescheduleOrderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConnectOrderRescheduleOrderPage" */
      '../pages/reschedule-order/ConnectOrderRescheduleOrderPage'
    )
)

const ConnectOrderCreateOrderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConnectOrderRescheduleOrderPage" */
      '../pages/create-order/ConnectOrderCreateOrderPage'
    )
)
// @hygen:inject page-import

export const connectOrdersRouter = (
  <DomainRoute route="connect-orders" accessControl={connectOrdersAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="connect-orders-orders-table"
        component={ConnectOrdersOrdersTablePage}
        scopePicker={{
          titles: ['connectOrdersDomain.title'],
        }}
      />
      <DomainRoute
        exact
        route="connect-orders-order-details"
        component={ConnectOrdersOrderDetailsPage}
      />
      <DomainRoute
        exact
        route="connect-orders-reschedule-order"
        component={ConnectOrderRescheduleOrderPage}
        accessControl={rescheduleAndCancelConnectOrderControl}
      />
      <DomainRoute
        exact
        route="connect-orders-order-tracking"
        component={ConnectOrdersOrderDetailsPage}
      />
      <DomainRoute
        exact
        route="connect-orders-create-order"
        component={ConnectOrderCreateOrderPage}
        accessControl={createConnectOrderControl}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
