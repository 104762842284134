import { type DashRouteType, URL_PARTNER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_PARTNER_IDP = `${URL_PARTNER_SCOPED}/instacart-developer-platform`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'instacart-developer-platform'>
  | DashRouteType<'instacart-developer-platform-get-started'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'instacart-developer-platform': URL_PARTNER_IDP,
  'instacart-developer-platform-get-started': `${URL_PARTNER_IDP}/get-started`,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
