import type dayjs from 'dayjs'
import { useCallback } from 'react'
import { InsightsPortalSchema, useGetInsightsPortalMetricsQuery } from '../../api'
import { useInsightsPortalAuthorizationContext } from '../../utils/authorization-context.hooks'
import { useFilterParamsContext } from '../../utils/filter-params/filterParamsContext'
import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'
import { type FilterParamsState } from '../../utils/filter-params/filterParams.types'
import { calculateComparisonPeriod } from '../../utils/insights-portal.utils'
import { useFormatValue } from '../../utils/format-value.hooks'
import { useDomainMessages } from '../../utils/domain/intl'
import { useDataSource } from '../../utils/data-source.hooks'
import { Metrics } from './index'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig('state', 'ZONE_STATE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig('region', 'REGION', optionsArrayToInFilterSelection('stringValue')),
]

export const imageCoverageSchemaFilterParamsConfig = transformFilterParamsForApiConfig

const transformFilterSelectionsToImageCoverageFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)

const useImageCoverageMetrics = (
  selectedFiltersValues: FilterParamsState['selectedFiltersValues'],
  dates: { from: dayjs.Dayjs; to: dayjs.Dayjs },
  metrics: Metrics[]
) => {
  const { partnerIds, retailerIds, retailerLocationIds } = useInsightsPortalAuthorizationContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')

  const currentPeriodDateRange = {
    from,
    to,
  }

  const { data, loading } = useGetInsightsPortalMetricsQuery({
    variables: {
      schema: InsightsPortalSchema.ImageCoverage,
      partnerIds,
      retailerIds,
      retailerLocationIds,
      metrics,
      filters: transformFilterSelectionsToImageCoverageFilters(
        selectedFiltersValues,
        currentPeriodDateRange
      ),
      dataSource: useDataSource(),
    },
  })

  return {
    data: data?.insightsPortalServiceGetInsightsPortalMetricValues?.metricValues || [],
    loading,
  }
}

export const useCurrentPeriodImageCoverageMetrics = (metrics: Metrics[]) => {
  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')

  const currentPeriodDateRange = {
    from,
    to,
  }

  return useImageCoverageMetrics(selectedFiltersValues, currentPeriodDateRange, metrics)
}

export const useLastPeriodImageCoverageMetrics = (metrics: Metrics[]) => {
  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')

  const lastPeriodDateRange = calculateComparisonPeriod(from, to, dates.comparisonType)

  return useImageCoverageMetrics(selectedFiltersValues, lastPeriodDateRange, metrics)
}

export const useImageCoverageMetricValueFormatter = () => {
  const { formatPercentage } = useFormatValue()

  const formatter = useCallback(
    (metric: Metrics, value: number) => {
      const formatters = [{ metric: Metrics.IMAGE_COVERAGE, formatter: formatPercentage }]

      const metricFormatter = formatters.find(f => f.metric === metric)

      if (metricFormatter) {
        return metricFormatter.formatter(value)
      }
      return value.toLocaleString()
    },
    [formatPercentage]
  )

  return {
    formatter,
  }
}

export const useImageCoverageMetricLabels = () =>
  useDomainMessages({
    IMAGE_COVERAGE: 'insightsPortalDomain.searchDashboard.metrics.imageCoverage',
  })
