import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_CONFIGURABLE_PRODUCTS = `${URL_RETAILER_SCOPED}/configurable-products`
const URL_CONFIGURABLE_PRODUCTS_SHOW = `${URL_CONFIGURABLE_PRODUCTS}/show`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'configurable-products'>
  | DashRouteType<'configurable-products-show'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'configurable-products': URL_CONFIGURABLE_PRODUCTS,
  'configurable-products-show': URL_CONFIGURABLE_PRODUCTS_SHOW,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
