import { useLocation, useRouteMatch } from 'react-router'
import { routes } from '@retailer-platform/domain-instacart-developer-platform'
import { URL_APP_ADMIN, URL_DASHBOARD } from '../../../utils/routing/routes'
import { url } from '../../../utils/parsing/url'
import { PartnerType } from '../../../exports/utils'

export const useRedirectionLocation = initialLoad => {
  const location = useLocation()
  const adminMatch = useRouteMatch(URL_APP_ADMIN)

  return (
    partnerType: string,
    partnerId: string,
    warehouseId: string = '0',
    changingPartnerTypes: boolean = false
  ) => {
    let newPath
    if (initialLoad || adminMatch || changingPartnerTypes) {
      /* eslint-disable @typescript-eslint/naming-convention */
      newPath = url(URL_DASHBOARD, {
        partner_id: partnerId,
        warehouse_id: warehouseId,
      })

      if (partnerType === PartnerType.IDP) {
        newPath = url(routes['instacart-developer-platform-get-started'], {
          partner_id: partnerId,
        })
      }

      /* eslint-enable @typescript-eslint/naming-convention */
    } else {
      // filter out anything that is a literal undefined
      newPath = location.pathname.replace(/\/partners\/(undefined|\d+)/, '/partners/' + partnerId)
      newPath = newPath.replace(/\/warehouses\/(undefined|\d+)/, '/warehouses/' + warehouseId)
      newPath = newPath.replace(/\/sites\/\d+/, '/sites/' + '0')
    }

    return newPath
  }
}
