/* eslint-disable react-hooks/rules-of-hooks */
import { useHistory } from 'react-router'
import { baseUseScopedPathname } from './url'

export type useGoToPathFn<T extends string = string> = (
  name: T,
  params?: object,
  routeType?: 'push' | 'replace',
  search?: Record<string, string>,
  hash?: Record<string, string>
) => () => void

export const baseUseGoToPath: useGoToPathFn = (
  name,
  params = {},
  routeType = 'push',
  search = {},
  hash = {}
) => {
  const history = useHistory()

  const routeMethod = history[routeType]
  const pathname = baseUseScopedPathname(name, params)

  const queryString = new URLSearchParams(search).toString()
  const hashString = new URLSearchParams(hash).toString()

  return () =>
    routeMethod({
      pathname,
      search: queryString ? queryString : undefined,
      hash: hashString ? hashString : undefined,
    })
}

export type useCreateHrefFn<T extends string = string> = (
  name: T,
  params?: object,
  search?: Record<string, string>
) => string

export const baseCreateHref: useCreateHrefFn = (name, params = {}, search = {}) => {
  const history = useHistory()

  const pathname = baseUseScopedPathname(name, params)

  const queryString = new URLSearchParams(search).toString()

  return history.createHref({ pathname, search: queryString ? queryString : undefined })
}
