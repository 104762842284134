import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const catalogAdminMDPlatformAccessConfiguration: DomainAccessControlConfig = {
  permissions: Permission.CatalogDetectionViewAccess,
}

export const catalogAdminMDPlatformEditAccessConfiguration: DomainAccessControlConfig = {
  permissions: [Permission.CatalogDetectionViewAccess, Permission.CatalogDetectionModifyAccess],
}
