import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

// Banner Routes
const URL_APP_ADMIN_BANNERS = `${URL_APP_ADMIN}/banners`
const URL_APP_ADMIN_BANNERS_LIST = `${URL_APP_ADMIN}/banners/list`
const URL_APP_ADMIN_BANNERS_DETAILS_OLD = `${URL_APP_ADMIN}/banners/details/:placementId`
const URL_APP_ADMIN_BANNERS_DETAILS = `${URL_APP_ADMIN}/banners/details/:placementId/locale/:locale`
const URL_APP_ADMIN_BANNERS_CREATE = `${URL_APP_ADMIN}/banners/create`
const URL_APP_ADMIN_BANNERS_EDIT_OLD = `${URL_APP_ADMIN}/banners/edit/:placementId`
const URL_APP_ADMIN_BANNERS_EDIT = `${URL_APP_ADMIN}/banners/edit/:placementId/locale/:locale`
const URL_APP_ADMIN_BANNERS_VISIBILITY_CONDITIONS = `${URL_APP_ADMIN}/banners/details/:placementId/locale/:locale/visibility-conditions`
const URL_APP_ADMIN_BANNERS_VISIBILITY_CONDITIONS_CREATE = `${URL_APP_ADMIN}/banners/details/:placementId/locale/:locale/visibility-conditions/create`

// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-banners'>
  | DashRouteType<'app-admin-banners-list'>
  | DashRouteType<'app-admin-banners-details-old', { placementId: string }>
  | DashRouteType<'app-admin-banners-details', { placementId: string; locale: string }>
  | DashRouteType<'app-admin-banners-create'>
  | DashRouteType<'app-admin-banners-edit-old', { placementId: string }>
  | DashRouteType<'app-admin-banners-edit', { placementId: string; locale: string }>
  | DashRouteType<
      'app-admin-banners-visibility-conditions',
      { placementId: string; locale: string }
    >
  | DashRouteType<
      'app-admin-banners-visibility-conditions-create',
      { placementId: string; locale: string }
    >
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-banners': URL_APP_ADMIN_BANNERS,
  'app-admin-banners-list': URL_APP_ADMIN_BANNERS_LIST,
  'app-admin-banners-details-old': URL_APP_ADMIN_BANNERS_DETAILS_OLD,
  'app-admin-banners-details': URL_APP_ADMIN_BANNERS_DETAILS,
  'app-admin-banners-create': URL_APP_ADMIN_BANNERS_CREATE,
  'app-admin-banners-edit-old': URL_APP_ADMIN_BANNERS_EDIT_OLD,
  'app-admin-banners-edit': URL_APP_ADMIN_BANNERS_EDIT,
  'app-admin-banners-visibility-conditions': URL_APP_ADMIN_BANNERS_VISIBILITY_CONDITIONS,
  'app-admin-banners-visibility-conditions-create':
    URL_APP_ADMIN_BANNERS_VISIBILITY_CONDITIONS_CREATE,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
