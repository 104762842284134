/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react'
import type Bluebird from 'bluebird'
import instacart, { type Go } from './instacart'

function getDisplayName(WrappedComponent: React.ComponentType<React.PropsWithChildren<any>>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export interface WithApiInjectedProps {
  api: Go
}

function withApi<P extends WithApiInjectedProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentClass<Omit<P, keyof WithApiInjectedProps>> {
  class WithApi extends Component<Omit<P, keyof WithApiInjectedProps>> {
    static displayName = `WithApi(${getDisplayName(WrappedComponent)})`

    api: Go = {
      get: (...args) => this.handleApiCall(instacart.go.get(...args)),
      post: (...args) => this.handleApiCall(instacart.go.post(...args)),
      put: (...args) => this.handleApiCall(instacart.go.put(...args)),
      delete: (...args) => this.handleApiCall(instacart.go.delete(...args)),
    }

    promises: Bluebird<any>[] = []

    componentWillUnmount() {
      this.promises.map(p => p.cancel())
    }

    addPromise(promise: Bluebird<any>) {
      this.promises = [...this.promises, promise]
    }

    removePromise(promise: Bluebird<any>) {
      this.promises = this.promises.filter(p => p !== promise)
    }

    handleApiCall<TResponse>(promise: Bluebird<TResponse>): Bluebird<TResponse> {
      this.addPromise(promise)

      return promise
        .then(result => {
          this.removePromise(promise)

          return result
        })
        .catch(e => {
          this.removePromise(promise)

          throw e
        })
    }

    render() {
      // cast temporarily
      // Workaround for https://github.com/microsoft/TypeScript/issues/28884
      return <WrappedComponent {...(this.props as P)} api={this.api} />
    }
  }

  return WithApi
}

export default withApi
