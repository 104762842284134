import moment from 'moment-timezone'
import { currentTime, currentUserTimezone } from './timeHelpers'

export enum PlacementManagerSurface {
  THEME_PAGE = 'theme-page',
  HOMEPAGE = 'homepage',
}

export const validSurfaceNameRouteParams = Object.values(PlacementManagerSurface).join('|')

export enum PlacementStatus {
  DISABLED = 'DISABLED',
  SCHEDULED = 'SCHEDULED',
  LIVE = 'LIVE',
  EXPIRED = 'EXPIRED',
}

export enum PlacementManagerState {
  LIST = 'list',
  DETAILS = 'details',
  EDIT = 'edit',
  CREATE = 'create',
}

export enum PlacementManagementAudience {
  INTERNAL_ADMIN = 'INTERNAL_ADMIN',
  THIRD_PARTY_ADMIN = 'THIRD_PARTY_ADMIN',
}

export enum PlacementManagerCreationStep {
  SELECT_PLACEMENT_TYPE,
  SETUP_PLACEMENT,
  PUBLISH_PLACEMENT,
}

/**
 * Get Placement Status
 * @date 9/28/2022 - 1:33:44 PM
 *
 * @export
 * @param {{
 *   startDate?: string
 *   endDate?: string
 *   enabled: boolean
 * }} placement
 * @returns {PlacementStatus}
 */
export function PlacementStatusFromPlacement(placement: {
  startDate?: string
  endDate?: string
  enabled: boolean
}): PlacementStatus {
  const enabled = placement.enabled || false
  if (!enabled) return PlacementStatus.DISABLED

  const currentDate = currentTime(true)
  const userTimezone = currentUserTimezone()
  const startDate = placement.startDate ? moment(placement.startDate).tz(userTimezone) : null
  const endDate = placement.endDate ? moment(placement.endDate).tz(userTimezone) : null

  // NOTE: We do not do checks on placement time = current time because those checks
  // would only really evaluate to true in milisecond timeframes, and would otherwise
  // unnecessarily complicate the logic.
  if (startDate && endDate) {
    if (startDate.isBefore(currentDate) && endDate.isAfter(currentDate)) {
      return PlacementStatus.LIVE
    } else if (endDate.isBefore(currentDate)) {
      return PlacementStatus.EXPIRED
    } else if (startDate.isAfter(currentDate)) {
      return PlacementStatus.SCHEDULED
    }
  } else if (startDate) {
    if (startDate.isBefore(currentDate)) {
      return PlacementStatus.LIVE
    } else {
      return PlacementStatus.SCHEDULED
    }
  } else if (endDate) {
    if (endDate.isAfter(currentDate)) {
      return PlacementStatus.LIVE
    } else {
      return PlacementStatus.EXPIRED
    }
  } else {
    return PlacementStatus.LIVE
  }
}
