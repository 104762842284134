import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  creativeManagerAccessControl,
  creativeManagerV2AccessControl,
} from '../access-control/creativeManagerAccess.configuration'
import {
  pageBuilderViewAccessControl,
  storefrontHomepageDashboardV2,
} from '../access-control/storefrontAccess.configuration'

// @hygen:inject page-import
const StorefrontCreativeManagerPageV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontCreativeManagerPageV2" */
      '../pages/creative-manager/CreativeManagerPageV2'
    )
)

const StorefrontCreativeManagerRecordViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontCreativeManagerRecordView" */
      '../pages/creative-manager/CreativeManagerRecordView'
    )
)

const StorefrontHomepageDashboardV2 = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontHomepageDashboardV2" */
      '../pages/homepage-dashboard/StorefrontHomepageDashboardPageV2'
    )
)

const StorefrontPartnerHomepageBuilderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontHomepageBuilderPage" */
      '../pages/homepage-builder/StorefrontPartnerHomepageBuilderPage'
    )
)

export const storefrontPartnerScopedRouter = (
  <DomainRoute route="storefront-partner-scoped">
    <DashSwitch>
      <DomainRoute
        exact
        route="storefront-creative-manager-v2"
        component={StorefrontCreativeManagerPageV2}
        accessControl={creativeManagerV2AccessControl}
      />
      <DomainRoute
        exact
        route="storefront-creative-view"
        component={StorefrontCreativeManagerRecordViewPage}
        accessControl={creativeManagerAccessControl}
      />
      <DomainRoute
        exact
        route="storefront-homepage-dashboard-v2"
        component={StorefrontHomepageDashboardV2}
        accessControl={storefrontHomepageDashboardV2}
      />
      <DomainRoute
        exact
        route="storefront-homepage-edit-v2"
        component={StorefrontPartnerHomepageBuilderPage}
        accessControl={pageBuilderViewAccessControl}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
