import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
  buildTimeIntervalDimensionsGrouping,
} from '../helpers/schema.helpers'
import { insightsPortalERVMetricAccessControl } from '../../access-control/insightsPortalERVMetricAccess.configuration'
import {
  InsightsFormatterType,
  InsightsGroupByTypes,
  InsightsMetricDesiredDirection,
} from '../types/schema.types'
import { orderDeliveriesSchemaFilterParamsConfig } from './insightsPortalOrderDeliveries.helpers'
import { GroupBys, Metrics, Dimensions } from './insightsPortalOrderDeliveries.types'

export { GroupBys, Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.COUNT_ORDER]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_ORDER',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.COUNT_USER',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.SUM_GMV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.performanceDashboard.metrics.SUM_GMV',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.SUM_GMV',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.SUM_ERV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.performanceDashboard.metrics.SUM_ERV',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.SUM_ERV',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
      private: insightsPortalERVMetricAccessControl,
    },
    [Metrics.AVG_GMV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.performanceDashboard.metrics.AVG_GMV',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.AVG_GMV',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.COUNT_USER]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_USER',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.COUNT_USER',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.AVG_RATING]: {
      formatter: InsightsFormatterType.Decimal,
      label: 'insightsPortalDomain.performanceDashboard.metrics.AVG_RATING',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.AVG_RATING',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.COUNT_FIRST_ORDER]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_FIRST_ORDER',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.COUNT_FIRST_ORDER',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.AVG_USER_PER_STORE]: {
      formatter: InsightsFormatterType.Integer,
      private: true,
    },
    [Metrics.AVG_GMV_PER_STORE]: {
      formatter: InsightsFormatterType.Currency,
      private: true,
    },
    [Metrics.AVG_FIRST_ORDER_PER_STORE]: {
      formatter: InsightsFormatterType.Integer,
      private: true,
    },
    [Metrics.MAX_UPDATED_AT_DATE_TIME_UTC]: {
      formatter: InsightsFormatterType.Date,
      private: true,
    },
  },
  dimensionsConfig: {
    [Dimensions.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      private: true,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [GroupBys.DELIVERY_STATE]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.deliveryState.label',
      private: true,
    },
    [Dimensions.PLATFORM]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.placements.filters.platform',
      private: true,
    },
    [Dimensions.INSIGHTS_PORTAL_REPORTING_PLATFORM]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.placements.filters.platform',
      private: true,
    },
    [Dimensions.ORDER_SOURCE_WITH_DELIVERY]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [Dimensions.WAREHOUSE_LOCATION_GROUP]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 5,
      label: 'insightsPortalDomain.filters.warehouseLocationGroup.label',
    },
    [Dimensions.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_CODE]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 5,
      label: 'insightsPortalDomain.campaigns.groupBys.WAREHOUSE_LOCATION_CODE',
    },
    // Pickup is deprecated as of 06/25/2024
    [Dimensions.PICKUP]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      private: true,
      label: 'insightsPortalDomain.filters.fulfillmentType.label',
    },
    [Dimensions.ALCOHOL]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.alcohol.orders.label',
    },
    [Dimensions.EXPRESS]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.express.orders.label',
    },
    [Dimensions.LOYALTY]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.loyalty.label',
    },
    [Dimensions.EBT]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.ebt.label',
    },
    [Dimensions.FIRST_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_FIRST_ORDER',
    },
    [Dimensions.VIRTUAL_WAREHOUSE]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.convenience.label',
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 5,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 2,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_STATE',
    },
    [Dimensions.PARTNER_GEO_DIVISION]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.partnerDivision.label',
    },
    [Dimensions.ORDER_CURRENCY]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.orderCurrency.label',
    },
    [Dimensions.REGION]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 4,
      label: 'insightsPortalDomain.controls.groupBy.region',
    },
    [Dimensions.FULFILLMENT_TYPE]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label:
        'insightsPortalDomain.performanceOverview.performanceByProgram.programName.FULFILLMENT_TYPE',
    },
    [Dimensions.FSA_HSA_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.fsaHsa.label',
    },
    [Dimensions.RX_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.rx.label',
    },
    ...buildTimeIntervalDimensionsConfig,
  },
  dimensionGroupings: [
    buildTimeIntervalDimensionsGrouping,
    {
      label: 'insightsPortalDomain.filters.stores',
      dimensions: [
        Dimensions.WAREHOUSE,
        Dimensions.WAREHOUSE_LOCATION_STATE,
        Dimensions.REGION,
        Dimensions.WAREHOUSE_LOCATION_GROUP,
        Dimensions.WAREHOUSE_LOCATION,
        Dimensions.WAREHOUSE_LOCATION_CODE,
        Dimensions.PARTNER_GEO_DIVISION,
      ],
    },
    {
      label: 'insightsPortalDomain.filters.orders',
      dimensions: [
        Dimensions.ORDER_SOURCE_WITH_DELIVERY,
        Dimensions.FULFILLMENT_TYPE,
        Dimensions.ALCOHOL,
        Dimensions.VIRTUAL_WAREHOUSE,
        Dimensions.EXPRESS,
        Dimensions.LOYALTY,
        Dimensions.EBT,
        Dimensions.FSA_HSA_ORDER,
        Dimensions.RX_ORDER,
        Dimensions.FIRST_ORDER,
        Dimensions.ORDER_CURRENCY,
      ],
    },
  ],

  // TODO: deprecate
  groupBys: GroupBys,
  filterConfigs: orderDeliveriesSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    orderDeliveriesSchemaFilterParamsConfig
  ),
  groupBysConfig: {
    ...buildTimeIntervalGroupBysConfig,
    [GroupBys.ORDER_SOURCE]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [GroupBys.ORDER_SOURCE_WITH_DELIVERY]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.collections.filters.orderSource',
    },
    [GroupBys.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [GroupBys.PICKUP]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.fulfillmentType.label',
    },
    [GroupBys.ALCOHOL]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.alcohol.orders.label',
    },
    [GroupBys.EXPRESS]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.express.orders.label',
    },
    [GroupBys.LOYALTY]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.loyalty.label',
    },
    [GroupBys.EBT]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.ebt.label',
    },
    [GroupBys.FIRST_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_FIRST_ORDER',
    },
    [GroupBys.VIRTUAL_WAREHOUSE]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.convenience.label',
    },
    [GroupBys.WAREHOUSE_LOCATION]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 5,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION',
    },
    [GroupBys.WAREHOUSE_LOCATION_STATE]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 2,
      label:
        'insightsPortalDomain.AggWarehouseLocationCampaignPerformance.metrics.WAREHOUSE_LOCATION_STATE',
    },
    [GroupBys.PARTNER_GEO_DIVISION]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.partnerDivision.label',
    },
    [GroupBys.ORDER_CURRENCY]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.orderCurrency.label',
    },
    [GroupBys.REGION]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 4,
      label: 'insightsPortalDomain.controls.groupBy.region',
    },
    [GroupBys.FULFILLMENT_TYPE]: {
      type: InsightsGroupByTypes.Program,
      magnitude: 1,
      label:
        'insightsPortalDomain.performanceOverview.performanceByProgram.programName.FULFILLMENT_TYPE',
    },
    [GroupBys.DELIVERY_STATE]: {
      type: InsightsGroupByTypes.Info,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.deliveryState.label',
    },
    [GroupBys.FSA_HSA_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.fsaHsa.label',
    },
    [GroupBys.RX_ORDER]: {
      type: InsightsGroupByTypes.Program,
      formatter: InsightsFormatterType.YesOrNo,
      magnitude: 1,
      label: 'insightsPortalDomain.filters.rx.label',
    },
  },
})
