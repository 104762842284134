/* eslint-disable @typescript-eslint/no-explicit-any */

import qs from 'query-string'
import HttpError from './errors/HttpError'

export type HttpMethod = 'get' | 'post' | 'put' | 'delete'

export interface RequestOpts<TData = {}, TQuery = {}> extends RequestInit {
  method?: TData extends undefined ? HttpMethod : Exclude<HttpMethod, 'get'>
  data?: TData
  query?: TQuery
}

function request<TResponse, TQuery = {}>(
  url: string,
  requestOpts?: RequestOpts<undefined, TQuery>
): Promise<TResponse>
function request<TResponse, TData, TQuery = {}>(
  url: string,
  requestOpts?: RequireKeys<RequestOpts<TData, TQuery>, 'method'>
): Promise<TResponse>
function request<TResponse>(
  url: string,
  requestOpts: RequestOpts<any, any> = { method: 'get' }
): Promise<TResponse> {
  const { data, method = 'get', headers, query, ...rest } = requestOpts

  const isJSONBody = !(requestOpts.body instanceof FormData)

  const opts = {
    method,
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...(isJSONBody ? { 'Content-Type': 'application/json' } : {}),
      ...headers,
    },
    ...rest,
  }
  let fetchUrl = url

  if (query) {
    fetchUrl += `?${qs.stringify(query)}`
  }

  return fetch(fetchUrl, opts).then(checkStatus).then(parseJSON)
}

async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  const body = await response.json().catch(() => undefined)
  const error = new HttpError(response, body)

  throw error
}

function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }

  return response.json()
}

export default request
