import { sessionDataHandler } from './sessionDataHandler'

export const SESSION_DATA_IDENTIFIER = 'session'

type SessionData =
  | {
      token: string
    }
  | undefined

export const fetchSessionData = async () =>
  sessionDataHandler.getItem<SessionData>(SESSION_DATA_IDENTIFIER)

export const storeSessionData = async (sessionData?: SessionData) =>
  sessionDataHandler.setItem<SessionData>(SESSION_DATA_IDENTIFIER, sessionData)
