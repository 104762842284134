import React, { type FunctionComponent } from 'react'
import { SparkleIcon, LogOutIcon, TechnicalIcon, CORE_COLORS, UserIcon } from '@instacart/ids-core'
import { useBooleanState } from '@retailer-platform/shared-components'
import { AccountManagementMyAccountModal } from '@retailer-platform/domain-account-management'
import { NavItem } from '../NavItem'
import useAccessControl from '../../../../../legacy/components/AccessControl/useAccessControl'
import { Permission } from '../../../../../legacy/common/types'
import { useNavContext } from '../../utils/NavContext'
import { useCurrentAccountDetails } from '../../../../../api/current-account/currentAccount.hooks'
import { trackEvent } from '../../../../../utils/events/trackEvent'
import { useAdminControls } from '../../../../../utils/contexts/admin-controls/AdminControlsContext'
import { AdminControlModal } from '../../../admin-controls-modal/AdminControlsModal'

export const RetailerNavFooterContent: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const hasAccess = useAccessControl()
  const isInternalAdmin = hasAccess({
    permissions: Permission.AdminPanel,
  })
  const [showMyAccountModal, openMyAccountModal, closeMyAccountModal] = useBooleanState(false)
  const { allowNavV1RouletteEnabled, setNavV2Enabled, closeNav } = useNavContext()
  const adminControlsContext = useAdminControls()
  const { account: currentAccount } = useCurrentAccountDetails()

  return (
    <>
      {allowNavV1RouletteEnabled && (
        <NavItem
          isMainItem
          Icon={SparkleIcon}
          title="navV2.userSettings.switchToNavV2"
          onClick={() => {
            setNavV2Enabled(true)
            closeNav()
            trackEvent({
              id: 'navV2.navigation.switchToNavV2',
              description: 'Tracks whenever the user switches to NavV2 from the side navigation',
              data: {
                accountID: currentAccount?.id,
              },
            })
          }}
          linkBaseWrapperStyles={{
            backgroundColor: CORE_COLORS.systemSuccessRegular,
          }}
        />
      )}
      <NavItem
        isMainItem
        Icon={UserIcon}
        title="app.admin.nav.links.myAccount"
        onClick={openMyAccountModal}
      />
      <AccountManagementMyAccountModal isOpen={showMyAccountModal} onClose={closeMyAccountModal} />
      {isInternalAdmin && (
        <>
          <NavItem
            isMainItem
            Icon={TechnicalIcon}
            title="app.admin.nav.links.adminControls"
            onClick={adminControlsContext.openAdminControls}
          />
          <AdminControlModal />
        </>
      )}
      <NavItem isMainItem Icon={LogOutIcon} route="logout" title="logout" />
    </>
  )
}
