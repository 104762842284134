import _groupBy from 'lodash/groupBy'
import { type WarehouseWithFeatures } from '../../../utils/contexts/warehouse/queries/warehouse'
import { type InstacartStoreConfiguration } from '../../../__codegen__/api'
import {
  type SiteOperationIntent,
  SiteSelectOption,
  type StoreConfigurationForSelection,
} from '../retailer-and-site-picker/RetailerAndSitePicker.types'

export interface Option {
  id: string
  name: string
  shortName?: string
  selected: boolean
  type: 'banner' | 'site' | 'warehouseGroup' | 'all'
  breadcrumbs: Option[]
  leafNode: boolean
  siteUrl?: string
  logoUrl?: string
  children?: Option[]
}

interface StoreConfig {
  id: string
  brandedName: string
  domainName: string
}

const getDomainName = (url: string) => new URL(url).hostname

export const storeConfigToOption = (
  storeConfig: StoreConfig,
  children: Option[],
  originalSelectedOptions: Set<string>
): Option => ({
  id: storeConfig.id,
  name: `${storeConfig.brandedName} - ${getDomainName(storeConfig.domainName)}`,
  shortName: storeConfig.brandedName,
  selected: originalSelectedOptions.has(storeConfig.id),
  type: 'site',
  siteUrl: storeConfig.domainName,
  logoUrl: '',
  children: children || [],
  leafNode: !children?.length,
  breadcrumbs: [],
})

export const bannerToOption = (
  banner: WarehouseWithFeatures,
  originalSelectedOptions: Set<string>
): Option => ({
  id: banner.id.toString(),
  name: banner.name,
  selected: originalSelectedOptions.has(banner.id.toString()),
  type: 'banner',
  logoUrl: banner.logo?.url,
  breadcrumbs: [],
  leafNode: true,
})

export const createSiteOptions = (
  storeConfigs: InstacartStoreConfiguration[],
  originalSelectedOptions: Set<string>
) => {
  const options: Option[] = []
  storeConfigs?.forEach(storeConfig => {
    const siteOption = storeConfigToOption(storeConfig, [], originalSelectedOptions)
    options.push(siteOption)
  })
  return options
}

export const groupBannersByWarehouseGroup = (
  warehouses: WarehouseWithFeatures[],
  originalSelectedOptions: Set<string>
) => {
  const warehouseGroups = _groupBy(
    warehouses,
    warehouse => warehouse.warehouseGroup?.franchisorId || 'undefined'
  )

  const options: Option[] = []

  warehouseGroups.undefined?.forEach(warehouse => {
    const bannerOption = bannerToOption(warehouse, originalSelectedOptions)
    options.push(bannerOption)
  })
  delete warehouseGroups.undefined

  for (const [franchisorId, franchisorWarehouses] of Object.entries(warehouseGroups)) {
    const children = franchisorWarehouses.map(warehouse =>
      bannerToOption(warehouse, originalSelectedOptions)
    )
    const warehouseGroupOption: Option = {
      id: franchisorId,
      name: franchisorWarehouses[0].warehouseGroup?.franchisorName,
      selected: false,
      type: 'warehouseGroup',
      children: children,
      leafNode: !children?.length,
      breadcrumbs: [],
    }

    options.push(warehouseGroupOption)
  }

  options.sort((a, b) => a.name.localeCompare(b.name))

  return options
  // return [...options.filter(e => e.selected), ...options.filter(e => !e.selected)]
}

export const groupBannersBySites = (
  storeConfigs: InstacartStoreConfiguration[],
  availableWarehouses: WarehouseWithFeatures[],
  originalSelectedOptions: Set<string>
) => {
  const options: Option[] = []
  storeConfigs?.forEach(storeConfig => {
    const filteredWarehouses = availableWarehouses?.filter(warehouse =>
      storeConfig.whitelistedWarehouseIds.includes(warehouse.id.toString())
    )

    const bannersByWarehouseGroup = groupBannersByWarehouseGroup(
      filteredWarehouses,
      originalSelectedOptions
    )
    const siteOption = storeConfigToOption(
      storeConfig,
      bannersByWarehouseGroup,
      originalSelectedOptions
    )
    bannersByWarehouseGroup.forEach(banner => {
      banner.breadcrumbs = [siteOption]
      const breadcumbsIds = [...banner.breadcrumbs, banner].map(e => e.id).join(':')
      banner.selected = originalSelectedOptions.has(breadcumbsIds)
    })

    siteOption.children = [
      ...bannersByWarehouseGroup.filter(e => e.selected),
      ...bannersByWarehouseGroup.filter(e => !e.selected),
    ]

    options.push(siteOption)
  })

  // sort by name
  options.sort((a, b) => a.name.localeCompare(b.name))
  return options
  // return [...options.filter(e => e.id === '1'), ...options.filter(e => e.id !== '1')]
}

export const getLeafNodes = (options: Option[]) => {
  const leafNodes: Option[] = []
  options.forEach(option => {
    if (option.leafNode) {
      leafNodes.push(option)
    } else {
      leafNodes.push(...getLeafNodes(option.children ?? []))
    }
  })
  return leafNodes
}

export const getKey = (option: Option) =>
  [...option.breadcrumbs, option].map(e => e.type + '-' + e.id).join(':')

export const isSelected = (option: Option, selectedOptions: Set<string>) =>
  selectedOptions.has(getKey(option))

export const normalizeStoreConfigurations = (
  storeConfigurations: InstacartStoreConfiguration[],
  storeConfigOperationIntent: SiteOperationIntent
): StoreConfigurationForSelection[] =>
  storeConfigurations?.map(s => {
    const disabled = storeConfigOperationIntent === 'modify' && !s.userAccessToModify

    return {
      label: '',
      value: '',
      logoUrl: '',
      retailerId: '',
      domainName: '',
      storeConfig: s,
      selectOption: disabled ? SiteSelectOption.SELECT_NOT_APPLICABLE : SiteSelectOption.ALLOW,
    }
  }) || []
