const PARTNER_BASE_URL = '/partners'
const PARTNER_SCOPED_URL = `${PARTNER_BASE_URL}/:partner_id`
const RETAILER_SCOPED_URL = `${PARTNER_SCOPED_URL}/warehouses/:warehouse_id`
const SETTINGS_URL = `${RETAILER_SCOPED_URL}/settings`
const GENERATED_REPORTS_URL = `${RETAILER_SCOPED_URL}/generated-reports`
const STORE_GROUPS_URL = `${RETAILER_SCOPED_URL}/store-groups`

// Note that this should not be modified
// It is hardcoded in the stripe admin panel
// If it needs to be updated, we need someone to go modify the list of valid
// redirect URIs in stripe first.
export const REGISTERED_STRIPE_REDIRECT_URI = 'https://retailers.instacart.com/stripe'

export const CATALOG_BASE_URL = `${RETAILER_SCOPED_URL}/catalog`
export const DEPARTMENTS_URL = `${RETAILER_SCOPED_URL}/catalog/departments`
export const NEW_DEPARTMENT_URL = `${RETAILER_SCOPED_URL}/departments/new`
export const EDIT_DEPARTMENT_URL = `${RETAILER_SCOPED_URL}/departments/:department_id/edit`
export const DEPARTMENT_URL = `${RETAILER_SCOPED_URL}/catalog/departments/:department_id/aisles`
export const NEW_AISLE_URL = `${RETAILER_SCOPED_URL}/catalog/departments/:department_id/aisles/new-aisle`
export const EDIT_AISLE_URL = `${RETAILER_SCOPED_URL}/catalog/departments/:department_id/aisles/:aisle_id/edit`
export const AISLE_URL = `${RETAILER_SCOPED_URL}/catalog/departments/:department_id/aisles/:aisle_id`

export const PRODUCTS_URL = `${RETAILER_SCOPED_URL}/catalog/products`
export const PRODUCT_URL = `${PRODUCTS_URL}/:product_id`

export const PRODUCT_EDIT_URL = `${PRODUCT_URL}/edit`
export const PRODUCT_EDIT_DETAILS_URL = `${PRODUCT_EDIT_URL}/details`
export const PRODUCT_EDIT_LOCATIONS_URL = `${PRODUCT_EDIT_URL}/stores`
export const PRODUCT_EDIT_AISLES_URL = `${PRODUCT_EDIT_URL}/aisles`
export const PRODUCT_REQUEST_NEW_URL = `${RETAILER_SCOPED_URL}/catalog/products/new/request`

export const SEARCH_BASE_URL = `${RETAILER_SCOPED_URL}/catalog/search`
export const NEW_SEARCH_URL = `${RETAILER_SCOPED_URL}/catalog/search/new`
export const SEARCH_RESULTS_URL = `${RETAILER_SCOPED_URL}/catalog/search/results`
export const ORDER_DELIVERY_URL = `${RETAILER_SCOPED_URL}/orders/delivery`
export const ORDER_PICKUP_URL = `${RETAILER_SCOPED_URL}/orders/pickup`
export const ORDER_SCAN_AND_PAY_URL = `${RETAILER_SCOPED_URL}/orders/scan-and-pay`
export const ORDER_URL = `${RETAILER_SCOPED_URL}/orders/:order_delivery_id`
export const REPORTS_BASE_URL = `${RETAILER_SCOPED_URL}/reports`
export const SUBSCRIPTIONS_URL = `${RETAILER_SCOPED_URL}/reports/subscriptions`
export const TRANSACTIONS_URL = `${RETAILER_SCOPED_URL}/reports/transactions`
export const INVENTORY_FILES_URL = `${RETAILER_SCOPED_URL}/reports/files`
export const TLOGS_URL = `${RETAILER_SCOPED_URL}/reports/tlogs`
export const ALCOHOL_TLOGS_URL = `${RETAILER_SCOPED_URL}/reports/alcohol-tlogs`
export const PICKUP_ANALYTICS_URL = `${RETAILER_SCOPED_URL}/reports/pickup-analytics`
export const SETTINGS_STRIPE_URL = `${SETTINGS_URL}/stripe`
export const SETTINGS_STORE_HOURS_URL = `${SETTINGS_URL}/store-hours`
export const ADMIN_BASE_URL = `${RETAILER_SCOPED_URL}/admin`
export const NEW_USER_URL = `${RETAILER_SCOPED_URL}/admin/users/new`
export const EDIT_USER_URL = `${RETAILER_SCOPED_URL}/admin/users/:uuid/edit`

// Generated Reports
export const GENERATED_REPORTS_ADD_URL = `${GENERATED_REPORTS_URL}/add`

// Store Groups
export const STORE_GROUPS_ADD_URL = `${STORE_GROUPS_URL}/add`
export const STORE_GROUPS_EDIT_URL = `${STORE_GROUPS_URL}/:storeGroupId/edit`

export const LEGACY_ROUTE_URLS = {
  catalog: CATALOG_BASE_URL,
  aisles: DEPARTMENT_URL,
  'new-aisle': NEW_AISLE_URL,
  'edit-aisle': EDIT_AISLE_URL,
  aisle: AISLE_URL,
  departments: DEPARTMENTS_URL,
  'new-department': NEW_DEPARTMENT_URL,
  'edit-department': EDIT_DEPARTMENT_URL,
  'new-product-request': PRODUCT_REQUEST_NEW_URL,
  product: PRODUCT_URL,
  editProduct: PRODUCT_EDIT_URL,
  search: SEARCH_BASE_URL,
  'new-search': NEW_SEARCH_URL,
  'search-results': SEARCH_RESULTS_URL,
  delivery: ORDER_DELIVERY_URL,
  pickup: ORDER_PICKUP_URL,
  scanandpay: ORDER_SCAN_AND_PAY_URL,
  order: ORDER_URL,
  reports: REPORTS_BASE_URL,
  subscriptions: SUBSCRIPTIONS_URL,
  transactions: TRANSACTIONS_URL,
  files: INVENTORY_FILES_URL,
  tlogs: TLOGS_URL,
  'alcohol-tlogs': ALCOHOL_TLOGS_URL,
  pickupAnalytics: PICKUP_ANALYTICS_URL,
  stripeSettings: SETTINGS_STRIPE_URL,
  storeHoursSettings: SETTINGS_STORE_HOURS_URL,
  admin: ADMIN_BASE_URL,
  'new-user': NEW_USER_URL,
  'edit-user': EDIT_USER_URL,
  'generated-reports-add': GENERATED_REPORTS_ADD_URL,
  productEditDetails: PRODUCT_EDIT_DETAILS_URL,
  productEditAisles: PRODUCT_EDIT_AISLES_URL,
  productEditLocations: PRODUCT_EDIT_LOCATIONS_URL,
  'store-groups': STORE_GROUPS_URL,
  'store-groups-add': STORE_GROUPS_ADD_URL,
  'store-groups-edit': STORE_GROUPS_EDIT_URL,
}
