import {
  optionsArrayToInFilterSelection,
  stringToInFilterSelection,
} from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'state',
    'WAREHOUSE_LOCATION_STATE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('region', 'REGION', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'orderSource',
    'ORDER_SOURCE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'storeLocation',
    'WAREHOUSE_LOCATION',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'storeLocationCode',
    'WAREHOUSE_LOCATION_CODE',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'storeGroup',
    'WAREHOUSE_LOCATION_GROUP',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'fulfillmentType',
    'FULFILLMENT_TYPE',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'alcohol',
    'ALCOHOL_DELIVERY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L1Category',
    'L1_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L2Category',
    'L2_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L3Category',
    'L3_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L4Category',
    'L4_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L5Category',
    'L5_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig(
    'L6Category',
    'L6_CATEGORY',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig('express', 'EXPRESS', optionsArrayToInFilterSelection('stringValue')),

  createFilterParamConfig(
    'convenience',
    'VIRTUAL_WAREHOUSE',
    optionsArrayToInFilterSelection('stringValue')
  ),

  createFilterParamConfig('loyalty', 'LOYALTY', optionsArrayToInFilterSelection('stringValue')),

  createFilterParamConfig('ebt', 'EBT_DELIVERY', optionsArrayToInFilterSelection('stringValue')),

  createFilterParamConfig(
    'firstOrder',
    'FIRST_ORDER',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'partnerDivision',
    'PARTNER_GEO_DIVISION',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'orderCurrency',
    'ORDER_CURRENCY',
    stringToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('rxOrder', 'RX_ORDER', optionsArrayToInFilterSelection('stringValue')),
]

export const orderItemsSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const transformFilterSelectionsToOrderItemsSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)

export const transformOrderItemsApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  WAREHOUSE_LOCATION: 'stringValue',
  WAREHOUSE_LOCATION_CODE: 'stringValue',
  ORDER_SOURCE: 'stringValue',
  REGION: 'stringValue',
  WAREHOUSE_LOCATION_STATE: 'stringValue',
  WAREHOUSE_LOCATION_GROUP: 'stringValue',
  PICKUP: 'stringValue',
  ALCOHOL_DELIVERY: 'stringValue',
  L1_CATEGORY: 'stringValue',
  L2_CATEGORY: 'stringValue',
  L3_CATEGORY: 'stringValue',
  L4_CATEGORY: 'stringValue',
  L5_CATEGORY: 'stringValue',
  L6_CATEGORY: 'stringValue',
  EXPRESS: 'stringValue',
  VIRTUAL_WAREHOUSE: 'stringValue',
  LOYALTY: 'stringValue',
  EBT_DELIVERY: 'stringValue',
  FIRST_ORDER: 'stringValue',
  PARTNER_GEO_DIVISION: 'stringValue',
  ORDER_CURRENCY: 'stringValue',
  RX_ORDER: 'stringValue',
} as const
