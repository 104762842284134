import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PUBLIC,
} from '@retailer-platform/dashboard/routing'

const URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT = `${URL_APP_ADMIN}/feature-entitlements-management`
const URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT_ENTITLEMENTS = `${URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT}/entitlements`
const URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT_PACKAGES = `${URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT}/packages`
const URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT_RETAILERS = `${URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT}/retailers`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-feature-entitlements-management'>
  | DashRouteType<'app-admin-feature-entitlements-management-entitlements'>
  | DashRouteType<'app-admin-feature-entitlements-management-packages'>
  | DashRouteType<'app-admin-feature-entitlements-management-retailers'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-feature-entitlements-management': URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT,
  'app-admin-feature-entitlements-management-entitlements':
    URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT_ENTITLEMENTS,
  'app-admin-feature-entitlements-management-packages':
    URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT_PACKAGES,
  'app-admin-feature-entitlements-management-retailers':
    URL_ADMIN_FEATURE_ENTITLEMENTS_MANAGEMENT_RETAILERS,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
