import { type Styles } from 'react-select/src/styles'
import { type Theme } from 'react-select/src/types'
import { borderRadius } from '../../../foundation/borderRadius'
import { colors } from '../../../foundation/colors'
import { spacing } from '../../../foundation/spacing'

export const SELECT_HEIGHT = 32
// Needs to be >10, which is the current `TableLoadingContainer` z-index
export const SELECT_Z_INDEX = 50
export const SELECT_OPTION_HEIGHT = SELECT_HEIGHT - 4
// removing 2 pixels from the height to account for 1px border on top and bottom
export const SELECT_INDICATOR_HEIGHT = SELECT_HEIGHT - 2

export const selectBaseStyles: Styles = {
  control: (base, state) => ({
    ...base,
    minHeight: SELECT_HEIGHT,
    borderRadius: borderRadius.X8,
    cursor: 'pointer',
    flex: 1,
    boxSizing: 'border-box',
  }),
  container: (base, state) => ({
    ...base,
    minHeight: SELECT_HEIGHT,
    width: '100%',
    fontSize: 12,
  }),
  input: (base, state) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: SELECT_INDICATOR_HEIGHT,
    minHeight: SELECT_INDICATOR_HEIGHT,
    margin: 'auto',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: 'none',
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: 2,
    marginBottom: 0,
    left: 0,
    zIndex: SELECT_Z_INDEX,
  }),
  option: (base, state) => ({
    ...base,
    height: SELECT_OPTION_HEIGHT,
    cursor: 'pointer',
  }),
  singleValue: (base, state) => ({
    ...base,
    height: SELECT_OPTION_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  }),
  group: (base, state) => ({
    ...base,
    paddingTop: spacing.X16,
    paddingBottom: spacing.X4,
  }),
  groupHeading: (base, state) => ({
    ...base,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  placeholder: (base, state) => ({
    ...base,
  }),
  multiValue: (base, state) => ({
    ...base,
  }),
}

export const makeSelectBaseTheme = (base: Theme): Theme => ({
  ...base,
  colors: {
    ...base.colors,
    primary: colors.SUCCESS.REGULAR,
    primary75: colors.SUCCESS.DARK,
    primary50: colors.SUCCESS.REGULAR,
    primary25: colors.SUCCESS.LIGHT,
    danger: colors.DETRIMENTAL.REGULAR,
    dangerLight: colors.DETRIMENTAL.LIGHT,
    neutral30: colors.GRAYSCALE.X30,
    neutral60: colors.GRAYSCALE.X50,
    neutral80: colors.GRAYSCALE.X70,
  },
})
