import { transformFilterParamsToApiFilterSelectionsFactory } from '../utils/insightsPortalSchemas.utils'
import {
  createSchemaConfig,
  buildTimeIntervalGroupBysConfig,
  buildTimeIntervalDimensionsConfig,
} from '../helpers/schema.helpers'
import {
  InsightsFormatterType,
  InsightsGroupByTypes,
  InsightsMetricDesiredDirection,
} from '../types/schema.types'
import { GroupBys, Metrics, Dimensions } from './insightsPortalAggWarehousePerformance.types'
import { aggPerformanceSchemaFilterParamsConfig } from './insightsPortalAggWarehousePerformanceSummary.hooks'

export { GroupBys, Metrics, Dimensions }
export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  metricsConfig: {
    [Metrics.SUM_GMV]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.performanceDashboard.metrics.SUM_GMV',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.SUM_GMV',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.COUNT_ORDER]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_ORDER',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.COUNT_USER',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.COUNT_FIRST_ORDER]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.performanceDashboard.metrics.COUNT_FIRST_ORDER',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.COUNT_FIRST_ORDER',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.AVG_BASKET]: {
      formatter: InsightsFormatterType.Currency,
      label: 'insightsPortalDomain.performanceDashboard.metrics.AVG_GMV',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.AVG_GMV',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.AVG_RATING]: {
      formatter: InsightsFormatterType.Decimal,
      label: 'insightsPortalDomain.performanceDashboard.metrics.AVG_RATING',
      tooltip: 'insightsPortalDomain.performanceDashboard.tooltips.AVG_RATING',
      desiredDirection: InsightsMetricDesiredDirection.POSITIVE,
    },
    [Metrics.AVG_ORDERED_ITEM]: {
      formatter: InsightsFormatterType.Integer,
    },
    [Metrics.AVG_ITEM_FOUND]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.SEARCH_CONVERSION]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.AVG_ITEM_FILLED]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.IMAGE_COVERAGE]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_UNATA_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_CONNECT_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_MARKETPLACE_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_PBI_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_LMD_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_DELIVERY_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_EBT_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_CONVENIENCE_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_ALCOHOL_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_EXPRESS_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_LOYALTY_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_FIRST_ORDER_GMV]: {
      formatter: InsightsFormatterType.Percentage,
    },
    [Metrics.RATIO_ORDER_WITH_RATING]: {
      formatter: InsightsFormatterType.Percentage,
    },
  },
  dimensionsConfig: {
    [Dimensions.ORDER_CURRENCY]: {
      label: 'insightsPortalDomain.filters.orderCurrency.label',
    },
    [Dimensions.PARTNER_GEO_DIVISION]: {
      label: 'insightsPortalDomain.filters.partnerDivision.label',
    },
    [Dimensions.REGION]: {
      label: 'insightsPortalDomain.filters.region.label',
    },
    [Dimensions.WAREHOUSE]: {
      label: 'insightsPortalDomain.filters.retailers.label',
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      label: 'insightsPortalDomain.filters.stores',
    },
    [Dimensions.WAREHOUSE_LOCATION_CODE]: {
      label: 'insightsPortalDomain.filters.storeLocationCode.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_GROUP]: {
      label: 'insightsPortalDomain.filters.storeGroup.label',
    },
    [Dimensions.WAREHOUSE_LOCATION_STATE]: {
      label: 'insightsPortalDomain.filters.state.label',
    },
    ...buildTimeIntervalDimensionsConfig,
  },

  // TODO: deprecate
  filterConfigs: aggPerformanceSchemaFilterParamsConfig,
  transformFilterParamsToApiFilterSelections: transformFilterParamsToApiFilterSelectionsFactory(
    aggPerformanceSchemaFilterParamsConfig
  ),
  groupBys: GroupBys,
  groupBysConfig: {
    [GroupBys.WAREHOUSE_LOCATION]: {},
    [GroupBys.WAREHOUSE_LOCATION_STATE]: {},
    [GroupBys.PARTNER_GEO_DIVISION]: {},
    [GroupBys.WAREHOUSE_LOCATION_GROUP]: {},
    [GroupBys.REGION]: {},
    ...buildTimeIntervalGroupBysConfig,
  },
})
