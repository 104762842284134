import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

import { ChevronLeftIcon, ChevronRightIcon } from '@instacart/ids-core'

const MAX_WEEK_OFFSET = 3
const MIN_WEEK_OFFSET = 0

interface ContainerProps {
  inputHeight: React.CSSProperties['height']
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: 200px;
  height: ${props => props.inputHeight};
  border-radius: 8px;
  background-color: #f6f7f8;
  padding: 8px 10px;
  text-align: center;
  display: flex;
  align-items: center;
`
const LeftIcon = styled.span`
  float: left;
`
const RightIcon = styled.span`
  float: right;
`
const Label = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: #343538;
  flex: 1;
`
const ClickTargetLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
`
const ClickTargetRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  width: 35px;
`

interface Props {
  week: string
  updateQueryWeek: (week: string) => void
  inputHeight?: React.CSSProperties['height']
  minWeekOffset?: number
  maxWeekOffset?: number
}

interface State {
  weekOffset: number
}

export class WeekPicker extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    const currentWeek = moment().startOf('isoWeek')
    const queryWeek = moment(props.week)
    const weekOffset = queryWeek.diff(currentWeek, 'weeks')

    this.state = {
      weekOffset,
    }
  }

  static defaultProps = {
    inputHeight: '35px',
    minWeekOffset: MIN_WEEK_OFFSET,
    maxWeekOffset: MAX_WEEK_OFFSET,
  }

  navigateLeft() {
    const { weekOffset } = this.state
    const { updateQueryWeek, minWeekOffset, maxWeekOffset } = this.props

    if (weekOffset <= minWeekOffset) {
      return
    }

    const newWeekOffset = weekOffset > maxWeekOffset ? maxWeekOffset : weekOffset - 1

    this.setState({
      weekOffset: newWeekOffset,
    })

    updateQueryWeek(moment().startOf('isoWeek').add(newWeekOffset, 'w').format('YYYY-MM-DD'))
  }

  navigateRight() {
    const { weekOffset } = this.state
    const { updateQueryWeek, maxWeekOffset, minWeekOffset } = this.props

    if (weekOffset >= maxWeekOffset) {
      return
    }

    const newWeekOffset = weekOffset < minWeekOffset ? minWeekOffset : weekOffset + 1

    this.setState({
      weekOffset: newWeekOffset,
    })

    updateQueryWeek(moment().startOf('isoWeek').add(newWeekOffset, 'w').format('YYYY-MM-DD'))
  }

  render() {
    const { weekOffset } = this.state
    const { week, inputHeight, minWeekOffset, maxWeekOffset } = this.props
    const startOfWeek = moment(week)
    const endOfWeek = moment(week).add(6, 'd')
    const label = `${startOfWeek.format('D MMM')} – ${endOfWeek.format('D MMM')}`

    const canGoLeft = weekOffset > minWeekOffset
    const canGoRight = weekOffset < maxWeekOffset
    return (
      <Container inputHeight={inputHeight}>
        <ClickTargetLeft
          onClick={() => this.navigateLeft()}
          style={{ cursor: canGoLeft ? 'pointer' : 'default' }}
        />
        <LeftIcon>
          <ChevronLeftIcon
            size={20}
            color={canGoLeft ? 'systemGrayscale70' : 'systemGrayscale30'}
          />
        </LeftIcon>
        <Label>{label}</Label>
        <RightIcon>
          <ChevronRightIcon
            size={20}
            color={canGoRight ? 'systemGrayscale70' : 'systemGrayscale30'}
          />
        </RightIcon>
        <ClickTargetRight
          onClick={() => this.navigateRight()}
          style={{ cursor: canGoRight ? 'pointer' : 'default' }}
        />
      </Container>
    )
  }
}
