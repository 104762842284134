import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const webhookConfigurationAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.webhookConfiguration],
  permissions: [Permission.DevPortal],
}

export const webhookConfigurationModifyAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.webhookConfiguration],
  permissions: [Permission.DevPortalAdmin],
}
