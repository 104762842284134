import { useLayoutEffect, useState } from 'react'

export const useWindowSizeIsWide = () => {
  const [isWide, setIsWide] = useState(true)
  useLayoutEffect(() => {
    const updateSize = () => {
      const newIsWide = window.innerWidth >= 1024
      setIsWide(newIsWide)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [isWide, setIsWide])
  return isWide
}
