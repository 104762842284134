import React, { type FunctionComponent } from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { AuthenticationDomainRedirect } from '@retailer-platform/domain-authentication'
import { DashSwitch } from '../../../legacy/dash-components/dash-switch/DashSwitch'
import { DashRoute } from '../../../legacy/dash-components/dash-route/DashRoute'
import { DashRedirect } from '../../../legacy/dash-components/dash-redirect/DashRedirect'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'
import { AdminSection } from '../../../sections/admin/AdminSection'
import { DASH_ROUTE_URLS } from '../../../utils/routing/routes'
import { HaltSection } from '../../../sections/halt/HaltSection'
import { AppAdminSectionAccessControl } from '../../../sections/admin/AdminSection.configuration'
import { CurrentAccountProvider } from '../../../utils/current-account/CurrentAccountProvider'
import {
  consumeStoredRedirectUrl,
  storeCurrentRedirectUrl,
} from '../../logged-in/utils/loggedIn.utils'

export const AppRoutes: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { loading, authorized, actionRequired } = useCurrentAccountDetails()
  const haltAccountActionRequiredMatch = useRouteMatch(
    DASH_ROUTE_URLS['app-halt-account-action-required']
  )

  // dev portal no longer exists, so redirect to where they can find their tools
  const location = useLocation()
  const history = useHistory()
  if (location.pathname.indexOf('app/dev-portal') !== -1) {
    history.push(location.pathname.replace('app/dev-portal', 'partners'))
    return null
  }

  if (loading) {
    return null
  }

  // If not authorized, return them to the landing page so they can log in
  if (!authorized) {
    storeCurrentRedirectUrl()
    return <AuthenticationDomainRedirect toRoute="app-public-authentication-log-in" />
  } else {
    // If we're logged in, and there was a stored redirect, we consume it
    const target = consumeStoredRedirectUrl()

    // If anything was returned from above, we redirect to it
    if (target) return <DashRedirect to={target} />
  }

  // If there is some setup still required, block them from accessing the core app
  // We want to redirect them if they aren't already on the action-required page
  if (!actionRequired && !haltAccountActionRequiredMatch) {
    return <DashRedirect toRoute="app-halt-account-action-required" />
  }

  return (
    <CurrentAccountProvider>
      <DashSwitch>
        <DashRoute route="app-halt" component={HaltSection} />

        <DashRoute
          route="app-admin"
          component={AdminSection}
          accessControl={AppAdminSectionAccessControl}
        />

        <DashRedirect toRoute="app-halt-redirect" />
      </DashSwitch>
    </CurrentAccountProvider>
  )
}
