import React, { useMemo, type ReactElement, type FunctionComponent, cloneElement } from 'react'
import { usePartnerContext, useRetailerId } from '@retailer-platform/dashboard/routing'
import { type NavItemProps } from '@retailer-platform/dashboard/gin-and-tonic'
import { useDomainGoToPath } from '../utils/domain/routing'
import { type DomainRouteName } from './routes'

type InsightsNavItemProps = Omit<NavItemProps, 'route'> & {
  route: DomainRouteName
}

interface WrappedComponentProps {
  children: ReactElement<InsightsNavItemProps>
}

export const InsightsPortalWarehouseContextFilterNavWrapper: FunctionComponent<
  React.PropsWithChildren<
    WrappedComponentProps & {
      route: DomainRouteName
    }
  >
> = ({ children, route }) => {
  const partnerContext = usePartnerContext()
  const currentRetailerId = useRetailerId({ throwOnMissing: false })
  const currentWarehouseLabel = useMemo(
    () => partnerContext?.warehouses?.find(warehouse => warehouse.id === +currentRetailerId)?.name,
    [partnerContext, currentRetailerId]
  )

  const filtersObj = useMemo(
    () =>
      !currentRetailerId || +currentRetailerId <= 0
        ? undefined
        : {
            retailer: JSON.stringify([{ value: currentRetailerId, label: currentWarehouseLabel }]),
            WAREHOUSE: JSON.stringify([{ value: currentRetailerId, label: currentWarehouseLabel }]),
          },
    [currentRetailerId, currentWarehouseLabel]
  )

  const goToPageWithContextAsFilter = useDomainGoToPath(
    route,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { warehouse_id: 0 },
    'push',
    filtersObj
  )

  return (
    <div
      onClick={() => {
        goToPageWithContextAsFilter()
      }}
    >
      {cloneElement(children, { ...children.props })}
    </div>
  )
}
