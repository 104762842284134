import React, { type FunctionComponent } from 'react'
import { DashSwitch } from '../../legacy/dash-components/dash-switch/DashSwitch'
import { DashRoute } from '../../legacy/dash-components/dash-route/DashRoute'
import { DashRedirect } from '../../legacy/dash-components/dash-redirect/DashRedirect'
import { LogOut } from '../../gin-and-tonic/containers/log-out/LogOut'
import { PublicRoutes } from './public/PublicRoutes'
import { AppRoutes } from './app/AppRoutes'

export const RootRoutes: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <DashSwitch>
    <DashRoute exact route="log-out" component={LogOut} />
    <DashRoute route="app" component={AppRoutes} />
    <DashRoute route="public" component={PublicRoutes} />
    <DashRedirect toRoute="public" />
  </DashSwitch>
)
