import React, { type FunctionComponent, type CSSProperties } from 'react'
import styled from '@emotion/styled'
import { Box, type BoxProps } from '../box/Box'
import {
  type FlexDirection,
  type FlexWrap,
  type RestrictedFlexProps,
  type FlexAlign,
  type FlexJustify,
} from './utils/flex.types'

type FlexBoxProps = Pick<CSSProperties, 'flexGrow' | 'flexShrink' | 'flexBasis'> &
  BoxProps & {
    flexDirection: FlexDirection
    flexWrap: FlexWrap
    flexJustify: FlexJustify
    flexAlign: FlexAlign
  }

const FlexBox = styled(Box)<FlexBoxProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ flexJustify }) => flexJustify};
  align-items: ${({ flexAlign }) => flexAlign};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-basis: ${({ flexBasis }) => flexBasis};
`

export interface FlexProps extends Partial<RestrictedFlexProps>, BoxProps {}

export const Flex: FunctionComponent<React.PropsWithChildren<FlexProps>> = ({
  children,
  direction,
  wrap,
  justify,
  align,
  grow,
  shrink,
  basis,
  ...rest
}) => {
  const flexDirection = direction || 'row'
  const flexWrap = wrap || 'wrap'
  const flexJustify = justify || 'flex-start'
  const flexAlign = align || 'flex-start'
  const flexGrow = grow || 0
  const flexShrink = shrink || 1
  const flexBasis = basis || 'auto'

  return (
    <FlexBox
      {...rest}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      flexJustify={flexJustify}
      flexAlign={flexAlign}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      flexBasis={flexBasis}
    >
      {children}
    </FlexBox>
  )
}
