import React, { type FunctionComponent } from 'react'
import { Route } from 'react-router'
import { DashSwitch } from '../legacy/dash-components/dash-switch/DashSwitch'
import { DashRoute } from '../legacy/dash-components/dash-route/DashRoute'
import { DashRedirect } from '../legacy/dash-components/dash-redirect/DashRedirect'
import { RootRoutes } from './root/RootRoutes'
import { BaseRoutes } from './BaseRoutes'

interface Props {}

export const AppRouter: FunctionComponent<React.PropsWithChildren<Props>> = () => (
  <DashSwitch>
    <DashRoute route="log-out" component={RootRoutes} />
    <DashRoute route="app" component={RootRoutes} />
    <DashRoute route="public" component={RootRoutes} />

    {/* These are handlers for our legacy urls */}
    <DashRedirect fromRoute="logout" toRoute="log-out" />

    <Route component={BaseRoutes} />
  </DashSwitch>
)
