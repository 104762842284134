import React from 'react'
import { Center, Modal, ModalBody, ModalHeader, Text } from '@retailer-platform/shared-components'
import { useDashMessages } from '../../../intl/intl.hooks'
import { useAdminControls } from '../../../utils/contexts/admin-controls/AdminControlsContext'
import { ForceCurrentICPlatformVersion } from './controls/ForceCurrentICPlatformVersion'
import { ShowNavAccessControl } from './controls/ShowNavAccessControl'
import { AccountDetails } from './controls/AccountDetails'
import { ShowContactHeader } from './controls/ShowContactHeader'

export interface AdminControlsModalProps {}

export const AdminControlModal = () => {
  const i18n = useDashMessages({
    title: 'app.admin.controlPanel.title',
    footer: 'app.admin.controlPanel.footer',
  })

  const adminControlsContext = useAdminControls()

  return (
    <Modal
      isOpen={adminControlsContext.isAdminControlsOpen}
      onRequestClose={adminControlsContext.closeAdminControls}
      maxHeight={'unset'}
    >
      <ModalHeader>{i18n.title}</ModalHeader>

      <ModalBody>
        <AccountDetails />
        <ShowNavAccessControl />
        <ShowContactHeader />
        <ForceCurrentICPlatformVersion />
      </ModalBody>

      <ModalBody>
        <Center fillParent>
          <Text size="small">{i18n.footer}</Text>
        </Center>
      </ModalBody>
    </Modal>
  )
}
