import React, { type FunctionComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import { GlobalStyles } from '@retailer-platform/shared-components'
import { spacing } from '../../../foundation/spacing'

const Root = styled.div({
  minWidth: '100vw',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 1024,
  marginTop: spacing.X0,
  marginBottom: spacing.X0,
  marginLeft: 'auto',
  marginRight: 'auto',
})

interface Props {
  className?: string
}

export const AppProviderStyling: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  children,
}) => (
  <Fragment>
    <GlobalStyles />

    <Root id="AppProviderStyling" className={className}>
      {children}
    </Root>
  </Fragment>
)
