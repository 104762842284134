const EN_US = {
  'yodaRulesDomain.title': 'Yoda Rules',

  'yodaRulesDomain.approvalRequest.showPage.title': 'Approval Request {id}',
  'yodaRulesDomain.approvalRequest.showPage.unsupportedContentType':
    'Content type {contentType} not supported.',

  'yodaRulesDomain.approvalRequest.details.createdAtTitle': 'Created at',
  'yodaRulesDomain.approvalRequest.details.changeTypeTitle': 'Change type',

  'yodaRules.approvalRequest.publishedStatusMessage':
    'The approval request has been approved and published.',
  'yodaRules.approvalRequest.cancelledStatusMessage': 'The approval request has been cancelled.',
} as const

export default EN_US
