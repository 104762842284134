/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef } from 'react'
import useDeepEffect from '../../legacy/common/react/hooks/useDeepEffect'
import { type TrackedEvent, trackEvent } from './trackEvent'

export const useTrackEvent: typeof trackEvent = (trackedEvent: TrackedEvent): void => {
  useDeepEffect(() => trackEvent(trackedEvent), [trackedEvent])
}

/**
 * A hook for measuring timings and sending them over to amplitude.
 * This will return a function that signals the end of the measurement, as well as it sends
 * the data over the amplitude in a format like
 *
 * { elapsed: 10012.32 }
 *
 * Keep in mind you must call the returned trigger function when ready to measure, and it won't
 * do anything by itself.
 * The trigger function also accepts additional data as an object.
 *
 * This hook accepts a dependencies array like `useMemo` which will tell the hook
 * to start a new measurement
 *
 * @param param0
 * @param dependencies
 * @returns
 */
export const useMeasureTiming = (
  { description, id }: { id: string; description: string },
  dependencies: any[]
) => {
  const alreadyTriggeredRef = useRef(false)

  // Every time dependencies change, we will snapshot a new start time
  // You can use an empty array to only execute this once, ever.
  const start = useMemo(
    () => performance.now(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  )

  // Every time the start time changes, we reset the triggered reference
  useEffect(() => {
    alreadyTriggeredRef.current = false
  }, [start])

  return useCallback(
    (data?: Record<string, any>) => {
      // Can only be triggered once per dependency change.
      if (alreadyTriggeredRef.current) return

      trackEvent({ id, description, data: { ...data, elapsed: performance.now() - start } })

      alreadyTriggeredRef.current = true
    },
    [description, alreadyTriggeredRef, id, start]
  )
}
