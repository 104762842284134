import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'

const PartnerSignupRegisterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PartnerSignupRegisterPage" */
      '../pages/register/PartnerSignupRegisterPage'
    )
)
const PartnerOnboardingSignupPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PartnerOnboardingSignupPage" */
      '../pages/signup/PartnerOnboardingSignupPage'
    )
)
const PartnerOnboardingCompletionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PartnerOnboardingCompletionPage" */
      '../pages/completion/PartnerOnboardingCompletionPage'
    )
)
// @hygen:inject page-import

export const partnerOnboardingPublicRouter = (
  <DomainRoute route="app-public-partner-onboarding">
    <DashSwitch>
      <DomainRoute
        exact
        route="app-public-partner-onboarding-register"
        component={PartnerSignupRegisterPage}
      />
      <DomainRoute
        exact
        route="app-public-partner-onboarding-signup"
        component={PartnerOnboardingSignupPage}
      />
      <DomainRoute
        exact
        route="app-public-partner-onboarding-completion"
        component={PartnerOnboardingCompletionPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
