import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint, NavItem } from '@retailer-platform/dashboard/routing'
import { productsRouter } from './routing/productsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { productsSearchAccessControl } from './access-control/searchAccess.configuration'
import { productsViewItemAnomalyAccessControl } from './access-control/viewAccess.configuration.ts'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'products',
  totem: {
    entity: 'products-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [productsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Catalog,
      attachToV2: NavEntryPoint.Catalog,
      subNavItems: [
        {
          type: 'item',
          labelId: 'productsDomain.search.title',
          route: 'products-search',
          accessControl: productsSearchAccessControl,
          positionNavV2: 0,
        },
      ],
    },
    {
      attachTo: NavItem.Catalog,
      attachToV2: NavEntryPoint.Catalog,
      subNavItems: [
        {
          type: 'item',
          labelId: 'productsDomain.itemAnomaly.reports.title',
          route: 'item-anomaly-reports',
          accessControl: productsViewItemAnomalyAccessControl,
          positionNavV2: 6,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
