import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { colors } from '../../foundation/colors'

interface LoadingDotsContainerProps {
  centered: boolean
}

const LoadingDotsContainer = styled.div<LoadingDotsContainerProps>`
  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .loading-dots {
    animation: load 1s infinite;
    animation-fill-mode: both;
  }

  .loading-dots:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .loading-dots:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  ${({ centered }) =>
    centered &&
    `
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

interface LoadingDotsProps {
  fill?: string
  height?: number
  centered?: boolean
  testId?: string
}

export const LoadingDots: FunctionComponent<React.PropsWithChildren<LoadingDotsProps>> = ({
  fill = colors.GRAYSCALE.X50,
  height = 40,
  centered = false,
  testId = null,
}) => {
  const svgHeight = `${height}px`
  const svgWidth = `${height * 3}px`

  return (
    <LoadingDotsContainer data-testid={testId || 'loading-dots'} centered={centered}>
      <svg
        height={svgHeight}
        width={svgWidth}
        viewBox="0 0 10 2"
        xmlns="http://www.w3.org/2000/svg"
        className="loading-dots-svg"
      >
        <circle className="loading-dots" fill={fill} cx="1" cy="1" r="1" />
        <circle className="loading-dots" fill={fill} cx="5" cy="1" r="1" />
        <circle className="loading-dots" fill={fill} cx="9" cy="1" r="1" />
      </svg>
    </LoadingDotsContainer>
  )
}
