const EN_US = {
  'integrationsDomain.title': 'Integrations',
  'integrationsDomain.list.name': 'Integration Name',

  'integrationsDomain.manage': 'Manage',
  'integrationsDomain.connect': 'Connect',
  'integrationsDomain.disconnect': 'Disconnect',

  'integrationsDomain.list.status': 'Status',
  'integrationsDomain.list.description': 'Description',

  'integrationsDomain.list.callback.success.header': 'Success!',
  'integrationsDomain.list.callback.success.content': 'The integration was added successfully.',

  'integrationsDomain.list.callback.error.header': 'Error adding integration',
  'integrationsDomain.list.callback.error.content':
    'An error occurred while adding this integration. Please try again or contact support.',

  'integrationsDomain.list.status.connected': 'Connected',
  'integrationsDomain.list.status.unknown': 'Unknown',
  'integrationsDomain.list.status.notConnected': 'Not connected',

  'integrationsDomain.integration.generic.connect.authenticationUrlError.header':
    'Could not redirect',
  'integrationsDomain.integration.generic.connect.authenticationUrlError.content':
    'We were not able to redirect you to the integration authentication page. Please try again or contact support.',
  'integrationsDomain.integration.generic.notFoundError.header': 'Integration not found.',
  'integrationsDomain.integration.generic.notFoundError.content':
    "You either don't have access to this integration or it doesn't exist.",
  'integrationsDomain.integration.generic.deleteConfirmationModal.confirm':
    'Disconnect {integrationName}',
  'integrationsDomain.integration.generic.deleteConfirmationModal.cancel': 'Cancel',
  'integrationsDomain.integration.generic.deleteConfirmationModal.header':
    'Are you sure you want to disconnect {integrationName}?',
  'integrationsDomain.integration.generic.deleteConfirmationModal.content':
    'This action is not undoable and any scheduled tasks will not execute anymore. You can re-add this integration at any time.',
  'integrationsDomain.integration.generic.modal.enabled': 'Enabled',
  'integrationsDomain.integration.generic.modal.status': 'Status',
  'integrationsDomain.integration.generic.modal.yes': 'Yes',
  'integrationsDomain.integration.generic.modal.no': 'No',
  'integrationsDomain.integration.generic.modal.selectStoreLocations':
    'Select store locations to sync',
  'integrationsDomain.integration.generic.modal.selectAllStoreLocations': 'Select All',
  'integrationsDomain.integration.generic.modal.deselectAllStoreLocations': 'Deselect All',
  'integrationsDomain.integration.generic.modal.schedule': 'Schedule',
  'integrationsDomain.integration.generic.modal.schedules.nightly': 'Nightly',
  'integrationsDomain.integration.generic.modal.schedules.weekly': 'Weekly',
  'integrationsDomain.integration.generic.modal.save': 'Save',
  'integrationsDomain.integration.generic.modal.error.header': 'An error occurred',
  'integrationsDomain.integration.generic.modal.error.content':
    'Something happened while attempting to update. Please try again or contact support.',
  'integrationsDomain.integration.generic.modal.success.header': 'Updated successfully',
  'integrationsDomain.integration.generic.modal.success.content':
    'The integration was successfully updated.',
  'integrationsDomain.integration.generic.modal.validation.required': 'Schedule is required',
  'integrationsDomain.integration.generic.delete.error.header': 'An error occurred',
  'integrationsDomain.integration.generic.delete.error.content':
    'Something happened while attempting to delete this integration. Please try again or contact support.',
  'integrationsDomain.integration.generic.delete.success.header': 'Removed successfully',
  'integrationsDomain.integration.generic.delete.success.content':
    'The integration was successfully removed.',

  // Integration specific
  'integrationsDomain.integration.generic.square.name': 'Square',
  'integrationsDomain.integration.generic.square.description':
    "Use Square's POS to seamlessly onboard and maintain your catalog of products into Instacart.",

  'integrationsDomain.integration.generic.shopify.name': 'Shopify',
  'integrationsDomain.integration.generic.shopify.description':
    "Use Shopify's POS to seamlessly onboard and maintain your catalog of products into Instacart.",

  'integrationsDomain.integration.generic.lightspeed.name': 'Lightspeed',
  'integrationsDomain.integration.generic.lightspeed.description':
    "Use Lightspeed's POS to seamlessly onboard and maintain your catalog of products into Instacart.",

  'integrationsDomain.integration.generic.clover.name': 'Clover',
  'integrationsDomain.integration.generic.clover.description':
    "Use Clover's POS to seamlessly onboard and maintain your catalog of products into Instacart.",

  'integrationsDomain.integration.generic.googleBusiness.name': 'Google Business',
  'integrationsDomain.integration.generic.googleBusiness.description':
    'Connect your Google Business Account to use your existing photos',

  'integrationsDomain.integration.generic.quickbooks.name': 'QuickBooks',
  'integrationsDomain.integration.generic.quickbooks.description':
    "Use QuickBooks' POS to seamlessly onboard and maintain your catalog of products into Instacart.",

  'integrationsDomain.integration.generic.vendhq.name': 'VendHQ',
  'integrationsDomain.integration.generic.vendhq.description':
    "Use VendHQ's POS to seamlessly onboard and maintain your catalog of products into Instacart.",

  'integrationsDomain.integration.generic.yext.name': 'Yext',
  'integrationsDomain.integration.generic.yext.description':
    'Connect your Yext account to automatically update Instacart with your latest store hours.',
} as const

export default EN_US
