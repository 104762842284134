import React, { type ReactElement, useMemo } from 'react'
import { type SerializedStyles, css } from '@emotion/react'
import { gridCompactHeaderRowStyles, gridHeaderRowStyles } from '../utils/grid.styles'
import { type GridDatum, type GridHeaderGroup } from '../utils/grid.types'
import { GridHeaderColumnCell } from './GridHeaderColumnCell'

interface Props<TDatum extends GridDatum> extends GridHeaderGroup<TDatum> {
  compact?: boolean
}

export const GridHeaderGroupRow = <TDatum extends GridDatum>({
  headers,
  getHeaderGroupProps,
  compact,
}: Props<TDatum>): ReactElement => {
  const renderedCells = headers.map(column => (
    <GridHeaderColumnCell key={column.id} {...column} compact={compact} />
  ))
  const { style, ...headerGroupProps } = getHeaderGroupProps()
  const { key, ...restHeaderGroupProps } = headerGroupProps
  const dynamicStyles = css(
    style as SerializedStyles,
    gridHeaderRowStyles,
    compact && gridCompactHeaderRowStyles
  )

  return (
    <div key={key} {...restHeaderGroupProps} css={dynamicStyles}>
      {renderedCells}
    </div>
  )
}
