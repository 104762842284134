import React from 'react'
import { Alert, Center } from '@retailer-platform/shared-components'
import { type ApolloError } from 'apollo-client'
import { ErrorPage } from '@retailer-platform/dashboard/gin-and-tonic'
import { useDomainMessages } from '../../utils/domain/intl'
import { useDomainApolloErrorMessage } from '../../utils/domain/apollo'

export interface Props {
  error: ApolloError
  refetch: () => void
}

export function ErrorPageAlert(props: Props) {
  return (
    <Center fillParent>
      <ErrorPage action={props.refetch}>
        <ErrorAlert {...props} />
      </ErrorPage>
    </Center>
  )
}

export const ErrorAlert = (props: Omit<Props, 'refetch'>) => {
  const i18n = useDomainMessages({
    error: 'approvalManagementLibrary.general.unexpectedFetchError',
  })

  const errorMessage = useDomainApolloErrorMessage(props.error, {
    default: 'approvalManagementLibrary.general.retryOrContactSupport',
  })

  return (
    <Alert variant="danger">
      <p>{i18n.error}</p> <p>{errorMessage}</p>
    </Alert>
  )
}
