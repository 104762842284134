// These colors are the representation of our brand in our product experiences.
// These colors have purpose, and should only be used for their specific meanings.
// One day, we may plan to make brand colors can be configured by our enterprise retail partners.
// Brand colors are: PRIMARY, SECONDARY, HIGHLIGHT, PROMOTIONAL, LOYALTY
// System colors are: SUCCESS, DETRIMENTAL, GRAYSCALE

export const colors = {
  PRIMARY: {
    REGULAR: '#0AAD0A',
    DARK: '#098A09',
    EXTRA_DARK: '#0C670C',
  },
  PRIMARY_TDS: {
    REGULAR: '#0A5546',
    DARK: '#003D29',
    EXTRA_DARK: '#001810',
    LIGHT: '#ECF6F3',
    EXTRA_LIGHT: '#F5FBF9',
  },
  SECONDARY: {
    REGULAR: '#72767E',
    DARK: '#343538',
    LIGHT: '#F6F7F8',
  },
  HIGHLIGHT: {
    REGULAR: '#2B78C6',
    DARK: '#1F5A96',
    LIGHT: '#F2F8FF',
  },
  PROMOTIONAL: {
    REGULAR: '#D43684',
    DARK: '#A51F61',
    LIGHT: '#FFF5FA',
  },
  LOYALTY: {
    REGULAR: '#E97300',
    DARK: '#BB5C05',
    LIGHT: '#FFF5EB',
  },
  SUCCESS: {
    REGULAR: '#0AAD0A',
    DARK: '#098A09',
    LIGHT: '#E5FFE5',
  },
  DETRIMENTAL: {
    REGULAR: '#DE3534',
    DARK: '#AE1D1C',
    LIGHT: '#FFF6F6',
  },
  GRAYSCALE: {
    X80: '#242529',
    X70: '#343538',
    X60: '#56595E',
    X50: '#72767E',
    X30: '#C7C8CD',
    X20: '#E8E9EB',
    X10: '#F6F7F8',
    X0: '#FFFFFF',
  },
} as const
