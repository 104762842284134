const SHIFTS_EN_US_TRANSLATIONS = {
  'shiftsDomain.title': 'Shifts',
  'shiftsDomain.picking': 'Picking',
  'shiftsDomain.running': 'Running',
  'shiftsDomain.driving': 'Driving',
  'shiftsDomain.recurring': 'Recurring shift',
  'shiftsDomain.fullService': 'Full Service',
  'shiftsDomain.time': 'Time ({zone})',
  'shiftsDomain.add_shopper': 'Add Device',
  'shiftsDomain.add_shopper_empty': 'No more Devices',
  'shiftsDomain.add_shopper_error': 'Error loading Devices',
  'shiftsDomain.instructions': 'Click and drag to create shifts',
  'shiftsDomain.createFailed':
    "We weren't able to create this shift. Please try again, and if the problem persists, contact support",
  'shiftsDomain.updateFailed':
    "We weren't able to update this shift. Please try again, and if the problem persists, contact support",
  'shiftsDomain.destroyFailed':
    "We weren't able to delete this shift. Please try again, and if the problem persists, contact support",
  'shiftsDomain.createFailed.past':
    'Shifts cannot be created in the past. Please try again, and if the problem persists, contact support',
  'shiftsDomain.createFailed.invalidHours':
    'Shifts cannot be created outside of store hours. Please try again, and if the problem persists, contact support',
  'shiftsDomain.invalidShift': 'Invalid shift length - our support team has been notified',
  'shiftsDomain.prompt.setRecurring':
    'Set recurring shift for every {weekday} from {start} - {end}?',
  'shiftsDomain.prompt.setRecurring.set': 'Set',
  'shiftsDomain.prompt.stopRecurring': 'Stop recurring shifts starting {rel} {date}?',
  'shiftsDomain.prompt.stopRecurring.stop': 'Stop',
  'shiftsDomain.prompt.editRecurring': 'This is a recurring shift. Edit this shift or all shifts?',
  'shiftsDomain.prompt.editRecurring.thisShift': 'This Shift',
  'shiftsDomain.prompt.editRecurring.allShifts': 'All Shifts',
  'shiftsDomain.prompt.deleteRecurring':
    'This is a recurring shift. Deleting this shift or all shifts may cause orders to be canceled or rescheduled. Delete this shift or all shifts?',
  'shiftsDomain.prompt.deleteRecurring.thisShift': 'This Shift',
  'shiftsDomain.prompt.deleteRecurring.allShifts': 'All Shifts',
  'shiftsDomain.prompt.deleteShift':
    'Deleting this shift may cause orders to be canceled or rescheduled. Delete this shift?',
  'shiftsDomain.prompt.deleteShift.delete': 'Delete',
  'shiftsDomain.prompt.error': '{message}',
  'shiftsDomain.prompt.errorDismissLabel': 'Ok',
  'shiftsDomain.prompt.shiftGenerationError': 'Some future shifts could not be generated: {errors}',
  'shiftsDomain.prompt.newShift': 'New shift',
  'shiftsDomain.prompt.editShift': 'Shift details',
  'shiftsDomain.prompt.from': 'From',
  'shiftsDomain.prompt.to': 'To',
  'shiftsDomain.prompt.repeatShift': 'Repeat Shift?',
  'shiftsDomain.prompt.doesNotRepeat': 'Does not repeat',
  'shiftsDomain.prompt.repeatsOn': 'Repeats on',
  'shiftsDomain.prompt.weeklyOnDay': 'Weekly on {dayOfWeek}',
  'shiftsDomain.prompt.everyWeekday': 'Every weekday (Mon - Fri)',
  'shiftsDomain.prompt.daily': 'Daily',
  'shiftsDomain.prompt.custom': 'Custom',
  'shiftsDomain.prompt.noEndDate': 'No end date',
  'shiftsDomain.prompt.endsOn': 'Ends on',
  'shiftsDomain.prompt.cancel': 'Cancel',
  'shiftsDomain.prompt.save': 'Save',
  'shiftsDomain.prompt.delete': 'Delete',
  'shiftsDomain.prompt.monday': 'Monday',
  'shiftsDomain.prompt.tuesday': 'Tuesday',
  'shiftsDomain.prompt.wednesday': 'Wednesday',
  'shiftsDomain.prompt.thursday': 'Thursday',
  'shiftsDomain.prompt.friday': 'Friday',
  'shiftsDomain.prompt.saturday': 'Saturday',
  'shiftsDomain.prompt.sunday': 'Sunday',
  'shiftsDomain.prompt.error.shift.delete':
    'There was an error deleting this shift. Please try again.',
  'shiftsDomain.prompt.error.shift.saving':
    'There was an error saving this shift. Please try again.',
  'shiftsDomain.prompt.error.shift.saving.changes':
    'There was an error saving changes to this shift. Please try again.',
  'shiftsDomain.confirmation.modal.checking': 'Checking for schedule conflicts',
  'shiftsDomain.confirmation.modal.startsFromLabel': 'From',
  'shiftsDomain.confirmation.modal.endsAtLabel': 'to',
  'shiftsDomain.confirmation.modal.noEndDate': 'No end date',
  'shiftsDomain.confirmation.modal.hasStartDate': 'Starts on',
  'shiftsDomain.confirmation.modal.hasEndDate': 'Ends on',
  'shiftsDomain.confirmation.modal.conflicts.overwrite':
    'existing shift(s) conflict with your new shift and will be deleted',
  'shiftsDomain.confirmation.modal.cancel': 'Cancel',
  'shiftsDomain.confirmation.modal.confirm': 'Confirm',
  'shiftsDomain.confirmation.modal.save.changes': 'Save changes',
  'shiftsDomain.confirmation.modal.save.title': 'Save changes to recurring shift?',
  'shiftsDomain.confirmation.modal.save.only.this': 'Only this shift',
  'shiftsDomain.confirmation.modal.save.this.and.following': 'This and following shifts',
  'shiftsDomain.confirmation.modal.delete': 'Delete',
  'shiftsDomain.confirmation.modal.delete.title': 'Delete this shift?',
  'shiftsDomain.confirmation.modal.delete.only.this': 'Delete only this shift',
  'shiftsDomain.confirmation.modal.delete.this.and.following': 'Delete this and following shifts',
  'shiftsDomain.cellOptions.stopRecurring': 'Stop Recurring',
  'shiftsDomain.cellOptions.setRecurring': 'Set Recurring',
  'shiftsDomain.cellOptions.delete': 'Delete',
  'shiftsDomain.notifications.single.created': 'Shift scheduled',
  'shiftsDomain.notifications.recurring.created': 'Repeating shift scheduled',
  'shiftsDomain.notifications.single.deleted': 'Shift deleted',
  'shiftsDomain.notifications.recurring.deleted': 'Repeating shift deleted',
  'shiftsDomain.notifications.changes.saved': 'Changes saved',
  'shiftsDomain.emptyView.title': 'Unable to display shift management',
  'shiftsDomain.emptyView.message':
    "We're currently unable to display shift management for this location.",
  'shiftsDomain.staffing_level': '{scheduled} of {required}',
  'shiftsDomain.allocationType.label': 'Shift type',
  'shiftsDomain.allocationType.dedicated.label': 'Dedicated',
  'shiftsDomain.allocationType.dedicated.tooltip':
    'Employees are expected to be busy fulfilling orders during this time.',
  'shiftsDomain.allocationType.oncall.label': 'On-call',
  'shiftsDomain.allocationType.oncall.tooltip':
    'On-call shifts are meant for capturing unexpected demand. Employees should have other work to complete during expected downtime.',
  'shiftsDomain.allocation': 'allocation',
  'shiftsDomain.allocation.dedicated.label': 'Dedicated Allocation',
  'shiftsDomain.allocation.dedicated.tooltip':
    'Dedicated hours staffed compared to dedicated hours recommended.',
  'shiftsDomain.allocation.oncall.label': 'On-Call Allocation',
  'shiftsDomain.allocation.oncall.tooltip':
    'On-call hours staffed compared to hours recommended. Employees on-call should be ready to shop, but focused on other work during expected downtime.',
  'shiftsDomain.pickupWarning':
    'Pickup is not yet active. You may begin scheduling shifts, but orders will only be sent to shoppers after an account rep enables this location for Pickup',
  'shiftsDomain.inactive':
    'This location is not yet active. You may begin scheduling shifts, but only shifts dated on or after the activation date will take effect.',
  'shiftsDomain.day': 'Day',
  'shiftsDomain.week': 'Week',
  'shiftsDomain.legend.understaffed': 'Hours understaffed',
  'shiftsDomain.legend.understaffed.tooltip':
    'Hours when staffing is less than the recommendation.',
  'shiftsDomain.legend.overstaffed': 'Hours overstaffed',
  'shiftsDomain.legend.overstaffed.tooltip': 'Hours when staffing is more than the recommendation.',
  'shiftsDomain.legend.optimal': 'Optimal',
  'shiftsDomain.legend.optimal.tooltip': 'Hours when staffing matches the recommendation.',
  'shiftsDomain.legend.none': 'No recommendations yet',
  'shiftsDomain.legend.none.tooltip':
    'Staffing recommendations are available two weeks in advance.',
  'shiftsDomain.picking.performance': 'Picking performance',
  'shiftsDomain.running.performance': 'Running performance',
  'shiftsDomain.fullService.performance': 'Full Service performance',
  'shiftsDomain.driving.performance': 'Driving performance',
  'shiftsDomain.performance.cta.toPickupAnalytics': 'See pickup data',
  'shiftsDomain.performance.understaffedHours': 'Understaffed hours',
  'shiftsDomain.performance.overstaffedHours': 'Overstaffed hours',
  'shiftsDomain.performance.totalHours': 'Total hours',
  'shiftsDomain.performance.totalHours.tooltip': 'Hours scheduled compared to hours recommended.',
  'shiftsDomain.performance.scheduleGaps': 'Schedule gaps',
  'shiftsDomain.performance.scheduleGaps.tooltip':
    'Number of times zero devices are scheduled when the staffing recommendation is greater than zero.',
  'shiftsDomain.performance.scheduledDevices': 'Scheduled devices',
  'shiftsDomain.performance.scheduledDevices.tooltip': 'Devices that are on shift.',
  'shiftsDomain.staffingAlert.shiftDetails': 'Shift details:',
  'shiftsDomain.staffingAlert.demandSurge': 'Demand surge',
  'shiftsDomain.staffingAlert.noRunner': 'No runner',
  'shiftsDomain.staffingAlert.viewDetails': 'View details',
  'shiftsDomain.staffingAlert.live': 'Live',
  'shiftsDomain.staffingAlert.recommendedShiftsRowTitle': 'Staffing alert shifts',
  'shiftsDomain.staffingAlert.rowTitle': 'Staffing alerts',
  'shiftsDomain.staffingAlert.rowSubTitle': 'When you’re actively losing orders',
  'shiftsDomain.staffingAlert.onlyAvailableTwoDays': 'Only available 2 days in advance',
  'shiftsDomain.staffingAlert.rowTitleToolTip':
    'Time periods within the next 48 hours when customers are trying to place orders, but can’t because your store doesn’t have availability to receive additional orders. Alerts update every 6 mins.',
  'shiftsDomain.staffingAlert.demandSurgePopupTitle':
    'You aren’t receiving additional orders because there aren’t enough pickers',
  'shiftsDomain.staffingAlert.demandSurgePopupSubtitle':
    'Add picking shifts during these recommended times to continue receiving orders.',
  'shiftsDomain.staffingAlert.noRunnerPopupTitle':
    'You aren’t receiving additional orders because a running shift isn’t scheduled',
  'shiftsDomain.staffingAlert.noRunnerPopupSubtitle':
    'Add running shifts during these recommended times to continue receiving orders',
  'shiftsDomain.capacityRecommendation.device': 'Device',
  'shiftsDomain.capacityRecommendation.demandSurgeTitle':
    'No availability to receive additional orders during this time',
  'shiftsDomain.capacityRecommendation.demandSurgeNotice':
    'Due to a demand surge, you’ll need to add this on-call shift to continue receiving orders.',
  'shiftsDomain.capacityRecommendation.noRunnerTitle':
    'No availability to receive additional orders during this time because there’s no runner',
  'shiftsDomain.capacityRecommendation.noRunnerNotice':
    'Add this running shift to continue receiving orders.',
  'shiftsDomain.capacityRecommendation.addPickingShift': 'Add picking shift',
  'shiftsDomain.capacityRecommendation.addRunningShift': 'Add running shift',
  'shiftsDomain.common.loading': 'Loading',
  'shiftsDomain.common.noAvailableDevices':
    'All your devices are currently booked. Please add a new device before creating this shift.',
  'shiftsDomain.common.errors.failedDownloadPickupData':
    'There was a problem downloading pickup data. Some of the information on this page may be incorrect. Please contact support if the problem persists.',
} as const

export default SHIFTS_EN_US_TRANSLATIONS
