import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'collectionId',
    'COLLECTION',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('platform', 'PLATFORM', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'orderSource',
    'ORDER_SOURCE',
    optionsArrayToInFilterSelection('stringValue')
  ),
]

export const collectionSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const mapApiNameToDashboardName = {
  WAREHOUSE: 'retailer',
  COLLECTION: 'collectionId',
  PLATFORM: 'platform',
  ORDER_SOURCE: 'orderSource',
}

export const transformCollectionApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  COLLECTION: 'stringValue',
  PLATFORM: 'stringValue',
  ORDER_SOURCE: 'stringValue',
  CAMPAIGN: 'stringValue',
} as const

export const transformFilterSelectionsToCollectionSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)
