// @ts-nocheck legacy file transformed to TS
export const MAIN_NAV_WIDTH = '60px'
export const DASH_NAV_WIDTH = '40px'
export const DASH_NAV_V2_HEIGHT = '48px'

export const NAV_ITEM_HEIGHT = '60px'
export const TOPBAR_HEIGHT = '40px'

export const SUBNAV_WIDTH = '220px'

export const FULL_VIEWPORT_WIDTH = '100vw'
export const FULL_VIEWPORT_HEIGHT = '100vh'

/**
 * These are z-index values, but let's keep
 * them here to make it clear what the ranking is.
 */
export const Z_INDEX_FIRST = 300
export const Z_INDEX_SECOND = 200
export const Z_INDEX_THIRD = 100

// DEPRECATED
export const OLD_TOPBAR_HEIGHT = '100px'
