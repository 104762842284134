import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

interface Props {
  padding?: string
  justifyContent?: string
}

const FilterBar = styled.div<Props>`
  width: 100%;
  position: relative;
  display: flex;
  padding: ${props => props.padding || '0'};
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'space-between'};
  min-height: 60px;
  align-items: center;
  background: white;
  border-bottom: 1px solid ${colors.GRAY_93};
`

const FilterBarSection = styled.div<{ fullBleed?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 20px;

  ${props =>
    props.fullBleed &&
    `
    align-self: stretch;
    padding: 0px 20px;

    &:first-child {
      padding: 0px 20px 0px 0px;
    }

    &:last-child {
      padding: 0px 0px 0px 20px;
    }

    &:only-child {
      padding: 0px;
    }
  `}
`

const FilterBarItems = styled.div<{ noWrap?: boolean }>`
  display: flex;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  align-items: center;
  justify-content: flex-start;
  margin: -5px -10px;

  ${FilterBarSection}:last-child & {
    justify-content: flex-end;
  }
`

const FilterBarItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 10px;
`

export default Object.assign(FilterBar, {
  Section: FilterBarSection,
  Items: FilterBarItems,
  Item: FilterBarItem,
})
