import { useState } from 'react'
import { useDeepMemo } from './useDeepReactHooks.hooks'

/**
 * Uses a state that must belong to a particular set of values. This returns a function
 * for each value specified in that set of values. This reduces the need for
 * `useCallback` when you already know which are the possible states
 *
 * For instance:
 *
 * ```tsx
 * function SomeComponent() {
 *   const [color, setRed, setBlue, setOrange] = useStateEnum(['red', 'blue', 'orange'] as const, 'red')
 *   console.log(color) // 'red' by default,  'orange' when user clicks the button
 *
 *   return <button onClick={setOrange}>Orange!</button>
 * }
 *  ```
 *
 * @param allowedValues the allowed values, this will generate a function for each one of them (recommended, use `as const` keyword for a more strict typing on default value)
 * @param initial the default value
 */
export const useStateEnum = <T>(
  allowedValues: ReadonlyArray<T>,
  initial: T | (() => T)
): [T, ...Array<() => void>] => {
  const [state, setState] = useState<T>(initial)

  const setStateFns = useDeepMemo(
    () => allowedValues.map(val => () => setState(val)),
    [allowedValues, setState]
  )

  return [state, ...setStateFns]
}

/**
 * Uses a boolean state, with setters for true and false cases.
 *
 * It returns in this order: [state, setTrue, setFalse]
 *
 * @param initial sets the initial value of this state, optional and defaults to false
 */
export const useBooleanState = (initial: boolean | (() => boolean) = false) =>
  useStateEnum([true, false], initial) as [
    current: boolean,
    setTrueFn: () => void,
    setFalseFn: () => void
  ]
