import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

export const ColumnRow = styled.div`
  overflow: scroll;
  height: 600px;
  position: relative;
  overflow-x: hidden;
`

export const ColumnTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.GRAY_46};
  padding: 0 50px 40px;
`

export const ColumnBase = styled.div`
  padding: 40px 0px 0px;
`
