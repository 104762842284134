import { type MenuProps, components } from 'react-select-5'
import React, { useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import { spacing } from '../../../foundation'
import { type SimpleOption } from '../utils/types'
import { Button } from '../../button/Button'
import { useConfirmContext } from '../utils/ConfirmStepProvider.context'
import { useMessages } from '../../../utils/intl/intl.hooks'

const MenuActionsApplyContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing.X8,
  padding: spacing.X8,
  paddingBottom: 0,
})

const StyledButton = styled(Button)({
  margin: 0,
  flex: 1,
})

/**
 * This will shorten the label for the selection to be like
 * {placeholder} {numSelected}
 * Instead of showing every single option. It's best to use this when space is limited.
 *
 * @param props
 * @returns
 */
export const ConfirmValuesMenu = (props: MenuProps<SimpleOption, any, any>) => {
  const { stagedOptions, replaceStagedOptions } = useConfirmContext()
  const messages = useMessages({
    cancel: 'sharedComponents.select.withConfirmation.cancel',
    apply: 'sharedComponents.select.withConfirmation.apply',
  })

  const value = props.getValue()

  useEffect(
    () => {
      replaceStagedOptions(value as SimpleOption[])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...value]
  )

  const commit = useCallback(() => {
    props.setValue(Array.from(stagedOptions), 'select-option')
  }, [props, stagedOptions])

  return (
    <components.Menu {...props}>
      {props.children}
      <MenuActionsApplyContainer>
        <StyledButton
          compact={props.selectProps.compact}
          variant="secondary"
          onClick={() => {
            props.setValue(props.getValue(), 'deselect-option')
          }}
        >
          {messages.cancel}
        </StyledButton>
        <StyledButton onClick={commit} compact={props.selectProps.compact}>
          {messages.apply}
        </StyledButton>
      </MenuActionsApplyContainer>
    </components.Menu>
  )
}
