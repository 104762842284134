import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { recipesAdminAdminRouter } from './routing/recipesAdminAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { recipeAdminLibraryCsvIngestionAccessConfiguration } from './access-control/recipeAdminLibraryCsvIngestionAccess.configuration'
import { recipeAdminLibraryManagerAccessConfiguration } from './access-control/recipeAdminLibraryManagerAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'recipes-admin',
  totem: {
    entity: 'recipes-admin-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [recipesAdminAdminRouter],
  },
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.RecipesAdmin,
      attachToV2: AdminNavEntryPointV2.Merchandising,
      subNavItems: [
        {
          route: 'app-admin-recipes-admin-library-csv-ingestion',
          labelId: 'recipesAdminDomain.library.csv-ingestion',
          accessControl: recipeAdminLibraryCsvIngestionAccessConfiguration,
          positionNavV2: 1,
        },
        {
          route: 'app-admin-recipes-admin-library-manager',
          labelId: 'recipesAdminDomain.library.library-manager',
          accessControl: recipeAdminLibraryManagerAccessConfiguration,
          positionNavV2: 2,
        },
      ],
    },
  ],
}

export default definition
