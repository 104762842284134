export enum Metrics {
  SUM_CONVERSION_GMV = 'SUM_CONVERSION_GMV',
  SUM_IMPRESSIONS = 'SUM_IMPRESSIONS',
}

export enum Dimensions {
  ITEM_NAME = 'ITEM_NAME',
  PRODUCT_ID = 'PRODUCT_ID',
  PRODUCT_UPC_CODE = 'PRODUCT_UPC_CODE',
  DEPARTMENT = 'DEPARTMENT',
  WAREHOUSE = 'WAREHOUSE',
  WAREHOUSE_ID = 'WAREHOUSE_ID',
  PRODUCT_RRC_CODE = 'PRODUCT_RRC_CODE',
}

export enum GroupBys {
  ITEM_NAME = 'ITEM_NAME',
  PRODUCT_ID = 'PRODUCT_ID',
  PRODUCT_UPC_CODE = 'PRODUCT_UPC_CODE',
  DEPARTMENT = 'DEPARTMENT',
  WAREHOUSE_ID = 'WAREHOUSE_ID',
  WAREHOUSE = 'WAREHOUSE',
  PRODUCT_RRC_CODE = 'PRODUCT_RRC_CODE',
}
