import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
// import { AdminNavEntryPoint } from 'gin-and-tonic/containers/nav/utils/adminNavConfig'
import { pickupSettingsRouter } from './routing/pickupSettingsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { pickupSettingsAccessControl } from './access-control/pickupSettingsAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'pickup-settings',
  totem: {
    entity: 'pickup-settings-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [pickupSettingsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Settings,
      attachToV2: NavEntryPoint.OperationsGeneral,
      subNavItems: [
        {
          type: 'item',
          labelId: 'pickupSettingsDomain.title',
          route: 'pickup-settings',
          accessControl: pickupSettingsAccessControl,
          id: 'nav-item-pickupSettings',
          positionNavV2: 2,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
