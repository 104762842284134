import { SESSION_DATA_IDENTIFIER } from '../../../../api/utils/apollo/session/sessionToken.utils'
import localforage from '../../../../legacy/common/localforage'
import {
  fetchRetailerPlatformMeshClient,
  fetchRetailersClient,
} from '../../../../legacy/apollo/dashboardClient'
import { storeCurrentRedirectUrl } from '../../../../routes/logged-in/utils/loggedIn.utils'
import clientEnv from '../../../../utils/global/clientEnv'

export enum LogoutReason {
  UserInitiated,
  TokenExpired,
}

/**
 * Logs the user out of the application.
 * This will:
 * - Empty all apollo stores for all clients
 * - Delete the session token
 * - Redirect to the login screen. If `tokenExpired` is true, it will also show a message on
 *   the login screen stating that fact.
 *
 * @param tokenExpired
 * @returns
 */
export const logout = async (tokenExpired: LogoutReason = LogoutReason.UserInitiated) => {
  const storeResetPromises = [fetchRetailersClient(), fetchRetailerPlatformMeshClient()].map(
    client => client.clearStore()
  )

  console.warn(`[Resetting the Apollo Stores]`)
  console.warn(`[Clearing out session token]`)

  await Promise.all([...storeResetPromises, localforage.removeItem(SESSION_DATA_IDENTIFIER)])

  // Blows away the app and reloads everything
  // Will ensure that nothing is cached, and ensure that the user
  // is sent back to the log-in page (after being redirected to auth service to clear session there)
  if (tokenExpired === LogoutReason.TokenExpired) {
    // If the user is getting logged out because of a token expiration
    // we try storing the current URL so we can redirect them later
    storeCurrentRedirectUrl()
    return window.location.replace(
      `${clientEnv.PUBLIC_CLIENT_AUTH_SERVICE_BASE_URL}/logout?return_uri=${encodeURIComponent(
        `${window.location.origin}/public/log-in?sessionExpired=1`
      )}`
    )
  }

  return window.location.replace(
    `${clientEnv.PUBLIC_CLIENT_AUTH_SERVICE_BASE_URL}/logout?return_uri=${encodeURIComponent(
      `${window.location.origin}/public/log-in`
    )}`
  )
}
