import styled from '@emotion/styled'
import { borderRadius, colors, fontSize, fontWeight, spacing } from '../../foundation'

export const style = {
  overlay: {
    display: 'flex',
    backgroundColor: '#4a4a4aba',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
  content: {
    borderRadius: borderRadius.X12,
    border: 'none',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    padding: 0,
    position: 'relative',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    overflow: 'visible',
  },
} as ReactModal.Styles

export const ModalContainer = styled.div({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  border: '1px solid rgba(91, 91, 91, 0.77)',
  borderRadius: borderRadius.X12,
})

export const ModalHeader = styled.div({
  display: 'flex',
  padding: spacing.X24,
  paddingLeft: spacing.X60, //to account for the close button in the top left
  paddingRight: spacing.X60, //to make the content centered
  flexDirection: 'row',
  // if the child element is just a string, center it
  // if its anything else, space the elements out
  justifyContent: 'center',
  width: '100%',
  minHeight: spacing.X64,
  alignItems: 'center',
  fontSize: fontSize.X18,
  fontWeight: fontWeight.SEMIBOLD,
  color: colors.GRAYSCALE.X70,
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})

export const ModalBody = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  position: 'relative',
  flexGrow: 1,
  padding: spacing.X24,
  fontSize: fontSize.X15,
  color: colors.GRAYSCALE.X70,

  'p:not(:last-of-type)': {
    margin: 0,
    marginBottom: spacing.X20,
  },

  'p:last-of-type': {
    marginBottom: 0,
  },
})

export const ModalDivider = styled.div({
  backgroundColor: colors.GRAYSCALE.X20,
  width: '100%',
  height: '1px',
})

export const ModalFooter = styled.div({
  display: 'flex',
  padding: `${spacing.X12} ${spacing.X24}`,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  alignItems: 'center',
  borderTop: `1px solid ${colors.GRAYSCALE.X20}`,
  zIndex: 1,
})

export const CloseButtonContainer = styled.div({
  position: 'absolute',
  zIndex: 100, // To get it to render on top of other elements so its clickable
  top: '16px',
  left: '16px',
  color: 'white',
  fontSize: '22px',
  cursor: 'pointer',
})
