import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const ordersOperationalDashboardAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.OrdersV4View],
    featureToggles: [FeatureToggle.ordersOperationalDashboard],
  },
  {
    permissions: [Permission.OrdersV4View],
    warehouseFeatureToggles: [FeatureToggle.ordersOperationalDashboard],
  },
]
