import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavEntryPoint, NavItem } from '@retailer-platform/dashboard/routing'
import { shiftsRouter } from './routing/shiftsRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { shiftsAccessControl } from './access-control/shiftsAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'shifts',
  totem: {
    entity: 'shifts-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [shiftsRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Shoppers, //old nav group -- delete this navItem when `dashboard_store_ops_nav` feature toggle is fully enabled
      subNavItems: [
        {
          type: 'item',
          labelId: 'shiftsDomain.title',
          route: 'shifts',
          accessControl: shiftsAccessControl,
        },
      ],
    },
    {
      attachTo: NavItem.StoreOperations,
      attachToV2: NavEntryPoint.OperationsLabor,
      subNavItems: [
        {
          type: 'item',
          labelId: 'shiftsDomain.title',
          route: 'shifts',
          accessControl: shiftsAccessControl,
          id: 'nav-item-shifts',
          position: 2,
          positionNavV2: 2,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
