import React from 'react'
import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { NewBadge } from '@retailer-platform/dashboard/gin-and-tonic'
import { roleManagementRouter } from './routing/roleManagementRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { RoleManagementAccessControl } from './access-control/roleManagementAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'role-management',
  totem: {
    entity: 'role-management-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [roleManagementRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Admin,
      attachToV2: NavEntryPoint.UserMenuUserManagement,
      subNavItems: [
        {
          type: 'item',
          labelId: 'roleManagementDomain.title',
          route: 'role-management-roles-list',
          position: 2,
          accessControl: RoleManagementAccessControl,
          positionNavV2: 1,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
