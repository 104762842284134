import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { integrationsAccessControl } from '../access-control/integrationsAccess.configuration'

const IntegrationsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "IntegrationsListPage" */
      '../pages/list/IntegrationsListPage'
    )
)

// @hygen:inject page-import

export const integrationsRouter = (
  <DomainRoute route="integrations" accessControl={integrationsAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="integrations-list"
        component={IntegrationsListPage}
        scopePicker={{
          titles: ['integrationsDomain.title'],
        }}
      />
      <DomainRoute exact route="integrations-view-generic" component={IntegrationsListPage} />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
