import { type InsightsSchemaConfigs } from '../configuration/schema.configuration'
// The following two exports are from separate files to avoid circular dependencies
// If someone is able to get tests passing with the types housed here in this file, feel free to move them back
export { GroupByTypes as InsightsGroupByTypes } from './groupBy.types'
export { FormatterType as InsightsFormatterType } from './formatter.types'

export type InsightsFormatterFunction = (val: string | number) => string

export enum InsightsMetricDesiredDirection {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export type InsightsConfiguredSchemas = keyof InsightsSchemaConfigs
