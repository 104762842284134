import React, { type FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'ic-snacks'
import EmptyView from '../../../../../../../components/EmptyView'
import { type OnClick } from '../../../../../../../../utils/react/event.types'

interface Props {
  toggleEditLocationsMode: OnClick
}

const emptyViewStyle = {
  padding: 30,
  minHeight: 495,
}

export const StoreGroupsFormSelectedListEmptyState: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ toggleEditLocationsMode }) => (
  <EmptyView
    size="small"
    iconName="store"
    title={<FormattedMessage id="settings.storeGroups.addStoreLocations" />}
    body={<FormattedMessage id="settings.storeGroups.form.locationsEmptyState.body" />}
    action={
      <Button
        size="small"
        onClick={toggleEditLocationsMode}
        elementAttributes={{ 'data-testid': 'store-group-toggle-edit-locations-mode' }}
      >
        <FormattedMessage id="settings.storeGroups.form.addLocations" />
      </Button>
    }
    style={emptyViewStyle}
  />
)
