import { type InstacartStoreConfiguration } from '../../../__codegen__/api'

export interface WarehousesForSelection {
  label: string
  value: string
  logo: {
    url: string
  }
  storeConfigurations: InstacartStoreConfiguration[]
}

export interface StoreConfigurationForSelection {
  label: string
  value: string
  logoUrl: string
  retailerId: string
  domainName: string
  storeConfig: InstacartStoreConfiguration
  selectOption: SiteSelectOption
}

export type FilterSitesFn = (
  siteOptions: StoreConfigurationForSelection[]
) => StoreConfigurationForSelection[]

export type SiteOperationIntent = 'view' | 'modify'

export enum SiteSelectOption {
  ALLOW,
  SELECT_NOT_APPLICABLE,
  HIDE,
}

export interface Site extends InstacartStoreConfiguration {}
