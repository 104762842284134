import chunk from 'lodash/chunk'
import { useMemo, useState } from 'react'
import { type StoreLocationDetails } from '../../../utils/locations.hooks'

const DEFAULT_PER_PAGE = 6

interface Props {
  locations: StoreLocationDetails[]
  perPage?: number
  minRows?: number
}

export const useStoreGroupsFormLocationPagination = ({
  locations,
  perPage = DEFAULT_PER_PAGE,
}: Props) => {
  const [pageIndex, setPageIndex] = useState(0)

  const chunks = useMemo(() => chunk(locations, perPage), [locations, perPage])

  const data = chunks[pageIndex]

  const meta = {
    per_page: perPage,

    total_pages: chunks.length,

    total_count: locations.length,
  }

  const onPageChange = (newPageIndex: number) => setPageIndex(newPageIndex - 1)

  return {
    data,
    meta,
    onPageChange,
    minRows: perPage,
  }
}
