import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_STORE_CONFIGURATION = `${URL_RETAILER_SCOPED}/store-configuration`
// @hygen:inject scoped-urls

const URL_STORECONFIGURATION_SITE_SCOPED = `${URL_STORE_CONFIGURATION}${URL_SITE_SCOPED}`
const URL_STORE_CONFIGURATION_SITE_SCOPED_VIEW = `${URL_STORECONFIGURATION_SITE_SCOPED}/view`
const URL_STORE_CONFIGURATION_SITE_SCOPED_DRAFTS = `${URL_STORECONFIGURATION_SITE_SCOPED}/drafts`
const URL_STORE_CONFIGURATION_SITE_SCOPED_EDIT = `${URL_STORECONFIGURATION_SITE_SCOPED}/drafts/edit/:draftId`
const URL_STORE_CONFIGURATION_SITE_SCOPED_CURRENT = `${URL_STORECONFIGURATION_SITE_SCOPED}/current`

export type DashRouteSet =
  | DashRouteType<'store-configuration'>
  | DashRouteType<'store-configuration-view'>
  | DashRouteType<'store-configuration-drafts'>
  | DashRouteType<'store-configuration-edit'>
  | DashRouteType<'store-configuration-current'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'store-configuration': URL_STORECONFIGURATION_SITE_SCOPED,
  'store-configuration-view': URL_STORE_CONFIGURATION_SITE_SCOPED_VIEW,
  'store-configuration-drafts': URL_STORE_CONFIGURATION_SITE_SCOPED_DRAFTS,
  'store-configuration-edit': URL_STORE_CONFIGURATION_SITE_SCOPED_EDIT,
  'store-configuration-current': URL_STORE_CONFIGURATION_SITE_SCOPED_CURRENT,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
