import _ from 'lodash'
import {
  type GroupedOptionsType,
  type CommonProps,
  type OptionTypeBase,
} from 'react-select/src/types'
import { useMemo } from 'react'
import { type CSSObjectStyleFn, type SelectStyleConfig } from './select.types'

// https://github.com/JedWatson/react-select/blob/v2.4.4/src/Select.js#L1307
export const isGroupedOptionsList = <TOption extends OptionTypeBase>(
  options: GroupedOptionsType<TOption> | ReadonlyArray<TOption>
): options is GroupedOptionsType<TOption> => Object.keys(options[0] || {}).includes('options')

interface IsSingleValueProps<TOption extends OptionTypeBase>
  extends Pick<CommonProps<TOption>, 'getValue' | 'isMulti' | 'hasValue'> {}

export const useIsSingleValue = <TOption extends OptionTypeBase>({
  getValue,
  isMulti,
  hasValue,
}: IsSingleValueProps<TOption>): TOption | undefined =>
  useMemo(() => {
    const value = getValue()

    if (isMulti || !hasValue || !value) {
      return undefined
    }

    return Array.isArray(value) ? value[0] : value
  }, [getValue, hasValue, isMulti])

type MergedStyles = {
  [K in keyof SelectStyleConfig]: Required<SelectStyleConfig>[K][]
}

/**
 * Takes n number of React Select's style objects and merges them by producing
 * a function for every property that combines the result of each one of the original function.
 * They will get applied from left to right.
 *
 * @param styles
 * @returns
 */
export const mergeReactSelectStyles = (...styles: SelectStyleConfig[]) => {
  // Merge every object into a new object that contains all the values of each property in an array
  const merged: MergedStyles = _.mergeWith<MergedStyles, SelectStyleConfig>(
    {},
    // This is just to squelch typescript, it needs to know the length, but lodash doesn't really care.
    ...(styles as [SelectStyleConfig]),
    (objValue: CSSObjectStyleFn[], srcValue: CSSObjectStyleFn) => {
      if (Array.isArray(objValue)) {
        objValue.push(srcValue)
      } else {
        objValue = [srcValue]
      }

      return objValue
    }
  )

  // Iterate through every property of the merged values and return a function for each property, that executes and merges all resulting styles
  const flattenedMergedStyles = _.mapValues(merged, fns => {
    if (!fns) return fns

    const compactedFns = _.compact(fns)
    if (compactedFns.length === 0) return undefined

    const mergerFn: CSSObjectStyleFn = (...args) =>
      compactedFns.reduce((prevStyles, fn: CSSObjectStyleFn) => {
        const newStyles = fn(...args)

        return _.merge(prevStyles, newStyles)
      }, {})

    return mergerFn
  })

  return flattenedMergedStyles
}
