import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  NoPermissionRequired,
  BusinessProgramRead,
  BusinessProgramAuthor,
} from '../access-control/businessAdminAccess.configuration'

const BusinessProgramCreatePage = lazy(
  () => import('../pages/program-create/BusinessProgramCreatePage')
)
const BusinessProgramListPage = lazy(() => import('../pages/program-list/BusinessProgramListPage'))
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const businessAdminRouter = (
  <DomainRoute route="app-admin-business" accessControl={NoPermissionRequired}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-business-program-create"
        component={BusinessProgramCreatePage}
        accessControl={BusinessProgramAuthor}
      />
      <DomainRoute
        exact
        route="app-admin-business-program-list"
        component={BusinessProgramListPage}
        accessControl={BusinessProgramRead}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
