import {
  type DomainAccessControlConfig,
  type DomainAccessControlConfigItem,
} from '../utils/domain/accessControl'
import { integrationsCloverAccessControl } from './cloverIntegration.configuration'
import { integrationsLightspeedAccessControl } from './lightspeedIntegration.configuration'
import { integrationsSquareAccessControl } from './squareIntegration.configuration'
import { integrationsShopifyAccessControl } from './shopifyIntegration.configuration'
import { integrationsGoogleBusinessAccessControl } from './googleBusinessIntegration.configuration'
import { integrationsQuickbooksAccessControl } from './quickBooksIntegration.configuration'
import { integrationsYextAccessControl } from './yextIntegration.configuration'
import { integrationsVendhqAccessControl } from './vendhqIntegration.configuration'

export const integrationsAccessControl: DomainAccessControlConfig = [
  /**
   * Add here all specific integrations access control settings
   *
   * As long as the user has access to one of them, the menu entry and page
   * should be visible for them
   */
  integrationsCloverAccessControl,
  integrationsLightspeedAccessControl,
  integrationsSquareAccessControl,
  integrationsShopifyAccessControl,
  integrationsGoogleBusinessAccessControl,
  integrationsQuickbooksAccessControl,
  integrationsVendhqAccessControl,
  integrationsYextAccessControl,
] as DomainAccessControlConfigItem[]
