import { useState, useEffect } from 'react'
import { camelCase } from 'lodash'
import { useRetailerId, usePartnerId } from '@retailer-platform/dashboard/routing'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import {
  dataFetchingLoading,
  type DataFetchingState,
  dataFetchingSuccess,
  dataFetchingError,
} from '@retailer-platform/dashboard/legacy'
import { usePickupAnalyticsQueryParamsBehaviour } from './pickupAnalyticsQueryParams.hooks'
import {
  pickupAnalyticsFetchData,
  type PickupAnalyticsApiResponse,
  pickupAnalyticsFetchMetric,
} from './pickupAnalytics.api'
import {
  pickupAnalyticsConvertApiDataToViewData,
  pickupAnalyticsConvertApiMetricToDisplayMetric,
} from './pickupAnalytics.utils'
import { type PickupAnalyticsApiMetric, type PickupAnalyticsMetrics } from './pickupAnalytics.types'

const initialApiState = dataFetchingLoading<PickupAnalyticsApiResponse>()

export const usePickupAnalyticsBehaviour = () => {
  const partnerId = usePartnerId()
  const retailerId = useRetailerId()

  const {
    dateSelectRangeValue,
    setDateSelectRangeValue,
    retailerLocationSelectValue,
    setRetailerLocationSelectValue,
  } = usePickupAnalyticsQueryParamsBehaviour()

  const [pickupAnalyticsApiState, setPickupAnalyticsApiState] =
    useState<DataFetchingState<PickupAnalyticsApiResponse>>(initialApiState)

  const [metrics, setMetrics] = useState<PickupAnalyticsMetrics>({})

  useEffect(() => {
    setPickupAnalyticsApiState(dataFetchingLoading())
    setMetrics({})

    const setMetricField =
      (fieldName: keyof PickupAnalyticsMetrics) =>
      (apiMetricData: { data: PickupAnalyticsApiMetric }) =>
        setMetrics(metrics => ({
          ...metrics,
          [fieldName]: pickupAnalyticsConvertApiMetricToDisplayMetric(apiMetricData.data),
        }))

    const setMetricFieldError = (fieldName: keyof PickupAnalyticsMetrics) => () =>
      setMetrics(metrics => ({
        ...metrics,
        [fieldName]: { error: true },
      }))

    const apiParams = {
      partnerId,
      retailerId,
      dateSelectRangeValue,
      retailerLocationSelectValue,
    }

    // TODO: parallelize all the data. A few metrics are still used from the old call because they were correct
    // all the other calls were parallelized after moving away from the aggregate table on the BE because of inaccurate data
    pickupAnalyticsFetchData(apiParams)
      .then(result => setPickupAnalyticsApiState(dataFetchingSuccess(result)))
      .catch((err?: Error) => setPickupAnalyticsApiState(dataFetchingError(err)))

    // parallelized metrics
    const metricNames = [
      'utilization_rate',
      'batch_acceptance',
      'late_percentage',
      'p75_wait_time',
      'seconds_per_item',
      'shopper_rating',
      'pickups',
    ]

    metricNames.forEach(key => {
      const normalizedName = camelCase(key) as keyof PickupAnalyticsMetrics

      pickupAnalyticsFetchMetric(apiParams, key)
        .then(setMetricField(normalizedName))
        .catch(setMetricFieldError(normalizedName))
    })
  }, [partnerId, retailerId, dateSelectRangeValue, retailerLocationSelectValue, setMetrics])

  return {
    dateSelectRangeValue,
    setDateSelectRangeValue,
    retailerLocationSelectValue,
    setRetailerLocationSelectValue,
    error: pickupAnalyticsApiState.error,
    isLoading: pickupAnalyticsApiState.loading,
    pickupAnalyticsData:
      pickupAnalyticsApiState.data &&
      pickupAnalyticsConvertApiDataToViewData(pickupAnalyticsApiState.data.data),
    retailerId,
    metrics,
  }
}

export type PickupAnalyticsBehaviour = ReturnType<typeof usePickupAnalyticsBehaviour>
