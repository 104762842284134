import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_SITE_SCOPED,
  URL_PARTNER_SCOPED,
} from '@retailer-platform/dashboard/routing'
import { URL_APP_ADMIN_LANDING_PAGE_SYSTEM } from '@retailer-platform/dashboard/routing'

const URL_STOREFRONT = `${URL_RETAILER_SCOPED}/storefront`
const URL_STOREFRONT_PARTNER_SCOPED = `${URL_PARTNER_SCOPED}/storefront`

// @hygen:inject scoped-urls

// site scoped
const URL_STOREFRONT_SITE_SCOPED = `${URL_STOREFRONT}${URL_SITE_SCOPED}`
const URL_STOREFRONT_ITEM_ATTRIBUTE_ICONS = `${URL_STOREFRONT_SITE_SCOPED}/item-attribute-icons`
const URL_STOREFRONT_STOREFRONT_SETTINGS = `${URL_STOREFRONT_SITE_SCOPED}/storefront-settings`
const URL_STOREFRONT_BRAND_SETTINGS = `${URL_STOREFRONT_STOREFRONT_SETTINGS}/brand-settings`
const URL_STOREFRONT_BRAND_SETTINGS_V2 = `${URL_STOREFRONT_STOREFRONT_SETTINGS}/brand-settings-v2`
const URL_STOREFRONT_BRAND_SETTINGS_EDIT = `${URL_STOREFRONT_BRAND_SETTINGS}/edit/:ippDraftId`
const URL_STOREFRONT_CUSTOM_IMAGES = `${URL_STOREFRONT_STOREFRONT_SETTINGS}/custom-images`
const URL_STOREFRONT_CUSTOM_IMAGES_V2 = `${URL_STOREFRONT_STOREFRONT_SETTINGS}/custom-images-v2`
const URL_STOREFRONT_STOREFRONT_NAVIGATION = `${URL_STOREFRONT_SITE_SCOPED}/navigation`
const URL_STOREFRONT_NAVIGATION_HEADER = `${URL_STOREFRONT_STOREFRONT_NAVIGATION}/header`
const URL_STOREFRONT_NAVIGATION_FOOTER = `${URL_STOREFRONT_STOREFRONT_NAVIGATION}/footer`
const URL_STOREFRONT_NAVIGATION_COLLECTIONS = `${URL_STOREFRONT_STOREFRONT_NAVIGATION}/collections/:l1(\\d+)?/:l2(\\d+)?/:l3(\\d+)?`
const URL_STOREFRONT_CONTENT_PAGES = `${URL_STOREFRONT_SITE_SCOPED}/content-pages`
const URL_STOREFRONT_CONTENT_PAGES_DASHBOARD = `${URL_STOREFRONT_CONTENT_PAGES}/dashboard`
const URL_STOREFRONT_CONTENT_PAGES_CREATE = `${URL_STOREFRONT_CONTENT_PAGES}/create`
const URL_STOREFRONT_CONTENT_PAGES_EDIT = `${URL_STOREFRONT_CONTENT_PAGES}/edit/:contentPageId`
const URL_STOREFRONT_CONTENT_PAGES_EDIT_VERSION = `${URL_STOREFRONT_CONTENT_PAGES_EDIT}/version/:contentPageVersionId`
const URL_STOREFRONT_HOMEPAGE = `${URL_STOREFRONT_SITE_SCOPED}/homepage`
const URL_STOREFRONT_PARTNER_HOMEPAGE = `${URL_STOREFRONT_PARTNER_SCOPED}/homepage`
const URL_STOREFRONT_HOMEPAGE_EDIT = `${URL_STOREFRONT_HOMEPAGE}/edit`
const URL_STOREFRONT_HOMEPAGE_EDIT_V2 = `${URL_STOREFRONT_PARTNER_HOMEPAGE}/edit/:storefrontPageId`
const URL_STOREFRONT_HOMEPAGE_DASHBOARD = `${URL_STOREFRONT_HOMEPAGE}/dashboard`
const URL_STOREFRONT_HOMEPAGE_DASHBOARD_V2 = `${URL_STOREFRONT_PARTNER_HOMEPAGE}/dashboard`
const URL_STOREFRONT_FLYOUT_PAGES = `${URL_STOREFRONT_SITE_SCOPED}/flyout-pages`
const URL_STOREFRONT_FLYOUT_PAGES_CREATE = `${URL_STOREFRONT_FLYOUT_PAGES}/create`
const URL_STOREFRONT_FLYOUT_PAGES_EDIT = `${URL_STOREFRONT_FLYOUT_PAGES}/edit/:flyoutContentPageId`
const URL_STOREFRONT_PLACEMENT_BUILDER = `${URL_STOREFRONT_SITE_SCOPED}/placement-builder`
const URL_STOREFRONT_PLACEMENT_BUILDER_CREATE_WITH_TYPE = `${URL_STOREFRONT_PLACEMENT_BUILDER}/create/:placementType/:layout?`
const URL_STOREFRONT_PLACEMENT_BUILDER_EDIT = `${URL_STOREFRONT_PLACEMENT_BUILDER}/edit/:placementType/:placementId/:layout?`
const URL_STOREFRONT_CREATIVE_MANAGER = `${URL_STOREFRONT_SITE_SCOPED}/creative`
const URL_STOREFRONT_PLACEMENT_VIEWER = `${URL_STOREFRONT_SITE_SCOPED}/placement-viewer/:placementId?`
const URL_STOREFRONT_ADMIN_NAVIGATION_FOOTER = `${URL_APP_ADMIN_LANDING_PAGE_SYSTEM}/footer`
const URL_STOREFRONT_AUTOSUGGEST_LINKS = `${URL_STOREFRONT_SITE_SCOPED}/autosuggest-links`
const URL_STOREFRONT_AUTOSUGGEST_LINKS_LIST = `${URL_STOREFRONT_AUTOSUGGEST_LINKS}/list`
const URL_STOREFRONT_AUTOSUGGEST_LINKS_UPSERT = `${URL_STOREFRONT_AUTOSUGGEST_LINKS}/upsert/:linkId?`
const URL_STOREFRONT_AUTOSUGGEST_LINKS_VIEW = `${URL_STOREFRONT_AUTOSUGGEST_LINKS}/list/:linkId`
const URL_STOREFRONT_SLOTS_MANAGER = `${URL_STOREFRONT_SITE_SCOPED}/slot-manager/:slotId?`
const URL_STOREFRONT_BANNER_LOGO = `${URL_STOREFRONT_STOREFRONT_SETTINGS}/banner-logo`

// partner scoped
const URL_STOREFRONT_CREATIVE_MANAGER_V2 = `${URL_STOREFRONT_PARTNER_SCOPED}/creative`
const URL_STOREFRONT_CREATIVE_VIEW = `${URL_STOREFRONT_PARTNER_SCOPED}/creative/view/:creativeId`

export interface NavigationCollectionsParams {
  l1?: string
  l2?: string
  l3?: string
}

export type DashRouteSet =
  | DashRouteType<'storefront'>
  | DashRouteType<'storefront-partner-scoped'>
  | DashRouteType<'storefront-navigation-header'>
  | DashRouteType<'storefront-brand-settings'>
  | DashRouteType<'storefront-brand-settings-v2'>
  | DashRouteType<'storefront-brand-settings-edit'>
  | DashRouteType<'storefront-custom-images'>
  | DashRouteType<'storefront-custom-images-v2'>
  | DashRouteType<'storefront-navigation-footer'>
  | DashRouteType<'storefront-placement-builder-create-with-type'>
  | DashRouteType<'storefront-placement-builder-edit'>
  | DashRouteType<'storefront-content-pages-dashboard'>
  | DashRouteType<'storefront-content-pages-create'>
  | DashRouteType<'storefront-content-pages-edit'>
  | DashRouteType<'storefront-content-pages-edit-version'>
  | DashRouteType<'storefront-navigation-collections', NavigationCollectionsParams>
  | DashRouteType<'storefront-homepage-dashboard'>
  | DashRouteType<'storefront-homepage-dashboard-v2'>
  | DashRouteType<'storefront-homepage-edit'>
  | DashRouteType<'storefront-homepage-edit-v2'>
  | DashRouteType<'storefront-flyout-pages-create'>
  | DashRouteType<'storefront-flyout-pages-edit'>
  | DashRouteType<'storefront-autosuggest-links-list'>
  | DashRouteType<'storefront-autosuggest-links-upsert'>
  | DashRouteType<'storefront-autosuggest-links-view'>
  | DashRouteType<'storefront-creative-manager'>
  | DashRouteType<'storefront-creative-manager-v2'>
  | DashRouteType<'storefront-creative-view'>
  | DashRouteType<'storefront-placement-viewer'>
  | DashRouteType<'storefront-admin'>
  | DashRouteType<'storefront-admin-navigation-footer'>
  | DashRouteType<'storefront-item-attribute-icons'>
  | DashRouteType<'storefront-slots-manager'>
  | DashRouteType<'storefront-banner-logo'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  storefront: URL_STOREFRONT_SITE_SCOPED,
  'storefront-partner-scoped': URL_STOREFRONT_PARTNER_SCOPED,
  'storefront-navigation-header': URL_STOREFRONT_NAVIGATION_HEADER,
  'storefront-brand-settings': URL_STOREFRONT_BRAND_SETTINGS,
  'storefront-brand-settings-v2': URL_STOREFRONT_BRAND_SETTINGS_V2,
  'storefront-brand-settings-edit': URL_STOREFRONT_BRAND_SETTINGS_EDIT,
  'storefront-custom-images': URL_STOREFRONT_CUSTOM_IMAGES,
  'storefront-custom-images-v2': URL_STOREFRONT_CUSTOM_IMAGES_V2,
  'storefront-navigation-footer': URL_STOREFRONT_NAVIGATION_FOOTER,
  'storefront-placement-builder-create-with-type':
    URL_STOREFRONT_PLACEMENT_BUILDER_CREATE_WITH_TYPE,
  'storefront-placement-builder-edit': URL_STOREFRONT_PLACEMENT_BUILDER_EDIT,
  'storefront-content-pages-dashboard': URL_STOREFRONT_CONTENT_PAGES_DASHBOARD,
  'storefront-content-pages-create': URL_STOREFRONT_CONTENT_PAGES_CREATE,
  'storefront-content-pages-edit': URL_STOREFRONT_CONTENT_PAGES_EDIT,
  'storefront-content-pages-edit-version': URL_STOREFRONT_CONTENT_PAGES_EDIT_VERSION,
  'storefront-navigation-collections': URL_STOREFRONT_NAVIGATION_COLLECTIONS,
  'storefront-homepage-dashboard': URL_STOREFRONT_HOMEPAGE_DASHBOARD,
  'storefront-homepage-dashboard-v2': URL_STOREFRONT_HOMEPAGE_DASHBOARD_V2,
  'storefront-homepage-edit': URL_STOREFRONT_HOMEPAGE_EDIT,
  'storefront-homepage-edit-v2': URL_STOREFRONT_HOMEPAGE_EDIT_V2,
  'storefront-flyout-pages-create': URL_STOREFRONT_FLYOUT_PAGES_CREATE,
  'storefront-flyout-pages-edit': URL_STOREFRONT_FLYOUT_PAGES_EDIT,
  'storefront-autosuggest-links-list': URL_STOREFRONT_AUTOSUGGEST_LINKS_LIST,
  'storefront-autosuggest-links-upsert': URL_STOREFRONT_AUTOSUGGEST_LINKS_UPSERT,
  'storefront-autosuggest-links-view': URL_STOREFRONT_AUTOSUGGEST_LINKS_VIEW,
  'storefront-creative-manager': URL_STOREFRONT_CREATIVE_MANAGER,
  'storefront-creative-manager-v2': URL_STOREFRONT_CREATIVE_MANAGER_V2,
  'storefront-creative-view': URL_STOREFRONT_CREATIVE_VIEW,
  'storefront-placement-viewer': URL_STOREFRONT_PLACEMENT_VIEWER,
  'storefront-admin': URL_APP_ADMIN_LANDING_PAGE_SYSTEM,
  'storefront-admin-navigation-footer': URL_STOREFRONT_ADMIN_NAVIGATION_FOOTER,
  'storefront-item-attribute-icons': URL_STOREFRONT_ITEM_ATTRIBUTE_ICONS,
  'storefront-slots-manager': URL_STOREFRONT_SLOTS_MANAGER,
  'storefront-banner-logo': URL_STOREFRONT_BANNER_LOGO,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
