import { useRetailerId } from '../../utils/routing/params.hooks'
import { type GetStoreConfigsQueryVariables, useGetStoreConfigsQuery } from '../../__codegen__/api'

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export interface PartialParams extends PartialBy<GetStoreConfigsQueryVariables, 'retailerIds'> {
  throwOnMissing?: boolean
}

export interface Props extends GetStoreConfigsQueryVariables {}

export const useRetailersStoreConfigurations = (props: PartialParams) => {
  const { throwOnMissing = true } = props
  const retailerId = useRetailerId({ throwOnMissing })
  return useStoreConfigurations({ retailerIds: [retailerId], ...props })
}

export const useStoreConfigurations = (props: Props) => {
  const vars = {
    ...props,
  }
  const onlyInvalidRetailerId = vars.retailerIds.length == 1 && vars.retailerIds[0] == '0'
  return useGetStoreConfigsQuery({ variables: vars, skip: onlyInvalidRetailerId })
}
