import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { AdminNavEntryPoint, AdminNavEntryPointV2 } from '@retailer-platform/dashboard/routing'
import { connectedStoresAdminRouter } from './routing/connectedStoresAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import {
  caperAccessControl,
  shelfLabelsAccessControl,
  uposStatusAccessControl,
} from './access-control/connectedStoresAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'connected-stores',
  totem: {
    entity: 'connected-stores-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [connectedStoresAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.ConnectedStores,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-connected-stores-caper',
          labelId: 'connectedStoresAdminDomain.caper.title',
          accessControl: caperAccessControl,
          subRoutes: [
            'app-admin-connected-stores-caper-config',
            'app-admin-connected-stores-caper-config-create',
            'app-admin-connected-stores-caper-config-partner',
            'app-admin-connected-stores-caper-config-retailer',
            'app-admin-connected-stores-caper-config-store',
            'app-admin-connected-stores-caper-config-device',
          ],
        },
        {
          route: 'app-admin-connected-stores-shelf-labels-stores',
          labelId: 'connectedStoresAdminDomain.shelfLabels',
          accessControl: shelfLabelsAccessControl,
          subRoutes: [
            'app-admin-connected-stores-shelf-labels-connections',
            'app-admin-connected-stores-shelf-labels-sync-strategies',
          ],
        },
        {
          route: 'app-admin-connected-stores-upos-status',
          labelId: 'connectedStoresAdminDomain.upos.title',
          accessControl: uposStatusAccessControl,
          subRoutes: [
            'app-admin-connected-stores-upos-retailers',
            'app-admin-connected-stores-upos-profiles',
            'app-admin-connected-stores-upos-stores',
            'app-admin-connected-stores-upos-integrations',
          ],
        },
      ],
    },
  ],
  // NOTE TO DEV: run `yarn ipp domain register <your-system-name>` to register your domain with Totem here
}

export default definition
