/* eslint-disable @typescript-eslint/no-explicit-any */
import { keysOfObject } from '../../legacy/common/utils'

/**
 * Compact an object, removing all keys which have value that match a set of values
 * @param object the object which should be compacted
 * @param targetValues when a key has one of these values, it will be filtered out
 */
export function compactObjectMatchingValues<ObjectType>(
  object: ObjectType,
  targetValues: ReadonlyArray<any>
) {
  const defaultResult: Partial<ObjectType> = {}

  return keysOfObject<ObjectType>(object).reduce((acc, key) => {
    const value = object[key]

    if (targetValues.some(target => value === target)) return acc
    return { ...acc, [key]: value }
  }, defaultResult) as ObjectType
}

/**
 * Compact an object, removing all keys which have value that are undefined
 * @param object the object which should be compacted
 */
export function compactObject<ObjectType>(object: ObjectType) {
  return compactObjectMatchingValues<ObjectType>(object, [undefined])
}
/**
 * Compact an object, removing all keys which have value that are null or undefined
 * @param object the object which should be compacted
 */
export function compactNullObject<ObjectType>(object: ObjectType) {
  return compactObjectMatchingValues<ObjectType>(object, [undefined, null])
}
