import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

const CardFooter = styled.div<{ dense?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${({ dense }) => (dense ? '0' : '60px')};
  padding: ${({ dense }) => (dense ? '12px 20px' : '20px')};
  border-top: 1px solid ${colors.GRAY_88};
`

export default CardFooter
