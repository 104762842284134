import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const storeConfigurationAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfiguration],
}

export const dmsDraftTableAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.dmsDraftTableEnabled],
}
