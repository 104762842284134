import React, { useRef } from 'react'
import { ClassNames, keyframes } from '@emotion/react'
import { useOnClickOutside } from '@instacart/ids-core'
import { TDS_DEFAULT_COLORS } from '@retailer-platform/shared-components/src/tds'
import { Modal, ModalContainer, spacing } from '@retailer-platform/shared-components'
import { MemoryRouter } from 'react-router'
import { useRetailerId, usePartnerId } from '../../../../utils/routing/params.hooks'
import { SurveyContextProvider } from '../RetailerSatisfactionSurveyContext'
import {
  type UserFeedbackSurveyPages,
  SURVEY_ANIMATION_SLIDE_DURATION_MS,
} from './UserFeedbackSurveyController'

type UserFeedbackSurveyModalProps = {
  children: JSX.Element[]
  initialPage: UserFeedbackSurveyPages
  milestoneSlug: string
  onSurveyCompleted: () => {}
  toolProps: {}
  surveyData: [{}, (x: {}) => void]
  onClose: () => void
  isClosing: boolean
}

const slideIn = keyframes({
  from: {
    transform: 'translateX(100%)',
  },
  to: {
    transform: 'translateX(0)',
  },
})

const slideOut = keyframes({
  from: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  to: {
    opacity: 0,
    transform: 'translateX(100%)',
  },
})

export const UserFeedbackSurveyModal = (props: UserFeedbackSurveyModalProps) => {
  const {
    children,
    initialPage,
    onClose,
    onSurveyCompleted,
    surveyData,
    toolProps,
    milestoneSlug,
    isClosing,
  } = props
  const modalContainerRef = useRef(null)
  useOnClickOutside(modalContainerRef, onClose)

  const retailerId = useRetailerId({ throwOnMissing: false }) ?? '0'
  const partnerId = usePartnerId({ throwOnMissing: false }) ?? '0'

  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          onRequestClose={onClose}
          isOpen={true}
          position={'top-center'}
          closeTimeoutMS={SURVEY_ANIMATION_SLIDE_DURATION_MS}
          maxWidth={552}
          hideOverlay
          styleOverrides={{
            content: {
              position: 'absolute',
              top: 'unset',
              right: '0',
              bottom: '0',
              width: '420px',
              maxHeight: 'auto',
              margin: spacing.X24,
            },
          }}
          className={css({
            backgroundColor: TDS_DEFAULT_COLORS.colors.systemGrayscale00,
            animation: isClosing
              ? `${slideOut} ${SURVEY_ANIMATION_SLIDE_DURATION_MS}ms ease-in-out`
              : `${slideIn} ${SURVEY_ANIMATION_SLIDE_DURATION_MS}ms ease-in-out`,
          })}
        >
          <ModalContainer
            css={{
              border: 0,
            }}
            ref={modalContainerRef}
          >
            <MemoryRouter initialEntries={[initialPage]}>
              <SurveyContextProvider
                toolProps={toolProps}
                surveyData={surveyData}
                milestoneSlug={milestoneSlug}
                onSurveyCompleted={onSurveyCompleted}
                retailerId={retailerId}
                partnerId={partnerId}
              >
                {children}
              </SurveyContextProvider>
            </MemoryRouter>
          </ModalContainer>
        </Modal>
      )}
    </ClassNames>
  )
}
