import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_SHOPPING_LIST = `${URL_RETAILER_SCOPED}/shopping-list`
const URL_SHOPPING_LIST_LIST = `${URL_SHOPPING_LIST}/list`
// @hygen:inject scoped-urls

export type DashRouteSet = DashRouteType<'shopping-list'> | DashRouteType<'shopping-list-list'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'shopping-list': URL_SHOPPING_LIST,
  'shopping-list-list': URL_SHOPPING_LIST_LIST,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
