import React from 'react'
import styled from '@emotion/styled'
import { colors, SVGIcon, type RadiumStyles } from 'ic-snacks'

interface ContainerProps {
  width?: string
  height?: string
  isSelected?: boolean
  margin?: string
  display?: string
  justifyContent?: string
  alignItems?: string
}

const Container = styled.div<ContainerProps>`
  border: 1px solid;
  border-radius: 4px;
  width: ${({ width }) => width || 'calc(50% - 12px)'};
  height: ${({ height }) => height || '105px'};
  border-color: ${({ isSelected }) => (isSelected ? colors.GREEN_500 : colors.GRAY_46)};
  position: relative;
  cursor: pointer;
  margin: ${({ margin }) => margin || '12px 0px'};
`

const Title = styled.div`
  margin: 13px;
`

const Description = styled.div`
  margin: 13px;
  font-size: 12px;
  line-height: 17px;
`

const ICON_STYLE: RadiumStyles = {
  position: 'absolute',
  top: '9px',
  right: '9px',
}

interface BaseProps {
  isSelected: boolean
  onChange?: () => void
  title?: React.ReactNode
  description?: React.ReactNode
  containerProps?: ContainerProps
  children?: React.ReactNode
  SelectedIconComponent?: typeof SVGIcon
  UnselectedIconComponent?: typeof SVGIcon
  selectedIconComponentProps?: React.ComponentPropsWithoutRef<typeof SVGIcon>
  unselectedIconComponentProps?: React.ComponentPropsWithoutRef<typeof SVGIcon>
}

const defaultProps: DefaultProps = {
  containerProps: {
    isSelected: false,
  },
  onChange() {},
  SelectedIconComponent: SVGIcon,
  UnselectedIconComponent: SVGIcon,
  selectedIconComponentProps: {
    style: ICON_STYLE,
    name: 'checkmarkCircleFilled',
    color: colors.GREEN_500,
  },
  unselectedIconComponentProps: {
    style: ICON_STYLE,
    name: 'checkmarkCircle',
    color: colors.GRAY_46,
  },
}

type DefaultProps = Required<
  Pick<
    BaseProps,
    | 'onChange'
    | 'containerProps'
    | 'SelectedIconComponent'
    | 'UnselectedIconComponent'
    | 'selectedIconComponentProps'
    | 'unselectedIconComponentProps'
  >
>

type Props = BaseProps & DefaultProps

function SelectableCell(props: Props) {
  const {
    children,
    title,
    description,
    isSelected,
    onChange,
    containerProps,
    SelectedIconComponent = SVGIcon,
    UnselectedIconComponent = SVGIcon,
    selectedIconComponentProps,
    unselectedIconComponentProps,
  } = props

  return (
    // FIXME: onClicks for <div> elements are not a11y friendly.
    <Container
      {...containerProps}
      style={containerProps}
      isSelected={isSelected}
      onClick={onChange}
      data-testid="selectable-cell"
    >
      {title && <Title data-testid="selectable-cell-title">{title}</Title>}
      {description && <Description>{description}</Description>}
      {children && children}
      {isSelected ? (
        <SelectedIconComponent {...selectedIconComponentProps} />
      ) : (
        <UnselectedIconComponent {...unselectedIconComponentProps} />
      )}
    </Container>
  )
}

SelectableCell.defaultProps = defaultProps

export default SelectableCell
