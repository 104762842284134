/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createBrowserHistory,
  type History,
  type Path,
  type LocationState,
  type LocationDescriptorObject,
  type Href,
} from 'history'
import qs from 'query-string'

export interface PatchedLocationDescriptorObject<TQuery, S = LocationState>
  extends LocationDescriptorObject<S> {
  query?: TQuery
}

export interface PatchedHistory<TQuery = any, HistoryLocationState = LocationState>
  extends History<HistoryLocationState> {
  push(path: Path, state?: HistoryLocationState): void
  push(location: PatchedLocationDescriptorObject<TQuery, HistoryLocationState>): void
  replace(path: Path, state?: HistoryLocationState): void
  replace(location: PatchedLocationDescriptorObject<TQuery, HistoryLocationState>): void
  createHref(location: PatchedLocationDescriptorObject<TQuery, HistoryLocationState>): Href
}

function createHistory(history: History = createBrowserHistory()): PatchedHistory {
  // using these to compare methods later for TS to infer signature
  const { push, replace } = history

  function patch(method: 'push' | 'replace' | 'createHref') {
    const originalMethod = history[method]

    // had to cast here to get around an issue where TS was
    // interpreting history[method] as an intersection of the three methods
    // instead of a union when using it on the left hand side of a variable assignment
    // eslint-disable-next-line no-param-reassign
    ;(history[method] as typeof originalMethod) = patchedMethod

    function patchedMethod(
      location: Path | PatchedLocationDescriptorObject<any, LocationState>,
      state?: LocationState
    ) {
      if (typeof location === 'string' && (originalMethod === push || originalMethod === replace)) {
        return (originalMethod as typeof push)(location, state)
      }

      // eslint-disable-next-line prefer-const
      let {
        query = {},
        pathname,
        search,
        ...others
      } = location as PatchedLocationDescriptorObject<any, LocationState>
      pathname = pathname || history.location.pathname

      Object.keys(query).forEach(key => {
        if (query[key] === 'null' || query[key] === 'undefined' || query[key] == null) {
          delete query[key]
        }
      })

      if (window.location.search.includes('debugAnalytics=true')) {
        query = {
          ...query,
          debugAnalytics: true,
        }
      }

      if (search) {
        query = {
          ...qs.parse(search),
          ...query,
        }
      }

      const payload = {
        ...others,
        pathname,
        search: qs.stringify(query),
      }

      return originalMethod(payload)
    }
  }

  patch('push')
  patch('replace')
  patch('createHref')

  return history
}

export default createHistory
