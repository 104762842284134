import React, { type FunctionComponent } from 'react'
import { NavSelect } from '../NavSelect'
import { NavSeparator } from '../NavSeparator'

export const RetailerNavHeaderContent: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <React.Fragment>
    <NavSelect />
    <NavSeparator />
  </React.Fragment>
)
