import { type BannersRoutingConfig } from '../types/banner.types'

export const bannersAdminRoutingConfig: BannersRoutingConfig = {
  list: 'app-admin-banners-list',
  create: 'app-admin-banners-create',
  detailsOld: 'app-admin-banners-details-old',
  details: 'app-admin-banners-details',
  editOld: 'app-admin-banners-edit-old',
  edit: 'app-admin-banners-edit',
  visibilityConditions: 'app-admin-banners-visibility-conditions',
  visibilityConditionCreate: 'app-admin-banners-visibility-conditions-create',
}
