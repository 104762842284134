import { type AnalyticsBrowser } from '@segment/analytics-next'
import { errors } from '../../../error-handling/errors'
import { eventTrackingEnabled } from './eventDebugging'
import { type GlobalAnalytics } from './segmentAnalytics.types'

const noop = () => {
  // noop
}
export const noopSegmentAnalytics = {
  alias: noop,
  group: noop,
  identify: noop,
  page: noop,
  track: noop,
}

export const segmentAnalytics = (): AnalyticsBrowser | typeof noopSegmentAnalytics => {
  const globalWithAnalytics = (global as GlobalAnalytics).analytics

  if (!eventTrackingEnabled()) {
    return noopSegmentAnalytics
  }

  // If the analytics module is not enabled, then we we should error and return early
  if (!globalWithAnalytics) {
    const errorMessage =
      'Segment `analytics` module unavailable - script was missing or failed to load'
    console.warn(errorMessage)
    errors.captureMessage(errorMessage, { level: 'error' })
    return noopSegmentAnalytics
  }

  return globalWithAnalytics
}

export const SEGMENT_OPTIONS = {}
