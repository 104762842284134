import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, AdminNavEntryPoint } from '@retailer-platform/dashboard/routing'
import { tdsPlaygroundAdminRouter } from './routing/tdsPlaygroundAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'tds-playground',
  routes,
  totem: {
    entity: 'account-management-dashboard-tool',
  },
  translations: { EN_US },
  routers: {
    admin: [tdsPlaygroundAdminRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [],
  // NOTE TO DEV: run `yarn ipp domain register <your-system-name>` to register your domain with Totem here
}

export default definition
