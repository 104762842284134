import React from 'react'

export interface SurfaceContext {
  surfaceId: number
  setSurfaceId(number): void
}

export const SurfaceContext = React.createContext<SurfaceContext>({
  surfaceId: 1,
  setSurfaceId: () => {},
})

SurfaceContext.displayName = 'SurfaceContext'
