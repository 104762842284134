import { useCallback, useMemo } from 'react'
import {
  type Partner,
  type Warehouse,
  type WarehouseLocation,
} from '../../../../legacy/common/types'
import {
  type GetRetailerLocalesMeshQuery,
  useGetRetailerLocalesMeshQuery,
  useGetRetailerQuery,
  useGetAllRetailerLocationsForRetailerQuery,
  type FeatureEntitlement,
  type FeatureToggle,
  type InstacartStoreConfiguration,
  LocationFeatureEnum,
} from '../../../../__codegen__/api'
import { makeQueryStaleWhileRevalidate } from '../../../apollo/makeQueryStaleWhileRevalidate'
import { WarehouseLocationFeature } from '../../../../legacy/common/warehouseLocations'
import { type WarehouseContextValue } from '../WarehouseContext'
import { mapWarehouseData } from './dataUtils'

export interface WarehouseWithLocales extends Warehouse {
  locales: GetRetailerLocalesMeshQuery['catalogToolsApiServiceGetLocalesByRetailer']['retailerLocales']
  defaultLocale: GetRetailerLocalesMeshQuery['catalogToolsApiServiceGetLocalesByRetailer']['retailerLocales'][number]
}

export interface PartnerWithLocales extends Partner {
  locales: GetRetailerLocalesMeshQuery['catalogToolsApiServiceGetLocalesByRetailer']['retailerLocales']
  defaultLocale: GetRetailerLocalesMeshQuery['catalogToolsApiServiceGetLocalesByRetailer']['retailerLocales'][number]
}

// Added this for PartnerContext new "warehouses" array.
export interface WarehouseWithFeatures extends Warehouse {
  context: WarehouseContextValue
  featureToggles: FeatureToggle[] //nav bar uses this
  featureEntitlements: FeatureEntitlement[] //nav bar uses this
  storeConfigurations: InstacartStoreConfiguration[]
}

export interface WarehouseWithLocations extends WarehouseWithLocales {
  warehouseLocations: WarehouseLocation[]
  inactiveWarehouseLocations: WarehouseLocation[]
}

export interface WarehouseData {
  warehouse: WarehouseWithLocations
}

export interface WarehouseVariables {
  id: string
}

const useCachedGetRetailerLocalesMesh = makeQueryStaleWhileRevalidate(
  useGetRetailerLocalesMeshQuery,
  { baseCacheKey: 'retailerLocales' }
)

const useCachedGetAllRetailerLocationsForRetailer = makeQueryStaleWhileRevalidate(
  useGetAllRetailerLocationsForRetailerQuery,
  { baseCacheKey: 'retailerLocationsForRetailer' }
)

export const useFetchWarehouse = (variables: WarehouseVariables) => {
  // Modern API data
  const {
    data: retailerData,
    loading: retailerLoading,
    error: retailerError,
    refetch: retailerRefetch,
  } = useGetRetailerQuery({
    variables: { retailerId: variables.id },
    skip: variables.id == '0' || variables.id == 'undefined',
  })

  const featureToggles = useMemo(() => retailerData?.retailer?.featureToggles, [retailerData])
  const featureEntitlements = useMemo(
    () => retailerData?.retailer?.featureEntitlements,
    [retailerData]
  )

  const locationFeatures = useMemo(
    () =>
      retailerData?.retailer?.locationFeatures?.map(e => {
        if (e == LocationFeatureEnum.RetailerAlcoholDelivery) {
          return WarehouseLocationFeature.RetailerAlcoholDelivery
        } else {
          return WarehouseLocationFeature.RetailerPickup
        }
      }),
    [retailerData]
  )

  const isCacheEnabled = useMemo(
    () =>
      featureToggles?.some(t => t.name === 'rt_retailer_stale_while_revalidate_enabled') ?? false,
    [featureToggles]
  )

  const partnerName = retailerData?.retailer?.partner?.name
  const partnerType = retailerData?.retailer?.partner?.partnerType

  // Locale data
  const {
    data: localesData,
    error: localesError,
    loading: localesLoading,
    refetch: localesRefetch,
    errorWhileRevalidating: localesRevalidationError,
  } = useCachedGetRetailerLocalesMesh(
    {
      variables: { retailerId: variables.id },
      skip: variables.id == '0' || variables.id == 'undefined',
    },
    { skipCache: !isCacheEnabled }
  )

  // Retailer location data
  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
    refetch: locationsRefetch,
    errorWhileRevalidating: locationsRevalidationError,
  } = useCachedGetAllRetailerLocationsForRetailer(
    {
      variables: { retailerId: variables.id },
      skip: variables.id == '0' || variables.id == 'undefined',
    },
    { skipCache: !isCacheEnabled }
  )

  /**
   * Combine the warehouse into a single unit with its locales.
   * This will wait for both locales and the warehouse locales.
   */
  const warehouse = useMemo(() => {
    if (!retailerData?.retailer || !locationsData?.retailerLocationSearchArray || localesLoading)
      return null

    // Map data from various sources to WarehouseWithLocations shape
    const warehouseData = mapWarehouseData({
      retailerData: retailerData?.retailer,
      localesData: localesData?.catalogToolsApiServiceGetLocalesByRetailer?.retailerLocales || [],
      locationsData: locationsData?.retailerLocationSearchArray,
    })

    return warehouseData
  }, [retailerData, locationsData, localesData, localesLoading])

  const combinedRefetch = useCallback(() => {
    retailerRefetch()
    locationsRefetch()
    localesRefetch()
  }, [retailerRefetch, locationsRefetch, localesRefetch])

  const error = retailerError || locationsError || localesError
  const errorWhileRevalidating = localesRevalidationError || locationsRevalidationError

  return {
    warehouse,
    partnerName,
    partnerType,
    locationFeatures,
    featureToggles,
    featureEntitlements,
    error,
    errorWhileRevalidating,
    loading: retailerLoading || locationsLoading || localesLoading,
    refetch: combinedRefetch,
  }
}
