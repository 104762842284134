import React, { type FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { DashButton } from '../../../../../../../dash-blocks/dash-button/DashButton'
import { LocationSelectorActions } from '../utils/locationSelector.types'
import { type OnClick } from '../../../../../../../../utils/react/event.types'

interface Props {
  onClick: OnClick
  actionType: LocationSelectorActions
}

export const StoreGroupsFormLocationSelectorToggle: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ onClick, actionType }) => {
  if (actionType === LocationSelectorActions.Remove) {
    return (
      <DashButton
        variant="outline-danger"
        onClick={onClick}
        testId={'store-location-selector-action-remove'}
      >
        <FormattedMessage id={`common.remove`} />
      </DashButton>
    )
  }

  return (
    <DashButton onClick={onClick} testId={'store-location-selector-action-add'}>
      <FormattedMessage id={`common.add`} />
    </DashButton>
  )
}
