// Please create a new permission per resource
// https://github.com/instacart/carrot/blob/master/retailer-tools/retailer-platform-api/app/support/permission.rb
// Example: https://github.com/instacart/carrot/pull/464461
// More instructions here: https://instacart.atlassian.net/wiki/spaces/Catalog/pages/2174812846
export enum Permission {
  BusinessesView = 'businesses.view',
  BusinessesModify = 'businesses.modify',
  BusinessProgramView = 'business_programs.view',
  BusinessProgramModify = 'business_programs.modify',
}
