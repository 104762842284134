import { type QueryResult } from '@apollo/react-common'
import { type QueryHookOptions } from '@apollo/react-hooks'
import { useDeepMemo } from '@retailer-platform/shared-common'
import localforage from 'localforage'
import { useEffect } from 'react'
import { type ApolloError } from 'apollo-client'
import { useLocalForageKey } from '../local-storage/useLocalForageKey.hooks'

export type QueryHook<TData, TVariables> = (
  options: QueryHookOptions<TData, TVariables>
) => QueryResult<TData, TVariables>

// Cache is namespaced, in case we need to fully clear it without touching other keys
const cacheNamespace = 'staleWhileRevalidate'
// This can be useful to invalidate cache -- recommended to bump if underlying data changes
const cacheVersion = 'v0'

export type StaleWhileRevalidateOptions = {
  baseCacheKey: string
}

/**
 * This will receive a query hook and add localstorage caching functionality to it.
 * Note that this will still call the underlying resource, and automatically update the cache once it gets
 * the new information.
 *
 * This will change the return slightly based on whether the item is in cache or not. It introduces a new key called
 * `errorWhileRevalidating` that will contain the contents of `error` when the item is in cache.
 *
 * This allows the consumer to do something different (e.g. showing a stale data warning) instead of fully erroring out.
 * For more details on how the return changes, refer to the following table:
 *
 * | in cache                     | data                 | loading  | error     | errorWhileRevalidating | ...rest  |
 * |------------------------------|----------------------|----------|-----------|------------------------|----------|
 * | unknown (loading cache data) | undefined            | true     | undefined | undefined              | original |
 * | yes                          | original || cached   | false    | undefined | original               | original |
 * | no (or skipCache: true)      | original             | original | original  | undefined              | original |
 *
 * @param useQueryHook
 * @param options
 * @returns
 */
export const makeQueryStaleWhileRevalidate =
  <
    TData extends {},
    TVariables extends {},
    THook extends QueryHook<TData, TVariables>,
    THookResult extends ReturnType<THook> & {
      /**
       * Will contain the error from this request as long as there was an error and
       * this request was cached. This is meant so the normal error can be 'swallowed'
       * while still allowing us to act on it.
       */
      errorWhileRevalidating?: ApolloError
    }
  >(
    useQueryHook: THook,
    options: StaleWhileRevalidateOptions
  ) =>
  (
    queryOptions: Parameters<THook>[0],
    cacheOptions?: {
      skipCache?: boolean
    }
  ): THookResult => {
    const cacheKey = useDeepMemo(
      () =>
        `${cacheNamespace}:${cacheVersion}:${options.baseCacheKey}:${JSON.stringify(
          queryOptions.variables
        )}`,
      // @ts-ignore, types for useDeepMemo are not right
      [queryOptions.variables]
    )

    const {
      data: cachedData,
      loading: cacheLoading,
      setData,
      hasKey,
    } = useLocalForageKey<TData>(cacheKey)
    const result = useQueryHook(queryOptions)

    // Persist data to cache
    // Note that cached items follow an LRU eviction policy by default
    // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Browser_storage_limits_and_eviction_criteria#lru_policy
    useEffect(() => {
      if (!result.error && result.data) setData(result.data)
    }, [setData, result.data, result.error])

    // If cache is not enabled, return results as-is
    if (cacheOptions?.skipCache) return result as THookResult

    return {
      ...result,
      // This will potentially swallow the error, as long as the key exists
      error: hasKey ? undefined : result.error,
      errorWhileRevalidating: hasKey ? result.error : undefined,
      // Return cached data, if there is data from origin, return that instead
      data: result.data || cachedData,
      // Return loading state of cache, or if we have a key already, return false. otherwise, return the original loading state
      loading: cacheLoading || (hasKey ? false : result.loading),
    } as THookResult
  }

/**
 * Gets cache keys for the namespace
 */
const getKeys = async ({
  version,
  startsWith,
  inverseSelection,
}: {
  version?: string
  startsWith?: string
  inverseSelection?: boolean
}) => {
  const prefix = [cacheNamespace, version, startsWith].filter(Boolean).join(':')
  const keys = await localforage.keys()

  return (
    keys
      // always get the full namespace, we then filter on top of that
      // this ensures that we only work with keys from this namespace,
      // even when inverting the selection
      .filter(key => key.startsWith(cacheNamespace))
      .filter(key => {
        const matches = key.startsWith(prefix)

        return inverseSelection ? !matches : matches
      })
  )
}

/**
 * Deletes all keys for stale while revalidate cache only, whose version is
 * different to the current one.
 *
 * Is this even really needed? LRU cache ensures that unused keys get deleted eventually, based on their use
 * see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Browser_storage_limits_and_eviction_criteria#lru_policy
 *
 * as such, changing the version would still be enough to invalidate the older cache
 * entries, while eventually deleting them.
 */
export const useQueryStaleWhileRevalidateCleanup = () => {
  useEffect(() => {
    // Delete any keys older than the current cacheVersion
    getKeys({ version: cacheVersion, inverseSelection: true })
      .then(keys => keys.forEach(k => localforage.removeItem(k)))
      .catch(() => console.error('Failed to delete keys'))
  }, [])
}
