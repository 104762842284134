import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const viewRetailerSecurityAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ViewWarehouseSecurity],
}

export const modifyRetailerSecurityAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ManageWarehouseSecurity],
}
