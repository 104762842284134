import React, { type FunctionComponent, type ReactNode } from 'react'
import styled from '@emotion/styled'
import { type SerializedStyles } from '@emotion/react'
import { colors, spacing } from '../../foundation'
import { TextButton } from '../text-button/TextButton'

export interface PageHeaderProps {
  title: ReactNode
  actions?: ReactNode
  wrapperStyle?: SerializedStyles
  subTitle?: string
  subTitleOnClick?: () => void
  info?: ReactNode

  /**
   * Some older usages of this component were expecting some padding on the bottom of
   * this component to position things in the page. Used to maintain that old design
   * going forward.
   */
  addBottomPadding?: boolean
  removeAllPadding?: boolean
}

const Wrapper = styled.div((props: { addBottomPadding?: boolean; removeAllPadding?: boolean }) => {
  const wrapperHeight = props.removeAllPadding ? spacing.X32 : spacing.X64
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: props.removeAllPadding ? '' : `1px solid ${colors.GRAYSCALE.X20}`,
    padding: props.removeAllPadding ? `${spacing.X0} 0` : `${spacing.X8} ${spacing.X16}`,
    height: wrapperHeight,
    marginBottom: props.addBottomPadding ? spacing.X16 : undefined,
    flexShrink: 0,
  }
})

const VerticalWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-start',
  alignContent: 'start',
})

const BreadcrumbPadding = styled.div({
  paddingLeft: spacing.X8,
})

const RightContent = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  button: {
    height: 40,
  },
})

const LeftContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const PageHeader: FunctionComponent<React.PropsWithChildren<PageHeaderProps>> = ({
  title,
  actions,
  wrapperStyle,
  subTitle,
  subTitleOnClick,
  info,
  addBottomPadding,
  removeAllPadding,
}) => {
  let titleComponent = <div>{title}</div>

  if (subTitle) {
    let subtitleText = <div css={{ fontSize: 12 }}>{subTitle}</div>

    if (subTitleOnClick) {
      subtitleText = <TextButton onClick={subTitleOnClick}>{subtitleText}</TextButton>
    }

    titleComponent = (
      <VerticalWrapper>
        {titleComponent}
        <BreadcrumbPadding>{subtitleText}</BreadcrumbPadding>
        {/* this BreadcrumbPadding is helpful since the `titleComponent` is always(?) a breadcrumb component
        and Breadcrumbs have some baked in padding. so this helps left align the title & subtitle. */}
      </VerticalWrapper>
    )
  }

  return (
    <Wrapper
      addBottomPadding={addBottomPadding}
      removeAllPadding={removeAllPadding}
      css={wrapperStyle}
    >
      <LeftContent>
        {titleComponent}
        <div css={{ marginLeft: spacing.X8 }}>{info}</div>
      </LeftContent>

      <RightContent>
        <div>{actions}</div>
      </RightContent>
    </Wrapper>
  )
}
