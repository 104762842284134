import dayjs from 'dayjs'
import { type QueryParamEncodedType } from './queryParams.types'

const dateFormat = 'YYYY-MM-DD'

/**
 * Checks if the provided string matches the expected dateFormat.
 *
 * @param dateString the string date to test
 * @returns a bool
 */
const isValidDateFormat = (dateString: string) => dayjs(dateString, dateFormat, true).isValid()

/**
 * Safely converts a string into a Day.js instance, if it matches the expected dateFormat, otherwise returns undefined.
 *
 * @param dateString the string date to attempt parsing
 * @returns a Day.js instance or undefined
 */
const stringToDayjs = (dateString: string) =>
  isValidDateFormat(dateString) ? dayjs(dateString, dateFormat) : undefined

export const DayjsDateParam = {
  encode: (dayjsDate: dayjs.Dayjs) => dayjsDate.format(dateFormat),
  decode: (dateString: QueryParamEncodedType) =>
    stringToDayjs(Array.isArray(dateString) ? dateString[0] : dateString),
}
