// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { useMediaQuery } from '@mantine/hooks'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { type MantineBreakpointsValues } from '@mantine/core'

export const breakpoints: Omit<MantineBreakpointsValues, 'xl'> = {
  xs: '320', // minimum width
  sm: '600', // tablet
  md: '960', // desktop
  lg: '1280', // oversized
}

/**
 * Returns the current breakpoint name based on min-width media queries corresponding to
 * the Mantine breakpoints defined in ThemeProvider.
 *
 * @returns xs, sm, md, or lg
 */
export const useGetCurrentBreakpoint = () => {
  const matches = {
    lg: useMediaQuery(`(min-width: ${breakpoints.lg}px)`),
    md: useMediaQuery(`(min-width: ${breakpoints.md}px)`),
    sm: useMediaQuery(`(min-width: ${breakpoints.sm}px)`),
    xs: useMediaQuery(`(min-width: ${breakpoints.xs}px)`),
  }

  return (Object.keys(matches) as Array<keyof typeof matches>).find(key => matches[key]) || 'xs'
}
