export enum AdminNavEntryPoint {
  Merchandising = 'merchandising',
  Account = 'account',
  CatalogAdmin = 'catalog-admin',
  CampaignManagement = 'campaign-management',
  ConnectedStores = 'connected-stores',
  FinancialHardening = 'financial-hardening',
  LandingPageSystem = 'landing-page-system',
  MarketplaceOps = 'marketplace-ops',
  RetailerManagement = 'retailer-management',
  RecipesAdmin = 'recipes-admin',
  Business = 'business',
  ApprovalsManagement = 'approvals-management',
  Analytics = 'analytics',
}

export enum AdminNavEntryPointV2 {
  Analytics = 'analytics',
  CatalogAdmin = 'catalog-admin',
  ConnectedStores = 'connected-stores',
  Merchandising = 'merchandising',
  Marketing = 'marketing',
  Operations = 'operations',
  UserMenu = 'userMenu',
}
