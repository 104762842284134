import { type RetailerLocationSelectValueType } from '../../legacy/dash-components/retailer-location-select/utils/options.types'
import { makeGenericPartialStringObjectParamEncoder } from './object.utils'
import { type QueryParamEncodedType } from './queryParams.types'

const genericEncoder = makeGenericPartialStringObjectParamEncoder<RetailerLocationSelectValueType>()

const decoder = (queryStr: QueryParamEncodedType): RetailerLocationSelectValueType | undefined => {
  const partialResult = genericEncoder.decode(queryStr)

  if (!partialResult || (!partialResult.storeGroupId && !partialResult.retailerLocationId)) {
    return undefined
  }

  return {
    storeGroupId: partialResult.storeGroupId || null,
    retailerLocationId: partialResult.retailerLocationId || null,
  }
}

export const retailerLocationSelectQueryParamEncoder = {
  encode: genericEncoder.encode,
  decode: decoder,
}
