import React from 'react'
import {
  NotificationLevel,
  NotificationsDisplay,
  notify,
} from '@retailer-platform/shared-components'
import { Z_INDEX_FIRST } from '../../common/theme'
import { NotificationsContext, type LegacyNotifyFn } from './NotificationsContext'

const contextNotify: LegacyNotifyFn = (notification, notificationStyle = 'default', dismissAfter) =>
  notify({
    contents: notification,
    timeout: dismissAfter,
  })

const contextNotifyError: LegacyNotifyFn = (
  notification,
  notificationStyle = 'default',
  dismissAfter
) =>
  notify({
    level: NotificationLevel.Error,
    contents: notification,
    timeout: dismissAfter,
  })

const contextValue = { notify: contextNotify, notifyError: contextNotifyError }
/**
 * This exists solely to keep a similar signature to the old notifications provider.
 * Will be used while we transition away from it.
 */
export const NotificationsProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = props => (
  <NotificationsContext.Provider value={contextValue}>
    <NotificationsDisplay css={{ left: 55, zIndex: Z_INDEX_FIRST }} />
    {props.children}
  </NotificationsContext.Provider>
)

export default NotificationsProvider
