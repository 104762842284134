// @ts-nocheck legacy file transformed to TS
import React, { Component } from 'react'
import instacart from './instacart'
import { fetchAisles } from './api'
import withRetailerRouter from './withRouter'

const withDepartmentAisles = View => {
  @withRetailerRouter
  class WithDepartmentAisles extends Component {
    state = {
      departments: null,
      aisles: null,
      departmentsLoading: true,
      aislesLoading: false,
    }

    async componentDidMount() {
      const { partner_id, warehouse_id } = this.props.match.params
      const { data: departments } = await instacart.go.get(
        `/v1/partners/${partner_id}/warehouses/${warehouse_id}/departments`
      )
      this.setState({ departments, departmentsLoading: false })
    }

    onDepartmentSelected = async departmentId => {
      this.setState({
        aisles: null,
        aislesLoading: true,
      })

      if (!departmentId) {
        return
      }

      const { data: aisles } = await fetchAisles({
        ...this.props.match.params,
        department_id: departmentId,
        skip_product_count: 'true',
      })

      this.setState({
        aisles,
        aislesLoading: false,
      })
    }

    render() {
      return (
        <View {...this.props} {...this.state} onDepartmentSelected={this.onDepartmentSelected} />
      )
    }
  }

  WithDepartmentAisles.displayName = `WithDepartmentAisles(${View.displayName || View.name})`

  return WithDepartmentAisles
}

export default withDepartmentAisles
