import {
  useGetTotemEntityQuery,
  type GetTotemEntityQueryVariables,
  useGetTotemSystemQuery,
} from '../../__codegen__/api'

export const useTotem = (params: GetTotemEntityQueryVariables) => {
  const {
    data: entityData,
    loading: entityLoading,
    error: entityError,
  } = useGetTotemEntityQuery({
    variables: params,
    skip: !params.externalId,
  })

  const systemName =
    entityData?.instacart_infra_totem_v1_TotemService_GetEntity?.entity?.system_name

  const {
    data: systemData,
    loading: systemLoading,
    error: systemError,
  } = useGetTotemSystemQuery({
    variables: { systemName },
    skip: !systemName,
  })

  return {
    data: systemData?.instacart_infra_totem_v1_TotemService_GetSystem?.system,
    loading: entityLoading || systemLoading,
    error: entityError || systemError,
  }
}
