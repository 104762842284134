import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { cashflowAdminAccessControl } from '../access-control/cashflowAdminAccess.configuration'

const CashflowPriceAuditLandingPage = lazy(
  () => import('../pages/price-audit-landing/CashflowPriceAuditLandingPage')
)
const CashflowPriceAuditDetailsPage = lazy(
  () => import('../pages/price-audit-details/CashflowPriceAuditDetailsPage')
)
const CashflowSpreadLandingPage = lazy(
  () => import('../pages/spread-landing/CashflowSpreadLandingPage')
)
const CashflowSpreadDetailsPage = lazy(
  () => import('../pages/spread-details/CashflowSpreadDetailsPage')
)

const CashflowPriceAuditFeedbackPage = lazy(
  () => import('../pages/price-audit-feedback/CashflowPriceAuditFeedbackPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const cashflowAdminRouter = (
  <DomainRoute route="app-admin-cashflow">
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-cashflow-price-audit-landing"
        component={CashflowPriceAuditLandingPage}
        accessControl={cashflowAdminAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-cashflow-price-audit-feedback"
        component={CashflowPriceAuditFeedbackPage}
      />
      <DomainRoute
        exact
        route="app-admin-cashflow-price-audit-details"
        component={CashflowPriceAuditDetailsPage}
        accessControl={cashflowAdminAccessControl}
      />
      <DomainRoute
        exact
        route="app-admin-cashflow-spread-landing"
        component={CashflowSpreadLandingPage}
      />
      <DomainRoute
        exact
        route="app-admin-cashflow-spread-details"
        component={CashflowSpreadDetailsPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
