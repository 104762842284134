import { dangerTheme } from '../../../common/utils/themes/snacks.themes'
import {
  type DashButtonSize,
  type DashButtonVariant,
  type DashButtonSnacksSize,
  type DashButtonSnacksStyle,
  type DashButtonSnacksTheme,
} from './dashButton.types'

export const mapDashButtonSizeToSnacksSize = (size?: DashButtonSize): DashButtonSnacksSize => {
  switch (size) {
    case 'small':
      return 'tiny'
    case 'large':
      return 'standard'
    default:
      return 'small'
  }
}

export const mapDashButtonVariantToSnacksStyle = (
  variant?: DashButtonVariant
): DashButtonSnacksStyle => {
  if (!variant) {
    return 'primary'
  }

  if (variant.startsWith('flat')) {
    return 'flat'
  }

  if (variant.startsWith('outline')) {
    return 'secondary'
  }

  return 'primary'
}

export const mapDashButtonVariantToSnacksTheme = (
  variant?: DashButtonVariant
): DashButtonSnacksTheme => {
  if (!variant) return undefined

  if (variant.endsWith('danger')) {
    return dangerTheme
  }

  return undefined
}
