import React, { type FunctionComponent, lazy } from 'react'
import { type DashRouteComponentProps, DashSwitch } from '@retailer-platform/dashboard/routing'
import { HideMarketplace, ViewType } from '@retailer-platform/dashboard/gin-and-tonic'
import { DomainRedirect, DomainRoute } from '../utils/domain/routingComponents'
import { widgetsConfigViewAccessControl } from '../access-control/widgetsAccess.configuration'

const Index = lazy(() => import('../pages/config/Index'))

const Page = lazy(() => import('../pages/config/Page'))

// @hygen:inject page-import

interface PageWrapperProps
  extends DashRouteComponentProps<{ name: string; storeConfigId: string }> {}

const PageWrapper: FunctionComponent<PageWrapperProps> = props =>
  // Since the `Page` route doesn't have a store picker, when the user switches
  // the partner from the top bar, `Page` gets redirected to a URL with
  // `storeConfigId = 0` and becomes unusable because it requires
  // `storeConfigId`. Redirect to the index page instead.
  props.match.params.storeConfigId === '0' ? (
    <DomainRedirect toRoute="widgets-config" to="." />
  ) : (
    <Page {...props} />
  )

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const widgetsRouter = (
  <DomainRoute route="widgets">
    <DashSwitch>
      <DomainRoute
        exact
        route="widgets-config"
        component={Index}
        accessControl={widgetsConfigViewAccessControl}
        scopePicker={{
          titles: ['widgetsDomain.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'view',
          storeConfigFilter: HideMarketplace,
        }}
      />
      <DomainRoute
        exact
        route="widgets-config-name"
        component={PageWrapper}
        accessControl={widgetsConfigViewAccessControl}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
