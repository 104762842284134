import React, { type FunctionComponent, useCallback } from 'react'
import { SVGIcon, colors } from 'ic-snacks'
import { injectIntl } from 'react-intl'
import styled from '@emotion/styled'
import { type InjectedDashIntlProps } from '../../../../../../../../intl/intl.types'
import TextField from '../../../../../../../components/Forms/TextField'
import { type OnChange } from '../../../../../../../../utils/react/event.types'
import FiltersContainer from '../../../../../../../routes/reports/files/Filters/FiltersContainer'

const searchIcon = <SVGIcon name="search" color={colors.GRAY_46} style={{ height: '18px' }} />

const LocationSelectorFilterContainer = styled(FiltersContainer)`
  padding: 15px 30px;
  border-bottom: 1px solid ${colors.GRAY_93};
`

interface Props extends InjectedDashIntlProps {
  filterValue: string
  setFilterValue: (nextValue: string) => void
}

const _LocationsListFilters: FunctionComponent<React.PropsWithChildren<Props>> = ({
  intl,
  filterValue,
  setFilterValue,
}) => {
  const onChange: OnChange = useCallback(
    ({ target: { value: nextValue } }) => setFilterValue(nextValue),
    [setFilterValue]
  )

  return (
    <LocationSelectorFilterContainer>
      <TextField
        name="storeGroupName"
        type="text"
        value={filterValue}
        onChange={onChange}
        elementAttributes={{
          placeholder: intl.formatMessage({
            id: 'settings.storeGroups.addModal.filters.name.placeholder',
          }),
          'data-testid': 'locations-list-filter',
        }}
        prefix={searchIcon}
        fullWidth
      />
    </LocationSelectorFilterContainer>
  )
}

export const LocationsListFilters = injectIntl(_LocationsListFilters)
