const EN_US = {
  'caperDomain.title': 'Caper',
  'caperDomain.educationalBanner.heading':
    'Drive top-line growth and labor efficiency with AI-powered Caper smart carts',
  'caperDomain.educationalBanner.information':
    'Customers can conveniently scan products as they shop, access promotions, coupons, loyalty offers, and brand advertisements—all through the cart’s digital screen—and seamlessly complete their checkout directly from the cart.',
  'caperDomain.educationalBanner.buttonText': 'Book a Demo',
} as const

export default EN_US
