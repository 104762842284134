import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_ADMIN_BUSINESS = `${URL_APP_ADMIN}/business`
const URL_ADMIN_BUSINESS_PROGRAM_CREATE = `${URL_ADMIN_BUSINESS}/programs/create`
const URL_ADMIN_BUSINESS_PROGRAM_LIST = `${URL_ADMIN_BUSINESS}/programs`
// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'app-admin-business'>
  | DashRouteType<'app-admin-business-program-list'>
  | DashRouteType<'app-admin-business-program-create'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-business': URL_ADMIN_BUSINESS,
  'app-admin-business-program-create': URL_ADMIN_BUSINESS_PROGRAM_CREATE,
  'app-admin-business-program-list': URL_ADMIN_BUSINESS_PROGRAM_LIST,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
