import React, { useCallback, useMemo, useState } from 'react'
import { type NotificationType } from './NotificationsDisplay.types'
import { NotificationsDisplayWrapper } from './Notifications.styles'
import { Notification } from './Notification'
import { useNotificationEvents } from './utils'

export const NotificationsDisplay: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = props => {
  const [notifications, setNotifications] = useState<Map<string, NotificationType>>(() => new Map())

  const removeNotification = useCallback((id: string) => {
    setNotifications(prev => {
      prev.delete(id)
      return new Map(prev)
    })
  }, [])

  useNotificationEvents('clearNotification', ({ id }) => removeNotification(id))
  useNotificationEvents('notification', notification => {
    setNotifications(() => new Map(notifications.set(notification.id, notification)))
  })

  const notificationsList = useMemo(() => Array.from(notifications.values()), [notifications])

  return (
    <NotificationsDisplayWrapper {...props}>
      {notificationsList.map(notification => (
        <Notification
          key={notification.id}
          {...notification}
          removeNotification={removeNotification}
        />
      ))}
    </NotificationsDisplayWrapper>
  )
}
