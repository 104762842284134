export enum Metrics {
  SUM_GMV = 'SUM_GMV',
  COUNT_ORDER = 'COUNT_ORDER',
  AVG_GMV = 'AVG_GMV',
  COUNT_USER = 'COUNT_USER',
  AVG_RATING = 'AVG_RATING',
  AVG_USER_PER_STORE = 'AVG_USER_PER_STORE',
  AVG_GMV_PER_STORE = 'AVG_GMV_PER_STORE',
  AVG_FIRST_ORDER_PER_STORE = 'AVG_FIRST_ORDER_PER_STORE',
  COUNT_FIRST_ORDER = 'COUNT_FIRST_ORDER',
  MAX_UPDATED_AT_DATE_TIME_UTC = 'MAX_UPDATED_AT_DATE_TIME_UTC',
  SUM_ERV = 'SUM_ERV',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  MONTH_PT = 'MONTH_PT',
  WAREHOUSE = 'WAREHOUSE',
  ORDER_SOURCE = 'ORDER_SOURCE',
  ORDER_SOURCE_WITH_DELIVERY = 'ORDER_SOURCE_WITH_DELIVERY',
  PICKUP = 'PICKUP',
  WAREHOUSE_LOCATION_GROUP = 'WAREHOUSE_LOCATION_GROUP',
  ALCOHOL = 'ALCOHOL',
  EXPRESS = 'EXPRESS',
  LOYALTY = 'LOYALTY',
  EBT = 'EBT',
  FIRST_ORDER = 'FIRST_ORDER',
  VIRTUAL_WAREHOUSE = 'VIRTUAL_WAREHOUSE',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  PARTNER_GEO_DIVISION = 'PARTNER_GEO_DIVISION',
  ORDER_CURRENCY = 'ORDER_CURRENCY',
  REGION = 'REGION',
  FULFILLMENT_TYPE = 'FULFILLMENT_TYPE',
  FSA_HSA_ORDER = 'FSA_HSA_ORDER',
  WAREHOUSE_LOCATION_CODE = 'WAREHOUSE_LOCATION_CODE',
  RX_ORDER = 'RX_ORDER',
  PLATFORM = 'PLATFORM',
  INSIGHTS_PORTAL_REPORTING_PLATFORM = 'INSIGHTS_PORTAL_REPORTING_PLATFORM',
  DELIVERY_STATE = 'DELIVERY_STATE',
}

export enum GroupBys {
  DATE_PT = 'DATE_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  MONTH_PT = 'MONTH_PT',
  WAREHOUSE = 'WAREHOUSE',
  ORDER_SOURCE = 'ORDER_SOURCE',
  ORDER_SOURCE_WITH_DELIVERY = 'ORDER_SOURCE_WITH_DELIVERY',
  PICKUP = 'PICKUP',
  ALCOHOL = 'ALCOHOL',
  EXPRESS = 'EXPRESS',
  LOYALTY = 'LOYALTY',
  EBT = 'EBT',
  FIRST_ORDER = 'FIRST_ORDER',
  VIRTUAL_WAREHOUSE = 'VIRTUAL_WAREHOUSE',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  PARTNER_GEO_DIVISION = 'PARTNER_GEO_DIVISION',
  ORDER_CURRENCY = 'ORDER_CURRENCY',
  REGION = 'REGION',
  FULFILLMENT_TYPE = 'FULFILLMENT_TYPE',
  DELIVERY_STATE = 'DELIVERY_STATE',
  FSA_HSA_ORDER = 'FSA_HSA_ORDER',
  RX_ORDER = 'RX_ORDER',
}
