import { useCallback, useEffect } from 'react'
import type dayjs from 'dayjs'
import {
  InsightsPortalOwner,
  InsightsPortalSchema,
  useGetInsightsPortalFilterOptionsLazyQuery,
  useGetInsightsPortalMetricsQuery,
} from '../../api'
import { useInsightsPortalAuthorizationContext } from '../../utils/authorization-context.hooks'
import { useFilterParamsContext } from '../../utils/filter-params/filterParamsContext'
import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformApiOptionsIntoDashboardOptions,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'
import { type FilterParamsState } from '../../utils/filter-params/filterParams.types'
import { useFormatValue } from '../../utils/format-value.hooks'
import { useDomainMessages } from '../../utils/domain/intl'
import { calculateComparisonPeriod } from '../../utils/insights-portal.utils'
import { useInsightsPortalQueryTags } from '../../utils/query-tags.hooks'
import { useDataSource } from '../../utils/data-source.hooks'
import { Metrics } from './index'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig('state', 'ZONE_STATE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig('region', 'REGION', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'orderSource',
    'ORDER_SOURCE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'popularSearchTerm',
    'POPULAR_SEARCH_TERM',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'guestUser',
    'GUEST_USER',
    optionsArrayToInFilterSelection('stringValue')
  ),
]

export const searchTermSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const mapApiNameToDashboardName = {
  WAREHOUSE: 'retailer',
  ZONE_STATE: 'state',
  REGION: 'region',
  ORDER_SOURCE: 'orderSource',
  POPULAR_SEARCH_TERM: 'popularSearchTerm',
  GUEST_USER: 'guestUser',
}

export const transformSearchApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  ZONE_STATE: 'stringValue',
  REGION: 'stringValue',
  ORDER_SOURCE: 'stringValue',
  POPULAR_SEARCH_TERM: 'stringValue',
  GUEST_USER: 'stringValue',
} as const

export const transformFilterSelectionsToSearchSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)

export const useSearchTermFilterOptions = () => {
  const [getFilters, { data, loading, error }] = useGetInsightsPortalFilterOptionsLazyQuery()

  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const { partnerIds, retailerIds, retailerLocationIds } = useInsightsPortalAuthorizationContext()

  const dataSource = useDataSource()

  const queryTags = useInsightsPortalQueryTags()

  useEffect(() => {
    getFilters({
      variables: {
        schema: InsightsPortalSchema.SearchTerm,
        partnerIds,
        retailerIds,
        retailerLocationIds,
        filters: transformFilterSelectionsToSearchSchemaFilters(selectedFiltersValues, dates),
        owner: InsightsPortalOwner.RetailerTools,
        queryTags,
        dataSource,
      },
    })
  }, [
    selectedFiltersValues,
    getFilters,
    dates,
    partnerIds,
    retailerIds,
    retailerLocationIds,
    dataSource,
    queryTags,
  ])

  const filterOptions = transformApiOptionsIntoDashboardOptions(
    data?.insightsPortalServiceGetInsightsPortalFilterOptions?.filterOptions,
    transformSearchApiOptionsConfig
  )
  return {
    filterOptions,
    loading,
    error,
  }
}

const useSearchTermMetrics = (
  selectedFiltersValues: FilterParamsState['selectedFiltersValues'],
  dates: { from: dayjs.Dayjs; to: dayjs.Dayjs },
  metrics: Metrics[]
) => {
  const { partnerIds, retailerIds, retailerLocationIds } = useInsightsPortalAuthorizationContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')
  const currentPeriodDateRange = {
    from,
    to,
  }

  const { data, loading } = useGetInsightsPortalMetricsQuery({
    variables: {
      schema: InsightsPortalSchema.SearchTerm,
      partnerIds,
      retailerIds,
      retailerLocationIds,
      metrics,
      filters: transformFilterSelectionsToSearchSchemaFilters(
        selectedFiltersValues,
        currentPeriodDateRange
      ),
      dataSource: useDataSource(),
    },
  })

  return {
    data: data?.insightsPortalServiceGetInsightsPortalMetricValues?.metricValues || [],
    loading,
  }
}

export const useCurrentPeriodSearchTermMetrics = (metrics: Metrics[]) => {
  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')

  const currentPeriodDateRange = {
    from,
    to,
  }

  return useSearchTermMetrics(selectedFiltersValues, currentPeriodDateRange, metrics)
}

export const useLastPeriodSearchTermMetrics = (metrics: Metrics[]) => {
  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')

  const lastPeriodDateRange = calculateComparisonPeriod(from, to, dates.comparisonType)

  return useSearchTermMetrics(selectedFiltersValues, lastPeriodDateRange, metrics)
}

export const useSearchTermMetricValueFormatter = () => {
  const { formatInteger, formatPercentage } = useFormatValue()

  const formatter = useCallback(
    (metric: Metrics, value: number) => {
      const formatters = [
        { metric: Metrics.COUNT_SEARCH, formatter: formatInteger, compact: false },
        {
          metric: Metrics.SEARCH_CONVERSION,
          formatter: formatPercentage,
          compact: false,
        },
        { metric: Metrics.COUNT_NULL_SEARCH, formatter: formatInteger, compact: false },
      ]

      const metricFormatter = formatters.find(f => f.metric === metric)

      if (metricFormatter) {
        return metricFormatter.formatter(value, metricFormatter.compact)
      }
      return value.toLocaleString()
    },
    [formatInteger, formatPercentage]
  )

  return {
    formatter,
  }
}

export const useSearchTermMetricLabels = () =>
  useDomainMessages({
    COUNT_SEARCH: 'insightsPortalDomain.searchDashboard.metrics.countSearch',
    SEARCH_CONVERSION: 'insightsPortalDomain.searchDashboard.metrics.searchConversion',
    COUNT_NULL_SEARCH: 'insightsPortalDomain.searchDashboard.metrics.countNullSearch',
    CONVERSION_GAP: 'insightsPortalDomain.searchDashboard.metrics.conversionGap',
  })
