const EN_US = {
  'webhookConfigurationDomain.title': 'Webhook Configurations',
  'webhookConfigurationDomain.devTab.label': 'Dev',
  'webhookConfigurationDomain.productionTab.label': 'Production',
  'webhookConfigurationDomain.devTab.banner.message':
    'New configuration in Dev environment will take up to 5 minutes to sync.',
  'webhookConfigurationDomain.createNewButton': 'Create New',
  'webhookConfigurationDomain.columnHeaders.name': 'Configuration Name',
  'webhookConfigurationDomain.columnHeaders.url': 'URL',
  'webhookConfigurationDomain.columnHeaders.events': 'Events',
  'webhookConfigurationDomain.columnHeaders.lastUpdated': 'Last Updated',
  'webhookConfigurationDomain.editText': 'Edit',
  'webhookConfigurationDomain.deleteText': 'Delete',
  'webhookConfigurationDomain.table.noData': 'No Configurations Found',
  'webhookConfigurationDomain.table.errorMsg': 'Error loading configurations',

  'webhookConfigurationDomain.configData.title.info': 'Configuration Information',
  'webhookConfigurationDomain.configData.info.env': 'Environment',
  'webhookConfigurationDomain.configData.info.testing': 'Testing',
  'webhookConfigurationDomain.configData.info.dev': 'Dev',
  'webhookConfigurationDomain.configData.info.production': 'Production',
  'webhookConfigurationDomain.configData.info.url': 'URL',
  'webhookConfigurationDomain.configData.info.callbackUrl': 'Callback URL',
  'webhookConfigurationDomain.configData.info.name': 'Configuration name',
  'webhookConfigurationDomain.configData.info.banner': 'Banner',
  'webhookConfigurationDomain.configData.info.status': 'Status',
  'webhookConfigurationDomain.configData.info.disable': 'Disabled',
  'webhookConfigurationDomain.configData.info.active': 'Active',
  'webhookConfigurationDomain.configData.info.noEvents': 'There are no events currently selected',
  'webhookConfigurationDomain.configData.title.auth': 'Authentication Information',
  'webhookConfigurationDomain.configData.auth.authUri': 'Auth URI',
  'webhookConfigurationDomain.configData.auth.clientId': 'Client ID',
  'webhookConfigurationDomain.configData.auth.clientSecret': 'Client Secret',
  'webhookConfigurationDomain.configData.auth.grantType': 'Grant Type',
  'webhookConfigurationDomain.configData.auth.scope': 'Scope',
  'webhookConfigurationDomain.configData.auth.header': 'Header',
  'webhookConfigurationDomain.configData.auth.resource': 'Resource',
  'webhookConfigurationDomain.configData.title.events': 'Events',
  'webhookConfigurationDomain.configData.title.additionalOption': 'Additional option',
  'webhookConfigurationDomain.configData.title.orderLocationFrequency':
    'Order location callback frequency',

  'webhookConfigurationDomain.events.header.shopping': 'Shopping',
  'webhookConfigurationDomain.events.header.delivery': 'Delivery',
  'webhookConfigurationDomain.events.header.lastMile': 'Last Mile Delivery',
  'webhookConfigurationDomain.events.header.pickup': 'Pickup',
  'webhookConfigurationDomain.events.header.other': 'Other',
  'webhookConfigurationDomain.events.subtitle':
    'Select the webhook events you need for this configuration. ',
  'webhookConfigurationDomain.events.link': 'View our documentation for webhook events.',
  'webhookConfigurationDomain.events.selectAll': 'Select all events',
  'webhookConfigurationDomain.events.selectAll.shopping': 'Select all Shopping events',
  'webhookConfigurationDomain.events.selectAll.delivery': 'Select all Delivery events',
  'webhookConfigurationDomain.events.selectAll.lastMile': 'Select all Last Mile Delivery events',
  'webhookConfigurationDomain.events.selectAll.pickup': 'Select all Pickup events',
  'webhookConfigurationDomain.events.selectAll.other': 'Select all Other events',

  'webhookConfigurationDomain.events.name.brandNew': 'Brand New',
  'webhookConfigurationDomain.events.name.acknowledged': 'Acknowledged',
  'webhookConfigurationDomain.events.name.picking': 'Picking',
  'webhookConfigurationDomain.events.name.staged': 'Staged',
  'webhookConfigurationDomain.events.name.delivering': 'Delivering',
  'webhookConfigurationDomain.events.name.delivered': 'Delivered',
  'webhookConfigurationDomain.events.name.lateDelivery': 'Late Delivery',
  'webhookConfigurationDomain.events.name.rescheduled': 'Rescheduled',
  'webhookConfigurationDomain.events.name.customerAcknowledged': 'Customer Acknowledged',
  'webhookConfigurationDomain.events.name.tipAdjustment': 'Tip Adjustment',
  'webhookConfigurationDomain.events.name.ratingReminder': 'Rating Reminder',
  'webhookConfigurationDomain.events.name.ratingUpdated': 'Rating Updated',
  'webhookConfigurationDomain.events.name.canceled': 'Canceled',
  'webhookConfigurationDomain.events.name.atStoreEta': 'At Store ETA',
  'webhookConfigurationDomain.events.name.arrivalAtCustomer': 'Arrival At Customer',
  'webhookConfigurationDomain.events.name.bagsVerified': 'Bags Verified',
  'webhookConfigurationDomain.events.name.pickupRunnerStarted': 'Pickup Runner Started',
  'webhookConfigurationDomain.events.name.unableToFindCustomer': 'Unable To Find Customer',
  'webhookConfigurationDomain.events.name.runnerNotFound': 'Runner Not Found',
  'webhookConfigurationDomain.events.name.pickupGeofenceReached': 'Pickup Geofence Reached',
  'webhookConfigurationDomain.events.name.latePickup': 'Late Pickup',
  'webhookConfigurationDomain.events.name.atStore': 'At Store',
  'webhookConfigurationDomain.events.name.acknowledgedForDelivery': 'Acknowledged For Delivery',
  'webhookConfigurationDomain.events.name.orderItemReplacement': 'Order Item Replacement',
  'webhookConfigurationDomain.events.name.orderItemRefund': 'Order Item Refund',
  'webhookConfigurationDomain.events.name.orderLocation': 'Order Location',
  'webhookConfigurationDomain.events.name.customerMia': 'Customer MIA',
  'webhookConfigurationDomain.events.name.checkout': 'Order Checkout',

  'webhookConfigurationDomain.events.description.brandNew':
    'This event occurs when an order is successfully created in the Instacart system.',
  'webhookConfigurationDomain.events.description.acknowledged':
    'This event occurs when an order is acknowledged by a shopper.',
  'webhookConfigurationDomain.events.description.picking':
    'This event occurs when a shopper begins fulfilling an order.',
  'webhookConfigurationDomain.events.description.staged':
    'This event occurs when the shopping and checkout are complete and the order has been placed in the staging area.',
  'webhookConfigurationDomain.events.description.delivering':
    'This event occurs when the order has left the store location and is being delivered.',
  'webhookConfigurationDomain.events.description.delivered':
    'This event occurs when the order has been successfully delivered or picked up by a customer.',
  'webhookConfigurationDomain.events.description.lateDelivery':
    'This event occurs when the delivery time of an order has changed.',
  'webhookConfigurationDomain.events.description.rescheduled':
    'This event occurs when an order has been rescheduled and will be fulfilled at another time.',
  'webhookConfigurationDomain.events.description.customerAcknowledged':
    'This event occurs when the runner is collecting the bags from the staging area.',
  'webhookConfigurationDomain.events.description.tipAdjustment':
    'This event occurs when the tip for an order has successfully been updated after delivery is complete.',
  'webhookConfigurationDomain.events.description.ratingReminder':
    'This event occurs one hour after the order is delivered or picked up. This callback is sent when the order does not have a rating at the time the event occurs.',
  'webhookConfigurationDomain.events.description.ratingUpdated':
    'This event occurs when the order rating is created or updated through the API.',
  'webhookConfigurationDomain.events.description.canceled':
    'This event occurs when an order has been canceled for any reason.',
  'webhookConfigurationDomain.events.description.atStoreEta':
    "Once a last mile delivery order is acknowledged, this event is emitted every two minutes with the shopper's estimated time of arrival to the store location.",
  'webhookConfigurationDomain.events.description.arrivalAtCustomer':
    "This event occurs when a shopper has arrived at the customer's location with their last mile delivery order.",
  'webhookConfigurationDomain.events.description.bagsVerified':
    'This event occurs when a shopper has verified the bags of a last mile delivery order at the store.',
  'webhookConfigurationDomain.events.description.pickupRunnerStarted':
    'This event occurs when the pickup runner is heading outside with bags.',
  'webhookConfigurationDomain.events.description.unableToFindCustomer':
    'This event occurs when the pickup runner was unable to find the customer.',
  'webhookConfigurationDomain.events.description.runnerNotFound':
    'This event occurs when no pickup runner was available.',
  'webhookConfigurationDomain.events.description.pickupGeofenceReached':
    'This event occurs when the customer reaches the virtual Geofence for the store. The message to the customer contains any additional pickup instructions.',
  'webhookConfigurationDomain.events.description.latePickup':
    'This event occurs when the pickup time of an order has changed.',
  'webhookConfigurationDomain.events.description.atStore':
    'This event occurs when a shopper arrives at the store to pick up a last mile delivery order.',
  'webhookConfigurationDomain.events.description.acknowledgedForDelivery':
    'This event occurs when a last mile delivery order is acknowledged by a shopper.',
  'webhookConfigurationDomain.events.description.orderItemReplacement':
    'This event occurs when a shopper replaces an order item.',
  'webhookConfigurationDomain.events.description.orderItemRefund':
    'This event occurs when a shopper refunds an order item.',
  'webhookConfigurationDomain.events.description.orderLocation':
    'This event occurs while the order is in the delivering status and the Order location callback frequency parameter is set below',
  'webhookConfigurationDomain.events.description.customerMia':
    'This event occurs when a shopper attempts to deliver an order and the customer is missing.',
  'webhookConfigurationDomain.events.description.checkout':
    'This event occurs when a shopper proceeds to checkout an order.',

  'webhookConfigurationDomain.deletionModal.devHeader': 'Delete configuration for Dev',
  'webhookConfigurationDomain.deletionModal.prodHeader': 'Delete configuration for Production',
  'webhookConfigurationDomain.deletionModal.subtitle':
    'Are you sure you want to delete this configuration?',
  'webhookConfigurationDomain.deletionModal.deleteButton': 'Delete',
  'webhookConfigurationDomain.deletionModal.cancelButton': 'Cancel',
  'webhookConfigurationDomain.deletionModal.deleteCheckbox': 'Yes, delete this configuration',
  'webhookConfigurationDomain.deletionModal.productionBanner':
    'This is a production configuration. Please verify that another production configuration exists before deleting to avoid customer impact.',
  'webhookConfigurationDomain.deletionModal.errorMessage':
    'Error deleting this configuration: {errorMsg}',

  'webhookConfigurationDomain.cancelEditModal.title': 'Do you want to cancel this edit?',
  'webhookConfigurationDomain.cancelEditModal.body':
    'You will lose these configuration updates if you cancel.',
  'webhookConfigurationDomain.cancelEditModal.returnToEditButton': 'No, back to edit',
  'webhookConfigurationDomain.cancelEditModal.cancelButton': 'Yes, cancel',

  'webhookConfigurationDomain.createNewForm.title': 'New Configuration',
  'webhookConfigurationDomain.editForm.title': 'Edit Configuration: {name}',
  'webhookConfigurationDomain.configForm.errors.required': 'This field is required',
  'webhookConfigurationDomain.configForm.errors.min': 'This field requires a value greater than 10',
  'webhookConfigurationDomain.configForm.errors.max': 'This field requires a value less than 300',
  'webhookConfigurationDomain.configForm.cancelButton': 'Cancel',
  'webhookConfigurationDomain.configForm.createButton': 'Create',
  'webhookConfigurationDomain.configForm.submitButton': 'Submit',
  'webhookConfigurationDomain.configForm.createSuccess': 'Configuration created successfully',
  'webhookConfigurationDomain.configForm.editSuccess': 'Configuration edited successfully',
  'webhookConfigurationDomain.configForm.submitError': 'Error submitting form. Please try again.',
  'webhookConfigurationDomain.configForm.searchPlaceholder': 'Search for an event',

  'webhookConfigurationDomain.configForm.authUri.description':
    'An endpoint to use for OAuth 2.0 authentication',
  'webhookConfigurationDomain.configForm.resource.description':
    'The requested resource for authentication',
  'webhookConfigurationDomain.configForm.clientId.description':
    'The ID that Connect uses to authenticate with your site',
  'webhookConfigurationDomain.configForm.clientSecret.description':
    'The secret that Connect uses to authenticate with your site',
  'webhookConfigurationDomain.configForm.grantType.description': 'The grant type for the token',
  'webhookConfigurationDomain.configForm.scope.description': 'The scope for Connect webhooks',
  'webhookConfigurationDomain.configForm.orderLocationFrequency.description':
    'You have selected the Order Location callback event, you can specify how often the callback is sent. Set an interval between 10 and 300 seconds',
} as const

export default EN_US
