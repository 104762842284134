import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { customersRouter } from './routing/customersRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import {
  customersAccessControl,
  customersPrivacyRequestsAccessControl,
} from './access-control/customersAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'customers',
  totem: {
    entity: 'customers-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [customersRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Customers,
      attachToV2: NavEntryPoint.OperationsCustomerSupport,
      subNavItems: [
        {
          type: 'item',
          labelId: 'customersDomain.search.pageTitle',
          route: 'customers-search',
          position: 1,
          positionNavV2: 5,
          accessControl: customersAccessControl,
        },
        {
          type: 'item',
          labelId: 'customersDomain.privacyRequests.pageTitle',
          route: 'customers-privacy-requests',
          position: 2,
          positionNavV2: 6,
          accessControl: customersPrivacyRequestsAccessControl,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
