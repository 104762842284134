import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const retailerFundedMarketingAdminAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.RetailerFundedMarketingAdminAccess],
}

export const retailerFundedMarketingAdminCustomCampaignAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.RetailerFundedMarketingAdminAccess],
  },
]
