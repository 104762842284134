import moment, { type Moment } from 'moment'
import { DATE_STRING_FORMAT } from '../../../../utils/time/dateFormat.constants'
import {
  dateRange,
  type DateRange,
  type StrictStartEndDate,
  type StartEndDate,
  type StringifiedStartEndDate,
} from './dateRanges.types'

export const dateToStringFormat = (date: Moment) => date.format(DATE_STRING_FORMAT)

type DateRangeToStartEndDate = Partial<StartEndDate> & {
  dateRange: DateRange
}

export const dateRangeToStartEndDate = ({
  dateRange: inputDateRange,
  startDate: inputStartDate,
  endDate: inputEndDate,
}: DateRangeToStartEndDate): StrictStartEndDate => {
  if (inputDateRange === dateRange.CUSTOM && inputStartDate && inputEndDate) {
    return {
      startDate: inputStartDate,
      endDate: inputEndDate,
    }
  }

  switch (inputDateRange) {
    case dateRange.LAST_90_DAYS:
      return {
        startDate: moment().subtract(90, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      }

    case dateRange.LAST_30_DAYS:
      return {
        startDate: moment().subtract(30, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      }

    case dateRange.LAST_7_DAYS:
      return {
        startDate: moment().subtract(7, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      }

    case dateRange.LAST_MONTH:
      return {
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().subtract(1, 'month').endOf('month'),
      }

    case dateRange.LAST_WEEK:
      return {
        startDate: moment().subtract(1, 'week').startOf('isoWeek'),
        endDate: moment().subtract(1, 'week').endOf('isoWeek'),
      }

    case dateRange.YESTERDAY:
      return {
        startDate: moment().subtract(1, 'day').startOf('day'),
        endDate: moment().subtract(1, 'day').endOf('day'),
      }

    case dateRange.TODAY:
    default:
      return {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
      }
  }
}

export const dateRangeToStringFormattedDates = ({
  dateRange: inputDateRange,
  startDate: inputStartDate,
  endDate: inputEndDate,
}: DateRangeToStartEndDate): StringifiedStartEndDate => {
  const { startDate, endDate } = dateRangeToStartEndDate({
    dateRange: inputDateRange,
    startDate: inputStartDate,
    endDate: inputEndDate,
  })

  return {
    startDateStr: dateToStringFormat(startDate),
    endDateStr: dateToStringFormat(endDate),
  }
}
