const EN_US = {
  'configurableProductsDomain.title': 'Configurable products',
  'configurableProductsDomain.breadcrumbs.root': 'Configurable Products',
  'configurableProductsDomain.list.filters.search': 'Search Product by UPC',
  'configurableProductsDomain.form.configId.label': 'Config Id',
  'configurableProductsDomain.form.configType.label': 'Config Type',
  'configurableProductsDomain.form.fields.options': 'Config Options',
  'configurableProductsDomain.validation.required': '{fieldName} is Required',
  'configurableProductsDomain.form.fields.placeHolder': 'Place Holder',
  'configurableProductsDomain.form.fields.loading': 'Loading',
  'configurableProductsDomain.form.fields.options.items': 'Config Option Items',
  'configurableProductsDomain.form.fields.options.items.action.addNewOptionItem':
    'Add Config Option Item',
  'configurableProductsDomain.form.fields.options.items.action.removeOptionItem':
    'Remove Config Option Item',
  'configurableProductsDomain.form.fields.options.action.addNewOption': 'Add Config Option',
  'configurableProductsDomain.form.fields.options.action.removeOption': 'Remove Config Option',
  'configurableProductsDomain.productName': 'Product Name: ',
  'configurableProductsDomain.productNotFound': 'Product Not Found',
  'configurableProductsDomain.configurationsNotFound':
    'No Configurations found, please create with below form',
  'configurableProductsDomain.configOptionType.baseSelection': 'Base Selection',
  'configurableProductsDomain.configOptionType.singleSelection': 'Single Selection',
  'configurableProductsDomain.configOptionType.multipleSelection': 'Multiple Selection',
  'configurableProductsDomain.configType.prepStyle': 'Prep Style',
  'configurableProductsDomain.configType.builder': 'Builder',
  'configurableProductsDomain.configType.custom': 'Custom',
  'configurableProductsDomain.form.fields.options.items.default.label': 'Default',
  'configurableProductsDomain.form.fields.options.items.status.label': 'Status',
  'configurableProductsDomain.form.fields.options.items.configOptionItemId.label':
    'Config Option Item Id: ',
  'configurableProductsDomain.form.fields.options.items.name.label': 'Name: ',
  'configurableProductsDomain.form.fields.options.items.price.label': 'Price: ',
  'configurableProductsDomain.form.fields.options.configName.label': 'Config Name: ',
  'configurableProductsDomain.form.fields.options.configOptionId.label': 'Config Option Id: ',
  'configurableProductsDomain.form.fields.options.configOptionName.label': 'Config Option Name: ',
  'configurableProductsDomain.form.fields.options.configOptionType.label': 'Config Option Type: ',
  'configurableProductsDomain.form.fields.options.mandatory.label': 'Mandatory',
  'configurableProductsDomain.form.fields.options.status.label': 'Status',
  'configurableProductsDomain.form.submit.success': 'Publish Success!',
  'configurableProductsDomain.form.submit.error': 'Publish Failed! Please try again later.',
  'configurableProductsDomain.form.cancel': 'Cancel',
  'configurableProductsDomain.form.submit': 'Submit',
} as const

export default EN_US
