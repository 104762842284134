import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute, DomainRedirect } from '../utils/domain/routingComponents'
import { merchandisingAccessControl } from '../access-control/merchandisingAccess.configuration'

const HeroBannersList = lazy(() => import('../components/hero-banners-list/HeroBannersList'))
const HeroBannerCreate = lazy(() => import('../components/hero-banner-create/HeroBannerCreate'))
const HeroBannerDetails = lazy(() => import('../components/hero-banner-details/HeroBannerDetails'))
const HeroBannerUpdate = lazy(() => import('../components/hero-banner-update/HeroBannerUpdate'))

export const merchandisingScopedRouter = (
  <DomainRoute route="merchandising" accessControl={merchandisingAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="merchandising-hero-banners-list"
        component={HeroBannersList}
        scopePicker={{ titles: ['merchandising.hero-banners.title'] }}
      />

      <DomainRoute exact route="merchandising-hero-banners-create" component={HeroBannerCreate} />

      <DomainRoute exact route="merchandising-hero-banners-edit" component={HeroBannerUpdate} />

      <DomainRoute exact route="merchandising-hero-banners-details" component={HeroBannerDetails} />

      <DomainRedirect toRoute="merchandising-hero-banners-list" />
    </DashSwitch>
  </DomainRoute>
)
