import React from 'react'
import styled from '@emotion/styled'
import { type OpenDirectionShape, SingleDatePicker as ReactDatePicker } from 'react-dates'
import moment, { type Moment } from 'moment'

import { ChevronDownIcon } from '@instacart/ids-core'

interface StyleOverridesProps {
  inputHeight: React.CSSProperties['height']
}

const StyleOverrides = styled.div<StyleOverridesProps>`
  .SingleDatePickerInput {
    border: none;
    border-radius: 8px;
    background-color: #f6f7f8;
  }

  .DateInput {
    border-radius: 8px;
  }

  .DateInput__small {
    width: 170px;
  }

  .DateInput_input {
    height: ${props => props.inputHeight};
    padding: 8px 10px;
    text-align: left;
    font-weight: 600;
    cursor: pointer;

    border: none;
    border-radius: 8px;
    background-color: #f6f7f8;
  }

  .SingleDatePickerInput_calendarIcon {
    padding: 6px 0 0 0;
    margin: 0 10px 0 0;
    outline: none;
  }
`

interface ShiftsSingleDatePickerProps {
  isOutsideRange: (day: Moment) => boolean
  date: string
  id: string
  openDirection?: OpenDirectionShape
  onDateChange: (date: moment.Moment | null) => void
  displayFormat: string | (() => string)
  inputHeight?: React.CSSProperties['height']
}

interface ShiftsSingleDatePickerState {
  focused: boolean
}

export class ShiftsSingleDatePicker extends React.Component<
  ShiftsSingleDatePickerProps,
  ShiftsSingleDatePickerState
> {
  static defaultProps = {
    id: 'rpp-single-date-picker',
    isOutsideRange: () => false,
    openDirection: 'down',
    inputHeight: '35px',
  }

  // needed for focus
  state: ShiftsSingleDatePickerState = {
    focused: false,
  }

  onFocusChange = ({ focused }) => {
    this.setState({ focused })
  }

  render() {
    const { isOutsideRange, date, id, openDirection, onDateChange, displayFormat, inputHeight } =
      this.props

    // KNOWN CONSOLE ERROR
    // @see https://github.com/airbnb/react-dates/issues/950
    return (
      <StyleOverrides inputHeight={inputHeight}>
        <ReactDatePicker
          noBorder
          id={id}
          placeholder="Date"
          small
          readOnly
          initialVisibleMonth={null}
          numberOfMonths={1}
          enableOutsideDays={false}
          isOutsideRange={isOutsideRange}
          date={date ? moment(date) : null}
          focused={this.state.focused}
          onDateChange={onDateChange}
          onFocusChange={this.onFocusChange}
          openDirection={openDirection}
          customInputIcon={<ChevronDownIcon size={20} color="systemGrayscale70" />}
          inputIconPosition={'after'}
          displayFormat={displayFormat}
        />
      </StyleOverrides>
    )
  }
}
