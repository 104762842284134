import React, { type FunctionComponent } from 'react'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Drawer } from '@mantine/core'
import styled from '@emotion/styled'
import { CloseIcon, MenuIcon } from '@instacart/ids-core'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import { MENU_BAR_Z_INDEX } from '../navBar.constants'
import { DrawerContents } from './DrawerContents'

const MenuItemIconContainer = styled.div<{
  hoverColor: string
}>(
  {
    height: '100%',
    width: '40px',
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ({ hoverColor }) => ({
    '&:hover': {
      backgroundColor: hoverColor,
    },
  })
)

type Props = {
  currentlySelectedItem: number
  setCurrentlySelectedItem: (value: number) => void
  navMenuHierarchy: NavMenuHierarchy
  leftDrawerOpened: boolean
  setLeftDrawerOpened: (value: boolean) => void
  navBarColorHover?: string
}

export const NavMenuLeftDrawer: FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentlySelectedItem,
  setCurrentlySelectedItem,
  navMenuHierarchy,
  leftDrawerOpened,
  setLeftDrawerOpened,
  navBarColorHover,
}) => {
  const closeDrawer = () => {
    setLeftDrawerOpened(false)
  }

  return (
    <>
      <MenuItemIconContainer
        hoverColor={navBarColorHover}
        onClick={() => (leftDrawerOpened ? closeDrawer() : setLeftDrawerOpened(true))}
      >
        {leftDrawerOpened ? (
          <CloseIcon color="systemGrayscale00" size={24} />
        ) : (
          <MenuIcon color="systemGrayscale00" size={24} />
        )}
      </MenuItemIconContainer>
      <Drawer
        opened={leftDrawerOpened}
        onClose={closeDrawer}
        withCloseButton={false}
        size={'auto'}
        padding={0}
        zIndex={MENU_BAR_Z_INDEX - 1}
      >
        {/* autoFocus hidden element first */}
        <div tabIndex={-1} data-autofocus />
        <DrawerContents
          closeDrawer={closeDrawer}
          navMenuHierarchy={navMenuHierarchy}
          currentlySelectedItem={currentlySelectedItem}
          setCurrentlySelectedItem={setCurrentlySelectedItem}
        />
      </Drawer>
    </>
  )
}
