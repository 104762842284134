import styled from '@emotion/styled'
import { colors } from '../../foundation/colors'
import { fontSize } from '../../foundation/fontSize'
import { fontWeight } from '../../foundation/fontWeight'

export const TextButton = styled.button({
  height: 18,
  paddingLeft: 2,
  paddingRight: 2,
  fontSize: fontSize.X12,
  fontWeight: fontWeight.SEMIBOLD,
  color: colors.PRIMARY.REGULAR,
  background: 'none',
  border: 'none',
  '&:hover': {
    background: 'none',
    color: colors.GRAYSCALE.X70,
  },
  '&:disabled': {
    background: 'none',
    color: colors.GRAYSCALE.X50,
  },
})
