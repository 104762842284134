import { useCallback, useEffect } from 'react'
import type dayjs from 'dayjs'
import {
  InsightsPortalOwner,
  InsightsPortalSchema,
  useGetInsightsPortalFilterOptionsLazyQuery,
  useGetInsightsPortalMetricsQuery,
} from '../../api'
import { useInsightsPortalAuthorizationContext } from '../../utils/authorization-context.hooks'
import { useFilterParamsContext } from '../../utils/filter-params/filterParamsContext'
import { optionsArrayToInFilterSelection } from '../utils/insightsPortalFilterTransforms'
import {
  createFilterParamConfig,
  transformApiOptionsIntoDashboardOptions,
  transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'
import { type FilterParamsState } from '../../utils/filter-params/filterParams.types'
import { useFormatValue } from '../../utils/format-value.hooks'
import { useDomainMessages } from '../../utils/domain/intl'
import { calculateComparisonPeriod } from '../../utils/insights-portal.utils'
import { useInsightsPortalQueryTags } from '../../utils/query-tags.hooks'
import { Metrics } from './index'

const transformFilterParamsForApiConfig = [
  createFilterParamConfig('retailer', 'WAREHOUSE', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'campaignStatus',
    'DIM_CAMPAIGN_STATUS',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'campaignName',
    'DIM_CAMPAIGN_NAME',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig('campaign', 'CAMPAIGN', optionsArrayToInFilterSelection('stringValue')),
  createFilterParamConfig(
    'campaignType',
    'CAMPAIGN_TYPE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'taasUserSegmentCampaignType',
    'TAAS_USER_SEGMENT_CAMPAIGN_TYPE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'dimUserSegmentCampaignType',
    'DIM_USER_SEGMENT_CAMPAIGN_TYPE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'userSegmentCampaignType',
    'USER_SEGMENT_CAMPAIGN_TYPE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'taasUserSegment',
    'TAAS_USER_SEGMENT',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'storeLocationCode',
    'WAREHOUSE_LOCATION_CODE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'region',
    'WAREHOUSE_LOCATION_REGION',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'state',
    'WAREHOUSE_LOCATION_STATE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'campaignEndingSoon',
    'CAMPAIGN_ENDING_SOON',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'campaignObjective',
    'DIM_CAMPAIGN_STATUS',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'dimCampaignObjective',
    'DIM_CAMPAIGN_OBJECTIVE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'orderSource',
    'ORDER_SOURCE',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'discoverability',
    'DIM_DISCOVERABILITY',
    optionsArrayToInFilterSelection('stringValue')
  ),
  createFilterParamConfig(
    'deliveryState',
    'DELIVERY_STATE',
    optionsArrayToInFilterSelection('stringValue'),
    'insightsPortalDomain.filters.deliveryState.label'
  ),
]

export const aggCampaignSchemaFilterParamsConfig = transformFilterParamsForApiConfig

export const mapApiNameToDashboardName = {
  WAREHOUSE: 'retailer',
  DIM_CAMPAIGN_STATUS: 'campaignStatus',
  CAMPAIGN_TYPE: 'campaignType',
  TAAS_USER_SEGMENT_CAMPAIGN_TYPE: 'taasUserSegmentCampaignType',
  DIM_USER_SEGMENT_CAMPAIGN_TYPE: 'dimUserSegmentCampaignType',
  USER_SEGMENT_CAMPAIGN_TYPE: 'userSegmentCampaignType',
  TAAS_USER_SEGMENT: 'taasUserSegment',
  DIM_CAMPAIGN_NAME: 'campaignName',
  CAMPAIGN_ENDING_SOON: 'campaignEndingSoon',
  CAMPAIGN: 'campaign',
  DIM_DISCOVERABILITY: 'discoverability',
  DIM_CAMPAIGN_OBJECTIVE: 'dimCampaignObjective',
  ORDER_SOURCE: 'orderSource',
  WAREHOUSE_LOCATION_CODE: 'storeLocationCode',
  WAREHOUSE_LOCATION_REGION: 'region',
  WAREHOUSE_LOCATION_STATE: 'state',
}

export const transformAggCampaignApiOptionsConfig = {
  WAREHOUSE: 'stringValue',
  DIM_DISCOVERABILITY: 'stringValue',
  DIM_CAMPAIGN_STATUS: 'stringValue',
  CAMPAIGN_ENDING_SOON: 'stringValue',
  CAMPAIGN_TYPE: 'stringValue',
  TAAS_USER_SEGMENT_CAMPAIGN_TYPE: 'stringValue',
  DIM_USER_SEGMENT_CAMPAIGN_TYPE: 'stringValue',
  USER_SEGMENT_CAMPAIGN_TYPE: 'stringValue',
  TAAS_USER_SEGMENT: 'stringValue',
  DIM_CAMPAIGN_NAME: 'stringValue',
  CAMPAIGN: 'stringValue',
  DIM_CAMPAIGN_OBJECTIVE: 'stringValue',
  ORDER_SOURCE: 'stringValue',
  WAREHOUSE_LOCATION_CODE: 'stringValue',
  WAREHOUSE_LOCATION_REGION: 'stringValue',
  WAREHOUSE_LOCATION_STATE: 'stringValue',
} as const

export const transformFilterSelectionsToAggCampaignSchemaFilters =
  transformFilterParamsToApiFilterSelectionsFactory(transformFilterParamsForApiConfig)

export const useAggCampaignFilterOptions = () => {
  const [getFilters, { data, loading, error }] = useGetInsightsPortalFilterOptionsLazyQuery()

  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const { partnerIds, retailerIds, retailerLocationIds } = useInsightsPortalAuthorizationContext()

  const schema =
    InsightsPortalSchema.FactCampaignPerformanceStitchFactCampaignImpressionsPerformance

  const queryTags = useInsightsPortalQueryTags()

  useEffect(() => {
    getFilters({
      variables: {
        schema,
        partnerIds,
        retailerIds,
        retailerLocationIds,
        filters: transformFilterSelectionsToAggCampaignSchemaFilters(selectedFiltersValues, dates),
        owner: InsightsPortalOwner.RetailerTools,
        queryTags,
      },
    })
  }, [
    selectedFiltersValues,
    getFilters,
    dates,
    partnerIds,
    retailerIds,
    retailerLocationIds,
    schema,
    queryTags,
  ])

  const filterOptions = transformApiOptionsIntoDashboardOptions(
    data?.insightsPortalServiceGetInsightsPortalFilterOptions?.filterOptions,
    transformAggCampaignApiOptionsConfig
  )
  return {
    filterOptions,
    loading,
    error,
  }
}

const useAggCampaignMetrics = (
  selectedFiltersValues: FilterParamsState['selectedFiltersValues'],
  dates: { from: dayjs.Dayjs; to: dayjs.Dayjs },
  metrics: Metrics[]
) => {
  const { partnerIds, retailerIds, retailerLocationIds } = useInsightsPortalAuthorizationContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')
  const currentPeriodDateRange = {
    from,
    to,
  }

  const schema =
    InsightsPortalSchema.FactCampaignPerformanceStitchFactCampaignImpressionsPerformance

  const { data, loading } = useGetInsightsPortalMetricsQuery({
    variables: {
      schema,
      partnerIds,
      retailerIds,
      retailerLocationIds,
      metrics,
      filters: transformFilterSelectionsToAggCampaignSchemaFilters(
        selectedFiltersValues,
        currentPeriodDateRange
      ),
    },
  })

  return {
    data: data?.insightsPortalServiceGetInsightsPortalMetricValues?.metricValues || [],
    loading,
  }
}

export const useCurrentPeriodAggCampaignMetrics = (metrics: Metrics[]) => {
  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')

  const currentPeriodDateRange = {
    from,
    to,
  }

  return useAggCampaignMetrics(selectedFiltersValues, currentPeriodDateRange, metrics)
}

export const useLastPeriodAggCampaignMetrics = (metrics: Metrics[]) => {
  const { selectedFiltersValues, dates } = useFilterParamsContext()

  const from = dates.from.clone().startOf('day')
  const to = dates.to.clone().endOf('day')

  const lastPeriodDateRange = calculateComparisonPeriod(from, to, dates.comparisonType)

  return useAggCampaignMetrics(selectedFiltersValues, lastPeriodDateRange, metrics)
}

export const useAggCampaignMetricValueFormatter = () => {
  const { formatInteger, formatCurrency, formatDecimal } = useFormatValue()

  const formatter = useCallback(
    (metric: Metrics, value: number) => {
      const formatters = [
        { metric: Metrics.CAMPAIGN_BUDGET, formatter: formatCurrency, compact: false },
        {
          metric: Metrics.SUM_CAMPAIGN_SPEND,
          formatter: formatCurrency,
          compact: false,
        },
        { metric: Metrics.SUM_REDEMPTIONS, formatter: formatInteger, compact: false },
        {
          metric: Metrics.SUM_ATTRIBUTED_SALES,
          formatter: formatCurrency,
          compact: false,
        },
        {
          metric: Metrics.RETURN_ON_AD_SPEND,
          formatter: (val: string | number): string => {
            const num = typeof val === 'number' ? val : parseFloat(val)
            if (isNaN(num)) {
              return '-'
            }
            return `${formatDecimal(value, false) + 'x'}`
          },
          compact: false,
        },
      ]

      const metricFormatter = formatters.find(f => f.metric === metric)

      if (metricFormatter) {
        return metricFormatter.formatter(value, metricFormatter.compact)
      }
      return value.toLocaleString()
    },
    [formatInteger, formatCurrency, formatDecimal]
  )

  return {
    formatter,
  }
}

export const useAggCampaignMetricLabels = () =>
  useDomainMessages({
    SUM_CAMPAIGN_SPEND: 'insightsPortalDomain.adsCampaignDashboard.metrics.spend',
    SUM_REDEMPTIONS: 'insightsPortalDomain.adsCampaignDashboard.metrics.redemptions',
    SUM_ATTRIBUTED_SALES: 'insightsPortalDomain.adsCampaignDashboard.metrics.attributedGmv',
    RETURN_ON_AD_SPEND: 'insightsPortalDomain.adsCampaignDashboard.metrics.roas',
    NUM_ACTIVE_CAMPAIGNS: 'insightsPortalDomain.adsCampaignDashboard.metrics.activeCampaigns',
    NUM_ENDING_SOON_CAMPAIGNS:
      'insightsPortalDomain.adsCampaignDashboard.metrics.campaignsEndingSoon',
  })
