import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'
import MenuItem from '../MenuItem'

const PartnerMenuItem = styled(MenuItem)`
  padding: 30px 50px;
  transition: background 0.08s ease;
  background: white;
  user-select: none;

  &:hover {
    background: ${colors.GRAY_93};
  }

  &:active {
    background: ${colors.GRAY_88};
  }
`

interface Props {
  id: number
  name?: string
  selectedPartnerId: number | undefined
  onClick: (id: number) => void
}

const PartnerCell: FunctionComponent<React.PropsWithChildren<Props>> = ({
  name,
  id,
  selectedPartnerId,
  onClick,
}) => (
  <PartnerMenuItem
    data-testid={`select-partner-warehouse-partner-cell-${id}`}
    active={selectedPartnerId === id}
    onClick={() => onClick(id)}
    title={name}
  >
    {name}
  </PartnerMenuItem>
)

export default PartnerCell
