import React, { type FunctionComponent } from 'react'
import {
  AccountIcon,
  CategoryIcon,
  ComputerIcon,
  EditIcon,
  GraphIcon,
  HelpIcon,
  HomeIcon,
  InventoryGoodIcon,
  ItemIcon,
  LegalIcon,
  ListIcon,
  OrderIcon,
  StoreIcon,
  TechnicalIcon,
  TrendingIcon,
  UserIcon,
  MapIcon,
  SparkleIcon,
  KeyIcon,
} from '@instacart/ids-core'
import { retailerCollectionsLegacyDepartmentsAislesAccessControl } from '@retailer-platform/domain-retailer-collections'
import {
  useCheckLaunchOnboardingProgress,
  onboardingAccessControl,
} from '@retailer-platform/domain-onboarding'
import { emergingBrandsPortalAccessControl } from '@retailer-platform/domain-emerging-brands-portal'
import {
  InstacartManagedReportsListPageAccessControl,
  ReportsPickupAnalyticsAccessControl,
} from '@retailer-platform/domain-reports'
import { NavItem } from '../NavItem'
import { NavFolder } from '../NavFolder'
import { CatalogSectionAccessControl } from '../../../../../legacy/sections/catalog/CatalogSection.configuration'
import {
  OrdersDeliveryAccessControl,
  OrdersPickupAccessControl,
  OrdersScanAndPayAccessControl,
} from '../../../../../legacy/sections/orders/OrdersSection.configuration'
import { GeneratedReportsSectionAccessControl } from '../../../../../legacy/sections/generated-reports/GeneratedReportsSection.configuration'
import { InventoryFilesAccessControl } from '../../../../../legacy/routes/reports/files/InventoryFiles.configuration'
import { TlogsContainerAccessControl } from '../../../../../legacy/routes/reports/tlogs/TlogsContainer.configuration'
import { AlcoholTlogsAccessControl } from '../../../../../legacy/routes/reports/alcohol/AlcoholTlogs.configuration'
import { AdminAccessControl } from '../../../../../legacy/routes/admin/Admin.configuration'
import { StripeAssociationsAccessControl } from '../../../../../legacy/routes/settings/stripe/StripeAssociations.configuration'
import { StoreGroupsSectionNavigationControl } from '../../../../../legacy/sections/store-groups/StoreGroupsSection.configuration'
import { NavItem as NavEntryPoint } from '../../../../../legacy/components/Nav/nav.types'
import { type AccessControlConfig } from '../../../../../legacy/common/accessControl/accessControl.types'
import clientEnv from '../../../../../utils/global/clientEnv'

export const RetailerNavMainContent: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { hasInProgressLaunchOnboarding, hasLaunchOnboarding } = useCheckLaunchOnboardingProgress()

  return (
    <React.Fragment>
      <NavItem isMainItem Icon={HomeIcon} route="dashboard" title="home.title" />
      {hasInProgressLaunchOnboarding && (
        <NavItem
          isMainItem
          Icon={ListIcon}
          route="onboarding-launch-checklist"
          title="onboarding.launchChecklist"
          accessControl={onboardingAccessControl as AccessControlConfig}
        />
      )}

      <NavFolder
        Icon={ItemIcon}
        title="catalog.title"
        accessControl={CatalogSectionAccessControl}
        navEntryPoint={NavEntryPoint.Catalog}
      >
        <NavItem
          route="departments"
          title="catalog.departments.title"
          accessControl={
            retailerCollectionsLegacyDepartmentsAislesAccessControl as AccessControlConfig
          }
        />
        <NavItem route="new-product-request" title="catalog.requestNewProduct" />
      </NavFolder>

      <NavFolder Icon={OrderIcon} title="orders.title" navEntryPoint={NavEntryPoint.Orders}>
        <NavItem
          accessControl={OrdersDeliveryAccessControl}
          route="delivery"
          title="delivery.title"
        />
        <NavItem
          accessControl={OrdersPickupAccessControl}
          route="pickup"
          title="pickup.title"
          id="nav-item-pickup"
        />
        <NavItem
          accessControl={OrdersScanAndPayAccessControl}
          route="scanandpay"
          title="scanandpay.title"
          id="nav-item-scanandpay"
        />
      </NavFolder>
      <NavFolder Icon={LegalIcon} title="files.title" navEntryPoint={NavEntryPoint.Files}>
        <NavItem
          route="files"
          title="reports.files.title"
          accessControl={InventoryFilesAccessControl}
          position={5}
        />
        <NavItem
          route="tlogs"
          title="tlogs.title"
          accessControl={TlogsContainerAccessControl}
          id="nav-item-tlogs"
        />
        <NavItem
          route="alcohol-tlogs"
          title="alcoholTlogs.title"
          accessControl={AlcoholTlogsAccessControl}
        />
      </NavFolder>
      <NavFolder
        Icon={TrendingIcon}
        title="insightsPortalDomain.nav.storePerformance.folder"
        navEntryPoint={NavEntryPoint.StorePerformance}
      ></NavFolder>
      <NavFolder
        Icon={TrendingIcon}
        title="insightsPortalDomain.nav.dataAndAnalytics.folder"
        navEntryPoint={NavEntryPoint.DataAndAnalytics}
      >
        <NavItem
          route="pickupAnalytics"
          title="reportsDomain.pickupAnalytics.title"
          id="nav-item-pickupAnalytics"
          accessControl={ReportsPickupAnalyticsAccessControl}
          position={1}
        />
      </NavFolder>
      <NavFolder Icon={GraphIcon} title="reports.title" navEntryPoint={NavEntryPoint.Reports}>
        <NavItem
          route="generated-reports"
          title="generatedReports.title"
          accessControl={GeneratedReportsSectionAccessControl}
        />
        <NavItem
          route="instacart-managed-reports-list"
          title="reportsDomain.instacartManagedReportsList.nav"
          id="nav-item-reports"
          // @ts-expect-error
          accessControl={InstacartManagedReportsListPageAccessControl}
        />
      </NavFolder>
      <NavFolder
        Icon={SparkleIcon}
        title="marketing.title"
        navEntryPoint={NavEntryPoint.RetailerFundedMarketing}
      />
      <NavFolder
        Icon={InventoryGoodIcon}
        title="inventoryIntelligence.title"
        navEntryPoint={NavEntryPoint.InventoryIntelligence}
      />
      <NavFolder
        Icon={StoreIcon}
        title="storeOperations.title"
        navEntryPoint={NavEntryPoint.StoreOperations}
      >
        <NavItem
          route="store-groups"
          title="settings.storeGroups.title"
          position={4}
          accessControl={StoreGroupsSectionNavigationControl}
        />
        {hasLaunchOnboarding && (
          <NavItem
            position={7}
            route="onboarding-store-management"
            title="onboarding.storeManagement"
            accessControl={onboardingAccessControl as AccessControlConfig}
          />
        )}
      </NavFolder>

      <NavFolder Icon={UserIcon} title="customers.title" navEntryPoint={NavEntryPoint.Customers} />

      <NavFolder
        Icon={AccountIcon}
        title="admin.title"
        accessControl={AdminAccessControl}
        navEntryPoint={NavEntryPoint.Admin}
      ></NavFolder>
      <NavFolder
        Icon={CategoryIcon}
        title="app.admin.nav.links.merchandising"
        navEntryPoint={NavEntryPoint.Merchandising}
      ></NavFolder>
      <NavFolder
        Icon={ComputerIcon}
        title="storefront.nav.title"
        navEntryPoint={NavEntryPoint.Storefront}
      />
      <NavFolder
        Icon={MapIcon}
        title="app.admin.nav.links.brandExplorer"
        navEntryPoint={NavEntryPoint.BrandExplorer}
        accessControl={emergingBrandsPortalAccessControl as AccessControlConfig}
        isNewNav={true}
      ></NavFolder>

      <NavFolder
        Icon={KeyIcon}
        title={'navV2.folder.developer.title'}
        navEntryPoint={NavEntryPoint.Developer}
      />

      <NavFolder Icon={EditIcon} title="settings.title" navEntryPoint={NavEntryPoint.Settings}>
        <NavItem
          route="store-groups"
          title="settings.storeGroups.title"
          accessControl={StoreGroupsSectionNavigationControl}
        />
        <NavItem
          route="stripeSettings"
          title="settings.stripe.title"
          accessControl={StripeAssociationsAccessControl}
        />
        {hasLaunchOnboarding && (
          <NavItem
            route="onboarding-retailer-settings"
            title="onboarding.retailerProfile"
            accessControl={onboardingAccessControl as AccessControlConfig}
          />
        )}
      </NavFolder>
      <NavFolder Icon={HelpIcon} title="help" navEntryPoint={NavEntryPoint.Help}>
        <NavItem title="help" href={clientEnv.PUBLIC_CLIENT_PARTNER_DOCS_URL} />
        <NavItem title="press" href="https://instacart.com/press" />
        <NavItem title="privacy" href="https://instacart.com/privacy" />
        <NavItem title="security_and_trust" href="https://instacart.safebase.us/" />
        <NavItem title="terms" href="https://docs.instacart.com/instacart/ipp-terms/" />
      </NavFolder>
      <NavFolder Icon={TechnicalIcon} title="connect.title" navEntryPoint={NavEntryPoint.Connect} />
    </React.Fragment>
  )
}
