import { useCallback, useEffect } from 'react'
import { useQueryParams } from 'use-query-params'
import {
  retailerLocationSelectQueryParamEncoder,
  dateSelectRangeQueryParamEncoder,
} from '@retailer-platform/dashboard/utils'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import {
  type DateSelectRangeValueType,
  type RetailerLocationSelectValueType,
} from '@retailer-platform/dashboard/legacy'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { dateRange } from '@retailer-platform/dashboard/legacy'

export const usePickupAnalyticsQueryParamsBehaviour = () => {
  const [queryValue, setQueryValue] = useQueryParams({
    dateSelectRangeValue: dateSelectRangeQueryParamEncoder,
    retailerLocationSelectValue: retailerLocationSelectQueryParamEncoder,
  })

  const setDateSelectRangeValue = useCallback(
    (nextDateSelectRangeValue: DateSelectRangeValueType) => {
      setQueryValue({
        ...queryValue,
        dateSelectRangeValue: nextDateSelectRangeValue,
      })
    },
    [queryValue, setQueryValue]
  )

  // initialize date range to the last week if mounted with no date information in query params
  useEffect(() => {
    if (queryValue.dateSelectRangeValue) return

    setDateSelectRangeValue({
      dateRange: dateRange.LAST_WEEK,
      customDates: { startDate: null, endDate: null },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setRetailerLocationSelectValue = useCallback(
    (nextRetailerLocationSelectValue: RetailerLocationSelectValueType) => {
      setQueryValue({
        ...queryValue,
        retailerLocationSelectValue: nextRetailerLocationSelectValue,
      })
    },
    [queryValue, setQueryValue]
  )

  const { dateSelectRangeValue, retailerLocationSelectValue } = queryValue

  return {
    dateSelectRangeValue,
    setDateSelectRangeValue,
    retailerLocationSelectValue,
    setRetailerLocationSelectValue,
  }
}

export type PickupAnalyticsQueryParamsBehaviour = ReturnType<
  typeof usePickupAnalyticsQueryParamsBehaviour
>
