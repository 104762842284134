export const dashColorMapping = {
  'light-primary': '#5FCA44',
  primary: '#43B02A',
  'dark-primary': '#177D05',
  white: '#FFFFFF',
  'lightest-neutral': '#F7F7F7',
  'lighter-neutral': '#EEEEEE',
  'light-neutral': '#E0E0E0',
  neutral: '#BDBDBD',
  'dark-neutral': '#757575',
  'darker-neutral': '#424242',
  'darkest-neutral': '#212121',
  'light-danger': '#FF195A',
  danger: '#E6003D',
  'dark-danger': '#B30029',
  'light-success': '#43B02A',
  success: '#2A9715',
  'dark-success': '#177D05',
} as const

export type DashColor = keyof typeof dashColorMapping

export const dashColors = {
  LIGHT_PRIMARY: dashColorMapping['light-primary'],
  PRIMARY: dashColorMapping.primary,
  DARK_PRIMARY: dashColorMapping['dark-primary'],
  WHITE: dashColorMapping.white,
  LIGHTEST_NEUTRAL: dashColorMapping['lightest-neutral'],
  LIGHTER_NEUTRAL: dashColorMapping['lighter-neutral'],
  LIGHT_NEUTRAL: dashColorMapping['light-neutral'],
  NEUTRAL: dashColorMapping.neutral,
  DARK_NEUTRAL: dashColorMapping['dark-neutral'],
  DARKER_NEUTRAL: dashColorMapping['darker-neutral'],
  DARKEST_NEUTRAL: dashColorMapping['darkest-neutral'],
  LIGHT_DANGER: dashColorMapping['light-danger'],
  DANGER: dashColorMapping.danger,
  DARK_DANGER: dashColorMapping['dark-danger'],
  LIGHT_SUCCESS: dashColorMapping['light-success'],
  SUCCESS: dashColorMapping.success,
  DARK_SUCCESS: dashColorMapping['dark-success'],
}
