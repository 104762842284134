export const fontSize = {
  X8: '8px',
  X10: '10px',
  X12: '12px',
  X14: '14px',
  X15: '15px',
  X18: '18px',
  X23: '23px',
  X31: '31px',
  X41: '41px',
} as const

export const defaultLineHeight = '1.5em'
