import React from 'react'

import {
  NotificationsContext,
  type NotificationsContextValue,
} from '../../../legacy/contexts/notifications/NotificationsContext'

interface Props extends Partial<NotificationsContextValue> {
  onNotifyCallback?: (...args: any) => void
  onNotifyErrorCallback?: (...args: any) => void
  children: React.ReactNode
}

class MockNotificationsProvider extends React.Component<Props> {
  render() {
    const { children, ...rest } = this.props

    const value = {
      notify: (...args) => {
        this.props.onNotifyCallback?.(...args)

        return null
      },
      notifyError: (...args) => {
        this.props.onNotifyErrorCallback?.(...args)

        return null
      },
    }

    return (
      <NotificationsContext.Provider value={{ ...value, ...rest }}>
        {children}
      </NotificationsContext.Provider>
    )
  }
}

export default MockNotificationsProvider
