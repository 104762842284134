import React, { useCallback, useEffect, useState } from 'react'
import { keyframes } from '@emotion/react'
import { CloseIcon } from '@instacart/ids-core'
import { Text } from '../text/Text'
import { NotificationWrapper } from './Notifications.styles'
import { type NotificationType, NotificationLevel } from './NotificationsDisplay.types'

const fade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(20px)',
  },
})

const fadeOut = keyframes({
  '100%': {
    opacity: 0,
    transform: 'translateX(20px)',
  },
})

export const Notification: React.FunctionComponent<
  React.PropsWithChildren<NotificationType & { removeNotification: (s: string) => void }>
> = props => {
  // These bits are all related to handling the in/out transitions of notifications
  const [shouldDismiss, setShouldDismiss] = useState(false)
  const { timeout = 6000, id, removeNotification: removeNotificationOriginal } = props

  const removeNotification = useCallback(() => {
    setShouldDismiss(true)
  }, [])

  useEffect(() => {
    if (timeout === null) return

    const handle = setTimeout(() => removeNotification(), timeout)
    return () => clearTimeout(handle)
  }, [id, removeNotification, timeout])

  useEffect(() => {
    if (!shouldDismiss) return

    const handle = setTimeout(() => removeNotificationOriginal(id), 250)

    return () => clearTimeout(handle)
  }, [id, removeNotification, removeNotificationOriginal, shouldDismiss])

  return (
    <NotificationWrapper
      data-testid={props.id}
      css={{ animation: `${shouldDismiss ? fadeOut : fade} 0.2s ease both` }}
    >
      <NotificationContents {...props} removeNotification={removeNotification} />
    </NotificationWrapper>
  )
}

export const NotificationContents: React.FunctionComponent<
  React.PropsWithChildren<NotificationType & { removeNotification: (id: string) => void }>
> = ({
  id,
  contents,
  title,
  body,
  removeNotification,
  dismissable = true,
  level = NotificationLevel.None,
  icon,
}) => (
  <React.Fragment>
    {icon && <div css={{ maxWidth: 32 }}>{icon}</div>}

    <div css={{ flex: 1 }}>
      {contents ? (
        contents
      ) : (
        <React.Fragment>
          <Text size="medium" weight="bold">
            {title}
          </Text>
          <Text size="small">{body}</Text>
        </React.Fragment>
      )}
    </div>

    {dismissable && (
      <div
        data-testid="notification-close-button"
        css={{ cursor: 'pointer' }}
        onClick={() => removeNotification(id)}
      >
        <CloseIcon size={18} />
      </div>
    )}
  </React.Fragment>
)
