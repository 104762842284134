import { useGetAllPartnersForUserPaginatedQuery } from '../../../__codegen__/api'
import { useRedirectionLocation } from './useRedirectLocation.hooks'

export const useShouldAutoRedirect = (autoSelect: boolean, initialLoad: boolean) => {
  const redirectLocation = useRedirectionLocation(initialLoad)

  const { data, loading, error } = useGetAllPartnersForUserPaginatedQuery({
    variables: {
      first: 2,
    },
    skip: !autoSelect,
  })

  if (!autoSelect) {
    return {
      redirect: false,
    }
  }
  if (loading) {
    return {
      redirect: false,
      loading: true,
    }
  }

  const partners = data?.partnerRetailerSearch?.nodes

  if (autoSelect) {
    if (partners?.length == 1) {
      const [partner] = partners
      const { retailers } = partner
      const warehouseId = retailers.length == 1 ? retailers[0].id : '0'

      return {
        redirect: true,
        url: redirectLocation(partner.partnerType, partner.id, warehouseId),
      }
    } else if (partners?.length > 1) {
      return {
        redirect: false,
      }
    }
    return null
  }
}
