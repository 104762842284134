import {
  type createFilterParamConfig,
  type transformFilterParamsToApiFilterSelectionsFactory,
} from '../utils/insightsPortalSchemas.utils'
import { type DomainMessages } from '../../utils/domain/intl'
import {
  type InsightsFormatterFunction,
  InsightsGroupByTypes,
  type InsightsMetricDesiredDirection,
} from '../types/schema.types'
import { InsightsFormatterType } from '../types/schema.types'
import { TimeIntervalDimensions } from '../types/groupBy.types'
import { type DomainAccessControlConfig } from '../../utils/domain/accessControl'

export enum PrivateSchemaPropertyType {
  DataExplorerFilters = 'DataExplorerFilters',
  DataExplorerTable = 'DataExplorerTable',
}

/*
  This function is used to define the configuration for a schema. It just returns what was passed into it
  but it is being used to ensure stricter generic typing which can't be done with a plain Interface or Object type
*/
export function createSchemaConfig<
  TMetrics extends Record<string, string>,
  TGroupBys extends Record<string, string>,
  TDimensions extends Record<string, string>
>(arg: {
  metrics: TMetrics
  groupBys?: TGroupBys
  dimensions?: TDimensions
  defaultDateDimension?: TDimensions[keyof TDimensions]
  dimensionGroupings?: {
    label: DomainMessages
    dimensions: TDimensions[keyof TDimensions][] | TimeIntervalDimensions[]
  }[]
  defaultFilters?: {
    name: TDimensions[keyof TDimensions]
    values: string[]
    accessControlEnablement: DomainAccessControlConfig
  }[]
  filterConfigs?: ReturnType<typeof createFilterParamConfig>[]
  transformFilterParamsToApiFilterSelections?: ReturnType<
    typeof transformFilterParamsToApiFilterSelectionsFactory
  >
  metricsConfig: Record<
    TMetrics[keyof TMetrics],
    Partial<{
      formatter: InsightsFormatterType | InsightsFormatterFunction
      // label should be the en-us translation key
      label: DomainMessages
      desiredDirection?: InsightsMetricDesiredDirection
      private: boolean | PrivateSchemaPropertyType | DomainAccessControlConfig
      tooltip?: DomainMessages
    }>
  >

  groupBysConfig?: Record<
    TGroupBys[keyof TGroupBys],
    Partial<{
      formatter: InsightsFormatterType | InsightsFormatterFunction
      // label should be the en-us translation key
      label: DomainMessages
      type: InsightsGroupByTypes
      magnitude: number
      private: boolean | PrivateSchemaPropertyType
      tooltip?: DomainMessages
    }>
  >

  dimensionsConfig?: Record<
    TDimensions[keyof TDimensions],
    Partial<{
      formatter: InsightsFormatterType | InsightsFormatterFunction
      // label should be the en-us translation key
      label: DomainMessages
      type: InsightsGroupByTypes
      magnitude: number
      private: boolean | PrivateSchemaPropertyType
      date?: boolean
      tooltip?: DomainMessages
    }>
  >
}) {
  return arg
}

export const buildTimeIntervalGroupBysConfig: Record<
  TimeIntervalDimensions,
  ReturnType<typeof createSchemaConfig>['groupBysConfig'][string]
> = {
  [TimeIntervalDimensions.DATE_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.days.label',
    formatter: InsightsFormatterType.Date,
  },
  [TimeIntervalDimensions.WEEK_SUN_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
  },
  [TimeIntervalDimensions.WEEK_MON_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
  },
  [TimeIntervalDimensions.WEEK_TUE_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
  },
  [TimeIntervalDimensions.WEEK_WED_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
  },
  [TimeIntervalDimensions.WEEK_THU_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
  },
  [TimeIntervalDimensions.WEEK_FRI_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
  },
  [TimeIntervalDimensions.WEEK_SAT_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
  },
  [TimeIntervalDimensions.MONTH_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.months.label',
    formatter: InsightsFormatterType.Date,
  },
}

export const buildTimeIntervalDimensionsConfig: Record<
  TimeIntervalDimensions,
  ReturnType<typeof createSchemaConfig>['dimensionsConfig'][string]
> = {
  [TimeIntervalDimensions.DATE_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.days.label',
    formatter: InsightsFormatterType.Date,
    date: true,
  },
  [TimeIntervalDimensions.WEEK_SUN_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    date: true,
  },
  [TimeIntervalDimensions.WEEK_MON_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
    date: true,
  },
  [TimeIntervalDimensions.WEEK_TUE_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
    date: true,
  },
  [TimeIntervalDimensions.WEEK_WED_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
    date: true,
  },
  [TimeIntervalDimensions.WEEK_THU_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
    date: true,
  },
  [TimeIntervalDimensions.WEEK_FRI_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
    date: true,
  },
  [TimeIntervalDimensions.WEEK_SAT_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.weeks.label',
    formatter: InsightsFormatterType.Date,
    private: true,
    date: true,
  },
  [TimeIntervalDimensions.MONTH_PT]: {
    type: InsightsGroupByTypes.TimeInterval,
    label: 'insightsPortalDomain.pillTabs.months.label',
    formatter: InsightsFormatterType.Date,
    date: true,
  },
}

export const buildTimeIntervalDimensionsGrouping: {
  label: DomainMessages
  dimensions: TimeIntervalDimensions[]
} = {
  label: 'insightsPortalDomain.pillTabs.days.label',
  dimensions: [
    TimeIntervalDimensions.DATE_PT,
    TimeIntervalDimensions.WEEK_SUN_PT,
    TimeIntervalDimensions.MONTH_PT,
  ],
}
