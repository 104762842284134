import React, { useMemo } from 'react'
import { useDeepMemo } from '../../../hooks/useDeepReactHooks.hooks'
import { GridHeaderGroupRow } from '../components/GridHeaderGroupRow'
import { type GridDatum, type CustomTableInstance } from './grid.types'

interface Props<TDatum extends GridDatum> extends CustomTableInstance<TDatum> {}

export const useGridWindowDetails = <TDatum extends GridDatum>({
  headerGroups,
  prepareRow,
  rows,
  compact,
}: Props<TDatum>) => {
  /**
   * We prepare a list of dependencies to pass to useDeepMemo, so that we can properly memoize the renderedHeaders
   * see below for a better explanation on why we do this.
   *
   * if there's a need for more properties to be 'watched', add them here.
   * Note that this cannot be memoized since headerGroups never changes unless the developer changes the columns purposedly.
   */

  const headerDeps = headerGroups
    .map(headerGroup =>
      headerGroup.headers.map(({ id, isSorted, isSortedDesc }) => ({
        id,
        isSorted,
        isSortedDesc,
      }))
    )
    .flat()

  const renderedHeaders = useDeepMemo(
    () =>
      headerGroups.map((headerGroup, index) => (
        <GridHeaderGroupRow key={index} {...headerGroup} compact={compact} />
      )),
    /**
     * Dependencies are set this way because of an issue with react-table, where changing the sort state of a column actually
     * mutates one of the objects nested in `headerGroups` instead of creating new objects. This causes useDeepMemo to incorrectly
     * think there's no changes, because it holds a reference to the same object, and the comparison will always be equal.
     *
     * While doing this  has a performance impact, this impact is negligible compared to the performance impact of re-rendering the entire grid and potentially
     * losing state. This will _still_ lose state when the user sorts by a column, but at least that behavior is less likely to happen.
     *
     * Also FYI, state is lost due to react-window needing a component for the `InnerElementType` prop, however, that component cannot be built statically.
     * TODO: kill all react-window-ified tables and use paginated versions only. We wouldn't be having this issue if it weren't for react-window.
     */
    [headerGroups, headerDeps]
  )

  const itemCount = rows.length

  const itemData = useMemo(
    () => ({
      rows: rows,
      prepareRow,
      compact,
    }),
    [rows, prepareRow, compact]
  )

  return {
    renderedHeaders,
    itemCount,
    itemData,
  }
}
