import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
  URL_PARTNER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const RELATIVE_LIST = '/list'
const RELATIVE_CREATE = '/create'
const RELATIVE_EDIT = '/edit/:accountCanonicalId'

const URL_ACCOUNT_MANAGEMENT = `${URL_RETAILER_SCOPED}/account-management`

const URL_RETAILER_SECURITY = `${URL_ACCOUNT_MANAGEMENT}/retailer-security`
// @hygen:inject scoped-urls

const URL_ADMIN_ACCOUNT_MANAGEMENT = `${URL_APP_ADMIN}/account-management`
const URL_ADMIN_ACCOUNT_MANAGEMENT_LIST = `${URL_ADMIN_ACCOUNT_MANAGEMENT}${RELATIVE_LIST}`
const URL_ADMIN_ACCOUNT_MANAGEMENT_CREATE = `${URL_ADMIN_ACCOUNT_MANAGEMENT}${RELATIVE_CREATE}`
const URL_ADMIN_ACCOUNT_MANAGEMENT_EDIT = `${URL_ADMIN_ACCOUNT_MANAGEMENT}${RELATIVE_EDIT}`

const URL_PARTNER_ACCOUNT_MANAGEMENT = `${URL_PARTNER_SCOPED}/account-management`
const URL_PARTNER_ACCOUNT_MANAGEMENT_LIST = `${URL_PARTNER_ACCOUNT_MANAGEMENT}${RELATIVE_LIST}`
const URL_PARTNER_ACCOUNT_MANAGEMENT_CREATE = `${URL_PARTNER_ACCOUNT_MANAGEMENT}${RELATIVE_CREATE}`
const URL_PARTNER_ACCOUNT_MANAGEMENT_EDIT = `${URL_PARTNER_ACCOUNT_MANAGEMENT}${RELATIVE_EDIT}`

// @hygen:inject admin-urls

export type DashRouteSet =
  | DashRouteType<'retailer-account-management'>
  | DashRouteType<'app-admin-account-management'>
  | DashRouteType<'app-admin-account-management-list'>
  | DashRouteType<'app-admin-account-management-create'>
  | DashRouteType<'app-admin-account-management-edit'>
  | DashRouteType<'partner-account-management'>
  | DashRouteType<'partner-account-management-list'>
  | DashRouteType<'partner-account-management-create'>
  | DashRouteType<'partner-account-management-edit'>
  | DashRouteType<'retailer-security'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-admin-account-management': URL_ADMIN_ACCOUNT_MANAGEMENT,
  'app-admin-account-management-list': URL_ADMIN_ACCOUNT_MANAGEMENT_LIST,
  'app-admin-account-management-create': URL_ADMIN_ACCOUNT_MANAGEMENT_CREATE,
  'app-admin-account-management-edit': URL_ADMIN_ACCOUNT_MANAGEMENT_EDIT,
  'partner-account-management': URL_PARTNER_ACCOUNT_MANAGEMENT,
  'partner-account-management-list': URL_PARTNER_ACCOUNT_MANAGEMENT_LIST,
  'partner-account-management-create': URL_PARTNER_ACCOUNT_MANAGEMENT_CREATE,
  'partner-account-management-edit': URL_PARTNER_ACCOUNT_MANAGEMENT_EDIT,
  'retailer-account-management': URL_ACCOUNT_MANAGEMENT,
  'retailer-security': URL_RETAILER_SECURITY,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
