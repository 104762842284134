import { JsonParam } from 'use-query-params'
import moment from 'moment'
import { type DateSelectRangeValueType } from '../../legacy/dash-components/date-select-range/utils/dateSelectRange.types'
import { dateToStringFormat } from '../../legacy/common/utils/time/dateFormat'
import { type DateRange, dateRange } from '../../legacy/common/utils/time/dateRanges.types'
import { type QueryParamEncodedType } from './queryParams.types'

type EncodedDateSelectRange = {
  dateRange: DateRange
  customStartDate?: string
  customEndDate?: string
}

const encode = (inputValue: DateSelectRangeValueType | undefined): QueryParamEncodedType => {
  if (!inputValue) return undefined

  const {
    dateRange: inputDateRange,
    customDates: { startDate, endDate },
  } = inputValue

  const queryObj: EncodedDateSelectRange = {
    dateRange: inputDateRange,
  }

  const isCustom = inputDateRange === dateRange.CUSTOM

  if (isCustom && startDate) {
    queryObj.customStartDate = dateToStringFormat(startDate)
  }

  if (isCustom && endDate) {
    queryObj.customEndDate = dateToStringFormat(endDate)
  }

  return JsonParam.encode(queryObj)
}

const decode = (queryStr: QueryParamEncodedType): DateSelectRangeValueType | undefined => {
  if (!queryStr) return undefined

  const {
    dateRange: inputDateRange,
    customStartDate,
    customEndDate,
  } = JsonParam.decode(queryStr) as Partial<EncodedDateSelectRange>

  // If we can't parse the dateRange, we fall back to undefined
  if (!inputDateRange) return undefined

  const parsedStartDate = customStartDate ? moment(customStartDate) : null
  const parsedEndDate = customEndDate ? moment(customEndDate) : null

  return {
    dateRange: inputDateRange,
    customDates: {
      startDate: parsedStartDate,
      endDate: parsedEndDate,
    },
  }
}

export const dateSelectRangeQueryParamEncoder = {
  decode,
  encode,
}
