import { type ReportTypeEnum } from '../../../api'
import { type DomainMessages } from '../../../utils/domain/intl'

// add a translation to display a report type in the dropdown
export const REPORT_TYPE_CONFIG: Partial<Record<ReportTypeEnum, DomainMessages>> = {
  ADS_DAILY: 'reportsDomain.normalizedReports.list.reportTypes.ADS_DAILY',
  ADS_MONTHLY_ADVERTISER_LEVEL:
    'reportsDomain.normalizedReports.list.reportTypes.ADS_MONTHLY_ADVERTISER_LEVEL',
  ADS_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.ADS_MONTHLY',
  ADS_WEEKLY: 'reportsDomain.normalizedReports.list.reportTypes.ADS_WEEKLY',
  ALDI_ACI_DAILY_REPORT: 'reportsDomain.normalizedReports.list.reportTypes.ALDI_ACI_DAILY_REPORT',
  ALDI_ACI_DAILY_STORE_LEVEL_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.ALDI_ACI_DAILY_STORE_LEVEL_REPORT',
  ASSOCIATE_LEVEL_PARTNER_PERFORMANCE_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.ASSOCIATE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  BYPASS_ORDERS_FEEDBACK_OUTSTANDING_FILES_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.BYPASS_ORDERS_FEEDBACK_OUTSTANDING_FILES_REPORT',
  CAPER_DAILY_TRANSACTIONS_DETAILS:
    'reportsDomain.normalizedReports.list.reportTypes.CAPER_DAILY_TRANSACTIONS_DETAILS',
  CAPER_DAILY_TRANSACTIONS_SUMMARY:
    'reportsDomain.normalizedReports.list.reportTypes.CAPER_DAILY_TRANSACTIONS_SUMMARY',
  CAPER_INCENTIVES_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.CAPER_INCENTIVES_REPORT',
  CAPER_INCENTIVES_MONTHLY:
    'reportsDomain.normalizedReports.list.reportTypes.CAPER_INCENTIVES_MONTHLY',
  DAILY_ULTRAFAST_DASHBOARD:
    'reportsDomain.normalizedReports.list.reportTypes.DAILY_ULTRAFAST_DASHBOARD',
  DAILY_ULTRAFAST_ORDER_REPORTING:
    'reportsDomain.normalizedReports.list.reportTypes.DAILY_ULTRAFAST_ORDER_REPORTING',
  DELIVERY_NOW_BY_MARIANOS_DAILY_ADJUSTMENT_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.DELIVERY_NOW_BY_MARIANOS_DAILY_ADJUSTMENT_REPORT',
  DISPLAY_PREVIEW_DAILY: 'reportsDomain.normalizedReports.list.reportTypes.DISPLAY_PREVIEW_DAILY',
  DISTRIBUTION_SYSTEM_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.DISTRIBUTION_SYSTEM_REPORT',
  DLOG_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.DLOG_MONTHLY',
  GIANT_EAGLE_LOYALTY_PERKS_ATTRIBUTION_AD_HOC_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.GIANT_EAGLE_LOYALTY_PERKS_ATTRIBUTION_AD_HOC_REPORT',
  LIVE_ISSUE: 'reportsDomain.normalizedReports.list.reportTypes.LIVE_ISSUE',
  MERGED_RETAILER_TAX_LOGS:
    'reportsDomain.normalizedReports.list.reportTypes.MERGED_RETAILER_TAX_LOGS',
  MERGED_SHOPPER_REIMBURSEMENTS_MONTHLY:
    'reportsDomain.normalizedReports.list.reportTypes.MERGED_SHOPPER_REIMBURSEMENTS_MONTHLY',
  MERGED_TAXES_PAID_AT_POS:
    'reportsDomain.normalizedReports.list.reportTypes.MERGED_TAXES_PAID_AT_POS',
  MLOG_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.MLOG_MONTHLY',
  MPF_TAXES_SEMI_MONTHLY: 'reportsDomain.normalizedReports.list.reportTypes.MPF_TAXES_SEMI_MONTHLY',
  NEW_VERTICALS_WEEKLY_RETAILER_UPDATE:
    'reportsDomain.normalizedReports.list.reportTypes.NEW_VERTICALS_WEEKLY_RETAILER_UPDATE',
  ORDER_RATINGS: 'reportsDomain.normalizedReports.list.reportTypes.ORDER_RATINGS',
  OUT_OF_STOCK: 'reportsDomain.normalizedReports.list.reportTypes.OUT_OF_STOCK',
  REPLACEMENTS_AND_REFUNDS:
    'reportsDomain.normalizedReports.list.reportTypes.REPLACEMENTS_AND_REFUNDS',
  RETAILER_PICKUP: 'reportsDomain.normalizedReports.list.reportTypes.RETAILER_PICKUP',
  RETAILER_TAX_LOGS: 'reportsDomain.normalizedReports.list.reportTypes.RETAILER_TAX_LOGS',
  RUNNER_HOURS: 'reportsDomain.normalizedReports.list.reportTypes.RUNNER_HOURS',
  SCAN_AND_PAY: 'reportsDomain.normalizedReports.list.reportTypes.SCAN_AND_PAY',
  SHOPPER_BREAKS: 'reportsDomain.normalizedReports.list.reportTypes.SHOPPER_BREAKS',
  SHOPPER_REIMBURSEMENTS_MONTHLY:
    'reportsDomain.normalizedReports.list.reportTypes.SHOPPER_REIMBURSEMENTS_MONTHLY',
  STORE_IN_STORE_PERFORMANCE_AGG:
    'reportsDomain.normalizedReports.list.reportTypes.STORE_IN_STORE_PERFORMANCE_AGG',
  STORE_IN_STORE_PERFORMANCE_DTL:
    'reportsDomain.normalizedReports.list.reportTypes.STORE_IN_STORE_PERFORMANCE_DTL',
  STORE_LEVEL_PARTNER_PERFORMANCE_REPORT:
    'reportsDomain.normalizedReports.list.reportTypes.STORE_LEVEL_PARTNER_PERFORMANCE_REPORT',
  TAXES_PAID_AT_POS: 'reportsDomain.normalizedReports.list.reportTypes.TAXES_PAID_AT_POS',
  UNATA_EXPRESS_WEEKLY: 'reportsDomain.normalizedReports.list.reportTypes.UNATA_EXPRESS_WEEKLY',
  UUID_COMPLIANCE: 'reportsDomain.normalizedReports.list.reportTypes.UUID_COMPLIANCE',
  WEEKLY_RETAILER_UPDATE: 'reportsDomain.normalizedReports.list.reportTypes.WEEKLY_RETAILER_UPDATE',
}

export type ReportTypeConfig = Partial<Record<ReportTypeEnum, DomainMessages>>

// config to determine if a report type can be merged
export const REPORT_TYPE_MERGE_CONFIG: Partial<Record<ReportTypeEnum, boolean>> = {
  RETAILER_TAX_LOGS: true,
  TAXES_PAID_AT_POS: true,
  SHOPPER_REIMBURSEMENTS_MONTHLY: true,
  TRANSACTION_LOGS: true,
  IN_STORE_TRANSACTIONS: true,
}

export const reportTypeCanMerge = (reportType: ReportTypeEnum): boolean =>
  Boolean(REPORT_TYPE_MERGE_CONFIG[reportType])
