import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

/**
 * A hook to use a key from sessionStorage.
 * It returns its data, loading and a function to set the value
 * The key values are stored as JSON strings and parsed when retrieved.
 *
 * @param key
 * @returns
 */
export const useSessionStorage = <T extends {}>(key: string) => {
  const queryClient = useQueryClient()

  const setKey = useMutation<any, any, T>({
    mutationFn: async value => sessionStorage.setItem(key, JSON.stringify(value)),
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    },
  })

  const getKey = useQuery<T, string>({
    queryKey: [key],
    queryFn: () => {
      const value = sessionStorage.getItem(key)

      try {
        return JSON.parse(value)
      } catch (e) {
        return value
      }
    },
  })

  return {
    loading: getKey.isLoading,
    data: getKey.data,
    setData: setKey.mutate,
    hasKey: getKey.isFetched ? Boolean(getKey.data) : undefined,
  }
}
