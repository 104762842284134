import React, { type FunctionComponent, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { ChevronDownIcon } from '@instacart/ids-core'
import { css, type Interpolation } from '@emotion/react'
import { colors, fontSize, fontWeight, spacing } from '../../foundation'
import { Text } from '../text/Text'

const AccordionHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: spacing.X16,
  paddingBottom: spacing.X16,
  cursor: 'pointer',
  gap: spacing.X8,
  alignItems: 'center',
})

const AccordionTitle = styled.div({
  flex: 2,
  fontWeight: fontWeight.SEMIBOLD,
  fontSize: fontSize.X15,
})

const chevronStyles = (expanded: boolean) =>
  css({
    transition: 'transform 0.2s',
    transform: expanded ? 'rotate(180deg)' : 'none',
  })

export interface AccordionContentWrapperProps {
  showContentBackground: boolean
}

const AccordionContentWrapper = styled.div<AccordionContentWrapperProps>(
  ({ showContentBackground }) => ({
    marginBottom: spacing.X12,
    padding: spacing.X12,
    background: showContentBackground ? colors.GRAYSCALE.X10 : colors.GRAYSCALE.X0,
    borderRadius: spacing.X12,
  })
)

export const AccordionTestIds = {
  header: 'accordion-header',
  subheader: 'accordion-subheader',
  title: 'accordion-title',
  content: 'accordion-content',
}

interface Props {
  title: React.ReactNode
  subtitle?: string
  showContentBackground?: boolean
  isExpanded?: boolean
  headerCss?: Interpolation<any>
  contentCss?: Interpolation<any>
}

export const Accordion: FunctionComponent<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  showContentBackground = false,
  isExpanded = false,
  headerCss,
  contentCss,
  children,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setExpanded(isExpanded)
  }, [isExpanded])

  return (
    <div {...props}>
      <AccordionHeader
        onClick={() => setExpanded(!expanded)}
        data-testid={AccordionTestIds.header}
        css={headerCss}
      >
        <AccordionTitle data-testid={AccordionTestIds.title}>{title}</AccordionTitle>
        {subtitle && (
          <Text data-testid={AccordionTestIds.subheader} size="small" weight="semibold">
            {subtitle}
          </Text>
        )}
        <ChevronDownIcon css={chevronStyles(expanded)} size={16} color="systemGrayscale70" />
      </AccordionHeader>
      {expanded && (
        <AccordionContentWrapper
          showContentBackground={showContentBackground}
          data-testid={AccordionTestIds.content}
          css={contentCss}
        >
          {children}
        </AccordionContentWrapper>
      )}
    </div>
  )
}
