import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  navigationAdminViewAccessControl,
  storefrontAdminAccessControl,
} from '../access-control/storefrontAdminAccess.configuration'

const StorefrontAdminNavigationFooterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StorefrontNavigationFooterPage" */
      '../pages/navigation/admin/StorefrontAdminNavigationFooterPage'
    )
)

export const storefrontAdminRouter = (
  <DomainRoute route="storefront-admin" accessControl={storefrontAdminAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="storefront-admin-navigation-footer"
        component={StorefrontAdminNavigationFooterPage}
        accessControl={navigationAdminViewAccessControl}
      />
    </DashSwitch>
  </DomainRoute>
)
