import { useMeshQuery } from '../../../apollo/clientHooks'
import {
  GetCatalogItemsDocument,
  type GetCatalogItemsQuery,
  type GetCatalogItemsQueryVariables,
} from '../../../../__codegen__/api'

export const useGetCatalogItems = (props: GetCatalogItemsQueryVariables) => {
  const variables = {
    ...props,
  }

  const { data, error, loading, refetch } = useMeshQuery<
    GetCatalogItemsQuery,
    GetCatalogItemsQueryVariables
  >(GetCatalogItemsDocument, {
    fetchPolicy: 'cache-and-network',
    variables,
  })

  return {
    data: data?.instacart_customers_product_retrieval_v1_retailer_tools_v1_RetailerToolsProductRetrievalService_GetItems,
    isLoading: loading,
    isError: error,
    refetch,
  }
}
