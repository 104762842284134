const EN_US = {
  'foodstormDomain.title': 'FoodStorm',
  'foodstormDomain.educationalBanner.heading':
    'Ready to digitize your deli, made-to-order counter and bakery?',
  'foodstormDomain.educationalBanner.information':
    "FoodStorm is Instacart's order management system built for grocery food service. Digitize in-store ordering with a kiosk and perimeter counter production with a kitchen display system.",
  'foodstormDomain.educationalBanner.buttonText': 'Learn More',
} as const

export default EN_US
