import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  NavItem,
  AdminNavEntryPoint,
  AdminNavEntryPointV2,
} from '@retailer-platform/dashboard/routing'
import { pageBuilderViewAccessControl } from './access-control/landingAccess.configuration'
import { landingRouter } from './routing/landingRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'landing',
  totem: {
    entity: 'landing-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    admin: [landingRouter],
  },
  navigationEntries: [],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.LandingPageSystem,
      attachToV2: AdminNavEntryPointV2.Marketing,
      subNavItems: [
        {
          route: 'landing-content-pages-dashboard',
          labelId: 'landingDomain.landingPageBuilder',
          accessControl: pageBuilderViewAccessControl,
          positionNavV2: 2,
        },
      ],
    },
  ],
}

export default definition
