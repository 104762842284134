import { type SingleDomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureEntitlements } from './featureEntitlements'

export const navigationViewAccessControl: SingleDomainAccessControlConfig = {
  featureEntitlementIds: [FeatureEntitlements.Navigation],
  permissions: [Permission.StoreConfigsView, Permission.StorefrontNavigationView],
}

export const navigationModifyAccessControl: SingleDomainAccessControlConfig = {
  featureEntitlementIds: [FeatureEntitlements.Navigation],
  permissions: [Permission.StoreConfigsModify],
}

export const departmentNavigationAccessControl: SingleDomainAccessControlConfig = {
  permissions: [Permission.DepartmentsView],
}

export const navigationOpenContentPageFlyoutAccessControl: SingleDomainAccessControlConfig = {
  featureEntitlementIds: [FeatureEntitlements.PageBuilderNavigateToFlyoutModal],
}
