import { useMemo } from 'react'
import { useLocation, matchPath, type match as RouteMatch } from 'react-router'
import { useCoreContext } from '../core/RPPCoreContext'

type AbsoluteRouteMatch = RouteMatch & {
  // The pathName for the current match, IPP-specific
  pathName: string
  route: string
}

/**
 * Similar to `useRouteMatch`, but has the following considerations:
 * - Will always match the most specific route possible, as opposed to react-router's only matching down to the level
 * you're currently on on the tree. In other words, this can be used anywhere to obtain the current match.
 * - Will attempt to do an exact match first, and fall back to an inexact match if not found.
 * - Will also return the pathName, which is an IPP-specific identifier for a route
 *
 * Note that the params are untyped, as there's no way of deterministically knowing which path we'll be matching against.
 * If you need something more specific and you know what you're matching against, use `useRouteMatch` from `react-router` instead
 *
 *  @returns
 */
export const useAbsoluteRouteMatch = (): AbsoluteRouteMatch | undefined => {
  const location = useLocation()
  const { routesByName } = useCoreContext()

  const patterns = useMemo(
    () =>
      // Get all entries and reverse to start first by more specific paths instead of the root
      Object.entries(routesByName).reverse(),
    [routesByName]
  )

  return useMemo(() => {
    let lastMatch: RouteMatch
    // Create a filter that can change between exact and inexact
    // This will also store the last match as a side effect
    const patternFilter =
      (exact: boolean) =>
      ([_, path]: [string, string]) => {
        const result = matchPath(location.pathname, { path, exact })
        if (result) lastMatch = result

        return result
      }

    /**
     * Attempt to get an exact pattern
     * Note: might also want to do a check against patternFilter(false) if this fails. However, this
     * seems to not be what we want, as otherwise this could match for instance url /foo against the / pattern
     *
     * Leaving this comment just in case we need to reconsider later.
     */
    const found = patterns.find(patternFilter(true))

    if (!found) return undefined

    return { route: found[0], pathName: found[0], ...lastMatch }
  }, [location.pathname, patterns])
}
