import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { storefrontOnboardingAccessControl } from './access-control/storefrontOnboardingAccess.configuration'
import { storefrontOnboardingRouter } from './routing/storefrontOnboardingRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'storefront-onboarding',
  totem: {
    entity: 'storefront-onboarding-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [storefrontOnboardingRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Storefront,
      attachToV2: NavEntryPoint.UserMenuSettings,
      subNavItems: [
        {
          type: 'item',
          labelId: 'storefrontOnboardingDomain.android.nav',
          route: 'storefront-onboarding-android',
          accessControl: storefrontOnboardingAccessControl,
          id: 'storefront-onboarding-android',
          positionNavV2: 5,
        },
        {
          type: 'item',
          labelId: 'storefrontOnboardingDomain.ios.nav',
          route: 'storefront-onboarding-ios',
          accessControl: storefrontOnboardingAccessControl,
          id: 'storefront-onboarding-ios',
          positionNavV2: 4,
        },
        {
          type: 'item',
          labelId: 'storefrontOnboardingDomain.web.nav',
          route: 'storefront-onboarding-web',
          accessControl: storefrontOnboardingAccessControl,
          id: 'storefront-onboarding-web',
          positionNavV2: 6,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
