/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import { useCoreContext } from '../../RPPCoreContext'
import { url } from '../../../parsing/url'

export type useScopedPathnameFn<T extends string = string> = (name: T, params?: object) => string

export const baseUseScopedPathname: useScopedPathnameFn = (name, params = {}) => {
  const getPath = baseUseGetScopedPathname()

  return useMemo(() => getPath(name, params), [getPath, name, params])
}

export type useGetScopedPathnameFn<T extends string = string> = () => useScopedPathnameFn<T>

/**
 * Returns a function to get a scoped pathname
 */
export const baseUseGetScopedPathname = (): useScopedPathnameFn => {
  const { routesByName } = useCoreContext()
  const queryParams = useParams()

  return useCallback(
    (name, params) => {
      const pattern = routesByName[name as unknown as string]

      return url(pattern, {
        ...queryParams,
        ...params,
      })
    },
    [routesByName, queryParams]
  )
}
