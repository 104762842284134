import React, { type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import styled from '@emotion/styled'
import { InformationIcon, OpenInIcon } from '@instacart/ids-core'
import { Tooltip, colors } from '@retailer-platform/shared-components'
import { type AccessControlConfig } from '../../../../legacy/components/AccessControl/accessControl.utils'
import { useNavContext } from '../../nav/utils/NavContext'
import { useAbsoluteRouteMatch } from '../../../../utils/routing/useAbsoluteRouteMatch.hooks'
import { useAdminControls } from '../../../../utils/contexts/admin-controls/AdminControlsContext'
import { useCoreContext } from '../../../../utils/core/RPPCoreContext'
import { useOptionalPartnerContext } from '../../../../exports/utils'
import { type NavSupportedEnvironment } from '../../../../exports/routing'
import { getHrefOverride } from '../utils/navEnvironment'
import { NavLink } from './NavLink'

export type NavItemProps = {
  title: string
  route?: string
  href?: string
  accessControl?: AccessControlConfig
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  routePresent?: boolean
  newTab?: boolean
  isNew?: boolean
  customPadding?: string
  NavItemWrapper?: FunctionComponent<React.PropsWithChildren<unknown>>
  environment?: NavSupportedEnvironment
}

export const NavItemStyle = styled.div<{
  selected?: boolean
  padding?: string
}>(
  {
    display: 'flex',
    minHeight: '40px',
    gap: '8px',
    alignItems: 'center',
    '&:focus': {
      outlineStyle: 'solid',
      outlineColor: colors.PRIMARY_TDS.REGULAR,
      outlineWidth: '2px',
    },
    '&:hover': {
      backgroundColor: colors.PRIMARY_TDS.LIGHT,
    },
  },
  ({ selected, padding }) => ({
    backgroundColor: selected && colors.PRIMARY_TDS.EXTRA_LIGHT,
    borderLeft: `3px solid ${selected ? colors.PRIMARY_TDS.REGULAR : 'transparent'}`,
    padding: padding ? padding : '8px 16px 8px 13px', // make the left side 13px to account for the 3px border
  })
)

const NewTag = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1px 4px',
  width: '35px',
  height: '20px',
  background: '#F6F7F8',
  marginLeft: '8px',

  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
})

export const TextContainer = styled.div<{
  fontWeight?: number
  paddingLeft?: string
}>(({ fontWeight }) => ({
  display: 'flex',
  alignItems: 'center',
  color: colors.PRIMARY_TDS.REGULAR,
  fontSize: '14px',
  fontWeight: fontWeight ? fontWeight : 600,
  lineHeight: '20px',
  flexShrink: 1,
}))

export const NavItem: FunctionComponent<React.PropsWithChildren<NavItemProps>> = ({
  onClick,
  route,
  title,
  href,
  newTab,
  isNew,
  customPadding,
  accessControl,
  NavItemWrapper,
  environment,
}) => {
  const intl = useIntl()
  const { mostRecentClickedRoute, setMostRecentClickedRoute } = useNavContext()
  const routeParams = useAbsoluteRouteMatch()
  const partnerContext = useOptionalPartnerContext()
  const { routesByName } = useCoreContext()

  const selected = route === routeParams?.route || route === mostRecentClickedRoute

  const hrefOverride = getHrefOverride(route, environment, partnerContext, routesByName)

  const titleMessage = intl.formatMessage({ id: title })

  const isCustomWrapper = !!NavItemWrapper
  const NavItemWrapperComponent = NavItemWrapper || React.Fragment
  return (
    <NavItemWrapperComponent {...(isCustomWrapper ? { route } : {})}>
      <div
        css={{
          position: 'relative',
          alignItems: 'center',
          maxWidth: '400px',
        }}
      >
        <NavLink
          onClick={e => {
            onClick(e)
            if (!newTab) {
              setMostRecentClickedRoute(route)
            }
          }}
          route={route}
          newTab={Boolean(hrefOverride || href) || newTab}
          href={hrefOverride || href}
        >
          <NavItemStyle selected={selected} padding={customPadding}>
            <TextContainer>{titleMessage}</TextContainer>

            {(hrefOverride || href || newTab) && (
              <OpenInIcon size={16} color={'brandPrimaryExtraDark'} />
            )}

            {isNew && <NewTag>New</NewTag>}

            <ToolTipForAccessControl accessControl={accessControl} />
          </NavItemStyle>
        </NavLink>
      </div>
    </NavItemWrapperComponent>
  )
}

const ToolTipForAccessControl = (props: { accessControl: unknown }) => {
  const hasAccess = useHasShowAccessControls()
  if (!hasAccess) {
    return null
  }

  return (
    <Tooltip
      target={
        <InformationIcon
          color="systemGrayscale00"
          size={22}
          style={{ paddingLeft: 8, fill: '#0A5546' }}
        />
      }
      containerStyles={{ maxWidth: null }}
    >
      <div>
        <pre>{JSON.stringify(props.accessControl || {}, null, 2)}</pre>
      </div>
    </Tooltip>
  )
}

const useHasShowAccessControls = () => {
  const { showNavAccessControlSettings } = useAdminControls()
  return showNavAccessControlSettings
}
