import { type OptionProps } from 'react-select-5'
import React, { useCallback } from 'react'
import { type SimpleOption } from '../utils/types'
import { useConfirmContext } from '../utils/ConfirmStepProvider.context'
import { defaultComponents } from '.'

export const ConfirmValuesOption = (props: OptionProps<SimpleOption, any, any>) => {
  const { stagedOptions, toggleStageOption } = useConfirmContext()

  const onClick = useCallback(() => {
    toggleStageOption(props.data)
  }, [props.data, toggleStageOption])

  // This is just to appease TS
  if (!defaultComponents.Option) return null
  return (
    <defaultComponents.Option
      {...props}
      innerProps={{ ...props.innerProps, onClick: onClick }}
      isSelected={stagedOptions.has(props.data)}
    />
  )
}
