import React, { type FunctionComponent, useMemo } from 'react'
import { css } from '@emotion/react'
import { type EmotionStyles } from '../../utils/styling/types'
import { type DashMessages } from '../../../intl/intl.types'
import { useDashMessage } from '../../../intl/intl.hooks'
import { colors } from '../../foundation/colors'
import { type TestID } from '../../utils/testing/types'
import { spacing } from '../../foundation/spacing'
import { fontSize, defaultLineHeight } from '../../foundation/fontSize'

export interface ErrorMessageProps extends EmotionStyles, TestID {
  messageId?: DashMessages
  collapse?: boolean
  className?: string
}

const errorMessageStyles = css({
  color: colors.DETRIMENTAL.REGULAR,
  marginTop: spacing.X4,
  marginBottom: spacing.X4,
  marginLeft: spacing.X8,
  marginRight: spacing.X8,
  fontSize: fontSize.X12,
})

export const ErrorMessage: FunctionComponent<React.PropsWithChildren<ErrorMessageProps>> = ({
  messageId,
  children,
  styles,
  collapse = true,
  ...rest
}) => {
  const message = useDashMessage(messageId)
  const resolvedMessage = children ?? message

  // By default, we don't collapse the error message components
  // When collapse=true, it will take up no space when the messageId is undefined
  const dynamicStyles = useMemo(() => {
    if (collapse) {
      return css({ minHeight: 0 })
    }

    return css({ minHeight: defaultLineHeight })
  }, [collapse])

  return (
    <div css={css(errorMessageStyles, styles, dynamicStyles)} {...rest}>
      {resolvedMessage}
    </div>
  )
}
