import React, { useMemo } from 'react'
// Approved by the IPP team.
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import ReactSelect, {
  createFilter,
  type GroupBase,
  mergeStyles,
  type SelectComponentsConfig,
} from 'react-select-5'
import { useDeepMemo } from '../../hooks/useDeepReactHooks.hooks'
import { getSelectBaseStyles, makeSelectBaseTheme } from './utils/styles'
import { CompactMultiSelectPlaceholder } from './components/CompactMultiSelectPlaceholder'
import { type BaseSelectProps } from './utils/types'
import { defaultComponents } from './components'
import { ConfirmValuesMenu } from './components/ConfirmValuesMenu'
import { ConfirmStepProvider } from './utils/ConfirmStepProvider.context'
import { ConfirmValuesOption } from './components/ConfirmValuesOption'

/**
 * This is the Select that has all the IPP specific functionality.
 * You may base your component off this only if needed, but for 99.9% of cases,
 * you should work with NewSelect instead.
 */
export const BaseSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  compact,
  styles,
  filterOption,
  ignoreAccents = true,
  compactMultiLabel = false,
  withConfirmation = false,
  ...props
}: BaseSelectProps<Option, IsMulti, Group>) => {
  /**
   * By default, react-select strips accents from inputs
   * There is a significant performance boost gained by disabling this
   */
  const customFilterOption = filterOption || createFilter({ ignoreAccents })

  /**
   * This allows for overrides of the incoming props. This can be used to modify props
   * based on additional props
   */
  const resolvedProps = useMemo(() => {
    const p: Partial<BaseSelectProps<Option, IsMulti, Group>> = { ...props }

    if (compactMultiLabel) {
      p.controlShouldRenderValue = false
      p.hideSelectedOptions = false
    }

    if (props.isMulti) {
      // isClearable is implicitly true when in multi mode, this makes it explicit.
      p.isClearable = props.isClearable ?? true
    }

    return p
  }, [compactMultiLabel, props])

  /**
   * This will merge the incoming styles with our own base. It will also apply
   */
  const resolvedStyles = useDeepMemo(() => {
    const selectBaseStyles = getSelectBaseStyles<Option, IsMulti, Group>({ compact })

    return mergeStyles(selectBaseStyles, styles || {})
  }, [styles, compact])

  /**
   * This allows for overrides of default components via the opting in of any number of props.
   * If you need to fall back to one of the original components, you may use the `components`
   * import from react-select
   */
  const resolvedComponents: SelectComponentsConfig<Option, IsMulti, Group> = useMemo(() => {
    const result = {
      ...defaultComponents,
      ...props.components,
    }

    if (compactMultiLabel) {
      result.Placeholder = CompactMultiSelectPlaceholder
    }

    if (withConfirmation) {
      result.Menu = ConfirmValuesMenu
      result.Option = ConfirmValuesOption
    }

    return result
  }, [compactMultiLabel, props.components, withConfirmation])

  return (
    <ConfirmStepProvider>
      <ReactSelect
        {...resolvedProps}
        styles={resolvedStyles}
        components={resolvedComponents}
        theme={makeSelectBaseTheme}
        filterOption={customFilterOption}
        compact={compact}
        compactMultiLabel={compactMultiLabel}
        withConfirmation={withConfirmation}
      />
    </ConfirmStepProvider>
  )
}
