// @ts-nocheck legacy file transformed to TS
import React from 'react'
import styled from '@emotion/styled'
import { colors, Text } from 'ic-snacks'

const PageCount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  cursor: pointer;
  background: ${colors.WHITE};
  border: 1px solid ${props => (props.disabled ? colors.GRAY_93 : colors.GREEN_500)};
  color: ${colors.GRAY_46};
`

const Input = styled.input`
  width: 32px;
  height: 32px;
  border: none;
  font: inherit;
  text-align: center;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

class PageInput extends React.Component {
  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.notifyPageChange()
    }
  }

  notifyPageChange = () => {
    const { onPageChange, page, totalPages } = this.props
    let newPage = this.state.newPage || page

    if (newPage < 1) newPage = 1
    if (newPage > totalPages) newPage = totalPages
    onPageChange(Number(newPage) - 1)
    this.setState({ newPage: null })
  }

  handleInputChange = e => {
    const {
      target: { value },
    } = e

    // we dont submit the input until enter is pressed or this input loses focus; no need to call onPageChange here
    this.setState({ newPage: value })
  }

  state = {
    newPage: null,
  }

  render() {
    const { page, totalPages } = this.props
    const { newPage } = this.state

    return (
      <PageCount disabled={!totalPages}>
        <Text variant="T.12">
          {totalPages ? (
            <Input
              type="number"
              value={newPage == null ? page : newPage}
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              onBlur={this.notifyPageChange}
              min="1"
              max={totalPages || '1'}
            />
          ) : (
            page
          )}
        </Text>
      </PageCount>
    )
  }
}

export default PageInput
