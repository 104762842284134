import { type FieldErrors } from 'react-hook-form-7'
import { useMessages, type MessageIds } from '../../utils/intl/intl.hooks'

type ErrorTypes<T extends string> = {
  default: T
  [key: string]: T
}

/**
 * This is a helper hook to map react-hook-form errors to our error messages.
 *
 * ```ts
 * type FormData = { username: string }
 *
 * const { errors } = useForm<FormData>()
 * const errorMessages = useFormErrorMessages<FormData>(errors, {
 *  username: {
 *    default: 'some-translation-string.default',
 *    minLength: 'some-translation-string.notEnoughCharacters
 *  }
 * })
 * ```
 *
 * Then just use `errorMessages.username` to access the resulting error message.
 * It will automatically use the correct error message based on the error type, or
 * the default otherwise.
 * If there's no errors on the field, it will return undefined.
 *
 * @param errors
 * @param map
 * @returns
 */
export const useFormErrorMessages = <
  FormType extends Record<string, any>,
  MessageType extends string
>(
  errors: FieldErrors<FormType>,
  map: Record<keyof FormType, ErrorTypes<MessageType>>
) => {
  const flattenedMessageMap: Record<string, MessageType> = Object.entries(map).reduce<
    Record<string, MessageType>
  >((acc, [fieldName, errorTypes]) => {
    Object.entries(errorTypes).forEach(([key, value]) => {
      acc[`${fieldName}.${key}`] = value
    })

    return acc
  }, {})

  // Casting here to make TS happy
  const messages = useMessages(flattenedMessageMap as Record<string, MessageIds>)

  // NOTE: no memo here because errors is a reference, and will never change!
  // No hook we have can handle this, lol.
  return Object.fromEntries(
    Object.entries(errors).map(([key, error]) => {
      const value =
        messages[`${key}.${error.type}`] || messages[`${key}.default` as keyof typeof messages]

      return [key, value]
    })
  ) as Record<keyof FormType, string>
}
