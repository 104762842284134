import React from 'react'
import { NewSelect } from '@retailer-platform/shared-components'
import { type SimpleOption } from '@retailer-platform/shared-components/src/common/select/utils/select.types'
import styled from '@emotion/styled'

interface NotificationsFilterProps<T> {
  placeholder?: string
  options: SimpleOption<T>[]
  selectedValue?: T
  onChange: (value?: T) => void
}

const FilterContainer = styled.div({
  width: '200px',
})
export const NotificationsFilter = <T,>({
  placeholder,
  options,
  selectedValue,
  onChange,
  ...rest
}: NotificationsFilterProps<T>) => (
  <FilterContainer>
    <NewSelect
      value={selectedValue}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      {...rest}
    />
  </FilterContainer>
)
