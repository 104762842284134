import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import capitalize from 'lodash/capitalize'
import { type DateRange } from '../DateRangePicker'

dayjs.extend(quarterOfYear)

export enum DateRangePickerPresetOption {
  Yesterday = 'yesterday',
  Last7Days = 'last_7_days',
  Last28Days = 'last_28_days',
  Last84Days = 'last_84_days',
  LastWeek = 'last_week',
  TwoWeeksAgo = 'two_weeks_ago',
  ThreeWeeksAgo = 'three_weeks_ago',
  FourWeeksAgo = 'four_weeks_ago',
  FiveWeeksAgo = 'five_weeks_ago',
  Last30Days = 'last_30_days',
  LastMonth = 'last_month',
  Last90Days = 'last_90_days',
  QuarterToDate = 'quarter_to_date',
  Last120Days = 'last_120_days',
  LastYear = 'last_1_year',
  YearToDate = 'year_to_date',
  Last2Years = 'last_2_years',
  Tomorrow = 'tomorrow',
  Next7Days = 'next_7_days',
  NextWeek = 'next_week',
  NextMonth = 'next_month',
  Custom = 'custom',
}

export enum WeekStartDay {
  Saturday = 'saturday',
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
}

export enum ComparisonPeriod {
  LastYear = 'lastYear',
  LastPeriod = 'lastPeriod',
  LastYearDayOfWeekMatch = 'lastYearDayOfWeekMatch',
  LastPeriodDayOfWeekMatch = 'lastPeriodDayOfWeekMatch',
}

export const comparisonPeriodCopy = {
  lastYear: 'Last Year',
  lastPeriod: 'Last Period',
  lastYearDayOfWeekMatch: 'Last Year - Day of week match',
  lastPeriodDayOfWeekMatch: 'Last Period - Day of week match',
}

export const weekStartDayOptions = [
  {
    value: WeekStartDay.Saturday,
    label: capitalize(WeekStartDay.Saturday),
  },
  {
    value: WeekStartDay.Sunday,
    label: capitalize(WeekStartDay.Sunday),
  },
  {
    value: WeekStartDay.Monday,
    label: capitalize(WeekStartDay.Monday),
  },
  {
    value: WeekStartDay.Tuesday,
    label: capitalize(WeekStartDay.Tuesday),
  },
  {
    value: WeekStartDay.Wednesday,
    label: capitalize(WeekStartDay.Wednesday),
  },
  {
    value: WeekStartDay.Thursday,
    label: capitalize(WeekStartDay.Thursday),
  },
  {
    value: WeekStartDay.Friday,
    label: capitalize(WeekStartDay.Friday),
  },
]

const weekStartDayToDateDifference = {
  [WeekStartDay.Saturday]: -1,
  [WeekStartDay.Sunday]: 0,
  [WeekStartDay.Monday]: 1,
  [WeekStartDay.Tuesday]: 2,
  [WeekStartDay.Wednesday]: 3,
  [WeekStartDay.Thursday]: 4,
  [WeekStartDay.Friday]: 5,
}

export interface DateRangePickerPresetConfig {
  start: Date
  end: Date
  key: DateRangePickerPresetOption
  label: string
}

export const dateRangePickerPastDatesPresetLabels = {
  [DateRangePickerPresetOption.Yesterday]: 'Yesterday',
  [DateRangePickerPresetOption.Last7Days]: 'Last 7 Days',
  [DateRangePickerPresetOption.Last28Days]: 'Last 28 Days',
  [DateRangePickerPresetOption.Last84Days]: 'Last 84 Days',
  [DateRangePickerPresetOption.LastWeek]: 'Last Week',
  [DateRangePickerPresetOption.TwoWeeksAgo]: '2 Weeks Ago',
  [DateRangePickerPresetOption.ThreeWeeksAgo]: '3 Weeks Ago',
  [DateRangePickerPresetOption.FourWeeksAgo]: '4 Weeks Ago',
  [DateRangePickerPresetOption.FiveWeeksAgo]: '5 Weeks Ago',
  [DateRangePickerPresetOption.Last30Days]: 'Last 30 Days',
  [DateRangePickerPresetOption.LastMonth]: 'Last Month',
  [DateRangePickerPresetOption.Last90Days]: 'Last 90 Days',
  [DateRangePickerPresetOption.QuarterToDate]: 'Quarter to Date',
  [DateRangePickerPresetOption.Last120Days]: 'Last 120 Days',
  [DateRangePickerPresetOption.LastYear]: 'Last Year',
  [DateRangePickerPresetOption.Last2Years]: 'Last 2 Years',
  [DateRangePickerPresetOption.YearToDate]: 'Year to Date',
}

export const dateRangePickerFutureDatesPresetLabels = {
  [DateRangePickerPresetOption.Tomorrow]: 'Tomorrow',
  [DateRangePickerPresetOption.Next7Days]: 'Next 7 Days',
  [DateRangePickerPresetOption.NextWeek]: 'Next Week',
  [DateRangePickerPresetOption.NextMonth]: 'Next Month',
}

export const buildDateRangePresetConfig = (
  presets: DateRangePickerPresetOption[] | undefined,
  weekStartDay: WeekStartDay,
  includeFutureDates?: boolean
): DateRangePickerPresetConfig[] => {
  const pastPresets = [
    {
      start: dayjs().subtract(1, 'days').toDate(),
      end: dayjs().subtract(1, 'days').toDate(),
      key: DateRangePickerPresetOption.Yesterday,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Yesterday],
    },
    {
      start: dayjs().subtract(7, 'days').toDate(),
      end: dayjs().subtract(1, 'days').toDate(),
      key: DateRangePickerPresetOption.Last7Days,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Last7Days],
    },
    {
      start: dayjs().subtract(28, 'days').toDate(),
      end: dayjs().subtract(1, 'days').toDate(),
      key: DateRangePickerPresetOption.Last28Days,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Last28Days],
    },
    {
      start: dayjs().subtract(84, 'days').toDate(),
      end: dayjs().subtract(1, 'days').toDate(),
      key: DateRangePickerPresetOption.Last84Days,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Last84Days],
    },
    {
      start: dayjs()
        .subtract(1, 'week')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
      end: dayjs()
        .subtract(1, 'weeks')
        .endOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
      key: DateRangePickerPresetOption.LastWeek,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.LastWeek],
    },
    {
      start: dayjs()
        .subtract(2, 'weeks')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
      end: dayjs()
        .subtract(2, 'weeks')
        .endOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
      key: DateRangePickerPresetOption.TwoWeeksAgo,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.TwoWeeksAgo],
    },
    {
      key: DateRangePickerPresetOption.ThreeWeeksAgo,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.ThreeWeeksAgo],
      start: dayjs()
        .subtract(3, 'weeks')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
      end: dayjs()
        .subtract(3, 'weeks')
        .endOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
    },
    {
      key: DateRangePickerPresetOption.FourWeeksAgo,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.FourWeeksAgo],
      end: dayjs()
        .subtract(4, 'weeks')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .add(6, 'day')
        .toDate(),
      start: dayjs()
        .subtract(4, 'weeks')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
    },
    {
      key: DateRangePickerPresetOption.FiveWeeksAgo,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.FiveWeeksAgo],
      end: dayjs()
        .subtract(5, 'weeks')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .add(6, 'day')
        .toDate(),
      start: dayjs()
        .subtract(5, 'weeks')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
    },
    {
      key: DateRangePickerPresetOption.Last30Days,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Last30Days],
      end: dayjs().subtract(1, 'days').endOf('day').toDate(),
      start: dayjs().subtract(30, 'd').toDate(),
    },

    {
      key: DateRangePickerPresetOption.LastMonth,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.LastMonth],
      end: dayjs().subtract(1, 'months').endOf('month').toDate(),
      start: dayjs().subtract(1, 'months').date(1).toDate(),
    },

    {
      key: DateRangePickerPresetOption.Last90Days,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Last90Days],
      end: dayjs().subtract(1, 'days').endOf('day').toDate(),
      start: dayjs().subtract(90, 'd').toDate(),
    },

    {
      key: DateRangePickerPresetOption.QuarterToDate,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.QuarterToDate],
      end: dayjs().subtract(1, 'days').endOf('day').toDate(),
      start: dayjs().startOf('Q').toDate(),
    },

    {
      key: DateRangePickerPresetOption.Last120Days,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Last120Days],
      end: dayjs().subtract(1, 'days').endOf('day').toDate(),
      start: dayjs().subtract(120, 'd').toDate(),
    },

    {
      key: DateRangePickerPresetOption.LastYear,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.LastYear],
      end: dayjs().subtract(1, 'days').endOf('day').toDate(),
      start: dayjs().subtract(1, 'y').toDate(),
    },

    {
      key: DateRangePickerPresetOption.Last2Years,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.Last2Years],
      end: dayjs().subtract(1, 'days').endOf('day').toDate(),
      start: dayjs().subtract(2, 'y').toDate(),
    },

    {
      key: DateRangePickerPresetOption.YearToDate,
      label: dateRangePickerPastDatesPresetLabels[DateRangePickerPresetOption.YearToDate],
      end: dayjs().subtract(1, 'days').endOf('day').toDate(),
      start: dayjs().startOf('year').toDate(),
    },
  ]

  const futurePresets = [
    {
      start: dayjs().add(1, 'days').toDate(),
      end: dayjs().add(1, 'days').toDate(),
      key: DateRangePickerPresetOption.Tomorrow,
      label: dateRangePickerFutureDatesPresetLabels[DateRangePickerPresetOption.Tomorrow],
    },
    {
      start: dayjs().add(1, 'days').toDate(),
      end: dayjs().add(7, 'days').toDate(),
      key: DateRangePickerPresetOption.Next7Days,
      label: dateRangePickerFutureDatesPresetLabels[DateRangePickerPresetOption.Next7Days],
    },
    {
      start: dayjs()
        .add(1, 'week')
        .startOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
      end: dayjs()
        .add(1, 'weeks')
        .endOf('week')
        .add(weekStartDayToDateDifference[weekStartDay], 'day')
        .toDate(),
      key: DateRangePickerPresetOption.NextWeek,
      label: dateRangePickerFutureDatesPresetLabels[DateRangePickerPresetOption.NextWeek],
    },
    {
      start: dayjs().add(1, 'months').date(1).toDate(),
      end: dayjs().add(1, 'months').endOf('month').toDate(),
      key: DateRangePickerPresetOption.NextMonth,
      label: dateRangePickerFutureDatesPresetLabels[DateRangePickerPresetOption.NextMonth],
    },
  ]

  const concatenatedPresets = pastPresets.concat(futurePresets)

  if (!presets) {
    if (includeFutureDates) {
      return concatenatedPresets
    }
    return pastPresets
  }

  return presets
    .map(presetKey => concatenatedPresets.find(({ key }) => key === presetKey))
    .filter(Boolean) as DateRangePickerPresetConfig[]
}

export const buildPresetDateRangeOptions = (presetsConfig: DateRangePickerPresetConfig[]) =>
  presetsConfig
    .map(({ label, key }) => ({
      label,
      value: key,
      disabled: false,
    }))
    .concat({
      label: 'Custom',
      value: DateRangePickerPresetOption.Custom,
      disabled: true,
    })

export const findMatchingPresetConfig = (
  dateRange: DateRange,
  presetsConfig: DateRangePickerPresetConfig[]
) =>
  presetsConfig.find(
    config =>
      dayjs(config.start).isSame(dateRange.start, 'day') &&
      dayjs(config.end).isSame(dateRange.end, 'day')
  )

export const findMatchingPresetConfigKey = (
  dateRange: DateRange,
  presetsConfig: DateRangePickerPresetConfig[]
) => findMatchingPresetConfig(dateRange, presetsConfig)?.key || DateRangePickerPresetOption.Custom
