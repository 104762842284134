import React, { type FunctionComponent, useMemo } from 'react'
import { ApolloProvider } from '@apollo/react-common'
import { IntlProvider } from 'react-intl'
import { QueryParamProvider } from 'use-query-params'
import { Route, useRouteMatch } from 'react-router'
import { TourProvider } from '@reactour/tour'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from '@retailer-platform/shared-components'
import { fetchDashboardClient } from '../../../legacy/apollo/dashboardClient'
import { makeIntlFormats } from '../../../intl/intl.utils'
import { type DashIntlShape } from '../../../intl/intl.types'
import { trackEvent } from '../../../utils/events/trackEvent'
import { NavProvider } from '../nav/utils/NavProvider'
import { UserFeedbackProvider } from '../user-feedback/UserFeedbackProvider'
import { getDefaultTourProviderProps } from '../../../utils/tour/tour.utils'
import { useTrackPageView } from '../../utils/instrumentation/useTrackPageView'
import { useQueryStaleWhileRevalidateCleanup } from '../../../utils/apollo/makeQueryStaleWhileRevalidate'
import { AdminControlsProvider } from '../../../utils/contexts/admin-controls/AdminControlsContext'
import { AppProviderPreloader } from './components/AppProviderPreloader'
import { AppProviderStyling } from './components/AppProviderStyling'

interface Props {
  messages: DashIntlShape['messages']
}

const tourProviderProps = getDefaultTourProviderProps()
const queryClient = new QueryClient()

export const AppProvider: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  messages,
}) => {
  trackEvent({ id: 'app.launched', description: 'The application was launched' })

  useTrackPageView()
  useQueryStaleWhileRevalidateCleanup()

  return (
    <ThemeProvider>
      <IntlProvider formats={makeIntlFormats()} locale="en-US" messages={messages}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ApolloProvider client={fetchDashboardClient()}>
            <QueryClientProvider client={queryClient}>
              <NavProvider>
                <UserFeedbackProvider>
                  <AdminControlsProvider>
                    <TourProvider {...tourProviderProps}>
                      <AppProviderStyling>
                        <AppProviderPreloader>{children}</AppProviderPreloader>
                      </AppProviderStyling>
                    </TourProvider>
                  </AdminControlsProvider>
                </UserFeedbackProvider>
              </NavProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </QueryParamProvider>
      </IntlProvider>
    </ThemeProvider>
  )
}
