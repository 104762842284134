import {
  AccessControl,
  WarehouseLocationFeature,
} from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureEntitlements } from './featureEntitlements'

export const partnerPickDevicesAccessControl: DomainAccessControlConfig = [
  // permissions and warehouseLocationFeatures both must be enabled
  {
    permissions: Permission.ManageShoppers,
    warehouseLocationFeatures: AccessControl.oneOf([
      WarehouseLocationFeature.RetailerPickup,
      WarehouseLocationFeature.RetailerAlcoholDelivery,
    ]),
  },
  {
    permissions: Permission.ManageShoppers,
    warehouseLocationFeatures: AccessControl.oneOf([
      WarehouseLocationFeature.RetailerPickup,
      WarehouseLocationFeature.RetailerAlcoholDelivery,
    ]),
  },
  {
    permissions: Permission.ManageShoppers,
    featureEntitlementIds: [FeatureEntitlements.ScanAndPayRetailers],
  },
]
