import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  NavItem,
  NavEntryPoint,
  AdminNavEntryPoint,
  AdminNavEntryPointV2,
} from '@retailer-platform/dashboard/routing'
import { insightsPortalRouter } from './routing/insightsPortalRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes, totemRouteOverrides } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import {
  insightsPortalAccessControl,
  insightsPortalCombinedIAMilestone2AccessControl,
} from './access-control/insightsPortalAccess.configuration'
import { insightsPortalSummaryReportAccessControl } from './access-control/insightsPortalSummaryReportAccess.configuration'
import { insightsPortalAdsDashboardAccessControl } from './access-control/insightsPortalAdsDashboardAccess.configuration'
import {
  insightsPortalCampaignCombinedIAMilestone2AccessControl,
  insightsPortalCampaignInsightsAccessControl,
} from './access-control/insightsPortalCampaignInsights.configuration'
import { insightsPortalDataExplorerV3AccessControl } from './access-control/insightsPortalDataExplorerAccess.configuration'
import {
  insightsPortalCollectionInsightsAccessControl,
  insightsPortalCollectionsIAMilestone2AccessControl,
} from './access-control/insightsPortalCollectionInsights.configuration'
import { insightsPortalCreativeInsightsAccessControl } from './access-control/insightsPortalCreativeInsights.configuration'
import { insightsPortalDashboardBuilderControl } from './access-control/insightsPortalDashboardBuilderAccess.configuration'
import { insightsPortalInventoryIntelligenceAccessControl } from './access-control/insightsPortalInventoryIntelligence.configuration'
import { InsightsPortalStoreLaborPerformanceV2AccessControl } from './access-control/insightsPortalStoreLaborPerformanceAccess.configuration'
import { insightsPortalAwarenessCampaignInsightsAccessControl } from './access-control/insightsPortalAwarenessCampaignInsights.configuration'
import { InsightsPortalWarehouseContextFilterNavWrapper } from './routing/InsightsPortalWarehouseContextFilterNavWrapper'
import { insightsAdminRouter } from './routing/insightsAdminRouter'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'insights-portal',
  totem: {
    entity: 'insights-portal-dashboard-tool',
    routeOverrides: totemRouteOverrides,
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [insightsPortalRouter],
    admin: [insightsAdminRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.DataAndAnalytics,
      attachToV2: NavEntryPoint.AnalyticsStorefrontPerformance,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.summaryReport.nav',
          route: 'insights-portal-executive-summary',
          accessControl: insightsPortalSummaryReportAccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 0,
          positionNavV2: 0,
        },
      ],
    },
    {
      attachToV2: NavEntryPoint.AnalyticsStorefrontPerformance,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.storePerformance.completedOrders.title',
          route: 'insights-portal-store-performance-completed-orders',
          accessControl: insightsPortalCombinedIAMilestone2AccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 1,
          positionNavV2: 1,
        },
      ],
    },
    {
      attachToV2: NavEntryPoint.AnalyticsStorefrontPerformance,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.storePerformance.basket.title',
          route: 'insights-portal-store-performance-basket-overview',
          accessControl: insightsPortalCombinedIAMilestone2AccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 2,
          positionNavV2: 2,
        },
      ],
    },
    {
      attachToV2: NavEntryPoint.AnalyticsStorefrontPerformance,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.storePerformance.search.title',
          route: 'insights-portal-store-performance-search',
          accessControl: insightsPortalCombinedIAMilestone2AccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 3,
          positionNavV2: 3,
        },
      ],
    },
    {
      attachToV2: NavEntryPoint.AnalyticsStorefrontPerformance,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.searchDashboard.metrics.imageCoverage',
          route: 'insights-portal-store-performance-image-coverage',
          accessControl: insightsPortalCombinedIAMilestone2AccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 4,
          positionNavV2: 4,
        },
      ],
    },

    {
      attachToV2: NavEntryPoint.OperationsLabor,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.storeLaborPerformanceV2.title',
          route: 'insights-portal-store-performance-labor-performance-v2',
          accessControl: InsightsPortalStoreLaborPerformanceV2AccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 1,
          positionNavV2: 1,
        },
      ],
    },
    {
      attachTo: NavItem.DataAndAnalytics,
      attachToV2: NavEntryPoint.AnalyticsMarketing,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.adsDashboard.nav',
          route: 'insights-portal-overview-ads-dashboard',
          accessControl: insightsPortalAdsDashboardAccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 5,
          positionNavV2: 3,
        },
      ],
    },
    {
      attachToV2: NavEntryPoint.AnalyticsMarketing,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.campaignsAnalytics.nav',
          route: 'insights-portal-marketing-campaigns-analytics-overview',
          accessControl: insightsPortalCampaignCombinedIAMilestone2AccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 7,
          positionNavV2: 0,
        },
      ],
    },
    {
      attachToV2: NavEntryPoint.AnalyticsMerchandising,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.collections.nav',
          route: 'insights-portal-merchandising-collections-overview',
          accessControl: insightsPortalCollectionsIAMilestone2AccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 9,
        },
      ],
    },
    {
      attachTo: NavItem.DataAndAnalytics,
      attachToV2: NavEntryPoint.AnalyticsMarketing,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.creatives.nav',
          route: 'insights-portal-placements-insights',
          accessControl: insightsPortalCreativeInsightsAccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 10,
          positionNavV2: 1,
        },
      ],
    },
    {
      attachTo: NavItem.DataAndAnalytics,
      attachToV2: NavEntryPoint.AnalyticsMarketing,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.awarenessCampaigns.nav',
          route: 'insights-portal-marketing-awareness-campaigns-analytics-overview',
          accessControl: insightsPortalAwarenessCampaignInsightsAccessControl,
          NavItemWrapper: InsightsPortalWarehouseContextFilterNavWrapper,
          position: 11,
          positionNavV2: 2,
        },
      ],
    },
    {
      attachTo: NavItem.InventoryIntelligence,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.inventoryIntelligence.oosInsights.title',
          route: 'insights-portal-inventory-intelligence',
          accessControl: insightsPortalInventoryIntelligenceAccessControl,
        },
      ],
    },
    {
      attachToV2: NavEntryPoint.AnalyticsReportsAndLogs,
      subNavItems: [
        {
          type: 'item',
          labelId: 'insightsPortalDomain.dataExplorer.navBeta',
          route: 'insights-portal-reports-logs-custom-report-list',
          accessControl: insightsPortalDataExplorerV3AccessControl,
          positionNavV2: 1,
          subRoutes: ['insights-portal-reports-logs-custom-report-exports-list'],
        },
      ],
    },
  ],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.Analytics,
      attachToV2: AdminNavEntryPointV2.Analytics,
      subNavItems: [
        {
          route: 'insights-portal-admin-dashboard-create',
          labelId: 'insightsPortalDomain.page.dashboardBuilder.nav',
          accessControl: insightsPortalDashboardBuilderControl,
        },
      ],
    },
  ],
}

export default definition
