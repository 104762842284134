/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import { DomainRoute } from '../utils/domain/routingComponents'

const AuthenticationLogInV2Page = lazy(() => import('../pages/log-in-v2/AuthenticationLogInV2Page'))
const AuthenticationCreateAccountPage = lazy(
  () => import('../pages/create-account/AuthenticationCreateAccountPage')
)
const AuthenticationRequestEmailVerificationPage = lazy(
  () => import('../pages/request-email-verification/AuthenticationRequestEmailVerificationPage')
)
const AuthenticationEmailVerificationPage = lazy(
  () => import('../pages/email-verification/AuthenticationEmailVerificationPage')
)
const AuthenticationPendingEmailVerificationPage = lazy(
  () => import('../pages/pending-email-verification/AuthenticationPendingEmailVerificationPage')
)
const AuthenticationPasswordResetPage = lazy(
  () => import('../pages/password-reset/AuthenticationPasswordResetPage')
)
const AuthenticationRequestPasswordResetPage = lazy(
  () => import('../pages/request-password-reset/AuthenticationRequestPasswordResetPage')
)
const AuthenticationPendingPasswordResetPage = lazy(
  () => import('../pages/pending-password-reset/AuthenticationPendingPasswordResetPage')
)
const AuthenticationCompleteAccountSetupPage = lazy(
  () => import('../pages/complete-account-setup/AuthenticationCompleteAccountSetupPage')
)
const AuthenticationRequestAccountSetupPage = lazy(
  () => import('../pages/request-account-setup/AuthenticationRequestAccountSetupPage')
)
const AuthenticationAuthServiceCallbackPage = lazy(
  () => import('../pages/auth-service-callback/AuthenticationAuthServiceCallbackPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const authenticationPublicRouter = [
  <DomainRoute
    exact
    route="app-public-authentication-log-in"
    component={AuthenticationLogInV2Page}
  />,

  <DomainRoute
    exact
    route="app-public-authentication-create-account"
    component={AuthenticationCreateAccountPage}
  />,

  <DomainRoute
    exact
    route="app-public-authentication-request-email-verification"
    component={AuthenticationRequestEmailVerificationPage}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-email-verification"
    component={AuthenticationEmailVerificationPage}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-pending-email-verification"
    component={AuthenticationPendingEmailVerificationPage}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-password-reset"
    component={AuthenticationPasswordResetPage}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-request-password-reset"
    component={AuthenticationRequestPasswordResetPage}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-pending-password-reset"
    component={AuthenticationPendingPasswordResetPage}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-complete-account-setup"
    component={AuthenticationCompleteAccountSetupPage}
  />,
  <DomainRoute
    exact
    route="app-public-authentication-request-account-setup"
    component={AuthenticationRequestAccountSetupPage}
  />,

  <DomainRoute
    exact
    route="app-public-authentication-auth-service-callback"
    component={AuthenticationAuthServiceCallbackPage}
  />,
  // @hygen:inject router-route
  // injection point for routes, please do not delete
]
