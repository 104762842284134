import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'

const OnboardingCompleteAccountSetupPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingCompleteAccountSetupPage" */
      '../pages/complete-account-setup/OnboardingCompleteAccountSetupPage'
    )
)
const OnboardingRequestAccountSetupPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingRequestAccountSetupPage" */
      '../pages/request-account-setup/OnboardingRequestAccountSetupPage'
    )
)
// @hygen:inject page-import

export const onboardingPublicRouter = (
  <DomainRoute route="app-public-onboarding">
    <DashSwitch>
      <DomainRoute
        exact
        route="app-public-onboarding-complete-account-setup"
        component={OnboardingCompleteAccountSetupPage}
      />
      <DomainRoute
        exact
        route="app-public-onboarding-request-account-setup"
        component={OnboardingRequestAccountSetupPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
