import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'

const insightsPortalERVMetricUserAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.InsightsPortalERVMetric],
}

const insightsPortalERVMetricWarehouseAccessControl: DomainAccessControlConfig = {
  warehouseFeatureToggles: [FeatureToggle.InsightsPortalERVMetric],
}

export const insightsPortalERVMetricAccessControl: DomainAccessControlConfig = [
  insightsPortalERVMetricUserAccessControl,
  insightsPortalERVMetricWarehouseAccessControl,
]
