import React, { lazy, type FunctionComponent } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute, DomainRedirect } from '../utils/domain/routingComponents'
import { bannersAccessControl } from '../access-control/bannersAccess.configuration'
import { bannersAdminRoutingConfig } from '../utils/bannersRoutingConfig'
import LinksToNewBannerDetails from '../components/banner-details/LinksToNewBannerDetails'

const BannersList = lazy(() => import('../components/banners-list/BannersList'))
const BannerCreate = lazy(() => import('../components/banner-create/BannerCreate'))
const BannerUpdate = lazy(() => import('../components/banner-update/BannerUpdate'))
const BannerDetails = lazy(() => import('../components/banner-details/BannerDetails'))

const VisibilityConditionsList = lazy(
  () => import('../components/visibility-conditions-list/VisibilityConditionsList')
)
const VisibilityConditionCreate = lazy(
  () => import('../components/visibility-condition-create/VisibilityConditionCreate')
)

// When this becomes an RPP domain, the totemEntity should be set to banners-dashboard-tool
export const bannersAdminRouter = (
  <DomainRoute route="app-admin-banners" accessControl={bannersAccessControl}>
    <DashSwitch>
      <DomainRoute exact route={bannersAdminRoutingConfig.list} component={BannersList} />
      <DomainRoute exact route={bannersAdminRoutingConfig.create} component={BannerCreate} />
      <DomainRoute exact route={bannersAdminRoutingConfig.edit} component={BannerUpdate} />
      <DomainRoute exact route={bannersAdminRoutingConfig.details} component={BannerDetails} />
      <DomainRoute
        exact
        route={bannersAdminRoutingConfig.visibilityConditions}
        component={VisibilityConditionsList}
      />
      <DomainRoute
        exact
        route={bannersAdminRoutingConfig.visibilityConditionCreate}
        component={VisibilityConditionCreate}
      />
      <DomainRoute
        exact
        route={bannersAdminRoutingConfig.detailsOld}
        component={LinksToNewBannerDetails}
      />
      <DomainRoute
        exact
        route={bannersAdminRoutingConfig.editOld}
        component={LinksToNewBannerDetails}
      />
      <DomainRedirect toRoute={bannersAdminRoutingConfig.list} />
    </DashSwitch>
  </DomainRoute>
)
