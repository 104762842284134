/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useMemo } from 'react'
import isEqual from 'lodash/isEqual'

function useDeepCompareMemoize(value?: any) {
  const ref = useRef()

  if (!value) return undefined

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

const useDeepEffect: typeof useEffect = (
  effect: React.EffectCallback,
  deps?: ReadonlyArray<any>
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, useDeepCompareMemoize(deps))
}

export const useDeepMemo: typeof useMemo = (factory, deps) =>
  // @ts-ignore-next-line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(factory, useDeepCompareMemoize(deps))

export default useDeepEffect
