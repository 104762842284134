import { type Moment } from 'moment'
import { useCallback, useMemo } from 'react'
import { usePartnerContext } from '@retailer-platform/dashboard/utils'
import { type WarehouseWithFeatures } from '@retailer-platform/dashboard/utils'
import { trackEvent } from '@retailer-platform/dashboard/analytics'
import { notify } from '@retailer-platform/shared-components'
import {
  MergeableReportTypeEnum,
  ReportTypeEnum,
  useMergeNormalizedReportsMutation,
} from '../../../api'
import { useDomainMessages } from '../../../utils/domain/intl'
export interface Props {
  partnerId: string
  retailerId: string
  retailerIds?: string[]
  reportType: ReportTypeEnum
  dateRange: { startDate: Moment; endDate: Moment }
}

const getMergeableReport = (reportType: ReportTypeEnum) => {
  switch (reportType) {
    case null:
      return null
    case undefined:
      return undefined
    case ReportTypeEnum.RetailerTaxLogs:
      return MergeableReportTypeEnum.RetailerTaxLogs
    case ReportTypeEnum.TaxesPaidAtPos:
      return MergeableReportTypeEnum.TaxesPaidAtPos
    case ReportTypeEnum.ShopperReimbursementsMonthly:
      return MergeableReportTypeEnum.ShopperReimbursementsMonthly
    case ReportTypeEnum.TransactionLogs:
      return MergeableReportTypeEnum.TransactionLogs
    case ReportTypeEnum.InStoreTransactions:
      return MergeableReportTypeEnum.InStoreTransactions
    // Add your new mergeable report types here
    default:
      throw new Error(`Unsupported ReportTypeEnum for merging: ${reportType}`)
  }
}

const getRetailerIdsFilterValues = (retailerIds: string[], warehouses: WarehouseWithFeatures[]) =>
  retailerIds?.length === 0 ? warehouses.map(w => String(w.id)) : retailerIds

const getEffectiveRetailerId = (retailerId: string, warehouses: WarehouseWithFeatures[]) =>
  retailerId === '0' ? String(warehouses[0].id) : retailerId

export const useMergeNormalizedReports = ({
  partnerId,
  retailerId,
  retailerIds,
  reportType,
  dateRange,
}: Props) => {
  const formattedDates = useMemo(() => {
    const format = 'YYYY-MM-DD'

    return {
      startDate: dateRange.startDate?.format(format),
      endDate: dateRange.endDate?.format(format),
    }
  }, [dateRange])

  const [triggerMutation, { data, loading, error }] = useMergeNormalizedReportsMutation()
  const { warehouses } = usePartnerContext()

  const retailerIdsFilterValues = getRetailerIdsFilterValues(retailerIds, warehouses)

  // effectiveRetailerId just to support backwards compatibility while both versions of Instacart Managed Reports exists
  const effectiveRetailerId = getEffectiveRetailerId(retailerId, warehouses)

  const i18n = useDomainMessages({
    mergeSuccess: 'reportsDomain.instacartManagedReports.merge.result.success.message',
  })

  const createMergedReport = useCallback(() => {
    const mergeableReport = getMergeableReport(reportType)

    trackEvent({
      id: 'instacart_managed_reports.merge_normalized_reports',
      description: 'Merge Instacart Managed Reports',
      data: {
        reportType,
        retailerIds: retailerIdsFilterValues,
        dateRange: formattedDates,
      },
    })

    triggerMutation({
      variables: {
        partnerId,
        retailerId: effectiveRetailerId,
        retailerIds: retailerIdsFilterValues,
        reportType: mergeableReport,
        startDate: formattedDates.startDate,
        endDate: formattedDates.endDate,
      },
    }).then(() => {
      notify({ body: i18n.mergeSuccess })
    })
  }, [
    triggerMutation,
    partnerId,
    reportType,
    formattedDates,
    effectiveRetailerId,
    retailerIdsFilterValues,
    i18n,
  ])

  return {
    createMergedReport,
    apiSuccess: data ? Boolean(data?.normalizedReportsMerge.success) : null,
    apiLoading: loading,
    apiError: error,
  }
}
