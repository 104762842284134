import { SearchIcon, RemoveIcon } from '@instacart/ids-core'
import { DecoratedInput } from '@retailer-platform/shared-components'
import React, { useState } from 'react'
import { useDashMessage } from '../../../intl/intl.hooks'

export interface Props {
  onChange: (filter: string) => void
}

export const SearchFilter: React.FC<React.PropsWithChildren<Props>> = ({ onChange }) => {
  const messageDefaultSearchText = useDashMessage('selectPartner.searchResults.defaultSearchText')

  const [value, setValue] = useState('')

  return (
    <div css={{ position: 'relative', marginBottom: '32px' }}>
      <DecoratedInput
        id="search"
        decorationComponent={SearchIcon}
        decorationPosition={'left'}
        placeholder={messageDefaultSearchText}
        onChange={evt => {
          setValue(evt.currentTarget.value || '')
          onChange(evt.currentTarget.value || '')
        }}
        value={value}
        autoFocus
      />
      <div
        css={{ position: 'absolute', top: '10px', right: '8px', cursor: 'pointer' }}
        onClick={() => {
          setValue('')
          onChange('')
        }}
      >
        <RemoveIcon size={16} />
      </div>
    </div>
  )
}
