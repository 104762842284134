import React, { type FunctionComponent, useMemo } from 'react'
import { css, type SerializedStyles } from '@emotion/react'
import { Link } from 'react-router-dom'
import { colors, fontSize, fontWeight } from '../../foundation'

const breadcrumbStyles = css({
  fontSize: fontSize.X18,
  fontWeight: fontWeight.BOLD,
})

const linkStyles = css({
  color: '#000000',
  ':visited': {
    color: 'inherit',
  },
  ':hover': {
    color: colors.PRIMARY.REGULAR,
  },
})

interface Props {
  path?: string
  additionalLinkStyle?: SerializedStyles
  className?: string
}

export const Breadcrumb: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  path,
  additionalLinkStyle,
  ...rest
}) => {
  const content = useMemo(() => {
    if (!path) return children

    return (
      <Link css={[linkStyles, additionalLinkStyle]} to={path}>
        {children}
      </Link>
    )
  }, [children, path, additionalLinkStyle])

  return (
    <div css={breadcrumbStyles} {...rest}>
      {content}
    </div>
  )
}
