const EN_US = {
  'partnerPickDevicesDomain.title': 'Devices', //old name -- delete and replace with title.new when `dashboard_store_ops_nav` feature toggle is fully enabled
  'partnerPickDevicesDomain.title.new': 'Device accounts',
  // List
  'partnerPickDevicesDomain.list.noResults': 'No results',
  'partnerPickDevicesDomain.list.status.all': 'All accounts',
  'partnerPickDevicesDomain.list.status.active': 'Active accounts',
  'partnerPickDevicesDomain.list.status.inactive': 'Inactive accounts',
  'partnerPickDevicesDomain.list.columns.name': 'Name',
  'partnerPickDevicesDomain.list.columns.deviceType': 'Device type',
  'partnerPickDevicesDomain.list.columns.login': 'Login',
  'partnerPickDevicesDomain.list.columns.email': 'Email',
  'partnerPickDevicesDomain.list.columns.status': 'Device status',
  'partnerPickDevicesDomain.list.columns.cardStatus': 'Card status',
  'partnerPickDevicesDomain.list.columns.cardStatus.multipleCards': 'Multiple',
  'partnerPickDevicesDomain.list.columns.actions.edit': 'Edit',
  'partnerPickDevicesDomain.list.columns.actions.reactivate': 'Reactivate',
  'partnerPickDevicesDomain.list.columns.actions.deactivate': 'Deactivate',
  'partnerPickDevicesDomain.add': 'Add account',

  // Enums
  'partnerPickDevicesDomain.details.status.active': 'Active',
  'partnerPickDevicesDomain.details.status.inactive': 'Inactive',
  'partnerPickDevicesDomain.details.deviceType.phone': 'Phone plan',
  'partnerPickDevicesDomain.details.deviceType.wifi': 'Wi-Fi',
  'partnerPickDevicesDomain.details.accountType.shopping': 'Shopping',
  'partnerPickDevicesDomain.details.accountType.manager': 'Store manager',
  'partnerPickDevicesDomain.details.cardStatus.physical': 'Physical - Active',
  'partnerPickDevicesDomain.details.cardStatus.digital': 'Digital - Active',
  'partnerPickDevicesDomain.details.cardStatus.inactive': 'Inactive',
  'partnerPickDevicesDomain.details.cardStatus.terminated': 'Terminated',
  'partnerPickDevicesDomain.details.cardStatus.suspended': 'Suspended',

  // Modal
  'partnerPickDevicesDomain.modal.title.add': 'Add account',
  'partnerPickDevicesDomain.modal.title.edit': 'Edit account',
  'partnerPickDevicesDomain.modal.cancel': 'Cancel',
  'partnerPickDevicesDomain.modal.save': 'Save',
  'partnerPickDevicesDomain.modal.deactivate.title': 'Deactivate account?',
  'partnerPickDevicesDomain.modal.deactivate.body': 'You can edit and reactivate it later.',
  'partnerPickDevicesDomain.modal.deactivate.confirm': 'Deactivate',

  // Form
  'partnerPickDevicesDomain.form.deviceType.label': 'Device type',
  'partnerPickDevicesDomain.form.deviceType.tooltip':
    'Wi-Fi devices will be assigned a 10-digit number that associates must use with a password to log in.',
  'partnerPickDevicesDomain.form.accountType.label': 'Account type',
  'partnerPickDevicesDomain.form.accountType.tooltip':
    'Shopping accounts are used by associates to shop and handoff orders. Store manager accounts have admin capabilities.',
  'partnerPickDevicesDomain.form.firstName.label': 'First name',
  'partnerPickDevicesDomain.form.lastName.label': 'Last name',
  'partnerPickDevicesDomain.form.phone.label': 'Phone number',
  'partnerPickDevicesDomain.form.phone.wifi.label': 'Login',
  'partnerPickDevicesDomain.form.phone.wifi.placeholder': 'Save to generate login number',
  'partnerPickDevicesDomain.form.email.label': 'Email',
  'partnerPickDevicesDomain.form.password.label': 'Password',

  // Validation
  'partnerPickDevicesDomain.validation.required': '{fieldName} is required',
  'partnerPickDevicesDomain.validation.email': '{fieldName} is not a valid email address',
  'partnerPickDevicesDomain.validation.phone': '{fieldName} is not a valid phone number',

  // Notification
  'partnerPickDevicesDomain.notification.create': '{device} account was added',
  'partnerPickDevicesDomain.notification.update': '{device} account was updated',

  // Error
  'partnerPickDevicesDomain.error': 'There was an error saving this account.',
} as const

export default EN_US
