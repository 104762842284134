import { type CSSProperties, type ReactNode } from 'react'
import type React from 'react'
import { cloneElement, isValidElement } from 'react'
import { type DashSpacing, dashSpaceMapping } from '../../foundation/spacing'
import { compactObject } from '../../../../utils/objects/compact'
import { type BoxMarginProps, type BoxPaddingProps } from './box.types'

const pixelsOrUndef = (value?: DashSpacing): string | undefined => {
  if (!value) return undefined

  return dashSpaceMapping[value]
}

export const resolveBoxMargin = ({
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  margin,
}: Partial<BoxMarginProps>): CSSProperties => {
  const styles: CSSProperties = {}

  styles.marginTop = pixelsOrUndef(marginTop)
  styles.marginLeft = pixelsOrUndef(marginLeft)
  styles.marginRight = pixelsOrUndef(marginRight)
  styles.marginBottom = pixelsOrUndef(marginBottom)

  if (!Array.isArray(margin)) {
    styles.margin = pixelsOrUndef(margin)
  } else {
    styles.margin = margin.map(pixelsOrUndef).join(' ')
  }

  return compactObject(styles)
}

export const resolveBoxPadding = ({
  paddingTop,
  paddingLeft,
  paddingRight,
  paddingBottom,
  padding,
}: Partial<BoxPaddingProps>): CSSProperties => {
  const styles: CSSProperties = {}

  styles.paddingTop = pixelsOrUndef(paddingTop)
  styles.paddingLeft = pixelsOrUndef(paddingLeft)
  styles.paddingRight = pixelsOrUndef(paddingRight)
  styles.paddingBottom = pixelsOrUndef(paddingBottom)

  if (!Array.isArray(padding)) {
    styles.padding = pixelsOrUndef(padding)
  } else {
    styles.padding = padding.map(pixelsOrUndef).join(' ')
  }

  return compactObject(styles)
}

export const makeBoxChildStyleMapper =
  (overrideChildStyles: CSSProperties) =>
  (child: ReactNode): ReactNode => {
    if (!isValidElement(child)) {
      return child
    }

    if (Object.keys(overrideChildStyles).length === 0) {
      return child
    }

    const style = { ...child.props.style, ...overrideChildStyles }
    return cloneElement<React.HTMLProps<'div'>>(child, { style })
  }
