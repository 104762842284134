import { useTable, useFlexLayout, useSortBy } from 'react-table-next'
import { useSticky } from 'react-table-sticky'
import {
  type GridData,
  type GridDatum,
  type GridColumns,
  type CustomTableInstance,
  type CustomTableOptions,
  type GridTableOptions,
} from './grid.types'

const defaultColumn = {}

export interface UseGridDetailsProps<TDatum extends GridDatum> {
  data: GridData<TDatum>
  columns: GridColumns<TDatum>
  tableOptions?: GridTableOptions<TDatum>
  compact?: boolean
}

export const useGridDetails = <TDatum extends GridDatum>({
  data,
  columns,
  tableOptions = {},
  compact = false,
}: UseGridDetailsProps<TDatum>) => {
  const useTableOptions: CustomTableOptions<TDatum> = {
    data,
    columns,
    defaultColumn,
    compact,
    ...tableOptions,
  }

  const gridDetails = useTable<TDatum>(
    useTableOptions,
    useFlexLayout,
    useSortBy,
    useSticky
    // React Table's plugin system doesn't play nice with TypeScript
    // Their suggested workaround: https://github.com/tannerlinsley/react-grid/commit/cee24c74d847cdb7d9ea374af065e85e73138743?short_path=357a56b#diff-357a56bc8df6226426455af0a807d77b
  ) as unknown as CustomTableInstance<TDatum>

  return gridDetails
}
