import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRedirect, DomainRoute } from '../utils/domain/routingComponents'
import { accountManagementAccessControl } from '../access-control/accountManagementAccess.configuration'
import { viewRetailerSecurityAccessControl } from '../access-control/multiFactorAuthAccess.configuration'

const RetailerSecurityPage = lazy(
  () => import('../pages/retailer/AccountManagementRetailerSecurityPage')
)

// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const accountManagementRouter = (
  <DomainRoute route="retailer-account-management" accessControl={accountManagementAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="retailer-security"
        component={RetailerSecurityPage}
        accessControl={viewRetailerSecurityAccessControl}
        scopePicker={{
          titles: ['accountManagementDomain.security.title'],
        }}
      />

      <DomainRedirect toRoute="retailer-security" />

      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
