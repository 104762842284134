import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const storefrontAdminAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.AdminPanel],
}

export const navigationAdminViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.AdminPanel, Permission.StorefrontNavigationView],
}
