import React from 'react'
import styled from '@emotion/styled'
import { type CellProps } from 'react-table-next'
import { type ReportsNormalizedReportsDatum } from '../../utils/ReportsNormalizedReportsList.types'

export type ReportsNormalizedReportsListDefaultCellProps = CellProps<ReportsNormalizedReportsDatum>

const StyledSpan = styled.span({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: 0,
  overflow: 'hidden',
  lineHeight: '1.5em',
})

export const ReportsNormalizedReportsListDefaultCell: React.FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListDefaultCellProps>
> = ({ row, column }) => {
  const content = row.original[column.id] || '—'

  return <StyledSpan title={content}>{content}</StyledSpan>
}
