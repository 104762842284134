import React, { type FunctionComponent } from 'react'
import { type AuthenticationDomainRouteName } from '@retailer-platform/domain-authentication'
import { LinkButton } from '../../../../gin-and-tonic/routing/link-button/LinkButton'
import { PublicLayout } from '../../../../gin-and-tonic/common/layout/PublicLayout'
import { type DashRouteName } from '../../../../utils/routing/routes'
import { useHaltAccountActionRequiredDetails } from './utils/haltAccountActionRequired.hooks'

const testId = 'app-halt-account-action-required-layout'

export const HaltAccountActionRequired: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { emailNeedsVerification, loading, errorMessageId } = useHaltAccountActionRequiredDetails()

  if (loading) {
    return null
  }

  if (errorMessageId) {
    return (
      <PublicLayout
        headerMessageId="common.error.title"
        contentMessageId={errorMessageId}
        data-testid={testId}
      />
    )
  }

  if (emailNeedsVerification) {
    return (
      <PublicLayout
        headerMessageId="app.halt.actionRequired.header"
        contentMessageId="app.halt.actionRequired.content.emailVerification"
        data-testid={testId}
      >
        <LinkButton
          messageId="app.halt.actionRequired.emailVerificationAction"
          toRoute={
            'app-public-authentication-request-email-verification' satisfies AuthenticationDomainRouteName as DashRouteName
          }
          data-testid="app-halt-account-action-required-action-button"
        />
      </PublicLayout>
    )
  }

  return (
    <PublicLayout
      headerMessageId="app.halt.actionRequired.header"
      contentMessageId="app.halt.actionRequired.content.common"
      data-testid={testId}
    >
      <LinkButton
        messageId="app.halt.actionRequired.refreshAction"
        toRoute="app-halt-redirect"
        data-testid="app-halt-account-action-required-refresh-button"
      />
    </PublicLayout>
  )
}
