import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_INTEGRATIONS = `${URL_RETAILER_SCOPED}/integrations`
const URL_INTEGRATIONS_LIST = `${URL_INTEGRATIONS}/list`
const URL_INTEGRATIONS_VIEW_GENERIC = `${URL_INTEGRATIONS}/view/:integrationType`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'integrations'>
  | DashRouteType<'integrations-list'>
  | DashRouteType<'integrations-view-generic', { integrationType: string }>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  integrations: URL_INTEGRATIONS,
  'integrations-list': URL_INTEGRATIONS_LIST,
  'integrations-view-generic': URL_INTEGRATIONS_VIEW_GENERIC,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
