import styled from '@emotion/styled'
import { fontWeight } from '@retailer-platform/shared-components'

interface CircleLogoProps {
  color: string
  size?: number
}

export const CircleLogo = styled.span<CircleLogoProps>(({ color, size }) => ({
  display: 'inline-block',
  flexShrink: 0,
  width: size ? `${size}px` : '26px',
  height: size ? `${size}px` : '26px',
  lineHeight: size ? `${size}px` : '26px',
  fontSize: 14,
  fontWeight: fontWeight.SEMIBOLD,
  color: '#fff',
  backgroundColor: color,
  borderRadius: '50%',
  textAlign: 'center',
}))
