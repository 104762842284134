/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Formik supports TS < 2.9, so doesn't have great typings
 * for some things as it can't use the infer keyword
 */
export type BetterFormikErrors<Values> = ValuesAsType<Values, string>
export type BetterFormikTouched<Values> = ValuesAsType<Values, boolean>

// prettier-ignore
export function hasTouchedFieldsWithErrors(
  e: BetterFormikErrors<any> | (BetterFormikErrors<any> | undefined)[] | string | undefined,
  t: BetterFormikTouched<any> | (BetterFormikTouched<any> | undefined)[] | boolean | undefined
) {
  if (e === undefined || t === undefined) {
    return false;
  }

  if (typeof e === 'string' && typeof t === 'boolean') {
    return t;
  }

  if (typeof e === 'object' && typeof t === 'object') {
    const keys = Object.keys(e);

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];

      const eVal = Array.isArray(e) ? e[parseInt(key, 10)] : e[key];
      const tVal = Array.isArray(t) ? t[parseInt(key, 10)] : t[key];

      if (hasTouchedFieldsWithErrors(eVal, tVal)) {
        return true;
      }
    }
  }

  return false;
}
