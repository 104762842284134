import React from 'react'
import { generatePath } from 'react-router'
import clientEnv from '../../../../utils/global/clientEnv'
import { NavSupportedEnvironment } from '../../../../exports/routing'
import { type PartnerContextValue } from '../../../../utils/contexts/partner/PartnerContext'

export const getHrefOverride = (
  route: string,
  environment: string,
  partnerContext: PartnerContextValue,
  routesByName: { [key: string]: string }
) => {
  let hrefOverride: string | undefined
  // this enables tools to link out to the correct environment (dev or prod) when in dev or prod
  if (
    environment &&
    environment !== clientEnv.PUBLIC_CLIENT_ENVIRONMENT &&
    (clientEnv.PUBLIC_CLIENT_ENVIRONMENT === NavSupportedEnvironment.Production ||
      clientEnv.PUBLIC_CLIENT_ENVIRONMENT === NavSupportedEnvironment.Dev)
  ) {
    if (environment === NavSupportedEnvironment.Production) {
      hrefOverride = `${clientEnv.PUBLIC_CLIENT_PRODUCTION_URL}${generatePath(routesByName[route], {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        partner_id: partnerContext?.partnerId,
      })}`
    } else if (environment === NavSupportedEnvironment.Dev) {
      hrefOverride = `${clientEnv.PUBLIC_CLIENT_RPP_DEV_URL}${generatePath(routesByName[route], {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        partner_id: partnerContext?.partnerId,
      })}`
    }
  }

  return hrefOverride
}
