import React from 'react'
import { createContextHoc } from '../../../legacy/contexts/utils'
import { type ApiDataState, type PartnerType } from '../../../legacy/common/types'
import { type FeatureEntitlement, type FeatureToggle } from '../../../__codegen__/api'
import { type WarehouseWithFeatures, type PartnerWithLocales } from '../warehouse/queries/warehouse'
import { type WarehouseLocationFeature } from '../../../legacy/common/warehouseLocations'

export type PartnerContextValue = ApiDataState<{
  warehouses: WarehouseWithFeatures[]
  partner: PartnerWithLocales

  // a valid ID is a non-zero ID representing an actual Partner.
  // An ID with a value of zero is a placeholder value until an actual ID is chosen by user
  validId: boolean
  locationFeatures: WarehouseLocationFeature[]
  partnerName: string
  partnerType: PartnerType
  partnerId: string
  featureToggles: FeatureToggle[]
  featureEntitlements: FeatureEntitlement[]
  hasIaNavV2: boolean
}>

export const PartnerContext = React.createContext<PartnerContextValue | null>(null)

PartnerContext.displayName = 'PartnerContext'

export const withPartnerContext = createContextHoc(PartnerContext, {
  displayName: 'withPartnerContext',
  required: true,
})
