import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const insightsPortalAdsDashboardAccessControl: DomainAccessControlConfig = [
  {
    permissions: Permission.InsightsPortalAdsDashboard,
    featureToggles: FeatureToggle.InsightsPortalAdsDashboard,
  },
  {
    permissions: Permission.InsightsPortalAdsDashboard,
    warehouseFeatureToggles: FeatureToggle.InsightsPortalAdsDashboardRetailers,
  },
]
