import React, { lazy } from 'react'
import RetailerRoute from '../../../../../legacy/components/RetailerRoute'
import RetailerSwitch from '../../../../../legacy/components/RetailerSwitch'
import { NotFoundPage } from '../../../../../legacy/dash-components/not-found-page/NotFoundPage'
import { RouteSuspense } from '../../../../../legacy/dash-components/route-suspense/RouteSuspense'
import { StripeAssociationsAccessControl } from '../../../../../legacy/routes/settings/stripe/StripeAssociations.configuration'

const StripeAssociations = lazy(
  () =>
    import(
      /* webpackChunkName: "stripe-associations" */ '../../../../../legacy/routes/settings/stripe/StripeAssociations'
    )
)

export const SettingsRoutes = () => (
  <RouteSuspense>
    <RetailerSwitch>
      <RetailerRoute
        route="stripeSettings"
        component={StripeAssociations}
        accessControl={StripeAssociationsAccessControl}
        scopePicker={{
          titles: ['settings.stripe.title'],
        }}
      />
      <RetailerRoute component={NotFoundPage} />
    </RetailerSwitch>
  </RouteSuspense>
)
