import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const widgetsConfigViewAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsView],
}

export const widgetsConfigModifyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.StoreConfigsModify],
}
