/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useContext, type FunctionComponent, useEffect } from 'react'
import qs from 'query-string'
import { LoadingPage, ErrorPage } from '@retailer-platform/dashboard/gin-and-tonic'
import {
  type PartnerPickCapacityRecommendation,
  type RecommendationTypeEnum,
  usePartnerPickGetCapacityRecommendationsQuery,
} from '../../api'
import { type ShiftsRouteQuery } from '../types'

export type RecommendationShiftType = { __typename?: 'PartnerPickCapacityRecommendation' } & Pick<
  PartnerPickCapacityRecommendation,
  'id' | 'retailerLocationId' | 'date' | 'recommendationType' | 'shiftType' | 'startsAt' | 'endsAt'
>

type RecommendedShiftsContextType = {
  recommendations: RecommendationShiftType[]
  allowedRecommendationTypes: RecommendationTypeEnum[]
  refetchRecommendations?(): void
}
export const RecommendedShiftsContext = createContext<RecommendedShiftsContextType>({
  recommendations: [],
  allowedRecommendationTypes: [],
  refetchRecommendations: null,
})

const POLL_INTERVAL = 6 * 60 * 1000 // Refetch data every 6 minutes
export const RecommendedShiftsContextProvider: FunctionComponent<
  React.PropsWithChildren<{
    pollInterval?: number
    warehouseIdAndDate: string
  }>
> = ({ children, pollInterval = POLL_INTERVAL, warehouseIdAndDate }) => {
  const query = qs.parse(location.search) as ShiftsRouteQuery
  const { warehouse_location_id, date } = query

  let recommendations = [],
    allowedRecommendationTypes = []
  const {
    data,
    error,
    loading,
    startPolling,
    stopPolling,
    refetch: refetchRecommendations,
  } = usePartnerPickGetCapacityRecommendationsQuery({
    variables: {
      retailerLocationId: warehouse_location_id,
      date,
    },
    onError: () => stopPolling(),
  })

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval)
    }
    return () => stopPolling()
  }, [startPolling, stopPolling, pollInterval, error, warehouseIdAndDate])

  if (error) {
    stopPolling()
    return <ErrorPage />
  }

  if (!data || loading) return <LoadingPage />

  recommendations = data.partnerPickGetCapacityRecommendations.recommendations
  allowedRecommendationTypes = data.partnerPickGetCapacityRecommendations.allowedRecommendationTypes

  return (
    <RecommendedShiftsContext.Provider
      value={{ recommendations, allowedRecommendationTypes, refetchRecommendations }}
    >
      {children}
    </RecommendedShiftsContext.Provider>
  )
}

export const useRecommendedShiftsContext = () => useContext(RecommendedShiftsContext)

/* eslint-enable @typescript-eslint/naming-convention */
