import { makeUseMessage, makeUseMessages, makeUseIntl } from '@retailer-platform/shared-components'
import { type DashIntlShape, type DashMessageDescriptor } from '@retailer-platform/dashboard/intl'
import { type EN_US } from '../../translations/en-US'

// EN_US will be used as the base type for message definitions
export type DomainMessages = keyof typeof EN_US

export const useDomainMessage = makeUseMessage<DomainMessages>()
export const useDomainMessages = makeUseMessages<DomainMessages>()
export const useDomainIntl = makeUseIntl<DashIntlShape<DashMessageDescriptor<DomainMessages>>>()
