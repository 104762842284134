import styled from '@emotion/styled'
import { borderRadius, colors, fontSize, spacing } from '../../foundation'

export const NotificationWrapper = styled.div({
  boxShadow: '0px 0px 16px 0px #00000029',
  background: colors.GRAYSCALE.X0,
  width: 450,
  position: 'relative',
  padding: spacing.X12,
  borderRadius: borderRadius.X8,
  fontSize: fontSize.X15,
  display: 'flex',
  flexDirection: 'row',
  gap: spacing.X12,
  alignItems: 'center',
})

export const NotificationsDisplayWrapper = styled.div({
  position: 'fixed',
  bottom: spacing.X12,
  left: spacing.X12,
  display: 'flex',
  gap: spacing.X12,
  flexDirection: 'column',
})
