/**
 * Maintaining a list of errors that we want to ignore from being sent to any monitoring providers
 * This should be used sparingly, and only for errors that we know we can't do anything about.
 *
 * Each entry should have some comment explaining why it's being ignored
 */
export const IGNORED_ERRORS = [
  /* This error is emitted whenever the user opens the partner / account menu in the header
   * It is benign and difficult to fix due to it coming from an IDS component.  Chrome and Firefox dont even
   * show this in the dev console.
   * https://stackoverflow.com/a/50387233
   */
  'ResizeObserver loop completed with undelivered notifications.',
]
