import React, { useState, type FunctionComponent, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Modal, colors, useDebouncedState } from '@retailer-platform/shared-components'
import { CloseIcon, ArrowLeftIcon } from '@instacart/ids-core'
import { Redirect } from 'react-router'
import { DashRedirect } from '../../../legacy/dash-components/dash-redirect/DashRedirect'
import { useLocalForageKey } from '../../../utils/local-storage/useLocalForageKey.hooks'
import { useCurrentAccountContext } from '../../../utils/current-account/CurrentAccountContext'
import { useDashMessages } from '../../../intl/intl.hooks'
import { AppAdminSectionAccessControl } from '../../../sections/admin/AdminSection.configuration'
import useAccessControl from '../../../legacy/components/AccessControl/useAccessControl'
import {
  useFetchPartnersQuery,
  useGetAllPartnersForUserPaginatedQuery,
} from '../../../__codegen__/api'
import { useOptionalPartnerContext } from '../../../exports/utils'
import { RecentlyVisitedList } from './RecentlyVisitedList'
import { PartnerList } from './PartnerList'
import { SearchFilter } from './SearchFilter'
import { UserOption } from './UserOption'
import { IconButton, LinkText, Spacer } from './SelectPartner.styles'
import { useRedirectionLocation } from './useRedirectLocation.hooks'
import { useShouldAutoRedirect } from './useShouldAutoRedirect.hook'

const ContentWrapper = styled.div({
  padding: '50px 40px',
  maxHeight: '800px',
})

const PartnersListWrapper = styled.div({
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '595px',
})

const Title = styled.div({
  height: '32px',
  margin: '8px 0px 16px',
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '32px',
})

const Subtitle = styled.div({
  height: '20px',
  margin: '8px 0px 24px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
})

const LineSeparator = styled.div({
  border: `1px solid ${colors.GRAYSCALE.X20}`,
  margin: '32px 0px',
})

export const CloseButtonContainer = styled.div({
  position: 'absolute',
  zIndex: 100,
  top: '16px',
  right: '16px',
  color: 'white',
  cursor: 'pointer',
})

export const BackButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  top: '16px',
  left: '16px',
  cursor: 'pointer',
  alignItems: 'center',
})

export type LocalStorageProps = {
  recentlyVisited: {
    partnerId: string
  }[]
}

export interface Props {
  handleClose?: () => void
  initialLoad?: boolean // if true redirect to dashboard after selecting partner
  restrictToPartnerView?: boolean // if true skip the first page where the user can choose between "Admin" and "Partner". We want to go directly to the Partner screen.
  autoSelect?: boolean // if true, try to autoselect partner if there is only 1
}

const SelectPartner: FunctionComponent<React.PropsWithChildren<Props>> = ({
  handleClose,
  initialLoad,
  restrictToPartnerView,
  autoSelect,
}) => {
  const [showRetailers, setShowRetailers] = useState<boolean>(restrictToPartnerView)
  const [adminPageRedirect, setAdminPageRedirect] = useState<string>(null)
  const [selectedPartnerId, setPartnerId] = useState<string>(null)
  const account = useCurrentAccountContext()
  const hasAccess = useAccessControl()
  const redirectLocation = useRedirectionLocation(initialLoad)
  const {
    redirect: autoRedirect,
    loading: autoRedirectLoading,
    url: autoRedirectUrl,
  } = useShouldAutoRedirect(autoSelect, initialLoad)
  const partnerContext = useOptionalPartnerContext()

  const [, debouncedSearchFilter, setSearchFilter] = useDebouncedState('', 250)

  const { data: localStorageData, setData: setLocalStorageData } =
    useLocalForageKey<LocalStorageProps>('recentlyVisited:' + account.account.id)

  const { data: partnerData, error: partnerDataError } = useGetAllPartnersForUserPaginatedQuery({
    variables: {
      name: debouncedSearchFilter,
      first: 50,
    },
  })

  const { data: recentlyVisitedPartnerData, error: recentlyVisitedPartnerError } =
    useFetchPartnersQuery({
      variables: {
        ids: localStorageData?.recentlyVisited.map(e => e.partnerId),
      },
      skip: !localStorageData?.recentlyVisited,
    })

  const messages = useDashMessages({
    partnerLabel: 'selectPartner.partnerLabel',
    partnerDescription: 'selectPartner.partnerDescription',
    adminLabel: 'selectPartner.adminLabel',
    adminDescription: 'selectPartner.adminDescription',
    selectPartnerTitle: 'selectPartner.selectPartnerTitle',
    mainTitle: 'selectPartner.mainTitle',
    mainSubTitle: 'selectPartner.mainSubTitle',
    back: 'selectPartner.back',
  })

  const hasAppAdminAccess = hasAccess(AppAdminSectionAccessControl)

  const userOptions = useMemo(() => {
    const options = []
    if (hasAppAdminAccess) {
      options.push({
        id: 1,
        label: messages.adminLabel,
        type: 'redirect',
        url: '',
      })
    }
    options.push({
      id: 2,
      label: messages.partnerLabel,
      description: messages.partnerDescription,
      type: 'list_retailers',
    })

    const userOptions = options.map(e => (
      <div
        key={e.id}
        onClick={evt => {
          if (e.type == 'list_retailers') {
            setShowRetailers(true)
          } else if (e.type == 'redirect') {
            setAdminPageRedirect('internal')
          }
        }}
      >
        <UserOption tag={e.tag} label={e.label} subLabel={e.description} />
      </div>
    ))

    return userOptions
  }, [hasAppAdminAccess, messages.adminLabel, messages.partnerDescription, messages.partnerLabel])

  useEffect(() => {
    // need to call handleClose from inside useEffect because otherwise
    // we are calling setState on the parent modal during a render
    // https://legacy.reactjs.org/blog/2020/02/26/react-v16.13.0.html#warnings-for-some-updates-during-render
    if (adminPageRedirect || selectedPartnerId) {
      handleClose?.()
    }
  }, [adminPageRedirect, handleClose, selectedPartnerId])

  if (adminPageRedirect) {
    return <DashRedirect push toRoute={'app-admin'} />
  }

  if (autoRedirectLoading) {
    return null
  }
  if (autoRedirect) {
    return <Redirect push to={autoRedirectUrl} />
  }

  if (selectedPartnerId) {
    const partners = partnerData?.partnerRetailerSearch?.nodes || []
    const recentlyVisitedPartners = recentlyVisitedPartnerData?.partners || []
    const partner = [...partners, ...recentlyVisitedPartners].find(p => p.id == selectedPartnerId)
    const warehouseId = partner?.retailers?.length == 1 ? partner.retailers[0].id : '0'

    const url = redirectLocation(
      partner.partnerType,
      selectedPartnerId,
      warehouseId,
      partnerContext && partner.partnerType !== partnerContext?.partnerType
    )

    return <Redirect push to={url} />
  }

  const onPartnerClick = (partnerId: string) => {
    setPartnerId(partnerId)

    let recentlyVisited = localStorageData?.recentlyVisited || []

    // remove partner ID from current position (if exists), and push to the front of the list
    recentlyVisited = recentlyVisited.filter(e => e.partnerId !== partnerId)
    recentlyVisited.unshift({ partnerId: partnerId })

    // always keep largest possible Recently Visited size at 5
    setLocalStorageData({ recentlyVisited: recentlyVisited.slice(0, 5) })
  }

  const onPartnerRemoveFromRecents = (partnerId: string) => {
    let recentlyVisited = localStorageData?.recentlyVisited || []
    recentlyVisited = recentlyVisited.filter(e => e.partnerId !== partnerId)

    setLocalStorageData({ recentlyVisited })
  }

  const onRemoveAllRecents = () => {
    setLocalStorageData({ recentlyVisited: [] })
  }

  const handleBack = () => {
    setSearchFilter('')
    setShowRetailers(false)
  }

  const backButton = !restrictToPartnerView && (
    <BackButtonContainer onClick={handleBack} data-testid={`default-back-button`}>
      <IconButton>
        <ArrowLeftIcon size={20} />
        <LinkText>{messages.back}</LinkText>
      </IconButton>
    </BackButtonContainer>
  )

  const closeButton = handleClose && (
    <CloseButtonContainer onClick={handleClose} data-testid={`default-close-button`}>
      <IconButton>
        <CloseIcon size={20} />
      </IconButton>
    </CloseButtonContainer>
  )

  return (
    <Modal
      isOpen={true}
      maxHeight={800}
      maxWidth={588}
      position={'top-center'}
      closeTimeoutMS={200}
      hideCloseButton
    >
      <ContentWrapper>
        {!showRetailers && (
          <>
            {closeButton}
            <Title>{messages.mainTitle}</Title>
            <div css={{ marginTop: '24px' }}>{userOptions}</div>
            {localStorageData?.recentlyVisited?.length > 0 && (
              <>
                <LineSeparator />
                <RecentlyVisitedList
                  filter={debouncedSearchFilter}
                  setPartnerId={onPartnerClick}
                  onPartnerRemoveFromRecents={onPartnerRemoveFromRecents}
                  onRemoveAllRecents={onRemoveAllRecents}
                  recentlyVisitedPartnerData={recentlyVisitedPartnerData}
                  recentlyVisitedPartnerError={recentlyVisitedPartnerError}
                />
              </>
            )}
          </>
        )}
        {showRetailers && (
          <>
            {backButton}
            {closeButton}
            <Title>{messages.selectPartnerTitle}</Title>
            <SearchFilter onChange={setSearchFilter} />
            <PartnersListWrapper>
              {!debouncedSearchFilter && (
                <>
                  <RecentlyVisitedList
                    filter={debouncedSearchFilter}
                    setPartnerId={onPartnerClick}
                    onPartnerRemoveFromRecents={onPartnerRemoveFromRecents}
                    onRemoveAllRecents={onRemoveAllRecents}
                    recentlyVisitedPartnerData={recentlyVisitedPartnerData}
                    recentlyVisitedPartnerError={recentlyVisitedPartnerError}
                  />
                  <Spacer height={24} />
                </>
              )}

              <PartnerList
                debouncedSearchFilter={debouncedSearchFilter}
                partnerData={partnerData}
                partnerDataError={partnerDataError}
                setPartnerId={onPartnerClick}
              />
            </PartnersListWrapper>
          </>
        )}
      </ContentWrapper>
    </Modal>
  )
}

export default SelectPartner
