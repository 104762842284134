import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'
import { useCoreContext } from '../../utils/core/RPPCoreContext'

export const useTotemInfo = () => {
  const { totemInfoByRoute } = useCoreContext()
  const { pathname } = useLocation()

  const totemInfo = useMemo(() => {
    const matchedKey = Object.keys(totemInfoByRoute).find(key => {
      if (matchPath(pathname, { path: key, exact: true })?.path) {
        return true
      }
    })
    return totemInfoByRoute[matchedKey]
  }, [pathname, totemInfoByRoute])

  return totemInfo
}
