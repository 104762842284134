import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Text } from '@instacart/ids-customers'
import { Card, type CardProps } from '../../card/Card'
import { spacing } from '../../../foundation'

const cardBodyStyles = css({
  padding: spacing.X40,
  gap: spacing.X36,
})

export const PublicCard: React.FunctionComponent<React.PropsWithChildren<CardProps>> & {
  Title: React.ComponentType<React.PropsWithChildren<{ className?: string }>>
  Subtitle: React.ComponentType<React.PropsWithChildren<{ className?: string }>>
} = props => <Card css={{ flex: 'none' }} {...props} bodyStyles={cardBodyStyles} />

PublicCard.Title = styled.h1(({ theme }) => ({
  ...theme.typography.headlineLarge1,
  fontVariationSettings: '"wght" 700, "opsz" 8',
}))

PublicCard.Subtitle = ({ children, ...props }) => (
  <Text typography="bodyLarge2" as="h2" {...props}>
    {children}
  </Text>
)
