import { useMemo, useState, useCallback } from 'react'

export interface UseSelectionInitialState {
  isGlobalSelection?: boolean
  globalCount?: number
  selectedKeys?: Record<string, boolean>
}

export const useSelection = (initialState: UseSelectionInitialState = {}) => {
  const [selected, baseSetSelected] = useState<Record<string, boolean>>(
    initialState.selectedKeys ?? {}
  )
  const [globalCount, baseSetGlobalCount] = useState(initialState.globalCount ?? 0)
  const [isGlobalSelection, baseSetIsGlobalSelection] = useState(
    initialState.isGlobalSelection ?? false
  )

  /**
   * Pushes a single key into the selected store
   */
  const setSelected = useCallback(
    (key, value: boolean) => {
      if (isGlobalSelection) return

      baseSetSelected({
        ...selected,
        [key]: value,
      })
    },
    [selected, baseSetSelected, isGlobalSelection]
  )

  const addToSelection = useCallback(
    (keys: Record<string, boolean>) => {
      baseSetSelected({ ...selected, ...keys })
    },
    [selected, baseSetSelected]
  )

  const isSelected = useCallback(
    (key: string) => isGlobalSelection || (selected[key] ?? false),
    [isGlobalSelection, selected]
  )

  const setGlobalCount = useCallback(
    count => {
      baseSetGlobalCount(count ?? 0)
    },
    [baseSetGlobalCount]
  )

  const clearSelection = useCallback(() => {
    baseSetSelected({})
    baseSetIsGlobalSelection(false)
  }, [baseSetSelected, baseSetIsGlobalSelection])

  const setIsGlobalSelection = useCallback(
    (value: boolean) => {
      clearSelection()
      baseSetIsGlobalSelection(value)
    },
    [baseSetIsGlobalSelection, clearSelection]
  )

  const selectedKeys = useMemo(
    () =>
      Object.entries(selected)
        .filter(([_, v]) => v)
        .map(([key]) => key),
    [selected]
  )

  const selectedCount = useMemo(
    () => (isGlobalSelection ? globalCount : selectedKeys.length),
    [selectedKeys, globalCount, isGlobalSelection]
  )

  return {
    /** Sets one key as selected/not selected */
    setSelected,
    /** Sets multiple keys as selected/not selected */
    addToSelection,
    /**
     * Whether the current selection is _global_, in other words, it follows a particular
     * set of selection params (e.g. querying by name, aisle id, etc)
     */
    isGlobalSelection,
    /** Mark the current selection as _global_ */
    globalSelect: setIsGlobalSelection,
    /** Checks a key for selection, if this is currently a global selection, this will always return true */
    isSelected,
    /** Retrieves the keys that are selected -- only works for non-global selections */
    selectedKeys,
    /** Retrieves the count of the current selection, be it global or non-global */
    selectedCount,
    /** Clears the current selection, be it global or non-global */
    clearSelection,
    /** Sets the total number of results for the global selection */
    setGlobalCount,
  }
}

export type UseSelectionHookReturn = ReturnType<typeof useSelection>
