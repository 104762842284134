import { ApprovalRequestState, type GetApprovalRequestQuery, type ReviewAction } from '.'

export type ApiApprovalRequest = Omit<
  NonNullable<GetApprovalRequestQuery['approvalsManagementGetRequest']>,
  '__typename'
>

export type ApiActivityHistory = Omit<ApiApprovalRequest['reviews'], '__typename'>

export type ApiReview = ApiActivityHistory[0]

export type ApiApprovalRequestState = ApiApprovalRequest['requestState']

export const ApiApprovalRequestStateEnum = ApprovalRequestState

export type ApiShallowSnapshot = Omit<NonNullable<ApiApprovalRequest['baseSnapshot']>, '__typename'>

export const AllApprovalRequestStates: ApiApprovalRequestState[] = [
  ApprovalRequestState.Pending,
  ApprovalRequestState.Approved,
  ApprovalRequestState.Cancelled,
  ApprovalRequestState.Published,
  ApprovalRequestState.Publishing,
]

export type ApiReviewAction = typeof ReviewAction
