import { NewSelect } from '@retailer-platform/shared-components'
import React, { type FunctionComponent, useMemo } from 'react'
import { usePartnerId } from '@retailer-platform/dashboard/routing'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { type ReportTypeEnum, useGetNormalizedReportTypesQuery } from '../../../../api'
import { type ReportTypeConfig } from '../../utils/ReportsNormalizedReportsList.utils'

export interface ReportsNormalizedReportsListReportTypeSelectProps {
  reportTypesConfig: ReportTypeConfig
  selectedReportType: ReportTypeEnum
  onChange: (r: ReportTypeEnum) => void
}

export const ReportsNormalizedReportsListReportTypeSelect: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsListReportTypeSelectProps>
> = ({ reportTypesConfig, selectedReportType, onChange }) => {
  const partnerId = usePartnerId()
  const { data, error } = useGetNormalizedReportTypesQuery({ variables: { partnerId } })

  const messages = useDomainMessages({
    all: 'reportsDomain.normalizedReports.list.reportTypes.ALL',
    label: 'reportsDomain.instacartManagedReports.filters.reportType.label',
    ...reportTypesConfig,
  })

  const options = useMemo(() => {
    const reportTypesWithi18n =
      data?.normalizedReportTypes
        .filter(item => reportTypesConfig[item.value] !== undefined)
        .map(item => ({
          value: item.value,
          label: messages[item.value] ?? item.label,
        })) ?? []

    return [
      { value: null, label: messages.all },
      ...reportTypesWithi18n.sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? 0),
    ]
  }, [reportTypesConfig, data, messages])

  return (
    <NewSelect
      aria-label="ReportsNormalizedReportsListReportTypeSelect"
      options={options}
      value={selectedReportType}
      onChange={val => onChange(val)}
      compact
      placeholder={messages.label}
    />
  )
}
