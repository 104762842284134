import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { ViewType } from '@retailer-platform/dashboard/gin-and-tonic'
import { DomainRoute } from '../utils/domain/routingComponents'
import { storeConfigurationAccessControl } from '../access-control/storeConfigurationAccess.configuration'

const StoreConfigurationViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StoreConfigurationViewPage" */
      '../pages/view/StoreConfigurationViewPage'
    )
)
const StoreConfigurationDraftsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StoreConfigurationDraftsPage" */
      '../pages/drafts/StoreConfigurationDraftsPage'
    )
)
const StoreConfigurationEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "StoreConfigurationEditPage" */
      '../pages/edit/StoreConfigurationEditPage'
    )
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const storeConfigurationRouter = (
  <DomainRoute route="store-configuration" accessControl={storeConfigurationAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="store-configuration-view"
        component={StoreConfigurationViewPage}
        scopePicker={{
          titles: ['storeConfigurationDomain.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
        }}
      />
      <DomainRoute
        exact
        route="store-configuration-drafts"
        component={StoreConfigurationDraftsPage}
        scopePicker={{
          titles: ['storeConfigurationDomain.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigOperationIntent: 'modify',
        }}
      />
      <DomainRoute exact route="store-configuration-edit" component={StoreConfigurationEditPage} />
      <DomainRoute
        exact
        route="store-configuration-current"
        component={StoreConfigurationEditPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
