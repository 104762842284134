import React from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import { type GridProps, Grid } from '../../common/grid/Grid'
import { type GridDatum } from '../../common/grid/utils/grid.types'
import {
  useGridListDetails,
  type UseGridListDetailsProps,
} from '../../common/grid-list/utils/gridList.hooks'

export interface GridListProps<TDatum extends GridDatum>
  extends GridProps<TDatum>,
    Pick<
      UseGridListDetailsProps,
      'canLoadMore' | 'loadingMore' | 'onLoadMore' | 'loadingItemCount'
    > {
  thresholdItemCount?: number
}

export const GridList = <TDatum extends GridDatum>({
  data,
  columns,
  height,
  thresholdItemCount = 5,
  emptyState,
  emptyStateShowHeader,
  emptyStateMessage,
  loadingMore,
  tableOptions,
  onStateChanged,
  itemKey,
  ...rest
}: GridListProps<TDatum>) => {
  const { itemCount, loadMoreItems, isItemLoaded } = useGridListDetails({
    dataArrayLength: data.length,
    loadingMore,
    ...rest,
  })

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={thresholdItemCount}
    >
      {({ onItemsRendered, ref }) => (
        <Grid
          data={data}
          columns={columns}
          height={height}
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          isLoading={loadingMore}
          emptyState={emptyState}
          emptyStateMessage={emptyStateMessage}
          emptyStateShowHeader={emptyStateShowHeader}
          outerRef={ref}
          tableOptions={tableOptions}
          onStateChanged={onStateChanged}
          itemKey={itemKey}
        />
      )}
    </InfiniteLoader>
  )
}
