import React, { type FunctionComponent, useMemo } from 'react'
import { useFormContext } from 'react-hook-form-7'
import { type Color, ConfirmIcon, IssueIcon } from '@instacart/ids-core'
import { useMessages } from '../../../../utils/intl/intl.hooks'
import { FormField, FormFieldLabel, FormFieldFeedback } from '../../../form-field/FormField'
import { PasswordInput } from '../../../password-input/PasswordInput'
import { colors } from '../../../../foundation'

type PartialFormData = {
  password: string
}

export type FormPasswordProps = {
  testidPrefix: string
  disabled?: boolean
  label?: string
  deps?: string[]
}

// IF CHANGING: also update auth_service/app/services/external_account_service/set_password.rb
const passwordValidations: Record<string, (v: string) => boolean> = {
  // Note: not using default `minLength` because it wont trigger when value is empty
  length: (value: string) => value?.length >= 8,
  numeric: (value: string) => /[0-9]/.test(value),
  alpha: (value: string) => /[a-z]/i.test(value),
}

export const FormPassword: FunctionComponent<React.PropsWithChildren<FormPasswordProps>> = ({
  label,
  testidPrefix,
  disabled,
  deps = [],
}) => {
  const {
    register,
    formState: { errors, dirtyFields },
  } = useFormContext<PartialFormData>()

  const messages = useMessages({
    placeholder: 'sharedComponents.publicPages.formPassword.placeholder',
    label: 'sharedComponents.publicPages.formPassword.label',
    length: 'sharedComponents.publicPages.formPassword.rules.length',
    numeric: 'sharedComponents.publicPages.formPassword.rules.numeric',
    alpha: 'sharedComponents.publicPages.formPassword.rules.alpha',
  })

  const validationFeedback = useMemo(() => {
    const isFieldDirty = dirtyFields.password
    const keys = ['length', 'numeric', 'alpha'] satisfies (keyof typeof messages)[]

    return keys.map<{
      key: string
      icon: React.ComponentType<any>
      message: string
      color: string
      iconColor: Color
    }>(key => {
      const hasError = errors.password?.types?.[key] || false
      const message = messages[key]

      // If the field is not dirty, we just show some neutral information
      if (!isFieldDirty) {
        return {
          key,
          message,
          'data-testid': `${testidPrefix}-password-${key}-neutral`,
          icon: ConfirmIcon,
          iconColor: 'systemGrayscale70',
          color: colors.GRAYSCALE.X70,
        }
      }

      return {
        key,
        message,
        'data-testid': `${testidPrefix}-password-${key}-${hasError ? 'error' : 'success'}`,
        icon: hasError ? IssueIcon : ConfirmIcon,
        iconColor: hasError ? 'systemDetrimentalRegular' : 'systemSuccessRegular',
        color: hasError ? colors.DETRIMENTAL.REGULAR : colors.GRAYSCALE.X70,
      }
    })
  }, [errors.password, messages, dirtyFields.password, testidPrefix])

  return (
    <FormField noMargin>
      <FormFieldLabel htmlFor="password">{label || messages.label}</FormFieldLabel>
      <PasswordInput
        id="password"
        data-testid={`${testidPrefix}-field-password`}
        placeholder={messages.placeholder}
        {...register('password', {
          validate: passwordValidations,
          required: true,
          deps: [...deps, 'confirmPassword'],
        })}
        disabled={disabled}
      />

      {validationFeedback.map(({ key, color, message, ...props }) => (
        <FormFieldFeedback key={key} css={{ color }} {...props}>
          {message}
        </FormFieldFeedback>
      ))}
    </FormField>
  )
}
