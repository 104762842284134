export const dashSpaceMapping = {
  none: '0px',
  xxsmall: '4px',
  xsmall: '8px',
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '48px',
  xxlarge: '64px',
} as const

export type DashSpacing = keyof typeof dashSpaceMapping

export const dashSpacing = {
  NONE: dashSpaceMapping.none,
  XXSMALL: dashSpaceMapping.xxsmall,
  XSMALL: dashSpaceMapping.xsmall,
  SMALL: dashSpaceMapping.small,
  MEDIUM: dashSpaceMapping.medium,
  LARGE: dashSpaceMapping.large,
  XLARGE: dashSpaceMapping.xlarge,
  XXLARGE: dashSpaceMapping.xxlarge,
} as const
