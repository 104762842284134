import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'
import { Permission } from './permissions'

export const insightsPortalAwarenessCampaignInsightsAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.Campaign],
    featureToggles: FeatureToggle.InsightsPortalAwarenessCampaignsDashboard,
  },
  {
    permissions: [Permission.Campaign],
    warehouseFeatureToggles: FeatureToggle.InsightsPortalAwarenessCampaignsDashboard,
  },
]
