import React from 'react'
import { type TestableProps } from '../../../common/utils/testing/testing.types'
import { type NoOptionsProps, type ToggleHeaderProps } from './select.types'

export interface DashSelectContextValue extends TestableProps {
  noOptions?: NoOptionsProps
  toggle?: ToggleHeaderProps
}

export const DashSelectContext = React.createContext<DashSelectContextValue | null>(null)

DashSelectContext.displayName = 'DashSelectContext'
