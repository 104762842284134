import React, { type FunctionComponent, Fragment } from 'react'
import { type AuthenticationDomainRouteName } from '@retailer-platform/domain-authentication'
import SelectPartnerWarehousePage from '../../legacy/components/SelectPartnerWarehouse/SelectPartnerWarehousePage'
import { DashRoute } from '../../legacy/dash-components/dash-route/DashRoute'
import { DashRedirect } from '../../legacy/dash-components/dash-redirect/DashRedirect'
import { DashSwitch } from '../../legacy/dash-components/dash-switch/DashSwitch'
import { type DashRouteComponentProps } from '../../legacy/dash-components/dash-route/utils/dashRoute.types'
import { CurrentAccountProvider } from '../../utils/current-account/CurrentAccountProvider'
import { useIsLoggedIn } from '../../gin-and-tonic/utils/access/useIsLoggedIn'
import { type DashRouteName } from '../../utils/routing/routes'
import { consumeStoredRedirectUrl, storeRedirectUrl } from './utils/loggedIn.utils'
import { PartnerScopedSection } from './partner-scoped/PartnerScopedSection'

interface Props extends DashRouteComponentProps<{}> {}

export const LoggedInRoutes: FunctionComponent<React.PropsWithChildren<Props>> = ({
  location: { pathname, search, hash },
}) => {
  const { isLoggedIn, loading } = useIsLoggedIn()
  // If we do not know yet if they are logged in, we do not render anything
  if (loading) return null

  // If we are not logged in, we want to redirect to the login page
  // We store the defaultRedirectOverride for later when the user logs in again
  if (!isLoggedIn) {
    storeRedirectUrl({ pathname, search, hash })
    return (
      <DashRedirect
        toRoute={
          'app-public-authentication-log-in' satisfies AuthenticationDomainRouteName as DashRouteName
        }
      />
    )
  } else {
    // If we're logged in, and there was a stored redirect, we consume it
    const target = consumeStoredRedirectUrl()

    // If anything was returned from above, we redirect to it
    if (target) return <DashRedirect to={target} />
  }

  return (
    <Fragment>
      <CurrentAccountProvider>
        <DashSwitch>
          <DashRoute route="scope" component={SelectPartnerWarehousePage} />
          <DashRoute route="partner-scoped" component={PartnerScopedSection} />
          {/* If the user is on the URL_BASE route, we send them to the scope selector */}
          <DashRedirect exact fromRoute="base" toRoute="scope" />
        </DashSwitch>
      </CurrentAccountProvider>
    </Fragment>
  )
}
