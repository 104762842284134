import {
  AccessControl,
  WarehouseLocationFeature,
} from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'

export const aggregateStorePerformanceAccessControl: DomainAccessControlConfig = [
  // permissions and warehouseLocationFeatures both must be enabled
  {
    permissions: Permission.AggregateStorePerformance,
    warehouseLocationFeatures: AccessControl.oneOf([
      WarehouseLocationFeature.RetailerPickup,
      WarehouseLocationFeature.RetailerAlcoholDelivery,
    ]),
  },
  {
    permissions: Permission.AggregateStorePerformance,
    warehouseLocationFeatures: AccessControl.oneOf([
      WarehouseLocationFeature.RetailerPickup,
      WarehouseLocationFeature.RetailerAlcoholDelivery,
    ]),
  },
]
