import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { ordersOperationalDashboardAccessControl } from '../access-control/ordersOperationalDashboardAccess.configuration'

const OrdersOperationalDashboardSearchPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OrdersOperationalDashboardSearchPage" */
      '../pages/OrdersOperationalDashboardSearchPage'
    )
)

const OrdersOperationalDashboardTrackingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OrdersOperationalDashboardTrackingPage" */
      '../pages/OrdersOperationalDashboardTrackingPage'
    )
)
// @hygen:inject page-import

const OrdersOperationalDashboardPickupDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OrdersOperationalDashboardPickupDetailsPage" */
      '../pages/OrdersOperationalDashboardPickupDetailsPage'
    )
)

export const ordersOperationalDashboardRouter = (
  <DomainRoute
    route="orders-operational-dashboard"
    accessControl={ordersOperationalDashboardAccessControl}
  >
    <DashSwitch>
      <DomainRoute
        route="orders-operational-dashboard-search-page"
        component={OrdersOperationalDashboardSearchPage}
        scopePicker={{
          titles: ['ordersOperationalDashboardDomain.menuItemTitle'],
        }}
      />
      <DomainRoute
        exact
        route="orders-operational-dashboard-tracking-page"
        component={OrdersOperationalDashboardTrackingPage}
      />
      <DomainRoute
        exact
        route="orders-operational-dashboard-details-tab"
        component={OrdersOperationalDashboardTrackingPage}
      />
      <DomainRoute
        exact
        route="orders-operational-dashboard-pickup-details-page"
        component={OrdersOperationalDashboardPickupDetailsPage}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
