import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

// This is intentionaly empty, people should see the nav items an then see
// a page that shows they do not have access to that page.
export const NoPermissionRequired: DomainAccessControlConfig = {}

// Specific permissions for Business Program entities
export const BusinessProgramRead: DomainAccessControlConfig = {
  permissions: [Permission.BusinessProgramView],
}

export const BusinessProgramAuthor: DomainAccessControlConfig = {
  permissions: [Permission.BusinessProgramModify],
}

export const BusinessesRead: DomainAccessControlConfig = {
  permissions: [Permission.BusinessesView],
}

export const BusinessesAuthor: DomainAccessControlConfig = {
  permissions: [Permission.BusinessesModify],
}
