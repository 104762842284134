import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  NavItem,
  NavEntryPoint,
  NavSupportedEnvironment,
} from '@retailer-platform/dashboard/routing'
import { webhookConfigurationRouter } from './routing/webhookConfigurationRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { webhookConfigurationAccessControl } from './access-control/webhookConfigurationAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'webhook-configuration',
  totem: {
    entity: 'webhook-configuration-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    partnerScoped: [webhookConfigurationRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Developer,
      attachToV2: NavEntryPoint.Developer,
      subNavItems: [
        {
          type: 'item',
          labelId: 'webhookConfigurationDomain.title',
          route: 'webhook-configuration',
          accessControl: webhookConfigurationAccessControl,
          position: 1,
          positionNavV2: 1,
          // this is ignored when developing locally
          environment: NavSupportedEnvironment.Production,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
