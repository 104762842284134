/* eslint-disable @typescript-eslint/no-explicit-any */

export const copyToClipboard = (value: string) => {
  const element = document.createElement('textarea')
  element.value = value
  document.body.appendChild(element)
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)
}

/*
 * compose
 *
 * NOTE: these typings come from redux.... but compose does not work
 * well with hocs in typescript, so please do not use this moving forward
 * for composing hocs together
 */

type Func0<R> = () => R
type Func1<T1, R> = (a1: T1) => R
type Func2<T1, T2, R> = (a1: T1, a2: T2) => R
type Func3<T1, T2, T3, R> = (a1: T1, a2: T2, a3: T3, ...args: any[]) => R

/**
 * Composes single-argument functions from right to left. The rightmost
 * function can take multiple arguments as it provides the signature for the
 * resulting composite function.
 *
 * @param funcs The functions to compose.
 * @returns R function obtained by composing the argument functions from right
 *   to left. For example, `compose(f, g, h)` is identical to doing
 *   `(...args) => f(g(h(...args)))`.
 */
export function compose(): <R>(a: R) => R

export function compose<F extends Function>(f: F): F

/* two functions */
export function compose<A, R>(f1: (b: A) => R, f2: Func0<A>): Func0<R>
export function compose<A, T1, R>(f1: (b: A) => R, f2: Func1<T1, A>): Func1<T1, R>
export function compose<A, T1, T2, R>(f1: (b: A) => R, f2: Func2<T1, T2, A>): Func2<T1, T2, R>
export function compose<A, T1, T2, T3, R>(
  f1: (b: A) => R,
  f2: Func3<T1, T2, T3, A>
): Func3<T1, T2, T3, R>

/* three functions */
export function compose<A, B, R>(f1: (b: B) => R, f2: (a: A) => B, f3: Func0<A>): Func0<R>
export function compose<A, B, T1, R>(
  f1: (b: B) => R,
  f2: (a: A) => B,
  f3: Func1<T1, A>
): Func1<T1, R>
export function compose<A, B, T1, T2, R>(
  f1: (b: B) => R,
  f2: (a: A) => B,
  f3: Func2<T1, T2, A>
): Func2<T1, T2, R>
export function compose<A, B, T1, T2, T3, R>(
  f1: (b: B) => R,
  f2: (a: A) => B,
  f3: Func3<T1, T2, T3, A>
): Func3<T1, T2, T3, R>

/* four functions */
export function compose<A, B, C, R>(
  f1: (b: C) => R,
  f2: (a: B) => C,
  f3: (a: A) => B,
  f4: Func0<A>
): Func0<R>
export function compose<A, B, C, T1, R>(
  f1: (b: C) => R,
  f2: (a: B) => C,
  f3: (a: A) => B,
  f4: Func1<T1, A>
): Func1<T1, R>
export function compose<A, B, C, T1, T2, R>(
  f1: (b: C) => R,
  f2: (a: B) => C,
  f3: (a: A) => B,
  f4: Func2<T1, T2, A>
): Func2<T1, T2, R>
export function compose<A, B, C, T1, T2, T3, R>(
  f1: (b: C) => R,
  f2: (a: B) => C,
  f3: (a: A) => B,
  f4: Func3<T1, T2, T3, A>
): Func3<T1, T2, T3, R>

/* rest */
export function compose<R>(f1: (b: any) => R, ...funcs: Function[]): (...args: any[]) => R

export function compose<R>(...funcs: Function[]): R {
  if (funcs.length === 0) {
    return ((arg: any) => arg) as unknown as R
  }

  if (funcs.length === 1) {
    return funcs[0] as unknown as R
  }

  return funcs.reduce(
    (a, b) =>
      (...args: any[]) =>
        a(b(...args))
  ) as unknown as R
}

type TransformValueCallback<T> = (n: T[keyof T]) => any
type TransformedValues<T, U extends TransformValueCallback<T>> = { [Key in keyof T]: ReturnType<U> }

/**
 * Takes an object, and maps each `{ key: value }` pair to `{ key: callback(value) }`
 * @param object the key-value object that will be transformed
 * @param callback the function that will be applied to each value
 * @returns the new object, with each value mapped to callback(value)
 */
export function transformValues<
  T extends { [key: string]: any },
  U extends TransformValueCallback<T>
>(object: T, callback: U): TransformedValues<T, U> {
  const keys: Array<keyof T> = Object.keys(object)

  return keys.reduce<Partial<TransformedValues<T, U>>>((acc, key) => {
    acc[key] = callback(object[key])
    return acc
  }, {}) as TransformedValues<T, U>
}

/**
 * Given an ObjectType, generate an array of array of keys of type ObjectType
 * @param object An object of type ObjectType
 */
export function keysOfObject<ObjectType>(object: ObjectType) {
  return Object.keys(object) as (keyof ObjectType)[]
}

// Remove types from T that are not assignable to U
export type Filter<T, U> = T extends U ? T : never
