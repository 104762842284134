const EN_US = {
  'aggregateStorePerformanceDomain.title': 'Stores', //old name -- delete and replace with title.new when `dashboard_store_ops_nav` feature toggle is fully enabled
  'aggregateStorePerformanceDomain.title.new': 'Staffing performance',
  // StoreGroupDropdown
  'aggregateStorePerformanceDomain.storeGroupDropdown.label': 'Select a group of stores to view',
  'aggregateStorePerformanceDomain.storeGroupDropdown.menuTitle': 'Store groups',
  'aggregateStorePerformanceDomain.storeGroupDropdown.edit': 'Edit store groups',
  'aggregateStorePerformanceDomain.storeGroupDropdown.create': 'Create new store group',
  'aggregateStorePerformanceDomain.storeGroupDropdown.clear': 'Clear',
  'aggregateStorePerformanceDomain.storeGroupDropdown.apply': 'Apply',
  'aggregateStorePerformanceDomain.storeGroupDropdown.allStores': 'All stores',
  'aggregateStorePerformanceDomain.storeGroupDropdown.defaultPlaceholder': 'Select a store group',
  'aggregateStorePerformanceDomain.storeGroupDropdown.groupsSelectedPlaceholder': 'Store groups',
  // StoreGroupModal
  'aggregateStorePerformanceDomain.storeGroupFormModal.title': 'Create new store group',
  'aggregateStorePerformanceDomain.storeGroupFormModal.cancel': 'Cancel',
  'aggregateStorePerformanceDomain.storeGroupFormModal.create': 'Create',
  'aggregateStorePerformanceDomain.storeGroupFormModal.save': 'Save changes',
  // EditStoreGroupsModal
  'aggregateStorePerformanceDomain.editStoreGroupsModal.title': 'Edit store groups',
  'aggregateStorePerformanceDomain.editStoreGroupsModal.cancel': 'Cancel',
  'aggregateStorePerformanceDomain.editStoreGroupsModal.header': 'Store Groups',
  'aggregateStorePerformanceDomain.editStoreGroupsModal.addNew': 'Add New',
  // Full Screen Modal
  'aggregateStorePerformanceDomain.fullScreenModal.close': 'Close',
  // StoreMetricsTable
  'aggregateStorePerformanceDomain.storeMetricsTable.storeName': 'Stores',
  'aggregateStorePerformanceDomain.storeMetricsTable.hoursScheduled': 'Scheduled hours',
  'aggregateStorePerformanceDomain.storeMetricsTable.hoursRecommended': 'Recommended hours',
  'aggregateStorePerformanceDomain.storeMetricsTable.scheduleGaps': 'Schedule gaps',
  'aggregateStorePerformanceDomain.storeMetricsTable.edit': 'Edit schedule',
  'aggregateStorePerformanceDomain.storeMetricsTable.matchRate': 'Match rate',
  'aggregateStorePerformanceDomain.storeMetricsTable.dedicatedMatchRate': 'Dedicated match rate',
  'aggregateStorePerformanceDomain.storeMetricsTable.utilization': 'Utilization',
  'aggregateStorePerformanceDomain.storeMetricsTable.dedicatedUtilization': 'Dedicated utilization',
  'aggregateStorePerformanceDomain.storeMetricsTable.fiveHourAvailability': '5-hour availability',
  'aggregateStorePerformanceDomain.storeMetricsTable.partnerPickOrders': 'PP orders',
  'aggregateStorePerformanceDomain.storeMetricsTable.partnerPickOrdersTooltipTitle':
    'Partner Pick orders',
  'aggregateStorePerformanceDomain.storeMetricsTable.partnerPickOrdersTooltipContent':
    'The amount of pickup orders shopped by your store employees.',
  'aggregateStorePerformanceDomain.storeMetricsTable.fullServiceShopperOrders': 'FSS orders',
  'aggregateStorePerformanceDomain.storeMetricsTable.fullServiceShopperOrdersTooltipTitle':
    'Full Service Shopper orders',
  'aggregateStorePerformanceDomain.storeMetricsTable.fullServiceShopperOrdersTooltipContent':
    'The amount of pickup orders shopped by Instacart shoppers.',
  'aggregateStorePerformanceDomain.storeMetricsTable.fullServiceShopperOrdersTooltipDisclaimer':
    'Only for stores that have FSS or Overflow enabled',
  // UpdatedAt
  'aggregateStorePerformanceDomain.updatedAt.text': 'Updated at',
  // PickingPerformanceOverview
  'aggregateStorePerformanceDomain.pickingPerformanceOverview.title': 'Store performance',
  'aggregateStorePerformanceDomain.pickingPerformanceOverview.subtitle': 'stores',
  // ExportButton
  'aggregateStorePerformanceDomain.exportButton.export': 'Export',
  // FilterPill
  'aggregateStorePerformanceDomain.filterPill.removeButtonLabel': 'Remove filter',
  // FiltersDropdown
  'aggregateStorePerformanceDomain.filtersDropdown.label': 'Filter',
  'aggregateStorePerformanceDomain.filtersDropdown.menuTitle': 'Add a filter',
  'aggregateStorePerformanceDomain.filtersDropdown.metrics': 'Metrics',
  'aggregateStorePerformanceDomain.filtersDropdown.clear': 'Clear',
  'aggregateStorePerformanceDomain.filtersDropdown.apply': 'Apply',
  'aggregateStorePerformanceDomain.filtersDropdown.availability': '5-hour availability',
  'aggregateStorePerformanceDomain.filtersDropdown.availabilitySubtitle':
    'Stores with 5-hour availability below 80%',
  'aggregateStorePerformanceDomain.filtersDropdown.utilization': 'Utilization',
  'aggregateStorePerformanceDomain.filtersDropdown.utilizationSubtitle':
    'Stores with utilization below 70%',
  'aggregateStorePerformanceDomain.filtersDropdown.matchRate': 'Match rate',
  'aggregateStorePerformanceDomain.filtersDropdown.matchRateSubtitle':
    'Stores with match rate below 80%',
  // HistoricalStorePerformanceMetrics
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.totalHours': 'Total hours',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.totalHoursTooltip':
    'Actual staffed picking hours divided by recommended picking hours.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.scheduleGaps': 'Schedule gaps',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.scheduleGapsTooltip':
    "Times when no devices are scheduled but there's a staffing recommendation to schedule at least 1 device.",
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.matchRate': 'Match rate',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.matchRateTooltip':
    'Hours of overlap between staffed picking hours and recommended picking hours divided by total recommended picking hours.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.dedicatedMatchRate':
    'Dedicated match rate',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.dedicatedMatchRateTooltip':
    'Hours of overlap between staffed picking hours and recommended picking hours divided by total recommended picking hours.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.dedicatedTooltipDisclaimer':
    'Doesn’t include flex devices.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.utilization': 'Utilization',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.utilizationTooltip':
    'Actual working time (actively picking or running) during a picking shift divided by total staffed picking hours.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.dedicatedUtilization':
    'Dedicated utilization',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.dedicatedUtilizationTooltip':
    'Actual working time (actively shopping or running) divided by staffed time.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.fiveHourAvailability':
    '5-hour availability',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.fiveHourAvailabilityTooltip':
    'Number of times customers could select a pickup window within 5 hours of ordering divided by total number of checkout visits.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.fulfilledOrders':
    'Fulfilled orders',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.fulfilledOrdersTooltip':
    'Total number of orders fulfilled.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.emptyMetrics.firstDayOfWeek':
    'Calculation in progress. Today’s data will be available tomorrow.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.emptyMetrics.futureWeek':
    'No data available. Date can’t be later than today.',
  'aggregateStorePerformanceDomain.historicalStorePerformanceMetrics.emptyMetrics.noStoresSelected':
    'No stores selected, no data to display.',
  // FiltersEmptyState
  'aggregateStorePerformanceDomain.filtersEmptyState.title': 'No results',
  'aggregateStorePerformanceDomain.filtersEmptyState.content':
    'Try removing or editing some filters for more results.',
  'aggregateStorePerformanceDomain.filtersEmptyState.edit': 'Edit filters',
} as const

export default EN_US
