import { nanoid } from 'nanoid'
import { createEventHook } from '../../hooks/event-hooks/createEventHook'
import { type NotificationType, type NotificationTypeInput } from './NotificationsDisplay.types'

export const [emitNotification, useNotificationEvents] = createEventHook<{
  notification: NotificationType
  clearNotification: { id: string }
}>()

/**
 * Sends a notification to the user.
 * If the id is not provided, one will be automatically generated
 *
 * @param notification
 */
export const notify = (notification: NotificationTypeInput) => {
  const id = notification.id ?? nanoid()

  emitNotification('notification', { ...notification, id } as NotificationType)
}

export const clearNotification = (id: string) => {
  emitNotification('clearNotification', { id })
}
