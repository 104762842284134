import React, { lazy, type FunctionComponent } from 'react'
import { Redirect } from 'react-router'
import { URL_STORE_GROUPS } from '../../../utils/routing/routes'
import RetailerSwitch from '../../components/RetailerSwitch'
import RetailerRoute from '../../components/RetailerRoute'

const StoreGroupsList = lazy(() => import('./components/list/StoreGroupsList'))
const StoreGroupsAdd = lazy(() => import('./components/add/StoreGroupsAdd'))
const StoreGroupsEdit = lazy(() => import('./components/edit/StoreGroupsEdit'))

export const StoreGroupsSection: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <RetailerSwitch>
    <RetailerRoute
      exact
      route="store-groups"
      component={StoreGroupsList}
      scopePicker={{ titles: ['settings.storeGroups.title'] }}
    />
    <RetailerRoute exact route="store-groups-add" component={StoreGroupsAdd} />
    <RetailerRoute exact route="store-groups-edit" component={StoreGroupsEdit} />
    <Redirect to={URL_STORE_GROUPS} />
  </RetailerSwitch>
)
