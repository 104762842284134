import React from 'react'
import styled from '@emotion/styled'
import { colors, spacing } from '@retailer-platform/shared-components'
import { type Pages } from './tool-effectiveness/ToolEffectivenessController'

export interface ButtonProps {
  buttons: { text: string; onClick: () => void; selected: boolean }[]
}

export interface PageProps {
  pageName: Pages
}

export const ButtonsSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '16px',
  marginLeft: 'auto',
  marginRight: 0,
})

const grey = colors.GRAYSCALE.X20
const green = colors.PRIMARY.REGULAR
const white = colors.GRAYSCALE.X0

interface ButtonItemType {
  lastElement: boolean
  selected: boolean
}

const ButtonItemContainer = styled.div({
  flex: '0 1 100%',
})
const ButtonItem = styled.div<ButtonItemType>(({ lastElement, selected }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  padding: spacing.X16,
  gap: spacing.X12,
  whiteSpace: 'nowrap',
  fontSize: 'min(14px, 13cqi)',
  alignItems: 'center',
  borderRight: lastElement ? '' : `1px solid ${grey}`,
  backgroundColor: selected ? green : '',
  color: selected ? white : '',
  ':hover': {
    backgroundColor: green,
    cursor: 'pointer',
    color: white,
  },
  textWrap: 'wrap',
  lineHeight: '16px',
  height: '100%',
}))

const ButtonArray = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  border: `1px solid ${grey}`,
  borderRadius: spacing.X8,
  overflow: 'clip',
  marginBottom: spacing.X12,
})

export const ButtonArrayComponent = (props: ButtonProps) => (
  <ButtonArray data-testid={`button-array`}>
    {props.buttons.map((button, index) => (
      <ButtonItemContainer key={button.text}>
        <ButtonItem
          key={button.text}
          data-testid={'button-array-' + index}
          onClick={() => {
            button.onClick()
          }}
          lastElement={index == props.buttons.length - 1}
          selected={button.selected}
        >
          {button.text}
        </ButtonItem>
      </ButtonItemContainer>
    ))}
  </ButtonArray>
)
