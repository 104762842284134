import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { dataIngestionAdminAccessControl } from '../access-control/dataIngestionAdminAccess.configuration'

const DataIngestionApprovalRequestShowPage = lazy(
  () => import('../pages/approval-request/show/DataIngestionApprovalRequestShowPage')
)
// @hygen:inject page-import

// Note that this is intentionally not a component. Do not wrap <DomainRoute> with anything else, as it will cause issues with the parent <Switch />
// More information here https://v5.reactrouter.com/web/api/Switch/children-node
export const dataIngestionAdminRouter = (
  <DomainRoute route="app-admin-data-ingestion" accessControl={dataIngestionAdminAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-data-ingestion-approval-request/show"
        component={DataIngestionApprovalRequestShowPage}
      />
      {/* @hygen:inject router-route 
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
