// @ts-nocheck legacy file transformed to TS
import styled from '@emotion/styled'
import { colors } from 'ic-snacks'

export const OldCard = styled.div`
  display: inline-block;
  background: white;
  padding: ${props => props.padding || '20px'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  position: relative;
  margin: 6px;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(34, 25, 25, 0.1);
  overflow: hidden;
`
