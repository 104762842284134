import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  NavItem,
  NavEntryPoint,
  NavSupportedEnvironment,
} from '@retailer-platform/dashboard/routing'
import { fulfillmentTestingCenterAdminRouter } from './routing/fulfillmentTestingCenterAdminRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { fulfillmentTestingCenterAdminAccessControl } from './access-control/fulfillmentTestingCenterAdminAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'fulfillment-testing-center',
  totem: {
    entity: 'fulfillment-testing-center-dashboard-tool',
    hasDevTooling: true,
  },
  routes,
  translations: { EN_US },
  routers: {
    partnerScoped: [fulfillmentTestingCenterAdminRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Developer,
      attachToV2: NavEntryPoint.Developer,
      subNavItems: [
        {
          type: 'item',
          route: 'ftc-orders',
          labelId: 'fulfillmentTestingCenterDomain.title',
          position: 2,
          positionNavV2: 2,
          // this is ignored when developing locally
          environment: NavSupportedEnvironment.Dev,
          accessControl: fulfillmentTestingCenterAdminAccessControl,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
