import { type ApolloError } from 'apollo-client'
import { useCallback, useMemo } from 'react'
import { useDashMessages } from '../../intl/intl.hooks'
import { type DashMessages } from '../../intl/intl.types'

type UseApolloErrorMessage<TMessageIds extends string> = <
  TMessageIdMap extends Record<string, TMessageIds>,
  TMessageValues extends Record<string, string>
>(
  error?: ApolloError,
  messageIdMap?: TMessageIdMap,
  messageValues?: TMessageValues
) => string | undefined

/**
 * A hook for obtaining the translated string of service error messages.
 * If the extracted error is not known it will fall back to a generic default.
 * if the error is a network error it will return a generic network error message.
 * Messages can be overridden by passing an extendedMessages object.
 * Messages can have variables by passing a messageValues object.
 *
 * The result of this function is the memoized message. If you need to use it in a useEffect
 * or other place where rules of hooks might prove troublesome, use useGetApolloErrorMessage
 *
 * @param error
 * @param extendedMessages
 * @param messageValues
 * @returns string
 */
export const useApolloErrorMessage: UseApolloErrorMessage<DashMessages> = (
  error,
  extendedMessages,
  messageValues
) => {
  const getErrorMessage = useGetApolloErrorMessage(extendedMessages, messageValues)

  return useMemo(() => getErrorMessage(error), [error, getErrorMessage])
}

type UseGetApolloErrorMessage<TMessageIds extends string> = <
  TMessageIdMap extends Record<string, TMessageIds>,
  TMessageValues extends Record<string, string>
>(
  messageIdMap?: TMessageIdMap,
  messageValues?: TMessageValues
) => (error: ApolloError) => string | undefined

/**
 * A hook for obtaining the translated string of service error messages.
 * If the extracted error is not known it will fall back to a generic default.
 * if the error is a network error it will return a generic network error message.
 * Messages can be overridden by passing an extendedMessages object.
 * Messages can have variables by passing a messageValues object.
 *
 * Main difference with useApolloErrorMessage is that this hook returns a function that can be
 * used in useEffects or other places when rules of hooks might prove troublesome
 *
 * @param error
 * @param extendedMessages
 * @param messageValues
 * @returns string
 */
export const useGetApolloErrorMessage: UseGetApolloErrorMessage<DashMessages> = (
  extendedMessages,
  messageValues
) => {
  const messages = useDashMessages(
    {
      // a default error message to use if the error code is not found
      default: 'apolloError.default',
      // Generic error message for network errors
      networkError: 'apolloError.networkError',
      // A list of known error codes and their corresponding messages
      UNDETERMINED_ACCOUNT: 'apolloError.UNDETERMINED_ACCOUNT',
      NOT_AUTHORIZED: 'apolloError.NOT_AUTHORIZED',
      TOO_MANY_REQUESTS: 'apolloError.TOO_MANY_REQUESTS',
      // User-defined error messages
      ...extendedMessages,
    },
    messageValues
  )

  return useCallback(
    (error?: ApolloError) => {
      // If there's no error, bail early.
      if (!error) return undefined
      if (error.networkError) return messages.networkError

      const errorCode = error?.graphQLErrors?.at(0)?.extensions?.code as string

      // Use by precedence: explicit defined messages first or generic default
      return messages[errorCode] || messages.default
    },
    [messages]
  )
}

export const makeUseApolloErrorMessage = <
  TMessageIds extends string,
  TMessageIdMap extends Record<string, string> = Record<string, TMessageIds>
>(
  baseMap?: TMessageIdMap
): UseApolloErrorMessage<TMessageIds> => {
  const localUseApolloErrorMessage = useApolloErrorMessage as UseApolloErrorMessage<TMessageIds>

  return (err, messageIdMap, messageValues) =>
    localUseApolloErrorMessage(
      err,
      {
        ...baseMap,
        ...messageIdMap,
      },
      messageValues
    )
}

export const makeUseGetApolloErrorMessage = <
  TMessageIds extends string,
  TMessageIdMap extends Record<string, string> = Record<string, TMessageIds>
>(
  baseMap?: TMessageIdMap
): UseGetApolloErrorMessage<TMessageIds> => {
  const localUseGetApolloErrorMessage =
    useGetApolloErrorMessage as UseGetApolloErrorMessage<TMessageIds>

  return (messageIdMap, messageValues) =>
    localUseGetApolloErrorMessage(
      {
        ...baseMap,
        ...messageIdMap,
      },
      messageValues
    )
}
