import React, { type FunctionComponent, useState } from 'react'
import styled from '@emotion/styled'
import { Button, ServerError, colors, Text } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import Modal from '../../../../../components/Modal'
import Dropdown from '../../../../../components/Dropdown'
import { errors } from '../../../../../../utils/error-handling/errors'
import instacart from '../../../../../common/instacart'
import Slash from '../../../../../components/Slash'
import {
  withNotificationsContext,
  type NotificationsContextValue,
} from '../../../../../contexts/notifications/NotificationsContext'
import TextField from '../../../../../components/Forms/TextField'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import { YES_NO_OPTIONS } from '../../../../../common/constants'
import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { COST_UNIT_OPTIONS } from '../../../../../common/utils/catalog/units'
import FormField from '../form-components/FormField'
import moment from 'moment'
import { DateRangePicker, type FocusedInputShape } from 'react-dates'
import { css, Global } from '@emotion/react'
import { useUpdateAvailabilityScoreOverrideMutation } from '@retailer-platform/domain-products/availabilityScoreOverride'
import { NINETY_DAYS } from './ProductLocations'
import {
  AvailabilityScoreOverrideDateError,
  notifyUpdateErrors,
} from './components/AvailabilityScoreOverrideErrors'

const buttonStyle = {
  marginLeft: '10px',
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 320px;
`
const AvailabilityScoreOverrideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AvailabilityScoreOverrideSelector = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 5px;
`

interface Props extends NotificationsContextValue {
  onClose: () => void
  itemIds: number[]
  partnerId: string
  warehouseId: string
  productId: string
  showAvailabilityScoreOverride: boolean
}

interface State {
  isBusy: boolean
  isError: boolean
  taxable: boolean
  available: boolean
  priced_by: string
  costPricePerUnit: string
}

interface AvailabilityScoreOverrideState {
  enable: boolean
  startAt: Date
  endAt: Date
  dateError: boolean
}

const BulkEditProductLocationsModal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onClose,
  itemIds,
  partnerId,
  warehouseId,
  productId,
  showAvailabilityScoreOverride,
  notify,
}) => {
  const [state, setState] = useState<State>({
    isBusy: false,
    isError: false,
    taxable: false,
    available: false,
    priced_by: '',
    costPricePerUnit: '',
  })
  const [availabilityScoreOverrideState, setAvailabilityScoreOverrideState] =
    useState<AvailabilityScoreOverrideState>({
      enable: false,
      startAt: null,
      endAt: null,
      dateError: false,
    })
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)

  const [updateAvailabilityScoreOverrideMutation] = useUpdateAvailabilityScoreOverrideMutation()

  const onSubmit = async () => {
    const { available, taxable, costPricePerUnit, priced_by } = state

    // Cast empty string as undefined so it doesn't serialize.
    const cost =
      costPricePerUnit != null && costPricePerUnit.length > 0 ? costPricePerUnit : undefined

    setState({
      ...state,
      isBusy: true,
      isError: false,
    })

    try {
      await instacart.go.put(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/items/bulk_update`,
        {
          data: {
            item_ids: itemIds,
            available,
            taxable,
            cost_price_per_unit: cost,
            priced_by,
          },
        }
      )

      if (showAvailabilityScoreOverride) {
        const {
          enable: availabilityScoreOverride,
          startAt: availabilityScoreOverrideStartAt,
          endAt: availabilityScoreOverrideEndAt,
        } = availabilityScoreOverrideState
        const itemEntityIds = itemIds.map(itemId => {
          return {
            itemId: {
              itemId: itemId.toString(),
            },
          }
        })
        const data = {
          entityId: itemEntityIds,
          retailerId: warehouseId,
          enable: availabilityScoreOverride,
          overrideStartAt: availabilityScoreOverride ? availabilityScoreOverrideStartAt : null,
          overrideEndAt: availabilityScoreOverride ? availabilityScoreOverrideEndAt : null,
        }
        const resp = await updateAvailabilityScoreOverrideMutation({
          variables: {
            data,
          },
        })

        const errors =
          resp.data?.retailerProductAttributeEditServiceUpdateAvailabilityScoreOverride?.errors
        if (errors) {
          errors.forEach(notifyUpdateErrors)
          setState({
            ...state,
            isBusy: false,
          })
          return
        }
      }
      deprecatedAnalytics.track('product.bulk_edit', {
        productId,
        itemIds,
      })
      notify(<FormattedMessage id="catalog.products.locations.requestSubmitted" />)
      onClose()
    } catch (e) {
      errors.captureException(e)

      setState({
        ...state,
        isError: true,
        isBusy: false,
      })
    }
  }

  const handleAvailableChange = (available: boolean) => {
    setState({
      ...state,
      available,
    })
  }

  const handleAvailabilityScoreOverrideChange = (enable: boolean) => {
    setAvailabilityScoreOverrideState({
      ...availabilityScoreOverrideState,
      enable,
    })
  }

  const handleAvailabilityScoreOverrideDateChange = ({ startDate, endDate }) => {
    const start = startDate?.toDate()
    const end = endDate?.toDate()
    setAvailabilityScoreOverrideState({
      ...availabilityScoreOverrideState,
      dateError: start && end && end.getTime() - start.getTime() > NINETY_DAYS,
      startAt: start,
      endAt: end,
    })
  }

  const handleTaxableChange = (taxable: boolean) => {
    setState({
      ...state,
      taxable,
    })
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      costPricePerUnit: e.target.value,
    })
  }

  const handlePricedByChange = (priced_by: string) => {
    setState({
      ...state,
      priced_by,
    })
  }

  const disableSave = () => {
    if (state.isBusy) {
      return true
    } else if (availabilityScoreOverrideState.dateError) {
      return true
    } else if (
      availabilityScoreOverrideState.enable &&
      (availabilityScoreOverrideState.startAt == null ||
        availabilityScoreOverrideState.endAt == null)
    ) {
      return true
    }
    return false
  }

  return (
    <Modal height={showAvailabilityScoreOverride ? 660 : 560} onClose={onClose}>
      {(
        Header: React.ComponentType<React.PropsWithChildren<unknown>>,
        Body: React.ComponentType<React.PropsWithChildren<unknown>>,
        Footer: React.ComponentType<React.PropsWithChildren<unknown>>
      ) => [
        <Header>
          <div>
            Bulk Edit
            <Text
              elementType="span"
              variant="T.18"
              fontWeight="light"
              style={{ color: colors.GRAY_74, marginLeft: 5 }}
            >
              ({itemIds.length} store locations)
            </Text>
          </div>
        </Header>,
        <Body>
          <Container>
            <InnerContainer>
              {state.isError && (
                <ServerError style={{ width: '100%' }} text="Please try again later" />
              )}
              <FormField label="Available">
                <Dropdown
                  theme="dark"
                  value={state.available}
                  onChange={handleAvailableChange}
                  options={YES_NO_OPTIONS}
                />
              </FormField>
              {showAvailabilityScoreOverride && (
                <FormField label="Disable Availability Prediction">
                  <AvailabilityScoreOverrideContainer>
                    <AvailabilityScoreOverrideSelector>
                      <Dropdown
                        theme="dark"
                        value={availabilityScoreOverrideState.enable}
                        onChange={handleAvailabilityScoreOverrideChange}
                        options={YES_NO_OPTIONS}
                      />
                      {availabilityScoreOverrideState.enable && (
                        <React.Fragment>
                          <Global
                            styles={css`
                              .DateRangePicker_picker {
                                z-index: 300;
                              }
                            `}
                          />
                          <DateRangePicker
                            startDate={
                              availabilityScoreOverrideState.startAt
                                ? moment(availabilityScoreOverrideState.startAt)
                                : null
                            }
                            startDateId="start_date_picker"
                            endDate={
                              availabilityScoreOverrideState.endAt
                                ? moment(availabilityScoreOverrideState.endAt)
                                : null
                            }
                            endDateId="end_date_picker"
                            onDatesChange={handleAvailabilityScoreOverrideDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={setFocusedInput}
                            small
                            showClearDates
                            withPortal
                          />
                        </React.Fragment>
                      )}
                    </AvailabilityScoreOverrideSelector>
                    {availabilityScoreOverrideState.enable &&
                      availabilityScoreOverrideState.dateError && (
                        <AvailabilityScoreOverrideDateError />
                      )}
                  </AvailabilityScoreOverrideContainer>
                </FormField>
              )}
              <FormField label="Taxable">
                <Dropdown
                  theme="dark"
                  value={state.taxable}
                  onChange={handleTaxableChange}
                  options={YES_NO_OPTIONS}
                />
              </FormField>
              <FormField label="Price">
                <TextField
                  name="costPricePerUnit"
                  prefix="$"
                  value={state.costPricePerUnit}
                  onChange={handlePriceChange}
                />
                <Slash />
                <Dropdown
                  width="130px"
                  theme="dark"
                  defaultText="Select Unit"
                  value={state.priced_by}
                  onChange={handlePricedByChange}
                  options={COST_UNIT_OPTIONS}
                />
              </FormField>
            </InnerContainer>
          </Container>
        </Body>,
        <Footer>
          <Button
            style={buttonStyle}
            onClick={onClose}
            disabled={state.isBusy}
            snacksStyle="secondary"
          >
            Cancel
          </Button>
          <Button
            style={buttonStyle}
            onClick={onSubmit}
            disabled={disableSave()}
            snacksStyle="primary"
          >
            Save
          </Button>
        </Footer>,
      ]}
    </Modal>
  )
}

export default withTrackEventOnMount({
  id: 'catalog.products.store_locations.bulk_edit.viewed',
  description: 'Viewed the bulk edit section of the product store locations page',
})(withNotificationsContext(BulkEditProductLocationsModal))
