import {
  type DashRouteType,
  URL_APP_ADMIN,
  URL_PUBLIC,
  URL_RETAILER_SCOPED,
} from '@retailer-platform/dashboard/routing'

const URL_FOODSTORM = `${URL_RETAILER_SCOPED}/foodstorm`
// @hygen:inject scoped-urls

export type DashRouteSet = DashRouteType<'foodstorm'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  foodstorm: URL_FOODSTORM,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
