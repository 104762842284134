import { useContext } from 'react'
import { PartnerContext } from '../../../utils/contexts/partner/PartnerContext'
import { type AccessControlConfig } from './accessControl.utils'
import { useAccessControlCommon } from './useAccessControlCommon'

/**
 * Returns true if the access control requirements are met for the
 * current user. Otherwise returns false.
 */
function usePartnerAccessControl(): (
  accessControlConfig: AccessControlConfig | AccessControlConfig[]
) => boolean {
  const partnerContext = useContext(PartnerContext)
  return useAccessControlCommon(partnerContext)
}

export default usePartnerAccessControl
