import { type DashRouteType, URL_PUBLIC } from '@retailer-platform/dashboard/routing'

const URL_PUBLIC_PARTNER_ONBOARDING = `${URL_PUBLIC}/partner-onboarding`
const URL_PUBLIC_PARTNER_ONBOARDING_REGISTER = `${URL_PUBLIC_PARTNER_ONBOARDING}/register`
const URL_PUBLIC_PARTNER_ONBOARDING_SIGNUP = `${URL_PUBLIC_PARTNER_ONBOARDING}/signup`
const URL_PUBLIC_PARTNER_ONBOARDING_COMPLETION = `${URL_PUBLIC_PARTNER_ONBOARDING}/completion`
// @hygen:inject public-urls

export type DashRouteSet =
  | DashRouteType<'app-public-partner-onboarding'>
  | DashRouteType<'app-public-partner-onboarding-register'>
  | DashRouteType<'app-public-partner-onboarding-signup'>
  | DashRouteType<'app-public-partner-onboarding-completion'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'app-public-partner-onboarding': URL_PUBLIC_PARTNER_ONBOARDING,
  'app-public-partner-onboarding-register': URL_PUBLIC_PARTNER_ONBOARDING_REGISTER,
  'app-public-partner-onboarding-signup': URL_PUBLIC_PARTNER_ONBOARDING_SIGNUP,
  'app-public-partner-onboarding-completion': URL_PUBLIC_PARTNER_ONBOARDING_COMPLETION,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
