import React from 'react'
import { RegionLocationContext } from '../../../../../contexts/regionLocation/RegionLocationContext'
import AddProductToLocationsModal from './AddProductToLocationsModal'

interface Props {
  onClose: () => void
  partnerId: string
  warehouseId: string
  productId: string
}

class AddProductToLocations extends React.Component<Props> {
  render() {
    return (
      <RegionLocationContext.Consumer>
        {context =>
          context && (
            <AddProductToLocationsModal
              {...this.props}
              inventoryAreas={context.inventoryAreas}
              inventoryAreaStatus={context.inventoryAreaStatus}
            />
          )
        }
      </RegionLocationContext.Consumer>
    )
  }
}

export default AddProductToLocations
