import { type DashRouteType, URL_RETAILER_SCOPED } from '@retailer-platform/dashboard/routing'

const URL_AGGREGATE_STORE_PERFORMANCE = `${URL_RETAILER_SCOPED}/aggregate-store-performance`
const URL_AGGREGATE_STORE_PERFORMANCE_OVERVIEW = `${URL_AGGREGATE_STORE_PERFORMANCE}/overview`
// @hygen:inject scoped-urls

export type DashRouteSet =
  | DashRouteType<'aggregate-store-performance'>
  | DashRouteType<'aggregate-store-performance-overview'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'aggregate-store-performance': URL_AGGREGATE_STORE_PERFORMANCE,
  'aggregate-store-performance-overview': URL_AGGREGATE_STORE_PERFORMANCE_OVERVIEW,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
