import React from 'react'
import { type SelectComponentsConfig, components } from 'react-select-5'
import { ConfirmIcon } from '@instacart/ids-core'
import { Checkbox } from '../../checkbox/Checkbox'
import { colors, fontSize, spacing } from '../../../foundation'

/**
 * Add here any components that should be used by default by BaseSelect,
 * if they're too long, consider putting them on their own file in this same directory
 */
export const defaultComponents: SelectComponentsConfig<any, any, any> = {
  Option: props => {
    const isCompact = props.selectProps.compact
    const isMulti = props.selectProps.isMulti

    return (
      <components.Option {...props}>
        <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {isMulti && (
            <Checkbox
              checked={props.isSelected}
              size={isCompact ? 15 : 18}
              css={{
                margin: 0,
                marginRight: isCompact ? spacing.X4 : spacing.X8,
                minWidth: isCompact ? 15 : 18,
              }}
            />
          )}

          <span>{props.label}</span>

          <div css={{ flex: 1 }} />

          {!isMulti && props.isSelected && <ConfirmIcon color="brandPrimaryRegular" />}
        </div>

        {props.data.description && (
          <div
            css={{
              marginTop: isCompact ? 0 : 2,
              marginLeft: isMulti ? (isCompact ? 20 : 27) : 0,
              fontSize: isCompact ? fontSize.X10 : fontSize.X12,
              colors: colors.GRAYSCALE.X70,
              fontWeight: 'normal',
              lineHeight: 1.25,
            }}
          >
            {props.data.description}
          </div>
        )}
      </components.Option>
    )
  },

  ValueContainer: props => (
    <components.ValueContainer {...props}>
      {props.selectProps.icon && (
        <props.selectProps.icon
          // need more specificitiy
          css={{ '&&': { gridColumnStart: 1 } }}
          size={props.selectProps.compact ? 15 : 20}
          color={props.isDisabled ? 'systemGrayscale30' : 'systemGrayscale70'}
        />
      )}

      {props.children}
    </components.ValueContainer>
  ),
}
