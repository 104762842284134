import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { FeatureToggle } from './featureToggles'

export const onboardingAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.launchOnboarding],
}

export const onboardingSiteSetupAccessControl: DomainAccessControlConfig = {
  featureToggles: [FeatureToggle.OnboardingSiteSetup],
}
